import { StyleSheet } from "react-native";

import { THintsDataItem } from "@components/hints";

import { translate } from "../../../../i18n";
import {
  ButtonOneDay,
  ButtonSelectPeriod,
  ButtonSevenDay,
  DateTimePoints,
  GraphDatails,
} from "./components";

const dialogStyle = StyleSheet.create({
  dialog: {
    position: "absolute",
    top: 269,
    left: 0,
    right: 0,
  },
  graph: {
    top: 80,
  },
});

export const SensorsHistoryHintsData = (): THintsDataItem[] => [
  {
    id: "DateTimePoints",
    title: translate("Hints.title"),
    text: translate("Hints.ClimateHistory.textHintDateTimePoints"),
    HintComponent: DateTimePoints,
    dialogStyle: dialogStyle.dialog,
  },
  {
    id: "ButtonSelectPeriod",
    title: translate("Hints.title"),
    text: translate("Hints.ClimateHistory.textHintButtonSelectPeriod"),
    HintComponent: ButtonSelectPeriod,
    dialogStyle: dialogStyle.dialog,
  },
  {
    id: "ButtonOneDay",
    title: translate("Hints.title"),
    text: translate("Hints.ClimateHistory.textHintButtonOneDay"),
    HintComponent: ButtonOneDay,
    dialogStyle: dialogStyle.dialog,
  },
  {
    id: "ButtonSevenDay",
    title: translate("Hints.title"),
    text: translate("Hints.ClimateHistory.textHintButtonSevenDay"),
    HintComponent: ButtonSevenDay,
    dialogStyle: dialogStyle.dialog,
  },
  {
    id: "GraphDetails",
    title: translate("Hints.title"),
    text: translate("Hints.ClimateHistory.textHintGraphDatails"),
    HintComponent: GraphDatails,
    dialogStyle: dialogStyle.graph,
  },
  {
    id: "gotIt",
    title: translate("Hints.helpButtonTitle"),
    text: translate("Hints.helpButtonContent"),
  },
];
