import React, { useEffect, useState } from "react";
import { Platform, StyleSheet } from "react-native";
import { StackScreenProps } from "@react-navigation/stack";
import { observer } from "mobx-react-lite";

import { calibrationIsAvailable } from "@models/sensor/sensor-calibration";
import { useStores } from "@models/index";
import { Screen } from "@components/index";
import { TopBarNew } from "@components/layout";
import { Dialog, ErrorsAlert } from "@components/ui";

import * as SensorIcons from "../../../../svgs/sensors";
import { CloseIcon, NoneIcon } from "../../../../svgs";
import { translate } from "../../../../i18n";
import { spacing } from "../../../../theme";
import { CalibrationNotAvailable } from "../NotAvailable";
import { SensorsNavigatorParamList } from "../../navigation";
import { CalibrationStep } from "../types";
import {
  CalibrationContentProps,
  CalibrationStepClean,
  CalibrationStepError,
  CalibrationStepExpired,
  CalibrationStepPrepare,
  CalibrationStepPrepareInfo,
  CalibrationStepProcess,
  CalibrationStepSuccess,
} from "./components";

const styles = StyleSheet.create({
  screen: {
    paddingBottom: Platform.OS === "ios" ? spacing[5] : 0,
    flex: 1,
  },
});

const CalibrationLongTimeContent = observer(
  ({
    calibration,
    onStop,
    onExit,
  }: CalibrationContentProps & { onStop: () => void; onExit: () => void }) => {
    if (calibration.isLoading) {
      return null;
    }

    if (calibration.isError) {
      return <CalibrationStepError calibration={calibration} />;
    }

    if (calibration.isSuccess) {
      return <CalibrationStepSuccess calibration={calibration} />;
    }

    if (calibration.isExpired) {
      return <CalibrationStepExpired calibration={calibration} />;
    }

    if (calibration.currentStep === CalibrationStep.PrepareInfo) {
      return <CalibrationStepPrepareInfo calibration={calibration} />;
    }

    if (calibration.currentStep === CalibrationStep.Prepare) {
      return <CalibrationStepPrepare calibration={calibration} />;
    }

    if (calibration.currentStep === CalibrationStep.Clean) {
      return <CalibrationStepClean calibration={calibration} />;
    }

    if (
      calibration.currentStep === CalibrationStep.Process1 ||
      calibration.currentStep === CalibrationStep.Process2
    ) {
      return (
        <CalibrationStepProcess
          calibration={calibration}
          onStop={onStop}
          onExit={onExit}
        />
      );
    }

    return null;
  },
);

export const SensorCalibrationLongTime: React.FC<
  StackScreenProps<SensorsNavigatorParamList, "sensor-calibration-longtime">
> = observer(({ route, navigation }) => {
  const { params = {} } = route as any;
  const { sensorStore } = useStores();

  const [isStopCalibration, setStopCalibration] = useState(false);
  const showStopCalibrationDialog = () => {
    setStopCalibration(true);
  };
  const hideStopCalibrationDialog = () => {
    setStopCalibration(false);
  };

  const [isReturnWarning, setReturnWarning] = useState(false);
  const showReturnWarningDialog = () => {
    setReturnWarning(true);
  };
  const hideReturnWarningDialog = () => {
    setReturnWarning(false);
  };

  const sensor = sensorStore.getSensor(params?.uid);
  const sensorCalibration = sensor?.calibration;

  useEffect(() => {
    sensorCalibration.fetchStatus();
  }, [sensorCalibration]);

  if (!sensorCalibration) {
    navigation.navigate("sensors-list");
    return null;
  }

  const goBack = () => {
    if (sensor?.isChangeableType) {
      navigation.navigate("sensors-list");
      navigation.navigate("sensor-details", { uid: sensor.uid });
    } else {
      navigation.goBack();
    }
  };

  const quitCalibration = () => {
    hideReturnWarningDialog();
    goBack();
  };

  const stopCalibration = () => {
    hideStopCalibrationDialog();
    sensorCalibration.stop();
    goBack();
  };

  const backHandler = () => {
    if (sensorCalibration.isInterrupted) {
      showStopCalibrationDialog();
      return null;
    }
    if (sensorCalibration.isInProgress) {
      showReturnWarningDialog();
      return null;
    }
    goBack();
    return null;
  };

  if (!calibrationIsAvailable(sensor.type)) {
    return <CalibrationNotAvailable sensor={sensor} />;
  }

  const Icon = SensorIcons[sensor.iconName] || NoneIcon;

  return (
    <Screen
      style={styles.screen}
      header={
        <TopBarNew
          title={translate("Sensors.Calibration.titleWithSensorType", {
            type: sensor.displayType,
          })}
          subtitle={sensor.displayName}
          icon={Icon}
          back={false}
          backHandler={backHandler}
          action={{
            icon: CloseIcon,
            onPress: backHandler,
          }}
        />
      }
      loading={sensorCalibration.isLoading}
    >
      <CalibrationLongTimeContent
        calibration={sensorCalibration}
        onStop={showStopCalibrationDialog}
        onExit={backHandler}
      />

      <Dialog
        title={translate("Sensors.Calibration.stopCalibrationTitle")}
        content={translate("Sensors.Calibration.stopCalibrationContent")}
        visible={isStopCalibration}
        onCancel={hideStopCalibrationDialog}
        okType="warning"
        okText={translate("common.stop")}
        onOk={stopCalibration}
      />

      <Dialog
        title={translate("common.warning")}
        content={translate(
          "Sensors.Calibration.warningExitLongTimeCalibrationContent",
          { time: sensorCalibration.getRemainingTime(true) },
        )}
        visible={isReturnWarning}
        onCancel={hideReturnWarningDialog}
        // okType="warning"
        okText={translate("common.ok")}
        onOk={quitCalibration}
      />

      <ErrorsAlert
        errors={sensorCalibration.statusErrors}
        onCancel={sensorCalibration.resetErrors}
      />
    </Screen>
  );
});
