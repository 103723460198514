import React, { useMemo, useState } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import { SwitchInput } from "@components/inputs";

import { spacing } from "../../../../../theme";
import { useAppTheme } from "../../../../../hooks";

const createStyles = (theme: any) =>
  StyleSheet.create({
    container: {
      marginBottom: spacing[4],
      padding: spacing[4],
      paddingBottom: spacing[3],
      borderRadius: spacing[4],
      borderWidth: 1,
      borderColor: theme.colors.menuBorder,
    },
    label: {
      fontSize: 16,
    },
    switch: {
      marginVertical: 0,
    },
  });

type Props = {
  label: string;
  children: React.ReactNode;
  onChange?: (state: boolean) => void;
  style?: StyleProp<ViewStyle>;
  disabled?: boolean;
  isOpened?: boolean;
};
// TODO replace switch in "OverheatPreventionInput", SunriseSunsetInput components !!!
export const SwitchBlock = ({
  label,
  children,
  style,
  disabled = false,
  isOpened = false,
  onChange,
}: Props) => {
  const theme = useAppTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);

  const [switchState, setSwitchState] = useState<boolean>(isOpened);

  const switchHandler = (state: boolean) => {
    if (disabled) return;
    setSwitchState(state);
    onChange(state);
  };

  return (
    <View style={[styles.container, style]}>
      <SwitchInput
        label={label}
        labelStyle={styles.label}
        containerStyle={styles.switch}
        onChange={switchHandler}
        value={switchState}
      />

      {switchState ? children : null}
    </View>
  );
};
