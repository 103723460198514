import React from "react";
import { observer } from "mobx-react-lite";
import { StyleSheet } from "react-native";

import { Text } from "@components/ui";

import { ExpandedAnswer, TQuestionsListItem } from "./ExpandedAnswer";

const styles = StyleSheet.create({
  text: {
    textAlign: "center",
  },
});

type TQuestionsListProps = {
  items: TQuestionsListItem[];
  searchText?: string;
};

export const QuestionsList = observer(
  ({ items, searchText }: TQuestionsListProps) => {
    return (
      <>
        {items.length === 0 ? (
          <Text style={styles.text} tx="Settings.faq.notFound" />
        ) : null}

        {items.map((item) => (
          <ExpandedAnswer key={item.id} item={item} searchText={searchText} />
        ))}
      </>
    );
  },
);
