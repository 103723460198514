import { View, StyleSheet } from "react-native";
import { Screen } from "@components/index";
import { Button, Heading } from "@components/ui";
import { LayoutCenter } from "@components/layout";

import { spacing } from "../../theme";
import { FooterFlowers, Success } from "../../svgs/pictures";

const styles = StyleSheet.create({
  screen: {
    paddingHorizontal: spacing[5],
    paddingBottom: spacing[0],
    paddingTop: spacing[6],
  },
  bottomImageContainer: {
    flex: 1,
    justifyContent: "flex-end",
  },
  title: {
    marginVertical: spacing[1],
    lineHeight: spacing[6],
    fontSize: spacing[5],
    textAlign: "center",
  },
});

export const PasswordRecoverySuccess = ({ navigation }) => {
  const submitHandler = () => navigation.navigate("SignIn");

  return (
    <Screen style={styles.screen}>
      <LayoutCenter>
        <View>
          <Success />
        </View>
        <View style={{ marginVertical: spacing[5] }}>
          <Heading
            style={styles.title}
            tx="Auth.SuccessfullRegistrationScreen.success"
          />
          <Heading
            style={styles.title}
            tx="Auth.PasswordRecoverySuccess.newPassword"
          />
        </View>
        <Button
          type="primary"
          onPress={submitHandler}
          tx="Auth.PasswordRecoverySuccess.returnToLogIn"
        >
          Return to Log in
        </Button>
        <View style={styles.bottomImageContainer}>
          <FooterFlowers />
        </View>
      </LayoutCenter>
    </Screen>
  );
};
