import React from "react";
import { StyleSheet } from "react-native";
import { observer } from "mobx-react-lite";

import { useStores } from "@models/index";
import { DeviceMode } from "@models/device/device";
import { DEVICE_MODE_ALL } from "@models/device/device-store";
import { TabItem, Tabs } from "@components/ui";

import { spacing } from "../../../../theme";
import { translate } from "../../../../i18n";

const styles = StyleSheet.create({
  tabs: {
    marginVertical: spacing[2],
    marginHorizontal: spacing[4],
  },
});

export const FilterTabs: React.FunctionComponent = observer(() => {
  const { deviceStore } = useStores();

  const onFilterPress = (item) => {
    deviceStore.setFilterMode(item.key);
  };

  const tabsItems: TabItem[] = [
    {
      key: DEVICE_MODE_ALL,
      name: translate("Devices.List.filter.All"),
      onPress: onFilterPress,
    },
    {
      key: DeviceMode.Auto,
      name: translate("Devices.List.filter.Auto"),
      onPress: onFilterPress,
    },
    {
      key: DeviceMode.Manual,
      name: translate("Devices.List.filter.Manual"),
      onPress: onFilterPress,
    },
  ];

  return (
    <Tabs
      items={tabsItems}
      currentTab={deviceStore.filterMode || DEVICE_MODE_ALL}
      style={styles.tabs}
    />
  );
});
