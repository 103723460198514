import React, { useState } from "react";
import { Pressable, StyleSheet } from "react-native";

import { Badge, Dialog, Text } from "@components/ui";
import { translate } from "../../../../i18n";
import { defaultColors } from "../../../../theme";

const styles = StyleSheet.create({
  badgePosition: {
    position: "absolute",
    top: 36,
    right: 48,
    zIndex: 10,
  },
  description: {
    color: defaultColors.label,
  },
});

export const NoFileSystemBadge = () => {
  const [visibleDialog, setVisibleDialog] = useState(false);
  const hideDialog = () => setVisibleDialog(false);
  const showDialog = () => setVisibleDialog(true);

  return (
    <>
      <Pressable style={styles.badgePosition} onPress={showDialog}>
        <Badge text={translate("Modules.state.noFileSystem")} />
      </Pressable>

      <Dialog
        title={translate("Modules.state.noFileSystem")}
        visible={visibleDialog}
        onDismiss={hideDialog}
        onOk={hideDialog}
        okText={translate("common.ok")}
      >
        <Text style={styles.description}>
          {translate("Modules.Details.noFileSystemDescription")}
        </Text>
      </Dialog>
    </>
  );
};
