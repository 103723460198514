import * as React from "react";
import Svg, { Path } from "react-native-svg";

export default function RightArrowIcon({
  color = "#939393",
  width = 10,
  height = 18,
}) {
  return (
    <Svg width={width} height={height} viewBox="0 0 10 18" fill="none">
      <Path d="M1 1L9 9L1 17" stroke={color} />
    </Svg>
  );
}
