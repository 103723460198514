import Svg, { SvgProps, Path } from "react-native-svg";

export const LetsStart = (props: SvgProps) => (
  <Svg width={360} height={360} fill="none" {...props}>
    <Path fill="#fff" d="M0 0h360v360H0z" />
    <Path
      d="m181.575 267.02 78.85-136.58h-157.71l78.86 136.58Z"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
      strokeDasharray="12 12"
    />
    <Path
      d="M260.124 166.88c20.125 0 36.44-16.315 36.44-36.44 0-20.125-16.315-36.44-36.44-36.44-20.126 0-36.44 16.315-36.44 36.44 0 20.125 16.314 36.44 36.44 36.44Z"
      fill="#B0EACC"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="M272.484 108.16h-24.72a7.26 7.26 0 0 0-7.26 7.26v30.05a7.26 7.26 0 0 0 7.26 7.26h24.72a7.26 7.26 0 0 0 7.26-7.26v-30.05a7.26 7.26 0 0 0-7.26-7.26Z"
      fill="#fff"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="M260.124 141.21c5.948 0 10.77-4.822 10.77-10.77 0-5.948-4.822-10.77-10.77-10.77-5.949 0-10.77 4.822-10.77 10.77 0 5.948 4.821 10.77 10.77 10.77Z"
      fill="#fff"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="M297.994 165.82a50.006 50.006 0 0 0 14.669-35.38 50 50 0 0 0-14.669-35.38M222.253 95.08a50 50 0 0 0 0 70.76"
      stroke="#2A2E32"
      strokeWidth={2.11}
      strokeLinejoin="round"
    />
    <Path
      d="M72.764 153.4h69.13a5.826 5.826 0 0 0 5.83-5.903 5.831 5.831 0 0 0-.83-2.927l-19.61-32.75a6.056 6.056 0 0 0-5.25-3h-49.27"
      fill="#fff"
    />
    <Path
      d="M72.764 153.4h69.13a5.826 5.826 0 0 0 5.83-5.903 5.831 5.831 0 0 0-.83-2.927l-19.61-32.75a6.056 6.056 0 0 0-5.25-3h-49.27"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="M92.683 153.4h-39.86a5.844 5.844 0 0 1-5.065-2.963 5.836 5.836 0 0 1 .066-5.867l19.56-32.74a6.237 6.237 0 0 1 5.35-3.038 6.235 6.235 0 0 1 5.35 3.038l19.61 32.75a5.829 5.829 0 0 1-5.01 8.82Z"
      fill="#fff"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="M72.165 129.94a.72.72 0 0 1 .59-.3.718.718 0 0 1 .58.29.357.357 0 0 1 0 .08l7.55 12.57h2.94l-10-16.61a1.282 1.282 0 0 0-2.19 0v.06l-10 16.58h2.9l7.57-12.58.06-.09Z"
      fill="#1FB25A"
    />
    <Path
      d="m88.374 141.55-11.25-18.71-1-1.64a4.004 4.004 0 0 0-3.385-1.869 3.998 3.998 0 0 0-3.385 1.869l-.38.64-11.82 19.71a3.611 3.611 0 0 0 0 3.75 4.008 4.008 0 0 0 3.38 1.88h9.61a1.34 1.34 0 0 1 1.34 1.33v2.91h2.51v-2.91a3.842 3.842 0 0 0-3.9-3.75h-9.58a1.403 1.403 0 0 1-1.18-.66 1.291 1.291 0 0 1 0-1.34l12.24-20.37a1.428 1.428 0 0 1 1.878-.476c.207.113.384.277.512.476l12.25 20.37a1.29 1.29 0 0 1 0 1.34 1.42 1.42 0 0 1-1.18.66h-4.3a1.426 1.426 0 0 1-1.21-.68l-5.64-9.4a1.266 1.266 0 0 0-1.06-.6 1.249 1.249 0 0 0-1.09.61v.06l-4.73 7.86h2.82l2.33-3.86a.21.21 0 0 1 0-.07.75.75 0 0 1 .59-.3.729.729 0 0 1 .58.29v.08l4 6.57a3.998 3.998 0 0 0 3.38 1.87h4.27a4 4 0 0 0 3.38-1.87 3.739 3.739 0 0 0 .02-3.77Z"
      fill="#1FB25A"
    />
    <Path
      d="M137.505 202.57c7.251 0 13.13-5.878 13.13-13.13 0-7.251-5.879-13.13-13.13-13.13-7.252 0-13.13 5.879-13.13 13.13 0 7.252 5.878 13.13 13.13 13.13Z"
      fill="#BDE1FF"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="M137.504 182.6v13.14M144.074 189.17h-13.14"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="M224.804 202.57c7.251 0 13.13-5.878 13.13-13.13 0-7.251-5.879-13.13-13.13-13.13-7.252 0-13.13 5.879-13.13 13.13 0 7.252 5.878 13.13 13.13 13.13Z"
      fill="#BDE1FF"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="M224.805 182.6v13.14M231.374 189.17h-13.14"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="M181.573 320.84c28.697 0 51.96-23.263 51.96-51.96s-23.263-51.96-51.96-51.96c-28.696 0-51.96 23.263-51.96 51.96s23.264 51.96 51.96 51.96Z"
      fill="#B0EACC"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="M143.824 233.15c-.59.62-1.16 1.26-1.72 1.91v67.65c.56.65 1.13 1.29 1.72 1.91h75.41a51.912 51.912 0 0 0 0-71.47h-75.41Z"
      fill="#fff"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="M174.065 304.62h-63.85a9.345 9.345 0 0 1-8.108-4.746 9.345 9.345 0 0 1 .108-9.394l31.32-52.46a10.002 10.002 0 0 1 13.511-3.563 9.997 9.997 0 0 1 3.649 3.563l31.41 52.46a9.343 9.343 0 0 1 .107 9.417 9.34 9.34 0 0 1-8.147 4.723Z"
      fill="#fff"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="M141.194 267.08a1.17 1.17 0 0 1 .93-.48c.183-.002.363.04.527.121.163.082.305.202.413.349a.41.41 0 0 1 .07.13l12.11 20.14h4.65l-16-26.62a2.007 2.007 0 0 0-2.766-.755 2 2 0 0 0-.734.755l-.06.1-16 26.56h4.65l12.12-20.16a.37.37 0 0 1 .09-.14Z"
      fill="#1FB25A"
    />
    <Path
      d="m167.154 285.64-18-30-1.59-2.56a6.383 6.383 0 0 0-8.521-2.21 6.39 6.39 0 0 0-2.319 2.21l-.61 1-19 31.59a5.82 5.82 0 0 0 0 6 6.304 6.304 0 0 0 5.42 3h15.4a2.18 2.18 0 0 1 2.14 2.14v4.67h4v-4.67a6.132 6.132 0 0 0-6.25-6h-15.31a2.293 2.293 0 0 1-1.89-1.07 2.058 2.058 0 0 1 0-2.14l19.62-32.64a2.277 2.277 0 0 1 3.005-.765c.335.182.618.445.825.765l19.62 32.64a2.063 2.063 0 0 1 0 2.14 2.294 2.294 0 0 1-1.89 1.07h-6.89a2.269 2.269 0 0 1-1.93-1.07l-9-15.05a1.997 1.997 0 0 0-1.755-1.041 2.001 2.001 0 0 0-1.755 1.041l-.06.1-7.59 12.53h4.65l3.72-6.19a.482.482 0 0 0 .06-.1 1.15 1.15 0 0 1 1.87 0l.08.13 6.33 10.52a6.335 6.335 0 0 0 5.42 3h6.87a6.335 6.335 0 0 0 5.42-3 6.001 6.001 0 0 0-.09-6.04Z"
      fill="#1FB25A"
    />
  </Svg>
);
