import * as React from "react";
import Svg, { Path } from "react-native-svg";

export default function SoilECIcon({ color = "#6F6F6F", size = 24 }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        fill={color}
        fillRule="evenodd"
        d="M15.048 4.55a.75.75 0 0 0-1.2-.9l-3.6 4.798a.75.75 0 0 0 .6 1.2h2.1l-2.7 3.6a.75.75 0 1 0 1.2.9l3.6-4.8a.75.75 0 0 0-.6-1.2h-2.1l2.7-3.599Zm2.523 7.2a.75.75 0 0 0 0 1.5h3a.75.75 0 0 0 0-1.5h-3Zm-15 5.57a.75.75 0 0 0 0 1.5h18a.75.75 0 0 0 0-1.5h-18Zm-.75-4.82a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 0 1.5h-3a.75.75 0 0 1-.75-.75Z"
        clipRule="evenodd"
      />
    </Svg>
  );
}
