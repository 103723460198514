import { ApiResponse } from "apisauce";

import { Api } from "./api";
import { ApiProblem, processResponse } from "./response-resolver";

const SUPPORT_PATH = "/Support/contact";

export type SettingsResult =
  | { kind: "ok"; data: Record<string, string> }
  | ApiProblem;

export type TSupportMessageData = {
  accountUid?: string;
  email: string;
  subject: string;
  message: string;
};

export class SupportApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async sendMessage(data: TSupportMessageData): Promise<SettingsResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(
        SUPPORT_PATH,
        data,
      );
      return processResponse(response);
    } catch (e) {
      return { kind: "error" };
    }
  }
}
