import React, { useMemo, useState } from "react";
import { StyleSheet } from "react-native";
import { useTheme } from "@react-navigation/native";
import { StackScreenProps } from "@react-navigation/stack";

import { useStores } from "@models/index";
import { Screen } from "@components/index";
import { TopBarNew } from "@components/layout";
import {
  CopyToClipboard,
  ErrorsAlert,
  Text,
  useCopyToClipboard,
} from "@components/ui";

import { observer } from "mobx-react-lite";
import { defaultColors, spacing } from "../../../../theme";
import { translate } from "../../../../i18n";
import {
  InfoIcon,
  WiFiIcon,
  CopyIcon,
  ModulesIcon,
  BlinkLightIcon,
} from "../../../../svgs";
import { HistoryIcon } from "../../../../svgs/menu";
import { ModulesNavigatorParamList } from "../../navigation";
import { ModuleDetailsImage } from "../components/DetailsImage";
import { FindModule, FirmwareInfo, InfoItem } from "./components";

const createStyles = (theme: any, opacity?: number) =>
  StyleSheet.create({
    screen: {
      paddingHorizontal: spacing[4],
      paddingTop: spacing[4],
      paddingBottom: spacing[1],
    },
    title: {
      fontSize: 16,
      textAlign: "center",
      marginTop: spacing[1],
      ...theme.fonts.medium,
    },
  });

export const ModuleInfoScreen: React.FC<
  StackScreenProps<ModulesNavigatorParamList, "module-info">
> = observer(({ route, navigation }) => {
  const uid = route.params?.uid;
  const { moduleStore } = useStores();
  const module = moduleStore.getModule(uid);

  const theme = useTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);
  const {
    copyToClipboard,
    copiedItem,
    onDismiss: onDismissCopy,
  } = useCopyToClipboard();

  const copySerialNumber = () => {
    copyToClipboard(module.uid, translate("Modules.info.serialNumber"));
  };

  const [isFindModule, setFindModule] = useState<boolean>(false);
  const showFindModuleDialog = () => {
    setFindModule(true);
  };
  const hideFindModuleDialog = () => {
    setFindModule(false);
  };

  const goToDiagnostic = () => {
    navigation.navigate("module-diagnostic-history", { uid: module.uid });
  };
  const signalLevel = module.onlineInfo.signalLevel
    ? module.onlineInfo.signalLevel - 1
    : 0;
  /* eslint-disable react/no-unstable-nested-components */
  const IconWifi = () => (
    <WiFiIcon color={defaultColors.primary} level={signalLevel} />
  );
  /* eslint-enable react/no-unstable-nested-components */

  const goToActivityHistory = () => {
    navigation.navigate("module-activity-history", { uid: module.uid });
  };

  return (
    <Screen
      style={styles.screen}
      preset="scroll"
      header={<TopBarNew title={translate("Modules.info.moduleInfo")} />}
    >
      <Text style={styles.title}>{module.displayName}</Text>

      <ModuleDetailsImage
        moduleType={module.type}
        disabled={!module.isOnline}
      />

      <FirmwareInfo module={module} />

      <InfoItem
        title={`${translate("Modules.info.type")}: ${module.typeName}`}
        iconRight={ModulesIcon}
      />
      <InfoItem
        title={`${translate("Modules.info.serialNumber")}: ${module.uid}`}
        iconRight={CopyIcon}
        onPress={copySerialNumber}
      />
      {module.ssid && (
        <InfoItem
          iconLeft={IconWifi}
          title={module.ssid}
          iconRight={InfoIcon}
          onPress={goToDiagnostic}
        />
      )}

      <InfoItem
        title={translate("Modules.info.findModule")}
        iconRight={BlinkLightIcon}
        onPress={showFindModuleDialog}
      />

      <InfoItem
        title={translate("Modules.info.activityHistory")}
        iconRight={HistoryIcon}
        onPress={goToActivityHistory}
      />

      <CopyToClipboard copiedItem={copiedItem} onDismiss={onDismissCopy} />

      {isFindModule && (
        <FindModule module={module} onClose={hideFindModuleDialog} />
      )}

      <ErrorsAlert errors={module.statusErrors} onCancel={module.resetErrors} />
    </Screen>
  );
});
