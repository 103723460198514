import { Platform, StyleSheet } from "react-native";

import { DeviceState, DeviceStateType } from "@models/device/types";
import { HintMenu, THintsDataItem } from "@components/hints";
import { Rule } from "@models/rule/rule";

import { translate } from "../../../../i18n";
import { spacing } from "../../../../theme";
import {
  getDeviceDetailsMenuItems,
  getDeviceRuleMenuItems,
} from "../constants";
import {
  ButtonCreatRuleHint,
  ButtonCreatRuleTypes,
  ButtonManualHint,
  DeviceStateHint,
  SwitchHint,
} from "./components";

const top = Platform.OS === "web" ? 55 : -20;

const dialogStyle = StyleSheet.create({
  dialogTop: {
    position: "absolute",
    right: 0,
    left: 0,
    top,
  },
  dialogBottom: {
    position: "absolute",
    right: 0,
    left: 0,
    bottom: spacing[4],
  },
});

const RuleMenuStyle = StyleSheet.create({
  positionRule: {
    top: 208,
    right: 32,
    alignItems: "flex-end",
  },
});

const DeviceDetailsHintMenu = () => HintMenu(getDeviceDetailsMenuItems());
const RuleHintMenu = () =>
  HintMenu(getDeviceRuleMenuItems(), RuleMenuStyle.positionRule);

const DeviceManuallyState = () =>
  DeviceStateHint({
    state: DeviceState.On,
    stateType: DeviceStateType.Manually,
  });

const DeviceContinuouslyState = () =>
  DeviceStateHint({
    state: DeviceState.On,
    stateType: DeviceStateType.Continuously,
  });

const DeviceScheduleState = () =>
  DeviceStateHint({
    state: DeviceState.On,
    stateType: DeviceStateType.BySchedule,
  });

const DeviceSensorState = () =>
  DeviceStateHint({
    state: DeviceState.On,
    stateType: DeviceStateType.BySensor,
  });

const DeviceStateSchedule = (): THintsDataItem => ({
  id: "DeviceStateSchedule",
  title: translate("Hints.title"),
  text: translate("Hints.DeviceDetails.textHintStateSchedule"),
  HintComponent: DeviceScheduleState,
  dialogStyle: dialogStyle.dialogBottom,
});

const DeviceStateSensor = (): THintsDataItem => ({
  id: "DeviceStateSensor",
  title: translate("Hints.title"),
  text: translate("Hints.DeviceDetails.textHintStateSensor"),
  HintComponent: DeviceSensorState,
  dialogStyle: dialogStyle.dialogBottom,
});

const ButtonCreatRoutineRuleHint = () =>
  ButtonCreatRuleHint(ButtonCreatRuleTypes.RoutineRule);

const ButtonCreatGrowthStagesRuleHint = () =>
  ButtonCreatRuleHint(ButtonCreatRuleTypes.GrowthStages);

const ButtonCreatCopyRuleHint = () =>
  ButtonCreatRuleHint(ButtonCreatRuleTypes.CopyRule);

const ButtonsCreateRule: THintsDataItem[] = [
  {
    id: "ButtonCreatRoutineRuleHint",
    title: translate("Hints.title"),
    text: translate("Hints.DeviceDetails.textHintButtonCreatRoutineRuleHint"),
    HintComponent: ButtonCreatRoutineRuleHint,
    dialogStyle: dialogStyle.dialogTop,
  },
  {
    id: "ButtonCreatGrowthStagesRuleHint",
    title: translate("Hints.title"),
    text: translate(
      "Hints.DeviceDetails.textHintButtonCreatGrowthStagesRuleHint",
    ),
    HintComponent: ButtonCreatGrowthStagesRuleHint,
    dialogStyle: dialogStyle.dialogTop,
  },
  {
    id: "ButtonCreatCopyRuleHint",
    title: translate("Hints.title"),
    text: translate("Hints.DeviceDetails.textHintButtonCreatCopyRuleHint"),
    HintComponent: ButtonCreatCopyRuleHint,
    dialogStyle: dialogStyle.dialogTop,
  },
];

const gotIt = (): THintsDataItem => ({
  id: "gotIt",
  title: translate("Hints.helpButtonTitle"),
  text: translate("Hints.helpButtonContent"),
});

const DeviceDetailsHintsData = (): THintsDataItem[] => [
  {
    id: "DeviceMenuHint",
    title: translate("Hints.title"),
    text: translate("Hints.DeviceDetails.textHintDeviceMenu"),
    HintComponent: DeviceDetailsHintMenu,
    dialogStyle: dialogStyle.dialogBottom,
  },
  {
    id: "DeviceState",
    title: translate("Hints.title"),
    text: translate("Hints.DeviceDetails.textHintStateIdle"),
    HintComponent: DeviceStateHint,
    dialogStyle: dialogStyle.dialogBottom,
  },
  {
    id: "ButtonManual",
    title: translate("Hints.title"),
    text: translate("Hints.DeviceDetails.textHintButtonManual"),
    HintComponent: ButtonManualHint,
    dialogStyle: dialogStyle.dialogBottom,
  },
  {
    id: "DeviceStateManually",
    title: translate("Hints.title"),
    text: translate("Hints.DeviceDetails.textHintStateManually"),
    HintComponent: DeviceManuallyState,
    dialogStyle: dialogStyle.dialogBottom,
  },
  {
    id: "DeviceStateContinuously",
    title: translate("Hints.title"),
    text: translate("Hints.DeviceDetails.textHintStateContinuously"),
    HintComponent: DeviceContinuouslyState,
    dialogStyle: dialogStyle.dialogBottom,
  },
];

const DeviceDetailsNotRule = (): THintsDataItem[] => [
  ...DeviceDetailsHintsData(),
  ...ButtonsCreateRule,
  gotIt(),
];

const DeviceDetailsRule = (): THintsDataItem[] => [
  ...DeviceDetailsHintsData(),
  {
    id: "Switch",
    title: translate("Hints.title"),
    text: translate("Hints.DeviceDetails.textHintSwitch"),
    HintComponent: SwitchHint,
    dialogStyle: dialogStyle.dialogBottom,
  },
  DeviceStateSchedule(),
  DeviceStateSensor(),
  {
    id: "RuleMenuHint",
    title: translate("Hints.title"),
    text: translate("Hints.DeviceDetails.textHintRuleMenu"),
    HintComponent: RuleHintMenu,
    dialogStyle: dialogStyle.dialogBottom,
  },
  gotIt(),
];

export const getDeviceDetailsHints = (rule: Rule) => {
  return rule ? DeviceDetailsRule() : DeviceDetailsNotRule();
};
