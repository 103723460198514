import * as React from "react";
import Svg, { SvgProps, G, Path, Defs, ClipPath } from "react-native-svg";

export const CalibrationECErrorSvg = (props: SvgProps) => (
  <Svg width={287} height={173} fill="none" {...props}>
    <G clipPath="url(#a)">
      <Path
        fill="#FBE3E3"
        d="M215.945 170.586H64.325c-17.23 0-27.9-18.82-19.06-33.651l74.39-124.845c9.2-15.443 31.51-15.453 40.73-.03l74.6 124.844c8.87 14.832-1.79 33.682-19.04 33.682Z"
      />
      <Path
        fill="#B6C5D3"
        d="M136.276 167.134c18.122 0 32.813-4.459 32.813-9.961 0-5.501-14.691-9.961-32.813-9.961-18.123 0-32.814 4.46-32.814 9.961 0 5.502 14.691 9.961 32.814 9.961Z"
      />
      <Path
        stroke="#2A2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="M140.721 29.473v-1.73c0-12.284-10.012-22.243-22.36-22.243-12.354 0-22.365 9.96-22.365 22.243v40.514c0 12.284-10.012 22.243-22.36 22.243h-2.094c-12.348 0-22.36-9.96-22.36-22.243"
      />
      <Path
        fill="#6A7B8C"
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="m144.789 42.879-2.768-21.058h-2.6l-2.768 21.058 4.068-.284 4.068.284ZM148.309 42.879h-15.108v15.108h15.108V42.88ZM148.309 121.893h-15.108v3.039h15.108v-3.039Z"
      />
      <Path
        stroke="#2A2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="M145.303 150.593h-9.104a2.995 2.995 0 0 1-2.998-2.998v-14.283h15.108v14.283a3.006 3.006 0 0 1-3.006 2.998Z"
      />
      <Path
        fill="#6A7B8C"
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="M145.067 133.312h-8.624v13.721h8.624v-13.721ZM150.069 57.98h-18.635v63.906h18.635V57.981Z"
      />
      <Path
        stroke="#2A2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="M150.069 124.925h-18.635v8.387h18.635v-8.387ZM133.303 47.764h15.217M133.303 52.739h15.217"
      />
      <Path
        fill="#70B8FF"
        fillOpacity={0.4}
        d="M140.377 116.742c15.857 0 28.712-3.935 28.712-8.789 0-4.855-12.855-8.79-28.712-8.79s-28.711 3.935-28.711 8.79c0 4.854 12.854 8.789 28.711 8.789Z"
      />
      <Path
        fill="#308AE3"
        fillRule="evenodd"
        d="M169.089 107.953c0 4.854-12.855 8.789-28.712 8.789-15.85 0-28.701-3.932-28.711-8.784V154.62c0 4.964 12.86 8.998 28.711 8.998 15.852 0 28.712-4.034 28.712-8.998v-48.425l-.566.012c.371.564.566 1.148.566 1.746Zm-57.423-.006c0-.186.019-.371.057-.555l-.057.001v.554Z"
        clipRule="evenodd"
        opacity={0.6}
      />
      <Path
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.196}
        d="M169.089 82.757v71.869c0 4.961-12.888 8.993-28.775 8.993-15.886 0-28.774-4.032-28.774-8.993V95.781c0-2.13-.865-4.16-2.394-5.633l-4.512-4.803"
      />
      <Path
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.196}
        d="M150.115 75.069c11.071 1.218 18.985 4.458 18.974 8.267 0 4.852-12.837 8.796-28.692 8.796-9.473 0-17.883-1.412-23.101-3.58a25.09 25.09 0 0 0-5.879-1.636l-5.765-.532c-1.106-.098-1.408-1.538-.416-2.041 2.99-1.51 7.201-3.622 9.156-4.49 4.104-1.83 9.419-3.768 16.634-4.713"
      />
      <Path
        stroke="#fff"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.196}
        d="M137.61 153.353a61.937 61.937 0 0 1-13.403-1.85v-23.284a61.937 61.937 0 0 0 13.403 1.85M156.566 150.914a60.422 60.422 0 0 1-5.865 1.398c-4.113.767-7.479-2.402-7.479-6.515v-8.365c0-4.113 3.366-7.597 7.479-8.384a60.422 60.422 0 0 0 5.865-1.398"
      />
      <Path
        stroke="#fff"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.196}
        d="M134.069 141.504a60.462 60.462 0 0 1-9.88-1.653"
      />
      <Path
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.196}
        d="M162.289 97.637v36.662M162.289 139.73v12.221"
      />
      <Path
        fill="#F76B6B"
        d="m232.176 45.5 7.513-7.687c3.201-3.276 3.201-8.59 0-11.856a8.058 8.058 0 0 0-11.586 0l-7.513 7.687-7.513-7.687a8.058 8.058 0 0 0-11.586 0c-3.201 3.276-3.201 8.59 0 11.856l7.513 7.687-7.513 7.687c-3.201 3.276-3.201 8.59 0 11.856a8.058 8.058 0 0 0 11.586 0l7.513-7.687 7.513 7.687a8.058 8.058 0 0 0 11.586 0c3.201-3.276 3.201-8.59 0-11.856l-7.513-7.687Z"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M.09.5h286v172H.09z" />
      </ClipPath>
    </Defs>
  </Svg>
);
