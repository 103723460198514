import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";

import { Badge, Text } from "@components/ui";

import { spacing } from "../../../../../theme";
import { useAppTheme } from "../../../../../hooks";
import { translate } from "../../../../../i18n";

const createStyles = (theme: any) =>
  StyleSheet.create({
    badges: {
      position: "absolute",
      top: -spacing[1],
      left: spacing[4],
      zIndex: 10,
      flexDirection: "row",
      columnGap: spacing[4],
    },
    badge: {
      // marginLeft: spacing[4],
    },
    badgeText: {
      color: theme.colors.badgeText,
      fontSize: 12,
      lineHeight: 16,
    },
    offline: {
      backgroundColor: theme.colors.error,
    },
  });

const BadgeOffline = () => {
  const theme = useAppTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <Badge style={[styles.badge, styles.offline]}>
      <Text style={styles.badgeText}>
        {translate("Devices.status.offline").toUpperCase()}
      </Text>
    </Badge>
  );
};

const BadgeDisconnect = () => {
  const theme = useAppTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <Badge style={[styles.badge, styles.offline]}>
      <Text style={styles.badgeText}>
        {translate("Sensors.State.disconnect").toUpperCase()}
      </Text>
    </Badge>
  );
};

const BadgeCalibration = () => {
  const theme = useAppTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <Badge
      style={[styles.badge]}
      gradientColors={[theme.colors.badgePurpleG1, theme.colors.badgePurpleG2]}
    >
      <Text style={styles.badgeText}>
        {translate("Sensors.State.need_calibration")?.toUpperCase()}
      </Text>
    </Badge>
  );
};

const BadgeRuleOn = () => {
  const theme = useAppTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <Badge
      style={styles.badge}
      gradientColors={[theme.colors.badgeGStart, theme.colors.badgeGEnd]}
    >
      <Text style={styles.badgeText}>{translate("Devices.Rules.ruleOn")}</Text>
    </Badge>
  );
};

export const ModuleCardBadges = ({ children }) => {
  const theme = useAppTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);

  return <View style={styles.badges}>{children}</View>;
};

ModuleCardBadges.BadgeRuleOn = BadgeRuleOn;
ModuleCardBadges.BadgeCalibration = BadgeCalibration;
ModuleCardBadges.BadgeOffline = BadgeOffline;
ModuleCardBadges.BadgeDisconnect = BadgeDisconnect;
