import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { palette } from "../../theme";

export default function HeaterIcon({ color = palette.Gray, size = 24 }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 36 36" fill="none">
      <Path
        d="M18 18C21 13.56 18 7.5 16.5 6C16.5 10.557 13.8405 13.1115 12 15C10.161 16.89 9 19.86 9 22.5C9 24.8869 9.94821 27.1761 11.636 28.864C13.3239 30.5518 15.6131 31.5 18 31.5C20.3869 31.5 22.6761 30.5518 24.364 28.864C26.0518 27.1761 27 24.8869 27 22.5C27 20.202 25.416 16.59 24 15C21.321 19.5 19.8135 19.5 18 18Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
