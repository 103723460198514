import React, { useMemo } from "react";
import { StyleSheet } from "react-native";
import { useTheme } from "@react-navigation/native";

import { Text } from "@components/ui";
import { CustomControl } from "@components/inputs";
import { spacing } from "../../../../../theme";

const createStyles = (theme: any) =>
  StyleSheet.create({
    control: {
      minHeight: 56,
      padding: spacing[4],
      marginBottom: spacing[3],
    },
    itemTitle: {
      fontSize: 14,
      color: theme.colors.subLabel,
    },
  });

type InfoItemProps = {
  title: string;
  iconLeft?: React.FunctionComponent<any>;
  iconRight?: React.FunctionComponent<any>;
  onPress?: () => void;
};

export const InfoItem = ({
  title,
  iconLeft: IconLeft,
  iconRight: IconRight,
  onPress,
}: InfoItemProps) => {
  const theme = useTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <CustomControl
      style={styles.control}
      onPress={onPress}
      icon={IconLeft && <IconLeft />}
      control={IconRight && <IconRight color={theme.colors.link} />}
    >
      <Text style={styles.itemTitle}>{title}</Text>
    </CustomControl>
  );
};
