import React, { useMemo, useState } from "react";
import { View, StyleSheet, Platform } from "react-native";
import { TextInput } from "react-native-paper";

import { Label } from "@components/inputs/Label";
import { Text } from "../ui/Text";
import { spacing } from "../../theme";
import { translate, TxKeyPath } from "../../i18n";
import { useAppTheme } from "../../hooks";

const createStyles = (theme: any) =>
  StyleSheet.create({
    container: {
      width: "100%",
      marginVertical: spacing[2],
    },
    input: {
      backgroundColor: theme.colors.inputBG,
      height: 48,
      lineHeight: Platform.OS === "ios" ? 24 : 32,
      fontSize: 18,
      paddingVertical: 0,
    },
    eyeStyle: {
      marginTop: 0,
      marginBottom: 0,
    },
    error: {
      fontSize: 14,
      color: theme.colors.error,
      paddingHorizontal: spacing[1],
      paddingTop: spacing[1],
    },
    label: {
      color: theme.colors.label,
    },
    description: {
      fontSize: 12,
      marginTop: spacing[1],
      paddingHorizontal: spacing[1],
      color: theme.colors.label,
    },
  });

type Props = React.ComponentProps<typeof TextInput> & {
  errorText?: string;
  description?: string;
  txDescription?: TxKeyPath;
  txLabel?: TxKeyPath;
};

export const PasswordInput = ({
  errorText,
  description,
  txDescription,
  txLabel,
  ...props
}: Props) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  const i18nLabel = txLabel ? translate(txLabel) : translate("fields.password");
  const i18nDescription = txDescription && translate(txDescription);
  const descriptionContent = errorText || i18nDescription || description;
  const descriptionStyle = errorText ? styles.error : styles.description;
  const [isFocus, setIsFocus] = useState(false);

  const [passwordVisible, setPasswordVisible] = useState(true);

  return (
    <View style={styles.container}>
      <Label isFocus={isFocus}>{i18nLabel}</Label>
      <TextInput
        style={styles.input}
        selectionColor={theme.colors.primary}
        underlineColor="transparent"
        mode="outlined"
        error={!!errorText}
        outlineColor={
          isFocus ? theme.colors.inputBorderFocus : theme.colors.inputBorder
        }
        secureTextEntry={passwordVisible}
        returnKeyType="done"
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        right={
          <TextInput.Icon
            style={styles.eyeStyle}
            icon={passwordVisible ? "eye" : "eye-off"}
            onPress={() => setPasswordVisible(!passwordVisible)}
          />
        }
        {...props}
      />
      {descriptionContent ? (
        <Text style={descriptionStyle}>{descriptionContent}</Text>
      ) : null}
    </View>
  );
};
