import React from "react";
import { View } from "react-native";
import { observer } from "mobx-react-lite";

import { ConfirmDialog } from "@components/dialog";
import { Text } from "@components/ui";
import { DeviceCard } from "@screens/Devices/components";
import { DeviceMode } from "@models/device/types";

import { translate } from "../../../../i18n";
import { useSensorRuledDevices } from "../../../../hooks";
import { spacing } from "../../../../theme";

type Props = {
  sensorUid: string;
};

export const ActiveSensorRulesWarning = observer(({ sensorUid }: Props) => {
  const { devices: allDevices, resetDevices } = useSensorRuledDevices({
    sensorUid,
  });

  const devices = allDevices?.filter(
    (device) => device.mode === DeviceMode.Auto,
  );

  if (!devices?.length) return null;

  return (
    <ConfirmDialog
      title={translate("common.warning")}
      onOk={resetDevices}
      okText={translate("common.ok")}
      visible
      backHandler={resetDevices}
    >
      <Text>{translate("Sensors.Calibration.ruledDeviceMessage")}</Text>
      <View style={{ marginTop: spacing[4], marginHorizontal: -spacing[2] }}>
        {devices?.map((device) => (
          <DeviceCard key={device.uid} device={device} />
        ))}
      </View>
    </ConfirmDialog>
  );
});
