import { useMemo } from "react";
import { View, StyleSheet } from "react-native";
import { observer } from "mobx-react-lite";

import { useStores } from "@models/index";
import { SwitchInput } from "@components/inputs";
import { useTheme } from "@react-navigation/native";
import { Text } from "@components/ui";

import { spacing } from "../../../../theme";
import { HintSettingSvg } from "../../../../svgs";

const createStyles = (theme: any) =>
  StyleSheet.create({
    container: {
      backgroundColor: theme.colors.background,
      borderWidth: 1,
      borderColor: theme.colors.cardBG,
      borderRadius: spacing[4],
      padding: spacing[4],
    },
    switchContainer: {
      marginTop: spacing[0],
    },
    switchLabel: {
      ...theme.fonts.medium,
      color: theme.colors.text,
      fontSize: 16,
    },
    svg: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.colors.inputBG,
      borderRadius: spacing[2],
      paddingBottom: spacing[5],
      paddingTop: spacing[6],
    },
    label: {
      zIndex: 10,
      position: "absolute",
      backgroundColor: theme.colors.background,
      borderRadius: spacing[4],
      paddingHorizontal: spacing[3],
      paddingVertical: spacing[1],
    },
    text: {
      textAlign: "center",
      fontSize: 16,
      lineHeight: 20,
      color: theme.colors.label,
    },
  });

export const HintSetting = observer(() => {
  const theme = useTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);

  const { settingsStore } = useStores();

  return (
    <View style={styles.container}>
      <SwitchInput
        txLabel="Settings.ShowHelpButton"
        labelStyle={styles.switchLabel}
        containerStyle={styles.switchContainer}
        onChange={settingsStore.setShowHelpButton}
        value={settingsStore.showHelpButton}
      />
      <View style={styles.svg}>
        <HintSettingSvg />
        <View style={styles.label}>
          <Text tx="Settings.pressToSee" style={styles.text} />
        </View>
      </View>
    </View>
  );
});
