// https://www.patreon.com/posts/97797279?utm_campaign=postshare_creator
import React, { useState } from "react";
import {
  Linking,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";

import { Text } from "@components/ui";

import { HintIcon } from "../../svgs";
import CloseIcon from "../../svgs/CloseIcon";
import { useAIAgronomistLink, useAppTheme } from "../../hooks";
import { spacing } from "../../theme";
import { translate } from "../../i18n";

const createStyles = (theme: any) =>
  StyleSheet.create({
    button: {
      width: 40,
      height: 40,
      // shadowColor: "#000",
      // shadowOffset: { width: 0, height: 2 },
      // shadowOpacity: 0.5,
      // shadowRadius: 2,
      // elevation: 2,
      backgroundColor: theme.colors.text,
      borderRadius: 100,
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      overflow: "hidden",
      zIndex: 20,
      bottom: Platform.OS === "web" ? 26 : 31,
      left: 26,
    },
    buttonText: {
      fontWeight: "bold",
      color: theme.colors.background,
      paddingHorizontal: spacing[4],
      paddingVertical: spacing[2],
      ...theme.fonts.bold,
    },

    helpButton: {
      height: 50,
      width: 50,
      borderRadius: 100,
      alignItems: "center",
      justifyContent: "center",
      zIndex: 99,
      position: "absolute",
      bottom: 21,
      left: 21,
    },
    crossBtn: {
      width: 40,
      height: 40,
      margin: 10,
      marginTop: Platform.OS === "web" ? 10 : 0,
      backgroundColor: theme.colors.text,
      borderRadius: 100,
      alignItems: "center",
      justifyContent: "center",
    },

    overlay: {
      zIndex: 9,
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: theme.colors.loadingBG,
    },
  });

type AnimatedHelpButtonProps = {
  onHowToWorkClick: () => void;
};

export const AnimatedHelpButton = ({
  onHowToWorkClick,
}: AnimatedHelpButtonProps) => {
  const theme = useAppTheme();
  const styles = createStyles(theme);
  const aiAgronomistLink = useAIAgronomistLink();

  const [hideButton, setHideButton] = useState(false);

  const hintsTranslateX = useSharedValue(0);
  const hintsTranslateY = useSharedValue(0);

  // const appletranslateX = useSharedValue(0);
  // const appletranslateY = useSharedValue(0);

  const agronomistTranslateX = useSharedValue(0);
  const agronomistTranslateY = useSharedValue(0);

  const btnWidth = useSharedValue(40);

  const hintsStyles = useAnimatedStyle(() => {
    return {
      transform: [
        { translateX: hintsTranslateX.value },
        { translateY: hintsTranslateY.value },
      ],
      width: btnWidth.value,
    };
  });

  // const appleStyles = useAnimatedStyle(() => {
  //   return {
  //     transform: [
  //       { translateX: appletranslateX.value },
  //       { translateY: appletranslateY.value },
  //     ],
  //     width: btnWidth.value,
  //   };
  // });

  const agronomistStyles = useAnimatedStyle(() => {
    return {
      transform: [
        { translateX: agronomistTranslateX.value },
        { translateY: agronomistTranslateY.value },
      ],
      width: btnWidth.value,
    };
  });

  const handlePress = () => {
    setHideButton(!hideButton);

    hintsTranslateX.value = withTiming(hintsTranslateX.value === 0 ? 30 : 0);
    hintsTranslateY.value = withTiming(hintsTranslateY.value === 0 ? -80 : 0);

    // appletranslateX.value = withTiming(appletranslateX.value === 0 ? 60 : 0);
    // appletranslateY.value = withTiming(appletranslateY.value === 0 ? -50 : 0);

    agronomistTranslateX.value = withTiming(
      agronomistTranslateX.value === 0 ? 80 : 0,
    );
    agronomistTranslateY.value = withTiming(
      agronomistTranslateY.value === 0 ? -16 : 0,
    );

    btnWidth.value = withTiming(btnWidth.value === 40 ? 148 : 40);
  };

  const howToWorkHandleClick = () => {
    handlePress();
    onHowToWorkClick();
  };

  // const aISupportHandleClick = () => {
  //   console.log("AI Support");
  // };

  const aIAgronomistHandleClick = () => {
    handlePress();
    Linking.openURL(aiAgronomistLink);
  };

  return (
    <>
      {!hideButton ? (
        <TouchableOpacity
          style={styles.helpButton}
          onPress={() => {
            handlePress();
          }}
        >
          <HintIcon />
        </TouchableOpacity>
      ) : (
        <TouchableOpacity
          style={styles.helpButton}
          onPress={() => {
            handlePress();
          }}
        >
          <View style={styles.crossBtn}>
            <CloseIcon color="white" size={32} />
          </View>
        </TouchableOpacity>
      )}

      {/* <Animated.View style={[styles.button, twitterStyles]}> */}
      {/*  <View */}
      {/*    style={{width: 20, height: 20, resizeMode: 'contain'}} */}
      {/*  /> */}
      {/* </Animated.View> */}
      {/* <Animated.View style={[styles.button, googleStyles]}> */}
      {/*  <View */}
      {/*    style={{width: 20, height: 20, resizeMode: 'contain'}} */}
      {/*    // source={require('./assets/google.png')} */}
      {/*  /> */}
      {/* </Animated.View> */}

      <Animated.View style={[styles.button, hintsStyles]}>
        <TouchableOpacity onPress={howToWorkHandleClick}>
          <Text style={styles.buttonText} numberOfLines={1}>
            {translate("Hints.howItWorks")}
          </Text>
        </TouchableOpacity>
      </Animated.View>

      {/* <Animated.View style={[styles.button, appleStyles]}> */}
      {/*  <TouchableOpacity onPress={aISupportHandleClick}> */}
      {/*    <Text style={styles.buttonText} numberOfLines={1}> */}
      {/*      AI Support */}
      {/*    </Text> */}
      {/*  </TouchableOpacity> */}
      {/* </Animated.View> */}

      <Animated.View style={[styles.button, agronomistStyles]}>
        <TouchableOpacity onPress={aIAgronomistHandleClick}>
          <Text style={styles.buttonText} numberOfLines={1}>
            {translate("Hints.aiAgronomist")}
          </Text>
        </TouchableOpacity>
      </Animated.View>

      {hideButton && (
        <TouchableOpacity style={styles.overlay} onPress={handlePress} />
      )}
    </>
  );
};
