import * as React from "react";
import Svg, { Path } from "react-native-svg";

import { defaultColors } from "../../theme";

export default function ModuleOffIcon({
  size = 24,
  color = defaultColors.text,
}) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M10.7506 2.77963C11.132 2.56474 11.5624 2.45184 12.0002 2.45184C12.438 2.45184 12.8684 2.56474 13.2499 2.77963C13.6313 2.99452 13.9509 3.30414 14.1778 3.67859L14.1783 3.67947L17.4729 9.1342C17.687 9.48876 17.5732 9.94979 17.2186 10.1639C16.8641 10.3781 16.403 10.2643 16.1889 9.9097L12.8949 4.45585C12.8017 4.30218 12.6702 4.17473 12.5136 4.08651C12.3569 3.99823 12.1801 3.95184 12.0002 3.95184C11.8204 3.95184 11.6435 3.99823 11.4868 4.08651C11.3315 4.17398 11.2002 4.30121 11.1064 4.4554L11.1056 4.45679L3.5047 17.1475C3.41504 17.3045 3.36762 17.4822 3.3671 17.6631C3.36658 17.8457 3.41388 18.0253 3.50429 18.1839C3.59471 18.3426 3.72509 18.4748 3.88248 18.5675C4.0388 18.6595 4.21636 18.7093 4.3977 18.712H10.4641C10.8783 18.712 11.2141 19.0477 11.2141 19.462C11.2141 19.8762 10.8783 20.212 10.4641 20.212H4.38451C3.94003 20.207 3.5046 20.0856 3.12153 19.8601C2.73847 19.6346 2.42111 19.3128 2.20104 18.9266C1.98096 18.5404 1.86583 18.1033 1.86711 17.6588C1.86838 17.2143 1.98601 16.7778 2.20829 16.3929L2.21428 16.3825L9.82267 3.67952C10.0489 3.30605 10.3681 2.99508 10.7506 2.77963Z"
        fill={color}
      />
      <Path
        d="M15.6813 20.198C15.6813 19.7838 16.0171 19.448 16.4313 19.448H16.4401C16.8543 19.448 17.1901 19.7838 17.1901 20.198C17.1901 20.6122 16.8543 20.948 16.4401 20.948H16.4313C16.0171 20.948 15.6813 20.6122 15.6813 20.198Z"
        fill={color}
      />
      <Path
        d="M14.2284 18.5442C14.8126 17.9601 15.605 17.632 16.4311 17.632C17.2572 17.632 18.0496 17.9601 18.6338 18.5442C18.9268 18.837 19.4016 18.8369 19.6945 18.544C19.8524 18.3861 19.9251 18.1753 19.9127 17.9686C19.9064 17.7878 19.8351 17.6087 19.6983 17.4695L16.0847 13.7899C16.1999 13.7842 16.3154 13.7813 16.4312 13.7813C18.2827 13.7813 20.0583 14.5167 21.3677 15.8256C21.6607 16.1185 22.1355 16.1184 22.4284 15.8255C22.7212 15.5325 22.7212 15.0577 22.4282 14.7648C20.8376 13.1746 18.6804 12.2813 16.4312 12.2813C15.8677 12.2813 15.3099 12.3374 14.7654 12.4465L13.6534 11.3142C13.3632 11.0186 12.8883 11.0143 12.5928 11.3046C12.2972 11.5948 12.2929 12.0696 12.5832 12.3652L13.1495 12.9419C12.1443 13.3636 11.2201 13.9792 10.4342 14.7648C10.1413 15.0577 10.1412 15.5325 10.4341 15.8255C10.7269 16.1184 11.2018 16.1185 11.4947 15.8256C12.2879 15.0327 13.2522 14.4502 14.3009 14.1142L16.2847 16.1343C15.1139 16.1714 13.9988 16.6526 13.1679 17.4833C12.8749 17.7762 12.8749 18.2511 13.1677 18.544C13.4606 18.8369 13.9354 18.837 14.2284 18.5442Z"
        fill={color}
      />
    </Svg>
  );
}
