import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { palette } from "../../theme";

export default function EditIcon({ color = palette.Black, size = 24 }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 3.92188C15.5509 3.92188 14.6408 4.29889 13.9697 4.96997L12.9701 5.96955C12.97 5.96969 12.9698 5.96983 12.9697 5.96997C12.9695 5.97011 12.9694 5.97026 12.9692 5.9704L3.46967 15.47C3.32902 15.6106 3.25 15.8014 3.25 16.0003V20.0003C3.25 20.4145 3.58579 20.7503 4 20.7503H8C8.19891 20.7503 8.38968 20.6713 8.53033 20.5306L18.0303 11.0306L19.0303 10.0306C19.7014 9.35955 20.0784 8.44936 20.0784 7.5003C20.0784 6.55125 19.7014 5.64106 19.0303 4.96997C18.3592 4.29889 17.4491 3.92188 16.5 3.92188ZM17.5 9.43964L17.9697 8.96997C18.3595 8.58019 18.5784 8.05154 18.5784 7.5003C18.5784 6.94907 18.3595 6.42041 17.9697 6.03063C17.5799 5.64085 17.0512 5.42188 16.5 5.42188C15.9488 5.42188 15.4201 5.64085 15.0303 6.03063L14.5607 6.5003L17.5 9.43964ZM13.5 7.56096L16.4393 10.5003L7.68934 19.2503H4.75V16.311L13.5 7.56096Z"
        fill={color}
      />
    </Svg>
  );
}
