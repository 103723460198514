import * as React from "react";
import Svg, {
  G,
  Rect,
  Path,
  Defs,
  LinearGradient,
  Stop,
  ClipPath,
} from "react-native-svg";

export const Offline = (props) => (
  <Svg width={45} height={44} viewBox="0 0 45 44" fill="none" {...props}>
    <G clipPath="url(#clip0_379_20050)">
      <Rect
        x={0.5}
        width={44}
        height={44}
        rx={16}
        fill="url(#paint0_linear_379_20050)"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3496 5.70007C16.9019 5.70007 17.3496 6.14779 17.3496 6.70007V12.9H26.1484V6.70007C26.1484 6.14779 26.5961 5.70007 27.1484 5.70007C27.7007 5.70007 28.1484 6.14779 28.1484 6.70007V12.9H30.2359L34.0429 9.09306C34.4334 8.70254 35.0666 8.70254 35.4571 9.09306C35.8476 9.48359 35.8476 10.1168 35.4571 10.5073L10.9571 35.0073C10.5666 35.3978 9.93339 35.3978 9.54287 35.0073C9.15235 34.6168 9.15235 33.9836 9.54287 33.5931L13.7819 29.354C13.7263 29.3173 13.6735 29.2745 13.6245 29.2255C12.4243 28.0253 11.75 26.3974 11.75 24.7V13.9C11.75 13.3477 12.1977 12.9 12.75 12.9H15.3496V6.70007C15.3496 6.14779 15.7973 5.70007 16.3496 5.70007ZM15.1672 27.9687L28.2359 14.9H27.1584L27.1484 14.9001L27.1384 14.9H16.3596L16.3496 14.9001L16.3396 14.9H13.75V24.7C13.75 25.867 14.2135 26.9861 15.0387 27.8113C15.0877 27.8603 15.1305 27.9131 15.1672 27.9687ZM30.75 18.3C31.3023 18.3 31.75 18.7477 31.75 19.3V24.7C31.75 26.3974 31.0757 28.0253 29.8755 29.2255C28.6752 30.4257 27.0474 31.1 25.35 31.1H22.749V33.7C22.749 34.3895 23.0229 35.0509 23.5105 35.5385C23.9981 36.026 24.6594 36.3 25.349 36.3H30.749C31.3013 36.3 31.749 36.7477 31.749 37.3C31.749 37.8523 31.3013 38.3 30.749 38.3H25.349C24.129 38.3 22.959 37.8153 22.0963 36.9527C21.2336 36.09 20.749 34.92 20.749 33.7V31.1H19.75C19.1977 31.1 18.75 30.6523 18.75 30.1C18.75 29.5477 19.1977 29.1 19.75 29.1H21.7389L21.749 29.1L21.7591 29.1H25.35C26.5169 29.1 27.6361 28.6365 28.4612 27.8113C29.2864 26.9861 29.75 25.867 29.75 24.7V19.3C29.75 18.7477 30.1977 18.3 30.75 18.3Z"
        fill="white"
      />
    </G>
    <Defs>
      <LinearGradient
        id="paint0_linear_379_20050"
        x1={0.5}
        y1={0}
        x2={44.5}
        y2={44}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#F54444" />
        <Stop offset={1} stopColor="#FB6E6E" />
      </LinearGradient>
      <ClipPath id="clip0_379_20050">
        <Rect x={0.5} width={44} height={44} rx={16} fill="white" />
      </ClipPath>
    </Defs>
  </Svg>
);
