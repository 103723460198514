import { StyleSheet, View, Text, Image } from "react-native";
import { shadow } from "react-native-paper";

import { MODULE_IMAGES } from "@assets/modules";

import { DefaultTheme, spacing } from "../../../../../theme";

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    overflow: "hidden",
    borderRadius: spacing[4],
    alignItems: "center",
    backgroundColor: DefaultTheme.colors.cardBG,
    padding: spacing[3],
    top: 76,
    left: 16,
    minWidth: "45%",
    ...(shadow(5) as any),
  },
  cardBottom: {
    width: 134,
    minHeight: 44,
    marginTop: spacing[3],
  },
  picture: {
    width: 134,
    height: 96,
  },
  badge: {
    position: "absolute",
    top: spacing[3],
    right: spacing[3],
  },
  typeName: {
    color: DefaultTheme.colors.label,
    marginBottom: spacing[2],
    fontSize: 12,
  },
  nameText: {
    fontSize: 14,
  },
});

export const ModuleCard = () => (
  <View style={styles.container}>
    <Image style={styles.picture} source={MODULE_IMAGES.drycontact} />

    <View style={styles.cardBottom}>
      <Text style={styles.typeName}>DryContactDirector</Text>
      <Text style={styles.nameText}>External Devices</Text>
    </View>
  </View>
);
