import React, { useMemo } from "react";
import { View, StyleSheet } from "react-native";
import { observer } from "mobx-react-lite";
import { useTheme } from "@react-navigation/native";

import { Text } from "@components/ui";
import { CustomControl } from "@components/inputs";

import { spacing } from "../../../../../theme";
import { ChevronRightIcon } from "../../../../../svgs";

const createStyles = (theme: any) =>
  StyleSheet.create({
    customControl: {
      minHeight: 92,
      marginBottom: spacing[3],
      paddingHorizontal: spacing[4],
      paddingVertical: spacing[3],
    },
    content: {
      marginLeft: spacing[2],
      marginTop: spacing[1],
      flexDirection: "column",
      flex: 1,
    },
    typeName: {
      color: theme.colors.label,
      fontSize: 12,
      marginBottom: 6,
    },
    name: {
      fontSize: 14,
    },
    pin: {
      color: theme.colors.label,
      fontSize: 12,
      marginTop: 6,
    },
  });

type ConnectedCardProps = {
  icon: React.FunctionComponent<any>;
  typeName: string;
  displayName: string;
  socketName?: string;
  onPress: () => void;
  children?: React.ReactNode;
};

export const ModuleConnectedItem = observer(
  ({
    icon: Icon,
    typeName,
    displayName,
    socketName,
    onPress,
    children,
  }: ConnectedCardProps) => {
    const theme = useTheme() as any;
    const styles = useMemo(() => createStyles(theme), [theme]);

    return (
      <View>
        {children}

        <CustomControl
          onPress={onPress}
          icon={<Icon size={36} color={theme.colors.label} />}
          control={<ChevronRightIcon color={theme.colors.label} />}
          style={styles.customControl}
        >
          <View style={styles.content}>
            <Text
              style={styles.typeName}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {typeName}
            </Text>

            <Text style={styles.name} numberOfLines={1} ellipsizeMode="tail">
              {displayName}
            </Text>

            {socketName && <Text style={styles.pin}>{socketName}</Text>}
          </View>
        </CustomControl>
      </View>
    );
  },
);
