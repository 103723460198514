import { flow, Instance, types } from "mobx-state-tree";
import dayjs from "dayjs";

import { withEnvironment } from "@models/extensions/with-environment";
import { withStatus } from "@models/extensions/with-status";
import { withRootStore } from "@models/extensions/with-root-store";
import { IsFetchTimeProps } from "@models/extensions/with-fetch-timeout";
import {
  TEMPERATURE_UNITS,
  TemperatureUnit,
  Units,
} from "@models/settings/user-settings-store";
import { SensorLongTimeCalibrationModel } from "@models/sensor/sensor-longtime-calibration";
import {
  DEFAULT_SENSOR_RANGES,
  SENSOR_ICONS,
  SENSOR_VALUE_DEFAULT_PRECISION,
  SensorTypeName,
} from "@models/sensor/constants";

import { SensorsApi } from "../../services/api";
import { translate } from "../../i18n";
import { displayNumber } from "../../utils/display";
import range from "../../utils/range";
import {
  celsiusToFahrenheit,
  fahrenheitToCelsius,
} from "../../utils/converters/temperatureConverter";
import { duplicateNameValidator } from "../../utils/validator";

export enum SensorState {
  Online = "online",
  Offline = "offline",
  NeedRecalibration = "need_recalibration",
  NeedCalibration = "need_calibration",
  Error = "error",
  Disconnect = "disconnect",
  NoData = "no_data",
  Unknown = "unknown",
  InvalidValue = "invalid_value",
  Calibration = "calibration",
}

export const SENSOR_STATE_TEXT = {
  [SensorState.Offline]: translate(`Sensors.State.${SensorState.Offline}`),
  [SensorState.Error]: translate(`Sensors.State.${SensorState.Error}`),
  [SensorState.NeedCalibration]: translate(
    `Sensors.State.${SensorState.NeedCalibration}`,
  ),
  [SensorState.NeedRecalibration]: translate(
    `Sensors.State.${SensorState.NeedRecalibration}`,
  ),
  [SensorState.Disconnect]: translate(
    `Sensors.State.${SensorState.Disconnect}`,
  ),
  [SensorState.InvalidValue]: translate(
    `Sensors.State.${SensorState.InvalidValue}`,
  ),
};

const isTemperature = (sensorType: string) =>
  sensorType === "air_temp" || sensorType === "water_temp";

type sensorValueProps = {
  sensorType: string;
  value?: number;
  units: Units;
  precision?: number;
  defaultValue?: string;
};

export const displaySensorValue = ({
  sensorType,
  value,
  units,
  precision = SENSOR_VALUE_DEFAULT_PRECISION,
  defaultValue,
}: sensorValueProps) => {
  if (value === undefined || value === null) return defaultValue;
  let cValue = value;

  if (
    isTemperature(sensorType) &&
    units.temperature === TemperatureUnit.fahrenheit
  ) {
    cValue = celsiusToFahrenheit(value);
  }

  return displayNumber(cValue, precision, defaultValue);
};

export const inputSensorValue = (
  value: number | undefined,
  sensorType: string,
  units: Units,
) => {
  if (value === undefined || value === null) return undefined;

  if (
    isTemperature(sensorType) &&
    units.temperature === TemperatureUnit.fahrenheit
  ) {
    return fahrenheitToCelsius(value);
  }

  return value;
};

const GroupModel = types.model("SensorGroup").props({
  uid: types.string,
  name: types.string,
});

export type TSensorRange = {
  minValue: number;
  maxValue: number;
  step: number;
};

export const SensorViewModel = types
  .model("SensorView")
  .props({
    uid: types.identifier,
    name: types.maybeNull(types.string),
    moduleUid: types.maybeNull(types.string),
    connectedToPin: types.maybeNull(types.number),
    moduleName: types.maybeNull(types.string),
    value: types.maybeNull(types.number),
    type: types.string,
    typeName: types.maybeNull(types.string),
    maxValue: types.maybeNull(types.number),
    minValue: types.maybeNull(types.number),
    lastCalibrationDate: types.maybeNull(types.Date),

    calibration: types.optional(SensorLongTimeCalibrationModel, {}),

    // TODO: add this required fields to API /api/v1/Sensor/{uid}
    inGroup: types.maybeNull(types.boolean), // TODO: remove this field
    group: types.maybeNull(types.map(GroupModel)), // TODO: remove this field
    position: types.maybeNull(types.number),

    extensionUid: types.maybe(types.string),
    extensionPin: types.maybe(types.number),
    extensionName: types.maybe(types.string),
  })
  .extend(withEnvironment)
  .extend(withStatus)
  .extend(withRootStore)
  .views((self) => ({
    get settings() {
      return self.rootStore.settingsStore;
    },

    get sensorType() {
      // TODO Sometimes getSensorType undefined !!!
      return self.rootStore.sensorStore?.getSensorType(self.type);
    },

    get module() {
      return self.rootStore.moduleStore.getModule(self.moduleUid);
    },
  }))
  .views((self) => ({
    get displayType() {
      return self.sensorType?.displayName || self.typeName || "Undefined";
    },

    get sensorRange(): TSensorRange {
      const sensorType = self.sensorType?.toJSON();

      if (!sensorType) {
        return DEFAULT_SENSOR_RANGES;
      }

      const sensorTypeData = {
        ...sensorType,
        minValue: self.minValue ?? sensorType.minValue,
        maxValue: self.maxValue || sensorType.maxValue,
      };

      if (isTemperature(self.type)) {
        const currentUnit = self.settings.currentTemperatureUnit;
        if (currentUnit === TemperatureUnit.fahrenheit) {
          return {
            ...sensorTypeData,
            minValue: celsiusToFahrenheit(sensorTypeData.minValue),
            maxValue: celsiusToFahrenheit(sensorTypeData.maxValue),
          };
        }
      }
      return sensorTypeData;
    },

    get defaultSensorRange() {
      const sensorType = self.sensorType?.toJSON();

      if (!sensorType) {
        return DEFAULT_SENSOR_RANGES;
      }

      if (isTemperature(self.type)) {
        const currentUnit = self.settings.currentTemperatureUnit;
        if (currentUnit === TemperatureUnit.fahrenheit) {
          return {
            ...sensorType,
            minValue: celsiusToFahrenheit(sensorType.minValue),
            maxValue: celsiusToFahrenheit(sensorType.maxValue),
          };
        }
      }
      return sensorType;
    },

    get canCalibrate() {
      return self.sensorType?.canCalibrate;
    },

    get periodCalibrationDays(): undefined | number {
      return self.sensorType?.validCalibrationPeriodDays || undefined;
    },

    get isUnknownAnalog() {
      return self.type === SensorTypeName.Analog;
    },
  }))
  .views((self) => ({
    get isOutOfRange() {
      const { minValue, maxValue } = self.sensorRange;

      if (
        !minValue ||
        !maxValue ||
        self.state !== SensorState.Online ||
        self.isNotCalibrated
      ) {
        return false;
      }

      const currentValue =
        isTemperature(self.type) &&
        self.settings.currentTemperatureUnit === TemperatureUnit.fahrenheit
          ? celsiusToFahrenheit(self.value)
          : self.value;

      return (
        (currentValue > maxValue || currentValue < minValue) &&
        currentValue !== -1
      );
    },

    get iconName() {
      return SENSOR_ICONS[self.type];
    },

    get unitName() {
      if (isTemperature(self.type)) {
        const currentUnit = self.settings.currentTemperatureUnit;
        return TEMPERATURE_UNITS[currentUnit]?.unit;
      }
      return self.sensorType?.unit;
    },

    get displayValue() {
      if (self.isNotCalibrated || self.isUnknownAnalog) return "---";

      let { value } = self;
      if (self.sensorType) {
        if (value < self.sensorType.limitMinValue)
          value = self.sensorType.limitMinValue;
        if (value > self.sensorType.limitMaxValue)
          value = self.sensorType.limitMaxValue;
      }

      return displaySensorValue({
        sensorType: self.type,
        units: self.settings.units,
        value,
        defaultValue: "-",
      });
    },

    get displayName() {
      return self.name;
    },

    get pinName() {
      return `Pin ${self.extensionPin || "-"}`;
    },

    get numberOfDecimals() {
      const step = self.sensorRange?.step || 0;
      return step < 1 ? 1 : 0;
    },

    get isChangeableType() {
      // TODO: !!!
      return [
        SensorTypeName.DO,
        SensorTypeName.ORP,
        SensorTypeName.SoilMoisture,
        SensorTypeName.LUX,
        SensorTypeName.PAR,
      ].includes(self.type as SensorTypeName);
    },

    get valueLimits() {
      const { limitMinValue, limitMaxValue, step, minValue, maxValue } =
        self.sensorType || {};

      if (isTemperature(self.type)) {
        const currentUnit = self.settings.currentTemperatureUnit;
        if (currentUnit === TemperatureUnit.fahrenheit) {
          return {
            step: 1,
            minValue: celsiusToFahrenheit(limitMinValue || minValue),
            maxValue: celsiusToFahrenheit(limitMaxValue || maxValue),
          };
        }
      }

      return {
        minValue: limitMinValue || minValue,
        maxValue: limitMaxValue || maxValue,
        step,
      };
    },

    get recalibrationDate(): undefined | Date {
      if (self.periodCalibrationDays && self.lastCalibrationDate) {
        return dayjs(self.lastCalibrationDate)
          .add(self.periodCalibrationDays, "days")
          .toDate();
      }
      return undefined;
    },
  }))
  .views((self) => ({
    get minMaxOptions() {
      const {
        minValue: defaultMinValue,
        maxValue: defaultMaxValue,
        step: defaultStep,
      } = DEFAULT_SENSOR_RANGES;
      const { minValue, maxValue, step } = self.valueLimits;

      return range(
        minValue || defaultMinValue,
        maxValue || defaultMaxValue,
        step || defaultStep,
      ).map((number) => ({
        value: Number(number.toFixed(self.numberOfDecimals)),
        label: `${number.toFixed(self.numberOfDecimals)} ${
          self.unitName || ""
        }`,
      }));
    },

    get availableAnalogTypes() {
      if (!self.sensorType?.isAnalog) return [];

      if (!self.module) {
        return self.rootStore.sensorStore.analogSensorsTypes;
      }

      if (self.module.isHydro) {
        return self.rootStore.sensorStore.analogDOORPSensorsTypes;
      }
      return self.rootStore.sensorStore.analogSensorsTypesForSensorDirector;
    },
  }))
  .actions((self) => {
    const sensorsApi = new SensorsApi(self.environment.api);
    // const calibrationApi = new CalibrationApi(self.environment.api);

    const updateName = flow(function* (rawName: string) {
      const name = rawName.trim();

      const nameError = duplicateNameValidator(
        name,
        self.rootStore.sensorStore.sensorsNames,
      );

      if (nameError) {
        return { error: nameError };
      }

      self.setStatusPending();
      const result = yield sensorsApi.update(self.uid, { name });

      if (result.kind === "ok") {
        self.setStatusDone();
        self.name = name;
        return {};
      }

      self.setStatusError(result.errors);
      if (__DEV__) console.log(result.kind);
      const [error] = result.errors;
      return { error };
    });

    const setType = flow(function* (typeName: string) {
      self.setStatusPending();
      const result = yield sensorsApi.setSensorType(self.uid, typeName);

      if (result.kind === "ok") {
        self.setStatusDone();
        // self.type = typeName;
        // self.typeName = typeName;
        return {};
      }
      self.setStatusError(result.errors);
      const [error] = result.errors;
      return { error };
    });

    const fetchDetails = flow(function* (fetchProps: IsFetchTimeProps = {}) {
      if (
        !fetchProps.force &&
        self.name &&
        (self.moduleName || self.moduleUid)
      ) {
        return self;
      }

      self.setStatusPending();
      const result = yield sensorsApi.getSensorData(self.uid);

      if (result.kind === "ok") {
        self.setStatusDone();

        self.name = result.data.name;
        self.type = result.data.type;
        self.typeName = result.data.typeName;
        self.moduleUid = result.data.moduleUid;
        self.moduleName = result.data.moduleName;
        self.connectedToPin = result.data.connectedToPin;
        self.lastCalibrationDate = result.data.lastCalibrationDate
          ? new Date(result.data.lastCalibrationDate)
          : null;

        return self;
      }

      self.setStatusError(result.errors);
      if (__DEV__) console.log(result.kind);
      return result;
    });

    const remove = flow(function* () {
      self.setStatusPending();
      const result = yield sensorsApi.remove(self.uid);

      if (result.kind === "ok") {
        self.setStatusDone();
      } else {
        self.setStatusError(result.errors);
        if (__DEV__) console.log(result.kind);
      }
    });

    const transformValues = (values: any[]) => {
      if (
        isTemperature(self.type) &&
        self.settings.currentTemperatureUnit === TemperatureUnit.fahrenheit
      ) {
        return values?.map((hItem) => ({
          ...hItem,
          value: celsiusToFahrenheit(hItem.value),
        }));
      }
      return values;
    };

    const updateSensorRange = flow(function* (
      minValue: number,
      maxValue: number,
    ) {
      const { units } = self.rootStore.settingsStore;
      const rangeMinValue = inputSensorValue(minValue, self.type, units);
      const rangeMaxValue = inputSensorValue(maxValue, self.type, units);

      self.setStatusPending();
      const result = yield sensorsApi.update(self.uid, {
        minValue: rangeMinValue,
        maxValue: rangeMaxValue,
      });

      if (result.kind === "ok") {
        self.setStatusDone();
        self.minValue = rangeMinValue;
        self.maxValue = rangeMaxValue;
      } else {
        self.setStatusError(result.errors);
      }

      return result;
    });

    return {
      updateName,
      updateSensorRange,
      setType,
      remove,
      fetchDetails,
      transformValues,
    };
  });

export const SensorDataModel = types
  .model("SensorData")
  .props({
    uid: types.identifier,
    value: types.maybe(types.number),
    state: types.maybeNull(
      types.enumeration<SensorState>("SensorState", Object.values(SensorState)),
    ),
    error: types.maybeNull(types.string),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .views((self) => ({
    get isOffline() {
      return self.state === SensorState.Offline;
    },
    get isDisconnected() {
      return self.state === SensorState.Disconnect;
    },
    get needCalibration() {
      return self.state === SensorState.NeedCalibration;
    },
    get needRecalibration() {
      return self.state === SensorState.NeedRecalibration;
    },
    get isNotCalibrated() {
      return self.needCalibration && !self.lastCalibrationDate;
    },
    get translatedState(): string {
      return SENSOR_STATE_TEXT[self.state] || "";
    },
  }))
  .actions((self) => {
    const sensorsApi = new SensorsApi(self.environment.api);

    const updateData = async (data: SensorData) => {
      if (data) {
        self.value = data.value;
        self.state = data.state;
        self.error = data.error;
      } else {
        self.value = undefined;
        self.state = SensorState.Error;
      }
    };

    const fetchData = flow(function* () {
      const result = yield sensorsApi.getOneSensorData(self.uid);

      if (result.kind === "ok") {
        updateData(result.data);
      }

      return result;
    });

    return {
      updateData,
      fetchData,
    };
  });

export const SensorTypesModel = types
  .model("SensorTypes")
  .props({
    // id: types.identifierNumber,
    name: types.identifier,
    displayName: types.maybeNull(types.string),
    unit: types.maybeNull(types.string),
    maxValue: types.maybeNull(types.number),
    minValue: types.maybeNull(types.number),
    limitMaxValue: types.maybeNull(types.number),
    limitMinValue: types.maybeNull(types.number),
    step: types.maybeNull(types.number),
    isAnalog: types.maybeNull(types.boolean),
    canCalibrate: types.maybeNull(types.boolean),
    validCalibrationPeriodDays: types.maybeNull(types.number),
  })
  .views((self) => ({
    get iconName() {
      return SENSOR_ICONS[self.name];
    },
  }));

export const SensorModel = types.compose(
  "SensorModel",
  SensorViewModel,
  SensorDataModel,
);

type SensorViewType = Instance<typeof SensorViewModel>;
export type SensorView = SensorViewType;

type SensorDataMOBXType = Instance<typeof SensorDataModel>;
export type SensorData = SensorDataMOBXType;

type SensorMOBXType = Instance<typeof SensorModel>;
export type Sensor = SensorMOBXType;

type SensorTypeMOBXType = Instance<typeof SensorTypesModel>;
export type TSensorType = SensorTypeMOBXType;

export const createSensorDefaultModel = () => types.optional(SensorModel, {});
