import { View, StyleSheet } from "react-native";
import { useNavigation, useTheme } from "@react-navigation/native";

import { Button, Text } from "@components/ui";
import { LayoutCenter } from "@components/layout";

import { Screen } from "../../components";
import { spacing } from "../../theme";
import { NewPasswordSetUpSuccess as NewPasswordSetUpSuccessPicture } from "../../svgs/pictures";
import { translate } from "../../i18n";

const styles = StyleSheet.create({
  screen: {
    paddingHorizontal: spacing[4],
  },
  title: {
    fontSize: 24,
  },
  textContainer: {
    marginVertical: spacing[6],
    alignItems: "center",
  },
});

export const NewPasswordSetUpSuccess = () => {
  const navigation = useNavigation() as any;
  const { fonts } = useTheme() as any;

  const submitHandler = () => {
    navigation.navigate("settings-account");
  };

  return (
    <Screen style={styles.screen}>
      <LayoutCenter>
        <NewPasswordSetUpSuccessPicture />
        <View style={styles.textContainer}>
          <Text style={[styles.title, fonts.medium]}>
            {translate("Auth.SuccessfullRegistrationScreen.success")}
          </Text>
          <Text style={[styles.title, fonts.medium]}>
            {translate("Settings.newPasswordSetUp")}
          </Text>
        </View>
        <Button
          type="primary"
          onPress={submitHandler}
          tx="Settings.backToSettings"
        >
          Back to Settings
        </Button>
      </LayoutCenter>
    </Screen>
  );
};
