import React from "react";
import { StatusBar, StyleSheet, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useTheme } from "@react-navigation/native";
import { RefreshControl } from "react-native-web-refresh-control";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { Portal } from "react-native-paper";

import { ScreenProps } from "./screen-props";
import { isNonScrolling, presets } from "./screen-presets";
import { defaultColors, spacing } from "../../theme";
import { Loading } from "../ui";
import { HelpHints } from "../hints";
import { KeyboardAware } from "./screenWithKeyboard";
import { CurrentServer } from "./CurrentServer";

// const isMobile = Platform.OS === "ios" || Platform.OS === "android";

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  screen: {
    // paddingHorizontal: spacing[4],
    paddingBottom: spacing[4],
  },
  screenWithHints: {
    paddingBottom: spacing[7],
  },
});

function ScreenWithoutScrolling(props: ScreenProps) {
  const preset = presets.fixed;
  const {
    style = {},
    statusBar,
    children,
    header,
    loading,
    loadingText,
    keyboard,
    hints,
    error,
  } = props;

  const contentContainerStyle = [
    styles.screen,
    preset.inner,
    style,
    hints?.length && styles.screenWithHints,
  ];

  return (
    <View style={preset.outer}>
      <Portal.Host>
        {loading && (
          <Portal>
            <Loading text={loadingText} />
          </Portal>
        )}
        <StatusBar
          barStyle={statusBar || "dark-content"}
          // for Android
          backgroundColor={defaultColors.background}
        />
        {header}
        <View style={contentContainerStyle}>
          {error ||
            (keyboard ? (
              <KeyboardAware>
                {children}
                {hints?.length ? <HelpHints hints={hints} /> : null}
              </KeyboardAware>
            ) : (
              <>
                {children}
                {hints?.length ? <HelpHints hints={hints} /> : null}
              </>
            ))}
        </View>
      </Portal.Host>
    </View>
  );
}

function ScreenWithScrolling(props: ScreenProps) {
  const preset = presets.scroll;
  const {
    style = {},
    keyboardShouldPersistTaps,
    statusBar,
    children,
    header,
    loading,
    loadingText,
    refreshing: screenRefreshing = false,
    onRefresh,
    onScroll,
    hints,
    error,
  } = props;

  const [refreshing, setRefreshing] = React.useState(false);

  const onRefreshHandler = React.useCallback(async () => {
    setRefreshing(true);
    await onRefresh?.();
    setRefreshing(false);
  }, [onRefresh]);

  const contentContainerStyle = [
    preset.inner,
    style,
    hints?.length && styles.screenWithHints,
  ];

  return (
    <View style={preset.outer}>
      <Portal.Host>
        {loading && (
          <Portal>
            <Loading text={loadingText} />
          </Portal>
        )}
        <StatusBar
          barStyle={statusBar || "dark-content"}
          // for Android
          backgroundColor={defaultColors.background}
        />
        {header}
        <View style={[styles.screen, preset.outer]}>
          <KeyboardAwareScrollView
            // scrollEnabled={false} // TODO: set from context when moving on chart
            style={preset.outer}
            contentContainerStyle={contentContainerStyle}
            keyboardShouldPersistTaps={keyboardShouldPersistTaps || "handled"}
            onScroll={onScroll}
            refreshControl={
              onRefresh || screenRefreshing ? (
                <RefreshControl
                  refreshing={refreshing || screenRefreshing}
                  onRefresh={onRefreshHandler}
                />
              ) : undefined
            }
          >
            {error || (
              <>
                {children}
                {hints?.length ? <HelpHints hints={hints} /> : null}
              </>
            )}
          </KeyboardAwareScrollView>
        </View>
      </Portal.Host>
    </View>
  );
}

/**
 * The starting component on every screen in the app.
 *
 * @param props The screen props
 */
export default function Screen(props: ScreenProps) {
  const insets = useSafeAreaInsets();
  const {
    colors: { background },
  } = useTheme() as any;
  const { backgroundColor, testID, preset } = props;
  const backgroundStyle = {
    backgroundColor: backgroundColor || background,
  };
  const insetStyle = { paddingTop: props.unsafe ? 0 : insets.top };

  return (
    <View
      testID={testID}
      style={[styles.container, backgroundStyle, insetStyle]}
    >
      <CurrentServer />
      {isNonScrolling(preset) ? (
        <ScreenWithoutScrolling {...props} />
      ) : (
        <ScreenWithScrolling {...props} />
      )}
    </View>
  );
}
