import React, { useMemo } from "react";
import { View, StyleSheet } from "react-native";
import { observer } from "mobx-react-lite";
import { useTheme } from "@react-navigation/native";

import { Text } from "@components/ui";
import { CustomControl } from "@components/inputs";

import { spacing } from "../../../../../theme";
import { AddIcon } from "../../../../../svgs/menu";

const createStyles = (theme: any) =>
  StyleSheet.create({
    customControl: {
      height: 92,
      marginBottom: spacing[3],
      backgroundColor: theme.colors.background,
      borderColor: theme.colors.cardBG,
      // borderColor: theme.colors.border,
      borderWidth: 1,
      padding: spacing[4],
    },
    content: {
      flexDirection: "column",
    },
    title: {
      fontSize: 14,
      color: theme.colors.label,
    },
    socketName: {
      color: theme.colors.label,
      fontSize: 12,
      marginTop: spacing[2],
    },
  });

type AddItemProps = {
  title: string;
  socketName: string;
  onPress: () => void;
};

export const ModuleEmptyItem = observer(
  ({ title, socketName, onPress }: AddItemProps) => {
    const theme = useTheme() as any;
    const styles = useMemo(() => createStyles(theme), [theme]);

    return (
      <CustomControl
        onPress={onPress}
        control={<AddIcon color={theme.colors.label} />}
        style={styles.customControl}
      >
        <View style={styles.content}>
          <Text style={styles.title}>{title}</Text>
          <Text style={styles.socketName}>{socketName}</Text>
        </View>
      </CustomControl>
    );
  },
);
