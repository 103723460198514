import * as Location from "expo-location";
import { requestLocationPermissions } from "./permissions";

export type TLocation = {
  lat: number | null;
  lng: number | null;
};

type TResultGetLocation = {
  location: TLocation;
  errorCode?: string;
  error?: string;
};

const locationNull: TLocation = {
  lat: null,
  lng: null,
};

export const getLocation = async (): Promise<TResultGetLocation> => {
  try {
    const granted = await requestLocationPermissions();

    if (granted) {
      const { latitude, longitude } = (
        await Location.getCurrentPositionAsync({})
      ).coords;

      return {
        location: {
          lat: latitude || null,
          lng: longitude || null,
        },
      };
    }
    return {
      error: "No location permissions",
      location: locationNull,
    };
  } catch (error) {
    return {
      errorCode: error.code,
      error: error.message,
      location: locationNull,
    };
  }
};
