import React, { useMemo, VoidFunctionComponent } from "react";
import { View, StyleSheet } from "react-native";
import { observer } from "mobx-react-lite";
import { useTheme } from "@react-navigation/native";

import { Module } from "@models/module/module";
import { Button, Heading, Text } from "@components/ui";
import { CheckListItem, CheckListStatus } from "@components/ui/CheckList";

import { spacing } from "../../../../theme";
import { translate } from "../../../../i18n";

const createStyles = (theme: any) =>
  StyleSheet.create({
    container: {
      backgroundColor: theme.colors.card,
      alignItems: "center",
      padding: spacing[4],
      borderTopLeftRadius: spacing[4],
      borderTopRightRadius: spacing[4],
      marginTop: -spacing[4],
      flex: 1,
    },
    info: {
      marginBottom: spacing[6],
    },
    testConnectionButton: {
      minWidth: 260,
    },
    testConnections: {
      marginBottom: spacing[3],
    },
    testing: {
      textAlign: "center",
      marginBottom: 25,
      fontSize: 16,
    },
    testConnectionHint: {
      fontSize: 14,
      color: theme.colors.label,
      textAlign: "center",
    },
    errorDescription: {
      fontSize: 14,
      color: theme.colors.palette.GrayBlue,
      textAlign: "center",
      marginBottom: spacing[4],
    },
    link: {
      marginTop: spacing[7],
    },
  });

type ModuleDetailsProps = {
  module: Module;
};

export const ModuleDetailsOffline: VoidFunctionComponent<ModuleDetailsProps> =
  observer(({ module }) => {
    const theme = useTheme() as any;
    const styles = useMemo(() => createStyles(theme), [theme]);

    // const testConnectionHandler = async () => {
    //   await module.testConnection();
    //   // if (module.isTestingCloud) {
    //   //  const { uid } = module;
    //   //  navigation.navigate("module-details", { uid });
    //   // }
    // };

    const whyOfflineHandler = () => {
      // TODO: !!!
      console.log("should navigate to manual...");
    };

    return (
      <View style={styles.container}>
        {module.isModuleConnectionFailure ? (
          <View>
            {/* <Button
              type="primary"
              onPress={testConnectionHandler}
              tx="Modules.Details.testConnection"
              style={styles.testConnectionButton}
            >
              Test connection
            </Button> */}
            <Text
              style={styles.testConnectionHint}
              tx="Modules.Details.testConnectionHint"
            />
          </View>
        ) : null}
        {module.isModuleConnectionFailure ? (
          <Button
            style={styles.link}
            type="link"
            tx="common.whyItsOffline"
            onPress={whyOfflineHandler}
          >
            Why it`s offline?
          </Button>
        ) : null}
        {module.isModuleConnectionTesting ? (
          <View style={styles.testConnections}>
            {!module.offlineTestError ? (
              <View>
                <Heading style={styles.testing} tx="Modules.Details.testing">
                  Testing ...
                </Heading>
                <CheckListItem
                  status={module.isTestingModule && CheckListStatus.Success}
                  label={translate("Modules.Details.connectionToModule")}
                />
                <CheckListItem
                  status={module.isTestingRouter && CheckListStatus.Success}
                  label={translate("Modules.Details.connectionToRouter")}
                />
                <CheckListItem
                  status={module.isTestingInternet && CheckListStatus.Success}
                  label={translate("Modules.Details.internetAvailability")}
                />
                <CheckListItem
                  status={module.isTestingCloud && CheckListStatus.Success}
                  label={translate("Modules.Details.connectionToCloud")}
                />
              </View>
            ) : (
              <View>
                <Text style={styles.errorDescription}>
                  {module.offlineTestError}
                </Text>
                <Text
                  style={styles.errorDescription}
                  tx="Modules.Details.linkOfflineErrorDescription"
                >
                  To solve this problem, press on link below and follow the
                  instructions
                </Text>
                <Button
                  style={styles.link}
                  type="link"
                  tx="Modules.Details.helpLinkOfflineError"
                >
                  Why it`s offline?
                </Button>
              </View>
            )}
          </View>
        ) : null}
      </View>
    );
  });
