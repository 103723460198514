import Svg, { SvgProps, Path } from "react-native-svg";

export const ModuleSuccessSvg = (props: SvgProps) => (
  <Svg width={360} height={250} fill="none" viewBox="0 0 360 250" {...props}>
    <Path
      d="M113.477 137.188C117.985 129.187 111.273 116.859 98.4838 109.652C85.6949 102.446 71.6723 103.091 67.1637 111.092C62.655 119.094 69.3675 131.422 82.1565 138.628C94.9455 145.835 108.968 145.19 113.477 137.188Z"
      fill="white"
      stroke="#2A2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M82.7023 119.835L113.482 137.175"
      stroke="#2A2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M121.277 131.987C134.659 131.913 145.412 114.523 145.294 93.1436C145.176 71.7647 134.233 54.4934 120.851 54.5671C107.469 54.6408 96.7164 72.0316 96.8341 93.4106C96.9519 114.79 107.896 132.061 121.277 131.987Z"
      fill="white"
      stroke="#2A2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M120.892 62.3647L121.272 131.985"
      stroke="#2A2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M142.772 78.8448L121.092 97.9948"
      stroke="#2A2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M97.0023 95.3047L121.192 116.495"
      stroke="#2A2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M104.612 68.5048L121.002 82.8648"
      stroke="#2A2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M130.842 89.3848L145.102 89.3047"
      stroke="#2A2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M101.082 74.5148L111.412 74.4648"
      stroke="#2A2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M254.544 216.725H102.924C85.6942 216.725 75.0242 197.945 83.8642 183.145L158.254 58.565C167.454 43.155 189.764 43.145 198.984 58.535L273.584 183.115C282.454 197.915 271.794 216.725 254.544 216.725Z"
      fill="#B0EACC"
    />
    <Path
      d="M135.113 180.425H176.623H211.683H256.773C264.753 180.425 269.683 171.725 265.583 164.885L231.083 107.265C228.963 103.725 225.473 101.945 221.843 101.935H135.113"
      fill="white"
    />
    <Path
      d="M135.113 180.425H176.623H211.683H256.773C264.753 180.425 269.683 171.725 265.583 164.885L231.083 107.265C228.963 103.725 225.473 101.945 221.843 101.935H135.113"
      stroke="#2A2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M170.172 180.425H100.052C92.0818 180.425 87.1518 171.735 91.2318 164.895L125.632 107.275C129.892 100.145 140.202 100.145 144.472 107.265L178.972 164.885C183.082 171.725 178.152 180.425 170.172 180.425Z"
      fill="white"
      stroke="#2A2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M134.071 139.145C134.301 138.815 134.691 138.615 135.101 138.615C135.511 138.615 135.881 138.805 136.131 139.135C136.161 139.185 136.201 139.225 136.211 139.275L149.501 161.395H154.611L137.041 132.165C136.651 131.475 135.901 131.055 135.101 131.055C134.321 131.055 133.581 131.465 133.191 132.125L133.121 132.235L115.591 161.405H120.701L134.011 139.265C134.021 139.215 134.041 139.175 134.071 139.145Z"
      fill="#1FB25A"
    />
    <Path
      d="M162.581 159.575L142.801 126.645L141.071 123.765C139.851 121.725 137.561 120.455 135.121 120.455C132.681 120.455 130.391 121.725 129.171 123.765L128.501 124.895L107.661 159.575C106.441 161.615 106.441 164.135 107.661 166.175C108.881 168.215 111.171 169.485 113.611 169.485H119.201H119.511H130.421H130.521C131.841 169.535 132.871 170.565 132.871 171.835V176.955H137.291V171.835C137.291 168.205 134.211 165.235 130.421 165.235H113.561C112.711 165.215 111.911 164.765 111.491 164.065C111.051 163.345 111.051 162.435 111.491 161.715L133.041 125.875C133.481 125.175 134.301 124.735 135.141 124.735C135.991 124.735 136.801 125.175 137.241 125.875L158.791 161.715C159.231 162.435 159.231 163.345 158.791 164.065C158.371 164.765 157.571 165.225 156.721 165.235H149.281H149.151C148.271 165.235 147.471 164.785 147.031 164.065L137.101 147.535C136.711 146.845 135.961 146.425 135.161 146.425C134.381 146.425 133.641 146.835 133.251 147.495L133.181 147.605L124.861 161.435H129.961L134.051 154.635C134.071 154.605 134.101 154.555 134.121 154.525C134.351 154.195 134.741 153.995 135.151 153.995C135.561 153.995 135.931 154.185 136.181 154.515C136.211 154.565 136.241 154.605 136.261 154.655L143.211 166.205C144.431 168.245 146.721 169.515 149.161 169.515H150.661H153.891H154.951H155.211H156.681C159.131 169.515 161.411 168.245 162.631 166.205C163.791 164.125 163.801 161.605 162.581 159.575Z"
      fill="#1FB25A"
    />
    <Path
      d="M236.532 223.905C261.081 223.905 280.982 204.004 280.982 179.455C280.982 154.906 261.081 135.005 236.532 135.005C211.983 135.005 192.082 154.906 192.082 179.455C192.082 204.004 211.983 223.905 236.532 223.905Z"
      fill="white"
      stroke="#2A2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M250.402 166.845L236.162 181.085L221.722 166.645C218.142 163.065 212.352 163.065 208.772 166.645C205.192 170.225 205.192 176.025 208.772 179.595L229.812 200.625C233.322 204.135 239.022 204.135 242.532 200.625L279.762 163.395C283.342 159.815 283.342 154.025 279.762 150.445C276.182 146.865 270.392 146.865 266.812 150.445L262.312 154.945"
      fill="#B0EACC"
    />
    <Path
      d="M250.402 166.845L236.162 181.085L221.722 166.645C218.142 163.065 212.352 163.065 208.772 166.645C205.192 170.225 205.192 176.025 208.772 179.595L229.812 200.625C233.322 204.135 239.022 204.135 242.532 200.625L279.762 163.395C283.342 159.815 283.342 154.025 279.762 150.445C276.182 146.865 270.392 146.865 266.812 150.445L262.312 154.945"
      stroke="#2A2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M237.412 76.5049H268.052C271.532 76.5049 273.692 80.3049 271.902 83.2949L256.872 108.465C255.012 111.575 250.502 111.585 248.642 108.475L233.572 83.3049C231.782 80.3049 233.932 76.5049 237.412 76.5049Z"
      fill="#B0EACC"
    />
  </Svg>
);
