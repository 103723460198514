import { ApiResponse } from "apisauce";

import { Api } from "./api";
import { ApiProblem, processResponse } from "./response-resolver";

const VERSIONS_PATH = "/Settings/versions";
// const VERSION_PATH = "/Settings/version";

export type SettingsResult =
  | { kind: "ok"; data: Record<string, string> }
  | ApiProblem;

export class SettingsApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getVersions(): Promise<SettingsResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        VERSIONS_PATH,
      );

      return processResponse(response);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  // async getApiVersion(): Promise<any> {
  //   try {
  //     const response: ApiResponse<SettingsResult> = await this.api.apisauce.get(
  //       VERSION_PATH,
  //     );
  //     return processResponse(response);
  //   } catch (e) {
  //     if (__DEV__) console.log(e.message);
  //     return { kind: "error" };
  //   }
  // }
}
