import React from "react";
import { Image } from "react-native";

import { MODULE_IMAGES } from "@assets/modules";
import { MODULE_PICTURE_SIZE, PictureSlider } from "./PictureSlider";

type ModuleDetailsImageProps = {
  moduleType: string;
  disabled?: boolean;
  currentItem?: number;
};
export const ModuleDetailsImage = ({
  disabled,
  currentItem,
  moduleType,
}: ModuleDetailsImageProps) => {
  const imageSource = MODULE_IMAGES[moduleType] || MODULE_IMAGES.default;
  const imageBackSource = MODULE_IMAGES[`${moduleType}-back`];
  const opacity = disabled ? 0.6 : 1;

  return !imageBackSource ? (
    <Image style={[MODULE_PICTURE_SIZE, { opacity }]} source={imageSource} />
  ) : (
    <PictureSlider
      images={[imageSource, imageBackSource]}
      disabled={disabled}
      currentItem={currentItem}
      showPagination
    />
  );
};
