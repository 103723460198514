import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { palette } from "../../theme";

export default function HumidifierIcon({ color = palette.Gray, size = 24 }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 36 36" fill="none">
      <Path
        d="M14.7856 12.0013H20.2495C20.9462 12.0146 21.6327 11.8336 22.2323 11.4785C22.8318 11.1234 23.3205 10.6083 23.6436 9.99101C23.9668 9.37369 24.1115 8.67855 24.0616 7.98357C24.0118 7.28858 23.7693 6.62123 23.3613 6.05637C22.9533 5.4915 22.3961 5.05147 21.752 4.78563C21.1079 4.51978 20.4026 4.43864 19.7149 4.55129C19.0273 4.66395 18.3847 4.96595 17.8592 5.42343C17.3336 5.8809 16.9459 6.47576 16.7395 7.14127"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M18.6431 17.9987L27.7502 17.9987C28.4469 17.9854 29.1334 18.1664 29.733 18.5215C30.3325 18.8766 30.8212 19.3917 31.1443 20.009C31.4675 20.6263 31.6122 21.3214 31.5623 22.0164C31.5125 22.7114 31.27 23.3788 30.862 23.9436C30.454 24.5085 29.8968 24.9485 29.2527 25.2144C28.6086 25.4802 27.9033 25.5614 27.2156 25.4487C26.528 25.3361 25.8854 25.0341 25.3599 24.5766C24.8343 24.1191 24.4466 23.5242 24.2402 22.8587"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M5.91642 22.0096C5.40931 22.9066 5.1426 23.9239 5.14307 24.9593C5.14354 25.9947 5.41117 27.0118 5.9191 27.9083C6.42702 28.8048 7.15734 29.5493 8.0367 30.0668C8.91605 30.5844 9.91348 30.8569 10.9288 30.8569C11.9441 30.8569 12.9415 30.5844 13.8209 30.0668C14.7002 29.5493 15.4305 28.8048 15.9385 27.9083C16.4464 27.0118 16.714 25.9947 16.7145 24.9593C16.715 23.9239 16.4482 22.9066 15.9411 22.0096L10.9297 14.1426L5.91546 22.0096H5.91642Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
