import React, { useMemo, useState } from "react";
import { StyleSheet } from "react-native";
import { StackScreenProps } from "@react-navigation/stack";

import { useStores } from "@models/root-store/root-store-context";
import { SensorState } from "@models/sensor/sensor";
import { Screen } from "@components/index";
import { TopBarNew } from "@components/layout";
import { HistoryHeader } from "@components/common";
import { ChartDataItem, ChartLineData } from "@components/Chart";

import { spacing } from "../../../../theme";
import { translate } from "../../../../i18n";
import { TPeriod } from "../../../../utils/timeConverter";
import { useAppTheme, useModuleDiagnosticHistory } from "../../../../hooks";
import { ModulesNavigatorParamList } from "../../navigation";
import { DiagnosticHistoryChart } from "./components/HistoryChart";

const createStyles = (theme: any, opacity?: number) =>
  StyleSheet.create({
    screen: {
      paddingHorizontal: spacing[4],
      paddingTop: spacing[4],
      paddingBottom: spacing[1],
    },
  });

export const ModuleDiagnosticHistoryScreen: React.FC<
  StackScreenProps<ModulesNavigatorParamList, "module-diagnostic-history">
> = ({ route }) => {
  const theme = useAppTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);

  const uid = route.params?.uid;
  const { moduleStore } = useStores();
  const module = moduleStore.getModule(uid);

  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [period, setPeriod] = useState<TPeriod>({} as TPeriod);

  const { diagnosticHistory, isLoading, errors } = useModuleDiagnosticHistory({
    uid: uid as string,
    startDate: period.startDate,
    endDate: period.endDate,
    limit: 150,
  });

  const rssiData: ChartDataItem[] = diagnosticHistory.map((item) => ({
    dateTime: item.timestamp,
    value: item.rssi,
    state: item.noData ? SensorState.Offline : null, // TODO: FIX SensorState.Offline !!!
  }));
  const rssiLines: ChartLineData[] = [
    {
      value: -80,
      label: "LOW",
    },
  ];

  const apiFailsData: ChartDataItem[] = diagnosticHistory.map((item) => ({
    dateTime: item.timestamp,
    value: item.apiSendFails,
    state: item.apiStatus !== "Connected" ? SensorState.Offline : null, // TODO: FIX SensorState.Offline !!!
  }));

  const apiFailRatioData: ChartDataItem[] = diagnosticHistory.map((item) => ({
    dateTime: item.timestamp,
    value: item.apiSendFailRatio,
    state: item.apiStatus !== "Connected" ? SensorState.Offline : null, // TODO: FIX SensorState.Offline !!!
  }));

  const mqttFailsData: ChartDataItem[] = diagnosticHistory.map((item) => ({
    dateTime: item.timestamp,
    value: item.mqttFails,
    state: item.mqttStatus !== "Connected" ? SensorState.Offline : null, // TODO: FIX SensorState.Offline !!!
  }));

  return (
    <Screen
      style={styles.screen}
      preset="scroll"
      header={
        <TopBarNew
          title={translate("Modules.Diagnostic.title")}
          subtitle={module.displayName}
        />
      }
    >
      <HistoryHeader
        onChange={setPeriod}
        currentDateTime={diagnosticHistory[currentIndex]?.timestamp}
      />

      <DiagnosticHistoryChart
        title="RSSI"
        isLoading={isLoading}
        data={rssiData}
        setCurrentIndex={setCurrentIndex}
        currentIndex={currentIndex}
        lines={rssiLines}
        height={110}
        error={errors?.[0]}
        // yMax={-30}
        // yMin={-98}
      />
      <DiagnosticHistoryChart
        title="API Fails"
        isLoading={isLoading}
        data={apiFailsData}
        setCurrentIndex={setCurrentIndex}
        currentIndex={currentIndex}
        height={80}
      />
      <DiagnosticHistoryChart
        title="API Fail Ratio"
        isLoading={isLoading}
        data={apiFailRatioData}
        setCurrentIndex={setCurrentIndex}
        currentIndex={currentIndex}
        height={80}
      />
      <DiagnosticHistoryChart
        title="MQTT Fails"
        isLoading={isLoading}
        data={mqttFailsData}
        setCurrentIndex={setCurrentIndex}
        currentIndex={currentIndex}
        height={80}
      />
    </Screen>
  );
};
