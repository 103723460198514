import { Platform } from "react-native";
import WifiManager from "react-native-wifi-reborn";

import { translate } from "../i18n";
import {
  requestLocationPermissions,
  requestNetworkPermissions,
} from "../services/permissions";
import { uniq } from "./uniq";

const MAX_WIFI_FREQUENCY = 2484;

type TResultWifiSsid = {
  ssid?: string;
  error?: string;
};

// for android and ios
export const getWifiSsid = async (): Promise<TResultWifiSsid> => {
  const granted = await requestLocationPermissions();
  if (!granted) {
    return {
      error: translate("permissions.ACCESS_FINE_LOCATION_denied"),
    };
  }

  const result: TResultWifiSsid = {};
  try {
    const ssid = await WifiManager?.getCurrentWifiSSID();
    if (ssid) {
      result.ssid = ssid;
    } else {
      result.error = "SSID not found";
    }
  } catch (error) {
    result.error = error.message;
  }

  return result;
};

export const valueMatchesPrefixes = (
  value: string,
  prefixes: string[],
): boolean => {
  return !!prefixes.find(
    (prefix) => prefix && value.toLowerCase().startsWith(prefix.toLowerCase()),
  );
};

export type TResultConnectToSsid = {
  isConnected: boolean;
  error?: string;
  permissionStatuses?: Record<string, string>;
  response?: any;
};

type ConnectToSsidProps = {
  ssid: string;
  password: string;
  isWep?: boolean;
  isHidden?: boolean;
};

export const connectToSsidForAndroid = async ({
  ssid,
  password,
  isWep = false,
  isHidden = false,
}: ConnectToSsidProps): Promise<TResultConnectToSsid> => {
  await requestLocationPermissions();
  const permissionStatuses = await requestNetworkPermissions();

  const result: TResultConnectToSsid = {
    isConnected: false,
    permissionStatuses,
  };

  // WifiManager.connectToProtectedSSID(ssid, pass, false, false)
  //   .then((r) => setResponse(JSON.stringify(r, null, 2)))
  //   .catch((e) => setError(e.toString()))
  //   .finally(() => setIsLoading(false));

  try {
    result.response = await WifiManager?.connectToProtectedSSID(
      ssid,
      password,
      isWep,
      isHidden,
    );
  } catch (error) {
    result.error = error.message;
  }

  if (!result.error) {
    const currentSsid = await getWifiSsid();

    if (currentSsid.ssid === ssid) {
      result.isConnected = true;
    }
  }

  await WifiManager?.forceWifiUsageWithOptions(result.isConnected, {
    noInternet: true,
  });

  return result;
};

export const disconnectSsid = async () => {
  if (Platform.OS === "android") {
    WifiManager?.disconnect();
  }

  if (Platform.OS === "ios") {
    const result: TResultWifiSsid = await getWifiSsid();
    if (result?.ssid) {
      await WifiManager?.disconnectFromSSID(result.ssid);
    }
  }
};

// Only for Android
type TResultWifiList = {
  list?: Array<string>;
  error?: string;
};

export const getWifiList = async (
  ssidPrefixes?: string[],
): Promise<TResultWifiList> => {
  const granted = await requestLocationPermissions();
  if (!granted) {
    return {
      error: translate("permissions.ACCESS_FINE_LOCATION_denied"),
    };
  }

  try {
    const wifiEntries = await WifiManager?.reScanAndLoadWifiList();
    const ssidList = uniq(wifiEntries?.map((wifiEntry) => wifiEntry.SSID));
    const list = ssidPrefixes?.length
      ? ssidList.filter((ssid) => valueMatchesPrefixes(ssid, ssidPrefixes))
      : ssidPrefixes;

    return {
      list,
      error: list.length ? undefined : "No wifi networks available",
    };
  } catch (error) {
    return {
      error: error.message,
    };
  }
};

// only for IOS
type ConnectToSSIDPrefixProps = {
  ssidPrefix: string;
  password: string;
  isWep?: boolean;
};

export const connectToSSIDPrefix = async ({
  ssidPrefix,
  password,
  isWep = false,
}: ConnectToSSIDPrefixProps): Promise<TResultConnectToSsid> => {
  const result: TResultConnectToSsid = { isConnected: false };

  try {
    await WifiManager?.connectToProtectedSSIDPrefix(
      ssidPrefix,
      password,
      isWep,
    );
  } catch (error) {
    result.error = error.message;
  }

  if (!result.error) {
    result.isConnected = true;
  }

  return result;
};

// for android and ios

export type TSsidPrefix = {
  ssid: string;
  password: string;
};

export const getWifiListByPrefixes = async (
  ssidPrefixList: TSsidPrefix[],
): Promise<TResultWifiList> => {
  let results: TResultWifiList = {};

  if (Platform.OS === "android") {
    const ssidPrefixes: Array<string> = [];

    ssidPrefixList.forEach((prefix) => {
      ssidPrefixes.push(prefix.ssid);
    });

    results = await getWifiList(ssidPrefixes);
  }

  // TODO: this solution will not work
  if (Platform.OS === "ios") {
    ssidPrefixList.forEach(async (prefix) => {
      const resultPrefix: TResultConnectToSsid = await connectToSSIDPrefix({
        ssidPrefix: prefix.ssid,
        password: prefix.password,
      });

      if (resultPrefix.isConnected) {
        const resultSsid = await getWifiSsid();
        if (resultSsid?.ssid) {
          if (!results.list) {
            results.list = [];
          }
          results.list.push(resultSsid.ssid);
        }
      } else {
        results.error = resultPrefix.error;
      }
    });

    if (results.list) {
      results.error = null;
      await disconnectSsid();
    }
  }

  return results;
};

export const isFrequencyMoreThan2GHz = async (): Promise<boolean> => {
  await requestLocationPermissions();
  const frequency = await WifiManager?.getFrequency();

  return Boolean(frequency && frequency > MAX_WIFI_FREQUENCY);
};

export const isEnabledWiFi = async () => {
  const isEnabled =
    Platform.OS === "android" ? await WifiManager?.isEnabled() : true;

  return isEnabled;
};
