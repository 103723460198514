import Svg, { SvgProps, Path } from "react-native-svg";

export const NoAddedModulesNew = (props: SvgProps) => {
  return (
    <Svg width={212} height={141} viewBox="0 0 212 141" fill="none" {...props}>
      <Path
        d="M91.0909 35.3149H54.1683C39.5565 35.3149 27.7109 47.2687 27.7109 61.8805V61.7723C27.7109 76.3841 39.5565 88.2296 54.1683 88.2296H154.851"
        stroke="#B6C5D3"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M91.0909 35.3149H54.1683C39.5565 35.3149 27.7109 47.2687 27.7109 61.8805V61.7723C27.7109 76.3841 39.5565 88.2296 54.1683 88.2296H154.851"
        stroke="#B6C5D3"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M67.1863 69.9186H167.869C179.8 69.9186 189.466 79.2992 189.466 91.2298V91.5234C189.466 103.454 179.8 113.12 167.869 113.12H153.768"
        stroke="#B6C5D3"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M155.862 124.63H56.1159C44.7809 124.63 37.7614 112.237 43.577 102.47L92.5158 20.2612C98.5682 10.0922 113.245 10.0856 119.311 20.2414L168.388 102.451C174.223 112.217 167.21 124.63 155.862 124.63Z"
        fill="#F4F4F4"
      />
      <Path
        d="M72.9893 104.368H104.797H131.663H166.215C172.329 104.368 176.107 97.7017 172.966 92.4603L146.529 48.3074C144.904 45.5948 142.23 44.2308 139.449 44.2231H72.9893"
        fill="white"
      />
      <Path
        d="M72.9893 104.368H104.797H131.663H166.215C172.329 104.368 176.107 97.7017 172.966 92.4603L146.529 48.3074C144.904 45.5948 142.23 44.2308 139.449 44.2231H72.9893"
        stroke="#B6C5D3"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M99.8542 104.37H46.1228C40.0155 104.37 36.2378 97.7111 39.3642 92.4698L65.7242 48.3169C68.9885 42.8533 76.8888 42.8533 80.1608 48.3092L106.597 92.4621C109.747 97.7035 105.969 104.37 99.8542 104.37Z"
        fill="white"
        stroke="#B6C5D3"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M51.9587 47.9406C50.9465 44.2239 53.1641 40.314 56.8731 39.2708C60.5898 38.2277 64.4378 40.2908 65.6355 43.9534C66.0992 45.3829 66.2846 46.851 66.246 48.3424C66.1687 51.3868 65.4269 54.2922 64.2988 57.1048C64.1597 57.4525 63.9588 57.5452 63.6033 57.4139C62.8924 57.1512 62.1738 56.9039 61.4784 56.6025C59.2144 55.6367 57.0972 54.439 55.2427 52.8008C54.0682 51.7654 53.0714 50.5755 52.3682 49.1614C52.2291 48.7519 52.0746 48.3501 51.9587 47.9406Z"
        fill="white"
        stroke="#B6C5D3"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M59.8641 47.8326C59.8641 41.3033 54.5711 36.0103 48.0417 36.0103"
        stroke="#B6C5D3"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M15.9117 50.6134C22.441 50.6134 27.734 55.9064 27.734 62.4358"
        stroke="#B6C5D3"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M14.5827 58.101C13.1609 57.3978 11.9786 56.401 10.9432 55.2265C9.30509 53.372 8.10741 51.2548 7.14153 48.9908C6.8479 48.2954 6.59291 47.5768 6.33019 46.8659C6.19883 46.5104 6.29156 46.3095 6.63927 46.1704C9.45191 45.05 12.3573 44.3005 15.4017 44.2232C16.9008 44.1846 18.3612 44.37 19.7907 44.8337C23.4533 46.0236 25.5164 49.8794 24.4732 53.5961C23.4301 57.2974 19.5202 59.5227 15.8035 58.5105C15.3863 58.4023 14.9845 58.2401 14.5827 58.101Z"
        stroke="#B6C5D3"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M200.874 100.052C200.874 93.5227 195.581 88.2297 189.052 88.2297"
        stroke="#B6C5D3"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M193.379 101.389C194.082 102.811 195.079 103.993 196.253 105.028C198.108 106.666 200.225 107.864 202.489 108.83C203.184 109.124 203.903 109.378 204.614 109.641C204.969 109.773 205.17 109.68 205.309 109.332C206.43 106.519 207.179 103.614 207.257 100.57C207.295 99.0706 207.11 97.6102 206.646 96.1807C205.456 92.5181 201.6 90.455 197.884 91.4981C194.183 92.5413 191.957 96.4512 192.969 100.168C193.085 100.577 193.24 100.979 193.379 101.389Z"
        stroke="#B6C5D3"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M42.0594 61.0771C35.5301 61.0771 30.2371 66.3701 30.2371 72.8994"
        stroke="#B6C5D3"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M42.1675 68.9742C38.4508 69.9864 34.541 67.7687 33.4978 64.0598C32.4547 60.3431 34.5178 56.495 38.1804 55.2973C39.6099 54.8337 41.078 54.6482 42.5693 54.6869C45.6138 54.7641 48.5192 55.5059 51.3318 56.6341C51.6795 56.7732 51.7722 56.9741 51.6409 57.3295C51.3782 58.0404 51.1309 58.759 50.8295 59.4545C49.8637 61.7185 48.666 63.8357 47.0278 65.6902C45.9924 66.8647 44.8025 67.8615 43.3884 68.5646C42.9866 68.7037 42.5848 68.866 42.1675 68.9742Z"
        stroke="#B6C5D3"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M197.201 67.5413C197.201 74.0707 191.908 79.3637 185.379 79.3637"
        stroke="#B6C5D3"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M189.706 66.2045C190.409 64.7827 191.406 63.6004 192.581 62.565C194.435 60.9269 196.552 59.7292 198.816 58.7633C199.512 58.4697 200.23 58.2147 200.941 57.952C201.297 57.8206 201.498 57.9134 201.637 58.2611C202.757 61.0737 203.507 63.9791 203.584 67.0235C203.622 68.5226 203.437 69.983 202.973 71.4125C201.783 75.0751 197.928 77.1382 194.211 76.095C190.51 75.0519 188.284 71.142 189.297 67.4253C189.412 67.0158 189.575 66.614 189.706 66.2045Z"
        stroke="#B6C5D3"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M176.695 58.2277C170.165 58.2277 164.872 63.5207 164.872 70.05"
        stroke="#B6C5D3"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M176.811 66.1323C173.094 67.1446 169.184 64.9269 168.141 61.2179C167.098 57.5012 169.161 53.6532 172.824 52.4555C174.253 51.9919 175.721 51.8064 177.212 51.845C180.257 51.9223 183.162 52.6641 185.975 53.7923C186.323 53.9313 186.415 54.1322 186.284 54.4877C186.021 55.1986 185.774 55.9172 185.473 56.6126C184.507 58.8766 183.309 60.9938 181.671 62.8483C180.636 64.0228 179.446 65.0196 178.032 65.7228C177.622 65.8619 177.22 66.0164 176.811 66.1323Z"
        stroke="#B6C5D3"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M42.4617 21.3976C37.8409 26.0183 37.8409 33.5058 42.4617 38.1189"
        stroke="#B6C5D3"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M48.1256 26.907C46.2093 30.2528 41.8744 31.4505 38.5209 29.5651C35.1519 27.672 33.8924 23.4917 35.6387 20.0609C36.3187 18.7241 37.2228 17.5573 38.3123 16.5219C40.5222 14.4201 43.0953 12.8979 45.8847 11.7002C46.2247 11.5534 46.4334 11.6307 46.5956 11.9707C46.9124 12.6584 47.2447 13.3384 47.5229 14.0415C48.4424 16.321 49.0915 18.67 49.246 21.1349C49.3465 22.7035 49.2074 24.2412 48.7051 25.748C48.5042 26.1343 48.3342 26.5361 48.1256 26.907Z"
        stroke="#B6C5D3"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M72.1929 72.7367C72.3692 72.4838 72.668 72.3306 72.9822 72.3306C73.2964 72.3306 73.5799 72.4762 73.7714 72.729C73.7944 72.7673 73.8251 72.798 73.8328 72.8363L84.0166 89.7864H87.9323L74.4688 67.3881C74.1699 66.8593 73.5952 66.5375 72.9822 66.5375C72.3845 66.5375 71.8174 66.8517 71.5186 67.3574L71.465 67.4417L58.0321 89.794H61.9478L72.1469 72.8287C72.1546 72.798 72.1699 72.7597 72.1929 72.7367Z"
        fill="#B6C5D3"
      />
      <Path
        d="M94.04 88.3923L78.883 63.1588L77.5574 60.9519C76.6225 59.3887 74.8677 58.4155 72.998 58.4155C71.1283 58.4155 69.3735 59.3887 68.4387 60.9519L67.9253 61.8178L51.956 88.3923C51.0212 89.9555 51.0212 91.8865 51.956 93.4497C52.8909 95.013 54.6457 95.9861 56.5154 95.9861H60.7989H61.0364H69.3965H69.4731C70.4846 96.0244 71.2739 96.8137 71.2739 97.7869V101.71H74.6608V97.7869C74.6608 95.0053 72.3007 92.7295 69.3965 92.7295H56.4771C55.8257 92.7141 55.2127 92.3693 54.8909 91.8329C54.5537 91.2812 54.5537 90.5839 54.8909 90.0322L71.4042 62.5688C71.7413 62.0324 72.3697 61.6952 73.0133 61.6952C73.6647 61.6952 74.2854 62.0324 74.6225 62.5688L91.1358 90.0322C91.473 90.5839 91.473 91.2812 91.1358 91.8329C90.814 92.3693 90.2009 92.7218 89.5496 92.7295H83.8485H83.7489C83.0746 92.7295 82.4615 92.3846 82.1244 91.8329L74.5152 79.1663C74.2164 78.6376 73.6417 78.3158 73.0287 78.3158C72.431 78.3158 71.8639 78.6299 71.5651 79.1357L71.5114 79.22L65.136 89.8176H69.044L72.1781 84.6069C72.1934 84.5839 72.2164 84.5456 72.2317 84.5226C72.408 84.2697 72.7068 84.1165 73.021 84.1165C73.3352 84.1165 73.6187 84.2621 73.8103 84.515C73.8333 84.5533 73.8563 84.5839 73.8716 84.6222L79.1972 93.4727C80.1321 95.0359 81.8868 96.0091 83.7566 96.0091H84.906H87.381H88.1933H88.3925H89.519C91.3963 96.0091 93.1435 95.0359 94.0783 93.4727C94.9672 91.8865 94.9749 89.9479 94.04 88.3923Z"
        fill="#B6C5D3"
      />
      <Path
        d="M144.651 139.89C159.436 139.89 171.421 127.905 171.421 113.12C171.421 98.3356 159.436 86.3503 144.651 86.3503C129.866 86.3503 117.881 98.3356 117.881 113.12C117.881 127.905 129.866 139.89 144.651 139.89Z"
        fill="white"
        stroke="#B6C5D3"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M150.588 107.36V99.8892C150.588 96.7058 148.006 94.1235 144.827 94.1285C141.644 94.1285 139.061 96.7107 139.066 99.8892L139.066 107.36L131.596 107.36C128.412 107.36 125.83 109.942 125.835 113.121C125.835 116.304 128.417 118.886 131.596 118.882L139.066 118.882L139.066 126.352C139.066 129.536 141.649 132.118 144.827 132.113C148.011 132.113 150.593 129.531 150.588 126.352L150.588 118.882L158.059 118.882C161.242 118.882 163.824 116.299 163.819 113.121C163.819 109.937 161.237 107.355 158.059 107.36L150.588 107.36Z"
        fill="#D0E7FE"
      />
      <Path
        d="M161.021 117.822C162.476 116.777 163.424 115.071 163.421 113.145C163.421 109.965 160.842 107.386 157.667 107.391L153.936 107.391L150.205 107.391L150.205 99.9287C150.205 96.7489 147.625 94.1696 144.45 94.1745C141.27 94.1745 138.691 96.7538 138.696 99.9287L138.696 107.391L131.234 107.391C128.054 107.391 125.475 109.97 125.48 113.145C125.48 116.325 128.059 118.904 131.234 118.899L138.696 118.899L138.696 126.362C138.696 129.541 141.275 132.121 144.45 132.116C147.63 132.116 150.209 129.537 150.204 126.362L150.205 118.899L155.197 118.899"
        stroke="#2B2E32"
        strokeWidth={2.2}
        strokeLinejoin="round"
      />
      <Path
        d="M143.536 24.4074H163.025C165.238 24.4074 166.612 26.8244 165.473 28.7262L155.913 44.7357C154.73 46.7138 151.862 46.7202 150.679 44.7421L141.093 28.7326C139.955 26.8244 141.322 24.4074 143.536 24.4074Z"
        fill="#F4F4F4"
      />
    </Svg>
  );
};
