import React, { useMemo } from "react";
import { StyleSheet, View, ViewStyle } from "react-native";

import { TSensorRange } from "@models/index";
import { RadioTabs, Text } from "@components/ui";
import { RuleDirection, RuleFormData } from "@models/rule/rule";
import { PickerInput, TItemPickerInput } from "@components/inputs";

import { spacing } from "../../../../../../theme";
import { translate } from "../../../../../../i18n";
import { useAppTheme } from "../../../../../../hooks";
import { SensorRuleErrors } from "./types";

const createStyles = (theme: any) =>
  StyleSheet.create({
    container: {
      marginBottom: spacing[4],
      padding: spacing[4],
      paddingBottom: spacing[3],
      borderRadius: spacing[4],
      borderWidth: 1,
      borderColor: theme.colors.menuBorder,
    },
    pickerInput: {
      marginTop: 0,
    },

    columns: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginHorizontal: -spacing[2],
    },
    column: {
      flex: 1,
      marginHorizontal: spacing[2],
    },

    label: {
      color: theme.colors.label,
      fontSize: 16,
      marginBottom: spacing[2],
    },
    pickerLabel: {
      textAlign: "left",
      width: "100%",
    },
    errorText: {
      color: theme.colors.error,
      fontSize: 12,
    },
  });

const directionItems = [
  {
    key: RuleDirection.Up,
    name: translate("Devices.Rules.less"),
  },
  {
    key: RuleDirection.Down,
    name: translate("Devices.Rules.more"),
  },
];

type SensorValuesProps = {
  formData?: RuleFormData;
  changeFormData?: (data: Partial<RuleFormData>) => void;
  sensorMinMaxOptions?: TItemPickerInput[];
  sensorRange?: TSensorRange;
  errors?: SensorRuleErrors;
  style?: ViewStyle;
};

export const SensorValues = ({
  formData,
  changeFormData = () => null,
  sensorMinMaxOptions = [],
  sensorRange,
  errors,
  style,
}: SensorValuesProps) => {
  const theme = useAppTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <View style={[styles.container, style]}>
      <Text style={styles.label} tx="Devices.Rules.runIfValue" />
      <RadioTabs
        items={directionItems}
        currentTab={formData?.direction}
        onPress={(value) =>
          changeFormData({ direction: value as RuleDirection })
        }
        style={{ marginVertical: spacing[2] }}
      />
      <View style={styles.columns}>
        <View style={styles.column}>
          <PickerInput
            txLabel="Devices.Rules.than"
            labelStyle={styles.pickerLabel}
            containerStyle={styles.column}
            selectedValue={Number(
              formData?.valueLow || sensorRange?.minValue || 0,
            )}
            errorText={errors?.valueLow}
            hasError={!!errors?.direction}
            onChange={(value) => changeFormData({ valueLow: Number(value) })}
            enabled={!!formData?.sensorUid}
            items={sensorMinMaxOptions}
            style={styles.pickerInput}
          />
        </View>
        <View style={styles.column}>
          <PickerInput
            txLabel="Devices.Rules.untilItBecomeLabel"
            labelStyle={styles.pickerLabel}
            containerStyle={styles.column}
            selectedValue={Number(
              formData?.valueHigh || sensorRange?.maxValue || 0,
            )}
            errorText={errors?.valueHigh}
            hasError={!!errors?.direction}
            onChange={(value) => changeFormData({ valueHigh: Number(value) })}
            enabled={!!formData?.sensorUid}
            items={sensorMinMaxOptions}
            style={styles.pickerInput}
          />
        </View>
      </View>
      {errors?.direction && (
        <Text style={styles.errorText}>{errors.direction}</Text>
      )}
    </View>
  );
};
