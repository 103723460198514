import { useMemo } from "react";
import { StyleSheet, View } from "react-native";

import { SwitchInput } from "@components/inputs";

import { useAppTheme } from "../../../../../hooks";
import { spacing } from "../../../../../theme";
import { normalizeFontSize } from "../../../../../utils/sizes";

const createStyles = (theme: any) =>
  StyleSheet.create({
    container: {
      flexDirection: "row",
      flex: 1,
      alignItems: "center",
      borderBottomColor: theme.colors.cardBG,
      borderBottomWidth: 1,
    },
    icon: {
      marginRight: spacing[4],
    },
    switch: {
      width: "auto",
      flex: 1,
      paddingRight: spacing[4],
      paddingTop: spacing[4],
      paddingBottom: spacing[3],
    },
    switchLabel: {
      marginBottom: 0,
      color: theme.colors.text,
      fontSize: normalizeFontSize(14, 16),
    },
  });

export type EquipmentSwitchProps = {
  title: string;
  onChange: (value: boolean) => void;
  value?: boolean;
  icon?: React.FunctionComponent<any>;
};

export const EquipmentSwitch = ({
  title,
  onChange,
  value,
  icon: Icon,
}: EquipmentSwitchProps) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <View style={styles.container}>
      {Icon ? (
        <View style={styles.icon}>
          <Icon />
        </View>
      ) : null}

      <SwitchInput
        label={title}
        onChange={onChange}
        value={value}
        containerStyle={styles.switch}
        labelStyle={styles.switchLabel}
      />
    </View>
  );
};
