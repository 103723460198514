import { observer } from "mobx-react-lite";

import { useStores } from "@models/root-store/root-store-context";

import { translate } from "../../../../i18n";
import { CalibrationStep } from "../types";
import { CALIBRATION_STEPS_PROPS } from "../constants";
import { CalibrationContent } from "./CalibrationContent";

export const CalibrationStepPrepare = observer(() => {
  const { sensorCalibration } = useStores();

  const sensorTypeProps =
    CALIBRATION_STEPS_PROPS[sensorCalibration.sensorType]?.[
      CalibrationStep.Prepare
    ] || {};

  return (
    <CalibrationContent
      title={translate("Sensors.Calibration.Prepare.title")}
      onSubmit={sensorCalibration.init}
      submitLabel={translate("Sensors.Calibration.startCalibration")}
      {...sensorTypeProps}
    />
  );
});
