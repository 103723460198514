import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { palette } from "../../theme";

export default function CO2Icon({ color = palette.Gray, size = 24 }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 36 36" fill="none">
      <Path
        d="M12.8569 14.7048C12.8569 13.5326 12.4618 12.4085 11.7586 11.5796C11.0553 10.7508 10.1015 10.2852 9.10693 10.2852H7.60693C6.61237 10.2852 5.65854 10.7508 4.95528 11.5796C4.25202 12.4085 3.85693 13.5326 3.85693 14.7048V20.0084C3.85693 21.1805 4.25202 22.3047 4.95528 23.1335C5.65854 23.9624 6.61237 24.428 7.60693 24.428H9.10693C10.1015 24.428 11.0553 23.9624 11.7586 23.1335C12.4618 22.3047 12.8569 21.1805 12.8569 20.0084"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M25.7144 14.7048C25.7144 13.5326 25.3193 12.4085 24.616 11.5796C23.9127 10.7508 22.9589 10.2852 21.9644 10.2852H20.4644C19.4698 10.2852 18.516 10.7508 17.8127 11.5796C17.1094 12.4085 16.7144 13.5326 16.7144 14.7048V20.0084C16.7144 21.1805 17.1094 22.3047 17.8127 23.1335C18.516 23.9624 19.4698 24.428 20.4644 24.428H21.9644C22.9589 24.428 23.9127 23.9624 24.616 23.1335C25.3193 22.3047 25.7144 21.1805 25.7144 20.0084V14.7048Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M28.2856 22.5006C28.2856 21.9892 28.4888 21.4987 28.8505 21.1371C29.2122 20.7754 29.7027 20.5723 30.2142 20.5723C30.7257 20.5723 31.2162 20.7754 31.5779 21.1371C31.9396 21.4987 32.1428 21.9892 32.1428 22.5006C32.1428 23.0704 31.7407 23.7714 31.3559 24.292L28.2856 28.2866L32.1428 28.2856"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
