import React, { useMemo } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { TextInput as PaperTextInput } from "react-native-paper";

import { DimmerRuleData } from "@models/rule/rule";
import { TextInput } from "@components/inputs";

import { translate } from "../../../../../../i18n";
import { spacing } from "../../../../../../theme";
import { useAppTheme } from "../../../../../../hooks";
import { stringToNumber } from "../../../../../../utils/numbers";
import { SwitchBlock } from "../SwitchBlock";

const createStyles = (theme: any) =>
  StyleSheet.create({
    container: {
      marginBottom: spacing[4],
      padding: spacing[4],
      paddingBottom: spacing[3],
      borderRadius: spacing[4],
      borderWidth: 1,
      borderColor: theme.colors.menuBorder,
    },
    unit: {
      fontSize: 18,
      color: theme.colors.text,
    },
    columns: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginHorizontal: -spacing[2],
    },
    column: {
      flex: 1,
      marginHorizontal: spacing[2],
    },
  });

type Props = {
  formData?: DimmerRuleData | DimmerRuleData;
  changeFormData?: (data: DimmerRuleData | DimmerRuleData) => void;
  errors?: Record<string, string | undefined>;
  style?: StyleProp<ViewStyle>;
  disabled?: boolean;
  isOpened?: boolean;
};

export const SunriseSunsetInput = ({
  formData,
  changeFormData = () => null,
  errors,
  style,
  disabled = false,
  isOpened = false,
}: Props) => {
  const theme = useAppTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);

  const isOpen = !!formData?.timeToTarget || isOpened;
  const switchHandler = (state: boolean) => {
    if (!state) {
      changeFormData({ timeToTarget: undefined });
    }
  };

  const changeHandler = (value?: string) => {
    changeFormData({ timeToTarget: stringToNumber(value) });
  };

  return (
    <View style={[styles.container, style]}>
      <SwitchBlock
        label={translate("Devices.Rules.sunriseSunset")}
        onChange={switchHandler}
        isOpened={isOpen}
      >
        <View style={styles.columns}>
          <View style={styles.column}>
            <TextInput
              txLabel="Devices.Rules.duration"
              value={formData?.timeToTarget?.toString() ?? ""}
              errorText={errors?.tankSize}
              onChangeText={changeHandler}
              returnKeyType="next"
              right={
                <PaperTextInput.Affix
                  text={translate("units.minutes")}
                  textStyle={styles.unit}
                />
              }
              keyboardType="numeric"
              disabled={disabled}
            />
          </View>
          <View style={styles.column} />
        </View>
      </SwitchBlock>
    </View>
  );
};
