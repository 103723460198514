import { View, StyleSheet } from "react-native";
import { observer } from "mobx-react-lite";

import { Text } from "@components/ui";
import { useStores } from "@models/index";

import { APIServer } from "../../services/api/api-config";
import { DefaultTheme, palette } from "../../theme";

const styles = StyleSheet.create({
  block: {
    width: "100%",
    alignItems: "center",
  },
  [`block-${APIServer.Dev}`]: {
    backgroundColor: palette.Blue,
  },
  [`block-${APIServer.Stage}`]: {
    backgroundColor: palette.Green,
  },
  [`block-${APIServer.Custom}`]: {
    backgroundColor: palette.RedNew,
  },

  text: {
    color: palette.White,
    fontSize: 8,
    textTransform: "uppercase",
    ...DefaultTheme.fonts?.bold,
  },
});

export const CurrentServer = observer(() => {
  const { accountStore, settingsStore } = useStores();
  const serverName = settingsStore?.DEV_apiServer;

  if (
    !settingsStore?.DEV_apiServer ||
    settingsStore.DEV_apiServer === APIServer.Prod
  ) {
    return null;
  }

  const labels = [
    settingsStore.DEV_apiServer,
    settingsStore.versions?.currentAppVersion,
  ];
  if (accountStore.currentUser?.email) {
    labels.push(accountStore.currentUser.email);
  }

  return (
    <View style={[styles.block, styles[`block-${serverName}`]]}>
      <Text numberOfLines={1} style={styles.text}>
        {labels.join(" / ")}
      </Text>
    </View>
  );
});
