import { ApiResponse, ApisauceInstance, create } from "apisauce";

const DEFAULT_MODULE_IP = "192.168.0.1";

const TEST_PATH = "/test";
const MODE_PATH = "/mode";
const WIFI_STATUS_PATH = "/wifi/status";
const UID_PATH = "/uid";
const CONNECT_TO_SERVER_PATH = "/connect_to_server";
const CONNECT_TO_WIFI_PATH = "/connect_to_wifi";
const SET_TEST_SUCCESS_PATH = "/set_test_success";
const RESET_PATH = "/reset";
const UPDATE_FIRMWARE = "/update";

export class EspApi {
  private apisauce: ApisauceInstance;

  constructor(ip: string = DEFAULT_MODULE_IP) {
    this.apisauce = create({
      baseURL: `http://${ip}`,
      headers: {
        Accept: "application/json",
        // "Access-Control-Allow-Origin": "*",
      },
      timeout: 30 * 1000,
      // withCredentials: false,
    });
  }

  async test(): Promise<ApiResponse<any>> {
    return this.apisauce.get(TEST_PATH);
  }

  async mode(): Promise<ApiResponse<any>> {
    return this.apisauce.get(MODE_PATH);
  }

  async wifiStatus(): Promise<ApiResponse<any>> {
    return this.apisauce.get(WIFI_STATUS_PATH);
  }

  async uid(): Promise<ApiResponse<any>> {
    return this.apisauce.get(UID_PATH);
  }

  async connectToServer({
    user,
    accountUid,
    token,
    ssid,
    password,
    server,
    lat,
    lng,
  }): Promise<ApiResponse<any>> {
    return this.apisauce.post(CONNECT_TO_SERVER_PATH, {
      server,
      accountUid,
      user,
      token,
      ssid,
      password,
      lat,
      lng,
    });
  }

  async connectToWifi(
    ssid: string,
    password: string,
  ): Promise<ApiResponse<any>> {
    return this.apisauce.post(CONNECT_TO_WIFI_PATH, { ssid, password });
  }

  async setTestSuccess(): Promise<ApiResponse<any>> {
    return this.apisauce.post(SET_TEST_SUCCESS_PATH);
  }

  async reset(): Promise<ApiResponse<any>> {
    return this.apisauce.post(RESET_PATH);
  }

  async updateFirmware(file: Blob): Promise<ApiResponse<any>> {
    const HTTP_TIMEOUT = 120000;
    const headers = {
      // Host: "",
      Accept: "*/*",
      // Connection: "keep-alive",
      "Content-Type": "multipart/form-data",
    };

    const data = new FormData();
    data.append("update", file);

    return this.apisauce.post(UPDATE_FIRMWARE, data, {
      headers,
      timeout: HTTP_TIMEOUT,
      withCredentials: false,
    });
  }
}
