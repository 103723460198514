import React from "react";
import { observer } from "mobx-react-lite";
import { StackNavigationProp } from "@react-navigation/stack";

import { useStores } from "@models/index";
import { Dialog } from "@components/ui";
import { Module } from "@models/module/module";
import { UpdateStatus } from "@models/module/module-update";
import { ModulesNavigatorParamList } from "@screens/Modules/navigation";
import { ModuleCompactCard } from "@screens/Modules/components";

import { navigationRef } from "../../navigators";
import { translate } from "../../i18n";

export const UpdateModulesNotification = observer(() => {
  const navigation =
    navigationRef as unknown as StackNavigationProp<ModulesNavigatorParamList>;

  const {
    settingsStore: { wasModulesToUpdateShown, markModulesToUpdateAsShown },
    moduleStore: { modulesToUpdate },
  } = useStores();

  const goToUpdate = (module: Module) => {
    markModulesToUpdateAsShown();
    module.update.setStatus(UpdateStatus.Ready);
    navigation.navigate("module-update", {
      uid: module.uid,
      navigator: "modules-list",
    });
  };

  if (wasModulesToUpdateShown) {
    return null;
  }

  if (!modulesToUpdate?.length) {
    return null;
  }

  const onCloseDialog = () => {
    markModulesToUpdateAsShown();
    navigation.navigate("modules-list");
  };

  return (
    <Dialog
      visible
      title={translate("Settings.UpdateModules.title")}
      onOk={onCloseDialog}
      onDismiss={onCloseDialog}
      okText={translate("Settings.UpdateModules.goToModules")}
      dismissable={false}
    >
      {modulesToUpdate.map((module: Module) => (
        <ModuleCompactCard
          key={module.uid}
          module={module}
          onPress={goToUpdate}
        />
      ))}
    </Dialog>
  );
});
