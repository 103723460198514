// https://github.com/rheng001/react-native-wheel-scrollview-picker

import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  Ref,
  useMemo,
} from "react";
import {
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  View,
  ViewProps,
  ViewStyle,
} from "react-native";

import { useAppTheme } from "../../hooks";
import { spacing } from "../../theme";

const createStyles = (theme: any) =>
  StyleSheet.create({
    itemWrapper: {
      minHeight: 30,
      justifyContent: "center",
      alignItems: "center",
    },
    itemTextStyle: {
      color: theme.colors.disabled,
      fontSize: 18,
      ...theme.fonts.medium,
    },
    activeItemTextStyle: {
      color: theme.colors.text,
      fontSize: 20,
      ...theme.fonts.medium,
    },
    highlight: {
      position: "absolute",
      width: "100%",
      backgroundColor: theme.colors.inputBG,
      borderColor: theme.colors.inputBorder,
      borderStyle: "solid",
      borderWidth: 1,
      borderRadius: spacing[4],
    },
  });

function isNumeric(str: string | unknown): boolean {
  if (typeof str === "number") return true;
  if (typeof str !== "string") return false;

  return (
    !Number.isNaN(str as unknown as number) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !Number.isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

const isViewStyle = (style: ViewProps["style"]): style is ViewStyle => {
  return (
    typeof style === "object" &&
    style !== null &&
    Object.keys(style).includes("height")
  );
};

const getSelectedIndex = (
  value: ItemValue,
  dataSource: Array<ItemT>,
): number => {
  const index = dataSource.findIndex((item) => item.value === value);

  return index && index >= 0 ? index : 0;
};

type ItemValue = number | string;

type ItemT = {
  label?: string;
  value: ItemValue;
};

export type ScrollPickerProps = {
  style?: ViewProps["style"];
  dataSource: Array<ItemT>;
  selectedValue?: ItemValue;
  onValueChange?: (value: ItemValue, index: number) => void;
  renderItem?: (data: ItemT, index: number, isSelected: boolean) => JSX.Element;
  itemTextStyle?: object;
  activeItemTextStyle?: object;
  itemHeight?: number;
  wrapperHeight?: number;
  wrapperBackground?: string;
  // TODO: add proper type to `scrollViewComponent` prop
  // tried using ComponentType<ScrollViewProps & { ref: React.RefObject<ScrollView> }>
  // but ScrollView component from react-native-gesture=handler is not compatible with this.
  scrollViewComponent?: any;
  enabled?: boolean;
};

export type ScrollPickerHandle = {
  scrollToTargetIndex: (val: number) => void;
};

export const ScrollPicker = React.forwardRef(
  (propsState: ScrollPickerProps & { ref?: Ref<ScrollPickerHandle> }, ref) => {
    const theme = useAppTheme();
    const styles = useMemo(() => createStyles(theme), [theme]);

    const {
      itemHeight = 30,
      style,
      scrollViewComponent,
      enabled = true,
      ...props
    } = propsState;

    const [initialized, setInitialized] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(
      getSelectedIndex(props.selectedValue, props.dataSource),
    );
    const sView = useRef<ScrollView>(null);
    const [isScrollTo, setIsScrollTo] = useState(false);
    const [dragStarted, setDragStarted] = useState(false);
    const [momentumStarted, setMomentumStarted] = useState(false);
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

    useImperativeHandle(ref, () => ({
      scrollToTargetIndex: (val: number) => {
        setSelectedIndex(val);
        sView?.current?.scrollTo({ y: val * itemHeight });
      },
    }));

    useEffect(() => {
      const newSelectedIndex = getSelectedIndex(
        props.selectedValue,
        props.dataSource,
      );
      setSelectedIndex(newSelectedIndex);

      const y = itemHeight * newSelectedIndex;
      sView?.current?.scrollTo({ y });
    }, [props.selectedValue, props.dataSource]);

    const wrapperHeight =
      props.wrapperHeight ||
      (isViewStyle(style) && isNumeric(style.height)
        ? Number(style.height)
        : 0) ||
      itemHeight * 5;

    useEffect(
      function initialize() {
        if (initialized) return;
        setInitialized(true);

        setTimeout(() => {
          const y = itemHeight * selectedIndex;
          sView?.current?.scrollTo({ y });
        }, 0);

        // eslint-disable-next-line consistent-return
        return () => {
          if (timer) clearTimeout(timer);
        };
      },
      [initialized, itemHeight, selectedIndex, sView, timer],
    );

    const renderPlaceHolder = () => {
      const h = (wrapperHeight - itemHeight) / 2;
      const header = <View style={{ height: h, flex: 1 }} />;
      const footer = <View style={{ height: h, flex: 1 }} />;

      return { header, footer };
    };

    const renderItem = (data: (typeof props.dataSource)[0], index: number) => {
      const isSelected = index === selectedIndex;
      const item = props.renderItem ? (
        props.renderItem(data, index, isSelected)
      ) : (
        <Text
          style={
            isSelected
              ? [
                  props.activeItemTextStyle
                    ? props.activeItemTextStyle
                    : styles.activeItemTextStyle,
                ]
              : [
                  props.itemTextStyle
                    ? props.itemTextStyle
                    : styles.itemTextStyle,
                ]
          }
        >
          {data.label ?? data.value.toString()}
        </Text>
      );

      return (
        <View style={[styles.itemWrapper, { height: itemHeight }]} key={index}>
          {item}
        </View>
      );
    };

    const scrollFix = useCallback(
      (e: NativeSyntheticEvent<NativeScrollEvent>) => {
        let y = 0;
        const h = itemHeight;

        if (e.nativeEvent.contentOffset) {
          y = e.nativeEvent.contentOffset.y;
        }

        const selectedNewIndex = Math.round(y / h);
        const yNew = selectedNewIndex * h;

        if (yNew !== y) {
          // using scrollTo in ios, onMomentumScrollEnd will be invoked
          if (Platform.OS === "ios") {
            setIsScrollTo(true);
          }
          sView?.current?.scrollTo({ y: yNew });
        }

        if (selectedIndex === selectedNewIndex) {
          return;
        }

        // onValueChange
        if (props.onValueChange) {
          const selectedValue = props.dataSource[selectedNewIndex].value;
          setSelectedIndex(selectedNewIndex);
          props.onValueChange(selectedValue, selectedNewIndex);
        }
      },
      [itemHeight, props, selectedIndex],
    );

    const onScrollBeginDrag = () => {
      setDragStarted(true);

      if (Platform.OS === "ios") {
        setIsScrollTo(false);
      }

      if (timer) clearTimeout(timer);
    };

    const onScrollEndDrag = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
      setDragStarted(false);

      // if not used, event will be garbaged
      const eNew: NativeSyntheticEvent<NativeScrollEvent> = { ...e };
      if (timer) clearTimeout(timer);

      setTimer(
        setTimeout(() => {
          if (!momentumStarted) {
            scrollFix(eNew);
          }
        }, 20),
      );
    };

    const onMomentumScrollBegin = () => {
      setMomentumStarted(true);
      if (timer) clearTimeout(timer);
    };

    const onMomentumScrollEnd = (
      e: NativeSyntheticEvent<NativeScrollEvent>,
    ) => {
      setMomentumStarted(false);

      if (!isScrollTo && !dragStarted) {
        scrollFix(e);
      }
    };

    const { header, footer } = renderPlaceHolder();
    const highlightWidth = (isViewStyle(style) ? style.width : 0) || "100%";

    const wrapperStyle: ViewStyle = {
      height: wrapperHeight,
      // flex: 1,
      backgroundColor: props.wrapperBackground || theme.colors.background,
      overflow: "hidden",
    };

    const highlightStyle: ViewStyle = {
      top: (wrapperHeight - itemHeight) / 2,
      height: itemHeight,
      width: highlightWidth,
    };

    const CustomScrollViewComponent = scrollViewComponent || ScrollView;

    return (
      <View style={wrapperStyle}>
        <View style={[styles.highlight, highlightStyle]} />
        {enabled ? (
          <CustomScrollViewComponent
            ref={sView}
            bounces={false}
            showsVerticalScrollIndicator={false}
            nestedScrollEnabled
            onMomentumScrollBegin={onMomentumScrollBegin}
            onMomentumScrollEnd={onMomentumScrollEnd}
            onScrollBeginDrag={onScrollBeginDrag}
            onScrollEndDrag={onScrollEndDrag}
          >
            {header}
            {props.dataSource.map(renderItem)}
            {footer}
          </CustomScrollViewComponent>
        ) : null}
      </View>
    );
  },
);
