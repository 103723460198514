import React, { useEffect, useMemo, useState } from "react";
import {
  StyleProp,
  TextStyle,
  StyleSheet,
  View,
  ViewStyle,
} from "react-native";

import { Text } from "@components/ui/Text";

import { secondsToText } from "../../utils/timeConverter";
import { useAppTheme } from "../../hooks";
import { spacing } from "../../theme";

const createStyles = (theme: any) =>
  StyleSheet.create({
    container: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      paddingVertical: spacing[1],
      paddingHorizontal: spacing[2],
      borderRadius: spacing[4],
      backgroundColor: theme.colors.progressBG,
      overflow: "hidden",
    },
    progress: {
      position: "absolute",
      left: 0,
      top: 0,
      bottom: 0,
      backgroundColor: theme.colors.progressActiveBG,
    },
  });

type ProgressBarTimerProps = {
  secondsLeft: number;
  secondsTotal: number;
  initialTimestamp: number;
  style?: StyleProp<ViewStyle>;
  styleText?: StyleProp<TextStyle>;
  text?: string;
  callback?: () => void;
};

export const ProgressBarTimer = ({
  secondsLeft,
  secondsTotal,
  style,
  styleText,
  text,
  callback,
  initialTimestamp,
}: ProgressBarTimerProps) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  const intervalMs = 1000;
  const initialDiff = Math.round((Date.now() - initialTimestamp) / 1000);
  const [currentSeconds, setCurrentSeconds] = useState<number>(
    (secondsLeft || 0) - initialDiff,
  );

  const progress = ((secondsTotal - currentSeconds) * 100) / secondsTotal || 0;

  useEffect(() => {
    const tick = setInterval(() => {
      const diff = Math.round((Date.now() - initialTimestamp) / 1000);
      const newCurrentSeconds = secondsLeft - diff;
      if (newCurrentSeconds > 0) {
        setCurrentSeconds(newCurrentSeconds);
      } else {
        setCurrentSeconds(0);
        clearInterval(tick);
        callback?.();
      }
    }, intervalMs);
    return () => clearInterval(tick);
  }, [secondsLeft, intervalMs]);

  if (secondsLeft === undefined || secondsLeft === null) return null;

  return (
    <View style={[styles.container, style]}>
      <View style={[styles.progress, { width: `${progress}%` }]} />
      <Text style={styleText}>
        {text || ""} {secondsToText(currentSeconds)}
      </Text>
    </View>
  );
};
