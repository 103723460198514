import React, { useMemo } from "react";
import { View, StyleSheet, ScrollView } from "react-native";

import {
  Text,
  FloatingButton,
  ErrorsMessage,
  ButtonType,
} from "@components/ui";

import { translate } from "../../../../i18n";
import { spacing } from "../../../../theme";
import { useAppTheme } from "../../../../hooks";
import { normalizeFontSize } from "../../../../utils/sizes";
import { YoutubeLink } from "./YoutubeLink";

const createStyles = (theme: any) =>
  StyleSheet.create({
    container: {
      padding: spacing[4],
      alignItems: "center",
      flex: 1,
      justifyContent: "center",
      paddingBottom: spacing[8],
      marginBottom: spacing[4],
    },
    picture: {
      alignItems: "center",
      paddingTop: spacing[6],
    },
    title: {
      fontSize: normalizeFontSize(24, 26),
      textAlign: "center",
      marginBottom: spacing[4],
      ...theme.fonts.medium,
    },
    itemsTitle: {
      color: theme.colors.label,
      marginBottom: spacing[1],
    },
    item: {
      paddingHorizontal: spacing[3],
      marginBottom: spacing[1],
      fontSize: normalizeFontSize(16, 20),
      textAlign: "center",
      ...theme.fonts.medium,
    },
    alert: {
      marginTop: spacing[3],
    },
    content: {
      paddingHorizontal: spacing[1],
      width: "100%",
    },
  });

type PrepareCalibrationProps = {
  children?: React.ReactNode;
  Picture: React.FC;
  title?: string;
  itemsTitle?: string;
  items?: Array<string>;
  alert?: string;
  onSubmit: () => void;
  submitLabel: string;
  submitType?: ButtonType;
  youtubeId?: string;
  footer?: React.ReactNode;
};

export const CalibrationContent = ({
  children,
  Picture,
  title,
  itemsTitle,
  items,
  alert,
  onSubmit,
  submitLabel,
  submitType = "primary",
  youtubeId,
  footer = null,
}: PrepareCalibrationProps) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <>
      <ScrollView>
        <View style={styles.picture}>{Picture ? <Picture /> : null}</View>

        <View style={styles.container}>
          {itemsTitle ? (
            <Text style={styles.itemsTitle}>{itemsTitle}</Text>
          ) : null}

          {title ? <Text style={styles.title}>{title}</Text> : null}

          {children ? <View style={styles.content}>{children}</View> : null}

          {items?.map((step, index) => (
            <Text style={styles.item} key={`step ${index + 1}`}>{`${
              index + 1
            }. ${step}`}</Text>
          ))}

          {alert ? (
            <ErrorsMessage style={styles.alert} errors={[alert]} />
          ) : null}

          {youtubeId ? (
            <YoutubeLink
              youtubeId={youtubeId}
              label={translate("Sensors.Calibration.watchVideo")}
            />
          ) : null}

          {footer}
        </View>
      </ScrollView>

      {onSubmit ? (
        <FloatingButton onPress={onSubmit} type={submitType}>
          {submitLabel}
        </FloatingButton>
      ) : null}
    </>
  );
};
