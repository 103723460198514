import * as React from "react";
import { ViewStyle } from "react-native";
import { LinearGradient } from "expo-linear-gradient";

import { defaultColors } from "../../theme";

const BG_GRADIENT: ViewStyle = {
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
};

export interface GradientBackgroundProps {
  colors?: string[];
  style?: ViewStyle;
}

export function GradientBackground({
  colors = [
    defaultColors.backgroundGradientStart,
    defaultColors.backgroundGradientEnd,
  ],
  style,
}: GradientBackgroundProps) {
  return (
    <LinearGradient
      colors={colors}
      style={[BG_GRADIENT, style]}
      locations={[0.0, 0.8]}
    />
  );
}

export function BackgroundGradient({
  colors = [
    defaultColors.backgroundGradientStart,
    defaultColors.backgroundGradientEnd,
  ],
  style,
}: GradientBackgroundProps) {
  return (
    <LinearGradient
      colors={colors}
      style={[BG_GRADIENT, style]}
      start={{ x: 0, y: 0.5 }}
      end={{ x: 1, y: 0.5 }}
    />
  );
}
