import React, { useMemo } from "react";
import { Image, StyleSheet, View } from "react-native";
import { observer } from "mobx-react-lite";
import { useTheme } from "@react-navigation/native";

import { MODULE_IMAGES } from "@assets/modules";
import { useStores } from "@models/root-store/root-store-context";
import { Text } from "@components/ui";
import { spacing } from "../../../theme";

const createStyles = (theme: any) =>
  StyleSheet.create({
    card: {
      borderRadius: spacing[4],
      alignItems: "center",
      flexDirection: "row",
      backgroundColor: theme.colors.card,
    },
    module: { flex: 1 },
    picture: {
      width: 128,
      height: 128,
      marginRight: spacing[4],
      borderRadius: spacing[4],
    },
    typeName: {
      marginBottom: spacing[1],
      fontSize: 12,
      color: theme.colors.label,
    },
    name: {
      marginBottom: spacing[3],
    },
    socket: {
      fontSize: 16,
    },
  });

type SelectedModuleProps = {
  moduleUid?: string;
  socketUid?: string;
};
export const SelectedModule: React.FunctionComponent<SelectedModuleProps> =
  observer(({ moduleUid, socketUid }) => {
    const { moduleStore, deviceStore } = useStores();
    const theme = useTheme() as any;
    const styles = useMemo(() => createStyles(theme), [theme]);

    const module = moduleStore.getModule(moduleUid);
    const socket = deviceStore.getDevice(socketUid);
    if (!module || !socket) return null;

    const imageSource = MODULE_IMAGES[module.type] || MODULE_IMAGES.default;

    return (
      <View style={styles.card}>
        <Image style={styles.picture} source={imageSource} />
        <View style={styles.module}>
          <Text style={styles.typeName}>{module.typeName}</Text>
          <Text numberOfLines={2} ellipsizeMode="tail" style={styles.name}>
            {module.displayName}
          </Text>
          <Text style={styles.socket}>{socket.socketName}</Text>
        </View>
      </View>
    );
  });
