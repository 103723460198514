import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { StyleSheet, View } from "react-native";
import { Portal } from "react-native-paper";
import { observer } from "mobx-react-lite";

import { useStores } from "@models/root-store/root-store-context";
import { Screen } from "@components/index";
import { Sensor, SensorTypeName } from "@models/index";
import { ErrorsAlert, ErrorsMessage, Loading, Text } from "@components/ui";
import { CustomControl } from "@components/inputs";

import { spacing } from "../../../theme";
import { useAppTheme } from "../../../hooks";
import { ChevronRightIcon } from "../../../svgs";

const createStyles = (theme: any) =>
  StyleSheet.create({
    scroll: {
      paddingTop: spacing[6],
      paddingBottom: spacing[8],
      paddingHorizontal: spacing[4],
    },
    title: {
      fontSize: 16,
      lineHeight: 22,
      marginBottom: spacing[1],
      ...theme.fonts.medium,
    },
    description: {
      fontSize: 14,
      lineHeight: 16,
      color: theme.colors.subLabel,
    },
  });

type TFormData = {
  minValue?: string;
  maxValue?: string;
};

type SensorItemProps = {
  sensor: Sensor;
  onPress?: (value: TFormData) => void;
};

const SensorItem = ({ sensor, onPress }: SensorItemProps) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  const { sensorRange } = sensor;
  const values = `${sensorRange.minValue} ${sensor?.unitName} - ${sensorRange.maxValue} ${sensor?.unitName}`;
  const onPressHandler = () => onPress(sensorRange);

  return (
    <CustomControl onPress={onPressHandler} control={<ChevronRightIcon />}>
      <View>
        <Text style={styles.title}>{sensor?.name}</Text>
        <Text style={styles.description}>{values}</Text>
      </View>
    </CustomControl>
  );
};

type SensorSelectorProps = {
  currentUid: string;
  sensorType: SensorTypeName;
  header: ReactNode;
  onPress?: (value: TFormData) => void;
};

export const SensorSelector = observer(
  ({
    header: Header,
    onPress,
    sensorType,
    currentUid,
  }: SensorSelectorProps) => {
    const theme = useAppTheme();
    const styles = useMemo(() => createStyles(theme), [theme]);

    const [errors, setErrors] = useState<string[]>();

    const { sensorStore } = useStores();
    useEffect(() => {
      sensorStore.fetchSensors({});
    }, []);

    const sensors = sensorStore.getSensorsByType(sensorType);
    // const { minValue, maxValue } = sensorStore.getSensorType(sensorType);

    const resetErrors = () => {
      setErrors([]);
    };

    const sensorsByType = sensors.filter(
      (sensor) => sensor.uid !== currentUid,
      // &&
      // (sensor.sensorRange.minValue !== minValue ||
      //   sensor.sensorRange.maxValue !== maxValue),
    );

    return (
      <Portal>
        <Screen
          testID="SensorSelector"
          preset="scroll"
          style={styles.scroll}
          header={Header}
        >
          {sensorStore.isLoading && <Loading />}
          {sensorsByType?.length ? (
            sensorsByType.map((sensor) => (
              <SensorItem key={sensor.uid} sensor={sensor} onPress={onPress} />
            ))
          ) : (
            <ErrorsMessage errors={["No sensors"]} />
          )}
        </Screen>

        <ErrorsAlert errors={errors} onCancel={resetErrors} />
      </Portal>
    );
  },
);
