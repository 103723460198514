import { Instance } from "mobx-state-tree";
import {
  FAQDataModel,
  ManualDataModel,
  ReleaseNotesDataModel,
} from "./documents-store";

export enum ReleaseNotesType {
  Api = "API",
  Ios = "IOS",
  Web = "WEB",
  android = "ANDROID",
}

type MOBXFAQData = Instance<typeof FAQDataModel>;
export type TFAQData = MOBXFAQData;

type MOBXManualData = Instance<typeof ManualDataModel>;
export type TManualData = MOBXManualData;

type MOBXReleaseNotesData = Instance<typeof ReleaseNotesDataModel>;
export type TReleaseNotesData = MOBXReleaseNotesData;
