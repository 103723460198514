import { flow, Instance, SnapshotOut, types } from "mobx-state-tree";
import dayjs from "dayjs";

import {
  createRule,
  RuleFormData,
  RuleModel,
  RuleType,
} from "@models/rule/rule";
import { displaySensorValue } from "@models/sensor/sensor";
import {
  IsFetchTimeProps,
  withFetchTimeout,
} from "../extensions/with-fetch-timeout";
import { withEnvironment } from "../extensions/with-environment";
import { StatusType, withStatus } from "../extensions/with-status";
import { withRootStore } from "../extensions/with-root-store";
import { DevicesApi } from "../../services/api";
import { translate } from "../../i18n";
import {
  DateTimeFormats,
  dateTimeHaveTimeToSeconds,
  getDuration,
  SECONDS_DAY,
} from "../../utils/timeConverter";
import { duplicateNameValidator } from "../../utils/validator";
import { RulesApi } from "../../services/api/rules-api";

import {
  DEVICE_ICONS,
  DeviceMode,
  DeviceState,
  DeviceStateType,
  DIMER_TYPE,
  FETCH_RULES_ALIAS,
  HYDRO_TYPE,
  NOT_CONNECTED_TYPE,
  NOT_VISIBLE_TYPES,
  TDeviceHistoryLogRule,
  TDeviceHistoryRule,
  TDeviceHistorySensor,
} from "./types";

export {
  DEVICE_ICONS,
  DeviceMode,
  DeviceState,
  DIMER_TYPE,
  HYDRO_TYPE,
  NOT_CONNECTED_TYPE,
  NOT_VISIBLE_TYPES,
};

/* eslint-disable no-promise-executor-return */
const delay = (ms) => new Promise((res) => setTimeout(res, ms));
/* eslint-enable no-promise-executor-return */

export const getIconByDeviceType = (deviceType: string) =>
  DEVICE_ICONS[deviceType] || DEVICE_ICONS.default;

// export enum DeviceTimePointUnit {
//   Cycles = "Cycles",
//   Seconds = "Seconds",
// }

export const DeviceTypeModel = types
  .model("DeviceType")
  .props({
    id: types.number,
    name: types.identifier,
    displayName: types.string,
    connectorTypes: types.maybe(types.array(types.string)),
    operationMaxTime: types.maybeNull(types.number),
    timeToSetPoint: types.maybeNull(types.number),
    cyclesToSetPoint: types.maybeNull(types.number),
  })
  .views((self) => ({
    get iconName() {
      return getIconByDeviceType(self.name);
    },
  }));

export const DeviceViewModel = types.model("DeviceView").props({
  uid: types.identifier,
  position: types.maybe(types.number),
  row: types.maybe(types.number),
  column: types.maybe(types.number),
});

export const DeviceModel = types
  .model("Device")
  .props({
    uid: types.identifier,
    name: types.maybeNull(types.string),
    // state: types.maybeNull(
    //   types.enumeration<DeviceState>("DeviceState", Object.values(DeviceState)),
    // ),
    state: types.maybeNull(types.string), // DeviceState
    mode: types.enumeration<DeviceMode>(
      "DeviceMode",
      Object.values(DeviceMode),
    ),
    type: types.string,
    moduleUid: types.maybeNull(types.string),
    connectedToPin: types.maybeNull(types.number),
    connectorType: types.maybeNull(types.string),
    connectorTypeName: types.maybeNull(types.string),
    typeName: types.maybeNull(types.string),
    secondsToSwitchBack: types.maybeNull(types.number),
    secondsEnabledFor: types.maybeNull(types.number),
    analogControlPinValue: types.maybeNull(types.number),
    error: types.maybeNull(types.string),
    rules: types.optional(types.array(RuleModel), []),
    activeRuleUid: types.maybeNull(types.string),
    fetchedAt: types.maybeNull(types.number),
  })
  .extend(withEnvironment)
  .extend(withStatus)
  .extend(withFetchTimeout)
  .extend(withRootStore)
  .views((self) => ({
    get activeRule() {
      return self.rules.find((r) => r.uid === self.activeRuleUid);
    },
  }))
  .views((self) => ({
    // TODO: Should be removed after - https://growdirector.atlassian.net/browse/GDV1-1148 !!!
    get activeRuleInSchedule() {
      const schedules = self.activeRule?.schedule;

      if (!schedules?.length || self.state !== DeviceState.On) {
        return null;
      }

      const activeSchedule = schedules.find((rule) => {
        const nowSeconds = dateTimeHaveTimeToSeconds(new Date().toISOString());
        return (
          nowSeconds >= dateTimeHaveTimeToSeconds(rule.startAt) &&
          nowSeconds <= dateTimeHaveTimeToSeconds(rule.endAt)
        );
      });

      return activeSchedule || null;
    },
    get isDimmer() {
      return self.type === DIMER_TYPE;
    },
  }))
  .views((self) => ({
    // TODO: Should be removed after - https://growdirector.atlassian.net/browse/GDV1-1148 !!!
    get secondsToDisableRuleInSchedule() {
      if (self.state !== DeviceState.On || self.mode !== DeviceMode.Auto) {
        return null;
      }

      const activeSchedule = self.activeRuleInSchedule;
      if (!activeSchedule) {
        return null;
      }

      const andAtInSec = dateTimeHaveTimeToSeconds(activeSchedule.endAt);
      const andAtAddDayInSec =
        dateTimeHaveTimeToSeconds(activeSchedule.endAt) + SECONDS_DAY;
      const currentTimeInSec = dateTimeHaveTimeToSeconds(
        new Date().toISOString(),
      );

      const secondsToSwitchBack =
        andAtInSec - currentTimeInSec > 0
          ? andAtInSec - currentTimeInSec
          : andAtAddDayInSec - currentTimeInSec;

      const secondsEnabledFor = Math.round(
        getDuration(activeSchedule.startAt, activeSchedule.endAt),
      );

      return {
        secondsToSwitchBack,
        secondsEnabledFor,
      };
    },

    get stateType(): DeviceStateType {
      if (self.state === DeviceState.Offline) {
        return DeviceStateType.Offline;
      }

      if (self.state === DeviceState.Off) {
        return DeviceStateType.Idle;
      }

      if (self.state === DeviceState.On) {
        if (self.mode === DeviceMode.Manual) {
          return self.secondsEnabledFor
            ? DeviceStateType.Manually
            : DeviceStateType.Continuously;
        }
        if (self.mode === DeviceMode.Auto) {
          if (!self.activeRule) {
            return DeviceStateType.ByRule;
          }
          return self.activeRule?.ruleType === RuleType.Schedule
            ? DeviceStateType.BySchedule
            : DeviceStateType.BySensor;
        }
      }

      return DeviceStateType.Idle;
    },

    get switchErrors() {
      if (self.mode !== DeviceMode.Manual) {
        return [
          translate("Devices.Rules.doesNotSupportManualControl.1"),
          translate("Devices.Rules.doesNotSupportManualControl.2"),
          translate("Devices.Rules.doesNotSupportManualControl.3"),
          translate("Devices.Rules.doesNotSupportManualControl.4"),
        ];
      }
      return undefined;
    },

    get automationErrors() {
      if (self.mode === DeviceMode.Manual && self.state === DeviceState.On) {
        return [translate("Devices.Rules.errors.automationCannotBeActivated")];
      }
      return undefined;
    },
  }))
  .actions((self) => ({
    clear: () => {
      self.clearFetchingTimes();
    },
  }))
  .actions((self) => {
    const devicesApi = new DevicesApi(self.environment.api);

    const fetchData = flow(function* () {
      const result = yield devicesApi.getDevice(self.uid);

      if (result.kind === "ok") {
        self.setStatusDone();

        // applySnapshot(self, result.data);
        self.name = result.data.name;
        self.type = result.data.type;
        self.typeName = result.data.typeName;
        self.state = result.data.state;
        self.mode = result.data.mode;
        self.activeRuleUid = result.data.activeRuleUid;

        self.secondsToSwitchBack =
          result.data.secondsToSwitchBack ||
          self.secondsToDisableRuleInSchedule?.secondsToSwitchBack;

        self.secondsEnabledFor =
          result.data.secondsEnabledFor ||
          self.secondsToDisableRuleInSchedule?.secondsEnabledFor;

        self.analogControlPinValue = result.data.analogControlPinValue;
        self.error = result.data.error;
        self.connectedToPin = result.data.connectedToPin;
        self.connectorType = result.data.connectorType;
        self.fetchedAt = Date.now();
      } else {
        self.setStatusError(result.errors);
        if (__DEV__) console.log(result.kind);
      }
    });

    const refetch = flow(function* () {
      self.setStatusPending();

      yield fetchData();

      if (self.state === DeviceState.Switching) {
        yield delay(1000);
        yield refetch();
      }
    });

    const setState = flow(function* (
      state: DeviceState,
      secondsToSwitchBack?: number,
      analogControlPinValue?: number,
    ) {
      if (self.switchErrors?.length) {
        return {
          errors: self.switchErrors,
        };
      }

      const prevState = self.state;
      self.state = DeviceState.Switching;

      self.setStatusPending();
      const result = yield devicesApi.setState(self.uid, {
        state,
        needToSwitchBack: !!secondsToSwitchBack,
        secondsToSwitchBack,
        analogControlPinValue,
      });

      if (result.kind === "ok") {
        self.setStatusDone();
        yield refetch();
      } else {
        self.setStatusError();
        self.state = prevState;
        if (__DEV__) console.log(result.kind);
      }
      return result;
    });

    const switchOn = async (
      secondsToSwitchBack?: number,
      analogControlPinValue?: number,
    ) => {
      return setState(
        DeviceState.On,
        secondsToSwitchBack,
        analogControlPinValue,
      );
    };

    const switchOff = async () => {
      return setState(DeviceState.Off);
    };

    /* eslint-disable consistent-return */
    const fetchRules = flow(function* (fetchProps: IsFetchTimeProps = {}) {
      const fetchRulesAlias = `${FETCH_RULES_ALIAS}_${self.uid}`;
      if (
        !self.isFetchTime(fetchRulesAlias, {
          ...fetchProps,
          timeoutInSec: 30,
        })
      )
        return undefined;

      self.setStatus(StatusType.PendingPartly);
      const result = yield devicesApi.getDeviceRules(self.uid);

      if (result.kind === "ok") {
        self.setStatusDone();
        self.rules.replace(result.data);
      } else {
        self.setStatusError(result.errors);
        if (__DEV__) console.log(result.kind);
      }
    });
    /* eslint-enable consistent-return */

    const buildRule = (ruleType: RuleType) => {
      return createRule(
        { deviceUid: self.uid, ruleType },
        { ...self.environment, rootStore: self.rootStore },
      );
    };

    const updateName = flow(function* (rawName: string) {
      const name = rawName.trim();

      const error = duplicateNameValidator(
        name,
        self.rootStore.deviceStore.devicesNames,
      );

      if (error) {
        return { error };
      }

      self.setStatusPending();
      const result = yield devicesApi.updateName(self.uid, name);

      if (result.kind === "ok") {
        self.setStatusDone();
        self.name = name;
        // self.rootStore.deviceStore.clearFetchingTimes();
        return {};
      }
      self.setStatusError();
      return { error: result.kind };
    });

    const disconnect = flow(function* () {
      self.setStatusPending();
      const result = yield devicesApi.disconnect(self.uid);

      if (result.kind === "ok") {
        self.type = NOT_CONNECTED_TYPE;
        self.setStatusDone();
      } else {
        self.setStatusError(result.errors);
        if (__DEV__) console.log(result.kind);
      }

      return result;
    });

    const { units } = self.rootStore.settingsStore;

    const getHistoryItemRule = (
      sensorHistory: TDeviceHistorySensor,
      rule: TDeviceHistoryRule,
    ): TDeviceHistoryLogRule | object => {
      if (rule.type === RuleType.Schedule) {
        const description = `${dayjs(rule.startAt).format(
          DateTimeFormats.TimeHMS,
        )} - ${dayjs(rule.endAt).format(DateTimeFormats.TimeHMS)}`;

        return {
          ruleType: rule.type,
          description,
        };
      }

      if (
        rule.type === RuleType.Sensor ||
        rule.type === RuleType.Ec ||
        rule.type === RuleType.Ph
      ) {
        // TODO if sensor removed, need sensor type instead of uid with backend !!!
        const sensor = self.rootStore.sensorStore.getSensor(sensorHistory.uid);

        const valueLow = displaySensorValue({
          sensorType: sensor?.type,
          value: rule.valueLow,
          units,
          precision: sensor?.numberOfDecimals,
        });
        const valueHigh = displaySensorValue({
          sensorType: sensor?.type,
          value: rule.valueHigh,
          units,
          precision: sensor?.numberOfDecimals,
        });
        const value = displaySensorValue({
          sensorType: sensor?.type,
          value: sensorHistory.value,
          units,
          precision: sensor?.numberOfDecimals,
        });

        const description = `${valueLow} ${
          sensor?.unitName || ""
        } -> ${valueHigh} ${sensor?.unitName || ""} [${value}]`;

        return {
          ruleType: rule.type,
          sensorName:
            sensor?.displayName || translate("Devices.History.sensorRemoved"),
          sensorIcon: sensor?.iconName,
          description,
        };
      }

      return {};
    };

    const getHistoryItemRuleOld = (
      ruleUid: string,
      sensorValue?: number | null,
    ): TDeviceHistoryLogRule | object => {
      const rulesByUid = Object.fromEntries(
        self.rules.map((rule) => [rule.uid, rule]),
      );

      const rule = rulesByUid[ruleUid];
      if (!rule) return {};

      const ruleProps = {
        ruleType: rule.ruleType,
      };

      if (rule.ruleType === RuleType.Sensor) {
        const sensor = self.rootStore.sensorStore.getSensor(rule.sensorUid);
        if (!sensor) {
          return ruleProps;
        }

        const valueLow = displaySensorValue({
          sensorType: sensor.type,
          value: rule.valueLow,
          units,
          precision: sensor.numberOfDecimals,
        });
        const valueHigh = displaySensorValue({
          sensorType: sensor.type,
          value: rule.valueHigh,
          units,
          precision: sensor.numberOfDecimals,
        });
        const value = displaySensorValue({
          sensorType: sensor.type,
          value: sensorValue,
          units,
          precision: sensor.numberOfDecimals,
        });
        const description = `${valueLow} ${sensor.unitName} -> ${valueHigh} ${sensor.unitName} [${value}]`;

        return {
          ...ruleProps,
          sensorName: sensor.displayName,
          sensorIcon: sensor.iconName,
          description,
        };
      }
      return {};
    };

    const toggleAutomation = flow(function* (enable?: boolean) {
      // TODO: Add each rule validation !!!

      self.setStatusPending();
      const result = yield devicesApi.toggleAutomation(
        self.uid,
        Boolean(enable),
      );

      if (result.kind === "ok") {
        self.setStatusDone();
        yield refetch();
        yield fetchRules({ force: true });
      } else {
        self.setStatusError();
      }
      return result;
    });

    const prepareRuleData = ({
      uid,
      device,
      ...ruleData
    }: RuleFormData): RuleFormData => {
      const data = {
        ...ruleData,
        deviceUid: self.uid,
      };

      if (ruleData.ruleType === RuleType.Schedule) {
        const preparedSchedule = ruleData.schedule.map(
          ({ startAt, endAt, repeatEverySeconds }) => ({
            startAt,
            endAt,
            repeatEverySeconds,
          }),
        );
        data.schedule = preparedSchedule;
      }

      return data;
    };

    const copyRule = flow(function* (ruleData: RuleFormData) {
      self.setStatusPending();

      const rulesApi = new RulesApi(self.environment.api);
      const result = yield rulesApi.createRule(prepareRuleData(ruleData));

      if (result.kind === "ok") {
        self.setStatusDone();
        yield fetchRules({ force: true });
      } else {
        self.setStatusError(result.errors);
      }

      if (result.kind === "ok") {
        self.setStatusDone();
        self.resetErrors();
      } else {
        self.setStatusError(result.errors);
      }

      return result.data;
    });

    return {
      switchOn,
      switchOff,
      fetchRules,
      buildRule,
      updateName,
      disconnect,
      getHistoryItemRule,
      getHistoryItemRuleOld,
      fetchData,
      refetch,
      toggleAutomation,
      copyRule,
    };
  })
  .views((self) => ({
    get iconName() {
      return getIconByDeviceType(self.type);
    },
    get isConnected() {
      return self.type !== NOT_CONNECTED_TYPE;
    },
    get displayName() {
      return self.name || self.typeName;
    },
    get socketName() {
      return `Socket ${self.connectedToPin}`;
    },

    get displayState() {
      return self.stateType
        ? translate(`Devices.stateType.${self.stateType}`)
        : translate(`Devices.status.unknown`);
    },

    get isOn() {
      return self.state === DeviceState.On;
    },
    get isSwitching() {
      return self.state === DeviceState.Switching;
    },
    get isDosingPump() {
      return self.type === HYDRO_TYPE;
    },
    get rule() {
      return self.rules[self.rules.length - 1];
    },
    get switchWarnings() {
      const activeRule = self.rules.find((rule) => rule.isRuleOn);
      if (self.state === DeviceState.On && activeRule) {
        return [
          translate("Devices.Rules.warnings.disablingCardWillDisableRule"),
        ];
      }
      return undefined;
    },
    get availableRuleTypes(): RuleType[] {
      if (self.isDimmer) {
        return [RuleType.DimmerManual, RuleType.DimmerAuto];
      }

      return [RuleType.Schedule, RuleType.Sensor];
    },
  }));

type DeviceMOBXType = Instance<typeof DeviceModel>;
export type Device = DeviceMOBXType;

type DeviceSnapshotType = SnapshotOut<typeof DeviceModel>;
export type DeviceSnapshot = DeviceSnapshotType;

type DeviceTypeMOBXType = Instance<typeof DeviceTypeModel>;
export type DeviceType = DeviceTypeMOBXType;

export const createDeviceDefaultModel = () => types.optional(DeviceModel, {});
