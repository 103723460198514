import { CalibrationContent } from "../../components/CalibrationContent";
import { translate } from "../../../../../i18n";
import { CalibrationStep } from "../../types";
import { CALIBRATION_STEPS_PROPS } from "../../constants";
import { CalibrationContentProps } from "./types";

export const CalibrationStepClean = ({
  calibration,
}: CalibrationContentProps) => {
  const sensorTypeProps =
    CALIBRATION_STEPS_PROPS[calibration.sensor?.type]?.[
      CalibrationStep.Clean
    ] || {};

  return (
    <CalibrationContent
      title={translate("Sensors.Calibration.clean")}
      itemsTitle={calibration.stepNameOf}
      onSubmit={calibration.nextStep}
      submitLabel={translate("common.next")}
      {...sensorTypeProps}
    />
  );
};
