import React, { useEffect, useState } from "react";
import { StyleProp, TextStyle } from "react-native";

import { Text } from "@components/ui/Text";
import { secondsToText } from "../../utils/timeConverter";

type Props = {
  seconds?: number;
  style?: StyleProp<TextStyle>;
  callback?: () => void;
  initialTimestamp: number;
};

const CountdownTimer: React.FunctionComponent<Props> = ({
  seconds,
  style,
  callback,
  initialTimestamp,
}) => {
  const intervalMs = 1000;
  const initialDiff = Math.round((Date.now() - initialTimestamp) / 1000);
  const [currentSeconds, setCurrentSeconds] = useState<number>(
    (seconds || 0) - initialDiff,
  );

  useEffect(() => {
    const tick = setInterval(() => {
      const diff = Math.round((Date.now() - initialTimestamp) / 1000);
      const newCurrentSeconds = seconds - diff;
      if (newCurrentSeconds > 0) {
        setCurrentSeconds(newCurrentSeconds);
      } else {
        setCurrentSeconds(0);
        clearInterval(tick);
        callback?.();
      }
    }, intervalMs);
    return () => clearInterval(tick);
  }, [seconds, intervalMs]);

  if (seconds === undefined || seconds === null) return null;

  return <Text style={style}>{secondsToText(currentSeconds)}</Text>;
};

export default CountdownTimer;
