import * as React from "react";
import { View } from "react-native";
import Svg, { SvgProps, Path } from "react-native-svg";

import { spacing } from "../../../../theme";

export const CalibrationPH7ErrorSvg = (props: SvgProps) => (
  <View style={{ marginTop: -spacing[8] }}>
    <Svg width={360} height={312} fill="none" {...props}>
      <Path
        d="M253.855 270.086H102.235C85.0047 270.086 74.3347 251.266 83.1747 236.435L157.565 111.59C166.765 96.1468 189.075 96.1368 198.295 111.56L272.895 236.404C281.765 251.236 271.105 270.086 253.855 270.086Z"
        fill="#FFF1F1"
      />
      <Path
        d="M65.6985 164.038H90.9285C93.7985 164.038 95.5685 167.175 94.0985 169.64L81.7185 190.414C80.1885 192.98 76.4785 192.99 74.9385 190.414L62.5285 169.64C61.0585 167.175 62.8285 164.038 65.6985 164.038Z"
        fill="#FFF1F1"
      />
      <Path
        d="M182.6 258.138C183.174 257.145 183.475 256.015 183.475 254.865V182.773H179.047H179.02H174.592V254.865C174.592 256.015 174.892 257.145 175.466 258.138L175.528 258.248C176.15 259.329 176.239 260.637 175.774 261.795L175.132 263.39C174.783 264.253 174.715 265.205 174.933 266.109L175.911 270.169C176.232 271.491 177.414 272.429 178.774 272.429H179.026H179.054H179.307C180.666 272.429 181.849 271.498 182.17 270.169L183.147 266.109C183.365 265.205 183.297 264.253 182.949 263.39L182.306 261.795C181.842 260.637 181.931 259.329 182.552 258.248L182.6 258.138Z"
        fill="white"
        stroke="#2B2E32"
        strokeWidth={2.2615}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M179.046 182.773H179.012H176.641V253.502C176.641 254.796 176.873 256.077 177.331 257.289L179.032 261.815V261.822V261.815L180.734 257.289C181.191 256.077 181.424 254.796 181.424 253.502V182.773H179.046Z"
        fill="#B6C5D3"
      />
      <Path
        d="M182.23 182.772H175.8C173.805 182.772 172.186 181.149 172.186 179.15V162.489C172.186 160.49 173.805 158.867 175.8 158.867H182.23C184.225 158.867 185.845 160.49 185.845 162.489V179.15C185.845 181.149 184.225 182.772 182.23 182.772Z"
        fill="white"
        stroke="#2B2E32"
        strokeWidth={2.2615}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M183.248 158.867L180.46 137.564H179.175H179.114H177.829L175.041 158.867L179.141 158.579L183.248 158.867Z"
        fill="#3F9A6E"
        stroke="#2B2E32"
        strokeWidth={2.2615}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M179.177 137.564V135.215C179.177 118.535 165.681 105.011 149.036 105.011C132.391 105.011 118.895 118.535 118.895 135.215V190.229C118.895 206.91 105.4 220.434 88.7546 220.434H85.9326C69.2872 220.434 55.7919 206.91 55.7919 190.229V182.519C55.7919 165.838 42.2967 152.314 25.6513 152.314H0"
        stroke="#2B2E32"
        strokeWidth={2.2615}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M176 302C193.673 302 208 297.642 208 292.265C208 286.889 193.673 282.531 176 282.531C158.327 282.531 144 286.889 144 292.265C144 297.642 158.327 302 176 302Z"
        fill="#B6C5D3"
      />
      <Path
        d="M180 252.752C195.464 252.752 208 248.906 208 244.162C208 239.418 195.464 235.572 180 235.572C164.536 235.572 152 239.418 152 244.162C152 248.906 164.536 252.752 180 252.752Z"
        fill="#70B8FF"
        fillOpacity={0.4}
      />
      <Path
        opacity={0.6}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M208 244.17C207.989 248.911 195.457 252.753 180 252.753C164.536 252.753 152 248.908 152 244.164C152 243.979 152.019 243.796 152.056 243.615L152 243.616V244.164V282.528V289.77C152 294.622 164.541 298.564 180 298.564C195.459 298.564 208 294.622 208 289.77V279.452V244.17ZM208 244.157V242.445L207.447 242.457C207.808 243.006 207.999 243.575 208 244.157Z"
        fill="#308AE3"
      />
      <Path
        d="M208 219.538V279.464V289.775C208 294.623 195.431 298.563 179.938 298.563C164.445 298.563 151.877 294.623 151.877 289.775V282.538V232.267C151.877 230.185 151.033 228.201 149.543 226.762L145.143 222.067"
        stroke="#2B2E32"
        strokeWidth={2.1962}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M174.285 211.74C165.477 212.429 159.28 214.636 154.659 216.702C152.752 217.549 148.645 219.613 145.729 221.089C144.762 221.581 145.056 222.988 146.136 223.084L151.757 223.603C153.734 223.904 155.654 224.437 157.491 225.202C162.579 227.321 170.78 228.701 180.019 228.701C195.481 228.701 208 224.847 208 220.105C208.012 215.802 197.695 212.241 184.233 211.618"
        stroke="#2B2E32"
        strokeWidth={2.1962}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M200 234.428V261.359"
        stroke="#2B2E32"
        strokeWidth={2.1962}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M161.442 283.763C162.753 284.12 164.078 284.425 165.402 284.703C167.495 285.14 169.217 283.657 169.217 281.551C169.217 280.928 169.217 280.305 169.217 279.696C169.217 277.577 167.495 275.629 165.402 275.205C164.078 274.927 162.753 274.623 161.442 274.265C161.442 277.418 161.442 280.584 161.442 283.763Z"
        stroke="white"
        strokeWidth={2.1962}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M161.442 272.794C161.442 277.921 161.442 283.047 161.442 288.174"
        stroke="white"
        strokeWidth={2.1962}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M174.674 269.893C174.674 276.031 174.674 284.156 174.674 290.294"
        stroke="white"
        strokeWidth={2.1962}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M184.373 269.967C184.373 276.105 184.373 284.316 184.373 290.454"
        stroke="white"
        strokeWidth={2.1962}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M174.674 280.044C177.908 280.277 181.159 280.344 184.393 280.21"
        stroke="white"
        strokeWidth={2.1962}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M189.24 271.488C193.175 271.158 197.085 270.485 200.918 269.457C198.354 275.182 195.764 280.754 193.15 286.174C193.15 287.303 193.162 288.42 193.162 289.537"
        stroke="white"
        strokeWidth={2.1962}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M245.086 162L252.599 154.313C255.8 151.037 255.8 145.722 252.599 142.457C249.398 139.181 244.204 139.181 241.013 142.457L233.5 150.144L225.987 142.457C222.786 139.181 217.592 139.181 214.401 142.457C211.2 145.733 211.2 151.047 214.401 154.313L221.914 162L214.401 169.687C211.2 172.963 211.2 178.278 214.401 181.543C217.602 184.819 222.796 184.819 225.987 181.543L233.5 173.856L241.013 181.543C244.214 184.819 249.408 184.819 252.599 181.543C255.8 178.267 255.8 172.953 252.599 169.687L245.086 162Z"
        fill="#F76B6B"
      />
    </Svg>
  </View>
);
