import * as React from "react";
import {
  TextStyle,
  StyleSheet,
  StyleProp,
  View,
  ImageSourcePropType,
} from "react-native";

import { Text } from "./Text";
import { Heading } from "./Heading";
import { IconButton } from "../common/IconButton";
import { palette, spacing } from "../../theme";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: spacing[4],
    paddingRight: spacing[2],
    marginTop: spacing[4],
    marginBottom: spacing[2],
  },
  heading: {
    marginVertical: spacing[0],
  },
  actions: {
    flexDirection: "row",
  },
  action: {
    marginVertical: spacing[0],
  },
  selectedAction: {
    backgroundColor: palette.GreenLight2,
  },
});

type IconSource = string | ImageSourcePropType | ((props) => React.ReactNode);
export type HeaderAction = {
  key: string;
  icon?: IconSource;
  text?: string;
  onPress?: (() => void) | undefined;
  selected?: boolean;
};
type Props = React.ComponentProps<typeof Text> & {
  style?: StyleProp<TextStyle>;
  actions?: HeaderAction[];
};

export function HeadingWithActions({ style, actions, ...textProps }: Props) {
  if (!actions?.length)
    return (
      <View style={styles.container}>
        <Heading {...textProps} style={style} />
      </View>
    );

  return (
    <View style={styles.container}>
      <Heading {...textProps} style={styles.heading} />

      <View style={styles.actions}>
        {actions.map((action) => (
          <IconButton
            key={`heading-action-${action.key}`}
            style={[styles.action, action.selected && styles.selectedAction]}
            icon={action.icon}
            onPress={action.onPress}
          />
        ))}
      </View>
    </View>
  );
}
