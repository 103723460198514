import { translate } from "../../../../i18n";

import {
  CalibrationCleanDOSvg,
  CalibrationDOAirErrorSvg,
  CalibrationDOAirProcessSvg,
  CalibrationDOAirSvg,
  CalibrationDOLiquidErrorSvg,
  CalibrationDOLiquidProcessSvg,
  CalibrationDOLiquidSvg,
  CalibrationPrepareDOSvg,
} from "../../../../svgs/pictures/calibration/DO";
import {
  CalibrationProcessStatus,
  CalibrationStep,
  TCalibrationStepProps,
} from "../types";

export const DO_STEPS = [
  CalibrationStep.Prepare,
  CalibrationStep.Clean,
  CalibrationStep.Process1,
  CalibrationStep.Process2,
  CalibrationStep.Success,
];

export const DO_STEPS_PROPS: TCalibrationStepProps = {
  [CalibrationStep.Prepare]: {
    Picture: CalibrationPrepareDOSvg,
    items: [
      translate("Sensors.Calibration.Prepare.paperTowel"),
      translate("Sensors.Calibration.Prepare.distilledOrTapWater"),
      translate("Sensors.Calibration.do.doCalibrationLiquid"),
    ],
  },

  [CalibrationStep.Clean]: {
    Picture: CalibrationCleanDOSvg,
    items: [
      translate("Sensors.Calibration.Clean.stirSensorInCleanWater"),
      translate("Sensors.Calibration.Clean.cleanAndDryWithPaperTowel"),
    ],
  },

  [CalibrationStep.Process1]: {
    Picture: CalibrationDOAirSvg,
    title: translate("Sensors.Calibration.calibrationInAir"),
    items: [
      translate("Sensors.Calibration.do.keepSensorInAir"),
      translate("Sensors.Calibration.pressCalibrate"),
    ],
  },

  [`${CalibrationStep.Process1}${CalibrationProcessStatus.Process}`]: {
    Picture: CalibrationDOAirProcessSvg,
    title: translate("Sensors.Calibration.calibrationInAir"),
    alert: translate("Sensors.Calibration.dontTouchSensorHead"),
  },

  [`${CalibrationStep.Process1}${CalibrationProcessStatus.Error}`]: {
    Picture: CalibrationDOAirErrorSvg,
    title: translate("Sensors.Calibration.calibrationInAir"),
  },

  [CalibrationStep.Process2]: {
    Picture: CalibrationDOLiquidSvg,
    title: translate("Sensors.Calibration.calibrationInLiquid"),
    items: [
      translate("Sensors.Calibration.do.placeSensorInCalibrationLiquid"),
      translate("Sensors.Calibration.pressCalibrate"),
    ],
  },

  [`${CalibrationStep.Process2}${CalibrationProcessStatus.Process}`]: {
    Picture: CalibrationDOLiquidProcessSvg,
    title: translate("Sensors.Calibration.calibrationInLiquid"),
    alert: translate("Sensors.Calibration.dontTouchSensorHead"),
  },

  [`${CalibrationStep.Process2}${CalibrationProcessStatus.Error}`]: {
    Picture: CalibrationDOLiquidErrorSvg,
    title: translate("Sensors.Calibration.calibrationInLiquid"),
  },

  [CalibrationStep.Error]: {
    Picture: CalibrationDOLiquidErrorSvg,
  },
};
