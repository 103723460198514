// TODO:
// https://reactnative.directory/?search=picker :
// https://github.com/Cero-Studio/ReactNativeWheelPicker
// https://github.com/react-native-picker/picker
// https://github.com/henninghall/react-native-date-picker ---

import React, { useMemo, useState } from "react";
import { Modal, StyleSheet, View } from "react-native";
import { useTheme } from "@react-navigation/native";

import { useStores } from "@models/index";
import { Screen } from "@components/index";
import { SelectorBar } from "@components/layout";
import { Text } from "@components/ui";
import { DEFAULT_TIME_PROPS, TimeInput } from "@components/inputs";
import { MIN_DURATION_SEC } from "@models/rule/rule";

import { translate } from "../../../../../../i18n";
import { spacing } from "../../../../../../theme";
import {
  addSecondsToDate,
  dateStringToTimeProps,
  getDuration,
  secondsToTimeProps,
  TimeProps,
  timePropsToDateString,
  timePropsToSeconds,
} from "../../../../../../utils/timeConverter";

export type ScheduleItemData = {
  index: number;
  startAt: string;
  endAt: string;
};

const createStyles = (theme: any) =>
  StyleSheet.create({
    container: {
      paddingHorizontal: spacing[4],
      paddingTop: spacing[4],
    },
    label: {
      marginBottom: spacing[3],
      fontSize: 16,
    },
    errorLabel: {
      color: theme.colors.error,
      marginTop: spacing[2],
      textAlign: "center",
    },
  });

const propsToScheduleItemData = (
  startAtProps: TimeProps,
  durationProps: TimeProps,
  index: number,
) => {
  const startAt = timePropsToDateString(startAtProps);
  const duration = timePropsToSeconds(durationProps);
  const endAt = addSecondsToDate(startAt, duration);

  return {
    index,
    startAt,
    endAt,
  };
};

type ScheduleSelectorProps = {
  scheduleItemData: ScheduleItemData;
  onClose: () => void;
  onChange: (scheduleItem: ScheduleItemData) => void;
};

export const ScheduleSelector = ({
  scheduleItemData,
  onClose,
  onChange,
}: ScheduleSelectorProps) => {
  const {
    startAt: initStartAt,
    endAt: initEndAt,
    index,
  } = scheduleItemData || {};

  const theme = useTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);
  const { settingsStore } = useStores();

  const startAtTimeProps = dateStringToTimeProps(initStartAt);
  const durationTimeProps = secondsToTimeProps(
    getDuration(initStartAt, initEndAt),
  );

  const [startAtProps, setStartAtProps] = useState<TimeProps>(
    startAtTimeProps || DEFAULT_TIME_PROPS,
  );
  const [durationProps, setDurationProps] = useState<TimeProps>(
    durationTimeProps || DEFAULT_TIME_PROPS,
  );
  const [error, setError] = useState<string>(null);

  const onApplyHandler = () => {
    if (
      !Number(durationProps.hours) &&
      !Number(durationProps.minutes) &&
      Number(durationProps.seconds) < MIN_DURATION_SEC
    ) {
      setError(
        translate("Devices.Details.runDeviceError", {
          seconds: MIN_DURATION_SEC,
        }),
      );
      return;
    }

    const newScheduleItemData = propsToScheduleItemData(
      startAtProps,
      durationProps,
      index,
    );

    onChange(newScheduleItemData);
  };

  return (
    <Modal testID="ScheduleSelector" onRequestClose={onClose}>
      <Screen
        preset="fixed"
        header={
          <SelectorBar
            title={translate("Devices.Rules.timeAndDuration")}
            onApply={onApplyHandler}
            onClose={onClose}
          />
        }
      >
        <View style={styles.container}>
          <Text style={styles.label} tx="Devices.Rules.startAt" />
          <TimeInput
            value={startAtProps}
            onChange={setStartAtProps}
            timeSystem={settingsStore.currentTimeSystem}
          />
        </View>

        <View style={styles.container}>
          <Text style={styles.label} tx="Devices.Rules.duration" />
          {error ? <Text style={styles.errorLabel}>{error}</Text> : null}
          <TimeInput
            value={durationProps}
            onChange={setDurationProps}
            displaySeconds
          />
        </View>
      </Screen>
    </Modal>
  );
};
