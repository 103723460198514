import React from "react";
import { Dimensions } from "react-native";

import { Image, Loading } from "@components/ui";
import { LayoutCenter } from "@components/layout";
/* eslint-disable @typescript-eslint/no-var-requires */
const SPLASH_IMAGE = require("@assets/splash.png");
/* eslint-enable @typescript-eslint/no-var-requires */
export const Splash = () => {
  const width = Dimensions.get("window").width * 0.9;
  const height = Math.round(width / 2.2);

  return (
    <LayoutCenter>
      <Image
        style={{ width, height, marginBottom: 64 }}
        source={SPLASH_IMAGE}
      />
      <Loading withMask={false} />
    </LayoutCenter>
  );
};
