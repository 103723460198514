import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { palette } from "../theme";

export default function ChevronUpIcon({ color = palette.Gray, size = 24 }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.673 11.414L9.491 5.277c-0.291 -0.218 -0.692 -0.218 -0.981 0L0.327 11.414c-0.362 0.272 -0.435 0.783 -0.164 1.144c0.16 0.214 0.406 0.327 0.656 0.327c0.171 0 0.343 -0.053 0.491 -0.164l7.691 -5.769l7.692 5.769c0.362 0.272 0.874 0.198 1.144 -0.164C18.107 12.197 18.034 11.685 17.673 11.414z"
        fill={color}
      />
    </Svg>
  );
}
