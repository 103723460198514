import { ModuleType } from "@models/module/module";

import { translate } from "../../i18n";
import { LogoHorizontal } from "../../svgs";

type TPage = {
  id: string;
  title: string;
  content: string;
  heading?: string;
  image?: React.FunctionComponent<any>;
};

export const getPageDataById = (id: string) =>
  PAGES.find((page) => page.id === id);

const PAGES: TPage[] = [
  {
    id: "module-manual-onboarding-details",
    title: "",
    heading: translate("Onboarding.ModuleConnection.titleReadDetails"),
    content: translate("Onboarding.ModuleConnection.contentReadDetails"),
  },
  {
    id: "reasons-for-connection-failure",
    title: translate("Modules.update.reasonsForConnectionFailure"),
    content:
      "\n" +
      " 1. The module is too far from the router.\n" +
      "**&#8594;** Move closer to the router and try again.\n" +
      "\n" +
      " 2. The module is attempting to connect but is stuck in the middle.\n" +
      "**&#8594;** Go to the SSID page, search for the module you want to add, click on it, and then remove it from your options.\n" +
      "\n" +
      "3. Start the module adding procedure from the beginning, enter the password (1-9), and wait until it is connected.\n" +
      "**&#8594;** Your module is on a different Wi-Fi network from your phone.\n" +
      "\n" +
      "4. Please ensure that the Wi-Fi network you are attempting to connect the module to operates on the 2.4 GHz frequency.\n" +
      "\n" +
      "5. Make sure that your phone is in Wi-Fi mode and not using the SIM card.\n" +
      "\n" +
      "6. Unplug your module from the power source, wait for 10 seconds, and then retry.",
  },
  {
    id: "privacy-policy",
    image: LogoHorizontal,
    title: translate("Auth.privacyPolicy"),
    heading: "PRIVACY POLICY",
    content:
      'GrowDirector Ltd. ("**GrowDirector**") is on of a kind grow controller that turns your plant interactive, interactive, and automated process. Growers of all kinds of crop, from vegetable and fruits to cannabis at any skill level, can easily control and manage their operations right mobile device using the all-in-one GrowDirector controller ( the "**Products**" )' +
      "\n\n By using GrowDirector Mobile Application or any of its Products services ( the **Mobile Application** )you contained in this Privacy Policy, which govern the processing of your personal data on the Mobile Application" +
      '\n\n We, GrowDirector (also "**We**", "**Our**", "**Us**") recommend that you print and keep a copy of this Privacy Policy.' +
      "\n\n This Privacy Policy sets out the essential details relating to your personal data relationship with Us. The Privacy Policy applies to all Our Products, services and any associated services. The terms governing your use of the Mobile Application, Products and services are defined in our Terms of Use." +
      "\n\n From time to time, we may develop new or offer additional Products and services. If the introduction of these new or additional Products and services results in any change to the way we collect or process your personal data we will provide you with more information and additional terms or policies. Unless stated otherwise when we introduce these new or additional Products and services, they will be subject to this Privacy Policy." +
      "\n\n The aim of this Policy is to: (i) ensure that you understand what personal data we collect about you, the reasons why we collect and use it, and who we share it with; (ii) explain the way we use the personal data that you share with us in order to give you a great experience when you are using the Mobile Application; and (iii) explain your rights and choices in relation to the personal data we collect and process about you and how we will protect your privacy." +
      "\n\n # 1. Collecting information on the Mobile Application" +
      "\n\n We collect your personal data in the following ways:" +
      "\n\n 1.1. Master data. When you create a profile on the Mobile Application, We collect only your email address. The purpose of the collection of this information is to identify you as user when you log on to the Mobile Application. We will not collect photos, precise mobile device location, voice data, or contacts from your device without your prior consent. You always will have the option to change your mind and withdraw your consent at any time." +
      "\n\n 1.2. General. Personal data collected that enables us to provide you with additional features/functionality - from time to time, you also may provide us with additional personal data or give us your permission to collect additional personal data e.g. to provide you with more features or functionality." +
      "\n\n 1.3. From third parties - We will receive personal data about you and your activity from third parties, including advertisers and partners we work with in order to provide you with the Our Products and services. We will use this personal data either where you have provided your consent to the third party or to Us to that data sharing taking place or where We have a legitimate interest to use the personal data in order to provide you with the Our Products and services; when you purchase Our app from the Apple Store or other applicable marketplaces, we may receive information from such platforms." +
      "\n\n 1.4. Purchasing. Our pricing structure may vary by country, subscription length and other in-app services (i.e., promotions). We may test new features and price points to improve the logistical Mobile Application. If you make a purchase, you will be asked to confirm your purchase by the payment provider and will be charged for the purchase by the set amount with your method of payment. Additional charges may apply, depending on the region you are purchasing from." +
      "\n\n 1.5. Subscription. If you purchase an auto-recurring periodic subscription through the purchase, your method of payment will continue to be billed for the subscription until you cancel." +
      "\n\n 1.6. Cookies. The Mobile Application uses cookies. Cookies are digital information which is stored on your computer. The cookie does not identify the individual user but the user&lsquo;s computer, and it used for different purposes. You can read more about what cookies the Mobile Application uses and for what purposes below." +
      "\n\n 1.7. IP addresses and browser settings. When visiting the Mobile Application, the IP address and the browser settings of your computer are registered. The IP address is the numerical address of the computer used when visiting the Mobile Application. Browser settings are e.g. the type of browser you use, browser language, time zone etc. The IP address and browser settings are registered to enable Us to trace the used computer in case of misuse or unlawful actions in connection with the visit on or use of the Mobile Application. Further, the IP address is used to derive your approximate location (city level)." +
      "\n\n 1.8. Behavioral Analytics. We may use your Personal information to provide you with directed advertisements, marketing, or collection of behavioral analytics for the interest of you and our company. We may use Google Analytics, Apple Analytics or other applicable applications to aid us in understanding how you use the Mobile Application. You can read more about how Google uses your Personal information at https://policies.google.com/privacy?hl=en, how Apple uses your Personal information at https://developer.apple.com/app-store/app-privacy-details/ and opt-out of Google Analytics at https://tools.google.com/dlpage/gaoptout and opt-out of Apple Analytics at https://support.apple.com/en-il/guide/mac-help/mh27990/mac; We may share information about your use of the Mobile Application, purchases and interaction with our advertising." +
      "\n\n 1.9. Maintain and Improve Our Services. We may use and collect your information to ensure our services are working as calculated, such as troubleshooting and tracking outages that are collected and/or reported. Additionally, we may use your information to make improvements to our services or develop new services for your benefit. We use also information we collect, like your email address, to interact with you directly. For example, we may send you a notification if we detect suspicious activity, like an attempt to sign in to your Google Account from an unusual location. Or we may let you know about upcoming changes or improvements to our services. And if you contact Google, we’ll keep a record of your request in order to help solve any issues you might be facing." +
      "\n\n 1.10. We do not collect other personal identifiable information from visitors of the Mobile Application, unless the visitor provides the information to us." +
      "\n\n # 2. Your rights and your preferences: Giving you choice and control" +
      '\n\n 2.1. You may be aware that a new European Union law, called the General Data Protection Regulation or "GDPR" gives certain rights to individuals in relation to their personal data. Accordingly, we have implemented additional transparency and access controls in our Privacy Center and Privacy Settings to help users take advantage of those rights. As available and except as limited under applicable law, the rights afforded to individuals are:' +
      "\n\n * Right of Access - the right to be informed of and request access to the personal data we process about you;" +
      "\n\n * Right to Rectification - the right to request that we amend or update your personal data where it is inaccurate or incomplete;" +
      "\n\n * Right to Erasure - the right to request that we delete your personal data;" +
      "\n\n * Right to Restrict - the right to request that we temporarily or permanently stop processing all or some of your personal data;" +
      "\n\n * Right to Object - the right, at any time, to object to us processing your personal data on grounds relating to your particular situation; the right to object to your personal data being processed or direct marketing purposes;" +
      "\n\n * Right to Data Portability - the right to request a copy of your personal data in electronic format and the right to transmit that personal data for use in another party’s service; and" +
      "\n\n * Right not to be subject to Automated Decision-making - the right to not be subject to a decision based solely on automated decision making, including profiling, where the decision would have a legal effect on you or produce a similarly significant effect." +
      "\n\n 2.2. In order to enable you to exercise these rights with ease and to record your preferences in relation to how We use your personal data, we provide you with access to the following settings via your Account Settings page:" +
      "\n\n * Privacy Settings - allows you to control some of the categories of personal data we process about you, enables you to access your personal data via a [Download my Data] button, and includes a link to the Privacy Center where you can find out more information about how We use your personal data and what your rights; and," +
      "\n\n * Notification Settings - allows you to choose which communications you receive from Us, manage your publicly available personal data, and set your sharing preferences." +
      "\n\n 2.3. The Privacy Center puts you in control of how We process your personal data. It provides you with information about what happens if you adjust your settings on your Account Settings page and how to opt out of receiving certain messages from Us. If we send you electronic marketing messages based on your consent or as otherwise permitted by applicable law, you may, at any time, respectively withdraw such consent or declare your objection (“opt-out”) at no cost. The electronic marketing messages you receive from Us (e.g. those sent via email) also will include an opt-out mechanism within the message itself (e.g. an unsubscribe link in the emails we send to you)." +
      "\n\n 2.4. If you have any questions about GDPR, your privacy, your rights, or how to exercise them, please contact our Data Protection Officer using the ‘Contact Us’ form on the Privacy Center. We will respond to your request within a reasonable period of time upon verification of your identity. If you are unhappy with the way we are using your personal data you can also contact and are free to lodge a complaint with your local Data Protection Authority." +
      "\n\n # 3. Disclosure of personal data" +
      "\n\n 3.1. Please note that your consent to these guidelines implies that your profile on the Mobile Application may not be anonymous." +
      "\n\n 3.2. We will share your personal data for activities such as statistical analysis and academic study but only in a pseudonymised format. We also may share your personal data in a pseudonymised format with our industry partners to help them understand how the content they license to us is performing. We also share your personal data in a pseudonymised format with marketing partners who help us with promotional efforts and with advertisers that allow us to offer a free service." +
      "\n\n 3.3. We may be required to disclose information by a court order from a competent court of law or public authority in accordance with the existing legislation." +
      "\n\n 3.4. We will share your personal data when we in good faith believe it is necessary for us to do so in order to comply with a legal obligation under applicable law, or respond to valid legal process, such as a search warrant, a court order, or a subpoena. We also will share your personal data where we in good faith believe that it is necessary for the purpose of our own, or a third party’s legitimate interest relating to national security, law enforcement, litigation, criminal investigation, protecting the safety of any person, or to prevent death or imminent bodily harm, provided that we deem that such interest is not overridden by your interests or fundamental rights and freedoms requiring the protection of your personal data." +
      "\n\n 3.5. We will share your personal data in those cases where we sell or negotiate to sell our business to a buyer or prospective buyer. In this situation, We will continue to ensure the confidentiality of your personal data and give you notice before your personal data is transferred to the buyer or becomes subject to a different Privacy Policy." +
      "\n\n # 4. Data controller" +
      "\n\n 4.1. We shall be the data controller of the master data you enter in connection with your creation of a profile on the Mobile Application. If you use a third party service to create an account, we will receive personal data via that third party service but only when you have consented to that third party service sharing your personal data with us." +
      "\n\n 4.2. The information for which you are the data controller. You shall be the data controller of the content you choose to disclose on the Mobile Application and for the data disclosed on your profile which is the consequence of the connection of your profile on the Mobile Application. " +
      "\n\n 4.3. Links to Mobile Applications. The Mobile Application contains links to other Mobile Applications. Note that by clicking such links you will be transferred to other Mobile Applications for which We are not the data controller. We thus recommend that you always read the personal data policy of these Mobile Applications, as their procedure for collecting and processing personal data may be different from Ours." +
      "\n\n # 5. Security measures" +
      "\n\n 5.1. We will use commercially reasonable efforts to see that the recorded data, including the personal information, under Our control is handled using appropriate security measures. We regularly audit the Mobile Application for possible vulnerabilities and attacks. However, since the internet is not a 100% secure environment, We cannot ensure or warrant the security of information you transmit to Us. Emails sent via the Mobile Application are not encrypted, and We therefore advise you not to communicate any confidential information through these means." +
      "\n\n 5.2. Depending on your account settings, your activity on the Mobile Application may be associated with your personal information in order to improve our services." +
      "\n\n # 6. Cookies" +
      "\n\n 6.1. The Mobile Application uses cookies and similar technologies. Cookies contain information that the Mobile Application uses to make the communication between you and your web browser more efficient. The cookie does not identify you as an individual user but identifies your computer. There are two types of cookies - session cookies and persistent cookies. Session cookies are temporary bits of information which are deleted when you exit your web browser. Persistent cookies are more permanent bits of information that are stored and remain on your computer until they are deleted. Persistent cookies delete themselves after a certain period of time but are renewed each time you visit the Mobile Application." +
      "\n\n 6.2. The Mobile Application uses session cookies and persistent cookies. We use similar technologies for storing and accessing information in the browser or device which utilizes local units and local storage, such as HTML 5 cookies, Flash and other methods. These technologies can operate across all your browsers. In some cases, the use of these technologies cannot be controlled by the browser but requires special tools. We use these technologies to store information to ensure the quality of reviews and to spot irregularities in the use of the Mobile Application." +
      "\n\n 6.3. We use cookies for:" +
      "\n\n * Statistics. Measuring Mobile Application traffic i.e. number of visits on the Mobile Application, which domains the visitors come from, which pages the visitors visit on the Mobile Application and in which overall geographical area the visitors are located." +
      "\n\n * Improvement of the functionalities of the Mobile Application: Optimizing your experience with the Mobile Application, which includes remembering your email when you return to the Mobile Application so you do not have to log in again." +
      "\n\n 6.4. Deletion of cookies. If you want to delete the cookies already on your equipment, you can, if you use a PC or a more recent browser, press CTRL + SHIFT + DELETE simultaneously. If the shortcuts do not work on your browser, please visit the support page for the browser in question. Please note that our Mobile Application will not work to its full extent if you delete the cookies." +
      "\n\n # 7. Access, correction and deletion" +
      "\n\n 7.1. Access. Upon written request to Us via the contact information stated herein, We shall inform you of the purpose of the processing; who receives the information and from where the information originates. Requests regarding the above shall only be processed if more than 6 months have lapsed since your previous request, unless you can substantiate a special interest in obtaining the information earlier." +
      "\n\n 7.2. Correction and deletion. If you discover that the information which We, as the data controller, process on you is incorrect or misleading, We shall make the correction hereof on your request. We recommend that you - if possible - make the correction of such errors yourself." +
      "\n\n 7.3. You may at any time correct or delete any content and information on the Mobile Application for which you are the data controller. If your personal data change, or if you no longer wish to appear on the Mobile Application, you may update or delete the information by logging on to your user profile." +
      "\n\n 7.4. When your profile is deleted, all the data associated with your user profile is deleted, including your master data and reviews on the Mobile Application." +
      "\n\n 7.5.  We reserves the right to block the access to your profile and/or delete your profile, if the profile or the content of your profile on the Mobile Application in our assessment is discriminating, racist, sexually oriented, unethical, threatening, offensive, harassing or in other ways violates the legislation, third party rights or the purpose of the Mobile Application. If we block the use of your profile, you shall be informed hereon, as well as on the reason for blocking the access, by email to the address you have stated in your profile." +
      "\n\n # 8.  Keeping your personal data safe" +
      "\n\n 8.1. We are committed to protecting our users’ personal data. We implement appropriate technical and organizational measures to help protect the security of your personal data; however, please note that no system is ever completely secure. We have implemented various policies including pseudonymisation, encryption, access, and retention policies to guard against unauthorized access and unnecessary retention of personal data in our systems." +
      "\n\n 8.2. Your password protects your user account, so we encourage you to use a unique and strong password, limit access to your computer and browser, and log out after having used the Mobile Application." +
      "\n\n 8.3. Personal data collected within the European Union may, for example, be transferred to and processed by third parties located in a country outside of the European Union. In such instances We shall ensure that the transfer of your personal data is carried out in accordance with applicable privacy laws and, in particular, that appropriate contractual, technical, and organizational measures are in place such as the Standard Contractual Clauses approved by the EU Commission." +
      "\n\n # 9. Changes in the privacy policy " +
      "\n\n We may at any time and without notice make changes to its privacy policy with future effect. We will make an effort to provide reasonable advance notice of any such changes. We shall inform the users of the Mobile Application of such changes when the users log on to the Mobile Application. You agree that the continued use of the Mobile Application after any posted modified version of the Privacy Policy is your acceptance of the modified Privacy Policy." +
      "\n\n # 10. Inquiries regarding the Mobile Application or the privacy policy" +
      "\n\n In case of inquiries regarding Our privacy policy, our processing of personal data, correction of data or your relations to us in general, you are welcome to contact Us by email." +
      "\n\n ************",
  },
  {
    id: "terms-of-use",
    image: LogoHorizontal,
    title: translate("Auth.userAgreement"),
    heading: "TERMS OF USE",
    content:
      " GrowDirector Ltd. (**GrowDirector**) one of a kind grow controller that turns your plant cultivation into a fully interactive, and automated process. Growers of all kinds of crop, from vegetable and fruits to cannabis at any skill level, can easily control and manage their operations right from their mobile device using the all-in-one GrowDirector controller **Product**)." +
      "\n\n This Terms of Use is a legal agreement between you (either an individual or a single entity, also referred herein as **you**) and GrowDirector regarding the use of GrowDirector mobile application (**Mobile Application**) and any of its Products or services. The terms also apply to any updates, supplements, web content or Internet-based services, such as remote remote access." +
      "\n\n **BY USING OR ACCESSING THE MOBILE APPLICATION AND ANY USE OF GROWDIRECTOR PRODUCTS OR SERVICES, YOU ACCEPT THESE TERMS. IF YOU DO NOT ACCEPT THESE TERMS, DO NOT USE THE PRODUCTS OR SERVICES AND/OR ACCESS THE MOBILE APPLICATION. IF YOU COMPLY WITH THIS TERMS OF USE, YOU HAVE THE RIGHTS TO USE THE PRODUCTS OR SERVICES AND/OR ACCESS THE MOBILE APPLICATION AS SPECIFICALLY SET FORTH BELOW.**" +
      "\n\n For the avoidance of doubt, you need to do your due diligence to make sure that the Mobile Application, the Products or services are compliant with your country laws and regulation – this also reduces the risk for having to recall a Product in the future." +
      "\n\n GrowDirector reserves the right, at its sole discretion, to change, modify, add or remove portions of this Terms of Use, at any time. It is your responsibility to check this Terms of Use periodically for any changes. Your continued use of the Mobile Application and any Product or service following the posting of changes will mean that you accept and agree to such changes. As long as you comply with this Terms of Use, GrowDirector grants you a personal, non-exclusive, non-transferable, limited privilege to enter the enter the Mobile Application and the use of Products and/or services." +
      "\n\n **Accounts and membership**" +
      "\n\n If you create an account in the Mobile Application, you are responsible for maintaining the security of your account and you are fully responsible for all activities that occur under the account and any other actions taken in connection with it." +
      "\n\n We may monitor and review new accounts before you may sign in and use GrowDirector services. Providing false contact information of any kind may result in the termination of your account. You must immediately notify us of any unauthorized uses of your account or any other breaches of security. We will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions. We may suspend, disable, or delete your account (or any part thereof) if we determine that you have violated any provision of this Agreement or that your conduct or content would tend to damage our reputation and goodwill. If we delete your account for the foregoing reasons, you may not re-register for GrowDirector services. We may block your email address and Internet protocol address to prevent further registration." +
      "\n\n **Content**" +
      '\n\n All text, graphics, user interfaces, visual interfaces, photographs, trademarks, logos, sounds, artwork and computer code (collectively, **Content**), including but not limited to the design, structure, selection, coordination, expression, "look and feel" and arrangement of such Content, contained on the Mobile Application and/or in the Products or services are owned, controlled or licensed by or to GrowDirector, and are protected by trade dress, copyright, patent and trademark laws, and various other intellectual property rights and unfair competition laws.' +
      '\n\n Except as expressly provided in this Terms of Use, no part of the Mobile Application, Products, services or Content may be copied, reproduced, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted or distributed in any way (including "mirroring") to any other computer, server, mobile application or other medium for publication or distribution or for any commercial enterprise, without GrowDirector’s express prior written consent.' +
      "\n\n GrowDirector do not own any data, information, material or Content that you submit in the Mobile Application in the course of using Products or services. You shall have sole responsibility for the accuracy, quality, integrity, legality, reliability, appropriateness, and intellectual property ownership or right to of all submitted Content. GrowDirector may, but have no obligation obligation to, monitor and review Content in the Mobile submitted or created using GrowDirector Products and services by you. Unless specifically permitted by you, your use of the Mobile Application and/or Products or services does not grant us the us the license to use, reproduce, adapt, modify, publish or distribute the Mobile Application and/or Products or services or any Content created by you or stored in your user account for commercial, marketing or any similar purpose. But you grant us permission to access, copy, distribute, store, transmit, reformat, display and perform the Content of your user account solely as required for the purpose of providing the services to you." +
      "\n\n For the avoidance of doubt, GrowDirector is not responsible for Content residing in the Mobile Application. In no event shall GrowDirector be held liable for any loss of any Content. It is sole responsibility to maintain appropriate backup of your Content. Content. Notwithstanding the foregoing, on some occasions and in certain circumstances, with absolutely no obligation, we may be able to restore some or all of your data that has been deleted as of a certain date and time when we may have backed up data for our own own purposes. We make no guarantee that the data you need will be available." +
      "\n\n **Intellectual property rights**" +
      "\n\n This Agreement does not transfer to you any intellectual property owned by GrowDirector or third-parties, and all rights, titles, and interests in and to such property will remain (as between the parties) solely with GrowDirector. All trademarks, Product marks, graphics and logos used in connection with GrowDirector Mobile Application and/or Products or services, are trademarks or registered trademarks of GrowDirector or GrowDirector’s licensors. Other trademarks, Product marks, graphics and logos used in connection with GrowDirector Mobile Application and/or Products or services may be the trademarks of other third-parties. Your use of our Mobile Application and/or Products or services grants you no right or license to reproduce or otherwise use any GrowDirector or third-party trademarks." +
      "\n\n **Your Use of the Mobile Application**" +
      '\n\n You may not use any "deep-link", "page-scrape","robot", "spider" or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Mobile Application or any Content, or in any way reproduce or circumvent the navigational structure or presentation of the Mobile Application or any Content, to obtain or attempt to obtain any materials, documents or information through any means not purposely made available through the Mobile Application. GrowDirector reserves the right to bar any such activity. You may not attempt to gain unauthorized access to any portion or feature of the Mobile Application, or any other systems or networks connected to the Mobile Application or to any GrowDirector server, or to any of the services offered on or through the Mobile Application, by hacking, "mining" or any other illegitimate means.' +
      "\n\n You may not probe, scan or test the vulnerability of the Mobile Application or any network connected to the Mobile Application, nor breach the security or authentication measures on the Mobile Application or any network connected to the Mobile Application. You may not reverse look-up, trace or seek to trace any information on any other user of or visitor to the Mobile Application, or any other customer of GrowDirector, including any GrowDirector account not owned by you, to its source, or exploit the Mobile Application or any Product or information made available or offered by or through the Mobile Application, in any way where the purpose is to reveal any information, including but not limited to personal identification or information, other than your own information, as provided for by the Mobile Application." +
      "\n\n You agree that you will not take any action that imposes an unreasonable or disproportionately large load on the infrastructure of the Mobile Application or GrowDirector’s systems or networks, or any systems or networks connected to the Mobile Application or to GrowDirector." +
      "\n\n You agree not to use any device, software or routine to interfereor attempt to interfere with the proper working of the Mobile Application or any transaction being conducted on the Mobile Application, or with any other person’s use of the Mobile Application." +
      "\n\n You may not forge headers or otherwise manipulate identifiers in order to disguise the origin of any message or transmittal you send to GrowDirector on or through the Mobile Application or any Product offered on or through the Mobile Application. You may not pretend that you are, or that you represent, someone else, or impersonate any other individual or entity." +
      "\n\n You may not use the Mobile Application or any Content for any purpose that is unlawful or prohibited by this Terms of Use, or to solicit the performance of any illegal activity or other activity which infringes the rights of GrowDirector or others." +
      "\n\n **Purchases; Other Terms and Conditions**" +
      "\n\n Additional terms and conditions may apply to purchases of goods or services and to specific portions or features of the Mobile Application, including contests, promotions or other similar features, all of which terms are made a part of this Terms of Use by this reference. You agree to abide by such other terms and conditions, including where applicable representing that you are of sufficient legal age to use or participate in such service or feature. If there is a conflict between this Terms of Use and the terms posted for or applicable to a specific portion of the Mobile Application or for any service offered on or through the Mobile Application, the latter terms shall control with respect to your use of that portion of the Mobile Application or the Products." +
      "\n\n GrowDirector’s obligations, if any, with regard to its Products and services are governed solely by the agreements pursuant to which they are provided, and nothing on this Mobile Application should be construed to alter such agreements." +
      "\n\n GrowDirector may make changes to any Products or services offered on the Mobile Application, or to the applicable prices for any such Products or services, at any time, without notice. The materials on the Mobile Application with respect to Products and services may be out of date, and GrowDirector makes no commitment to update the materials on the Mobile Application with respect to such Products and services." +
      "\n\n **Accounts, Passwords and Security**" +
      "\n\n Certain features or services offered on or through the Mobile Application may require you to open an account (including setting up a User ID and password). You are entirely responsible for maintaining the confidentiality of the information you hold for your account, including your password, and for any and all activity that occurs under your account as a result of your failing to keep this information secure and confidential. You agree to notify GrowDirector immediately of any unauthorized use of your account or password, or any other breach of security. You may be held liable for losses incurred by GrowDirector or any other user of or visitor to the Mobile Application due to someone else using your User ID, password or account as a result of your failing to keep your account information secure and confidential." +
      "\n\n You may not use anyone else’s User ID, password or account at any time without the express permission and consent of the holder of that User ID, password or account. GrowDirector cannot and will not be liable for any loss or damage arising from your failure to comply with these obligations." +
      "\n\n **Privacy**" +
      "\n\n GrowDirector’s Privacy Policy applies to use of this Mobile Application, and its terms are made a part of this Terms of Use by this reference. Additionally, by using the Mobile Application and/or Products or services, you acknowledge and agree that Internet transmissions are never completely private or secure. You understand that any message or information you send to the Mobile Application may be read or intercepted by others, even if there is a special notice that a particular transmission (for example, credit card information) is encrypted." +
      "\n\n **Links to Other Sites and to the Mobile Application**" +
      '\n\n Mobile Application may contain links to other independent third-party Mobile Applications ("**Linked Sites**"). These Linked Sites are provided solely as a convenience to our visitors. Such Linked Sites are not under GrowDirector’s control, and GrowDirector is not responsible for and does not endorse the content of such Linked Sites, including any information or materials contained on such Linked Sites. You will need to make your own independent judgment regarding your interaction with these Linked Sites.' +
      "\n\n GrowDirector is not, directly or indirectly, implying any approval, association, sponsorship, endorsement, or affiliation with any linked mobile application, unless specifically stated herein. GrowDirector is not responsible for examining or evaluating, and we do not warrant the offerings of, any businesses or individuals or the content of their mobile applications. GrowDirector do not assume any responsibility or liability for the actions, Products, services, and content of any other third-parties. You should carefully review the legal statements and other conditions of use of any mobile application which you access through a link from this Mobile Application. Your linking to any other off-site mobile applications is at your own risk." +
      "\n\n **Disclaimers**" +
      '\n\n GROWDIRECTOR DOES NOT PROMISE THAT THE MOBILE APPLICATION OR ANY CONTENT, PRODUCT, SERVICE OR FEATURE OF THE MOBILE APPLICATION WILL BE ERROR-FREE OR UNINTERRUPTED, OR THAT ANY DEFECTS WILL BE CORRECTED, OR THAT YOUR USE OF THE MOBILE APPLICATION WILL PROVIDE SPECIFIC RESULTS. THE MOBILE APPLICATION AND ITS CONTENT ARE DELIVERED ON AN "AS-IS" AND "AS-AVAILABLE" BASIS. ALL INFORMATION PROVIDED ON THE MOBILE APPLICATION OR ANY CONTENT, PRODUCT, SERVICE OR FEATURE OF THE MOBILE APPLICATION IS SUBJECT TO CHANGE WITHOUT NOTICE. GROWDIRECTOR CANNOT ENSURE THAT ANY FILES OR OTHER DATA YOU DOWNLOAD FROM THE MOBILE APPLICATION WILL BE FREE OF VIRUSES OR CONTAMINATION OR DESTRUCTIVE FEATURES. GROWDIRECTOR DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY WARRANTIES OF ACCURACY, NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. GROWDIRECTOR DISCLAIMS ANY AND ALL LIABILITY FOR THE ACTS, OMISSIONS AND CONDUCT OF ANY THIRD PARTIES (INCLUDING DISTRIBUTORS, AGENTS, AFFILIATES AND ETC.), IN CONNECTION WITH OR RELATED TO YOUR USE OF THE MOBILE APPLICATION AND/OR ANY GROWDIRECTOR PRODUCT, SERVICES. YOU ASSUME TOTAL RESPONSIBILITY FOR YOUR USE OF THE MOBILE APPLICATION AND ANY CONTENT, PRODUCT, SERVICE OR FEATURE OF THE MOBILE APPLICATION (INCLUDING LINKED SITES). YOUR SOLE REMEDY AGAINST GROWDIRECTOR FOR DISSATISFACTION WITH THE MOBILE APPLICATION OR ANY PRODUCT OR SERVICE IS TO STOP USING THE MOBILE APPLICATION OR ANY PRODUCT OR SERVICE. THIS LIMITATION OF RELIEF IS A PART OF THE BARGAIN BETWEEN THE PARTIES.' +
      "\n\n The above disclaimer applies to any damages, liability or injuries caused by any failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communication line failure, theft or destruction of or unauthorized access to, alteration of, or use, whether for breach of contract, tort, negligence or any other cause of action." +
      "\n\n GrowDirector reserves the right to do any of the following, at any time, without notice: (1) to modify, suspend or terminate operation of or access to the Mobile Application, or any portion of the Mobile Application, for any reason; (2) to modify or change the Mobile Application, or any portion of the Mobile Application, and any applicable policies or terms; and (3) to interrupt the operation of the Mobile Application, or any portion of the Mobile Application, as necessary to perform routine or non-routine maintenance, error correction, or other changes." +
      "\n\n **Limitation of Liability**" +
      "\n\n Except where prohibited by law, in no event will GrowDirector be liable to you for any indirect, consequential, exemplary, incidental or punitive damages, including lost profits, even if GrowDirector has been advised of the possibility of such damages." +
      "\n\n To the fullest extent permitted by applicable law, in no event will GrowDirector, its affiliates, officers, directors, employees, agents, suppliers or licensors be liable to any person for (a): any indirect, incidental, special, punitive, cover or consequential damages (including, without limitation, damages for lost profits, revenue, sales, goodwill, use or content, impact on business, business interruption, loss of anticipated savings, loss of business opportunity) however caused, under any theory of liability, including, without limitation, contract, tort, warranty, breach of statutory duty, negligence or otherwise, even if GrowDirector has been advised as to the possibility of such damages or could have foreseen such damages. To the maximum extent permitted by applicable law, the aggregate liability of GrowDirector and its affiliates, officers, employees, agents, suppliers and licensors, relating to the services will be limited to an amount greater of one dollar or any amounts actually paid in cash by you to GrowDirector for the prior one month period prior to the first event or occurrence giving rise to such liability. The limitations and exclusions also apply if this remedy does not fully compensate you for any losses or fails of its essential purpose." +
      "\n\n **Indemnity**" +
      "\n\n You agree to indemnify and hold GrowDirector, its officers, directors, shareholders, predecessors, successors in interest, employees, agents, subsidiaries and affiliates, harmless from any demands, loss, liability, claims or expenses (including attorneys’ fees), made against GrowDirector by any third party due to or arising out of or in connection with your use of the Mobile Application." +
      "\n\n **Violation of This Terms of Use**" +
      "\n\n GrowDirector may disclose any information it has about you (including your identity) if GrowDirector determines that such disclosure is necessary in connection with any investigation or complaint regarding your use of the Mobile Application, or to identify, contact or bring legal action against someone who may be causing injury to or interference with (either intentionally or unintentionally) GrowDirector’s rights or property, or the rights or property of visitors to or users of the Mobile Application. GrowDirector reserves the right at all times to disclose any information that GrowDirector deems necessary to comply with any applicable law, regulation, legal process or governmental request. GrowDirector also may disclose your information when GrowDirector determines that applicable law requires or permits such disclosure, including exchanging information with other companies and organizations for fraud protection purposes." +
      "\n\n You acknowledge and agree that GrowDirector may preserve any transmittal or communication by you with GrowDirector through the Mobile Application or any Product offered on or through the Mobile Application, and may also disclose such data if required to do so by law or GrowDirector determines that such preservation or disclosure is reasonably necessary to (1) comply with legal process, (2) enforce this Terms of Use, (3) respond to claims that any such data violates the rights of others, or (4) protect the rights, property or personal safety of GrowDirector, its employees, users of or visitors to the Mobile Application, and the public." +
      "\n\n You agree that GrowDirector may, in its sole discretion and without prior notice, terminate your access to the Mobile Application and/or block your future access to the Mobile Application if we determine that you have violated this Terms of Use or other agreements or guidelines which may be associated with your use of the Mobile Application. You also agree that any violation by you of this Terms of Use will constitute an unlawful and unfair business practice, and will cause irreparable harm to GrowDirector, for which monetary damages would be inadequate, and you consent to GrowDirector obtaining any injunctive or equitable relief that GrowDirector deems necessary or appropriate in such circumstances. These remedies are in addition to any other remedies GrowDirector may have at law or in equity." +
      "\n\n You agree that GrowDirector may, in its sole discretion and without prior notice, terminate your access to the Mobile Application, for cause, which includes (but is not limited to) (1) requests by law enforcement or other government agencies, (2) a request by you (self-initiated account deletions), (3) discontinuance or material modification of the Mobile Application or any Product offered on or through the Mobile Application, or (4) unexpected technical issues or problems." +
      "\n\n If GrowDirector does take any legal action against you as a result of your violation of this Terms of Use, GrowDirector will be entitled to recover from you, and you agree to pay, all reasonable attorneys’ fees and costs of such action, in addition to any other relief granted to GrowDirector. You agree that GrowDirector will not be liable to you or to any third party for termination of your access to the Mobile Application as a result of any violation of this Terms of Use." +
      "\n\n **Governing Law**" +
      "\n\n This Terms of Use and the Privacy Policy shall be solely governed by the Laws of the State of Israel, and any dispute arising from, or in relation with this Terms of Use, shall be subject to the exclusive jurisdiction of the competent courts of Tel-Aviv, and each of the parties hereby irrevocably submits to the jurisdiction of such jurisdiction." +
      "\n\n **Miscellaneous**" +
      "\n\n You may not use or export or re-export any Products or services or Content or any copy or adaptation of such, offered on the Mobile Application, in violation of any applicable laws or regulations, including without limitation United States export laws and regulations." +
      "\n\n If any of the provisions of this Terms of Use are held by a court or other tribunal of competent jurisdiction to be void or unenforceable, such provisions shall be limited or eliminated to the minimum extent necessary and replaced with a valid provision that best embodies the intent of this Terms of Use, so that this Terms of Use shall remain in full force and effect. This Terms of Use constitute the entire agreement between you and GrowDirector with regard to your use of the Mobile Application, and any and all other written or oral agreements or understandings previously existing between you and GrowDirector with respect to such use are hereby superseded and cancelled. Other than as provided in a purchase agreement you enter into with GrowDirector, GrowDirector will not accept any counter-offers to this Terms of Use, and all such offers are hereby categorically rejected. GrowDirector’s failure to insist on or enforce strict performance of this Terms of Use shall not be construed as a waiver by GrowDirector of any provision or any right it has to enforce thus Terms of Use, nor shall any course of conduct between GrowDirector and you or any other party be deemed to modify any provision of this Terms of Use. This Terms of Use shall not be interpreted or construed to confer any rights or remedies on any third parties." +
      "\n\n GrowDirector provides access to GrowDirector international data and, therefore, may contain references or cross references to GrowDirector Products and services that are not announced in your country. Such reference does not imply that GrowDirector in your country intends to announce such Products, programs or services." +
      "\n\n **Language**. The parties hereto confirm their express wish that this Agreement, as well as all other documents related hereto, including notices, be drawn up in the English language only and declare themselves satisfied therewith." +
      "\n\n ************",
  },
  {
    id: `disclaimer-devise-${ModuleType.DryContact}`,
    title: "Technical Warning",
    content:
      "**Technical Warning: Handling and Connecting DryContact Director Device**" +
      "\n\n **Disclaimer:** This information is provided to ensure safe handling and installation of the DryContact Director device. Adherence to these instructions is mandatory to prevent injury, loss of life, and damage to equipment." +
      "\n\n **Safety Precautions:**" +
      "\n\n 1. **Authorized Installation:** Installation of the DryContact Director device in circuits using 120Vac/60Hz or 230Vac/50Hz must be performed by authorized and certified electricians. Failure to do so may result in hazards, including electric shock." +
      "\n\n 2. **Circuit Protection:** When used in circuits with 120Vac/60Hz or 230Vac/50Hz power sources, an external 1-Pole circuit breaker rated 10A and certified according to IEC 60947-2 (or a Listed branch circuit protective circuit breaker in US/Canada) must be used for overcurrent protection and mains disconnection." +
      "\n\n 3. **Environmental Considerations:** The DryContact Director device is designed for indoor use within the temperature range of 0-50°C (32-122°F) and a maximum relative humidity of 90%, non-condensing. Install the device in an environment that meets these conditions." +
      "\n\n 4. **Electromagnetic Compatibility:** The DryContact Director device complies with electromagnetic radiation limits specified by the Federal Communications Commission (FCC). Keep the device away from electromagnetic interference sources." +
      "\n\n 5. **CE Mark:** The device meets the requirements of applicable EU directives. Disposal must adhere to the Waste Electrical and Electronic Equipment directive; do not dispose of the device in household waste." +
      "\n\n 6. **User-Serviceable Parts:** The DryContact Director device contains no user-serviceable parts. Do not attempt to open, modify, disassemble, or repair any component of the device." +
      "\n\n **Connection and Installation:**" +
      "\n\n 1. **Load Connection:** Connect the DryContact Director device to the controlled load using the provided terminal block plug and wire fixing screws. Follow the current capacity requirements specified in the technical specification datasheet." +
      "\n\n 2. **Power Source:** Connect the device to a power source and ensure it is within 10 meters (30 feet) of the module during setup." +
      "\n\n 3. **Wi-Fi Compatibility:** The DryContact Director device requires a Wi-Fi network that supports the 2.4 GHz frequency for connection. Verify your router's compatibility before proceeding." +
      "\n\n 4. **Module Naming:** If renaming the module, follow the provided steps in the documentation. Enter the new name in the designated field and confirm the renaming process." +
      "\n\n 5. **Module Deletion:** To delete a module, first remove connected devices, sensors, and associated rules. Follow the provided steps to initiate the deletion process." +
      "\n\n Failure to comply with these safety instructions and precautions may lead to hazardous situations, equipment damage, and non-compliance with regulatory requirements. For additional support or questions, contact Grow Director Ltd. through WhatsApp at [+972 51 284 7070](tel:+972512847070) or via email at [contact@growdirector.com](mailto:contact@growdirector.com)." +
      "\n\n Remember, the wording and details of this warning may need to be reviewed and adjusted based on specific legal and regulatory requirements in both the US and EU regions. It's also important to ensure that this warning is provided in the appropriate context within your documentation.",
  },
  {
    id: `disclaimer-devise-${ModuleType.Hydro}`,
    title: "Technical Warning",
    content:
      "**HydroDirector Module User Agreement and Disclaimer**" +
      "\n\n *Thank you for choosing our HydroDirector module. We appreciate your trust in our product, and we are confident that your experience will be enhanced with the efficient and extended functionality provided by our module.*" +
      "\n\n **Usage Guidelines:**" +
      "\n\n 1. **Module Overview:**" +
      "\n\n * The HydroDirector module consists of two sides: the pump side and the adaptor side." +
      "\n\n * Adaptor Side Components:" +
      "\n\n   * 24V Adaptor" +
      "\n\n   * pH Adaptor" +
      "\n\n   * EC & Water Temperature Adaptor" +
      "\n\n   * DO/ORP Adaptor" +
      "\n\n   * Internet Connection Button" +
      "\n\n 2. **Installation Instructions:**" +
      "\n\n * Before plugging in the sensors, ensure the electricity is unplugged." +
      "\n\n * Connect sensors securely before restoring electricity." +
      "\n\n * For Internet connection, press the button for 15-20 seconds or until the green light blinks." +
      "\n\n 3. **Pump Side Features:**" +
      "\n\n * Three peristaltic pumps are located on the front side of the module." +
      "\n\n * Each pump can deliver up to 12 liters per hour (4 gallons)." +
      "\n\n * These pumps are designed for use with water and liquids." +
      "\n\n 4. **Acid Pumping Precautions:**" +
      "\n\n * When pumping acids, dilute them at a ratio of 1:10 (1 part acid to 10 parts water)." +
      "\n\n * The density of acid can harm the pump and impact its performance." +
      "\n\n * Dilution helps prevent damage and ensures optimal pump functionality." +
      "\n\n 5. **Monthly Maintenance:**" +
      "\n\n * It is highly recommended to clean the pumps once a month." +
      "\n\n * Clean the pumps by pumping clear water for 2 minutes to clear the inner pipes." +
      "\n\n **Disclaimer:**" +
      "\n\n * The HydroDirector module is intended for use as described in the provided instructions." +
      "\n\n **HydroDirector Module User Agreement and Disclaimer**" +
      "\n\n * Improper use, neglect, or failure to follow instructions may result in damage to the module and void the warranty." +
      "\n\n * The user is responsible for ensuring a safe and appropriate environment for module operation." +
      "\n\n * The HydroDirector module requires a stable and robust internet connection within the 2.4-4G range. It is the client's responsibility to ensure their internet connection is steady and strong." +
      "\n\n * We are not liable for any damages, injury, or loss arising from the misuse or neglect of the HydroDirector module." +
      "\n\n * Any modifications to the module without prior approval may void the warranty." +
      "\n\n * GrowDirector is not obligated to replace any module that has been damaged due to incorrect use or improper installation." +
      "\n\n *By using the HydroDirector module, you agree to comply with the terms and guidelines outlined in this document. Please read and understand these instructions thoroughly for a seamless experience with our product.*" +
      "\n\n *Thank you for choosing our HydroDirector module. We value your satisfaction and are here to assist you with any questions or concerns.*",
  },
];
