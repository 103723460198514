import React from "react";
import { G, Line as SvgLine, Text as SvgText } from "react-native-svg";

import { apx } from "@components/Chart/utils";
import { ChartLineData } from "@components/Chart";

import { palette } from "../../../theme";

const LABEL_OFFSET = 4;

type VerticallyLineValueProps = {
  x: number;
  color: string;
  label?: string;
  height: number;
  offsetScaleY: number;
};
const VerticallyLineValue = ({
  x,
  color,
  label,
  height,
  offsetScaleY,
}: VerticallyLineValueProps) => {
  return (
    <>
      <G x={x} y={0}>
        <SvgLine
          y1={offsetScaleY - 3}
          y2={height - offsetScaleY + 3}
          stroke={color}
          strokeWidth={apx(3)}
          strokeDasharray={[6, 3]}
        />
      </G>
      {label ? (
        <G x={x} y={10}>
          <SvgText fill={color} fontSize={12} fontWeight="bold">
            {label}
          </SvgText>
        </G>
      ) : null}
    </>
  );
};

type HorizontalLineValueProps = {
  y: number;
  color: string;
  label?: string;
  xOffset: number;
  width: number;
};
const HorizontalLineValue = ({
  y,
  color,
  label,
  xOffset,
  width,
}: HorizontalLineValueProps) => {
  return (
    <>
      <G x={0} y={y}>
        <SvgLine
          x1={xOffset}
          x2={width}
          stroke={color}
          strokeWidth={apx(2)}
          strokeDasharray={[6, 3]}
        />
      </G>
      {label ? (
        <G x={width - (label.length * 6 + LABEL_OFFSET)} y={y - 4}>
          <SvgText fill={color} fontSize={12} fontWeight="bold">
            {label}
          </SvgText>
        </G>
      ) : null}
    </>
  );
};

type AdditionalLinesProps = {
  offsetScaleY: number;
  lines: ChartLineData[];
  width?: number;
  height?: number;
  x?: (y: number) => number;
  y?: (x: number) => number;
  xOffset?: number;
};

export const AdditionalLines = ({
  lines,
  offsetScaleY,
  width,
  height,
  y,
  x,
  xOffset,
}: AdditionalLinesProps) => {
  return lines.map((lineData, index) => {
    if (lineData?.isVertically) {
      return (
        <VerticallyLineValue
          key={`chart-line-${index}`}
          color={lineData.color || palette.PurpleG1}
          label={lineData.label}
          x={x(lineData.value)}
          height={height}
          offsetScaleY={offsetScaleY}
        />
      );
    }

    return (
      <HorizontalLineValue
        key={`chart-line-${index}`}
        color={lineData.color || palette.RedNew}
        label={lineData.label}
        y={y(lineData.value)}
        width={width}
        xOffset={xOffset}
      />
    );
  });
};
