import * as React from "react";
import Svg, { Path } from "react-native-svg";

import { defaultColors } from "../theme";

export default function ReleaseNotesIcon({
  size = 24,
  color = defaultColors.text,
}) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.17732 5.31983C8.88443 5.61272 8.88443 6.0876 9.17732 6.38049C9.47021 6.67338 9.94509 6.67338 10.238 6.38049L11.2199 5.39861V8.66072C11.2199 9.07493 11.5556 9.41072 11.9699 9.41072C12.3841 9.41072 12.7199 9.07493 12.7199 8.66072V5.39934L13.701 6.38049C13.9939 6.67338 14.4688 6.67338 14.7617 6.38049C15.0546 6.0876 15.0546 5.61272 14.7617 5.31983L12.4998 3.05799C12.2069 2.76509 11.7321 2.76509 11.4392 3.05799L9.17732 5.31983ZM9.89389 10.0762C9.75323 9.93555 9.56247 9.85654 9.36356 9.85654H2.50973C2.20638 9.85654 1.9329 10.0393 1.81682 10.3195C1.70073 10.5998 1.7649 10.9224 1.9794 11.1369L4.39581 13.5533V20.0965C4.39581 20.4257 4.61056 20.7165 4.92525 20.8133L11.777 22.9215L11.2496 20.0666V20.0584L11.7791 22.9222C11.9213 22.9659 12.0711 22.9659 12.2103 22.9251L12.2223 22.9215L19.074 20.8133C19.3887 20.7165 19.6035 20.4257 19.6035 20.0965V13.5533L22.0199 11.1369C22.2344 10.9224 22.2986 10.5998 22.1825 10.3195C22.0664 10.0393 21.7929 9.85654 21.4896 9.85654H14.6357C14.4368 9.85654 14.2461 9.93555 14.1054 10.0762L11.9996 12.182L9.89389 10.0762ZM11.2496 20.0584L10.1587 14.1573H11.2496V20.0584ZM11.2496 20.0666V21.1899L5.89581 19.5425V14.1573H10.158L11.2496 20.0666ZM9.77423 12.0779L9.36356 9.85654L9.77382 12.0775L9.0529 11.3565H4.32039L5.62117 12.6573H9.88094L9.77382 12.0775L9.77423 12.0779ZM9.77423 12.0779L9.88135 12.6573H10.3537L9.77423 12.0779ZM12.7496 14.1573H16.9206C17.3348 14.1573 17.6706 13.8215 17.6706 13.4073C17.6706 12.9931 17.3348 12.6573 16.9206 12.6573H13.6456L14.9464 11.3565H19.6789L18.3231 12.7123C18.1825 12.8529 18.1035 13.0437 18.1035 13.2426V19.5425L12.7496 21.1899V14.1573Z"
        fill={color}
      />
    </Svg>
  );
}
