import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { palette } from "../theme";

const MAX_LEVEL = 4;

export default function WiFiIcon({
  size = 24,
  color = palette.Black,
  bgColor = palette.Disable2,
  level = MAX_LEVEL,
}) {
  let levelValue = level;
  if (levelValue < 0) levelValue = 0;
  if (levelValue > MAX_LEVEL) levelValue = MAX_LEVEL;

  const defaultColors = new Array(MAX_LEVEL - levelValue).fill(bgColor);
  const valueColors = new Array(levelValue).fill(color);
  const [colorPoint, colorLevel1, colorLevel2, colorLevel3] = [
    ...valueColors,
    ...defaultColors,
  ];

  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M12 18H12.01"
        stroke={colorPoint}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M9.17188 15.1711C9.92199 14.4213 10.9392 14 11.9999 14C13.0605 14 14.0778 14.4213 14.8279 15.1711"
        stroke={colorLevel1}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M6.34277 12.3433C7.08565 11.6004 7.96758 11.0111 8.93821 10.609C9.90884 10.2069 10.9492 10 11.9998 10C13.0504 10 14.0907 10.2069 15.0613 10.609C16.032 11.0111 16.9139 11.6004 17.6568 12.3433"
        stroke={colorLevel2}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M3.51465 9.51525C8.20065 4.82825 15.7986 4.82825 20.5146 9.51525"
        stroke={colorLevel3}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
