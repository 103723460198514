import React from "react";
import { View, Dimensions, StyleSheet } from "react-native";

import HistorySkeletonSVG from "../../../../svgs/pictures/HistorySkeleton";
import { palette, spacing } from "../../../../theme";

const styles = StyleSheet.create({
  container: {
    backgroundColor: palette.BlueBackground,
    borderRadius: spacing[2],
    paddingLeft: spacing[1],
    paddingTop: spacing[1],
    marginBottom: spacing[3],
    height: 111,
  },
});

export const HistorySkeleton = () => {
  return (
    <View style={styles.container}>
      <HistorySkeletonSVG width={Dimensions.get("window").width - 28} />
    </View>
  );
};
