import React, { useMemo, useState } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import { DimmerRuleData, Rule } from "@models/rule/rule";
import { useStores } from "@models/index";
import { CustomSelectInput, TextInput } from "@components/inputs";

import { spacing } from "../../../../../../theme";
import { translate } from "../../../../../../i18n";
import { useAppTheme } from "../../../../../../hooks";
import { stringToNumber } from "../../../../../../utils/numbers";
import * as SensorIcons from "../../../../../../svgs/sensors";
import { SensorSelector } from "../SensorSelector";
import { SwitchBlock } from "../SwitchBlock";

const createStyles = (theme: any) =>
  StyleSheet.create({
    container: {
      marginBottom: spacing[4],
      padding: spacing[4],
      paddingBottom: spacing[3],
      borderRadius: spacing[4],
      borderWidth: 1,
      borderColor: theme.colors.menuBorder,
    },
    firstInput: {
      marginTop: spacing[2],
    },
    columns: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginHorizontal: -spacing[2],
    },
    column: {
      flex: 1,
      marginHorizontal: spacing[2],
    },
  });

type Props = {
  rule?: Rule;
  formData?: DimmerRuleData | DimmerRuleData;
  changeFormData?: (data: DimmerRuleData | DimmerRuleData) => void;
  errors?: Record<string, string | undefined>;
  style?: StyleProp<ViewStyle>;
  disabled?: boolean;
  isOpened?: boolean;
};

export const OverheatPreventionInput = ({
  rule,
  formData,
  changeFormData = () => null,
  errors,
  style,
  disabled = false,
  isOpened = false,
}: Props) => {
  const theme = useAppTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);
  const { sensorStore } = useStores();

  const isOpen = Boolean(
    (formData?.controlSensorUid && formData?.controlSensorValue) || isOpened,
  );

  const [isSensorSelector, setSensorSelector] = useState<boolean>(false);
  const openSensorSelector = () => {
    if (!disabled) {
      setSensorSelector(true);
    }
  };
  const closeSensorSelector = () => {
    setSensorSelector(false);
  };

  const changeSensorSelector = (value: string) => {
    changeFormData({ controlSensorUid: value });
    closeSensorSelector();
  };

  const switchHandler = (state: boolean) => {
    if (!state) {
      changeFormData({
        controlSensorUid: undefined,
        controlSensorValue: undefined,
      });
    }
  };

  const valueChangeHandler = (value?: string) => {
    changeFormData({ controlSensorValue: stringToNumber(value) });
  };

  const sensor = sensorStore.getSensor(formData?.controlSensorUid);
  const SensorIcon = SensorIcons[sensor?.iconName];

  return (
    <View style={[styles.container, style]}>
      <SwitchBlock
        label={translate("Devices.Rules.overheatPrevention")}
        onChange={switchHandler}
        isOpened={isOpen}
      >
        <>
          <CustomSelectInput
            txLabel="Devices.Rules.controllingSensor"
            value={sensor?.displayName}
            errorText={errors?.controlSensorUid}
            icon={SensorIcon}
            iconColor={theme.colors.label}
            onPress={openSensorSelector}
            style={styles.firstInput}
          />

          <View style={styles.columns}>
            <View style={styles.column}>
              <TextInput
                txLabel="Devices.Rules.criticalValue"
                value={formData?.controlSensorValue?.toString() ?? ""}
                errorText={errors?.controlSensorValue}
                onChangeText={valueChangeHandler}
                disabled={!formData?.controlSensorUid}
                returnKeyType="next"
                keyboardType="numeric"
              />
            </View>
            <View style={styles.column} />
          </View>
        </>
      </SwitchBlock>

      {isSensorSelector ? (
        <SensorSelector
          sensors={rule?.controlSensorsForDevice}
          currentUid={formData?.controlSensorUid}
          onChange={changeSensorSelector}
          onClose={closeSensorSelector}
        />
      ) : null}
    </View>
  );
};
