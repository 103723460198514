import React from "react";
import { observer } from "mobx-react-lite";
import { StackNavigationProp } from "@react-navigation/stack";

import { ModuleDevice } from "@models/module/module";
import { DevicesNavigatorParamList } from "@screens/Devices/navigation";
import { DeviceMode, DeviceState } from "@models/device/device";

import { useNavigationSafety } from "../../../../../hooks";
import { translate } from "../../../../../i18n";
import { NoneIcon } from "../../../../../svgs";
import * as DeviceIcons from "../../../../../svgs/devices";
import { ModuleEmptyItem } from "./EmptyItem";
import { ModuleConnectedItem } from "./ConnectedItem";
import { ModuleCardBadges } from "./ModuleCardBadges";

type DeviceCardProps = {
  device: ModuleDevice;
};

export const ModuleDeviceCard = observer(({ device }: DeviceCardProps) => {
  const Icon = DeviceIcons[device.iconName] || NoneIcon;
  const navigation =
    useNavigationSafety() as StackNavigationProp<DevicesNavigatorParamList>;

  const goToDeviceDetails = () => {
    const { uid } = device;
    navigation?.navigate("device-details", { uid });
  };

  const goToAddDevice = () => {
    navigation?.navigate("devices-add", {
      moduleUid: device.uid.split(".")?.[0],
      socketUid: device.uid,
    });
  };

  const isAuto = device.mode === DeviceMode.Auto;
  const isOffline = device.state === DeviceState.Offline;

  return device.isConnected ? (
    <ModuleConnectedItem
      icon={Icon}
      typeName={device.typeName}
      displayName={device.displayName}
      socketName={device.socketName}
      onPress={goToDeviceDetails}
    >
      <ModuleCardBadges>
        {isAuto && <ModuleCardBadges.BadgeRuleOn />}
        {isOffline && <ModuleCardBadges.BadgeOffline />}
      </ModuleCardBadges>
    </ModuleConnectedItem>
  ) : (
    <ModuleEmptyItem
      title={translate("Devices.Add.device")}
      socketName={device.socketName}
      onPress={goToAddDevice}
    />
  );
});
