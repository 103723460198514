import { RuleType } from "@models/rule/rule";

export enum DeviceState {
  On = "on",
  Off = "off",
  Switching = "switching",
  Offline = "offline",
  Unknown = "unknown",
}
export enum DeviceMode {
  Manual = "manual",
  Auto = "auto",
  Unknown = "unknown",
}

export enum DeviceStateType {
  ByRule = "byRule",
  BySensor = "bySensor",
  BySchedule = "bySchedule",
  Manually = "manually",
  Continuously = "continuously",
  Idle = "idle",
  Offline = "offline",
}

export const HYDRO_TYPE = "dosing_pump";
export const DIMER_TYPE = "dimmer";

export const NOT_CONNECTED_TYPE = "not_connected";
export const NOT_VISIBLE_TYPES = ["unknown", NOT_CONNECTED_TYPE];

export const FETCH_RULES_ALIAS = "fetchRules";

export const DEVICE_ICONS = {
  irrigation_pump: "IrrigationIcon",
  water_pump: "WaterPumpIcon",
  humidifier: "HumidifierIcon",
  dehumidifier: "DehumidifierIcon",
  lights: "LightIcon",
  inline_fan: "InlineFanIcon",
  outline_fan: "OutlineFanIcon",
  circulation_fan: "CirculationFanIcon",
  irrigation_valves: "IrrigationIcon",
  co2_valve: "CO2Icon",
  chiller: "ChillerIcon",
  heater: "HeaterIcon",
  dosing_pump: "DosingPumpIcon",
  dimmer: "DimmerIcon",
  custom_device: "CustomDeviceIcon",
  air_conditioner: "AirConditionerIcon",
  led: "DimmerIcon",
  default: "UndefinedDeviceIcon",
};

export enum DeviceHistoryMode {
  All = "all",
  Manual = "manual",
  ByRule = "auto",
  Unknown = "unknown",
}

export enum DeviceHistoryView {
  Detailed = "detailed",
  Compact = "compact",
  Diagram = "diagram",
}

export type TDeviceHistorySensor = {
  value: number | null;
  uid: string | null;
};

export type TDeviceHistoryRule = {
  endAt: string;
  startAt: string;
  type: RuleType;
  valueHigh: number | null;
  valueLow: number | null;
};

export type TDeviceHistoryItem = {
  id: string;
  state: DeviceState;
  dateTime: Date | null;
  endDateTime?: Date | null;
  activeRuleUid: string | null;
  isAuto: boolean;
  isManual: boolean;
  sensor: TDeviceHistorySensor | null;
  rule: TDeviceHistoryRule | null;
  isOffline: boolean;
  ruleStopReason?: string;
};

export type TDeviceHistoryLogRule = {
  ruleType?: RuleType;
  sensorName?: string;
  sensorIcon?: string;
  description?: string;
};

export type TDeviceHistoryLog = {
  mode: DeviceMode;
  state: DeviceState;
  startTime: string;
  rule: TDeviceHistoryLogRule | object | undefined;
  dateTime?: Date;
  id: string;
  duration?: string;
  durationInSec?: number;
  isOffline: boolean;
};
