import * as React from "react";
import Svg, { SvgProps, G, Path, Defs, ClipPath } from "react-native-svg";

export const CalibrationPrepareORPSvg = (props: SvgProps) => (
  <Svg width={287} height={172} fill="none" {...props}>
    <G clipPath="url(#a)">
      <Path
        fill="#B6C5D3"
        d="M23.99 141.135 10.005 143l5.593-23.305 21.441-2.331 33.093 23.771H23.99Z"
      />
      <Path
        fill="#fff"
        d="M28.848 131.312c-11.107 3.306-18.853 11.385-18.853 11.385V50.372s8.815-8.936 20.845-11.295"
      />
      <Path
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.196}
        d="M28.848 131.312c-11.107 3.306-18.853 11.385-18.853 11.385V50.372s8.815-8.936 20.845-11.295"
      />
      <Path
        fill="#fff"
        d="M89.454 42.583v89.219c0 5.298-13.622 9.582-30.437 9.582-16.805 0-30.438-4.295-30.438-9.582v-89.22"
      />
      <Path
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.196}
        d="M89.454 42.583v89.219c0 5.298-13.622 9.582-30.437 9.582-16.805 0-30.438-4.295-30.438-9.582v-89.22"
      />
      <Path
        fill="#2B2E32"
        d="M68.088 136.754h-2.449v-4.451h2.449v4.451Zm0-8.903h-2.449V123.4h2.449v4.451Zm0-8.903h-2.449v-4.451h2.449v4.451Zm0-8.903h-2.449v-4.451h2.449v4.451Zm0-8.903h-2.449v-4.451h2.449v4.451Zm0-8.903h-2.449v-4.452h2.449v4.452Zm0-8.903h-2.449v-4.451h2.449v4.451Zm0-8.903h-2.449V69.98h2.449v4.452Zm0-8.903h-2.449v-4.452h2.449v4.452Zm0-8.903h-2.449v-4.452h2.449v4.452Z"
      />
      <Path
        fill="#fff"
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.196}
        d="M59.017 52.164c16.81 0 30.437-4.29 30.437-9.582C89.454 37.29 75.827 33 59.017 33c-16.81 0-30.438 4.29-30.438 9.582 0 5.292 13.627 9.582 30.438 9.582Z"
      />
      <Path
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.196}
        d="M58.623 49.347c-13.428 0-24.314-3.024-24.314-6.754 0-1.535 1.845-2.951 4.953-4.086m19.36-2.667c13.429 0 24.314 3.023 24.314 6.753 0 1.813-2.57 3.459-6.753 4.672M28.848 131.312c-11.107 3.306-18.853 11.385-18.853 11.385V50.372s8.815-8.936 20.845-11.295"
      />
      <Path
        fill="#B6C5D3"
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.196}
        d="M59.018 45.787c7.216 0 13.065-1.49 13.065-3.327 0-1.838-5.85-3.328-13.065-3.328-7.216 0-13.066 1.49-13.066 3.328 0 1.837 5.85 3.327 13.066 3.327Z"
      />
      <Path
        fill="#B6C5D3"
        d="M124.094 142.451c15.677 0 28.385-3.858 28.385-8.617s-12.708-8.616-28.385-8.616c-15.676 0-28.385 3.857-28.385 8.616 0 4.759 12.709 8.617 28.385 8.617Z"
      />
      <Path
        fill="#70B8FF"
        fillOpacity={0.4}
        d="M127.642 98.86c13.717 0 24.837-3.404 24.837-7.603 0-4.2-11.12-7.603-24.837-7.603s-24.836 3.404-24.836 7.603 11.119 7.603 24.836 7.603Z"
      />
      <Path
        fill="#308AE3"
        fillRule="evenodd"
        d="M152.479 91.257c0 4.2-11.12 7.603-24.837 7.603-13.709 0-24.824-3.4-24.836-7.596V131.626c0 4.294 11.124 7.784 24.836 7.784 13.713 0 24.837-3.49 24.837-7.784v-41.89l-.49.01c.322.489.49.994.49 1.511Zm-49.673-.007c0-.16.017-.32.049-.478h-.049v.478Z"
        clipRule="evenodd"
        opacity={0.6}
      />
      <Path
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.196}
        d="M152.479 69.461v62.17c0 4.291-11.148 7.779-24.891 7.779-13.743 0-24.891-3.488-24.891-7.779V80.728a6.762 6.762 0 0 0-2.071-4.873L96.723 71.7"
      />
      <Path
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.196}
        d="M127.659 62.365c-10.594 0-17.558 2.383-22.495 4.585-1.691.75-5.334 2.577-7.92 3.883-.859.436-.597 1.682.36 1.766l4.986.46c1.754.266 3.457.738 5.086 1.415 4.514 1.875 11.789 3.097 19.983 3.097 13.716 0 24.82-3.411 24.82-7.609.013-4.198-11.104-7.597-24.82-7.597Z"
      />
      <Path
        fill="#fff"
        d="m127.812 104.651.093.094a49.973 49.973 0 0 1 2.38 2.818 38.94 38.94 0 0 1 2.287 3.196 29.78 29.78 0 0 1 1.822 3.256c.418.876.778 1.788 1.057 2.711.186.628.336 1.267.441 1.918.116.722.162 1.456.116 2.202-.058 1.089-.302 2.131-.778 3.102a7.009 7.009 0 0 1-2.356 2.699c-.767.533-1.602.9-2.496 1.148a9.98 9.98 0 0 1-1.289.261 8.606 8.606 0 0 1-1.741.071 9.269 9.269 0 0 1-3.506-.853 7.628 7.628 0 0 1-2.368-1.728c-.79-.864-1.324-1.882-1.626-3.031a9.153 9.153 0 0 1-.255-2.652c.035-.71.139-1.42.29-2.119.267-1.184.674-2.32 1.173-3.421a26.86 26.86 0 0 1 1.776-3.279 40.02 40.02 0 0 1 2.391-3.386 50.317 50.317 0 0 1 2.485-2.948c.046 0 .069-.024.104-.059Z"
      />
      <Path
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.196}
        d="M146.597 82.333v31.714M146.597 118.746v10.571"
      />
      <Path
        fill="#B6C5D3"
        d="M187.088 142.451c15.676 0 28.385-3.858 28.385-8.617s-12.709-8.616-28.385-8.616c-15.677 0-28.385 3.857-28.385 8.616 0 4.759 12.708 8.617 28.385 8.617Z"
      />
      <Path
        fill="#70B8FF"
        fillOpacity={0.4}
        d="M190.636 98.86c13.717 0 24.837-3.404 24.837-7.603 0-4.2-11.12-7.603-24.837-7.603s-24.837 3.404-24.837 7.603 11.12 7.603 24.837 7.603Z"
      />
      <Path
        fill="#308AE3"
        fillRule="evenodd"
        d="M215.472 91.257c0 4.2-11.119 7.603-24.836 7.603-13.684 0-24.783-3.387-24.837-7.572V131.626c0 4.294 11.124 7.784 24.837 7.784 13.712 0 24.837-3.49 24.837-7.784v-41.89l-.491.01c.322.489.49.994.49 1.511Zm-49.673-.031c.002-.152.018-.304.049-.454h-.049v.454Z"
        clipRule="evenodd"
        opacity={0.6}
      />
      <Path
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.196}
        d="M215.472 69.461v62.17c0 4.291-11.149 7.779-24.891 7.779-13.743 0-24.892-3.488-24.892-7.779V80.728a6.761 6.761 0 0 0-2.07-4.873l-3.903-4.155"
      />
      <Path
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.196}
        d="M190.652 62.365c-10.595 0-17.558 2.383-22.495 4.585-1.691.75-5.334 2.577-7.921 3.883-.858.436-.597 1.682.361 1.766l4.986.46c1.754.266 3.457.738 5.086 1.415 4.514 1.875 11.788 3.097 19.983 3.097 13.716 0 24.82-3.411 24.82-7.609.013-4.198-11.104-7.597-24.82-7.597ZM208.637 82.333v21.955"
      />
      <Path
        stroke="#fff"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.196}
        d="M173.607 110.21c1.5.436 3.02.816 4.54 1.14 2.57.548 4.68 3.142 4.68 6.037v1.375c0 1.945-1 3.611-2.57 4.193a62.475 62.475 0 0 1-7.36 1.375v3.175c3.27.995 6.59 1.721 9.93 2.168M196.107 112.491c-3.14.268-6.29.29-9.43.067v6.953c1.57.112 3.14.157 4.72.146 2.61-.011 4.72 2.124 4.72 5.008v.101c0 2.884-2.11 5.422-4.72 5.444-1.57.011-3.15-.033-4.72-.145M204.387 129.438c-2.57.582-4.67-1.486-4.67-4.371v-.492c0-2.884 2.1-5.5 4.67-6.081 2.57-.57 4.62 1.118 4.62 4.002v.492c0 2.884-2.05 5.88-4.62 6.45Z"
      />
      <Path
        stroke="#fff"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.196}
        d="M209.007 110.724c-.64.19-1.28.38-1.93.559-4.04 1.096-7.37 4.874-7.37 9.022v3.79"
      />
      <Path
        fill="#B6C5D3"
        d="M250.081 142.451c15.677 0 28.385-3.858 28.385-8.617s-12.708-8.616-28.385-8.616c-15.677 0-28.385 3.857-28.385 8.616 0 4.759 12.708 8.617 28.385 8.617Z"
      />
      <Path
        fill="#70B8FF"
        fillOpacity={0.4}
        d="M253.629 98.86c13.717 0 24.837-3.404 24.837-7.603 0-4.2-11.12-7.603-24.837-7.603s-24.837 3.404-24.837 7.603 11.12 7.603 24.837 7.603Z"
      />
      <Path
        fill="#308AE3"
        fillRule="evenodd"
        d="M278.466 91.257c0 4.2-11.12 7.603-24.837 7.603-13.684 0-24.782-3.387-24.837-7.572V131.626c0 4.294 11.124 7.784 24.837 7.784s24.837-3.49 24.837-7.784v-41.89l-.49.01c.321.489.49.994.49 1.511Zm-49.674-.031c.002-.152.019-.304.05-.454h-.05v.454Z"
        clipRule="evenodd"
        opacity={0.6}
      />
      <Path
        stroke="#fff"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.196}
        d="M271.63 109.378a64.485 64.485 0 0 1-9.29 2.106v6.929c1.56-.234 3.12-.523 4.67-.88 2.57-.579 4.62 1.103 4.62 3.978v.1c0 2.874-2.05 5.849-4.62 6.428-1.55.346-3.11.646-4.67.88M244.16 130.212v-19.397a197.582 197.582 0 0 1-10.02 9.827 65.494 65.494 0 0 0 10.93 2.328M247.69 111.796c3.68.301 7.37.278 11.05-.056v3.587a611.464 611.464 0 0 1-7.8 14.874"
      />
      <Path
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.196}
        d="M278.465 69.461v62.17c0 4.291-11.148 7.779-24.891 7.779-13.743 0-24.891-3.488-24.891-7.779V80.728a6.762 6.762 0 0 0-2.071-4.873l-3.903-4.155"
      />
      <Path
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.196}
        d="M253.645 62.365c-10.594 0-17.558 2.383-22.495 4.585-1.691.75-5.334 2.577-7.921 3.883-.858.436-.597 1.682.361 1.766l4.986.46c1.754.266 3.457.738 5.086 1.415 4.514 1.875 11.789 3.097 19.983 3.097 13.716 0 24.82-3.411 24.82-7.609.013-4.198-11.104-7.597-24.82-7.597ZM271.63 82.333v21.099M.09 104h9.974M278.377 104h7.713M152.447 104h13.352M89.454 104h13.351M215.43 104h13.362"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M.09 0h286v172H.09z" />
      </ClipPath>
    </Defs>
  </Svg>
);
