import { useCallback } from "react";
import { StackNavigationProp } from "@react-navigation/stack";

import { SensorState, useStores } from "@models/index";
import { Sensor } from "@models/sensor/sensor";
import { SensorsNavigatorParamList } from "@screens/Sensors/navigation";

import { useNavigationSafety } from "./useNavigationSafety";

export const useGoToSensorDetails = (
  rawSensor: Sensor,
  showOfflinePopup?: () => void,
) => {
  const { sensorStore } = useStores();
  const navigation =
    useNavigationSafety() as StackNavigationProp<SensorsNavigatorParamList>;

  const sensor =
    typeof rawSensor.canCalibrate !== "boolean"
      ? sensorStore.getSensor(rawSensor.uid) || rawSensor
      : rawSensor;

  return useCallback(() => {
    if (sensor.isUnknownAnalog) {
      return navigation.navigate("sensor-types-list", { uid: sensor.uid });
    }

    // const isOfflineAndNotCalibrated =
    //   sensor.state === SensorState.Offline &&
    //   sensor.canCalibrate &&
    //   !sensor.lastCalibrationDate;
    //
    // if (isOfflineAndNotCalibrated) {
    //   if (showOfflinePopup) {
    //     return showOfflinePopup();
    //   }
    //   return navigation.navigate("sensor-details", { uid: sensor.uid });
    // }

    if (sensor.needCalibration && !sensor.lastCalibrationDate) {
      return navigation.navigate("sensor-calibration", { uid: sensor.uid });
    }
    return navigation.navigate("sensor-details", { uid: sensor.uid });
  }, [sensor, showOfflinePopup]);
};
