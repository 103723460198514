import * as semver from "semver";
import { useStores } from "@models/root-store/root-store-context";
import { translate } from "../i18n";

export enum ModuleFeature {
  DeviceHistory = "DeviceHistory",
  ModuleActivityHistory = "ModuleActivityHistory",
  ModuleCommands = "ModuleCommands",
}

const FEATURES_FIRMWARE_FROM: Record<ModuleFeature, string> = {
  [ModuleFeature.DeviceHistory]: "0.0.63",
  [ModuleFeature.ModuleActivityHistory]: "1.6.27",
  [ModuleFeature.ModuleCommands]: "1.6.29",
};

type useCheckingModuleFeatureAvailabilityResponse = {
  availability: boolean;
  message?: string;
  isUpdateAvailable?: boolean;
};

export const useCheckingModuleFeatureAvailability = (
  uid: string,
  feature: ModuleFeature,
): useCheckingModuleFeatureAvailabilityResponse => {
  const { moduleStore } = useStores();
  const module = moduleStore.getModule(uid);
  const featureFirmware = FEATURES_FIRMWARE_FROM[feature];

  if (!module)
    return {
      availability: true,
    };

  const availability =
    module.firmware && featureFirmware
      ? semver.gte(module.currentFirmwareVersion, featureFirmware)
      : true;

  const message = availability
    ? undefined
    : translate("errorScreen.functionalityIsAvailableForFirmware", {
        version: featureFirmware,
      });

  return {
    availability,
    message,
    isUpdateAvailable: module.isFirmwareUpdateAvailable,
  };
};
