import {
  flow,
  getSnapshot,
  Instance,
  SnapshotOut,
  types,
} from "mobx-state-tree";
import { values } from "mobx";

import { RuleTypesModel } from "@models/rule/rule";
import { uniq } from "../../utils/uniq";
import { DevicesApi } from "../../services/api";
import { sortDataBy, localeCompare } from "../../utils/sortDataBy";
import { withEnvironment } from "../extensions/with-environment";
import { withStatus } from "../extensions/with-status";
import {
  IsFetchTimeProps,
  withFetchTimeout,
} from "../extensions/with-fetch-timeout";
import { TFilterItem } from "../types";
import { withRootStore } from "../extensions/with-root-store";
import {
  Device,
  DeviceMode,
  DeviceModel,
  DeviceTypeModel,
  DeviceViewModel,
  NOT_CONNECTED_TYPE,
  NOT_VISIBLE_TYPES,
} from "./device";

export const DEVICE_MODE_ALL = "all";

type DeviceConnectData = {
  type: string;
  name: string;
};

const FETCH_DEVICES_ALIAS = "fetchDevices";
const FETCH_DEVICES_VIEWS_ALIAS = "fetchDevicesViews";

type FilterMode = DeviceMode | typeof DEVICE_MODE_ALL;

const filterByMode = (devices: Device[], mode?: FilterMode) => {
  return mode && mode !== DEVICE_MODE_ALL
    ? devices.filter((device) => device.mode === mode)
    : devices;
};

export const DeviceStoreModel = types
  .model("DeviceStore")
  .props({
    devices: types.map(DeviceModel),
    deviceViews: types.optional(types.array(DeviceViewModel), []),
    deviceTypes: types.optional(types.array(DeviceTypeModel), []),
    filterMode: types.maybe(
      types.enumeration<FilterMode>("FilterMode", [
        ...Object.values(DeviceMode),
        DEVICE_MODE_ALL,
      ]),
    ),

    ruleTypes: types.optional(types.array(RuleTypesModel), []),
  })
  .extend(withEnvironment)
  .extend(withStatus)
  .extend(withFetchTimeout)
  .extend(withRootStore)
  .views((self) => ({
    get devicesSettings() {
      return self.rootStore.settingsStore.userSettings.devicesSettings;
    },
    get visibleDevices(): Device[] {
      return sortDataBy([...self.deviceViews], "position")
        .map((view) => {
          const device = self.devices.get(view.uid);
          return device?.type !== NOT_CONNECTED_TYPE ? device : undefined;
        })
        .filter(Boolean);
    },
    get visibleTypes() {
      return self.deviceTypes.filter(
        (type) => !NOT_VISIBLE_TYPES.includes(type.name),
      );
    },
  }))
  .actions((self) => ({
    clear: () => {
      self.devices.forEach((device: Device, key: string) => {
        device.clear();
      });
      self.devices.clear();
      self.deviceViews.clear();
      self.deviceTypes.clear();
      self.clearFetchingTimes();
    },
  }))
  .actions((self) => ({
    setDeviceViews: (deviceViewsSnapshots) => {
      self.deviceViews.replace(deviceViewsSnapshots);
    },
    setDevices: (devicesSnapshots) => {
      devicesSnapshots.forEach((deviceSnapshot) =>
        self.devices.put({ ...deviceSnapshot, fetchedAt: Date.now() }),
      );
    },
    setDeviceTypes: (deviceTypesSnapshots) => {
      self.deviceTypes.replace(deviceTypesSnapshots);
    },
    setFilterMode: (filterValue) => {
      self.filterMode = filterValue;
    },
    getDevice: (uid) => {
      // TODO: fetch device if absent in collection
      // return self.devices.find((item) => item.uid === uid);
      return self.devices.get(uid);
    },
    getType: (typeName) => {
      return self.deviceTypes.find((model) => model.name === typeName);
    },
  }))
  .actions((self) => {
    const devicesApi = new DevicesApi(self.environment.api);

    /* eslint-disable consistent-return */
    const fetchDevicesViews = flow(function* (
      fetchProps: IsFetchTimeProps = {},
    ) {
      if (!self.isFetchTime(FETCH_DEVICES_VIEWS_ALIAS, fetchProps))
        return undefined;

      self.setStatusPending();
      const result = yield devicesApi.getDevicesViews();
      // TODO: update device state !!!
      // /api/v1/Dashboard/device_state
      if (result.kind === "ok") {
        self.setStatusDone();
        self.resetErrors();
        self.setDeviceViews(result.data);
      } else {
        self.setStatusError(result.errors);
        self.clearFetchTime(FETCH_DEVICES_VIEWS_ALIAS);
        if (__DEV__) console.log(result.kind);
      }
    });
    /* eslint-enable consistent-return */

    /* eslint-disable consistent-return */
    const fetchDevices = flow(function* (fetchProps: IsFetchTimeProps = {}) {
      if (!self.isFetchTime(FETCH_DEVICES_ALIAS, fetchProps)) return undefined;

      self.setStatusPending();
      const result = yield devicesApi.getDevices();

      if (result.kind === "ok") {
        self.setStatusDone();
        self.resetErrors();
        self.setDevices(result.data);
      } else {
        self.setStatusError(result.errors);
        self.clearFetchTime(FETCH_DEVICES_ALIAS);
        if (__DEV__) console.log(result.kind);
      }
    });
    /* eslint-enable consistent-return */

    const fetchDeviceTypes = flow(function* () {
      const result = yield devicesApi.getDeviceTypes();

      if (result.kind === "ok") {
        self.setDeviceTypes(result.data);
      }
      if (__DEV__) console.log(result.kind);
    });

    const connect = flow(function* (uid, data: DeviceConnectData) {
      self.setStatusPending();
      const result = yield devicesApi.connect(uid, {
        name: data.name,
        connectedDeviceType: data.type,
        mode: "manual",
      });

      if (result.kind === "ok") {
        self.setStatusDone();
        self.clearFetchTime(FETCH_DEVICES_VIEWS_ALIAS);
        self.clearFetchTime(FETCH_DEVICES_ALIAS);
      } else {
        self.setStatusError();
        if (__DEV__) console.log(result.kind);
      }
      return result;
    });

    const updateViewFilter = (items: TFilterItem[]) => {
      const settings = items.map((item) => ({
        uid: item.uid,
        positionInView: item.position,
        hideInView: !item.isChecked,
      }));
      self.rootStore.settingsStore.userSettings.saveDevicesSettings(settings);
    };

    const typesForModule = (moduleUid?: string, socketUid?: string) => {
      const module = self.rootStore.moduleStore.getModule(moduleUid);
      if (!module) return self.visibleTypes;

      if (socketUid) {
        const socket = module.devices.find(
          (moduleDevice) => moduleDevice.uid === socketUid,
        );
        if (socket) {
          return self.visibleTypes.filter((item) =>
            item.connectorTypes.includes(socket.connectorType),
          );
        }
      }

      const uniqTypes = uniq(
        module.devices.map((moduleDevice) => moduleDevice.connectorType),
      );
      return self.visibleTypes.filter((item) =>
        item.connectorTypes.find((connectorTypes) =>
          uniqTypes.includes(connectorTypes),
        ),
      );
    };

    const fetchRuleTypes = flow(function* () {
      const result = yield devicesApi.getRuleTypes();

      if (result.kind === "ok") {
        self.ruleTypes = result.data;
      }
    });

    return {
      fetchDevices,
      fetchDevicesViews,
      fetchDeviceTypes,
      connect,
      updateViewFilter,
      typesForModule,

      fetchRuleTypes,
    };
  })
  .actions((self) => ({
    connectorTypesByType: (typeName) => {
      return self.getType(typeName)?.connectorTypes || [];
    },
    getDevices: (uids) => {
      return uids
        .map((uid) => {
          return self.getDevice(uid);
        })
        .filter((device) => {
          return device !== null && device !== undefined;
        });
    },
    afterUserLogin: async () => {
      self.fetchDevices();
      await self.fetchDeviceTypes();
      await self.fetchRuleTypes();
    },
  }))

  .views((self) => ({
    get sortedDevices() {
      const filtered = filterByMode(self.visibleDevices, self.filterMode);

      return filtered.sort((deviceA, deviceB) => {
        const positionA =
          self.devicesSettings.get(deviceA.uid)?.positionInView || 0;
        const positionB =
          self.devicesSettings.get(deviceB.uid)?.positionInView || 0;
        return (
          positionA - positionB || localeCompare(deviceA?.name, deviceB?.name)
        );
      });
    },

    get viewFilter() {
      return filterByMode(self.visibleDevices, self.filterMode).reduce(
        (result, { uid }: Device) => {
          if (self.devicesSettings.get(uid)?.hideInView) {
            result.hiddenUids.push(uid);
          } else {
            result.visibleUids.push(uid);
          }
          return result;
        },
        { hiddenUids: [], visibleUids: [], total: self.devices.size },
      );
    },
  }))
  .views((self) => ({
    get byMode() {
      return filterByMode(self.visibleDevices, self.filterMode);
    },

    get filteredAndSortedDevices() {
      const devicesSettings = getSnapshot(self.devicesSettings) || {};

      return self.sortedDevices.filter(
        // GDVI-862 mobX does not observe changes related to sorting
        (device) => !devicesSettings[device.uid]?.hideInView,
      );
    },

    get notConnected() {
      return values(self.devices).filter((item) => !item.isConnected);
    },

    get isAnyOn() {
      return values(self.devices).find((item) => item.isOn);
    },

    get isAnyOnInAutoMode() {
      return values(self.devices).find(
        (item) => item.isOn && item.mode === DeviceMode.Auto,
      );
    },

    get devicesNames() {
      return values(self.devices)
        .filter((device) => !NOT_VISIBLE_TYPES.includes(device.type))
        .map((device) => device.name);
    },
  }));

type CharacterStoreType = Instance<typeof DeviceStoreModel>;
export type CharacterStore = CharacterStoreType;
type CharacterStoreSnapshotType = SnapshotOut<typeof DeviceStoreModel>;
export type CharacterStoreSnapshot = CharacterStoreSnapshotType;
export const createCharacterStoreDefaultModel = () =>
  types.optional(DeviceStoreModel, {});
