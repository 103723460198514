import * as React from "react";
import { View } from "react-native";
import Svg, { G, Rect, Path, SvgProps } from "react-native-svg";

import { spacing } from "../../../../theme";

export const CalibrationSoilMoistureErrorSvg = (props: SvgProps) => (
  <View style={{ marginTop: -spacing[8] }}>
    <Svg width={360} height={312} viewBox="0 0 360 312" fill="none" {...props}>
      <G clipPath="url(#clip0_306_3512)">
        <Path
          d="M253.855 280.086H102.235C85.0047 280.086 74.3347 261.266 83.1747 246.435L157.565 121.59C166.765 106.147 189.075 106.137 198.295 121.56L272.895 246.404C281.765 261.236 271.105 280.086 253.855 280.086Z"
          fill="#FFF1F1"
        />
        <Path
          d="M65.6985 174.038H90.9285C93.7985 174.038 95.5685 177.175 94.0985 179.64L81.7185 200.414C80.1885 202.98 76.4785 202.99 74.9385 200.414L62.5285 179.64C61.0585 177.175 62.8285 174.038 65.6985 174.038Z"
          fill="#FFF1F1"
        />
        <Path
          d="M245.086 172L252.599 164.313C255.8 161.037 255.8 155.722 252.599 152.457C249.398 149.181 244.204 149.181 241.013 152.457L233.5 160.144L225.987 152.457C222.786 149.181 217.592 149.181 214.401 152.457C211.2 155.733 211.2 161.047 214.401 164.313L221.914 172L214.401 179.687C211.2 182.963 211.2 188.278 214.401 191.543C217.602 194.819 222.796 194.819 225.987 191.543L233.5 183.856L241.013 191.543C244.214 194.819 249.408 194.819 252.599 191.543C255.8 188.267 255.8 182.953 252.599 179.687L245.086 172Z"
          fill="#F76B6B"
        />
        <Path
          d="M175.308 309C195.02 309 211 304.149 211 298.165C211 292.18 195.02 287.329 175.308 287.329C155.595 287.329 139.615 292.18 139.615 298.165C139.615 304.149 155.595 309 175.308 309Z"
          fill="#B6C5D3"
        />
        <Path
          opacity={0.6}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M211 266.297C211 271.577 197.018 275.857 179.769 275.857C162.549 275.857 148.584 271.591 148.539 266.323V287.555V295.615C148.539 301.015 162.527 305.403 179.769 305.403C197.012 305.403 211 301.015 211 295.615V284.131V264.385L210.384 264.397C210.788 265.011 211 265.646 211 266.297ZM148.539 266.271C148.54 266.075 148.561 265.88 148.601 265.687L148.539 265.688V266.271Z"
          fill="#308AE3"
        />
        <Path
          d="M166.445 231.093V268.314"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M178.039 231.093V268.314"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M172.874 231.093V268.314"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M189.632 231.093V268.314"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M178.438 174.254V171.751C178.438 153.977 164.028 139.567 146.254 139.567C128.48 139.567 114.069 153.977 114.069 171.751V230.371C114.069 248.145 99.6589 262.555 81.8848 262.555H78.8714C61.0974 262.555 46.687 248.145 46.687 230.371V222.155C46.687 204.381 32.2766 189.97 14.5025 189.97H-12.8882"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Rect
          width={34.1699}
          height={42.7124}
          rx={3}
          transform="matrix(-1 0 0 1 195.124 188.381)"
          fill="#6A7B8C"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M183.53 177.957C183.53 176.3 182.187 174.957 180.53 174.957H175.547C173.89 174.957 172.547 176.3 172.547 177.957V188.381H183.53V177.957Z"
          fill="#6A7B8C"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M189.632 194.333V212.234"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M189.632 217.726V223.829"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M179.769 275.857C197.018 275.857 211 271.577 211 266.297C211 261.017 197.018 256.736 179.769 256.736C162.521 256.736 148.539 261.017 148.539 266.297C148.539 271.577 162.521 275.857 179.769 275.857Z"
          fill="#70B8FF"
          fillOpacity={0.4}
        />
        <Path
          d="M160.829 210.69C157.171 211.695 154.113 212.898 151.504 214.061C149.378 215.005 144.797 217.302 141.544 218.944C140.465 219.492 140.794 221.059 141.998 221.165L148.268 221.743C150.472 222.078 152.615 222.671 154.663 223.523C160.339 225.881 169.486 227.417 179.79 227.417C197.037 227.417 211 223.128 211 217.849C211.011 214.288 204.653 211.185 195.219 209.542"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M211 217.219V283.917V295.394C211 300.79 196.981 305.176 179.701 305.176C162.42 305.176 148.401 300.79 148.401 295.394V287.339V231.386C148.401 229.069 147.46 226.861 145.798 225.259L140.89 220.034"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M202.077 233.791V272.67"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M202.077 279.044V291.791"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M178.449 264.385C178.495 264.43 178.525 264.461 178.571 264.506C179.651 265.678 180.701 266.88 181.69 268.128C182.756 269.452 183.76 270.807 184.688 272.237C185.571 273.591 186.377 274.976 187.077 276.422C187.625 277.548 188.097 278.72 188.462 279.907C188.706 280.713 188.904 281.535 189.041 282.372C189.193 283.301 189.254 284.244 189.193 285.203C189.117 286.603 188.797 287.942 188.173 289.19C187.443 290.62 186.408 291.777 185.084 292.66C184.08 293.344 182.984 293.816 181.812 294.136C181.249 294.288 180.686 294.394 180.123 294.47C179.362 294.577 178.601 294.607 177.84 294.562C176.242 294.486 174.705 294.136 173.244 293.466C172.073 292.918 171.023 292.188 170.14 291.244C169.105 290.133 168.405 288.825 168.009 287.349C167.872 286.816 167.781 286.268 167.72 285.72C167.659 285.127 167.644 284.533 167.675 283.94C167.72 283.027 167.857 282.114 168.055 281.216C168.405 279.694 168.938 278.233 169.592 276.818C170.262 275.357 171.053 273.957 171.92 272.602C172.879 271.096 173.944 269.65 175.055 268.25C176.09 266.941 177.186 265.678 178.312 264.461C178.373 264.461 178.403 264.43 178.449 264.385Z"
          fill="white"
        />
      </G>
    </Svg>
  </View>
);
