import * as React from "react";
import Svg, { Path } from "react-native-svg";

export default function ModulesIcon({
  color = "#939393",
  width = 24,
  height = 24,
}) {
  return (
    <Svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <Path
        d="M20.5 17.5L12.8658 3.57864C12.4885 2.89067 11.5021 2.88535 11.1174 3.56921L3.83828 16.5097C3.46331 17.1763 3.94503 18 4.70985 18H15.2516C16.025 18 16.5057 17.1596 16.1135 16.493L12.0001 9.5L9 15H12"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </Svg>
  );
}
