import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { StyleSheet, View } from "react-native";
import dayjs from "dayjs";

import { useStores } from "@models/index";
import { StackScreenProps } from "@react-navigation/stack";

import { ErrorsMessage, Text } from "@components/ui";
import { TopBarNew } from "@components/layout";
import { Screen } from "@components/index";
import { Calendar } from "@components/common";
import { ModuleFeatureError } from "@screens/Modules/components";

import { ModulesNavigatorParamList } from "@screens/Modules/navigation";
import { translate } from "../../../../i18n";

import { defaultColors, spacing } from "../../../../theme";
import {
  dateTimeFormat,
  DateTimeFormats,
  TPeriod,
} from "../../../../utils/timeConverter";
import {
  ModuleFeature,
  useCheckingModuleFeatureAvailability,
} from "../../../../hooks";
import { groupByDataByDate } from "../../../../utils/sortDataBy";
import { useModuleActivityHistory } from "../../../../hooks/useModuleActivityHistory";
import { ModuleActivityHistoryCard } from "./components/ModuleActivityHistoryCard";

const styles = StyleSheet.create({
  screen: {
    padding: spacing[4],
  },
  date: {
    fontSize: 14,
    lineHeight: 16,
    color: defaultColors.subtitle,
    marginBottom: spacing[3],
    marginTop: spacing[4],
  },
  filterBar: {
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: spacing[3],
  },
});

export const ModuleActivityHistoryScreen: React.FC<
  StackScreenProps<ModulesNavigatorParamList, "module-activity-history">
> = observer(({ route }) => {
  const uid = route.params?.uid;
  const { moduleStore } = useStores();
  const module = moduleStore.getModule(uid);

  const startDate = dayjs().add(-1, "day").format(DateTimeFormats.DateYMD);
  const endDate = dayjs().format(DateTimeFormats.DateYMD);

  const initialPeriod: TPeriod = {
    startDate,
    endDate,
  };

  const [period, setPeriod] = useState<TPeriod>(initialPeriod);

  if (!module)
    return <ErrorsMessage errors={[translate("Modules.Details.noModule")]} />;

  const { activityHistory, isLoading, errors, refetch } =
    useModuleActivityHistory({
      uid: uid as string,
      startDate: period.startDate,
      endDate: period.endDate,
    });

  const {
    availability,
    message: availabilityError,
    isUpdateAvailable,
  } = useCheckingModuleFeatureAvailability(
    module.uid,
    ModuleFeature.ModuleActivityHistory,
  );

  const groupedActivityHistory = groupByDataByDate(
    activityHistory,
    "timestamp",
  );
  const dates = groupedActivityHistory && Object.keys(groupedActivityHistory);

  return (
    <Screen
      testID="DeviceDetails"
      preset="scroll"
      header={
        <TopBarNew
          title={translate("Modules.History.title")}
          subtitle={module.displayName}
        />
      }
      style={styles.screen}
      onRefresh={refetch}
      loading={isLoading}
      error={
        !availability && (
          <ModuleFeatureError
            moduleUid={module.uid}
            message={availabilityError}
            isUpdateAvailable={isUpdateAvailable}
          />
        )
      }
    >
      <View style={styles.filterBar}>
        {period && (
          <Calendar
            period={period}
            onChange={setPeriod}
            defaultPeriod={initialPeriod}
          />
        )}
      </View>

      <ErrorsMessage errors={errors} />
      {!activityHistory.length ? (
        <ErrorsMessage
          errors={[translate("Devices.History.errors.noHistory")]}
        />
      ) : null}

      {dates.map((date) => (
        <View key={date}>
          <Text style={styles.date}>
            {dateTimeFormat(date, DateTimeFormats.DateDM)}
          </Text>

          {groupedActivityHistory[date]?.map((item) => (
            <ModuleActivityHistoryCard
              {...item}
              key={item.timestamp.getTime()}
            />
          ))}
        </View>
      ))}
    </Screen>
  );
});
