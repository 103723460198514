import React from "react";
import { View, StyleSheet, Pressable, ViewStyle } from "react-native";
import { observer } from "mobx-react-lite";
import { StackNavigationProp } from "@react-navigation/stack";

import { Device, DeviceState } from "@models/device/device";
import { BackgroundGradient, Text } from "@components/ui";

import { DeviceCardStatus } from "@screens/Devices/components/DeviceCardStatus";
import { DefaultTheme, spacing } from "../../../theme";
import { NoneIcon } from "../../../svgs";
import * as DeviceIcons from "../../../svgs/devices";
import { useNavigationSafety } from "../../../hooks";
import { DevicesNavigatorParamList } from "../navigation";
import { AutoMode } from "./AutoMode";

const styles = StyleSheet.create({
  card: {
    borderRadius: spacing[4],
    backgroundColor: DefaultTheme.colors.cardBG,
    padding: spacing[4],
    marginBottom: spacing[3],
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  gradient: {
    borderRadius: spacing[4],
  },

  device: {
    flex: 2,
    flexDirection: "row",
    alignItems: "center",
  },

  icon: {
    marginRight: spacing[4],
  },
  description: {
    flex: 1,
    alignSelf: "flex-start",
  },

  typeName: {
    color: DefaultTheme.colors.label,
    fontSize: 12,
    flexShrink: 1,
    marginBottom: spacing[1],
  },
  "typeName-on": {
    color: DefaultTheme.colors.cardBG,
  },

  name: {
    fontSize: 14,
    marginBottom: spacing[2],
  },
  "name-on": {
    color: DefaultTheme.colors.card,
  },

  status: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
    marginTop: -2,
    marginBottom: -2,
  },
  statusText: {
    fontSize: 12,
    color: DefaultTheme.colors.subLabel,
    marginRight: spacing[1],
    textTransform: "lowercase",
  },
  "statusText-on": {
    color: DefaultTheme.colors.text,
  },
});

interface DeviceCardProps {
  device: Device;
  style?: ViewStyle;
}

const DeviceCard: React.FunctionComponent<DeviceCardProps> = observer(
  ({ device, style }) => {
    const { uid, typeName, name, state } = device;
    const navigation =
      useNavigationSafety() as StackNavigationProp<DevicesNavigatorParamList>;

    const toDetailsHandler = () =>
      navigation ? navigation.navigate("device-details", { uid }) : null;
    const Icon = DeviceIcons[device.iconName] || NoneIcon;
    const statusTextStyle = [styles.statusText, styles[`statusText-${state}`]];

    return (
      <View style={[styles.card, style]}>
        {state === DeviceState.On && (
          <BackgroundGradient style={styles.gradient} />
        )}

        <Pressable style={styles.device} onPress={toDetailsHandler}>
          <View style={styles.icon}>
            <Icon
              size={40}
              color={
                state === DeviceState.On
                  ? DefaultTheme.colors.card
                  : DefaultTheme.colors.label
              }
            />
          </View>
          <View style={styles.description}>
            <Text
              style={[styles.typeName, styles[`typeName-${state}`]]}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {typeName}
            </Text>
            <Text
              style={[styles.name, styles[`name-${state}`]]}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {name || "Unknown"}
            </Text>
            <View style={styles.status}>
              <DeviceCardStatus device={device} style={statusTextStyle} />
            </View>
          </View>
        </Pressable>

        <AutoMode device={device} />
      </View>
    );
  },
);

export default DeviceCard;
