import * as FileSystem from "expo-file-system";
import * as Sharing from "expo-sharing";
import { Platform } from "react-native";
// import { saveAs } from "file-saver";

type TDownloadFileProps = {
  fileName: string;
  path: string;
  api: any;
};

export const downloadFile = async ({
  path,
  fileName,
  api,
}: TDownloadFileProps) => {
  const headers = api?.apisauce.headers;
  const url = api.apisauce.getBaseURL() + path;

  if (Platform.OS === "web") {
    const response = await fetch(url, { headers });
    if (!response.ok) {
      throw new Error(response.statusText, { cause: response.status });
    }
    // const blob = await response.blob();
    // saveAs(blob, fileName);
    return true;
  }

  const downloadInstance = FileSystem.createDownloadResumable(
    url,
    FileSystem.documentDirectory + fileName,
    {
      cache: false,
      headers: { Authorization: headers.Authorization, Allow: "GET" },
    },
  );

  try {
    const resDownload = await downloadInstance.downloadAsync();
    const sharingIsAvailable = await Sharing.isAvailableAsync();
    if (sharingIsAvailable) {
      Sharing.shareAsync(resDownload.uri);
    } else {
      console.error("Sharing is not available");
    }
  } catch (error) {
    console.log("error", error);
  }

  return true;
};
