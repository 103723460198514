import { ApiResponse } from "apisauce";

import { ApiProblem, processResponse } from "./response-resolver";
import { Api } from "./api";

type CalibrationResult = { kind: "ok"; data: any } | ApiProblem;
type CalibrationValueProps = {
  calibrationUid: string;
  value?: number;
};

const SENSOR_CALIBRATION_PATH = "/SensorCalibration";

export enum CalibrationStatus {
  NotCalibrated = "NotCalibrated",
  Started = "Started",
  FirstValueStabilization = "FirstValueStabilization",
  FirstValueStart = "FirstValueStart",
  FirstValueStop = "FirstValueStop",
  SecondValueStabilization = "SecondValueStabilization",
  SecondValueStart = "SecondValueStart",
  SecondValueStop = "SecondValueStop",
  Completed = "Completed",
  Expired = "Expired",
  Error = "Error",
  Cancelled = "Cancelled",
}

const transformStatusResponse = ({
  calibrationUid,
  calibrationTimeInSeconds,
  status,
  ...data
}) => {
  // const calibrationStatus =
  //   ![
  //     CalibrationStatus.Completed,
  //     CalibrationStatus.Cancelled,
  //     CalibrationStatus.Error,
  //   ].includes(status) &&
  //   data.validUntil &&
  //   Date.parse(data.validUntil) < Date.now()
  //     ? CalibrationStatus.Expired
  //     : status;

  return {
    ...data,
    uid: calibrationUid,
    timeInSeconds: calibrationTimeInSeconds,
    calibrationStatus: status,
  };
};

export class SensorCalibrationApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async status(sensorUid: string): Promise<CalibrationResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `${SENSOR_CALIBRATION_PATH}/status/${sensorUid}`,
      );
      return processResponse(response, transformStatusResponse);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async start(sensorUid: string): Promise<CalibrationResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(
        `${SENSOR_CALIBRATION_PATH}/start`,
        { sensorUid },
      );
      return processResponse(response, ({ calibrationUid, ...data }) => ({
        ...data,
        uid: calibrationUid,
      }));
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async stop(calibrationUid: string): Promise<CalibrationResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(
        `${SENSOR_CALIBRATION_PATH}/stop`,
        { calibrationUid },
      );
      return processResponse(response, transformStatusResponse);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async firstValue(
    calibrationUid: string,
    value?: number,
  ): Promise<CalibrationResult> {
    const body: CalibrationValueProps = { calibrationUid };
    if (value) {
      body.value = value;
    }
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(
        `${SENSOR_CALIBRATION_PATH}/first_value`,
        body,
      );
      return processResponse(response, transformStatusResponse);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async secondValue(
    calibrationUid: string,
    value?: number,
  ): Promise<CalibrationResult> {
    const body: CalibrationValueProps = { calibrationUid };
    if (value) {
      body.value = value;
    }
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(
        `${SENSOR_CALIBRATION_PATH}/second_value`,
        body,
      );
      return processResponse(response, transformStatusResponse);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  // status
  // {
  //     "calibrationUid": "db4a4702-7b8d-434c-bc48-65b0587a19d3",
  //     "validUntil": "2024-01-06T14:31:36.527636+00:00",
  //     "calibrationTimeInSeconds": 120,
  //     "status": "FirstValueStop",
  //     "updatedAt": "2024-01-06T14:10:07.195206+00:00",
  //     "warnings": [
  //         {
  //             "code": "CalibrationOutOfRange",
  //             "message": "The analog signal from the sensor is outside the expected range. Please make sure you are following the instructions correctly.",
  //             "details": "Sensor ph:4"
  //         }
  //     ],
  //     "errors": []
  // }

  // {
  //     "calibrationUid": "aee3cf3f-047c-4eb3-b213-a4c3d174f73a",
  //     "validUntil": "2024-01-06T15:08:57.295465+00:00",
  //     "calibrationTimeInSeconds": 120,
  //     "status": "Error",
  //     "updatedAt": "2024-01-06T14:37:57.160013+00:00",
  //     "warnings": [],
  //     "errors": [
  //         {
  //             "code": "500",
  //             "message": "Incorrect calibration range.",
  //             "details": ""
  //         }
  //     ]
  // }

  // start
  // {
  //   "calibrationUid": "67a6780a-18a5-4acd-8b7d-0a7c9b31a501",
  //   "validUntil": "2024-01-05T16:52:23.0273913Z"
  // }

  // stop - OK
  // {
  //   "calibrationUid": "67a6780a-18a5-4acd-8b7d-0a7c9b31a501",
  //   "validUntil": "2024-01-05T16:52:23.027391+00:00",
  //   "calibrationTimeInSeconds": 120,
  //   "status": "Cancelled",
  //   "updatedAt": "0001-01-01T00:00:00",
  //   "warnings": [],
  //   "errors": []
  // }

  // stop - ERROR
  // {
  //   "type": "api",
  //   "error": "Calibration has already been completed.",
  //   "errorCode": 0
  // }
}
