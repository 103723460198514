import Svg, { SvgProps, Path } from "react-native-svg";

export const Success = (props: SvgProps) => (
  <Svg width={360} height={250} fill="none" viewBox="0 0 360 250">
    <Path
      d="M66.6942 139.332H91.9243C94.7943 139.332 96.5643 142.462 95.0942 144.922L82.7242 165.652C81.1942 168.212 77.4843 168.222 75.9443 165.652L63.5342 144.922C62.0542 142.462 63.8242 139.332 66.6942 139.332Z"
      fill="#B0EACC"
    />
    <Path
      d="M238.005 60.3555H280.165C284.955 60.3555 287.925 65.5755 285.465 69.6955L264.785 104.335C262.225 108.615 256.025 108.625 253.455 104.345L232.715 69.7055C230.245 65.5855 233.215 60.3555 238.005 60.3555Z"
      fill="#B0EACC"
    />
    <Path
      d="M254.544 216.725H102.924C85.6942 216.725 75.0242 197.945 83.8642 183.145L158.254 58.565C167.454 43.155 189.764 43.145 198.984 58.535L273.584 183.115C282.454 197.915 271.794 216.725 254.544 216.725Z"
      fill="#B0EACC"
    />
    <Path
      d="M252.382 177.408C267.975 176.262 280.034 167.421 279.317 157.66C278.599 147.9 265.377 140.917 249.784 142.063C234.191 143.209 222.132 152.051 222.85 161.811C223.567 171.571 236.789 178.554 252.382 177.408Z"
      fill="white"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M260.386 159.045L222.856 161.805"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M265.007 239.442C276.836 231.488 276.122 209.718 263.413 190.819C250.703 171.919 230.812 163.046 218.984 171C207.155 178.954 207.869 200.723 220.578 219.623C233.287 238.522 253.179 247.396 265.007 239.442Z"
      fill="white"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M260.387 232.553L218.997 171.003"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M231.257 230.823L239.207 201.053"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M262.167 189.233L228.207 184.693"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M271.217 217.503L248.197 214.433"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M235.627 214.443L223.027 222.923"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M270.797 210.083L261.667 216.233"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M115.636 129.802C131.229 128.656 143.288 119.815 142.571 110.055C141.853 100.295 128.631 93.3119 113.038 94.4579C97.4453 95.604 85.3862 104.445 86.1036 114.205C86.8209 123.965 100.043 130.948 115.636 129.802Z"
      fill="white"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M105.057 112.805L142.587 110.045"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M146.441 100.844C158.269 92.8899 157.555 71.1207 144.846 52.2209C132.137 33.3212 112.245 24.448 100.417 32.402C88.5888 40.356 89.3028 62.1252 102.012 81.0249C114.721 99.9246 134.613 108.798 146.441 100.844Z"
      fill="white"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M105.057 39.295L146.447 100.845"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M134.187 41.025L126.237 70.795"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M103.277 82.615L137.237 87.155"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M94.2268 54.345L117.237 57.415"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M129.817 57.405L142.417 48.925"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M94.6467 61.765L103.777 55.615"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M176.748 204.003C209.321 204.003 235.728 177.597 235.728 145.023C235.728 112.449 209.321 86.043 176.748 86.043C144.174 86.043 117.768 112.449 117.768 145.023C117.768 177.597 144.174 204.003 176.748 204.003Z"
      fill="white"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M125.688 145.023C125.688 116.823 148.547 93.963 176.747 93.963C186.047 93.963 194.767 96.453 202.277 100.793"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M227.818 145.023C227.818 173.223 204.958 196.083 176.758 196.083"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M195.158 128.293L176.268 147.183L157.108 128.023C152.358 123.273 144.668 123.273 139.928 128.023C135.178 132.773 135.188 140.463 139.928 145.213L167.848 173.113C172.508 177.773 180.068 177.773 184.728 173.113L234.118 123.723C238.868 118.973 238.868 111.283 234.118 106.543C229.368 101.793 221.678 101.793 216.938 106.543L210.968 112.513"
      fill="#B0EACC"
    />
    <Path
      d="M195.158 128.293L176.268 147.183L157.108 128.023C152.358 123.273 144.668 123.273 139.928 128.023C135.178 132.773 135.188 140.463 139.928 145.213L167.848 173.113C172.508 177.773 180.068 177.773 184.728 173.113L234.118 123.723C238.868 118.973 238.868 111.283 234.118 106.543C229.368 101.793 221.678 101.793 216.938 106.543L210.968 112.513"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </Svg>
);
