import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { useTheme } from "@react-navigation/native";
import { observer } from "mobx-react-lite";

import { Sensor, SensorState } from "@models/index";
import { Loading, Text } from "@components/ui";

import { palette, spacing } from "../../../../theme";
import { translate } from "../../../../i18n";

const createStyles = (theme: any) =>
  StyleSheet.create({
    container: {
      paddingHorizontal: spacing[4],
      marginVertical: spacing[4],
    },
    loading: {
      minHeight: 74,
    },
    valueContainer: {
      flexDirection: "row",
      alignItems: "baseline",
    },
    value: {
      fontSize: 40,
      fontWeight: "600", // TODO !
      marginRight: spacing[2],
    },
    valueUnit: {
      fontSize: 20,
      fontWeight: "500",
    },
    cardState: {
      color: palette.RedNew,
      fontSize: 40,
      ...theme.fonts.medium,
    },
  });

type Props = {
  sensor: Sensor;
  isLoading?: boolean;
};

export const SensorsDetailsCard = observer(({ sensor, isLoading }: Props) => {
  const theme = useTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <View style={styles.container}>
      {sensor.isLoading || isLoading ? (
        <Loading withMask={false} size="small" style={styles.loading} />
      ) : (
        <>
          <Text>{translate("Sensors.Details.currentValue")}</Text>

          {sensor.isOffline ||
          sensor.isDisconnected ||
          sensor.state === SensorState.InvalidValue ? (
            <Text style={styles.cardState}>{sensor.translatedState}</Text>
          ) : (
            <View style={styles.valueContainer}>
              <Text style={[styles.value]}>{sensor.displayValue}</Text>
              {sensor.unitName ? (
                <Text style={styles.valueUnit}>{sensor.unitName}</Text>
              ) : null}
            </View>
          )}
        </>
      )}
    </View>
  );
});
