import * as React from "react";
import { View } from "react-native";
import Svg, {
  SvgProps,
  G,
  Path,
  Circle,
  Defs,
  ClipPath,
} from "react-native-svg";

import { spacing } from "../../../../theme";

export const CalibrationCleanPHSvg = (props: SvgProps) => (
  <View style={{ marginTop: -spacing[8] }}>
    <Svg width={360} height={312} fill="none" {...props}>
      <G clipPath="url(#a)">
        <Circle
          cx={103}
          cy={298}
          r={11}
          fill="#B6C5D3"
          stroke="#fff"
          strokeWidth={2}
        />
        <Path
          fill="#fff"
          d="M104.374 304h-1.68v-8.32l.032-.672.032-.64a7.775 7.775 0 0 1-.432.4c-.149.128-.32.277-.512.448l-1.248 1.008-.864-1.088 3.264-2.56h1.408V304Z"
        />
        <Circle
          cx={255}
          cy={298}
          r={11}
          fill="#B6C5D3"
          stroke="#fff"
          strokeWidth={2}
        />
        <Path
          fill="#fff"
          d="M258.838 304h-7.68v-1.312l2.944-2.992a49.412 49.412 0 0 0 1.44-1.52c.384-.437.672-.853.864-1.248.192-.405.288-.843.288-1.312 0-.587-.171-1.024-.512-1.312-.341-.299-.784-.448-1.328-.448-.523 0-.992.101-1.408.304a6.609 6.609 0 0 0-1.296.816l-.912-1.136c.309-.267.64-.507.992-.72a5.31 5.31 0 0 1 1.2-.512 5.346 5.346 0 0 1 1.472-.192c.725 0 1.349.128 1.872.384.523.245.923.597 1.2 1.056.288.459.432.997.432 1.616 0 .459-.069.891-.208 1.296a4.89 4.89 0 0 1-.608 1.168 8.942 8.942 0 0 1-.944 1.168 42.9 42.9 0 0 1-1.232 1.248l-2.128 2.064v.08h5.552V304Z"
        />
        <Path
          fill="#EDF1F5"
          d="m248.729 271.88 30.217 7.379c2.541.62 4.737-1.024 5.345-3.568l-.058-.014c.608-2.544-.545-5.006-3.086-5.627l-76.537-18.691 10.183-22.006a14.514 14.514 0 0 0 1.005-9.19l-1.554-7.141a14.505 14.505 0 0 1 .769-8.645l9.382-22.673 73.576 17.967a4.762 4.762 0 0 1 3.498 5.728 4.704 4.704 0 0 1-5.699 3.481l-30.217-7.379-1.552 6.491a17.26 17.26 0 0 1-7.788 10.725l-4.048 2.473a17.262 17.262 0 0 0-7.789 10.724 17.256 17.256 0 0 1-7.788 10.725l-2.515 1.536a17.285 17.285 0 0 0-7.692 10.342l-2.942 11.178 55.559 13.568a4.346 4.346 0 0 0 5.255-3.197l17.218-70.676"
        />
        <Path
          stroke="#2B2E32"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={2.261}
          d="m248.729 271.88 30.217 7.379c2.541.62 4.737-1.024 5.345-3.568l-.058-.014c.608-2.544-.545-5.006-3.086-5.627l-76.537-18.691 10.183-22.006a14.514 14.514 0 0 0 1.005-9.19l-1.554-7.141a14.505 14.505 0 0 1 .769-8.645l9.382-22.673 73.576 17.967a4.762 4.762 0 0 1 3.498 5.728 4.704 4.704 0 0 1-5.699 3.481l-30.217-7.379-1.552 6.491a17.26 17.26 0 0 1-7.788 10.725l-4.048 2.473a17.262 17.262 0 0 0-7.789 10.724 17.256 17.256 0 0 1-7.788 10.725l-2.515 1.536a17.285 17.285 0 0 0-7.692 10.342l-2.942 11.178 55.559 13.568a4.346 4.346 0 0 0 5.255-3.197l17.218-70.676"
        />
        <Path
          fill="#fff"
          stroke="#2B2E32"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={2.261}
          d="M251.84 237.701a5.702 5.702 0 0 1-.765-2.858v-62.946h7.773v62.946a5.693 5.693 0 0 1-.766 2.858l-.053.096a3.549 3.549 0 0 0-.216 3.097l.562 1.393a3.89 3.89 0 0 1 .174 2.374l-.855 3.545a2.583 2.583 0 0 1-2.505 1.973H254.722a2.576 2.576 0 0 1-2.505-1.973l-.855-3.545a3.898 3.898 0 0 1 .173-2.374l.562-1.393a3.548 3.548 0 0 0-.215-3.097l-.042-.096Z"
        />
        <Path
          fill="#B6C5D3"
          d="M254.95 171.897h2.105v61.756c0 1.13-.204 2.248-.604 3.307l-1.489 3.952v.006-.006l-1.489-3.952a9.349 9.349 0 0 1-.604-3.307v-61.756h2.081Z"
        />
        <Path
          fill="#fff"
          stroke="#2B2E32"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={2.261}
          d="M252.163 171.897h5.626a3.164 3.164 0 0 0 3.163-3.163v-14.547a3.164 3.164 0 0 0-3.163-3.163h-5.626a3.164 3.164 0 0 0-3.163 3.163v14.547a3.164 3.164 0 0 0 3.163 3.163Z"
        />
        <Path
          fill="#3F9A6E"
          stroke="#2B2E32"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={2.261}
          d="m251.272 151.024 2.44-18.6h2.301l2.44 18.6-3.587-.251-3.594.251Z"
        />
        <Path
          stroke="#2B2E32"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={2.261}
          d="M254.836 132.424v-2.051c0-14.565 11.808-26.373 26.373-26.373 14.565 0 26.373 11.808 26.373 26.373v48.035c0 14.564 11.808 26.373 26.373 26.373h2.469c14.565 0 26.373-11.809 26.373-26.373v-6.733c0-14.564 11.809-26.373 26.373-26.373h22.445"
        />
        <Path
          fill="#fff"
          stroke="#2B2E32"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={2.261}
          d="M105.775 237.701a5.702 5.702 0 0 0 .765-2.858v-62.946h-7.772v62.946a5.7 5.7 0 0 0 .765 2.858l.054.096a3.55 3.55 0 0 1 .215 3.097l-.562 1.393a3.889 3.889 0 0 0-.173 2.374l.855 3.545a2.584 2.584 0 0 0 2.505 1.973H102.893c1.19 0 2.224-.813 2.505-1.973l.855-3.545a3.883 3.883 0 0 0-.173-2.374l-.562-1.393a3.548 3.548 0 0 1 .215-3.097l.042-.096Z"
        />
        <Path
          fill="#B6C5D3"
          d="M102.665 171.897H100.561v61.756c0 1.13.203 2.248.604 3.307l1.488 3.952v.006-.006l1.489-3.952a9.327 9.327 0 0 0 .604-3.307v-61.756h-2.081Z"
        />
        <Path
          fill="#fff"
          stroke="#2B2E32"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={2.261}
          d="M105.452 171.897h-5.626a3.164 3.164 0 0 1-3.163-3.163v-14.547a3.164 3.164 0 0 1 3.163-3.163h5.626a3.164 3.164 0 0 1 3.163 3.163v14.547a3.164 3.164 0 0 1-3.163 3.163Z"
        />
        <Path
          fill="#3F9A6E"
          stroke="#2B2E32"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={2.261}
          d="m106.343 151.024-2.439-18.6H101.602l-2.44 18.6 3.588-.251 3.593.251Z"
        />
        <Path
          stroke="#2B2E32"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={2.261}
          d="M102.779 132.424v-2.051c0-14.565-11.808-26.373-26.373-26.373-14.564 0-26.373 11.808-26.373 26.373v48.035c0 14.564-11.808 26.373-26.373 26.373h-2.469c-14.565 0-26.373-11.809-26.373-26.373v-6.733c0-14.564-11.808-26.373-26.373-26.373H-54"
        />
        <Path
          fill="#B6C5D3"
          d="M100 276c15.464 0 28-3.806 28-8.5 0-4.695-12.536-8.5-28-8.5s-28 3.805-28 8.5c0 4.694 12.536 8.5 28 8.5Z"
        />
        <Path
          fill="#70B8FF"
          fillOpacity={0.4}
          d="M103.5 233c13.531 0 24.5-3.358 24.5-7.5 0-4.142-10.969-7.5-24.5-7.5S79 221.358 79 225.5c0 4.142 10.969 7.5 24.5 7.5Z"
        />
        <Path
          fill="#308AE3"
          fillRule="evenodd"
          d="M128 225.504c-.007 4.14-10.974 7.496-24.5 7.496-13.531 0-24.5-3.358-24.5-7.5 0-.161.016-.321.05-.479l-.05.001v40.299c0 4.236 10.973 7.679 24.5 7.679s24.5-3.443 24.5-7.679v-39.817Zm0-.009V224l-.483.01c.316.48.482.977.483 1.485Z"
          clipRule="evenodd"
          opacity={0.6}
        />
        <Path
          stroke="#2B2E32"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={2.196}
          d="M128 204v61.326c0 4.233-10.998 7.674-24.554 7.674s-24.554-3.441-24.554-7.674v-50.213a6.67 6.67 0 0 0-2.042-4.806L73 206.208"
        />
        <Path
          stroke="#2B2E32"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={2.196}
          d="M98.5 197.19c-7.707.602-13.13 2.529-17.173 4.332-1.668.74-5.263 2.542-7.814 3.831-.846.43-.589 1.659.356 1.742l4.919.454c1.73.262 3.41.728 5.017 1.396 4.452 1.849 11.628 3.055 19.711 3.055 13.53 0 24.484-3.365 24.484-7.506.011-3.757-9.016-6.866-20.796-7.41"
        />
        <Path
          stroke="#2B2E32"
          strokeDasharray="4 4"
          d="M92.5 180c-4.542 1.18-7.5 3.105-7.5 5.28 0 3.59 8.059 6.5 18 6.5s18-2.91 18-6.5c0-2.175-2.958-4.1-7.5-5.28"
        />
        <Path
          stroke="#2B2E32"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={2.196}
          d="M121 217v30.5M121 252.5v10"
        />
        <Path
          fill="#EDF1F5"
          d="M297.83 199.432a4.813 4.813 0 0 1 3.515 5.754 4.657 4.657 0 0 1-5.676 3.467l-30.195-7.514-1.523 6.501a17.119 17.119 0 0 1-7.734 10.719l-4.031 2.463a17.114 17.114 0 0 0-7.734 10.718 17.115 17.115 0 0 1-7.733 10.719l-2.505 1.53a17.141 17.141 0 0 0-7.639 10.336l-2.893 11.192 55.517 13.815a4.3 4.3 0 0 0 5.234-3.183l16.914-70.771"
        />
        <Path
          stroke="#2B2E32"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={2.261}
          d="M297.83 199.432a4.813 4.813 0 0 1 3.515 5.754 4.657 4.657 0 0 1-5.676 3.467l-30.195-7.514-1.523 6.501a17.119 17.119 0 0 1-7.734 10.719l-4.031 2.463a17.114 17.114 0 0 0-7.734 10.718 17.115 17.115 0 0 1-7.733 10.719l-2.505 1.53a17.141 17.141 0 0 0-7.639 10.336l-2.893 11.192 55.517 13.815a4.3 4.3 0 0 0 5.234-3.183l16.914-70.771"
        />
        <Path
          fill="#fff"
          d="m102.464 241 .096.096a50.434 50.434 0 0 1 2.447 2.841 38.828 38.828 0 0 1 2.352 3.223 29.915 29.915 0 0 1 1.874 3.283c.43.884.8 1.803 1.087 2.734.191.633.346 1.277.453 1.934.12.728.167 1.468.12 2.22-.06 1.099-.311 2.149-.8 3.128a7.104 7.104 0 0 1-2.424 2.722 8.457 8.457 0 0 1-2.566 1.158c-.442.12-.884.203-1.326.263a9.019 9.019 0 0 1-1.79.071 9.664 9.664 0 0 1-3.606-.859 7.856 7.856 0 0 1-2.435-1.743 7.115 7.115 0 0 1-1.671-3.056 9.095 9.095 0 0 1-.263-2.674 12.96 12.96 0 0 1 .298-2.137c.275-1.194.693-2.34 1.206-3.45a26.906 26.906 0 0 1 1.827-3.307 40.63 40.63 0 0 1 2.459-3.415 50.773 50.773 0 0 1 2.555-2.972c.047 0 .071-.024.107-.06Z"
        />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path fill="#fff" d="M0 0h360v312H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  </View>
);
