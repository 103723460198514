import React, { useCallback, useEffect, useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { observer } from "mobx-react-lite";
import { StackScreenProps } from "@react-navigation/stack";
import { useIsFocused } from "@react-navigation/native";

import { Module } from "@models/module/module";
import { useStores } from "@models/index";
import { Screen } from "@components/index";
import { NoModules } from "@components/common";
import { HeadingWithActions, gridStyles, ErrorsMessage } from "@components/ui";

import { ModuleCard } from "../components";
import { ModulesNavigatorParamList } from "../navigation";
import { AddIcon } from "../../../svgs/menu";
import { spacing } from "../../../theme";
import { ModulesListHintsData } from "./hints";
import { translate } from "../../../i18n";

const styles = StyleSheet.create({
  message: {
    marginHorizontal: spacing[4],
    marginBottom: spacing[4],
  },
  list: {
    paddingHorizontal: spacing[4],
    marginTop: spacing[0],
  },
});

export const ModulesListScreen: React.FC<
  StackScreenProps<ModulesNavigatorParamList, "modules-list">
> = observer(({ navigation }) => {
  const addHandler = () => navigation.navigate("module-add-manual-onboarding");
  const actions = useMemo(
    () => [
      {
        key: "modules-add",
        icon: AddIcon,
        onPress: addHandler,
      },
    ],
    [],
  );

  const { moduleStore, accountStore, settingsStore } = useStores();
  const isFocused = useIsFocused();

  useEffect(() => {
    moduleStore.fetchModules();
  }, [accountStore.currentUser.id, isFocused]);

  const onRefresh = useCallback(async () => {
    await moduleStore.fetchModules({ force: true });
  }, []);

  if (moduleStore.isLoaded && !moduleStore.modules.length) {
    return (
      <Screen testID="ModulesList">
        <NoModules
          heading={translate("Modules.List.title")}
          description={translate("NoData.modulesNoModulesDescription")}
          actions={actions}
        />
      </Screen>
    );
  }

  return (
    <Screen
      testID="ModulesList"
      preset="scroll"
      header={<HeadingWithActions tx="Modules.List.title" actions={actions} />}
      onRefresh={onRefresh}
      refreshing={moduleStore.isLoading}
      hints={settingsStore.showHelpButton && ModulesListHintsData()}
    >
      <ErrorsMessage
        errors={moduleStore.statusErrors}
        onCancel={moduleStore.resetErrors}
        style={styles.message}
      />

      <View style={[gridStyles.container, styles.list]}>
        {moduleStore.sortedModules.map((module: Module) => (
          <ModuleCard
            key={module.uid}
            module={module}
            style={gridStyles.item}
          />
        ))}
      </View>
    </Screen>
  );
});
