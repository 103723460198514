import { ContextMenu, MenuItem } from "@components/ui";

import { ChevronDownIcon } from "../../svgs";
import { FilterButton } from "./FilterButton";

type FilterMenuProps<T> = {
  menuItems: MenuItem[];
  value: T;
  onChange: (key: T) => void;
  defaultValue?: string;
};

export const FilterMenu = <T extends string>({
  menuItems,
  value,
  defaultValue,
  onChange,
}: FilterMenuProps<T>) => {
  const selected = defaultValue && defaultValue !== value;
  const label = menuItems.find((item) => item.key === value)?.title || "-";
  const anchorProps = {
    label,
    selected,
    iconRight: ChevronDownIcon,
  };

  return (
    <ContextMenu
      menuItems={menuItems}
      onPress={onChange}
      anchor={FilterButton}
      anchorProps={anchorProps}
    />
  );
};
