import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

export const CalibrationDOAirProcessSvg = (props: SvgProps) => (
  <Svg width={286} height={172} fill="none" {...props}>
    <Path
      fill="#C4E1FE"
      fillOpacity={0.4}
      d="M215.855 170.086H64.235c-17.23 0-27.9-18.82-19.06-33.651l74.39-124.845c9.2-15.443 31.51-15.453 40.73-.03l74.6 124.844c8.87 14.832-1.79 33.682-19.04 33.682ZM43.008 20h40.984c4.662 0 7.537 4.987 5.15 8.906l-20.11 33.029c-2.486 4.079-8.513 4.095-11.014 0l-20.16-33.029c-2.387-3.919.488-8.906 5.15-8.906Z"
    />
    <Path
      fill="#6A7B8C"
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.261}
      d="M143.583 82.115h-7.578a4.266 4.266 0 0 1-4.26-4.268V58.211a4.266 4.266 0 0 1 4.26-4.267h7.578a4.266 4.266 0 0 1 4.26 4.267V77.84a4.268 4.268 0 0 1-4.26 4.276ZM144.824 53.943l-3.423-12.073h-3.215l-3.423 12.073 5.03-.155 5.031.155Z"
    />
    <Path
      stroke="#2A2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.261}
      d="M139.794 41.869v-1.597c0-11.342-9.243-20.537-20.644-20.537-11.406 0-20.65 9.195-20.65 20.537v37.404c0 11.342-9.243 20.537-20.644 20.537h-1.932c-11.402 0-20.645-9.195-20.645-20.537"
    />
    <Path
      fill="#6A7B8C"
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.261}
      d="M144.824 82.115h-10.061v66.405h10.061V82.115Z"
    />
    <Path
      fill="#6A7B8C"
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.261}
      d="M146.174 159.587h-12.76c-.918 0-1.669-.74-1.669-1.661v-7.746c0-.913.744-1.66 1.669-1.66h12.76a1.66 1.66 0 0 1 1.669 1.66v7.746a1.67 1.67 0 0 1-1.669 1.661Z"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.261}
      d="M134.763 143.489h10.061"
    />
    <Path
      fill="#308AE3"
      d="M230.501 47.478a5.088 5.088 0 0 1-5.083-5.094V29.145a5.088 5.088 0 0 1 5.083-5.094 5.088 5.088 0 0 1 5.084 5.094v13.247c-.008 2.806-2.283 5.086-5.084 5.086ZM230.501 111.227a5.088 5.088 0 0 1-5.083-5.094V92.886a5.088 5.088 0 0 1 5.083-5.094 5.088 5.088 0 0 1 5.084 5.094v13.247c-.008 2.814-2.283 5.094-5.084 5.094ZM250.615 67.643a5.088 5.088 0 0 1 5.084-5.095h13.218a5.088 5.088 0 0 1 5.084 5.095 5.088 5.088 0 0 1-5.084 5.094h-13.218a5.098 5.098 0 0 1-5.084-5.094ZM187 67.643a5.088 5.088 0 0 1 5.084-5.095h13.218a5.088 5.088 0 0 1 5.084 5.095 5.088 5.088 0 0 1-5.084 5.094h-13.218A5.098 5.098 0 0 1 187 67.643ZM244.725 53.382a5.098 5.098 0 0 1 0-7.201l9.343-9.364a5.073 5.073 0 0 1 7.186 0 5.099 5.099 0 0 1 0 7.202l-9.343 9.363a5.073 5.073 0 0 1-7.186 0ZM199.741 98.46a5.099 5.099 0 0 1 0-7.201l9.343-9.364a5.073 5.073 0 0 1 7.186 0 5.099 5.099 0 0 1 0 7.202l-9.344 9.363a5.072 5.072 0 0 1-7.185 0ZM199.741 36.817a5.072 5.072 0 0 1 7.185 0l9.344 9.364a5.098 5.098 0 0 1 0 7.2 5.073 5.073 0 0 1-7.186 0l-9.343-9.362a5.099 5.099 0 0 1 0-7.202Z"
    />
  </Svg>
);
