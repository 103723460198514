import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";

import { RuleType } from "@models/rule/rule";
import {
  DeviceHistoryView,
  DeviceMode,
  DeviceState,
  TDeviceHistoryLog,
} from "@models/device/types";
import { Text } from "@components/ui";

import { translate } from "../../../../../i18n";
import { useAppTheme } from "../../../../../hooks";
import { spacing } from "../../../../../theme";
import { DeviceHistoryRule } from "./DeviceHistoryRule";

const createStyles = (theme: any) =>
  StyleSheet.create({
    container: {
      padding: spacing[4],
      paddingVertical: spacing[3],
      marginBottom: spacing[2],
      backgroundColor: theme.colors.cardBG,
      borderRadius: spacing[4],
    },
    containerOffline: {
      backgroundColor: theme.colors.errorBG,
    },
    card: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    startDate: {
      fontSize: 12,
      lineHeight: 16,
      color: theme.colors.subtitle,
    },
    badges: {
      flexDirection: "row",
    },
    badge: {
      borderRadius: spacing[4],
      paddingHorizontal: spacing[3],
      paddingVertical: spacing[1],
      backgroundColor: theme.colors.cardStateBG,
      lineHeight: 16,
      fontSize: 14,
      marginLeft: spacing[2],
      ...theme.fonts.medium,
    },
    badgeOn: {
      backgroundColor: theme.colors.badgeOn,
      color: theme.colors.background,
    },
    badgeOff: {
      backgroundColor: theme.colors.warning,
      color: theme.colors.background,
    },
  });

const getTitle = (mode: DeviceMode, ruleType?: RuleType): string => {
  if (mode === DeviceMode.Manual) {
    return translate("Devices.History.manually");
  }

  if (mode === DeviceMode.Auto) {
    if (ruleType === RuleType.Schedule) {
      return translate("Devices.History.bySchedule");
    }
    if (ruleType === RuleType.Sensor) {
      return translate("Devices.History.bySensor");
    }
    return translate("Devices.History.byRule");
  }

  return translate("Devices.status.unknown");
};

const StateBadge = ({ state }: { state: DeviceState }) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  /* eslint-disable no-nested-ternary */
  const stateLabel =
    state === DeviceState.On
      ? translate("Devices.Details.turnOn")
      : state === DeviceState.Off
      ? translate("Devices.Details.turnOff")
      : "-";
  /* eslint-enable no-nested-ternary */

  return (
    <Text
      style={[
        styles.badge,
        state === DeviceState.Off ? styles.badgeOff : styles.badgeOn,
      ]}
    >
      {stateLabel}
    </Text>
  );
};

type DeviceHistoryCardProps = TDeviceHistoryLog & {
  type?: DeviceHistoryView;
};

export const DeviceHistoryCard = ({
  mode,
  startTime,
  state,
  rule,
  duration,
  type,
  isOffline,
}: DeviceHistoryCardProps) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  const title = getTitle(mode, rule?.ruleType);

  return (
    <View style={[styles.container, isOffline && styles.containerOffline]}>
      <View style={styles.card}>
        <Text style={styles.startDate}>{startTime}</Text>

        <View style={styles.badges}>
          {type === DeviceHistoryView.Compact && (
            <Text style={[styles.badge, styles.badgeOn]}>{duration}</Text>
          )}
          <Text style={styles.badge}>{title}</Text>
          {type === DeviceHistoryView.Detailed && <StateBadge state={state} />}
        </View>
      </View>

      {mode === DeviceMode.Auto ? <DeviceHistoryRule {...rule} /> : null}
    </View>
  );
};
