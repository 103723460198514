import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";

import { useStores } from "@models/root-store/root-store-context";
import { Rule } from "@models/rule/rule";
import { DeviceTypeControl } from "@screens/Devices/Add/DeviceTypeSelector";
import { Text } from "@components/ui";

import { uniq } from "../../../../../../utils/uniq";
import { spacing } from "../../../../../../theme";
import { translate } from "../../../../../../i18n";
import { useAppTheme } from "../../../../../../hooks";

const createStyles = (theme: any) =>
  StyleSheet.create({
    description: {
      color: theme.colors.label,
      marginBottom: spacing[3],
    },
  });

type DeviceTypeSelectorProps = {
  rules: Rule[];
  onPress: (type) => void;
};

export const DeviceTypeSelector = ({
  rules,
  onPress,
}: DeviceTypeSelectorProps) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  const { deviceStore } = useStores();
  const deviceTypeNames = uniq(rules?.map((rule) => rule.device.type) || []);
  const deviceTypes = deviceStore.visibleTypes.filter((deviceType) =>
    deviceTypeNames.includes(deviceType.name),
  );

  return (
    <View>
      <Text style={styles.description}>
        {translate("Devices.Rules.Copy.selectTypeDevice")}
      </Text>

      {deviceTypes.map((deviceType) => (
        <DeviceTypeControl
          key={deviceType.name}
          deviceType={deviceType}
          onPress={onPress}
          isInitial
        />
      ))}
    </View>
  );
};
