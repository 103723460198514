import { Platform, StyleSheet } from "react-native";

import { ModuleType } from "@models/module/module";
import { HintMenu, THintsDataItem } from "@components/hints";

import { translate } from "../../../../i18n";
import { getModuleDetailsMenuItems } from "../constants";
import {
  ModuleHydroTabsHint,
  ModulePumpCardHint,
  ModuleDeviceCardHint,
  ModuleSensorCardHint,
  ButtonModuleInfoHint,
} from "./components";
import { spacing } from "../../../../theme";

const top = Platform.OS === "web" ? 55 : -20;

const dialogStyle = StyleSheet.create({
  dialog: {
    position: "absolute",
    right: 0,
    left: 0,
    top,
  },

  dialogBottom: {
    position: "absolute",
    right: 0,
    left: 0,
    bottom: spacing[2],
  },
});

const ModuleDetailsHintMenu = () => HintMenu(getModuleDetailsMenuItems());

const MenuHint: THintsDataItem = {
  id: "MenuHint",
  title: translate("Hints.title"),
  text: translate("Hints.ModuleDetails.textHintMenu"),
  HintComponent: ModuleDetailsHintMenu,
};

const ModuleInfoHint: THintsDataItem = {
  id: "ButtonModuleInfoHint",
  title: translate("Hints.title"),
  text: translate("Hints.ModuleDetails.textHintButtonInfo"),
  HintComponent: ButtonModuleInfoHint,
  dialogStyle: dialogStyle.dialogBottom,
};

const PreviewHint: THintsDataItem[] = [MenuHint, ModuleInfoHint];

const ModuleSocketDetailsHintsData = (): THintsDataItem[] => [
  ...PreviewHint,
  {
    id: "ModuleDeviceCard",
    title: translate("Hints.title"),
    text: translate("Hints.ModuleDetails.textHintCard"),
    HintComponent: ModuleDeviceCardHint,
    dialogStyle: dialogStyle.dialog,
  },
  {
    id: "gotIt",
    title: translate("Hints.helpButtonTitle"),
    text: translate("Hints.helpButtonContent"),
  },
];

const ModuleSensorDetailsHintsData = (): THintsDataItem[] => [
  ...PreviewHint,
  {
    id: "ModuleSensorCard",
    title: translate("Hints.title"),
    text: translate("Hints.ModuleDetails.textHintCard"),
    HintComponent: ModuleSensorCardHint,
    dialogStyle: dialogStyle.dialog,
  },
  {
    id: "gotIt",
    title: translate("Hints.helpButtonTitle"),
    text: translate("Hints.helpButtonContent"),
  },
];

const ModuleHydroDetailsHintsData = (): THintsDataItem[] => [
  ...PreviewHint,
  {
    id: "ModuleHydroTabs",
    title: translate("Hints.title"),
    text: translate("Hints.ModuleDetails.textHintHydroTabs"),
    HintComponent: ModuleHydroTabsHint,
    dialogStyle: dialogStyle.dialog,
  },
  {
    id: "ModulePumpCard",
    title: translate("Hints.title"),
    text: translate("Hints.ModuleDetails.textHintCard"),
    HintComponent: ModulePumpCardHint,
    dialogStyle: dialogStyle.dialog,
  },
  {
    id: "gotIt",
    title: translate("Hints.helpButtonTitle"),
    text: translate("Hints.helpButtonContent"),
  },
];

export const getModuleDetailsHints = (type: ModuleType) => {
  if (type === ModuleType.Hydro) {
    return ModuleHydroDetailsHintsData();
  }

  if (type === ModuleType.Sensor) {
    return ModuleSensorDetailsHintsData();
  }

  return ModuleSocketDetailsHintsData();
};
