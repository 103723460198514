import React, { useMemo } from "react";
import { StyleSheet, View, ViewStyle } from "react-native";

import { Text } from "@components/ui";
import { LineChart, LineChartProps } from "@components/Chart";

import { spacing } from "../../../../../theme";
import { useAppTheme } from "../../../../../hooks";
import { HistorySkeleton } from "./HistorySkeleton";

const createStyles = (theme) =>
  StyleSheet.create({
    container: {
      backgroundColor: theme.colors.cardBG,
      borderRadius: spacing[2],
      padding: spacing[2],
      marginBottom: spacing[3],
    },
    chartTitle: {
      flexDirection: "row",
      alignItems: "center",
    },
    chartTitleText: {
      marginBottom: spacing[2],
      fontSize: 14,
      color: theme.colors.text,
      ...theme.fonts.medium,
    },
  });

type DiagnosticHistoryChartProps = LineChartProps & {
  title: string;
  isLoading: boolean;
  style?: ViewStyle;
};

export const DiagnosticHistoryChart = ({
  data,
  yMax,
  yMin,
  yPrecision,
  currentIndex,
  setCurrentIndex,
  color,
  lines,
  height,
  error,
  title,
  isLoading,
  style,
}: DiagnosticHistoryChartProps) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  if (isLoading) return <HistorySkeleton />;

  return (
    <View style={[styles.container, style]}>
      <View style={styles.chartTitle}>
        <Text style={styles.chartTitleText}>{title}</Text>
      </View>

      <LineChart
        data={data}
        yMax={yMax}
        yMin={yMin}
        yPrecision={yPrecision}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        color={color}
        error={error}
        lines={lines}
        height={height}
      />
    </View>
  );
};
