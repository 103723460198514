import { StyleProp, TextStyle, ViewStyle } from "react-native";
import { observer } from "mobx-react-lite";

import { Device, DeviceState } from "@models/device/device";
import { Badge, CountdownTimer, Text } from "@components/ui";

import { DeviceStateType } from "@models/device/types";
import { DisconnectIcon } from "../../../svgs";
import { translate } from "../../../i18n";

type DeviceCardStatusProps = {
  device: Device;
  style?: StyleProp<TextStyle>;
  badgeStyle?: StyleProp<ViewStyle>;
};

export const DeviceCardStatus: React.FunctionComponent<DeviceCardStatusProps> =
  observer(({ device, style, badgeStyle }) => {
    const { state, secondsToSwitchBack, error, fetchedAt } = device;

    const isCountdown =
      secondsToSwitchBack && device.stateType !== DeviceStateType.Continuously;

    if (error) {
      return (
        <>
          <Text style={style}>{device.displayState}</Text>
          <Badge icon={DisconnectIcon} text={error} />
        </>
      );
    }

    if (state === DeviceState.Offline) {
      return (
        <Badge text={translate("Devices.status.offline")} style={badgeStyle} />
      );
    }

    return (
      <>
        <Text style={style} numberOfLines={1} ellipsizeMode="tail">
          {device.displayState}
          {isCountdown ? ":" : ""}
        </Text>
        {isCountdown ? (
          <CountdownTimer
            seconds={secondsToSwitchBack}
            style={style}
            callback={device.refetch}
            initialTimestamp={fetchedAt}
          />
        ) : null}
      </>
    );
  });
