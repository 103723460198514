import React, { useState } from "react";
import { StyleProp, ViewStyle } from "react-native";
import * as LocalAuthentication from "expo-local-authentication";

import { Dialog } from "@components/ui";
import { useStores } from "@models/index";
import { IconButton } from "@components/common";

import { useAppTheme } from "../../../hooks";
import { useLocalAuthentication } from "../../../hooks/useLocalAuthentication";
import { authenticateUser } from "../../../services/localAuthentication";
import {
  SecureValueKey,
  saveSecureValue,
  getSecureValue,
} from "../../../utils/storage";
import { translate } from "../../../i18n";
import { FaceIdIcon, TouchIdIcon } from "../../../svgs";

type SignInWithBiometricProps = {
  style?: StyleProp<ViewStyle>;
};
export const SignInWithBiometric = ({ style }: SignInWithBiometricProps) => {
  const theme = useAppTheme();
  const { accountStore } = useStores();

  const [visibleDialog, setVisibleDialog] = useState<boolean>(false);
  const showDialog = () => setVisibleDialog(true);
  const closeDialog = () => setVisibleDialog(false);

  const { authenticationType, useBiometricToSignIn, hasLoginData } =
    useLocalAuthentication();

  const okDialog = () => {
    saveSecureValue(SecureValueKey.UseBiometricToSignIn, true);
    closeDialog();
  };
  const cancelDialog = () => {
    saveSecureValue(SecureValueKey.UseBiometricToSignIn, false);
    closeDialog();
  };

  // useEffect(() => {
  //   if (useBiometricToSignIn && authenticationType) {
  //     signIn();
  //   }
  // }, [useBiometricToSignIn]);

  const signIn = async () => {
    const isOk = await authenticateUser();

    if (isOk) {
      const loginData = await getSecureValue(SecureValueKey.LoginData);
      if (!loginData?.username) return;
      await accountStore.login(loginData.username, loginData.password);
    }
  };

  const onPress = () => {
    if (useBiometricToSignIn) {
      signIn();
    } else {
      showDialog();
    }
  };

  if (!authenticationType || !hasLoginData) return null;

  const icon =
    authenticationType ===
    LocalAuthentication.AuthenticationType.FACIAL_RECOGNITION
      ? FaceIdIcon
      : TouchIdIcon;

  return (
    <>
      <IconButton
        icon={icon}
        onPress={onPress}
        size={30}
        iconColor={theme.colors.background}
        style={style}
      />

      <Dialog
        title={translate("Authentication.dialogTitle")}
        content={translate("Authentication.dialogContent")}
        visible={visibleDialog}
        onDismiss={closeDialog}
        onOk={okDialog}
        okText={translate("common.allow")}
        onCancel={cancelDialog}
        cancelText={translate("common.cancel")}
      />
    </>
  );
};
