import Svg, { SvgProps, Path } from "react-native-svg";

export const NotFoundSvg = (props: SvgProps) => {
  return (
    <Svg width={212} height={140} fill="none" {...props}>
      <Path fill="#fff" d="M0 0h212v140H0z" />
      <Path
        fill="#F4F4F4"
        d="M155.862 123.758H56.116c-11.335 0-18.355-12.393-12.539-22.159l48.939-82.21c6.052-10.168 20.729-10.175 26.795-.02l49.077 82.21c5.835 9.766-1.178 22.179-12.526 22.179Z"
      />
      <Path
        stroke="#B6C5D3"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.5}
        d="M74.251 34.443H56.262c-14.611 0-26.457 11.954-26.457 26.566V60.9c0 14.612 11.846 26.458 26.457 26.458h100.684c11.93 0 21.597 9.38 21.597 21.311v.293c0 11.931-9.667 21.597-21.597 21.597h-14.102"
      />
      <Path
        stroke="#B6C5D3"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.5}
        d="M18.006 49.742c6.529 0 11.822 5.293 11.822 11.823"
      />
      <Path
        stroke="#B6C5D3"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.5}
        d="M16.677 57.23c-1.422-.703-2.604-1.7-3.64-2.875-1.638-1.854-2.836-3.971-3.802-6.235-.293-.696-.548-1.414-.81-2.125-.132-.356-.04-.557.308-.696 2.813-1.12 5.718-1.87 8.763-1.947 1.499-.038 2.959.147 4.389.61a7.161 7.161 0 0 1 4.682 8.763c-1.043 3.701-4.953 5.927-8.67 4.914-.417-.108-.819-.27-1.22-.41ZM159.348 113.336c6.53 0 11.823 5.293 11.823 11.823"
      />
      <Path
        stroke="#B6C5D3"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.5}
        d="M158.012 120.831c-1.422-.703-2.604-1.7-3.64-2.874-1.638-1.855-2.836-3.972-3.801-6.236-.294-.695-.549-1.414-.812-2.125-.131-.355-.038-.556.309-.695 2.813-1.121 5.718-1.87 8.763-1.947 1.499-.039 2.959.146 4.389.61a7.162 7.162 0 0 1 4.682 8.763c-1.043 3.701-4.953 5.926-8.669 4.914-.41-.116-.812-.27-1.221-.41ZM44.154 60.205c-6.53 0-11.823 5.293-11.823 11.823"
      />
      <Path
        stroke="#B6C5D3"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.5}
        d="M44.262 68.102c-3.717 1.013-7.627-1.205-8.67-4.914a7.165 7.165 0 0 1 4.683-8.763c1.43-.463 2.897-.649 4.389-.61 3.044.077 5.95.82 8.762 1.947.348.14.44.34.31.696-.264.71-.51 1.43-.812 2.125-.966 2.264-2.164 4.38-3.802 6.235-1.035 1.175-2.225 2.172-3.64 2.875-.401.139-.803.301-1.22.41ZM185.806 83.935c0 6.529-5.293 11.822-11.822 11.822"
      />
      <Path
        stroke="#B6C5D3"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.5}
        d="M178.311 82.598c.703-1.422 1.7-2.604 2.874-3.64 1.855-1.638 3.972-2.835 6.236-3.801.695-.294 1.414-.549 2.125-.812.355-.131.556-.038.695.31 1.121 2.812 1.87 5.717 1.947 8.762.039 1.499-.146 2.96-.61 4.389a7.162 7.162 0 0 1-8.763 4.682c-3.701-1.043-5.926-4.953-4.914-8.67.116-.409.278-.81.41-1.22ZM163.677 75.667c-6.53 0-11.823 5.293-11.823 11.823"
      />
      <Path
        stroke="#B6C5D3"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.5}
        d="M163.792 83.572c-3.716 1.012-7.626-1.206-8.669-4.915a7.165 7.165 0 0 1 4.682-8.762c1.43-.464 2.898-.649 4.389-.61 3.045.077 5.95.819 8.763 1.947.347.139.44.34.309.695-.263.711-.51 1.43-.812 2.125-.965 2.264-2.163 4.381-3.801 6.236-1.036 1.174-2.226 2.171-3.64 2.874-.409.14-.811.294-1.221.41ZM61.958 46.961c0-6.53-5.293-11.822-11.823-11.822"
      />
      <Path
        stroke="#B6C5D3"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.5}
        d="M54.053 47.07c-1.012-3.718 1.205-7.627 4.914-8.67a7.165 7.165 0 0 1 8.763 4.682c.463 1.43.649 2.898.61 4.389-.077 3.045-.819 5.95-1.947 8.763-.14.347-.34.44-.695.309-.711-.263-1.43-.51-2.125-.812-2.264-.966-4.382-2.163-6.236-3.802-1.175-1.035-2.172-2.225-2.875-3.639-.139-.41-.293-.811-.41-1.22ZM188.27 111.845c0-6.529-5.293-11.822-11.822-11.822"
      />
      <Path
        stroke="#B6C5D3"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.5}
        d="M180.366 111.954c-1.013-3.717 1.205-7.627 4.914-8.67a7.164 7.164 0 0 1 8.762 4.683c.464 1.429.649 2.897.611 4.389-.077 3.044-.819 5.949-1.947 8.762-.139.348-.34.44-.696.309-.711-.263-1.429-.51-2.125-.811-2.264-.966-4.381-2.164-6.235-3.802-1.175-1.035-2.172-2.225-2.875-3.639-.139-.402-.294-.804-.409-1.221ZM44.555 20.526c-4.62 4.62-4.62 12.108 0 16.721"
      />
      <Path
        stroke="#B6C5D3"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.5}
        d="M50.22 26.035c-1.917 3.346-6.252 4.544-9.605 2.659a7.157 7.157 0 0 1-2.883-9.505c.68-1.337 1.584-2.503 2.674-3.539 2.21-2.101 4.783-3.624 7.572-4.821.34-.147.55-.07.711.27.317.688.65 1.368.928 2.07.92 2.28 1.568 4.63 1.723 7.094.1 1.569-.039 3.107-.541 4.613-.201.387-.371.788-.58 1.16Z"
      />
      <Path
        fill="#fff"
        stroke="#B6C5D3"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.5}
        d="M106.324 47.481c-19.315 0-34.982 15.676-34.982 35.026a34.87 34.87 0 0 0 7.397 21.523c-.06.06-.119.109-.178.169l-14.537 14.554a7.892 7.892 0 0 0 0 11.149 7.868 7.868 0 0 0 11.136 0l14.536-14.554a8.094 8.094 0 0 0 1.13-1.44 34.826 34.826 0 0 0 15.498 3.624c19.315 0 34.982-15.676 34.982-35.025 0-19.35-15.667-35.026-34.982-35.026Zm0 62.178c-14.982 0-27.119-12.161-27.119-27.153 0-15 12.147-27.152 27.119-27.152 14.982 0 27.119 12.162 27.119 27.153 0 14.99-12.137 27.152-27.119 27.152Z"
      />
      <Path
        fill="#fff"
        stroke="#B6C5D3"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.5}
        d="M132.545 89.481c3.845-14.494-4.773-29.364-19.249-33.213-14.476-3.85-29.327 4.78-33.172 19.273-3.844 14.493 4.774 29.364 19.25 33.213 14.475 3.849 29.326-4.779 33.171-19.273Z"
      />
      <Path
        stroke="#B6C5D3"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.5}
        d="M125.401 82.507c0 7.912-4.799 14.693-11.65 17.602M87.247 82.507c0-10.554 8.537-19.102 19.077-19.102"
      />
    </Svg>
  );
};
