import React, { useMemo, useState } from "react";
import { Pressable, StyleSheet, View, ViewStyle } from "react-native";
import { observer } from "mobx-react-lite";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";

import { Device } from "@models/device/device";
import { Rule, RuleType } from "@models/rule/rule";
import { Badge, Loading, Text } from "@components/ui";
import { ConfirmDialog } from "@components/dialog";

import {
  CopyRuleIcon,
  GrowthStagesIcon,
  RoutineRuleIcon,
} from "../../../../svgs/devices/rules";
import { translate } from "../../../../i18n";
import { palette, spacing } from "../../../../theme";
import { useAppTheme } from "../../../../hooks";
import { normalizeFontSize } from "../../../../utils/sizes";
import { DevicesNavigatorParamList } from "../../navigation";
import { DeviceRuleControls } from "./DeviceRuleControls";
import { RulesSchedule } from "./RulesSchedule";
import { RulesSensor } from "./RulesSensor";
import { RulesDimmer } from "./RulesDimmer";

const DETAILS_COMPONENTS = {
  [RuleType.DimmerManual]: RulesDimmer,
  [RuleType.DimmerAuto]: RulesDimmer,
  [RuleType.Schedule]: RulesSchedule,
  [RuleType.Sensor]: RulesSensor,
};

const createStyles = (theme: any) =>
  StyleSheet.create({
    container: {
      paddingHorizontal: spacing[4],
      paddingVertical: spacing[2],
      borderWidth: 1,
      borderColor: theme.colors.menuBorder,
      borderRadius: spacing[4],
    },
    disabled: {
      opacity: 0.5,
    },

    badge: {
      zIndex: 1000,
      position: "absolute",
      top: -spacing[3],
      right: spacing[3],
    },

    automationType: {
      flex: 1,
      justifyContent: "flex-end",
      alignItems: "center",
    },
    automationTypeLabel: {
      fontSize: normalizeFontSize(16, 20),
      color: theme.colors.label,
      marginBottom: spacing[4],
    },
    newRuleBtn: {
      width: "100%",
      flexDirection: "row",
      alignItems: "center",
      backgroundColor: theme.colors.cardBgDark,
      marginBottom: spacing[4],
      borderRadius: spacing[4],
      padding: spacing[4],
    },
    newRuleBtnDisabled: {
      backgroundColor: theme.colors.cardDisabled,
    },
    icon: {
      marginRight: spacing[5],
    },
    description: {
      flex: 1,
    },
    title: {
      fontSize: normalizeFontSize(20, 24),
      lineHeight: 32,
      fontWeight: "500",
    },
    text: {
      fontSize: 12,
      lineHeight: 16,
    },
  });

type CardRuleProps = {
  Icon: React.FunctionComponent<any>;
  title: string;
  text: string;
  goTo: () => void;
  disabled?: boolean;
};

export const CardRule = ({
  Icon,
  title,
  text,
  goTo,
  disabled,
}: CardRuleProps) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  return (
    <Pressable
      style={[styles.newRuleBtn, disabled && styles.newRuleBtnDisabled]}
      onPress={goTo}
    >
      <View style={styles.icon}>
        <Icon size={52} />
      </View>
      <View style={styles.description}>
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.text}>{text}</Text>
      </View>
    </Pressable>
  );
};

const RuleStateBadge = ({ rule }: { rule: Rule }) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const [content, setContent] = useState<string | null>(null);

  const onSynchronizingPress = () =>
    setContent(translate("Devices.Rules.state.SynchronizingMessage"));
  const resetContent = () => setContent(null);

  return (
    <>
      {rule.isSynchronizing ? (
        <Pressable style={styles.badge} onPress={onSynchronizingPress}>
          <Badge
            text={translate("Devices.Rules.state.Synchronizing")}
            gradientColors={[palette.OrangeG2, palette.OrangeG1]}
          />
        </Pressable>
      ) : null}

      <ConfirmDialog
        title={translate("common.information")}
        onOk={resetContent}
        okText={translate("common.ok")}
        visible={Boolean(content)}
        content={content}
      />
    </>
  );
};

type RuleDetailsProps = {
  rule: Rule;
  style?: ViewStyle;
};
export const DeviceRuleDetails: React.VFC<RuleDetailsProps> = ({
  rule,
  style,
}) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const DetailsComponent = DETAILS_COMPONENTS[rule.ruleType] || RulesSensor;

  return (
    <View style={[styles.container, !rule.isRuleOn && styles.disabled, style]}>
      {rule.device?.isLoadingPartly ||
        (rule?.state === "Synchronizing" && (
          <Loading
            style={{ backgroundColor: theme.colors.tabBG }}
            color={theme.colors.primary}
          />
        ))}
      <RuleStateBadge rule={rule} />
      <DetailsComponent rule={rule} />
    </View>
  );
};

type DeviceDetailsRulesProps = {
  device: Device;
};

export const DeviceDetailsRules: React.FC<DeviceDetailsRulesProps> = observer(
  ({ device }) => {
    const theme = useAppTheme();
    const styles = useMemo(() => createStyles(theme), [theme]);
    const navigation =
      useNavigation() as StackNavigationProp<DevicesNavigatorParamList>;

    const goToRoutineRules = () => {
      navigation.navigate("device-rules-edit", { deviceUid: device.uid });
    };

    const goToGrowthStagesRule = () => {
      // TODO !!!
      console.log("goToGrowthStagesRule");
    };

    const { rule } = device;
    const isLoading = !device.isError && !device.isLoaded && !rule;

    if (isLoading) {
      return <Loading withMask={false} />;
    }

    // TODO: Add refetching rule status if rule.state === Synchronizing !!!
    // TODO: Add modifiedAt field to rule

    const goToCopyRule = () => {
      navigation.navigate("device-rules-copy", { deviceUid: device.uid });
    };

    if (!rule) {
      return (
        <View style={styles.automationType}>
          <Text style={styles.automationTypeLabel}>
            {translate("Devices.Details.setUpDeviceAutomationType")}
          </Text>
          <CardRule
            Icon={RoutineRuleIcon}
            title={translate("Devices.Details.routineRuleTitle")}
            text={translate("Devices.Details.routineRuleText")}
            goTo={goToRoutineRules}
          />

          <CardRule
            disabled
            Icon={GrowthStagesIcon}
            title={translate("Devices.Details.growthStagesTitle")}
            text={`${translate(
              "Devices.Details.growthStagesText",
            )} (coming soon...)`} // TODO: !!!
            goTo={goToGrowthStagesRule}
          />

          <CardRule
            Icon={CopyRuleIcon}
            title={translate("Devices.Details.copyRule")}
            text={translate("Devices.Details.copyRuleText")}
            goTo={goToCopyRule}
          />
        </View>
      );
    }

    return (
      <View>
        <DeviceRuleControls device={device} rule={rule} />
        <DeviceRuleDetails rule={rule} />
      </View>
    );
  },
);
