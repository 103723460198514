import { ApiResponse } from "apisauce";

import { Module, ModuleTypeType } from "@models/module/module";

import { ApiProblem, processResponse } from "./response-resolver";
import { Api } from "./api";
import { convertDeviceState } from "./devices-api";

const MODULES_PATH = "/Modules";
const MODULE_TYPES_PATH = "/Modules/types";
const CAN_UPDATE_PATH = "/can_update";
const COMMAND_PATH = "/commands";
const DIAGNOSTIC_HISTORY_PATH = "/diagnostic_history";
const ACTIVITY_HISTORY_PATH = "/activity_history";
const FIRMWARE_PATH = "/Firmware/stable";

export type GetModulesResult = { kind: "ok"; data: Module[] } | ApiProblem;
export type GetModuleTypesResult =
  | { kind: "ok"; data: ModuleTypeType[] }
  | ApiProblem;
export type GetModuleResult = { kind: "ok"; data: Module } | ApiProblem;
export type CanUpdateResult = { kind: "ok"; data: Module } | ApiProblem;
export type TestConnectionResult = { kind: "ok" } | ApiProblem;

type AnyResult = { kind: "ok"; data: any } | ApiProblem;

type TFirmware = {
  firmware: string;
  link: string;
};

type GetDiagnosticHistoryProps = {
  uid: string;
  startDate: string;
  endDate: string;
  limit?: number;
};

type GetActivityHistoryParams = {
  startDate: string;
  endDate: string;
};

export type FirmwareResult = { kind: "ok"; data: TFirmware } | ApiProblem;

export type ModuleDeleteType = {
  type: string;
  title: string;
  status: number;
  detail: string;
  instance: string;
  additionalProp1: string;
  additionalProp2: string;
  additionalProp3: string;
};

export type DeleteModuleResult =
  | { kind: "ok"; data: ModuleDeleteType }
  | ApiProblem;

const prepareData = (module: any): Module => {
  const devices = module.devices?.length
    ? module.devices.map((device) => ({
        ...device,
        state: convertDeviceState(device.state),
      }))
    : module.devices;

  return {
    ...module,
    createdDate: Date.parse(module.createdDate) || null,
    devices,
  };
};

export enum ModuleCommands {
  BlinkingStart = "BlinkingStart",
  BlinkingStop = "BlinkingStop",
  Reboot = "Reboot",
  LoggingOn = "LoggingOn",
  LoggingOff = "LoggingOff",
}

export type TModuleUpdate = {
  name?: string;
  lat?: number | null;
  lng?: number | null;
};

export class ModuleApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getModules(): Promise<GetModulesResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        MODULES_PATH,
      );
      return processResponse(response, (data) => data?.map(prepareData));
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async getModuleTypes(): Promise<GetModuleTypesResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        MODULE_TYPES_PATH,
      );
      return processResponse(response);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async getModule(uid: string): Promise<GetModuleResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `${MODULES_PATH}/${uid}`,
      );
      return processResponse(response, prepareData);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async update(
    uid: string,
    paramsUpdate: TModuleUpdate,
  ): Promise<GetModulesResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.put(
        `${MODULES_PATH}/${uid}`,
        paramsUpdate,
      );
      return processResponse(response);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async checkConnectionToModule(): Promise<TestConnectionResult> {
    try {
      // eslint-disable-next-line no-promise-executor-return
      await new Promise((resolve) => setTimeout(resolve, 500));

      return { kind: "ok" };
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async remove(uid: string): Promise<DeleteModuleResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.delete(
        `${MODULES_PATH}/${uid}`,
        { uid },
      );
      return processResponse(response);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async canUpdate(uid: string): Promise<CanUpdateResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `${MODULES_PATH}/${uid}${CAN_UPDATE_PATH}`,
      );
      return processResponse(response);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async sendCommand(
    uid: string,
    command: ModuleCommands,
    data: Record<string, any> = {},
  ): Promise<any> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(
        `${MODULES_PATH}/${uid}${COMMAND_PATH}/${command}`,
        data,
      );
      return processResponse(response);
    } catch (e) {
      return { kind: "error" };
    }
  }

  async getLastFirmware(): Promise<FirmwareResult> {
    try {
      const response: ApiResponse<FirmwareResult> = await this.api.apisauce.get(
        FIRMWARE_PATH,
      );
      return processResponse(
        response,
        (data) => (Array.isArray(data) ? data[0] : data) || {},
      );
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async getDiagnosticHistory({
    uid,
    startDate,
    endDate,
    limit,
  }: GetDiagnosticHistoryProps): Promise<AnyResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `${MODULES_PATH}/${uid}${DIAGNOSTIC_HISTORY_PATH}`,
        {
          startDate,
          endDate,
          limit,
        },
      );

      return processResponse(response, (data) => {
        return data.map((item) => ({
          ...item,
          timestamp: new Date(item.timestamp),
        }));
      });
    } catch (e) {
      return { kind: "error" };
    }
  }

  async getActivityHistory(
    uid: string,
    params: GetActivityHistoryParams,
  ): Promise<AnyResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `${MODULES_PATH}/${uid}${ACTIVITY_HISTORY_PATH}`,
        params,
      );

      return processResponse(response, (data) => {
        return data.map((item) => ({
          ...item,
          timestamp: new Date(item.timestamp),
        }));
      });
    } catch (e) {
      return { kind: "error" };
    }
  }
}
