import Svg, { SvgProps, Path } from "react-native-svg";

export const NewPasswordSetUpSuccess = (props: SvgProps) => (
  <Svg width={360} height={250} fill="none" {...props}>
    <Path
      d="M254.044 236.725h-151.62c-17.23 0-27.9-18.78-19.06-33.58l74.39-124.58c9.2-15.41 31.51-15.42 40.73-.03l74.6 124.58c8.87 14.8-1.79 33.61-19.04 33.61ZM67.698 153h25.23c2.87 0 4.64 3.13 3.17 5.59l-12.38 20.73c-1.53 2.56-5.24 2.57-6.78 0l-12.41-20.73c-1.47-2.46.3-5.59 3.17-5.59Z"
      fill="#B0EACC"
    />
    <Path
      d="M159.224 104.266h-38.24c-18.91 0-34.24 15.47-34.24 34.38v-.14c0 18.91 15.33 34.24 34.24 34.24h130.3c15.44 0 27.95 12.14 27.95 27.58v.38c0 15.44-12.51 27.95-27.95 27.95h-18.25"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M253.489 104h30.64c3.48 0 5.64 3.8 3.85 6.79l-15.03 25.17c-1.86 3.11-6.37 3.12-8.23.01l-15.07-25.17c-1.8-3 .36-6.8 3.84-6.8Z"
      fill="#B0EACC"
    />
    <Path
      d="M185.325 107.326c-2.16-.95-4.59-1.33-7.09-1.33-10.74 0-19.44 8.7-19.44 19.44v28.44h-9.01v-28.44c0-15.71 12.74-28.44 28.44-28.44 15.71 0 28.44 12.74 28.44 28.44v28.44h-9.01v-28.44c.02-.01.65-12.43-12.33-18.11Z"
      fill="#fff"
    />
    <Path
      d="M189.845 153.866h26.78c9.64 0 17.46 7.82 17.46 17.46v51.33c0 9.64-7.82 17.46-17.46 17.46h-76.79c-9.64 0-17.46-7.82-17.46-17.46v-51.33c0-9.64 7.82-17.46 17.46-17.46h38.4"
      fill="#fff"
    />
    <Path
      d="M189.845 153.866h26.78c9.64 0 17.46 7.82 17.46 17.46v51.33c0 9.64-7.82 17.46-17.46 17.46h-76.79c-9.64 0-17.46-7.82-17.46-17.46v-51.33c0-9.64 7.82-17.46 17.46-17.46h38.4M158.795 125.426v28.44h-9.01v-28.44c0-15.71 12.74-28.44 28.44-28.44 15.71 0 28.44 12.74 28.44 28.44v28.44h-9.01v-28.44c0-10.74-8.7-19.44-19.44-19.44-2.5 0-4.89.47-7.09 1.33"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M134.135 187.816v-14c0-4.81 3.9-8.71 8.71-8.71h70.44c4.81 0 8.71 3.9 8.71 8.71v46.13c0 4.81-3.9 8.71-8.71 8.71h-41.76"
      fill="#fff"
    />
    <Path
      d="M134.135 187.816v-14c0-4.81 3.9-8.71 8.71-8.71h70.44c4.81 0 8.71 3.9 8.71 8.71v46.13c0 4.81-3.9 8.71-8.71 8.71h-41.76"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M160.285 228.656h-17.44a8.71 8.71 0 0 1-8.71-8.71v-14.92"
      fill="#fff"
    />
    <Path
      d="M160.285 228.656h-17.44a8.71 8.71 0 0 1-8.71-8.71v-14.92"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M187.475 189.816c0-5.45-4.72-9.8-10.3-9.18-4.11.45-7.51 3.74-8.09 7.83a9.239 9.239 0 0 0 4.93 9.57l-.99 11.93a5.227 5.227 0 0 0 5.21 5.66c3.06 0 5.46-2.61 5.21-5.66l-.99-11.93c2.98-1.54 5.02-4.64 5.02-8.22Z"
      fill="#fff"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M71.474 124.065c8.45 0 15.3 6.85 15.3 15.3"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M69.754 133.755c-1.84-.91-3.37-2.2-4.71-3.72-2.12-2.4-3.67-5.14-4.92-8.07-.38-.9-.71-1.83-1.05-2.75-.17-.46-.05-.72.4-.9 3.64-1.45 7.4-2.42 11.34-2.52 1.94-.05 3.83.19 5.68.79a9.268 9.268 0 0 1 6.06 11.34c-1.35 4.79-6.41 7.67-11.22 6.36-.54-.14-1.06-.35-1.58-.53ZM254.394 206.366c8.45 0 15.3 6.85 15.3 15.3"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M252.664 216.066c-1.84-.91-3.37-2.2-4.71-3.72-2.12-2.4-3.67-5.14-4.92-8.07-.38-.9-.71-1.83-1.05-2.75-.17-.46-.05-.72.4-.9 3.64-1.45 7.4-2.42 11.34-2.52 1.94-.05 3.83.19 5.68.79a9.268 9.268 0 0 1 6.06 11.34c-1.35 4.79-6.41 7.67-11.22 6.36-.53-.15-1.05-.35-1.58-.53ZM105.314 137.606c-8.45 0-15.3 6.85-15.3 15.3"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M105.454 147.827c-4.81 1.31-9.87-1.56-11.22-6.36a9.274 9.274 0 0 1 6.06-11.341c1.85-.6 3.75-.84 5.68-.79 3.94.1 7.7 1.061 11.34 2.521.45.18.57.439.4.899-.34.92-.66 1.85-1.05 2.75-1.25 2.93-2.8 5.671-4.92 8.071-1.34 1.52-2.88 2.809-4.71 3.719-.52.18-1.04.391-1.58.531ZM288.634 168.316c0 8.45-6.85 15.3-15.3 15.3"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M278.934 166.586c.91-1.84 2.2-3.37 3.72-4.71 2.4-2.12 5.14-3.67 8.07-4.92.9-.38 1.83-.71 2.75-1.05.46-.17.72-.05.9.4 1.45 3.64 2.42 7.4 2.52 11.34.05 1.94-.19 3.83-.79 5.68a9.268 9.268 0 0 1-11.34 6.06c-4.79-1.35-7.67-6.41-6.36-11.22.15-.53.36-1.05.53-1.58ZM259.995 157.616c-8.45 0-15.3 6.85-15.3 15.3"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M260.145 167.846c-4.81 1.31-9.87-1.56-11.22-6.36a9.273 9.273 0 0 1 6.06-11.34c1.85-.6 3.75-.84 5.68-.79 3.94.1 7.7 1.06 11.34 2.52.45.18.57.44.4.9-.34.92-.66 1.85-1.05 2.75-1.25 2.93-2.8 5.67-4.92 8.07-1.34 1.52-2.88 2.81-4.71 3.72-.53.18-1.05.38-1.58.53ZM128.355 120.466c0-8.45-6.85-15.3-15.3-15.3"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M118.125 120.606c-1.31-4.81 1.56-9.87 6.36-11.22a9.273 9.273 0 0 1 11.34 6.06c.6 1.85.84 3.75.79 5.68-.1 3.94-1.06 7.7-2.52 11.34-.18.45-.44.57-.9.4-.92-.34-1.85-.66-2.75-1.05-2.93-1.25-5.67-2.8-8.07-4.92-1.52-1.34-2.81-2.88-3.72-4.71-.18-.53-.38-1.05-.53-1.58ZM291.823 204.437c0-8.45-6.85-15.3-15.3-15.3"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M281.594 204.577c-1.31-4.81 1.56-9.87 6.36-11.22a9.273 9.273 0 0 1 11.34 6.06c.6 1.85.84 3.75.79 5.68-.1 3.94-1.06 7.7-2.52 11.34-.18.45-.44.57-.9.4-.92-.34-1.85-.66-2.75-1.05-2.93-1.25-5.67-2.8-8.07-4.92-1.52-1.34-2.81-2.88-3.72-4.71-.18-.52-.38-1.04-.53-1.58ZM105.834 86.255c-5.98 5.98-5.98 15.67 0 21.64"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M113.163 93.385c-2.48 4.33-8.09 5.88-12.43 3.44a9.263 9.263 0 0 1-3.73-12.3c.88-1.73 2.05-3.24 3.461-4.58 2.86-2.72 6.189-4.69 9.799-6.24.44-.19.71-.09.92.35.41.89.841 1.77 1.201 2.68 1.19 2.95 2.029 5.99 2.229 9.18.13 2.03-.05 4.02-.7 5.97-.26.5-.48 1.02-.75 1.5Z"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M209.764 142.565c14.785 0 26.77-11.985 26.77-26.77 0-14.784-11.985-26.77-26.77-26.77s-26.77 11.986-26.77 26.77c0 14.785 11.985 26.77 26.77 26.77Z"
      fill="#fff"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="m218.114 108.195-8.57 8.57-8.7-8.7a5.523 5.523 0 0 0-7.8 0 5.523 5.523 0 0 0 0 7.8l12.67 12.66a5.426 5.426 0 0 0 7.66 0l22.42-22.42a5.522 5.522 0 0 0 0-7.8 5.523 5.523 0 0 0-7.8 0l-2.71 2.71"
      fill="#B0EACC"
    />
    <Path
      d="m218.114 108.195-8.57 8.57-8.7-8.7a5.523 5.523 0 0 0-7.8 0 5.523 5.523 0 0 0 0 7.8l12.67 12.66a5.426 5.426 0 0 0 7.66 0l22.42-22.42a5.522 5.522 0 0 0 0-7.8 5.523 5.523 0 0 0-7.8 0l-2.71 2.71"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
  </Svg>
);
