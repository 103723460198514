import { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { View, StyleSheet } from "react-native";

import { Text } from "@components/ui";
import { useStores } from "@models/index";
import { CustomControl } from "@components/inputs";
import {
  NotificationChannel,
  TNotificationType,
} from "@models/notifications/notifications-store";
import {
  NotificationEntityType,
  NotificationType,
} from "@models/notifications/constants";

import { useAppTheme, useNotificationTypeEntities } from "../../../../hooks";
import { translate } from "../../../../i18n";
import { defaultColors, spacing } from "../../../../theme";
import { normalizeFontSize } from "../../../../utils/sizes";
import { ChevronDownIcon, ChevronRightIcon, CloseIcon } from "../../../../svgs";
import { NotificationTypeIcon } from "../components/NotificationTypeIcon";

const createStyles = (theme: any) =>
  StyleSheet.create({
    customControl: {
      padding: spacing[4],
      justifyContent: "space-between",
    },
    content: {
      flex: 1,
      flexDirection: "column",
    },
    header: {
      flexDirection: "row",
      alignItems: "center",
    },
    typeName: {
      color: theme.colors.text,
      fontSize: normalizeFontSize(16, 18),
      ...theme.fonts.medium,
    },
    description: {
      fontSize: normalizeFontSize(12, 14),
      marginTop: spacing[2],
    },
    info: {
      flexDirection: "row",
      marginTop: spacing[3],
    },
    state: {
      flexDirection: "row",
      marginRight: spacing[3],
    },
    stateText: {
      fontSize: normalizeFontSize(12, 16),
      color: theme.colors.subtitle,
    },
  });

const iconStateStyles = () =>
  StyleSheet.create({
    container: {
      marginRight: spacing[1],
    },
  });

type IconStateProps = {
  state: boolean;
};

const IconState = ({ state }: IconStateProps) => {
  const styles = iconStateStyles();

  return (
    <View style={styles.container}>
      {state ? (
        <ChevronDownIcon size={20} color={defaultColors.cardActive} />
      ) : (
        <CloseIcon size={20} color={defaultColors.subtitle} />
      )}
    </View>
  );
};

type NotificationsPreferencesItemProps = {
  nType: TNotificationType;
  onPress: (type: string) => void;
};

export const NotificationsPreferencesCard = observer(
  ({ nType, onPress }: NotificationsPreferencesItemProps) => {
    const theme = useAppTheme();
    const styles = useMemo(() => createStyles(theme), [theme]);
    const { notificationsStore } = useStores();

    const { type, preferencesTitle, preferencesDescription, entityType } =
      nType;

    const stateEmail: boolean = notificationsStore.preferences.enabled(
      type,
      NotificationChannel.Email,
    );

    const statePush: boolean = notificationsStore.preferences.enabled(
      type,
      NotificationChannel.Push,
    );

    const onPressItem = () => {
      onPress(type);
    };

    const { entities } = useNotificationTypeEntities(
      entityType as NotificationEntityType,
    );

    const numberDisableEquipment =
      notificationsStore.preferences.numberDisableEquipment(type);

    const entitiesCount = entities.length;

    const infoEquipment = (): string => {
      if (entityType === NotificationEntityType.Devices) {
        const numberEnabledDevices = entitiesCount - numberDisableEquipment;

        return entitiesCount === numberEnabledDevices
          ? translate("Settings.notifications.preferences.devices.all")
          : `${numberEnabledDevices}/${entitiesCount} ${translate(
              "Settings.notifications.preferences.devices.name",
            )}`;
      }

      if (entityType === NotificationEntityType.Modules) {
        const numberEnabledModules = entitiesCount - numberDisableEquipment;

        return entitiesCount === numberEnabledModules
          ? translate("Settings.notifications.preferences.modules.all")
          : `${numberEnabledModules}/${entitiesCount} ${translate(
              "Settings.notifications.preferences.modules.name",
            )}`;
      }

      if (entityType === NotificationEntityType.Sensors) {
        const numberEnabledSensors = entitiesCount - numberDisableEquipment;

        return entitiesCount === numberEnabledSensors
          ? translate("Settings.notifications.preferences.sensors.all")
          : `${numberEnabledSensors}/${entitiesCount} ${translate(
              "Settings.notifications.preferences.sensors.name",
            )}`;
      }

      return "";
    };

    return (
      <CustomControl
        onPress={onPressItem}
        control={<ChevronRightIcon color={theme.colors.label} />}
        style={styles.customControl}
      >
        <View style={styles.content}>
          <View style={styles.header}>
            <NotificationTypeIcon type={type as NotificationType} />
            <Text
              style={styles.typeName}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {preferencesTitle}
            </Text>
          </View>

          <Text
            style={styles.description}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {preferencesDescription}
          </Text>

          <View style={styles.info}>
            <View style={styles.state}>
              <IconState state={statePush} />
              <Text
                style={styles.stateText}
                numberOfLines={1}
                ellipsizeMode="tail"
                tx="Settings.notifications.push"
              />
            </View>

            <View style={styles.state}>
              <IconState state={stateEmail} />
              <Text
                style={styles.stateText}
                numberOfLines={1}
                ellipsizeMode="tail"
                tx="Settings.notifications.email"
              />
            </View>

            <View style={styles.state}>
              <Text
                style={styles.stateText}
                numberOfLines={1}
                ellipsizeMode="tail"
              >
                {infoEquipment()}
              </Text>
            </View>
          </View>
        </View>
      </CustomControl>
    );
  },
);
