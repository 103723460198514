import RN from "react-native";
import * as Location from "expo-location";

const CHANGE_WIFI_STATE_PERMISSION = "android.permission.CHANGE_WIFI_STATE";
const CHANGE_NETWORK_STATE_PERMISSION =
  "android.permission.CHANGE_NETWORK_STATE";
const NEARBY_WIFI_DEVICES = "android.permission.NEARBY_WIFI_DEVICES";
const NETWORK_PERMISSIONS = [
  CHANGE_WIFI_STATE_PERMISSION,
  CHANGE_NETWORK_STATE_PERMISSION,
  NEARBY_WIFI_DEVICES,
  "android.permission.ACCESS_WIFI_STATE",
  "android.permission.ACCESS_NETWORK_STATE",
  "android.permission.INTERNET",
];

export const requestLocationPermissions = async (): Promise<boolean> => {
  if (RN.Platform.OS === "android") {
    const granted = await RN.PermissionsAndroid.request(
      RN.PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION,
    );
    return granted === RN.PermissionsAndroid.RESULTS.GRANTED;
  }

  if (RN.Platform.OS === "ios") {
    const { status } = await Location.requestForegroundPermissionsAsync();
    return status === Location.PermissionStatus.GRANTED;
  }

  return true;
};

export const requestNetworkPermissions = async (): Promise<
  Record<string, string> | undefined
> => {
  if (RN.Platform.OS === "android") {
    const granted = await RN.PermissionsAndroid.request(NEARBY_WIFI_DEVICES); // TODO: For 32 version and above - https://developer.android.com/develop/connectivity/wifi/wifi-permissions

    const result =
      (await RN.PermissionsAndroid.requestMultiple(
        NETWORK_PERMISSIONS as RN.Permission[],
      )) || {};
    result[CHANGE_WIFI_STATE_PERMISSION] = await RN.PermissionsAndroid.check(
      CHANGE_WIFI_STATE_PERMISSION as RN.Permission,
    );
    result[CHANGE_NETWORK_STATE_PERMISSION] = await RN.PermissionsAndroid.check(
      CHANGE_NETWORK_STATE_PERMISSION as RN.Permission,
    );

    result.Version = RN.Platform.Version; // https://reactnative.dev/docs/platform-specific-code
    result["NEARBY_WIFI_DEVICES-granted"] = granted;
    result[NEARBY_WIFI_DEVICES] = await RN.PermissionsAndroid.check(
      NEARBY_WIFI_DEVICES as RN.Permission,
    );

    return result;
  }

  return undefined;
  // return requestMultiple(NETWORK_PERMISSIONS as Permission[]); // Not working for NETWORK_PERMISSIONS
};
