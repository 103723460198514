import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { palette } from "../../theme";

export default function DosingPumpIcon({ color = palette.Gray, size = 24 }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 36 36" fill="none">
      <Path
        fill={color}
        fillRule="evenodd"
        d="M25.886 5.262c.438.236.865.502 1.277.797a1.001 1.001 0 0 0 .06 1.348l1.415 1.414a1 1 0 0 0 1.348.06c.295.413.56.84.797 1.278a12.1 12.1 0 0 1 0 11.466c-.237.438-.503.864-.798 1.277a1 1 0 0 0-1.347.061l-1.414 1.414a1 1 0 0 0-.061 1.347 12.08 12.08 0 0 1-7.746 2.218l-5.628 5.628a1 1 0 0 1-1.414 0l-1.414-1.415a1 1 0 0 1 0-1.414l3.963-3.963a12.055 12.055 0 0 1-3.308-2.35 12.096 12.096 0 0 1-1.855-2.386l-3.75 3.75a1 1 0 0 1-1.414 0l-1.414-1.415a1 1 0 0 1 0-1.414l5.064-5.064a12.096 12.096 0 0 1 1.276-7.74c.237-.438.503-.864.797-1.277a.997.997 0 0 0 1.348-.061l1.414-1.414a1 1 0 0 0 .06-1.348c.413-.295.84-.56 1.278-.797a12.1 12.1 0 0 1 11.466 0ZM8.662 19.605 4.597 23.67l.707.707 3.743-3.743a11.999 11.999 0 0 1-.385-1.03Zm7.732 7.762.047.016-4.066 4.065.707.707 4.472-4.472c-.39-.085-.778-.19-1.16-.316Zm-1.52-20.64a10.596 10.596 0 0 1 10.557-.001c.007.63.25 1.26.732 1.741l1.415 1.415a2.49 2.49 0 0 0 1.741.732 10.596 10.596 0 0 1 0 10.556 2.49 2.49 0 0 0-1.741.732l-1.415 1.415a2.49 2.49 0 0 0-.732 1.74c-4.042 2.331-9.298 1.767-12.754-1.689-3.456-3.456-4.02-8.712-1.69-12.754a2.49 2.49 0 0 0 1.741-.732l1.415-1.415a2.49 2.49 0 0 0 .731-1.74Zm5.64 9.525a.512.512 0 1 0-.725-.725.512.512 0 0 0 .725.725Zm1.06 1.06a2.012 2.012 0 1 0-2.845-2.845 2.012 2.012 0 0 0 2.845 2.845Zm3.175-6.017a6.5 6.5 0 1 1-9.193 9.193 6.5 6.5 0 0 1 9.193-9.193Zm1.06-1.06a8 8 0 1 1-11.314 11.314A8 8 0 0 1 25.81 10.235Z"
        clipRule="evenodd"
      />
    </Svg>
  );
}
