import {
  applySnapshot,
  flow,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";
import { values } from "mobx";

import { Module, ModuleModel, ModuleTypeModel } from "./module";
import { ModuleApi } from "../../services/api";
import { withEnvironment } from "../extensions/with-environment";
import { withStatus } from "../extensions/with-status";
import { withRootStore } from "../extensions/with-root-store";
import {
  IsFetchTimeProps,
  withFetchTimeout,
} from "../extensions/with-fetch-timeout";

type ModulesWithFeeSocketsParams = {
  deviceType?: string;
};

const FETCH_MODULES_ALIAS = "fetchModules";
const FETCH_MODULE_TYPES_ALIAS = "fetchModuleTypes";

export const ModuleStoreModel = types
  .model("ModulesStore")
  .props({
    modules: types.optional(types.array(ModuleModel), []),
    moduleTypes: types.map(ModuleTypeModel),
  })
  .extend(withEnvironment)
  .extend(withStatus)
  .extend(withRootStore)
  .extend(withFetchTimeout)
  .actions((self) => ({
    clear: () => {
      self.modules.clear();
      self.moduleTypes.clear();
      self.clearFetchingTimes();
    },
  }))
  .actions((self) => {
    const moduleApi = new ModuleApi(self.environment.api);

    const updateModulesData = (data: Module[]) => {
      const dataWithDevicesAndPins = data.map((moduleData) => {
        const module = self.modules.find((item) => moduleData.uid === item.uid);
        return {
          pins: module?.pins ? getSnapshot(module?.pins) : [],
          devices: module?.devices ? getSnapshot(module?.devices) : [],
          ...moduleData,
        };
      });
      self.modules.replace(dataWithDevicesAndPins);
    };

    /* eslint-disable consistent-return */
    const fetchModules = flow(function* (fetchProps: IsFetchTimeProps = {}) {
      if (!self.isFetchTime(FETCH_MODULES_ALIAS, fetchProps)) return undefined;

      self.setStatusPending();
      const result = yield moduleApi.getModules();

      if (result.kind === "ok") {
        self.setStatusDone();
        self.resetErrors();
        updateModulesData(result.data);
      } else {
        self.setStatusError(result.errors);
        self.clearFetchTime(FETCH_MODULES_ALIAS);
        if (__DEV__) console.log(result.kind);
      }
    });
    /* eslint-enable consistent-return */

    /* eslint-disable consistent-return */
    const fetchModuleTypes = flow(function* (
      fetchProps: IsFetchTimeProps = {},
    ) {
      if (
        !self.isFetchTime(FETCH_MODULE_TYPES_ALIAS, {
          timeoutInSec: 60 * 5,
          ...fetchProps,
        })
      )
        return undefined;

      self.setStatusPending();
      const result = yield moduleApi.getModuleTypes();

      if (result.kind === "ok") {
        self.setStatusDone();
        self.resetErrors();
        result.data.forEach((dataItem) => self.moduleTypes.put(dataItem));
      } else {
        self.setStatusError(result.errors);
        self.clearFetchTime(FETCH_MODULE_TYPES_ALIAS);
        if (__DEV__) console.log(result.kind);
      }
    });
    /* eslint-enable consistent-return */

    const getModule = (uid: string) => {
      if (!uid) return undefined;
      return self.modules.find((item) => item.uid === uid);
    };

    const fetchModule = flow(function* (uid: string) {
      let module = getModule(uid);
      module?.update.reset();

      if (!module) {
        self.setStatusPending();
      } else {
        module?.setStatusPending();
      }

      const result = yield moduleApi.getModule(uid);

      if (result.kind === "ok") {
        module = getModule(uid);
        if (module) {
          applySnapshot(module, result.data);
          module.setStatusDone();
          return module;
        }

        self.modules.push(result.data);
        self.setStatusDone();
        return getModule(uid);
      }

      self.setStatusError(result.errors);
      module?.setStatusError(result.errors);
      if (__DEV__) console.log(result.kind);
      return null;
    });

    const modulesWithFeeSockets = ({
      deviceType,
    }: ModulesWithFeeSocketsParams = {}) => {
      let notConnectedDevices = self.rootStore.deviceStore.notConnected;
      if (deviceType) {
        const connectorTypes =
          self.rootStore.deviceStore.connectorTypesByType(deviceType);
        notConnectedDevices = notConnectedDevices.filter((item) =>
          connectorTypes.includes(item.connectorType),
        );
      }
      const modulesUids = notConnectedDevices
        .map((device) => device.moduleUid)
        .filter((item, i, _self) => item && _self.indexOf(item) === i);

      return self.modules.filter((module) => modulesUids.includes(module.uid));
    };

    const removeModule = flow(function* (uid: string) {
      const module = getModule(uid);
      const isOk = yield module.remove();

      if (isOk) {
        self.modules.replace(self.modules.filter((m) => m.uid !== uid));
      }
      self.rootStore.sensorStore.clearCache();

      return isOk;
    });

    return {
      fetchModules,
      getModule,
      fetchModuleTypes,
      fetchModule,
      modulesWithFeeSockets,
      removeModule,
    };
  })
  .actions((self) => ({
    afterUserLogin: async () => {
      await self.fetchModuleTypes();
    },
  }))
  .views((self) => ({
    get sortedModules() {
      return (values(self.modules) as Module[]).sort(
        (a, b) => (b.createdDate || 0) - (a.createdDate || 0),
      );
    },
    get modulesNames() {
      return (values(self.modules) as Module[]).map((module) => module.name);
    },

    get modulesToUpdate() {
      return self.modules.filter(
        (module) => module.isOnline && module.isFirmwareUpdateAvailable,
      );
    },
  }));

type ModuleStoreType = Instance<typeof ModuleStoreModel>;
export type ModuleStore = ModuleStoreType;
