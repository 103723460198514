import React from "react";
import {
  View,
  Pressable,
  StyleSheet,
  StyleProp,
  ViewStyle,
  TextStyle,
} from "react-native";
import { observer } from "mobx-react-lite";
import { LinearGradient } from "expo-linear-gradient";

import { Sensor, SensorState } from "@models/index";
import { Text } from "@components/ui";

import { palette, spacing } from "../../../../theme";
import * as SensorIcons from "../../../../svgs/sensors";
import { NoneIcon, WiFiOffIcon } from "../../../../svgs";
import { useAppTheme, useGoToSensorDetails } from "../../../../hooks";
import { normalizeFontSize } from "../../../../utils/sizes";

const createStyles = (theme: any) =>
  StyleSheet.create({
    card: {
      overflow: "hidden",
      alignItems: "flex-start",
      borderRadius: spacing[4],
      backgroundColor: palette.BlueLightBackground,
    },
    [`card-${SensorState.Disconnect}`]: {
      backgroundColor: palette.RedLight,
    },

    cardName: {
      width: "100%",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: spacing[2],
    },
    cardNameIcon: {
      marginRight: spacing[1],
    },
    cardTypeText: {
      fontSize: 10,
    },
    cardNameText: {
      flexShrink: 1,
    },

    cardValue: {
      flexDirection: "row",
      flexShrink: 1,
      marginTop: spacing[3],
      alignItems: "baseline",
    },
    cardValueValue: {
      fontSize: normalizeFontSize(32),
      fontWeight: "500",
      marginRight: spacing[1],
      marginLeft: 2,
      ...theme.fonts.bold,
    },
    cardValueUnit: {
      fontSize: normalizeFontSize(14, 18),
      fontWeight: "500",
    },

    badge: {
      fontSize: 12,
      paddingHorizontal: spacing[2],
      lineHeight: 20,
      position: "absolute",
      right: 0,
      top: 0,
      borderTopRightRadius: spacing[2],
      borderBottomLeftRadius: spacing[2],
    },
    offlineBox: {
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      marginTop: spacing[5],
      marginBottom: spacing[2],
    },
    offlineText: {
      fontSize: 16,
      marginHorizontal: spacing[3],
      color: palette.RedNew,
      ...theme.fonts.medium,
    },
    disconnectText: {
      color: palette.RedNew,
      fontSize: 16,
      marginTop: spacing[6],
      ...theme.fonts.medium,
    },
    calibrateLabel: {
      zIndex: 10,
      position: "absolute",
      right: 8,
      top: 8,
      paddingHorizontal: spacing[3],
      paddingVertical: 2,
      alignItems: "center",
      justifyContent: "center",
      borderRadius: spacing[4],
      textTransform: "uppercase",
    },
    gradientContainer: {
      width: "100%",
      padding: spacing[2],
      borderRadius: spacing[4],
    },
  });

interface SensorCardProps {
  style?: StyleProp<ViewStyle>;
  sensor: Sensor;
  showOfflinePopup: () => void;
}

type SensorCardValueProps = {
  sensor: Sensor;
  valueStyle?: StyleProp<TextStyle>;
};
export const SensorCardValue: React.FunctionComponent<SensorCardValueProps> =
  observer(({ sensor, valueStyle }) => {
    const theme = useAppTheme();
    const styles = createStyles(theme);

    const { state, error, value } = sensor;
    const valueColor =
      state === SensorState.Error ? theme.colors.error : theme.colors.text;
    const isOutOfRangeColor = sensor.isOutOfRange && palette.White;

    if (state === SensorState.Offline)
      return (
        <View style={styles.offlineBox}>
          <WiFiOffIcon color={palette.RedNew} />
          <Text style={styles.offlineText} tx="Sensors.State.offline" />
        </View>
      );

    if (state === SensorState.Disconnect)
      return (
        <Text style={styles.disconnectText}>
          {sensor.translatedState.toUpperCase()}
        </Text>
      );

    if (state === SensorState.InvalidValue && !sensor.isUnknownAnalog)
      return (
        <Text style={styles.disconnectText}>
          {sensor.translatedState.toUpperCase()}
        </Text>
      );

    if (error || value === -1)
      return (
        <View style={styles.offlineBox}>
          <Text style={styles.offlineText}>{error}</Text>
        </View>
      );

    return (
      <View style={styles.cardValue}>
        <Text
          numberOfLines={1}
          ellipsizeMode="tail"
          style={[
            styles.cardValueValue,
            { color: valueColor },
            isOutOfRangeColor && { color: isOutOfRangeColor },
            valueStyle,
          ]}
        >
          {sensor.displayValue}
        </Text>
        {sensor.unitName ? (
          <Text
            style={[
              styles.cardValueUnit,
              isOutOfRangeColor && { color: isOutOfRangeColor },
              valueStyle,
            ]}
          >
            {sensor.unitName}
          </Text>
        ) : null}
      </View>
    );
  });

type SensorCardViewProps = {
  sensor: Sensor;
  isDisabledTitle?: boolean;
  isDisabledValue?: boolean;
};
export const SensorCardView: React.FunctionComponent<SensorCardViewProps> =
  observer(({ sensor, isDisabledTitle = false, isDisabledValue = false }) => {
    const theme = useAppTheme();
    const styles = createStyles(theme);

    const Icon = SensorIcons[sensor.iconName] || NoneIcon;
    const isOutOfRangeColor = sensor.isOutOfRange && palette.White;

    const colorTitle = isDisabledTitle
      ? theme.colors.disabled
      : isOutOfRangeColor || theme.colors.label;

    return (
      <View style={[styles.card, styles[`card-${sensor.state}`]]}>
        <LinearGradient
          colors={
            isOutOfRangeColor
              ? [palette.RedG1, palette.RedG2]
              : ["transparent", "transparent"]
          }
          start={{ x: 0, y: 0.5 }}
          end={{ x: 0.9, y: 1.5 }}
          style={styles.gradientContainer}
        >
          {sensor.needCalibration || sensor.needRecalibration ? (
            <LinearGradient
              colors={[palette.PurpleG1, palette.PurpleG2]}
              start={{ x: 0, y: 1 }}
              end={{ x: 1, y: 1 }}
              style={styles.calibrateLabel}
            >
              <Text style={{ color: palette.White, fontSize: 12 }}>
                {sensor.translatedState}
              </Text>
            </LinearGradient>
          ) : null}

          <View style={styles.cardName}>
            <View style={styles.cardNameIcon}>
              <Icon color={colorTitle} />
            </View>
            <Text
              numberOfLines={1}
              ellipsizeMode="tail"
              style={[styles.cardTypeText, { color: colorTitle }]}
            >
              {sensor.displayType.toUpperCase()}
            </Text>
          </View>
          <Text
            numberOfLines={1}
            ellipsizeMode="tail"
            style={[styles.cardNameText, { color: colorTitle }]}
          >
            {sensor.displayName}
          </Text>

          <SensorCardValue
            sensor={sensor}
            valueStyle={isDisabledValue && { color: theme.colors.disabled }}
          />
        </LinearGradient>
      </View>
    );
  });

const cStyles = StyleSheet.create({
  container: {
    flexGrow: 1,
    flexBasis: "50%",
  },
});

export const SensorCard: React.FunctionComponent<SensorCardProps> = observer(
  ({ sensor, style, showOfflinePopup }) => {
    const goToSensor = useGoToSensorDetails(sensor, showOfflinePopup);

    return (
      <Pressable style={[cStyles.container, style]} onPress={goToSensor}>
        <SensorCardView sensor={sensor} />
      </Pressable>
    );
  },
);
