import { useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { useTheme } from "@react-navigation/native";

import { TDeviceHistoryLogRule } from "@models/device/types";
import { ErrorsMessage, Text } from "@components/ui";

import { spacing } from "../../../../../theme";
import * as SensorsIcons from "../../../../../svgs/sensors";
import { NoneIcon } from "../../../../../svgs";
import { translate } from "../../../../../i18n";

const createStyles = (theme: any) =>
  StyleSheet.create({
    container: {
      marginTop: spacing[4],
    },
    rule: {
      flexDirection: "row",
      alignItems: "center",
      paddingVertical: spacing[2],
      paddingHorizontal: spacing[3],
      backgroundColor: theme.colors.inputBG,
      borderRadius: 8,
    },
    description: {
      flex: 1,
    },
    text: {
      fontSize: 12,
      lineHeight: 16,
      letterSpacing: 0.24,
    },
    icon: {
      marginRight: spacing[3],
      backgroundColor: theme.colors.inputBG,
    },
    title: {
      marginBottom: spacing[1],
    },
  });

export const DeviceHistoryRule = ({
  ruleType,
  sensorName,
  sensorIcon,
  description,
}: TDeviceHistoryLogRule) => {
  const theme = useTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);
  const SensorIcon = SensorsIcons[sensorIcon] || NoneIcon;

  if (!ruleType) {
    return (
      <ErrorsMessage
        style={styles.container}
        errors={[translate("Devices.History.errors.noRule")]}
      />
    );
  }

  return (
    <View style={[styles.container, styles.rule]}>
      {sensorIcon ? (
        <View style={styles.icon}>
          <SensorIcon color={theme.colors.text} size={20} />
        </View>
      ) : null}

      <View style={styles.description}>
        {sensorName ? (
          <Text style={[styles.text, styles.title]}>{sensorName}</Text>
        ) : null}
        {description ? <Text style={styles.text}>{description}</Text> : null}
      </View>
    </View>
  );
};
