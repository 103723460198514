import React, { useMemo, useState } from "react";
import { StyleSheet, View } from "react-native";
import { observer } from "mobx-react-lite";
import { useNavigation, useTheme } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";

import {
  Text,
  ContextMenu,
  Switch,
  Loading,
  Dialog,
  useDialog,
  MenuItem,
  ErrorsAlert,
} from "@components/ui";
import { Device } from "@models/device/device";
import { Rule } from "@models/rule/rule";

import { WarningSvg } from "../../../../svgs/pictures";
import { spacing } from "../../../../theme";
import { translate } from "../../../../i18n";
import { addActionsToMenuItems } from "../../../../utils/addActionsToMenuItems";
import { DevicesNavigatorParamList } from "../../navigation";
import { getDeviceRuleMenuItems, MenuDeviceRuleItemsKeys } from "../constants";

const createStyles = (theme: any) =>
  StyleSheet.create({
    row: {
      paddingBottom: spacing[3],
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },

    input: {
      flexDirection: "row",
      alignItems: "center",
      paddingLeft: spacing[4],
    },
    label: {
      fontSize: 20,
      marginRight: spacing[4],
    },
    switch: {},

    errorBox: {
      paddingHorizontal: spacing[4],
      marginBottom: spacing[4],
      marginTop: -spacing[1],
    },
    error: {
      color: theme.colors.error,
    },
  });

type DeviceRuleControlsProps = {
  device: Device;
  rule: Rule;
};

export const DeviceRuleControls: React.FC<DeviceRuleControlsProps> = observer(
  ({ device, rule }) => {
    const theme = useTheme() as any;
    const styles = useMemo(() => createStyles(theme), [theme]);
    const navigation =
      useNavigation() as StackNavigationProp<DevicesNavigatorParamList>;
    const [errors, setErrors] = useState<string[]>();
    const resetErrors = () => setErrors([]);

    const goToRules = () => {
      navigation.navigate("device-rules-edit", { deviceUid: rule.deviceUid });
    };

    const deleteRuleHandler = () => rule.deleteRule();

    const { showDialog: showDeleteRuleDialog, ...dialogDeleteRuleProps } =
      useDialog({ onOk: deleteRuleHandler });

    const ruleErrors = rule.errors;

    const toggleRule = async (switchOn) => {
      const deviceAutomationErrors = device.automationErrors;
      if (deviceAutomationErrors) {
        setErrors(deviceAutomationErrors);
        return undefined;
      }

      // TODO: move rules validation to toggleAutomation method
      if (switchOn && ruleErrors?.length) return undefined;

      return device.toggleAutomation(switchOn);
    };

    const menuItems: MenuItem[] = addActionsToMenuItems(
      getDeviceRuleMenuItems(),
      {
        [MenuDeviceRuleItemsKeys.Edit]: goToRules,
        [MenuDeviceRuleItemsKeys.Delete]: showDeleteRuleDialog,
      },
    );

    /* eslint-disable react/no-array-index-key */
    return (
      <View>
        <View style={styles.row}>
          <View style={styles.input}>
            <Text
              style={[styles.label, theme.fonts.medium]}
              tx="Devices.Details.workingRule"
            />
            {rule.isLoading ? (
              <Loading size="small" withMask={false} />
            ) : (
              <Switch
                style={styles.switch}
                value={rule.isRuleOn}
                onValueChange={toggleRule}
              />
            )}
          </View>
          <ContextMenu menuItems={menuItems} />
        </View>

        {Boolean(ruleErrors?.length) && (
          <View style={styles.errorBox}>
            {ruleErrors.map((ruleError, index) => (
              <Text style={styles.error} key={`error-${index}`}>
                {ruleError}
              </Text>
            ))}
          </View>
        )}

        <Dialog
          {...dialogDeleteRuleProps}
          title={translate("Devices.Rules.delete")}
          okText={translate("common.delete")}
          okType="warning"
          content={translate("Devices.Rules.deleteDescription")}
        />

        <ErrorsAlert
          errors={errors}
          onCancel={resetErrors}
          Image={WarningSvg}
          title=""
        />
      </View>
    );
  },
);
