import * as React from "react";
import Svg, { Path } from "react-native-svg";

export const StatusScheduleSvg = ({ size = 45, color = "#2B2E32" }) => (
  <Svg width={size} height={size} viewBox="0 0 45 45" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.93155 15.1211L13.5038 9.80978C12.0193 9.27321 10.2934 9.5781 9.07369 10.7407C7.85401 11.9032 7.46675 13.6125 7.93155 15.1211ZM7.03159 16.7767C5.70478 14.509 6.05361 11.5471 8.03876 9.65488C10.0239 7.76269 12.9992 7.55622 15.2007 8.9902C15.3928 9.11532 15.5171 9.32143 15.5382 9.5497C15.5593 9.77798 15.4748 10.0034 15.3088 10.1615L8.19639 16.9409C8.03045 17.099 7.80128 17.1726 7.57428 17.1406C7.34727 17.1087 7.14736 16.9746 7.03159 16.7767ZM10.5386 25.5292C10.5386 19.2108 15.6607 14.0887 21.9791 14.0887C28.2975 14.0887 33.4196 19.2108 33.4196 25.5292C33.4196 31.8475 28.2975 36.9696 21.9791 36.9696C15.6607 36.9696 10.5386 31.8475 10.5386 25.5292ZM21.9791 12.5887C14.8323 12.5887 9.03865 18.3823 9.03865 25.5292C9.03865 29.3865 10.7264 32.8496 13.4036 35.2204L10.3463 38.2777C10.0534 38.5706 10.0534 39.0455 10.3463 39.3384C10.6392 39.6313 11.1141 39.6313 11.407 39.3384L14.5908 36.1545C16.6857 37.6139 19.2325 38.4696 21.9791 38.4696C24.7255 38.4696 27.2721 37.614 29.3669 36.1549L32.5504 39.3384C32.8433 39.6313 33.3181 39.6313 33.611 39.3384C33.9039 39.0455 33.9039 38.5706 33.611 38.2777L30.5541 35.2208C33.2317 32.85 34.9196 29.3867 34.9196 25.5292C34.9196 18.3823 29.1259 12.5887 21.9791 12.5887ZM22.7287 18.5632C22.7287 18.149 22.3929 17.8132 21.9787 17.8132C21.5645 17.8132 21.2287 18.149 21.2287 18.5632V25.0938C21.2287 25.508 21.5645 25.8438 21.9787 25.8438H26.5501C26.9643 25.8438 27.3001 25.508 27.3001 25.0938C27.3001 24.6796 26.9643 24.3438 26.5501 24.3438H22.7287V18.5632ZM29.7971 9.80978L35.3694 15.1211C35.8342 13.6125 35.4469 11.9032 34.2272 10.7407C33.0076 9.5781 31.2816 9.27321 29.7971 9.80978ZM35.2622 9.65488C37.2473 11.5471 37.5962 14.509 36.2693 16.7767C36.1536 16.9746 35.9537 17.1087 35.7267 17.1406C35.4996 17.1726 35.2705 17.099 35.1045 16.9409L27.9921 10.1615C27.8262 10.0034 27.7417 9.77798 27.7627 9.5497C27.7838 9.32143 27.9081 9.11532 28.1002 8.9902C30.3018 7.55622 33.277 7.76269 35.2622 9.65488Z"
      fill={color}
    />
  </Svg>
);
