import Svg, { Rect, Path } from "react-native-svg";

export default function AttentionIcon({ size = 20, color = "#F76B6B" }) {
  return (
    <Svg width={size} height={size} fill="none">
      <Rect width={size} height={size} rx={size / 2} fill={color} />
      <Path
        d="M10.759 11.878H9.247l-.294-6.874h2.114l-.308 6.874ZM8.91 14.104c0-.392.102-.667.308-.826.214-.168.471-.252.77-.252.298 0 .55.08.756.238.214.159.322.439.322.84 0 .383-.108.658-.322.826a1.155 1.155 0 0 1-.756.252c-.299 0-.556-.084-.77-.252-.206-.168-.308-.443-.308-.826Z"
        fill="#fff"
      />
    </Svg>
  );
}
