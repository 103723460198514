import * as React from "react";
import Svg, { Rect, Circle, Path } from "react-native-svg";
// import { SvgAnimated } from "../SvgAnimated";

const HistorySkeletonSVG = ({
  width = 328,
  height = 103,
  // color = "url(#loading-animated)",
  color = "#B6C5D3",
}) => (
  <Svg
    width={width}
    height={height}
    fill="none"
    // uniqueKey="loading-animated"
  >
    <Circle cx={16} cy={16} r={8} fill={color} />
    <Rect x={30} y={11.5} width={86} height={9} rx={4.5} fill={color} />
    <Path
      d="M8 95c31.898 0 20.577-63 52-63s20.577 63 52 63 20.577-63 52-63 20.577 63 52 63 20.577-63 52-63 23.591 63 52 63"
      stroke={color}
      strokeWidth={4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default HistorySkeletonSVG;
