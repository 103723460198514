import { flow, Instance, SnapshotOut, types } from "mobx-state-tree";
import { values, keys } from "mobx";
import dayjs from "dayjs";

import { TFilterItem } from "@models/types";
import { UserSettingsModel } from "@models/settings/user-settings-store";
import { SensorTypeName } from "@models/sensor/constants";

import {
  Sensor,
  SensorData,
  SensorModel,
  TSensorType,
  SensorTypesModel,
} from "./sensor";
import { getExportSensorsDataPath, SensorsApi } from "../../services/api";
import { withEnvironment } from "../extensions/with-environment";
import byUid from "../../utils/byUid";
import { localeCompare } from "../../utils/sortDataBy";
import { DateTimeFormats, TPeriod } from "../../utils/timeConverter";
import { withStatus } from "../extensions/with-status";
import {
  IsFetchTimeProps,
  withFetchTimeout,
} from "../extensions/with-fetch-timeout";
import { withRootStore } from "../extensions/with-root-store";
import { downloadFile } from "../../services/downloadFile";

const FETCH_SENSORS_ALIAS = "fetchSensors";
const FETCH_SENSOR_TYPES_ALIAS = "fetchSensorsTypes";

export const SensorStoreModel = types
  .model("SensorStore")
  .props({
    sensors: types.optional(types.map(SensorModel), {}),
    sensorsTypes: types.optional(types.map(SensorTypesModel), {}),
    isNoData: false,
  })
  .extend(withEnvironment)
  .extend(withStatus)
  .extend(withFetchTimeout)
  .extend(withRootStore)
  .views((self) => ({
    get sensorsSettings() {
      return (
        self.rootStore.settingsStore.userSettings?.sensorsSettings ||
        UserSettingsModel.create({}).sensorsSettings
      );
    },
  }))
  .views((self) => ({
    get sortedSensors() {
      // const positionIsAvailable = values(self.sensorsSettings).map((data: any) => data.positionInView).filter(Boolean).length;
      // console.log("positionIsAvailable", positionIsAvailable)

      return (values(self.sensors) as Sensor[]).sort((sensorA, sensorB) => {
        const positionA =
          self.sensorsSettings.get(sensorA.uid)?.positionInView || 0;
        const positionB =
          self.sensorsSettings.get(sensorB.uid)?.positionInView || 0;
        return (
          positionA - positionB || localeCompare(sensorA?.name, sensorB?.name)
        );
      });
      // return sortDataBy(values(self.sensors) as any[], "position");
    },
  }))
  .actions((self) => ({
    clear: () => {
      self.sensors.clear();
      self.sensorsTypes.clear();
      self.clearFetchingTimes();
    },

    putSensor: (data: Sensor) => {
      const lastCalibrationDate = data.lastCalibrationDate
        ? new Date(data.lastCalibrationDate)
        : null;
      self.sensors.put({ ...data, lastCalibrationDate });
    },
  }))
  .actions((self) => {
    const sensorsApi = new SensorsApi(self.environment.api);

    const updateData = (data: SensorData[]) => {
      const dataByUid = byUid(data);
      self.sensors.forEach((sensor, uid) => sensor.updateData(dataByUid[uid]));
    };

    const updateViewFilter = (items: TFilterItem[]) => {
      const settings = items.map((item) => ({
        uid: item.uid,
        positionInView: item.position,
        hideInView: !item.isChecked,
      }));
      self.rootStore.settingsStore.userSettings.saveSensorsSettings(settings);
    };

    const updateHistoryFilter = (items: TFilterItem[]) => {
      const settings = items.map((item) => ({
        uid: item.uid,
        positionInHistory: item.position,
        hideInHistory: !item.isChecked,
      }));
      self.rootStore.settingsStore.userSettings.saveSensorsSettings(settings);
    };

    /* eslint-disable consistent-return */
    const fetchSensors = flow(function* (fetchProps: IsFetchTimeProps = {}) {
      if (!self.isFetchTime(FETCH_SENSORS_ALIAS, fetchProps)) return undefined;

      self.setStatusPending();
      const result = yield sensorsApi.getSensorsViews();

      if (result.kind === "ok") {
        self.setStatusDone();
        self.resetErrors();
        self.sensors.clear();
        result.data.forEach(self.putSensor);
      } else {
        self.setStatusError(result.errors);
        self.clearFetchTime(FETCH_SENSORS_ALIAS);
        if (__DEV__) console.log(result.kind);
      }
    });
    /* eslint-enable consistent-return */

    const getSensorsData = flow(function* () {
      const result = yield sensorsApi.getSensorsData();

      if (result.kind === "ok") {
        updateData(result.data);
      } else if (__DEV__) console.log(result.kind);
    });

    const fetchSensorsTypes = flow(function* (
      fetchProps: IsFetchTimeProps = {},
    ) {
      if (!self.isFetchTime(FETCH_SENSOR_TYPES_ALIAS, fetchProps)) return false;
      const result = yield sensorsApi.getSensorsTypes();

      if (result.kind === "ok") {
        self.sensorsTypes.clear();
        result.data.forEach((dataItem) => self.sensorsTypes.put(dataItem));
      } else {
        self.clearFetchTime(FETCH_SENSOR_TYPES_ALIAS);
      }
      return true;
    });

    const getSensorType = (identifier: string): TSensorType => {
      return self.sensorsTypes.get(identifier);
    };

    const getSensor = (uid: string): Sensor => {
      return self.sensors.get(uid);
    };

    const getSensors = (uids: string[]) => {
      return uids
        .map((uid) => {
          return getSensor(uid);
        })
        .filter((sensor) => {
          return sensor !== null && sensor !== undefined;
        });
    };

    const clearCache = () => {
      self.clearFetchTime(FETCH_SENSORS_ALIAS);
      self.clearFetchTime(FETCH_SENSOR_TYPES_ALIAS);
    };

    const getSensorsByType = (sensorType: SensorTypeName) => {
      return self.sortedSensors.filter((sensor) => sensor.type === sensorType);
    };

    const exportSensorsHistory = flow(function* (
      uids: string[],
      period?: TPeriod,
    ) {
      const startDate = period
        ? dayjs(period.startDate)
        : dayjs(new Date()).add(-30, "day");
      const endDate = period ? dayjs(period.endDate) : dayjs(new Date());

      const exportSensorsDataPath = getExportSensorsDataPath(uids, {
        startDate: startDate.format(DateTimeFormats.DateYMD),
        endDate: endDate.format(DateTimeFormats.DateYMD),
      });
      if (!exportSensorsDataPath) return;

      const fileName = `${
        uids.length === 1
          ? self.getSensor(uids[0])?.name || "sensor_data"
          : "sensors_data"
      }_${startDate.format(DateTimeFormats.DateYMD)}-${endDate.format(
        DateTimeFormats.DateYMD,
      )}`
        .replace(/[\s_-]+/g, "-")
        .replace(/[^a-zA-Z0-9-]/g, "");

      self.setStatusPending();
      yield downloadFile({
        path: exportSensorsDataPath,
        fileName: `${fileName}.xlsx`,
        api: self.environment.api,
      });
      self.setStatusDone();
    });

    return {
      fetchSensors,
      getSensorType,
      getSensorsData,
      getSensor,
      getSensors,
      updateHistoryFilter,
      updateViewFilter,
      fetchSensorsTypes,
      clearCache,
      getSensorsByType,
      exportSensorsHistory,
    };
  })
  .views((self) => ({
    get analogDOORPSensorsTypes() {
      return [
        self.sensorsTypes.get(SensorTypeName.DO),
        self.sensorsTypes.get(SensorTypeName.ORP),
      ];
    },

    get analogSensorsTypesForSensorDirector() {
      return [
        self.sensorsTypes.get(SensorTypeName.SoilMoisture),
        // self.sensorsTypes.get(SensorTypeName.SoilEC),
        self.sensorsTypes.get(SensorTypeName.LUX),
        self.sensorsTypes.get(SensorTypeName.PAR),
      ];
    },

    get analogSensorsTypes() {
      return (values(self.sensorsTypes) as any).filter(
        (itemType) =>
          itemType.isAnalog && itemType?.name !== SensorTypeName.Analog,
      );
    },

    get historyFilter() {
      return keys(self.sensors).reduce(
        (result, uid: string) => {
          if (self.sensorsSettings.get(uid)?.hideInHistory) {
            result.hiddenUids.push(uid);
          } else {
            result.visibleUids.push(uid);
          }
          return result;
        },
        { hiddenUids: [], visibleUids: [], total: self.sensors.size },
      );
    },

    get viewFilter() {
      return keys(self.sensors).reduce(
        (result, uid: string) => {
          if (self.sensorsSettings.get(uid)?.hideInView) {
            result.hiddenUids.push(uid);
          } else {
            result.visibleUids.push(uid);
          }
          return result;
        },
        { hiddenUids: [], visibleUids: [], total: self.sensors.size },
      );
    },
  }))
  .views((self) => ({
    get historySensors() {
      return self.sortedSensors.filter(
        (sensor) => !self.sensorsSettings.get(sensor.uid)?.hideInHistory,
      );
    },

    get filteredSensors() {
      return self.sortedSensors.filter(
        (sensor) => !self.sensorsSettings.get(sensor.uid)?.hideInView,
      );
    },

    get hasUncalibrated() {
      return (values(self.sensors) as Sensor[]).find(
        (sensor) => sensor.needCalibration && !sensor.lastCalibrationDate,
      );
    },
    get hasOutOfRange() {
      return (values(self.sensors) as Sensor[]).find(
        (sensor) => sensor.isOutOfRange,
      );
    },

    get sensorsNames() {
      return (values(self.sensors) as Sensor[]).map((sensor) => sensor.name);
    },
  }));

type CharacterStoreType = Instance<typeof SensorStoreModel>;
export type CharacterStore = CharacterStoreType;
type CharacterStoreSnapshotType = SnapshotOut<typeof SensorStoreModel>;
export type CharacterStoreSnapshot = CharacterStoreSnapshotType;
export const createCharacterStoreDefaultModel = () =>
  types.optional(SensorStoreModel, {});
