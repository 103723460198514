import { useMemo } from "react";
import { View, Text, StyleSheet } from "react-native";
import dayjs from "dayjs";

import { palette, spacing } from "../../theme";
import { dateTimeFormat, DateTimeFormats } from "../../utils/timeConverter";

const styles = StyleSheet.create({
  rangeLine: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    marginVertical: spacing[2],
  },
  text: {
    fontSize: 10,
  },
  rangeLineContainer: {
    paddingLeft: 48,

    backgroundColor: palette.White,
    paddingHorizontal: spacing[4],
    paddingBottom: spacing[1],
    alignItems: "center",
    borderTopColor: palette.BlueBackground,
    borderTopWidth: 1,
  },
  currentTime: {
    fontSize: 16,
    color: palette.Green,
  },
});

const HOURS_OFFSET = 4;

type RangeItem = {
  label: string;
  percent: number;
  timestamp: number;
};

const getHoursRanges = (startDate: Date, endDate: Date): RangeItem[] => {
  const totalMinutes = dayjs(endDate).diff(startDate, "minutes");

  const initValue =
    startDate.getMinutes() > 30
      ? dayjs(startDate).startOf("hour").add(1, "hour")
      : dayjs(startDate).startOf("hour");

  const count = Math.round(totalMinutes / (HOURS_OFFSET * 60)) + 1;

  return [...Array(count)].map((item, index) => {
    const nextValue = dayjs(initValue).add(HOURS_OFFSET * index, "hours");
    const diffMinutes = dayjs(nextValue).diff(startDate, "minutes");

    return {
      label: nextValue.format(DateTimeFormats.TimeHM),
      percent: Math.round((diffMinutes / totalMinutes) * 10000) / 100,
      timestamp: nextValue.unix(),
    };
  });
};

const getDaysRanges = (startDate: Date, endDate: Date): RangeItem[] => {
  const totalDays = dayjs(endDate).diff(startDate, "days");

  const initValue = dayjs(startDate).startOf("day");

  const customRange = Math.ceil(totalDays / 8);
  const result = [];

  for (let i = 0; i < totalDays; i += customRange) {
    const nextValue = dayjs(initValue).add(i, "days");
    const diffDays = dayjs(nextValue).diff(startDate, "days", true);

    result.push({
      label: dateTimeFormat(nextValue, DateTimeFormats.DateDM),
      percent: Math.round((diffDays / totalDays) * 10000) / 100,
      timestamp: nextValue.unix(),
    });
  }
  return result;
};

const getRange = (startDate: Date, endDate: Date): RangeItem[] => {
  const diffDay = dayjs(endDate).diff(startDate, "day");

  if (diffDay <= 1) {
    return getHoursRanges(startDate, endDate);
  }
  return getDaysRanges(startDate, endDate);
};

interface Props {
  startDate: Date;
  endDate: Date;
  currentDate?: string;
}

const RangeLine = ({ startDate, endDate, currentDate }: Props) => {
  const range = useMemo(
    () => getRange(startDate, endDate),
    [startDate, endDate],
  );

  return (
    <View style={styles.rangeLineContainer}>
      <View style={styles.rangeLine}>
        {range.map(({ label, timestamp }) => {
          return (
            <Text key={timestamp} style={styles.text}>
              {label}
            </Text>
          );
        })}
      </View>
      <Text style={styles.currentTime}>{currentDate || "00:00"}</Text>
    </View>
  );
};

export default RangeLine;
