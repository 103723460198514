import { I18n } from "i18n-js";

import en from "./en.json";
import he from "./he.json";
import fr from "./fr.json";

/* eslint-disable @typescript-eslint/no-shadow */
export enum Language {
  en = "en",
  de = "de",
  fr = "fr",
  it = "it",
  he = "he",
}

export type TLanguage = {
  key: Language;
  name: string;
  isRLT?: boolean;
};

export const LANGUAGES: TLanguage[] = [
  {
    key: Language.en,
    name: "English",
    isRLT: false,
  },
  {
    key: Language.he,
    name: "עברית",
    isRLT: true,
  },
  {
    key: Language.fr,
    name: "Français",
    isRLT: false,
  },
];

export const isLanguageRTL = (language: Language): boolean => {
  const currentLang = LANGUAGES.find((item) => item.key === language);
  return currentLang?.isRLT || false;
};

/* eslint-enable @typescript-eslint/no-shadow */

export const i18n = new I18n(
  {
    en,
    he,
    fr,
  },
  { defaultLocale: Language.en, enableFallback: false },
);

/**
 * Builds up valid keyPaths for translations.
 * Update to your default locale of choice if not English.
 */
type DefaultLocale = typeof en;
export type TxKeyPath = RecursiveKeyOf<DefaultLocale>;

type RecursiveKeyOf<TObj extends Record<string, any>> = {
  [TKey in keyof TObj & string]: TObj[TKey] extends Record<string, any>
    ? `${TKey}` | `${TKey}.${RecursiveKeyOf<TObj[TKey]>}`
    : `${TKey}`;
}[keyof TObj & string];
