import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

export const Welcome = ({ size = 360 }) => (
  <Svg width={size} height={size} fill="none">
    <G clipPath="url(#a)">
      <Path fill="#fff" d="M0 0h360v360H0z" />
      <Path
        d="M179.011 259.23c9.334 0 16.9-11.954 16.9-26.7 0-14.746-7.566-26.7-16.9-26.7-9.333 0-16.9 11.954-16.9 26.7 0 14.746 7.567 26.7 16.9 26.7ZM197.521 293.03h17.5v6.89a12.689 12.689 0 0 1-12.69 12.69h-17.53v-6.89a12.68 12.68 0 0 1 3.727-8.984 12.692 12.692 0 0 1 8.993-3.706Z"
        fill="#B0EACC"
        stroke="#2A2E32"
        strokeWidth={2.2}
        strokeLinejoin="round"
      />
      <Path
        d="M179.012 331.01v-6.21a15.397 15.397 0 0 1 4.51-10.88l8.6-8.6"
        stroke="#2A2E32"
        strokeWidth={2.2}
        strokeLinejoin="round"
      />
      <Path
        d="M174.111 318.67h-14.78a10.712 10.712 0 0 1-10.71-10.71v-5.82h14.77c2.841 0 5.565 1.128 7.573 3.137a10.706 10.706 0 0 1 3.137 7.573v5.82h.01Z"
        fill="#B0EACC"
        stroke="#2A2E32"
        strokeWidth={2.2}
        strokeLinejoin="round"
      />
      <Path
        d="M191.91 269.1a19.848 19.848 0 0 0-1.83 8.34v4h17a19.82 19.82 0 0 0 19.82-19.82v-4h-17a19.748 19.748 0 0 0-13.23 5.07"
        fill="#B0EACC"
      />
      <Path
        d="M191.91 269.1a19.848 19.848 0 0 0-1.83 8.34v4h17a19.82 19.82 0 0 0 19.82-19.82v-4h-17a19.748 19.748 0 0 0-13.23 5.07"
        stroke="#2A2E32"
        strokeWidth={2.2}
        strokeLinejoin="round"
      />
      <Path
        d="M179.012 328.43v-24.71a26.616 26.616 0 0 1 7.8-18.82l11.42-11.41"
        stroke="#2A2E32"
        strokeWidth={2.2}
        strokeLinejoin="round"
      />
      <Path
        d="M166.111 269.1a19.696 19.696 0 0 1 1.84 8.34v4h-17a19.814 19.814 0 0 1-18.305-12.236 19.799 19.799 0 0 1-1.505-7.584v-4h17a19.76 19.76 0 0 1 13.24 5.07"
        fill="#B0EACC"
      />
      <Path
        d="M166.111 269.1a19.696 19.696 0 0 1 1.84 8.34v4h-17a19.814 19.814 0 0 1-18.305-12.236 19.799 19.799 0 0 1-1.505-7.584v-4h17a19.76 19.76 0 0 1 13.24 5.07"
        stroke="#2A2E32"
        strokeWidth={2.2}
        strokeLinejoin="round"
      />
      <Path
        d="M179.011 328.43v-24.71a26.622 26.622 0 0 0-7.79-18.82l-11.42-11.41"
        stroke="#2A2E32"
        strokeWidth={2.2}
        strokeLinejoin="round"
      />
      <Path
        d="M179.011 334.19v-5.24a12.934 12.934 0 0 0-3.8-9.18l-7.26-7.28M179.012 234.96v103.13M225.031 353h103.77M211.111 352.82h-11.63a8.898 8.898 0 0 0-8.79-7.57h-.41a12.68 12.68 0 0 0-23.22 0h-.1a8.898 8.898 0 0 0-8.79 7.57h-26.37"
        stroke="#2A2E32"
        strokeWidth={2.2}
        strokeLinejoin="round"
      />
      <Path
        d="M95.662 303.26c3.208 0 5.81-4.114 5.81-9.19 0-5.075-2.602-9.19-5.81-9.19-3.21 0-5.81 4.115-5.81 9.19 0 5.076 2.6 9.19 5.81 9.19ZM108.4 321.65h12v4.74a8.74 8.74 0 0 1-8.74 8.74h-12v-4.74a8.74 8.74 0 0 1 8.74-8.74Z"
        fill="#B0EACC"
        stroke="#2A2E32"
        strokeWidth={2.2}
        strokeLinejoin="round"
      />
      <Path
        d="M95.66 347.79v-4.3a10.58 10.58 0 0 1 3.1-7.48l5.92-5.93"
        stroke="#2A2E32"
        strokeWidth={2.2}
        strokeLinejoin="round"
      />
      <Path
        d="M104.541 305.18a13.6 13.6 0 0 0-1.27 5.74v2.78h11.68a13.64 13.64 0 0 0 13.64-13.64v-2.78h-11.68a13.603 13.603 0 0 0-9.11 3.49"
        fill="#B0EACC"
      />
      <Path
        d="M104.541 305.18a13.6 13.6 0 0 0-1.27 5.74v2.78h11.68a13.64 13.64 0 0 0 13.64-13.64v-2.78h-11.68a13.603 13.603 0 0 0-9.11 3.49"
        stroke="#2A2E32"
        strokeWidth={2.2}
        strokeLinejoin="round"
      />
      <Path
        d="M95.66 346.02v-17a18.31 18.31 0 0 1 5.36-12.94l7.86-7.86"
        stroke="#2A2E32"
        strokeWidth={2.2}
        strokeLinejoin="round"
      />
      <Path
        d="M89.6 319.49a9.182 9.182 0 0 1 .87 3.92v1.9h-8a9.3 9.3 0 0 1-9.3-9.31v-1.89h8a9.282 9.282 0 0 1 6.21 2.38"
        fill="#B0EACC"
      />
      <Path
        d="M89.6 319.49a9.182 9.182 0 0 1 .87 3.92v1.9h-8a9.3 9.3 0 0 1-9.3-9.31v-1.89h8a9.282 9.282 0 0 1 6.21 2.38"
        stroke="#2A2E32"
        strokeWidth={2.2}
        strokeLinejoin="round"
      />
      <Path
        d="M95.66 347.32v-11.6a12.52 12.52 0 0 0-3.66-8.84l-5.36-5.39M95.66 302.73v49.93"
        stroke="#2A2E32"
        strokeWidth={2.2}
        strokeLinejoin="round"
      />
      <Path
        d="M250.771 303.26c3.209 0 5.81-4.114 5.81-9.19 0-5.075-2.601-9.19-5.81-9.19-3.209 0-5.81 4.115-5.81 9.19 0 5.076 2.601 9.19 5.81 9.19ZM263.501 321.65h12v4.74a8.74 8.74 0 0 1-8.74 8.74h-11.96v-4.74a8.741 8.741 0 0 1 8.7-8.74Z"
        fill="#B0EACC"
        stroke="#2A2E32"
        strokeWidth={2.2}
        strokeLinejoin="round"
      />
      <Path
        d="M250.801 347.79v-4.3a10.531 10.531 0 0 1 3.1-7.48l5.92-5.93"
        stroke="#2A2E32"
        strokeWidth={2.2}
        strokeLinejoin="round"
      />
      <Path
        d="M259.651 305.18a13.426 13.426 0 0 0-1.27 5.74v2.78h11.68a13.641 13.641 0 0 0 13.64-13.64v-2.78h-11.68a13.6 13.6 0 0 0-9.11 3.49"
        fill="#B0EACC"
      />
      <Path
        d="M259.651 305.18a13.426 13.426 0 0 0-1.27 5.74v2.78h11.68a13.641 13.641 0 0 0 13.64-13.64v-2.78h-11.68a13.6 13.6 0 0 0-9.11 3.49"
        stroke="#2A2E32"
        strokeWidth={2.2}
        strokeLinejoin="round"
      />
      <Path
        d="M250.801 346.02v-17a18.309 18.309 0 0 1 5.36-12.94l7.86-7.86"
        stroke="#2A2E32"
        strokeWidth={2.2}
        strokeLinejoin="round"
      />
      <Path
        d="M244.712 319.49a9.34 9.34 0 0 1 .86 3.92v1.9h-8a9.31 9.31 0 0 1-9.31-9.31v-1.89h8a9.24 9.24 0 0 1 6.21 2.38"
        fill="#B0EACC"
      />
      <Path
        d="M244.712 319.49a9.34 9.34 0 0 1 .86 3.92v1.9h-8a9.31 9.31 0 0 1-9.31-9.31v-1.89h8a9.24 9.24 0 0 1 6.21 2.38"
        stroke="#2A2E32"
        strokeWidth={2.2}
        strokeLinejoin="round"
      />
      <Path
        d="M250.801 347.32v-11.6a12.523 12.523 0 0 0-3.66-8.84l-5.34-5.39M250.771 302.73v49.93M117.451 353h-55.74"
        stroke="#2A2E32"
        strokeWidth={2.2}
        strokeLinejoin="round"
      />
      <Path
        d="M174.221 203.26v-23.22c0-13.91 13.66-25.18 30.51-25.18"
        stroke="#2A2E32"
        strokeWidth={2.2}
        strokeLinejoin="round"
        strokeDasharray="12 12"
      />
      <Path
        d="M187.932 204.88V185c0-12.62 6.66-15.77 15.92-18.37"
        stroke="#2A2E32"
        strokeWidth={2.2}
        strokeLinejoin="round"
        strokeDasharray="12 12"
      />
      <Path
        d="M123.081 234.97a2.89 2.89 0 1 0 .001-5.779 2.89 2.89 0 0 0-.001 5.779ZM123.081 287.25a2.89 2.89 0 1 0 .001-5.779 2.89 2.89 0 0 0-.001 5.779ZM115.191 250.78a2.89 2.89 0 1 0 0-5.78 2.89 2.89 0 0 0 0 5.78Z"
        fill="#fff"
        stroke="#2A2E32"
        strokeWidth={2.2}
        strokeLinejoin="round"
      />
      <Path
        d="M47.24 352.66H29.08"
        stroke="#2A2E32"
        strokeWidth={2.2}
        strokeLinejoin="round"
      />
      <Path
        d="M76.1 194.66a10.997 10.997 0 0 1 3.32.51 16.84 16.84 0 0 1 32.26-.19c.853-.213 1.73-.321 2.61-.32a11 11 0 0 1 11 10.52L97.5 217.63l-21.4-22.97Z"
        fill="#fff"
        stroke="#2A2E32"
        strokeWidth={2.2}
        strokeLinejoin="round"
      />
      <Path
        d="m108.56 209.75 20.24-8.18a8.002 8.002 0 0 1 10.48 4.46 2.81 2.81 0 0 1-1.52 3.63L97.8 226.49a14.339 14.339 0 0 1-11.24 0l-18.15-7.79a38 38 0 0 0-9.16-2.64l-20.08-4.13 3.63-16.38 11 2.12a21.782 21.782 0 0 0 12.88-2.05h.09a21.766 21.766 0 0 1 17.67-.79l20.07 7.88a6.563 6.563 0 0 1 3.74 8.46l-.33.86a3.188 3.188 0 0 1-4 1.89l-20.12-6.75"
        fill="#fff"
      />
      <Path
        d="m108.56 209.75 20.24-8.18a8.002 8.002 0 0 1 10.48 4.46 2.81 2.81 0 0 1-1.52 3.63L97.8 226.49a14.339 14.339 0 0 1-11.24 0l-18.15-7.79a38 38 0 0 0-9.16-2.64l-20.08-4.13 3.63-16.38 11 2.12a21.782 21.782 0 0 0 12.88-2.05h.09a21.766 21.766 0 0 1 17.67-.79l20.07 7.88a6.563 6.563 0 0 1 3.74 8.46l-.33.86a3.188 3.188 0 0 1-4 1.89l-20.12-6.75"
        stroke="#2A2E32"
        strokeWidth={2.2}
        strokeLinejoin="round"
      />
      <Path
        d="m-1.2 209.86 38.7 7.77a40.44 40.44 0 0 0 6.1-25.42C37.8 194.13-4 173.49-4 173.49"
        fill="#fff"
      />
      <Path
        d="m-1.2 209.86 38.7 7.77a40.44 40.44 0 0 0 6.1-25.42C37.8 194.13-4 173.49-4 173.49"
        stroke="#2A2E32"
        strokeWidth={2.2}
        strokeLinejoin="round"
      />
      <Path
        d="m278.801 117.33 6.4-4.74a20.52 20.52 0 0 1 28.7 4.28l2.8 3.79a20.494 20.494 0 0 1 3.811 15.23 20.5 20.5 0 0 1-8.081 13.46"
        fill="#fff"
      />
      <Path
        d="m278.801 117.33 6.4-4.74a20.52 20.52 0 0 1 28.7 4.28l2.8 3.79a20.494 20.494 0 0 1 3.811 15.23 20.5 20.5 0 0 1-8.081 13.46"
        stroke="#2A2E32"
        strokeWidth={2.2}
        strokeLinejoin="round"
      />
      <Path
        d="M273.982 110.92a8.323 8.323 0 0 0-10.72-.68l-15 11.12-15 11.11a8.33 8.33 0 0 0-2.46 10.46l23.77 46.88a17.536 17.536 0 0 0 19.171 9.253 17.54 17.54 0 0 0 6.909-3.083l14.79-11 14.78-10.95a17.554 17.554 0 0 0 4.967-5.708 17.557 17.557 0 0 0 .943-14.748 17.56 17.56 0 0 0-4.2-6.294l-37.95-36.36ZM217.891 153.76l-3.46-2.27a2.789 2.789 0 0 0-4.29 1.93l-.89 5.95-1.58 9.57a2.799 2.799 0 0 0 .933 2.513 2.805 2.805 0 0 0 2.617.577l4.66-4.77 31 7.52-10.08-19.91-18.91-1.11Z"
        fill="#BDE1FF"
        stroke="#2A2E32"
        strokeWidth={2.2}
        strokeLinejoin="round"
      />
      <Path
        d="m234.451 150.2 28.83-21.35M269.051 124.58l10.81-8.01M217.891 153.76l-2.01 13.5"
        stroke="#2A2E32"
        strokeWidth={2.2}
        strokeLinejoin="round"
      />
      <Path
        d="m320.277 123.014-9.037 9.036a3.61 3.61 0 0 0 0 5.106l.007.007a3.61 3.61 0 0 0 5.106 0l9.036-9.037a3.61 3.61 0 0 0 0-5.105l-.007-.007a3.61 3.61 0 0 0-5.105 0ZM312.804 117.53l-9.751 9.751a4.58 4.58 0 0 0 6.477 6.478l9.751-9.751a4.58 4.58 0 0 0-6.477-6.478Z"
        fill="#fff"
        stroke="#2A2E32"
        strokeWidth={2.2}
        strokeLinejoin="round"
      />
      <Path
        d="m306.019 111.355-9.751 9.751a4.58 4.58 0 1 0 6.477 6.477l9.751-9.751a4.58 4.58 0 1 0-6.477-6.477Z"
        fill="#fff"
        stroke="#2A2E32"
        strokeWidth={2.2}
        strokeLinejoin="round"
      />
      <Path
        d="m302.243 102.183-9.751 9.751a4.581 4.581 0 0 0 6.478 6.477l9.751-9.751a4.581 4.581 0 0 0-6.478-6.477Z"
        fill="#fff"
        stroke="#2A2E32"
        strokeWidth={2.2}
        strokeLinejoin="round"
      />
      <Path
        d="m340.092 79.56-5.76 5.84a9.884 9.884 0 0 1-4.61 2.6l-15 3.69a9.879 9.879 0 0 0-4.61 2.6l-6.72 6.73a7.9 7.9 0 0 0 0 11.18l7.71 7.72a3.088 3.088 0 0 0 4.36 0 7.162 7.162 0 0 0 2.1-5.07 7.157 7.157 0 0 0-2.1-5.07l-4.88-4.89.27-.28a12.242 12.242 0 0 0 3.978 2.658 12.244 12.244 0 0 0 9.384 0 12.253 12.253 0 0 0 3.978-2.658l3.59-3.59"
        fill="#fff"
      />
      <Path
        d="m340.092 79.56-5.76 5.84a9.884 9.884 0 0 1-4.61 2.6l-15 3.69a9.879 9.879 0 0 0-4.61 2.6l-6.72 6.73a7.9 7.9 0 0 0 0 11.18l7.71 7.72a3.088 3.088 0 0 0 4.36 0 7.162 7.162 0 0 0 2.1-5.07 7.157 7.157 0 0 0-2.1-5.07l-4.88-4.89.27-.28a12.242 12.242 0 0 0 3.978 2.658 12.244 12.244 0 0 0 9.384 0 12.253 12.253 0 0 0 3.978-2.658l3.59-3.59"
        stroke="#2A2E32"
        strokeWidth={2.2}
        strokeLinejoin="round"
      />
      <Path
        d="m354.37 93.18-7.21 7.31a19.321 19.321 0 0 0-4.36 6.72l-.75 1.94a19.217 19.217 0 0 1-4.37 6.72l-11.95 12"
        fill="#fff"
      />
      <Path
        d="m354.37 93.18-7.21 7.31a19.321 19.321 0 0 0-4.36 6.72l-.75 1.94a19.217 19.217 0 0 1-4.37 6.72l-11.95 12"
        stroke="#2A2E32"
        strokeWidth={2.2}
        strokeLinejoin="round"
      />
      <Path d="m370.101 26-34.31 49.55 22.52 21.02 14.64-12.39" fill="#fff" />
      <Path
        d="m370.101 26-34.31 49.55 22.52 21.02 14.64-12.39"
        stroke="#2A2E32"
        strokeWidth={2.2}
        strokeLinejoin="round"
      />
      <Path
        d="M178.801 185.76v-46.27a14.921 14.921 0 0 0-14.91-14.91h-42.51a14.91 14.91 0 0 0-14.91 14.91v21.23a14.911 14.911 0 0 0 14.91 14.91h46.1l11.32 10.13Z"
        fill="#fff"
        stroke="#2A2E32"
        strokeWidth={2.2}
        strokeLinejoin="round"
      />
      <Path
        d="M159.13 137.49c-3.61-4.07-11.15-3.55-16.5 2.47-4.92-5.73-12.88-6.54-16.49-2.47-4.79 5.41-2.49 15.28 5.14 22 3.52 3.12 7.55 6 11.35 6.64 3.81-.63 7.84-3.52 11.36-6.64 7.63-6.76 9.93-16.63 5.14-22Z"
        fill="#F66B6B"
        stroke="#2A2E32"
        strokeWidth={2.2}
        strokeLinejoin="round"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h360v360H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);
