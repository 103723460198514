import { Dimensions } from "react-native";
import dayjs from "dayjs";

import { ColumnChart } from "@components/Chart";
import { TDeviceHistoryLog } from "@models/device/types";

const PADDING = 32;

type DeviceHistoryDiagramProps = {
  dates: string[];
  historyLogs: TDeviceHistoryLog[];
};

export const DeviceHistoryDiagram = ({
  dates,
  historyLogs,
}: DeviceHistoryDiagramProps) => {
  const width = Dimensions.get("window").width - PADDING;

  const datesInSecond = dates.map((date) => dayjs(date).valueOf());

  const startTime = dayjs(Math.min(...datesInSecond)).startOf("day");
  const endTime = dayjs(Math.max(...datesInSecond)).endOf("day");
  const periodInSec = endTime.diff(startTime, "second");

  const scale = width / periodInSec;

  const data = [];
  dates.forEach((item) => {
    historyLogs[item].forEach((log: TDeviceHistoryLog) => {
      data.push({
        dateTime: log.dateTime,
        width: Math.round(log.durationInSec * scale) || 1,
        value:
          Math.round(dayjs(log.dateTime).diff(startTime, "second") * scale) ||
          1,
        label: `${log.startTime}  ${log.duration}`,
      });
    });
  });

  data.reverse();

  return <ColumnChart data={data} xMax={scale} />;
};
