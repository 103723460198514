import { useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "@models/index";
import {
  ScrollView,
  View,
  StyleSheet,
  StyleProp,
  ViewStyle,
  TouchableOpacity,
} from "react-native";

import { Screen } from "@components/index";
import {
  Checkbox,
  Dialog,
  FloatingButton,
  Text,
  useDialog,
} from "@components/ui";
import { StackScreenProps } from "@react-navigation/stack";
import {
  NotificationsSettingsKey,
  TNotificationsSettings,
} from "@models/settings/user-settings-store";
import { NotificationType } from "@models/notifications/constants";
import { SettingsNavigatorParamList } from "@screens/Settings/navigation";
import { TopBar, TopBarAlign } from "@components/layout";

import { useAppTheme } from "../../../../hooks";
import { TxKeyPath, translate } from "../../../../i18n";
import { spacing } from "../../../../theme";
import { normalizeFontSize } from "../../../../utils/sizes";
import { goBack } from "../../../../navigators";
import { NotificationTypeIcon } from "./NotificationTypeIcon";

const createStyles = (theme: any) =>
  StyleSheet.create({
    screen: {
      paddingHorizontal: spacing[4],
      paddingVertical: spacing[2],
    },
    list: {
      marginBottom: spacing[8],
    },
    item: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      paddingLeft: spacing[4],
      paddingRight: spacing[2],
      paddingVertical: spacing[2],
      marginVertical: spacing[1],
      backgroundColor: theme.colors.cardBG,
      borderRadius: spacing[4],
    },
    row: {
      flexDirection: "row",
      alignItems: "center",
      flex: 1,
    },
    title: {
      fontSize: normalizeFontSize(16, 18),
      ...theme.fonts.medium,
    },
    description: {
      marginVertical: spacing[4],
      fontSize: normalizeFontSize(14, 16),
      lineHeight: 20,
    },
    types: {
      marginLeft: spacing[5],
    },
    group: {
      marginBottom: spacing[4],
    },
  });

type FilterItemsProps = {
  type: NotificationType;
  title: string;
  state: boolean;
  onPress: (type: string) => void;
  style?: StyleProp<ViewStyle>;
};

const FilterItem = ({
  type,
  title,
  state,
  onPress,
  style,
}: FilterItemsProps) => {
  const onCheck = () => {
    onPress(type);
  };

  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <TouchableOpacity style={[styles.item, style && style]} onPress={onCheck}>
      <View style={styles.row}>
        <NotificationTypeIcon type={type} defaultIcon={null} />
        <Text style={styles.title} numberOfLines={1} ellipsizeMode="tail">
          {title}
        </Text>
      </View>

      <Checkbox status={state ? "checked" : "unchecked"} onPress={onCheck} />
    </TouchableOpacity>
  );
};

export const NotificationsViewFilter: React.FC<
  StackScreenProps<SettingsNavigatorParamList, "settings-notifications-filter">
> = observer(() => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  const { notificationsStore } = useStores();
  const {
    severityLevelsSettings: initSeverityLevelsSettings,
    notificationsTypesSettings: initNotificationsTypesSettings,
    severityLevels,
    notificationsTypesForSeverityLevel,
    getNotificationType,
  } = notificationsStore;

  const [severityLevelsState, setSeverityLevelsState] = useState(
    initSeverityLevelsSettings || [],
  );
  const [notificationsTypesState, setNotificationsTypesState] = useState(
    initNotificationsTypesSettings || [],
  );

  const settings: TNotificationsSettings = {
    [NotificationsSettingsKey.SeverityLevels]: severityLevelsState,
    [NotificationsSettingsKey.Types]: notificationsTypesState,
  };

  const save = async () => {
    await notificationsStore.updateViewFilter(settings);
    goBack();
  };

  const {
    showDialog: showLastSeverityLevelDialog,
    ...dialogSeverityLevelProps
  } = useDialog();

  const setNotificationsTypesToState = (types: string[], checked: boolean) => {
    setNotificationsTypesState(
      checked
        ? [...notificationsTypesState, ...types]
        : notificationsTypesState.filter(
            (item: string) => !types.includes(item),
          ),
    );
  };

  const changeSeverityLevels = (severityLevel: string, checked: boolean) => {
    const typesForSeverityLevel = notificationsTypesForSeverityLevel(
      severityLevel,
    ).map((item) => item.type);

    if (checked) {
      setNotificationsTypesToState(typesForSeverityLevel, true);
      setSeverityLevelsState([...severityLevelsState, severityLevel]);
    } else {
      const newSeverityLevelsState = severityLevelsState.filter(
        (item) => item !== severityLevel,
      );
      if (newSeverityLevelsState.length) {
        setSeverityLevelsState(newSeverityLevelsState);
        setNotificationsTypesToState(typesForSeverityLevel, false);
      } else {
        showLastSeverityLevelDialog();
      }
    }
  };

  const onPressNotificationsTypes = (type: string) => {
    const { severityLevel } = getNotificationType(type);
    const checked = !notificationsTypesState.includes(type);

    if (checked) {
      setNotificationsTypesState([...notificationsTypesState, type]);
      if (!severityLevelsState.includes(severityLevel)) {
        setSeverityLevelsState([...severityLevelsState, severityLevel]);
      }
    } else {
      const newNotificationsTypesState = notificationsTypesState.filter(
        (item) => item !== type,
      );
      const isLastTypeInSeverity =
        notificationsTypesForSeverityLevel(severityLevel).filter((item) =>
          notificationsTypesState.includes(item.type),
        ).length <= 1;

      if (isLastTypeInSeverity) {
        changeSeverityLevels(severityLevel, false);
      } else {
        setNotificationsTypesState(newNotificationsTypesState);
      }
    }
  };

  const onPressSeverityLevels = (severityLevel: string) => {
    changeSeverityLevels(
      severityLevel,
      !severityLevelsState.includes(severityLevel),
    );
  };

  return (
    <Screen
      style={styles.screen}
      preset="fixed"
      header={
        <TopBar
          title={translate("Settings.notifications.filter.title")}
          back
          align={TopBarAlign.Left}
        />
      }
    >
      <ScrollView style={styles.list}>
        <Text style={styles.description}>
          {translate("Settings.notifications.filter.description")}
        </Text>

        {severityLevels.map((severity) => {
          return (
            <View key={severity} style={styles.group}>
              <FilterItem
                type={severity}
                title={translate(
                  `Settings.notifications.${severity}` as TxKeyPath,
                  {
                    defaultValue: severity,
                  },
                )}
                state={severityLevelsState.includes(severity)}
                onPress={onPressSeverityLevels}
              />

              {notificationsTypesForSeverityLevel(severity).map((item) => (
                <FilterItem
                  key={item.type}
                  style={styles.types}
                  type={item.type}
                  title={item.preferencesTitle}
                  state={notificationsTypesState.includes(item.type)}
                  onPress={onPressNotificationsTypes}
                />
              ))}
            </View>
          );
        })}
      </ScrollView>
      <FloatingButton onPress={save} tx="common.apply">
        Select
      </FloatingButton>

      <Dialog
        {...dialogSeverityLevelProps}
        title={translate("common.warning")}
        content={translate(
          "Settings.notifications.filter.disableLastSeverityLevelMessage",
        )}
        onCancel={false}
      />
    </Screen>
  );
});
