import { View, Text, StyleSheet } from "react-native";
import { useTheme } from "@react-navigation/native";
import { spacing } from "../../theme";
import { kNumberFormatter } from "../../utils/numbers";

const createStyles = (
  theme: any,
  leftPercent: string,
  secondPercent: string,
  thirdPercent: string,
  rightPercent: string,
  height = 8,
) =>
  StyleSheet.create({
    minMaxBar: {
      flexDirection: "row",
      width: "100%",
      overflow: "hidden",
    },
    minMaxBarText: {
      flexDirection: "row",
      width: "100%",
      overflow: "hidden",
      justifyContent: "space-between",
      marginTop: 2,
    },
    minText: {
      fontSize: 11,
      // paddingLeft: `calc(${leftPercent} - 15px)`,
      paddingLeft: 15,
    },
    maxText: {
      fontSize: 11,
      // paddingRight: `calc(${rightPercent} - 15px)`,
      paddingRight: 15,
    },
    leftBar: {
      borderTopLeftRadius: spacing[1],
      borderBottomLeftRadius: spacing[1],
      backgroundColor: theme.colors.label,
      height,
      width: leftPercent,
    },
    secondBar: {
      backgroundColor: theme.colors.minMaxBarCenter,
      height,
      width: secondPercent,
    },
    valueBox: {
      backgroundColor: theme.colors.minMaxBarCenter,
      width: "2%",
    },
    valueBar: {
      backgroundColor: theme.colors.text,
      borderRadius: spacing[1],
      height,
      width: "100%",
    },
    thirdBar: {
      backgroundColor: theme.colors.minMaxBarCenter,
      height,
      width: thirdPercent,
    },
    rightBar: {
      borderTopRightRadius: spacing[1],
      borderBottomRightRadius: spacing[1],
      backgroundColor: theme.colors.label,
      height,
      width: rightPercent,
    },
  });

type Props = React.ComponentProps<typeof View> & {
  min: number;
  max: number;
  value: number;
};

export const MinMaxBar = (props: Props) => {
  const { min = 0, max = 0, value, style } = props;

  const capacity = max ? (max - min) * 2 : null;
  const onePercent = capacity / 100;
  const minPercent = onePercent ? min / onePercent : 0;
  const valuePercent = onePercent ? value / onePercent : 50;
  const maxPercent = onePercent ? max / onePercent : 100;

  const leftPercentText = `${minPercent}%`;
  const secondPercentText = `${valuePercent - minPercent}%`;
  const thirdPercentText = `${maxPercent - valuePercent}%`;
  const rightPercentText = `${100 - maxPercent}%`;

  const theme = useTheme();
  const styles = createStyles(
    theme,
    leftPercentText,
    secondPercentText,
    thirdPercentText,
    rightPercentText,
  );

  return (
    <View style={style}>
      <View style={styles.minMaxBar}>
        <View style={styles.leftBar} />
        <View style={styles.secondBar} />
        <View style={styles.valueBox}>
          <View style={styles.valueBar} />
        </View>
        <View style={styles.thirdBar} />
        <View style={styles.rightBar} />
      </View>
      <View style={styles.minMaxBarText}>
        <Text style={styles.minText}>{min ? kNumberFormatter(min) : "--"}</Text>
        <Text style={styles.maxText}>{max ? kNumberFormatter(max) : "--"}</Text>
      </View>
    </View>
  );
};
