import * as React from "react";
import { View } from "react-native";
import Svg, { G, Rect, Circle, Path, SvgProps } from "react-native-svg";
import { spacing } from "../../../../theme";

export const CalibrationSoilMoistureAirSvg = (props: SvgProps) => (
  <View style={{ marginTop: -spacing[8] }}>
    <Svg width={360} height={312} viewBox="0 0 360 312" fill="none" {...props}>
      <G clipPath="url(#clip0_306_690)">
        <Circle
          cx={103}
          cy={297}
          r={11}
          fill="#B6C5D3"
          stroke="white"
          strokeWidth={2}
        />
        <Path
          d="M104.374 303H102.694V295.416C102.694 295.149 102.694 294.904 102.694 294.68C102.705 294.445 102.715 294.221 102.726 294.008C102.737 293.784 102.747 293.571 102.758 293.368C102.619 293.507 102.475 293.64 102.326 293.768C102.177 293.896 102.006 294.045 101.814 294.216L100.566 295.224L99.7019 294.136L102.966 291.576H104.374V303Z"
          fill="white"
        />
        <Path
          d="M279.563 191.215H231.671C230.142 191.215 228.91 189.984 228.91 188.455V87.56C228.91 86.0311 230.142 84.7994 231.671 84.7994H279.563C281.092 84.7994 282.323 86.0311 282.323 87.56V188.455C282.323 189.97 281.092 191.215 279.563 191.215Z"
          fill="#DBE2EA"
        />
        <Path
          d="M287.139 99.3947V87.4323C287.139 83.3268 283.812 80 279.707 80H231.432C227.327 80 224 83.3268 224 87.4323V188.568C224 192.673 227.327 196 231.432 196H279.707C283.812 196 287.139 192.673 287.139 188.568V118.336"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M257.904 101.448L233.71 126.364"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M241.668 102.043L234.632 109.404"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M276.507 186.02H234.646C234.32 186.02 234.051 185.751 234.051 185.425V178.12C234.051 177.795 234.32 177.526 234.646 177.526H276.507C276.833 177.526 277.102 177.795 277.102 178.12V185.425C277.102 185.751 276.833 186.02 276.507 186.02Z"
          fill="#3F9A6E"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Rect
          x={247}
          y={85}
          width={18}
          height={4}
          rx={2}
          fill="#2B2E32"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Circle
          cx={255}
          cy={298}
          r={11}
          fill="#B6C5D3"
          stroke="white"
          strokeWidth={2}
        />
        <Path
          d="M258.838 304H251.158V302.688L254.102 299.696C254.678 299.109 255.158 298.603 255.542 298.176C255.926 297.739 256.214 297.323 256.406 296.928C256.598 296.523 256.694 296.085 256.694 295.616C256.694 295.029 256.523 294.592 256.182 294.304C255.841 294.005 255.398 293.856 254.854 293.856C254.331 293.856 253.862 293.957 253.446 294.16C253.03 294.352 252.598 294.624 252.15 294.976L251.238 293.84C251.547 293.573 251.878 293.333 252.23 293.12C252.593 292.907 252.993 292.736 253.43 292.608C253.878 292.48 254.369 292.416 254.902 292.416C255.627 292.416 256.251 292.544 256.774 292.8C257.297 293.045 257.697 293.397 257.974 293.856C258.262 294.315 258.406 294.853 258.406 295.472C258.406 295.931 258.337 296.363 258.198 296.768C258.059 297.163 257.857 297.552 257.59 297.936C257.334 298.32 257.019 298.709 256.646 299.104C256.273 299.499 255.862 299.915 255.414 300.352L253.286 302.416V302.496H258.838V304Z"
          fill="white"
        />
        <Path
          d="M273.837 229.087L274.449 226.801C275.258 223.784 278.354 221.996 281.371 222.805C284.388 223.613 286.175 226.709 285.367 229.726L280.91 246.359L279.342 252.21C278.896 253.874 278.089 255.427 276.981 256.747L265.683 270.177L264.001 275.5M226.788 273.638L230.326 260.435L225.159 243.434C224.521 241.327 224.483 239.084 225.053 236.96L227.655 227.247C228.663 223.486 231.466 220.467 235.14 219.175L242.029 216.758"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M238.323 230.591L250.661 184.547C251.469 181.53 254.565 179.743 257.582 180.551C260.599 181.359 262.387 184.455 261.578 187.472L252.369 221.841"
          fill="white"
        />
        <Path
          d="M238.323 230.591L250.661 184.547C251.469 181.53 254.565 179.743 257.582 180.551C260.599 181.359 262.387 184.455 261.578 187.472L252.369 221.841"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M252.37 221.834L256.147 207.738C256.956 204.721 260.052 202.933 263.069 203.742C266.086 204.55 267.873 207.646 267.065 210.663L262.608 227.296"
          fill="white"
        />
        <Path
          d="M252.37 221.834L256.147 207.738C256.956 204.721 260.052 202.933 263.069 203.742C266.086 204.55 267.873 207.646 267.065 210.663L262.608 227.296"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M262.612 227.306L265.947 214.859C266.756 211.843 269.852 210.055 272.869 210.863C275.885 211.672 277.673 214.768 276.865 217.785L272.816 232.896"
          fill="white"
        />
        <Path
          d="M262.612 227.306L265.947 214.859C266.756 211.843 269.852 210.055 272.869 210.863C275.885 211.672 277.673 214.768 276.865 217.785L272.816 232.896"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M91.9519 188.25V218.75"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M101.452 188.25V218.75"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M97.2197 188.25V218.75"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M110.952 188.25V218.75"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M101.779 141.674V139.623C101.779 125.058 89.971 113.25 75.4064 113.25C60.8417 113.25 49.0333 125.058 49.0333 139.623V187.658C49.0333 202.222 37.2249 214.031 22.6603 214.031H20.191C5.62631 214.031 -6.18206 202.222 -6.18206 187.658V180.925C-6.18206 166.361 -17.9904 154.552 -32.5551 154.552H-55"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Rect
          width={28}
          height={35}
          rx={3}
          transform="matrix(-1 0 0 1 115.452 153.25)"
          fill="#6A7B8C"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M105.952 145.25C105.952 143.593 104.609 142.25 102.952 142.25H99.9519C98.2951 142.25 96.9519 143.593 96.9519 145.25V153.25H105.952V145.25Z"
          fill="#6A7B8C"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M110.952 158.127V172.796"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M110.952 177.296V182.297"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
      </G>
    </Svg>
  </View>
);
