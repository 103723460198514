import * as React from "react";

import Svg, { Path } from "react-native-svg";

export const ModuleAppConnectingSvg = () => (
  <Svg width={360} height={250} viewBox="0 0 360 250" fill="none">
    <Path
      d="M113.477 137.188C117.985 129.187 111.273 116.858 98.4838 109.652C85.6949 102.446 71.6723 103.091 67.1637 111.092C62.655 119.094 69.3675 131.422 82.1565 138.628C94.9455 145.835 108.968 145.19 113.477 137.188Z"
      fill="white"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M82.7023 119.835L113.482 137.175"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M121.277 131.987C134.659 131.913 145.412 114.523 145.294 93.1436C145.176 71.7647 134.233 54.4934 120.851 54.5671C107.469 54.6408 96.7164 72.0316 96.8342 93.4106C96.952 114.79 107.896 132.061 121.277 131.987Z"
      fill="white"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M120.892 62.3647L121.272 131.985"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M142.772 78.8447L121.092 97.9947"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M97.0023 95.3048L121.192 116.495"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M104.612 68.5048L121.002 82.8648"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M130.842 89.3848L145.102 89.3048"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M101.082 74.5147L111.412 74.4647"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M254.544 216.725H102.924C85.6942 216.725 75.0242 197.945 83.8642 183.145L158.254 58.565C167.454 43.155 189.764 43.145 198.984 58.535L273.584 183.115C282.454 197.915 271.794 216.725 254.544 216.725Z"
      fill="#B0EACC"
    />
    <Path
      d="M135.111 180.426H176.621H211.681H256.771C264.751 180.426 269.681 171.726 265.581 164.886L231.081 107.266C228.961 103.726 225.471 101.946 221.841 101.936H135.111"
      fill="white"
    />
    <Path
      d="M135.111 180.426H176.621H211.681H256.771C264.751 180.426 269.681 171.726 265.581 164.886L231.081 107.266C228.961 103.726 225.471 101.946 221.841 101.936H135.111"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M170.172 180.425H100.052C92.0818 180.425 87.1518 171.735 91.2318 164.895L125.632 107.275C129.892 100.145 140.202 100.145 144.472 107.265L178.972 164.885C183.082 171.725 178.152 180.425 170.172 180.425Z"
      fill="white"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M134.073 139.145C134.303 138.815 134.693 138.615 135.103 138.615C135.513 138.615 135.883 138.805 136.133 139.135C136.163 139.185 136.203 139.225 136.213 139.275L149.503 161.395H154.613L137.043 132.165C136.653 131.475 135.903 131.055 135.103 131.055C134.323 131.055 133.583 131.465 133.193 132.125L133.123 132.235L115.593 161.405H120.703L134.013 139.265C134.023 139.215 134.043 139.175 134.073 139.145Z"
      fill="#3F9A6E"
    />
    <Path
      d="M162.583 159.575L142.803 126.645L141.073 123.765C139.853 121.725 137.563 120.455 135.123 120.455C132.683 120.455 130.393 121.725 129.173 123.765L128.503 124.895L107.663 159.575C106.443 161.615 106.443 164.135 107.663 166.175C108.883 168.215 111.173 169.485 113.613 169.485H119.203H119.513H130.423H130.523C131.843 169.535 132.873 170.565 132.873 171.835V176.955H137.293V171.835C137.293 168.205 134.213 165.235 130.423 165.235H113.563C112.713 165.215 111.913 164.765 111.493 164.065C111.053 163.345 111.053 162.435 111.493 161.715L133.043 125.875C133.483 125.175 134.303 124.735 135.143 124.735C135.993 124.735 136.803 125.175 137.243 125.875L158.793 161.715C159.233 162.435 159.233 163.345 158.793 164.065C158.373 164.765 157.573 165.225 156.723 165.235H149.283H149.153C148.273 165.235 147.473 164.785 147.033 164.065L137.103 147.535C136.713 146.845 135.963 146.425 135.163 146.425C134.383 146.425 133.643 146.835 133.253 147.495L133.183 147.605L124.863 161.435H129.963L134.053 154.635C134.073 154.605 134.103 154.555 134.123 154.525C134.353 154.195 134.743 153.995 135.153 153.995C135.563 153.995 135.933 154.185 136.183 154.515C136.213 154.565 136.243 154.605 136.263 154.655L143.213 166.205C144.433 168.245 146.723 169.515 149.163 169.515H150.663H153.893H154.953H155.213H156.683C159.133 169.515 161.413 168.245 162.633 166.205C163.793 164.125 163.803 161.605 162.583 159.575Z"
      fill="#3F9A6E"
    />
    <Path
      d="M235.252 223.865H194.632C193.552 223.865 192.682 222.995 192.682 221.915V144.555C192.682 143.475 193.552 142.605 194.632 142.605H235.252C236.332 142.605 237.202 143.475 237.202 144.555V221.915C237.202 222.995 236.332 223.865 235.252 223.865Z"
      fill="white"
    />
    <Path
      d="M237.202 155.965V147.515C237.202 144.615 234.852 142.265 231.952 142.265H197.852C194.952 142.265 192.602 144.615 192.602 147.515V218.955C192.602 221.855 194.952 224.205 197.852 224.205H231.952C234.852 224.205 237.202 221.855 237.202 218.955V169.335"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M216.552 157.415L199.462 175.005"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M205.082 157.835L200.112 163.035"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M220.172 147.165H209.392C207.452 147.165 205.882 145.595 205.882 143.655V142.265H223.692V143.655C223.682 145.595 222.112 147.165 220.172 147.165Z"
      fill="#2B2E32"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M217.681 133.505C216.901 134.275 215.871 134.705 214.771 134.705C213.671 134.705 212.641 134.275 211.861 133.505C211.081 132.735 210.651 131.695 210.651 130.605C210.651 129.515 211.081 128.475 211.861 127.705C212.641 126.935 213.671 126.505 214.771 126.505C215.871 126.505 216.901 126.935 217.681 127.705C218.461 128.475 218.891 129.515 218.891 130.605C218.891 131.695 218.461 132.725 217.681 133.505Z"
      fill="#308AE3"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M231.121 123.895C230.211 124.795 229.021 125.295 227.731 125.295C226.451 125.295 225.251 124.795 224.341 123.895C221.791 121.345 218.391 119.935 214.771 119.935C211.161 119.935 207.751 121.335 205.201 123.895C204.291 124.795 203.091 125.295 201.811 125.295C200.531 125.295 199.331 124.795 198.421 123.895C197.511 122.985 197.021 121.795 197.021 120.515C197.021 119.245 197.521 118.035 198.421 117.135C202.781 112.775 208.591 110.385 214.761 110.385C220.931 110.385 226.741 112.785 231.101 117.135C232.011 118.035 232.501 119.235 232.501 120.515C232.521 121.785 232.021 122.985 231.121 123.895Z"
      fill="#308AE3"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M241.111 113.925C240.201 114.825 239.001 115.325 237.721 115.325C236.441 115.325 235.241 114.825 234.331 113.925C229.111 108.715 222.161 105.845 214.771 105.845C207.382 105.845 200.442 108.715 195.212 113.925C194.302 114.825 193.102 115.325 191.822 115.325C190.542 115.325 189.342 114.825 188.432 113.925C187.522 113.015 187.031 111.825 187.031 110.545C187.031 109.275 187.532 108.065 188.432 107.165C195.462 100.145 204.822 96.275 214.762 96.275C224.702 96.275 234.061 100.145 241.091 107.155C242.001 108.065 242.492 109.265 242.492 110.535C242.512 111.825 242.011 113.025 241.111 113.925Z"
      fill="#308AE3"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M247.731 105.335C246.451 105.335 245.251 104.835 244.341 103.935C236.441 96.055 225.951 91.715 214.771 91.715C203.591 91.715 193.101 96.055 185.201 103.935C184.301 104.845 183.101 105.335 181.811 105.335C180.531 105.335 179.331 104.845 178.421 103.945C177.511 103.035 177.021 101.845 177.021 100.565C177.021 99.285 177.521 98.085 178.421 97.185C188.121 87.505 201.031 82.165 214.761 82.165C228.491 82.165 241.401 87.495 251.111 97.185C252.011 98.095 252.511 99.285 252.511 100.565C252.511 101.845 252.011 103.045 251.111 103.945C250.211 104.845 249.011 105.335 247.731 105.335Z"
      fill="#308AE3"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M240.293 201.945H262.843C282.423 201.945 298.293 186.075 298.293 166.495C298.293 146.915 282.423 131.045 262.843 131.045H245.323"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
      strokeDasharray="12 12"
    />
  </Svg>
);
