import React, { useEffect, useMemo } from "react";
import { StyleSheet, View, ViewStyle } from "react-native";

import { Sensor } from "@models/sensor/sensor";
import { Text } from "@components/ui";
import { LineChart, LineChartProps } from "@components/Chart";

import { spacing } from "../../../../theme";
import * as SensorIcons from "../../../../svgs/sensors";
import { NoneIcon } from "../../../../svgs";
import { useAppTheme, useSensorHistory } from "../../../../hooks";
import { HistorySkeleton } from "./HistorySkeleton";
import { TPeriod } from "../../../../utils/timeConverter";

const createStyles = (theme) =>
  StyleSheet.create({
    container: {
      backgroundColor: theme.colors.cardBG,
      borderRadius: spacing[2],
      padding: spacing[2],
      marginBottom: spacing[3],
    },
    chartTitle: {
      flexDirection: "row",
      alignItems: "center",
    },
    chartTitleText: {
      marginLeft: spacing[1],
      fontSize: 10,
      color: theme.colors.label,
    },
  });

type ChartViewProps = LineChartProps & {
  iconName: string;
  displayName: string;
  style?: ViewStyle;
};

export const HistoryChartView = ({
  data,
  yMax,
  yMin,
  yPrecision,
  currentIndex,
  setCurrentIndex,
  color,
  error,
  iconName,
  displayName,
  style,
}: ChartViewProps) => {
  const theme = useAppTheme();
  const Icon = SensorIcons[iconName] || NoneIcon;
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <View style={[styles.container, style]}>
      <View style={styles.chartTitle}>
        <Icon color={theme.colors.label} size={20} />
        <Text style={styles.chartTitleText}>{displayName}</Text>
      </View>

      <LineChart
        data={data}
        yMax={yMax}
        yMin={yMin}
        yPrecision={yPrecision}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        color={color}
        error={error}
      />
    </View>
  );
};

type ChartProps = {
  sensor: Sensor;
  period: TPeriod;
  currentIndex: number;
  setCurrentIndex: (index: number) => void;
  setCurrentDate?: (value: Date) => void;
  color?: string;
  style?: ViewStyle;
};

export const HistoryChart = ({
  sensor,
  period,
  currentIndex,
  setCurrentIndex,
  setCurrentDate,
  color,
  style,
}: ChartProps) => {
  const { sensorHistory, isLoading, errors } = useSensorHistory({
    uid: sensor.uid,
    startDate: period.startDate,
    endDate: period.endDate,
    transformValues: sensor.transformValues,
    strict: true,
  });

  const setCurrentIndexHandler = (index: number) => {
    setCurrentDate(sensorHistory?.[index]?.dateTime);
    setCurrentIndex(index);
  };

  useEffect(() => {
    if (sensorHistory?.length) {
      setCurrentIndexHandler(0);
    }
  }, [sensorHistory]);

  if (isLoading) return <HistorySkeleton />;

  return (
    <HistoryChartView
      style={style}
      data={sensorHistory}
      iconName={sensor.iconName}
      displayName={sensor.displayName}
      yMax={sensor.sensorRange.maxValue}
      yMin={sensor.sensorRange.minValue}
      yPrecision={sensor.numberOfDecimals}
      currentIndex={currentIndex}
      setCurrentIndex={setCurrentIndexHandler}
      color={color}
      error={errors?.[0]}
    />
  );
};
