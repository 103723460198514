import React, { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { View, Platform, StyleSheet } from "react-native";
import { StackScreenProps } from "@react-navigation/stack";

import { useStores } from "@models/index";
import { Screen } from "@components/index";
import { TopBar } from "@components/layout";
import {
  CopyToClipboard,
  SelectionMenu,
  Text,
  useCopyToClipboard,
} from "@components/ui";

import { CustomControl } from "@components/inputs";
import {
  EmailIcon,
  // ConnectedAccountsIcon,
  PasswordIcon,
  DeleteIcon,
  AccountIcon,
} from "../../../svgs/menu";
import { spacing } from "../../../theme";
import { translate } from "../../../i18n";
import { useAppTheme } from "../../../hooks";
import { CopyIcon } from "../../../svgs";
import { SettingsNavigatorParamList } from "../navigation";
// import ConnectedAccounts from "../components/ConnectedAccounts";
import { SwitchAuthentication } from "./components/SwitchAuthentication";

const createStyles = (theme: any) =>
  StyleSheet.create({
    container: {
      padding: spacing[4],
    },
    control: {
      minHeight: 48,
      paddingHorizontal: spacing[5],
      marginBottom: spacing[3],
    },
    textContainer: {
      flex: 1,
      marginLeft: 10,
    },
    itemTitle: {
      fontSize: 16,
      color: "rgba(43, 46, 50, 0.87)",
      ...theme.fonts?.medium,
      marginBottom: spacing[1],
    },
    description: {
      fontSize: 12,
      color: theme.colors.label,
    },
  });

const iconCopy = CopyIcon({});

export const AccountScreen: React.FC<
  StackScreenProps<SettingsNavigatorParamList, "settings-account">
> = observer(({ navigation }) => {
  const theme = useAppTheme();
  const { accountStore } = useStores();

  const styles = useMemo(() => createStyles(theme), [theme]);

  const changePasswordHandler = () =>
    navigation.navigate("settings-account-password");
  const deleteAccountHandler = () =>
    navigation.navigate("settings-account-delete");
  const changeEmailHandler = () =>
    navigation.navigate("settings-account-change-email");

  const {
    copyToClipboard,
    copiedItem,
    onDismiss: onDismissCopy,
  } = useCopyToClipboard();

  const copyUserInfo = () => {
    const data = `${translate("fields.email")}: ${
      accountStore.currentUser.email
    }\r\nUserUID: ${accountStore.currentUser.uid}\r\nAccountUid: ${
      accountStore.currentUser.accountUid
    }`;
    copyToClipboard(data, translate("Settings.userInfo"));
  };

  return (
    <Screen
      testID="Account"
      header={<TopBar title={translate("Settings.account")} back />}
    >
      <View style={styles.container}>
        <CustomControl
          style={styles.control}
          onPress={copyUserInfo}
          control={iconCopy}
          icon={<AccountIcon color={theme.colors.label} />}
        >
          <View style={styles.textContainer}>
            <Text style={styles.itemTitle} numberOfLines={1}>
              {accountStore.currentUser.email}
            </Text>
            <Text style={styles.description} numberOfLines={1}>
              Uid: {accountStore.currentUser.uid}
            </Text>
            <Text style={styles.description} numberOfLines={1}>
              Acc: {accountStore.currentUser.accountUid}
            </Text>
          </View>
        </CustomControl>

        <SelectionMenu style={{ marginTop: 0 }}>
          <SelectionMenu.Item
            title={translate("Settings.changePassword")}
            icon={PasswordIcon}
            onPress={changePasswordHandler}
          />

          <SelectionMenu.Item
            title={translate("Settings.ChangeEmail.title")}
            icon={EmailIcon}
            onPress={changeEmailHandler}
          />

          {/* <SelectionMenu.Item */}
          {/*  title={translate("Settings.connectedAccounts")} */}
          {/*  description={ConnectedAccounts} */}
          {/*  type="button" */}
          {/*  icon={ConnectedAccountsIcon} */}
          {/* /> */}

          {/* <SwitchAuthentication  /> */}

          {Platform.OS !== "web" && <SwitchAuthentication />}

          <SelectionMenu.Item
            title={translate("Auth.AccountDelete.title")}
            onPress={deleteAccountHandler}
            icon={DeleteIcon}
            color={theme.colors.warning}
            iconColor={theme.colors.warning}
            type="button"
          />
        </SelectionMenu>
      </View>

      <CopyToClipboard copiedItem={copiedItem} onDismiss={onDismissCopy} />
    </Screen>
  );
});
