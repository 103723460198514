import { useCallback, useEffect, useState } from "react";

import { useStores } from "@models/index";

import { ModuleActivityType } from "@models/module/module";
import { getPeriodWithTime } from "../utils/timeConverter";
import { ModuleApi } from "../services/api";
import { translate } from "../i18n";

type UseModuleActivityHistoryProps = {
  uid: string;
  startDate: string | undefined;
  endDate: string | undefined;
};

type TModuleActivityItem = {
  moduleState: ModuleActivityType;
  timestamp: Date;
};

type TModuleActivityResult = {
  isLoading: boolean;
  activityHistory: TModuleActivityItem[];
  errors: string[] | null;
  refetch: () => void;
};

export const useModuleActivityHistory = ({
  uid,
  startDate,
  endDate,
}: UseModuleActivityHistoryProps): TModuleActivityResult => {
  const [activityHistory, setActivityHistory] = useState<Array<any> | []>([]);
  const [errors, setErrors] = useState<string[] | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [refetchToken, setRefetchToken] = useState<number | null>(null);

  const rootStore = useStores();
  const moduleApi = new ModuleApi(rootStore.environment.api);

  const getHistory = async () => {
    if (!uid || !startDate || !endDate) return;

    setLoading(true);
    const result = await moduleApi.getActivityHistory(
      uid,
      getPeriodWithTime({ startDate, endDate }),
    );
    setLoading(false);

    if (result.kind === "ok" && result.data) {
      setActivityHistory(result.data);
      setErrors(null);
    }

    if (result.kind === "error") {
      setActivityHistory([]);
      setErrors([translate("Sensors.History.errors.requestFailed")]);
    }
  };

  useEffect(() => {
    getHistory();
  }, [uid, startDate, endDate, refetchToken]);

  const refetch = useCallback(() => setRefetchToken(Date.now()), []);

  return {
    isLoading,
    activityHistory,
    errors,
    refetch,
  };
};
