import { useCallback, useState } from "react";
import { Dimensions, View, ViewStyle, StyleSheet } from "react-native";
import {
  GestureHandlerRootView,
  LongPressGestureHandler,
} from "react-native-gesture-handler";
import Animated from "react-native-reanimated";
import * as shape from "d3-shape";

import { ErrorsMessage } from "@components/ui";

import { spacing } from "../../theme";
import { apx } from "./utils";
import AreaChart from "./lib/area-chart";
import { Rectangle, ScaleXDiagram, TooltipDiagram } from "./components";

const styles = StyleSheet.create({
  container: {
    position: "relative",
    overflow: "hidden",
  },
  emptyContainer: {
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: spacing[4],
  },
});

const RIGHT_PADDING = 8;

const OFFSET_SCALE_Y = 17;

const CONTENT_INSET = {
  top: OFFSET_SCALE_Y,
  bottom: OFFSET_SCALE_Y,
  right: apx(RIGHT_PADDING),
};

const yAccessor = ({ item }) => item?.value;

type ChartDataItem = {
  dateTime: string | Date;
  value: number;
  duration: number;
  label: string;
  state?: string;
};

export type ColumnChartProps = {
  data: ChartDataItem[];
  height?: number;
  style?: ViewStyle;
  color?: string;
  xMax?: number;
  xMin?: number;
  error?: string;
};

export const ColumnChart = ({
  data,
  height = Dimensions.get("window").width * 0.8,
  style,
  color,
  xMax,
  xMin = 0,

  error,
}: ColumnChartProps) => {
  const [positionX, setPositonX] = useState<number>(0);

  const updatePosition = useCallback(
    ({ nativeEvent }) => {
      const x = Math.round(nativeEvent.x);

      setPositonX(x || positionX);
    },
    [data],
  );

  if (error) {
    return (
      <View style={[styles.emptyContainer, { height }]}>
        <ErrorsMessage errors={[error]} />
      </View>
    );
  }

  return (
    <View style={[styles.container, style]}>
      <GestureHandlerRootView>
        <LongPressGestureHandler
          maxDist={100000}
          minDurationMs={0}
          onGestureEvent={updatePosition}
        >
          <Animated.View
            style={{
              height,
            }}
          >
            <AreaChart
              style={{ flex: 1 }}
              data={data}
              yAccessor={yAccessor}
              curve={shape.curveMonotoneX}
              contentInset={{ ...CONTENT_INSET }}
              xMin={xMin}
              xMax={xMax}
            >
              <TooltipDiagram
                positionX={positionX}
                offsetScaleY={OFFSET_SCALE_Y}
              />

              <Rectangle color={color} />

              {/* <AdditionalLines lines={[{ value: 10 }]} xOffset={16} /> */}
              <ScaleXDiagram offsetScaleX={RIGHT_PADDING} labelsCount={4} />
            </AreaChart>
          </Animated.View>
        </LongPressGestureHandler>
      </GestureHandlerRootView>
    </View>
  );
};
