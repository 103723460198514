import React, { useCallback, useMemo, useState } from "react";
import { useTheme } from "@react-navigation/native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import {
  FlatList,
  TouchableOpacity,
  View,
  StyleSheet,
  Linking,
  Dimensions,
  ViewStyle,
} from "react-native";

import { Text, Button, FloatingButton } from "@components/ui";

import { translate } from "../../i18n";
import { spacing } from "../../theme";

const createStyles = (theme: any) =>
  StyleSheet.create({
    picture: {
      alignItems: "center",
    },
    textContainer: {
      marginTop: spacing[6],
      marginBottom: spacing[6],
      paddingHorizontal: spacing[4],
    },
    title: {
      fontSize: 24,
      textAlign: "center",
      marginBottom: spacing[4],
    },
    description: {
      fontSize: 14,
      textAlign: "center",
      color: theme.colors.label,
    },
    sliderPagination: {
      flexDirection: "row",
      alignSelf: "center",
    },
    paginationItem: {
      marginRight: spacing[3],
    },
    dotActive: {
      backgroundColor: theme.colors.cardActive,
    },
    dot: {
      width: spacing[3],
      height: spacing[3],
      borderRadius: spacing[2],
      marginHorizontal: spacing[2],
      backgroundColor: theme.colors.cardBG,
    },
    buttons: {
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
      zIndex: 10,
      position: "absolute",
      paddingHorizontal: spacing[6],
    },
    button: {
      width: "45%",
    },
  });

type TButtons = {
  labelBack: string;
  labelNext: string;
  labelLast?: string;
  onBack: () => void;
  onNext: () => void;
  isFirstIndex?: boolean;
  isLastIndex?: boolean;
};

const FloatingButtons = ({
  labelBack,
  labelNext,
  labelLast,
  onBack,
  onNext,
  isFirstIndex = false,
  isLastIndex = false,
}: TButtons) => {
  const theme = useTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);
  const insets = useSafeAreaInsets();

  if (isFirstIndex) {
    return <FloatingButton onPress={onNext}>{labelNext}</FloatingButton>;
  }

  return (
    <View style={[styles.buttons, { bottom: spacing[4] + insets.bottom }]}>
      <Button style={styles.button} type="ghost" onPress={onBack}>
        {labelBack}
      </Button>
      <Button style={styles.button} type="primary" onPress={onNext}>
        {isLastIndex ? labelLast : labelNext}
      </Button>
    </View>
  );
};

export type TOnboardingStep = {
  id: number;
  Picture: React.VoidFunctionComponent;
  title: string;
  description: string;
  linkTitle?: string;
  linkUrl?: string;
  pageId?: string;
};

type OnboardingProps = {
  data: TOnboardingStep[];
  afterEnd: () => void;
  labelLastButton?: string;
  navigation?: any;
  style?: ViewStyle;
};

export const OnboardingStep = ({
  data,
  afterEnd,
  labelLastButton = translate("common.go"),
  navigation,
  style,
}: OnboardingProps) => {
  const theme = useTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);

  const { width } = Dimensions.get("window");

  const [activeIndex, setActiveIndex] = useState(0);
  const lastIndex = data.length - 1;
  const currentStep = data[activeIndex];

  const flatListRef = React.useRef<FlatList>();

  const goToSlide = (index: number) => {
    flatListRef.current?.scrollToOffset({
      offset: index * width,
      animated: false,
    });
    setActiveIndex(index);
  };

  const onScroll = (event) => {
    const offset = event.nativeEvent.contentOffset.x;
    const newIndex = Math.round(offset / width);
    if (newIndex !== activeIndex) {
      setActiveIndex(newIndex);
    }
  };

  const onLinkPress = useCallback(() => {
    if (currentStep.linkUrl) return Linking.openURL(currentStep.linkUrl);
    if (navigation && currentStep.pageId)
      return navigation.navigate("Page", { id: currentStep.pageId });
    return false;
  }, [currentStep]);

  return (
    <>
      <View style={style}>
        <FlatList
          ref={flatListRef}
          data={data}
          bounces={false}
          keyExtractor={(item) => item.id}
          initialNumToRender={data.length}
          onScroll={onScroll}
          horizontal
          pagingEnabled
          showsHorizontalScrollIndicator={false}
          // scrollEventThrottle={16}
          renderItem={({
            item: { Picture, title, description, linkTitle },
          }) => (
            <View style={{ width }}>
              <View style={styles.picture}>
                <Picture />
              </View>
              <View style={styles.textContainer}>
                <Text style={[styles.title, theme.fonts.medium]}>{title}</Text>
                <Text style={styles.description}>{description}</Text>

                {Boolean(linkTitle) && (
                  <Button type="link" onPress={onLinkPress}>
                    {linkTitle}
                  </Button>
                )}
              </View>
            </View>
          )}
        />
        <View style={styles.sliderPagination}>
          {data.map((item, index) => (
            <TouchableOpacity
              key={`pagination-${item.id}`}
              style={[styles.dot, index === activeIndex && styles.dotActive]}
              onPress={() => goToSlide(index)}
            />
          ))}
        </View>
      </View>

      <FloatingButtons
        isFirstIndex={activeIndex === 0}
        isLastIndex={activeIndex === lastIndex}
        labelBack={translate("common.back")}
        labelNext={translate("common.next")}
        labelLast={labelLastButton}
        onBack={() => goToSlide(activeIndex - 1)}
        onNext={
          activeIndex === lastIndex
            ? afterEnd
            : () => goToSlide(activeIndex + 1)
        }
      />
    </>
  );
};
