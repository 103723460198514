import { ApiResponse } from "apisauce";

import { ApiProblem, processResponse } from "./response-resolver";
import { Api } from "./api";

export type StartCalibrationResult =
  | { kind: "ok"; data: { calibrationUid: string; validUntil: Date } }
  | ApiProblem;

type ValueResult = { kind: "ok"; data: any } | ApiProblem;

export type StopCalibrationResult = { kind: "ok" } | ApiProblem;

export type processProps = {
  calibrationUid: string;
  value?: number;
};

export class CalibrationApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async calibration(sensorUid: string): Promise<StartCalibrationResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(
        `/Calibration/start`,
        { sensorUid },
      );
      return processResponse(response);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async startCalibration(sensorUid: string): Promise<StartCalibrationResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(
        `/Calibration/start`,
        { sensorUid },
      );
      return processResponse(response);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async firstValueStart(
    calibrationUid: string,
    value?: number,
  ): Promise<ValueResult> {
    const body: processProps = { calibrationUid };
    if (value) {
      body.value = value;
    }
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(
        `/Calibration/first_value`,
        body,
      );
      return processResponse(response);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async firstValueStop(
    calibrationUid: string,
    value?: number,
  ): Promise<ValueResult> {
    const body: processProps = { calibrationUid };
    if (value) {
      body.value = value;
    }
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(
        `/Calibration/first_value_stop`,
        body,
      );
      return processResponse(response);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async secondValueStart(
    calibrationUid: string,
    value?: number,
  ): Promise<ValueResult> {
    const body: processProps = { calibrationUid };
    if (value) {
      body.value = value;
    }
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(
        `/Calibration/second_value`,
        body,
      );
      return processResponse(response);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async secondValueStop(
    calibrationUid: string,
    value?: number,
  ): Promise<ValueResult> {
    const body: processProps = { calibrationUid };
    if (value) {
      body.value = value;
    }
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(
        `/Calibration/second_value_stop`,
        body,
      );
      return processResponse(response);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async stopCalibration(
    calibrationUid: string,
  ): Promise<StopCalibrationResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(
        `/Calibration/stop`,
        { calibrationUid },
      );
      return processResponse(response);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }
}
