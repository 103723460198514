import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { StackScreenProps } from "@react-navigation/stack";

import { useStores } from "@models/index";
import { Screen } from "@components/index";
import { ErrorsAlert, ErrorsMessage, ErrorText } from "@components/ui";
import { TopBarContent, TopBarNew } from "@components/layout";
import { ConfirmDialog } from "@components/dialog";

import { translate } from "../../../../../i18n";
import { goBack } from "../../../../../navigators";
import { DevicesNavigatorParamList } from "../../../navigation";
import { NoneIcon } from "../../../../../svgs";
import * as DeviceIcons from "../../../../../svgs/devices";
import { useRules } from "../../../../../hooks";
import { spacing } from "../../../../../theme";
import { DeviceTypeSelector } from "./components/DeviceTypeSelector";
import { RuleSelector } from "./components/RuleSelector";

export const DeviceRulesCopyScreen: React.FC<
  StackScreenProps<DevicesNavigatorParamList, "device-rules-copy">
> = observer(({ route, navigation }) => {
  const { params } = route;

  const { deviceStore } = useStores();
  const device = deviceStore.getDevice(params?.deviceUid);

  const [deviceType, setDeviceType] = useState();
  const [ruleForCopy, setRuleForCopy] = useState(null);

  if (!device)
    return <ErrorText>{translate("Devices.Details.noDevice")}</ErrorText>;

  const { isLoading } = device;
  const { isLoading: areRulesLoading, rules, errors, refetch } = useRules();

  const copyRule = async () => {
    const data = await device.copyRule(ruleForCopy);
    setRuleForCopy(null);
    if (data) {
      navigation.navigate("device-details", { uid: device.uid });
    }
  };

  const DeviceIcon = DeviceIcons[device.iconName] || NoneIcon;

  return (
    <Screen
      testID="DeviceRulesCopy"
      header={
        <>
          <TopBarNew
            title={translate("Devices.Rules.Copy.title")}
            backHandler={goBack}
          />
          <TopBarContent
            title={device.displayName}
            subtitle={device.typeName}
            icon={DeviceIcon}
          />
        </>
      }
      preset="scroll"
      loading={isLoading || areRulesLoading}
      onRefresh={refetch}
      style={{ padding: spacing[4] }}
    >
      <ErrorsMessage errors={errors} />

      {deviceType ? (
        <RuleSelector
          rules={rules}
          deviceType={deviceType}
          onPress={setRuleForCopy}
        />
      ) : (
        <DeviceTypeSelector rules={rules} onPress={setDeviceType} />
      )}

      <ErrorsAlert
        errors={deviceStore.statusErrors || device.statusErrors}
        onCancel={() => {
          deviceStore.resetErrors();
          device.resetErrors();
        }}
      />

      <ConfirmDialog
        content={translate("Devices.Rules.Copy.confirmMessage", {
          deviceFrom: ruleForCopy?.device?.displayName,
          deviceTo: device.displayName,
        })}
        okText={translate("Devices.Details.copyRule")}
        onOk={copyRule}
        onCancel={() => setRuleForCopy(null)}
        visible={ruleForCopy}
      />
    </Screen>
  );
});
