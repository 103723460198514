import Svg, { SvgProps, Path } from "react-native-svg";

export const GrowBook = (props: SvgProps) => (
  <Svg width={360} height={360} fill="none" {...props}>
    <Path fill="#fff" d="M0 0h360v360H0z" />
    <Path
      d="M220.37 122.787c9.76-.718 16.742-13.944 15.594-29.54-1.148-15.596-9.991-27.657-19.751-26.938-9.76.718-16.741 13.944-15.594 29.54 1.148 15.596 9.991 27.657 19.751 26.938Z"
      fill="#fff"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="m218 85 2 38"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="M278.199 125.066c18.9-12.712 27.774-32.609 19.819-44.44-7.954-11.83-29.724-11.116-48.624 1.596-18.901 12.713-27.774 32.609-19.819 44.44 7.954 11.831 29.724 11.117 48.624-1.596Z"
      fill="#fff"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="m291 85-61 42M289 114l-29-8M248 83l-5 34M276 74l-3 23M273 110l8 13M269 75l6 9"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="M185 185.366v-54.48c0-1.693.337-3.37.991-4.934a12.88 12.88 0 0 1 2.823-4.181 13.015 13.015 0 0 1 4.223-2.793 13.123 13.123 0 0 1 4.981-.978h97.974c3.45 0 6.758 1.358 9.198 3.774a12.823 12.823 0 0 1 3.81 9.112v126.228c0 3.418-1.37 6.695-3.81 9.112a13.074 13.074 0 0 1-9.198 3.774h-57.753"
      fill="#fff"
    />
    <Path
      d="M185 185.366v-54.48c0-1.693.337-3.37.991-4.934a12.88 12.88 0 0 1 2.823-4.181 13.015 13.015 0 0 1 4.223-2.793 13.123 13.123 0 0 1 4.981-.978h97.974c3.45 0 6.758 1.358 9.198 3.774a12.828 12.828 0 0 1 3.81 9.112v126.228c0 3.418-1.371 6.695-3.81 9.112a13.074 13.074 0 0 1-9.198 3.774h-57.753M247 206h41M247 216h32M247 225h41M247 234h18"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="m213 214.772 5.293-5.589a7.45 7.45 0 0 1 8.114-1.616 7.448 7.448 0 0 1 4.593 6.881 7.45 7.45 0 0 1-2.179 5.264L214.063 234"
      fill="#fff"
    />
    <Path
      d="m213 214.772 5.293-5.589a7.45 7.45 0 0 1 8.114-1.616 7.45 7.45 0 0 1 2.414 12.145L214.063 234"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="M206.21 308h-48.42a2.814 2.814 0 0 1-1.976-.802 2.738 2.738 0 0 1-.814-1.947V204.749a2.738 2.738 0 0 1 1.721-2.542 2.814 2.814 0 0 1 1.069-.207h48.42a2.814 2.814 0 0 1 1.976.802 2.738 2.738 0 0 1 .814 1.947v100.502a2.72 2.72 0 0 1-.817 1.944 2.8 2.8 0 0 1-1.973.805Z"
      fill="#DBE2EA"
    />
    <Path
      d="M215 305.484v-70.151h-2.222v70.112l2.222.039ZM206.384 314a8.665 8.665 0 0 0 6.092-2.494 8.466 8.466 0 0 0 2.524-6.022h-2.222a6.246 6.246 0 0 1-.484 2.42 6.302 6.302 0 0 1-1.386 2.052 6.436 6.436 0 0 1-4.524 1.848V314Zm-48.768 0h48.768v-2.196h-48.768V314ZM149 305.484c0 2.259.908 4.425 2.524 6.022a8.665 8.665 0 0 0 6.092 2.494v-2.196a6.436 6.436 0 0 1-4.524-1.848 6.302 6.302 0 0 1-1.386-2.052 6.246 6.246 0 0 1-.484-2.42H149Zm0-100.968v100.968h2.222V204.516H149Zm8.616-8.516a8.665 8.665 0 0 0-6.092 2.494 8.466 8.466 0 0 0-2.524 6.022h2.222a6.246 6.246 0 0 1 .484-2.42 6.302 6.302 0 0 1 1.386-2.052 6.381 6.381 0 0 1 2.076-1.369 6.455 6.455 0 0 1 2.448-.479V196Zm48.768 0h-48.768v2.196h48.768V196Zm8.616 8.516a8.466 8.466 0 0 0-2.524-6.022 8.665 8.665 0 0 0-6.092-2.494v2.196a6.455 6.455 0 0 1 2.448.479 6.381 6.381 0 0 1 2.076 1.369 6.302 6.302 0 0 1 1.386 2.052c.321.767.485 1.589.484 2.42H215Zm0 34.601v-34.601h-2.222v34.601H215ZM183.689 217.447l-24.195 24.921 1.578 1.533 24.196-24.921-1.579-1.533ZM167.447 218.008l-7.033 7.362 1.591 1.52 7.033-7.361-1.591-1.521Z"
      fill="#2A2E32"
    />
    <Path
      d="M203.411 294h-41.822c-.325 0-.589.28-.589.625v7.75c0 .345.264.625.589.625h41.822c.325 0 .589-.28.589-.625v-7.75c0-.345-.264-.625-.589-.625Z"
      fill="#3F9A6E"
    />
    <Path
      d="M202.812 302.251a.528.528 0 0 1 .149-.368.49.49 0 0 1 .358-.147V304a1.663 1.663 0 0 0 1.189-.516 1.78 1.78 0 0 0 .492-1.233h-2.188Zm0-7.512v7.512H205v-7.512h-2.188Zm.507.515a.505.505 0 0 1-.468-.316.523.523 0 0 1-.039-.199H205c0-.461-.177-.904-.492-1.23a1.653 1.653 0 0 0-1.189-.509v2.254Zm-41.628 0h41.628V293h-41.628v2.254Zm.497-.515a.527.527 0 0 1-.146.364.49.49 0 0 1-.351.151V293a1.66 1.66 0 0 0-1.192.509 1.78 1.78 0 0 0-.499 1.23h2.188Zm0 7.512v-7.512H160v7.512h2.188Zm-.497-.515c.131 0 .258.054.351.151a.524.524 0 0 1 .146.364H160c0 .464.178.909.495 1.237.317.328.747.512 1.196.512v-2.264Zm41.628 0h-41.628V304h41.628v-2.264ZM193.734 199.036a3.74 3.74 0 0 1-1.164 2.689 4.038 4.038 0 0 1-2.797 1.113V205a6.34 6.34 0 0 0 4.382-1.749 5.877 5.877 0 0 0 1.825-4.215h-2.246Zm0-1.965v1.965h2.246v-1.965h-2.246Zm-24.611 1.081h25.734V196h-25.734v2.152Zm1.113.855v-1.965H168v1.965h2.236Zm3.96 3.802a4.04 4.04 0 0 1-2.797-1.113 3.74 3.74 0 0 1-1.163-2.689H168c0 1.581.653 3.098 1.815 4.217a6.323 6.323 0 0 0 4.381 1.747v-2.162Zm15.577 0h-15.577v2.162h15.577v-2.162Zm5.104-5.738H196a1.066 1.066 0 0 0-.329-.761 1.132 1.132 0 0 0-.794-.31v1.071Zm-25.734 0V196a1.145 1.145 0 0 0-.793.31 1.045 1.045 0 0 0-.33.761h1.123Z"
      fill="#2A2E32"
    />
    <Path
      d="m121 351 14.489-27.519a16.132 16.132 0 0 0 .963-12.786l-3.688-10.671a34.893 34.893 0 0 1-.598-21.06l3.668-12.887c.373-1.305.564-2.655.567-4.011v-19.055a3.99 3.99 0 0 1 1.187-2.836 4.076 4.076 0 0 1 2.866-1.175 10.383 10.383 0 0 1 3.943.773 10.284 10.284 0 0 1 3.343 2.208 10.18 10.18 0 0 1 2.234 3.307c.518 1.236.785 2.562.785 3.901v22.695a10.308 10.308 0 0 0 3.039 7.291l3.131 3.129c8.37 8.364 13.071 12.917 13.071 38.5v-7.02"
      fill="#fff"
    />
    <Path
      d="m121 351 14.489-27.519a16.132 16.132 0 0 0 .963-12.786l-3.688-10.671a34.893 34.893 0 0 1-.598-21.06l3.668-12.887c.373-1.305.564-2.655.567-4.011v-19.055a3.99 3.99 0 0 1 1.187-2.836 4.076 4.076 0 0 1 2.866-1.175 10.383 10.383 0 0 1 3.943.773 10.284 10.284 0 0 1 3.343 2.208 10.18 10.18 0 0 1 2.234 3.307c.518 1.236.785 2.562.785 3.901v22.695a10.308 10.308 0 0 0 3.039 7.291l3.131 3.129c8.37 8.364 13.071 12.917 13.071 38.5v-7.02"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="m172 351 5.872-11.444a19.913 19.913 0 0 1 5.028-6.261 20.048 20.048 0 0 1 7.105-3.781 16.811 16.811 0 0 0 8.503-5.816L207 313"
      fill="#fff"
    />
    <Path
      d="m172 351 5.872-11.444a19.913 19.913 0 0 1 5.028-6.261 20.048 20.048 0 0 1 7.105-3.781 16.811 16.811 0 0 0 8.503-5.816L207 313"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="M221.514 252.094a7.567 7.567 0 1 0-10.699-10.701l-2.602 2.603a7.57 7.57 0 0 0-.004 10.704 7.567 7.567 0 0 0 10.703-.003l2.602-2.603ZM224.521 269.668a7.57 7.57 0 0 0 .004-10.704 7.567 7.567 0 0 0-10.702.003l-4.518 4.52a7.57 7.57 0 0 0-.004 10.704 7.566 7.566 0 0 0 10.702-.004l4.518-4.519Z"
      fill="#fff"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="M224.981 288.712a7.566 7.566 0 1 0-10.699-10.701l-5.876 5.877a7.572 7.572 0 0 0-.004 10.705 7.567 7.567 0 0 0 10.702-.004l5.877-5.877Z"
      fill="#fff"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="M227 133a24.005 24.005 0 0 0-16.971 7.029A24.005 24.005 0 0 0 203 157h24v-24Z"
      fill="#F66B6B"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="M232.5 138v23.99H209a24.364 24.364 0 0 0 3.956 13.336 23.613 23.613 0 0 0 10.544 8.844 23.047 23.047 0 0 0 13.579 1.37 23.356 23.356 0 0 0 12.035-6.566 24.167 24.167 0 0 0 6.434-12.288 24.483 24.483 0 0 0-1.336-13.868 23.898 23.898 0 0 0-8.655-10.773A23.159 23.159 0 0 0 232.5 138Z"
      fill="#B0EACC"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="M77 143H59v42h18v-42ZM109 110H90v75h19v-75ZM140 129h-19v56h19v-56ZM171 90h-18v95h18V90Z"
      fill="#BDE1FF"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path d="m68 105 31.33-34.326 31.33 16.829L162 60" fill="#fff" />
    <Path
      d="m68 105 31.33-34.326 31.33 16.829L162 60"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="M162 66a6 6 0 1 0 0-12 6 6 0 0 0 0 12ZM131 93c3.314 0 6-2.91 6-6.5s-2.686-6.5-6-6.5-6 2.91-6 6.5 2.686 6.5 6 6.5ZM99 78a6 6 0 1 0 0-12 6 6 0 0 0 0 12ZM68 111a6 6 0 1 0 0-12 6 6 0 0 0 0 12Z"
      fill="#fff"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="M101.741 216H66.258C62.25 216 59 219.227 59 223.209v55.582c0 3.982 3.25 7.209 7.258 7.209h35.483c4.009 0 7.259-3.227 7.259-7.209v-55.582c0-3.982-3.25-7.209-7.259-7.209ZM68 211v10M99 211v10M84 211v10M70 237h27M70 243h20M70 253h14M70 259h27"
      stroke="#2A2E32"
      strokeWidth={2.11}
      strokeLinejoin="round"
    />
    <Path
      d="M60 294c7.18 0 13-5.82 13-13s-5.82-13-13-13-13 5.82-13 13 5.82 13 13 13Z"
      fill="#B0EACC"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="M54 280.936 58.537 285 66 277"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
  </Svg>
);
