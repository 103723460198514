import React from "react";
import { StyleSheet, View } from "react-native";

import { LayoutCenter } from "@components/layout";
import { ScreenMessage } from "@components/ui";

import { NoAddedSensors } from "../../../../../../svgs/pictures";
import { spacing } from "../../../../../../theme";

const styles = StyleSheet.create({
  container: {
    justifyContent: "space-between",
    marginHorizontal: spacing[7],
    marginBottom: spacing[5],
  },
  picture: {
    marginBottom: spacing[4],
  },
});

type Props = {
  title: string;
  message?: string;
};

export const NoSensors: React.VFC<Props> = ({ title, message }) => {
  return (
    <LayoutCenter style={styles.container}>
      <View style={styles.picture}>
        <NoAddedSensors />
      </View>
      <ScreenMessage title={title} message={message} />
    </LayoutCenter>
  );
};
