import { Platform } from "react-native";
import { ApisauceInstance, create } from "apisauce";

import { i18n } from "../../i18n";
import { generateId } from "../../utils/generateId";

/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
const { version: appVersion } = require("../../../package.json");
/* eslint-enable global-require */
/* eslint-enable @typescript-eslint/no-var-requires */

const LOGS_SERVER_URL = "https://log.growdirector.com/api/v1/log";

enum LogLevel {
  Trace = "Trace",
  Debug = "Debug",
  Info = "Information",
  Warning = "Warning",
  Error = "Error",
  Critical = "Critical",
  None = "None",
}

type RNLoggerUser = {
  id: string;
  accountUid: string;
  email: string;
  ip_address?: string;
  username?: string;
};

type RNLoggerContext = {
  contextId: string;
  appVersion: string;
  platform: string;
  platformVersion?: any;
  locale: string;
  apiUrl: string;
  apiServer: string;
};

type RNLoggerContextParams = Pick<
  RNLoggerContext,
  "locale" | "apiUrl" | "apiServer"
>;

/* eslint-disable class-methods-use-this */
class RNLogger {
  apisauce: ApisauceInstance;

  user: RNLoggerUser;

  ctx: RNLoggerContext;

  constructor() {
    if (rnLogger) {
      // return rnLogger;
      throw new Error("New instance cannot be created!!");
    }
    this.configAPI();
    this.presetContext();
  }

  private configAPI() {
    this.apisauce = create({
      baseURL: LOGS_SERVER_URL,
      // timeout: 10000,
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
      },
    });
  }

  private presetContext() {
    this.ctx = {
      contextId: `ctx-${generateId()}`,
      appVersion,
      platform: Platform.OS,
      platformVersion: Platform.Version,
      locale: i18n.locale,
      apiUrl: "",
      apiServer: "",
    };
  }

  get client() {
    return `${this.ctx.platform}/${this.user?.id || "-"}`;
  }

  get defaultPayloads() {
    return {
      client: this.client,
      ctx: this.ctx.contextId,
      apiServer: this.ctx.apiServer,
      accountUid: this.user?.accountUid,
    };
  }

  setUser(user: RNLoggerUser) {
    this.user = user;
    this.info("RNLogger user", this.user);
  }

  setContext(ctxParams: RNLoggerContextParams) {
    this.ctx.apiUrl = ctxParams.apiUrl;
    this.ctx.apiServer = ctxParams.apiServer;
    this.ctx.locale = ctxParams.locale;
    this.info("RNLogger context", this.ctx);
  }

  error(message: string, payload: Record<string, any>) {
    return this.apisauce.post("", {
      logLevel: LogLevel.Error,
      message,
      payload: {
        ...payload,
        ...this.defaultPayloads,
      },
    });
  }

  info(message: string, payload: Record<string, any>) {
    return this.apisauce.post("", {
      logLevel: LogLevel.Info,
      message,
      payload: {
        ...payload,
        ...this.defaultPayloads,
      },
    });
  }

  debug(message: string, payload: Record<string, any>) {
    return this.apisauce.post("", {
      logLevel: LogLevel.Debug,
      message,
      payload: {
        ...payload,
        ...this.defaultPayloads,
      },
    });
  }
}
/* eslint-enable class-methods-use-this */

export const rnLogger = new RNLogger();
