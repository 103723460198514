import React, { useEffect, useState } from "react";
import { Image, StyleSheet, View } from "react-native";

import { MODULE_IMAGES } from "@assets/modules";
import {
  MODULE_SSID_PREFIXES_BY_TYPE,
  ModuleType,
} from "@models/module/module";
import {
  ErrorText,
  FloatingButton,
  Heading,
  RadioButton,
  Text,
} from "@components/ui";
import { CustomControl } from "@components/inputs";

import { useStores } from "@models/root-store/root-store-context";
import { palette, spacing } from "../../../../../theme";
import { valueMatchesPrefixes } from "../../../../../utils/wifi";

const detectModuleTypeByModuleSSID = (ssid: string): ModuleType | undefined => {
  let foundType;
  Object.entries(MODULE_SSID_PREFIXES_BY_TYPE).every(([type, prefixes]) => {
    if (valueMatchesPrefixes(ssid, prefixes)) {
      foundType = type;
      return false;
    }
    return true;
  });

  return foundType;
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  heading: {
    textAlign: "center",
    marginHorizontal: spacing[5],
    marginBottom: spacing[6],
    marginTop: spacing[7],
  },

  image: {
    width: 90,
    height: 57,
  },
  typeText: {},
  ssidText: {
    color: palette.GrayDark,
    fontSize: 12,
  },
});

type ModuleCardProps = {
  ssid: string;
  onPress?: (ssid: string) => void;
  checked?: boolean;
};
const ModuleCard = ({ ssid, onPress, checked }: ModuleCardProps) => {
  const { moduleStore } = useStores();
  const onPressHandler = () => onPress?.(ssid);
  const moduleTypeName = detectModuleTypeByModuleSSID(ssid);
  const moduleType = moduleStore.moduleTypes.get(moduleTypeName);
  const moduleTypeDisplay = moduleType ? moduleType.displayName : "Unknown";
  const imageSource = MODULE_IMAGES[moduleTypeName] || MODULE_IMAGES.default;

  return (
    <CustomControl
      onPress={onPressHandler}
      icon={<Image style={styles.image} source={imageSource} />}
      control={
        <RadioButton
          value="second"
          status={checked ? "checked" : "unchecked"}
          onPress={onPressHandler}
        />
      }
    >
      <View style={{ flex: 1 }}>
        <Text style={styles.typeText}>{moduleTypeDisplay}</Text>
        <Text style={styles.ssidText} numberOfLines={1} ellipsizeMode="tail">
          {ssid}
        </Text>
      </View>
    </CustomControl>
  );
};

type ModuleListProps = {
  modulesSSIDList: Array<string>;
  onSubmit: (moduleSSID: string) => void;
};
export const ModuleList = ({ modulesSSIDList, onSubmit }: ModuleListProps) => {
  const [moduleSSID, setModuleSSID] = useState<string>(null);
  const [isError, setError] = useState<boolean>(false);

  useEffect(() => {
    if (modulesSSIDList?.length === 1) {
      setModuleSSID(modulesSSIDList[0]);
    }
  }, [modulesSSIDList]);

  const onSubmitHandler = () => {
    if (moduleSSID) {
      onSubmit(moduleSSID);
    } else {
      setError(true);
    }
  };

  return (
    <View style={styles.container}>
      <Heading
        level={2}
        tx="Modules.Add.Smart.selectModule"
        style={styles.heading}
      />

      {modulesSSIDList.map((ssid) => (
        <ModuleCard
          key={ssid}
          ssid={ssid}
          onPress={setModuleSSID}
          checked={moduleSSID === ssid}
        />
      ))}

      {isError ? <ErrorText tx="Modules.Add.Smart.selectModule" /> : null}
      <FloatingButton onPress={onSubmitHandler} tx="common.start">
        Start
      </FloatingButton>
    </View>
  );
};
