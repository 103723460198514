import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import { observer } from "mobx-react-lite";

import { Device, DeviceState } from "@models/device/device";
import { Button, ErrorsAlert, Loading } from "@components/ui";
import { ConfirmDialog, ConfirmType } from "@components/dialog";

import { translate } from "../../../../i18n";
import { spacing } from "../../../../theme";
import { WarningSvg } from "../../../../svgs/pictures";
import { useDeviceSwitcher } from "../../components/useSwitcher";
import { DurationSelectorDialog } from "../../components/DurationSelector";
import { DurationDimmerSelectorDialog } from "../../components/DurationDimmerSelector";

const styles = StyleSheet.create({
  card: {
    alignItems: "center",
    justifyContent: "center",
    width: 72,
    height: 72,
  },
  loading: {
    padding: spacing[2],
  },
});

type DeviceOnOffButtonProps = {
  device: Device;
};

export const DeviceOnOffButton: React.FC<DeviceOnOffButtonProps> = observer(
  ({ device }) => {
    const [errors, setErrors] = useState<string[]>([]);
    const resetErrors = () => setErrors([]);
    const { state } = device;

    const [warning, setWarning] = useState<string>(null);
    const resetWarning = () => setWarning(null);

    const {
      visibleDurationDialog,
      hideDurationDialog,
      switchDevice,
      onSwitchPress,
    } = useDeviceSwitcher({ device, setErrors });

    const valueChangeHandler = () => {
      if (device.switchErrors?.length) {
        setErrors(device.switchErrors);
        return null;
      }

      const switchWarning = device.switchWarnings?.join();
      setWarning(switchWarning || null);
      if (!switchWarning) {
        onSwitchPress();
      }
      return null;
    };

    if (device.isLoading || device.isSwitching) {
      return (
        <View style={styles.card}>
          <Loading size="small" withMask={false} style={styles.loading} />
        </View>
      );
    }

    return (
      <>
        {state === DeviceState.Off ? (
          <Button type="secondary" onPress={valueChangeHandler}>
            {translate("Devices.Details.manuallyEnable")}
          </Button>
        ) : (
          // TODO so that you can always disable it, replace valueChangeHandler with switchDevice !!!
          <Button type="secondary_warn" onPress={valueChangeHandler}>
            {translate("Devices.Details.shutdown")}
          </Button>
        )}

        {device.type === "dimmer" ? (
          <DurationDimmerSelectorDialog
            onOk={switchDevice}
            onCancel={hideDurationDialog}
            visible={visibleDurationDialog}
          />
        ) : (
          <DurationSelectorDialog
            onOk={switchDevice}
            onCancel={hideDurationDialog}
            visible={visibleDurationDialog}
          />
        )}

        <ConfirmDialog
          content={warning}
          okText={
            device.isOn
              ? translate("common.turnOff")
              : translate("common.turnOn")
          }
          type={ConfirmType.Warning}
          onOk={() => {
            resetWarning();
            onSwitchPress();
          }}
          onCancel={resetWarning}
          visible={Boolean(warning)}
        />

        <ErrorsAlert
          errors={errors}
          onCancel={resetErrors}
          Image={WarningSvg}
          title=""
        />
      </>
    );
  },
);
