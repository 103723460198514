import Svg, { Path } from "react-native-svg";

export default function CalendarIcon({ size = 24, color = "#fff" }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.75 3a.75.75 0 0 0-1.5 0v1.25h-6.5V3a.75.75 0 0 0-1.5 0v1.25H6A2.75 2.75 0 0 0 3.25 7v12A2.75 2.75 0 0 0 6 21.75h12A2.75 2.75 0 0 0 20.75 19V7A2.75 2.75 0 0 0 18 4.25h-1.25V3Zm2.5 7.25V7c0-.69-.56-1.25-1.25-1.25h-1.25V7a.75.75 0 0 1-1.5 0V5.75h-6.5V7a.75.75 0 0 1-1.5 0V5.75H6c-.69 0-1.25.56-1.25 1.25v3.25h14.5Zm-14.5 1.5h14.5V19c0 .69-.56 1.25-1.25 1.25H6c-.69 0-1.25-.56-1.25-1.25v-7.25Zm5.5 3.25a.75.75 0 0 1 .75-.75h1a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-1.5 0v-2.25H11a.75.75 0 0 1-.75-.75Z"
        fill={color}
      />
    </Svg>
  );
}
