import React from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "@models/index";
import { FilterScreen, useFilterItems } from "@components/screen/Filter";
import { FilterItem } from "@screens/Sensors/components/FilterItem";

import { translate } from "../../../i18n";

export const ClimateHistoryFilter = observer(() => {
  const { sensorStore } = useStores();
  const items = useFilterItems(sensorStore.sortedSensors);

  return (
    <FilterScreen
      items={items}
      selectedUids={sensorStore.historyFilter.visibleUids}
      onApply={sensorStore.updateHistoryFilter}
      ItemComponent={FilterItem}
      description={translate("Sensors.History.selectSensorsWillBeDisplayed")}
    />
  );
});
