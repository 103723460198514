import { StyleSheet, View } from "react-native";
import { shadow } from "react-native-paper";

import { DeviceCard } from "@screens/Devices/components";
import { Device, DeviceState } from "@models/device/device";

import { spacing } from "../../../../../theme";

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    borderRadius: spacing[4],
    top: 128,
    left: 12,
    right: 12,
    ...(shadow(5) as any),
  },
  card: {
    marginBottom: 0,
  },
});

const device: Device = {
  name: "complex conditioner...",
  state: DeviceState.On,
  typeName: "Chiller",
  iconName: "ChillerIcon",
  displayState: "Work manually: 00:12:05",
};

export const Card = () => {
  return (
    <View style={styles.container}>
      <DeviceCard device={device} style={styles.card} />
    </View>
  );
};
