import React from "react";
import { View, StyleSheet, StyleProp, TextStyle } from "react-native";
import { useTheme } from "@react-navigation/native";

import { spacing } from "../../theme";
import { Heading } from "./Heading";
import { Text } from "./Text";

const styles = StyleSheet.create({
  title: {
    textAlign: "center",
    marginBottom: spacing[4],
  },
  description: {
    textAlign: "center",
    marginBottom: spacing[4],
  },
});

type Props = {
  title: string;
  message?: string;
  style?: StyleProp<TextStyle>;
};

export default function ScreenMessage({ title, message, style }: Props) {
  const theme = useTheme() as any;
  const descriptionStyle = { color: theme.colors.label };

  return (
    <View style={style}>
      <Heading style={styles.title} level={2}>
        {title}
      </Heading>
      <Text style={[styles.description, descriptionStyle]}>{message}</Text>
    </View>
  );
}
