import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

export const CalibrationPrepareDOSvg = (props: SvgProps) => (
  <Svg width={286} height={172} fill="none" {...props}>
    <Path
      fill="#B6C5D3"
      d="M41.159 141.136 27.176 143l5.593-23.305 21.44-2.331 33.094 23.772H41.159Z"
    />
    <Path
      fill="#fff"
      d="M46.017 131.312c-11.106 3.306-18.852 11.385-18.852 11.385V50.372s8.814-8.936 20.844-11.295"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M46.017 131.312c-11.106 3.306-18.852 11.385-18.852 11.385V50.372s8.814-8.936 20.844-11.295"
    />
    <Path
      fill="#fff"
      d="M106.623 42.582v89.22c0 5.298-13.621 9.582-30.437 9.582-16.804 0-30.437-4.296-30.437-9.582v-89.22"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M106.623 42.582v89.22c0 5.298-13.621 9.582-30.437 9.582-16.804 0-30.437-4.296-30.437-9.582v-89.22"
    />
    <Path
      fill="#2B2E32"
      d="M85.257 136.755H82.81v-4.452h2.448v4.452Zm0-8.904H82.81V123.4h2.448v4.451Zm0-8.903H82.81v-4.451h2.448v4.451Zm0-8.903H82.81v-4.451h2.448v4.451Zm0-8.903H82.81v-4.451h2.448v4.451Zm0-8.903H82.81v-4.451h2.448v4.451Zm0-8.903H82.81v-4.451h2.448v4.451Zm0-8.903H82.81v-4.452h2.448v4.452Zm0-8.903H82.81v-4.452h2.448v4.452Zm0-8.903H82.81v-4.452h2.448v4.452Z"
    />
    <Path
      fill="#fff"
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M76.186 52.164c16.81 0 30.437-4.29 30.437-9.582 0-5.292-13.627-9.582-30.437-9.582-16.81 0-30.437 4.29-30.437 9.582 0 5.292 13.627 9.582 30.437 9.582Z"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M75.792 49.347c-13.428 0-24.314-3.024-24.314-6.754 0-1.535 1.845-2.951 4.953-4.086m19.361-2.667c13.428 0 24.314 3.023 24.314 6.753 0 1.813-2.57 3.459-6.754 4.672M46.017 131.312c-11.106 3.306-18.852 11.385-18.852 11.385V50.372s8.814-8.936 20.844-11.295"
    />
    <Path
      fill="#B6C5D3"
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M76.187 45.787c7.216 0 13.066-1.49 13.066-3.327 0-1.838-5.85-3.328-13.066-3.328-7.215 0-13.065 1.49-13.065 3.328 0 1.837 5.85 3.327 13.065 3.327Z"
    />
    <Path
      fill="#B6C5D3"
      d="M154.217 142.451c15.676 0 28.385-3.857 28.385-8.616 0-4.759-12.709-8.617-28.385-8.617-15.677 0-28.385 3.858-28.385 8.617s12.708 8.616 28.385 8.616Z"
    />
    <Path
      fill="#70B8FF"
      fillOpacity={0.4}
      d="M157.765 98.86c13.717 0 24.837-3.404 24.837-7.603 0-4.2-11.12-7.603-24.837-7.603s-24.837 3.404-24.837 7.603 11.12 7.603 24.837 7.603Z"
    />
    <Path
      fill="#308AE3"
      fillRule="evenodd"
      d="M182.602 91.257c0 4.2-11.12 7.603-24.837 7.603-13.71 0-24.825-3.4-24.837-7.596V131.626c0 4.294 11.124 7.784 24.837 7.784 13.712 0 24.837-3.49 24.837-7.784v-41.89l-.491.01c.322.489.491.994.491 1.511Zm-49.674-.007c0-.16.017-.32.049-.478h-.049v.478Z"
      clipRule="evenodd"
      opacity={0.6}
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M182.602 69.461v62.17c0 4.292-11.149 7.779-24.892 7.779-13.742 0-24.891-3.487-24.891-7.779V80.728a6.762 6.762 0 0 0-2.07-4.873l-3.904-4.155"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M157.781 62.365c-10.594 0-17.558 2.383-22.494 4.585-1.692.75-5.335 2.577-7.921 3.883-.858.436-.597 1.682.36 1.766l4.987.46c1.753.266 3.456.738 5.085 1.415 4.514 1.875 11.789 3.097 19.983 3.097 13.716 0 24.821-3.411 24.821-7.609.012-4.198-11.105-7.597-24.821-7.597Z"
    />
    <Path
      fill="#fff"
      d="m157.934 104.651.093.094a49.973 49.973 0 0 1 2.38 2.818 38.702 38.702 0 0 1 2.287 3.196 29.789 29.789 0 0 1 1.823 3.256c.418.876.777 1.788 1.056 2.711.186.628.337 1.267.441 1.918a9.91 9.91 0 0 1 .116 2.202c-.058 1.089-.302 2.131-.778 3.102a7.009 7.009 0 0 1-2.356 2.699c-.766.533-1.602.9-2.496 1.148a9.98 9.98 0 0 1-1.289.261 8.606 8.606 0 0 1-1.741.071 9.273 9.273 0 0 1-3.506-.853 7.628 7.628 0 0 1-2.368-1.728c-.79-.864-1.324-1.882-1.625-3.031a9.147 9.147 0 0 1-.256-2.652c.035-.71.14-1.42.29-2.119.267-1.184.674-2.32 1.173-3.421a26.86 26.86 0 0 1 1.776-3.279 39.803 39.803 0 0 1 2.392-3.386 49.914 49.914 0 0 1 2.484-2.948c.046 0 .07-.024.104-.059Z"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M176.719 82.333v31.714M176.719 118.746v10.571"
    />
    <Path
      fill="#B6C5D3"
      d="M230.385 142.451c15.677 0 28.385-3.858 28.385-8.617s-12.708-8.616-28.385-8.616c-15.677 0-28.385 3.857-28.385 8.616 0 4.759 12.708 8.617 28.385 8.617Z"
    />
    <Path
      fill="#70B8FF"
      fillOpacity={0.4}
      d="M233.933 98.86c13.717 0 24.837-3.404 24.837-7.603 0-4.2-11.12-7.603-24.837-7.603s-24.837 3.404-24.837 7.603 11.12 7.603 24.837 7.603Z"
    />
    <Path
      fill="#308AE3"
      fillRule="evenodd"
      d="M258.77 91.257c0 4.2-11.12 7.603-24.837 7.603-13.684 0-24.783-3.387-24.837-7.572V131.626c0 4.294 11.124 7.784 24.837 7.784s24.837-3.49 24.837-7.784v-41.89l-.49.01c.321.489.49.994.49 1.511Zm-49.674-.031c.002-.152.019-.304.05-.454h-.05v.454Z"
      clipRule="evenodd"
      opacity={0.6}
    />
    <Path
      stroke="#fff"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M226.466 129.278a45.108 45.108 0 0 1-5.071-1.008v-15.397c1.762 0 3.371.183 5.071.421 2.391.336 4.339 2.339 4.325 4.749v7.257c0 2.424-1.948 4.315-4.325 3.978ZM240.617 129.169c-.263.041-.526.083-.802.11-2.391.33-4.339-1.525-4.325-3.888v-7.693c0-2.362 1.948-4.327 4.325-4.657.263-.041.539-.068.802-.109 2.376-.371 4.269 1.044 4.269 3.406v7.693c.014 2.35-1.893 4.767-4.269 5.138Z"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M258.769 69.461v62.17c0 4.291-11.148 7.779-24.891 7.779-13.743 0-24.891-3.488-24.891-7.779V80.728a6.762 6.762 0 0 0-2.071-4.873l-3.903-4.155"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M233.949 62.365c-10.594 0-17.558 2.383-22.495 4.585-1.691.75-5.334 2.577-7.921 3.883-.858.436-.596 1.682.361 1.766l4.986.46c1.754.266 3.457.738 5.086 1.415 4.514 1.875 11.789 3.097 19.983 3.097 13.716 0 24.82-3.411 24.82-7.609.013-4.198-11.104-7.597-24.82-7.597ZM251.934 82.333v31.714M251.934 118.746v10.571M3 104h24M259 104h24M107 104h26M183 104h26"
    />
    <Path
      stroke="#fff"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M226.708 108.351c-2.622-.385-4.708-3.078-4.708-5.691v-6.389c0-2.623 2.086-4.152 4.708-3.767 2.623.385 4.77 2.552 4.76 5.164v6.39c0 2.612-2.137 4.688-4.76 4.293ZM243.75 91.785a553.155 553.155 0 0 1-7.402 17.295M238.089 94.215c0-.577-.475-.992-1.053-.941-.587.05-1.063.557-1.063 1.134a.953.953 0 0 0 1.063.962c.588-.05 1.053-.577 1.053-1.155ZM244.023 106.407c0-.578-.466-.932-1.043-.79-.577.131-1.043.698-1.043 1.286 0 .577.466.952 1.043.82.577-.142 1.043-.739 1.043-1.316Z"
    />
  </Svg>
);
