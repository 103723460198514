import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { StackScreenProps } from "@react-navigation/stack";

import { useStores } from "@models/index";
import { HYDRO_TYPE } from "@models/device/types";
import { Loading } from "@components/ui";

import { DevicesNavigatorParamList } from "../navigation";
import { DeviceAddForm, DeviceAddFormData } from "./Form";
import NoFreeSockets from "./NoFreeSockets";

export const DevicesAddScreen: React.FC<
  StackScreenProps<DevicesNavigatorParamList, "devices-add">
> = observer(({ route }) => {
  const { params } = route;
  const initialFormData: DeviceAddFormData = {
    moduleUid: params?.moduleUid,
    socketUid: params?.socketUid,
  };
  const { moduleStore, deviceStore } = useStores();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await Promise.all([
        moduleStore.fetchModules(),
        deviceStore.fetchDevices(),
        deviceStore.fetchDeviceTypes(),
      ]);
      setIsLoading(false);
    };

    fetchData();
  }, [moduleStore, deviceStore]);

  if (moduleStore.isLoading || deviceStore.isLoading || isLoading) {
    return <Loading />;
  }

  if (moduleStore.isLoaded && !moduleStore.modulesWithFeeSockets().length) {
    return <NoFreeSockets />;
  }

  const module = moduleStore.getModule(params?.moduleUid);

  if (module?.isHydro) {
    initialFormData.type = HYDRO_TYPE;
  }

  return <DeviceAddForm initialFormData={initialFormData} />;
});
