import React, { useMemo, useState } from "react";
import { Image, Pressable, StyleSheet, View } from "react-native";
import { observer } from "mobx-react-lite";
import { useTheme } from "@react-navigation/native";

import { MODULE_IMAGES } from "@assets/modules";
import { Module } from "@models/module/module";
import { Screen } from "@components/index";
import { ScreenMessage, Text } from "@components/ui";
import { SelectorBar } from "@components/layout";

import {
  ModuleSocketData,
  ModuleSocketSelector,
} from "@screens/Devices/Add/ModuleSocketSelector";
import { translate } from "../../../i18n";
import { spacing } from "../../../theme";
import { ChevronRightIcon } from "../../../svgs";

const createStyles = (theme: any) =>
  StyleSheet.create({
    container: {
      padding: spacing[4],
    },
    description: {
      color: theme.colors.label,
      marginBottom: spacing[3],
    },
    card: {
      borderRadius: spacing[4],
      marginBottom: spacing[3],
      paddingRight: spacing[3],
      alignItems: "center",
      flexDirection: "row",
      backgroundColor: theme.colors.cardBG,
    },
    cardBlinked: {
      backgroundColor: theme.colors.cardDisabled,
    },
    module: {
      marginLeft: spacing[3],
      flex: 1,
    },
    picture: {
      width: 108,
      height: 108,
      marginLeft: spacing[2],
      borderRadius: spacing[4],
    },
    typeName: {
      marginBottom: spacing[1],
      fontSize: 12,
      color: theme.colors.label,
    },
    name: {
      marginBottom: spacing[3],
    },
    sockets: {
      flexDirection: "row",
    },
    socket: {
      fontSize: 12,
      color: theme.colors.palette.White,
      borderRadius: spacing[2],
      marginRight: spacing[1],
      width: spacing[4],
      lineHeight: spacing[4],
      textAlign: "center",
    },
    socketFree: {
      backgroundColor: theme.colors.palette.Green,
    },
    socketConnected: {
      backgroundColor: theme.colors.palette.Orange,
    },
  });

type ModuleWithSocketsProps = {
  module: Module;
  onPress?: (identifier: string) => void;
};
const ModuleWithSockets: React.FunctionComponent<ModuleWithSocketsProps> = ({
  module,
  onPress,
}) => {
  const theme = useTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);
  const onPressHandler = () => onPress(module.uid);
  const imageSource = MODULE_IMAGES[module.type] || MODULE_IMAGES.default;

  return (
    <Pressable style={styles.card} onPress={onPressHandler}>
      <Image style={styles.picture} source={imageSource} />
      <View style={styles.module}>
        <Text style={styles.typeName}>{module.typeName}</Text>
        <Text style={styles.name} numberOfLines={2} ellipsizeMode="tail">
          {module.name || "Unknown"}
        </Text>
        <View style={styles.sockets}>
          {module.sockets.map((socket) => (
            <Text
              key={socket.uid}
              style={[
                styles.socket,
                socket.isConnected ? styles.socketConnected : styles.socketFree,
              ]}
            >
              {socket.connectedToPin}
            </Text>
          ))}
        </View>
      </View>
      <ChevronRightIcon />
    </Pressable>
  );
};

type DeviceModuleSelectorProps = {
  modules: Module[];
  moduleSocketData?: ModuleSocketData;
  onChange: (type: ModuleSocketData) => void;
  onClose?: () => void;
};
export const DeviceModuleSelector: React.FunctionComponent<DeviceModuleSelectorProps> =
  observer(({ modules, moduleSocketData = {}, onChange, onClose }) => {
    const theme = useTheme() as any;
    const styles = useMemo(() => createStyles(theme), [theme]);
    const [moduleUid, setModuleUid] = useState<string>();
    const closeSocketSelector = () => setModuleUid(undefined);

    if (moduleUid) {
      return (
        <ModuleSocketSelector
          onChange={onChange}
          onClose={closeSocketSelector}
          moduleSocketData={{ ...moduleSocketData, moduleUid }}
        />
      );
    }

    return (
      <Screen
        testID="DeviceModuleSelector"
        preset="scroll"
        style={styles.container}
        header={
          <SelectorBar
            title={translate("Devices.Add.selectModule")}
            onClose={onClose}
          />
        }
      >
        <View style={{ flex: 1 }}>
          {modules.length ? (
            <>
              <Text
                style={styles.description}
                tx="Devices.Add.selectModuleDescription"
              />
              {modules.map((module) => (
                <ModuleWithSockets
                  key={module.uid}
                  module={module}
                  onPress={setModuleUid}
                />
              ))}
            </>
          ) : (
            <ScreenMessage
              title={translate("NoData.noFreeSockets")}
              message={translate("NoData.noFreeSocketsForDeviceType")}
            />
          )}
        </View>
      </Screen>
    );
  });
