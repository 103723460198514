import Svg, { SvgProps, Path } from "react-native-svg";

export const OneSpace = (props: SvgProps) => (
  <Svg width={360} height={360} fill="none" {...props}>
    <Path fill="#fff" d="M0 0h360v360H0z" />
    <Path
      d="M213 227h47.982a34.145 34.145 0 0 1 24.065 10.04A34.117 34.117 0 0 1 295 261.134v-.139a33.996 33.996 0 0 1-9.964 24.045 34.024 34.024 0 0 1-24.054 9.96h-33.58"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="M311 247a16.919 16.919 0 0 0-6.122 1.139 16.099 16.099 0 0 0-5.191 3.251 14.975 14.975 0 0 0-3.469 4.867A14.204 14.204 0 0 0 295 262"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="M312.196 256.191a15.85 15.85 0 0 0 4.759-3.776 29.995 29.995 0 0 0 4.949-8.169c.394-.911.716-1.852 1.009-2.784a.608.608 0 0 0-.404-.911 32.718 32.718 0 0 0-11.422-2.541 16.702 16.702 0 0 0-5.726.8 9.304 9.304 0 0 0-5.298 4.522 9.366 9.366 0 0 0-.691 6.948 9.322 9.322 0 0 0 4.303 5.485 9.26 9.26 0 0 0 6.888.953c.575-.142 1.099-.355 1.633-.527ZM301 163c0-2.101-.388-4.182-1.142-6.123a16.133 16.133 0 0 0-3.251-5.191 14.987 14.987 0 0 0-4.867-3.468A14.19 14.19 0 0 0 286 147"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="M290.863 164.301a15.432 15.432 0 0 0 3.747 4.683 29.68 29.68 0 0 0 8.127 4.891c.906.378 1.843.706 2.769 1.044a.617.617 0 0 0 .906-.397 32.292 32.292 0 0 0 2.578-11.275 16.307 16.307 0 0 0-.805-5.648 9.183 9.183 0 0 0-4.443-5.468 9.38 9.38 0 0 0-7.058-.766 9.257 9.257 0 0 0-5.539 4.386 9.057 9.057 0 0 0-.775 6.969c.101.537.312 1.054.493 1.581ZM277 260a15.001 15.001 0 0 1 15 15"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="M276.283 270.63a9.052 9.052 0 0 0 6.967-.775 9.255 9.255 0 0 0 4.385-5.54 9.384 9.384 0 0 0-.765-7.058 9.183 9.183 0 0 0-5.468-4.443 16.54 16.54 0 0 0-5.646-.806 32.296 32.296 0 0 0-11.272 2.538.61.61 0 0 0-.259.12.61.61 0 0 0-.139.787c.338.926.666 1.863 1.044 2.769a29.7 29.7 0 0 0 4.891 8.128 15.412 15.412 0 0 0 4.682 3.746c.526.212 1.043.393 1.58.534ZM254 243a15.001 15.001 0 0 1 15-15"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="M263.675 243.625a9.13 9.13 0 0 0-.864-6.919 9.295 9.295 0 0 0-5.514-4.338 9.396 9.396 0 0 0-7.005.726 9.234 9.234 0 0 0-4.485 5.375 16.682 16.682 0 0 0-.798 5.675 32.41 32.41 0 0 0 2.534 11.37.593.593 0 0 0 .349.434.603.603 0 0 0 .56-.035c.938-.329 1.867-.659 2.776-1.049a29.702 29.702 0 0 0 8.146-4.905 15.6 15.6 0 0 0 3.766-4.716c.172-.559.384-1.079.535-1.618ZM212 175a15.01 15.01 0 0 1 4.394-10.61A14.993 14.993 0 0 1 227 160"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="M222.675 175.65a9.166 9.166 0 0 0-.864-6.933 9.296 9.296 0 0 0-5.514-4.348 9.373 9.373 0 0 0-7.005.728 9.248 9.248 0 0 0-4.485 5.387 16.747 16.747 0 0 0-.798 5.687c.144 3.904.996 7.75 2.514 11.355.171.45.434.57.908.4.929-.33 1.858-.661 2.766-1.001a29.596 29.596 0 0 0 8.147-4.927 15.688 15.688 0 0 0 3.766-4.716c.181-.57.383-1.091.565-1.632ZM276 209c1.585 1.378 2.842 3.015 3.7 4.817a13.19 13.19 0 0 1 1.3 5.683c0 1.95-.442 3.882-1.3 5.683-.858 1.802-2.115 3.439-3.7 4.817"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="M268.51 216.203a9.358 9.358 0 0 0 5.58 4.436 9.29 9.29 0 0 0 7.065-.802 9.398 9.398 0 0 0 4.46-5.575 9.463 9.463 0 0 0-.726-7.122 17.102 17.102 0 0 0-3.497-4.665 33.157 33.157 0 0 0-9.933-6.369.618.618 0 0 0-.271-.102.616.616 0 0 0-.526.202.6.6 0 0 0-.135.258c-.416.908-.852 1.816-1.217 2.745a30.18 30.18 0 0 0-2.26 9.37 15.94 15.94 0 0 0 .71 6.124c.243.48.476 1.01.75 1.5ZM241 182c-3.202-2.918-5-6.875-5-11s1.798-8.082 5-11"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="M247.538 174.514a8.905 8.905 0 0 0-5.293-4.183 8.69 8.69 0 0 0-6.643.789 9.045 9.045 0 0 0-4.217 5.313 9.285 9.285 0 0 0 .605 6.818 16.736 16.736 0 0 0 3.349 4.501 31.436 31.436 0 0 0 9.428 6.145.563.563 0 0 0 .531.064.593.593 0 0 0 .354-.408c.395-.877.809-1.754 1.155-2.65a29.493 29.493 0 0 0 2.145-9.041 15.614 15.614 0 0 0-.673-5.91c-.26-.453-.433-.965-.741-1.438Z"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="M141.931 228h-37.719a34.354 34.354 0 0 1-24.195-10.068A34.218 34.218 0 0 1 70 193.757v.129a34.11 34.11 0 0 1 10.02-24.143 34.247 34.247 0 0 1 24.192-10h158.811a27.674 27.674 0 0 0 10.665-1.989 27.612 27.612 0 0 0 9.075-5.935A27.54 27.54 0 0 0 291 132.24v-.369a27.895 27.895 0 0 0-8.212-19.711 28.007 28.007 0 0 0-19.765-8.16h-26.728"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="m204 214.772 5.588-5.589a7.9 7.9 0 0 1 2.548-1.616 8.248 8.248 0 0 1 6.015 0c.954.375 1.82.924 2.549 1.616 1.473 1.397 2.3 3.29 2.3 5.265 0 1.974-.827 3.867-2.3 5.264L205.122 234"
      fill="#fff"
    />
    <Path
      d="m204 214.772 5.588-5.589a7.9 7.9 0 0 1 2.548-1.616 8.248 8.248 0 0 1 6.015 0c.954.375 1.82.924 2.549 1.616 1.473 1.397 2.3 3.29 2.3 5.265 0 1.974-.827 3.867-2.3 5.264L205.122 234"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="M197.262 308h-47.524a2.718 2.718 0 0 1-1.939-.802 2.755 2.755 0 0 1-.799-1.947V204.749a2.755 2.755 0 0 1 .799-1.947 2.727 2.727 0 0 1 1.939-.802h47.524a2.718 2.718 0 0 1 1.939.802 2.755 2.755 0 0 1 .799 1.947v100.502c0 .361-.071.718-.208 1.052a2.738 2.738 0 0 1-2.53 1.697Z"
      fill="#DBE2EA"
    />
    <Path
      d="M206 305.484v-70.151h-2.189v70.112l2.189.039ZM197.514 314a8.472 8.472 0 0 0 6.001-2.494 8.532 8.532 0 0 0 2.485-6.022h-2.189a6.312 6.312 0 0 1-3.885 5.841 6.26 6.26 0 0 1-2.412.479V314Zm-48.028 0h48.028v-2.196h-48.028V314ZM141 305.484c0 2.259.894 4.425 2.485 6.022a8.472 8.472 0 0 0 6.001 2.494v-2.196a6.288 6.288 0 0 1-5.821-3.9 6.312 6.312 0 0 1-.476-2.42H141Zm0-100.968v100.968h2.189V204.516H141Zm8.486-8.516a8.472 8.472 0 0 0-6.001 2.494 8.532 8.532 0 0 0-2.485 6.022h2.189a6.312 6.312 0 0 1 3.885-5.841 6.279 6.279 0 0 1 2.412-.479V196Zm48.028 0h-48.028v2.196h48.028V196Zm8.486 8.516a8.532 8.532 0 0 0-2.485-6.022 8.472 8.472 0 0 0-6.001-2.494v2.196a6.279 6.279 0 0 1 4.456 1.848 6.305 6.305 0 0 1 1.841 4.472H206Zm0 34.601v-34.601h-2.189v34.601H206ZM174.779 217.432l-24.195 24.923 1.578 1.534 24.195-24.923-1.578-1.534ZM158.537 217.993l-7.033 7.362 1.59 1.521 7.034-7.362-1.591-1.521Z"
      fill="#2A2E32"
    />
    <Path
      d="M194.411 294h-41.822c-.325 0-.589.28-.589.625v7.75c0 .345.264.625.589.625h41.822c.325 0 .589-.28.589-.625v-7.75c0-.345-.264-.625-.589-.625Z"
      fill="#3F9A6E"
    />
    <Path
      d="M193.812 302.251a.528.528 0 0 1 .149-.368.49.49 0 0 1 .358-.147V304a1.663 1.663 0 0 0 1.189-.516 1.78 1.78 0 0 0 .492-1.233h-2.188Zm0-7.512v7.512H196v-7.512h-2.188Zm.507.515a.505.505 0 0 1-.468-.316.523.523 0 0 1-.039-.199H196c0-.461-.177-.904-.492-1.23a1.653 1.653 0 0 0-1.189-.509v2.254Zm-41.628 0h41.628V293h-41.628v2.254Zm.497-.515a.527.527 0 0 1-.146.364.49.49 0 0 1-.351.151V293a1.66 1.66 0 0 0-1.192.509 1.78 1.78 0 0 0-.499 1.23h2.188Zm0 7.512v-7.512H151v7.512h2.188Zm-.497-.515c.131 0 .258.054.351.151a.524.524 0 0 1 .146.364H151c0 .464.178.909.495 1.237.317.328.747.512 1.196.512v-2.264Zm41.628 0h-41.628V304h41.628v-2.264ZM184.734 199.036a3.74 3.74 0 0 1-1.164 2.689 4.038 4.038 0 0 1-2.797 1.113V205a6.34 6.34 0 0 0 4.382-1.749 5.877 5.877 0 0 0 1.825-4.215h-2.246Zm0-1.965v1.965h2.246v-1.965h-2.246Zm-24.611 1.081h25.734V196h-25.734v2.152Zm1.113.855v-1.965H159v1.965h2.236Zm3.96 3.802a4.04 4.04 0 0 1-2.797-1.113 3.74 3.74 0 0 1-1.163-2.689H159c0 1.581.653 3.098 1.815 4.217a6.323 6.323 0 0 0 4.381 1.747v-2.162Zm15.577 0h-15.577v2.162h15.577v-2.162Zm5.104-5.738H187a1.066 1.066 0 0 0-.329-.761 1.132 1.132 0 0 0-.794-.31v1.071Zm-25.734 0V196a1.145 1.145 0 0 0-.793.31 1.045 1.045 0 0 0-.33.761h1.123Z"
      fill="#2A2E32"
    />
    <Path
      d="m112 351 14.489-27.519a16.132 16.132 0 0 0 .963-12.786l-3.688-10.671a34.893 34.893 0 0 1-.598-21.06l3.668-12.887c.373-1.305.564-2.655.567-4.011v-19.055a3.99 3.99 0 0 1 1.187-2.836 4.076 4.076 0 0 1 2.866-1.175 10.383 10.383 0 0 1 3.943.773 10.284 10.284 0 0 1 3.343 2.208 10.18 10.18 0 0 1 2.234 3.307c.518 1.236.785 2.562.785 3.901v22.695a10.308 10.308 0 0 0 3.039 7.291l3.131 3.129c8.37 8.364 13.071 12.917 13.071 38.5v-7.02"
      fill="#fff"
    />
    <Path
      d="m112 351 14.489-27.519a16.132 16.132 0 0 0 .963-12.786l-3.688-10.671a34.893 34.893 0 0 1-.598-21.06l3.668-12.887c.373-1.305.564-2.655.567-4.011v-19.055a3.99 3.99 0 0 1 1.187-2.836 4.076 4.076 0 0 1 2.866-1.175 10.383 10.383 0 0 1 3.943.773 10.284 10.284 0 0 1 3.343 2.208 10.18 10.18 0 0 1 2.234 3.307c.518 1.236.785 2.562.785 3.901v22.695a10.308 10.308 0 0 0 3.039 7.291l3.131 3.129c8.37 8.364 13.071 12.917 13.071 38.5v-7.02"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="m163 351 5.872-11.444a19.913 19.913 0 0 1 5.028-6.261 20.048 20.048 0 0 1 7.105-3.781 16.811 16.811 0 0 0 8.503-5.816L198 313"
      fill="#fff"
    />
    <Path
      d="m163 351 5.872-11.444a19.913 19.913 0 0 1 5.028-6.261 20.048 20.048 0 0 1 7.105-3.781 16.811 16.811 0 0 0 8.503-5.816L198 313"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="M212.604 252.083a7.565 7.565 0 1 0-10.699-10.702l-2.602 2.603a7.565 7.565 0 1 0 10.698 10.702l2.603-2.603ZM215.611 269.659a7.565 7.565 0 1 0-10.698-10.702l-4.519 4.52a7.566 7.566 0 1 0 10.699 10.702l4.518-4.52Z"
      fill="#fff"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="M216.07 288.705a7.572 7.572 0 0 0 .004-10.706 7.566 7.566 0 0 0-10.702.004l-5.876 5.878a7.566 7.566 0 1 0 10.698 10.702l5.876-5.878Z"
      fill="#fff"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="M141 138h94.072a7.96 7.96 0 0 0 6.892-4.052 8.027 8.027 0 0 0-.077-8.022l-26.684-44.778a8.283 8.283 0 0 0-3.019-3.035A8.232 8.232 0 0 0 208.059 77H141"
      fill="#fff"
    />
    <Path
      d="M141 138h94.072a7.96 7.96 0 0 0 6.892-4.052 8.027 8.027 0 0 0-.077-8.022l-26.684-44.778a8.283 8.283 0 0 0-3.019-3.035A8.232 8.232 0 0 0 208.059 77H141"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="M168.105 138h-54.188a7.925 7.925 0 0 1-3.981-1.096 7.972 7.972 0 0 1-2.9-2.954 8.04 8.04 0 0 1 .07-8.017l26.577-44.8a8.507 8.507 0 0 1 3.093-3.028 8.447 8.447 0 0 1 8.35 0 8.5 8.5 0 0 1 3.093 3.028l26.657 44.77a8.033 8.033 0 0 1 .102 8.022 7.986 7.986 0 0 1-2.892 2.967 7.922 7.922 0 0 1-3.981 1.108Z"
      fill="#fff"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="M140.189 106.119a.99.99 0 0 1 .781-.399 1 1 0 0 1 .791.39l.07.107 10.214 16.754H156l-13.507-22.144a1.722 1.722 0 0 0-.631-.606 1.748 1.748 0 0 0-2.335.606l-.05.078L126 123h3.955l10.224-16.764a.463.463 0 0 1 .01-.117Z"
      fill="#1FB25A"
    />
    <Path
      d="m162.213 121.427-15.304-25.615-1.345-2.242a5.44 5.44 0 0 0-1.971-1.885 5.406 5.406 0 0 0-7.239 1.885l-.518.87-16.131 27.027a5.027 5.027 0 0 0 0 5.135 5.415 5.415 0 0 0 1.971 1.891c.808.45 1.716.685 2.639.681h13.084c.48.005.939.199 1.28.539.34.34.535.801.542 1.283V135h3.416v-4.004a5.257 5.257 0 0 0-1.602-3.663 5.2 5.2 0 0 0-3.705-1.462h-13.045a1.913 1.913 0 0 1-1.603-.921 1.758 1.758 0 0 1 0-1.822l16.669-27.887a1.995 1.995 0 0 1 1.633-.89 1.915 1.915 0 0 1 1.623.89l16.679 27.887a1.763 1.763 0 0 1 0 1.822 1.928 1.928 0 0 1-1.604.921h-5.854a1.917 1.917 0 0 1-1.643-.921l-7.688-12.852a1.728 1.728 0 0 0-2.987 0v.09l-6.442 10.76h3.983l3.166-5.295a.347.347 0 0 0 .05-.08 1.01 1.01 0 0 1 .351-.295 1.001 1.001 0 0 1 .891 0c.138.07.258.171.351.295.029.031.05.069.06.11l5.377 9.009a5.415 5.415 0 0 0 1.971 1.891c.807.45 1.716.685 2.639.681h5.815a5.361 5.361 0 0 0 2.64-.68 5.41 5.41 0 0 0 1.971-1.892 5.146 5.146 0 0 0-.12-5.265Z"
      fill="#1FB25A"
    />
    <Path
      d="M81.002 177H63.998c-2.76 0-4.998 2.262-4.998 5.052v20.896c0 2.79 2.238 5.052 4.998 5.052h17.004c2.76 0 4.998-2.262 4.998-5.052v-20.896c0-2.79-2.238-5.052-4.998-5.052Z"
      fill="#B0EACC"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="M72.5 200a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15Z"
      fill="#B0EACC"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="M99 217a34.697 34.697 0 0 0 7.401-11.24A35.096 35.096 0 0 0 109 192.5c0-4.55-.883-9.056-2.599-13.26A34.697 34.697 0 0 0 99 168M91 210a24.044 24.044 0 0 0 5.18-7.799 24.17 24.17 0 0 0 0-18.402A24.044 24.044 0 0 0 91 176M46 168a34.69 34.69 0 0 0-7.401 11.24A35.1 35.1 0 0 0 36 192.5a35.1 35.1 0 0 0 2.599 13.26A34.69 34.69 0 0 0 46 217M54 176a24.044 24.044 0 0 0-5.18 7.799 24.17 24.17 0 0 0 0 18.402A24.044 24.044 0 0 0 54 210"
      stroke="#2A2E32"
      strokeWidth={2.11}
      strokeLinejoin="round"
    />
    <Path
      d="M95 280c7.18 0 13-6.044 13-13.5S102.18 253 95 253s-13 6.044-13 13.5S87.82 280 95 280Z"
      fill="#BDE1FF"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="M95 260v13M101 266H88"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="M213 90c7.18 0 13-5.82 13-13s-5.82-13-13-13-13 5.82-13 13 5.82 13 13 13Z"
      fill="#BDE1FF"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
    <Path
      d="M213 70v13M220 77h-13"
      stroke="#2A2E32"
      strokeWidth={2.2}
      strokeLinejoin="round"
    />
  </Svg>
);
