import { StyleSheet, View } from "react-native";
import { shadow } from "react-native-paper";

import { TabItem, Tabs } from "@components/ui";

import { DefaultTheme, spacing } from "../../../../../theme";
import { translate } from "../../../../../i18n";

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    backgroundColor: DefaultTheme.colors.card,
    padding: spacing[2],
    borderRadius: 12,
    top: 68,
    left: 12,
    right: 12,
    ...(shadow(5) as any),
  },
});

export const Menu = () => {
  const tabsItems: TabItem[] = [
    {
      key: "all",
      name: translate("Devices.List.filter.All"),
    },
    {
      key: "auto",
      name: translate("Devices.List.filter.Auto"),
    },
    {
      key: "manual",
      name: translate("Devices.List.filter.Manual"),
    },
  ];
  return (
    <View style={styles.container}>
      <Tabs items={tabsItems} currentTab="all" />
    </View>
  );
};
