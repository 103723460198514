import React, { useState } from "react";
import { Snackbar } from "react-native-paper";
import Clipboard from "@react-native-clipboard/clipboard";

import { CloseIcon } from "../../svgs";
import { translate } from "../../i18n";

type CopyToClipboardProps = {
  copiedItem?: string;
  onDismiss: () => void;
};

export const CopyToClipboard = ({
  copiedItem,
  onDismiss,
}: CopyToClipboardProps) => {
  return (
    <Snackbar
      visible={Boolean(copiedItem)}
      onDismiss={onDismiss}
      duration={5000}
      action={{
        label: "",
        icon: CloseIcon,
      }}
    >
      {`${copiedItem} ${translate("common.copied")}`}
    </Snackbar>
  );
};

type UseCopyToClipboardResponse = {
  copyToClipboard: (data: string, label: string) => void;
  copiedItem?: string;
  onDismiss: () => void;
};

export const useCopyToClipboard = (): UseCopyToClipboardResponse => {
  const [copiedItem, setCopiedItem] = useState<string>(null);
  const copyToClipboard = (data: string, label: string) => {
    Clipboard.setString(data);
    setCopiedItem(label);
  };

  const onDismiss = () => {
    setCopiedItem(null);
  };

  return {
    copyToClipboard,
    copiedItem,
    onDismiss,
  };
};
