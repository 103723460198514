import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { palette } from "../theme";

export default function RangeIcon({ color = palette.Black, size = 24 }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        id="Union"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.95527 5.79373C8.66237 5.50084 8.66237 5.02596 8.95527 4.73307L11.4697 2.21866C11.5421 2.14626 11.6256 2.09176 11.7147 2.05515C11.7977 2.02101 11.8881 2.00131 11.9829 1.99919C11.9943 1.99893 12.0057 1.99893 12.0171 1.99919C12.2033 2.00341 12.3882 2.07657 12.5303 2.21866L15.0447 4.73307C15.3376 5.02596 15.3376 5.50084 15.0447 5.79373C14.7518 6.08662 14.277 6.08662 13.9841 5.79373L12.75 4.55965V9.29984C12.75 9.71405 12.4142 10.0498 12 10.0498C11.5858 10.0498 11.25 9.71405 11.25 9.29984V4.55966L10.0159 5.79373C9.72303 6.08662 9.24816 6.08662 8.95527 5.79373ZM12.75 14.4072C12.75 13.993 12.4142 13.6572 12 13.6572C11.5858 13.6572 11.25 13.993 11.25 14.4072V19.495L10.0159 18.2609C9.72303 17.968 9.24815 17.968 8.95526 18.2609C8.66237 18.5538 8.66237 19.0287 8.95526 19.3216L11.4697 21.836C11.7626 22.1289 12.2374 22.1289 12.5303 21.836L15.0447 19.3216C15.3376 19.0287 15.3376 18.5538 15.0447 18.2609C14.7518 17.968 14.277 17.968 13.9841 18.2609L12.75 19.495V14.4072ZM15.3738 9.29984C15.3738 8.88562 15.7096 8.54984 16.1238 8.54984H21.1898C21.604 8.54984 21.9398 8.88562 21.9398 9.29984C21.9398 9.71405 21.604 10.0498 21.1898 10.0498H16.1238C15.7096 10.0498 15.3738 9.71405 15.3738 9.29984ZM2.74969 8.54984C2.33548 8.54984 1.99969 8.88562 1.99969 9.29984C1.99969 9.71405 2.33548 10.0498 2.74969 10.0498H7.81575C8.22996 10.0498 8.56575 9.71405 8.56575 9.29984C8.56575 8.88562 8.22996 8.54984 7.81575 8.54984H2.74969ZM15.3738 14.4072C15.3738 13.993 15.7096 13.6572 16.1238 13.6572H21.1898C21.604 13.6572 21.9398 13.993 21.9398 14.4072C21.9398 14.8214 21.604 15.1572 21.1898 15.1572H16.1238C15.7096 15.1572 15.3738 14.8214 15.3738 14.4072ZM2.74969 13.6572C2.33548 13.6572 1.99969 13.993 1.99969 14.4072C1.99969 14.8214 2.33548 15.1572 2.74969 15.1572H7.81575C8.22996 15.1572 8.56575 14.8214 8.56575 14.4072C8.56575 13.993 8.22996 13.6572 7.81575 13.6572H2.74969Z"
        fill={color}
      />
    </Svg>
  );
}
