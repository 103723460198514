import { StyleSheet } from "react-native";
import {
  IconButton as IconButtonPaper,
  IconButtonProps,
} from "react-native-paper";

const style = StyleSheet.create({
  button: {
    backgroundColor: "transparent",
  },
});

export const IconButton = (props: IconButtonProps) => {
  return <IconButtonPaper {...props} style={[style.button, props.style]} />;
};
