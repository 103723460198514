import Svg, { Path } from "react-native-svg";
import { palette } from "../theme";

export default function RightThinArrowIcon({
  color = palette.Gray,
  size = 24,
}) {
  return (
    <Svg width={size} height={size} fill="none">
      <Path
        d="M6 12h12m0 0-5-5m5 5-5 5"
        stroke={color}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
