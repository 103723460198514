import { View, StyleSheet } from "react-native";
import { shadow } from "react-native-paper";

import { RuleType } from "@models/rule/rule";

import { DefaultTheme, spacing } from "../../../../../../theme";
import { RuleTypeItem } from "../../components";

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    backgroundColor: DefaultTheme.colors.background,
    padding: spacing[1],
    borderRadius: spacing[2],
    top: 238,
    left: spacing[3],
    right: spacing[3],
    ...(shadow(5) as any),
  },

  item: { marginBottom: 0 },
});

export const ButtonRuleTypeHinte = (type = RuleType.Schedule) => {
  return (
    <View style={styles.container}>
      <RuleTypeItem type={type} currentType={type} style={styles.item} />
    </View>
  );
};
