import Svg, { SvgProps, Path } from "react-native-svg";

export const NoAddedDevices = (props: SvgProps) => {
  return (
    <Svg width={234} height={179} viewBox="0 0 234 179" fill="none" {...props}>
      <Path
        d="M193.548 169.725H41.9281C24.6981 169.725 14.0281 150.945 22.8681 136.145L97.2581 11.565C106.458 -3.845 128.768 -3.85499 137.988 11.535L212.588 136.115C221.458 150.915 210.798 169.725 193.548 169.725Z"
        fill="#FFF1F1"
      />
      <Path
        d="M3.69851 63.9023H28.9285C31.7985 63.9023 33.5685 67.0324 32.0985 69.4924L19.7185 90.2224C18.1885 92.7824 14.4785 92.7924 12.9385 90.2224L0.528511 69.4924C-0.941489 67.0324 0.828509 63.9023 3.69851 63.9023Z"
        fill="#FFF1F1"
      />
      <Path
        d="M183.249 12.3555H213.889C217.369 12.3555 219.529 16.1555 217.739 19.1455L202.709 44.3155C200.849 47.4255 196.339 47.4355 194.479 44.3255L179.409 19.1555C177.609 16.1555 179.769 12.3555 183.249 12.3555Z"
        fill="#FFF1F1"
      />
      <Path
        d="M74.4395 115.764H115.949H151.009H196.099C204.079 115.764 209.009 107.064 204.909 100.224L170.409 42.6044C168.289 39.0644 164.799 37.2844 161.169 37.2744H74.4395"
        fill="white"
      />
      <Path
        d="M74.4395 115.764H115.949H151.009H196.099C204.079 115.764 209.009 107.064 204.909 100.224L170.409 42.6044C168.289 39.0644 164.799 37.2844 161.169 37.2744H74.4395"
        stroke="#2B2E32"
        strokeWidth="2.1962"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <Path
        d="M109.5 115.764H39.3799C31.4099 115.764 26.4799 107.074 30.5599 100.234L64.9599 42.6144C69.2199 35.4844 79.5299 35.4844 83.7999 42.6044L118.3 100.224C122.41 107.064 117.48 115.764 109.5 115.764Z"
        fill="white"
        stroke="#2B2E32"
        strokeWidth="2.1962"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <Path
        d="M73.3999 74.4836C73.6299 74.1536 74.0199 73.9536 74.4299 73.9536C74.8399 73.9536 75.2099 74.1436 75.4599 74.4736C75.4899 74.5236 75.5299 74.5636 75.5399 74.6136L88.8299 96.7336H93.9399L76.3699 67.5036C75.9799 66.8136 75.2299 66.3936 74.4299 66.3936C73.6499 66.3936 72.9099 66.8036 72.5199 67.4636L72.4499 67.5736L54.9199 96.7436H60.0299L73.3399 74.6036C73.3499 74.5636 73.3699 74.5136 73.3999 74.4836Z"
        fill="#3F9A6E"
      />
      <Path
        d="M101.909 94.914L82.1292 61.9839L80.3992 59.104C79.1792 57.064 76.8892 55.7939 74.4492 55.7939C72.0092 55.7939 69.7192 57.064 68.4992 59.104L67.8292 60.2339L46.9892 94.914C45.7692 96.954 45.7692 99.4739 46.9892 101.514C48.2092 103.554 50.4992 104.824 52.9392 104.824H58.5292H58.8392H69.7492H69.8492C71.1692 104.874 72.1992 105.904 72.1992 107.174V112.294H76.6192V107.174C76.6192 103.544 73.5392 100.574 69.7492 100.574H52.8892C52.0392 100.554 51.2392 100.104 50.8192 99.4039C50.3792 98.6839 50.3792 97.774 50.8192 97.054L72.3692 61.214C72.8092 60.514 73.6292 60.074 74.4692 60.074C75.3192 60.074 76.1292 60.514 76.5692 61.214L98.1192 97.054C98.5592 97.774 98.5592 98.6839 98.1192 99.4039C97.6992 100.104 96.8992 100.564 96.0492 100.574H88.6092H88.4792C87.5992 100.574 86.7992 100.124 86.3592 99.4039L76.4292 82.8739C76.0392 82.1839 75.2892 81.7639 74.4892 81.7639C73.7092 81.7639 72.9692 82.174 72.5792 82.834L72.5092 82.944L64.1892 96.774H69.2892L73.3792 89.974C73.3992 89.944 73.4292 89.894 73.4492 89.864C73.6792 89.534 74.0692 89.334 74.4792 89.334C74.8892 89.334 75.2592 89.524 75.5092 89.854C75.5392 89.904 75.5692 89.944 75.5892 89.994L82.5392 101.544C83.7592 103.584 86.0492 104.854 88.4892 104.854H89.9892H93.2192H94.2792H94.5392H96.0092C98.4592 104.854 100.739 103.584 101.959 101.544C103.119 99.4739 103.129 96.944 101.909 94.914Z"
        fill="#3F9A6E"
      />
      <Path
        d="M186.24 69.0244H192.57C214.51 69.0244 232.29 86.8044 232.29 108.744C232.29 130.684 214.51 148.464 192.57 148.464H178.18"
        stroke="#2B2E32"
        strokeWidth="2.1962"
        strokeMiterlimit="10"
        strokeLinejoin="round"
        strokeDasharray="12 12"
      />
      <Path
        d="M49.5891 69.0244H43.2591C21.3191 69.0244 3.53906 86.8044 3.53906 108.744C3.53906 130.684 21.3191 148.464 43.2591 148.464H127.809"
        stroke="#2B2E32"
        strokeWidth="2.1962"
        strokeMiterlimit="10"
        strokeLinejoin="round"
        strokeDasharray="12 12"
      />
      <Path
        d="M152.239 77.6045H170.639C173.539 77.6045 175.889 79.9545 175.889 82.8545V154.294C175.889 157.194 173.539 159.544 170.639 159.544H136.539C133.639 159.544 131.289 157.194 131.289 154.294V82.8545C131.289 79.9545 133.639 77.6045 136.539 77.6045H139.119"
        fill="white"
      />
      <Path
        d="M152.239 77.6045H170.639C173.539 77.6045 175.889 79.9545 175.889 82.8545V154.294C175.889 157.194 173.539 159.544 170.639 159.544H136.539C133.639 159.544 131.289 157.194 131.289 154.294V82.8545C131.289 79.9545 133.639 77.6045 136.539 77.6045H139.119"
        stroke="#2B2E32"
        strokeWidth="2.1962"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <Path
        d="M131.289 85.9746H175.889"
        stroke="#2B2E32"
        strokeWidth="2.1962"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <Path
        d="M131.289 148.414H175.889"
        stroke="#2B2E32"
        strokeWidth="2.1962"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <Path
        d="M155.239 92.7549L138.139 110.345"
        stroke="#2B2E32"
        strokeWidth="2.1962"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <Path
        d="M143.769 93.1748L138.799 98.3748"
        stroke="#2B2E32"
        strokeWidth="2.1962"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <Path
        d="M178.82 156.034L186.4 148.454C189.63 145.224 189.63 139.984 186.4 136.764C183.17 133.534 177.93 133.534 174.71 136.764L167.13 144.344L159.55 136.764C156.32 133.534 151.08 133.534 147.86 136.764C144.63 139.994 144.63 145.234 147.86 148.454L155.44 156.034L147.86 163.614C144.63 166.844 144.63 172.084 147.86 175.304C151.09 178.534 156.33 178.534 159.55 175.304L167.13 167.724L174.71 175.304C177.94 178.534 183.18 178.534 186.4 175.304C189.63 172.074 189.63 166.834 186.4 163.614L178.82 156.034Z"
        fill="#F76B6B"
        stroke="#2B2E32"
        strokeWidth="2.1962"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default NoAddedDevices;
