import { SensorTypeName } from "@models/sensor/constants";

import { PH_STEPS, PH_STEPS_PROPS } from "./ph";
import { EC_STEPS, EC_STEPS_PROPS } from "./ec";
import { DO_STEPS, DO_STEPS_PROPS } from "./do";
import { ORP_STEPS, ORP_STEPS_PROPS } from "./orp";
import { SOIL_MOISTURE_STEPS, SOIL_MOISTURE_STEPS_PROPS } from "./soilMoisture";
import { LUX_STEPS, LUX_STEPS_PROPS } from "./lux";

export const CALIBRATION_STEPS = {
  [SensorTypeName.PH]: PH_STEPS,
  [SensorTypeName.EC]: EC_STEPS,
  [SensorTypeName.DO]: DO_STEPS,
  [SensorTypeName.ORP]: ORP_STEPS,
  [SensorTypeName.SoilMoisture]: SOIL_MOISTURE_STEPS,
  [SensorTypeName.LUX]: LUX_STEPS,
};

export const CALIBRATION_STEPS_PROPS = {
  [SensorTypeName.PH]: PH_STEPS_PROPS,
  [SensorTypeName.EC]: EC_STEPS_PROPS,
  [SensorTypeName.DO]: DO_STEPS_PROPS,
  [SensorTypeName.ORP]: ORP_STEPS_PROPS,
  [SensorTypeName.SoilMoisture]: SOIL_MOISTURE_STEPS_PROPS,
  [SensorTypeName.LUX]: LUX_STEPS_PROPS,
};

export const CALIBRATION_TIME_MS = 120 * 1000;

export const DEFAULT_LIQUIDS_VALUES = {
  [SensorTypeName.PH]: [4, 7],
  [SensorTypeName.EC]: [0, 1.42],
  [SensorTypeName.DO]: [],
  [SensorTypeName.ORP]: [256, 475],
  [SensorTypeName.SoilMoisture]: [],
  [SensorTypeName.LUX]: [],
};
