/* eslint-disable import/prefer-default-export */
export const palette = {
  White: "#FFFFFF",
  // Background: "#ECF0F4",
  Disable: "#E3E3E3",
  Disable2: "#D7D7D7",
  Black: "#2B2E32",
  Blue: "#308AE3",
  BlueDark: "#D0E7FE",
  BlueLight: "#F7FAFC",

  // F9FAFE

  BlueDarkBackground: "#BAD5F0", //
  BlueLightBackground: "#F6F8FD",
  BlueBackground: "#EDF1F5",
  Red: "#D01616",
  RedNew: "#F66B6B",
  RedNewDark: "#EE5858",
  Yellow: "#FFB23E",
  Gray: "#6A7B8C",
  GrayDark: "#555D67",
  GrayBlue: "#4A6F84",
  LogoGreen: "#1FB25A",
  GrayBlueLight: "#B6C5D3",
  Green: "#3F9A6E",
  // GreenMedium: "#65B893",
  GreenDark: "#328C61",
  GreenLight: "#4DAF8C",
  GreenLight2: "#B0EACC",
  Green0: "#D7F4E5",
  Orange: "#EA7C63",

  OrangeG1: "#FFB23E",
  OrangeG2: "#FB6E6E",

  RedG1: "#F54444",
  RedG2: "#FB6E6E",
  RedLight: "#FFF1F1",

  TabGradient1: "#364F5C",
  TabGradient2: "#22343E",
  PurpleG1: "#BB20E2",
  PurpleG2: "#1B52BC",

  BlueG1: "#2E7CA9",
  BlueG2: "#055B8C",
};
