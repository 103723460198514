import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

export const ModuleWaitingSvg = (props: SvgProps) => (
  <Svg width={359} height={250} fill="none" viewBox="0 0 359 250" {...props}>
    <Path
      d="M112.477 137.188C116.985 129.187 110.273 116.859 97.4838 109.652C84.6949 102.446 70.6723 103.091 66.1637 111.092C61.655 119.094 68.3675 131.422 81.1565 138.628C93.9455 145.835 107.968 145.19 112.477 137.188Z"
      fill="white"
      stroke="#2A2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M81.7023 119.835L112.482 137.175"
      stroke="#2A2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M120.277 131.987C133.659 131.913 144.412 114.523 144.294 93.1436C144.176 71.7647 133.233 54.4934 119.851 54.5671C106.469 54.6408 95.7164 72.0316 95.8341 93.4106C95.9519 114.79 106.896 132.061 120.277 131.987Z"
      fill="white"
      stroke="#2A2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M119.892 62.3647L120.272 131.985"
      stroke="#2A2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M141.772 78.8448L120.092 97.9948"
      stroke="#2A2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M96.0023 95.3047L120.192 116.495"
      stroke="#2A2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M103.612 68.5048L120.002 82.8648"
      stroke="#2A2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M129.842 89.3848L144.102 89.3047"
      stroke="#2A2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M100.082 74.5148L110.412 74.4648"
      stroke="#2A2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M253.544 216.725H101.924C84.6942 216.725 74.0242 197.945 82.8642 183.145L157.254 58.565C166.454 43.155 188.764 43.145 197.984 58.535L272.584 183.115C281.454 197.915 270.794 216.725 253.544 216.725Z"
      fill="#C4E1FE"
      fillOpacity="0.4"
    />
    <Path
      d="M134.113 180.425H175.623H210.683H255.773C263.753 180.425 268.683 171.725 264.583 164.885L230.083 107.265C227.963 103.725 224.473 101.945 220.843 101.935H134.113"
      fill="white"
    />
    <Path
      d="M134.113 180.425H175.623H210.683H255.773C263.753 180.425 268.683 171.725 264.583 164.885L230.083 107.265C227.963 103.725 224.473 101.945 220.843 101.935H134.113"
      stroke="#2A2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M169.172 180.425H99.0518C91.0818 180.425 86.1518 171.735 90.2318 164.895L124.632 107.275C128.892 100.145 139.202 100.145 143.472 107.265L177.972 164.885C182.082 171.725 177.152 180.425 169.172 180.425Z"
      fill="white"
      stroke="#2A2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M133.071 139.145C133.301 138.815 133.691 138.615 134.101 138.615C134.511 138.615 134.881 138.805 135.131 139.135C135.161 139.185 135.201 139.225 135.211 139.275L148.501 161.395H153.611L136.041 132.165C135.651 131.475 134.901 131.055 134.101 131.055C133.321 131.055 132.581 131.465 132.191 132.125L132.121 132.235L114.591 161.405H119.701L133.011 139.265C133.021 139.215 133.041 139.175 133.071 139.145Z"
      fill="#1FB25A"
    />
    <Path
      d="M161.581 159.575L141.801 126.645L140.071 123.765C138.851 121.725 136.561 120.455 134.121 120.455C131.681 120.455 129.391 121.725 128.171 123.765L127.501 124.895L106.661 159.575C105.441 161.615 105.441 164.135 106.661 166.175C107.881 168.215 110.171 169.485 112.611 169.485H118.201H118.511H129.421H129.521C130.841 169.535 131.871 170.565 131.871 171.835V176.955H136.291V171.835C136.291 168.205 133.211 165.235 129.421 165.235H112.561C111.711 165.215 110.911 164.765 110.491 164.065C110.051 163.345 110.051 162.435 110.491 161.715L132.041 125.875C132.481 125.175 133.301 124.735 134.141 124.735C134.991 124.735 135.801 125.175 136.241 125.875L157.791 161.715C158.231 162.435 158.231 163.345 157.791 164.065C157.371 164.765 156.571 165.225 155.721 165.235H148.281H148.151C147.271 165.235 146.471 164.785 146.031 164.065L136.101 147.535C135.711 146.845 134.961 146.425 134.161 146.425C133.381 146.425 132.641 146.835 132.251 147.495L132.181 147.605L123.861 161.435H128.961L133.051 154.635C133.071 154.605 133.101 154.555 133.121 154.525C133.351 154.195 133.741 153.995 134.151 153.995C134.561 153.995 134.931 154.185 135.181 154.515C135.211 154.565 135.241 154.605 135.261 154.655L142.211 166.205C143.431 168.245 145.721 169.515 148.161 169.515H149.661H152.891H153.951H154.211H155.681C158.131 169.515 160.411 168.245 161.631 166.205C162.791 164.125 162.801 161.605 161.581 159.575Z"
      fill="#1FB25A"
    />
    <Path
      d="M237.232 159.415C234.302 159.415 231.932 157.035 231.932 154.115V140.315C231.932 137.385 234.312 135.015 237.232 135.015C240.162 135.015 242.532 137.395 242.532 140.315V154.115C242.532 157.035 240.162 159.415 237.232 159.415Z"
      fill="#308AE3"
      stroke="#2A2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M237.232 225.845C234.302 225.845 231.932 223.465 231.932 220.545V206.745C231.932 203.815 234.312 201.445 237.232 201.445C240.162 201.445 242.532 203.825 242.532 206.745V220.545C242.532 223.465 240.162 225.845 237.232 225.845Z"
      fill="#308AE3"
      stroke="#2A2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M258.233 180.425C258.233 177.495 260.613 175.125 263.533 175.125H277.333C280.263 175.125 282.633 177.505 282.633 180.425C282.633 183.355 280.253 185.725 277.333 185.725H263.533C260.613 185.725 258.233 183.355 258.233 180.425Z"
      fill="#308AE3"
      stroke="#2A2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M191.812 180.425C191.812 177.495 194.193 175.125 197.113 175.125H210.913C213.843 175.125 216.213 177.505 216.213 180.425C216.213 183.355 213.833 185.725 210.913 185.725H197.113C194.183 185.725 191.812 183.355 191.812 180.425Z"
      fill="#308AE3"
      stroke="#2A2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M252.082 165.565C250.012 163.495 250.012 160.135 252.082 158.065L261.842 148.305C263.912 146.235 267.272 146.235 269.342 148.305C271.412 150.375 271.412 153.735 269.342 155.805L259.583 165.565C257.513 167.635 254.152 167.635 252.082 165.565Z"
      fill="#308AE3"
      stroke="#2A2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M205.112 212.535C203.042 210.465 203.042 207.105 205.112 205.035L214.873 195.275C216.943 193.205 220.303 193.205 222.373 195.275C224.443 197.345 224.443 200.705 222.373 202.775L212.612 212.535C210.542 214.605 207.182 214.605 205.112 212.535Z"
      fill="#308AE3"
      stroke="#2A2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M205.112 148.305C207.182 146.235 210.542 146.235 212.612 148.305L222.373 158.065C224.443 160.135 224.443 163.495 222.373 165.565C220.303 167.635 216.943 167.635 214.873 165.565L205.112 155.805C203.042 153.735 203.042 150.375 205.112 148.305Z"
      fill="#308AE3"
      stroke="#2A2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M236.412 76.5049H267.052C270.532 76.5049 272.692 80.3049 270.902 83.2949L255.872 108.465C254.012 111.575 249.502 111.585 247.642 108.475L232.572 83.3049C230.782 80.3049 232.932 76.5049 236.412 76.5049Z"
      fill="#C4E1FE"
      fillOpacity="0.4"
    />
  </Svg>
);
