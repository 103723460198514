import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { defaultColors } from "../theme";

export default function ScheduleIcon({
  color = defaultColors.text,
  size = 44,
}) {
  return (
    <Svg width={size} height={size} viewBox="0 0 44 44" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.93158 14.313L13.5038 9.00168C12.0193 8.46511 10.2934 8.77 9.07372 9.93256C7.85404 11.0951 7.46678 12.8044 7.93158 14.313ZM7.03162 15.9686C5.70481 13.7009 6.05364 10.739 8.03879 8.84678C10.0239 6.95459 12.9992 6.74813 15.2007 8.1821C15.3928 8.30722 15.5171 8.51333 15.5382 8.74161C15.5593 8.96988 15.4748 9.19526 15.3089 9.35343L8.19642 16.1328C8.03048 16.2909 7.80131 16.3645 7.57431 16.3325C7.3473 16.3006 7.14739 16.1665 7.03162 15.9686ZM10.5387 24.7211C10.5387 18.4027 15.6607 13.2806 21.9791 13.2806C28.2975 13.2806 33.4196 18.4027 33.4196 24.7211C33.4196 31.0394 28.2975 36.1615 21.9791 36.1615C15.6607 36.1615 10.5387 31.0394 10.5387 24.7211ZM21.9791 11.7806C14.8323 11.7806 9.03868 17.5742 9.03868 24.7211C9.03868 28.5784 10.7264 32.0415 13.4037 34.4123L10.3464 37.4696C10.0535 37.7625 10.0535 38.2374 10.3464 38.5303C10.6393 38.8232 11.1141 38.8232 11.407 38.5303L14.5909 35.3464C16.6857 36.8058 19.2325 37.6615 21.9791 37.6615C24.7256 37.6615 27.2721 36.8059 29.3669 35.3468L32.5504 38.5303C32.8433 38.8232 33.3182 38.8232 33.6111 38.5303C33.904 38.2374 33.904 37.7625 33.6111 37.4696L30.5542 34.4127C33.2317 32.0419 34.9196 28.5786 34.9196 24.7211C34.9196 17.5742 29.1259 11.7806 21.9791 11.7806ZM22.7287 17.7551C22.7287 17.3409 22.3929 17.0051 21.9787 17.0051C21.5645 17.0051 21.2287 17.3409 21.2287 17.7551V24.2857C21.2287 24.6999 21.5645 25.0357 21.9787 25.0357H26.5501C26.9643 25.0357 27.3001 24.6999 27.3001 24.2857C27.3001 23.8715 26.9643 23.5357 26.5501 23.5357H22.7287V17.7551ZM29.7972 9.00168L35.3694 14.313C35.8342 12.8044 35.447 11.0951 34.2273 9.93256C33.0076 8.77 31.2817 8.46511 29.7972 9.00168ZM35.2622 8.84678C37.2474 10.739 37.5962 13.7009 36.2694 15.9686C36.1536 16.1665 35.9537 16.3006 35.7267 16.3325C35.4997 16.3645 35.2705 16.2909 35.1046 16.1328L27.9921 9.35343C27.8262 9.19526 27.7417 8.96988 27.7628 8.74161C27.7838 8.51333 27.9082 8.30722 28.1003 8.1821C30.3018 6.74813 33.277 6.95459 35.2622 8.84678Z"
        fill={color}
      />
    </Svg>
  );
}
