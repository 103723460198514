import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { StyleSheet } from "react-native";
import { StackNavigationProp, StackScreenProps } from "@react-navigation/stack";
import { useNavigation } from "@react-navigation/native";

import { useStores } from "@models/index";
import { Screen } from "@components/index";
import {
  Dialog,
  ErrorsAlert,
  ErrorText,
  MenuItem,
  useDialog,
} from "@components/ui";
import { InputDialog } from "@components/dialog";
import { ModulesNavigatorParamList } from "@screens/Modules/navigation";
import { TopBarNew } from "@components/layout";

import { spacing } from "../../../theme";
import { translate } from "../../../i18n";
import * as DeviceIcons from "../../../svgs/devices";
import { NoneIcon } from "../../../svgs";
import { goBack } from "../../../navigators";
import { useDeviceAndRules } from "../../../hooks";
import { addActionsToMenuItems } from "../../../utils/addActionsToMenuItems";
import { DevicesNavigatorParamList } from "../navigation";
import { DeviceDetailsRules, DetailsDeviceStatus } from "./components";
import { getDeviceDetailsMenuItems, MenuDeviceItemsKeys } from "./constants";
import { getDeviceDetailsHints } from "./hints";
import { WarningSvg } from "../../../svgs/pictures";

const styles = StyleSheet.create({
  screen: {
    // flex: 1,
    paddingHorizontal: spacing[4],
  },
});

export const DeviceDetailsScreen: React.FC<
  StackScreenProps<DevicesNavigatorParamList, "device-details">
> = observer(({ route }) => {
  const { params = {} } = route as any;

  const { deviceStore, settingsStore } = useStores();
  const device = deviceStore.getDevice(params?.uid);

  const [visibleRename, setVisibleRename] = useState<boolean>(false);
  const showRenameDialog = () => setVisibleRename(true);
  const hideRenameDialog = () => setVisibleRename(false);

  // const [isScrolling, setScrolling] = useState<boolean>(false);

  // const onScrollVertical = (value) => {
  //   if (value.nativeEvent.contentOffset.y > 16 && device.rule) {
  //     setScrolling(true);
  //   } else {
  //     setScrolling(false);
  //   }
  // };

  const navigation = useNavigation();

  const goToModule = () => {
    (
      navigation as unknown as StackNavigationProp<ModulesNavigatorParamList>
    ).navigate("module-details", { uid: device.moduleUid });
  };

  const goToHistory = () => {
    (
      navigation as unknown as StackNavigationProp<DevicesNavigatorParamList>
    ).navigate("device-history", { uid: device.uid });
  };

  const onDeleteHandler = async () => {
    const result = await device.disconnect();
    if (result.kind === "ok") {
      goBack();
    }
  };

  const { showDialog: showDeleteDeviceDialog, ...dialogDeleteDeviceProps } =
    useDialog({ onOk: onDeleteHandler });

  const fetchData = async (force = false) => {
    await device.refetch(); // TODO: prefetch only device status !!! (without loading indicator)
    await device.fetchRules({ force }); // if without await refetch rewrite device data & rules (will be fixed when WSS)
  };

  useEffect(() => {
    fetchData(true);
  }, []);

  useDeviceAndRules(device?.uid);

  const onRefresh = useCallback(async () => {
    await fetchData(true);
    // setScrolling(false);
  }, []);

  const menuItems: MenuItem[] = addActionsToMenuItems(
    getDeviceDetailsMenuItems(),
    {
      [MenuDeviceItemsKeys.Edit]: showRenameDialog,
      [MenuDeviceItemsKeys.Connection]: goToModule,
      [MenuDeviceItemsKeys.History]: goToHistory,
      [MenuDeviceItemsKeys.Delete]: showDeleteDeviceDialog,
    },
  );

  const Icon = DeviceIcons[device?.iconName] || NoneIcon;

  if (!device)
    return <ErrorText>{translate("Devices.Details.noDevice")}</ErrorText>;

  return (
    <Screen
      testID="DeviceDetails"
      preset="scroll"
      header={
        <TopBarNew
          title={device.displayName}
          subtitle={device.typeName}
          icon={Icon}
          menuItems={menuItems}
        />
      }
      style={styles.screen}
      onRefresh={onRefresh}
      // onScroll={onScrollVertical}
      hints={
        settingsStore.showHelpButton && getDeviceDetailsHints(device?.rule)
      }
    >
      <DetailsDeviceStatus device={device} />

      <DeviceDetailsRules device={device} />

      <InputDialog
        title={translate("Devices.Details.renameDevice")}
        label={translate("fields.deviceName")}
        visible={visibleRename}
        value={device.name || device.displayName}
        onOk={device.updateName}
        onCancel={hideRenameDialog}
      />

      <Dialog
        {...dialogDeleteDeviceProps}
        title={translate("Devices.Details.delete")}
        okText={translate("common.delete")}
        okType="warning"
        content={`${translate("Devices.Details.deleteDescription")} ${
          device.displayName
        }?`}
      />

      <ErrorsAlert
        errors={device.statusErrors}
        onCancel={device.resetErrors}
        Image={WarningSvg}
        title=""
      />
    </Screen>
  );
});
