import { useEffect, useState } from "react";

import { useStores } from "@models/index";

import {
  SensorCalibrationsStatus,
  SensorsApi,
  TSensorCalibration,
} from "../services/api";
import { translate } from "../i18n";

type UseSensorCalibrationsProps = {
  uid: string;
  startDate: string | undefined;
  endDate: string | undefined;
  status?: SensorCalibrationsStatus;
};

type UseSensorCalibrationsResult = {
  isLoading: boolean;
  calibrations: TSensorCalibration[];
  errors: string[] | null;
};

export const useSensorCalibrations = ({
  uid,
  startDate,
  endDate,
  status = SensorCalibrationsStatus.Completed,
}: UseSensorCalibrationsProps): UseSensorCalibrationsResult => {
  const [calibrations, setCalibrations] = useState<
    Array<TSensorCalibration> | []
  >([]);
  const [errors, setErrors] = useState<string[] | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);

  const rootStore = useStores();
  const sensorsApi = new SensorsApi(rootStore.environment.api);

  const getCalibrations = async () => {
    if (!uid || !startDate || !endDate) return;

    setLoading(true);
    const result = await sensorsApi.getSensorCalibrations({
      uid,
      startDate,
      endDate,
      status,
    });
    setLoading(false);

    if (result.kind === "ok") {
      setCalibrations(result.data);
      setErrors(null);
    }

    if (result.kind === "error") {
      setCalibrations([]);
      setErrors([translate("Sensors.History.errors.requestFailed")]);
    }
  };

  useEffect(() => {
    getCalibrations();
  }, [uid, startDate, endDate, status]);

  return {
    isLoading,
    calibrations,
    errors,
  };
};
