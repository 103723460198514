import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { StackScreenProps } from "@react-navigation/stack";
import { useTheme } from "@react-navigation/native";

import { useStores } from "@models/index";
import { Screen } from "@components/index";
import { TopBarNew } from "@components/layout";
import { Text } from "@components/ui";

import { spacing } from "../../../../theme";
import { translate } from "../../../../i18n";
import { SettingsNavigatorParamList } from "../../navigation";
import { EnterCode, EnterEmail, Warning } from "./components";
import { AccountDeleteState, TDataNextState } from "./types";

const styles = {
  screen: {
    flex: 1,
    paddingHorizontal: spacing[4],
    paddingTop: spacing[1],
  },
  title: {
    textAlign: "center",
    fontSize: 24,
    lineHeight: 32,
    marginTop: spacing[6],
    marginBottom: spacing[4],
  },
};

const CONTENT = {
  [AccountDeleteState.Warning]: Warning,
  [AccountDeleteState.EnterEmail]: EnterEmail,
  [AccountDeleteState.EnterCode]: EnterCode,
};

export const AccountDeleteScreen: React.FC<
  StackScreenProps<SettingsNavigatorParamList, "settings-account-delete">
> = observer(({ navigation }) => {
  const { accountStore } = useStores();
  const theme = useTheme() as any;

  const initialState = AccountDeleteState.Warning;
  const [state, setState] = useState<AccountDeleteState>(initialState);
  const [historyState, setHistoryState] = useState<AccountDeleteState[]>([
    initialState,
  ]);
  const { email } = accountStore.currentUser;

  const Content = CONTENT[state];

  const onNextState = ({ nextState }: TDataNextState) => {
    setHistoryState([...historyState, nextState]);
    setState(nextState);

    if (nextState === AccountDeleteState.EnterCode) {
      accountStore.accountDeleteRequest(email);
    }
  };

  const backHandler = () => {
    const prevState = historyState[historyState.length - 2];
    if (state === initialState || !prevState) {
      setHistoryState([initialState]);

      return navigation.navigate("settings-account");
    }
    setHistoryState(historyState.slice(0, historyState.length - 1));

    return setState(prevState);
  };

  return (
    <Screen
      testID="AccountDelete"
      preset="scroll"
      style={styles.screen}
      header={<TopBarNew isShadow={false} backHandler={backHandler} />}
      keyboard
    >
      <Text style={[styles.title, theme.fonts.medium]}>
        {translate("Auth.AccountDelete.title")}
      </Text>
      <Content email={email} onNextState={onNextState} />
    </Screen>
  );
});
