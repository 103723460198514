import { ApiResponse } from "apisauce";

import { Rule } from "@models/rule/rule";
import { ApiProblem, processResponse } from "./response-resolver";
import { Api } from "./api";

const RULES_PATH = "/Rules";

export type GetRulesResult = { kind: "ok"; data: Rule[] } | ApiProblem;
export type GetRuleResult = { kind: "ok"; data: Rule } | ApiProblem;
export type DeleteRuleResult = { kind: "ok"; data?: any } | ApiProblem;

const filterRuleData = (data) => {
  const asArray = Object.entries(data);
  const filtered = asArray.filter(([key, value]) => {
    return key !== "uid" && value !== null;
  });
  return Object.fromEntries(filtered);
};

export class RulesApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getRules(): Promise<GetRulesResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        RULES_PATH,
      );
      return processResponse(response);
    } catch (e) {
      return { kind: "error" };
    }
  }

  async updateRule(uid: string, ruleData: Rule): Promise<GetRuleResult> {
    const filteredData = filterRuleData(ruleData);
    try {
      const response: ApiResponse<any> = await this.api.apisauce.put(
        `${RULES_PATH}/${uid}`,
        filteredData,
      );
      return processResponse(response);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async createRule(ruleData: Rule): Promise<GetRuleResult> {
    const filteredData = filterRuleData(ruleData);
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(
        RULES_PATH,
        filteredData,
      );
      return processResponse(response);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async deleteRule(ruleUid: string): Promise<DeleteRuleResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.delete(
        `${RULES_PATH}/${ruleUid}`,
        // { ruleUid },
      );
      return processResponse(response);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }
}
