import Svg, { Path } from "react-native-svg";

const PowerIcon = ({ size = 24, color = "#2B2E32" }) => (
  <Svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <Path d="M12 2v10" stroke={color} />
    <Path d="M18.4 6.6a9 9 0 1 1-12.77.04" stroke={color} />
  </Svg>
);

export default PowerIcon;
