import { StyleProp, View, ViewStyle } from "react-native";

import { TReleaseNotesData } from "@models/documents/types";
import { MarkdownContent } from "@components/ui";

import { translate } from "../../i18n";
import { dateTimeFormat, DateTimeFormats } from "../../utils/timeConverter";

type ReleaseNoteProps = {
  releaseNote: TReleaseNotesData;
  style?: StyleProp<ViewStyle>;
};

const getContent = (item: TReleaseNotesData): string => {
  const date = dateTimeFormat(item.date, DateTimeFormats.DateMDY);
  const title = translate("Settings.ReleaseNotes.version", {
    version: item.version,
  });

  return `### ${title} (${date})\n ${item.content}`;
};

export const ReleaseNoteContent = ({
  releaseNote,
  style,
}: ReleaseNoteProps) => (
  <View style={style}>
    <MarkdownContent>{getContent(releaseNote)}</MarkdownContent>
  </View>
);
