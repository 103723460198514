import React from "react";
import { Checkbox as PaperCheckbox } from "react-native-paper";
import { useTheme } from "@react-navigation/native";

type Props = React.ComponentProps<typeof PaperCheckbox>;

function Checkbox({ color, ...props }: Props) {
  const { colors } = useTheme() as any;

  return <PaperCheckbox.Android color={color || colors.primary} {...props} />;
}

export default Checkbox;
