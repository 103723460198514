import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { palette } from "../theme";

export default function WiFiOffIcon({ color = palette.Black, size = 24 }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M12 18H12.01"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M9.17188 15.1711C9.92199 14.4213 10.9392 14 11.9999 14C13.0605 14 14.0778 14.4213 14.8279 15.1711"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M14.3698 10.3581C15.6124 10.7462 16.741 11.4329 17.6568 12.3581M6.34277 12.3431C7.4032 11.2797 8.74274 10.5378 10.2068 10.2031L6.34277 12.3431Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M10.1601 6.13976C12.0252 5.85056 13.9322 6.00537 15.7263 6.59162C17.5203 7.17787 19.1507 8.17902 20.4851 9.51376M3.51514 9.51476C4.53858 8.48918 5.73928 7.65743 7.05914 7.05976L3.51514 9.51476Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M3 3L21 21"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
