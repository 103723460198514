import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { observer } from "mobx-react-lite";
import { useTheme } from "@react-navigation/native";

import { useStores } from "@models/root-store/root-store-context";
import { ProgressBar, Tabs, Text } from "@components/ui";
import { TextInput } from "@components/inputs";

import { translate } from "../../../../i18n";
import { normalizeFontSize } from "../../../../utils/sizes";
import { spacing } from "../../../../theme";
import { CALIBRATION_STEPS_PROPS, CALIBRATION_TIME_MS } from "../constants";
import { CalibrationContent } from "./CalibrationContent";

const createStyles = (theme: any) =>
  StyleSheet.create({
    errorTitle: {
      fontSize: normalizeFontSize(24, 26),
      textAlign: "center",
      marginVertical: spacing[2],
      color: theme.colors.error,
      ...theme.fonts.medium,
    },

    title: {
      fontSize: normalizeFontSize(24, 26),
      textAlign: "center",
      ...theme.fonts.medium,
    },
    titleContainer: {
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: spacing[4],
    },
    input: {
      fontSize: normalizeFontSize(24, 26),
      textAlign: "center",
      height: 40,
      lineHeight: 40,
      ...theme.fonts.medium,
      marginTop: -spacing[1],
    },
    inputContainer: {
      width: 80,
      marginRight: spacing[1],
      marginTop: spacing[0],
      marginBottom: spacing[0],
    },

    valueTabs: {
      marginBottom: spacing[4],
    },
    valueTabsLabel: {
      fontSize: 18,
      lineHeight: 22,
      ...theme.fonts.medium,
    },
  });

type CalibrationStepProps = {
  onAbort: () => void;
};

export const CalibrationStepProcess = observer(
  ({ onAbort }: CalibrationStepProps) => {
    const { sensorCalibration } = useStores();
    const theme = useTheme() as any;
    const styles = useMemo(() => createStyles(theme), [theme]);

    const status = sensorCalibration.status || "";
    const {
      withInput,
      valueOptions,
      afterInputText,
      title,
      titleTx,
      ...sensorTypeProps
    } =
      CALIBRATION_STEPS_PROPS[sensorCalibration.sensorType]?.[
        `${sensorCalibration.currentStep}${status}`
      ] ||
      CALIBRATION_STEPS_PROPS[sensorCalibration.sensorType]?.[
        sensorCalibration.currentStep
      ] ||
      {};

    console.log("stepValue", sensorCalibration.stepValue ?? "");

    if (sensorCalibration.isInProgress) {
      return (
        <CalibrationContent
          title={
            titleTx
              ? translate(titleTx, { stepValue: sensorCalibration.stepValue })
              : title
          }
          itemsTitle={sensorCalibration.stepNameOf}
          onSubmit={onAbort}
          submitLabel={translate("Sensors.Calibration.interrupt")}
          submitType="secondary"
          {...sensorTypeProps}
        >
          <ProgressBar
            looped
            durationMs={
              sensorCalibration.isInProgress
                ? CALIBRATION_TIME_MS + CALIBRATION_TIME_MS / 10
                : 0
            }
          />
        </CalibrationContent>
      );
    }

    if (sensorCalibration.hasError) {
      return (
        <CalibrationContent
          title={
            titleTx
              ? translate(titleTx, { stepValue: sensorCalibration.stepValue })
              : title
          }
          itemsTitle={sensorCalibration.stepNameOf}
          submitLabel={translate("common.tryAgain")}
          onSubmit={sensorCalibration.tryAgain}
          {...sensorTypeProps}
          alert={sensorCalibration.error}
        >
          <Text
            style={styles.errorTitle}
            tx="Sensors.Calibration.somethingWentWrong"
          />
        </CalibrationContent>
      );
    }

    const onTabPress = (item) => {
      sensorCalibration.setStepValue(item.key);
    };

    return (
      <CalibrationContent
        title={withInput ? undefined : title}
        itemsTitle={sensorCalibration.stepNameOf}
        submitLabel={translate("Sensors.Calibration.Calibrate")}
        onSubmit={sensorCalibration.process}
        {...sensorTypeProps}
      >
        {valueOptions?.length ? (
          <Tabs
            items={valueOptions.map((item) => ({
              ...item,
              onPress: onTabPress,
            }))}
            currentTab={sensorCalibration.stepValue?.toString()}
            style={styles.valueTabs}
            labelStyle={styles.valueTabsLabel}
          />
        ) : null}

        {withInput ? (
          <>
            <Text style={styles.title}>{title}</Text>
            <View style={styles.titleContainer}>
              <TextInput
                keyboardType="numeric"
                value={(sensorCalibration.stepValue ?? "").toString()}
                onChangeText={sensorCalibration.setStepValue}
                maxLength={3}
                style={styles.input}
                containerStyle={styles.inputContainer}
              />
              <Text style={styles.title}>{afterInputText}</Text>
            </View>
          </>
        ) : null}
      </CalibrationContent>
    );
  },
);
