import * as React from "react";
import Svg, { Path } from "react-native-svg";

export default function ArrowRightIcon({ color = "#2B2E32", size = 24 }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M6 12H18M18 12L13 7M18 12L13 17"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
