import * as React from "react";
import Svg, { SvgProps, Path, Circle, Rect } from "react-native-svg";

export const CalibrationORP256Svg = (props: SvgProps) => (
  <Svg width={287} height={172} fill="none" {...props}>
    <Path
      fill="#B6C5D3"
      d="M71.475 142.451c15.677 0 28.385-3.858 28.385-8.617s-12.709-8.616-28.385-8.616c-15.677 0-28.385 3.857-28.385 8.616 0 4.759 12.708 8.617 28.385 8.617Z"
    />
    <Path
      stroke="#2A2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.261}
      d="M75.32 16.27v-.985c0-6.988-5.696-12.654-12.72-12.654-7.029 0-12.724 5.666-12.724 12.654v23.049c0 6.988-5.696 12.654-12.721 12.654h-1.191c-7.026 0-12.721-5.666-12.721-12.654"
    />
    <Path
      fill="#6A7B8C"
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.261}
      d="M78.84 34.965 76.443 16.75h-2.248L71.8 34.965l3.52-.246 3.518.246ZM81.884 34.965h-13.07v13.07h13.07v-13.07ZM81.884 103.316h-13.07v2.629h13.07v-2.629Z"
    />
    <Path
      stroke="#2A2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.261}
      d="M79.284 128.143H71.41a2.592 2.592 0 0 1-2.594-2.594v-12.355h13.069v12.355a2.6 2.6 0 0 1-2.6 2.594Z"
    />
    <Path
      fill="#6A7B8C"
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.261}
      d="M79.08 113.194h-7.46v11.869h7.46v-11.869ZM83.406 48.029h-16.12v55.281h16.12V48.029Z"
    />
    <Path
      stroke="#2A2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.261}
      d="M83.406 105.939h-16.12v7.255h16.12v-7.255ZM68.903 39.19h13.164M68.903 43.494h13.164"
    />
    <Path
      fill="#70B8FF"
      fillOpacity={0.4}
      d="M75.023 98.86c13.717 0 24.837-3.404 24.837-7.603 0-4.2-11.12-7.603-24.837-7.603s-24.837 3.404-24.837 7.603 11.12 7.603 24.837 7.603Z"
    />
    <Path
      fill="#308AE3"
      fillRule="evenodd"
      d="M99.86 91.257c0 4.2-11.12 7.603-24.837 7.603-13.71 0-24.825-3.4-24.837-7.596V131.626c0 4.294 11.124 7.784 24.837 7.784s24.837-3.49 24.837-7.784v-41.89l-.49.01c.321.489.49.994.49 1.511Zm-49.674-.007c0-.16.017-.32.05-.478h-.05v.478Z"
      clipRule="evenodd"
      opacity={0.6}
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M99.86 69.461v62.17c0 4.291-11.149 7.779-24.892 7.779-13.742 0-24.891-3.488-24.891-7.779V80.728a6.762 6.762 0 0 0-2.07-4.873L44.103 71.7"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M83.446 62.811c9.578 1.053 16.424 3.856 16.414 7.151 0 4.198-11.105 7.61-24.82 7.61-8.195 0-15.47-1.223-19.983-3.098a21.712 21.712 0 0 0-5.086-1.415l-4.987-.46c-.957-.084-1.218-1.33-.36-1.766 2.586-1.306 6.23-3.133 7.92-3.883 3.55-1.583 8.148-3.26 14.39-4.078"
    />
    <Path
      stroke="#fff"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M57.678 109.127c1.5.436 3.02.817 4.54 1.141 2.57.548 4.68 3.141 4.68 6.037v1.375c0 1.945-1 3.611-2.57 4.192a62.271 62.271 0 0 1-7.36 1.375v3.175c3.27.995 6.59 1.722 9.93 2.169M80.178 111.408c-3.14.269-6.29.291-9.43.067v6.954c1.57.112 3.14.156 4.72.145 2.61-.011 4.72 2.124 4.72 5.009v.1c0 2.885-2.11 5.422-4.72 5.445-1.57.011-3.15-.034-4.72-.146M88.458 128.356c-2.57.581-4.67-1.487-4.67-4.371v-.492c0-2.884 2.1-5.5 4.67-6.082 2.57-.57 4.62 1.118 4.62 4.002v.492c0 2.885-2.05 5.881-4.62 6.451Z"
    />
    <Path
      stroke="#fff"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M93.078 109.642c-.64.19-1.28.38-1.93.559-4.04 1.095-7.37 4.874-7.37 9.021v3.79"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M93.978 82.333v21.196"
    />
    <Circle
      cx={76.09}
      cy={160}
      r={11}
      fill="#B6C5D3"
      stroke="#fff"
      strokeWidth={2}
    />
    <Path
      fill="#fff"
      d="M77.464 166h-1.68v-8.32a159.294 159.294 0 0 1 .064-1.312 7.501 7.501 0 0 1-.432.4c-.15.128-.32.277-.512.448l-1.248 1.008-.864-1.088 3.264-2.56h1.408V166Z"
    />
    <Circle
      cx={210.09}
      cy={160}
      r={11}
      fill="#B6C5D3"
      stroke="#fff"
      strokeWidth={2}
    />
    <Path
      fill="#fff"
      d="M213.928 166h-7.68v-1.312l2.944-2.992a49.412 49.412 0 0 0 1.44-1.52c.384-.437.672-.853.864-1.248.192-.405.288-.843.288-1.312 0-.587-.171-1.024-.512-1.312-.342-.299-.784-.448-1.328-.448-.523 0-.992.101-1.408.304a6.609 6.609 0 0 0-1.296.816l-.912-1.136c.309-.267.64-.507.992-.72a5.271 5.271 0 0 1 1.2-.512 5.34 5.34 0 0 1 1.472-.192c.725 0 1.349.128 1.872.384.522.245.922.597 1.2 1.056.288.459.432.997.432 1.616 0 .459-.07.891-.208 1.296a4.928 4.928 0 0 1-.608 1.168 8.942 8.942 0 0 1-.944 1.168 44.22 44.22 0 0 1-1.232 1.248l-2.128 2.064v.08h5.552V166ZM250.84 156.845l32.987-3.854-32.41-82.027c-1.788-4.627-5.112-10.111-9.525-12.373l-6.452-3.324-.047 48.862c.084 2.728-1.34 7.662-3.565 6.089l-22.197-15.605a6.825 6.825 0 0 0-8.854.875 6.814 6.814 0 0 0-.298 9.06l6.201 7.429-17.821-.167.391 1.797a8.553 8.553 0 0 0 8.585 6.731l17.299-.298 10.381 13.976c4.413 6.917 12.876 15.232 21.07 15.79l1.936.131 2.319 6.908Z"
    />
    <Path
      fill="#DBE2EA"
      d="M228.319 107.386h-44.591a2.565 2.565 0 0 1-2.57-2.57V10.877a2.565 2.565 0 0 1 2.57-2.57h44.591a2.565 2.565 0 0 1 2.57 2.57v93.939a2.573 2.573 0 0 1-2.57 2.57Z"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M235.373 21.896V10.758a6.92 6.92 0 0 0-6.92-6.92h-44.946a6.919 6.919 0 0 0-6.92 6.92v94.163a6.918 6.918 0 0 0 6.92 6.92h44.946a6.92 6.92 0 0 0 6.92-6.92v-65.39M208.153 23.808l-22.526 23.198M193.036 24.361l-6.55 6.854"
    />
    <Path
      fill="#3F9A6E"
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M225.474 102.549h-38.975a.558.558 0 0 1-.554-.554v-6.801c0-.303.251-.554.554-.554h38.975c.303 0 .554.25.554.554v6.801a.558.558 0 0 1-.554.554Z"
    />
    <Rect
      width={16.759}
      height={3.724}
      x={198.001}
      y={8.494}
      fill="#2B2E32"
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      rx={1.862}
    />
    <Path
      stroke="#2A2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="m235.44 55.267 9.692 5.726a10.38 10.38 0 0 1 4.358 5.102L283.827 153"
    />
    <Path
      fill="#fff"
      d="M200.926 95.312a7.262 7.262 0 0 1 9.32-.801l24.756 17.476-14.803 13.752-19.394-20.27c-2.719-2.858-2.673-7.364.121-10.157Z"
    />
    <Path
      stroke="#2A2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M246.641 112.434h-6.75a17.54 17.54 0 0 1-10.335-3.371l-19.59-14.3a6.928 6.928 0 0 0-8.957.67c-2.662 2.634-2.737 6.917-.176 9.655l19.366 20.651 8.286 12.923a24.443 24.443 0 0 0 18.612 11.172l1.415.112 2.328 6.899M241.632 97.21l-4.255-17.503-2.365-2.737M188.496 111.81l.745 2.141a9.099 9.099 0 0 0 8.584 6.099h11.266"
    />
  </Svg>
);
