import Svg, { Path } from "react-native-svg";

import { palette } from "../../theme";

export default function LanguageIcon({ color = palette.Black, size = 24 }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 2.25a.75.75 0 0 1 .75.75v1.25H11a.75.75 0 0 1 0 1.5H9.73c-.112 2.035-.676 3.891-1.559 5.304-.081.13-.166.258-.253.381 1.019.471 2.332.78 3.8.815a.75.75 0 0 1-.036 1.5c-1.823-.045-3.502-.464-4.801-1.155C6.06 13.31 5.085 13.75 4 13.75a.75.75 0 0 1 0-1.5c.537 0 1.078-.182 1.59-.544-.807-.726-1.341-1.644-1.34-2.707a.75.75 0 0 1 1.5.002c0 .53.28 1.105.894 1.636a6.4 6.4 0 0 0 .255-.378c.72-1.152 1.218-2.726 1.328-4.509H4a.75.75 0 0 1 0-1.5h4.25V3A.75.75 0 0 1 9 2.25Zm7 8a.75.75 0 0 1 .685.445l4 9a.75.75 0 0 1-1.37.61l-.691-1.555h-5.248l-.69 1.555a.75.75 0 0 1-1.371-.61l4-9A.75.75 0 0 1 16 10.25Zm-1.957 7h3.914L16 12.847l-1.957 4.403Z"
        fill={color}
      />
    </Svg>
  );
}
