import Svg, { Path } from "react-native-svg";

const Filter = ({ size = 24, color = "#2B2E32" }) => (
  <Svg width={size} height={size} fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.25 4.292a.75.75 0 0 1 .25-.042h13a.75.75 0 0 1 .247.042 1.75 1.75 0 0 1 .875 2.625.738.738 0 0 1-.068.088L14.75 12.29V19a.75.75 0 0 1-1.2.6l-4-3a.75.75 0 0 1-.3-.6v-3.71L4.444 7.005a.748.748 0 0 1-.068-.088 1.75 1.75 0 0 1 .875-2.625Zm.422 1.458a.252.252 0 0 0-.09.227.25.25 0 0 0 .022.073l4.95 5.446a.75.75 0 0 1 .195.504v3.625l2.5 1.875V12a.75.75 0 0 1 .195-.505l4.95-5.445a.247.247 0 0 0 .011-.185.25.25 0 0 0-.079-.115H5.672Z"
      fill={color}
    />
  </Svg>
);

export default Filter;
