import { ApiResponse } from "apisauce";

import { Api } from "./api";
import { User, UserRegister } from "../../models";
import { ApiProblem, processResponse } from "./response-resolver";

const LOGIN_PATH = "/Account/token";
const REGISTER_PATH = "/Account/register";
const PROFILE_PATH = "/Account/profile";
const RECOVERY_REQUEST_PATH = "/Account/recovery/request";
const RECOVERY_VERIFY_PATH = "/Account/recovery/verify";
const RECOVERY_CHANGE_PASSWORD_PATH = "/Account/recovery/change_password";
const CHANGE_PASSWORD_PATH = "/Account/change_password";
const REQUEST_ACCOUNT_DELETE_PATH = "/Account/delete/request";
const CONFIRM_ACCOUNT_DELETE_PATH = "/Account/delete/confirm";
const SETTINGS_PATH = "/Account/settings";
const CHANGE_EMAIL_REQUEST_PATH = "/Account/change_email/request";
const CHANGE_EMAIL_CONFIRM_PATH = "/Account/change_email/confirm";
const CHANGE_EMAIL_CONFIRM_NEW_EMAIL_PATH =
  "/Account/change_email/confirm_new_email";

// Services/Notifications/api/v1/Notifications?pageNumber=1&pageSize=20

/* eslint-disable @typescript-eslint/no-shadow */
export enum AccountSettings {
  User = "user",
  Sensors = "sensors",
  Devices = "devices",
  Notifications = "notifications",
}
/* eslint-enable @typescript-eslint/no-shadow */

export type LoginResponse = {
  token: string;
  user: User;
};

// recovery.token != user.token
export type RecoveryTokenResponse = {
  token: string;
};

export type LoginResult = { kind: "ok"; data: LoginResponse } | ApiProblem;
export type UserResult = { kind: "ok"; data: User } | ApiProblem;
export type RegisterResult = { kind: "ok"; data: LoginResponse } | ApiProblem;
export type RecoveryRequestResult = { kind: "ok" } | ApiProblem;
export type RecoveryTokenResult =
  | { kind: "ok"; data: RecoveryTokenResponse }
  | ApiProblem;
export type RecoveryChangePasswordResult = { kind: "ok" } | ApiProblem;
export type RecoveryChangePasswordAttrs = {
  code: string;
  email: string;
  password: string;
  confirmPassword: string;
};
export type AccountDeleteRequestResult = { kind: "ok" } | ApiProblem;
export type AccountDeleteConfirmResult = { kind: "ok" } | ApiProblem;

export type AccountSaveResult = { kind: "ok" } | ApiProblem;
export type AccountSettingsValue = Record<
  string,
  string | number | boolean | null
> | null;
type SettingsResult = { kind: "ok"; data: AccountSettingsValue } | ApiProblem;
export type ChangeEmailResult = { kind: "ok" } | ApiProblem;

export class AccountApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async login(username: string, password: string): Promise<LoginResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(
        LOGIN_PATH,
        { username, password },
      );
      return processResponse(response, (data) => ({
        token: data.token,
        user: data.userDetails,
      }));
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async register(user: UserRegister): Promise<RegisterResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(
        REGISTER_PATH,
        user,
      );

      return processResponse(response, (data) => ({
        token: data.token,
        user: data.userDetails,
      }));
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async getCurrentUser(): Promise<UserResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        PROFILE_PATH,
      );
      return processResponse(response);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async recoveryRequest(email: string): Promise<RecoveryRequestResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(
        RECOVERY_REQUEST_PATH,
        { email },
      );
      return processResponse(response);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async recoveryToken(
    email: string,
    code: string,
  ): Promise<RecoveryTokenResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(
        RECOVERY_VERIFY_PATH,
        { email, code },
      );

      return processResponse(response);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async recoveryChangePassword({
    code,
    email,
    password,
    confirmPassword,
  }: RecoveryChangePasswordAttrs): Promise<RecoveryChangePasswordResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(
        RECOVERY_CHANGE_PASSWORD_PATH,
        { code, email, password, confirmPassword },
      );

      return processResponse(response);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async changePassword(
    oldPassword: string,
    newPassword: string,
    confirmedPassword: string,
  ): Promise<RecoveryChangePasswordResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(
        CHANGE_PASSWORD_PATH,
        { oldPassword, newPassword, confirmedPassword },
      );

      return processResponse(response);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async accountDeleteRequest(
    email: string,
  ): Promise<AccountDeleteRequestResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(
        REQUEST_ACCOUNT_DELETE_PATH,
        { email },
      );
      return processResponse(response);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async accountDeleteConfirm(
    email: string,
    code: string,
  ): Promise<AccountDeleteConfirmResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(
        CONFIRM_ACCOUNT_DELETE_PATH,
        { email, code },
      );
      return processResponse(response);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }
  // async updateUser(user: UserRegister): Promise<any> {
  //   // TODO: !!!
  //   return { kind: "ok", data: {} };
  // }

  async getSettings(name: AccountSettings): Promise<SettingsResult | null> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `${SETTINGS_PATH}/${name}`,
      );
      return processResponse(response);
    } catch (e) {
      return { kind: "error" };
    }
  }

  async saveSettings(
    name: AccountSettings,
    settings: AccountSettingsValue,
  ): Promise<AccountSaveResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(
        `${SETTINGS_PATH}/${name}`,
        settings,
      );
      return processResponse(response);
    } catch (e) {
      return { kind: "error" };
    }
  }

  async changeEmailRequest(newEmail: string): Promise<ChangeEmailResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(
        CHANGE_EMAIL_REQUEST_PATH,
        { newEmail },
      );
      return processResponse(response);
    } catch (e) {
      return { kind: "error" };
    }
  }

  async changeEmailConfirm(
    newEmail: string,
    code: string,
  ): Promise<ChangeEmailResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(
        CHANGE_EMAIL_CONFIRM_PATH,
        { newEmail, code },
      );
      return processResponse(response);
    } catch (e) {
      return { kind: "error" };
    }
  }

  async changeEmailConfirmNewEmail(
    newEmail: string,
    code: string,
  ): Promise<ChangeEmailResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(
        CHANGE_EMAIL_CONFIRM_NEW_EMAIL_PATH,
        { newEmail, code },
      );
      return processResponse(response);
    } catch (e) {
      return { kind: "error" };
    }
  }
}
