import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { observer } from "mobx-react-lite";
import { useTheme } from "@react-navigation/native";

import { useStores } from "@models/index";
import { RuleScheduleItem } from "@models/rule/rule";
import { Label } from "@components/inputs";
import { Text } from "@components/ui";
import { IconButton } from "@components/common";

import { spacing } from "../../../../../../theme";
import { DeleteIcon, EditIcon } from "../../../../../../svgs/menu";
import { displayDuration, displayTime } from "../../../../../../utils/display";

const createStyles = (theme: any) =>
  StyleSheet.create({
    card: {
      backgroundColor: theme.colors.cardBG,
      borderRadius: spacing[2],
      marginTop: spacing[2],
      marginBottom: spacing[1],
      padding: spacing[3],

      flexDirection: "row",
      justifyContent: "space-between",
    },
    content: {
      justifyContent: "space-between",
    },
    controls: {
      flexDirection: "row",
      alignItems: "center",
    },
    item: {
      flexDirection: "row",
      alignItems: "center",
    },
    label: {
      minWidth: 80,
    },
    value: {
      fontSize: 14,
    },
  });

type ScheduleCardProps = {
  scheduleItem: RuleScheduleItem;
  onEdit: () => void;
  onDelete: () => void;
  isDeleteDisabled?: boolean;
};

export const ScheduleCard: React.FC<ScheduleCardProps> = observer(
  ({ scheduleItem, onEdit, onDelete, isDeleteDisabled }) => {
    const theme = useTheme() as any;
    const styles = useMemo(() => createStyles(theme), [theme]);
    const { settingsStore } = useStores();

    return (
      <View style={styles.card}>
        <View style={styles.content}>
          <View style={styles.item}>
            <Label
              tx="Devices.Rules.startAt"
              size="small"
              style={styles.label}
            />
            <Text style={styles.value}>
              {displayTime(
                scheduleItem.startAt,
                settingsStore.currentTimeSystem,
              )}
            </Text>
          </View>
          <View style={styles.item}>
            <Label
              tx="Devices.Rules.duration"
              size="small"
              style={styles.label}
            />
            <Text style={styles.value}>
              {displayDuration(scheduleItem.startAt, scheduleItem.endAt)}
            </Text>
          </View>
        </View>

        <View style={styles.controls}>
          <IconButton icon={EditIcon} onPress={onEdit} />
          <IconButton
            icon={DeleteIcon}
            iconColor={theme.colors.error}
            onPress={onDelete}
            disabled={isDeleteDisabled}
          />
        </View>
      </View>
    );
  },
);
