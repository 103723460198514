/* eslint-disable import/prefer-default-export */
/* eslint-disable no-promise-executor-return */

export const JSONParseSafety = (str: string) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return null;
  }
};
