import { useState } from "react";
import { Device, DeviceState } from "@models/device/device";

type HookProps = {
  device: Device;
  setErrors: (errors: string[]) => void;
};

type HookResult = {
  visibleDurationDialog: boolean;
  showDurationDialog: () => void;
  hideDurationDialog: () => void;
  switchDevice: (seconds?: number, pinValue?: number) => void;
  onSwitchPress: () => void;
};

export function useDeviceSwitcher({
  device,
  setErrors,
}: HookProps): HookResult {
  const [visibleDurationDialog, setVisibleDurationDialog] = useState(false);
  const showDurationDialog = () => setVisibleDurationDialog(true);
  const hideDurationDialog = () => setVisibleDurationDialog(false);

  const switchDevice = async (seconds?: number, pinValue?: number) => {
    const timeToSwitchBack = seconds || 0;
    hideDurationDialog();

    const switchOff = async () => {
      // await device.rule.switchOff(); // TODO: automation -> off - https://growdirector.atlassian.net/browse/GDV1-606
      await device.switchOff();
    };

    const result =
      device.state === DeviceState.Off
        ? await device.switchOn(timeToSwitchBack, pinValue)
        : device.state === DeviceState.On && (await switchOff());

    if (result?.errors) {
      setErrors(result.errors);
    }
    return result;
  };

  const onSwitchPress = () => {
    if (device.state === DeviceState.Off) {
      return showDurationDialog();
    }
    return switchDevice();
  };

  return {
    visibleDurationDialog,
    showDurationDialog,
    hideDurationDialog,
    switchDevice,
    onSwitchPress,
  };
}
