import React, { useMemo } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";

import { SensorState, useStores } from "@models/index";
import { Sensor } from "@models/sensor/sensor";
import { Rule, RuleDirection } from "@models/rule/rule";
import { SensorsNavigatorParamList } from "@screens/Sensors/navigation";
import { Badge, Text } from "@components/ui";

import { observer } from "mobx-react-lite";
import { translate } from "../../../../i18n";
import { useAppTheme } from "../../../../hooks";
import { spacing } from "../../../../theme";
import * as SensorsIcons from "../../../../svgs/sensors";
import { NoneIcon, ArrowRightIcon, DisconnectIcon } from "../../../../svgs";
import { displayNumber } from "../../../../utils/display";
import { dateTimeToTime, secondsToText } from "../../../../utils/timeConverter";

const createStyles = (theme: any) =>
  StyleSheet.create({
    input: {
      marginVertical: 10,
    },
    label: {
      color: theme.colors.subLabel,
      marginBottom: spacing[1],
    },
    value: {
      fontSize: 16,
      lineHeight: 22,
      ...theme.fonts.medium,
    },
    button: {
      maxWidth: "100%",
      flexDirection: "row",
      alignItems: "center",
    },
    buttonLabel: {
      marginLeft: spacing[2],
      color: theme.colors.link,
      flex: 1,
    },
    arrow: {
      marginHorizontal: spacing[2],
    },
    valueContainer: {
      flexDirection: "row",
      alignItems: "center",
      overflow: "hidden",
      textOverflow: "ellipsis",
      flex: 1,
    },
    inlineIcon: {
      marginLeft: spacing[1],
    },
  });

export const DeviceRuleSensorBlock = observer(
  ({ sensor, label }: { sensor?: Sensor; label?: string }) => {
    const theme = useAppTheme();
    const styles = useMemo(() => createStyles(theme), [theme]);

    const navigation =
      useNavigation() as StackNavigationProp<SensorsNavigatorParamList>;
    const goToSensor = () => {
      navigation.navigate("sensor-details", { uid: sensor.uid });
    };

    const SensorIcon = SensorsIcons[sensor?.iconName] || NoneIcon;

    return (
      <View style={styles.input}>
        <Text style={styles.label}>
          {label || translate("Devices.Rules.sensor")}
        </Text>
        <View style={styles.valueContainer}>
          {sensor ? (
            <Pressable style={styles.button} onPress={goToSensor}>
              <SensorIcon color={theme.colors.link} size={24} />
              <Text
                style={[styles.value, styles.buttonLabel]}
                numberOfLines={1}
              >
                {sensor.displayName}
              </Text>
              {[SensorState.Offline, SensorState.Disconnect].includes(
                sensor.state,
              ) && (
                <Badge
                  icon={DisconnectIcon}
                  style={styles.inlineIcon}
                  iconSize={14}
                />
              )}
            </Pressable>
          ) : (
            <Badge text={translate("Devices.History.errors.noSensor")} />
          )}
        </View>
      </View>
    );
  },
);

export const DeviceRuleSensorValuesBlock = observer(
  ({ rule, sensor }: { rule: Rule; sensor?: Sensor }) => {
    const theme = useAppTheme();
    const styles = useMemo(() => createStyles(theme), [theme]);

    const unitName = sensor?.unitName;
    const sensorPrecision = sensor?.numberOfDecimals || 2;

    const { valueLow, valueHigh } = rule.sensorData;
    const valueLowDisplay = displayNumber(
      valueLow || rule.valueLow,
      sensorPrecision,
      "-",
    );
    const valueHighDisplay = displayNumber(
      valueHigh || rule.valueHigh,
      sensorPrecision,
      "-",
    );

    const label =
      rule.direction === RuleDirection.Down
        ? translate("Devices.Rules.decrease")
        : translate("Devices.Rules.increase");

    return (
      <View style={styles.input}>
        <Text style={styles.label}>{label}</Text>
        <View style={styles.valueContainer}>
          <Text style={styles.value}>{valueLowDisplay}</Text>
          {unitName ? <Text style={styles.value}>{` ${unitName}`}</Text> : null}

          <View style={styles.arrow}>
            <ArrowRightIcon color={theme.colors.text} size={24} />
          </View>

          <Text style={styles.value}>{valueHighDisplay}</Text>
          {unitName ? <Text style={styles.value}>{` ${unitName}`}</Text> : null}
        </View>
      </View>
    );
  },
);

export const DeviceRuleValueBlock = ({
  label,
  value,
  isOff,
}: {
  label: string;
  value?: string;
  isOff?: boolean;
}) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <View style={styles.input}>
      <Text style={styles.label}>{label}</Text>
      <View style={styles.valueContainer}>
        <Text style={styles.value}>
          {isOff ? translate("common.off") : value}
        </Text>
      </View>
    </View>
  );
};

export const DeviceRuleTimeBlock = ({
  label,
  value,
}: {
  label: string;
  value?: number;
}) => {
  if (!value) return null;

  return (
    <DeviceRuleValueBlock
      label={label}
      value={secondsToText(value, "duration")}
    />
  );
};

export const DeviceRuleWorkingTimeBlock = ({
  startDayTime,
  endDayTime,
}: {
  startDayTime?: string;
  endDayTime?: string;
}) => {
  const { settingsStore } = useStores();

  return (
    <DeviceRuleValueBlock
      label={translate("Devices.Rules.workingTime")}
      value={`${dateTimeToTime(
        startDayTime,
        settingsStore.currentTimeSystem,
      )} - ${dateTimeToTime(endDayTime, settingsStore.currentTimeSystem)}`}
    />
  );
};

export const DeviceRuleSensorValueBlock = ({
  value,
  sensor,
  label,
}: {
  value?: number;
  sensor?: Sensor;
  label: string;
}) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  const unitName = sensor?.unitName;
  const sensorPrecision = sensor?.numberOfDecimals || 2;
  // TODO: displaySensorValue !!!
  const valueDisplay = displayNumber(value, sensorPrecision, "-");

  return (
    <View style={styles.input}>
      <Text style={styles.label}>{label}</Text>
      <View style={styles.valueContainer}>
        <Text style={styles.value}>{valueDisplay}</Text>
        {unitName ? <Text style={styles.value}>{` ${unitName}`}</Text> : null}
      </View>
    </View>
  );
};
