import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { View, StyleSheet, TouchableWithoutFeedback } from "react-native";
import { useTheme } from "@react-navigation/native";

import { MarkdownContent, Text } from "@components/ui";

import { ChevronUpIcon, ChevronRightIcon } from "../../../svgs";
import { spacing } from "../../../theme";
import { SelectionSearchText } from "./SelectionSearchText";

const createStyles = (theme: any) =>
  StyleSheet.create({
    container: {
      backgroundColor: theme.colors.cardBG,
      marginHorizontal: spacing[4],
      marginTop: spacing[2],
      padding: spacing[4],
      borderRadius: spacing[4],
      color: theme.colors.text,
    },
    button: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    title: {
      fontSize: 16,
    },
  });

const highlightText = (text: string, searchText: string): string => {
  const arrayText = text.toLowerCase().split(searchText.toLowerCase());
  const lastIndex = arrayText.length - 1;
  let indexIn = 0;

  return arrayText
    .map((item, index) => {
      const itemEnd = indexIn + item.length;
      const itemText = text.slice(indexIn, itemEnd);
      const highlightedText =
        index < lastIndex
          ? `~~${text.slice(itemEnd, itemEnd + searchText.length)}~~`
          : "";
      indexIn = indexIn + item.length + searchText.length;
      return itemText + highlightedText;
    })
    .join("");
};

export type TQuestionsListItem = {
  id?: string;
  title: string;
  content: string;
  category?: string;
};

type Props = {
  item: TQuestionsListItem;
  searchText?: string;
};

export const ExpandedAnswer = observer(({ item, searchText }: Props) => {
  const theme = useTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);
  const [visibleContent, setVisibleContent] = useState(false);

  useEffect(() => {
    setVisibleContent(Boolean(searchText));
  }, [searchText]);

  const onPress = () => {
    setVisibleContent(!visibleContent);
  };

  const content = searchText
    ? highlightText(item.content, searchText)
    : item.content;

  return (
    <View style={styles.container}>
      <TouchableWithoutFeedback onPress={onPress}>
        <View style={styles.button}>
          <Text style={styles.title}>
            {searchText ? (
              <SelectionSearchText text={item.title} searchText={searchText} />
            ) : (
              item.title
            )}
          </Text>
          <View>
            {visibleContent ? (
              <ChevronUpIcon color={theme.colors.text} />
            ) : (
              <ChevronRightIcon color={theme.colors.text} />
            )}
          </View>
        </View>
      </TouchableWithoutFeedback>

      {visibleContent ? <MarkdownContent>{content}</MarkdownContent> : null}
    </View>
  );
});
