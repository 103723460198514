import React from "react";
import { observer } from "mobx-react-lite";

import { Rule, RuleType } from "@models/rule/rule";

import {
  DeviceRuleSensorBlock,
  DeviceRuleSensorValueBlock,
  DeviceRuleValueBlock,
  DeviceRuleWorkingTimeBlock,
} from "@screens/Devices/Details/components/DeviceRuleDetailsBlocks";
import { translate } from "../../../../i18n";
import { useSensor } from "../../../../hooks";

type RuleProps = {
  rule: Rule;
};
export const RulesDimmer: React.FC<RuleProps> = observer(({ rule }) => {
  const data = rule.dimmerData;
  // const lightSensor = sensorStore.getSensor(data.sensorUid);
  // const controlSensor = sensorStore.getSensor(data.controlSensorUid);
  const { sensor: lightSensor } = useSensor(data.sensorUid);
  const { sensor: controlSensor } = useSensor(data.controlSensorUid);

  return (
    <>
      <DeviceRuleWorkingTimeBlock
        startDayTime={data.startDayTime}
        endDayTime={data.endDayTime}
      />

      {rule.ruleType === RuleType.DimmerAuto && (
        <>
          <DeviceRuleSensorBlock sensor={lightSensor} />
          <DeviceRuleValueBlock
            label={translate("Devices.Rules.desiredValue")}
            value={data.valueTarget?.toString()}
          />
        </>
      )}

      {rule.ruleType === RuleType.DimmerManual && (
        <DeviceRuleValueBlock
          label={translate("Devices.Rules.dimming")}
          value={`${data.analogControlPinValue} %`}
        />
      )}

      <DeviceRuleValueBlock
        label={translate("Devices.Rules.sunriseSunset")}
        value={`${data.timeToTarget} ${translate("units.minutes")}`}
        isOff={!data.timeToTarget}
      />

      {data.controlSensorUid && data.controlSensorValue ? (
        <>
          <DeviceRuleSensorBlock
            sensor={controlSensor}
            label={translate("Devices.Rules.controllingSensor")}
          />
          <DeviceRuleSensorValueBlock
            sensor={controlSensor}
            label={translate("Devices.Rules.criticalValue")}
            value={data.controlSensorValue}
          />
        </>
      ) : (
        <DeviceRuleValueBlock
          label={translate("Devices.Rules.overheatPrevention")}
          isOff
        />
      )}
    </>
  );
});
