import React from "react";
import { StyleSheet, Linking } from "react-native";

import { Screen } from "@components/index";
import { LayoutCenter, TopBar } from "@components/layout";
import { Button, ScreenMessage } from "@components/ui";

import { translate } from "../../../i18n";
import { spacing } from "../../../theme";

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    marginHorizontal: spacing[7],
    marginBottom: spacing[5],
  },
});

export default function NoFreeSockets() {
  const buyModuleHandler = () => {
    Linking.openURL("https://growdirector.com/"); // TODO: move to config !!!
  };

  return (
    <Screen
      testID="NoFreeSockets"
      header={<TopBar title={translate("Devices.Add.title")} back />}
    >
      <LayoutCenter style={styles.container}>
        <ScreenMessage
          title={translate("NoData.noFreeSockets")}
          message={translate("NoData.noFreeSocketsDescription")}
        />
        <Button tx="NoData.buyModule" type="primary" onPress={buyModuleHandler}>
          Buy module
        </Button>
      </LayoutCenter>
    </Screen>
  );
}
