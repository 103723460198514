import Svg, { SvgProps, Path } from "react-native-svg";

export const Leaves = (props: SvgProps) => (
  <Svg width={146} height={76} fill="none" {...props}>
    <Path
      d="M27.869 81.203c12.464-.916 21.382-17.8 19.919-37.711-1.464-19.912-12.755-35.31-25.22-34.394-12.464.916-21.382 17.8-19.918 37.711C4.113 66.72 15.404 82.12 27.869 81.203Z"
      fill="#fff"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="m24.387 33.282 3.52 47.93"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M101.717 84.085c24.133-16.229 35.463-41.63 25.306-56.735-10.158-15.105-37.956-14.194-62.09 2.034C40.8 45.613 29.472 71.014 39.629 86.12c10.157 15.105 37.956 14.194 62.089-2.034Z"
      fill="#fff"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="m118.246 33.283-78.6 52.86M116.038 70.483l-38.02-10.15M62.927 31.023l-5.79 43.36M99.027 19.463l-3.92 29.39M95.127 64.902l10.82 16.09M89.557 19.993l7.84 11.66"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
  </Svg>
);
