import * as FileSystem from "expo-file-system";
import { ApiResponse, create } from "apisauce";

export enum ModuleUpdateStatus {
  loading = "loading",
  noConnectWifi = "noConnectWifi",
  noCanUpdate = "noCanUpdate",
  noUpdateFirmware = "noUpdateFirmware",
  noAutoDisconnectDevices = "noAutoDisconnectDevices",
  noLinkDownload = "noLinkDownload",
  ok = "ok",
}

// type FirmwareUpdateResult = {
//   ok: boolean;
//   status: ModuleUpdateStatus;
//   logs?: string;
// };
//
// let updateLogs = "";
//
// const setUpdateLog = (log: string) => {
//   updateLogs = updateLogs.concat(`${dayjs().format(DateTimeFormats.TimeHMS)} - ${log}\n`);
// };

// const returnResult = (status: ModuleUpdateStatus) => {
//   return {
//     ok: status === ModuleUpdateStatus.ok,
//     status,
//     logs: updateLogs,
//   };
// };

// export const firmwareUpdate = async (
//   ip: string,
//   firmwareUrl: string,
// ): Promise<FirmwareUpdateResult> => {
//   updateLogs = "";
//
//   if (!firmwareUrl) {
//     return returnResult(ModuleUpdateStatus.noLinkDownload);
//   }
//
//   setUpdateLog(`Downloading: ${firmwareUrl}`);
//
//   if (Platform.OS === "web") {
//     const fileData = await downloadFirmWareWeb(firmwareUrl);
//     if (!fileData) {
//       setUpdateLog("No fileData");
//       return returnResult(ModuleUpdateStatus.noUpdateFirmware);
//     }
//     setUpdateLog("fileData fetched successfully");
//
//     const updateResult = await uploadFirmWareWeb(ip, fileData);
//     setUpdateLog(
//       `updateResult: ${JSON.stringify({
//         ok: updateResult.ok,
//         problem: updateResult.problem,
//         data: updateResult.data,
//       })}`,
//     );
//
//     return updateResult.ok
//       ? returnResult(ModuleUpdateStatus.ok)
//       : returnResult(ModuleUpdateStatus.noUpdateFirmware);
//   }
//
//   const fileUri = await downloadFirmWareMobile(firmwareUrl);
//
//   if (!fileUri) {
//     setUpdateLog("No file");
//     return returnResult(ModuleUpdateStatus.noUpdateFirmware);
//   }
//   setUpdateLog(`Downloaded: ${fileUri}`);
//
//   const isOk = await uploadFirmWareMobile(ip, fileUri);
//
//   return isOk
//     ? returnResult(ModuleUpdateStatus.ok)
//     : returnResult(ModuleUpdateStatus.noUpdateFirmware);
// };

type FirmwareDownloadResult = {
  ok: boolean;
  headers?: any;
  status: number;
  uri?: string;
};

export const downloadFirmWareMobile = async (
  url: string,
): Promise<FirmwareDownloadResult> => {
  const [fileName] = url.split("/").slice(-1);
  if (!fileName) return null;

  const fileUri = `${FileSystem.cacheDirectory + fileName}.bin`;

  try {
    const { headers, status, uri } = await FileSystem.downloadAsync(
      url,
      fileUri,
    );

    return {
      ok: status === 200,
      headers,
      status,
      uri,
    };
  } catch (error) {
    console.error("Couldn't download file:", error);
    return { ...error, ok: false };
  }
};

export const uploadFirmWareMobile = async (
  ip: string,
  fileUri: string,
): Promise<ApiResponse<any>> => {
  const url = `http://${ip}/update`;
  try {
    const response = await FileSystem.uploadAsync(url, fileUri, {
      fieldName: "update",
      httpMethod: "POST",
      uploadType: FileSystem.FileSystemUploadType.MULTIPART,
      headers: {
        Accept: "*/*",
        "Content-Type": "multipart/form-data",
      },
    });

    if (response.status !== 200 || response.body === "FAIL") {
      return {
        ok: false,
        problem: JSON.stringify(response),
      } as ApiResponse<any>;
    }

    return {
      ok: true,
      data: response,
    } as ApiResponse<any>;
  } catch (error) {
    return {
      ok: false,
      problem: {
        message: error.message,
        code: error.code,
        stack: error.stack,
      } as any,
      originalError: error,
    } as ApiResponse<any>;
  }
};

// async function ensureDirExists() {
//   const dirInfo = await FileSystem.getInfoAsync(gifDir);
//   if (!dirInfo.exists) {
//     console.log("Gif directory doesn't exist, creating...");
//     await FileSystem.makeDirectoryAsync(gifDir, { intermediates: true });
//   }
// }

export const downloadFirmWareWeb = async (url: string): Promise<Blob> => {
  const apisauce = create({
    baseURL: "",
    timeout: 30000,
    responseType: "blob",
  });
  const result = await apisauce.get(url);

  return result.data as Blob;
};

export const uploadFirmWareWeb = async (
  ip: string,
  file: Blob,
): Promise<ApiResponse<any>> => {
  const url = `http://${ip}/update`;
  const apisauce = create({
    baseURL: "",
    timeout: 120000,
    headers: {
      Accept: "*/*",
      Connection: "keep-alive",
      "Content-Type": "multipart/form-data",
    },
    withCredentials: false,
  });
  const data = new FormData();
  data.append("update", file);

  return apisauce.post(url, data);
};
