import React, { useEffect, useMemo } from "react";
import { View, StyleSheet } from "react-native";
import { observer } from "mobx-react-lite";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";

import { useStores } from "@models/index";
import { ModulesNavigatorParamList } from "@screens/Modules/navigation";
import { ModuleCompactCard } from "@screens/Modules/components";
import { ErrorText, Loading, Text } from "@components/ui";

import { translate } from "../../../../i18n";
import { spacing } from "../../../../theme";
import { useAppTheme } from "../../../../hooks";

const createStyles = (theme: any) =>
  StyleSheet.create({
    container: { marginTop: spacing[4] },
    title: {
      marginBottom: spacing[2],
      fontSize: 16,
      lineHeight: 22,
      ...theme.fonts.medium,
    },
    loading: {
      minHeight: "auto",
    },
  });

type ConnectedModuleCardProps = {
  moduleUid: string;
  connectedToPin: number;
};

export const ConnectedModuleCard = observer(
  ({ moduleUid, connectedToPin }: ConnectedModuleCardProps) => {
    const theme = useAppTheme();
    const styles = useMemo(() => createStyles(theme), [theme]);

    const navigation =
      useNavigation() as StackNavigationProp<ModulesNavigatorParamList>;
    const { moduleStore } = useStores();

    const goToModule = () => {
      navigation.navigate("module-details", { uid: moduleUid });
    };

    const module = moduleStore.getModule(moduleUid);

    useEffect(() => {
      const fetchData = async () => {
        await moduleStore.fetchModule(moduleUid);
      };
      fetchData();
    }, [moduleUid]);

    if (!module)
      return moduleStore.isLoading ? (
        <View style={styles.container}>
          <Loading withMask={false} style={styles.loading} />
        </View>
      ) : (
        <View style={styles.container}>
          <ErrorText>{translate("Modules.Details.noModule")}</ErrorText>
        </View>
      );

    return (
      <View style={styles.container}>
        <Text style={styles.title}>
          {translate("Sensors.Details.connectedTo")}
        </Text>

        <ModuleCompactCard
          module={module}
          onPress={goToModule}
          description={translate("Sensors.Details.pin", {
            number: connectedToPin,
          })}
        />
      </View>
    );
  },
);
