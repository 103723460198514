import { useStores } from "@models/index";
import { useFocusEffect } from "@react-navigation/native";

const SENSORS_INTERVAL_MS = 10 * 1000;

export const useSensor = (sensorUid?: string) => {
  const { sensorStore } = useStores();

  const sensor = sensorStore.getSensor(sensorUid);

  const fetchData = async () => {
    await sensor.fetchData();
  };

  useFocusEffect(() => {
    const dataInterval = sensor
      ? setInterval(() => fetchData(), SENSORS_INTERVAL_MS)
      : undefined;
    return () => clearInterval(dataInterval);
  });

  return { sensor, isLoading: sensorStore.isLoading };
};
