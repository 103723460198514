import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";

import { ModuleActivityType } from "@models/module/module";
import { Text } from "@components/ui";

import dayjs from "dayjs";
import { useAppTheme } from "../../../../../hooks";
import { spacing } from "../../../../../theme";
import { DateTimeFormats } from "../../../../../utils/timeConverter";
import { translate, TxKeyPath } from "../../../../../i18n";

const createStyles = (theme: any) =>
  StyleSheet.create({
    container: {
      padding: spacing[4],
      paddingVertical: spacing[3],
      marginBottom: spacing[2],
      backgroundColor: theme.colors.cardBG,
      borderRadius: spacing[4],
    },
    card: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    startDate: {
      fontSize: 12,
      lineHeight: 16,
      color: theme.colors.subtitle,
    },
    badges: {
      flexDirection: "row",
    },
    badge: {
      borderRadius: spacing[4],
      paddingHorizontal: spacing[3],
      paddingVertical: spacing[1],
      backgroundColor: theme.colors.cardStateBG,
      lineHeight: 16,
      fontSize: 14,
      marginLeft: spacing[2],
      ...theme.fonts.medium,
    },
    badgePowerOn: {
      backgroundColor: theme.colors.badgeOn,
      color: theme.colors.background,
    },
    badgeOnline: {
      backgroundColor: theme.colors.badgeOn,
      color: theme.colors.background,
    },
    badgeOffline: {
      backgroundColor: theme.colors.warning,
      color: theme.colors.background,
    },
    badgeRT: {
      backgroundColor: theme.colors.palette.Yellow,
    },
  });

type ModuleActivityHistoryCardProps = {
  moduleState: ModuleActivityType;
  timestamp: Date;
  details?: string;
};

export const ModuleActivityHistoryCard = ({
  moduleState,
  timestamp,
  details,
}: ModuleActivityHistoryCardProps) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <View style={styles.container}>
      <View style={styles.card}>
        <Text style={styles.startDate}>
          {dayjs(timestamp).format(DateTimeFormats.TimeHMS)}
        </Text>

        <View style={styles.badges}>
          <Text
            style={[
              styles.badge,
              moduleState === ModuleActivityType.PowerOn && styles.badgePowerOn,
              moduleState === ModuleActivityType.Online && styles.badgeOnline,
              moduleState === ModuleActivityType.Offline && styles.badgeOffline,
              moduleState === ModuleActivityType.RebootTechnical &&
                styles.badgeRT,
            ]}
          >
            {translate(`Modules.History.states.${moduleState}` as TxKeyPath, {
              defaultValue: moduleState,
            })}
          </Text>
        </View>
      </View>

      {details ? <Text>{details}</Text> : null}
    </View>
  );
};
