import React, { useMemo } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import { useStores } from "@models/index";
import { Label, TimeInputPopup } from "@components/inputs";

import { spacing } from "../../../../../../theme";
import { useAppTheme } from "../../../../../../hooks";
import {
  dateStringToTimeProps,
  TimeProps,
  timePropsToDateString,
} from "../../../../../../utils/timeConverter";

const createStyles = (theme: any) =>
  StyleSheet.create({
    container: {
      marginBottom: spacing[4],
      padding: spacing[4],
      paddingBottom: spacing[3],
      borderRadius: spacing[4],
      borderWidth: 1,
      borderColor: theme.colors.menuBorder,
    },
    columns: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginHorizontal: -spacing[2],
    },
    column: {
      flex: 1,
      marginHorizontal: spacing[2],
    },
  });

type WorkingTimeData = {
  startDayTime?: string;
  endDayTime?: string;
};

type Props = {
  startDayTime?: string | undefined;
  endDayTime?: string | undefined;
  onChangeStartAt?: (value: string) => void;
  onChangeEndAt?: (value: string) => void;
  errors?: WorkingTimeData;
  style?: StyleProp<ViewStyle>;
  disabled?: boolean;
};

export const WorkingTimeInput: React.VFC<Props> = ({
  startDayTime,
  endDayTime,
  onChangeStartAt,
  onChangeEndAt,
  errors = {},
  style,
  disabled = false,
}) => {
  const theme = useAppTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);
  const { settingsStore } = useStores();

  const onChangeStartAtHandler = (timeProps: TimeProps) => {
    onChangeStartAt(timePropsToDateString(timeProps));
  };
  const onChangeEndAtHandler = (timeProps: TimeProps) => {
    onChangeEndAt(timePropsToDateString(timeProps));
  };

  return (
    <View style={[styles.container, style]}>
      <Label tx="Devices.Rules.workingTime" size="large" />
      <View style={styles.columns}>
        <View style={styles.column}>
          <TimeInputPopup
            onChangeSubmit={onChangeStartAtHandler}
            value={dateStringToTimeProps(startDayTime)}
            timeSystem={settingsStore.currentTimeSystem}
            txLabel="Devices.Rules.from"
            errorText={errors.startDayTime}
            disabled={disabled}
          />
        </View>
        <View style={styles.column}>
          <TimeInputPopup
            onChangeSubmit={onChangeEndAtHandler}
            value={dateStringToTimeProps(endDayTime)}
            timeSystem={settingsStore.currentTimeSystem}
            txLabel="Devices.Rules.to"
            errorText={errors.endDayTime}
            disabled={disabled}
          />
        </View>
      </View>
    </View>
  );
};
