import React, { useEffect, useState } from "react";
import { StyleSheet } from "react-native";
import { Text } from "react-native-paper";

import { useStores } from "@models/index";
import { Screen } from "@components/index";
import { LayoutCenter, TopBar } from "@components/layout";
import { Button, ErrorText, Heading, Form } from "@components/ui";
import { PasswordInput } from "@components/inputs";

import {
  passwordValidator,
  confirmPasswordValidator,
} from "../../utils/validator";
import { spacing } from "../../theme";
import { translate } from "../../i18n";

const styles = StyleSheet.create({
  screen: {
    padding: spacing[4],
  },
  heading: {
    marginTop: spacing[8],
  },
  enterEmailText: {
    marginBottom: spacing[6],
  },
  form: {
    flex: 1,
    height: 400,
    alignContent: "space-between",
  },
  buttonSetPassword: {
    marginTop: spacing[4],
  },
});

export function SetNewPasswordScreen({ navigation, route }) {
  const [formData, setFormData] = useState({
    password: undefined,
    confirmPassword: undefined,
  });
  const [errors, setErrors] = useState({
    password: undefined,
    confirmPassword: undefined,
  });
  const [error, setError] = useState("");

  const { accountStore } = useStores();
  const { recoveryToken } = route.params;
  const [email, code] = (recoveryToken || "").split("|");

  useEffect(() => {
    if (!recoveryToken) navigation.navigate("ForgotPassword");
  }, [recoveryToken, navigation]);

  const setPasswordHandler = async () => {
    const newErrors = {
      password: passwordValidator(formData.password),
      confirmPassword: confirmPasswordValidator(
        formData.password,
        formData.confirmPassword,
      ),
    };
    setErrors(newErrors);

    const hasErrors = Object.values(newErrors).join("").length;
    if (hasErrors) return null;

    const result = await accountStore.recoveryChangePassword({
      code,
      email,
      password: formData.password,
      confirmPassword: formData.confirmPassword,
    });

    if (result.kind === "ok") {
      navigation.navigate("PasswordRecoverySuccess");
    } else {
      const errorDisplay =
        result.errors?.join("\n\r") || translate("errorScreen.somethingWrong");
      setError(errorDisplay);
    }

    return null;
  };

  const setFormDataHandler = (key, value) => {
    setErrors({ ...errors, [key]: undefined });
    setFormData({ ...formData, [key]: value });
  };

  return (
    <Screen testID="SetNewPassword" header={<TopBar back title="" />} keyboard>
      <LayoutCenter style={styles.screen}>
        <Heading
          style={styles.heading}
          tx="Auth.SetNewPasswordScreen.heading"
        />
        <Text style={styles.enterEmailText}>
          {translate("Auth.SetNewPasswordScreen.typeNewPassword")}
        </Text>
        {error.length > 0 ? <ErrorText>{error}</ErrorText> : null}

        <Form.Container style={styles.form}>
          <Form.Inputs>
            <PasswordInput
              value={formData.password}
              errorText={errors.password}
              onChangeText={(value) => setFormDataHandler("password", value)}
            />
            <PasswordInput
              txLabel="fields.confirmPassword"
              value={formData.confirmPassword}
              errorText={errors.confirmPassword}
              onChangeText={(value) =>
                setFormDataHandler("confirmPassword", value)
              }
            />
          </Form.Inputs>
          <Button
            style={styles.buttonSetPassword}
            type="primary"
            onPress={setPasswordHandler}
            tx="Auth.SetNewPasswordScreen.setPassword"
          >
            Set password
          </Button>
        </Form.Container>
      </LayoutCenter>
    </Screen>
  );
}
