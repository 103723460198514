import React, { useMemo } from "react";
import { StyleSheet, Platform } from "react-native";
import { useTheme } from "@react-navigation/native";
import { Dialog as PaperDialog, Portal } from "react-native-paper";

import { translate } from "../../i18n";
import { spacing } from "../../theme";
import { Button, ButtonType } from "./Button";
import { Text } from "./Text";

const paddingBottom = Platform.OS === "ios" ? 48 : spacing[4];
const createStyles = (theme: any) =>
  StyleSheet.create({
    drawer: {
      borderTopRightRadius: spacing[4],
      borderTopLeftRadius: spacing[4],
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      position: "absolute",
      bottom: Platform.OS !== "web" ? -spacing[7] : spacing[0],
      paddingBottom: Platform.OS === "web" ? spacing[0] : paddingBottom,
      width: "100%",
      left: -26,
    },
    content: {
      fontSize: 16,
      color: theme.colors.label,
    },
    actions: {
      justifyContent: "space-around",
      paddingBottom: spacing[4],
      paddingHorizontal: spacing[2],
      flex: 1,
      alignItems: "flex-end",
    },
    button: {
      flex: 1,
      marginVertical: spacing[0],
      marginHorizontal: spacing[2],
    },
  });

export interface DrawerProps {
  content?: string;
  visible?: boolean;
  onCancel?: any;
  onOk?: any;
  onDismiss?: any;
  okType?: ButtonType;
  cancelType?: ButtonType;
  okText?: string;
  cancelText?: string;
  children?: React.ReactNode;
  style?: any;
}

export function Drawer(props: DrawerProps) {
  const {
    content,
    visible = false,
    onCancel,
    onOk,
    onDismiss,
    okType = "primary",
    cancelType = "ghost",
    okText = translate("common.ok"),
    cancelText = translate("common.cancel"),
    children = null,
    style,
  } = props;
  const theme = useTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <Portal>
      <PaperDialog
        style={[styles.drawer, style]}
        visible={visible}
        onDismiss={onCancel || onDismiss}
      >
        <PaperDialog.Content>
          {content ? <Text style={styles.content}>{content}</Text> : children}
        </PaperDialog.Content>

        <PaperDialog.Actions style={styles.actions}>
          {onCancel ? (
            <Button type={cancelType} style={styles.button} onPress={onCancel}>
              {cancelText}
            </Button>
          ) : null}
          {onOk ? (
            <Button type={okType} style={styles.button} onPress={onOk}>
              {okText}
            </Button>
          ) : null}
        </PaperDialog.Actions>
      </PaperDialog>
    </Portal>
  );
}
