import React from "react";
import { G, Line, Text as SvgText } from "react-native-svg";

import { palette } from "../../../theme";
import { apx } from "../utils";

const FONT_SIZE = 20;
const LENGTH_VALUE = 5;
const OFFSET_LEFT_VALUE = 10;

export const TooltipDiagram = (props: any) => {
  const { data, positionX, width, height, offsetScaleY } = props;
  if (positionX < 0) {
    return null;
  }

  const indexData = data.findIndex(
    (item) =>
      positionX >= item.value && positionX <= item.value + item.duration,
  );

  const text = data[indexData]?.label ? data[indexData].label : "";
  const xText =
    positionX < width / 2
      ? OFFSET_LEFT_VALUE
      : -1 * (text.length * LENGTH_VALUE + OFFSET_LEFT_VALUE);

  return (
    <G key="tooltip">
      <G x={positionX}>
        <G x={xText} y={40}>
          <SvgText fontSize={apx(FONT_SIZE)} fontWeight="bold">
            {text}
          </SvgText>
        </G>
        <G>
          <Line
            y1={offsetScaleY}
            y2={height - offsetScaleY}
            stroke={palette.GrayBlueLight}
            strokeWidth={apx(2)}
            strokeDasharray={[6, 3]}
          />
        </G>
      </G>
    </G>
  );
};
