import { useEffect, useRef } from "react";
import { Platform } from "react-native";
import * as Device from "expo-device";
import * as Notifications from "expo-notifications";
import Constants from "expo-constants";

import { rnLogger } from "./logger";

const NOTIFICATIONS_GRANTED = "granted";

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: true,
  }),
});

const checkPermissionsForPushNotifications = async (): Promise<boolean> => {
  const { status: existingStatus } = await Notifications.getPermissionsAsync();
  let finalStatus = existingStatus;

  if (existingStatus !== NOTIFICATIONS_GRANTED) {
    const { status } = await Notifications.requestPermissionsAsync();
    finalStatus = status;
  }

  if (finalStatus !== NOTIFICATIONS_GRANTED) {
    rnLogger.error("Failed to get push token for push notification!", {
      action: "register_push_token",
    });
    return false;
  }

  return true;
};

export const useNotificationHandler = (
  goTo: (event: Notifications.NotificationResponse) => void,
) => {
  const notificationListener = useRef<Notifications.Subscription>();

  useEffect(() => {
    notificationListener.current =
      Notifications.addNotificationResponseReceivedListener(goTo);

    return () => {
      Notifications.removeNotificationSubscription(
        notificationListener.current,
      );
    };
  }, []);
};

export const registerForPushNotificationsAsync = async (): Promise<
  string | null
> => {
  if (!Device.isDevice) {
    rnLogger.error("Must use physical device for Push Notifications", {
      action: "register_push_token",
    });
    return null;
  }

  const havePermissions = await checkPermissionsForPushNotifications();
  if (!havePermissions) {
    return null;
  }

  const token = await Notifications.getExpoPushTokenAsync({
    projectId: Constants.expoConfig.extra.eas.projectId,
  });

  if (Platform.OS === "android") {
    Notifications.setNotificationChannelAsync("default", {
      name: "default",
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: "#FF231F7C",
    });
  }

  return token.data;
};
