import React from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { shadow } from "react-native-paper";
import { useTheme } from "@react-navigation/native";
import { LinearGradient } from "expo-linear-gradient";

import { Text } from "./Text";
import { palette, spacing } from "../../theme";

const styles = StyleSheet.create({
  container: {
    borderRadius: spacing[4],
    flexDirection: "row",
    alignItems: "center",
    maxWidth: "100%",
    ...(shadow(4) as any),
  },
  containerText: {
    paddingHorizontal: spacing[2],
  },
  containerIcon: {
    padding: spacing[1],
  },
  containerIconText: {
    marginRight: spacing[1],
  },
  text: {
    lineHeight: 20,
    fontSize: 12,
    letterSpacing: 1,
    textAlign: "center",
  },
});

type Props = {
  icon?: React.FunctionComponent<any>;
  iconSize?: number;
  text?: string;
  style?: StyleProp<ViewStyle>;
  children?: React.ReactNode;
  gradientColors?: string[];
};

const Badge: React.FunctionComponent<Props> = ({
  text,
  icon: Icon,
  iconSize = 24,
  children,
  style,
  gradientColors,
}) => {
  const { colors, fonts } = useTheme() as any;
  const themeTextStyle = {
    ...fonts.medium,
    color: colors.palette.White,
  };

  const badgeColors = gradientColors || [palette.RedG1, palette.RedG2];
  if (Icon && !text) {
    return (
      <LinearGradient
        colors={badgeColors}
        style={[styles.container, styles.containerIcon, style]}
        start={{ x: 0.25, y: 0.25 }}
        end={{ x: 0.75, y: 0.75 }}
      >
        <Icon color={colors.palette.White} size={iconSize} />
      </LinearGradient>
    );
  }

  return (
    <LinearGradient
      colors={badgeColors}
      style={[styles.container, styles.containerText, style]}
      start={{ x: 0.25, y: 0.25 }}
      end={{ x: 0.75, y: 0.75 }}
    >
      {text ? (
        <>
          {Icon && (
            <View style={styles.containerIconText}>
              <Icon color={colors.palette.White} size={14} />
            </View>
          )}
          <Text
            style={[styles.text, themeTextStyle]}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {text}
          </Text>
        </>
      ) : (
        children
      )}
    </LinearGradient>
  );
};

export default Badge;
