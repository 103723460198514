import { ApiResponse } from "apisauce";
import { GeneralApiProblem, getGeneralApiProblem } from "./api-problem";
import { translate, TxKeyPath } from "../../i18n";

export type ApiProblem = {
  kind: "error";
  errors?: string[];
  errorCode?: number;
  problem?: GeneralApiProblem;
};
export type GeneralResult = { kind: "ok"; data: any } | ApiProblem;

function getErrors(response: ApiResponse<any>, problem?: GeneralApiProblem) {
  if (response.data?.errors) {
    return Object.values(response.data?.errors).flatMap((x) => x.toString());
  }
  if (response.data?.error) {
    return [response.data.error as string];
  }
  if (response.data?.detail) {
    return [response.data.detail as string];
  }

  if (problem?.kind) {
    return [translate(`errors.${problem.kind}` as TxKeyPath)];
  }

  return [translate("errorScreen.somethingWrong")];
}

export function processResponse(
  response: ApiResponse<any>,
  dataProcessor?: (data: any) => any,
): GeneralResult {
  if (response.ok) {
    return {
      kind: "ok",
      data: dataProcessor?.(response.data || {}) || response.data,
    };
  }

  const problem = getGeneralApiProblem(response);

  return {
    kind: "error",
    errors: getErrors(response, problem),
    errorCode: response.status,
    problem,
  };
}
