import { ApiResponse } from "apisauce";

import { ApiProblem, processResponse } from "./response-resolver";
import { Api } from "./api";

const SERVICE_PATH = "/Services/Notifications/api/v1";

const PUSH_NOTIFICATIONS_TOKEN_ADD_PATH = "/Accounts/push_token";
const PREFERENCES_PATH = "/Accounts/preferences";

const NOTIFICATIONS_PATH = "/Notifications";
const NOTIFICATION_TYPES_PATH = "/NotificationTypes";

type AddPushNotificationsTokenResult = { kind: "ok" } | ApiProblem;
type getAnyResult = { kind: "ok"; data: any } | ApiProblem;

const getOriginFromUrl = (url: string): string | null => {
  const regex =
    /^((https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)(:\d+)?).*$/i;
  const match = url.match(regex);

  return match ? match[1] : null;
};

export class NotificationsApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  getBaseUrl(): string {
    const origin = getOriginFromUrl(this.api.apisauce.getBaseURL());
    return `${origin}${SERVICE_PATH}`;
  }

  async addPushNotificationsToken({
    token,
  }: {
    token: string;
  }): Promise<AddPushNotificationsTokenResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(
        PUSH_NOTIFICATIONS_TOKEN_ADD_PATH,
        { token },
        { baseURL: this.getBaseUrl() },
      );
      return processResponse(response);
    } catch (e) {
      return { kind: "error" };
    }
  }

  async fetchNotifications({
    pageNumber = 1,
    pageSize = 30,
    types = [],
    severityLevels = [],
    isOpened = null,
    startDate = null,
    endDate = null,
  } = {}): Promise<getAnyResult> {
    const params = [];

    if (types?.length) {
      params.push(...types.map((t) => `Types=${t}`));
    }
    if (severityLevels?.length) {
      params.push(...severityLevels.map((sl) => `SeverityLevels=${sl}`));
    }
    if (isOpened === true || isOpened === false) {
      params.push(`isOpened=${isOpened}`);
    }
    if (startDate) {
      params.push(`StartDate=${startDate}`);
    }
    if (endDate) {
      params.push(`EndDate=${endDate}`);
    }

    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `${NOTIFICATIONS_PATH}?PageSize=${pageSize}&PageNumber=${pageNumber}&${params.join(
          "&",
        )}`,
        undefined,
        { baseURL: this.getBaseUrl() },
      );
      return processResponse(response);
    } catch (e) {
      return { kind: "error" };
    }
  }

  async fetchNotificationDetails(uid: string): Promise<getAnyResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `${NOTIFICATIONS_PATH}/${uid}`,
        undefined,
        { baseURL: this.getBaseUrl() },
      );
      return processResponse(response);
    } catch (e) {
      return { kind: "error" };
    }
  }

  async getNotificationTypes(): Promise<getAnyResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        NOTIFICATION_TYPES_PATH,
        undefined,
        { baseURL: this.getBaseUrl() },
      );
      return processResponse(response);
    } catch (e) {
      return { kind: "error" };
    }
  }

  async getNotificationsPreferences(): Promise<getAnyResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        PREFERENCES_PATH,
        undefined,
        { baseURL: this.getBaseUrl() },
      );
      return processResponse(response);
    } catch (e) {
      return { kind: "error" };
    }
  }

  async saveNotificationsPreferences(preferences: any): Promise<any> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.put(
        PREFERENCES_PATH,
        preferences,
        { baseURL: this.getBaseUrl() },
      );
      return processResponse(response);
    } catch (e) {
      return { kind: "error" };
    }
  }
}
