import { StyleSheet, View } from "react-native";
import { shadow } from "react-native-paper";

import { SENSOR_STATE_TEXT, SensorState } from "@models/index";

import { spacing } from "../../../../../theme";
import { SensorCardView } from "../../components/SensorCard";

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    borderRadius: spacing[4],
    minWidth: "45%",
    top: 76,
    left: 16,
    ...(shadow(5) as any),
  },
});

const sensor = {
  uid: "SensorUnusualReading",
  type: "air_temp",
  displayType: "Air temperature",
  name: "Temperature 1",
  displayName: "Temperature 1",
  value: 35,
  displayValue: 35,
  unitName: "\u00B0C",
  iconName: "AirTemperatureIcon",
  isOutOfRange: false,
  needCalibration: true,
  translatedState: SENSOR_STATE_TEXT[SensorState.NeedCalibration],
};

export const SensorCalibration = () => (
  <View style={styles.container}>
    <SensorCardView sensor={sensor as any} isDisabledTitle isDisabledValue />
  </View>
);
