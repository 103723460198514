import React, { useMemo } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";

import { Rule, RuleModel } from "@models/rule/rule";
import { Text } from "@components/ui";
import { DeviceRuleDetails } from "@screens/Devices/Details/components";
import { useStores } from "@models/root-store/root-store-context";

import { useAppTheme } from "../../../../../../hooks";
import { spacing } from "../../../../../../theme";
import CopyIcon from "../../../../../../svgs/CopyIcon";
import { translate } from "../../../../../../i18n";

const createStyles = (theme: any) =>
  StyleSheet.create({
    card: {
      borderRadius: spacing[4],
      marginBottom: spacing[4],
      width: "100%",
      borderWidth: 1,
      borderColor: theme.colors.menuBorder,
      overflow: "hidden",
    },
    titleContainer: {
      backgroundColor: theme.colors.cardBG,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingHorizontal: spacing[3],
    },
    title: {
      flex: 1,
      fontSize: 16,
      paddingVertical: spacing[3],
      ...theme.fonts.medium,
    },
    details: {
      borderWidth: 0,
    },
    description: {
      color: theme.colors.label,
      marginBottom: spacing[3],
    },
  });

type RuleSelectorProps = {
  rules: Rule[];
  deviceType: string;
  onPress: (type) => void;
};

export const RuleSelector = ({
  rules,
  deviceType,
  onPress,
}: RuleSelectorProps) => {
  const theme = useAppTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);
  const rootStore = useStores();

  const filteredRules = rules.filter(
    (rule) => rule.device?.type === deviceType,
  );

  return (
    <View>
      <Text style={styles.description}>
        {translate("Devices.Rules.Copy.selectCopyRule")}
      </Text>

      {filteredRules.map((rule) => (
        <TouchableOpacity
          key={rule.uid}
          style={styles.card}
          onPress={() => onPress(rule)}
        >
          <View style={styles.titleContainer}>
            <Text style={styles.title} numberOfLines={1}>
              {rule.device?.displayName}
            </Text>
            <CopyIcon />
          </View>
          <DeviceRuleDetails
            rule={RuleModel.create(rule, {
              ...rootStore.environment,
              rootStore,
            })}
            style={styles.details}
          />
        </TouchableOpacity>
      ))}
    </View>
  );
};
