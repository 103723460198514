import { Pressable, StyleSheet, ViewStyle } from "react-native";

import { Label } from "@components/inputs";
import { IconButton } from "@components/common";

import { spacing } from "../../../../../../theme";
import AddIcon from "../../../../../../svgs/menu/AddIcon";
import { useAppTheme } from "../../../../../../hooks";

const createStyles = (theme) =>
  StyleSheet.create({
    container: {
      borderWidth: 1,
      borderColor: theme.colors.menuBorder,
      borderRadius: spacing[4],
      padding: spacing[2],
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      marginTop: spacing[2],
      marginBottom: spacing[4],
    },
  });

type AddScheduleRuleButtonProps = {
  style?: ViewStyle;
  onPress?: () => void;
};

export const AddScheduleRuleButton = ({
  style,
  onPress,
}: AddScheduleRuleButtonProps) => {
  const theme = useAppTheme() as any;
  const styles = createStyles(theme);

  return (
    <Pressable style={[styles.container, style]} onPress={onPress}>
      <Label tx="Devices.Rules.addScheduleRule" />
      <IconButton
        icon={AddIcon}
        size={32}
        iconColor={theme.colors.primary}
        onPress={onPress}
      />
    </Pressable>
  );
};
