import { useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";

import {
  ConnectingStatus,
  ModuleConnection,
} from "@models/module/module-connection";
import { Button, Dialog, MarkdownContent, Text } from "@components/ui";
import { getPageDataById } from "@screens/Pages/PagesData";

import { translate } from "../../../../../i18n";
import { spacing } from "../../../../../theme";

const styles = StyleSheet.create({
  container: {
    marginTop: spacing[4],
  },
  button: {
    marginHorizontal: spacing[3],
    marginBottom: spacing[4],
    width: "auto",
  },
  buttonLabel: {
    fontSize: 14,
    marginHorizontal: spacing[1],
  },
  logsText: {
    fontSize: 12,
  },
  logsContainer: {
    maxHeight: 400,
    padding: 4,
  },
  content: {
    maxHeight: 400,
    marginHorizontal: -spacing[3],
    paddingRight: spacing[2],
  },
});

type Props = {
  moduleConnection: ModuleConnection;
};
export const ModuleConnectControls = ({ moduleConnection }: Props) => {
  const [reasonsVisibility, setReasonsVisibility] = useState(false);
  const [logsVisibility, setLogsVisibility] = useState(false);

  const toggleLogs = () => {
    setLogsVisibility((prevState) => !prevState);
  };

  const showReasons = () => {
    setReasonsVisibility(true);
  };
  const hideReasons = () => {
    setReasonsVisibility(false);
  };

  const onPressOk = () => {
    moduleConnection.prevStep();
    // moduleConnection.reset();
  };

  const connectionFailureReasonsPage = getPageDataById(
    "reasons-for-connection-failure",
  );

  return (
    <View style={styles.container}>
      {moduleConnection.status === ConnectingStatus.Error && (
        <>
          <Button
            style={styles.button}
            type="primary"
            onPress={showReasons}
            labelStyle={styles.buttonLabel}
          >
            {translate("Modules.update.reasonsForConnectionFailure")}
          </Button>
          <Button type="ghost" style={styles.button} onPress={onPressOk}>
            {translate("common.ok")}
          </Button>
        </>
      )}

      <Dialog
        title={
          logsVisibility
            ? translate("Modules.update.logs")
            : translate("Modules.update.reasonsForConnectionFailure")
        }
        visible={reasonsVisibility}
        onOk={hideReasons}
        onCancel={toggleLogs}
        onDismiss={hideReasons}
        okText={translate("common.ok")}
        cancelText={
          logsVisibility
            ? translate("Modules.update.hideLogs")
            : translate("Modules.update.showLogs")
        }
      >
        {logsVisibility ? (
          <ScrollView style={styles.logsContainer}>
            <Text selectable style={styles.logsText}>
              {moduleConnection.logs}
            </Text>
          </ScrollView>
        ) : (
          <ScrollView style={styles.content}>
            <MarkdownContent>
              {connectionFailureReasonsPage?.content}
            </MarkdownContent>
          </ScrollView>
        )}
      </Dialog>
    </View>
  );
};
