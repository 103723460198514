import { useEffect, useState } from "react";

import { useStores } from "@models/index";

import { ModuleApi } from "../services/api";
import { translate } from "../i18n";

type UseModuleDiagnosticHistoryProps = {
  uid: string;
  startDate: string | undefined;
  endDate: string | undefined;
  limit?: number;
};

type TModuleDiagnosticResult = {
  isLoading: boolean;
  diagnosticHistory: any[];
  errors: string[] | null;
};

export const useModuleDiagnosticHistory = ({
  uid,
  startDate,
  endDate,
  limit,
}: UseModuleDiagnosticHistoryProps): TModuleDiagnosticResult => {
  const [diagnosticHistory, setDiagnosticHistory] = useState<Array<any> | []>(
    [],
  );
  const [errors, setErrors] = useState<string[] | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);

  const rootStore = useStores();
  const moduleApi = new ModuleApi(rootStore.environment.api);

  const getHistory = async () => {
    if (!uid || !startDate || !endDate) return;

    setLoading(true);
    const result = await moduleApi.getDiagnosticHistory({
      uid,
      startDate,
      endDate,
      limit,
    });
    setLoading(false);

    if (result.kind === "ok" && result.data) {
      setDiagnosticHistory(result.data);
      setErrors(null);
    }

    if (result.kind === "error") {
      setDiagnosticHistory([]);
      setErrors([translate("Sensors.History.errors.requestFailed")]);
    }
  };

  useEffect(() => {
    getHistory();
  }, [uid, startDate, endDate]);

  return {
    isLoading,
    diagnosticHistory,
    errors,
  };
};
