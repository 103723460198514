import React from "react";
import Svg, { Path } from "react-native-svg";
import { palette } from "../theme";

export default function DisconnectIcon({ color = palette.Gray, size = 24 }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 2.25C9.32282 2.25 9.60943 2.45657 9.71151 2.76283L11.3782 7.76283C11.5092 8.15579 11.2968 8.58053 10.9038 8.71151C10.5109 8.8425 10.0861 8.63013 9.95515 8.23717L9 5.37171L6.71151 12.2372C6.60943 12.5434 6.32282 12.75 6 12.75H2C1.58579 12.75 1.25 12.4142 1.25 12C1.25 11.5858 1.58579 11.25 2 11.25H5.45943L8.28849 2.76283C8.39057 2.45657 8.67718 2.25 9 2.25ZM17.2885 11.7628C17.3906 11.4566 17.6772 11.25 18 11.25H22C22.4142 11.25 22.75 11.5858 22.75 12C22.75 12.4142 22.4142 12.75 22 12.75H18.5406L15.7115 21.2372C15.6094 21.5434 15.3228 21.75 15 21.75C14.6772 21.75 14.3906 21.5434 14.2885 21.2372L12.6218 16.2372C12.4908 15.8442 12.7032 15.4195 13.0962 15.2885C13.4891 15.1575 13.9139 15.3699 14.0448 15.7628L15 18.6283L17.2885 11.7628Z"
        fill={color}
      />
      <Path
        d="M19.1426 5L5.00044 19.1421"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeDasharray="3 3"
      />
    </Svg>
  );
}
