import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { ScrollView, StyleSheet } from "react-native";

import { StackScreenProps } from "@react-navigation/stack";
import { useStores } from "@models/index";
import { TopBar, TopBarAlign } from "@components/layout";
import { FloatingButton, RadioItem } from "@components/ui";

import { Screen } from "../../../components";
import { SettingsNavigatorParamList } from "../navigation";
import { Language, TLanguage, translate } from "../../../i18n";
import { spacing } from "../../../theme";
import { goBack } from "../../../navigators";

const styles = StyleSheet.create({
  screen: {
    padding: spacing[4],
  },
  scroll: {
    marginBottom: spacing[8],
  },
});

export const LanguageScreen: React.FC<
  StackScreenProps<SettingsNavigatorParamList, "settings-notifications">
> = observer(() => {
  const { settingsStore } = useStores();
  const [language, setLanguage] = useState<Language | string>();

  useEffect(() => {
    setLanguage(settingsStore.currentLanguage);
  }, []);

  if (!language) return null;

  const onApply = () => {
    settingsStore.setLanguage(language);
    goBack();
  };

  return (
    <Screen
      style={styles.screen}
      header={
        <TopBar
          title={translate("Settings.language")}
          align={TopBarAlign.Left}
          back
        />
      }
    >
      <ScrollView style={styles.scroll}>
        {settingsStore.availableLanguages.map((lang: TLanguage) => (
          <RadioItem
            key={`lang-${lang.key}`}
            value={lang.key}
            label={lang.name}
            onPress={setLanguage}
            checked={language === lang.key}
          />
        ))}
      </ScrollView>

      <FloatingButton onPress={onApply}>
        {translate("common.apply")}
      </FloatingButton>
    </Screen>
  );
});
