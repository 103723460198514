import { useMemo } from "react";
import { StyleSheet } from "react-native";

import { useStores } from "@models/index";
import { NotificationChannel } from "@models/notifications/notifications-store";
import { SwitchInput } from "@components/inputs";

import { useAppTheme } from "../../../../../hooks";
import { spacing } from "../../../../../theme";
import { normalizeFontSize } from "../../../../../utils/sizes";
import { TxKeyPath, translate } from "../../../../../i18n";

const createStyles = (theme: any) =>
  StyleSheet.create({
    switch: {
      paddingHorizontal: spacing[4],
      paddingTop: spacing[4],
      paddingBottom: spacing[3],
      borderRadius: spacing[4],
      marginVertical: spacing[1],
      backgroundColor: theme.colors.cardBG,
    },
    switchLabel: {
      marginBottom: 0,
      color: theme.colors.text,
      fontSize: normalizeFontSize(16, 18),
      ...theme.fonts.medium,
    },
  });

type NotificationTypeSwitchProps = {
  notificationType: string;
  channelType: NotificationChannel;
};

export const NotificationTypeSwitch = ({
  notificationType,
  channelType,
}: NotificationTypeSwitchProps) => {
  const { notificationsStore } = useStores();
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  const onChange = () => {
    notificationsStore.preferences.toggle(notificationType, channelType);
  };

  const value = notificationsStore.preferences.enabled(
    notificationType,
    channelType,
  );

  const label = translate(
    `Settings.notifications.${channelType.toLowerCase()}` as TxKeyPath,
    {
      defaultValue: channelType,
    },
  );

  return (
    <SwitchInput
      label={label}
      onChange={onChange}
      value={value}
      containerStyle={styles.switch}
      labelStyle={styles.switchLabel}
    />
  );
};
