import React, { useMemo } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { ActivityIndicator } from "react-native-paper";

import { Heading } from "@components/ui/Heading";
import { Text } from "@components/ui/Text";

import { CheckmarkIcon, CrossIcon } from "../../svgs";
import { spacing } from "../../theme";
import { useAppTheme } from "../../hooks";

export enum CheckListStatus {
  Processing = "processing",
  Success = "success",
  Error = "error",
  Default = "default",
}

const createStyles = (theme: any) =>
  StyleSheet.create({
    container: {
      alignItems: "center",
      marginBottom: spacing[4],
    },
    heading: {
      fontSize: 16,
    },
    listContainer: {
      paddingRight: spacing[5],
    },
    itemContainer: {
      flexDirection: "row",
      marginBottom: spacing[2],
      maxWidth: "100%",
    },
    label: {
      fontSize: 14,
      color: theme.colors.label,
    },
    "label-default": {
      color: theme.colors.text,
    },
    "label-processing": {},
    "label-success": {
      color: theme.colors.primary,
    },
    "label-error": {
      color: theme.colors.error,
    },
    checkStatus: {
      marginHorizontal: spacing[3],
    },
    progress: {
      width: 20,
      height: 20,
      margin: 2,
    },
  });

type CheckListProps = {
  title?: string;
  items: CheckListItemProps[];
  style?: StyleProp<ViewStyle>;
};

/* eslint-disable react/no-array-index-key */
export const CheckList = ({ title, items, style }: CheckListProps) => {
  const theme = useAppTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <View style={[styles.container, style]}>
      {title && <Heading style={styles.heading}>{title}</Heading>}
      <View style={styles.listContainer}>
        {items.map((itemProps, index) => (
          <CheckListItem {...itemProps} key={`checklist-item-${index}`} />
        ))}
      </View>
    </View>
  );
};
/* eslint-enable react/no-array-index-key */

export type CheckListItemProps = {
  label: string;
  status?: CheckListStatus;
};
export const CheckListItem = ({
  label,
  status = CheckListStatus.Processing,
}: CheckListItemProps) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <View style={styles.itemContainer}>
      <View style={styles.checkStatus}>
        {status === CheckListStatus.Processing ? (
          <ActivityIndicator
            animating
            color={theme.colors.palette.Blue}
            style={styles.progress}
          />
        ) : (
          <ItemIcon status={status} />
        )}
      </View>
      <Text style={[styles.label, styles[`label-${status}`]]}>{label}</Text>
    </View>
  );
};

type ItemIconProps = {
  status: CheckListStatus;
};
const ItemIcon = ({ status }: ItemIconProps) => {
  const theme = useAppTheme() as any;

  if (status === CheckListStatus.Error) {
    return <CrossIcon color={theme.colors.error} />;
  }
  if (status === CheckListStatus.Success) {
    return <CheckmarkIcon color={theme.colors.palette.Green} />;
  }

  return <CheckmarkIcon color={theme.colors.text} />;
};
