import * as React from "react";
import Svg, { Path } from "react-native-svg";

const CopyIcon = ({ size = 24, color = "#4A6F84" }) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 3.25C5.27065 3.25 4.57118 3.53973 4.05546 4.05546C3.53973 4.57118 3.25 5.27065 3.25 6V14C3.25 14.7293 3.53973 15.4288 4.05546 15.9445C4.57118 16.4603 5.27065 16.75 6 16.75H7.25V18C7.25 19.5188 8.48122 20.75 10 20.75H18C19.5188 20.75 20.75 19.5188 20.75 18V10C20.75 8.48122 19.5188 7.25 18 7.25H16.75V6C16.75 5.27065 16.4603 4.57118 15.9445 4.05546C15.4288 3.53973 14.7293 3.25 14 3.25H6ZM15.25 7.25V6C15.25 5.66848 15.1183 5.35054 14.8839 5.11612C14.6495 4.8817 14.3315 4.75 14 4.75H6C5.66848 4.75 5.35054 4.8817 5.11612 5.11612C4.8817 5.35054 4.75 5.66848 4.75 6V14C4.75 14.3315 4.8817 14.6495 5.11612 14.8839C5.35054 15.1183 5.66848 15.25 6 15.25H7.25V10C7.25 8.48122 8.48122 7.25 10 7.25H15.25ZM8.75 16V18C8.75 18.6904 9.30964 19.25 10 19.25H18C18.6904 19.25 19.25 18.6904 19.25 18V10C19.25 9.30964 18.6904 8.75 18 8.75H16H10C9.30964 8.75 8.75 9.30964 8.75 10V16Z"
      fill={color}
    />
  </Svg>
);

export default CopyIcon;
