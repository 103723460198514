import { PixelRatio } from "react-native";

const fontScale = PixelRatio.getFontScale();

export const normalizeFontSize = (
  fontSize: number,
  maxFontSize?: number,
): number => {
  if (fontScale > 1) {
    return Math.round((maxFontSize || fontSize) / fontScale);
  }

  return fontSize;
};
