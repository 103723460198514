import React, { useCallback, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { observer } from "mobx-react-lite";
import { StackScreenProps } from "@react-navigation/stack";
import { useKeepAwake } from "expo-keep-awake";

import { useStores } from "@models/index";
import {
  MODULE_UPDATE_STEPS,
  UpdateStatus,
  UpdateStep,
} from "@models/module/module-update";
import { Screen } from "@components/index";
import { TopBar } from "@components/layout";
import {
  CheckList,
  CheckListItemProps,
  CheckListStatus,
  Text,
  ErrorsAlert,
} from "@components/ui";

import {
  ModuleSuccessSvg,
  ModuleWaitingSvg,
  WarningSvg,
} from "../../../svgs/pictures";
import { translate } from "../../../i18n";
import { DefaultTheme, spacing } from "../../../theme";
import { useHardwareBackPress } from "../../../hooks";
import { ModulesNavigatorParamList } from "../navigation";
import { UpdateCounter } from "./components/Counter";
import { UpdateControls } from "./components/Controls";

const styles = StyleSheet.create({
  screen: {
    padding: spacing[4],
  },
  image: {
    alignItems: "center",
    justifyContent: "center",
    minHeight: 250,
  },
  version: {
    fontSize: 16,
    textAlign: "center",
    marginTop: spacing[4],
  },
  error: {
    color: DefaultTheme.colors.error,
    textAlign: "center",
  },
});

const getSteps = (
  currentStep: UpdateStep,
  status: UpdateStatus,
): CheckListItemProps[] => {
  const index = MODULE_UPDATE_STEPS.findIndex(
    (step) => step.key === currentStep,
  );
  const currentStatus =
    status === UpdateStatus.Error || status === UpdateStatus.CanNotUpdate
      ? CheckListStatus.Error
      : CheckListStatus.Processing;

  return MODULE_UPDATE_STEPS.slice(0, index + 1).map((step, i) => ({
    ...step,
    status: i === index ? currentStatus : CheckListStatus.Success,
  }));
};

export const ModuleUpdateScreen: React.FC<
  StackScreenProps<ModulesNavigatorParamList, "module-update">
> = observer(({ navigation, route }) => {
  const { params = {} } = route as any;

  const { moduleStore, settingsStore } = useStores();
  const module = moduleStore.getModule(params?.uid);
  const [errors, setErrors] = useState<string[]>([]);
  const resetErrors = () => setErrors([]);

  if (!module) {
    navigation.navigate("modules-list");
    return null;
  }
  const moduleUpdate = module.update;

  const goToAfterEnd = useCallback(async () => {
    resetErrors();
    moduleUpdate.reset();
    await moduleStore.fetchModule(module.uid);

    if (params?.navigator) {
      navigation.navigate(params?.navigator, { uid: module.uid });
    } else {
      navigation.navigate("module-details", { uid: module.uid });
    }
  }, [navigation, module.uid]);

  const steps = getSteps(moduleUpdate.currentStep, moduleUpdate.status);

  useHardwareBackPress(goToAfterEnd);
  useKeepAwake();

  useEffect(() => {
    if (!moduleUpdate.status) {
      goToAfterEnd();
    }
  }, [moduleUpdate.status]);

  const backHandler = () => {
    if (moduleUpdate.status === UpdateStatus.Processing) {
      setErrors([translate("Modules.update.pleaseWaitForProcessToComplete")]);
    } else {
      goToAfterEnd();
    }
  };

  return (
    <Screen
      testID="ModuleUpdate"
      style={styles.screen}
      header={
        <TopBar
          title={translate("Modules.update.title")}
          back
          backHandler={backHandler}
        />
      }
    >
      <View style={styles.image}>
        <StatusImage status={moduleUpdate.status} />
      </View>
      {!moduleUpdate.currentStep && (
        <>
          <Text style={styles.version}>
            {translate("Modules.update.currentVersion", {
              version: module.currentFirmwareVersion,
            })}
          </Text>
          <Text style={styles.version}>
            {translate("Modules.update.versionForUpdate", {
              version: settingsStore.latestFWVersion,
            })}
          </Text>
        </>
      )}

      {moduleUpdate.currentStep && (
        <CheckList
          title={translate("Modules.update.stepsTitle")}
          items={steps}
        />
      )}

      {moduleUpdate.currentStep === UpdateStep.Rebooting && (
        <UpdateCounter update={moduleUpdate} />
      )}
      {moduleUpdate.error && (
        <Text style={styles.error}>{moduleUpdate.error}</Text>
      )}

      <UpdateControls module={module} reset={moduleUpdate.reset} />

      <ErrorsAlert errors={errors} onCancel={resetErrors} title="" />
    </Screen>
  );
});

const StatusImage = ({ status }: { status: UpdateStatus }) => {
  if (status === UpdateStatus.Error) {
    return <WarningSvg />;
  }
  if (status === UpdateStatus.Success) {
    return <ModuleSuccessSvg />;
  }
  return <ModuleWaitingSvg />;
};
