import React, { useMemo } from "react";
import { StyleSheet } from "react-native";
import { useTheme } from "@react-navigation/native";
import { Appbar } from "react-native-paper";

import { useHardwareBackPress } from "../../hooks";
import { ChevronDownIcon } from "../../svgs";
// import { BackgroundGradient } from "../ui";
import { TopBarType } from "./TopBar";

const createStyles = (theme: any) =>
  StyleSheet.create({
    header: {
      marginTop: 0,
      elevation: 0,
      zIndex: 99,
      backgroundColor: theme.colors.background,
      justifyContent: "space-between",
    },
    back: {
      zIndex: 5,
    },
    content: {
      marginLeft: 0,
      position: "absolute",
      left: 0,
      right: 0,
      zIndex: 0,
    },
    title: {
      fontSize: 20,
      color: theme.colors.heading,
      textAlign: "center",
    },
  });

type SelectorBarProps = {
  title: string;
  onApply?: () => void;
  onClose?: () => void;
  type?: TopBarType;
};
export const SelectorBar: React.FunctionComponent<SelectorBarProps> = ({
  title,
  onApply,
  onClose,
  type = TopBarType.Default,
}) => {
  const theme = useTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);

  const colors = {
    [TopBarType.Default]: theme.colors.heading,
    [TopBarType.Disabled]: theme.colors.heading,
    [TopBarType.Active]: theme.colors.card,
  };
  const color = colors[type];

  useHardwareBackPress(onClose);

  return (
    <Appbar.Header statusBarHeight={0} style={styles.header}>
      {/* {type === TopBarType.Active && <BackgroundGradient />} */}
      {onClose ? (
        <Appbar.BackAction
          style={styles.back}
          color={color}
          onPress={onClose}
        />
      ) : null}
      <Appbar.Content
        style={styles.content}
        title={title}
        titleStyle={[styles.title, { color }]}
      />
      {onApply ? (
        <Appbar.Action icon={ChevronDownIcon} onPress={onApply} color={color} />
      ) : null}
    </Appbar.Header>
  );
};
