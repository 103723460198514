import Svg, {
  Circle,
  Defs,
  LinearGradient,
  Path,
  Rect,
  Stop,
} from "react-native-svg";
import React from "react";

export const Router24GHzSvg = () => (
  <Svg width="360" height="212" viewBox="0 0 360 212" fill="none">
    <Circle cx="179" cy="106" r="106" fill="url(#paint0_linear_377_14324)" />
    <Path
      d="M138.961 115.029L95.8277 43.5307C94.6013 41.4978 91.9733 40.8207 89.9172 42.0078C87.8667 43.1916 87.1366 45.7959 88.2727 47.8731L128.341 121.133C129.416 123.097 131.476 124.319 133.716 124.319C138.484 124.319 141.424 119.112 138.961 115.029Z"
      fill="#B0EACC"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M221.976 115.029L265.109 43.5307C266.336 41.4978 268.964 40.8207 271.02 42.0078C273.07 43.1916 273.8 45.7959 272.664 47.8731L232.596 121.133C231.521 123.097 229.461 124.319 227.221 124.319C222.453 124.319 219.513 119.112 221.976 115.029Z"
      fill="#B0EACC"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M96.3534 136.581C96.3534 125.535 105.308 116.581 116.353 116.581H243.437C254.483 116.581 263.437 125.535 263.437 136.581V138.156C263.437 149.201 254.483 158.156 243.437 158.156H116.353C105.308 158.156 96.3534 149.201 96.3534 138.156V136.581Z"
      fill="white"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Rect
      x="115.18"
      y="131.485"
      width="73.7365"
      height="11.7665"
      rx="5.88323"
      fill="#B0EACC"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Circle
      cx="216.371"
      cy="136.976"
      r="6.27545"
      fill="#B0EACC"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Circle
      cx="238.336"
      cy="136.976"
      r="6.27545"
      fill="#B0EACC"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M118.317 158.156H154.401C154.401 162.488 150.889 166 146.557 166H126.162C121.829 166 118.317 162.488 118.317 158.156Z"
      fill="#B0EACC"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M206.958 158.156H243.042C243.042 162.488 239.53 166 235.198 166H214.802C210.47 166 206.958 162.488 206.958 158.156Z"
      fill="#B0EACC"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M183.845 101.17C182.839 102.163 181.512 102.717 180.094 102.717C178.676 102.717 177.349 102.163 176.343 101.17C175.338 100.178 174.784 98.8375 174.784 97.4327C174.784 96.0278 175.338 94.6874 176.343 93.6949C177.349 92.7025 178.676 92.1483 180.094 92.1483C181.512 92.1483 182.839 92.7025 183.845 93.6949C184.85 94.6874 185.404 96.0278 185.404 97.4327C185.404 98.8375 184.85 100.165 183.845 101.17Z"
      fill="#308AE3"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M201.167 88.7844C199.994 89.9444 198.46 90.5888 196.798 90.5888C195.148 90.5888 193.601 89.9444 192.428 88.7844C189.142 85.4978 184.76 83.6805 180.094 83.6805C175.441 83.6805 171.046 85.4849 167.759 88.7844C166.587 89.9444 165.04 90.5888 163.39 90.5888C161.74 90.5888 160.194 89.9444 159.021 88.7844C157.848 87.6115 157.217 86.0778 157.217 84.428C157.217 82.7912 157.861 81.2316 159.021 80.0717C164.64 74.4522 172.129 71.3718 180.081 71.3718C188.033 71.3718 195.522 74.4651 201.141 80.0717C202.314 81.2316 202.945 82.7783 202.945 84.428C202.971 86.0649 202.327 87.6115 201.167 88.7844Z"
      fill="#308AE3"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M214.043 75.9344C212.87 77.0944 211.323 77.7388 209.673 77.7388C208.024 77.7388 206.477 77.0944 205.304 75.9344C198.576 69.2194 189.619 65.5203 180.094 65.5203C170.569 65.5203 161.625 69.2194 154.884 75.9344C153.711 77.0944 152.164 77.7388 150.514 77.7388C148.865 77.7388 147.318 77.0944 146.145 75.9344C144.972 74.7615 144.341 73.2278 144.341 71.578C144.341 69.9411 144.985 68.3816 146.145 67.2217C155.206 58.1738 167.27 53.1859 180.081 53.1859C192.892 53.1859 204.956 58.1738 214.017 67.2088C215.19 68.3816 215.821 69.9283 215.821 71.5651C215.847 73.2278 215.203 74.7744 214.043 75.9344Z"
      fill="#308AE3"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M222.575 64.863C220.925 64.863 219.378 64.2186 218.206 63.0586C208.024 52.9023 194.503 47.3087 180.094 47.3087C165.684 47.3087 152.164 52.9023 141.982 63.0586C140.822 64.2315 139.276 64.863 137.613 64.863C135.963 64.863 134.416 64.2315 133.244 63.0715C132.071 61.8986 131.439 60.3649 131.439 58.7151C131.439 57.0654 132.084 55.5187 133.244 54.3587C145.746 41.8825 162.385 35 180.081 35C197.777 35 214.416 41.8696 226.931 54.3587C228.091 55.5316 228.736 57.0654 228.736 58.7151C228.736 60.3649 228.091 61.9115 226.931 63.0715C225.771 64.2315 224.225 64.863 222.575 64.863Z"
      fill="#308AE3"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Defs>
      <LinearGradient
        id="paint0_linear_377_14324"
        x1="179"
        y1="0"
        x2="179"
        y2="212"
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#C1DCF7" />
        <Stop offset="1" stopColor="#C1DCF6" stopOpacity="0.2" />
      </LinearGradient>
    </Defs>
  </Svg>
);
