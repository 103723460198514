import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { palette } from "../theme";

export default function CheckmarkIcon({
  color = palette.Black,
  width = 24,
  height = 24,
}) {
  return (
    <Svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7803 6.46967C20.0732 6.76256 20.0732 7.23744 19.7803 7.53033L9.78033 17.5303C9.48744 17.8232 9.01256 17.8232 8.71967 17.5303L3.71967 12.5303C3.42678 12.2374 3.42678 11.7626 3.71967 11.4697C4.01256 11.1768 4.48744 11.1768 4.78033 11.4697L9.25 15.9393L18.7197 6.46967C19.0126 6.17678 19.4874 6.17678 19.7803 6.46967Z"
        fill={color}
      />
    </Svg>
  );
}
