import React, { useMemo } from "react";
import {
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";
import { useTheme } from "@react-navigation/native";

import { spacing } from "../../theme";

const createStyles = (theme: any) =>
  StyleSheet.create({
    card: {
      backgroundColor: theme.colors.cardBG,
      borderRadius: spacing[4],
      // marginTop: spacing[2],
      marginBottom: spacing[2],
      padding: spacing[3],
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    content: {
      flexDirection: "row",
      alignItems: "center",
      marginRight: 20,
      flex: 1,
    },
    icon: {
      marginRight: spacing[3],
    },
  });

type CustomControlProps = {
  onPress?: () => void;
  icon?: React.ReactNode;
  control?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
  onLongPress?: () => void | null;
  children?: React.ReactNode;
};

export const CustomControl = ({
  onPress,
  style,
  icon,
  control,
  children,
  onLongPress,
}: CustomControlProps) => {
  const theme = useTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <TouchableOpacity
      style={[styles.card, style]}
      onPress={onPress}
      onLongPress={onLongPress}
    >
      <View style={styles.content}>
        {icon ? <View style={styles.icon}>{icon}</View> : null}
        {children}
      </View>
      {control}
    </TouchableOpacity>
  );
};
