import * as React from "react";

import Svg, { Path } from "react-native-svg";

export const ModuleConnecting = () => (
  <Svg width={360} height={240} viewBox="0 0 360 240" fill="none">
    <Path
      d="M251.993 193H101.992C84.9377 193 74.3996 174.521 83.1411 159.962L156.721 37.3827C165.811 22.2102 187.906 22.2102 197.023 37.356L270.844 159.935C279.612 174.494 269.074 193 251.993 193Z"
      fill="#AAD6BC"
    />
    <Path
      d="M255.742 69.3974L239.397 41.8314C237.539 38.6973 239.897 34.6816 243.613 34.6499L274.891 34.3798C278.761 34.3432 281.168 38.4037 279.288 41.7833L264.07 69.1414C262.263 72.3909 257.603 72.5364 255.742 69.3974Z"
      fill="#D3EADD"
    />
    <Path
      d="M70.1578 112.003L60.4275 95.5922C59.3212 93.7264 60.7252 91.3357 62.937 91.3169L81.558 91.1561C83.8617 91.1343 85.295 93.5516 84.1754 95.5636L75.116 111.851C74.04 113.785 71.2658 113.872 70.1578 112.003Z"
      fill="#D3EADD"
    />
    <Path
      d="M128.199 138.447H172.317H209.587H257.529C266.004 138.447 271.254 129.2 266.897 121.925L230.227 60.6708C227.975 56.8999 224.257 55.0211 220.407 55.0078H128.199"
      fill="white"
    />
    <Path
      d="M165.467 138.451H90.9282C82.4537 138.451 77.217 129.217 81.5609 121.942L118.124 60.688C122.641 53.1062 133.621 53.1062 138.151 60.6747L174.834 121.929C179.191 129.204 173.955 138.451 165.467 138.451Z"
      fill="white"
    />
    <Path
      d="M128.199 138.447H172.317H209.587H257.529C266.004 138.447 271.254 129.2 266.897 121.925L230.227 60.6707C227.975 56.8998 224.257 55.021 220.407 55.0077H128.199"
      stroke="#2B2E32"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M127.093 94.5664C127.333 94.2199 127.746 93.9934 128.186 93.9934C128.626 93.9934 129.026 94.1933 129.279 94.5397C129.319 94.593 129.345 94.633 129.372 94.6863L143.51 118.204H148.933L130.251 87.1312C129.838 86.3983 129.039 85.9453 128.186 85.9453C127.36 85.9453 126.574 86.385 126.161 87.0779L126.094 87.1978L107.439 118.218H112.863L127.014 94.6863C127.04 94.6463 127.053 94.593 127.093 94.5664Z"
      fill="#1FB25A"
    />
    <Path
      d="M157.394 116.285L136.367 81.2674L134.529 78.2027C133.223 76.0441 130.798 74.685 128.199 74.685C125.601 74.685 123.176 76.0308 121.87 78.2027L121.164 79.402L99.0048 116.285C97.6989 118.457 97.6989 121.135 99.0048 123.294C100.311 125.452 102.736 126.811 105.334 126.811H111.277H111.61H123.203H123.309C124.708 126.865 125.801 127.957 125.801 129.303V134.753H130.504V129.303C130.504 125.439 127.227 122.294 123.203 122.294H105.281C104.375 122.281 103.535 121.788 103.082 121.042C102.616 120.269 102.616 119.31 103.082 118.537L125.987 80.428C126.454 79.6818 127.32 79.2154 128.226 79.2154C129.132 79.2154 129.998 79.6818 130.465 80.428L153.37 118.537C153.836 119.296 153.836 120.269 153.37 121.042C152.917 121.788 152.064 122.268 151.171 122.294H143.27H143.136C142.204 122.294 141.351 121.815 140.884 121.042L130.331 103.466C129.918 102.734 129.119 102.281 128.266 102.281C127.426 102.281 126.654 102.72 126.241 103.413L126.174 103.533L117.326 118.244H122.749L127.107 111.022C127.12 110.982 127.16 110.942 127.173 110.902C127.413 110.555 127.826 110.329 128.266 110.329C128.692 110.329 129.092 110.529 129.359 110.875C129.399 110.928 129.425 110.968 129.439 111.022L136.82 123.294C138.126 125.452 140.551 126.811 143.15 126.811H144.749H148.186H149.319H149.599H151.158C153.756 126.811 156.181 125.466 157.487 123.294C158.686 121.122 158.7 118.444 157.394 116.285Z"
      fill="#1FB25A"
    />
    <Path
      d="M165.467 138.451H90.9282C82.4537 138.451 77.217 129.217 81.5609 121.942L118.124 60.688C122.641 53.1062 133.621 53.1062 138.151 60.6747L174.834 121.929C179.191 129.204 173.955 138.451 165.467 138.451Z"
      stroke="#2B2E32"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M182.882 124.138C184.707 124.138 186.186 122.659 186.186 120.834C186.186 119.009 184.707 117.529 182.882 117.529C181.057 117.529 179.577 119.009 179.577 120.834C179.577 122.659 181.057 124.138 182.882 124.138Z"
      fill="#2B2E32"
    />
    <Path
      d="M195.715 124.138C197.54 124.138 199.019 122.659 199.019 120.834C199.019 119.009 197.54 117.529 195.715 117.529C193.89 117.529 192.41 119.009 192.41 120.834C192.41 122.659 193.89 124.138 195.715 124.138Z"
      fill="#2B2E32"
    />
    <Path
      d="M208.545 124.138C210.37 124.138 211.849 122.659 211.849 120.834C211.849 119.009 210.37 117.529 208.545 117.529C206.72 117.529 205.24 119.009 205.24 120.834C205.24 122.659 206.72 124.138 208.545 124.138Z"
      fill="#2B2E32"
    />
    <Path
      d="M221.364 124.138C223.189 124.138 224.669 122.659 224.669 120.834C224.669 119.009 223.189 117.529 221.364 117.529C219.539 117.529 218.06 119.009 218.06 120.834C218.06 122.659 219.539 124.138 221.364 124.138Z"
      fill="#2B2E32"
    />
    <Path
      d="M234.195 124.138C236.02 124.138 237.5 122.659 237.5 120.834C237.5 119.009 236.02 117.529 234.195 117.529C232.37 117.529 230.891 119.009 230.891 120.834C230.891 122.659 232.37 124.138 234.195 124.138Z"
      fill="#2B2E32"
    />
    <Path
      d="M247.028 124.138C248.853 124.138 250.333 122.659 250.333 120.834C250.333 119.009 248.853 117.529 247.028 117.529C245.203 117.529 243.724 119.009 243.724 120.834C243.724 122.659 245.203 124.138 247.028 124.138Z"
      fill="#2B2E32"
    />
    <Path
      d="M259.858 117.545C258.033 117.545 256.554 119.024 256.554 120.849C256.554 122.675 258.033 124.154 259.858 124.154C261.684 124.154 263.163 122.675 263.163 120.849C263.149 119.01 261.67 117.545 259.858 117.545Z"
      fill="#2B2E32"
    />
    <Path
      d="M176.925 129.898C176.325 132.443 175.019 134.775 173.021 136.494C173.78 137.493 174.966 138.146 176.312 138.146C178.604 138.146 180.469 136.294 180.469 133.989C180.469 131.91 178.923 130.204 176.925 129.898Z"
      fill="#2B2E32"
    />
    <Path
      d="M189.171 138.147C191.467 138.147 193.328 136.286 193.328 133.99C193.328 131.694 191.467 129.833 189.171 129.833C186.875 129.833 185.014 131.694 185.014 133.99C185.014 136.286 186.875 138.147 189.171 138.147Z"
      fill="#2B2E32"
    />
    <Path
      d="M202.015 138.147C204.311 138.147 206.172 136.286 206.172 133.99C206.172 131.694 204.311 129.833 202.015 129.833C199.719 129.833 197.857 131.694 197.857 133.99C197.857 136.286 199.719 138.147 202.015 138.147Z"
      fill="#2B2E32"
    />
    <Path
      d="M214.861 138.147C217.157 138.147 219.019 136.286 219.019 133.99C219.019 131.694 217.157 129.833 214.861 129.833C212.565 129.833 210.704 131.694 210.704 133.99C210.704 136.286 212.565 138.147 214.861 138.147Z"
      fill="#2B2E32"
    />
    <Path
      d="M227.719 138.147C230.015 138.147 231.876 136.286 231.876 133.99C231.876 131.694 230.015 129.833 227.719 129.833C225.423 129.833 223.562 131.694 223.562 133.99C223.562 136.286 225.423 138.147 227.719 138.147Z"
      fill="#2B2E32"
    />
    <Path
      d="M240.565 138.147C242.861 138.147 244.722 136.286 244.722 133.99C244.722 131.694 242.861 129.833 240.565 129.833C238.269 129.833 236.407 131.694 236.407 133.99C236.407 136.286 238.269 138.147 240.565 138.147Z"
      fill="#2B2E32"
    />
    <Path
      d="M253.409 129.84C251.117 129.84 249.252 131.693 249.252 133.998C249.252 136.29 251.104 138.155 253.409 138.155C255.714 138.155 257.567 136.303 257.567 133.998C257.567 131.693 255.701 129.84 253.409 129.84Z"
      fill="#2B2E32"
    />
    <Path
      d="M267.43 131.272C267.523 130.899 267.629 130.526 267.749 130.153C267.283 129.966 266.79 129.833 266.257 129.833C263.965 129.833 262.1 131.685 262.1 133.99C262.1 134.856 262.379 135.642 262.846 136.309C263.419 135.816 264.045 135.376 264.738 135.003C265.151 134.643 265.617 134.323 266.097 134.017C266.404 133.217 266.777 132.444 267.243 131.725C267.296 131.565 267.376 131.432 267.43 131.272Z"
      fill="#2B2E32"
    />
    <Path
      d="M169.931 103.54C170.865 103.54 171.623 102.783 171.623 101.848C171.623 100.913 170.865 100.156 169.931 100.156C168.996 100.156 168.238 100.913 168.238 101.848C168.238 102.783 168.996 103.54 169.931 103.54Z"
      fill="#2B2E32"
    />
    <Path
      d="M182.762 103.54C183.696 103.54 184.454 102.783 184.454 101.848C184.454 100.913 183.696 100.156 182.762 100.156C181.827 100.156 181.069 100.913 181.069 101.848C181.069 102.783 181.827 103.54 182.762 103.54Z"
      fill="#2B2E32"
    />
    <Path
      d="M195.605 103.54C196.54 103.54 197.298 102.783 197.298 101.848C197.298 100.913 196.54 100.156 195.605 100.156C194.671 100.156 193.913 100.913 193.913 101.848C193.913 102.783 194.671 103.54 195.605 103.54Z"
      fill="#2B2E32"
    />
    <Path
      d="M208.44 103.54C209.375 103.54 210.133 102.783 210.133 101.848C210.133 100.913 209.375 100.156 208.44 100.156C207.506 100.156 206.748 100.913 206.748 101.848C206.748 102.783 207.506 103.54 208.44 103.54Z"
      fill="#2B2E32"
    />
    <Path
      d="M221.284 103.54C222.219 103.54 222.976 102.783 222.976 101.848C222.976 100.913 222.219 100.156 221.284 100.156C220.349 100.156 219.592 100.913 219.592 101.848C219.592 102.783 220.349 103.54 221.284 103.54Z"
      fill="#2B2E32"
    />
    <Path
      d="M234.129 103.54C235.063 103.54 235.821 102.783 235.821 101.848C235.821 100.913 235.063 100.156 234.129 100.156C233.194 100.156 232.437 100.913 232.437 101.848C232.437 102.783 233.194 103.54 234.129 103.54Z"
      fill="#2B2E32"
    />
    <Path
      d="M246.959 103.54C247.893 103.54 248.651 102.783 248.651 101.848C248.651 100.913 247.893 100.156 246.959 100.156C246.024 100.156 245.267 100.913 245.267 101.848C245.267 102.783 246.024 103.54 246.959 103.54Z"
      fill="#2B2E32"
    />
    <Path
      d="M176.008 112.666C177.31 112.666 178.366 111.61 178.366 110.307C178.366 109.005 177.31 107.949 176.008 107.949C174.705 107.949 173.649 109.005 173.649 110.307C173.649 111.61 174.705 112.666 176.008 112.666Z"
      fill="#2B2E32"
    />
    <Path
      d="M188.799 112.666C190.101 112.666 191.157 111.61 191.157 110.307C191.157 109.005 190.101 107.949 188.799 107.949C187.496 107.949 186.44 109.005 186.44 110.307C186.44 111.61 187.496 112.666 188.799 112.666Z"
      fill="#2B2E32"
    />
    <Path
      d="M201.603 112.666C202.905 112.666 203.961 111.61 203.961 110.307C203.961 109.005 202.905 107.949 201.603 107.949C200.3 107.949 199.244 109.005 199.244 110.307C199.244 111.61 200.3 112.666 201.603 112.666Z"
      fill="#2B2E32"
    />
    <Path
      d="M214.408 112.666C215.711 112.666 216.767 111.61 216.767 110.307C216.767 109.005 215.711 107.949 214.408 107.949C213.106 107.949 212.05 109.005 212.05 110.307C212.05 111.61 213.106 112.666 214.408 112.666Z"
      fill="#2B2E32"
    />
    <Path
      d="M227.214 112.666C228.517 112.666 229.572 111.61 229.572 110.307C229.572 109.005 228.517 107.949 227.214 107.949C225.911 107.949 224.855 109.005 224.855 110.307C224.855 111.61 225.911 112.666 227.214 112.666Z"
      fill="#2B2E32"
    />
    <Path
      d="M240.005 112.666C241.308 112.666 242.363 111.61 242.363 110.307C242.363 109.005 241.308 107.949 240.005 107.949C238.702 107.949 237.646 109.005 237.646 110.307C237.646 111.61 238.702 112.666 240.005 112.666Z"
      fill="#2B2E32"
    />
    <Path
      d="M252.809 112.666C254.111 112.666 255.167 111.61 255.167 110.307C255.167 109.005 254.111 107.949 252.809 107.949C251.506 107.949 250.45 109.005 250.45 110.307C250.45 111.61 251.506 112.666 252.809 112.666Z"
      fill="#2B2E32"
    />
    <Path
      d="M144.01 184.291H152.697C158.813 184.291 163.77 179.334 163.77 173.218C163.77 167.102 158.813 162.145 152.697 162.145H144.01C141.851 162.145 140.106 163.89 140.106 166.049V180.373C140.092 182.545 141.851 184.291 144.01 184.291Z"
      fill="white"
      stroke="#2B2E32"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M130.767 171.122H140.095V168.178H130.767C129.955 168.178 129.302 168.831 129.302 169.643C129.302 170.456 129.955 171.122 130.767 171.122Z"
      fill="white"
      stroke="#2B2E32"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M130.767 178.106H140.095V175.162H130.767C129.955 175.162 129.302 175.815 129.302 176.627C129.302 177.453 129.955 178.106 130.767 178.106Z"
      fill="white"
      stroke="#2B2E32"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M247.045 88.7769H253.774C277.093 88.7769 296.001 107.685 296.001 131.003C296.001 154.321 277.093 173.229 253.774 173.229H224.001"
      stroke="#2B2E32"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M192.001 173.233H165.278"
      stroke="#2B2E32"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M208.021 178.515H208.04"
      stroke="#2B2E32"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M202.715 173.209C204.122 171.802 206.031 171.011 208.021 171.011C210.011 171.011 211.919 171.802 213.327 173.209"
      stroke="#2B2E32"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M197.409 167.9C198.803 166.506 200.458 165.4 202.279 164.646C204.1 163.892 206.052 163.504 208.023 163.504C209.994 163.504 211.946 163.892 213.767 164.646C215.588 165.4 217.243 166.506 218.637 167.9"
      stroke="#2B2E32"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M192.104 162.595C200.896 153.802 215.151 153.802 223.999 162.595"
      stroke="#2B2E32"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
