import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { palette } from "../theme";

export default function MessageIcon({ color = palette.Black, size = 24 }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        id="Union"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 7C3.75 6.30964 4.30965 5.75 5 5.75H19C19.6904 5.75 20.25 6.30964 20.25 7V7.59861L12 13.0986L3.75 7.59861V7ZM2.25 7.98837C2.2499 7.99567 2.2499 8.00296 2.25 8.01025V17C2.25 18.5188 3.48122 19.75 5 19.75H19C20.5188 19.75 21.75 18.5188 21.75 17V8.01026C21.7501 8.00296 21.7501 7.99566 21.75 7.98836V7C21.75 5.48122 20.5188 4.25 19 4.25H5C3.48122 4.25 2.25 5.48122 2.25 7V7.98837ZM20.25 9.40139V17C20.25 17.6904 19.6904 18.25 19 18.25H5C4.30965 18.25 3.75 17.6904 3.75 17V9.40139L11.584 14.624C11.8359 14.792 12.1641 14.792 12.416 14.624L20.25 9.40139Z"
        fill={color}
      />
    </Svg>
  );
}
