import { useTheme } from "@react-navigation/native";
import { StyleSheet } from "react-native";

import { Text } from "../Text";

const styles = StyleSheet.create({
  bold: {
    fontSize: 14,
  },
});

type Props = React.ComponentProps<typeof Text>;

const BoldText = ({ style, children, ...props }: Props) => {
  const { colors, fonts } = useTheme() as any;

  const themeStyle = {
    ...fonts.bold,
    color: colors.text,
  };

  return (
    <Text {...props} style={[styles.bold, themeStyle, style]}>
      {children}
    </Text>
  );
};

export default BoldText;
