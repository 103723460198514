import Svg, { SvgProps, Path } from "react-native-svg";

export const FooterFlowers = (props: SvgProps) => (
  <Svg width={360} height={140} fill="none" viewBox="0 0 360 140">
    <Path
      d="M226.761 16.2188H251.991C254.861 16.2188 256.631 19.3487 255.161 21.8087L242.781 42.5388C241.251 45.0988 237.541 45.1088 236.001 42.5388L223.591 21.8087C222.121 19.3487 223.891 16.2188 226.761 16.2188Z"
      fill="#B0EACC"
    />
    <Path
      d="M226.301 80.2406H163.261C156.091 80.2406 151.661 72.4306 155.341 66.2806L186.271 14.4806C190.101 8.07063 199.371 8.07062 203.211 14.4706L234.231 66.2706C237.911 72.4206 233.471 80.2406 226.301 80.2406Z"
      fill="#B0EACC"
    />
    <Path
      d="M196.751 24.3594V139.799"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M205.611 30.9294V45.3694"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M187.901 30.9294V45.3694"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M196.751 61.2094H201.771C207.941 61.2094 212.951 56.1994 212.951 50.0294V45.0094H207.931C201.761 45.0094 196.751 50.0194 196.751 56.1894V61.2094Z"
      fill="white"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M196.751 76.5494H201.771C207.941 76.5494 212.951 71.5394 212.951 65.3694V60.3494H207.931C201.761 60.3494 196.751 65.3594 196.751 71.5294V76.5494Z"
      fill="white"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M196.751 91.8894H201.771C207.941 91.8894 212.951 86.8794 212.951 80.7094V75.6894H207.931C201.761 75.6894 196.751 80.6994 196.751 86.8694V91.8894Z"
      fill="white"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M196.751 107.219H201.771C207.941 107.219 212.951 102.209 212.951 96.0394V91.0194H207.931C201.761 91.0194 196.751 96.0294 196.751 102.199V107.219Z"
      fill="white"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M196.751 61.2094H191.731C185.561 61.2094 180.551 56.1994 180.551 50.0294V45.0094H185.571C191.741 45.0094 196.751 50.0194 196.751 56.1894V61.2094Z"
      fill="white"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M196.751 76.5494H191.731C185.561 76.5494 180.551 71.5394 180.551 65.3694V60.3494H185.571C191.741 60.3494 196.751 65.3594 196.751 71.5294V76.5494Z"
      fill="white"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M196.751 91.8894H191.731C185.561 91.8894 180.551 86.8794 180.551 80.7094V75.6894H185.571C191.741 75.6894 196.751 80.6994 196.751 86.8694V91.8894Z"
      fill="white"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M196.751 107.219H191.731C185.561 107.219 180.551 102.209 180.551 96.0394V91.0194H185.571C191.741 91.0194 196.751 96.0294 196.751 102.199V107.219Z"
      fill="white"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M152.35 2V139.8"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M162.92 9.84003V27.08"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M141.78 9.84003V27.08"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M152.35 45.99H158.35C165.72 45.99 171.7 40.01 171.7 32.64V26.64H165.7C158.33 26.64 152.35 32.62 152.35 39.99V45.99Z"
      fill="white"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M152.35 64.3H158.35C165.72 64.3 171.7 58.32 171.7 50.95V44.95H165.7C158.33 44.95 152.35 50.93 152.35 58.3V64.3Z"
      fill="white"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M152.35 82.61H158.35C165.72 82.61 171.7 76.63 171.7 69.26V63.26H165.7C158.33 63.26 152.35 69.24 152.35 76.61V82.61Z"
      fill="white"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M152.35 100.92H158.35C165.72 100.92 171.7 94.94 171.7 87.57V81.57H165.7C158.33 81.57 152.35 87.55 152.35 94.92V100.92Z"
      fill="white"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M152.35 45.99H146.35C138.98 45.99 133 40.01 133 32.64V26.64H139C146.37 26.64 152.35 32.62 152.35 39.99V45.99Z"
      fill="white"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M152.35 64.3H146.35C138.98 64.3 133 58.32 133 50.95V44.95H139C146.37 44.95 152.35 50.93 152.35 58.3V64.3Z"
      fill="white"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M152.35 82.61H146.35C138.98 82.61 133 76.63 133 69.26V63.26H139C146.37 63.26 152.35 69.24 152.35 76.61V82.61Z"
      fill="white"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M152.35 100.92H146.35C138.98 100.92 133 94.94 133 87.57V81.57H139C146.37 81.57 152.35 87.55 152.35 94.92V100.92Z"
      fill="white"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </Svg>
);
