import { StyleSheet } from "react-native";

import { THintsDataItem } from "@components/hints";
import { translate } from "../../../../i18n";
import { ModuleCard, ButtonAdd } from "./components";

const dialogStyle = StyleSheet.create({
  card: {
    top: 120,
  },
});
export const ModulesListHintsData = (): THintsDataItem[] => [
  {
    id: "ModuleCard",
    title: translate("Hints.title"),
    text: translate("Hints.Modules.textHintModuleCard"),
    HintComponent: ModuleCard,
    dialogStyle: dialogStyle.card,
  },
  {
    id: "ButtonAdd",
    title: translate("Hints.title"),
    text: translate("Hints.Modules.textHintButtonAdd"),
    HintComponent: ButtonAdd,
  },
  {
    id: "gotIt",
    title: translate("Hints.helpButtonTitle"),
    text: translate("Hints.helpButtonContent"),
  },
];
