import React, { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { StyleSheet } from "react-native";
import { useTheme } from "@react-navigation/native";

import { Text } from "@components/ui";

const createStyles = (theme: any) =>
  StyleSheet.create({
    highlight: {
      backgroundColor: theme.colors.tabActiveBG,
    },
  });

type Props = {
  text: string;
  searchText: string;
};

export const SelectionSearchText = observer(({ text, searchText }: Props) => {
  const theme = useTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);

  const arrayText = text.toLowerCase().split(searchText.toLowerCase());
  let indexIn = 0;

  const newText = arrayText.map((item, index) => {
    const itemText = text.slice(indexIn, indexIn + item.length);
    const selectionText = text.slice(
      indexIn + item.length,
      indexIn + item.length + searchText.length,
    );
    indexIn = indexIn + item.length + searchText.length;

    return (
      // eslint-disable-next-line react/no-array-index-key
      <Text key={index}>
        {itemText}
        {index < arrayText.length - 1 ? (
          <Text style={styles.highlight}>{selectionText}</Text>
        ) : null}
      </Text>
    );
  });

  return <Text>{newText}</Text>;
});
