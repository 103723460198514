import React, { useMemo, useState } from "react";
import { StyleSheet, View } from "react-native";
import { observer } from "mobx-react-lite";
import { useNavigation } from "@react-navigation/native";

import { DeviceType } from "@models/device/device";
import { RadioItem, Text } from "@components/ui";
import { SelectorBar } from "@components/layout";
import { Screen } from "@components/index";

import { useAppTheme } from "../../../hooks";
import { translate } from "../../../i18n";
import * as DeviceIcons from "../../../svgs/devices";
import { ChevronRightIcon, NoneIcon } from "../../../svgs";
import { spacing } from "../../../theme";

const createStyles = (theme: any) =>
  StyleSheet.create({
    description: {
      color: theme.colors.label,
      marginBottom: spacing[3],
    },
    container: {
      padding: spacing[4],
    },
  });

type TypeControlProps = {
  deviceType: DeviceType;
  onPress?: (identifier: string) => void;
  checked?: boolean;
  isInitial?: boolean;
};
export const DeviceTypeControl: React.FunctionComponent<TypeControlProps> = ({
  deviceType,
  onPress,
  checked = false,
  isInitial = false,
}) => {
  const Icon = DeviceIcons[deviceType.iconName] || NoneIcon;

  return (
    <RadioItem
      value={deviceType.name}
      icon={<Icon size={36} />}
      label={deviceType.displayName}
      onPress={onPress}
      checked={checked}
      controlIcon={isInitial ? <ChevronRightIcon /> : null}
    />
  );
};

type DeviceTypeSelectorProps = {
  deviceTypes: DeviceType[];
  onChange: (type: string) => void;
  onClose?: () => void;
  currentValue?: string;
};
export const DeviceTypeSelector: React.FunctionComponent<DeviceTypeSelectorProps> =
  observer(({ deviceTypes, onChange, onClose, currentValue }) => {
    const isInitial = !currentValue;
    const [value, setValue] = useState<string>(currentValue);
    const navigation = useNavigation();
    const onApplyHandler = () => onChange(value);
    const theme = useAppTheme();
    const styles = useMemo(() => createStyles(theme), [theme]);

    return (
      <Screen
        testID="DeviceTypeSelector"
        preset="scroll"
        header={
          <SelectorBar
            title={translate(
              isInitial ? "Devices.Add.title" : "Devices.Add.selectDevice",
            )}
            onApply={isInitial ? undefined : onApplyHandler}
            onClose={isInitial ? navigation.goBack : onClose}
          />
        }
      >
        <View style={styles.container}>
          {isInitial ? (
            <Text style={styles.description}>
              {translate("Devices.Add.selectDeviceDescription")}
            </Text>
          ) : null}
          {deviceTypes.map((deviceType) => (
            <DeviceTypeControl
              key={deviceType.name}
              deviceType={deviceType}
              onPress={isInitial ? onChange : setValue}
              checked={deviceType.name === value}
              isInitial={isInitial}
            />
          ))}
        </View>
      </Screen>
    );
  });
