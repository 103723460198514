import dayjs from "dayjs";
import { translate } from "../i18n";
import { DateTimeFormats, getDuration } from "./timeConverter";

type ValidatorOptions = { setErrors?: (errors: any) => void };
export type DataValidator<DataType> = (
  data: DataType,
  options?: ValidatorOptions,
) => boolean;
export type ItemValidator<DataType> = (
  value: any,
  data?: DataType,
) => string | undefined;
type CreateDataValidator<DataType = unknown> = (
  validators: Record<string, ItemValidator<DataType>>,
) => DataValidator<DataType>;

export const createDataValidator: CreateDataValidator =
  (validators) =>
  (data, { setErrors }) => {
    const errorsArray = Object.entries(validators).map(([key]) => [
      key,
      validators[key]?.(data[key], data),
    ]);

    const errors = Object.fromEntries(errorsArray);
    setErrors?.((prevErrors) => ({ ...prevErrors, ...errors }));

    return !Object.values(errors).filter((n) => n).length;
  };

export const emailValidator = (email: string) => {
  const re = /\S+@\S+\.\S+/;

  if (!email || email.length <= 0)
    return translate("validations.requiredEmail");
  if (!re.test(email)) return translate("validations.wrongEmail");

  return "";
};

export const passwordValidator = (password: string) => {
  const minLength = 8;
  const re = new RegExp(
    `(?=.{${minLength},}$)(?=.*[A-Z])(?=.*[!#$%&'*+-/=?^_\`.{|}~]).*`,
  );

  if (!password || !re.test(password))
    return translate("validations.passwordFormat", {
      charactersCount: minLength,
    });

  return "";
};

export const newPasswordValidator = (
  newPassword: string,
  oldPassword: string,
) => {
  const minLength = 8;
  const re = new RegExp(
    `(?=.{${minLength},}$)(?=.*[A-Z])(?=.*[!#$%&'*+-/=?^_\`.{|}~]).*`,
  );

  if (!newPassword || !re.test(newPassword))
    return translate("validations.passwordFormat", {
      charactersCount: minLength,
    });

  if (newPassword === oldPassword) {
    return translate("Settings.newAndOldPasswordSame");
  }

  return "";
};

export const confirmPasswordValidator = (
  password: string,
  confirmPassword: string,
) => {
  if (!password) {
    return translate("Settings.requiredField");
  }
  if (password !== confirmPassword) {
    return translate("validations.passwordsDoesntMath");
  }

  return "";
};

export const nameValidator = (name: string) => {
  if (!name || name.length <= 0) return translate("validations.requiredName");

  return "";
};

export const requiredStringValidator = (value: string) => {
  if (!value || value.length <= 0) return translate("validations.required");

  return "";
};

export const requiredNumberValidator = (
  value?: string | number | null,
): string | undefined => {
  const numberValue = Number(value);
  if (numberValue !== 0 && !numberValue)
    return translate("validations.mustBeNumber");

  return undefined;
};

export const urlValidator = (url: string): string => {
  const re = /\S+:\/\/\S+/;

  if (!re.test(url)) {
    return translate("validations.wrongUrl");
  }

  return undefined;
};

export const duplicateNameValidator = (
  newName: string | undefined = "",
  names: Array<string> = [],
): string => {
  const name = newName.trim();

  if (!name || name.length === 0) {
    return translate("validations.requiredName");
  }
  const findName = names.find((value) => name === value);

  if (findName?.length) {
    return translate("validations.withSameNameAlreadyExists");
  }

  return undefined;
};

export const moreThanValidator = (
  value: string | number | undefined,
  thanValue: number,
): string => {
  const error = requiredNumberValidator(value);
  if (error) return error;

  if (Number(value) <= thanValue) {
    return translate("validations.valueShouldBeMore", {
      value: thanValue,
    });
  }

  return undefined;
};

export const periodValidator = (startAt: string, endAt: string): string => {
  if (!startAt) {
    return translate("validations.valueShouldBeMore", {
      value: translate("Devices.Rules.from"),
    });
  }

  const isPeriodValid = !!getDuration(startAt, endAt);

  if (!isPeriodValid) {
    return translate("validations.valueShouldBeMore", {
      value: dayjs(startAt).format(DateTimeFormats.TimeHM),
    });
  }

  return undefined;
};
