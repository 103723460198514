import { useEffect, useState } from "react";

import { useStores } from "@models/index";

import { TSensorHistoryItem, SensorsApi } from "../services/api";
import { translate } from "../i18n";

type UseSensorHistoryProps = {
  uid: string;
  startDate: string | undefined;
  endDate: string | undefined;
  transformValues?: (items: TSensorHistoryItem[]) => TSensorHistoryItem[];
  strict?: boolean;
  limit?: number;
};

type TSensorHistoryResult = {
  isLoading: boolean;
  sensorHistory: TSensorHistoryItem[];
  errors: string[] | null;
};

export const useSensorHistory = ({
  uid,
  startDate,
  endDate,
  transformValues,
  strict,
  limit,
}: UseSensorHistoryProps): TSensorHistoryResult => {
  const [sensorHistory, setSensorHistory] = useState<
    Array<TSensorHistoryItem> | []
  >([]);
  const [errors, setErrors] = useState<string[] | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);

  const rootStore = useStores();
  const sensorsApi = new SensorsApi(rootStore.environment.api);

  const getHistory = async () => {
    if (!uid || !startDate || !endDate) return;

    setLoading(true);
    const result = await sensorsApi.getSensorHistory({
      uid,
      startDate,
      endDate,
      strict,
      limit,
    });
    setLoading(false);

    if (result.kind === "ok" && result.data) {
      setSensorHistory(
        transformValues ? transformValues(result.data) : result.data,
      );
      setErrors(null);
    }

    if (result.kind === "error") {
      setSensorHistory([]);
      setErrors([translate("Sensors.History.errors.requestFailed")]);
    }
  };

  useEffect(() => {
    getHistory();
  }, [uid, startDate, endDate]);

  return {
    isLoading,
    sensorHistory,
    errors,
  };
};
