import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { Button as PaperButton } from "react-native-paper";
import { TranslateOptions } from "i18n-js";

import { spacing } from "../../theme";
import { translate, TxKeyPath } from "../../i18n";
import { normalizeFontSize } from "../../utils/sizes";
import { useAppTheme } from "../../hooks";

const createStyles = (theme: any) =>
  StyleSheet.create({
    type: {
      marginVertical: spacing[2],
    },
    "type-primary": {
      width: "100%",
    },
    "type-secondary": {
      borderColor: theme.colors.primary,
      backgroundColor: theme.colors.background,
    },
    "type-secondary_warn": {
      borderColor: theme.colors.warning,
      backgroundColor: theme.colors.background,
    },
    "type-ghost": {
      backgroundColor: theme.colors.background,
    },
    "type-warning": {
      backgroundColor: theme.colors.warning,
    },
    "type-link": {
      marginVertical: spacing[0],
    },

    label: {
      ...theme.fonts?.regular,
      fontSize: normalizeFontSize(18, 20),
      lineHeight: 22,
      letterSpacing: 0,
      marginVertical: spacing[3],
    },
    "label-primary": {
      ...theme.fonts?.medium,
    },
    "label-secondary": {
      color: theme.colors.primary,
    },
    "label-secondary_warn": {
      color: theme.colors.warning,
    },
    "label-ghost": {
      color: theme.colors.text,
    },
    "label-warning": {
      color: theme.colors.background,
    },
    "label-link": {
      fontSize: normalizeFontSize(14, 16),
      lineHeight: 20,
      marginHorizontal: spacing[2],
      color: theme.colors.link,
    },

    labelDisabled: {
      color: theme.colors.disabled,
    },
    labelPrimaryDisabled: {
      color: theme.colors.primaryDisabledText,
    },

    "disabled-type-primary": {
      backgroundColor: theme.colors.disabled,
    },
    "disabled-type-secondary": {
      borderColor: theme.colors.disabled,
    },
    "disabled-type-secondary_warn": {
      borderColor: theme.colors.disabled,
    },
    "disabled-type-ghost": {},
    "disabled-type-warning": {
      backgroundColor: theme.colors.warningDisabledBackground,
    },

    floatingContainer: {
      zIndex: 10,
      position: "absolute",
      alignItems: "center",
      left: 0,
      right: 0,
      bottom: spacing[4],
      paddingHorizontal: spacing[6],
    },
    floatingButton: {
      width: "100%",
    },
    rowReverse: {
      flexDirection: "row-reverse",
    },
  });

const TYPE_MODES = {
  link: "text",
  primary: "contained",
  secondary: "outlined",
  secondary_warn: "outlined",
  ghost: "text",
  warning: "contained",
};

export type ButtonType =
  | "link"
  | "primary"
  | "secondary"
  | "secondary_warn"
  | "ghost"
  | "warning";

type ButtonProps = React.ComponentProps<typeof PaperButton> & {
  type?: ButtonType;
  tx?: TxKeyPath;
  txOptions?: TranslateOptions;
  iconPosition?: "left" | "right";
};

export const Button = ({
  type = "secondary",
  mode,
  uppercase = false,
  tx,
  txOptions,
  children,
  style: customStyle,
  labelStyle: customLabelStyle,
  disabled,
  iconPosition,
  ...props
}: ButtonProps) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  const i18nText = tx && translate(tx, txOptions);
  const labelStyle = [styles.label, styles[`label-${type}`], customLabelStyle];

  const style = [styles.type, styles[`type-${type}`], customStyle];

  if (disabled) {
    labelStyle.push(
      type === "primary" ? styles.labelPrimaryDisabled : styles.labelDisabled,
    );
    style.push(styles[`disabled-type-${type}`]);
  }

  const buttonMode = TYPE_MODES[type] || mode;

  return (
    <PaperButton
      contentStyle={iconPosition === "right" && styles.rowReverse}
      mode={buttonMode as any}
      uppercase={uppercase}
      style={style}
      labelStyle={labelStyle}
      disabled={disabled}
      {...props}
    >
      {i18nText || children}
    </PaperButton>
  );
};

export const FloatingButtonView = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const insets = useSafeAreaInsets();

  return (
    <View
      style={[styles.floatingContainer, { bottom: spacing[4] + insets.bottom }]}
    >
      {children}
    </View>
  );
};

export const FloatingButton = (props: ButtonProps) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  return (
    <FloatingButtonView>
      <Button
        type="primary"
        {...props}
        style={[styles.floatingButton, props.style]}
      />
    </FloatingButtonView>
  );
};
