import * as React from "react";
import Svg, { Path, Rect, G, Circle } from "react-native-svg";

export default function HintSettingSvg(props) {
  return (
    <Svg width={84} height={160} viewBox="0 0 84 160" fill="none" {...props}>
      <Path
        d="M5.06925 8.70677C5.06925 4.28849 8.65097 0.706779 13.0693 0.706779H76.0003C80.4186 0.706779 84.0003 4.2885 84.0003 8.70678V50.6723H5.06925V8.70677Z"
        fill="#B6C5D3"
      />
      <Rect
        x={5.56925}
        y={1.20678}
        width={77.931}
        height={152.517}
        rx={7.5}
        stroke="#B6C5D3"
      />
      <G filter="url(#filter0_d_595_4612)">
        <Circle cx={15.9309} cy={143.361} r={5.7931} fill="#2B2E32" />
      </G>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6675 139.207C15.2675 139.206 14.8728 139.297 14.5143 139.475C14.1558 139.652 13.8434 139.91 13.6017 140.229C13.4348 140.449 13.4778 140.763 13.6978 140.93C13.9178 141.096 14.2314 141.053 14.3984 140.833C14.5465 140.638 14.7381 140.48 14.9578 140.371C15.1775 140.262 15.4195 140.206 15.6647 140.207C15.9099 140.207 16.1515 140.265 16.3707 140.375C16.5898 140.485 16.7804 140.644 16.9275 140.84C17.0746 141.037 17.1742 141.264 17.2184 141.505C17.2626 141.746 17.2502 141.995 17.1822 142.23C17.1142 142.466 16.9925 142.682 16.8266 142.863C16.6619 143.042 16.4583 143.181 16.2315 143.268C15.8865 143.391 15.5904 143.622 15.3871 143.927C15.1824 144.234 15.083 144.6 15.1039 144.968C15.1196 145.244 15.3558 145.455 15.6314 145.439C15.9071 145.423 16.118 145.187 16.1023 144.911C16.0937 144.759 16.1347 144.609 16.2192 144.482C16.3036 144.355 16.4269 144.26 16.5706 144.209L16.5706 144.209L16.5833 144.204C16.9569 144.062 17.2922 143.834 17.5628 143.539C17.8335 143.245 18.0321 142.892 18.143 142.507C18.2539 142.123 18.2741 141.718 18.202 141.325C18.1299 140.932 17.9675 140.56 17.7275 140.24C17.4876 139.92 17.1766 139.661 16.8191 139.481C16.4616 139.302 16.0674 139.208 15.6675 139.207ZM16.1035 146.957C16.1035 146.681 15.8797 146.457 15.6035 146.457C15.3274 146.457 15.1035 146.681 15.1035 146.957V146.965C15.1035 147.241 15.3274 147.465 15.6035 147.465C15.8797 147.465 16.1035 147.241 16.1035 146.965V146.957Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6672 118.721C15.2782 118.72 14.8922 118.797 14.5389 118.948C14.1855 119.1 13.8709 119.323 13.6237 119.606C13.4418 119.814 13.4629 120.13 13.6707 120.312C13.8785 120.493 14.1944 120.472 14.3762 120.265C14.5189 120.102 14.7083 119.964 14.9331 119.867C15.1579 119.771 15.4087 119.72 15.6648 119.721C15.921 119.721 16.1713 119.773 16.3954 119.871C16.6194 119.968 16.8078 120.107 16.9493 120.27C17.0905 120.434 17.1812 120.617 17.2209 120.805C17.2606 120.993 17.2497 121.185 17.1881 121.371C17.1263 121.556 17.0134 121.734 16.8515 121.886C16.6905 122.038 16.4863 122.16 16.2522 122.239C15.9183 122.342 15.6187 122.54 15.4067 122.815C15.1921 123.094 15.081 123.437 15.1041 123.79C15.1222 124.066 15.3602 124.274 15.6357 124.256C15.9113 124.238 16.12 124 16.102 123.725C16.0956 123.627 16.1249 123.522 16.1994 123.425C16.2752 123.326 16.3953 123.241 16.5504 123.193L16.5505 123.193L16.562 123.19C16.9284 123.068 17.2633 122.873 17.5377 122.614C17.8124 122.355 18.0198 122.038 18.1369 121.686C18.2542 121.334 18.276 120.961 18.1993 120.598C18.1228 120.236 17.9515 119.9 17.7056 119.616C17.46 119.332 17.1468 119.107 16.7943 118.954C16.4418 118.801 16.0562 118.721 15.6672 118.721ZM16.1035 125.506C16.1035 125.23 15.8796 125.006 15.6035 125.006C15.3273 125.006 15.1035 125.23 15.1035 125.506V125.513C15.1035 125.789 15.3273 126.013 15.6035 126.013C15.8796 126.013 16.1035 125.789 16.1035 125.513V125.506Z"
        fill="white"
      />
      <Rect
        x={13.7588}
        y={30.9133}
        width={38.3793}
        height={3.62069}
        rx={1.81034}
        fill="white"
      />
      <Rect
        x={13.7588}
        y={37.4314}
        width={19.5517}
        height={3.62069}
        rx={1.81034}
        fill="white"
      />
      <Rect
        x={28.9654}
        y={100.638}
        width={30.4138}
        height={9.41379}
        rx={4}
        fill="#B6C5D3"
      />
      <Circle cx={15.931} cy={143.362} r={15.431} stroke="#3F9A6E" />
    </Svg>
  );
}
