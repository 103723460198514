import * as React from "react";
import { View } from "react-native";
import Svg, { SvgProps, G, Path, Defs, ClipPath, Rect } from "react-native-svg";

import { spacing } from "../../../../theme";

export const CalibrationPreparePHSvg = (props: SvgProps) => (
  <View style={{ marginTop: -spacing[8] }}>
    <Svg width={360} height={312} fill="none" {...props}>
      <G clipPath="url(#clip0_205_4178)">
        <Path
          d="M148.54 197.083L133.012 199.153L139.223 173.273L163.033 170.685L199.782 197.083H148.54Z"
          fill="#B6C5D3"
        />
        <Path
          d="M153.935 186.174C141.601 189.845 133 198.817 133 198.817V96.2917C133 96.2917 142.788 86.368 156.147 83.748"
          fill="white"
        />
        <Path
          d="M153.935 186.174C141.601 189.845 133 198.817 133 198.817V96.2917C133 96.2917 142.788 86.368 156.147 83.748"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M221.238 87.6411V186.718C221.238 192.601 206.111 197.359 187.437 197.359C168.776 197.359 153.637 192.588 153.637 186.718V87.6411"
          fill="white"
        />
        <Path
          d="M221.238 87.6411V186.718C221.238 192.601 206.111 197.359 187.437 197.359C168.776 197.359 153.637 192.588 153.637 186.718V87.6411"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M197.51 192.218H194.792V187.274H197.51V192.218ZM197.51 182.331H194.792V177.387H197.51V182.331ZM197.51 172.444H194.792V167.501H197.51V172.444ZM197.51 162.557H194.792V157.614H197.51V162.557ZM197.51 152.671H194.792V147.727H197.51V152.671ZM197.51 142.784H194.792V137.841H197.51V142.784ZM197.51 132.897H194.792V127.954H197.51V132.897ZM197.51 123.011H194.792V118.067H197.51V123.011ZM197.51 113.124H194.792V108.181H197.51V113.124ZM197.51 103.237H194.792V98.2939H197.51V103.237Z"
          fill="#2B2E32"
        />
        <Path
          d="M187.437 98.2811C206.105 98.2811 221.238 93.5172 221.238 87.6406C221.238 81.7639 206.105 77 187.437 77C168.77 77 153.637 81.7639 153.637 87.6406C153.637 93.5172 168.77 98.2811 187.437 98.2811Z"
          fill="white"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M187 95.1533C172.088 95.1533 160 91.7955 160 87.6533C160 85.948 162.049 84.3757 165.5 83.1159M187 80.1533C201.912 80.1533 214 83.5112 214 87.6533C214 89.6661 211.146 91.4937 206.5 92.8408"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M153.935 186.174C141.601 189.845 133 198.817 133 198.817V96.2917C133 96.2917 142.788 86.368 156.147 83.748"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M187.439 91.2C195.452 91.2 201.947 89.5456 201.947 87.5048C201.947 85.464 195.452 83.8097 187.439 83.8097C179.426 83.8097 172.93 85.464 172.93 87.5048C172.93 89.5456 179.426 91.2 187.439 91.2Z"
          fill="#B6C5D3"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M88 308.153C103.464 308.153 116 304.348 116 299.653C116 294.959 103.464 291.153 88 291.153C72.536 291.153 60 294.959 60 299.653C60 304.348 72.536 308.153 88 308.153Z"
          fill="#B6C5D3"
        />
        <Path
          d="M91.5 265.153C105.031 265.153 116 261.795 116 257.653C116 253.511 105.031 250.153 91.5 250.153C77.969 250.153 67 253.511 67 257.653C67 261.795 77.969 265.153 91.5 265.153Z"
          fill="#70B8FF"
          fillOpacity={0.4}
        />
        <Path
          opacity={0.6}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M116 257.658C115.993 261.798 105.026 265.153 91.5 265.153C77.969 265.153 67 261.795 67 257.653C67 257.493 67.0165 257.333 67.0491 257.175L67 257.176V257.653V291.152V297.475C67 301.711 77.9734 305.153 91.5 305.153C105.027 305.153 116 301.711 116 297.475V288.466V257.658ZM116 257.649V256.153L115.517 256.163C115.833 256.644 115.999 257.14 116 257.649Z"
          fill="#308AE3"
        />
        <Path
          d="M116 236.153V288.477V297.48C116 301.713 105.002 305.153 91.4462 305.153C77.89 305.153 66.8924 301.713 66.8924 297.48V291.161V247.267C66.8924 245.449 66.1543 243.717 64.8504 242.46L61 238.361"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M91.5165 229.153C81.0656 229.153 74.1965 231.504 69.3268 233.676C67.6586 234.416 64.0645 236.218 61.5132 237.507C60.6668 237.936 60.9244 239.165 61.8689 239.249L66.7877 239.702C68.5172 239.965 70.1977 240.43 71.8046 241.098C76.2572 242.948 83.433 244.153 91.5165 244.153C105.046 244.153 116 240.788 116 236.647C116.012 232.507 105.046 229.153 91.5165 229.153Z"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M91.4642 272.153C91.5 272.189 91.5239 272.213 91.5597 272.249C92.4073 273.168 93.231 274.111 94.007 275.09C94.8427 276.129 95.6306 277.191 96.3588 278.313C97.0513 279.376 97.684 280.462 98.2331 281.596C98.6629 282.48 99.033 283.399 99.3195 284.33C99.5105 284.963 99.6657 285.608 99.7732 286.264C99.8926 286.992 99.9403 287.733 99.8926 288.485C99.8329 289.583 99.5822 290.634 99.0927 291.613C98.5197 292.735 97.7079 293.642 96.6692 294.335C95.8813 294.872 95.0218 295.242 94.1025 295.493C93.6608 295.612 93.2191 295.695 92.7774 295.755C92.1805 295.839 91.5836 295.863 90.9867 295.827C89.7331 295.767 88.5274 295.493 87.3813 294.967C86.4621 294.537 85.6383 293.964 84.9459 293.224C84.1341 292.353 83.585 291.326 83.2746 290.168C83.1671 289.75 83.0955 289.32 83.0478 288.891C83 288.425 82.9881 287.959 83.0119 287.494C83.0478 286.778 83.1552 286.061 83.3104 285.357C83.585 284.163 84.0028 283.017 84.5162 281.907C85.0414 280.761 85.6622 279.662 86.3427 278.6C87.0948 277.418 87.9305 276.284 88.802 275.186C89.6138 274.159 90.4733 273.168 91.3567 272.213C91.4045 272.213 91.4284 272.189 91.4642 272.153Z"
          fill="white"
        />
        <Path
          d="M110 249.153V279.653"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M110 284.653V294.653"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M265 308.153C280.464 308.153 293 304.348 293 299.653C293 294.959 280.464 291.153 265 291.153C249.536 291.153 237 294.959 237 299.653C237 304.348 249.536 308.153 265 308.153Z"
          fill="#B6C5D3"
        />
        <Path
          d="M268.5 265.153C282.031 265.153 293 261.795 293 257.653C293 253.511 282.031 250.153 268.5 250.153C254.969 250.153 244 253.511 244 257.653C244 261.795 254.969 265.153 268.5 265.153Z"
          fill="#70B8FF"
          fillOpacity={0.4}
        />
        <Path
          opacity={0.6}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M293 257.658C292.993 261.798 282.026 265.153 268.5 265.153C254.969 265.153 244 261.795 244 257.653C244 257.493 244.017 257.333 244.049 257.175L244 257.176V257.653V291.152V297.475C244 301.711 254.973 305.153 268.5 305.153C282.027 305.153 293 301.711 293 297.475V288.466V257.658ZM293 257.649V256.153L292.517 256.163C292.833 256.644 292.999 257.14 293 257.649Z"
          fill="#308AE3"
        />
        <Path
          d="M293 236.153V288.477V297.48C293 301.713 282.002 305.153 268.446 305.153C254.89 305.153 243.892 301.713 243.892 297.48V291.161V247.267C243.892 245.449 243.154 243.717 241.85 242.46L238 238.361"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M268.516 229.153C258.066 229.153 251.196 231.504 246.327 233.676C244.659 234.416 241.065 236.218 238.513 237.507C237.667 237.936 237.924 239.165 238.869 239.249L243.788 239.702C245.517 239.965 247.198 240.43 248.805 241.098C253.257 242.948 260.433 244.153 268.516 244.153C282.046 244.153 293 240.788 293 236.647C293.012 232.507 282.046 229.153 268.516 229.153Z"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M263.177 280.526C263.177 285.766 263.177 292.703 263.177 297.944"
          stroke="white"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M252 292.193C253.06 292.483 254.132 292.729 255.203 292.954C256.906 293.307 258.288 292.108 258.288 290.404C258.288 289.901 258.288 289.398 258.288 288.905C258.288 287.191 256.906 285.616 255.203 285.263C254.132 285.038 253.06 284.791 252 284.502C252 287.062 252 289.622 252 292.193Z"
          stroke="white"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M252 283.314C252 287.459 252 291.605 252 295.751"
          stroke="white"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M271.457 280.589C271.457 285.829 271.457 292.84 271.457 298.08"
          stroke="white"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M263.177 289.192C265.938 289.391 268.714 289.448 271.475 289.334"
          stroke="white"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M275.047 281.867C278.368 281.589 281.667 281.021 284.902 280.153C282.738 284.984 280.553 289.687 278.346 294.261C278.346 295.214 278.357 296.157 278.357 297.1"
          stroke="white"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M286 249.153V272.336"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M176 308.153C191.464 308.153 204 304.348 204 299.653C204 294.959 191.464 291.153 176 291.153C160.536 291.153 148 294.959 148 299.653C148 304.348 160.536 308.153 176 308.153Z"
          fill="#B6C5D3"
        />
        <Path
          d="M179.5 265.153C193.031 265.153 204 261.795 204 257.653C204 253.511 193.031 250.153 179.5 250.153C165.969 250.153 155 253.511 155 257.653C155 261.795 165.969 265.153 179.5 265.153Z"
          fill="#70B8FF"
          fillOpacity={0.4}
        />
        <Path
          opacity={0.6}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M204 257.658C203.993 261.798 193.026 265.153 179.5 265.153C165.969 265.153 155 261.795 155 257.653C155 257.493 155.017 257.333 155.049 257.175L155 257.176V257.653V291.152V297.475C155 301.711 165.973 305.153 179.5 305.153C193.027 305.153 204 301.711 204 297.475V288.466V257.658ZM204 257.649V256.153L203.517 256.163C203.833 256.644 203.999 257.14 204 257.649Z"
          fill="#308AE3"
        />
        <Path
          d="M204 236.153V288.477V297.48C204 301.713 193.002 305.153 179.446 305.153C165.89 305.153 154.892 301.713 154.892 297.48V291.161V247.267C154.892 245.449 154.154 243.717 152.85 242.46L149 238.361"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M179.516 229.153C169.066 229.153 162.196 231.504 157.327 233.676C155.659 234.416 152.065 236.218 149.513 237.507C148.667 237.936 148.924 239.165 149.869 239.249L154.788 239.702C156.517 239.965 158.198 240.43 159.805 241.098C164.257 242.948 171.433 244.153 179.516 244.153C193.046 244.153 204 240.788 204 236.647C204.012 232.507 193.046 229.153 179.516 229.153Z"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M162.879 292.367C163.999 292.673 165.13 292.933 166.261 293.17C168.047 293.544 169.518 292.277 169.518 290.479C169.518 289.947 169.518 289.415 169.518 288.895C169.518 287.086 168.047 285.423 166.261 285.061C165.13 284.824 163.999 284.564 162.879 284.258C162.879 286.95 162.879 289.653 162.879 292.367Z"
          stroke="white"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M162.879 283.003C162.879 287.38 162.879 291.757 162.879 296.133"
          stroke="white"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M174.177 280.526C174.177 285.766 174.177 292.703 174.177 297.944"
          stroke="white"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M182.457 280.589C182.457 285.829 182.457 292.84 182.457 298.08"
          stroke="white"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M174.177 289.192C176.938 289.391 179.714 289.448 182.475 289.334"
          stroke="white"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M193.799 296.632C193.799 291.135 193.799 285.65 193.799 280.153C191.368 283.931 188.902 287.561 186.425 291.033C189.988 290.683 193.528 289.993 197 288.986"
          stroke="white"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M197 249.153V271.839"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M34 160.153L133.487 160.153"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M221 160.153L320.487 160.153"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_205_4178">
          <Rect width={360} height={312} fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  </View>
);
