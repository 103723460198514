import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { palette } from "../theme";

export default function InfinityIcon({ color = palette.Black, size = 16 }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 16 16" fill="none">
      <Path
        d="M6.55159 6.11337C6.17869 5.74035 5.70353 5.48629 5.18623 5.38333C4.66892 5.28037 4.1327 5.33313 3.64539 5.53494C3.15807 5.73675 2.74155 6.07855 2.44849 6.51709C2.15544 6.95564 1.99902 7.47125 1.99902 7.9987C1.99902 8.52615 2.15544 9.04176 2.44849 9.4803C2.74155 9.91885 3.15807 10.2606 3.64539 10.4625C4.1327 10.6643 4.66892 10.717 5.18623 10.6141C5.70353 10.5111 6.17869 10.2571 6.55159 9.88403C7.14331 9.34741 7.63378 8.70882 7.99959 7.9987C8.36541 7.28858 8.85587 6.64998 9.44759 6.11337C9.8205 5.74035 10.2957 5.48629 10.813 5.38333C11.3303 5.28037 11.8665 5.33313 12.3538 5.53494C12.8411 5.73675 13.2576 6.07855 13.5507 6.51709C13.8437 6.95564 14.0002 7.47125 14.0002 7.9987C14.0002 8.52615 13.8437 9.04176 13.5507 9.4803C13.2576 9.91885 12.8411 10.2606 12.3538 10.4625C11.8665 10.6643 11.3303 10.717 10.813 10.6141C10.2957 10.5111 9.8205 10.2571 9.44759 9.88403C8.85587 9.34741 8.36541 8.70882 7.99959 7.9987C7.63378 7.28858 7.14331 6.64998 6.55159 6.11337"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
