import * as React from "react";
import Svg, { Path } from "react-native-svg";

export default function SoilPHIcon({ color = "#6F6F6F", size = 24 }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        fill={color}
        fillRule="evenodd"
        d="M13.35 5.3a.75.75 0 0 0-1.5 0v7.287a.75.75 0 0 0 1.5 0v-3.11h1.5v3.11a.75.75 0 0 0 1.5 0V5.3a.75.75 0 0 0-1.5 0v2.676h-1.5V5.3Zm-7.2 4.302c0-.967.783-1.75 1.75-1.75h1c.966 0 1.75.783 1.75 1.75v1.97a1.75 1.75 0 0 1-1.75 1.75H7.65v2.78a.75.75 0 0 1-1.5 0v-6.5Zm1.5 2.22H8.9a.25.25 0 0 0 .25-.25v-1.97a.25.25 0 0 0-.25-.25h-1a.25.25 0 0 0-.25.25v2.22Zm10.8.678a.75.75 0 0 1 .75-.75h1.371a.75.75 0 0 1 0 1.5H19.2a.75.75 0 0 1-.75-.75ZM1.82 18.07a.75.75 0 0 1 .75-.75h18a.75.75 0 0 1 0 1.5h-18a.75.75 0 0 1-.75-.75Zm.75-6.32a.75.75 0 0 0 0 1.5H4.2a.75.75 0 0 0 0-1.5H2.57Z"
        clipRule="evenodd"
      />
    </Svg>
  );
}
