import React, { useMemo, useState } from "react";
import { StyleSheet, View, Linking, ViewStyle, ScrollView } from "react-native";
import { Portal } from "react-native-paper";
import { observer } from "mobx-react-lite";

import { LayoutCenter } from "@components/layout";
import { TitleDialog } from "@components/dialog";
import { Button, Dialog, Text } from "@components/ui";

import { AnimatedHelpButton } from "@components/hints/AnimatedHelpButton";
import { useStores } from "@models/root-store/root-store-context";
import { translate } from "../../i18n";
// import { HintIcon } from "../../svgs";
import CarouselEllipseIcon from "../../svgs/CarouselEllipseIcon";
import { spacing } from "../../theme";
import { useAppTheme } from "../../hooks";
import { normalizeFontSize } from "../../utils/sizes";

const createStyles = (theme: any) =>
  StyleSheet.create({
    // hintsButton: {
    //   position: "absolute",
    //   bottom: spacing[3],
    //   left: spacing[4],
    // },
    image: {
      marginVertical: spacing[4],
    },
    sliderPagination: {
      flexDirection: "row",
      bottom: 0,
      alignSelf: "center",
      marginBottom: spacing[1],
      marginTop: spacing[4],
    },
    paginationItem: {
      marginRight: spacing[3],
    },
    header: {
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-between",
    },
    title: {
      fontSize: normalizeFontSize(18, 22),
      ...theme.fonts.medium,
    },
    text: {
      fontSize: 16,
      color: theme.colors.label,
      marginVertical: spacing[4],
    },
    helpButton: {
      alignItems: "flex-start",
      marginTop: -spacing[2],
      marginLeft: -spacing[2],
    },
    contentScroll: {
      maxHeight: 100,
    },
  });

export type THintsDataItem = {
  id: string;
  title: string;
  text: string;
  helpFileName?: string;
  Image?: React.FunctionComponent<any>;
  HintComponent?: React.FunctionComponent<any>;
  dialogStyle?: ViewStyle;
  isScroll?: boolean;
};

type ContentHintProps = {
  hint: THintsDataItem;
};
const Content = ({ hint }: ContentHintProps) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  const { text, helpFileName, Image } = hint;
  return (
    <>
      <Text style={styles.text}>{text}</Text>
      {helpFileName ? (
        <Button
          type="link"
          onPress={() => Linking.openURL(helpFileName)}
          style={styles.helpButton}
        >
          {translate("Hints.helpPage")}
        </Button>
      ) : null}
      {Image && (
        <LayoutCenter style={styles.image}>
          <Image />
        </LayoutCenter>
      )}
    </>
  );
};

const ContentHint = ({ hint }: ContentHintProps) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  if (hint?.isScroll) {
    return (
      <ScrollView style={styles.contentScroll}>
        <Content hint={hint} />
      </ScrollView>
    );
  }

  return <Content hint={hint} />;
};

type ContentCarouselProps = {
  length: number;
  activeIndex: number;
};
const ContentCarousel = ({ length, activeIndex }: ContentCarouselProps) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  /* eslint-disable react/no-array-index-key */
  return (
    <View style={styles.sliderPagination}>
      {Array(length)
        .fill(null)
        .map((_, index) => (
          <View key={`carousel-item-${index}`} style={styles.paginationItem}>
            <CarouselEllipseIcon
              color={
                index === activeIndex
                  ? theme.colors.cardActive
                  : theme.colors.cardDisabled
              }
            />
          </View>
        ))}
    </View>
  );
  /* eslint-enable react/no-array-index-key */
};

type HelpHintsProps = {
  hints: THintsDataItem[];
};
export const HelpHints = observer(({ hints }: HelpHintsProps) => {
  // const theme = useAppTheme();
  // const styles = useMemo(() => createStyles(theme), [theme]);

  const [visible, setVisible] = useState(false);
  const [activeItemIndex, setActiveItemIndex] = useState<number>(0);

  const currentHint = hints[activeItemIndex] || hints[0];
  const { title, HintComponent, dialogStyle } = currentHint;
  const isLastItem = activeItemIndex === hints.length - 1;

  const openDialog = () => {
    setActiveItemIndex(0);
    setVisible(true);
  };

  const closeDialog = () => {
    setVisible(false);
  };

  const onPrevious = () => {
    if (activeItemIndex === 0) {
      closeDialog();
    } else {
      setActiveItemIndex(activeItemIndex - 1);
    }
  };
  const onNext = () => {
    if (isLastItem) {
      closeDialog();
    } else {
      setActiveItemIndex(activeItemIndex + 1);
    }
  };

  return (
    <>
      <Dialog
        style={dialogStyle}
        title={
          <TitleDialog
            title={title}
            onClose={isLastItem ? undefined : closeDialog}
            textOnClose={translate("common.skip")}
          />
        }
        visible={visible}
        onDismiss={closeDialog}
        onOk={onNext}
        okText={
          isLastItem ? translate("common.gotIt") : translate("common.next")
        }
        onCancel={onPrevious}
        cancelText={
          activeItemIndex === 0
            ? translate("common.close")
            : translate("common.previous")
        }
      >
        <ContentHint hint={currentHint} />
        <ContentCarousel length={hints.length} activeIndex={activeItemIndex} />
      </Dialog>

      {(!visible || isLastItem) && (
        <Portal>
          <AnimatedHelpButton onHowToWorkClick={openDialog} />
          {/* <TouchableOpacity onPress={openDialog} style={styles.hintsButton}> */}
          {/*  <HintIcon /> */}
          {/* </TouchableOpacity> */}
        </Portal>
      )}

      {visible && HintComponent ? (
        <Portal>
          <HintComponent />
        </Portal>
      ) : null}
    </>
  );
});

type HelpHintsOneTimeProps = HelpHintsProps & {
  onOk: (id: string) => void;
};
export const HelpHintsOneTime = ({ hints, onOk }: HelpHintsOneTimeProps) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  const [visible, setVisible] = useState<boolean>(true);
  const [activeItemIndex, setActiveItemIndex] = useState<number>(0);

  if (!visible || !hints?.length) return null;

  const currentHint = hints[activeItemIndex];
  if (!currentHint) return null;

  const { title, HintComponent, dialogStyle } = currentHint;
  const isLastItem = activeItemIndex === hints.length - 1;

  const onDismiss = () => {
    setVisible(false);
  };

  const onOkHandler = () => {
    onOk(currentHint.id);
    if (isLastItem) {
      setVisible(false);
    } else {
      setActiveItemIndex(activeItemIndex + 1);
    }
  };

  return (
    <>
      <Dialog
        style={dialogStyle}
        title={<Text style={styles.title}>{title}</Text>}
        visible={visible}
        onDismiss={onDismiss}
        onOk={onOkHandler}
        okText={translate("common.gotIt")}
      >
        <ContentHint hint={currentHint} />
      </Dialog>

      {HintComponent ? (
        <Portal>
          <HintComponent />
        </Portal>
      ) : null}
    </>
  );
};
