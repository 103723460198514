import React, { useMemo } from "react";
import { StyleProp, StyleSheet, ViewStyle } from "react-native";
import { IconButton, Menu } from "react-native-paper";
import { useTheme } from "@react-navigation/native";

import { spacing } from "../../theme";
import { DotsVerticalIcon } from "../../svgs";

const createStyles = (theme: any) =>
  StyleSheet.create({
    menu: {
      borderRadius: spacing[2],
      backgroundColor: theme.colors.text,
      padding: spacing[2],
    },
    item: {
      paddingHorizontal: 0,
    },
    title: {
      color: theme.colors.background,
    },
    topLine: {
      borderTopColor: theme.colors.subtitle,
      borderTopWidth: 1,
    },
  });

export type MenuItem = {
  key: string;
  title: string;
  icon?: React.FunctionComponent<any>;
  color?: string;
  onPress?: (() => void) | undefined;
  topLine?: boolean | false;
};

export type ContextMenuAnchorProps = {
  onPress?: () => void;
};

type ContextMenuProps = {
  menuItems: MenuItem[];
  iconColor?: any;
  style?: StyleProp<ViewStyle>;
  onPress?: (key: string) => void;
  anchor?: React.FunctionComponent<ContextMenuAnchorProps>;
  anchorProps?: Record<string, any>;
};

export const ContextMenu = ({
  menuItems,
  iconColor,
  style,
  onPress,
  anchor: Anchor,
  anchorProps,
}: ContextMenuProps) => {
  const theme = useTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);

  const [isMenuVisible, setMenuVisible] = React.useState(false);

  // TODO fixed double click for web  !!!
  const timeoutOpenMenu = React.useRef<NodeJS.Timeout>(null);

  const openMenu = () => {
    setMenuVisible(true);
    timeoutOpenMenu.current = setTimeout(() => {
      timeoutOpenMenu.current = null;
    }, 300);
  };

  const closeMenu = () => {
    if (!timeoutOpenMenu.current) {
      setMenuVisible(false);
    }
  };

  return (
    <Menu
      contentStyle={[styles.menu, style]}
      onDismiss={closeMenu}
      visible={isMenuVisible}
      anchor={
        Anchor ? (
          <Anchor {...anchorProps} onPress={openMenu} />
        ) : (
          <IconButton
            icon={DotsVerticalIcon}
            iconColor={iconColor}
            onPress={openMenu}
          />
        )
      }
    >
      {menuItems.map(
        ({ key, title, icon: Icon, color, topLine, onPress: onItemPress }) => (
          <Menu.Item
            style={[styles.item, topLine && styles.topLine]}
            key={`menu-item-${key}`}
            title={title}
            titleStyle={[styles.title, color && { color }]}
            leadingIcon={
              Icon
                ? ({ size }) => (
                    <Icon
                      color={color || theme.colors.background}
                      size={size}
                    />
                  )
                : undefined
            }
            onPress={() => {
              closeMenu();
              onItemPress?.();
              onPress?.(key);
            }}
          />
        ),
      )}
    </Menu>
  );
};
