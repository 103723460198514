import React, { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { StyleSheet, View } from "react-native";
import { LinearGradient } from "expo-linear-gradient";

import { DeviceState, DeviceStateType } from "@models/device/types";
import { Rule } from "@models/rule/rule";
import { Device } from "@models/device/device";
import { SensorCardValue } from "@screens/Sensors/List/components/SensorCard";
import { Text } from "@components/ui";
import { ProgressBarTimer } from "@components/common";

import { spacing } from "../../../../theme";
import {
  Offline,
  StatusContinuouslySvg,
  StatusIdleSvg,
  StatusScheduleSvg,
  StatusSensorSvg,
} from "../../../../svgs/devices/status";
import ClockIcon from "../../../../svgs/ClockIcon";
import { displayNumber } from "../../../../utils/display";
import { useAppTheme, useSensor } from "../../../../hooks";
import { RightThinArrowIcon } from "../../../../svgs";
import { DeviceOnOffButton } from "./DeviceOnOffButton";

const createStyles = (theme: any) =>
  StyleSheet.create({
    container: {
      marginTop: spacing[7],
      marginBottom: spacing[5],
      alignItems: "center",
      paddingHorizontal: spacing[2],
    },

    containerScroll: {
      marginVertical: spacing[5],
      marginHorizontal: spacing[2],
      flexDirection: "row-reverse",
      justifyContent: "space-between",
      alignItems: "center",
    },

    statusBox: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      marginBottom: spacing[5],
    },

    iconBG: {
      borderRadius: spacing[4],
    },
    statusText: {
      fontSize: 24,
      lineHeight: 32,
      ...theme.fonts.medium,
    },
    textOffline: {
      color: theme.colors.warning,
    },

    onOffButton: {
      marginBottom: spacing[5],
    },

    sensorStatusBox: {
      marginBottom: spacing[5],
    },
    sensorStatus: {
      alignItems: "center",
    },
    sensorValue: {
      color: theme.colors.text,
      fontSize: 28,
      lineHeight: 44,
      ...theme.fonts.bold,
    },
    sensorSubtitleText: {
      color: theme.colors.subtitle,
      fontSize: 14,
      lineHeight: 16,
    },
    sensorSubtitle: {
      flexDirection: "row",
      alignItems: "center",
    },

    progress: {
      width: "100%",
      marginBottom: spacing[5],
    },
  });

type StatusRuleSensorProps = {
  rule: Rule;
  isDimmer?: boolean;
};
const StatusRuleSensor = observer(
  ({ rule, isDimmer }: StatusRuleSensorProps) => {
    const theme = useAppTheme();
    const styles = useMemo(() => createStyles(theme), [theme]);

    const { sensor } = useSensor(rule?.sensorUid);
    if (!sensor) return null;

    const { valueLow, valueHigh } = rule.sensorData;

    const valueLowDisplay = displayNumber(
      valueLow,
      sensor.numberOfDecimals,
      "-",
    );
    const valueHighDisplay = displayNumber(
      valueHigh,
      sensor.numberOfDecimals,
      "-",
    );
    const unitName = sensor?.unitName;

    return (
      <View style={styles.sensorStatusBox}>
        <SensorCardValue sensor={sensor} valueStyle={styles.sensorValue} />

        {!isDimmer && (
          <View style={styles.sensorStatus}>
            <View style={styles.sensorSubtitle}>
              <Text>
                {valueLowDisplay}
                {unitName ? (
                  <Text
                    style={styles.sensorSubtitleText}
                  >{` ${unitName} `}</Text>
                ) : null}
              </Text>
              <RightThinArrowIcon />
              <Text>
                {` ${valueHighDisplay}`}
                {unitName ? (
                  <Text
                    style={styles.sensorSubtitleText}
                  >{` ${unitName}`}</Text>
                ) : null}
              </Text>
            </View>
          </View>
        )}
      </View>
    );
  },
);

const StatusIcon = ({ stateType }: { stateType: DeviceStateType }) => {
  const theme = useAppTheme();

  if (stateType === DeviceStateType.Manually) {
    return (
      <View style={{ padding: 4 }}>
        <ClockIcon color={theme.colors.background} size={36} />
      </View>
    );
  }

  if (stateType === DeviceStateType.Idle) {
    return <StatusIdleSvg color={theme.colors.background} />;
  }

  if (stateType === DeviceStateType.Continuously) {
    return <StatusContinuouslySvg color={theme.colors.background} />;
  }

  if (stateType === DeviceStateType.BySensor) {
    return <StatusSensorSvg color={theme.colors.background} />;
  }

  if (stateType === DeviceStateType.BySchedule) {
    return <StatusScheduleSvg color={theme.colors.background} />;
  }

  return null;
};

type DeviceStatusProps = {
  state: DeviceState;
  displayState: string;
  stateType: DeviceStateType;
};

export const DeviceStatus = ({
  state,
  displayState,
  stateType,
}: DeviceStatusProps) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  const iconColors =
    state === DeviceState.On
      ? [
          theme.colors.backgroundGradientStart,
          theme.colors.backgroundGradientEnd,
        ]
      : [theme.colors.badgeGStart, theme.colors.badgeGEnd];

  return (
    <View style={styles.statusBox}>
      <Text style={styles.statusText}>{displayState}</Text>
      <LinearGradient
        colors={iconColors}
        start={{ x: 0, y: 1 }}
        end={{ x: 1, y: 1 }}
        style={styles.iconBG}
      >
        <StatusIcon stateType={stateType} />
      </LinearGradient>
    </View>
  );
};

type DetailsDeviceStatusProps = {
  device: Device;
  isScroll?: boolean;
};
export const DetailsDeviceStatus = observer(
  ({ device, isScroll = false }: DetailsDeviceStatusProps) => {
    const theme = useAppTheme();
    const styles = useMemo(() => createStyles(theme), [theme]);

    const {
      state,
      displayState,
      stateType,
      secondsToSwitchBack,
      secondsEnabledFor,
      fetchedAt,
      // analogControlPinValue,
      rule,
    } = device;

    const containerStyle = isScroll ? styles.containerScroll : styles.container;

    // const whyOfflineHandler = () => {
    //   // TODO: !!!
    //   console.log("should navigate to manual...");
    // };

    if (state === DeviceState.Offline) {
      return (
        <View style={containerStyle}>
          <View style={styles.statusBox}>
            <Text style={[styles.statusText, styles.textOffline]}>
              {device.displayState}
            </Text>
            <View>
              <Offline />
            </View>
          </View>

          {/* <Button */}
          {/*  type="link" */}
          {/*  tx="common.whyItsOffline" */}
          {/*  onPress={whyOfflineHandler} */}
          {/* > */}
          {/*  Why it`s offline? */}
          {/* </Button> */}
        </View>
      );
    }

    return (
      <View style={containerStyle}>
        <DeviceStatus
          state={state}
          displayState={displayState}
          stateType={stateType}
        />

        {device.stateType === DeviceStateType.BySensor && (
          <StatusRuleSensor rule={rule} isDimmer={device.isDimmer} />
        )}

        {secondsToSwitchBack && secondsEnabledFor ? (
          <ProgressBarTimer
            secondsLeft={secondsToSwitchBack}
            secondsTotal={secondsEnabledFor}
            style={styles.progress}
            callback={device.refetch}
            initialTimestamp={fetchedAt}
          />
        ) : null}

        {/* {analogControlPinValue > 0 ? (
          <Text style={styles.statusText}>
            {translate("Devices.Details.dimmingValue", {
              analogControlPinValue,
            })}
          </Text>
        ) : null} */}

        <View style={styles.onOffButton}>
          <DeviceOnOffButton device={device} />
        </View>
      </View>
    );
  },
);
