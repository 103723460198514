import React, { useEffect, useMemo, useState } from "react";
import { View, Image, StyleSheet } from "react-native";
import { observer } from "mobx-react-lite";
import { StackScreenProps } from "@react-navigation/stack";
import { useTheme } from "@react-navigation/native";

import { Text, Button, Heading, ErrorText } from "@components/ui";
import { Screen } from "@components/index";
import { TextInput } from "@components/inputs";
import { MODULE_IMAGES } from "@assets/modules";
import { useStores } from "@models/index";

import { ModulesNavigatorParamList } from "../navigation";
import { spacing } from "../../../theme";
import { translate } from "../../../i18n";
import { requiredStringValidator } from "../../../utils/validator";

const createStyles = (theme: any) =>
  StyleSheet.create({
    screen: {
      paddingHorizontal: spacing[4],
      paddingTop: spacing[8],
      paddingBottom: spacing[5],
    },
    containerContent: {
      flex: 1,
      justifyContent: "space-between",
    },
    card: {
      alignItems: "center",
    },
    heading: {
      textAlign: "center",
      color: theme.colors.primary,
    },
    picture: {
      width: 216,
      height: 153,
      marginVertical: spacing[4],
    },
    type: {
      fontSize: 14,
      marginBottom: spacing[3],
    },
    uid: {
      fontSize: 12,
      color: theme.colors.label,
    },
    connectAnother: {
      marginBottom: spacing[4],
    },
  });

export const SuccessfullyConnectedScreen: React.FC<
  StackScreenProps<
    ModulesNavigatorParamList,
    "module-add-successfully-connected"
  >
> = observer(({ navigation, route }) => {
  const theme = useTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);
  const { moduleStore } = useStores();

  const { uid, type } = route.params || {};
  const module = moduleStore.getModule(uid);

  const [error, setError] = useState<string>(null);
  const [moduleName, setModuleName] = useState<string>();

  // GDV1-504: Caching of data on the Module page
  useEffect(() => {
    moduleStore.fetchModules({ force: true });
  }, [uid]);

  useEffect(() => {
    setError(null);
    setModuleName("");
  }, []);

  if (!module) {
    return <ErrorText>{translate("Modules.Details.noModule")}</ErrorText>;
  }

  const imageSource = MODULE_IMAGES[module.type] || MODULE_IMAGES.default;

  const updateName = async () => {
    if (error) return false;
    const result = await module.updateName(moduleName);
    if (result?.error) {
      setError(result?.error);
      return false;
    }
    setError(null);
    return true;
  };

  const connectAnotherHandler = async () => {
    const updateResult = await updateName();
    if (!updateResult) return;

    if (type === "smart") {
      navigation.navigate("module-add-smart");
    } else {
      navigation.navigate("module-add-manual");
    }
  };

  const finishHandler = async () => {
    const updateResult = await updateName();
    if (updateResult) {
      navigation.navigate("modules-list");
    }
  };

  const changeModuleName = (value: string) => {
    setModuleName(value);
    setError(requiredStringValidator(value));
  };

  return (
    <Screen
      testID="ModuleAddSuccessfullyConnected"
      style={styles.screen}
      keyboard
      loading={module.isLoading}
    >
      <View style={styles.containerContent}>
        <View style={styles.card}>
          <Image style={styles.picture} source={imageSource} />
          <Text style={styles.type}>{module.typeName}</Text>
          <Text style={styles.uid}>{module.uid}</Text>
        </View>

        <Heading
          tx="Modules.Add.SuccessfullyConnected.title"
          level={2}
          style={styles.heading}
        />

        <TextInput
          txLabel="Modules.Add.SuccessfullyConnected.moduleName"
          autoComplete="off"
          value={moduleName}
          onChangeText={changeModuleName}
          errorText={error}
        />

        <View>
          <Button
            type="primary"
            onPress={finishHandler}
            tx="Modules.Add.SuccessfullyConnected.finish"
          >
            Finish
          </Button>
          <Button
            onPress={connectAnotherHandler}
            tx="Modules.Add.SuccessfullyConnected.connectAnother"
            style={styles.connectAnother}
            type="ghost"
          >
            Connect another one
          </Button>
        </View>
      </View>
    </Screen>
  );
});
