import React, { memo } from "react";
import { StyleSheet } from "react-native";

import { useStores } from "@models/index";
import { Checkbox, Text } from "@components/ui";
import { CustomControl } from "@components/inputs";
import { FilterItemComponentProps } from "@components/screen/Filter";
import { ModuleCardBadges } from "@screens/Modules/Details/components/Items/ModuleCardBadges";

import { spacing } from "../../../theme";
import * as SensorIcons from "../../../svgs/sensors";
import { NoneIcon } from "../../../svgs";
import { useAppTheme } from "../../../hooks";
import { normalizeFontSize } from "../../../utils/sizes";

const styles = StyleSheet.create({
  item: {
    height: 56,
    marginBottom: spacing[2],
  },
  text: {
    fontSize: normalizeFontSize(14, 16),
  },
});

export const FilterItem = memo(
  ({
    item,
    isChecked,
    drag,
    handleChangeCheckbox,
  }: FilterItemComponentProps) => {
    const theme = useAppTheme() as any;
    const { sensorStore } = useStores();

    const { uid, iconName, needCalibration, isOffline, isDisconnect } =
      sensorStore.getSensor(item.uid);
    const Icon = SensorIcons[iconName] || NoneIcon;

    const onPress = () => {
      handleChangeCheckbox(uid);
    };

    return (
      <>
        <ModuleCardBadges>
          {needCalibration && <ModuleCardBadges.BadgeCalibration />}
          {isOffline && <ModuleCardBadges.BadgeOffline />}
          {isDisconnect && <ModuleCardBadges.BadgeDisconnect />}
        </ModuleCardBadges>

        <CustomControl
          onLongPress={drag}
          key={`filter-item-${uid}`}
          onPress={onPress}
          style={styles.item}
          icon={<Icon size={24} color={theme.colors.label} />}
          control={
            <Checkbox
              status={isChecked ? "checked" : "unchecked"}
              onPress={onPress}
            />
          }
        >
          <Text style={styles.text} numberOfLines={1} ellipsizeMode="tail">
            {item.displayName}
          </Text>
        </CustomControl>
      </>
    );
  },
);
