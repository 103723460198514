import Svg, { SvgProps, Path } from "react-native-svg";

export const RequestSentSuccess = (props: SvgProps) => (
  <Svg width={360} height={250} fill="none" viewBox="0 0 360 250" {...props}>
    <Path
      d="M254.352 236.725h-151.62c-17.23 0-27.9-18.78-19.06-33.58l74.39-124.58c9.2-15.41 31.51-15.42 40.73-.03l74.6 124.58c8.87 14.8-1.79 33.61-19.04 33.61ZM68.742 167.675h18.67c2.12 0 3.44 2.31 2.35 4.14l-9.16 15.34c-1.13 1.9-3.88 1.9-5.02 0l-9.19-15.34c-1.09-1.82.22-4.14 2.35-4.14ZM235.492 85.395h27.24c3.1 0 5.01 3.37 3.42 6.03l-13.36 22.38c-1.65 2.77-5.66 2.77-7.32 0l-13.4-22.38c-1.59-2.65.32-6.03 3.42-6.03Z"
      fill="#B0EACC"
    />
    <Path
      d="M149.872 85.395h-5.5"
      stroke="#2A2E32"
      strokeWidth={2.196}
      strokeLinejoin="round"
    />
    <Path
      d="M133.272 85.395h-37.94c-10.43 0-18.88 8.45-18.88 18.88v1.28c0 10.43 8.45 18.88 18.88 18.88h158.29c10.43 0 18.88 8.45 18.88 18.88s-8.45 18.88-18.88 18.88h-11.33"
      stroke="#2A2E32"
      strokeWidth={2.196}
      strokeLinejoin="round"
      strokeDasharray="11.1 11.1"
    />
    <Path
      d="M236.742 162.205h-5.5"
      stroke="#2A2E32"
      strokeWidth={2.196}
      strokeLinejoin="round"
    />
    <Path
      d="M223.632 179.755h-90.16c-5.59 0-10.12-4.53-10.12-10.12v-57.69c0-5.59 4.53-10.12 10.12-10.12h90.16c5.59 0 10.12 4.53 10.12 10.12v57.69c0 5.59-4.53 10.12-10.12 10.12Z"
      fill="#fff"
      stroke="#2A2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path d="m233.562 115.975-55.01 31.9-55.01-31.9" fill="#fff" />
    <Path
      d="m233.562 115.975-55.01 31.9-55.01-31.9M118.872 140.785h-35.57M118.872 157.135h-21.87"
      stroke="#2A2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M233.752 206.525c14.785 0 26.77-11.985 26.77-26.77s-11.985-26.77-26.77-26.77c-14.784 0-26.77 11.985-26.77 26.77s11.986 26.77 26.77 26.77Z"
      fill="#fff"
      stroke="#2A2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="m242.102 172.165-8.57 8.57-8.7-8.7a5.523 5.523 0 0 0-7.8 0 5.523 5.523 0 0 0 0 7.8l12.67 12.66a5.426 5.426 0 0 0 7.66 0l22.42-22.42a5.523 5.523 0 0 0 0-7.8 5.523 5.523 0 0 0-7.8 0l-2.71 2.71"
      fill="#B0EACC"
    />
    <Path
      d="m242.102 172.165-8.57 8.57-8.7-8.7a5.523 5.523 0 0 0-7.8 0 5.523 5.523 0 0 0 0 7.8l12.67 12.66a5.426 5.426 0 0 0 7.66 0l22.42-22.42a5.523 5.523 0 0 0 0-7.8 5.523 5.523 0 0 0-7.8 0l-2.71 2.71"
      stroke="#2A2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M128.763 227.775h6.88c6.33 0 11.46-5.13 11.46-11.46v-9.8h-6.88c-6.33 0-11.46 5.13-11.46 11.46v9.8Z"
      fill="#fff"
      stroke="#2A2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="m137.933 217.155-9.17 10.62v7.73"
      stroke="#2A2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M128.762 216.105h-6.88c-6.33 0-11.46-5.13-11.46-11.46v-9.8h6.88c6.33 0 11.46 5.13 11.46 11.46v9.8Z"
      fill="#fff"
      stroke="#2A2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="m119.593 205.485 9.17 10.62v7.74"
      stroke="#2A2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M175.992 227.775h6.88c6.33 0 11.46-5.13 11.46-11.46v-9.8h-6.88c-6.33 0-11.46 5.13-11.46 11.46v9.8Z"
      fill="#fff"
      stroke="#2A2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="m185.152 217.155-9.16 10.62v7.73"
      stroke="#2A2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M175.992 216.105h-6.88c-6.33 0-11.46-5.13-11.46-11.46v-9.8h6.88c6.33 0 11.46 5.13 11.46 11.46v9.8Z"
      fill="#fff"
      stroke="#2A2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="m166.822 205.485 9.17 10.62v7.74M110.152 235.505h46.67M164.412 235.505h34.91"
      stroke="#2A2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
  </Svg>
);
