import React, { useEffect, useState } from "react";
import {
  Dimensions,
  FlatList,
  Image,
  ImageSourcePropType,
  StyleSheet,
  View,
} from "react-native";
import { useTheme } from "@react-navigation/native";

import { spacing } from "../../../../theme";
import CarouselEllipseIcon from "../../../../svgs/CarouselEllipseIcon";

const styles = StyleSheet.create({
  slider: {
    flex: 1,
    alignItems: "center",
  },

  sliderPagination: {
    flexDirection: "row",
    bottom: 0,
    alignSelf: "center",
    marginBottom: spacing[2],
  },
  paginationItem: {
    marginRight: spacing[3],
  },
});

type Props = {
  children?: React.ReactNode;
  images?: ImageSourcePropType[];
  disabled?: boolean;
  currentItem?: number;
  showPagination?: boolean;
};

export const MODULE_PICTURE_SIZE = {
  width: 220,
  height: 182,
};

export const PictureSlider = ({
  children,
  images,
  disabled,
  currentItem,
  showPagination = true,
}: Props) => {
  const theme = useTheme() as any;
  const { width } = Dimensions.get("window");

  const opacity = disabled ? 0.6 : 1;
  const [activePaginationItem, setActivePaginationItem] = useState<number>(0);

  const onScrollHandler = ({ nativeEvent }) => {
    const slide = Math.ceil(nativeEvent.contentOffset.x / width);
    if (slide !== activePaginationItem) {
      setActivePaginationItem(slide);
    }
  };

  const flatListRef = React.useRef<FlatList>();
  const goToSlide = (index: number) => {
    flatListRef.current?.scrollToOffset({
      offset: index * width,
      animated: false,
    });
  };

  useEffect(() => {
    goToSlide(currentItem);
  }, [currentItem]);

  return (
    <View style={[styles.slider]}>
      <FlatList
        ref={flatListRef}
        data={images}
        bounces={false}
        style={[MODULE_PICTURE_SIZE, { opacity }]}
        keyExtractor={(item) => item}
        initialNumToRender={images.length}
        onScroll={onScrollHandler}
        horizontal
        pagingEnabled
        showsHorizontalScrollIndicator={false}
        scrollEnabled
        renderItem={({ item }) => (
          <Image style={MODULE_PICTURE_SIZE} source={item} />
        )}
      />

      {showPagination && (
        <View style={styles.sliderPagination}>
          {images.map((image, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <View key={`pagination-${index}`} style={styles.paginationItem}>
              <CarouselEllipseIcon
                color={
                  index === activePaginationItem
                    ? theme.colors.text
                    : theme.colors.cardDisabled
                }
              />
            </View>
          ))}
        </View>
      )}

      {children}
    </View>
  );
};
