import React, { useMemo, useState } from "react";
import {
  View,
  StyleSheet,
  StyleProp,
  ViewStyle,
  TextStyle,
  Platform,
} from "react-native";
import { TextInput as Input } from "react-native-paper";

import { Text } from "../ui/Text";
import { spacing } from "../../theme";
import { useAppTheme } from "../../hooks";
import { translate, TxKeyPath } from "../../i18n";
import { Label } from "./Label";

const createStyles = (theme: any) =>
  StyleSheet.create({
    container: {
      width: "100%",
      marginVertical: spacing[2],
    },
    input: {
      backgroundColor: theme.colors.inputBG,
      height: 48,
      lineHeight: Platform.OS === "ios" ? 24 : 32,
      fontSize: 18,
      paddingVertical: 0,
    },
    inputError: {
      backgroundColor: theme.colors.errorBG,
    },
    labelRow: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    hintLabel: {
      fontSize: 12,
      color: theme.colors.label,
    },
    error: {
      fontSize: 12,
      color: theme.colors.error,
      paddingTop: spacing[1],
    },
    description: {
      fontSize: 12,
      marginTop: spacing[1],
      paddingHorizontal: spacing[1],
      color: theme.colors.label,
    },
  });

type Props = React.ComponentProps<typeof Input> & {
  errorText?: string;
  hasError?: boolean;
  txLabel?: TxKeyPath;
  txHintLabel?: TxKeyPath;
  hintLabel?: string;
  description?: string;
  txDescription?: TxKeyPath;
  labelStyle?: StyleProp<TextStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  inputStyle?: StyleProp<TextStyle>;
};

export const TextInput = ({
  errorText,
  hasError,
  txLabel,
  label,
  txHintLabel,
  hintLabel,
  description,
  txDescription,
  labelStyle,
  containerStyle,
  inputStyle,
  ...props
}: Props) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  const i18nLabel = txLabel && translate(txLabel);
  const labelContent = i18nLabel || label;
  const i18nHintLabel = txHintLabel && translate(txHintLabel);
  const hintLabelContent = i18nHintLabel || hintLabel;
  const i18nDescription = txDescription && translate(txDescription);
  const descriptionContent = i18nDescription || description;
  const [isFocus, setIsFocus] = useState(false);
  const outlineColor = isFocus
    ? theme.colors.inputBorderFocus
    : theme.colors.inputBorder;
  const isError = !!errorText || hasError;
  const calculatedInputStyle = [
    styles.input,
    isError && styles.inputError,
    inputStyle,
  ];

  return (
    <View style={[styles.container, containerStyle]}>
      <View style={styles.labelRow}>
        {labelContent ? (
          <Label style={labelStyle} isFocus={isFocus} isError={isError}>
            {labelContent}
          </Label>
        ) : null}
        {hintLabelContent ? (
          <Text style={styles.hintLabel}>{hintLabelContent}</Text>
        ) : null}
      </View>
      <Input
        style={calculatedInputStyle}
        selectionColor={theme.colors.primary}
        underlineColor="transparent"
        underlineColorAndroid="transparent"
        mode="outlined"
        error={isError}
        outlineColor={outlineColor}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        {...props}
      />
      {descriptionContent ? (
        <Text style={styles.description}>{descriptionContent}</Text>
      ) : null}
      {errorText ? <Text style={styles.error}>{errorText}</Text> : null}
    </View>
  );
};
