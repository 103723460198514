import * as React from "react";
import Svg, { Path } from "react-native-svg";

export const StatusOfflineSvg = ({ size = 45, color = "#2B2E32" }) => (
  <Svg width={size} height={size} viewBox="0 0 44 45" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8496 6.50815C16.4019 6.50815 16.8496 6.95586 16.8496 7.50815V13.7081H25.6485V7.50815C25.6485 6.95586 26.0962 6.50815 26.6485 6.50815C27.2008 6.50815 27.6485 6.95586 27.6485 7.50815V13.7081H29.736L33.5429 9.90114C33.9334 9.51061 34.5666 9.51061 34.9571 9.90114C35.3477 10.2917 35.3477 10.9248 34.9571 11.3154L10.4571 35.8154C10.0666 36.2059 9.43345 36.2059 9.04292 35.8154C8.6524 35.4248 8.6524 34.7917 9.04292 34.4011L13.282 30.1621C13.2263 30.1254 13.1735 30.0826 13.1245 30.0336C11.9243 28.8334 11.25 27.2055 11.25 25.5081V14.7081C11.25 14.1558 11.6977 13.7081 12.25 13.7081H14.8496V7.50815C14.8496 6.95586 15.2974 6.50815 15.8496 6.50815ZM14.6673 28.7768L27.736 15.7081H26.6584L26.6485 15.7081L26.6385 15.7081H15.8596L15.8496 15.7081L15.8397 15.7081H13.25V25.5081C13.25 26.6751 13.7136 27.7942 14.5388 28.6194C14.5877 28.6683 14.6306 28.7211 14.6673 28.7768ZM30.25 19.1081C30.8023 19.1081 31.25 19.5558 31.25 20.1081V25.5081C31.25 27.2055 30.5757 28.8334 29.3755 30.0336C28.1753 31.2338 26.5474 31.9081 24.85 31.9081H22.2491V34.5081C22.2491 35.1976 22.523 35.8589 23.0106 36.3465C23.4982 36.8341 24.1595 37.1081 24.8491 37.1081H30.2491C30.8013 37.1081 31.2491 37.5558 31.2491 38.1081C31.2491 38.6603 30.8013 39.1081 30.2491 39.1081H24.8491C23.6291 39.1081 22.459 38.6234 21.5964 37.7607C20.7337 36.8981 20.2491 35.728 20.2491 34.5081V31.9081H19.25C18.6977 31.9081 18.25 31.4604 18.25 30.9081C18.25 30.3558 18.6977 29.9081 19.25 29.9081H21.239L21.2491 29.9081L21.2591 29.9081H24.85C26.017 29.9081 27.1361 29.4445 27.9613 28.6194C28.7865 27.7942 29.25 26.6751 29.25 25.5081V20.1081C29.25 19.5558 29.6977 19.1081 30.25 19.1081Z"
      fill={color}
    />
  </Svg>
);
