import React, { useMemo } from "react";
import { StyleSheet } from "react-native";
import { useNavigation, useTheme } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";

import { Text } from "@components/ui";
import { SensorsNavigatorParamList } from "@screens/Sensors/navigation";

import { CalibrationSuccessSvg } from "../../../../svgs/pictures/calibration";
import { translate } from "../../../../i18n";
import { CalibrationContent } from "./CalibrationContent";

const createStyles = (theme: any) =>
  StyleSheet.create({
    description: {
      color: theme.colors.label,
      textAlign: "center",
    },
  });

export const CalibrationStepSuccess = () => {
  const theme = useTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);

  const navigation =
    useNavigation() as StackNavigationProp<SensorsNavigatorParamList>;

  const onSubmit = () => {
    navigation.navigate("sensors-list");
  };

  return (
    <CalibrationContent
      Picture={CalibrationSuccessSvg}
      title={translate("Sensors.Calibration.sensorHasBeenCalibrated")}
      submitLabel={translate("common.done")}
      onSubmit={onSubmit}
    >
      <Text style={styles.description}>
        {translate("Sensors.Calibration.successMessage")}
      </Text>
    </CalibrationContent>
  );
};
