import React, { useCallback, useMemo } from "react";
import { StyleSheet } from "react-native";
import { useTheme } from "@react-navigation/native";
import { List } from "react-native-paper";

import { spacing } from "../../theme";
import RightArrowIcon from "../../svgs/RightArrowIcon";

const createStyles = (theme: any) =>
  StyleSheet.create({
    section: {
      backgroundColor: theme.colors.background,
    },

    item: {
      borderRadius: spacing[4],
      marginBottom: spacing[3],
      backgroundColor: theme.colors.cardBG,
    },
    icon: {
      marginVertical: 0,
    },
    description: {
      fontSize: 12,
    },
  });

type SectionProps = React.ComponentProps<typeof List.Section>;

function SelectionMenu(props: SectionProps) {
  const { children } = props;
  const theme = useTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <List.Section style={[styles.section, props.style]}>
      {children}
    </List.Section>
  );
}

type ItemProps = React.ComponentProps<typeof List.Item> & {
  type?: "nav" | "button";
  icon?: any;
  color?: string;
  iconColor?: string;
};

function MenuItem(props: ItemProps) {
  const { type = "nav", icon, color, iconColor, ...otherProps } = props;
  const theme = useTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);

  const titleStyle = {
    ...theme.fonts.medium,
    color,
  };
  const iconStyle =
    type === "button" || !otherProps.description ? styles.icon : undefined;

  const right = useCallback(
    () => (
      <List.Icon
        color={theme.colors.text}
        style={iconStyle}
        icon={RightArrowIcon}
      />
    ),
    [iconStyle],
  );

  const left = useCallback(
    () => (
      <List.Icon
        color={iconColor || theme.colors.label}
        icon={icon}
        style={iconStyle}
      />
    ),
    [iconStyle, color, icon],
  );

  return (
    <List.Item
      {...otherProps}
      titleStyle={titleStyle}
      descriptionStyle={styles.description}
      style={styles.item}
      left={left}
      right={type === "nav" ? right : undefined}
    />
  );
}

SelectionMenu.Item = MenuItem;

export default SelectionMenu;
