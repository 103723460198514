import { View, StyleSheet } from "react-native";
import { observer } from "mobx-react-lite";

import { StackScreenProps } from "@react-navigation/stack";
import { LayoutCenter, TopBar, TopBarAlign } from "@components/layout";

import { Heading, SelectionMenu } from "../../../components/ui";
import { HelpIcon, ListIcon } from "../../../svgs/menu";
import { Screen } from "../../../components";
import { spacing } from "../../../theme";
import { translate } from "../../../i18n";
import { ReleaseNotesIcon } from "../../../svgs";
import { SettingsNavigatorParamList } from "../navigation";
import { HintSetting } from "./components";

const styles = StyleSheet.create({
  container: {
    padding: spacing[4],
    marginBottom: spacing[4],
  },
  heading: {
    marginBottom: spacing[4],
  },
  menu: {
    marginBottom: spacing[0],
  },
});

export const HelpScreen: React.FC<
  StackScreenProps<SettingsNavigatorParamList, "settings-help">
> = observer(({ navigation }) => {
  const faqHandler = () => navigation.navigate("settings-help-faq");
  const manualHandler = () => navigation.navigate("settings-help-manual");
  const releaseNotesHandler = () =>
    navigation.navigate("settings-help-release-notes");

  const onboardingHandler = () =>
    navigation.navigate("settings-onboarding", { isNeedNavigate: true });

  return (
    <Screen
      preset="scroll"
      header={
        <TopBar
          title={translate("Settings.help")}
          align={TopBarAlign.Left}
          back
        />
      }
      testID="Help"
    >
      <View testID="Help" style={styles.container}>
        <LayoutCenter style={styles.heading}>
          <Heading level={2}>{translate("Settings.hello!")}</Heading>
          <Heading level={2}>{translate("Settings.helpYou?")}</Heading>
        </LayoutCenter>

        <SelectionMenu style={styles.menu}>
          <SelectionMenu.Item
            title={translate("Settings.faq.title")}
            icon={HelpIcon}
            onPress={faqHandler}
          />
          <SelectionMenu.Item
            title={translate("Settings.manual")}
            icon={ListIcon}
            onPress={manualHandler}
          />
          <SelectionMenu.Item
            title={translate("Settings.ReleaseNotes.title")}
            icon={ReleaseNotesIcon}
            onPress={releaseNotesHandler}
          />
        </SelectionMenu>

        <HintSetting />

        <SelectionMenu style={styles.menu}>
          <SelectionMenu.Item
            title={translate("Settings.startOnboardingTutorial")}
            icon={HelpIcon}
            onPress={onboardingHandler}
          />
        </SelectionMenu>
      </View>
    </Screen>
  );
});
