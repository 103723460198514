import React from "react";
import { RadioButton as PaperRadioButton } from "react-native-paper";
import { useAppTheme } from "../../hooks";

type Props = React.ComponentProps<typeof PaperRadioButton>;

export function RadioButton({ color, ...props }: Props) {
  const { colors } = useAppTheme();

  return (
    <PaperRadioButton.Android
      color={color || colors.primary}
      {...props}
      uncheckedColor={color || colors.primary}
    />
  );
}
