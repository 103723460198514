import { StyleSheet } from "react-native";

import { THintsDataItem } from "@components/hints";

import { translate } from "../../../../i18n";
import { InputsRange } from "./components";

const dialogStyle = StyleSheet.create({
  dialog: {
    position: "absolute",
    top: 204,
    left: 0,
    right: 0,
  },
});

const SettingMinRange = () => InputsRange({ disabledMax: true });
const SettingMaxRange = () => InputsRange({ disabledMin: true });

export const SensorsRangeHintsData = (): THintsDataItem[] => [
  {
    id: "SettingMinRange",
    title: translate("Hints.title"),
    text: translate("Hints.SensorRange.textHintSettingRange", {
      value: translate("Hints.SensorRange.Minimum"),
    }),
    HintComponent: SettingMinRange,
    dialogStyle: dialogStyle.dialog,
  },
  {
    id: "SettingMaxRange",
    title: translate("Hints.title"),
    text: translate("Hints.SensorRange.textHintSettingRange", {
      value: translate("Hints.SensorRange.Maximum"),
    }),
    HintComponent: SettingMaxRange,
    dialogStyle: dialogStyle.dialog,
  },
];
