import { View, StyleSheet, TouchableOpacity } from "react-native";
import { observer } from "mobx-react-lite";

import { Text } from "@components/ui";
import { useStores } from "@models/index";
import { goToAppUpdate } from "@models/settings/versions-store";

import { APIServer } from "../../services/api/api-config";
import { DefaultTheme, palette } from "../../theme";
import { translate } from "../../i18n";

const styles = StyleSheet.create({
  block: {
    width: "100%",
    alignItems: "center",
  },
  [`block-${APIServer.Dev}`]: {
    backgroundColor: palette.Blue,
  },
  [`block-${APIServer.Stage}`]: {
    backgroundColor: palette.Green,
  },
  [`block-${APIServer.Custom}`]: {
    backgroundColor: palette.RedNew,
  },
  blockOutdated: {
    backgroundColor: palette.RedNew,
  },

  text: {
    color: palette.White,
    fontSize: 8,
    textTransform: "uppercase",
    ...DefaultTheme.fonts?.bold,
  },
});

const OutdatedWarning = observer(() => {
  const { settingsStore } = useStores();

  if (!settingsStore.versions?.isNewAppAvailable) {
    return null;
  }

  return (
    <TouchableOpacity
      style={[styles.block, styles.blockOutdated]}
      onPress={goToAppUpdate}
    >
      <Text numberOfLines={1} style={styles.text}>
        {translate("Settings.UpdateApp.newVersionIsAvailable", {
          currentVersion: settingsStore.versions?.currentAppVersion,
          newVersion: settingsStore.versions?.latestAppVersion,
        })}
      </Text>
    </TouchableOpacity>
  );
});

export const CurrentServer = observer(() => {
  const { accountStore, settingsStore } = useStores();
  const serverName = settingsStore?.DEV_apiServer;

  if (
    !settingsStore?.DEV_apiServer ||
    settingsStore.DEV_apiServer === APIServer.Prod
  ) {
    return <OutdatedWarning />;
  }

  const labels = [
    settingsStore.DEV_apiServer,
    settingsStore.versions?.currentAppVersion,
  ];
  if (accountStore.currentUser?.email) {
    labels.push(accountStore.currentUser.email);
  }

  return (
    <View style={[styles.block, styles[`block-${serverName}`]]}>
      <Text numberOfLines={1} style={styles.text}>
        {labels.join(" / ")}
      </Text>
    </View>
  );
});
