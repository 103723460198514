import React, { useEffect, useMemo, useState } from "react";
import { Platform, StyleSheet, TouchableOpacity, View } from "react-native";
import { observer } from "mobx-react-lite";

import { Screen } from "@components/index";
import { StackScreenProps } from "@react-navigation/stack";
import { LayoutCenter } from "@components/layout";
import { Button, ErrorText, Form, Heading, Text } from "@components/ui";
import { PasswordInput, TextInput } from "@components/inputs";

import { useAppTheme } from "../../hooks";
import { emailValidator, requiredStringValidator } from "../../utils/validator";
import { GuestNavigatorParamList } from "../../navigators/GuestStack";
import { UserLogin, useStores } from "../../models";
import { spacing } from "../../theme";
import { LoginBG } from "../../svgs/pictures";
import { SignInWithBiometric } from "./components/SignInWithBiometric";

const createStyles = (theme: any) =>
  StyleSheet.create({
    screen: {
      padding: spacing[4],
      paddingTop: 0,
    },
    image: {
      marginTop: -spacing[5],
    },
    heading: {
      marginTop: 0,
      marginBottom: spacing[2],
    },
    label: { color: theme.colors.label },

    buttons: {
      flexDirection: "row",
    },

    iconButton: {
      backgroundColor: theme.colors.primary,
      borderRadius: spacing[4],
      marginVertical: spacing[2],
      marginLeft: spacing[4],
      marginHorizontal: 0,
      height: 46,
      width: 64,
    },
  });

export const SignInScreen: React.FC<
  StackScreenProps<GuestNavigatorParamList, "SignIn">
> = observer(({ navigation }) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  const [formData, setFormData] = useState<UserLogin>({
    username: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    username: undefined,
    password: undefined,
  });
  const { accountStore } = useStores();

  const [touchCount, setTouchCount] = useState(0);

  const submitHandler = async () => {
    const newErrors = {
      username: emailValidator(formData.username),
      password: requiredStringValidator(formData.password),
    };
    setErrors(newErrors);
    if (newErrors.username || newErrors.password) return;

    await accountStore.login(formData.username, formData.password);
  };

  const setFormDataHandler = (key, value) => {
    setErrors({ ...errors, [key]: undefined });
    setFormData({ ...formData, [key]: value });
  };
  const signUpHandler = () => navigation.navigate("SignUp");
  const passRecoveryHandler = () => navigation.navigate("ForgotPassword");

  useEffect(() => {
    if (!formData.password && !formData.username) accountStore.resetErrors();
  }, [formData, accountStore]);

  const logoTouchHandler = () => {
    if (!touchCount) {
      setTimeout(() => setTouchCount(0), 10000);
    }
    if (touchCount >= 20) {
      navigation.navigate("dev-settings");
    }
    setTouchCount((prevTouchCount) => prevTouchCount + 1);
  };

  return (
    <Screen testID="SignIn" keyboard>
      <LayoutCenter style={styles.screen}>
        <TouchableOpacity style={styles.image} onPress={logoTouchHandler}>
          <LoginBG />
        </TouchableOpacity>

        <Heading style={styles.heading} tx="Auth.welcomeBack" />
        {accountStore.statusErrors?.length ? (
          <ErrorText>{accountStore.statusErrors.join()}</ErrorText>
        ) : (
          <Text tx="Auth.enterEmailAndPassword" style={styles.label} />
        )}

        <Form.Container>
          <Form.Inputs>
            <TextInput
              txLabel="fields.email"
              onChangeText={(value) => setFormDataHandler("username", value)}
              value={formData.username}
              errorText={errors.username}
              returnKeyType="next"
              autoCapitalize="none"
              textContentType="emailAddress"
              keyboardType="email-address"
              blurOnSubmit={false}
            />
            <PasswordInput
              value={formData.password}
              errorText={errors.password}
              onChangeText={(value) => setFormDataHandler("password", value)}
              onSubmitEditing={submitHandler}
            />
          </Form.Inputs>

          <View style={styles.buttons}>
            <Button
              type="primary"
              onPress={submitHandler}
              tx="Auth.logIn"
              style={{ flex: 1, width: "auto" }}
            >
              Login
            </Button>
            {Platform.OS !== "web" && (
              <SignInWithBiometric style={styles.iconButton} />
            )}
          </View>
        </Form.Container>
        <Form.Links>
          <Button type="link" onPress={signUpHandler} tx="Auth.createAccount">
            Create account
          </Button>
          <Button
            type="link"
            onPress={passRecoveryHandler}
            tx="Auth.forgotPassword"
          >
            Forgot password?
          </Button>
        </Form.Links>
      </LayoutCenter>
    </Screen>
  );
});
