/* eslint-disable global-require */
export const MODULE_IMAGES = {
  dimmer: require("./dimmer.png"),
  "dimmer-back": require("./dimmer-back.png"),
  drycontact: require("./drycontact.png"),
  "drycontact-back": require("./drycontact-back.png"),
  hydro: require("./hydro.png"),
  "hydro-back": require("./hydro-back.png"),
  sensor: require("./sensor.png"),
  "sensor-back": require("./sensor-back.png"),
  server: require("./server.png"),
  "server-back": require("./server-back.png"),
  socket: require("./socket-eu.png"),
  "socket-back": require("./socket-eu-back.png"),
  default: require("./default.png"),
};
