import React, { useCallback, useEffect, useState } from "react";
import { Platform, StyleSheet, View, ViewStyle } from "react-native";
import { TextInput as PaperTextInput } from "react-native-paper";

import { MODULE_SSID_PREFIXES } from "@models/module/module";
import { Button, ErrorsMessage, Heading } from "@components/ui";
import { PasswordInput, SwitchInput, TextInput } from "@components/inputs";
import { ConfirmDialog, ConfirmType } from "@components/dialog";

import { requiredStringValidator } from "../../../../utils/validator";
import { translate } from "../../../../i18n";
import { spacing } from "../../../../theme";
import {
  getWifiSsid,
  isEnabledWiFi,
  isFrequencyMoreThan2GHz,
  valueMatchesPrefixes,
} from "../../../../utils/wifi";
import { rnLogger } from "../../../../services/logger";

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  heading: {
    textAlign: "center",
    marginHorizontal: spacing[5],
    marginBottom: spacing[5],
    marginTop: spacing[6],
  },
  containerFooter: {
    flex: 1,
    paddingVertical: spacing[2],
    justifyContent: "space-between",
  },
  button: {
    width: "auto",
    marginHorizontal: spacing[4],
    marginVertical: 0,
  },
  switch: {
    marginTop: spacing[4],
    paddingRight: spacing[1],
  },
  inputIcon: {
    marginTop: 0,
    marginBottom: 0,
  },
  errorMessage: {
    maxHeight: 112,
    marginBottom: spacing[4],
  },
});

const validationSsid = (value: string): string => {
  const errorStrting = requiredStringValidator(value);
  if (errorStrting) {
    return errorStrting;
  }

  if (value.includes(" ")) {
    return translate("Modules.Add.Manual.errors.ssidCannotContainSpaces");
  }
  return "";
};

type TErrors = {
  ssid?: string;
  password?: string;
};

export type TFormDataWifiInput = {
  ssid: string;
  password: string;
  isSave?: boolean;
};

type WifiInputProps = {
  ssid: string;
  password: string;
  onSubmit: (formData: TFormDataWifiInput) => void;
  style?: ViewStyle;
};

export const WiFiInput = ({
  ssid: initialSsid,
  password: initialPassword,
  onSubmit,
  style,
}: WifiInputProps) => {
  const [ssid, setSsid] = useState<string>(initialSsid);
  const [password, setPassword] = useState<string>(initialPassword);
  const [isSave, setIsSave] = useState<boolean>(true);
  const [errors, setErrors] = useState<TErrors>(null);
  const [isFrequencyConfirm, setIsFrequencyConfirm] = useState<boolean>(null);
  const [isDisabledWiFi, setDisabledWiFi] = useState<boolean>(false);

  const setCurrentSsid = useCallback(async () => {
    const result = await getWifiSsid();

    rnLogger.debug(`getWifiSsid result`, result); // TODO: remove !!!

    if (
      result.ssid &&
      !valueMatchesPrefixes(result.ssid, MODULE_SSID_PREFIXES)
    ) {
      setSsid(result.ssid);
    }
  }, [setSsid]);

  useEffect(() => {
    if (!initialSsid) {
      setSsid(initialSsid || "");
      setPassword(initialPassword || "");
      setCurrentSsid();
    }
  }, [initialSsid, initialPassword]);

  const submitHandler = () => {
    onSubmit({
      ssid,
      password,
      isSave,
    });
  };

  const nextHandler = async () => {
    const newErrors: TErrors = {
      ssid: validationSsid(ssid),
      password: requiredStringValidator(password),
    };
    setErrors(newErrors);
    if (newErrors.ssid || newErrors.password) return;

    if (Platform.OS === "android") {
      const isEnabled = await isEnabledWiFi();

      setDisabledWiFi(!isEnabled);
      if (!isEnabled) return;

      const isFrequencyNot2GHz = await isFrequencyMoreThan2GHz();
      setIsFrequencyConfirm(isFrequencyNot2GHz);
      if (!isFrequencyNot2GHz) {
        submitHandler();
      }
    } else {
      submitHandler();
    }
  };

  return (
    <View style={[styles.container, style]}>
      <Heading
        level={2}
        tx="Modules.Add.Manual.steps.wifiSetting"
        style={styles.heading}
      />

      <View>
        <TextInput
          txLabel="Modules.Add.Smart.wifissid"
          autoComplete="off"
          value={ssid}
          onChangeText={setSsid}
          errorText={errors?.ssid}
          right={
            <PaperTextInput.Icon
              style={styles.inputIcon}
              icon="wifi"
              onPress={setCurrentSsid}
            />
          }
        />
        <PasswordInput
          txLabel="Modules.Add.Smart.wifiPassword"
          autoComplete="off"
          value={password}
          onChangeText={setPassword}
          errorText={errors?.password}
        />

        <SwitchInput
          txLabel="Modules.Add.Smart.saveWiFiSettings"
          containerStyle={styles.switch}
          onChange={setIsSave}
          value={isSave}
        />
      </View>

      <View style={styles.containerFooter}>
        <ErrorsMessage
          errors={[translate("Modules.Add.Manual.attentionConnectModule")]}
          style={styles.errorMessage}
        />

        <Button style={styles.button} type="primary" onPress={nextHandler}>
          {translate("common.next")}
        </Button>
      </View>

      <ConfirmDialog
        content={translate("Modules.Add.Manual.frequencyValidationMessage")}
        okText={translate("common.continue")}
        type={ConfirmType.Warning}
        onOk={submitHandler}
        onCancel={() => setIsFrequencyConfirm(false)}
        visible={isFrequencyConfirm}
      />

      <ConfirmDialog
        content={translate("Modules.Add.Manual.isOffWiFiMessage")}
        type={ConfirmType.Warning}
        onCancel={() => setDisabledWiFi(false)}
        visible={isDisabledWiFi}
      />
    </View>
  );
};
