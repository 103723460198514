import * as React from "react";
import { TextStyle, StyleSheet, StyleProp } from "react-native";
import { useTheme } from "@react-navigation/native";

import { Text } from "./Text";
import { spacing } from "../../theme";

const styles = StyleSheet.create({
  text: {
    fontSize: 16,
    lineHeight: spacing[5],
    marginTop: spacing[2],
    marginBottom: spacing[1],
  },
});

type Props = React.ComponentProps<typeof Text> & {
  style?: StyleProp<TextStyle>;
  children: React.ReactNode;
};

export const Subheading = (props: Props) => {
  const { colors, fonts } = useTheme() as any;
  const themeStyle = {
    ...fonts.medium,
    color: colors.subheading,
  };
  const { style } = props;

  return <Text {...props} style={[styles.text, themeStyle, style]} />;
};
