import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

export const CalibrationORP475ErrorSvg = (props: SvgProps) => (
  <Svg width={287} height={172} fill="none" {...props}>
    <Path
      fill="#FBE3E3"
      d="M215.945 170.086H64.325c-17.23 0-27.9-18.82-19.06-33.651l74.39-124.845c9.2-15.443 31.51-15.453 40.73-.03l74.6 124.844c8.87 14.832-1.79 33.682-19.04 33.682Z"
    />
    <Path
      fill="#B6C5D3"
      d="M136.276 166.634c18.122 0 32.813-4.459 32.813-9.961 0-5.501-14.691-9.961-32.813-9.961-18.123 0-32.814 4.46-32.814 9.961 0 5.502 14.691 9.961 32.814 9.961Z"
    />
    <Path
      stroke="#2A2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.261}
      d="M141.716 28.973v-1.73C141.716 14.96 131.705 5 119.356 5c-12.353 0-22.364 9.96-22.364 22.243v40.514C96.992 80.04 86.98 90 74.632 90h-2.094c-12.348 0-22.36-9.96-22.36-22.243"
    />
    <Path
      fill="#6A7B8C"
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.261}
      d="m144.789 42.379-2.768-21.058h-2.6l-2.768 21.058 4.068-.284 4.068.284ZM148.309 42.379h-15.108v15.108h15.108V42.38ZM148.309 121.393h-15.108v3.039h15.108v-3.039Z"
    />
    <Path
      stroke="#2A2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.261}
      d="M145.303 150.093h-9.104a2.995 2.995 0 0 1-2.998-2.998v-14.283h15.108v14.283a3.006 3.006 0 0 1-3.006 2.998Z"
    />
    <Path
      fill="#6A7B8C"
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.261}
      d="M145.067 132.812h-8.624v13.721h8.624v-13.721ZM150.069 57.48h-18.635v63.906h18.635V57.481Z"
    />
    <Path
      stroke="#2A2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.261}
      d="M150.069 124.425h-18.635v8.387h18.635v-8.387ZM133.303 47.264h15.217M133.303 52.239h15.217"
    />
    <Path
      fill="#70B8FF"
      fillOpacity={0.4}
      d="M140.378 116.242c15.857 0 28.711-3.935 28.711-8.789 0-4.855-12.854-8.79-28.711-8.79-15.858 0-28.712 3.935-28.712 8.79 0 4.854 12.854 8.789 28.712 8.789Z"
    />
    <Path
      fill="#308AE3"
      fillRule="evenodd"
      d="M169.089 107.453c0 4.854-12.854 8.789-28.711 8.789-15.851 0-28.702-3.932-28.712-8.784V154.12c0 4.964 12.86 8.998 28.712 8.998 15.851 0 28.711-4.034 28.711-8.998v-48.425l-.566.012c.371.564.566 1.148.566 1.746Zm-57.423-.006c0-.186.02-.371.057-.555l-.057.001v.554Z"
      clipRule="evenodd"
      opacity={0.6}
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M169.089 82.257v71.869c0 4.961-12.888 8.993-28.775 8.993-15.886 0-28.774-4.032-28.774-8.993V95.281c0-2.13-.865-4.16-2.393-5.633l-4.513-4.803"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M150.115 74.57c11.071 1.217 18.986 4.457 18.974 8.266 0 4.852-12.837 8.796-28.692 8.796-9.473 0-17.883-1.412-23.101-3.58a25.09 25.09 0 0 0-5.879-1.636l-5.764-.532c-1.107-.097-1.409-1.538-.417-2.041 2.99-1.51 7.201-3.622 9.156-4.49 4.104-1.83 9.419-3.768 16.634-4.713"
    />
    <Path
      stroke="#fff"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M160.808 129.261a72.12 72.12 0 0 1-10.417 2.361v7.771a68.8 68.8 0 0 0 5.237-.987c2.882-.649 5.18 1.237 5.18 4.46v.113c0 3.223-2.298 6.559-5.18 7.209a76.435 76.435 0 0 1-5.237.987M130.004 152.624v-21.751a221.245 221.245 0 0 1-11.236 11.019 73.238 73.238 0 0 0 12.257 2.611M133.963 131.973c4.126.337 8.264.312 12.391-.063v4.023a684.482 684.482 0 0 1-8.747 16.679"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M162.289 97.137v24.413"
    />
    <Path
      fill="#F76B6B"
      d="m232.266 45 7.513-7.687c3.201-3.276 3.201-8.59 0-11.856a8.06 8.06 0 0 0-11.587 0l-7.512 7.687-7.513-7.687a8.058 8.058 0 0 0-11.586 0c-3.202 3.276-3.202 8.59 0 11.856L209.093 45l-7.512 7.687c-3.202 3.276-3.202 8.59 0 11.856a8.058 8.058 0 0 0 11.586 0l7.513-7.687 7.512 7.687a8.06 8.06 0 0 0 11.587 0c3.201-3.276 3.201-8.59 0-11.856L232.266 45Z"
    />
  </Svg>
);
