// https://callstack.github.io/react-native-paper/theming-with-react-navigation.html

import { DefaultTheme as NavigationDefaultTheme } from "@react-navigation/native";
import {
  configureFonts,
  DefaultTheme as PaperDefaultTheme,
} from "react-native-paper";

import { defaultColors } from "./colors";
import { fontConfig } from "./fonts";
import { spacing } from "./spacing";

export const DefaultTheme = {
  ...PaperDefaultTheme,
  ...NavigationDefaultTheme,

  colors: {
    ...PaperDefaultTheme.colors,
    ...NavigationDefaultTheme.colors,
    ...defaultColors,
  },
  fonts: configureFonts({ config: fontConfig, isV3: false }),
  roundness: spacing[4],
  // for the dialog false is the text in uppercase
  isV3: true,
  version: 2,
};
