import { TOnboardingStep } from "@components/common";

import { translate } from "../../../../../i18n";
import {
  // Module30ftSvg,
  Module9mSvg,
  PowerOnSvg,
  Router24GHzSvg,
  VideoSvg,
} from "../../../../../svgs/pictures/onboarding-modules";

const videoLink = "https://www.youtube.com/watch?v=sz0JL_nEeN4";

// const Slide30ft = {
//   id: 1,
//   Picture: Module30ftSvg,
//   title: translate("Onboarding.ModuleConnection.title30feet"),
//   description: translate("Onboarding.ModuleConnection.content30feet"),
// };
const Slide9m = {
  id: 1,
  Picture: Module9mSvg,
  title: translate("Onboarding.ModuleConnection.title9m"),
  description: translate("Onboarding.ModuleConnection.content9m"),
};

export const ONBOARDING_STEPS: TOnboardingStep[] = [
  {
    id: 0,
    Picture: Router24GHzSvg,
    title: translate("Onboarding.ModuleConnection.title24GHz"),
    description: translate("Onboarding.ModuleConnection.content24GHz"),
    linkTitle: translate("Onboarding.ModuleConnection.ReadDetails"),
    pageId: "module-manual-onboarding-details",
  },
  Slide9m,
  {
    id: 3,
    Picture: VideoSvg,
    title: translate("Onboarding.ModuleConnection.titleSeeMore"),
    description: translate("Onboarding.ModuleConnection.contentSeeMore"),
    linkTitle: translate("Onboarding.ModuleConnection.SeeVideo"),
    linkUrl: videoLink,
  },
  {
    id: 4,
    Picture: PowerOnSvg,
    title: translate("Onboarding.ModuleConnection.titlePowerOn"),
    description: translate("Onboarding.ModuleConnection.contentPowerOn"),
  },
];
