const MS_IN_SEC = 1000;
const DEFAULT_TIMEOUT = 60;

type QueryAlias = string;
export type IsFetchTimeProps = {
  timeoutInSec?: number;
  force?: boolean;
};

export const withFetchTimeout = () => {
  const fetchingTimes: Record<QueryAlias, number> = {};

  return {
    actions: {
      // getFetchingTime(queryAlias: QueryAlias) {
      //   return fetchingTimes[queryAlias];
      // },
      //
      // setFetchingTime(queryAlias: QueryAlias) {
      //   fetchingTimes[queryAlias] = Date.now();
      // },

      clearFetchingTimes() {
        Object.keys(fetchingTimes).forEach((key) => delete fetchingTimes[key]);
      },

      clearFetchTime(queryAlias: QueryAlias) {
        delete fetchingTimes[queryAlias];
      },

      isFetchTime(
        queryAlias: QueryAlias,
        { timeoutInSec, force }: IsFetchTimeProps = {},
      ): boolean {
        if (force) {
          fetchingTimes[queryAlias] = Date.now();
          return true;
        }

        const prevTime = fetchingTimes[queryAlias];
        if (!prevTime) {
          fetchingTimes[queryAlias] = Date.now();
          return true;
        }

        const timeoutMs = timeoutInSec
          ? timeoutInSec * MS_IN_SEC
          : DEFAULT_TIMEOUT * MS_IN_SEC;
        if (Date.now() - prevTime > timeoutMs) {
          fetchingTimes[queryAlias] = Date.now();
          return true;
        }

        return false;
      },
    },
  };
};
