import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { ActivityIndicator } from "react-native-paper";

import { Text } from "./Text";

import { spacing } from "../../theme";
import { useAppTheme } from "../../hooks";

const createStyles = (theme: any) =>
  StyleSheet.create({
    overlay: {
      zIndex: 999,
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: theme.colors.loadingBG,
    },
    "size-small": {
      justifyContent: "center",
    },
    "size-large": {
      minHeight: 100,
    },
    text: {
      lineHeight: 32,
      fontSize: 24,
      color: theme.colors.loading,
      marginTop: spacing[4],
      ...theme.fonts.medium,
    },
  });

type Props = React.ComponentProps<typeof ActivityIndicator> & {
  withMask?: boolean;
  light?: boolean;
  text?: string;
};

export const Loading = ({
  size = "large",
  style,
  withMask = true,
  text,
  ...props
}: Props) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const defaultColor = withMask ? theme.colors.loading : theme.colors.primary;

  return (
    <View style={[withMask && styles.overlay, styles[`size-${size}`], style]}>
      <ActivityIndicator
        size={size}
        color={props.color || defaultColor}
        {...props}
      />
      {Boolean(text) && <Text style={styles.text}>{text}</Text>}
    </View>
  );
};
