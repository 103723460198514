import Svg, { SvgProps, Path } from "react-native-svg";

export const NoDetectedModules = (props: SvgProps) => {
  return (
    <Svg width={236} height={179} viewBox="0 0 236 179" fill="none" {...props}>
      <Path
        d="M193.548 169.725H41.9281C24.6981 169.725 14.0281 150.945 22.8681 136.145L97.2581 11.565C106.458 -3.845 128.768 -3.85499 137.988 11.535L212.588 136.115C221.458 150.915 210.798 169.725 193.548 169.725Z"
        fill="#FFF1F1"
      />
      <Path
        d="M82.598 29.5039H33.778C19.408 29.5039 7.74805 41.1539 7.74805 55.5339C7.74805 69.9039 19.398 81.5639 33.778 81.5639H196.828C217.398 81.5639 234.068 98.2339 234.068 118.804C234.068 139.374 217.398 156.044 196.828 156.044H191.008"
        stroke="#2B2E32"
        strokeWidth="2.1962"
        strokeMiterlimit="10"
        strokeLinejoin="round"
        strokeDasharray="12 12"
      />
      <Path
        d="M67.957 160.774H74.837C81.167 160.774 86.297 155.644 86.297 149.314V139.514H79.417C73.087 139.514 67.957 144.644 67.957 150.974V160.774Z"
        fill="white"
        stroke="#2B2E32"
        strokeWidth="2.1962"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <Path
        d="M77.127 150.153L67.957 160.773V168.503"
        stroke="#2B2E32"
        strokeWidth="2.1962"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <Path
        d="M67.9572 149.104H61.0772C54.7472 149.104 49.6172 143.974 49.6172 137.644V127.844H56.4972C62.8272 127.844 67.9572 132.974 67.9572 139.304V149.104Z"
        fill="white"
        stroke="#2B2E32"
        strokeWidth="2.1962"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <Path
        d="M58.7871 138.483L67.9571 149.103V156.843"
        stroke="#2B2E32"
        strokeWidth="2.1962"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <Path
        d="M115.188 160.774H122.068C128.398 160.774 133.527 155.644 133.527 149.314V139.514H126.648C120.318 139.514 115.188 144.644 115.188 150.974V160.774Z"
        fill="white"
        stroke="#2B2E32"
        strokeWidth="2.1962"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <Path
        d="M124.348 150.153L115.188 160.773V168.503"
        stroke="#2B2E32"
        strokeWidth="2.1962"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <Path
        d="M115.188 149.104H108.308C101.978 149.104 96.8477 143.974 96.8477 137.644V127.844H103.728C110.058 127.844 115.188 132.974 115.188 139.304V149.104Z"
        fill="white"
        stroke="#2B2E32"
        strokeWidth="2.1962"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <Path
        d="M106.018 138.483L115.188 149.103V156.843"
        stroke="#2B2E32"
        strokeWidth="2.1962"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <Path
        d="M49.3477 168.504H96.0177"
        stroke="#2B2E32"
        strokeWidth="2.1962"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <Path
        d="M103.607 168.504H138.517"
        stroke="#2B2E32"
        strokeWidth="2.1962"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <Path
        d="M74.4375 115.764H115.948H151.008H196.097C204.077 115.764 209.008 107.064 204.908 100.224L170.408 42.6044C168.287 39.0644 164.797 37.2844 161.167 37.2744H74.4375"
        fill="white"
      />
      <Path
        d="M74.4375 115.764H115.948H151.008H196.097C204.077 115.764 209.008 107.064 204.908 100.224L170.408 42.6044C168.288 39.0644 164.797 37.2844 161.167 37.2744H74.4375"
        stroke="#2B2E32"
        strokeWidth="2.1962"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <Path
        d="M109.498 115.764H39.3779C31.4079 115.764 26.4779 107.074 30.5579 100.234L64.9579 42.6144C69.2179 35.4844 79.5279 35.4844 83.7979 42.6044L118.298 100.224C122.408 107.064 117.478 115.764 109.498 115.764Z"
        fill="white"
        stroke="#2B2E32"
        strokeWidth="2.1962"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <Path
        d="M73.398 74.4836C73.628 74.1536 74.018 73.9536 74.428 73.9536C74.838 73.9536 75.208 74.1436 75.458 74.4736C75.488 74.5236 75.528 74.5636 75.538 74.6136L88.828 96.7336H93.938L76.368 67.5036C75.978 66.8136 75.228 66.3936 74.428 66.3936C73.648 66.3936 72.908 66.8036 72.518 67.4636L72.448 67.5736L54.918 96.7436H60.028L73.338 74.6036C73.348 74.5636 73.368 74.5136 73.398 74.4836Z"
        fill="#3F9A6E"
      />
      <Path
        d="M101.907 94.914L82.1273 61.9839L80.3973 59.104C79.1773 57.064 76.8873 55.7939 74.4473 55.7939C72.0073 55.7939 69.7173 57.064 68.4973 59.104L67.8273 60.2339L46.9873 94.914C45.7673 96.954 45.7673 99.4739 46.9873 101.514C48.2073 103.554 50.4973 104.824 52.9373 104.824H58.5273H58.8373H69.7473H69.8473C71.1673 104.874 72.1973 105.904 72.1973 107.174V112.294H76.6173V107.174C76.6173 103.544 73.5373 100.574 69.7473 100.574H52.8873C52.0373 100.554 51.2373 100.104 50.8173 99.4039C50.3773 98.6839 50.3773 97.774 50.8173 97.054L72.3673 61.214C72.8073 60.514 73.6273 60.074 74.4673 60.074C75.3173 60.074 76.1273 60.514 76.5673 61.214L98.1173 97.054C98.5573 97.774 98.5573 98.6839 98.1173 99.4039C97.6973 100.104 96.8973 100.564 96.0473 100.574H88.6073H88.4773C87.5973 100.574 86.7973 100.124 86.3573 99.4039L76.4273 82.8739C76.0373 82.1839 75.2873 81.7639 74.4873 81.7639C73.7073 81.7639 72.9673 82.174 72.5773 82.834L72.5073 82.944L64.1873 96.774H69.2873L73.3773 89.974C73.3973 89.944 73.4273 89.894 73.4473 89.864C73.6773 89.534 74.0673 89.334 74.4773 89.334C74.8873 89.334 75.2573 89.524 75.5073 89.854C75.5373 89.904 75.5673 89.944 75.5873 89.994L82.5373 101.544C83.7573 103.584 86.0473 104.854 88.4873 104.854H89.9873H93.2173H94.2773H94.5373H96.0073C98.4573 104.854 100.737 103.584 101.957 101.544C103.117 99.4739 103.127 96.944 101.907 94.914Z"
        fill="#3F9A6E"
      />
      <Path
        d="M183.249 12.3555H213.889C217.369 12.3555 219.529 16.1555 217.739 19.1455L202.709 44.3155C200.849 47.4255 196.339 47.4355 194.479 44.3255L179.409 19.1555C177.609 16.1555 179.769 12.3555 183.249 12.3555Z"
        fill="#FFF1F1"
      />
      <Path
        d="M181.008 156.032L188.588 148.452C191.818 145.222 191.818 139.982 188.588 136.762C185.358 133.532 180.118 133.532 176.898 136.762L169.318 144.342L161.738 136.762C158.508 133.532 153.267 133.532 150.048 136.762C146.818 139.992 146.818 145.232 150.048 148.452L157.628 156.032L150.048 163.612C146.818 166.842 146.818 172.082 150.048 175.302C153.278 178.532 158.518 178.532 161.738 175.302L169.318 167.722L176.898 175.302C180.128 178.532 185.368 178.532 188.588 175.302C191.818 172.072 191.818 166.832 188.588 163.612L181.008 156.032Z"
        fill="#F76B6B"
        stroke="#2B2E32"
        strokeWidth="2.1962"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <Path
        d="M3.69851 63.9023H28.9285C31.7985 63.9023 33.5685 67.0324 32.0985 69.4924L19.7185 90.2224C18.1885 92.7824 14.4785 92.7924 12.9385 90.2224L0.528511 69.4924C-0.941489 67.0324 0.828509 63.9023 3.69851 63.9023Z"
        fill="#FFF1F1"
      />
    </Svg>
  );
};

export default NoDetectedModules;
