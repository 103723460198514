import { MenuItem } from "@components/ui";

import { translate } from "../../../i18n";
import { DeleteIcon, EditIcon } from "../../../svgs/menu";
import { PowerIcon } from "../../../svgs";
import { defaultColors } from "../../../theme";

export enum MenuItemsKeys {
  "Edit" = "modules-edit",
  "Reboot" = "module-reboot",
  "Delete" = "module-delete",
}

export const getModuleDetailsMenuItems = (): MenuItem[] => [
  {
    key: MenuItemsKeys.Edit,
    title: translate("Modules.Details.editModule"),
    icon: EditIcon,
  },
  {
    key: MenuItemsKeys.Reboot,
    title: translate("Modules.Details.rebootModule"),
    icon: PowerIcon,
  },
  {
    key: MenuItemsKeys.Delete,
    title: translate("Modules.Details.delete"),
    icon: DeleteIcon,
    color: defaultColors.warning,
    topLine: true,
  },
];
