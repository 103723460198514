import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { observer } from "mobx-react-lite";

import {
  Button,
  ProgressBar,
  ProgressBarCounterProps,
  Text,
} from "@components/ui";
import { TextInput } from "@components/inputs";
import { CalibrationProcessStatus } from "@screens/Sensors/Calibration/types";

import { useAppTheme } from "../../../../../hooks";
import { translate } from "../../../../../i18n";
import { normalizeFontSize } from "../../../../../utils/sizes";
import { spacing } from "../../../../../theme";
import { CALIBRATION_STEPS_PROPS } from "../../constants";
import { CalibrationContent } from "../../components/CalibrationContent";
import { CalibrationContentProps } from "./types";
import { secondsToText } from "../../../../../utils/timeConverter";

const createStyles = (theme: any) =>
  StyleSheet.create({
    title: {
      fontSize: normalizeFontSize(24, 26),
      textAlign: "center",
      ...theme.fonts.medium,
    },
    titleContainer: {
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: spacing[4],
    },
    input: {
      fontSize: normalizeFontSize(24, 26),
      textAlign: "center",
      height: 40,
      lineHeight: 40,
      ...theme.fonts.medium,
      marginTop: -spacing[1],
    },
    inputContainer: {
      width: 80,
      marginRight: spacing[1],
      marginTop: spacing[0],
      marginBottom: spacing[0],
    },

    counterText: {
      fontSize: 16,
      marginTop: spacing[4],
      textAlign: "center",
    },
    buttonContainer: {
      width: "100%",
      paddingHorizontal: spacing[3],
      marginTop: spacing[2],
    },

    stopButton: {
      marginTop: spacing[4],
    },
  });

const ProgressCounter = ({
  durationMs,
  durationProgressMs,
}: ProgressBarCounterProps) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  if (!durationMs) {
    return null;
  }

  const valueInSec = Math.round((durationMs - durationProgressMs) / 1000);

  return (
    <Text style={styles.counterText}>
      {translate("Sensors.Calibration.calibrationWillBeFinishedAfter", {
        duration: secondsToText(valueInSec, "duration"),
      })}
    </Text>
  );
};

type CalibrationStepProps = CalibrationContentProps & {
  onStop: () => void;
  onExit: () => void;
};

export const CalibrationStepProcess = observer(
  ({ calibration, onStop, onExit }: CalibrationStepProps) => {
    const theme = useAppTheme();
    const styles = useMemo(() => createStyles(theme), [theme]);

    const status = calibration.isInProgress
      ? CalibrationProcessStatus.Process
      : "";

    const { withInput, afterInputText, title, titleTx, ...sensorTypeProps } =
      CALIBRATION_STEPS_PROPS[calibration.sensor?.type]?.[
        `${calibration.currentStep}${status}`
      ] ||
      CALIBRATION_STEPS_PROPS[calibration.sensor?.type]?.[
        calibration.currentStep
      ] ||
      {};

    if (calibration.isInProgress) {
      const remainingTime = calibration.getRemainingTime() * 1000 || undefined;

      return (
        <CalibrationContent
          title={
            titleTx
              ? translate(titleTx, { stepValue: calibration.stepValue || "-" })
              : title
          }
          itemsTitle={calibration.stepNameOf}
          // onSubmit={onStop}
          // submitLabel={translate("Sensors.Calibration.interrupt")}
          // submitType="secondary"
          {...sensorTypeProps}
          footer={
            <View style={styles.buttonContainer}>
              <Button type="primary" onPress={onExit}>
                {translate("Sensors.Calibration.temporarilyOut")}
              </Button>

              <Button
                type="secondary"
                onPress={onStop}
                style={styles.stopButton}
              >
                {translate("Sensors.Calibration.interrupt")}
              </Button>
            </View>
          }
        >
          <ProgressBar
            looped
            durationMs={calibration.isInProgress ? remainingTime : 0}
            afterEnd={calibration.fetchStatus}
            counter={ProgressCounter}
          />
        </CalibrationContent>
      );
    }

    return (
      <CalibrationContent
        title={withInput ? undefined : title}
        itemsTitle={calibration.stepNameOf}
        submitLabel={translate("Sensors.Calibration.Calibrate")}
        onSubmit={calibration.valueStart}
        {...sensorTypeProps}
      >
        {withInput ? (
          <>
            <Text style={styles.title}>{title}</Text>
            <View style={styles.titleContainer}>
              <TextInput
                keyboardType="numeric"
                value={(calibration.stepValue ?? "").toString()}
                onChangeText={calibration.setStepValue}
                maxLength={3}
                style={styles.input}
                containerStyle={styles.inputContainer}
              />
              <Text style={styles.title}>{afterInputText}</Text>
            </View>
          </>
        ) : null}
      </CalibrationContent>
    );
  },
);
