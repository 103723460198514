import React from "react";
import { StyleSheet, View } from "react-native";

import { Sensor } from "@models/index";
import { Screen } from "@components/index";
import { LayoutCenter, TopBar } from "@components/layout";
import { ScreenMessage } from "@components/ui";

import { NoAddedModules } from "../../../svgs/pictures";
import { spacing } from "../../../theme";
import { translate } from "../../../i18n";

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    marginHorizontal: spacing[7],
    marginBottom: spacing[5],
  },
  picture: {
    marginBottom: spacing[5],
  },
});

type Props = {
  sensor: Sensor;
};

export const CalibrationNotAvailable = ({ sensor }: Props) => {
  return (
    <Screen testID="CalibrationNotAvailable" header={<TopBar title="" back />}>
      <View style={{ flex: 1 }}>
        <LayoutCenter style={styles.container}>
          <NoAddedModules style={styles.picture} />
          <ScreenMessage
            title={translate("Sensors.Calibration.isNotAvailable")}
            message={translate("Sensors.Calibration.isNotAvailableFor", {
              type: sensor.displayType,
            })}
          />
        </LayoutCenter>
      </View>
    </Screen>
  );
};
