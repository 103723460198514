import { StyleSheet } from "react-native";
import { spacing } from "../../theme";

export const gridStyles = StyleSheet.create({
  container: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: spacing[2],
    marginLeft: -spacing[3],
    marginTop: -spacing[3],
  },
  item: {
    flexGrow: 1,
    flexBasis: "40%",
    marginLeft: spacing[3],
    marginTop: spacing[3],
  },
});
