import React, { useState } from "react";
import { StyleSheet } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";

import { useStores } from "@models/index";
import { Screen } from "@components/index";
import { LayoutCenter, TopBar, TopBarAlign } from "@components/layout";
import { Button, ErrorText, Form } from "@components/ui";
import { PasswordInput } from "@components/inputs";

import {
  confirmPasswordValidator,
  newPasswordValidator,
} from "../../../utils/validator";
import { spacing } from "../../../theme";
import { translate } from "../../../i18n";
import { SettingsNavigatorParamList } from "../navigation";

const styles = StyleSheet.create({
  screen: {
    padding: spacing[4],
  },
  heading: {
    marginTop: 124,
  },
  enterEmailText: {
    marginBottom: 64,
  },
  buttonSetPassword: {
    marginTop: "auto",
    marginBottom: 38,
  },
});

export function ChangePasswordScreen() {
  const [formData, setFormData] = useState({
    oldPassword: undefined,
    password: undefined,
    confirmPassword: undefined,
  });
  const [errors, setErrors] = useState({
    oldPassword: undefined,
    password: undefined,
    confirmPassword: undefined,
  });
  const [error, setError] = useState("");

  const navigation =
    useNavigation() as StackNavigationProp<SettingsNavigatorParamList>;
  const { accountStore } = useStores();

  const setPasswordHandler = async () => {
    const newErrors = {
      oldPassword: formData.oldPassword
        ? ""
        : translate("Settings.requiredField"),
      password: newPasswordValidator(formData.password, formData.oldPassword),
      confirmPassword: confirmPasswordValidator(
        formData.password,
        formData.confirmPassword,
      ),
    };
    setErrors(newErrors);

    if (
      newErrors.oldPassword ||
      newErrors.password ||
      newErrors.confirmPassword
    )
      return;

    const result = await accountStore.changePassword(
      formData.oldPassword,
      formData.password,
      formData.confirmPassword,
    );

    if (result) {
      navigation.navigate("settings-new-password-success");
    } else {
      setError(translate("errorScreen.somethingWrong"));
    }
  };

  const setFormDataHandler = (key, value) => {
    setErrors((prevState) => ({ ...prevState, [key]: undefined }));
    setFormData((prevState) => ({ ...prevState, [key]: value }));
  };

  return (
    <Screen
      style={styles.screen}
      header={
        <TopBar
          back
          title={translate("Settings.changePassword")}
          align={TopBarAlign.Left}
        />
      }
      keyboard
    >
      <LayoutCenter>
        {error ? <ErrorText>{error}</ErrorText> : null}
        <Form.Container>
          <Form.Inputs>
            <PasswordInput
              value={formData.oldPassword}
              errorText={errors.oldPassword}
              txLabel="Settings.oldPassword"
              onChangeText={(value) => setFormDataHandler("oldPassword", value)}
            />
            <PasswordInput
              value={formData.password}
              errorText={errors.password}
              txLabel="Settings.newPassword"
              onChangeText={(value) => setFormDataHandler("password", value)}
            />
            <PasswordInput
              value={formData.confirmPassword}
              errorText={errors.confirmPassword}
              txLabel="Settings.confirmNewPassword"
              onChangeText={(value) =>
                setFormDataHandler("confirmPassword", value)
              }
            />
          </Form.Inputs>

          <Button
            style={styles.buttonSetPassword}
            type="primary"
            onPress={setPasswordHandler}
            tx="Settings.updatePassword"
          >
            Update Password
          </Button>
        </Form.Container>
      </LayoutCenter>
    </Screen>
  );
}
