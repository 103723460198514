import { useState } from "react";

import { Dialog, FloatingButton, useDialog } from "@components/ui";
import { EnterCodeFromMail } from "@components/common";
import { useStores } from "@models/index";

import { TContent } from "../types";
import { translate } from "../../../../../i18n";

export const EnterCode = ({ email }: TContent) => {
  const { accountStore } = useStores();
  const [isButtonVisible, setButtonVisible] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [code, setCode] = useState<string>("");

  const onSendNewCode = () => {
    return accountStore.accountDeleteRequest(email);
  };

  const onEnterCode = (enterCode: string) => {
    setCode(enterCode);
    setButtonVisible(true);
    setError("");
  };

  const deleteAccount = async () => {
    const result = await accountStore.accountDeleteConfirm(email, code);

    if (result.errors) {
      setError(result.errors[0]);
      // setButtonVisible(false);
    } else {
      await accountStore.logout();
    }
  };

  const { showDialog, ...dialogProps } = useDialog({ onOk: deleteAccount });

  return (
    <>
      <EnterCodeFromMail
        email={email}
        onPress={onEnterCode}
        onSendNewCode={onSendNewCode}
        isClearNextStep={false}
        errorText={error}
      />
      {isButtonVisible ? (
        <FloatingButton
          type="warning"
          tx="Auth.AccountDelete.title"
          onPress={showDialog}
        >
          Delete Account
        </FloatingButton>
      ) : null}

      <Dialog
        {...dialogProps}
        title={translate("Auth.AccountDelete.deleteAccount")}
        content={translate("Auth.AccountDelete.warningMessage")}
        okType="warning"
        okText={translate("common.delete")}
      />
    </>
  );
};
