import React from "react";
import { Switch as PaperSwitch } from "react-native-paper";
import { useTheme } from "@react-navigation/native";

import { palette } from "../../theme";

type Props = React.ComponentProps<typeof PaperSwitch>;

function Switch(props: Props) {
  const { colors } = useTheme() as any;

  const color = props.value ? palette.White : palette.BlueLight;

  return (
    <PaperSwitch
      trackColor={{
        true: colors.switchTrackTrue || palette.GreenDark,
        false: colors.switchTrackFalse || palette.GrayBlueLight,
      }}
      thumbColor={color}
      color={color}
      {...props}
    />
  );
}

export default Switch;
