import React, { useCallback, useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { useNavigation, useTheme } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";

import { Module } from "@models/module/module";
import { UpdateStatus } from "@models/module/module-update";
import { Button, Text } from "@components/ui";
import { ModulesNavigatorParamList } from "@screens/Modules/navigation";

import { translate } from "../../../../../i18n";
import { spacing } from "../../../../../theme";

const createStyles = (theme: any) =>
  StyleSheet.create({
    container: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      padding: spacing[4],
      paddingRight: spacing[2],
      marginBottom: spacing[3],
    },
    textContainer: {
      justifyContent: "center",
    },
    title: {
      fontSize: 14,
      lineHeight: 16,
    },
    subtitle: {
      fontSize: 12,
      color: theme.colors.subLabel,
      marginTop: spacing[1],
    },
    button: {
      width: "auto",
      marginTop: 0,
      marginBottom: 0,
    },
    buttonLabel: {
      fontSize: 14,
      lineHeight: 24,
    },
    buttonLabelDowngrade: {
      fontSize: 14,
      marginVertical: spacing[2],
    },
  });

type FirmwareInfoProps = {
  module: Module;
};

export const FirmwareInfo = ({ module }: FirmwareInfoProps) => {
  const navigation =
    useNavigation() as StackNavigationProp<ModulesNavigatorParamList>;

  const theme = useTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);

  const goToUpdate = useCallback(() => {
    module.update.setStatus(UpdateStatus.Ready);
    navigation.navigate("module-update", {
      uid: module.uid,
      navigator: "module-info",
    });
  }, [module.uid]);

  return (
    <View style={styles.container}>
      <View style={styles.textContainer}>
        <Text style={styles.title}>
          {translate("Modules.info.firmware", {
            firmwareVersion: module.currentFirmwareVersion || "-",
          })}
        </Text>
        {module.isFirmwareUpdateAvailable && (
          <Text style={styles.subtitle} tx="Modules.info.newVersion" />
        )}
      </View>

      {module.isFirmwareUpdateAvailable && (
        <Button
          type="primary"
          tx="common.update"
          onPress={goToUpdate}
          style={styles.button}
          labelStyle={styles.buttonLabel}
          disabled={!module.isOnline}
        >
          Update
        </Button>
      )}

      {module.isFirmwareDowngradeAvailable && (
        <Button
          type="primary"
          tx="common.downgrade"
          onPress={goToUpdate}
          style={styles.button}
          labelStyle={styles.buttonLabelDowngrade}
          disabled={!module.isOnline}
        >
          Downgrade
        </Button>
      )}
    </View>
  );
};
