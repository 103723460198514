import React, { useState } from "react";
import { View, StyleSheet } from "react-native";

import { useStores } from "@models/index";
import { Button, Loading, Text } from "@components/ui";
import { TopBar } from "@components/layout";
import { Screen } from "@components/index";
import { CustomSelectInput, Label, TextInput } from "@components/inputs";

import { SelectedModule } from "../components/SelectedModule";
import { DeviceTypeSelector } from "./DeviceTypeSelector";
import { DeviceModuleSelector } from "./DeviceModuleSelector";
import {
  duplicateNameValidator,
  requiredStringValidator,
} from "../../../utils/validator";
import { defaultColors, spacing } from "../../../theme";
import { translate } from "../../../i18n";
import * as DeviceIcons from "../../../svgs/devices";
import { NoneIcon } from "../../../svgs";
import { goBack } from "../../../navigators";

const style = StyleSheet.create({
  form: {
    flex: 1,
    justifyContent: "space-between",
    padding: spacing[4],
  },
  inputs: {
    marginBottom: spacing[4],
  },
  input: {
    marginBottom: spacing[5],
  },
  inputMargin: {
    marginTop: spacing[2],
  },
  customInput: {
    borderRadius: spacing[4],
    borderWidth: 1,
    borderColor: defaultColors.inputBorder,
    padding: spacing[2],
  },
  errorText: {
    fontSize: 12,
    color: defaultColors.error,
    paddingTop: spacing[1],
  },
});

export type DeviceAddFormData = {
  type?: string;
  moduleUid?: string;
  socketUid?: string;
  name?: string;
};
type FormDataErrors = Record<string, string>;

interface DeviceAddFormProps {
  initialFormData?: DeviceAddFormData;
}

export const DeviceAddForm: React.FunctionComponent<DeviceAddFormProps> = ({
  initialFormData = {},
}) => {
  const { moduleStore, deviceStore } = useStores();
  const [formData, setFormData] = useState<DeviceAddFormData>({
    type: undefined,
    name: undefined,
    moduleUid: undefined,
    socketUid: undefined,
    ...initialFormData,
  });
  const [errors, setErrors] = useState<FormDataErrors>({});

  const [typeSelectorIsOpen, setTypeSelectorOpen] = useState<boolean>(false);
  const openTypeSelector = () => setTypeSelectorOpen(true);
  const closeTypeSelector = () => setTypeSelectorOpen(false);

  const [moduleSelectorIsOpen, setModuleSelectorOpen] =
    useState<boolean>(false);
  const openModuleSelector = () => setModuleSelectorOpen(true);
  const closeModuleSelector = () => setModuleSelectorOpen(false);

  const changeFormData = (data: DeviceAddFormData) => {
    setFormData((prevData) => ({
      ...prevData,
      ...data,
    }));
    validateData(data);
  };

  const changeTypeHandler = (type: string) => {
    changeFormData({ type });
    closeTypeSelector();
  };

  const changeModuleHandler = ({ moduleUid, socketUid }) => {
    changeFormData({ moduleUid, socketUid });
    closeModuleSelector();
  };

  const validateDataItem = (key: string, value: any): string | undefined => {
    switch (key) {
      case "type":
        return requiredStringValidator(value);
      case "moduleUid":
        return requiredStringValidator(value);
      case "name":
        return duplicateNameValidator(value, deviceStore.devicesNames);
      default:
        return undefined;
    }
  };

  const validateData = (data?: DeviceAddFormData): boolean => {
    const newErrors = {};
    /* eslint-disable no-restricted-syntax */
    for (const [key, value] of Object.entries(data || formData)) {
      newErrors[key] = validateDataItem(key, value);
    }
    /* eslint-enable no-restricted-syntax */
    const allErrors = { ...errors, ...newErrors };
    setErrors(allErrors);
    return !Object.values(allErrors).filter((n) => n).length;
  };

  const connectHandler = async () => {
    const isValid = validateData();
    if (isValid) {
      const connectResult = await deviceStore.connect(formData.socketUid, {
        type: formData.type,
        name: formData.name,
      });
      if (connectResult.kind === "ok") {
        goBack();
      } else {
        console.error(connectResult);
      }
    }
  };

  if (!formData.type || typeSelectorIsOpen) {
    return (
      <DeviceTypeSelector
        deviceTypes={deviceStore.typesForModule(
          formData.moduleUid,
          formData.socketUid,
        )}
        onChange={changeTypeHandler}
        onClose={closeTypeSelector}
        currentValue={formData.type}
      />
    );
  }

  if (moduleSelectorIsOpen) {
    const modulesWithFeeSockets = moduleStore.modulesWithFeeSockets({
      deviceType: formData.type,
    });

    return (
      <DeviceModuleSelector
        modules={modulesWithFeeSockets}
        moduleSocketData={{
          moduleUid: formData.moduleUid,
          socketUid: formData.socketUid,
        }}
        onChange={changeModuleHandler}
        onClose={closeModuleSelector}
      />
    );
  }

  if (deviceStore.isLoading) {
    return <Loading />;
  }

  const deviceType = deviceStore.getType(formData.type);
  const deviceTypeIcon = DeviceIcons[deviceType.iconName] || NoneIcon;

  return (
    <Screen
      testID="DevicesAdd"
      keyboard
      header={<TopBar title={translate("Devices.Add.title")} back />}
    >
      <View style={style.form}>
        <View style={style.inputs}>
          <CustomSelectInput
            txLabel="Devices.Add.deviceType"
            value={deviceType.displayName}
            errorText={errors.type}
            icon={deviceTypeIcon}
            onPress={openTypeSelector}
            style={style.input}
          />

          <View style={style.input}>
            <Label
              tx="Devices.Add.connectionPlace"
              isError={!!errors.moduleUid}
            />
            {formData.moduleUid ? (
              <View style={[style.customInput, style.inputMargin]}>
                <SelectedModule
                  moduleUid={formData.moduleUid}
                  socketUid={formData.socketUid}
                />
                <Button
                  tx="fields.change"
                  type="secondary"
                  onPress={openModuleSelector}
                >
                  Change
                </Button>
              </View>
            ) : (
              <Button
                tx="Devices.Add.selectModule"
                type="secondary"
                onPress={openModuleSelector}
                style={style.inputMargin}
              >
                Select module
              </Button>
            )}
            {errors.moduleUid ? (
              <Text style={style.errorText}>{errors.moduleUid}</Text>
            ) : null}
          </View>

          <TextInput
            txLabel="Devices.Add.deviceName"
            value={formData.name}
            errorText={errors.name}
            onChangeText={(value) => changeFormData({ name: value })}
            returnKeyType="next"
            autoCapitalize="words"
          />
        </View>
        <Button
          type="primary"
          onPress={connectHandler}
          tx="Devices.Add.connect"
        >
          Connect
        </Button>
      </View>
    </Screen>
  );
};
