import { StyleSheet, View } from "react-native";
import { useTheme } from "@react-navigation/native";

import { ProgressBar, Text } from "@components/ui";

import { spacing } from "../../../../theme";
import { ModuleSearching } from "../../../../svgs/pictures";
import { TxKeyPath } from "../../../../i18n";

const styles = StyleSheet.create({
  container: {
    padding: spacing[4],
  },
  image: {
    marginVertical: spacing[6],
    alignItems: "center",
  },
  searchingText: {
    fontSize: 16,
    textAlign: "center",
    marginBottom: spacing[5],
  },
});

type DetectingProgressProps = {
  title?: string;
  titleTx?: TxKeyPath;
  timeMs?: number;
};

export const DetectingProgress = ({
  titleTx,
  title,
  timeMs,
}: DetectingProgressProps) => {
  const { fonts } = useTheme() as any;

  return (
    <View style={styles.container}>
      <View style={styles.image}>
        <ModuleSearching />
      </View>
      {titleTx || title ? (
        <Text style={[styles.searchingText, fonts.medium]} tx={titleTx}>
          {title}
        </Text>
      ) : null}

      <ProgressBar durationMs={timeMs} looped />
    </View>
  );
};
