import { useMemo } from "react";
import { TouchableOpacity, View, StyleSheet } from "react-native";

import { Text } from "@components/ui";
import { CloseIcon } from "../../svgs";
import { useAppTheme } from "../../hooks";
import { normalizeFontSize } from "../../utils/sizes";

const createStyles = (theme: any) =>
  StyleSheet.create({
    header: {
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-between",
    },
    title: {
      fontSize: normalizeFontSize(18, 22),
      ...theme.fonts.medium,
    },
    skipLabel: {
      fontSize: normalizeFontSize(16, 18),
      paddingTop: 2,
      color: theme.colors.subLabel,
    },
  });

type DialogTitleProps = {
  title: string;
  onClose?: () => void;
  textOnClose?: string;
};

export const TitleDialog = ({
  title,
  onClose,
  textOnClose,
}: DialogTitleProps) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <View style={styles.header}>
      <Text style={styles.title}>{title}</Text>
      {onClose ? (
        <TouchableOpacity onPress={onClose}>
          <Text style={styles.skipLabel}>
            {textOnClose || <CloseIcon color={theme.colors.text} />}
          </Text>
        </TouchableOpacity>
      ) : null}
    </View>
  );
};
