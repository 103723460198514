import { useEffect, useState } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { ProgressBar as PaperProgressBar } from "react-native-paper";
import { useTheme } from "@react-navigation/native";
import { useKeepAwake } from "expo-keep-awake";

import dayjs from "dayjs";
import { spacing } from "../../theme";

const FREQ_PROGRESS_UPDATE_MS = 200;

const createStyles = () =>
  StyleSheet.create({
    progressBar: {
      borderRadius: spacing[2],
      height: spacing[2],
    },
  });

export type ProgressBarCounterProps = {
  durationMs?: number;
  durationProgressMs?: number;
};

type ProgressBarProps = {
  durationMs?: number;
  looped?: boolean;
  beforeStart?: () => void;
  afterEnd?: () => void;
  color?: string;
  style?: StyleProp<ViewStyle>;
  counter?: React.FunctionComponent<ProgressBarCounterProps>;
};

export const ProgressBar = ({
  durationMs,
  looped = false,
  beforeStart,
  afterEnd,
  color,
  style,
  counter: Counter,
}: ProgressBarProps) => {
  const { colors } = useTheme() as any;
  const [progress, setProgress] = useState(0);
  const styles = createStyles();

  useKeepAwake();

  useEffect(() => {
    setProgress(0);
    const initDateTime = new Date();

    if (!durationMs) return undefined;

    beforeStart?.();
    const intervalId = setInterval(() => {
      setProgress(() => {
        const newProgress = dayjs().diff(initDateTime) / durationMs;

        if (newProgress >= 1) {
          if (!looped) {
            clearInterval(intervalId);
          }
          setProgress(0);
          afterEnd?.();
        }
        return newProgress;
      });
    }, FREQ_PROGRESS_UPDATE_MS);

    return () => clearInterval(intervalId);
  }, [durationMs]);

  return (
    <>
      {/* TODO View for web !!!  */}
      <View>
        <PaperProgressBar
          progress={progress}
          style={[styles.progressBar, style]}
          indeterminate={durationMs === undefined}
          color={color || colors.primary}
        />
      </View>
      {Counter && (
        <Counter
          durationMs={durationMs}
          durationProgressMs={Math.round(durationMs * progress)}
        />
      )}
    </>
  );
};
