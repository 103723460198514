import React, { useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import dayjs from "dayjs";
import { CalendarList } from "react-native-calendars";

import { palette } from "../../theme";
import { DateTimeFormats } from "../../utils/timeConverter";

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

const initialState = {
  isFromDatePicked: false,
  isToDatePicked: false,
  markedDates: {},
  fromDate: "",
};

type Props = {
  onSuccess: (rangeStart?: string, rangeEnd?: string) => void;
  theme: any;
  maxDate?: string;
  isClear?: boolean;
};

const DateRangePicker = ({ theme, maxDate, onSuccess, isClear }: Props) => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (isClear) {
      setState(initialState);
      onSuccess(null);
    }
  }, [isClear]);

  const setupStartMarker = (day: { dateString: string }) => {
    const markedDates = {
      [day.dateString]: {
        startingDay: true,
        color: palette.Green,
        textColor: palette.White,
      },
    };
    setState({
      isFromDatePicked: true,
      isToDatePicked: false,
      fromDate: day.dateString,
      markedDates,
    });

    onSuccess?.(day.dateString, day.dateString);
  };

  const setupMarkedDates = (fromDate, toDate, markedDates) => {
    let mFromDate = dayjs(fromDate);
    const mToDate = dayjs(toDate);
    const range = mToDate.diff(mFromDate, "days");

    if (range >= 0) {
      if (range === 0) {
        markedDates = {
          [toDate]: {
            color: palette.Green,
            textColor: palette.White,
          },
        };
      } else {
        for (let i = 1; i <= range; i += 1) {
          mFromDate = mFromDate.add(1, "day");
          const tempDate = mFromDate.format(DateTimeFormats.DateYMD);

          if (i < range) {
            markedDates[tempDate] = {
              color: palette.BlueBackground,
              textColor: theme.markTextColor,
            };
          } else {
            markedDates[tempDate] = {
              endingDay: true,
              color: palette.Green,
              textColor: palette.White,
            };
          }
        }
      }
    }
    return [markedDates, range];
  };

  const onDayPress = (day: { dateString: string }) => {
    if (
      !state.isFromDatePicked ||
      (state.isFromDatePicked && state.isToDatePicked)
    ) {
      setupStartMarker(day);
    } else if (!state.isToDatePicked) {
      const markedDates = { ...state.markedDates };
      const [mMarkedDates, range] = setupMarkedDates(
        state.fromDate,
        day.dateString,
        markedDates,
      );
      if (range >= 0) {
        setState({
          ...state,
          isFromDatePicked: true,
          isToDatePicked: true,
          markedDates: mMarkedDates,
        });

        onSuccess?.(state.fromDate, day.dateString);
      } else {
        setupStartMarker(day);
      }
    }
  };

  return (
    <View style={styles.container}>
      <CalendarList
        maxDate={maxDate}
        pastScrollRange={5}
        futureScrollRange={0}
        markingType="period"
        current={state.fromDate}
        markedDates={state.markedDates}
        onDayPress={onDayPress}
      />
    </View>
  );
};

export default DateRangePicker;
