import {
  Pressable,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from "react-native";

import { Text } from "@components/ui";

import { defaultColors, spacing } from "../../theme";

const styles = StyleSheet.create({
  button: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: spacing[2],
    paddingRight: spacing[2],
    paddingLeft: spacing[1],
    paddingVertical: spacing[1],
    borderWidth: 1,
    borderRadius: 16,
    borderColor: defaultColors.inputBorder,
  },
  buttonSelected: {
    borderColor: defaultColors.tabActiveBG0,
    backgroundColor: defaultColors.tabActiveBG0,
  },

  buttonText: {
    marginLeft: spacing[1],
    fontSize: 12,
    lineHeight: 16,
    letterSpacing: 0.24,
  },
  icon: {
    marginLeft: spacing[1],
  },
});

type FilterButtonProps = {
  onPress: () => void;
  style?: StyleProp<ViewStyle>;
  iconLeft?: React.FunctionComponent<any>;
  iconRight?: React.FunctionComponent<any>;
  label?: string;
  selected?: boolean;
  colorText?: string;
};

export const FilterButton = ({
  onPress,
  style,
  iconLeft: IconLeft,
  iconRight: IconRight,
  label,
  selected,
  colorText = defaultColors.text,
}: FilterButtonProps) => {
  return (
    <Pressable onPress={onPress}>
      <View style={[styles.button, selected && styles.buttonSelected, style]}>
        {IconLeft ? (
          <View style={styles.icon}>
            <IconLeft size={20} color={colorText} />
          </View>
        ) : null}

        {label ? (
          <Text style={[styles.buttonText, { color: colorText }]}>{label}</Text>
        ) : null}
        {IconRight ? (
          <View style={styles.icon}>
            <IconRight size={20} color={colorText} />
          </View>
        ) : null}
      </View>
    </Pressable>
  );
};
