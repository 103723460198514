import { StyleSheet, View } from "react-native";
import { shadow } from "react-native-paper";

import { DefaultTheme, spacing } from "../../../../../theme";
import { CardTypeUnknown } from "./svgs/CardTypeUnknown";

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    borderRadius: spacing[4],
    backgroundColor: DefaultTheme.colors.cardBG,
    minWidth: "45%",
    top: 76,
    left: 16,
    ...(shadow(5) as any),
  },
});

export const SensorTypeUnknown = () => (
  <View style={styles.container}>
    <CardTypeUnknown />
  </View>
);
