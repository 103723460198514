import { useMemo } from "react";

import { StyleSheet, View } from "react-native";
import { Text } from "@components/ui";
import { ModuleDeviceCard } from "@screens/Modules/Details/components/Items/DeviceCard";

import { spacing } from "../../../theme";
import { useAppTheme, useSensorRuledDevices } from "../../../hooks";
import { translate } from "../../../i18n";

const createStyles = (theme: any) =>
  StyleSheet.create({
    container: { marginTop: spacing[4] },
    title: {
      marginBottom: spacing[2],
      fontSize: 16,
      lineHeight: 22,
      ...theme.fonts.medium,
    },
  });

type RuledDevicesProps = {
  sensorUid: string;
};

export const RuledDevices = ({ sensorUid }: RuledDevicesProps) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  const { devices } = useSensorRuledDevices({
    sensorUid,
  });

  if (!devices?.length) return null;

  return (
    <View style={styles.container}>
      <Text style={styles.title}>
        {translate("Sensors.Details.ruledDevice")}
      </Text>
      {devices.map((device) => (
        <ModuleDeviceCard key={device.uid} device={device} />
      ))}
    </View>
  );
};
