import React, { useCallback, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Linking, StyleSheet, View } from "react-native";
import { StackScreenProps } from "@react-navigation/stack";

import { useStores } from "@models/root-store/root-store-context";
import { Screen } from "@components/index";
import { TopBar } from "@components/layout";
import { Button, ErrorsMessage } from "@components/ui";

import { translate } from "../../../../i18n";
import { spacing } from "../../../../theme";
import { SettingsNavigatorParamList } from "../../navigation";

export const MANUAL_URL =
  "https://growdirector.wpengine.com/wp-content/uploads/2023/05/GrowDirector-3-PRO-Manual.pdf";

const styles = StyleSheet.create({
  screen: {},
  container: {
    padding: spacing[4],
  },
  button: {
    alignItems: "flex-start",
  },
  message: {
    marginBottom: spacing[4],
  },
});

export const HelpManualScreen: React.FC<
  StackScreenProps<SettingsNavigatorParamList, "settings-help-manual">
> = observer(() => {
  const { documentsStore, accountStore } = useStores();

  useEffect(() => {
    documentsStore.readManualData();
  }, [accountStore.currentUser.id]);

  const onRefresh = useCallback(async () => {
    await documentsStore.readManualData({ force: true });
  }, []);

  return (
    <Screen
      style={styles.screen}
      testID="HelpManual"
      header={<TopBar title={translate("Settings.manual")} back />}
      preset="scroll"
      onRefresh={onRefresh}
      refreshing={!documentsStore.isLoaded}
    >
      <View style={styles.container}>
        <ErrorsMessage
          errors={documentsStore.statusErrors}
          onCancel={documentsStore.resetErrors}
          style={styles.message}
        />

        {documentsStore.manualsData.map((item) => (
          <Button
            key={item.id}
            type="link"
            onPress={() => Linking.openURL(item.url)}
            style={styles.button}
          >
            {item.title}
          </Button>
        ))}
      </View>
    </Screen>
  );
});
