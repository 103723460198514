import { createContext, useContext } from "react";

import { Language } from "../i18n";

// type LocaleContextParams = {
//   locale: Language;
// }
const LocaleContext = createContext<Language>(Language.en);

/**
 * The provider our root component will use to expose the root store
 */
export const LocaleProvider = LocaleContext.Provider;

/**
 * A hook that screens can use to gain access to our stores, with
 * `const { someStore, someOtherStore } = useStores()`,
 * or less likely: `const rootStore = useStores()`
 */
export const useLocale = () => useContext(LocaleContext);
