import React, { useState } from "react";
import { StyleSheet } from "react-native";

import { Screen } from "@components/index";
import { FloatingButton, Text } from "@components/ui";
import { TopBar } from "@components/layout";
import { useStores } from "@models/index";
import { EnterCodeFromMail, SendCodeToEmail } from "@components/common";

import { translate } from "../../../i18n";
import { spacing } from "../../../theme";

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: spacing[4],
    paddingTop: spacing[4],
  },
  message: {
    textAlign: "center",
  },
});

enum Steps {
  EnterEmail = "EnterEmail",
  EnterCodeEmailOld = "EnterCodeEmailOld",
  EnterCodeEmailNew = "EnterCodeEmailNew",
}

export const ChangeEmailScreen = ({ navigation }) => {
  const { accountStore } = useStores();
  const currentEmail = accountStore.currentUser.email;

  const [step, setStep] = useState<Steps>(Steps.EnterEmail);
  const [newEmail, setNewEmail] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  const [codeOldEmail, setCodeOldEmail] = useState<string>("");
  const [codeNewEmail, setCodeNewEmail] = useState<string>("");

  const [isContinueVisible, setContinueVisible] = useState<boolean>(false);
  const [isClearCode, setClearCode] = useState<boolean>(false);

  const isEnterEmail = step === Steps.EnterEmail;
  const isEnterCodeEmailOld = step === Steps.EnterCodeEmailOld;
  const isEnterCodeEmailNew = step === Steps.EnterCodeEmailNew;

  const emailCode = isEnterCodeEmailOld ? currentEmail : newEmail;

  const onPrevStep = () => {
    setError("");

    if (isEnterCodeEmailOld) {
      return setStep(Steps.EnterEmail);
    }

    if (isEnterCodeEmailNew) {
      onRequestEmail(newEmail);
      setClearCode(true);
      return setStep(Steps.EnterCodeEmailOld);
    }

    return navigation.goBack();
  };

  const onSendEmail = (newEmailValue: string) => {
    setNewEmail(newEmailValue);
    setStep(Steps.EnterCodeEmailOld);
  };

  const onRequestEmail = async (newEmailValue: string) => {
    const result = await accountStore.changeEmailRequest(newEmailValue);
    if (result.kind === "ok") {
      setMessage(result.data?.toString());
    }
    return result;
  };

  const onSendNewCode = async () => {
    if (isEnterCodeEmailOld) {
      return onRequestEmail(newEmail);
    }

    if (isEnterCodeEmailNew) {
      const result = await accountStore.changeEmailConfirm(
        newEmail,
        codeOldEmail,
      );
      if (result.kind === "ok") {
        setMessage(result.data?.toString());
      }
      return result;
    }

    return null;
  };

  const onEnterCode = (code: string) => {
    if (isEnterCodeEmailOld) {
      setCodeOldEmail(code);
      setClearCode(false);
    }

    if (isEnterCodeEmailNew) {
      setCodeNewEmail(code);
    }

    setContinueVisible(true);
    setError("");
  };

  const onSendCode = async () => {
    const result = isEnterCodeEmailOld
      ? await accountStore.changeEmailConfirm(newEmail, codeOldEmail)
      : await accountStore.changeEmailConfirmNewEmail(newEmail, codeNewEmail);
    if (result.kind === "ok") {
      setMessage(result.data?.toString());
    }

    setContinueVisible(false);

    if (result.errors) {
      setClearCode(false);
      return setError(result.errors[0]);
    }

    if (isEnterCodeEmailOld) {
      setStep(Steps.EnterCodeEmailNew);
      setClearCode(true);
    }

    if (isEnterCodeEmailNew) {
      await accountStore.logout();
    }

    return null;
  };

  return (
    <Screen
      testID="ChangeEmail"
      style={styles.container}
      header={
        <TopBar
          title={translate("Settings.ChangeEmail.title")}
          back
          backHandler={onPrevStep}
        />
      }
    >
      {isEnterEmail ? (
        <SendCodeToEmail
          title={translate("Settings.ChangeEmail.newEmail")}
          onPress={onSendEmail}
          onRequest={onRequestEmail}
        />
      ) : null}

      {isEnterCodeEmailOld || isEnterCodeEmailNew ? (
        <>
          <EnterCodeFromMail
            email={emailCode}
            onPress={onEnterCode}
            onSendNewCode={onSendNewCode}
            isClearNextStep={false}
            isClearCode={isClearCode}
            isSendNewCode={isEnterCodeEmailOld}
            errorText={error}
          >
            <Text style={styles.message}>{message}</Text>
          </EnterCodeFromMail>
          {isContinueVisible && (
            <FloatingButton tx="common.continue" onPress={onSendCode}>
              Continue
            </FloatingButton>
          )}
        </>
      ) : null}
    </Screen>
  );
};
