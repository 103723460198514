import { View, StyleSheet } from "react-native";
import { shadow } from "react-native-paper";

import { useStores } from "@models/index";

import * as SensorIcons from "../../../../../../svgs/sensors";
import { DefaultTheme, spacing } from "../../../../../../theme";
import { SensorInput } from "../../components/sensor";

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    backgroundColor: DefaultTheme.colors.background,
    padding: spacing[1],
    borderRadius: spacing[2],
    top: 136,
    left: spacing[3],
    right: spacing[3],
    ...(shadow(5) as any),
  },
  input: {
    marginBottom: 0,
  },
});

export const SensorInputHinte = (uid: string) => {
  const { sensorStore } = useStores();

  const sensor = sensorStore.getSensor(uid);

  const SensorIcon = SensorIcons[sensor?.iconName];

  return (
    <View style={styles.container}>
      <SensorInput
        style={styles.input}
        displayName={sensor?.displayName}
        icon={SensorIcon}
      />
    </View>
  );
};
