import * as React from "react";
import { View } from "react-native";
import Svg, {
  SvgProps,
  G,
  Path,
  Circle,
  Rect,
  Defs,
  ClipPath,
} from "react-native-svg";

import { spacing } from "../../../../theme";

export const CalibrationPH4Svg = (props: SvgProps) => (
  <View style={{ marginTop: -spacing[8] }}>
    <Svg width={360} height={312} fill="none" {...props}>
      <G clipPath="url(#clip0_205_4416)">
        <Circle
          cx={103}
          cy={297}
          r={11}
          fill="#B6C5D3"
          stroke="white"
          strokeWidth={2}
        />
        <Path
          d="M104.374 303H102.694V295.416C102.694 295.149 102.694 294.904 102.694 294.68C102.705 294.445 102.715 294.221 102.726 294.008C102.737 293.784 102.747 293.571 102.758 293.368C102.619 293.507 102.475 293.64 102.326 293.768C102.177 293.896 102.006 294.045 101.814 294.216L100.566 295.224L99.7019 294.136L102.966 291.576H104.374V303Z"
          fill="white"
        />
        <Path
          d="M105.775 237.701C106.277 236.834 106.54 235.848 106.54 234.843V171.897H102.666H102.642H98.7676V234.843C98.7676 235.848 99.0307 236.834 99.5329 237.701L99.5867 237.797C100.131 238.741 100.208 239.883 99.8019 240.894L99.2399 242.287C98.935 243.04 98.8752 243.871 99.0665 244.661L99.9215 248.206C100.203 249.36 101.237 250.179 102.427 250.179H102.648H102.672H102.893C104.083 250.179 105.117 249.366 105.398 248.206L106.253 244.661C106.445 243.871 106.385 243.04 106.08 242.287L105.518 240.894C105.111 239.883 105.189 238.741 105.733 237.797L105.775 237.701Z"
          fill="white"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M102.665 171.897H102.635H100.561V233.653C100.561 234.783 100.764 235.901 101.165 236.96L102.653 240.912V240.918V240.912L104.142 236.96C104.543 235.901 104.746 234.783 104.746 233.653V171.897H102.665Z"
          fill="#B6C5D3"
        />
        <Path
          d="M105.452 171.897H99.8259C98.0801 171.897 96.6631 170.48 96.6631 168.734V154.187C96.6631 152.441 98.0801 151.024 99.8259 151.024H105.452C107.198 151.024 108.615 152.441 108.615 154.187V168.734C108.615 170.48 107.198 171.897 105.452 171.897Z"
          fill="white"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M106.343 151.024L103.904 132.424H102.78H102.726H101.602L99.1624 151.024L102.75 150.773L106.343 151.024Z"
          fill="#3F9A6E"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M102.779 132.424V130.373C102.779 115.808 90.971 104 76.4064 104C61.8417 104 50.0333 115.808 50.0333 130.373V178.408C50.0333 192.972 38.2249 204.781 23.6603 204.781H21.191C6.62631 204.781 -5.18206 192.972 -5.18206 178.408V171.675C-5.18206 157.111 -16.9904 145.302 -31.5551 145.302H-54"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M100 276C115.464 276 128 272.194 128 267.5C128 262.805 115.464 259 100 259C84.536 259 72 262.805 72 267.5C72 272.194 84.536 276 100 276Z"
          fill="#B6C5D3"
        />
        <Path
          d="M103.5 233C117.031 233 128 229.642 128 225.5C128 221.358 117.031 218 103.5 218C89.969 218 79 221.358 79 225.5C79 229.642 89.969 233 103.5 233Z"
          fill="#70B8FF"
          fillOpacity={0.4}
        />
        <Path
          opacity={0.6}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M128 225.504C127.993 229.644 117.026 233 103.5 233C89.969 233 79 229.642 79 225.5C79 225.339 79.0165 225.179 79.0491 225.021L79 225.022V225.5V258.998V265.321C79 269.557 89.9734 273 103.5 273C117.027 273 128 269.557 128 265.321V256.312V225.504ZM128 225.495V224L127.517 224.01C127.833 224.49 127.999 224.987 128 225.495Z"
          fill="#308AE3"
        />
        <Path
          d="M128 204V256.323V265.326C128 269.559 117.002 273 103.446 273C89.89 273 78.8924 269.559 78.8924 265.326V259.007V215.113C78.8924 213.296 78.1543 211.563 76.8504 210.307L73 206.208"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M98.5 197.19C90.7929 197.792 85.3705 199.719 81.3268 201.522C79.6586 202.262 76.0645 204.064 73.5132 205.353C72.6668 205.783 72.9244 207.012 73.8689 207.095L78.7877 207.549C80.5172 207.811 82.1977 208.277 83.8046 208.945C88.2572 210.794 95.433 212 103.516 212C117.046 212 128 208.635 128 204.494C128.011 200.737 118.984 197.628 107.204 197.084"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M121 217V240.861"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M279.563 191.215H231.67C230.142 191.215 228.91 189.984 228.91 188.455V87.56C228.91 86.0311 230.142 84.7994 231.67 84.7994H279.563C281.092 84.7994 282.323 86.0311 282.323 87.56V188.455C282.323 189.97 281.092 191.215 279.563 191.215Z"
          fill="#DBE2EA"
        />
        <Path
          d="M287.139 99.3947V87.4323C287.139 83.3268 283.812 80 279.707 80H231.432C227.327 80 224 83.3268 224 87.4323V188.568C224 192.673 227.327 196 231.432 196H279.707C283.812 196 287.139 192.673 287.139 188.568V118.336"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M257.904 101.448L233.71 126.364"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M241.668 102.043L234.632 109.404"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M276.507 186.02H234.646C234.32 186.02 234.051 185.751 234.051 185.425V178.12C234.051 177.795 234.32 177.526 234.646 177.526H276.507C276.833 177.526 277.102 177.795 277.102 178.12V185.425C277.102 185.751 276.833 186.02 276.507 186.02Z"
          fill="#3F9A6E"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Rect
          x={247}
          y={85}
          width={18}
          height={4}
          rx={2}
          fill="#2B2E32"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Circle
          cx={255}
          cy={298}
          r={11}
          fill="#B6C5D3"
          stroke="white"
          strokeWidth={2}
        />
        <Path
          d="M258.838 304H251.158V302.688L254.102 299.696C254.678 299.109 255.158 298.603 255.542 298.176C255.926 297.739 256.214 297.323 256.406 296.928C256.598 296.523 256.694 296.085 256.694 295.616C256.694 295.029 256.523 294.592 256.182 294.304C255.841 294.005 255.398 293.856 254.854 293.856C254.331 293.856 253.862 293.957 253.446 294.16C253.03 294.352 252.598 294.624 252.15 294.976L251.238 293.84C251.547 293.573 251.878 293.333 252.23 293.12C252.593 292.907 252.993 292.736 253.43 292.608C253.878 292.48 254.369 292.416 254.902 292.416C255.627 292.416 256.251 292.544 256.774 292.8C257.297 293.045 257.697 293.397 257.974 293.856C258.262 294.315 258.406 294.853 258.406 295.472C258.406 295.931 258.337 296.363 258.198 296.768C258.059 297.163 257.857 297.552 257.59 297.936C257.334 298.32 257.019 298.709 256.646 299.104C256.273 299.499 255.862 299.915 255.414 300.352L253.286 302.416V302.496H258.838V304Z"
          fill="white"
        />
        <Path
          d="M273.837 229.087L274.449 226.801C275.258 223.784 278.354 221.996 281.371 222.805C284.388 223.613 286.175 226.709 285.367 229.726L280.91 246.359L279.342 252.21C278.896 253.874 278.089 255.427 276.981 256.747L265.683 270.177L264.001 275.5M226.788 273.638L230.326 260.435L225.159 243.434C224.521 241.327 224.484 239.084 225.053 236.96L227.655 227.247C228.663 223.486 231.466 220.467 235.14 219.175L242.029 216.758"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M238.323 230.591L250.661 184.547C251.469 181.53 254.565 179.743 257.582 180.551C260.599 181.359 262.387 184.455 261.578 187.472L252.369 221.841"
          fill="white"
        />
        <Path
          d="M238.323 230.591L250.661 184.547C251.469 181.53 254.565 179.743 257.582 180.551C260.599 181.359 262.387 184.455 261.578 187.472L252.369 221.841"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M252.37 221.834L256.147 207.738C256.956 204.721 260.052 202.933 263.069 203.742C266.086 204.55 267.873 207.646 267.065 210.663L262.608 227.296"
          fill="white"
        />
        <Path
          d="M252.37 221.834L256.147 207.738C256.956 204.721 260.052 202.933 263.069 203.742C266.086 204.55 267.873 207.646 267.065 210.663L262.608 227.296"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M262.612 227.305L265.947 214.859C266.755 211.843 269.852 210.055 272.868 210.863C275.885 211.672 277.673 214.768 276.865 217.785L272.816 232.896"
          fill="white"
        />
        <Path
          d="M262.612 227.305L265.947 214.859C266.755 211.843 269.852 210.055 272.868 210.863C275.885 211.672 277.673 214.768 276.865 217.785L272.816 232.896"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M87.6854 258.392C88.8051 258.697 89.9361 258.957 91.067 259.195C92.854 259.568 94.3242 258.301 94.3242 256.503C94.3242 255.972 94.3242 255.44 94.3242 254.92C94.3242 253.11 92.854 251.448 91.067 251.086C89.9361 250.848 88.8051 250.588 87.6854 250.283C87.6854 252.974 87.6854 255.678 87.6854 258.392Z"
          stroke="white"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M87.6854 249.027C87.6854 253.404 87.6854 257.781 87.6854 262.158"
          stroke="white"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M98.9835 246.55C98.9835 251.791 98.9835 258.728 98.9835 263.968"
          stroke="white"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M107.264 246.613C107.264 251.853 107.264 258.864 107.264 264.104"
          stroke="white"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M98.9835 255.217C101.745 255.416 104.52 255.473 107.281 255.359"
          stroke="white"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M118.606 262.656C118.606 257.16 118.606 251.674 118.606 246.178C116.174 249.955 113.709 253.586 111.232 257.058C114.795 256.707 118.334 256.017 121.807 255.011"
          stroke="white"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_205_4416">
          <Rect width={360} height={312} fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  </View>
);
