/**
 * Welcome to the main entry point of the app. In this file, we'll
 * be kicking off our app.
 *
 * Most of this file is boilerplate and you shouldn't need to modify
 * it very often. But take some time to look through and understand
 * what is going on here.
 *
 * The app navigation resides in ./app/navigators, so head over there
 * if you're interested in adding screens and navigators.
 */
// import "./utils/ignore-warnings"
import React, { useState, useEffect } from "react";
// import { useColorScheme } from "react-native";
import { Provider as PaperProvider } from "react-native-paper";
import {
  SafeAreaProvider,
  initialWindowMetrics,
} from "react-native-safe-area-context";

import { Splash } from "@components/screen/Spalsh";
import { Language, forceRTL, i18n, isLanguageRTL } from "./i18n";
import { initFonts } from "./theme/fonts";
import { DefaultTheme } from "./theme"; // DarkTheme
import * as storage from "./utils/storage";
import { useNavigationPersistence } from "./navigators";
import { AppNavigator } from "./navigators/AppNavigator";
import { RootStore, RootStoreProvider, setupRootStore } from "./models";
import { ErrorBoundary } from "./screens";
import { AppNotifications } from "./notifications/AppNotifications";
import { LocaleProvider } from "./navigators/locale-context";

// This puts screens in a native ViewController or Activity. If you want fully native
// stack navigation, use `createNativeStackNavigator` in place of `createStackNavigator`:
// https://github.com/kmagiera/react-native-screens#using-native-stack-navigator

forceRTL(isLanguageRTL(i18n.locale as Language));

export const NAVIGATION_PERSISTENCE_KEY = "NAVIGATION_STATE";

/**
 * This is the root component of our app.
 */
function App() {
  const [rootStore, setRootStore] = useState<RootStore | undefined>(undefined);
  const [locale, setLocale] = useState<Language>(i18n.locale as Language);

  const {
    initialNavigationState,
    onNavigationStateChange,
    isRestored: isNavigationStateRestored,
  } = useNavigationPersistence(storage, NAVIGATION_PERSISTENCE_KEY);

  // Kick off initial async loading actions, like loading fonts and RootStore
  useEffect(() => {
    (async () => {
      await initFonts(); // expo
      setupRootStore().then(setRootStore);
    })();

    return i18n.onChange(({ locale: newLocale }) => {
      // console.log("i18n.onChange newLocale", newLocale)
      setLocale(newLocale as Language);
    });
  }, []);

  // const colorScheme = useColorScheme();
  const theme = DefaultTheme; // colorScheme === "dark" ? DarkTheme : DefaultTheme;

  // Before we show the app, we have to wait for our state to be ready.
  // In the meantime, don't render anything. This will be the background
  // color set in native by rootView's background color.
  // In iOS: application:didFinishLaunchingWithOptions:
  // In Android: https://stackoverflow.com/a/45838109/204044
  // You can replace with your own loading component if you wish.
  if (!rootStore || !isNavigationStateRestored) return <Splash />;

  // otherwise, we're ready to render the app
  return (
    <RootStoreProvider value={rootStore}>
      <LocaleProvider value={locale}>
        <PaperProvider theme={theme}>
          <SafeAreaProvider initialMetrics={initialWindowMetrics}>
            <ErrorBoundary catchErrors="always">
              <AppNavigator
                initialState={initialNavigationState}
                onStateChange={onNavigationStateChange}
                theme={theme}
              />
              <AppNotifications />
            </ErrorBoundary>
          </SafeAreaProvider>
        </PaperProvider>
      </LocaleProvider>
    </RootStoreProvider>
  );
}

export default App;
