import { View, StyleSheet, Linking } from "react-native";

import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { LayoutCenter } from "@components/layout";
import {
  Button,
  HeaderAction,
  Heading,
  HeadingWithActions,
  Text,
} from "@components/ui";
import { ModulesNavigatorParamList } from "@screens/Modules/navigation";

import { translate } from "../../i18n";
import { spacing } from "../../theme";
import { NoAddedModulesNew } from "../../svgs/pictures";
import { useAIAgronomistLink, useAppTheme } from "../../hooks";

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    alignContent: "center",
  },
  heading: {
    marginVertical: spacing[0],
  },
  container: {
    paddingHorizontal: spacing[6],
  },
  title: {
    textAlign: "center",
    maxWidth: 216,
    marginBottom: spacing[3],
  },
  description: {
    textAlign: "center",
  },
  picture: {
    marginVertical: spacing[6],
  },
});

type NoModuleProps = {
  heading: string;
  description: string;
  actions?: HeaderAction[];
};

export const NoModules = ({ heading, description, actions }: NoModuleProps) => {
  const navigation =
    useNavigation() as StackNavigationProp<ModulesNavigatorParamList>;

  const { colors } = useAppTheme();
  const aiAgronomistLink = useAIAgronomistLink();

  const connectModuleHandler = () => {
    navigation.navigate("modules-list");
    navigation.navigate("module-add-manual-onboarding");
  };

  const aIAgronomistHandleClick = () => {
    Linking.openURL(aiAgronomistLink);
  };

  return (
    <View style={styles.screen}>
      <HeadingWithActions style={styles.heading} actions={actions}>
        {heading}
      </HeadingWithActions>

      <LayoutCenter style={styles.container}>
        <Heading style={styles.title} level={2}>
          {translate("NoData.noModulesDetected")}
        </Heading>

        <Text style={styles.description}>{description}</Text>

        <View style={styles.picture}>
          <NoAddedModulesNew />
        </View>

        <Button
          tx="NoData.connectModule"
          type="primary"
          onPress={connectModuleHandler}
        >
          Connect module
        </Button>

        <Button
          tx="NoData.goToAIAgronomist"
          type="primary"
          onPress={aIAgronomistHandleClick}
          style={{ backgroundColor: colors.palette.Blue }}
        >
          Go to AI Agronomist
        </Button>
      </LayoutCenter>
    </View>
  );
};
