import * as React from "react";
import Svg, {
  SvgProps,
  G,
  Circle,
  Path,
  Rect,
  Defs,
  ClipPath,
} from "react-native-svg";

export const CalibrationDOAirSvg = (props: SvgProps) => (
  <Svg width={286} height={172} fill="none" {...props}>
    <G clipPath="url(#a)">
      <Circle
        cx={76}
        cy={160}
        r={11}
        fill="#B6C5D3"
        stroke="#fff"
        strokeWidth={2}
      />
      <Path
        fill="#fff"
        d="M77.374 166h-1.68v-8.32a131.533 131.533 0 0 1 .064-1.312 7.568 7.568 0 0 1-.432.4c-.15.128-.32.277-.512.448l-1.248 1.008-.864-1.088 3.264-2.56h1.408V166Z"
      />
      <Circle
        cx={210}
        cy={160}
        r={11}
        fill="#B6C5D3"
        stroke="#fff"
        strokeWidth={2}
      />
      <Path
        fill="#fff"
        d="M213.838 166h-7.68v-1.312l2.944-2.992a49.412 49.412 0 0 0 1.44-1.52c.384-.437.672-.853.864-1.248.192-.405.288-.843.288-1.312 0-.587-.171-1.024-.512-1.312-.341-.299-.784-.448-1.328-.448-.523 0-.992.101-1.408.304a6.609 6.609 0 0 0-1.296.816l-.912-1.136c.309-.267.64-.507.992-.72a5.31 5.31 0 0 1 1.2-.512 5.346 5.346 0 0 1 1.472-.192c.725 0 1.349.128 1.872.384.523.245.923.597 1.2 1.056.288.459.432.997.432 1.616 0 .459-.069.891-.208 1.296a4.89 4.89 0 0 1-.608 1.168 8.942 8.942 0 0 1-.944 1.168 42.9 42.9 0 0 1-1.232 1.248l-2.128 2.064v.08h5.552V166ZM242.633 156.845l32.987-3.855-32.41-82.026c-1.788-4.627-5.111-10.111-9.525-12.374l-6.452-3.324-.046 48.862c.083 2.728-1.341 7.663-3.566 6.09l-22.197-15.605a6.825 6.825 0 0 0-8.854.875 6.813 6.813 0 0 0-.298 9.059l6.201 7.43-17.821-.167.391 1.797a8.553 8.553 0 0 0 8.585 6.731l17.299-.298 10.381 13.975c4.413 6.918 12.877 15.233 21.07 15.791l1.937.131 2.318 6.908Z"
      />
      <Path
        fill="#DBE2EA"
        d="M220.112 107.387h-44.591a2.566 2.566 0 0 1-2.57-2.571V10.877a2.565 2.565 0 0 1 2.57-2.57h44.591a2.565 2.565 0 0 1 2.57 2.57v93.939a2.574 2.574 0 0 1-2.57 2.571Z"
      />
      <Path
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.196}
        d="M227.166 21.896V10.758a6.92 6.92 0 0 0-6.92-6.92H175.3a6.919 6.919 0 0 0-6.92 6.92v94.163a6.918 6.918 0 0 0 6.92 6.92h44.946a6.92 6.92 0 0 0 6.92-6.92v-65.39M199.946 23.808 177.42 47.006M184.829 24.361l-6.55 6.854"
      />
      <Path
        fill="#3F9A6E"
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.196}
        d="M217.267 102.549h-38.975a.558.558 0 0 1-.554-.554v-6.8c0-.304.251-.554.554-.554h38.975c.303 0 .554.25.554.553v6.801a.558.558 0 0 1-.554.554Z"
      />
      <Rect
        width={16.759}
        height={3.724}
        x={189.794}
        y={8.494}
        fill="#2B2E32"
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.196}
        rx={1.862}
      />
      <Path
        stroke="#2A2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.196}
        d="m227.233 55.267 9.692 5.725a10.38 10.38 0 0 1 4.358 5.103L275.62 153"
      />
      <Path
        fill="#fff"
        d="M192.719 95.312a7.262 7.262 0 0 1 9.32-.801l24.757 17.476-14.804 13.752-19.394-20.27a7.273 7.273 0 0 1 .121-10.157Z"
      />
      <Path
        stroke="#2A2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.196}
        d="M238.434 112.434h-6.75a17.54 17.54 0 0 1-10.335-3.371l-19.59-14.3a6.927 6.927 0 0 0-8.956.67c-2.663 2.634-2.738 6.917-.177 9.655l19.366 20.651 8.286 12.923a24.443 24.443 0 0 0 18.612 11.172l1.415.112 2.328 6.899M233.425 97.21l-4.255-17.503-2.365-2.737M180.289 111.81l.745 2.141a9.1 9.1 0 0 0 8.584 6.099h11.266"
      />
      <Path
        fill="#fff"
        d="m47.566 140.035 5.313-19.859a31.132 31.132 0 0 0 .584-13.444L46.4 65.882a14.8 14.8 0 0 1 4.14-13.071L68.1 33.828a16.695 16.695 0 0 1 3.385-2.584l31.283-10.628a2.469 2.469 0 0 1 3.158 1.606 10.51 10.51 0 0 1-1.62 9.395l-3.919 8.63c.272.045.549.1.814.17 2.703.726 4.672 2.825 5.371 5.348l4.63-1.594a1.734 1.734 0 0 1 2.222 1.132 7.421 7.421 0 0 1-4.712 9.215l-3.722 1.262-.652 2.438-2.731 10.207a16.896 16.896 0 0 0-.408 6.613c1.012 7.577 4.988 19.381-8.963 35.554-1.022 1.188-2.243 16.228-2.243 16.228l9.308 2.498-5.948 22.234-45.787-11.517Z"
      />
      <Path
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="m47.573 140.046 5.323-19.868a31.134 31.134 0 0 0 .59-13.449l-7.062-40.857a14.81 14.81 0 0 1 4.148-13.078l17.58-18.995a16.722 16.722 0 0 1 3.388-2.587l31.312-10.643a2.47 2.47 0 0 1 3.16 1.606c1.024 3.329.32 6.79-1.625 9.399l-1.515 2.083"
      />
      <Path fill="#fff" d="m92.914 150.725 5.96-22.244-45.726-12.252" />
      <Path
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="m92.914 150.725 5.96-22.244-45.726-12.252"
      />
      <Path
        fill="#fff"
        d="m106.131 44.745 4.88-1.555c.975-.313 2.091.331 2.449 1.413 1.487 4.443-.531 8.972-4.541 10.224l-3.924 1.228"
      />
      <Path
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="m106.131 44.745 4.88-1.555c.975-.313 2.091.331 2.449 1.413 1.487 4.443-.531 8.972-4.541 10.224l-3.924 1.228"
      />
      <Path
        fill="#fff"
        d="m104.413 58.172 5.874.348c.981.053 1.717.921 1.626 1.892-.369 4.006-3.862 6.985-7.878 6.728l-1.452-.09"
      />
      <Path
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="m104.413 58.172 5.874.348c.981.053 1.717.921 1.626 1.892-.369 4.006-3.862 6.985-7.878 6.728l-1.452-.09"
      />
      <Path
        fill="#fff"
        d="m75.312 49.569 3.656-4.75a5.899 5.899 0 0 1 2.752-1.878l11.425-3.668"
      />
      <Path
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="m75.312 49.569 3.656-4.75a5.899 5.899 0 0 1 2.752-1.878l11.425-3.668"
      />
      <Path
        fill="#6A7B8C"
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="m68.825 61.599-3.16-5.472a3.557 3.557 0 0 0-4.857-1.297l-14.18 8.186a3.557 3.557 0 0 0-1.305 4.855l3.16 5.473a3.557 3.557 0 0 0 4.857 1.297l14.173-8.184a3.558 3.558 0 0 0 1.312-4.858ZM49 74.24 38.854 76.8l-.653-1.13-.035-.06-.652-1.13 7.291-7.506 1.985 3.697L49 74.24Z"
      />
      <Path
        stroke="#2A2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="m38.377 75.53-.916.528c-6.51 3.759-14.85 1.5-18.629-5.044-3.78-6.546-1.565-14.899 4.945-18.657l21.47-12.395c6.509-3.759 8.724-12.111 4.946-18.655l-.641-1.11c-3.778-6.544-12.119-8.802-18.629-5.043"
      />
      <Path
        fill="#6A7B8C"
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="m69.342 62.495-4.194-7.265 47.95-27.685 4.195 7.266-47.95 27.684Z"
      />
      <Path
        fill="#6A7B8C"
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="m125.847 31.171-5.319-9.214a1.388 1.388 0 0 0-1.895-.512l-5.594 3.229a1.389 1.389 0 0 0-.503 1.898l5.32 9.213c.385.669 1.23.897 1.895.513l5.593-3.229c.659-.38.886-1.235.503-1.898Z"
      />
      <Path
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="m109.466 29.643 4.194 7.265"
      />
      <Rect
        width={15.406}
        height={18.467}
        fill="#fff"
        rx={7.703}
        transform="scale(1 -1) rotate(-16.403 -150.62 -336.46)"
      />
      <Path
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="M90.033 126.804s1.223-15.054 2.25-16.235c13.976-16.189 10.001-27.996 8.982-35.57a17.057 17.057 0 0 1 .412-6.615l5.002-18.668a7.64 7.64 0 0 0-5.399-9.35 7.64 7.64 0 0 0-9.35 5.398l-6.727 21.871a10.825 10.825 0 0 1-7.83 7.703"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h286v172H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);
