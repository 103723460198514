import * as React from "react";
import Svg, { Circle } from "react-native-svg";
import { palette } from "../theme";

export default function CarouselEllipseIcon({
  color = palette.Disable2,
  width = 12,
  height = 12,
}) {
  return (
    <Svg width={width} height={height} viewBox="0 0 12 12" fill="none">
      <Circle cx="6" cy="6" r="6" fill={color} />
    </Svg>
  );
}
