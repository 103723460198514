import { useCallback } from "react";
import { StyleSheet, Linking, StyleProp, ViewStyle } from "react-native";

import { Button } from "@components/ui";

import { VideoIcon } from "../../../../svgs";

const styles = StyleSheet.create({
  buttonLabel: {
    fontSize: 16,
  },
});

type YoutubeLinkProps = {
  youtubeId: string;
  label?: string;
  style?: StyleProp<ViewStyle>;
};

const CustomVideoIcon = ({ color }) => {
  return <VideoIcon color={color} size={36} />;
};

export const YoutubeLink = ({ youtubeId, label, style }: YoutubeLinkProps) => {
  const goToVideo = useCallback(() => {
    const videoLink = `https://www.youtube.com/watch?v=${youtubeId}&t=1s`;
    Linking.openURL(videoLink);
  }, [youtubeId]);

  return (
    <Button
      onPress={goToVideo}
      type="link"
      icon={CustomVideoIcon}
      iconPosition="left"
      labelStyle={styles.buttonLabel}
      style={style}
    >
      {label}
    </Button>
  );
};
