import * as React from "react";
import { View } from "react-native";
import Svg, { SvgProps, Path } from "react-native-svg";

import { spacing } from "../../../../theme";

export const CalibrationPH4ProcessSvg = (props: SvgProps) => (
  <View style={{ marginTop: -spacing[8] }}>
    <Svg width={360} height={312} fill="none" {...props}>
      <Path
        d="M253.855 270.086H102.235C85.0047 270.086 74.3347 251.266 83.1747 236.435L157.565 111.59C166.765 96.1468 189.075 96.1368 198.295 111.56L272.895 236.404C281.765 251.236 271.105 270.086 253.855 270.086Z"
        fill="#C4E1FE"
        fillOpacity={0.4}
      />
      <Path
        d="M65.6985 164.038H90.9285C93.7985 164.038 95.5685 167.175 94.0985 169.64L81.7185 190.414C80.1885 192.98 76.4785 192.99 74.9385 190.414L62.5285 169.64C61.0585 167.175 62.8285 164.038 65.6985 164.038Z"
        fill="#C4E1FE"
        fillOpacity={0.4}
      />
      <Path
        d="M241.501 147.478C238.693 147.478 236.418 145.199 236.418 142.384V129.145C236.418 126.331 238.693 124.051 241.501 124.051C244.31 124.051 246.585 126.331 246.585 129.145V142.392C246.577 145.199 244.302 147.478 241.501 147.478Z"
        fill="#308AE3"
      />
      <Path
        d="M241.501 211.227C238.693 211.227 236.418 208.947 236.418 206.133V192.886C236.418 190.071 238.693 187.792 241.501 187.792C244.31 187.792 246.585 190.071 246.585 192.886V206.133C246.577 208.947 244.302 211.227 241.501 211.227Z"
        fill="#308AE3"
      />
      <Path
        d="M261.615 167.643C261.615 164.828 263.89 162.548 266.699 162.548H279.917C282.726 162.548 285.001 164.828 285.001 167.643C285.001 170.457 282.726 172.737 279.917 172.737H266.699C263.89 172.729 261.615 170.449 261.615 167.643Z"
        fill="#308AE3"
      />
      <Path
        d="M198 167.643C198 164.828 200.275 162.548 203.084 162.548H216.302C219.111 162.548 221.386 164.828 221.386 167.643C221.386 170.457 219.111 172.737 216.302 172.737H203.084C200.275 172.729 198 170.449 198 167.643Z"
        fill="#308AE3"
      />
      <Path
        d="M255.725 153.382C253.74 151.393 253.74 148.17 255.725 146.181L265.068 136.817C267.053 134.828 270.269 134.828 272.254 136.817C274.239 138.806 274.239 142.03 272.254 144.019L262.911 153.382C260.926 155.371 257.71 155.371 255.725 153.382Z"
        fill="#308AE3"
      />
      <Path
        d="M210.741 198.46C208.756 196.471 208.756 193.248 210.741 191.259L220.084 181.896C222.069 179.907 225.285 179.907 227.27 181.896C229.255 183.885 229.255 187.108 227.27 189.097L217.926 198.46C215.942 200.449 212.725 200.449 210.741 198.46Z"
        fill="#308AE3"
      />
      <Path
        d="M210.741 136.817C212.725 134.828 215.942 134.828 217.926 136.817L227.27 146.181C229.255 148.17 229.255 151.393 227.27 153.382C225.285 155.371 222.069 155.371 220.084 153.382L210.741 144.019C208.756 142.03 208.756 138.806 210.741 136.817Z"
        fill="#308AE3"
      />
      <Path
        d="M182.6 258.509C183.174 257.516 183.475 256.386 183.475 255.236V183.144H179.047H179.02H174.592V255.236C174.592 256.386 174.892 257.516 175.466 258.509L175.528 258.619C176.15 259.701 176.239 261.008 175.774 262.166L175.132 263.761C174.783 264.624 174.715 265.576 174.933 266.48L175.911 270.54C176.232 271.862 177.414 272.8 178.774 272.8H179.026H179.054H179.307C180.666 272.8 181.849 271.869 182.17 270.54L183.147 266.48C183.365 265.576 183.297 264.624 182.949 263.761L182.306 262.166C181.842 261.008 181.931 259.701 182.552 258.619L182.6 258.509Z"
        fill="white"
        stroke="#2B2E32"
        strokeWidth={2.2615}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M179.046 183.144H179.012H176.641V253.873C176.641 255.167 176.873 256.448 177.331 257.66L179.032 262.186V262.193V262.186L180.734 257.66C181.191 256.448 181.424 255.167 181.424 253.873V183.144H179.046Z"
        fill="#B6C5D3"
      />
      <Path
        d="M182.23 183.143H175.8C173.805 183.143 172.186 181.52 172.186 179.521V162.861C172.186 160.861 173.805 159.238 175.8 159.238H182.23C184.225 159.238 185.845 160.861 185.845 162.861V179.521C185.845 181.52 184.225 183.143 182.23 183.143Z"
        fill="white"
        stroke="#2B2E32"
        strokeWidth={2.2615}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M183.248 159.238L180.46 137.935H179.175H179.114H177.829L175.041 159.238L179.141 158.95L183.248 159.238Z"
        fill="#3F9A6E"
        stroke="#2B2E32"
        strokeWidth={2.2615}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M179.177 137.935V135.587C179.177 118.906 165.681 105.382 149.036 105.382C132.391 105.382 118.895 118.906 118.895 135.587V190.6C118.895 207.281 105.4 220.805 88.7546 220.805H85.9326C69.2872 220.805 55.7919 207.281 55.7919 190.6V182.89C55.7919 166.209 42.2967 152.685 25.6513 152.685H0"
        stroke="#2B2E32"
        strokeWidth={2.2615}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M176 302.372C193.673 302.372 208 298.013 208 292.637C208 287.26 193.673 282.902 176 282.902C158.327 282.902 144 287.26 144 292.637C144 298.013 158.327 302.372 176 302.372Z"
        fill="#B6C5D3"
      />
      <Path
        d="M180 253.123C195.464 253.123 208 249.277 208 244.533C208 239.789 195.464 235.944 180 235.944C164.536 235.944 152 239.789 152 244.533C152 249.277 164.536 253.123 180 253.123Z"
        fill="#70B8FF"
        fillOpacity={0.4}
      />
      <Path
        opacity={0.6}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M208 244.541C207.989 249.282 195.457 253.124 180 253.124C164.536 253.124 152 249.279 152 244.535C152 244.35 152.019 244.167 152.056 243.986L152 243.987V244.535V282.899V290.141C152 294.993 164.541 298.935 180 298.935C195.459 298.935 208 294.993 208 290.141V279.824V244.541ZM208 244.528V242.816L207.447 242.828C207.808 243.377 207.999 243.946 208 244.528Z"
        fill="#308AE3"
      />
      <Path
        d="M208 219.91V279.835V290.146C208 294.994 195.431 298.934 179.938 298.934C164.445 298.934 151.877 294.994 151.877 290.146V282.909V232.638C151.877 230.556 151.033 228.572 149.543 227.133L145.143 222.438"
        stroke="#2B2E32"
        strokeWidth={2.1962}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M174.285 212.111C165.477 212.8 159.28 215.007 154.659 217.073C152.752 217.92 148.645 219.984 145.729 221.46C144.762 221.952 145.056 223.359 146.136 223.455L151.757 223.974C153.734 224.275 155.654 224.808 157.491 225.573C162.579 227.692 170.78 229.072 180.019 229.072C195.481 229.072 208 225.218 208 220.476C208.012 216.173 197.695 212.612 184.233 211.989"
        stroke="#2B2E32"
        strokeWidth={2.1962}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M200 234.799L200 262.821"
        stroke="#2B2E32"
        strokeWidth={2.1962}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M161.442 283.763C162.753 284.12 164.078 284.425 165.402 284.703C167.495 285.14 169.217 283.657 169.217 281.551C169.217 280.928 169.217 280.305 169.217 279.696C169.217 277.577 167.495 275.629 165.402 275.205C164.078 274.927 162.753 274.623 161.442 274.265C161.442 277.418 161.442 280.584 161.442 283.763Z"
        stroke="white"
        strokeWidth={2.1962}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M161.442 272.794C161.442 277.921 161.442 283.047 161.442 288.174"
        stroke="white"
        strokeWidth={2.1962}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M174.674 269.893C174.674 276.031 174.674 284.156 174.674 290.294"
        stroke="white"
        strokeWidth={2.1962}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M184.373 269.967C184.373 276.105 184.373 284.316 184.373 290.454"
        stroke="white"
        strokeWidth={2.1962}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M174.674 280.044C177.908 280.277 181.159 280.344 184.393 280.21"
        stroke="white"
        strokeWidth={2.1962}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M197.657 288.757C197.657 282.319 197.657 275.895 197.657 269.457C194.809 273.881 191.921 278.133 189.02 282.2C193.193 281.789 197.339 280.981 201.406 279.803"
        stroke="white"
        strokeWidth={2.1962}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
    </Svg>
  </View>
);
