import { Rect } from "react-native-svg";

import { palette } from "../../../theme";

export const Rectangle = (props: any) => {
  const { data, height, color = palette.Blue } = props;

  return (
    <>
      {data.map((item, index) => (
        <Rect
          key={`rectangle-${index}`}
          x={item.value}
          y={height * 0.4}
          width={item.width}
          height={height * 0.55}
          fill={color}
        />
      ))}
    </>
  );
};
