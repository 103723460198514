import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";

import { ModulesNavigatorParamList } from "@screens/Modules/navigation";
import { Button, ErrorsMessage } from "@components/ui";

import { spacing } from "../../../theme";

type ModuleFeatureErrorProps = {
  moduleUid: string;
  message: string;
  isUpdateAvailable: boolean;
};

export const ModuleFeatureError = ({
  moduleUid,
  message,
  isUpdateAvailable,
}: ModuleFeatureErrorProps) => {
  const navigation =
    useNavigation() as StackNavigationProp<ModulesNavigatorParamList>;

  const onPress = () => {
    navigation.navigate("module-info", { uid: moduleUid });
  };

  return (
    <>
      <ErrorsMessage
        errors={[message]}
        style={{ marginVertical: spacing[5] }}
      />
      {isUpdateAvailable && (
        <Button onPress={onPress} type="primary" tx="Modules.update.title">
          Module update
        </Button>
      )}
    </>
  );
};
