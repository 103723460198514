import { Instance, SnapshotOut, types } from "mobx-state-tree";

import { i18n } from "../../i18n";
import { rnLogger } from "../../services/logger";
import { AccountStoreModel } from "../account/account-store";
import { SensorStoreModel } from "../sensor/sensor-store";
import { SensorCalibrationModel } from "../sensor/sensor-calibration";
import { DeviceStoreModel } from "../device/device-store";
import { ModuleStoreModel } from "../module/module-store";
import { ModuleConnectionModel } from "../module/module-connection";
import { DocumentsStoreModel } from "../documents/documents-store";
import { SettingsStoreModel } from "../settings/settings-store";
import { NotificationsStoreModel } from "../notifications/notifications-store";
import { GrowDirectorProStoreModel } from "../growdirectorpro/growdirectorpro-store";
import { withEnvironment } from "../extensions/with-environment";
import { withStatus } from "../extensions/with-status";

/**
 * A RootStore model.
 */
// prettier-ignore
export const RootStoreModel = types.model("RootStore").props({
  accountStore: types.optional(AccountStoreModel, { currentUser: undefined }),
  settingsStore: types.optional(SettingsStoreModel, { apiVersion: "-" } as any),
  sensorStore: types.optional(SensorStoreModel, {} as any),
  deviceStore: types.optional(DeviceStoreModel, {} as any),
  moduleStore: types.optional(ModuleStoreModel, { modules: [] }),
  growDirectorProStore: types.optional(GrowDirectorProStoreModel, { logs: [] } as any),
  moduleConnection: types.optional(ModuleConnectionModel, {} as any),
  sensorCalibration: types.optional(SensorCalibrationModel, {} as any),
  documentsStore: types.optional(DocumentsStoreModel, {} as any),
  notificationsStore: types.optional(NotificationsStoreModel, {} as any),
})
  .extend(withEnvironment)
  .extend(withStatus)
  .actions(self => {
    const afterUserLogin = () => {
    rnLogger.setContext({
      locale: self.settingsStore.currentLanguage,
      apiUrl: self.settingsStore.DEV_serverUrl,
      apiServer: self.settingsStore.DEV_apiServer,
    });

    rnLogger.setUser({
      id: self.accountStore.currentUser?.id,
      email: self.accountStore.currentUser?.email,
      accountUid: self.accountStore.currentUser?.accountUid,
    });

    self.notificationsStore.addPushNotificationsToken();
    self.notificationsStore.fetchNotificationTypes();

    self.settingsStore.initUserSettings();
    self.settingsStore.versions.fetch();

    self.documentsStore.readReleaseNotesData();

    self.deviceStore.afterUserLogin();
    self.moduleStore.afterUserLogin();

    self.sensorStore.fetchSensorsTypes();
    
  }

  const clearAllData = () => {
    // TODO: clear all store data !!!
    // self.settingsStore.clear();
    self.accountStore.clear();
    self.sensorStore.clear();
    self.deviceStore.clear();
    self.moduleStore.clear();
    self.growDirectorProStore.clear();
    self.moduleConnection.clear();
    // self.documentsStore.clear();
  }

  const unauthorizedMonitor = (response: any) => {
    if (response.status === 401) {
      self.accountStore.logout();
    }
  }

  const afterCreate = async () => {
    self.settingsStore?.versions.clear();

    await self.settingsStore.DEV_applySettings();
    self.environment.api.apisauce.addMonitor(unauthorizedMonitor);

    i18n.locale = self.settingsStore.currentLanguage;
    self.environment.api.setAcceptLanguage(self.settingsStore.currentLanguage);
    await self.accountStore.checkCurrentUser();

    rnLogger.setContext({
      locale: self.settingsStore.currentLanguage,
      apiUrl: self.settingsStore.DEV_serverUrl,
      apiServer: self.settingsStore.DEV_apiServer,
    });

    if (self.accountStore.isLoggedIn) {
      afterUserLogin();
    }
  }

  return {
    afterCreate,
    clearAllData,
    afterUserLogin,
  }
});

/**
 * The RootStore instance.
 */
export type RootStore = Instance<typeof RootStoreModel>;

/**
 * The data of a RootStore.
 */
export type RootStoreSnapshot = SnapshotOut<typeof RootStoreModel>;
