import React, { useState, useCallback, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { StackScreenProps } from "@react-navigation/stack";
import { useIsFocused } from "@react-navigation/native";

import { useStores } from "@models/index";
import { Module } from "@models/module/module";
import { Screen } from "@components/index";
import {
  Dialog,
  ErrorsAlert,
  ErrorText,
  Loading,
  MenuItem,
  useDialog,
} from "@components/ui";
import { TopBarNew } from "@components/layout";
import { InputDialog } from "@components/dialog";
import { Page } from "@screens/Pages";

import { addActionsToMenuItems } from "../../../utils/addActionsToMenuItems";
import { translate } from "../../../i18n";
import { ModulesNavigatorParamList } from "../navigation";
import {
  ModuleDetailsOnline,
  ModuleDetailsPreview,
  RebootModule,
} from "./components";
import { getModuleDetailsHints } from "./hints";
import { MenuItemsKeys, getModuleDetailsMenuItems } from "./constants";

export const ModuleDetailsScreen: React.FC<
  StackScreenProps<ModulesNavigatorParamList, "module-details">
> = observer(({ navigation, route }) => {
  const { params = {} } = route as any;

  const { moduleStore, settingsStore } = useStores();
  const [visibleRename, setVisibleRename] = useState<boolean>(false);
  const showRenameDialog = () => setVisibleRename(true);
  const hideRenameDialog = () => setVisibleRename(false);

  const [isShowRebootConfirm, setIsShowRebootConfirm] = useState(false);
  const showRebootConfirm = () => setIsShowRebootConfirm(true);
  const hideRebootConfirm = () => setIsShowRebootConfirm(false);

  const module = moduleStore.getModule(params?.uid);

  const fetchData = useCallback(async () => {
    await moduleStore.fetchModule(params?.uid);
  }, []);

  // TODO: move to model
  const [scrollItem, setScrollItem] = useState<number>(0);

  const isFocused = useIsFocused();

  useEffect(() => {
    // rnLogger.debug(`ModuleDetailsScreen useEffect isFocused`, { module: module.toJSON() }); // TODO: remove !!!
    // console.log(`ModuleDetailsScreen useEffect module`, module.toJSON()); // TODO: remove !!!
    // // console.log("ModuleDetailsScreen useEffect silent", silentRef.current)

    if (isFocused) {
      fetchData();
    }
  }, [isFocused]);

  const onDeleteHandler = async () => {
    const isOk = await moduleStore.removeModule(module.uid);
    if (isOk) {
      navigation.navigate("modules-list");
    }
  };

  const { showDialog: showDeleteDialog, ...dialogDeleteProps } = useDialog({
    onOk: onDeleteHandler,
  });

  const headerActions: MenuItem[] = addActionsToMenuItems(
    getModuleDetailsMenuItems(),
    {
      [MenuItemsKeys.Edit]: showRenameDialog,
      [MenuItemsKeys.Reboot]: showRebootConfirm,
      [MenuItemsKeys.Delete]: showDeleteDialog,
    },
  );

  if (!module)
    return moduleStore.isLoading ? (
      <Loading />
    ) : (
      <ErrorText>{translate("Modules.Details.noModule")}</ErrorText>
    );

  if (!module.isDisclaimerAccepted) {
    return (
      <Page
        id={`disclaimer-devise-${module.type}`}
        onOk={module.acceptDisclaimer}
        okText={translate("common.continue")}
        checkboxText={translate("common.agree")}
      />
    );
  }

  return (
    <Screen
      testID="ModulesDetails"
      preset="scroll"
      onRefresh={fetchData}
      header={
        <TopBarNew
          title={module.displayName}
          subtitle={module.typeName}
          menuItems={headerActions}
        />
      }
      // loading={module.isLoading}
      // loadingText={module.loadingText}
      hints={settingsStore.showHelpButton && getModuleDetailsHints(module.type)}
    >
      <ModuleDetailsPreview module={module} scrollItem={scrollItem} />

      <ModuleDetails module={module} setScrollItem={setScrollItem} />

      <InputDialog
        title={translate("Modules.Details.renameModule")}
        label={translate("fields.moduleName")}
        visible={visibleRename}
        value={module.name}
        onOk={module.updateName}
        onCancel={hideRenameDialog}
      />

      <Dialog
        {...dialogDeleteProps}
        title={translate("Modules.Details.delete")}
        okText={translate("common.delete")}
        okType="warning"
        content={`${translate("Modules.Details.deleteDescription")} ${
          module?.name
        }?`}
      />

      <RebootModule
        module={module}
        visible={isShowRebootConfirm}
        onClose={hideRebootConfirm}
      />

      {module.isUpdating ? <Loading /> : null}

      <ErrorsAlert errors={module.statusErrors} onCancel={module.resetErrors} />
    </Screen>
  );
});

type ModuleDetailsProps = {
  module: Module;
  setScrollItem?: (item: number) => void;
};
const ModuleDetails = observer(
  ({ module, setScrollItem }: ModuleDetailsProps) => {
    return (
      <ModuleDetailsOnline module={module} setNewItemScroll={setScrollItem} />
    );
  },
);
