import * as React from "react";
import Svg, { Path } from "react-native-svg";

export default function BlinkIcon({
  color = "#2C3E50",
  width = 19,
  height = 20,
}) {
  return (
    <Svg width={width} height={height} viewBox="0 0 19 20" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 0.25C9.41421 0.25 9.75 0.585786 9.75 1V3C9.75 3.41421 9.41421 3.75 9 3.75C8.58579 3.75 8.25 3.41421 8.25 3V1C8.25 0.585786 8.58579 0.25 9 0.25ZM9 7.75C7.75736 7.75 6.75 8.75736 6.75 10C6.75 11.2426 7.75736 12.25 9 12.25C10.2426 12.25 11.25 11.2426 11.25 10C11.25 8.75736 10.2426 7.75 9 7.75ZM5.25 10C5.25 7.92893 6.92893 6.25 9 6.25C11.0711 6.25 12.75 7.92893 12.75 10C12.75 12.0711 11.0711 13.75 9 13.75C6.92893 13.75 5.25 12.0711 5.25 10ZM15.9303 4.13189C16.2232 3.839 16.2232 3.36413 15.9303 3.07123C15.6374 2.77834 15.1626 2.77834 14.8697 3.07123L13.4697 4.47123C13.1768 4.76413 13.1768 5.239 13.4697 5.53189C13.7626 5.82479 14.2374 5.82479 14.5303 5.53189L15.9303 4.13189ZM15.25 10C15.25 9.58579 15.5858 9.25 16 9.25H18C18.4142 9.25 18.75 9.58579 18.75 10C18.75 10.4142 18.4142 10.75 18 10.75H16C15.5858 10.75 15.25 10.4142 15.25 10ZM14.5303 14.4697C14.2374 14.1768 13.7626 14.1768 13.4697 14.4697C13.1768 14.7626 13.1768 15.2374 13.4697 15.5303L14.8697 16.9303C15.1626 17.2232 15.6374 17.2232 15.9303 16.9303C16.2232 16.6374 16.2232 16.1626 15.9303 15.8697L14.5303 14.4697ZM9 16.25C9.41421 16.25 9.75 16.5858 9.75 17V19C9.75 19.4142 9.41421 19.75 9 19.75C8.58579 19.75 8.25 19.4142 8.25 19V17C8.25 16.5858 8.58579 16.25 9 16.25ZM4.52994 15.5303C4.82283 15.2374 4.82283 14.7626 4.52994 14.4697C4.23705 14.1768 3.76217 14.1768 3.46928 14.4697L2.06928 15.8697C1.77639 16.1626 1.77639 16.6374 2.06928 16.9303C2.36217 17.2232 2.83705 17.2232 3.12994 16.9303L4.52994 15.5303ZM0.25 10C0.25 9.58579 0.585786 9.25 1 9.25H3C3.41421 9.25 3.75 9.58579 3.75 10C3.75 10.4142 3.41421 10.75 3 10.75H1C0.585786 10.75 0.25 10.4142 0.25 10ZM3.12994 3.07123C2.83705 2.77834 2.36217 2.77834 2.06928 3.07123C1.77639 3.36413 1.77639 3.839 2.06928 4.13189L3.46928 5.53189C3.76217 5.82479 4.23705 5.82479 4.52994 5.53189C4.82283 5.239 4.82283 4.76413 4.52994 4.47123L3.12994 3.07123Z"
        fill={color}
      />
    </Svg>
  );
}
