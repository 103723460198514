import React from "react";
import { observer } from "mobx-react-lite";
import { StyleSheet } from "react-native";

import { StackScreenProps } from "@react-navigation/stack";
import { useStores } from "@models/index";

import {
  TemperatureUnit,
  TimeSystem,
  // VolumeUnit,
} from "@models/settings/user-settings-store";
import { Text, RadioItem } from "@components/ui";
import { TopBar, TopBarAlign } from "@components/layout";

import { Screen } from "../../../components";
import { SettingsNavigatorParamList } from "../navigation";
import { translate } from "../../../i18n";
import { palette, spacing } from "../../../theme";

const styles = StyleSheet.create({
  screen: {
    padding: spacing[4],
  },
  title: {
    marginTop: spacing[4],
    marginBottom: spacing[2],
    color: palette.GrayDark,
  },
});

export const UnitsScreen: React.FC<
  StackScreenProps<SettingsNavigatorParamList, "settings-units">
> = observer(() => {
  const { settingsStore } = useStores();

  return (
    <Screen
      style={styles.screen}
      header={
        <TopBar
          title={translate("Settings.units")}
          align={TopBarAlign.Left}
          back
        />
      }
    >
      {/* <Text style={styles.title}>{translate("Settings.volume")}</Text> */}
      {/* {settingsStore.availableVolumeUnits.map((unit: VolumeUnit) => ( */}
      {/*  <RadioItem */}
      {/*    key={`volume-${unit}`} */}
      {/*    value={unit} */}
      {/*    label={translate(`Settings.Formats.Volumes.${unit}`)} */}
      {/*    onPress={settingsStore.setVolume} */}
      {/*    checked={settingsStore.currentVolumeUnit === unit} */}
      {/*  /> */}
      {/* ))} */}

      <Text style={styles.title}>{translate("Settings.temperature")}</Text>
      {settingsStore.availableTemperatureUnits.map((unit: TemperatureUnit) => (
        <RadioItem
          key={`temperature-${unit}`}
          value={unit}
          label={translate(`Settings.Formats.Temperature.${unit}`)}
          onPress={settingsStore.setTemperature}
          checked={settingsStore.currentTemperatureUnit === unit}
        />
      ))}

      <Text style={styles.title}>{translate("Settings.timeFormat")}</Text>
      {settingsStore.availableTimeSystems.map((timeSystem: TimeSystem) => (
        <RadioItem
          key={`time-${timeSystem}`}
          value={timeSystem}
          label={translate(`Settings.Formats.Times.${timeSystem}`)}
          onPress={settingsStore.setTimeFormat}
          checked={settingsStore.currentTimeSystem === timeSystem}
        />
      ))}
    </Screen>
  );
});
