import { SendCodeToEmail } from "@components/common";
import { useStores } from "@models/index";

import { AccountDeleteState, TContent } from "../types";

// TODO: will be deleted !!!
export const EnterEmail = ({ onNextState, email }: TContent) => {
  const goToEnterCode = (newEmail: string) => {
    onNextState({
      nextState: AccountDeleteState.EnterCode,
      newEmail,
    });
  };

  const { accountStore } = useStores();
  const onRequest = (userName: string) => {
    return accountStore.accountDeleteRequest(userName);
  };

  return (
    <SendCodeToEmail
      onPress={goToEnterCode}
      onRequest={onRequest}
      defaultUserName={email}
    />
  );
};
