import { useMemo } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { observer } from "mobx-react-lite";

import { Button, Text } from "@components/ui";
import { TNotification } from "@models/notifications/notifications-store";
import {
  NotificationType,
  NotificationSeverityLevel,
} from "@models/notifications/constants";

import { spacing } from "../../../../theme";
import { translate } from "../../../../i18n";
import { useAppTheme } from "../../../../hooks";
import { DotIcon } from "../../../../svgs";
import { NotificationTypeIcon } from "./NotificationTypeIcon";

const createStyles = (theme: any) =>
  StyleSheet.create({
    container: {
      width: "100%",
      backgroundColor: theme.colors.inputBG,
      borderRadius: spacing[4],
      padding: spacing[4],
      marginBottom: spacing[3],
    },
    warning: {
      backgroundColor: theme.colors.errorBG,
    },
    header: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    headerContent: {
      flexDirection: "row",
      alignItems: "center",
    },
    title: {
      fontSize: 16,
      lineHeight: 22,
      ...theme.fonts.medium,
    },
    titleWarning: {
      color: theme.colors.warning,
    },
    content: {
      marginTop: spacing[2],
      fontSize: 14,
      lineHeight: 20,
    },
    button: {
      marginTop: spacing[4],
    },
    dateContainer: {
      marginTop: spacing[3],
      flexDirection: "row",
      justifyContent: "space-between",
    },
    date: {
      fontSize: 12,
      lineHeight: 16,
      color: theme.colors.subLabel,
    },
    disabledText: {
      color: theme.colors.primaryDisabledText,
    },
  });

type NotificationsCardProps = {
  notification: TNotification;
  actionText?: string;
  action?: () => void;
};

export const NotificationCard = observer(
  ({ notification, actionText, action }: NotificationsCardProps) => {
    const theme = useAppTheme();
    const styles = useMemo(() => createStyles(theme), [theme]);

    const {
      uid,
      type,
      severityLevel = NotificationSeverityLevel.Info,
      title,
      body,
      isNew,
      isDetailed,
    } = notification;

    const isWarning = [
      NotificationSeverityLevel.Warning,
      NotificationSeverityLevel.Critical,
    ].includes(severityLevel as NotificationSeverityLevel);
    const styleContainer = [styles.container, isWarning && styles.warning];
    const styleText = [
      styles.title,
      isWarning && styles.titleWarning,
      !isDetailed && !isNew && styles.disabledText,
    ];

    return (
      <TouchableOpacity
        style={styleContainer}
        key={uid}
        onPress={notification.open}
      >
        <View style={styles.header}>
          <View style={styles.headerContent}>
            <NotificationTypeIcon
              type={type as NotificationType}
              severityLevel={severityLevel as NotificationSeverityLevel}
            />
            <Text style={styleText}>{title}</Text>
          </View>

          {isNew && <DotIcon />}
        </View>
        <Text
          numberOfLines={isDetailed ? 4 : 1}
          style={[styles.content, !isDetailed && !isNew && styles.disabledText]}
        >
          {body}
        </Text>

        {action && actionText ? (
          <Button style={styles.button} onPress={action}>
            {actionText}
          </Button>
        ) : null}

        <View style={styles.dateContainer}>
          <Text style={styles.date}>{notification.displayDate}</Text>
          {isDetailed && (
            <Text style={styles.date}>
              {translate("Settings.notifications.openedAt", {
                date: notification.displayOpenAt,
              })}
            </Text>
          )}
        </View>
      </TouchableOpacity>
    );
  },
);
