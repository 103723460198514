import { useMemo } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import { Text } from "@components/ui";

import { useAppTheme } from "../../../../../hooks";
import { normalizeFontSize } from "../../../../../utils/sizes";
import { spacing } from "../../../../../theme";

const createStyles = (theme: any) =>
  StyleSheet.create({
    title: {
      marginTop: spacing[4],
      marginBottom: spacing[1],
      fontSize: normalizeFontSize(20, 22),
      lineHeight: 32,
      ...theme.fonts.medium,
    },
    description: {
      fontSize: normalizeFontSize(14, 16),
      lineHeight: 20,
      color: theme.colors.label,
    },
  });

type HeaderPreferenceProps = {
  title: string;
  description: string;
  style?: StyleProp<ViewStyle>;
};

export const HeaderPreference = ({
  title,
  description,
  style,
}: HeaderPreferenceProps) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <View style={style}>
      <Text style={styles.title}>{title}</Text>
      <Text style={styles.description}>{description}</Text>
    </View>
  );
};
