import React, { useCallback, useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { StackScreenProps } from "@react-navigation/stack";
import { Linking, View, StyleSheet } from "react-native";
import { useIsFocused } from "@react-navigation/native";

import { Screen } from "@components/index";
import { useStores } from "@models/index";
import {
  HeaderAction,
  HeadingWithActions,
  Dialog,
  useDialog,
  Text,
  ErrorsMessage,
} from "@components/ui";

import { AddIcon } from "../../../svgs/menu";
import { DevicesNavigatorParamList } from "../navigation";
import { DeviceCard } from "../components";
import DevicesNoData from "./components/NoData";
import { FilterTabs } from "./components/FilterTabs";
import { NoDevices } from "./components/NoDevices";
import { spacing } from "../../../theme";
import { translate } from "../../../i18n";
import { Filter } from "../../../svgs";
import { DevicesListHintsData, DeviceListOneTimeHints } from "./hints";

const styles = StyleSheet.create({
  message: {
    marginHorizontal: spacing[4],
    marginVertical: spacing[2],
  },
  devicesList: {
    paddingHorizontal: spacing[4],
    paddingTop: spacing[3],
  },
});

const NoDataDialog = (props) => (
  <Dialog
    {...props}
    okText={translate("NoData.buyNew")}
    title={translate("NoData.noFreeSockets")}
    content={translate("NoData.noFreeSocketsDescription")}
  />
);

export const DevicesListScreen: React.FC<
  StackScreenProps<DevicesNavigatorParamList, "devices-list">
> = observer(({ navigation, route }) => {
  const { deviceStore, moduleStore, accountStore, settingsStore } = useStores();

  const buyModuleHandler = () => {
    Linking.openURL("https://growdirector.com/shop/"); // TODO: move to config !!!
  };

  const { showDialog: showNoFreeSocketsDialog, ...dialogProps } = useDialog({
    onOk: buyModuleHandler,
  });

  const addHandler = () => {
    if (moduleStore.modulesWithFeeSockets().length) {
      navigation.navigate("devices-add");
    } else {
      showNoFreeSocketsDialog();
    }
  };

  const allDevicesByMode = deviceStore.byMode;
  const isAppliedFilter = Boolean(deviceStore.viewFilter.hiddenUids.length);

  const headerActions = useMemo<HeaderAction[]>(
    () => [
      {
        key: "device-filter",
        icon: Filter,
        onPress: () => navigation.navigate("devices-filter"),
        selected: isAppliedFilter,
      },
      {
        key: "device-add",
        icon: AddIcon,
        onPress: addHandler,
      },
    ],
    [isAppliedFilter],
  );

  const isFocused = useIsFocused();
  const fetchData = async (force = false) => {
    moduleStore.fetchModules({ force });
    await deviceStore.fetchDevices({ force });
    await deviceStore.fetchDevicesViews({ force });
  };

  useEffect(() => {
    fetchData();
  }, [accountStore.currentUser.id, isFocused]);

  const onRefresh = useCallback(async () => {
    await fetchData(true);
  }, []);

  if (deviceStore.isLoaded && !deviceStore.visibleDevices.length) {
    return (
      <Screen testID="DevicesList">
        <DevicesNoData headerActions={headerActions} />
        <NoDataDialog {...dialogProps} />
      </Screen>
    );
  }

  const devices = deviceStore.filteredAndSortedDevices;

  return (
    <Screen
      testID="DevicesList"
      preset="scroll"
      header={
        <>
          <HeadingWithActions tx="Devices.List.title" actions={headerActions} />
          <FilterTabs />
        </>
      }
      onRefresh={onRefresh}
      refreshing={!deviceStore.isLoaded}
      hints={settingsStore.showHelpButton && DevicesListHintsData()}
    >
      <ErrorsMessage
        errors={deviceStore.statusErrors}
        onCancel={deviceStore.resetErrors}
        style={styles.message}
      />

      {isAppliedFilter ? (
        <Text style={styles.message}>
          {translate("Sensors.List.count", {
            count: deviceStore.viewFilter.visibleUids.length,
            total: allDevicesByMode.length, // deviceStore.viewFilter.total,
          })}
        </Text>
      ) : null}

      {devices.length ? (
        <View style={styles.devicesList}>
          {devices.map((device) => (
            <DeviceCard key={device.uid} device={device} />
          ))}
        </View>
      ) : (
        deviceStore.isLoaded && (
          <NoDevices deviceMode={deviceStore.filterMode} />
        )
      )}

      <NoDataDialog {...dialogProps} />

      <DeviceListOneTimeHints />
    </Screen>
  );
});
