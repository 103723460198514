import { ApiResponse } from "apisauce";

import { SensorData, SensorState, SensorView } from "@models/sensor/sensor";

import { TPeriod } from "../../utils/timeConverter";
import { ApiProblem, processResponse } from "./response-resolver";
import { Api } from "./api";

const SENSORS_PATH = "/Sensors";
const SENSOR_DATA_PATH = "/Sensors/data";
const SENSOR_TYPES_PATH = "/Sensors/types";
const EXPORT_SENSORS_DATA_PATH = "/ExportData/sensors/file";

export type TSensorHistoryItem = {
  value: number;
  dateTime: Date;
  state: SensorState;
};

type GetSensorHistoryProps = {
  uid: string;
  startDate: string;
  endDate: string;
  strict?: boolean;
  limit?: number;
};

export enum SensorCalibrationsStatus {
  Started = "Started",
  FirstValueStabilization = "FirstValueStabilization",
  FirstValueStart = "FirstValueStart",
  FirstValueStop = "FirstValueStop",
  SecondValueStabilization = "SecondValueStabilization",
  SecondValueStart = "SecondValueStart",
  SecondValueStop = "SecondValueStop",
  Completed = "Completed",
  Expired = "Expired",
  Error = "Error",
  Cancelled = "Cancelled",
  NotCalibrated = "NotCalibrated",
}

export type TSensorCalibration = {
  uid: string;
  startTime: Date;
  stopTime: Date;
  firstValue: number;
  firstRawValue: number;
  secondValue: number;
  secondRawValue: number;
  coefficient1: number;
  coefficient2: number;
  status: SensorCalibrationsStatus;
};

type GetSensorHistoryCalibrationsProps = {
  uid: string;
  startDate: string;
  endDate: string;
  status?: SensorCalibrationsStatus;
};

type UpdateSensorData = {
  name?: string;
  minValue?: number;
  maxValue?: number;
};

export type GetSensorsViewsResult =
  | { kind: "ok"; data: SensorView[] }
  | ApiProblem;
export type GetSensorsDataResult =
  | { kind: "ok"; data: SensorData[] }
  | ApiProblem;
export type GetSensorsHistoryResult =
  | { kind: "ok"; data: TSensorHistoryItem[] }
  | ApiProblem;
export type GetSensorHistoryCalibrationsResult =
  | { kind: "ok"; data: TSensorCalibration[] }
  | ApiProblem;

type AnyResult = { kind: "ok"; data: any } | ApiProblem;

export class SensorsApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getSensorsViews(): Promise<GetSensorsViewsResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        SENSORS_PATH,
      );
      return processResponse(response);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async getSensorsData(): Promise<GetSensorsDataResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        SENSOR_DATA_PATH,
      );

      return processResponse(response);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async getSensorData(uid: string): Promise<AnyResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `${SENSORS_PATH}/${uid}`,
      );
      return processResponse(response);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async getOneSensorData(uid: string): Promise<AnyResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `${SENSORS_PATH}/${uid}/data`,
      );
      return processResponse(response);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async setSensorType(uid: string, typeName: string): Promise<AnyResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(
        `${SENSORS_PATH}/${uid}/set_type`,
        { name: typeName },
      );
      return processResponse(response);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async getSensorsTypes(): Promise<AnyResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        SENSOR_TYPES_PATH,
      );
      return processResponse(response);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async update(uid: string, data: UpdateSensorData): Promise<AnyResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.put(
        `${SENSORS_PATH}/${uid}`,
        data,
      );

      return processResponse(response);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  // TODO: remove this endpoint !!!
  async remove(uid: string): Promise<AnyResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.delete(
        `/Sensors/${uid}/remove_from_dashboard`,
        { uid },
      );

      return processResponse(response);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async getSensorHistory({
    uid,
    startDate,
    endDate,
    strict = false,
    limit,
  }: GetSensorHistoryProps): Promise<GetSensorsHistoryResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `${SENSORS_PATH}/${uid}/history`,
        {
          startDate,
          endDate,
          strict,
          limit,
        },
      );

      return processResponse(response, (data) => {
        const filteredData = data.filter(Boolean).length ? data : [];
        return filteredData.map((item) =>
          item
            ? {
                ...item,
                dateTime: new Date(item.dateTime),
              }
            : null,
        );
      });
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async getSensorCalibrations({
    uid,
    startDate,
    endDate,
    status = SensorCalibrationsStatus.Completed,
  }: GetSensorHistoryCalibrationsProps): Promise<GetSensorHistoryCalibrationsResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `${SENSORS_PATH}/${uid}/calibrations`,
        {
          startDate,
          endDate,
          status,
        },
      );

      return processResponse(response, (data) => {
        const filteredData = data.filter(Boolean).length ? data : [];
        return filteredData.map((item) =>
          item
            ? {
                ...item,
                startTime: new Date(item.startTime),
                stopTime: new Date(item.stopTime),
              }
            : null,
        );
      });
    } catch (e) {
      return { kind: "error" };
    }
  }

  // monthAgo(date = new Date()) {
  //   date.setMonth(date.getMonth() - 1);
  //   return date;
  // }

  // async history(
  //   uid: string,
  //   start: Date | undefined,
  //   end = new Date(),
  // ): Promise<any> {
  //   const StartDate = (start || this.monthAgo()).toISOString();
  //   const EndDate = end.toISOString(); // this.monthAgo();
  //   try {
  //     const response: ApiResponse<any> = await this.api.apisauce.get(
  //       `/Sensor/${uid}/history`,
  //       { StartDate, EndDate },
  //     );

  //     return { kind: "ok", data: response.data };
  //   } catch (e) {
  //     if (__DEV__) console.log(e.message);
  //     return { kind: "error" };
  //   }
  // }
}

export const getExportSensorsDataPath = (
  uids: string[],
  period: TPeriod,
): string | undefined => {
  if (!uids?.length) return undefined;

  const params = [
    ...uids.map((uid) => `sensors=${uid}`),
    `from=${period.startDate}`,
    `to=${period.endDate}`,
  ].join("&");

  return `${EXPORT_SENSORS_DATA_PATH}?${params}`;
};
