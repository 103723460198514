// https://github.com/expo/google-fonts/tree/master/font-packages/noto-sans\
import * as Font from "expo-font";

export const initFonts = async () => {
  /* eslint-disable global-require */
  await Font.loadAsync({
    // Google Font: https://fonts.google.com/noto/specimen/Noto+Sans
    "NotoSans-Regular": require("./noto-sans/NotoSans_400Regular.ttf"),
    // "NotoSans_400Regular_Italic": require('./noto-sans/NotoSans_400Regular_Italic.ttf'),
    "NotoSans-Bold": require("./noto-sans/NotoSans_700Bold.ttf"),

    // FontSquirrel: https://www.fontsquirrel.com/fonts/noto-sans
    "NotoSans-Medium": require("./noto-sans/NotoSans-Medium.ttf"),
    "NotoSans-SemiBold": require("./noto-sans/NotoSans-SemiBold.ttf"),
  });
  /* eslint-enable global-require */
};

export const fontConfig = {
  web: {
    regular: {
      fontFamily:
        '"NotoSans-Regular", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
      fontWeight: "400" as const,
    },
    bold: {
      fontFamily:
        '"NotoSans-Bold", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
      fontWeight: "700" as const,
    },
    medium: {
      fontFamily:
        '"NotoSans-Medium", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
      fontWeight: "500" as const,
    },
    light: {
      fontFamily:
        '"NotoSans-Regular", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
      fontWeight: "300" as const,
    },
    thin: {
      fontFamily: "NotoSans-Regular",
      fontWeight: "100" as const,
    },
  },
  ios: {
    regular: {
      fontFamily: "NotoSans-Regular",
      fontWeight: "400" as const,
    },
    bold: {
      fontFamily: "NotoSans-Bold",
      fontWeight: "700" as const,
    },
    medium: {
      fontFamily: "NotoSans-Medium",
      fontWeight: "500" as const,
    },
    light: {
      fontFamily: "NotoSans-Regular",
      fontWeight: "300" as const,
    },
    thin: {
      fontFamily: "NotoSans-Regular",
      fontWeight: "100" as const,
    },
  },
  android: {
    regular: {
      fontFamily: "NotoSans-Regular",
      fontWeight: "400" as const,
    },
    bold: {
      fontFamily: "NotoSans-Bold",
      fontWeight: "700" as const,
    },
    medium: {
      fontFamily: "NotoSans-Medium",
      fontWeight: "500" as const,
    },
    light: {
      fontFamily: "NotoSans-Regular",
      fontWeight: "300" as const,
    },
    thin: {
      fontFamily: "NotoSans-Regular",
      fontWeight: "100" as const,
    },
  },
  default: {
    regular: {
      fontFamily: "NotoSans-Regular",
      fontWeight: "normal" as const,
    },
    bold: {
      fontFamily: "NotoSans-Bold",
      fontWeight: "700" as const,
    },
    medium: {
      fontFamily: "NotoSans-Medium",
      fontWeight: "normal" as const,
    },
    light: {
      fontFamily: "NotoSans-Regular",
      fontWeight: "normal" as const,
    },
    thin: {
      fontFamily: "NotoSans-Regular",
      fontWeight: "normal" as const,
    },
  },
};
