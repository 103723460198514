import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";

import {
  ClimateHistoryFilter,
  ClimateHistoryScreen,
  SensorCalibration,
  SensorCalibrationLongTime,
  SensorDetailsScreen,
  SensorsViewFilter,
  SensorTypesListScreen,
  SensorRangeScreen,
} from "@screens/Sensors";

import {
  DeviceDetailsScreen,
  DeviceRulesScreen,
  DevicesAddScreen,
  DevicesFilter,
  DeviceHistoryScreen,
  DeviceRulesCopyScreen,
} from "@screens/Devices";

import {
  ModuleAddChooseConnection,
  ModuleAddManualScreen,
  ModuleAddSmartScreen,
  SuccessfullyConnectedScreen,
  ModuleDetailsScreen,
  ModuleInfoScreen,
  ModuleDiagnosticHistoryScreen,
  ModuleActivityHistoryScreen,
  ModuleUpdateScreen,
  ModuleAddManualOnboardingScreen,
} from "@screens/Modules";

import {
  AccountScreen,
  ChangePasswordScreen,
  ChangeEmailScreen,
  AccountDeleteScreen,
  HelpFAQScreen,
  HelpManualScreen,
  HelpScreen,
  LanguageScreen,
  NotificationsScreen,
  NotificationsPreferencesScreen,
  NotificationsPreferencesTypeScreen,
  SupportScreen,
  UnitsScreen,
  ReleaseNotesScreen,
} from "@screens/Settings";

import {
  NewPasswordSetUpSuccess,
  RequestSentSuccess,
} from "@screens/StatusScreens";
import { Onboarding } from "@screens/Onboarding";

import { DevicesNavigatorParamList } from "@screens/Devices/navigation";
import { ModulesNavigatorParamList } from "@screens/Modules/navigation";
import { SensorsNavigatorParamList } from "@screens/Sensors/navigation";
import { SettingsNavigatorParamList } from "@screens/Settings/navigation";

import { NotificationsViewFilter } from "@screens/Settings/Notifications/components/Filter";
import { DEVSettingsScreen } from "@screens/Settings/DEV";
import { Page } from "@screens/Pages";

import { TabNavigator } from "./TabNavigator";

type NavigatorParamList = DevicesNavigatorParamList &
  ModulesNavigatorParamList &
  SensorsNavigatorParamList &
  SettingsNavigatorParamList & {
    home: undefined;
    "dev-settings": undefined;
    Page: { id: string };
  };

const Stack = createNativeStackNavigator<NavigatorParamList>();
export const UserStack = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
      initialRouteName="home"
    >
      <Stack.Screen name="home" component={TabNavigator} />

      <Stack.Screen name="Page" component={Page} />
      {/* <Stack.Screen name="sensors-list" component={SensorsListScreen} /> */}
      <Stack.Screen name="sensor-details" component={SensorDetailsScreen} />
      <Stack.Screen name="climate-history" component={ClimateHistoryScreen} />
      <Stack.Screen name="history-filter" component={ClimateHistoryFilter} />
      <Stack.Screen name="sensors-filter" component={SensorsViewFilter} />
      <Stack.Screen name="sensor-calibration" component={SensorCalibration} />
      <Stack.Screen
        name="sensor-calibration-longtime"
        component={SensorCalibrationLongTime}
      />
      <Stack.Screen
        name="sensor-types-list"
        component={SensorTypesListScreen}
      />
      <Stack.Screen name="sensor-range" component={SensorRangeScreen} />

      {/* <Stack.Screen name="devices-list" component={DevicesListScreen} /> */}
      <Stack.Screen name="devices-add" component={DevicesAddScreen} />
      <Stack.Screen name="devices-filter" component={DevicesFilter} />
      <Stack.Screen name="device-details" component={DeviceDetailsScreen} />

      <Stack.Screen name="device-rules-edit" component={DeviceRulesScreen} />
      <Stack.Screen
        name="device-rules-copy"
        component={DeviceRulesCopyScreen}
      />

      <Stack.Screen name="device-history" component={DeviceHistoryScreen} />

      {/* <Stack.Screen name="modules-list" component={ModulesListScreen} /> */}
      <Stack.Screen
        name="module-add-choose-connection"
        component={ModuleAddChooseConnection}
      />
      <Stack.Screen
        name="module-add-manual"
        component={ModuleAddManualScreen}
      />
      <Stack.Screen
        name="module-add-manual-onboarding"
        component={ModuleAddManualOnboardingScreen}
      />
      <Stack.Screen name="module-add-smart" component={ModuleAddSmartScreen} />
      <Stack.Screen
        name="module-add-successfully-connected"
        component={SuccessfullyConnectedScreen}
      />
      <Stack.Screen name="module-details" component={ModuleDetailsScreen} />
      <Stack.Screen name="module-update" component={ModuleUpdateScreen} />
      <Stack.Screen name="module-info" component={ModuleInfoScreen} />
      <Stack.Screen
        name="module-diagnostic-history"
        component={ModuleDiagnosticHistoryScreen}
      />
      <Stack.Screen
        name="module-activity-history"
        component={ModuleActivityHistoryScreen}
      />

      {/* <Stack.Screen name="settings-list" component={SettingsScreen} /> */}
      <Stack.Screen name="settings-account" component={AccountScreen} />
      <Stack.Screen
        name="settings-account-password"
        component={ChangePasswordScreen}
      />
      <Stack.Screen
        name="settings-account-change-email"
        component={ChangeEmailScreen}
      />
      <Stack.Screen
        name="settings-account-delete"
        component={AccountDeleteScreen}
      />

      <Stack.Screen name="settings-help" component={HelpScreen} />
      <Stack.Screen name="settings-help-faq" component={HelpFAQScreen} />
      <Stack.Screen name="settings-help-manual" component={HelpManualScreen} />
      <Stack.Screen
        name="settings-help-release-notes"
        component={ReleaseNotesScreen}
      />

      <Stack.Screen
        name="settings-new-password-success"
        component={NewPasswordSetUpSuccess}
      />
      <Stack.Screen name="settings-support" component={SupportScreen} />
      <Stack.Screen name="settings-language" component={LanguageScreen} />
      <Stack.Screen name="settings-units" component={UnitsScreen} />
      <Stack.Screen name="settings-onboarding" component={Onboarding} />
      <Stack.Screen
        name="settings-request-sent-success"
        component={RequestSentSuccess}
      />

      <Stack.Screen
        name="settings-notifications"
        component={NotificationsScreen}
      />
      <Stack.Screen
        name="settings-notifications-preferences"
        component={NotificationsPreferencesScreen}
      />
      <Stack.Screen
        name="settings-notifications-filter"
        component={NotificationsViewFilter}
      />
      <Stack.Screen
        name="settings-notifications-preferences-type"
        component={NotificationsPreferencesTypeScreen}
      />

      <Stack.Screen name="dev-settings" component={DEVSettingsScreen} />
    </Stack.Navigator>
  );
};
