import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import { observer } from "mobx-react-lite";

import { Rule, RuleScheduleItem } from "@models/rule/rule";
import { Button, Dialog } from "@components/ui";

import { translate } from "../../../../../../i18n";
import { sortDataByTime } from "../../../../../../utils/sortDataBy";
import { AddScheduleRuleButton } from "./AddScheduleRuleButton";
import { ScheduleCard } from "./ScheduleCard";
import { ScheduleItemData, ScheduleSelector } from "./ScheduleSelector";

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

type RuleFormProps = {
  rule: Rule;
  onSubmit: (data: RuleScheduleItem[]) => void;
  onUnsavedChanges?: (isUnsavedChanges: boolean) => void;
};

export const FormSchedule = observer(
  ({ rule, onSubmit, onUnsavedChanges }: RuleFormProps) => {
    const [deleteIndex, setDeleteIndex] = useState<number | undefined>();
    const [scheduleItems, setScheduleItems] = useState<RuleScheduleItem[]>(
      rule.sortedSchedule.map((item) => item.toJSON()), // getSnapshot
    );
    const [scheduleItemData, setScheduleItemData] = useState<
      RuleScheduleItem & { index: number }
    >();
    const [isScheduleSelector, setScheduleSelector] = useState<boolean>(false);

    const openScheduleSelector = () => {
      setScheduleSelector(true);
    };
    const closeScheduleSelector = () => {
      setScheduleSelector(false);
    };

    const onSubmitHandler = () => {
      onSubmit(scheduleItems);
    };

    const onEditHandler = (index: number) => {
      const { startAt, endAt } = scheduleItems[index] || {};
      setScheduleItemData({
        index,
        startAt,
        endAt,
      });
      openScheduleSelector();
    };

    const onAddScheduleHandler = () => {
      onEditHandler(scheduleItems.length);
    };

    const changeScheduleItemHandler = (scheduleItem: ScheduleItemData) => {
      if (scheduleItem) {
        const { startAt, endAt, index } = scheduleItem;
        setScheduleItems((prevData) => {
          const newData = [...prevData];
          newData[index] = { startAt, endAt } as RuleScheduleItem;

          return sortDataByTime(newData, "startAt");
        });
        onUnsavedChanges(true);
      }

      closeScheduleSelector();
    };

    const hideDeleteDialog = () => {
      setDeleteIndex(undefined);
    };

    const onDeleteScheduleHandler = () => {
      const newScheduleData = scheduleItems.filter((_, i) => i !== deleteIndex);
      setScheduleItems(newScheduleData);
      onUnsavedChanges(true);
      hideDeleteDialog();
    };

    const isOneItem = scheduleItems.length === 1;

    /* eslint-disable react/no-array-index-key */
    return (
      <View style={styles.container}>
        {scheduleItems?.length ? (
          <View>
            {scheduleItems.map((scheduleItem, index) => (
              <ScheduleCard
                scheduleItem={scheduleItem}
                onEdit={() => onEditHandler(index)}
                onDelete={() => setDeleteIndex(index)}
                key={`schedule-${index}`}
                isDeleteDisabled={isOneItem}
              />
            ))}
          </View>
        ) : null}

        <AddScheduleRuleButton onPress={onAddScheduleHandler} />

        <Button
          type="primary"
          onPress={onSubmitHandler}
          tx="Devices.Rules.setRule"
          disabled={rule.isLoading || !scheduleItems?.length}
        >
          Set Rule
        </Button>

        <Dialog
          title={translate("common.confirm")}
          content={translate("Devices.Rules.wantRemoveItem")}
          okText={translate("common.yes")}
          visible={deleteIndex !== undefined}
          onOk={onDeleteScheduleHandler}
          onCancel={hideDeleteDialog}
          onDismiss={hideDeleteDialog}
        />

        {isScheduleSelector ? (
          <ScheduleSelector
            scheduleItemData={scheduleItemData}
            onClose={closeScheduleSelector}
            onChange={changeScheduleItemHandler}
          />
        ) : null}
      </View>
    );
    /* eslint-enable react/no-array-index-key */
  },
);
