import React, { useState } from "react";
import { View, StyleSheet } from "react-native";

import { UserRegister, useStores } from "@models/index";
import { Screen } from "@components/index";
import { LayoutCenter } from "@components/layout";
import { Button, Form, Logo, Text } from "@components/ui";
import { PasswordInput, TextInput } from "@components/inputs";

import { emailValidator, passwordValidator } from "../../utils/validator";
import { spacing } from "../../theme";
import { translate } from "../../i18n";

const styles = StyleSheet.create({
  screen: {
    padding: spacing[4],
  },
  userAgreement: {
    marginVertical: spacing[4],
    flex: 2,
  },
  labelText: {
    textAlign: "center",
    fontSize: 12,
  },
  userLogin: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    marginVertical: spacing[4],
  },
  userAgreementLinks: {
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
  },
});

const defaultData = {
  FirstName: undefined,
  LastName: undefined,
  Email: undefined,
  Password: undefined,

  PhoneNumber: undefined,
  CompanyName: undefined,
  CompanyUrl: undefined,
  RoleInCompany: undefined,
  Description: undefined,
};

export function SignUpScreen({ navigation }) {
  const [formData, setFormData] = useState<UserRegister>({
    ...defaultData,
  });
  const [errors, setErrors] = useState({ ...defaultData });
  const [error, setError] = useState();
  const { accountStore } = useStores();

  const signInHandler = () => navigation.navigate("SignIn");
  const PrivacyPolicyHandler = () =>
    navigation.navigate("Page", { id: "privacy-policy" });
  const TermsOfUseHandler = () =>
    navigation.navigate("Page", { id: "terms-of-use" });

  const submitHandler = async () => {
    const newErrors = {
      ...errors,
      Email: emailValidator(formData.Email),
      Password: passwordValidator(formData.Password),
    };
    setErrors(newErrors);
    if (Object.values(newErrors).filter((n) => n).length) return;

    try {
      await accountStore.register(formData);
      navigation.navigate("RegistrationSuccess");
    } catch (err) {
      setError(err.message);
    }
  };

  const setFormDataHandler = (key, value) => {
    setErrors({ ...errors, [key]: undefined });
    setFormData({ ...formData, [key]: value });
  };

  const errorEmailText = errors.Email || error;

  return (
    <Screen testID="SignUp" style={styles.screen} keyboard>
      <LayoutCenter>
        <Logo style={{ marginTop: spacing[7] }} />

        <Form.Container>
          <Form.Inputs>
            <TextInput
              txLabel="fields.email"
              value={formData.Email}
              errorText={errorEmailText}
              onChangeText={(value) => setFormDataHandler("Email", value)}
              returnKeyType="next"
              autoCapitalize="none"
            />
            <PasswordInput
              value={formData.Password}
              txDescription="fields.descriptions.password"
              errorText={errors.Password}
              onChangeText={(value) => setFormDataHandler("Password", value)}
            />
          </Form.Inputs>
          <Button
            type="primary"
            onPress={submitHandler}
            tx="Auth.createAccount"
          >
            Create account
          </Button>
        </Form.Container>

        <View style={styles.userAgreement}>
          <Text style={styles.labelText}>
            {translate("Auth.byClickingCreateAccount")}
          </Text>
          <View style={styles.userAgreementLinks}>
            <Button
              type="link"
              onPress={TermsOfUseHandler}
              labelStyle={styles.labelText}
              tx="Auth.userAgreement"
            >
              User Agreement
            </Button>
            <Text style={styles.labelText}>{translate("Auth.and")}</Text>
            <Button
              type="link"
              onPress={PrivacyPolicyHandler}
              labelStyle={styles.labelText}
              tx="Auth.privacyPolicy"
            >
              Privacy Policy
            </Button>
          </View>
        </View>

        <View style={styles.userLogin}>
          <Text tx="Auth.alreadyHaveAnAccount" />
          <Button type="link" onPress={signInHandler} tx="Auth.logIn">
            Log In
          </Button>
        </View>
      </LayoutCenter>
    </Screen>
  );
}
