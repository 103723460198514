import { observer } from "mobx-react-lite";

import { ModuleSensor } from "@models/module/module";
import { SensorState } from "@models/sensor/sensor";

import { NewSensorDialog } from "@screens/Sensors/components";
import { useDialog } from "@components/ui";
import { useStores } from "@models/index";

import { NoneIcon } from "../../../../../svgs";
import * as SensorIcons from "../../../../../svgs/sensors";
import { useGoToSensorDetails } from "../../../../../hooks";
import { translate } from "../../../../../i18n";
import { ModuleConnectedItem } from "./ConnectedItem";
import { ModuleEmptyItem } from "./EmptyItem";
import { ModuleCardBadges } from "./ModuleCardBadges";

type SensorCardProps = {
  moduleSensor: ModuleSensor;
  // onSensorAdd?: (sensor: ModuleSensor) => void;
  showOfflinePopup?: () => void;
};

export const ModuleSensorCard = observer(
  ({ moduleSensor, showOfflinePopup }: SensorCardProps) => {
    const { sensorStore } = useStores();
    const sensor = sensorStore.getSensor(moduleSensor.uid) || moduleSensor;
    const goToSensor = useGoToSensorDetails(sensor, showOfflinePopup);

    const { showDialog: showAddSensorDialog, ...dialogProps } = useDialog();

    const Icon = SensorIcons[moduleSensor.iconName] || NoneIcon;

    const isNeedCalibration =
      sensor.needCalibration || sensor.needRecalibration;
    const isOffline = sensor.state === SensorState.Offline;
    const isDisconnect = !isOffline && sensor.state === SensorState.Disconnect;

    return moduleSensor.isConnected ? (
      <ModuleConnectedItem
        key={moduleSensor.uid}
        icon={Icon}
        typeName={moduleSensor.displayType}
        displayName={moduleSensor.displayName}
        socketName={moduleSensor.pinName}
        onPress={goToSensor}
      >
        <ModuleCardBadges>
          {isNeedCalibration && <ModuleCardBadges.BadgeCalibration />}
          {isOffline && <ModuleCardBadges.BadgeOffline />}
          {isDisconnect && <ModuleCardBadges.BadgeDisconnect />}
        </ModuleCardBadges>
      </ModuleConnectedItem>
    ) : (
      <>
        <NewSensorDialog {...dialogProps} />
        <ModuleEmptyItem
          title={translate("Sensors.Add.addSensor")}
          socketName={moduleSensor.pinName}
          onPress={showAddSensorDialog}
        />
      </>
    );
  },
);
