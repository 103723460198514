import React, { useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";

import { Text } from "@components/ui";

import { palette, spacing } from "../../theme";
import { TxKeyPath } from "../../i18n";
import { CustomSelectInput } from "./CustomSelectInput";

const styles = StyleSheet.create({
  dropdownContainer: {
    position: "absolute",
    width: "100%",
    backgroundColor: palette.White,
    borderRadius: spacing[4],
    top: 174,
    zIndex: 100,

    shadowColor: palette.Black,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,
    elevation: 5,
  },
  item: {
    height: 48,
    padding: spacing[3],
  },
  itemText: {
    fontSize: 18,
  },
});

export type DropDownOption = {
  key: string;
  value: string;
};

type DropDownPickerProps = {
  options: DropDownOption[];
  onChange: (item: DropDownOption) => void;
  txLabel?: TxKeyPath;
  selected?: string;
  error?: string;
};

export const DropDownPicker = ({
  selected,
  error,
  options,
  onChange,
  txLabel,
}: DropDownPickerProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const selectHandler = () => {
    setIsOpen((prevState) => !prevState);
  };

  const currentValue = options.find((item) => item.key === selected)?.value;

  return (
    <>
      <CustomSelectInput
        txLabel={txLabel}
        value={currentValue}
        errorText={error}
        onPress={selectHandler}
      />
      {isOpen ? (
        <View style={styles.dropdownContainer}>
          {options.map(({ key, value }) => (
            <TouchableOpacity
              key={key}
              style={styles.item}
              onPress={() => {
                onChange({ key, value });
                setIsOpen(false);
              }}
            >
              <Text style={styles.itemText}>{value}</Text>
            </TouchableOpacity>
          ))}
        </View>
      ) : null}
    </>
  );
};
