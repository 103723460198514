import { ApiResponse } from "apisauce";

import { ApiProblem, processResponse } from "./response-resolver";
import { Api } from "./api";

const GDP_GENERATE_TOKEN_PATH = "/GrowDirectorPro/generate_token";
const GDP_LIST_PATH = "/GrowDirectorPro";
const GDP_ADD_PATH = "/GrowDirectorPro/add";

type GrowDirectorPro = {
  uid: string;
};

export type GenerateTokenResult =
  | { kind: "ok"; data: { token: string } }
  | ApiProblem;

export type GrowDirectorListResult =
  | { kind: "ok"; data: GrowDirectorPro[] }
  | ApiProblem;

export type GrowDirectorAddResult =
  | { kind: "ok"; data: GrowDirectorPro[] }
  | ApiProblem;

export class GrowDirectorProApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async generateToken(): Promise<GenerateTokenResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        GDP_GENERATE_TOKEN_PATH,
      );
      return processResponse(response);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async getList(): Promise<GrowDirectorListResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        GDP_LIST_PATH,
      );
      return processResponse(response);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async getModule(uid: string): Promise<GrowDirectorListResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/GrowDirectorPro/${uid}/module`,
      );
      return processResponse(response);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async addModule(token: string, uid: string): Promise<GrowDirectorAddResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(
        GDP_ADD_PATH,
        { token, uid },
      );
      return processResponse(response);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }
}
