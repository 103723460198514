import React, { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { View, StyleSheet, Platform } from "react-native";
import { Appbar, shadow } from "react-native-paper";
import { useNavigation, useTheme } from "@react-navigation/native";
import { LinearGradient } from "expo-linear-gradient";

import { ContextMenu, MenuItem, Text } from "@components/ui";

import { spacing } from "../../theme";
import { useHardwareBackPress } from "../../hooks";

const createStyles = (theme: any) =>
  StyleSheet.create({
    header: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      paddingVertical: spacing[2],

      borderBottomWidth: 1,
      borderBottomColor: theme.colors.menuBorder,

      marginTop: 0,
      elevation: 0,
      backgroundColor: theme.colors.transparent,
      zIndex: 99,
    },
    headerWithoutBack: {
      paddingLeft: spacing[4],
    },
    shadow: {
      ...(Platform.OS === "ios" ? {} : (shadow(2) as any)),
      elevation: 0.5,
    },
    backBtn: {
      marginRight: spacing[2],
      zIndex: 5,
    },
    icon: {
      width: 40,
      height: 40,
      marginRight: spacing[3],
    },
    title: {
      fontSize: 20,
      lineHeight: 28,
      minHeight: 28,
      color: theme.colors.text,
    },
    subtitle: {
      fontSize: 14,
      lineHeight: 20,
      color: theme.colors.text,
    },
    gradientBG: {
      padding: spacing[2],
      borderRadius: 40,
    },
    content: {
      flex: 1,
      minHeight: 30,
    },

    headerSmall: {
      paddingTop: spacing[3],
      paddingHorizontal: spacing[4],
      backgroundColor: theme.colors.background,
      marginTop: -spacing[1],
    },
    titleSmall: {
      fontSize: 16,
      lineHeight: 22,
      height: "auto",
    },
    iconSmall: {
      width: 28,
      height: 28,
      marginRight: spacing[3],
    },
    gradientBGSmall: {
      padding: spacing[1],
      borderRadius: 32,
    },
  });

type Action = {
  icon: React.FunctionComponent<any>;
  color?: string;
  onPress?: () => void;
};

type TopBarNewProps = {
  menuItems?: MenuItem[];
  icon?: React.FunctionComponent<any>;
  title?: string;
  subtitle?: string;
  isShadow?: boolean;
  back?: boolean | undefined;
  backHandler?: () => void;
  action?: Action;
};
export const TopBarNew = observer(
  ({
    menuItems,
    icon: Icon,
    title,
    subtitle,
    isShadow = true,
    back = true,
    backHandler,
    action,
  }: TopBarNewProps) => {
    const theme = useTheme() as any;
    const styles = useMemo(() => createStyles(theme), [theme]);

    const navigation = useNavigation();
    useHardwareBackPress(backHandler);

    return (
      <Appbar.Header
        statusBarHeight={0}
        style={[
          styles.header,
          isShadow && styles.shadow,
          !back && styles.headerWithoutBack,
        ]}
      >
        {back && (
          <Appbar.BackAction
            color={theme.colors.heading}
            onPress={backHandler || navigation.goBack}
            style={styles.backBtn}
          />
        )}

        {Icon && (
          <View style={styles.icon}>
            <LinearGradient
              colors={[
                theme.colors.GradientIconStartBG,
                theme.colors.GradientIconEndBG,
              ]}
              start={{ x: 0, y: 1 }}
              end={{ x: 1, y: 1 }}
              style={styles.gradientBG}
            >
              <Icon color={theme.colors.background} size={24} />
            </LinearGradient>
          </View>
        )}

        <View style={styles.content}>
          <Text
            style={[styles.title, theme.fonts.medium]}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {title}
          </Text>
          {subtitle && (
            <Text style={styles.subtitle} numberOfLines={1}>
              {subtitle}
            </Text>
          )}
        </View>

        {menuItems?.length ? <ContextMenu menuItems={menuItems} /> : null}

        {action ? (
          <Appbar.Action
            icon={action.icon}
            color={action.color || theme.colors.text}
            onPress={action.onPress}
          />
        ) : null}
      </Appbar.Header>
    );
  },
);

type TopBarContentProps = {
  title?: string;
  subtitle?: string;
  icon?: React.FunctionComponent<any>;
};

export const TopBarContent = ({
  title,
  subtitle,
  icon: Icon,
}: TopBarContentProps) => {
  const theme = useTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <View style={[styles.header, styles.headerSmall]}>
      {Icon && (
        <View style={styles.iconSmall}>
          <LinearGradient
            colors={[
              theme.colors.GradientIconStartBG,
              theme.colors.GradientIconEndBG,
            ]}
            start={{ x: 0, y: 1 }}
            end={{ x: 1, y: 1 }}
            style={styles.gradientBGSmall}
          >
            <Icon color={theme.colors.background} size={20} />
          </LinearGradient>
        </View>
      )}

      <View style={styles.content}>
        <Text
          style={[styles.title, styles.titleSmall, theme.fonts.medium]}
          numberOfLines={1}
          ellipsizeMode="tail"
        >
          {title}
        </Text>
        {subtitle && <Text style={styles.subtitle}>{subtitle}</Text>}
      </View>
    </View>
  );
};
