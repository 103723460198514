import React from "react";
import { Circle, G, Line, Text as SvgText } from "react-native-svg";

import { SensorState } from "@models/sensor/sensor";

import { SENSOR_VALUE_DEFAULT_PRECISION } from "@models/sensor/constants";
import { translate } from "../../../i18n";
import { displayNumber } from "../../../utils/display";
import { palette } from "../../../theme";
import { numberOfIntegers } from "../../../utils/numbers";
import { apx } from "../utils";

const FONT_SIZE = 32;
const LENGTH_VALUE = 16;
const OFFSET_VALUE = 36;
const OFFSET_LEFT_VALUE = 10;

export const Tooltip = (props: any) => {
  const { x, y, data, positionX, height, offsetScaleY } = props;
  if (positionX < 0) {
    return null;
  }
  const isOffline = data[positionX]?.state === SensorState.Offline; // TODO: Remove !!!
  let value = isOffline
    ? translate("Sensors.State.offline")
    : displayNumber(data[positionX]?.value, SENSOR_VALUE_DEFAULT_PRECISION);

  const positionY = y(data[positionX]?.value);
  let textColor = isOffline ? palette.RedNew : palette.Black;

  if (data[positionX]?.state === SensorState.Calibration) {
    value = translate("Sensors.State.calibration");
    textColor = palette.PurpleG1;
  }

  const indentValue = numberOfIntegers(value) * LENGTH_VALUE + OFFSET_VALUE;

  return (
    <G key="tooltip">
      <G x={x(positionX)}>
        <G
          x={
            positionX > data.length / 2
              ? -apx(indentValue)
              : apx(OFFSET_LEFT_VALUE)
          }
          y={
            positionY > height / 2
              ? positionY - apx(LENGTH_VALUE)
              : positionY + apx(OFFSET_VALUE)
          }
        >
          <SvgText fill={textColor} fontSize={apx(FONT_SIZE)} fontWeight="bold">
            {value}
          </SvgText>
        </G>
        <G>
          <Line
            y1={offsetScaleY}
            y2={height - offsetScaleY}
            stroke={palette.GrayBlueLight}
            strokeWidth={apx(2)}
            strokeDasharray={[6, 3]}
          />
          <Circle
            cy={positionY}
            r={apx(8)}
            stroke={palette.White}
            strokeWidth={apx(2)}
            fill={palette.Black}
          />
        </G>
      </G>
    </G>
  );
};
