import { types, Instance } from "mobx-state-tree";
import * as semver from "semver";
import dayjs from "dayjs";

import { withEnvironment } from "../extensions/with-environment";
import {
  API_SERVER_URIS,
  APIServer,
  SERVER_URL_TO_API_URL,
} from "../../services/api/api-config";
import {
  forceRTL,
  i18n,
  isLanguageRTL,
  LANGUAGES,
  Language,
  TLanguage,
} from "../../i18n";
import { DateTimeFormats } from "../../utils/timeConverter";
import { withRootStore } from "../extensions/with-root-store";
import {
  TemperatureUnit,
  TimeSystem,
  Units,
  UserSettingsModel,
  VolumeUnit,
} from "./user-settings-store";
import { VersionsModel } from "./versions-store";

const DEVSettingsModel = types.model("DEVSettings").props({
  apiServer: types.maybeNull(
    types.enumeration<APIServer>("ApiServer", Object.values(APIServer)),
  ),
  apiUrl: types.maybeNull(types.string),
});

export const SettingsStoreModel = types
  .model("SettingsStore")
  .props({
    versions: types.optional(VersionsModel, {}),
    userSettings: types.optional(UserSettingsModel, {}),
    DEV: types.optional(DEVSettingsModel, {}),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .views((self) => ({
    get currentUserId() {
      return self.rootStore.accountStore.currentUser?.id;
    },
  }))
  .views((self) => ({
    get DEV_serverUrl(): string {
      if (self.DEV?.apiServer === APIServer.Custom && self.DEV?.apiUrl) {
        return self.DEV?.apiUrl;
      }
      return (
        API_SERVER_URIS[self.DEV?.apiServer] || API_SERVER_URIS[APIServer.Prod]
      );
    },

    get DEV_apiServer(): APIServer {
      return self.DEV?.apiServer || APIServer.Prod;
    },

    get latestFWVersion() {
      return semver.valid(
        (self.versions?.firmware || "").replace("VER", "").trim(),
      );
    },
  }))
  .views((self) => ({
    get wasUpdateAppHintShown(): boolean {
      if (!self.currentUserId) return true;
      return Boolean(
        self.userSettings?.updateVersionHint ===
          dayjs().format(DateTimeFormats.DateYMD),
      );
    },

    get wasReleaseNotesHintShown(): boolean {
      if (!self.currentUserId) return true;
      return Boolean(
        self.userSettings?.releaseNotesHint ===
          self.versions?.currentAppVersion,
      );
    },

    get wasModulesToUpdateShown(): boolean {
      if (!self.currentUserId) return true;

      return Boolean(
        self.userSettings?.lastDateShowModulesToUpdate ===
          dayjs().format(DateTimeFormats.DateYMD),
      );
    },
  }))
  .actions((self) => {
    const initUserSettings = () => {
      if (!self.currentUserId) {
        return null;
      }

      // const debouncedSaveUserSettings = debounce(self.userSettings.saveUserSettings, 500);
      // onSnapshot(self.userSettings, debouncedSaveUserSettings);

      self.userSettings.fetchUserSettings();
      self.userSettings.fetchSensorsSettings();
      self.userSettings.fetchDevicesSettings();
      self.userSettings.fetchNotificationsSettings();

      return self.userSettings;
    };

    const setLanguage = (language: Language) => {
      self.userSettings.language = language;
      const isPrevRTL = isLanguageRTL(i18n.locale as Language);
      const isRTL = isLanguageRTL(language);

      i18n.locale = language;
      self.userSettings.saveUserSettings();
      self.environment.api.setAcceptLanguage(language);
      forceRTL(isRTL, isPrevRTL !== isRTL);
    };

    const setVolume = (volumeUnit: VolumeUnit) => {
      self.userSettings.volumeUnit = volumeUnit;
      self.userSettings.saveUserSettings();
    };

    const setTemperature = (temperatureUnit: TemperatureUnit) => {
      self.userSettings.temperatureUnit = temperatureUnit;
      self.userSettings.saveUserSettings();
    };

    const setTimeFormat = (timeSystem: TimeSystem) => {
      self.userSettings.timeSystem = timeSystem;
      self.userSettings.saveUserSettings();
    };

    const setShowHelpButton = (value: boolean) => {
      self.userSettings.showHelpButton = value;
      self.userSettings.saveUserSettings();
    };

    const isOneTimeHintShown = (key: string) => {
      return self.userSettings?.oneTimeHints?.get(key);
    };

    const markOneTimeHintAsShown = (key: string) => {
      self.userSettings.oneTimeHints.set(key, { key });
      self.userSettings.saveUserSettings();
    };

    const markUpdateAppHintAsShown = () => {
      self.userSettings.updateVersionHint = dayjs().format(
        DateTimeFormats.DateYMD,
      );
      self.userSettings.saveUserSettings();
    };

    const markReleaseNotesHintAsShown = () => {
      self.userSettings.releaseNotesHint = self.versions.currentAppVersion;
      self.userSettings.saveUserSettings();
    };

    const markModulesToUpdateAsShown = () => {
      self.userSettings.lastDateShowModulesToUpdate = dayjs().format(
        DateTimeFormats.DateYMD,
      );
      self.userSettings.saveUserSettings();
    };

    return {
      initUserSettings,
      setLanguage,
      setVolume,
      setTemperature,
      setTimeFormat,

      setShowHelpButton,
      isOneTimeHintShown,
      markOneTimeHintAsShown,
      markUpdateAppHintAsShown,
      markReleaseNotesHintAsShown,
      markModulesToUpdateAsShown,
    };
  })
  .actions((self) => ({
    DEV_setApiServer: async (apiServer: APIServer, apiUrl?: string) => {
      const prevApiServer = self.DEV.apiServer;

      self.DEV.apiServer = apiServer;
      if (apiUrl) {
        self.DEV.apiUrl = apiUrl;
      }
      self.environment.api.setBaseURL(SERVER_URL_TO_API_URL(apiServer, apiUrl));

      if (self.currentUserId && prevApiServer !== apiServer) {
        await self.rootStore.accountStore.logout();
      }
    },
  }))
  .actions((self) => ({
    DEV_applySettings: async () => {
      return self.DEV_setApiServer(self.DEV_apiServer, self.DEV?.apiUrl);
    },
  }))
  .views((self) => ({
    get currentLanguage() {
      return self.userSettings?.language || Language.en;
    },
    get currentVolumeUnit() {
      return self.userSettings?.volumeUnit || VolumeUnit.liters;
    },
    get currentTemperatureUnit() {
      return self.userSettings?.temperatureUnit || TemperatureUnit.celsius;
    },
    get currentTimeSystem() {
      return self.userSettings?.timeSystem || TimeSystem.hours24;
    },
    get availableLanguages(): TLanguage[] {
      return LANGUAGES;
    },
    get availableVolumeUnits() {
      return Object.values(VolumeUnit);
    },
    get availableTemperatureUnits() {
      return Object.values(TemperatureUnit);
    },
    get availableTimeSystems() {
      return Object.values(TimeSystem);
    },
    get showHelpButton() {
      return self.userSettings?.showHelpButton;
    },
  }))
  .views((self) => ({
    get units(): Units {
      return {
        temperature: self.currentTemperatureUnit,
        volume: self.currentVolumeUnit,
      };
    },
    get currentLanguageProps(): TLanguage {
      return LANGUAGES.find((item) => item.key === self.currentLanguage);
    },
  }));

export type SettingsStore = Instance<typeof SettingsStoreModel>;
