import { useCallback } from "react";
import { useFocusEffect } from "@react-navigation/native";
import { useStores } from "@models/index";

const SENSORS_INTERVAL_MS = 10 * 1000;

export function useSensors(
  { refreshInterval = SENSORS_INTERVAL_MS } = {
    refreshInterval: SENSORS_INTERVAL_MS,
  },
) {
  const { sensorStore, accountStore } = useStores();

  const fetchViews = async () => {
    await sensorStore.fetchSensors();
    await sensorStore.getSensorsData();
  };
  const fetchData = async () => {
    await sensorStore.getSensorsData();
  };

  useFocusEffect(
    useCallback(() => {
      fetchViews();
      const dataInterval = setInterval(() => fetchData(), refreshInterval);
      return () => clearInterval(dataInterval);
    }, [accountStore.currentUser.id]),
  );

  // useEffect(() => {
  //   async function fetchViews() {
  //     await sensorStore.getSensorsViews();
  //     await sensorStore.getSensorsData();
  //   }
  //   async function fetchData() {
  //     await sensorStore.getSensorsData();
  //   }
  //
  //   fetchViews();
  //
  //   const timer = setInterval(() => {
  //     console.log("TIMEOUT")
  //     fetchData();
  //   }, refreshInterval);
  //
  //   return () => clearTimeout(timer);
  // }, []);

  return {
    isLoading: sensorStore.isLoading,
    isLoaded: sensorStore.isLoaded,
    sensors: sensorStore.filteredSensors,
    allSensors: sensorStore.sortedSensors,
  };
}
