import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { palette } from "../../theme";

export default function HelpIcon({ color = palette.Black, size = 24 }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M12 17V17.01"
        stroke={color}
        strokeWidth="2.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M12 13.5011C11.9816 13.1765 12.0692 12.8547 12.2495 12.5842C12.4299 12.3136 12.6933 12.109 13 12.0011C13.3759 11.8574 13.7133 11.6284 13.9856 11.3321C14.2579 11.0358 14.4577 10.6804 14.5693 10.2937C14.6809 9.9071 14.7013 9.49984 14.6287 9.10401C14.5562 8.70818 14.3928 8.33459 14.1513 8.01266C13.9099 7.69072 13.597 7.42923 13.2373 7.24876C12.8776 7.06829 12.4809 6.97378 12.0785 6.97267C11.6761 6.97155 11.2789 7.06386 10.9182 7.24233C10.5576 7.4208 10.2432 7.68055 10 8.00114"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
