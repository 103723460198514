import { useEffect } from "react";

import { Sensor, useStores } from "@models/index";
import { Device } from "@models/device/device";
import { Module } from "@models/module/module";
import { NotificationEntityType } from "@models/notifications/constants";

type useEquipmentTypeResult = {
  entityType: NotificationEntityType;
  entities: Device[] | Module[] | Sensor[] | [];
};

export const useNotificationTypeEntities = (
  entityType: NotificationEntityType,
): useEquipmentTypeResult => {
  const { deviceStore, moduleStore, sensorStore } = useStores();

  const fetchData = async () => {
    if (NotificationEntityType.Devices === entityType) {
      await deviceStore.fetchDevices();
      await deviceStore.fetchDevicesViews();
    }
    if (NotificationEntityType.Modules === entityType) {
      await moduleStore.fetchModules();
    }
    if (NotificationEntityType.Sensors === entityType) {
      await sensorStore.fetchSensors();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  let entities = [];

  if (NotificationEntityType.Devices === entityType) {
    entities = deviceStore.filteredAndSortedDevices || [];
  }
  if (NotificationEntityType.Modules === entityType) {
    entities = moduleStore.sortedModules || [];
  }
  if (NotificationEntityType.Sensors === entityType) {
    entities = sensorStore.filteredSensors || [];
  }

  return {
    entityType,
    entities,
  };
};
