import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

export const CalibrationPrepareECSvg = (props: SvgProps) => (
  <Svg width={287} height={173} fill="none" {...props}>
    <Path
      fill="#B6C5D3"
      d="M41.249 141.636 27.265 143.5l5.594-23.305 21.44-2.331 33.094 23.772H41.249Z"
    />
    <Path
      fill="#fff"
      d="M46.107 131.812c-11.106 3.306-18.852 11.385-18.852 11.385V50.872s8.814-8.936 20.844-11.295"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M46.107 131.812c-11.106 3.306-18.852 11.385-18.852 11.385V50.872s8.814-8.936 20.844-11.295"
    />
    <Path
      fill="#fff"
      d="M106.713 43.082v89.22c0 5.298-13.621 9.582-30.437 9.582-16.805 0-30.437-4.296-30.437-9.582v-89.22"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M106.713 43.082v89.22c0 5.298-13.621 9.582-30.437 9.582-16.804 0-30.437-4.296-30.437-9.582v-89.22"
    />
    <Path
      fill="#2B2E32"
      d="M85.347 137.254H82.9v-4.451h2.448v4.451Zm0-8.903H82.9V123.9h2.448v4.451Zm0-8.903H82.9v-4.451h2.448v4.451Zm0-8.903H82.9v-4.451h2.448v4.451Zm0-8.903H82.9v-4.451h2.448v4.451Zm0-8.903H82.9v-4.451h2.448v4.451Zm0-8.903H82.9v-4.451h2.448v4.451Zm0-8.903H82.9v-4.452h2.448v4.452Zm0-8.903H82.9v-4.452h2.448v4.452Zm0-8.903H82.9v-4.452h2.448v4.452Z"
    />
    <Path
      fill="#fff"
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M76.276 52.664c16.81 0 30.437-4.29 30.437-9.582 0-5.292-13.627-9.582-30.437-9.582-16.81 0-30.437 4.29-30.437 9.582 0 5.292 13.627 9.582 30.437 9.582Z"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M75.882 49.847c-13.428 0-24.314-3.024-24.314-6.754 0-1.535 1.845-2.951 4.953-4.086m19.361-2.667c13.428 0 24.314 3.023 24.314 6.753 0 1.813-2.57 3.459-6.754 4.672M46.107 131.812c-11.106 3.306-18.852 11.385-18.852 11.385V50.872s8.814-8.936 20.844-11.295"
    />
    <Path
      fill="#B6C5D3"
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M76.277 46.287c7.216 0 13.065-1.49 13.065-3.327 0-1.838-5.85-3.328-13.065-3.328-7.216 0-13.065 1.49-13.065 3.328 0 1.837 5.85 3.327 13.065 3.327Z"
    />
    <Path
      fill="#B6C5D3"
      d="M154.306 142.951c15.677 0 28.385-3.858 28.385-8.617s-12.708-8.616-28.385-8.616c-15.676 0-28.385 3.857-28.385 8.616 0 4.759 12.709 8.617 28.385 8.617Z"
    />
    <Path
      fill="#70B8FF"
      fillOpacity={0.4}
      d="M157.854 99.36c13.718 0 24.837-3.404 24.837-7.603 0-4.2-11.119-7.603-24.837-7.603-13.717 0-24.836 3.404-24.836 7.603s11.119 7.603 24.836 7.603Z"
    />
    <Path
      fill="#308AE3"
      fillRule="evenodd"
      d="M182.691 91.757c0 4.199-11.119 7.603-24.837 7.603-13.709 0-24.824-3.4-24.836-7.596V132.126c0 4.294 11.124 7.784 24.836 7.784 13.713 0 24.837-3.49 24.837-7.784v-41.89l-.49.01c.322.489.49.994.49 1.51Zm-49.673-.007c0-.16.017-.32.049-.478h-.049v.478Z"
      clipRule="evenodd"
      opacity={0.6}
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M182.691 69.961v62.17c0 4.292-11.148 7.779-24.891 7.779-13.743 0-24.891-3.487-24.891-7.779V81.228a6.762 6.762 0 0 0-2.071-4.873l-3.903-4.155"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M157.871 62.865c-10.594 0-17.558 2.383-22.495 4.585-1.691.75-5.334 2.577-7.921 3.883-.858.436-.596 1.682.361 1.766l4.986.46c1.754.266 3.457.738 5.086 1.415 4.514 1.875 11.789 3.097 19.983 3.097 13.716 0 24.82-3.411 24.82-7.609.013-4.198-11.104-7.597-24.82-7.597Z"
    />
    <Path
      fill="#fff"
      d="m158.024 105.151.093.094a49.973 49.973 0 0 1 2.38 2.818 38.702 38.702 0 0 1 2.287 3.196 29.78 29.78 0 0 1 1.822 3.256c.418.876.778 1.788 1.057 2.711.186.628.337 1.267.441 1.918a9.91 9.91 0 0 1 .116 2.202c-.058 1.089-.302 2.131-.778 3.102a7.009 7.009 0 0 1-2.356 2.699c-.767.533-1.602.9-2.496 1.148a9.98 9.98 0 0 1-1.289.261 8.606 8.606 0 0 1-1.741.071 9.269 9.269 0 0 1-3.506-.853 7.628 7.628 0 0 1-2.368-1.728c-.79-.864-1.324-1.882-1.626-3.031a9.153 9.153 0 0 1-.255-2.652c.035-.71.139-1.42.29-2.119.267-1.184.674-2.32 1.173-3.421a26.86 26.86 0 0 1 1.776-3.279 40.02 40.02 0 0 1 2.391-3.386 50.317 50.317 0 0 1 2.485-2.948c.046 0 .069-.024.104-.059Z"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M176.809 82.833v31.714M176.809 119.246v10.571M3.09 104.5h24M259.09 104.5h24M107.09 104.5h26M183.09 104.5h26"
    />
    <Path
      fill="#B6C5D3"
      d="M231.09 142.951c15.464 0 28-3.805 28-8.5 0-4.694-12.536-8.5-28-8.5s-28 3.806-28 8.5c0 4.695 12.536 8.5 28 8.5Z"
    />
    <Path
      fill="#70B8FF"
      fillOpacity={0.4}
      d="M234.59 99.951c13.531 0 24.5-3.358 24.5-7.5 0-4.142-10.969-7.5-24.5-7.5s-24.5 3.358-24.5 7.5c0 4.142 10.969 7.5 24.5 7.5Z"
    />
    <Path
      fill="#308AE3"
      fillRule="evenodd"
      d="M259.09 92.456c-.008 4.14-10.974 7.495-24.5 7.495-13.531 0-24.5-3.358-24.5-7.5 0-.16.016-.32.049-.478h-.049V132.274c0 4.236 10.973 7.678 24.5 7.678 13.526 0 24.5-3.442 24.5-7.678V92.456Zm0-.009v-1.496l-.484.01c.317.48.483.977.484 1.486Z"
      clipRule="evenodd"
      opacity={0.6}
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M259.09 70.951v61.327c0 4.233-10.998 7.673-24.554 7.673s-24.554-3.44-24.554-7.673V82.065a6.67 6.67 0 0 0-2.042-4.807l-3.85-4.099"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M234.606 63.951c-10.451 0-17.32 2.351-22.189 4.523-1.669.74-5.263 2.542-7.814 3.83-.846.43-.589 1.66.356 1.743l4.918.453c1.73.263 3.411.728 5.017 1.396 4.453 1.85 11.629 3.055 19.712 3.055 13.53 0 24.484-3.365 24.484-7.506.012-4.14-10.954-7.494-24.484-7.494ZM252.09 83.951v30.5M252.09 119.451v10"
    />
    <Path
      stroke="#fff"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M232.213 130.411a42.162 42.162 0 0 1-9.105-1.257v-15.816a42.099 42.099 0 0 0 9.105 1.256M245.089 128.754c-1.31.388-2.647.695-3.984.949-2.794.522-5.08-1.631-5.08-4.425v-5.682c0-2.794 2.286-5.161 5.08-5.696a40.952 40.952 0 0 0 3.984-.949"
    />
    <Path
      stroke="#fff"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M229.807 122.362a41.173 41.173 0 0 1-6.711-1.123"
    />
  </Svg>
);
