import Svg, { Path } from "react-native-svg";

export default function ORPIcon({ color = "#6F6F6F", size = 24 }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4892 5.71119C12.7159 5.36449 12.6186 4.8997 12.2719 4.67305C11.9252 4.4464 11.4604 4.54372 11.2337 4.89042L9.80097 7.08206H6.96378L5.87339 5.41389C5.64676 5.06718 5.18197 4.96983 4.83526 5.19646C4.48854 5.42309 4.39119 5.88788 4.61782 6.23459L5.71329 7.91052L4.15028 10.6179C4.01631 10.8499 4.01631 11.1358 4.15028 11.3678L5.74992 14.1386L4.35313 16.276C4.12654 16.6227 4.22393 17.0875 4.57067 17.3141C4.9174 17.5407 5.38218 17.4433 5.60878 17.0966L7.04187 14.9037H9.87057L11.234 17.2653L9.89503 19.3152C9.66851 19.662 9.76601 20.1267 10.1128 20.3532C10.4596 20.5798 10.9243 20.4823 11.1509 20.1355L12.504 18.0638H15.6204L17.6024 21.0964C17.829 21.4432 18.2938 21.5405 18.6405 21.3139C18.9872 21.0873 19.0846 20.6226 18.858 20.2758L16.7743 17.0875L18.0358 14.9024H20.5689C20.9832 14.9024 21.3189 14.5666 21.3189 14.1524C21.3189 13.7382 20.9832 13.4024 20.5689 13.4024H18.0351L16.711 11.1089L18.2299 8.78541C18.4566 8.43871 18.3593 7.97391 18.0126 7.74726C17.6659 7.52061 17.2011 7.61794 16.9744 7.96464L15.4855 10.2422H14.8728C14.4586 10.2422 14.1228 10.578 14.1228 10.9922C14.1228 11.4064 14.4586 11.7422 14.8728 11.7422H15.3446L16.7364 14.153L15.3446 16.5638H12.561L11.1692 14.153L12.7776 11.3672C12.9511 11.0665 12.8929 10.6957 12.6583 10.4616L11.1227 7.80162L12.4892 5.71119ZM7.05763 8.58206L5.66582 10.9929L7.05763 13.4037H9.84118L11.233 10.9929L9.84118 8.58206H7.05763Z"
        fill={color}
      />
    </Svg>
  );
}
