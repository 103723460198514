import React from "react";
import Svg, { Path } from "react-native-svg";
import { palette } from "../theme";

export default function LinkIcon({ color = palette.Blue, size = 16 }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 16 16" fill="none">
      <Path
        d="M6.6665 9.33293C6.88376 9.55467 7.14307 9.73082 7.42926 9.85108C7.71544 9.97133 8.02275 10.0333 8.33317 10.0333C8.6436 10.0333 8.9509 9.97133 9.23709 9.85108C9.52327 9.73082 9.78258 9.55467 9.99984 9.33293L12.6665 6.66627C13.1085 6.22424 13.3569 5.62472 13.3569 4.9996C13.3569 4.37448 13.1085 3.77496 12.6665 3.33293C12.2245 2.89091 11.625 2.64258 10.9998 2.64258C10.3747 2.64258 9.7752 2.89091 9.33317 3.33293L8.99984 3.66627"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M9.33342 6.66714C9.11617 6.4454 8.85686 6.26925 8.57067 6.149C8.28449 6.02874 7.97718 5.9668 7.66676 5.9668C7.35633 5.9668 7.04903 6.02874 6.76284 6.149C6.47665 6.26925 6.21734 6.4454 6.00009 6.66714L3.33342 9.3338C2.89139 9.77583 2.64307 10.3753 2.64307 11.0005C2.64307 11.6256 2.89139 12.2251 3.33342 12.6671C3.77545 13.1092 4.37497 13.3575 5.00009 13.3575C5.62521 13.3575 6.22473 13.1092 6.66676 12.6671L7.00009 12.3338"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
