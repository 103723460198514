import Svg, { Path } from "react-native-svg";

import { palette } from "../../theme";

export default function UnitsIcon({ color = palette.Black, size = 24 }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 4.75a.25.25 0 0 0-.25.25v2.25H6a.75.75 0 0 1 0 1.5H4.75v2.5H7a.75.75 0 0 1 0 1.5H4.75v2.5H6a.75.75 0 0 1 0 1.5H4.75V19a.25.25 0 0 0 .25.25h5a.25.25 0 0 0 .25-.25v-7A1.75 1.75 0 0 1 12 10.25h7a.25.25 0 0 0 .25-.25V5a.25.25 0 0 0-.25-.25h-2.25V6a.75.75 0 0 1-1.5 0V4.75h-2.5V7a.75.75 0 0 1-1.5 0V4.75h-2.5V6a.75.75 0 0 1-1.5 0V4.75H5Zm11-1.5h3A1.75 1.75 0 0 1 20.75 5v5A1.75 1.75 0 0 1 19 11.75h-7a.25.25 0 0 0-.25.25v7A1.75 1.75 0 0 1 10 20.75H5A1.75 1.75 0 0 1 3.25 19V5A1.75 1.75 0 0 1 5 3.25h11Z"
        fill={color}
      />
    </Svg>
  );
}
