import * as React from "react";
import Svg, { Path } from "react-native-svg";

const GrowthStagesIcon = ({ size = 52 }) => (
  <Svg width={size} height={size} viewBox="0 0 52 52" fill="none">
    <Path
      d="M38.9998 10.8334H12.9998C10.6066 10.8334 8.6665 12.7735 8.6665 15.1667V41.1667C8.6665 43.5599 10.6066 45.5 12.9998 45.5H38.9998C41.3931 45.5 43.3332 43.5599 43.3332 41.1667V15.1667C43.3332 12.7735 41.3931 10.8334 38.9998 10.8334Z"
      stroke="#2B2E32"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M34.6665 6.5V15.1667"
      stroke="#2B2E32"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M17.3335 6.5V15.1667"
      stroke="#2B2E32"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M8.6665 20H43.3332"
      stroke="#2B2E32"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M38 26H24V30H38V26Z"
      stroke="#2B2E32"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M18.46 26H14.04V30.42H18.46V26Z"
      stroke="#2B2E32"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M38.2198 35.88H33.7998V40.3H38.2198V35.88Z"
      stroke="#2B2E32"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M28 36H14V40H28V36Z"
      stroke="#2B2E32"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default GrowthStagesIcon;
