import * as React from "react";
import { View } from "react-native";
import Svg, { G, Rect, Circle, Path, SvgProps } from "react-native-svg";
import { spacing } from "../../../../theme";

export const CalibrationSoilMoistureWaterSvg = (props: SvgProps) => (
  <View style={{ marginTop: -spacing[8] }}>
    <Svg width={360} height={312} viewBox="0 0 360 312" fill="none" {...props}>
      <G clipPath="url(#clip0_306_691)">
        <Path
          d="M91.9519 215.169V245.669"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M101.452 215.169V245.669"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M97.2197 215.169V245.669"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M110.952 215.169V245.669"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M101.779 168.592V166.542C101.779 151.977 89.971 140.169 75.4064 140.169C60.8417 140.169 49.0333 151.977 49.0333 166.542V214.576C49.0333 229.141 37.2249 240.949 22.6603 240.949H20.191C5.62631 240.949 -6.18206 229.141 -6.18206 214.576V207.844C-6.18206 193.279 -17.9904 181.471 -32.5551 181.471H-55"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Rect
          width={28}
          height={35}
          rx={3}
          transform="matrix(-1 0 0 1 115.452 180.169)"
          fill="#6A7B8C"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M105.952 172.169C105.952 170.512 104.609 169.169 102.952 169.169H99.9519C98.2951 169.169 96.9519 170.512 96.9519 172.169V180.169H105.952V172.169Z"
          fill="#6A7B8C"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M110.952 185.046V199.714"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M110.952 204.215V209.216"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Circle
          cx={103}
          cy={297}
          r={11}
          fill="#B6C5D3"
          stroke="white"
          strokeWidth={2}
        />
        <Path
          d="M104.374 303H102.694V295.416C102.694 295.149 102.694 294.904 102.694 294.68C102.705 294.445 102.715 294.221 102.726 294.008C102.737 293.784 102.747 293.571 102.758 293.368C102.619 293.507 102.475 293.64 102.326 293.768C102.177 293.896 102.006 294.045 101.814 294.216L100.566 295.224L99.7019 294.136L102.966 291.576H104.374V303Z"
          fill="white"
        />
        <Path
          d="M100 276C115.464 276 128 272.194 128 267.5C128 262.805 115.464 259 100 259C84.536 259 72 262.805 72 267.5C72 272.194 84.536 276 100 276Z"
          fill="#B6C5D3"
        />
        <Path
          d="M103.5 250C117.031 250 128 246.642 128 242.5C128 238.358 117.031 235 103.5 235C89.969 235 79 238.358 79 242.5C79 246.642 89.969 250 103.5 250Z"
          fill="#70B8FF"
          fillOpacity={0.4}
        />
        <Path
          opacity={0.6}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M128 242.504C127.993 246.644 117.026 250 103.5 250C89.969 250 79 246.642 79 242.5C79 242.339 79.0165 242.18 79.0491 242.022L79 242.023V242.5V259.177V265.5C79 269.736 89.9734 273.178 103.5 273.178C117.027 273.178 128 269.736 128 265.5V256.491V242.504ZM128 242.496V241L127.517 241.01C127.833 241.49 127.999 241.987 128 242.496Z"
          fill="#308AE3"
        />
        <Path
          d="M128 204V256.323V265.326C128 269.559 117.002 273 103.446 273C89.89 273 78.8924 269.559 78.8924 265.326V259.007V215.113C78.8924 213.296 78.1543 211.563 76.8504 210.307L73 206.208"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M87.3676 199.246C85.0522 199.951 83.0625 200.748 81.3268 201.522C79.6586 202.262 76.0645 204.064 73.5132 205.353C72.6668 205.783 72.9244 207.012 73.8689 207.095L78.7877 207.549C80.5172 207.811 82.1977 208.277 83.8046 208.945C88.2572 210.794 95.433 212 103.516 212C117.046 212 128 208.635 128 204.494C128.008 201.68 122.947 199.229 115.456 197.948"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M279.563 191.215H231.67C230.142 191.215 228.91 189.984 228.91 188.455V87.56C228.91 86.0311 230.142 84.7994 231.67 84.7994H279.563C281.092 84.7994 282.323 86.0311 282.323 87.56V188.455C282.323 189.97 281.092 191.215 279.563 191.215Z"
          fill="#DBE2EA"
        />
        <Path
          d="M287.139 99.3947V87.4323C287.139 83.3268 283.812 80 279.707 80H231.432C227.327 80 224 83.3268 224 87.4323V188.568C224 192.673 227.327 196 231.432 196H279.707C283.812 196 287.139 192.673 287.139 188.568V118.336"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M257.904 101.448L233.71 126.364"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M241.668 102.043L234.632 109.404"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M276.507 186.02H234.646C234.32 186.02 234.051 185.751 234.051 185.425V178.12C234.051 177.795 234.32 177.526 234.646 177.526H276.507C276.833 177.526 277.102 177.795 277.102 178.12V185.425C277.102 185.751 276.833 186.02 276.507 186.02Z"
          fill="#3F9A6E"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Rect
          x={247}
          y={85}
          width={18}
          height={4}
          rx={2}
          fill="#2B2E32"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Circle
          cx={255}
          cy={298}
          r={11}
          fill="#B6C5D3"
          stroke="white"
          strokeWidth={2}
        />
        <Path
          d="M258.838 304H251.158V302.688L254.102 299.696C254.678 299.109 255.158 298.603 255.542 298.176C255.926 297.739 256.214 297.323 256.406 296.928C256.598 296.523 256.694 296.085 256.694 295.616C256.694 295.029 256.523 294.592 256.182 294.304C255.841 294.005 255.398 293.856 254.854 293.856C254.331 293.856 253.862 293.957 253.446 294.16C253.03 294.352 252.598 294.624 252.15 294.976L251.238 293.84C251.547 293.573 251.878 293.333 252.23 293.12C252.593 292.907 252.993 292.736 253.43 292.608C253.878 292.48 254.369 292.416 254.902 292.416C255.627 292.416 256.251 292.544 256.774 292.8C257.297 293.045 257.697 293.397 257.974 293.856C258.262 294.315 258.406 294.853 258.406 295.472C258.406 295.931 258.337 296.363 258.198 296.768C258.059 297.163 257.857 297.552 257.59 297.936C257.334 298.32 257.019 298.709 256.646 299.104C256.273 299.499 255.862 299.915 255.414 300.352L253.286 302.416V302.496H258.838V304Z"
          fill="white"
        />
        <Path
          d="M273.837 229.087L274.449 226.801C275.258 223.784 278.354 221.996 281.371 222.805C284.388 223.613 286.175 226.709 285.367 229.726L280.91 246.359L279.342 252.21C278.896 253.874 278.089 255.427 276.981 256.747L265.683 270.177L264.001 275.5M226.788 273.638L230.326 260.435L225.159 243.434C224.521 241.327 224.484 239.084 225.053 236.96L227.655 227.247C228.663 223.486 231.466 220.467 235.14 219.175L242.029 216.758"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M238.323 230.591L250.661 184.547C251.469 181.53 254.565 179.743 257.582 180.551C260.599 181.359 262.387 184.455 261.578 187.472L252.369 221.841"
          fill="white"
        />
        <Path
          d="M238.323 230.591L250.661 184.547C251.469 181.53 254.565 179.743 257.582 180.551C260.599 181.359 262.387 184.455 261.578 187.472L252.369 221.841"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M252.37 221.834L256.147 207.738C256.956 204.721 260.052 202.933 263.069 203.742C266.086 204.55 267.873 207.646 267.065 210.663L262.608 227.296"
          fill="white"
        />
        <Path
          d="M252.37 221.834L256.147 207.738C256.956 204.721 260.052 202.933 263.069 203.742C266.086 204.55 267.873 207.646 267.065 210.663L262.608 227.296"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M262.612 227.306L265.947 214.859C266.755 211.843 269.852 210.055 272.868 210.863C275.885 211.672 277.673 214.768 276.865 217.785L272.816 232.896"
          fill="white"
        />
        <Path
          d="M262.612 227.306L265.947 214.859C266.755 211.843 269.852 210.055 272.868 210.863C275.885 211.672 277.673 214.768 276.865 217.785L272.816 232.896"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M102.464 241C102.5 241.036 102.524 241.06 102.56 241.096C103.407 242.015 104.231 242.958 105.007 243.937C105.843 244.975 106.631 246.038 107.359 247.16C108.051 248.223 108.684 249.309 109.233 250.443C109.663 251.327 110.033 252.246 110.32 253.177C110.511 253.81 110.666 254.454 110.773 255.111C110.893 255.839 110.94 256.579 110.893 257.331C110.833 258.43 110.582 259.48 110.093 260.459C109.52 261.581 108.708 262.489 107.669 263.181C106.881 263.718 106.022 264.088 105.103 264.339C104.661 264.459 104.219 264.542 103.777 264.602C103.18 264.685 102.584 264.709 101.987 264.673C100.733 264.614 99.5274 264.339 98.3813 263.814C97.4621 263.384 96.6383 262.811 95.9459 262.071C95.1341 261.199 94.585 260.173 94.2746 259.015C94.1671 258.597 94.0955 258.167 94.0478 257.737C94 257.272 93.9881 256.806 94.0119 256.341C94.0478 255.624 94.1552 254.908 94.3104 254.204C94.585 253.01 95.0028 251.864 95.5162 250.754C96.0414 249.607 96.6622 248.509 97.3427 247.447C98.0948 246.265 98.9305 245.131 99.802 244.032C100.614 243.006 101.473 242.015 102.357 241.06C102.404 241.06 102.428 241.036 102.464 241Z"
          fill="white"
        />
        <Path
          d="M121 217V247.5"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M121 252.5V262.5"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
      </G>
    </Svg>
  </View>
);
