import { StepPrepareInfo } from "../../components";

import { translate } from "../../../../../i18n";
import { CALIBRATION_STEPS_PROPS } from "../../constants";
import { CalibrationStep } from "../../types";
import { CalibrationContentProps } from "./types";

export const CalibrationStepPrepareInfo = ({
  calibration,
}: CalibrationContentProps) => {
  const sensorTypeProps =
    CALIBRATION_STEPS_PROPS[calibration.sensor?.type]?.[
      CalibrationStep.PrepareInfo
    ] || {};

  return (
    <StepPrepareInfo
      submitLabel={translate("Sensors.Calibration.goToCalibration")}
      onSubmit={calibration.nextStep}
      {...sensorTypeProps}
    />
  );
};
