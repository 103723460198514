import * as React from "react";
import Svg, { Path } from "react-native-svg";

export default function FaceIdIcon({ color = "#000000", size = 24 }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        fill={color}
        fillRule="evenodd"
        d="M8.1 2.25h.233a.75.75 0 0 1 0 1.5h-.2c-.852 0-1.447 0-1.91.038-.453.037-.714.107-.911.207a2.25 2.25 0 0 0-.984.984c-.1.197-.17.458-.207.912-.037.462-.038 1.057-.038 1.909V8a.75.75 0 0 1-1.5 0v-.232c0-.813 0-1.469.043-2 .045-.546.14-1.026.366-1.47a3.75 3.75 0 0 1 1.639-1.64c.444-.226.924-.32 1.47-.365.531-.043 1.187-.043 2-.043zm10.342 1.538c-.462-.037-1.057-.038-1.909-.038h-.2a.75.75 0 0 1 0-1.5h.232c.813 0 1.469 0 2 .043.546.045 1.026.14 1.47.366a3.75 3.75 0 0 1 1.64 1.639c.226.444.32.924.365 1.47.043.531.043 1.187.043 2V8a.75.75 0 0 1-1.5 0v-.2c0-.852 0-1.447-.038-1.91-.037-.453-.107-.714-.207-.911a2.25 2.25 0 0 0-.983-.984c-.198-.1-.459-.17-.913-.207zM7.833 7.25a.75.75 0 0 1 .75.75v1.5a.75.75 0 0 1-1.5 0V8a.75.75 0 0 1 .75-.75zm5 0a.75.75 0 0 1 .75.75v3.1c0 1.214-1.036 2.25-2.25 2.25a.75.75 0 0 1 0-1.5c.386 0 .75-.364.75-.75V8a.75.75 0 0 1 .75-.75zm4 0a.75.75 0 0 1 .75.75v1.5a.75.75 0 0 1-1.5 0V8a.75.75 0 0 1 .75-.75zm-8.33 7.42a.75.75 0 0 1 1.06 0 3.837 3.837 0 0 0 5.44 0 .75.75 0 1 1 1.06 1.06 5.337 5.337 0 0 1-7.56 0 .75.75 0 0 1 0-1.06zm-5.17.58a.75.75 0 0 1 .75.75v.2c0 .853 0 1.447.038 1.91.037.453.107.714.207.911.216.424.56.768.984.984.197.1.458.17.912.207.462.037 1.057.038 1.909.038h.2a.75.75 0 0 1 0 1.5h-.232c-.813 0-1.469 0-2-.043-.546-.045-1.026-.14-1.47-.366a3.75 3.75 0 0 1-1.64-1.639c-.226-.444-.32-.924-.365-1.47-.043-.531-.043-1.187-.043-2V16a.75.75 0 0 1 .75-.75zm18 0a.75.75 0 0 1 .75.75v.232c0 .813 0 1.469-.043 2-.045.546-.14 1.026-.366 1.47a3.75 3.75 0 0 1-1.639 1.64c-.444.226-.924.32-1.47.365-.531.043-1.187.043-2 .043h-.232a.75.75 0 0 1 0-1.5h.2c.852 0 1.447 0 1.91-.038.453-.037.714-.107.911-.207a2.25 2.25 0 0 0 .984-.983c.1-.198.17-.459.207-.913.037-.462.038-1.057.038-1.909V16a.75.75 0 0 1 .75-.75z"
        clipRule="evenodd"
      />
    </Svg>
  );
}
