import * as React from "react";
import Svg, { SvgProps, Path, Circle } from "react-native-svg";

export const CalibrationCleanORPSvg = (props: SvgProps) => (
  <Svg width={287} height={172} fill="none" {...props}>
    <Path
      fill="#EDF1F5"
      d="m203.819 135.494 30.217 7.379c2.541.621 4.737-1.024 5.345-3.568l-.058-.014c.608-2.544-.546-5.006-3.086-5.627l-76.537-18.69 10.183-22.006a14.516 14.516 0 0 0 1.005-9.19l-1.555-7.142a14.506 14.506 0 0 1 .77-8.645l9.382-22.673 73.575 17.967a4.761 4.761 0 0 1 3.499 5.728 4.705 4.705 0 0 1-5.699 3.481l-30.217-7.379-1.552 6.492a17.263 17.263 0 0 1-7.788 10.724l-4.048 2.473a17.261 17.261 0 0 0-7.789 10.725 17.261 17.261 0 0 1-7.788 10.724l-2.515 1.536a17.285 17.285 0 0 0-7.692 10.342l-2.942 11.178 55.558 13.568a4.347 4.347 0 0 0 5.256-3.196l17.218-70.676"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.261}
      d="m203.819 135.494 30.217 7.379c2.541.621 4.737-1.024 5.345-3.568l-.058-.014c.608-2.544-.546-5.006-3.086-5.627l-76.537-18.69 10.183-22.006a14.516 14.516 0 0 0 1.005-9.19l-1.555-7.142a14.506 14.506 0 0 1 .77-8.645l9.382-22.673 73.575 17.967a4.761 4.761 0 0 1 3.499 5.728 4.705 4.705 0 0 1-5.699 3.481l-30.217-7.379-1.552 6.492a17.263 17.263 0 0 1-7.788 10.724l-4.048 2.473a17.261 17.261 0 0 0-7.789 10.725 17.261 17.261 0 0 1-7.788 10.724l-2.515 1.536a17.285 17.285 0 0 0-7.692 10.342l-2.942 11.178 55.558 13.568a4.347 4.347 0 0 0 5.256-3.196l17.218-70.676"
    />
    <Path
      fill="#B6C5D3"
      d="M71.475 142.451c15.677 0 28.385-3.858 28.385-8.617s-12.709-8.616-28.385-8.616c-15.677 0-28.385 3.857-28.385 8.616 0 4.759 12.708 8.617 28.385 8.617Z"
    />
    <Path
      stroke="#2A2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.261}
      d="M75.32 16.27v-.985c0-6.988-5.696-12.654-12.72-12.654-7.029 0-12.724 5.666-12.724 12.654v23.049c0 6.988-5.696 12.654-12.721 12.654h-1.191c-7.026 0-12.721-5.666-12.721-12.654"
    />
    <Path
      fill="#6A7B8C"
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.261}
      d="M78.84 34.965 76.443 16.75h-2.248L71.8 34.965l3.52-.246 3.518.246ZM81.884 34.965h-13.07v13.07h13.07v-13.07ZM81.884 103.316h-13.07v2.629h13.07v-2.629Z"
    />
    <Path
      stroke="#2A2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.261}
      d="M79.284 128.143H71.41a2.592 2.592 0 0 1-2.594-2.594v-12.355h13.069v12.355a2.6 2.6 0 0 1-2.6 2.594Z"
    />
    <Path
      fill="#6A7B8C"
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.261}
      d="M79.08 113.194h-7.46v11.869h7.46v-11.869ZM83.406 48.029h-16.12v55.281h16.12V48.029Z"
    />
    <Path
      stroke="#2A2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.261}
      d="M83.406 105.939h-16.12v7.255h16.12v-7.255ZM68.903 39.19h13.164M68.903 43.494h13.164"
    />
    <Path
      fill="#70B8FF"
      fillOpacity={0.4}
      d="M75.023 98.86c13.717 0 24.837-3.404 24.837-7.603 0-4.2-11.12-7.603-24.837-7.603s-24.837 3.404-24.837 7.603 11.12 7.603 24.837 7.603Z"
    />
    <Path
      fill="#308AE3"
      fillRule="evenodd"
      d="M99.86 91.257c0 4.2-11.12 7.603-24.837 7.603-13.71 0-24.825-3.4-24.837-7.596V131.626c0 4.294 11.124 7.784 24.837 7.784s24.837-3.49 24.837-7.784v-41.89l-.49.01c.321.489.49.994.49 1.511Zm-49.674-.007c0-.16.017-.32.05-.478h-.05v.478Z"
      clipRule="evenodd"
      opacity={0.6}
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M99.86 69.461v62.17c0 4.291-11.149 7.779-24.892 7.779-13.742 0-24.891-3.488-24.891-7.779V80.728a6.762 6.762 0 0 0-2.07-4.873L44.103 71.7"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M83.446 62.811c9.578 1.053 16.424 3.856 16.414 7.151 0 4.198-11.105 7.61-24.82 7.61-8.195 0-15.47-1.223-19.983-3.098a21.712 21.712 0 0 0-5.086-1.415l-4.987-.46c-.957-.084-1.218-1.33-.36-1.766 2.586-1.306 6.23-3.133 7.92-3.883 3.55-1.583 8.148-3.26 14.39-4.078"
    />
    <Path
      fill="#fff"
      d="m75.193 104.651.093.094a49.677 49.677 0 0 1 2.38 2.818 38.797 38.797 0 0 1 2.286 3.196 29.736 29.736 0 0 1 1.823 3.256c.418.876.778 1.788 1.056 2.711.186.628.337 1.267.441 1.918.116.722.163 1.456.116 2.202-.058 1.089-.301 2.131-.777 3.102a7.012 7.012 0 0 1-2.357 2.699c-.766.533-1.602.9-2.496 1.148-.43.119-.859.201-1.288.261-.58.083-1.161.106-1.742.071a9.27 9.27 0 0 1-3.506-.853 7.633 7.633 0 0 1-2.368-1.728c-.79-.864-1.323-1.882-1.625-3.031a9.297 9.297 0 0 1-.22-1.267 9.205 9.205 0 0 1-.035-1.385c.034-.71.139-1.42.29-2.119a17.74 17.74 0 0 1 1.172-3.421 26.875 26.875 0 0 1 1.776-3.279 40.024 40.024 0 0 1 2.392-3.386 50.075 50.075 0 0 1 2.484-2.948c.047 0 .07-.024.105-.059Z"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M93.978 82.333v31.714M93.978 118.746v10.571"
    />
    <Circle
      cx={76.09}
      cy={160}
      r={11}
      fill="#B6C5D3"
      stroke="#fff"
      strokeWidth={2}
    />
    <Path
      fill="#fff"
      d="M77.464 166h-1.68v-8.32a159.294 159.294 0 0 1 .064-1.312 7.501 7.501 0 0 1-.432.4c-.15.128-.32.277-.512.448l-1.248 1.008-.864-1.088 3.264-2.56h1.408V166Z"
    />
    <Circle
      cx={210.09}
      cy={160}
      r={11}
      fill="#B6C5D3"
      stroke="#fff"
      strokeWidth={2}
    />
    <Path
      fill="#fff"
      d="M213.928 166h-7.68v-1.312l2.944-2.992a49.412 49.412 0 0 0 1.44-1.52c.384-.437.672-.853.864-1.248.192-.405.288-.843.288-1.312 0-.587-.171-1.024-.512-1.312-.342-.299-.784-.448-1.328-.448-.523 0-.992.101-1.408.304a6.609 6.609 0 0 0-1.296.816l-.912-1.136c.309-.267.64-.507.992-.72a5.271 5.271 0 0 1 1.2-.512 5.34 5.34 0 0 1 1.472-.192c.725 0 1.349.128 1.872.384.522.245.922.597 1.2 1.056.288.459.432.997.432 1.616 0 .459-.07.891-.208 1.296a4.928 4.928 0 0 1-.608 1.168 8.942 8.942 0 0 1-.944 1.168 44.22 44.22 0 0 1-1.232 1.248l-2.128 2.064v.08h5.552V166Z"
    />
    <Path
      stroke="#2A2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.261}
      d="M222.624 17.444v-.984c0-6.988-5.696-12.654-12.721-12.654-7.028 0-12.724 5.666-12.724 12.654V39.51c0 6.988-5.695 12.654-12.72 12.654h-1.191c-7.026 0-12.721-5.666-12.721-12.654"
    />
    <Path
      fill="#6A7B8C"
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.261}
      d="m226.143 36.14-2.395-18.216H221.5l-2.395 18.216 3.519-.246 3.519.246ZM229.188 36.14h-13.069v13.07h13.069V36.14ZM229.188 104.49h-13.069v2.63h13.069v-2.63Z"
    />
    <Path
      stroke="#2A2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.261}
      d="M226.588 129.318h-7.875a2.592 2.592 0 0 1-2.594-2.594v-12.355h13.069v12.355a2.6 2.6 0 0 1-2.6 2.594Z"
    />
    <Path
      fill="#6A7B8C"
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.261}
      d="M226.383 114.369h-7.46v11.869h7.46v-11.869ZM230.71 49.203h-16.12v55.281h16.12v-55.28Z"
    />
    <Path
      stroke="#2A2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.261}
      d="M230.71 107.114h-16.12v7.254h16.12v-7.254ZM216.207 40.366h13.164M216.207 44.67h13.164"
    />
    <Path
      fill="#EDF1F5"
      d="M252.92 63.046a4.813 4.813 0 0 1 3.515 5.754 4.657 4.657 0 0 1-5.676 3.467l-30.195-7.513-1.523 6.5a17.117 17.117 0 0 1-7.734 10.719l-4.032 2.463a17.12 17.12 0 0 0-7.733 10.719 17.12 17.12 0 0 1-7.733 10.719l-2.505 1.53a17.136 17.136 0 0 0-7.639 10.335l-2.894 11.192 55.518 13.816a4.302 4.302 0 0 0 5.234-3.184l16.914-70.771"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.261}
      d="M252.92 63.046a4.813 4.813 0 0 1 3.515 5.754 4.657 4.657 0 0 1-5.676 3.467l-30.195-7.513-1.523 6.5a17.117 17.117 0 0 1-7.734 10.719l-4.032 2.463a17.12 17.12 0 0 0-7.733 10.719 17.12 17.12 0 0 1-7.733 10.719l-2.505 1.53a17.136 17.136 0 0 0-7.639 10.335l-2.894 11.192 55.518 13.816a4.302 4.302 0 0 0 5.234-3.184l16.914-70.771"
    />
    <Path
      stroke="#2B2E32"
      strokeDasharray="4 4"
      d="M64.523 52.429c-4.542 1.18-7.5 3.105-7.5 5.28 0 3.59 8.059 6.5 18 6.5s18-2.91 18-6.5c0-2.175-2.958-4.1-7.5-5.28"
    />
  </Svg>
);
