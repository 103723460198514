export enum SensorTypeName {
  UNKNOWN = "UNKNOWN",
  None = "none",
  Analog = "analog",
  Humidity = "humidity",
  AirTemp = "air_temp",
  WaterTemp = "water_temp",
  PH = "ph",
  EC = "ec",
  CO2 = "co2",
  VPD = "vpd",
  DO = "do",
  ORP = "orp",
  SoilMoisture = "soil_moisture",
  SoilEC = "soil_ec",
  SoilPH = "soil_ph",
  LUX = "lux",
  PAR = "par",
}

export const SENSOR_ICONS: Record<string, string> = {
  analog: "",
  [SensorTypeName.Humidity]: "AirHumidityIcon",
  [SensorTypeName.AirTemp]: "AirTemperatureIcon",
  [SensorTypeName.WaterTemp]: "WaterTemperatureIcon",
  [SensorTypeName.PH]: "PHHydroIcon",
  [SensorTypeName.EC]: "ECHydroIcon",
  [SensorTypeName.CO2]: "CO2LevelIcon",
  [SensorTypeName.VPD]: "VPDIcon",
  [SensorTypeName.DO]: "DOIcon",
  [SensorTypeName.ORP]: "ORPIcon",
  [SensorTypeName.SoilMoisture]: "SoilMoistureIcon",
  [SensorTypeName.SoilEC]: "SoilECIcon",
  [SensorTypeName.SoilPH]: "SoilPHIcon",
  [SensorTypeName.LUX]: "LuxIcon",
  [SensorTypeName.PAR]: "ParIcon",
};

export const DEFAULT_SENSOR_RANGES = { minValue: 1, maxValue: 100, step: 1 };

// export const SENSOR_VALUE_LIMITS = {
//   [SensorTypeName.AirTemp]: { minValue: 5, maxValue: 50 },
//   [SensorTypeName.WaterTemp]: { minValue: 5, maxValue: 40 },
//   [SensorTypeName.Humidity]: { minValue: 10, maxValue: 90 },
//   [SensorTypeName.SoilMoisture]: { minValue: 10, maxValue: 100 },
//   [SensorTypeName.PH]: { minValue: 4, maxValue: 8 },
//   [SensorTypeName.EC]: { minValue: 0.1, maxValue: 4 },
// };

export const SENSOR_VALUE_DEFAULT_PRECISION = 2;
