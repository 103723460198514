import { StyleSheet, View } from "react-native";
import { shadow } from "react-native-paper";

import { MenuItem, Text } from "@components/ui";

import { defaultColors, spacing } from "../../theme";
import { DotsVerticalIcon } from "../../svgs";

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    top: spacing[4],
    right: spacing[4],
    flexDirection: "row",
  },
  dots: {
    borderRadius: spacing[2],
    height: 40,
    marginLeft: spacing[2],
    alignItems: "center",
    backgroundColor: defaultColors.background,
    padding: spacing[2],
    ...(shadow(5) as any),
  },
  menu: {
    borderRadius: spacing[2],
    backgroundColor: defaultColors.text,
    padding: spacing[2],
  },
  item: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: spacing[2],
    paddingVertical: spacing[3],
  },
  title: {
    fontSize: 16,
    marginLeft: spacing[6],
    color: defaultColors.background,
  },
  topLine: {
    borderTopColor: defaultColors.subtitle,
    borderTopWidth: 1,
  },
});

export const HintMenu = (menuItems: MenuItem[], style = null) => (
  <View style={[styles.container, style && style]}>
    <View style={styles.menu}>
      {menuItems.map(({ key, title, icon: Icon, color, topLine }) => (
        <View
          style={[styles.item, topLine && styles.topLine]}
          key={`menu-item-${key}`}
        >
          <Icon color={color || defaultColors.background} />
          <Text style={[styles.title, color && { color }]}>{title}</Text>
        </View>
      ))}
    </View>

    <View style={styles.dots}>
      <DotsVerticalIcon color={defaultColors.text} />
    </View>
  </View>
);
