import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { palette } from "../../theme";

export default function DeleteIcon({ color = palette.Red, size = 24 }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3498 5.25156V4.3556C10.365 4.35308 10.3819 4.35156 10.3998 4.35156H13.5998C13.6177 4.35156 13.6346 4.35308 13.6498 4.3556V5.25156H10.3498ZM8.8498 5.25156V4.20156C8.8498 3.76247 9.08335 3.40287 9.38412 3.1773C9.68076 2.95482 10.0477 2.85156 10.3998 2.85156H13.5998C13.9519 2.85156 14.3189 2.95482 14.6155 3.1773C14.9163 3.40287 15.1498 3.76247 15.1498 4.20156V5.25156H17.9922H18.0058H19.1998C19.614 5.25156 19.9498 5.58735 19.9498 6.00156C19.9498 6.41578 19.614 6.75156 19.1998 6.75156H18.6995L17.8925 18.3721C17.8865 19.0674 17.6547 19.7536 17.2167 20.2792C16.7696 20.8157 16.1307 21.1516 15.4284 21.1516H8.57123C7.86895 21.1516 7.23002 20.8157 6.78288 20.2792C6.3449 19.7536 6.11307 19.0675 6.10707 18.3722L5.30008 6.75156H4.7998C4.38559 6.75156 4.0498 6.41578 4.0498 6.00156C4.0498 5.58735 4.38559 5.25156 4.7998 5.25156H5.99377H6.00737H8.8498ZM14.3998 6.75156H9.5998H6.80369L7.60515 18.2925C7.60635 18.3098 7.60695 18.3271 7.60695 18.3444C7.60695 18.7308 7.73605 19.0799 7.93522 19.3189C8.13106 19.5539 8.36421 19.6516 8.57123 19.6516H15.4284C15.6354 19.6516 15.8685 19.5539 16.0644 19.3189C16.2636 19.0799 16.3927 18.7308 16.3927 18.3444C16.3927 18.3271 16.3933 18.3098 16.3945 18.2925L17.1959 6.75156H14.3998ZM10.7998 10.0516C11.214 10.0516 11.5498 10.3873 11.5498 10.8016V18.0016C11.5498 18.4158 11.214 18.7516 10.7998 18.7516C10.3856 18.7516 10.0498 18.4158 10.0498 18.0016V10.8016C10.0498 10.3873 10.3856 10.0516 10.7998 10.0516ZM13.9498 10.8016C13.9498 10.3873 13.614 10.0516 13.1998 10.0516C12.7856 10.0516 12.4498 10.3873 12.4498 10.8016V18.0016C12.4498 18.4158 12.7856 18.7516 13.1998 18.7516C13.614 18.7516 13.9498 18.4158 13.9498 18.0016V10.8016Z"
        fill={color}
      />
    </Svg>
  );
}
