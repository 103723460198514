import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { defaultColors } from "../../theme";

export default function NotificationSettingsIcon({
  color = defaultColors.text,
  size = 24,
}) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M17.2491 8.51677C17.27 8.24634 17.3545 7.98466 17.4956 7.753C18.4356 6.209 16.6686 4.443 15.1256 5.383C14.1464 5.98004 12.8226 5.43161 12.5526 4.317C12.1266 2.561 9.62856 2.561 9.20256 4.317C8.92656 5.452 7.63056 5.99 6.63056 5.382C5.08656 4.442 3.32056 6.209 4.26056 7.752C4.8576 8.73121 4.30918 10.0549 3.19456 10.325C1.43856 10.751 1.43856 13.249 3.19456 13.675C4.30808 13.9458 4.8557 15.2683 4.25956 16.247C3.31956 17.791 5.08656 19.557 6.62956 18.617C7.09307 18.3344 7.68539 18.2885 8.18691 18.4963M10.8776 14.1282L16.5 17.8764L22.1224 14.1282M13.1394 10.0268C11.8646 8.56604 9.41417 8.72311 8.35233 10.3821C7.58753 11.577 7.78252 13.1927 8.80963 14.1713M20.873 12.254C21.563 12.254 22.1224 12.8134 22.1224 13.5035V19.7506C22.1224 20.4406 21.563 21 20.873 21H12.127C11.437 21 10.8776 20.4406 10.8776 19.7506V13.5035C10.8776 12.8134 11.437 12.254 12.127 12.254H20.873Z"
        stroke={color}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
