import React, { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { ScrollView, View, StyleSheet, Platform } from "react-native";
import { StackScreenProps } from "@react-navigation/stack";
import { useTheme } from "@react-navigation/native";
import { TextInput } from "react-native-paper";

import { useStores } from "@models/root-store/root-store-context";
import { FAQData } from "@models/documents/documents-store";
import { Screen } from "@components/index";
import { TopBar, TopBarAlign } from "@components/layout";
import { ErrorsMessage, TabItem, Tabs } from "@components/ui";

import { translate } from "../../../../i18n";
import { spacing } from "../../../../theme";
import { uniq } from "../../../../utils/uniq";
import { QuestionsList } from "../../components/QuestionsList";
import { SettingsNavigatorParamList } from "../../navigation";

const createStyles = (theme: any) =>
  StyleSheet.create({
    container: {
      paddingBottom: spacing[4],
    },
    menu: {
      marginBottom: spacing[4],
    },
    tabs: {
      marginHorizontal: spacing[4],
    },
    input: {
      marginHorizontal: spacing[4],
      marginBottom: spacing[4],
      backgroundColor: theme.colors.inputBG,
      height: 48,
      fontSize: 18,
      paddingVertical: 0,
      lineHeight: Platform.OS === "ios" ? 24 : 32,
    },
    iconStyle: {
      marginTop: 0,
      marginBottom: 0,
    },
    message: {
      marginHorizontal: spacing[4],
      marginBottom: spacing[4],
    },
  });

const ALL_KEY = "all";

const getUniqCategories = (data: FAQData[]): string[] =>
  uniq(data.map(({ category }) => category));

const filterData = (data: FAQData[], category: string, searchText: string) => {
  const dataByCategory =
    category === ALL_KEY
      ? data
      : data.filter((item) => item.category === category);

  if (!searchText) return dataByCategory;

  return dataByCategory.filter(
    ({ title, content }) =>
      `${title.toLowerCase()}+" | "+${content.toLowerCase()}`.indexOf(
        searchText.toLowerCase(),
      ) >= 0,
  );
};

export const HelpFAQScreen: React.FC<
  StackScreenProps<SettingsNavigatorParamList, "settings-help-faq">
> = observer(() => {
  const theme = useTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);

  const { documentsStore, accountStore } = useStores();
  const tabKeys = getUniqCategories(documentsStore.faqData);

  const [searchText, setSearchText] = useState("");
  const [isFocus, setIsFocus] = useState(false);
  const [itemKey, setItemKey] = useState(ALL_KEY);

  useEffect(() => {
    documentsStore.readFAQData();
  }, [accountStore.currentUser.id]);

  const onFilterPress = (item) => {
    setItemKey(item.key);
    setSearchText("");
  };

  // TODO: !!!
  const onSearch = () => {
    console.log("search?");
  };

  const tabsItems: TabItem[] = [
    {
      key: ALL_KEY,
      name: translate("common.all"),
      onPress: onFilterPress,
    },
  ];

  tabKeys.map((category) =>
    tabsItems.push({
      key: category,
      name: category,
      onPress: onFilterPress,
    }),
  );

  const items = filterData(documentsStore.faqData, itemKey, searchText);

  const onRefresh = useCallback(async () => {
    await documentsStore.readFAQData({ force: true });
  }, []);

  return (
    <Screen
      style={styles.container}
      testID="HelpFAQ"
      preset="scroll"
      onRefresh={onRefresh}
      refreshing={!documentsStore.isLoaded}
      header={
        <>
          <TopBar
            title={translate("Settings.faq.title")}
            align={TopBarAlign.Left}
            back
          />
          <TextInput
            style={styles.input}
            onChangeText={setSearchText}
            placeholder={translate("Settings.faq.searchHere")}
            selectionColor={theme.colors.primary}
            underlineColor="transparent"
            mode="outlined"
            value={searchText}
            outlineColor={
              isFocus ? theme.colors.inputBorderFocus : theme.colors.inputBorder
            }
            returnKeyType="done"
            onFocus={() => {
              setItemKey(ALL_KEY);
              setIsFocus(true);
            }}
            onBlur={() => {
              setIsFocus(false);
            }}
            right={
              <TextInput.Icon
                style={styles.iconStyle}
                icon="magnify"
                onPress={() => onSearch()}
              />
            }
          />
          {tabsItems?.length > 1 ? (
            <View style={styles.menu}>
              <ScrollView
                bounces={false}
                horizontal
                // pagingEnabled
                showsHorizontalScrollIndicator={false}
              >
                <Tabs
                  items={tabsItems}
                  currentTab={itemKey}
                  style={styles.tabs}
                  withBG
                />
              </ScrollView>
            </View>
          ) : null}
        </>
      }
    >
      <ErrorsMessage
        errors={documentsStore.statusErrors}
        onCancel={documentsStore.resetErrors}
        style={styles.message}
      />

      <QuestionsList items={items} searchText={searchText} />
    </Screen>
  );
});
