import { flow, Instance, types } from "mobx-state-tree";
import dayjs from "dayjs";

import { withEnvironment } from "@models/extensions/with-environment";
import { withRootStore } from "@models/extensions/with-root-store";
import {
  CalibrationProcessStatus,
  CalibrationStep,
} from "@screens/Sensors/Calibration/types";
import {
  CALIBRATION_STEPS,
  CALIBRATION_TIME_MS,
  DEFAULT_LIQUIDS_VALUES,
} from "@screens/Sensors/Calibration/constants";

import { rnLogger } from "../../services/logger";
import { DateTimeFormats } from "../../utils/timeConverter";
import { CalibrationApi } from "../../services/api";
import { translate } from "../../i18n";
import { stringToNumber } from "../../utils/numbers";

export const calibrationIsAvailable = (sensorType: string) => {
  return CALIBRATION_STEPS[sensorType]?.length;
};

const SensorCalibrationWarningModel = types
  .model("SensorCalibrationWorning")
  .props({
    code: types.string,
    details: types.string,
    message: types.string,
  });

export type TSensorCalibrationWarning = Instance<
  typeof SensorCalibrationWarningModel
>;

export const SensorCalibrationModel = types
  .model("SensorCalibration")
  .props({
    uid: types.maybe(types.string),
    validUntil: types.maybeNull(types.string),
    sensorUid: types.maybe(types.string),
    sensorType: types.maybe(types.string),
    stepIndex: types.optional(types.number, 0),
    status: types.maybe(
      types.enumeration<CalibrationProcessStatus>(
        "CalibrationStatus",
        Object.values(CalibrationProcessStatus),
      ),
    ),
    stepValue: types.maybe(types.number),
    error: types.maybe(types.string),
    warnings: types.maybe(types.array(SensorCalibrationWarningModel)),
    logs: types.optional(types.string, ""),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .views((self) => ({
    get steps() {
      return CALIBRATION_STEPS[self.sensorType] || [];
    },
  }))
  .views((self) => ({
    get currentStep() {
      return self.steps[self.stepIndex];
    },

    get stepNameOf() {
      return translate("Sensors.Calibration.stepNameOf", {
        index: self.stepIndex,
        total: self.steps.length - 2,
      });
    },

    get isInProgress() {
      return self.status === CalibrationProcessStatus.Process;
    },

    get hasError() {
      return self.status === CalibrationProcessStatus.Error;
    },
  }))
  .views((self) => ({
    get isLastProcess() {
      return self.currentStep === CalibrationStep.Process2;
    },

    get hasStarted() {
      return (
        ![CalibrationStep.Prepare, CalibrationStep.Success].includes(
          self.currentStep,
        ) && !self.hasError
      );
    },

    get warningMessages(): string[] | [] {
      if (!self.warnings?.length) return [];

      return self.warnings.map((item) => item.message);
    },
  }))
  .actions((self) => ({
    addLog(log: string) {
      rnLogger.info(log, {
        action: "sensor_calibration",
      });
      self.logs = self.logs.concat(
        `${dayjs().format(DateTimeFormats.TimeHMS)} - ${log}\n`,
      );
    },

    setError(error: string, status = CalibrationProcessStatus.Error) {
      self.error = error;
      self.status = status;

      rnLogger.error(self.logs, {
        action: "sensor_calibration",
      });
      return false;
    },

    reset: (sensorUid?: string, sensorType?: string) => {
      self.uid = undefined;
      self.sensorUid = sensorUid;
      self.sensorType = sensorType;
      self.stepIndex = 0;
      self.status = undefined;
      self.stepValue = undefined;
      self.error = undefined;
      self.logs = "";
      self.warnings = undefined;
    },

    nextStep() {
      self.status = undefined;
      const nextStepIndex = self.stepIndex + 1;
      const nextStep = self.steps[nextStepIndex];

      if (nextStep) {
        self.stepIndex = nextStepIndex;

        if (
          [CalibrationStep.Process1, CalibrationStep.Process2].includes(
            nextStep,
          )
        ) {
          const valueIndex = nextStep === CalibrationStep.Process2 ? 1 : 0;
          self.stepValue =
            DEFAULT_LIQUIDS_VALUES[self.sensorType]?.[valueIndex];
        }
      }
    },

    setStepValue(value?: number | string) {
      self.stepValue = stringToNumber(value.toString());
    },

    setWarnings(warnings: TSensorCalibrationWarning[]) {
      if (!warnings) return false;

      self.warnings = warnings;

      return true;
    },

    resetWarnings() {
      self.warnings = undefined;

      return true;
    },
  }))
  .actions((self) => {
    const calibrationApi = new CalibrationApi(self.environment.api);

    const init = flow(function* () {
      if (!self.sensorUid) {
        self.setError("No sensor");
        return;
      }
      const result = yield calibrationApi.startCalibration(self.sensorUid);

      if (result.kind === "ok") {
        self.setWarnings(result.data?.warnings);
        self.uid = result.data.calibrationUid;
        self.validUntil = result.data.validUntil;
        self.nextStep();
      } else {
        self.setError(result.errors?.[0]);
      }
    });

    const stop = flow(function* () {
      if (!self.uid) return;
      const result = yield calibrationApi.stopCalibration(self.uid);

      if (result.kind === "ok") {
        self.setWarnings(result.data?.warnings);
        self.nextStep(); // Success
      } else {
        self.setError(result.errors?.[0]);
      }
    });

    const valueStart = flow(function* () {
      if (!self.uid) return;
      self.status = CalibrationProcessStatus.Process;
      const result = self.isLastProcess
        ? yield calibrationApi.secondValueStart(self.uid, self.stepValue)
        : yield calibrationApi.firstValueStart(self.uid, self.stepValue);

      if (result.kind === "ok") {
        self.setWarnings(result.data?.warnings);
        setTimeout(self.valueStop, CALIBRATION_TIME_MS / 2);
      } else {
        self.setError(result.errors?.[0]);
      }
    });

    const valueStop = flow(function* () {
      if (!self.uid) return;
      const result = self.isLastProcess
        ? yield calibrationApi.secondValueStop(self.uid, self.stepValue)
        : yield calibrationApi.firstValueStop(self.uid, self.stepValue);

      if (result.kind === "ok") {
        self.setWarnings(result.data?.warnings);

        if (self.isLastProcess) {
          yield stop();
        } else {
          self.nextStep();
        }
      } else {
        self.setError(result.errors?.[0]);
      }
    });

    const process = () => {
      self.status = CalibrationProcessStatus.Process;
      setTimeout(self.valueStart, CALIBRATION_TIME_MS / 2);
    };

    const tryAgain = () => {
      self.reset(self.sensorUid, self.sensorType);
    };

    return {
      valueStart,
      valueStop,
      stop,

      init,
      process,
      tryAgain,
    };
  });

export type TSensorCalibration = Instance<typeof SensorCalibrationModel>;
