import * as React from "react";
import Svg, { Circle } from "react-native-svg";
import { palette } from "../theme";

export default function DotIcon({ color = palette.RedNew, size = 24 }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Circle id="Ellipse 64" cx={12} cy={12} r={4.8} fill={color} />
    </Svg>
  );
}
