import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { palette } from "../theme";

function Logo({ color = palette.LogoGreen, width = 156, height = 84 }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 156 84" fill="none">
      <Path
        d="M76.9714 17.1871C77.1807 16.8845 77.5394 16.6972 77.9131 16.6972C78.2867 16.6972 78.6305 16.8701 78.8547 17.1727C78.8846 17.2159 78.9145 17.2591 78.9294 17.3023L91.1108 37.6461H95.789L79.6917 10.7613C79.333 10.1273 78.6454 9.73828 77.9131 9.73828C77.1956 9.73828 76.523 10.1129 76.1643 10.718L76.1045 10.8189L60.0371 37.6461H64.7154L76.9116 17.288C76.9266 17.2591 76.9415 17.2159 76.9714 17.1871Z"
        fill={color}
      />
      <Path
        d="M103.098 35.9761L84.9678 5.69103L83.3835 3.04001C82.2625 1.16701 80.17 0 77.928 0C75.6861 0 73.5936 1.16701 72.4726 3.04001L71.8598 4.07737L52.7582 35.9761C51.6372 37.8491 51.6372 40.1688 52.7582 42.0418C53.8792 43.9148 55.9717 45.0818 58.2137 45.0818H63.3403H63.6243H73.6234H73.7131C74.9238 45.125 75.8654 46.0759 75.8654 47.2429V51.9543H79.9159V47.2429C79.9159 43.9003 77.091 41.1773 73.6234 41.1773H58.1688C57.3916 41.1629 56.6592 40.7451 56.2706 40.0967C55.8671 39.434 55.8671 38.5983 56.2706 37.9355L76.0149 4.97064C76.4184 4.32229 77.1657 3.91891 77.943 3.91891C78.7202 3.91891 79.4675 4.32229 79.8711 4.97064L99.6153 37.9355C100.019 38.5983 100.019 39.434 99.6153 40.0967C99.2267 40.7451 98.4943 41.1629 97.7171 41.1773H90.9016H90.782C89.9749 41.1773 89.2425 40.7595 88.8389 40.0967L79.7366 24.8966C79.3778 24.2626 78.6903 23.8736 77.9579 23.8736C77.2405 23.8736 76.5679 24.2482 76.2092 24.8533L76.1494 24.9542L68.5267 37.6762H73.2049L76.9565 31.4233C76.9715 31.3945 77.0013 31.3512 77.0163 31.3224C77.2255 31.0198 77.5843 30.8325 77.9579 30.8325C78.3316 30.8325 78.6754 31.0054 78.8996 31.308C78.9295 31.3512 78.9593 31.3945 78.9743 31.4377L85.3415 42.0562C86.4625 43.9292 88.555 45.0962 90.7969 45.0962H92.172H95.1314H96.1029H96.3421H97.6872C99.9292 45.0962 102.022 43.9292 103.143 42.0562C104.204 40.1688 104.219 37.8491 103.098 35.9761Z"
        fill={color}
      />
      <Path
        d="M11.7823 81.7836C11.2464 82.4865 10.5036 83.0188 9.554 83.3804C8.60437 83.7421 7.52586 83.923 6.31846 83.923C5.07715 83.923 3.97829 83.6398 3.02187 83.0733C2.06546 82.5069 1.3261 81.6983 0.803798 80.6473C0.288282 79.5896 0.0203493 78.3578 0 76.952V75.7851C0 73.5331 0.535865 71.7758 1.6076 70.5133C2.67933 69.244 4.175 68.6094 6.09462 68.6094C7.74292 68.6094 9.05205 69.0188 10.022 69.8377C10.992 70.6566 11.5754 71.8372 11.7721 73.3795H9.24876C8.96387 71.5847 7.92945 70.6874 6.14549 70.6874C4.99237 70.6874 4.11395 71.107 3.51026 71.9464C2.91334 72.779 2.60471 74.0039 2.58436 75.6213V76.7678C2.58436 78.3783 2.92013 79.6305 3.59165 80.5245C4.26997 81.4116 5.20943 81.8552 6.41004 81.8552C7.72596 81.8552 8.66203 81.555 9.21824 80.9544V78.0371H6.16584V76.0717H11.7823V81.7836Z"
        fill={color}
      />
      <Path
        d="M20.0543 77.9756H17.185V83.7182H14.6108V68.8141H19.8202C21.5296 68.8141 22.8489 69.1997 23.7782 69.9708C24.7075 70.7419 25.1721 71.8577 25.1721 73.3181C25.1721 74.3144 24.9313 75.1504 24.4497 75.826C23.9749 76.4948 23.3101 77.01 22.4555 77.3717L25.7826 83.5852V83.7182H23.0253L20.0543 77.9756ZM17.185 75.8977H19.8304C20.6986 75.8977 21.377 75.6793 21.8653 75.2425C22.3537 74.799 22.5979 74.195 22.5979 73.4307C22.5979 72.6323 22.3707 72.0147 21.9162 71.5779C21.4685 71.1412 20.797 70.916 19.9016 70.9023H17.185V75.8977Z"
        fill={color}
      />
      <Path
        d="M39.437 76.6552C39.437 78.1155 39.186 79.3985 38.6841 80.504C38.1821 81.6027 37.4631 82.4489 36.527 83.0426C35.5977 83.6295 34.526 83.923 33.3118 83.923C32.1112 83.923 31.0395 83.6295 30.0966 83.0426C29.1606 82.4489 28.4348 81.6061 27.9193 80.5143C27.4105 79.4224 27.1528 78.1633 27.146 76.737V75.8977C27.146 74.4441 27.4004 73.1611 27.9091 72.0488C28.4246 70.9364 29.147 70.0868 30.0763 69.4999C31.0124 68.9062 32.0841 68.6094 33.2915 68.6094C34.4989 68.6094 35.5672 68.9028 36.4965 69.4897C37.4326 70.0698 38.155 70.9091 38.6637 72.0078C39.1724 73.0997 39.4302 74.3724 39.437 75.826V76.6552ZM36.8628 75.8772C36.8628 74.2257 36.5508 72.9598 35.9267 72.0795C35.3095 71.1992 34.431 70.759 33.2915 70.759C32.1791 70.759 31.3074 71.1992 30.6766 72.0795C30.0526 72.953 29.7337 74.1916 29.7202 75.7953V76.6552C29.7202 78.293 30.0356 79.5589 30.6664 80.4528C31.304 81.3468 32.1858 81.7938 33.3118 81.7938C34.4514 81.7938 35.3264 81.3571 35.9369 80.4836C36.5542 79.61 36.8628 78.3339 36.8628 76.6552V75.8772Z"
        fill={color}
      />
      <Path
        d="M53.6205 80.0332L55.8996 68.8141H58.4534L55.0347 83.7182H52.5725L49.7541 72.837L46.8747 83.7182H44.4022L40.9835 68.8141H43.5374L45.8368 80.0127L48.6654 68.8141H50.8224L53.6205 80.0332Z"
        fill={color}
      />
      <Path
        d="M60.3357 83.7182V68.8141H64.7108C66.02 68.8141 67.1799 69.1075 68.1906 69.6944C69.208 70.2813 69.9949 71.1139 70.5511 72.1921C71.1073 73.2703 71.3854 74.5055 71.3854 75.8977V76.6449C71.3854 78.0575 71.1039 79.2995 70.5409 80.3709C69.9847 81.4424 69.1877 82.2681 68.1499 82.8481C67.1188 83.4282 65.9352 83.7182 64.5989 83.7182H60.3357ZM62.9099 70.9023V81.6505H64.5887C65.9386 81.6505 66.973 81.2274 67.692 80.3812C68.4178 79.5282 68.7875 78.3066 68.801 76.7166V75.8874C68.801 74.2701 68.4517 73.0349 67.7531 72.1819C67.0544 71.3288 66.0403 70.9023 64.7108 70.9023H62.9099Z"
        fill={color}
      />
      <Path d="M76.6661 83.7182H74.102V68.8141H76.6661V83.7182Z" fill={color} />
      <Path
        d="M85.2738 77.9756H82.4046V83.7182H79.8304V68.8141H85.0398C86.7491 68.8141 88.0685 69.1997 88.9977 69.9708C89.927 70.7419 90.3917 71.8577 90.3917 73.3181C90.3917 74.3144 90.1509 75.1504 89.6693 75.826C89.1945 76.4948 88.5297 77.01 87.675 77.3717L91.0022 83.5852V83.7182H88.2448L85.2738 77.9756ZM82.4046 75.8977H85.05C85.9182 75.8977 86.5965 75.6793 87.0849 75.2425C87.5733 74.799 87.8175 74.195 87.8175 73.4307C87.8175 72.6323 87.5902 72.0147 87.1358 71.5779C86.6881 71.1412 86.0166 70.916 85.1212 70.9023H82.4046V75.8977Z"
        fill={color}
      />
      <Path
        d="M101.492 77.0646H95.4078V81.6505H102.52V83.7182H92.8336V68.8141H102.449V70.9023H95.4078V75.0173H101.492V77.0646Z"
        fill={color}
      />
      <Path
        d="M115.92 78.8662C115.771 80.4563 115.187 81.6983 114.17 82.5922C113.152 83.4794 111.799 83.923 110.11 83.923C108.93 83.923 107.889 83.6432 106.987 83.0836C106.091 82.5172 105.399 81.7153 104.911 80.678C104.423 79.6408 104.168 78.4363 104.148 77.0646V75.6725C104.148 74.2667 104.395 73.0281 104.891 71.9567C105.386 70.8853 106.095 70.0595 107.017 69.4795C107.946 68.8994 109.018 68.6094 110.232 68.6094C111.867 68.6094 113.183 69.0564 114.18 69.9503C115.177 70.8443 115.757 72.1068 115.92 73.7378H113.356C113.234 72.6664 112.922 71.8952 112.42 71.4244C111.925 70.9467 111.195 70.7078 110.232 70.7078C109.113 70.7078 108.252 71.1207 107.648 71.9464C107.051 72.7653 106.746 73.9698 106.732 75.5599V76.8804C106.732 78.4909 107.017 79.7192 107.587 80.5654C108.163 81.4116 109.005 81.8347 110.11 81.8347C111.121 81.8347 111.881 81.6061 112.389 81.1489C112.898 80.6917 113.22 79.9308 113.356 78.8662H115.92Z"
        fill={color}
      />
      <Path
        d="M128.628 70.9023H124.009V83.7182H121.455V70.9023H116.876V68.8141H128.628V70.9023Z"
        fill={color}
      />
      <Path
        d="M142.12 76.6552C142.12 78.1155 141.869 79.3985 141.367 80.504C140.865 81.6027 140.146 82.4489 139.21 83.0426C138.28 83.6295 137.209 83.923 135.994 83.923C134.794 83.923 133.722 83.6295 132.779 83.0426C131.843 82.4489 131.117 81.6061 130.602 80.5143C130.093 79.4224 129.835 78.1633 129.829 76.737V75.8977C129.829 74.4441 130.083 73.1611 130.592 72.0488C131.107 70.9364 131.83 70.0868 132.759 69.4999C133.695 68.9062 134.767 68.6094 135.974 68.6094C137.182 68.6094 138.25 68.9028 139.179 69.4897C140.115 70.0698 140.838 70.9091 141.346 72.0078C141.855 73.0997 142.113 74.3724 142.12 75.826V76.6552ZM139.545 75.8772C139.545 74.2257 139.233 72.9598 138.609 72.0795C137.992 71.1992 137.114 70.759 135.974 70.759C134.862 70.759 133.99 71.1992 133.359 72.0795C132.735 72.953 132.416 74.1916 132.403 75.7953V76.6552C132.403 78.293 132.718 79.5589 133.349 80.4528C133.987 81.3468 134.868 81.7938 135.994 81.7938C137.134 81.7938 138.009 81.3571 138.62 80.4836C139.237 79.61 139.545 78.3339 139.545 76.6552V75.8772Z"
        fill={color}
      />
      <Path
        d="M150.127 77.9756H147.258V83.7182H144.684V68.8141H149.893C151.602 68.8141 152.922 69.1997 153.851 69.9708C154.78 70.7419 155.245 71.8577 155.245 73.3181C155.245 74.3144 155.004 75.1504 154.523 75.826C154.048 76.4948 153.383 77.01 152.528 77.3717L155.855 83.5852V83.7182H153.098L150.127 77.9756ZM147.258 75.8977H149.903C150.771 75.8977 151.45 75.6793 151.938 75.2425C152.427 74.799 152.671 74.195 152.671 73.4307C152.671 72.6323 152.444 72.0147 151.989 71.5779C151.541 71.1412 150.87 70.916 149.974 70.9023H147.258V75.8977Z"
        fill={color}
      />
    </Svg>
  );
}

export default Logo;
