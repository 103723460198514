import { View, StyleSheet } from "react-native";
import { shadow } from "react-native-paper";

import { RuleDirection } from "@models/rule/rule";

import { useStores } from "@models/index";
import { DefaultTheme, spacing } from "../../../../../../theme";
import { SensorValues } from "../../components/sensor";

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    backgroundColor: DefaultTheme.colors.background,
    padding: spacing[1],
    borderRadius: spacing[2],
    top: 326,
    left: spacing[3],
    right: spacing[3],
    ...(shadow(5) as any),
  },
  values: {
    marginBottom: 0,
  },
});

export const SensorValuesHinte = (uid: string, direction: RuleDirection) => {
  const { sensorStore } = useStores();

  const sensor = sensorStore.getSensor(uid);
  const { minMaxOptions } = sensor;
  const minOptions = minMaxOptions[0];
  const maxOptions = minMaxOptions[minMaxOptions.length - 1];
  const minMax = [minOptions, maxOptions];

  const data = {
    direction,
    valueLow:
      direction === RuleDirection.Up ? minOptions.value : maxOptions.value,
    valueHigh:
      direction === RuleDirection.Up ? maxOptions.value : minOptions.value,
  };

  return (
    <View style={styles.container}>
      <SensorValues
        style={styles.values}
        formData={data}
        sensorMinMaxOptions={minMax}
      />
    </View>
  );
};
