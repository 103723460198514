/* eslint-disable import/prefer-default-export */
export const kNumberFormatter = (num: number) => {
  if (Math.abs(num) > 999) {
    return `${(Math.sign(num) * (Math.abs(num) / 1000)).toFixed(1)}k`;
  }
  if (num > 0 && num < 10) {
    return (Math.sign(num) * Math.abs(num)).toFixed(3);
  }
  return (Math.sign(num) * Math.abs(num)).toFixed(2);
};

export const stringToNumber = (rawValue?: string): number | undefined => {
  const value = (rawValue || "").replace(",", ".");

  return value === "" || Number.isNaN(parseFloat(value))
    ? undefined
    : parseFloat(value);
};

export const numberOfIntegers = (value) =>
  Math.round(Number(value)).toString().length;

export const numberToString = (value: number | null | undefined): string =>
  value?.toString() ?? "";
