import { useLayoutEffect, useState } from "react";
import { StyleSheet } from "react-native";

import { Dialog, Text, ProgressBar } from "@components/ui";
import { Module } from "@models/module/module";
import { TitleDialog } from "@components/dialog";

import { translate } from "../../../../../i18n";
import { defaultColors, spacing } from "../../../../../theme";
import { ModuleDetailsImage } from "../../components/DetailsImage";

const FIND_MODULE_TIME_MS = 180 * 1000;

const styles = StyleSheet.create({
  description: {
    marginTop: spacing[4],
    color: defaultColors.label,
  },
});

type FindModuleProps = {
  module: Module;
  onClose: () => void;
};

export const FindModule = ({ module, onClose }: FindModuleProps) => {
  const [isBlinked, setIsBlinked] = useState<boolean>(false);

  const textButton = isBlinked
    ? translate("common.stop")
    : translate("common.start");

  const findModuleStart = () => {
    setIsBlinked(true);
    module.findModuleStart();
  };

  const findModuleStop = () => {
    setIsBlinked(false);
    module.findModuleStop();
  };

  const onDialogClose = () => {
    findModuleStop();
    onClose();
  };

  const onButtonPress = () => {
    if (isBlinked) {
      onDialogClose();
    } else {
      findModuleStart();
    }
  };

  useLayoutEffect(() => {
    findModuleStart();
  }, [module.uid]);

  return (
    <Dialog
      title={
        <TitleDialog
          title={translate("Modules.info.findingModule.title")}
          onClose={onDialogClose}
        />
      }
      visible
      onDismiss={onDialogClose}
      onOk={onButtonPress}
      okText={textButton}
    >
      <Text style={styles.description}>
        {translate("Modules.info.findingModule.description")}
      </Text>
      <ModuleDetailsImage
        moduleType={module.type}
        disabled={!module.isOnline}
      />

      <ProgressBar
        durationMs={isBlinked ? FIND_MODULE_TIME_MS : 0}
        afterEnd={findModuleStop}
      />
    </Dialog>
  );
};
