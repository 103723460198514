import React, { useMemo } from "react";
import { StyleSheet } from "react-native";
import { StackNavigationProp } from "@react-navigation/stack";
import { useNavigation } from "@react-navigation/native";
import { useStores } from "@models/index";

import { Screen } from "@components/index";
import { TopBar } from "@components/layout";
import { OnboardingStep, TOnboardingStep } from "@components/common";

import {
  Welcome as WelcomeImage,
  SmartMachine as SmartMachineImage,
  OneSpace as OneSpaceImage,
  GrowBook as GrowBookImage,
  LetsStart as LetsStartImage,
} from "../../svgs/pictures/onboarding";
import { translate } from "../../i18n";
import { spacing } from "../../theme";
import { goBack } from "../../navigators";

const MODULES_ROUTE = "modules-list";

const styles = StyleSheet.create({
  screen: {
    flex: 1,
  },
  onboarding: {
    marginTop: -spacing[6],
  },
});

const data: TOnboardingStep[] = [
  {
    id: 0,
    Picture: WelcomeImage,
    title: translate("Onboarding.welcomeToGrowDirector"),
    description: translate("Onboarding.welcomeToGrowDirectorDescription"),
  },
  {
    id: 1,
    Picture: SmartMachineImage,
    title: translate("Onboarding.smartMachineLearning"),
    description: translate("Onboarding.smartMachineLearningDescription"),
  },
  {
    id: 2,
    Picture: OneSpaceImage,
    title: translate("Onboarding.oneSpace"),
    description: translate("Onboarding.oneSpaceDescription"),
  },
  {
    id: 3,
    Picture: GrowBookImage,
    title: translate("Onboarding.growBook"),
    description: translate("Onboarding.growBookDescription"),
  },
  {
    id: 4,
    Picture: LetsStartImage,
    title: translate("Onboarding.letsStart"),
    description: translate("Onboarding.letsStartDescription"),
  },
];

export const Onboarding = ({ route }) => {
  const navigation = useNavigation() as StackNavigationProp<any>;
  const { accountStore } = useStores();
  const { params = {} } = route as any;

  const goToModules = () => {
    navigation.popToTop();

    if (params.isNeedNavigate) {
      navigation.navigate(MODULES_ROUTE);
      return;
    }

    accountStore.changeActiveStatus(true, MODULES_ROUTE);
    setTimeout(() => {
      accountStore.setDefoultInitialRouteName();
    }, 2000);
  };

  const actions = useMemo(
    () => [
      {
        key: "onboarding-close",
        text: translate("Onboarding.Skip"),
        onPress: () => {
          accountStore.changeActiveStatus(true);
          goBack();
        },
      },
    ],
    [],
  );

  return (
    <Screen
      preset="fixed"
      style={styles.screen}
      header={<TopBar title="" actions={actions} />}
    >
      <OnboardingStep
        data={data}
        afterEnd={goToModules}
        style={styles.onboarding}
      />
    </Screen>
  );
};

// TODO: look at this - https://github.com/Jacse/react-native-app-intro-slider
