import {
  applySnapshot,
  flow,
  getParent,
  Instance,
  types,
} from "mobx-state-tree";
import dayjs from "dayjs";

import { withEnvironment } from "@models/extensions/with-environment";
import { withRootStore } from "@models/extensions/with-root-store";
import { Sensor } from "@models/sensor/sensor";
import { withStatus } from "@models/extensions/with-status";
import { CalibrationStep } from "@screens/Sensors/Calibration/types";
import {
  CALIBRATION_STEPS,
  DEFAULT_LIQUIDS_VALUES,
} from "@screens/Sensors/Calibration/constants";

import { rnLogger } from "../../services/logger";
import {
  dateTimeFormat,
  DateTimeFormats,
  secondsToText,
} from "../../utils/timeConverter";
import { CalibrationStatus, SensorCalibrationApi } from "../../services/api";
import { translate } from "../../i18n";
import { stringToNumber } from "../../utils/numbers";
import { delay } from "../../utils/delay";

const CalibrationWarningModel = types.model("CalibrationWarning").props({
  code: types.maybeNull(types.string),
  message: types.maybeNull(types.string),
  details: types.maybeNull(types.string),
});

const CalibrationErrorModel = types.model("CalibrationError").props({
  code: types.maybeNull(types.string),
  message: types.maybeNull(types.string),
});

export const SensorLongTimeCalibrationModel = types
  .model("SensorLongTimeCalibration")
  .props({
    uid: types.maybeNull(types.string),
    validUntil: types.maybeNull(types.string),
    timeInSeconds: types.maybeNull(types.number),
    calibrationStatus: types.maybeNull(
      types.enumeration<CalibrationStatus>(
        "CalibrationStatus",
        Object.values(CalibrationStatus),
      ),
    ),
    updatedAt: types.maybeNull(types.string),

    warnings: types.optional(types.array(CalibrationWarningModel), []),
    errors: types.optional(types.array(CalibrationErrorModel), []),

    stepIndex: types.optional(types.number, 0),
    stepValue: types.maybe(types.number),
    // error: types.maybe(types.string),
    logs: types.optional(types.string, ""),
  })
  .extend(withEnvironment)
  .extend(withStatus)
  .extend(withRootStore)
  .views((self) => ({
    get sensor() {
      return getParent<Sensor>(self);
    },

    get isUpToDate() {
      return Boolean(
        self.updatedAt && dayjs().diff(dayjs(self.updatedAt), "day", true) < 1,
      );
    },

    get dateTime() {
      return dateTimeFormat(
        self.updatedAt,
        `${DateTimeFormats.DateDM} ${DateTimeFormats.TimeHM}`,
      );
    },

    get errorMessage() {
      return self.errors
        .map((error) => `${error.code}: ${error.message}`)
        .join("; ");
    },
  }))
  .views((self) => ({
    get steps() {
      return CALIBRATION_STEPS[self.sensor.type] || [];
    },
  }))
  .views((self) => ({
    get currentStep() {
      return self.steps[self.stepIndex];
    },

    get stepNameOf() {
      return translate("Sensors.Calibration.stepNameOf", {
        index: self.stepIndex,
        total: self.steps.length - 2,
      });
    },
  }))
  .views((self) => ({
    get isExpired() {
      return (
        self.calibrationStatus === CalibrationStatus.Expired && self.isUpToDate
      );
    },

    get isError() {
      return (
        self.calibrationStatus === CalibrationStatus.Error && self.isUpToDate
      );
    },

    get isSuccess() {
      return (
        self.calibrationStatus === CalibrationStatus.Completed &&
        self.isUpToDate
      );
    },

    get isInProgress() {
      return [
        CalibrationStatus.FirstValueStabilization,
        CalibrationStatus.FirstValueStart,
        CalibrationStatus.SecondValueStabilization,
        CalibrationStatus.SecondValueStart,
        CalibrationStatus.SecondValueStop,
      ].includes(self.calibrationStatus);
    },

    get isInterrupted() {
      return [
        CalibrationStatus.Started,
        CalibrationStatus.FirstValueStop,
        CalibrationStatus.SecondValueStop,
      ].includes(self.calibrationStatus);
    },

    get isSecondProcessStep() {
      return self.currentStep === CalibrationStep.Process2;
    },
  }))
  .actions((self) => ({
    addLog(log: string) {
      rnLogger.info(log, {
        action: "sensor_calibration",
      });
      self.logs = self.logs.concat(
        `${dayjs().format(DateTimeFormats.TimeHMS)} - ${log}\n`,
      );
    },

    reset: () => {
      self.uid = undefined;
      self.stepIndex = 0;
      self.calibrationStatus = undefined;
      self.stepValue = undefined;
      self.errors.clear();
      self.warnings.clear();
      self.logs = "";
    },

    nextStep() {
      const nextStepIndex = self.stepIndex + 1;
      const nextStep = self.steps[nextStepIndex];

      if (nextStep) {
        self.stepIndex = nextStepIndex;
        if (
          [CalibrationStep.Process1, CalibrationStep.Process2].includes(
            nextStep,
          )
        ) {
          const valueIndex = nextStep === CalibrationStep.Process2 ? 1 : 0;
          self.stepValue =
            DEFAULT_LIQUIDS_VALUES[self.sensor.type]?.[valueIndex];
        }
      }
    },

    setStepValue(value?: number | string) {
      self.stepValue = stringToNumber(value.toString());
    },

    getRemainingTime(formatted = false) {
      if (
        !self.isInProgress ||
        self.calibrationStatus === CalibrationStatus.SecondValueStop
      ) {
        return undefined;
      }

      const diff = dayjs().diff(dayjs(self.updatedAt), "seconds");
      const value = self.timeInSeconds - diff + 10; // TODO: remove +10sec after fixing https://growdirector.atlassian.net/browse/GDV1-1288
      if (value < 0) {
        return 0;
      }
      return formatted ? secondsToText(value, "duration") : value;
    },
  }))
  .actions((self) => {
    const sensorCalibrationApi = new SensorCalibrationApi(self.environment.api);

    const setErrors = (errors?: string[]) => {
      self.addLog(errors?.join("\n\r"));
      rnLogger.error(self.logs, {
        action: "sensor_calibration",
      });
      self.setStatusError(errors);
    };

    const setStepFromStatus = () => {
      if (self.calibrationStatus === CalibrationStatus.Cancelled) {
        self.reset();
        return;
      }

      if (self.calibrationStatus === CalibrationStatus.Started) {
        self.stepIndex = self.steps.findIndex(
          (item) => item === CalibrationStep.Clean,
        );

        if (self.stepIndex < 0) {
          self.stepIndex = self.steps.findIndex(
            (item) => item === CalibrationStep.Process1,
          );
        }

        self.stepValue = undefined;
      }

      if (
        [
          CalibrationStatus.FirstValueStabilization,
          CalibrationStatus.FirstValueStart,
        ].includes(self.calibrationStatus)
      ) {
        self.stepIndex = self.steps.findIndex(
          (item) => item === CalibrationStep.Process1,
        );
        if (!self.getRemainingTime()) {
          refetchStatus();
        }
        return;
      }

      if (self.calibrationStatus === CalibrationStatus.FirstValueStop) {
        self.stepIndex =
          self.steps.findIndex((item) => item === CalibrationStep.Process1) + 1;
        return;
      }

      if (
        [
          CalibrationStatus.SecondValueStabilization,
          CalibrationStatus.SecondValueStart,
        ].includes(self.calibrationStatus)
      ) {
        self.stepIndex = self.steps.findIndex(
          (item) => item === CalibrationStep.Process2,
        );
        if (!self.getRemainingTime()) {
          refetchStatus();
        }
        return;
      }

      if (self.calibrationStatus === CalibrationStatus.SecondValueStop) {
        self.stepIndex = self.steps.findIndex(
          (item) => item === CalibrationStep.Process2,
        );
        refetchStatus();
      }
    };

    const refetchStatus = flow(function* () {
      yield delay(1000);
      yield fetchStatus(true);
    });

    const fetchStatus = flow(function* (silent = false) {
      if (!self.sensor?.uid) {
        setErrors(["No sensor"]);
        return;
      }

      if (silent) {
        self.setStatusPendingPartly();
      } else {
        self.setStatusPending();
      }
      const result = yield sensorCalibrationApi.status(self.sensor.uid);

      if (result.kind === "ok") {
        applySnapshot(self, result.data);
        setStepFromStatus();
        self.setStatusDone();
      } else {
        setErrors(result.errors);
      }
    });

    const start = flow(function* () {
      // self.setStatusPending();
      const result = yield sensorCalibrationApi.start(self.sensor.uid);

      if (result.kind === "ok") {
        // self.setStatusDone();
        self.uid = result.data.uid;
        self.validUntil = result.data.validUntil;
        self.calibrationStatus = CalibrationStatus.Started;
        setStepFromStatus();
      } else {
        setErrors(result.errors);
      }
    });

    const stop = flow(function* () {
      if (!self.uid) return;
      // self.setStatusPending();
      const result = yield sensorCalibrationApi.stop(self.uid);

      if (result.kind !== "ok") {
        console.error("stop result", result);
      }

      // if (result.kind === "ok") {
      //   self.setStatusDone();
      // } else {
      //   setErrors(result.errors);
      // }
      self.reset();
    });

    const valueStart = flow(function* () {
      if (!self.uid) return;

      const result = self.isSecondProcessStep
        ? yield sensorCalibrationApi.secondValue(self.uid, self.stepValue)
        : yield sensorCalibrationApi.firstValue(self.uid, self.stepValue);

      if (result.kind === "ok") {
        applySnapshot(self, result.data);
        self.updatedAt = new Date().toISOString(); // TODO: kludge solution
        setStepFromStatus();
      } else {
        setErrors(result.errors);
      }
    });

    return {
      fetchStatus,
      start,
      stop,
      valueStart,
    };
  });

export type TSensorLongTimeCalibration = Instance<
  typeof SensorLongTimeCalibrationModel
>;
