import { useEffect, useState } from "react";
import { StyleSheet } from "react-native";

import {
  MODULE_REBOOTING_TIMEOUT_MS,
  ModuleUpdate,
} from "@models/module/module-update";
import { Text } from "@components/ui";

const styles = StyleSheet.create({
  counter: {
    fontSize: 14,
    textAlign: "center",
  },
});

type Props = {
  update: ModuleUpdate;
  afterReboot?: () => void;
};
export const UpdateCounter = ({ update, afterReboot }: Props) => {
  const [secondsLeft, setSecondsLeft] = useState(
    MODULE_REBOOTING_TIMEOUT_MS / 1000,
  );

  useEffect(() => {
    const tick = setInterval(() => {
      const seconds = update.rebootingLeft;
      if (seconds <= 0) {
        clearInterval(tick);
        afterReboot?.();
      } else {
        setSecondsLeft(seconds);
      }
    }, 1000);
  }, []);

  return (
    <Text style={styles.counter}>
      {secondsLeft} seconds left until reboot is complete
    </Text>
  );
};
