import { View, StyleSheet, ViewStyle } from "react-native";
import { shadow } from "react-native-paper";

import { Button } from "@components/ui";

import { DefaultTheme, spacing } from "../../../../../theme";
import { translate } from "../../../../../i18n";

const styles = StyleSheet.create({
  position: {
    position: "absolute",
    top: 172,
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
  },

  container: {
    backgroundColor: DefaultTheme.colors.background,
    paddingHorizontal: spacing[2],
    borderRadius: spacing[2],
    ...(shadow(5) as any),
  },
});

export enum ButtonManualHintType {
  ManuallyEnable = "ManuallyEnable",
  Shutdown = "Shutdown",
}

type ButtonManualHintProps = {
  type: ButtonManualHintType;
  style?: ViewStyle;
};

export const ButtonManualHint = ({
  type = ButtonManualHintType.ManuallyEnable,
  style,
}: ButtonManualHintProps) => {
  const typeButton =
    type === ButtonManualHintType.ManuallyEnable
      ? "secondary"
      : "secondary_warn";

  const text =
    type === ButtonManualHintType.ManuallyEnable
      ? translate("Devices.Details.manuallyEnable")
      : translate("Devices.Details.shutdown");

  return (
    <View style={[styles.position, style]}>
      <View style={styles.container}>
        <Button type={typeButton}>{text}</Button>
      </View>
    </View>
  );
};
