import { StyleSheet, View } from "react-native";
import { shadow } from "react-native-paper";

import { AutoMode } from "@screens/Devices/components/AutoMode";
import { Device, DeviceMode, DeviceState } from "@models/device/device";
import { BackgroundGradient } from "@components/ui";

import { DefaultTheme, spacing } from "../../../../../theme";
import { Card } from "./Card";

const styles = StyleSheet.create({
  autoMode: {
    borderRadius: spacing[4],
    paddingVertical: spacing[2],
    ...(shadow(5) as any),
  },
  backgroundAutoMode: {
    borderRadius: spacing[4],
  },
  mask: {
    borderRadius: spacing[4],
    position: "absolute",
    minHeight: 92,
    top: 128,
    left: 12,
    right: 12,
    backgroundColor: DefaultTheme.colors.dialogBG,
    justifyContent: "center",
    alignItems: "flex-end",
    paddingRight: spacing[2],
  },
});

const device: Device = {
  uid: "device-auto-mode-hint",
  type: "chiller",
  name: "Chiller",
  state: DeviceState.On,
  mode: DeviceMode.Auto,
};

export const AutoModeCard = () => {
  return (
    <>
      <Card />
      <View style={styles.mask}>
        <View style={styles.autoMode}>
          <BackgroundGradient style={styles.backgroundAutoMode} />
          <AutoMode device={device} />
        </View>
      </View>
    </>
  );
};
