import React, { ReactNode, useState } from "react";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

const CONTENT_STYLE = { flex: 1 };

type Props = {
  children: ReactNode;
};

export const KeyboardAware = ({ children }: Props) => {
  const [scroll, setScroll] = useState(false);
  const [style, setStyle] = useState(CONTENT_STYLE);

  return (
    <KeyboardAwareScrollView
      contentContainerStyle={style}
      scrollEnabled={scroll}
      keyboardShouldPersistTaps="handled"
      extraHeight={15}
      onKeyboardDidHide={() => {
        setScroll(false);
        setStyle(CONTENT_STYLE);
      }}
      onKeyboardDidShow={() => {
        setScroll(true);
        setStyle(undefined);
      }}
      enableAutomaticScroll
    >
      {children}
    </KeyboardAwareScrollView>
  );
};
