import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

export const WarningSvg = (props: SvgProps) => (
  <Svg width={196} height={138} fill="none" viewBox="0 0 196 138" {...props}>
    <Path
      d="M157.578 137.725H36.331c-13.778 0-22.31-15.018-15.242-26.853l59.488-99.624c7.358-12.323 25.198-12.33 32.571-.024l59.656 99.624c7.093 11.835-1.431 26.877-15.226 26.877ZM3.272 72.56h20.175c2.295 0 3.71 2.503 2.535 4.47l-9.9 16.577c-1.223 2.047-4.19 2.055-5.421 0L.737 77.03c-1.184-1.967.24-4.47 2.535-4.47Z"
      fill="#FFF1F1"
    />
    <Path
      d="M63.679 26.515h-12.5c-15.121 0-27.38 12.37-27.38 27.492v-.111c0 15.121 12.259 27.38 27.38 27.38h104.199c12.347 0 22.35 9.709 22.35 22.056v.303c0 12.347-10.003 22.351-22.35 22.351h-41.496"
      stroke="#2A2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M140.952 13.51h24.502c2.783 0 4.51 3.04 3.079 5.43l-12.019 20.128c-1.488 2.487-5.094 2.495-6.582.008l-12.051-20.128c-1.439-2.399.28-5.437 3.071-5.437Z"
      fill="#FFF1F1"
    />
    <Path
      d="M11.58 42.347c6.757 0 12.235 5.477 12.235 12.235"
      stroke="#2A2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M10.203 50.096c-1.471-.728-2.695-1.76-3.766-2.975-1.696-1.92-2.935-4.11-3.935-6.453-.304-.72-.568-1.464-.84-2.2-.135-.367-.04-.575.32-.72 2.911-1.159 5.918-1.934 9.069-2.014 1.551-.04 3.063.152 4.542.631a7.412 7.412 0 0 1 4.846 9.069c-1.08 3.83-5.126 6.133-8.973 5.086-.431-.112-.847-.28-1.263-.424ZM157.855 108.16c6.758 0 12.236 5.478 12.236 12.235"
      stroke="#2A2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M156.471 115.917c-1.472-.728-2.695-1.759-3.767-2.975-1.695-1.919-2.935-4.11-3.934-6.453-.304-.72-.568-1.463-.84-2.199-.136-.368-.04-.576.32-.72 2.911-1.159 5.918-1.935 9.068-2.015 1.552-.04 3.063.152 4.542.632a7.41 7.41 0 0 1 4.846 9.068c-1.079 3.83-5.125 6.134-8.972 5.086-.424-.12-.84-.28-1.263-.424ZM38.641 53.176c-6.757 0-12.235 5.478-12.235 12.235"
      stroke="#2A2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M38.754 61.349c-3.846 1.047-7.893-1.248-8.972-5.086a7.415 7.415 0 0 1 4.846-9.069c1.48-.48 2.999-.671 4.542-.631 3.15.08 6.157.847 9.068 2.015.36.144.456.352.32.72-.272.735-.528 1.479-.84 2.199-1 2.343-2.239 4.534-3.934 6.453-1.072 1.216-2.303 2.247-3.766 2.975-.416.144-.832.304-1.264.424ZM185.239 77.733c0 6.758-5.478 12.235-12.235 12.235"
      stroke="#2A2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M177.482 76.35c.727-1.472 1.759-2.695 2.974-3.767 1.92-1.695 4.111-2.935 6.454-3.934.719-.304 1.463-.568 2.199-.84.368-.136.576-.04.72.32 1.159 2.91 1.935 5.917 2.015 9.068.04 1.552-.152 3.063-.632 4.542a7.411 7.411 0 0 1-9.068 4.846c-3.831-1.08-6.134-5.125-5.086-8.972.12-.424.288-.84.424-1.263ZM162.335 69.177c-6.758 0-12.235 5.477-12.235 12.235"
      stroke="#2A2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M162.455 77.358c-3.846 1.047-7.893-1.248-8.972-5.086a7.414 7.414 0 0 1 4.846-9.069c1.479-.48 2.999-.671 4.542-.632 3.151.08 6.158.848 9.068 2.016.36.144.456.352.32.72-.272.735-.527 1.479-.839 2.198-1 2.344-2.239 4.535-3.935 6.454-1.071 1.215-2.303 2.247-3.766 2.975-.424.144-.84.304-1.264.424ZM57.065 39.46c0-6.757-5.478-12.234-12.235-12.234"
      stroke="#2A2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M48.885 39.58c-1.048-3.846 1.247-7.892 5.085-8.972a7.415 7.415 0 0 1 9.069 4.846c.48 1.48.672 2.999.631 4.542-.08 3.15-.847 6.158-2.015 9.068-.144.36-.352.456-.72.32-.735-.272-1.479-.528-2.199-.84-2.342-.999-4.534-2.238-6.453-3.934-1.215-1.071-2.247-2.303-2.975-3.766-.144-.424-.304-.84-.423-1.264ZM187.79 106.617c0-6.757-5.478-12.235-12.235-12.235"
      stroke="#2A2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M179.609 106.729c-1.047-3.847 1.248-7.893 5.086-8.973a7.415 7.415 0 0 1 9.068 4.846c.48 1.48.672 2.999.632 4.543-.08 3.15-.848 6.157-2.015 9.068-.144.36-.352.456-.72.32-.735-.272-1.479-.528-2.199-.84-2.343-1-4.534-2.239-6.453-3.934-1.216-1.072-2.247-2.303-2.975-3.767-.144-.416-.304-.831-.424-1.263ZM39.055 12.111c-4.782 4.782-4.782 12.531 0 17.305"
      stroke="#2A2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M44.918 17.814c-1.983 3.462-6.47 4.702-9.94 2.75a7.407 7.407 0 0 1-2.983-9.835c.704-1.384 1.64-2.591 2.767-3.663 2.287-2.175 4.95-3.75 7.837-4.99.352-.152.568-.072.736.28.328.712.672 1.416.96 2.143.951 2.36 1.623 4.79 1.783 7.341.104 1.624-.04 3.215-.56 4.774-.208.4-.384.808-.6 1.2Z"
      stroke="#2A2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M96.953 131.471c22.993 0 41.632-18.639 41.632-41.63 0-22.993-18.639-41.632-41.632-41.632-22.992 0-41.63 18.639-41.63 41.631s18.638 41.631 41.63 41.631Z"
      fill="#fff"
      stroke="#2A2E32"
      strokeWidth={2.572}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M96.954 116.461c6.572 0 11.899-5.327 11.899-11.899 0-6.572-5.327-11.899-11.9-11.899-6.57 0-11.898 5.328-11.898 11.899 0 6.572 5.327 11.899 11.899 11.899Z"
      fill="#F76B6B"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M108.853 59.509V40.556c0-6.573-5.326-11.899-11.9-11.899-6.573 0-11.898 5.326-11.898 11.9v33.506c0 6.573 5.325 11.899 11.899 11.899 6.573 0 11.899-5.326 11.899-11.9v-3.214"
      fill="#F76B6B"
    />
    <Path
      d="M108.853 59.509V40.556c0-6.573-5.326-11.899-11.9-11.899-6.573 0-11.898 5.326-11.898 11.9v33.506c0 6.573 5.325 11.899 11.899 11.899 6.573 0 11.899-5.326 11.899-11.9v-3.214"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
  </Svg>
);
