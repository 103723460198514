import React, { useMemo } from "react";
import {
  StyleProp,
  StyleSheet,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";
import { useTheme } from "@react-navigation/native";

import { Text } from "../ui/Text";
import { Label } from "./Label";
import { spacing } from "../../theme";
import { TxKeyPath } from "../../i18n";
import ChevronDownIcon from "../../svgs/ChevronDownIcon";

const createStyles = (theme: any) =>
  StyleSheet.create({
    card: {
      backgroundColor: theme.colors.inputBG,
      borderRadius: spacing[4],
      borderWidth: 1,
      borderColor: theme.colors.inputBorder,
      marginTop: spacing[2],
      marginBottom: spacing[2],
      padding: spacing[3],

      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    cardError: {
      borderColor: theme.colors.error,
    },
    text: {
      color: theme.colors.primary,
      textAlign: "center",
      lineHeight: 24,
    },
    error: {
      fontSize: 12,
      color: theme.colors.error,
      paddingTop: spacing[1],
    },
    valueContainer: {
      flexDirection: "row",
      alignItems: "center",
      flex: 1,
    },
    icon: {
      marginRight: spacing[2],
    },
    value: {
      fontSize: 18,
    },
  });

type CustomSelectInputProps = {
  txLabel?: TxKeyPath;
  value?: string;
  icon?: React.FunctionComponent<any>;
  iconColor?: string;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  labelStyle?: StyleProp<TextStyle>;
  errorText?: string;
};
export const CustomSelectInput: React.VoidFunctionComponent<
  CustomSelectInputProps
> = ({
  txLabel,
  value,
  icon: Icon,
  iconColor,
  onPress,
  style,
  labelStyle,
  errorText,
}) => {
  const theme = useTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);
  const isError = !!errorText;

  return (
    <View style={style}>
      {txLabel ? (
        <Label tx={txLabel} isError={isError} style={labelStyle} />
      ) : null}
      <TouchableOpacity
        style={[styles.card, isError && styles.cardError]}
        onPress={onPress}
      >
        <View style={styles.valueContainer}>
          {Icon ? (
            <View style={styles.icon}>
              <Icon size={24} color={iconColor || theme.colors.text} />
            </View>
          ) : null}
          <Text style={styles.value} numberOfLines={1}>
            {value}
          </Text>
        </View>

        <ChevronDownIcon color={theme.colors.text} />
      </TouchableOpacity>
      {errorText ? <Text style={styles.error}>{errorText}</Text> : null}
    </View>
  );
};
