import { useStores } from "@models/index";
import { useFocusEffect } from "@react-navigation/native";

const DEVICE_INTERVAL_MS = 5 * 1000;
const RULES_INTERVAL_MS = 3 * 1000;

export const useDeviceAndRules = (deviceUid?: string) => {
  const { deviceStore } = useStores();
  const device = deviceStore.getDevice(deviceUid);

  useFocusEffect(() => {
    // device.refetch();
    const dataInterval = device
      ? setInterval(() => {
          device?.fetchData(); // TODO: fetch only device data (state) !!!
        }, DEVICE_INTERVAL_MS)
      : undefined;
    return () => clearInterval(dataInterval);
  });

  useFocusEffect(() => {
    // device.fetchRules({ force: true });
    const dataInterval = device
      ? setInterval(() => {
          if (device?.rule?.state === "Synchronizing") {
            device.fetchRules({ force: true });
          }
        }, RULES_INTERVAL_MS)
      : undefined;
    return () => clearInterval(dataInterval);
  });
};
