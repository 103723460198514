import React, { useEffect, useState } from "react";
import { View, StyleSheet, TextInput } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { observer } from "mobx-react-lite";
import SmartConfig from "react-native-smartconfig-ash";

import { useStores } from "@models/index";
import { ConnectionStatus } from "@models/growdirectorpro/growdirectorpro-store";
import { Screen } from "@components/index";
import { TopBar } from "@components/layout";
import { Button, Text } from "@components/ui";
import { ConfirmDialog, ConfirmType } from "@components/dialog";

import { translate } from "../../../../i18n";
import { DefaultTheme, spacing } from "../../../../theme";
import { ModuleConnecting } from "../../../../svgs/pictures";
import {
  DetectingProgress,
  TFormDataWifiInput,
  WiFiInput,
} from "../components";

import {
  connectModuleToServer,
  connectModuleToWiFi,
  wifiStatusOk,
} from "../../../../services/connectModuleToServer";

const BSSID = "d4:6e:0e:50:db:90";
const WAITING_NEW_MS = 15 * 1000;
const WAITING_WIFI = 15 * 1000;

const PROCESS_TITLE_TX = {
  [ConnectionStatus.Detecting]: "Modules.Add.searchingModule",
  [ConnectionStatus.Connecting]: "Modules.Add.connectingModule",
  [ConnectionStatus.Waiting]: "Modules.Add.waitingModule",
};

const styles = StyleSheet.create({
  screen: {
    paddingHorizontal: spacing[4],
    justifyContent: "center",
  },
  inputs: {
    marginBottom: spacing[3],
  },
  image: {
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    marginVertical: spacing[3],
  },
  errorText: {
    color: DefaultTheme.colors.error,
    marginBottom: spacing[3],
  },
});

export const ModuleAddSmartScreen = observer(() => {
  const navigation = useNavigation() as any;
  const { accountStore, growDirectorProStore, moduleStore, settingsStore } =
    useStores();

  const [ssid, setSsid] = useState("");
  const [password, setPassword] = useState("");
  const [isShowWarning, setIsShowWarning] = useState(false);

  const [showLogs, setShowLogs] = useState(false);
  const toggleLogs = () => setShowLogs((prevValue) => !prevValue);

  useEffect(() => {
    growDirectorProStore.resetResult();
  }, []);

  useEffect(() => {
    if (growDirectorProStore.connectionStatus === ConnectionStatus.Success) {
      navigation.navigate("module-add-successfully-connected", {
        uid: growDirectorProStore.uid,
        type: "smart",
      });
      growDirectorProStore.resetResult();
    }
  }, [growDirectorProStore.connectionStatus]);

  const loopUntilWifiStatusOk = async (ip: string) => {
    const startTime = Date.now();

    const timerId = setInterval(async () => {
      if (Date.now() - startTime > WAITING_WIFI) {
        clearInterval(timerId);

        growDirectorProStore.setLog(`Error on connectModuleToWiFi...`);
        growDirectorProStore.setConnectionStatus(ConnectionStatus.Error);
        return;
      }

      const wifiStatusIsOk = await wifiStatusOk(ip);
      growDirectorProStore.setLog(`wifiStatus: ${wifiStatusIsOk}`);

      if (wifiStatusIsOk) {
        clearInterval(timerId);
        connect(ip);
      }
    }, 2000);
  };

  const waitingNewModule = () => {
    growDirectorProStore.setConnectionStatus(ConnectionStatus.Waiting);
    growDirectorProStore.setLog(
      `Waiting module ${growDirectorProStore.uid}...`,
    );
    const startTime = Date.now();

    const timerId = setInterval(async () => {
      growDirectorProStore.setLog(`Fetch module...`);
      const module = await moduleStore.fetchModule(growDirectorProStore.uid);

      if (module) {
        clearInterval(timerId);
        growDirectorProStore.setLog(
          `Module ${growDirectorProStore.uid} was added`,
        );
        growDirectorProStore.setConnectionStatus(ConnectionStatus.Success);
      }

      if (Date.now() - startTime > WAITING_NEW_MS) {
        clearInterval(timerId);
        growDirectorProStore.setConnectionStatus(ConnectionStatus.Error);
        growDirectorProStore.setLog("Timeout for new module has expired");
      }
    }, 2000);
  };

  const growDirectorAdd = async () => {
    const addResult = await growDirectorProStore.addModule(
      growDirectorProStore.token,
      growDirectorProStore.uid,
    );
    growDirectorProStore.setLog(`Add result: ${JSON.stringify(addResult)}`);

    const list = await growDirectorProStore.getModuleList();
    growDirectorProStore.setLog(`List 2: ${JSON.stringify(list)}`);

    const module = await growDirectorProStore.getModule(
      growDirectorProStore.uid,
    );
    growDirectorProStore.setLog(`Module: ${JSON.stringify(module)}`);

    setTimeout(waitingNewModule, 5000); // TODO: !!! ?
  };

  const loopUntilModuleList = async () => {
    const list = await growDirectorProStore.getModuleList();
    growDirectorProStore.setLog(`List: ${JSON.stringify(list)}`);

    // if (list === undefined) {
    //   setTimeout(loopUntilModuleList, 2000);
    // } else {
    //   await growDirectorAdd();
    // }
    await growDirectorAdd();
  };

  const preConnect = async (ip: string) => {
    growDirectorProStore.setLog(`Connecting module to wifi...`);
    try {
      const wifiResult = await connectModuleToWiFi(
        ip,
        ssid,
        password,
        growDirectorProStore.setLog,
      );
      growDirectorProStore.setLog(
        `Connecting result: ${JSON.stringify(wifiResult)}`,
      );
      loopUntilWifiStatusOk(ip); // -> connect(ip)
    } catch (e) {
      growDirectorProStore.setLog(`Error catch: ${JSON.stringify(e)}`);
      growDirectorProStore.setConnectionStatus(ConnectionStatus.Error);
    }
  };

  const connect = async (ip: string) => {
    const token = await growDirectorProStore.generateToken();
    const user = accountStore.currentUser.email;
    const { accountUid } = accountStore.currentUser;
    const serverUrl = settingsStore.DEV_serverUrl;

    growDirectorProStore.setLog(`Connecting to server: ${user} - ${token}`);
    const result = await connectModuleToServer(ip, {
      ssid,
      password,
      accountUid,
      user,
      token,
      server: serverUrl,
    });
    growDirectorProStore.setLog(`Board logs: ${JSON.stringify(result.logs)}`);

    if (result.ok) {
      growDirectorProStore.setLog(`Module initialized: ${result.uid}`);
      growDirectorProStore.setUid(result.uid);
      await loopUntilModuleList();
    } else {
      growDirectorProStore.setLog(`Error on connectModuleToServer...`);
      growDirectorProStore.setConnectionStatus(ConnectionStatus.Error);
    }
  };

  const searchHandler = () => {
    growDirectorProStore.resetResult();
    growDirectorProStore.setConnectionStatus(ConnectionStatus.Detecting);
    growDirectorProStore.setLog(`Detecting module... ${ssid} - ${password}`);

    try {
      SmartConfig.start(
        ssid,
        BSSID,
        password,
        growDirectorProStore.searchTimeOut,
        (event) => {
          const { eventName, data } = event;
          if (eventName === "onFoundDevice") {
            const parsedData = JSON.parse(data);
            growDirectorProStore.setLog(
              `Found device: ${parsedData.ip} - ${parsedData.bssid}`,
            );
            growDirectorProStore.setIP(parsedData.ip);
            growDirectorProStore.setConnectionStatus(
              ConnectionStatus.Connecting,
            );

            preConnect(parsedData.ip);
            // connect(parsedData.ip);

            // growDirectorProStore.connect(parsedData.ip, ssid, password);
          } else if (!growDirectorProStore.ip) {
            growDirectorProStore.setLog("Not found");
            growDirectorProStore.setConnectionStatus(ConnectionStatus.Error);
          }
        },
      );
    } catch (e) {
      growDirectorProStore.setLog(JSON.stringify(e));
      growDirectorProStore.setConnectionStatus(ConnectionStatus.Error);
    }
  };

  const backHandler = () => {
    if (growDirectorProStore.connectionStatus === ConnectionStatus.Detecting) {
      setIsShowWarning(true);
    } else {
      navigation.goBack();
    }
  };

  const abortHandler = () => {
    growDirectorProStore.abortSearching();
    navigation.goBack();
  };

  const setSettingWifi = (formData: TFormDataWifiInput) => {
    setSsid(formData.ssid);
    setPassword(formData.password);
    // TODO!!!  implement formData.isSave
  };

  return (
    <Screen
      style={styles.screen}
      preset="fixed"
      header={
        <TopBar
          title={translate("Modules.Add.Smart.title")}
          back
          backHandler={backHandler}
        />
      }
    >
      {showLogs && (
        <View>
          {Boolean(growDirectorProStore.logs?.length) && (
            <TextInput
              style={{ height: 250 }}
              multiline
              numberOfLines={4}
              value={growDirectorProStore.logs.join("\n")}
            />
          )}
        </View>
      )}

      {[
        ConnectionStatus.Detecting,
        ConnectionStatus.Connecting,
        ConnectionStatus.Waiting,
      ].includes(growDirectorProStore.connectionStatus) ? (
        <>
          <DetectingProgress
            title={translate(
              PROCESS_TITLE_TX[growDirectorProStore.connectionStatus],
            )}
            timeMs={growDirectorProStore.searchTimeOut}
          />
          <Button
            type="link"
            tx="Modules.Add.Smart.toggleLogs"
            onPress={toggleLogs}
          >
            Show/hide logs
          </Button>
        </>
      ) : (
        <View>
          {!showLogs && (
            <View style={styles.image}>
              <ModuleConnecting />
            </View>
          )}

          <WiFiInput
            ssid={ssid}
            password={password}
            onSubmit={setSettingWifi}
            style={styles.inputs}
          />
          {growDirectorProStore.connectionStatus === ConnectionStatus.Error && (
            <Text
              style={styles.errorText}
              tx="Modules.Add.Smart.errorDescription"
            />
          )}
          <Button
            onPress={searchHandler}
            type="primary"
            tx="Modules.Add.Smart.connect"
          >
            Connect
          </Button>

          {growDirectorProStore.connectionStatus !== ConnectionStatus.New && (
            <Button
              type="link"
              tx="Modules.Add.Smart.toggleLogs"
              onPress={toggleLogs}
            >
              Show/hide logs
            </Button>
          )}
        </View>
      )}

      <ConfirmDialog
        content={translate("Modules.Add.Smart.abortProcessDescription")}
        okText={translate("Modules.Add.Smart.abortProcess")}
        type={ConfirmType.Warning}
        onOk={abortHandler}
        onCancel={() => setIsShowWarning(false)}
        visible={isShowWarning}
      />
    </Screen>
  );
});
