import * as React from "react";
import Svg, {
  SvgProps,
  G,
  Path,
  Circle,
  Defs,
  ClipPath,
} from "react-native-svg";

export const CalibrationCleanDOSvg = (props: SvgProps) => (
  <Svg width={286} height={172} fill="none" {...props}>
    <G clipPath="url(#a)">
      <Path
        fill="#EDF1F5"
        d="m203.729 138.745 30.217 7.379c2.541.621 4.737-1.024 5.345-3.568l-.058-.014c.608-2.544-.545-5.006-3.086-5.627l-76.537-18.69 10.183-22.006a14.516 14.516 0 0 0 1.005-9.19l-1.554-7.142a14.505 14.505 0 0 1 .769-8.645l9.382-22.673 73.576 17.968a4.761 4.761 0 0 1 3.498 5.727 4.704 4.704 0 0 1-5.699 3.481l-30.217-7.379-1.552 6.492a17.26 17.26 0 0 1-7.788 10.724l-4.048 2.473a17.261 17.261 0 0 0-7.789 10.725 17.254 17.254 0 0 1-7.788 10.724l-2.515 1.537a17.28 17.28 0 0 0-7.692 10.341l-2.942 11.178 55.559 13.568a4.346 4.346 0 0 0 5.255-3.196l17.218-70.676"
      />
      <Path
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="m203.729 138.745 30.217 7.379c2.541.621 4.737-1.024 5.345-3.568l-.058-.014c.608-2.544-.545-5.006-3.086-5.627l-76.537-18.69 10.183-22.006a14.516 14.516 0 0 0 1.005-9.19l-1.554-7.142a14.505 14.505 0 0 1 .769-8.645l9.382-22.673 73.576 17.968a4.761 4.761 0 0 1 3.498 5.727 4.704 4.704 0 0 1-5.699 3.481l-30.217-7.379-1.552 6.492a17.26 17.26 0 0 1-7.788 10.724l-4.048 2.473a17.261 17.261 0 0 0-7.789 10.725 17.254 17.254 0 0 1-7.788 10.724l-2.515 1.537a17.28 17.28 0 0 0-7.692 10.341l-2.942 11.178 55.559 13.568a4.346 4.346 0 0 0 5.255-3.196l17.218-70.676"
      />
      <Path
        fill="#B6C5D3"
        d="M71.385 142.451c15.677 0 28.385-3.858 28.385-8.617s-12.708-8.616-28.385-8.616c-15.677 0-28.385 3.857-28.385 8.616 0 4.759 12.708 8.617 28.385 8.617Z"
      />
      <Path
        fill="#6A7B8C"
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="M77.615 50.82h-5.201a2.928 2.928 0 0 1-2.925-2.93V34.413c0-1.617 1.31-2.93 2.924-2.93h5.202a2.928 2.928 0 0 1 2.925 2.93v13.473a2.93 2.93 0 0 1-2.925 2.935ZM78.468 31.482l-2.35-8.288H73.91l-2.35 8.288 3.453-.107 3.454.107Z"
      />
      <Path
        stroke="#2A2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="M75.014 23.193v-1.096C75.014 14.312 68.67 8 60.844 8c-7.83 0-14.175 6.312-14.175 14.097v25.676c0 7.786-6.345 14.097-14.171 14.097H31.17C23.345 61.87 17 55.56 17 47.773"
      />
      <Path
        fill="#6A7B8C"
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="M78.468 50.82H71.56v45.583h6.907V50.82Z"
      />
      <Path
        fill="#6A7B8C"
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="M79.394 104h-8.759c-.63 0-1.146-.508-1.146-1.14v-5.317c0-.627.51-1.14 1.146-1.14h8.759a1.14 1.14 0 0 1 1.146 1.14v5.317c0 .627-.516 1.14-1.146 1.14Z"
      />
      <Path
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="M71.561 92.95h6.907"
      />
      <Path
        stroke="#2B2E32"
        strokeDasharray="4 4"
        d="M64.433 55.98c-4.542 1.18-7.5 3.106-7.5 5.28 0 3.59 8.059 6.5 18 6.5s18-2.91 18-6.5c0-2.174-2.958-4.1-7.5-5.28"
      />
      <Path
        fill="#6A7B8C"
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="M224.615 50.82h-5.202a2.928 2.928 0 0 1-2.924-2.93V34.413c0-1.617 1.31-2.93 2.924-2.93h5.202a2.929 2.929 0 0 1 2.925 2.93v13.473a2.93 2.93 0 0 1-2.925 2.935ZM225.468 31.482l-2.35-8.288h-2.207l-2.35 8.288 3.453-.107 3.454.107Z"
      />
      <Path
        stroke="#2A2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="M222.014 23.193v-1.096c0-7.785-6.345-14.097-14.171-14.097-7.829 0-14.174 6.312-14.174 14.097v25.676c0 7.786-6.345 14.097-14.171 14.097h-1.327C170.345 61.87 164 55.56 164 47.773"
      />
      <Path
        fill="#6A7B8C"
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="M225.468 50.82h-6.907v45.583h6.907V50.82Z"
      />
      <Path
        fill="#6A7B8C"
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="M226.394 104h-8.759c-.63 0-1.146-.508-1.146-1.14v-5.317c0-.627.511-1.14 1.146-1.14h8.759a1.14 1.14 0 0 1 1.146 1.14v5.317c0 .627-.516 1.14-1.146 1.14Z"
      />
      <Path
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="M218.561 92.95h6.907"
      />
      <Path
        fill="#70B8FF"
        fillOpacity={0.4}
        d="M74.933 98.86c13.717 0 24.837-3.404 24.837-7.603 0-4.2-11.12-7.603-24.837-7.603s-24.837 3.404-24.837 7.603 11.12 7.603 24.837 7.603Z"
      />
      <Path
        fill="#308AE3"
        fillRule="evenodd"
        d="M99.77 91.257c0 4.2-11.12 7.603-24.837 7.603-13.71 0-24.825-3.4-24.837-7.596V131.626c0 4.294 11.125 7.784 24.837 7.784 13.713 0 24.837-3.49 24.837-7.784v-41.89l-.49.01c.321.489.49.994.49 1.511Zm-49.674-.007c0-.16.017-.32.05-.478h-.05v.478Z"
        clipRule="evenodd"
        opacity={0.6}
      />
      <Path
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.196}
        d="M99.77 69.461v62.17c0 4.291-11.149 7.779-24.891 7.779-13.743 0-24.892-3.488-24.892-7.779V80.728a6.762 6.762 0 0 0-2.07-4.873L44.014 71.7"
      />
      <Path
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.196}
        d="M71.5 62.452c-8.69.449-14.665 2.545-19.045 4.498-1.691.75-5.335 2.577-7.921 3.883-.858.436-.597 1.682.36 1.766l4.987.46c1.753.266 3.457.738 5.086 1.415 4.514 1.876 11.788 3.097 19.983 3.097 13.715 0 24.82-3.411 24.82-7.609.011-3.804-9.12-6.953-21.041-7.51"
      />
      <Path
        fill="#fff"
        d="m75.103 104.651.093.094a49.718 49.718 0 0 1 2.38 2.818 38.844 38.844 0 0 1 2.287 3.196 29.753 29.753 0 0 1 1.822 3.256c.418.876.778 1.788 1.056 2.711.186.628.337 1.267.442 1.918.116.722.162 1.456.116 2.202-.058 1.089-.302 2.131-.778 3.102a7.012 7.012 0 0 1-2.357 2.699c-.766.533-1.602.9-2.496 1.148-.43.119-.859.201-1.288.261a8.61 8.61 0 0 1-1.742.071 9.27 9.27 0 0 1-3.506-.853 7.634 7.634 0 0 1-2.368-1.728c-.79-.864-1.323-1.882-1.625-3.031a9.282 9.282 0 0 1-.22-1.267 9.205 9.205 0 0 1-.035-1.385c.034-.71.139-1.42.29-2.119.267-1.184.673-2.32 1.172-3.421a26.875 26.875 0 0 1 1.777-3.279 40 40 0 0 1 2.391-3.386 50.073 50.073 0 0 1 2.484-2.948c.047 0 .07-.024.105-.059Z"
      />
      <Path
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.196}
        d="M93.888 82.333v31.714M93.888 118.746v10.571"
      />
      <Circle
        cx={76}
        cy={160}
        r={11}
        fill="#B6C5D3"
        stroke="#fff"
        strokeWidth={2}
      />
      <Path
        fill="#fff"
        d="M77.374 166h-1.68v-8.32a131.533 131.533 0 0 1 .064-1.312 7.568 7.568 0 0 1-.432.4c-.15.128-.32.277-.512.448l-1.248 1.008-.864-1.088 3.264-2.56h1.408V166Z"
      />
      <Circle
        cx={210}
        cy={160}
        r={11}
        fill="#B6C5D3"
        stroke="#fff"
        strokeWidth={2}
      />
      <Path
        fill="#fff"
        d="M213.838 166h-7.68v-1.312l2.944-2.992a49.412 49.412 0 0 0 1.44-1.52c.384-.437.672-.853.864-1.248.192-.405.288-.843.288-1.312 0-.587-.171-1.024-.512-1.312-.341-.299-.784-.448-1.328-.448-.523 0-.992.101-1.408.304a6.609 6.609 0 0 0-1.296.816l-.912-1.136c.309-.267.64-.507.992-.72a5.31 5.31 0 0 1 1.2-.512 5.346 5.346 0 0 1 1.472-.192c.725 0 1.349.128 1.872.384.523.245.923.597 1.2 1.056.288.459.432.997.432 1.616 0 .459-.069.891-.208 1.296a4.89 4.89 0 0 1-.608 1.168 8.942 8.942 0 0 1-.944 1.168 42.9 42.9 0 0 1-1.232 1.248l-2.128 2.064v.08h5.552V166Z"
      />
      <Path
        fill="#EDF1F5"
        d="M252.83 66.297a4.813 4.813 0 0 1 3.515 5.754 4.657 4.657 0 0 1-5.676 3.467l-30.195-7.513-1.523 6.5a17.117 17.117 0 0 1-7.734 10.72l-4.031 2.462a17.117 17.117 0 0 0-7.734 10.719 17.117 17.117 0 0 1-7.733 10.719l-2.505 1.53a17.14 17.14 0 0 0-7.639 10.335l-2.893 11.192 55.517 13.816a4.302 4.302 0 0 0 5.234-3.183l16.914-70.772"
      />
      <Path
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="M252.83 66.297a4.813 4.813 0 0 1 3.515 5.754 4.657 4.657 0 0 1-5.676 3.467l-30.195-7.513-1.523 6.5a17.117 17.117 0 0 1-7.734 10.72l-4.031 2.462a17.117 17.117 0 0 0-7.734 10.719 17.117 17.117 0 0 1-7.733 10.719l-2.505 1.53a17.14 17.14 0 0 0-7.639 10.335l-2.893 11.192 55.517 13.816a4.302 4.302 0 0 0 5.234-3.183l16.914-70.772"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h286v172H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);
