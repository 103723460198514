import { StyleSheet, View } from "react-native";
import { shadow } from "react-native-paper";

import { TabItem, Tabs } from "@components/ui";

import { translate } from "../../../../../i18n";

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    borderRadius: 20,
    top: 344,
    left: 16,
    right: 16,
    ...(shadow(5) as any),
  },
});

export const ModuleHydroTabsHint = () => {
  const tabsItems: TabItem[] = [
    {
      key: "pumps",
      name: translate("Modules.Details.pumps"),
    },
    {
      key: "sensors",
      name: translate("Modules.Details.sensors"),
    },
  ];

  return (
    <View style={styles.container}>
      <Tabs items={tabsItems} currentTab="pumps" withBG />
    </View>
  );
};
