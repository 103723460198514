import { StyleSheet, View } from "react-native";
import { shadow } from "react-native-paper";

import {
  ModuleDeviceCard,
  ModuleSensorCard,
} from "@screens/Modules/Details/components/Items";
import { ModuleDevice, ModuleSensor } from "@models/module/module";
import { DeviceMode } from "@models/device/device";

import { DefaultTheme, spacing } from "../../../../../theme";

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    backgroundColor: DefaultTheme.colors.cardBG,
    borderRadius: spacing[4],
    height: 90,
    left: 16,
    right: 16,
    ...(shadow(5) as any),
  },
});

const pump = {
  iconName: "DosingPumpIcon",
  typeName: "Dosing pump",
  displayName: "Pump 1",
  isConnected: true,
  socketName: "Soket 1",
  // mode: DeviceMode.Auto,
} as ModuleDevice;

const device = {
  iconName: "HumidifierIcon",
  typeName: "Humidifier",
  displayName: "Device 1",
  socketName: "Socket 1",
  isConnected: true,
  // mode: DeviceMode.Auto,
} as ModuleDevice;

const sensor = {
  iconName: "VPDIcon",
  typeName: "VPD",
  displayName: "Sensor 1",
  pinName: "Pin 1",
  isConnected: true,
} as ModuleSensor;

export const ModulePumpCardHint = () => {
  const top = 398;

  return (
    <View style={[styles.container, { top }]}>
      <ModuleDeviceCard device={pump} />
    </View>
  );
};

export const ModuleDeviceCardHint = () => {
  const top = 376;

  return (
    <View style={[styles.container, { top }]}>
      <ModuleDeviceCard device={device} />
    </View>
  );
};

export const ModuleSensorCardHint = () => {
  const top = 376;

  return (
    <View style={[styles.container, { top }]}>
      <ModuleSensorCard moduleSensor={sensor} />
    </View>
  );
};
