import { StyleSheet, View } from "react-native";
import { shadow } from "react-native-paper";

import { DateTime } from "./svgs/DateTime";

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    top: 56,
    left: 8,
    ...(shadow(5) as any),
  },
});

export const DateTimePoints = () => (
  <View style={styles.container}>
    <DateTime />
  </View>
);
