import * as React from "react";
import { View, ViewStyle, StyleSheet, StyleProp } from "react-native";
import { spacing } from "../../theme";

const styles = StyleSheet.create({
  container: {
    width: "100%",
    marginVertical: spacing[4],
  },
  inputs: {
    marginBottom: 16,
  },
  links: {
    width: "100%",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
});

type Props = React.ComponentProps<typeof View> & {
  style?: StyleProp<ViewStyle>;
  children: React.ReactNode;
};

function Container(props: Props) {
  const { style } = props;
  return <View {...props} style={[styles.container, style]} />;
}

function Inputs(props: Props) {
  const { style } = props;
  return <View {...props} style={[styles.inputs, style]} />;
}

function Links(props: Props) {
  const { style } = props;
  return <View {...props} style={[styles.links, style]} />;
}

export default { Container, Inputs, Links };
