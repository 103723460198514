import * as React from "react";
import { View } from "react-native";
import Svg, { G, Rect, Path, SvgProps } from "react-native-svg";

import { spacing } from "../../../../theme";

export const CalibrationPrepareSoilMoistureSvg = (props: SvgProps) => (
  <View style={{ marginTop: -spacing[8] }}>
    <Svg width={360} height={312} viewBox="0 0 360 312" fill="none" {...props}>
      <G clipPath="url(#clip0_306_694)">
        <Path
          d="M174 165V195.5"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M174 200V210"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M152 224C167.464 224 180 220.194 180 215.5C180 210.806 167.464 207 152 207C136.536 207 124 210.806 124 215.5C124 220.194 136.536 224 152 224Z"
          fill="#B6C5D3"
        />
        <Path
          d="M155.5 181C169.031 181 180 177.642 180 173.5C180 169.358 169.031 166 155.5 166C141.969 166 131 169.358 131 173.5C131 177.642 141.969 181 155.5 181Z"
          fill="#70B8FF"
          fillOpacity={0.4}
        />
        <Path
          opacity={0.6}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M180 173.504C179.993 177.644 169.026 181 155.5 181C141.969 181 131 177.642 131 173.5C131 173.339 131.017 173.18 131.049 173.022L131 173.023V173.5V206.998V213.322C131 217.558 141.973 221 155.5 221C169.027 221 180 217.558 180 213.322V204.313V173.504ZM180 173.496V172L179.517 172.01C179.833 172.49 179.999 172.987 180 173.496Z"
          fill="#308AE3"
        />
        <Path
          d="M180 152V204.323V213.327C180 217.56 169.002 221 155.446 221C141.89 221 130.892 217.56 130.892 213.327V207.007V163.114C130.892 161.296 130.154 159.564 128.85 158.307L125 154.208"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M155.516 145C145.066 145 138.196 147.351 133.327 149.523C131.659 150.263 128.065 152.064 125.513 153.353C124.667 153.783 124.924 155.012 125.869 155.095L130.788 155.549C132.517 155.811 134.198 156.277 135.805 156.945C140.257 158.795 147.433 160 155.516 160C169.046 160 180 156.635 180 152.494C180.012 148.353 169.046 145 155.516 145Z"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M50.5 173L131.5 173"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M180.5 173H315"
          stroke="#2B2E32"
          strokeWidth={2.1962}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M155.464 188C155.5 188.036 155.524 188.06 155.56 188.096C156.407 189.015 157.231 189.958 158.007 190.937C158.843 191.975 159.631 193.038 160.359 194.16C161.051 195.223 161.684 196.309 162.233 197.443C162.663 198.327 163.033 199.246 163.32 200.177C163.511 200.81 163.666 201.454 163.773 202.111C163.893 202.839 163.94 203.579 163.893 204.331C163.833 205.43 163.582 206.48 163.093 207.459C162.52 208.581 161.708 209.489 160.669 210.181C159.881 210.718 159.022 211.088 158.103 211.339C157.661 211.459 157.219 211.542 156.777 211.602C156.18 211.685 155.584 211.709 154.987 211.673C153.733 211.614 152.527 211.339 151.381 210.814C150.462 210.384 149.638 209.811 148.946 209.071C148.134 208.199 147.585 207.173 147.275 206.015C147.167 205.597 147.096 205.167 147.048 204.737C147 204.272 146.988 203.806 147.012 203.341C147.048 202.624 147.155 201.908 147.31 201.204C147.585 200.01 148.003 198.864 148.516 197.754C149.041 196.607 149.662 195.509 150.343 194.447C151.095 193.265 151.93 192.131 152.802 191.032C153.614 190.006 154.473 189.015 155.357 188.06C155.404 188.06 155.428 188.036 155.464 188Z"
          fill="white"
        />
        <Path
          d="M286.048 188.25V218.75"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M276.548 188.25V218.75"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M280.78 188.25V218.75"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M267.048 188.25V218.75"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M276.221 141.674V139.623C276.221 125.058 288.029 113.25 302.594 113.25C317.158 113.25 328.967 125.058 328.967 139.623V187.658C328.967 202.222 340.775 214.031 355.34 214.031H357.809C372.374 214.031 384.182 202.222 384.182 187.658V180.925C384.182 166.361 395.99 154.552 410.555 154.552H433"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Rect
          x={262.548}
          y={153.25}
          width={28}
          height={35}
          rx={3}
          fill="#6A7B8C"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M272.048 145.25C272.048 143.593 273.391 142.25 275.048 142.25H278.048C279.705 142.25 281.048 143.593 281.048 145.25V153.25H272.048V145.25Z"
          fill="#6A7B8C"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M286.031 158.127V172.796"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M286.031 177.296V182.297"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
      </G>
    </Svg>
  </View>
);
