import { useState } from "react";
import {
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  View,
} from "react-native";
import { observer } from "mobx-react-lite";
import { useTheme } from "@react-navigation/native";

import { Screen } from "@components/index";
import { TopBar } from "@components/layout";
import {
  Checkbox,
  ErrorsMessage,
  FloatingButton,
  MarkdownContent,
  Text,
} from "@components/ui";

import { spacing } from "../../theme";
import { translate } from "../../i18n";
import { getPageDataById } from "./PagesData";

const createStyles = (theme: any) =>
  StyleSheet.create({
    screen: {
      paddingHorizontal: spacing[4],
    },
    container: {
      marginRight: -spacing[2],
      paddingRight: spacing[2],
    },
    screenWithButton: {
      paddingBottom: Platform.OS === "ios" ? 126 : 86,
    },
    title: {
      ...theme.fonts.bold,
      textAlign: "center",
      fontSize: 18,
    },
    image: {
      flex: 1,
      alignItems: "center",
    },
    checkbox: {
      flexDirection: "row",
      alignItems: "center",
    },
  });

type PageProps = {
  route?: any;
  id?: string;
  okText?: string;
  onOk?: () => void;
  checkboxText?: string;
};

export const Page = observer(
  ({ route, id, okText, onOk, checkboxText }: PageProps) => {
    const theme = useTheme() as any;
    const styles = createStyles(theme);

    const [isChecked, onChecked] = useState<boolean>(false);
    const onPressCheckbox = () => onChecked((prevValue) => !prevValue);

    const pageId = id || route?.params?.id;
    const page = getPageDataById(pageId);
    const Image = page?.image ? page.image : null;
    const buttonText = okText || translate("common.ok");

    const isCheckbox = checkboxText?.length;
    const buttonDisabled = isCheckbox && !isChecked;

    if (page) {
      return (
        <Screen
          header={<TopBar title={page.title} back />}
          preset="fixed"
          style={[styles.screen, onOk && styles.screenWithButton]}
        >
          <ScrollView style={styles.container}>
            {Image ? (
              <View style={styles.image}>
                <Image />
              </View>
            ) : null}

            {page.heading ? (
              <Text style={styles.title}>{page.heading}</Text>
            ) : null}

            <MarkdownContent>{page.content}</MarkdownContent>

            {isCheckbox ? (
              <Pressable style={styles.checkbox} onPress={onPressCheckbox}>
                <Checkbox
                  status={isChecked ? "checked" : "unchecked"}
                  onPress={onPressCheckbox}
                />
                <Text>{checkboxText}</Text>
              </Pressable>
            ) : null}
          </ScrollView>

          {onOk ? (
            <FloatingButton onPress={onOk} disabled={buttonDisabled}>
              {buttonText}
            </FloatingButton>
          ) : null}
        </Screen>
      );
    }

    return (
      <Screen
        header={<TopBar title="" back />}
        preset="fixed"
        style={styles.screen}
      >
        <ErrorsMessage errors={[translate("errors.not-found")]} />
      </Screen>
    );
  },
);
