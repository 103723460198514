import * as React from "react";
import Svg, { SvgProps, Path, Circle, Rect } from "react-native-svg";

export const CalibrationDOLiquidSvg = (props: SvgProps) => (
  <Svg width={286} height={172} fill="none" {...props}>
    <Path
      fill="#B6C5D3"
      d="M71.602 142.965c15.604 0 28.254-3.84 28.254-8.577s-12.65-8.577-28.254-8.577c-15.605 0-28.255 3.84-28.255 8.577s12.65 8.577 28.255 8.577Z"
    />
    <Path
      fill="#6A7B8C"
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.261}
      d="M77.803 51.756h-5.178a2.915 2.915 0 0 1-2.91-2.916V35.423a2.915 2.915 0 0 1 2.91-2.916h5.178a2.915 2.915 0 0 1 2.911 2.916v13.411a2.916 2.916 0 0 1-2.91 2.922ZM78.652 32.506l-2.34-8.25h-2.196l-2.34 8.25 3.438-.106 3.438.106Z"
    />
    <Path
      stroke="#2A2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.261}
      d="M75.215 23.994v-.984c0-6.988-5.725-12.654-12.785-12.654-7.064 0-12.788 5.666-12.788 12.654V46.06c0 6.988-5.725 12.654-12.785 12.654H35.66c-7.06 0-12.785-5.666-12.785-12.654"
    />
    <Path
      fill="#6A7B8C"
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.261}
      d="M78.652 51.756h-6.875v45.373h6.875V51.756Z"
    />
    <Path
      fill="#6A7B8C"
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.261}
      d="M79.574 104.691h-8.719c-.627 0-1.14-.506-1.14-1.135v-5.293c0-.624.508-1.135 1.14-1.135h8.719c.632 0 1.14.506 1.14 1.135v5.293c0 .624-.513 1.135-1.14 1.135Z"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.261}
      d="M71.777 93.691h6.875"
    />
    <Path
      fill="#70B8FF"
      fillOpacity={0.4}
      d="M75.133 99.574c13.654 0 24.722-3.388 24.722-7.568s-11.068-7.568-24.722-7.568c-13.654 0-24.723 3.388-24.723 7.568s11.069 7.568 24.723 7.568Z"
    />
    <Path
      fill="#308AE3"
      fillRule="evenodd"
      d="M99.856 92.006c0 4.18-11.069 7.568-24.723 7.568-13.634 0-24.69-3.378-24.722-7.55v40.166c0 4.274 11.073 7.748 24.722 7.748 13.65 0 24.723-3.474 24.723-7.748V90.493l-.488.01c.32.486.488.989.488 1.503Zm-49.445-.018c0-.156.017-.31.049-.465l-.05.002v.463Z"
      clipRule="evenodd"
      opacity={0.6}
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M99.856 70.31v61.885c0 4.271-11.097 7.743-24.777 7.743-13.68 0-24.777-3.472-24.777-7.743V81.526a6.731 6.731 0 0 0-2.06-4.85l-3.886-4.137"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M71.716 63.334c-8.65.446-14.598 2.533-18.957 4.477-1.684.747-5.31 2.565-7.885 3.865-.854.434-.594 1.674.359 1.758l4.964.458c1.745.265 3.44.735 5.062 1.409 4.493 1.866 11.734 3.083 19.891 3.083 13.653 0 24.706-3.396 24.706-7.575.011-3.787-9.078-6.92-20.945-7.475M94 83.124v31.568M94 119.369v10.523"
    />
    <Path
      stroke="#fff"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M68.303 129.489a44.727 44.727 0 0 1-5.048-1.004v-15.326c1.754 0 3.356.183 5.048.42 2.38.334 4.32 2.328 4.306 4.727v7.223c0 2.413-1.94 4.295-4.306 3.96ZM82.389 129.381c-.261.041-.523.082-.798.109-2.38.328-4.319-1.518-4.305-3.87v-7.657c0-2.352 1.94-4.308 4.305-4.636.262-.041.537-.068.798-.109 2.366-.369 4.25 1.039 4.25 3.391v7.658c.014 2.338-1.884 4.745-4.25 5.114Z"
    />
    <Circle
      cx={76}
      cy={160}
      r={11}
      fill="#B6C5D3"
      stroke="#fff"
      strokeWidth={2}
    />
    <Path
      fill="#fff"
      d="M77.374 166h-1.68v-8.32a131.533 131.533 0 0 1 .064-1.312 7.568 7.568 0 0 1-.432.4c-.15.128-.32.277-.512.448l-1.248 1.008-.864-1.088 3.264-2.56h1.408V166Z"
    />
    <Circle
      cx={210}
      cy={160}
      r={11}
      fill="#B6C5D3"
      stroke="#fff"
      strokeWidth={2}
    />
    <Path
      fill="#fff"
      d="M213.838 166h-7.68v-1.312l2.944-2.992a49.412 49.412 0 0 0 1.44-1.52c.384-.437.672-.853.864-1.248.192-.405.288-.843.288-1.312 0-.587-.171-1.024-.512-1.312-.341-.299-.784-.448-1.328-.448-.523 0-.992.101-1.408.304a6.609 6.609 0 0 0-1.296.816l-.912-1.136c.309-.267.64-.507.992-.72a5.31 5.31 0 0 1 1.2-.512 5.346 5.346 0 0 1 1.472-.192c.725 0 1.349.128 1.872.384.523.245.923.597 1.2 1.056.288.459.432.997.432 1.616 0 .459-.069.891-.208 1.296a4.89 4.89 0 0 1-.608 1.168 8.942 8.942 0 0 1-.944 1.168 42.9 42.9 0 0 1-1.232 1.248l-2.128 2.064v.08h5.552V166ZM250.75 156.845l32.987-3.855-32.41-82.026c-1.787-4.627-5.111-10.111-9.525-12.374l-6.452-3.324-.046 48.862c.083 2.728-1.341 7.663-3.566 6.09l-22.197-15.605a6.825 6.825 0 0 0-8.854.875 6.813 6.813 0 0 0-.298 9.059l6.201 7.43-17.821-.167.391 1.797a8.554 8.554 0 0 0 8.585 6.731l17.299-.298 10.381 13.975c4.413 6.918 12.877 15.233 21.07 15.791l1.937.131 2.318 6.908Z"
    />
    <Path
      fill="#DBE2EA"
      d="M228.229 107.387h-44.591a2.566 2.566 0 0 1-2.57-2.571V10.877a2.565 2.565 0 0 1 2.57-2.57h44.591a2.565 2.565 0 0 1 2.57 2.57v93.939a2.574 2.574 0 0 1-2.57 2.571Z"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M235.283 21.896V10.758a6.92 6.92 0 0 0-6.92-6.92h-44.946a6.919 6.919 0 0 0-6.92 6.92v94.163a6.918 6.918 0 0 0 6.92 6.92h44.946a6.92 6.92 0 0 0 6.92-6.92v-65.39M208.063 23.808l-22.526 23.198M192.947 24.361l-6.551 6.854"
    />
    <Path
      fill="#3F9A6E"
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M225.384 102.549h-38.975a.558.558 0 0 1-.554-.554v-6.8c0-.304.251-.554.554-.554h38.975c.303 0 .554.25.554.553v6.801a.558.558 0 0 1-.554.554Z"
    />
    <Rect
      width={16.759}
      height={3.724}
      x={197.911}
      y={8.494}
      fill="#2B2E32"
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      rx={1.862}
    />
    <Path
      stroke="#2A2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="m235.35 55.267 9.693 5.725a10.383 10.383 0 0 1 4.357 5.103L283.737 153"
    />
    <Path
      fill="#fff"
      d="M200.836 95.312a7.262 7.262 0 0 1 9.32-.801l24.757 17.476-14.804 13.752-19.394-20.27a7.273 7.273 0 0 1 .121-10.157Z"
    />
    <Path
      stroke="#2A2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M246.551 112.434h-6.75a17.54 17.54 0 0 1-10.335-3.371l-19.59-14.3a6.927 6.927 0 0 0-8.956.67c-2.663 2.634-2.738 6.917-.177 9.655l19.366 20.651 8.286 12.923a24.443 24.443 0 0 0 18.612 11.172l1.415.112 2.328 6.899M241.542 97.21l-4.255-17.503-2.365-2.737M188.406 111.81l.745 2.141a9.1 9.1 0 0 0 8.584 6.099h11.266"
    />
    <Path
      stroke="#fff"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M68.65 108.36c-2.59-.38-4.65-3.04-4.65-5.62v-6.31c0-2.59 2.06-4.1 4.65-3.72 2.59.38 4.71 2.52 4.7 5.1v6.31c0 2.58-2.11 4.63-4.7 4.24ZM85.48 92c-2.41 5.9-4.85 11.6-7.31 17.08M79.89 94.4c0-.57-.47-.98-1.04-.93-.58.05-1.05.55-1.05 1.12 0 .57.47 1.01 1.05.95.58-.05 1.04-.57 1.04-1.14ZM85.75 106.44c0-.57-.46-.92-1.03-.78-.57.13-1.03.69-1.03 1.27 0 .57.46.94 1.03.81.57-.14 1.03-.73 1.03-1.3Z"
    />
  </Svg>
);
