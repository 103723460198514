import { View, StyleSheet } from "react-native";
import { shadow } from "react-native-paper";

import { DefaultTheme, spacing } from "../../../../../../theme";
import { WorkingTimeInput } from "../../components/dimmer";

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    backgroundColor: DefaultTheme.colors.background,
    padding: spacing[1],
    borderRadius: spacing[2],
    top: 138,
    left: spacing[3],
    right: spacing[3],
    ...(shadow(5) as any),
  },
  reservoir: {
    marginBottom: 0,
  },
});

export const WorkingTimeInputHinte = () => {
  return (
    <View style={styles.container}>
      <WorkingTimeInput style={styles.reservoir} disabled />
    </View>
  );
};
