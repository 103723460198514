import * as storage from "./storage";

const TOKEN_KEY = "@token";

export function persistToken(token: string) {
  return storage.saveString(TOKEN_KEY, token);
}

export function eraseToken() {
  return storage.remove(TOKEN_KEY);
}

export function getToken() {
  return storage.loadString(TOKEN_KEY);
}
