import { FloatingButton, Text } from "@components/ui";
import { StyleSheet, View } from "react-native";

import { AccountDeleteState, TContent } from "../types";
import { WarningSvg } from "../../../../../svgs/pictures";
import { palette, spacing } from "../../../../../theme";

const styles = StyleSheet.create({
  image: {
    alignItems: "center",
    justifyContent: "center",
    marginTop: spacing[4],
    marginBottom: spacing[4],
  },
  message: {
    color: palette.GrayBlue,
    textAlign: "center",
  },
});

export const Warning = ({ onNextState }: TContent) => {
  const goToEnterCode = () =>
    onNextState({ nextState: AccountDeleteState.EnterCode });

  return (
    <>
      <View style={styles.image}>
        <WarningSvg />
      </View>
      <Text style={styles.message} tx="Auth.AccountDelete.warning" />

      <FloatingButton
        tx="common.continue"
        onPress={goToEnterCode}
        type="warning"
      >
        Continue
      </FloatingButton>
    </>
  );
};
