import { StyleSheet } from "react-native";

import { DeviceMode } from "@models/device/types";
import { LayoutCenter } from "@components/layout";
import { ScreenMessage } from "@components/ui";

import { NotFoundSvg } from "../../../../svgs/pictures";
import { translate } from "../../../../i18n";
import { spacing } from "../../../../theme";

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    marginHorizontal: spacing[7],
    marginTop: spacing[6],
  },
});

export const NoDevices = ({ deviceMode }: { deviceMode: DeviceMode }) => {
  return (
    <LayoutCenter style={styles.container}>
      <NotFoundSvg />
      <ScreenMessage
        style={{ marginTop: 24 }}
        title={translate("NoData.nothingHere")}
        message={translate(
          deviceMode === DeviceMode.Manual
            ? "NoData.noDevicesWithoutAutomation"
            : "NoData.noDevicesWithAutomation",
        )}
      />
    </LayoutCenter>
  );
};
