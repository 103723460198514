import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Dimensions, View, StyleSheet } from "react-native";
import { observer } from "mobx-react-lite";
import { StackNavigationProp, StackScreenProps } from "@react-navigation/stack";
import { useNavigation } from "@react-navigation/native";

import { useStores } from "@models/index";
import { SensorState } from "@models/sensor/sensor";
import { Screen } from "@components/index";
import { InputDialog } from "@components/dialog";
import { TopBarNew } from "@components/layout";
import { Dialog, ErrorsAlert, MenuItem, useDialog } from "@components/ui";

import {
  ConnectedModuleCard,
  RecalibrateCard,
  SensorHistory,
  SensorsDetailsCard,
} from "./components";
import { SensorsNavigatorParamList } from "../navigation";
import { useSensor } from "../../../hooks";
import { DownloadIcon, NoneIcon, RangeIcon } from "../../../svgs";
import { DeleteIcon, EditIcon } from "../../../svgs/menu";
import { translate } from "../../../i18n";
import * as SensorIcons from "../../../svgs/sensors";
import { defaultColors, spacing } from "../../../theme";
import { RuledDevices } from "../components";

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: spacing[4],
  },
});

export const SensorDetailsScreen: React.FC<
  StackScreenProps<SensorsNavigatorParamList, "sensor-details">
> = observer((props) => {
  const { sensorStore } = useStores();

  const { route } = props;
  const { params = {} } = route as any;
  const { sensor } = useSensor(params?.uid);

  const navigation =
    useNavigation() as StackNavigationProp<SensorsNavigatorParamList>;

  const [visibleRename, setVisibleRename] = useState<boolean>(false);
  const showRenameDialog = () => setVisibleRename(true);
  const hideRenameDialog = () => setVisibleRename(false);

  const onDeleteHandler = async () => {
    await sensor.remove();
    navigation.navigate("sensors-list");
  };
  const { showDialog: showDeleteDialog, ...dialogDeleteProps } = useDialog({
    onOk: onDeleteHandler,
  });

  const fetchData = async (force = false) => {
    if (!sensor) return;
    await sensor.fetchDetails({ force });
    // TODO: fetch single sensor data
    // TODO: reset sensor history
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onRefresh = useCallback(async () => {
    await fetchData(true);
  }, []);

  const goToCalibration = () => {
    navigation.navigate("sensor-calibration", { uid: sensor.uid });
  };

  const deleteSensor = () => {
    if (sensor.state === SensorState.Online) {
      sensor.setStatusError([translate("Sensors.Details.noDeleteDescription")]);
    } else {
      showDeleteDialog();
    }
  };

  const changeSensorType = () => {
    navigation.navigate("sensor-types-list", { uid: sensor.uid });
  };

  const editRange = () => {
    navigation.navigate("sensor-range", { uid: sensor.uid });
  };

  const exportHistory = async () => {
    await sensorStore.exportSensorsHistory([sensor.uid]);
  };

  const menuItems = useMemo<MenuItem[]>(() => {
    const items = [
      {
        key: "sensor-edit",
        icon: EditIcon,
        // color: palette.Black,
        title: translate("Sensors.Details.renameSensor"),
        onPress: showRenameDialog,
      },
      {
        key: "sensor-edit-range",
        title: translate("Sensors.Range.editRange"),
        icon: RangeIcon,
        onPress: editRange,
      },
      {
        key: "exportHistory",
        title: translate("Sensors.Export.exportHistory"),
        icon: DownloadIcon,
        onPress: exportHistory,
      },
      {
        key: "sensor-delete",
        title: translate("Sensors.Details.deleteSensor"),
        icon: DeleteIcon,
        color: defaultColors.warning,
        onPress: deleteSensor,
        topLine: true,
      },
    ];

    if (sensor?.isChangeableType) {
      items.push({
        key: "sensor-change-type",
        icon: NoneIcon,
        // color: palette.Black,
        title: translate("Sensors.Details.changeSensorType"),
        onPress: changeSensorType,
      });
    }

    return items;
  }, [sensor]);

  if (!sensor) {
    navigation.navigate("sensors-list");
    return null;
  }

  const Icon = SensorIcons[sensor.iconName] || NoneIcon;

  const { height: windowHeight } = Dimensions.get("window");
  let chartHeight = windowHeight - 350 - spacing[6] - 50;
  if (sensor.canCalibrate) {
    chartHeight -= 80;
  }

  return (
    <Screen
      testID="SensorsDetails"
      preset="scroll"
      header={
        <TopBarNew
          title={sensor.displayName}
          subtitle={sensor.displayType}
          icon={Icon}
          menuItems={menuItems}
        />
      }
      onRefresh={onRefresh}
    >
      <SensorsDetailsCard sensor={sensor} isLoading={sensorStore.isLoading} />

      <SensorHistory sensor={sensor} chartHeight={chartHeight} />

      <View style={styles.container}>
        {sensor.canCalibrate && sensor.state !== SensorState.Offline && (
          <RecalibrateCard
            date={sensor.lastCalibrationDate}
            recalibrationDate={sensor.recalibrationDate}
            onPress={goToCalibration}
            periodCalibrationDays={sensor.periodCalibrationDays}
            isInProgress={sensor.calibration?.isInProgress}
          />
        )}

        {sensor.moduleUid && (
          <ConnectedModuleCard
            moduleUid={sensor.moduleUid}
            connectedToPin={sensor.connectedToPin}
          />
        )}

        <RuledDevices sensorUid={sensor.uid} />
      </View>
      <InputDialog
        title={translate("Sensors.Details.renameSensor")}
        label={translate("fields.sensorName")}
        visible={visibleRename}
        value={sensor.name || sensor.displayName}
        onOk={sensor.updateName}
        onCancel={hideRenameDialog}
      />

      <Dialog
        {...dialogDeleteProps}
        title={translate("Sensors.Details.deleteSensor")}
        okText={translate("common.delete")}
        okType="warning"
        content={`${translate(
          "Sensors.Details.deleteDescription",
        )}\n${translate("common.youWantToDelete")} ${sensor.displayName}?`}
      />

      <ErrorsAlert errors={sensor.statusErrors} onCancel={sensor.resetErrors} />
    </Screen>
  );
});
