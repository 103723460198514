import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { observer } from "mobx-react-lite";
import { useTheme } from "@react-navigation/native";

import { Device, DeviceMode, DeviceState } from "@models/device/device";
import { Loading, Text } from "@components/ui";

import { spacing } from "../../../theme";
import { translate } from "../../../i18n";

const createStyles = (theme: any) =>
  StyleSheet.create({
    card: {
      alignItems: "center",
      justifyContent: "center",
      width: 72,
    },
    loading: {
      padding: spacing[2],
    },
    text: {
      fontSize: 12,
      textAlign: "center",
      color: theme.colors.link,
      marginHorizontal: spacing[3],
    },
    "text-on": {
      color: theme.colors.card,
    },
  });

type AutoModeProps = {
  device: Device;
};

export const AutoMode: React.FC<AutoModeProps> = observer(({ device }) => {
  const theme = useTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);
  const { state, mode, isLoading, isSwitching } = device;

  if (isLoading || isSwitching) {
    return (
      <View style={styles.card}>
        <Loading size="small" withMask={false} style={styles.loading} />
      </View>
    );
  }

  if (mode === DeviceMode.Auto && state !== DeviceState.Offline) {
    return (
      <View style={styles.card}>
        <Text style={[styles.text, styles[`text-${state}`]]} numberOfLines={2}>
          {translate("Devices.Details.autoMode")}
        </Text>
      </View>
    );
  }

  return null;
});
