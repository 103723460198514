import Svg, { Path } from "react-native-svg";

const CloseIcon = ({ size = 24 }) => {
  return (
    <Svg width={size} height={size} fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.959 7.53a.75.75 0 1 0-1.06-1.06l-4.185 4.184L7.53 6.47a.75.75 0 0 0-1.06 1.06l4.184 4.184-4.184 4.184a.75.75 0 0 0 1.06 1.06l4.184-4.183 4.184 4.184a.75.75 0 0 0 1.06-1.06l-4.183-4.185 4.184-4.184Z"
        fill="#000"
      />
    </Svg>
  );
};

export default CloseIcon;
