import {
  ApiResponse,
  ApisauceConfig,
  ApisauceInstance,
  create,
} from "apisauce";

import { Language } from "../../i18n";
import { ApiConfig, DEFAULT_API_CONFIG } from "./api-config";
import { ApiProblem, processResponse } from "./response-resolver";
import { rnLogger } from "../logger";

const SENSITIVE_FIELDS = [
  "password",
  "oldPassword",
  "newPassword",
  "confirmedPassword",
  "token",
];
const FILTERED = "[FILTERED]";

const validateFormData = (formData: string | undefined) => {
  if (!formData) return formData;

  let newFormData = formData;
  SENSITIVE_FIELDS.forEach((field: string) => {
    newFormData = newFormData.replace(
      new RegExp(`"${field}":"((?!undefined|null).)*"`, "i"),
      `"${field}":"${FILTERED}"`,
    );
  });

  return newFormData;
};

/**
 * Manages all requests to the API.
 */
export class Api {
  /**
   * The underlying apisauce instance which performs the requests.
   */
  apisauce: ApisauceInstance;

  /**
   * Configurable options.
   */
  config: ApiConfig;

  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   */
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config;
  }

  /**
   * Sets up the API.  This will be called during the bootup
   * sequence and will happen before the first React component
   * is mounted.
   *
   * Be as quick as possible in here.
   */
  setup(config?: ApisauceConfig) {
    // construct the apisauce instance
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        Accept: "application/json",
      },
      ...(config || {}),
    });

    // TODO: add to DEV settings !!!
    this.apisauce.addMonitor(Api.loggerMonitor);
    // if (__DEV__) this.apisauce.addMonitor(Api.errorMonitor);
  }

  setBaseURL(url: string) {
    this.apisauce.setBaseURL(url);
  }

  setAcceptLanguage(language: Language) {
    this.apisauce.setHeader("Accept-Language", language);
  }

  static async loggerMonitor(response: ApiResponse<any>) {
    if (response.ok) {
      await rnLogger.info("API request", {
        duration: response.duration,
        data: validateFormData(JSON.stringify(response.data)),
        url: response.config.url,
        params: JSON.stringify(response.config.params),
        method: response.config.method?.toUpperCase(),
        formData: validateFormData(response.config.data),
      });
    } else {
      await Api.errorMonitor(response);
    }
  }

  static async errorMonitor(response: any) {
    if (!response.ok) {
      const result = processResponse(response) as ApiProblem;
      console.log("ERROR: ", result);

      await rnLogger.error("API request error", {
        error: result.errors?.[0],
        errorCode: result.errorCode,
        problem: result.problem?.kind,
        url: response.config.url,
        params: JSON.stringify(response.config.params),
        method: response.config.method?.toUpperCase(),
        formData: validateFormData(response.config.data),
      });
    }
  }
}
