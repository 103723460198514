import React from "react";
import { observer } from "mobx-react-lite";

import { Rule, RuleType } from "@models/rule/rule";

import {
  DeviceRuleSensorBlock,
  DeviceRuleSensorValuesBlock,
  DeviceRuleTimeBlock,
  DeviceRuleValueBlock,
} from "@screens/Devices/Details/components/DeviceRuleDetailsBlocks";
import { useSensor } from "../../../../hooks";
import { translate } from "../../../../i18n";

type RulesSensorProps = {
  rule: Rule;
};

export const RulesSensor: React.FC<RulesSensorProps> = observer(({ rule }) => {
  const { sensor } = useSensor(rule.sensorUid);

  return (
    <>
      <DeviceRuleSensorBlock sensor={sensor} />
      <DeviceRuleSensorValuesBlock rule={rule} sensor={sensor} />

      {[RuleType.Ph, RuleType.Ec].includes(rule.ruleType) ? (
        <>
          <DeviceRuleTimeBlock
            label={translate("Devices.Rules.mixingTime")}
            value={rule.mixTime}
          />
          <DeviceRuleTimeBlock
            label={translate("Devices.Rules.dosingTime")}
            value={rule.dosingTime}
          />
          {rule.cyclesToSetPoint ? (
            <DeviceRuleValueBlock
              label={translate("Devices.Rules.timeToSetPoint")}
              value={`${rule.cyclesToSetPoint} ${translate("units.cycles")}`}
            />
          ) : null}
        </>
      ) : (
        <DeviceRuleTimeBlock
          label={translate("Devices.Rules.timeToSetPoint")}
          value={rule.timeToSetPoint}
        />
      )}
    </>
  );
});
