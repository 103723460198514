import React, { useMemo } from "react";
import { StyleSheet } from "react-native";
import { useTheme } from "@react-navigation/native";

import { Text } from "@components/ui";

import { translate } from "../../../../../i18n";
import { WarningSvg } from "../../../../../svgs/pictures";
import { CalibrationContent } from "../../components/CalibrationContent";
import { CalibrationContentProps } from "./types";

const createStyles = (theme: any) =>
  StyleSheet.create({
    description: {
      color: theme.colors.label,
      textAlign: "center",
    },
  });

export const CalibrationStepExpired = ({
  calibration,
}: CalibrationContentProps) => {
  const theme = useTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <CalibrationContent
      Picture={WarningSvg}
      title={translate("Sensors.Calibration.calibrationExpired")}
      submitLabel={translate("common.tryAgain")}
      onSubmit={calibration.start}
    >
      <Text style={styles.description}>
        {translate("Sensors.Calibration.calibrationExpiredDescription", {
          date: calibration.dateTime,
        })}
      </Text>
    </CalibrationContent>
  );
};
