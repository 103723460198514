import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { defaultColors } from "../theme";

export default function DimmingManual({
  color = defaultColors.text,
  size = 44,
}) {
  return (
    <Svg width={size} height={size} viewBox="0 0 44 44" fill="none">
      <Path
        d="M22.7499 5.49999C22.7499 4.53272 21.2499 4.53272 21.2499 5.49999V7.33332C21.2499 8.30059 22.7499 8.30059 22.7499 7.33332V5.49999Z"
        fill={color}
      />
      <Path
        d="M10.7969 9.73632C10.1129 9.05236 9.05224 10.113 9.7362 10.797L11.0195 12.0803C11.7035 12.7643 12.7642 11.7036 12.0802 11.0197L10.7969 9.73632Z"
        fill={color}
      />
      <Path
        d="M34.2635 10.797C34.9475 10.113 33.8868 9.05236 33.2029 9.73633L31.9195 11.0197C31.2356 11.7036 32.2962 12.7643 32.9802 12.0803L34.2635 10.797Z"
        fill={color}
      />
      <Path
        d="M5.49987 21.25C4.5326 21.25 4.5326 22.75 5.49987 22.75H7.3332C8.30047 22.75 8.30047 21.25 7.3332 21.25H5.49987Z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0786 15.172C19.9846 13.0775 24.0151 13.0775 26.9212 15.172C27.7059 15.7376 28.5829 14.5207 27.7982 13.9552C24.3743 11.4873 19.6255 11.4873 16.2015 13.9552C12.7775 16.423 11.2758 20.9281 12.5343 24.9568C13.1495 26.9263 14.3636 28.6544 16.0068 29.9008C16.6151 30.5134 17.0881 31.2812 17.3616 32.1016C17.6743 33.0398 17.5832 33.876 17.5832 34.8333C17.5832 36.0047 18.0485 37.1281 18.8768 37.9564C20.5778 39.6574 23.4219 39.6574 25.1229 37.9564C25.9512 37.1281 26.4165 36.0047 26.4165 34.8333C26.4165 33.876 26.3254 33.0398 26.6381 32.1016C26.9169 31.2654 27.4021 30.4854 28.0275 29.8663C28.7149 29.1859 27.6597 28.1198 26.9722 28.8003C26.4878 29.2799 26.0758 29.8245 25.7473 30.4167H18.2525C17.9274 29.8307 17.4946 29.1418 16.9499 28.7333C15.5367 27.6734 14.4928 26.1957 13.966 24.5096C12.8979 21.0903 14.1725 17.2666 17.0786 15.172ZM19.0836 34.8839C19.2223 33.8902 19.1506 32.8793 18.8743 31.9167H25.1254C24.8491 32.8793 24.7775 33.8902 24.9161 34.8839C24.903 35.6391 24.5973 36.3607 24.0623 36.8957C22.9389 38.019 21.0608 38.019 19.9375 36.8957C19.4024 36.3607 19.0967 35.6391 19.0836 34.8839Z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.7499 21.825V18C34.7499 17.0327 33.2499 17.0327 33.2499 18V21.825C31.5382 22.1725 30.2499 23.6858 30.2499 25.5C30.2499 27.3142 31.5382 28.8275 33.2499 29.175V38.5C33.2499 39.4673 34.7499 39.4673 34.7499 38.5V29.175C36.4615 28.8275 37.7499 27.3142 37.7499 25.5C37.7499 23.6858 36.4615 22.1725 34.7499 21.825ZM33.9999 27.75C35.2425 27.75 36.2499 26.7426 36.2499 25.5C36.2499 24.2573 35.2425 23.25 33.9999 23.25C32.7572 23.25 31.7499 24.2573 31.7499 25.5C31.7499 26.7426 32.7572 27.75 33.9999 27.75Z"
        fill={color}
      />
    </Svg>
  );
}
