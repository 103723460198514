import React from "react";
import { StyleSheet, View } from "react-native";
import Slider from "@react-native-community/slider";

import { Text } from "@components/ui/Text";

import { palette } from "../../theme";

const styles = StyleSheet.create({
  text: {
    textAlign: "center",
  },
  slider: {
    width: "100%",
    maxHeight: 20,
  },
});

type SliderRangeProps = {
  value?: number;
  minimumValue?: number;
  maximumValue?: number;
  step?: number;
  onValueChange?: (value: number) => void;
};

const SliderRange = ({
  value,
  minimumValue,
  maximumValue,
  step = 10,
  onValueChange,
}: SliderRangeProps) => {
  // draft version points on the slider

  // const maximumValue = 110;
  // const minimumValue = 10;
  // const step = 10;

  // const percentShift = useMemo(() => {
  //   const arrSteps = [];
  //   for (let i = 0; i < 11; i += 1) {
  //     const size = i % 2 === 0 ? "l" : "s";
  //     arrSteps.push(size);
  //   }
  //   return arrSteps;
  // }, [value]);

  return (
    <View>
      {/* <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          position: "absolute",
          width: "100%",
          bottom: 15,
          alignItems: "center",
        }}
      >
        {percentShift.map((dot) => (
          <View
            style={{
              width: dot === "s" ? 6 : 8,
              height: dot === "s" ? 6 : 8,
              backgroundColor: palette.GrayBlueLight,
              borderRadius: 50,
            }}
          />
        ))}
      </View>
       <View style={{width: '100%', paddingLeft: percentShift+'%'}}>
        <Text style={{marginLeft: -28}}>{value}</Text>
      </View>  */}

      <Text style={styles.text}>{value}</Text>
      <Slider
        style={styles.slider}
        minimumValue={minimumValue}
        maximumValue={maximumValue}
        step={step}
        minimumTrackTintColor={palette.GrayBlueLight}
        maximumTrackTintColor={palette.GrayBlueLight}
        thumbTintColor={palette.GrayBlue}
        onValueChange={onValueChange}
        value={value}
        // onSlidingComplete={(sliderValue) => console.log(sliderValue)}
      />
    </View>
  );
};

export default SliderRange;
