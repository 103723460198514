import React, { useMemo } from "react";
import { StyleSheet } from "react-native";

import { Text } from "../ui/Text";
import { spacing } from "../../theme";
import { useAppTheme } from "../../hooks";

const createStyles = (theme: any) =>
  StyleSheet.create({
    label: {
      color: theme.colors.label,
      marginRight: spacing[1],
    },
    "label-default": {
      fontSize: 14,
      marginBottom: spacing[1],
    },
    "label-small": {
      fontSize: 12,
    },
    "label-large": {
      fontSize: 16,
      marginBottom: spacing[2],
    },
    labelFocus: {
      color: theme.colors.labelFocus,
    },
    labelError: {
      color: theme.colors.error,
    },
  });

type Props = React.ComponentProps<typeof Text> & {
  isFocus?: boolean;
  isError?: boolean;
  size?: "small" | "default" | "large";
  colon?: boolean;
};

export const Label: React.FunctionComponent<Props> = ({
  isFocus,
  isError,
  size = "default",
  colon,
  children,
  style,
  ...props
}) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <Text
      {...props}
      style={[
        styles.label,
        styles[`label-${size}`],
        isFocus && styles.labelFocus,
        isError && styles.labelError,
        style,
      ]}
    >
      {children}
      {colon && ":"}
    </Text>
  );
};
