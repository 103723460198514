import * as React from "react";
import Svg, { G, Path, Defs, Rect } from "react-native-svg";

export default function HintIcon() {
  return (
    <Svg width={60} height={60} fill="none">
      <G filter="url(#a)">
        <Rect width={40} height={40} x={10} y={5} fill="#2B2E32" rx={20} />
        <Path
          fill="#fff"
          fillRule="evenodd"
          d="M30 12.25c-7.042 0-12.75 5.708-12.75 12.75S22.958 37.75 30 37.75 42.75 32.042 42.75 25 37.042 12.25 30 12.25ZM15.75 25c0-7.87 6.38-14.25 14.25-14.25S44.25 17.13 44.25 25 37.87 39.25 30 39.25 15.75 32.87 15.75 25ZM30 31.125c.76 0 1.375.616 1.375 1.375v.015a1.375 1.375 0 1 1-2.75 0V32.5c0-.76.616-1.375 1.375-1.375Zm.12-14.416a4.65 4.65 0 0 0-3.718 1.84.75.75 0 1 0 1.195.906 3.15 3.15 0 1 1 3.643 4.843 3 3 0 0 0-1.989 2.996.75.75 0 0 0 1.498-.085 1.5 1.5 0 0 1 1-1.5l.019-.007a4.649 4.649 0 0 0-1.648-8.993Z"
          clipRule="evenodd"
        />
      </G>
      <Defs />
    </Svg>
  );
}
