import * as React from "react";
import Svg, { Path } from "react-native-svg";

import { defaultColors } from "../theme";

const BlinkLightIcon = ({ size = 24, color = defaultColors.link }) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 -6.10352e-05C12.5523 -6.10352e-05 13 0.447654 13 0.999939V2.99994C13 3.55222 12.5523 3.99994 12 3.99994C11.4477 3.99994 11 3.55222 11 2.99994V0.999939C11 0.447654 11.4477 -6.10352e-05 12 -6.10352e-05ZM12 7.74994C9.65277 7.74994 7.74998 9.65273 7.74998 11.9999C7.74998 14.3472 9.65277 16.2499 12 16.2499C14.3472 16.2499 16.25 14.3472 16.25 11.9999C16.25 9.65273 14.3472 7.74994 12 7.74994ZM6.24998 11.9999C6.24998 8.8243 8.82435 6.24994 12 6.24994C15.1756 6.24994 17.75 8.8243 17.75 11.9999C17.75 15.1756 15.1756 17.7499 12 17.7499C8.82435 17.7499 6.24998 15.1756 6.24998 11.9999ZM13 21C13 20.4477 12.5523 20 12 20C11.4477 20 11 20.4477 11 21V23C11 23.5523 11.4477 24 12 24C12.5523 24 13 23.5523 13 23V21ZM3.51273 3.51289C3.90326 3.12236 4.53642 3.12236 4.92695 3.51289L6.34695 4.93289C6.73747 5.32341 6.73747 5.95658 6.34695 6.3471C5.95642 6.73762 5.32326 6.73762 4.93273 6.3471L3.51273 4.9271C3.12221 4.53658 3.12221 3.90341 3.51273 3.51289ZM19.0671 17.6526C18.6766 17.2621 18.0434 17.2621 17.6529 17.6526C17.2624 18.0431 17.2624 18.6763 17.6529 19.0668L19.0729 20.4868C19.4634 20.8774 20.0966 20.8774 20.4871 20.4868C20.8776 20.0963 20.8776 19.4631 20.4871 19.0726L19.0671 17.6526ZM-1.52588e-05 12C-1.52588e-05 11.4477 0.4477 11 0.999985 11H2.99998C3.55227 11 3.99998 11.4477 3.99998 12C3.99998 12.5523 3.55227 13 2.99998 13H0.999985C0.4477 13 -1.52588e-05 12.5523 -1.52588e-05 12ZM21 11C20.4477 11 20 11.4477 20 12C20 12.5523 20.4477 13 21 13H23C23.5523 13 24 12.5523 24 12C24 11.4477 23.5523 11 23 11H21ZM6.34695 17.6526C6.73747 18.0431 6.73747 18.6763 6.34695 19.0668L4.92695 20.4868C4.53642 20.8774 3.90326 20.8774 3.51273 20.4868C3.12221 20.0963 3.12221 19.4631 3.51273 19.0726L4.93273 17.6526C5.32326 17.2621 5.95642 17.2621 6.34695 17.6526ZM20.4871 4.9271C20.8776 4.53657 20.8776 3.90341 20.4871 3.51289C20.0966 3.12236 19.4634 3.12236 19.0729 3.51289L17.6529 4.93289C17.2624 5.32341 17.2624 5.95658 17.6529 6.3471C18.0434 6.73762 18.6766 6.73762 19.0671 6.3471L20.4871 4.9271Z"
      fill={color}
    />
  </Svg>
);
export default BlinkLightIcon;
