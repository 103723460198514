import * as React from "react";
import Svg, { Path } from "react-native-svg";

export default function NoMessageIcon(props) {
  return (
    <Svg width={212} height={140} viewBox="0 0 212 140" fill="none" {...props}>
      <Path
        d="M157.124 123.63H57.378C46.0429 123.63 39.0235 111.237 44.839 101.47L93.7779 19.2612C99.8303 9.09224 114.507 9.08564 120.573 19.2414L169.65 101.451C175.485 111.217 168.472 123.63 157.124 123.63Z"
        fill="#F4F4F4"
      />
      <Path
        d="M137.22 89.8588H77.6084C73.9124 89.8588 70.9172 86.8599 70.9172 83.1593V44.9683C70.9172 41.2677 73.9124 38.2688 77.6084 38.2688H137.22C140.916 38.2688 143.912 41.2677 143.912 44.9683V83.1593C143.912 86.8599 140.916 89.8588 137.22 89.8588Z"
        fill="white"
        stroke="#B6C5D3"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path d="M143.786 47.6361L107.415 68.754L71.043 47.6361" fill="white" />
      <Path
        d="M143.786 47.6361L107.415 68.754L71.043 47.6361"
        stroke="#B6C5D3"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M67.9552 64.0605H44.437"
        stroke="#B6C5D3"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M67.9551 74.8843H53.4951"
        stroke="#B6C5D3"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M158.109 71.8717C145.229 71.8717 134.782 82.3248 134.782 95.2272C134.782 100.642 136.627 105.621 139.715 109.579C139.675 109.619 139.636 109.652 139.596 109.692L129.903 119.397C127.853 121.449 127.853 124.779 129.903 126.831C131.953 128.884 135.278 128.884 137.328 126.831L147.021 117.126C147.318 116.828 147.563 116.504 147.775 116.166C150.889 117.709 154.4 118.583 158.109 118.583C170.989 118.583 181.435 108.13 181.435 95.2272C181.435 82.3248 170.989 71.8717 158.109 71.8717ZM158.109 113.333C148.118 113.333 140.026 105.223 140.026 95.2272C140.026 85.2243 148.125 77.1214 158.109 77.1214C168.099 77.1214 176.192 85.231 176.192 95.2272C176.192 105.223 168.099 113.333 158.109 113.333Z"
        fill="white"
        stroke="#B6C5D3"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M175.594 99.878C178.157 90.2135 172.411 80.2979 162.758 77.731C153.105 75.1641 143.202 80.9179 140.639 90.5825C138.075 100.247 143.822 110.163 153.474 112.73C163.127 115.296 173.03 109.543 175.594 99.878Z"
        fill="white"
        stroke="#B6C5D3"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M170.83 95.2272C170.83 100.503 167.63 105.025 163.061 106.965"
        stroke="#B6C5D3"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M145.388 95.2273C145.388 88.1902 151.08 82.4903 158.109 82.4903"
        stroke="#B6C5D3"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M67.5085 118.512H72.0575C76.2427 118.512 79.6346 115.116 79.6346 110.926V104.438H75.0857C70.9004 104.438 67.5085 107.834 67.5085 112.025V118.512Z"
        fill="white"
        stroke="#B6C5D3"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M73.5716 111.482L67.5085 118.512V123.629"
        stroke="#B6C5D3"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M67.5084 110.787H62.9595C58.7742 110.787 55.3823 107.391 55.3823 103.2V96.7124H59.9312C64.1165 96.7124 67.5084 100.108 67.5084 104.299V110.787Z"
        fill="white"
        stroke="#B6C5D3"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M61.4453 103.756L67.5083 110.787V115.911"
        stroke="#B6C5D3"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M98.7361 118.512H103.285C107.47 118.512 110.862 115.116 110.862 110.926V104.438H106.313C102.128 104.438 98.7361 107.834 98.7361 112.025V118.512Z"
        fill="white"
        stroke="#B6C5D3"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M104.792 111.482L98.7361 118.512V123.629"
        stroke="#B6C5D3"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M98.7359 110.787H94.187C90.0017 110.787 86.6099 107.391 86.6099 103.2V96.7124H91.1588C95.3441 96.7124 98.7359 100.108 98.7359 104.299V110.787Z"
        fill="white"
        stroke="#B6C5D3"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M92.6729 103.756L98.7359 110.787V115.911"
        stroke="#B6C5D3"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M55.2039 123.63H86.0612"
        stroke="#B6C5D3"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M91.0796 123.63H114.161"
        stroke="#B6C5D3"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
    </Svg>
  );
}
