import React, { useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { observer } from "mobx-react-lite";
import { StackNavigationProp } from "@react-navigation/stack";

import { Button, Heading, Loading, ScreenMessage } from "@components/ui";
import { useStores } from "@models/index";
import { NoModules } from "@components/common";
import { LayoutCenter } from "@components/layout";
import { SettingsNavigatorParamList } from "@screens/Settings/navigation";

import { spacing } from "../../../../theme";
import { translate } from "../../../../i18n";
import { NoAddedSensors } from "../../../../svgs/pictures";

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    paddingHorizontal: spacing[4],
  },
  container: {
    justifyContent: "space-between",
    marginHorizontal: spacing[7],
    marginBottom: spacing[5],
  },
  picture: {
    marginBottom: spacing[4],
  },
  button: {
    marginTop: spacing[4],
  },
});

function NoSensors() {
  const navigation =
    useNavigation() as StackNavigationProp<SettingsNavigatorParamList>;
  const connectSensorHandler = () =>
    navigation.navigate("settings-help-manual");

  return (
    <View style={styles.screen}>
      <Heading tx="Sensors.List.title" />
      <LayoutCenter style={styles.container}>
        <View style={styles.picture}>
          <NoAddedSensors />
        </View>
        <ScreenMessage
          title={translate("NoData.noSensors")}
          message={translate("NoData.noSensorsDescription")}
        />
        <View style={styles.button}>
          <Button
            type="ghost"
            tx="NoData.cantConnectSensor"
            onPress={connectSensorHandler}
          >
            Can not connect the sensor?
          </Button>
        </View>
      </LayoutCenter>
    </View>
  );
}

const SensorsNoData = observer(() => {
  const { moduleStore } = useStores();

  const fetchModules = async () => {
    await moduleStore.fetchModules({});
  };

  useEffect(() => {
    fetchModules();
  }, [moduleStore]);

  if (!moduleStore.isLoaded) return <Loading />;

  return moduleStore.modules.length ? (
    <NoSensors />
  ) : (
    <NoModules
      heading={translate("Sensors.List.title")}
      description={translate("NoData.sensorsNoModulesDescription")}
    />
  );
});

export default SensorsNoData;
