import React, { useEffect, useMemo, useState } from "react";
import { View, StyleSheet } from "react-native";
import { observer } from "mobx-react-lite";
import { StackNavigationProp, StackScreenProps } from "@react-navigation/stack";
import { useNavigation, useTheme } from "@react-navigation/native";

import { SensorTypeName, TSensorType, useStores } from "@models/index";
import { Screen } from "@components/index";
import {
  CheckList,
  CheckListStatus,
  ErrorsAlert,
  ErrorsMessage,
  RadioItem,
} from "@components/ui";
import { TopBar, TopBarAlign } from "@components/layout";

import { spacing } from "../../../theme";
import * as SensorIcons from "../../../svgs/sensors";
import { translate } from "../../../i18n";
import { CheckmarkIcon, NoneIcon } from "../../../svgs";
import { ModuleWaitingSvg } from "../../../svgs/pictures";
import { goBack } from "../../../navigators";
import { SensorsNavigatorParamList } from "../navigation";

const WAITING_TYPE_UPDATE_MS = 60 * 1000;
const INTERVAL_TYPE_UPDATE_MS = 5 * 1000;

const styles = StyleSheet.create({
  screen: {
    padding: spacing[4],
  },
  text: {
    marginLeft: spacing[4],
    marginBottom: spacing[4],
  },
  message: {
    marginBottom: spacing[3],
  },

  image: {
    alignItems: "center",
    justifyContent: "center",
    minHeight: 250,
    marginTop: -spacing[4],
  },
});

type TypeItemProps = {
  sensorType: TSensorType;
  onPress?: (value: string) => void;
  checked?: boolean;
};

const TypeItem = observer(({ sensorType, onPress, checked }: TypeItemProps) => {
  const Icon = SensorIcons[sensorType.iconName] || NoneIcon;
  const theme = useTheme() as any;

  return (
    <RadioItem
      value={sensorType.name}
      label={sensorType.displayName}
      checked={checked}
      icon={<Icon size={24} color={theme.colors.label} />}
      onPress={onPress}
    />
  );
});

export const SensorTypesListScreen: React.FC<
  StackScreenProps<SensorsNavigatorParamList, "sensor-types-list">
> = observer(({ route }) => {
  const { sensorStore } = useStores();
  const { params = {} } = route as any;
  const navigation =
    useNavigation() as StackNavigationProp<SensorsNavigatorParamList>;

  const sensor = sensorStore.getSensor(params?.uid);
  const [selectedType, setSelectedType] = useState(sensor.type);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[] | null>(null);

  const allErrors = sensor.statusErrors || errors;
  const resetErrors = () => {
    setErrors(null);
    sensor.resetErrors();
  };

  const loopUntilTypeUpdated = async () => {
    setIsUpdating(true);
    sensor.resetErrors();
    const startTime = Date.now();
    const currentType = sensor.type;

    const timerId = setInterval(async () => {
      if (Date.now() - startTime > WAITING_TYPE_UPDATE_MS) {
        clearInterval(timerId);
        setIsUpdating(false);
        sensor.setStatusError([
          translate("Sensors.List.failedToUpdateSensorType"),
        ]);
        return;
      }

      const result = await sensor.fetchDetails({ force: true });
      if (result.type !== currentType) {
        clearInterval(timerId);
        setIsUpdating(false);
        navigation.navigate("sensor-calibration", { uid: sensor.uid });
      }
    }, INTERVAL_TYPE_UPDATE_MS);
  };

  const setSelectedItem = async () => {
    if (!selectedType || selectedType === SensorTypeName.Analog) {
      setErrors([translate("Sensors.List.errors.selectSensorType")]);
      return;
    }
    if (selectedType === sensor.type) {
      goBack();
      // setErrors([translate("Sensors.List.errors.selectOtherSensorType")]);
      return;
    }

    const result = await sensor.setType(selectedType);
    if (result.error) return;

    loopUntilTypeUpdated();
  };

  const headerActions = useMemo(
    () => [
      {
        key: "sensors-change-type",
        icon: CheckmarkIcon,
        onPress: setSelectedItem,
      },
    ],
    [selectedType],
  );

  useEffect(() => {
    setIsUpdating(false);
    // sensorStore.fetchSensorsTypes();
  }, []);

  // TODO: fetch single sensor data, remove after https://growdirector.atlassian.net/browse/GDV1-1645
  useEffect(() => {
    const fetchData = async (force = false) => {
      if (!sensor) return;
      await sensor.fetchDetails();
    };

    fetchData();
  }, []);

  return (
    <Screen
      style={styles.screen}
      header={
        <TopBar
          title={translate("Sensors.List.selectType")}
          back
          align={TopBarAlign.Left}
          actions={!isUpdating && headerActions}
        />
      }
    >
      {isUpdating ? (
        <UpdatingView />
      ) : (
        <View>
          <ErrorsMessage
            errors={[translate("Sensors.List.selectTypeMessage")]}
            style={styles.message}
          />
          {sensor.availableAnalogTypes.map((sensorType) => (
            <TypeItem
              key={`type-item-${sensorType.name}`}
              sensorType={sensorType}
              onPress={setSelectedType}
              checked={selectedType === sensorType.name}
            />
          ))}
        </View>
      )}

      <ErrorsAlert errors={allErrors} onCancel={resetErrors} />
    </Screen>
  );
});

const UpdatingView = () => {
  const steps = [
    {
      label: translate("Sensors.List.fewMinutes"),
      status: CheckListStatus.Processing,
    },
  ];

  return (
    <View>
      <View style={styles.image}>
        <ModuleWaitingSvg />
      </View>
      <CheckList
        title={translate("Sensors.List.updatingSensorType")}
        items={steps}
      />
    </View>
  );
};
