import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import { observer } from "mobx-react-lite";

import { DimmerRuleData, Rule } from "@models/rule/rule";
import { Button } from "@components/ui";

import {
  createDataValidator,
  DataValidator,
  moreThanValidator,
  periodValidator,
  requiredNumberValidator,
  requiredStringValidator,
} from "../../../../../../utils/validator";
import { DimmerRuleErrors } from "./types";
import { AutoBalancing } from "./AutoBalansing";
import { OverheatPreventionInput } from "./OverheatPreventionInput";
import { SunriseSunsetInput } from "./SunriseSunsetInput";
import { WorkingTimeInput } from "./WorkingTimeInput";

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

const dataIsValid: DataValidator<DimmerRuleData> = createDataValidator({
  startDayTime: requiredStringValidator,
  endDayTime: requiredStringValidator,
  sensorUid: requiredStringValidator,
  valueTarget: requiredNumberValidator,
});

type RuleFormProps = {
  rule: Rule;
  onSubmit: (data: DimmerRuleData) => void;
  onUnsavedChanges?: (isUnsavedChanges: boolean) => void;
};

export const FormDimmerAuto = observer(
  ({ rule, onSubmit, onUnsavedChanges }: RuleFormProps) => {
    const [formData, setFormData] = useState<DimmerRuleData>(rule.dimmerData);
    const [errors, setErrors] = useState<DimmerRuleErrors>(
      {} as DimmerRuleErrors,
    );

    const setErrorFilds = (filds) => {
      setErrors({
        ...errors,
        ...filds,
      });
    };

    const onSubmitHandler = () => {
      const isValid = dataIsValid(formData, { setErrors });

      const endDayTimeError = periodValidator(
        formData.startDayTime,
        formData.endDayTime,
      );
      const valueTargetError = moreThanValidator(formData.valueTarget, 0);
      setErrorFilds({
        endDayTime: endDayTimeError,
        valueTarget: valueTargetError,
      });

      if (isValid && !endDayTimeError && !valueTargetError) {
        onSubmit(formData);
      }
    };

    const changeFormData = (data: any) => {
      setFormData((prevData) => {
        const newData = {
          ...prevData,
          ...data,
        };

        dataIsValid(data, { setErrors: setErrorFilds });
        return newData;
      });
      onUnsavedChanges(true);
    };

    const onChangeStartAt = (value: string) => {
      changeFormData({ startDayTime: value });
    };

    const onChangeEndAt = (value: string) => {
      changeFormData({ endDayTime: value });
    };

    return (
      <View style={styles.container}>
        <WorkingTimeInput
          startDayTime={formData.startDayTime}
          endDayTime={formData.endDayTime}
          onChangeStartAt={onChangeStartAt}
          onChangeEndAt={onChangeEndAt}
          errors={errors}
        />

        <AutoBalancing
          rule={rule}
          formData={formData}
          changeFormData={changeFormData}
          errors={errors}
        />

        <OverheatPreventionInput
          rule={rule}
          formData={formData}
          errors={errors}
          changeFormData={changeFormData}
        />

        <SunriseSunsetInput
          formData={formData}
          errors={errors}
          changeFormData={changeFormData}
        />

        <Button
          type="primary"
          onPress={onSubmitHandler}
          tx="Devices.Rules.setRule"
        >
          Set Rule
        </Button>
      </View>
    );
  },
);
