import React, { useMemo, useState, VoidFunctionComponent } from "react";
import { View, StyleSheet } from "react-native";
import { observer } from "mobx-react-lite";

import {
  Module,
  ModuleDevice,
  ModuleSensor,
  ModuleType,
} from "@models/module/module";
import {
  Button,
  Heading,
  Loading,
  TabItem,
  Tabs,
  Text,
  useDialog,
} from "@components/ui";
import { LayoutCenter } from "@components/layout";
import {
  NewSensorDialog,
  SensorOfflinePopup,
  useOfflinePopup,
} from "@screens/Sensors/components";

import { useAppTheme } from "../../../../hooks";
import { palette, spacing } from "../../../../theme";
import { translate } from "../../../../i18n";
import { ModuleDeviceCard, ModuleSensorCard } from "./Items";

const createStyles = (theme: any) =>
  StyleSheet.create({
    container: {
      backgroundColor: theme.colors.card,
      alignItems: "center",
      padding: spacing[4],
      borderTopLeftRadius: spacing[4],
      borderTopRightRadius: spacing[4],
      marginTop: -spacing[4],
    },
    list: {
      width: "100%",
      // paddingVertical: spacing[1],
    },
    tabs: {
      marginBottom: spacing[4],
    },
    loading: {
      marginTop: spacing[5],
    },
    textName: {
      color: theme.colors.label,
      fontSize: 12,
      marginBottom: spacing[2],
    },
    pin: {
      color: theme.colors.label,
      fontSize: 12,
      marginTop: spacing[2],
    },
    disabledSocket: {
      height: 98,
      backgroundColor: palette.BlueBackground,
      borderRadius: spacing[4],
      padding: spacing[4],
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    title: {
      fontSize: 14,
      lineHeight: 16,
      color: theme.colors.label,
      marginBottom: spacing[4],
    },
  });

const noDataStyles = StyleSheet.create({
  container: {
    marginHorizontal: spacing[7],
    marginTop: spacing[5],
  },
  title: {
    fontSize: 20,
    textAlign: "center",
    marginBottom: spacing[5],
  },
});

const DisabledDimmerSocket = () => {
  const theme = useAppTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <View style={styles.disabledSocket}>
      <View>
        <Text style={styles.textName}>
          {translate("Modules.Details.noLights")}
        </Text>
        <Text>{translate("Modules.Details.emptySlot")}</Text>
        <Text style={styles.pin}>{translate("Modules.Details.aux")}</Text>
      </View>
      <Text style={styles.textName}>
        {translate("Modules.Details.comingSoon")}
      </Text>
    </View>
  );
};

const DeviceItems: VoidFunctionComponent<ModuleDetailsProps> = observer(
  ({ module }) => {
    const theme = useAppTheme() as any;
    const styles = useMemo(() => createStyles(theme), [theme]);

    return (
      <View style={styles.list}>
        <Text style={styles.title} tx="Modules.Details.connectedDevices" />
        {module.devices.map((device: ModuleDevice) => (
          <ModuleDeviceCard key={`device-${device.uid}`} device={device} />
        ))}
        {module.type === "dimmer" && module.devices.length === 1 ? (
          <DisabledDimmerSocket />
        ) : null}
      </View>
    );
  },
);

const NoSensors = () => {
  const { showDialog: showAddSensorDialog, ...dialogProps } = useDialog();

  return (
    <LayoutCenter style={noDataStyles.container}>
      <Heading style={noDataStyles.title} level={2} tx="NoData.noSensors" />
      <Button
        tx="NoData.HowAddSensor"
        type="link"
        onPress={showAddSensorDialog}
      >
        How to add sensor?
      </Button>

      <NewSensorDialog {...dialogProps} />
    </LayoutCenter>
  );
};

const SensorItems: VoidFunctionComponent<ModuleDetailsProps> = observer(
  ({ module }) => {
    const theme = useAppTheme() as any;
    const styles = useMemo(() => createStyles(theme), [theme]);

    const { visibleOfflinePopup, showOfflinePopup, hideOfflinePopup } =
      useOfflinePopup();

    if (!module.sensors?.length) {
      return <NoSensors />;
    }

    return (
      <>
        <View style={styles.list}>
          <Text style={styles.title} tx="Modules.Details.connectedSensors" />
          {module.sensors.map((sensor: ModuleSensor) => (
            <ModuleSensorCard
              key={`sensor-${sensor.uid}`}
              moduleSensor={sensor}
              showOfflinePopup={showOfflinePopup}
            />
          ))}
        </View>

        <SensorOfflinePopup
          visible={visibleOfflinePopup}
          onClose={hideOfflinePopup}
        />
      </>
    );
  },
);

enum ModuleItemsView {
  Pumps = "pumps",
  Sensors = "sensors",
}

const HydroItems: VoidFunctionComponent<ModuleDetailsProps> = observer(
  ({ module, setNewItemScroll }) => {
    const theme = useAppTheme() as any;
    const styles = useMemo(() => createStyles(theme), [theme]);

    const { visibleOfflinePopup, showOfflinePopup, hideOfflinePopup } =
      useOfflinePopup();

    const [view, setView] = useState<ModuleItemsView>(ModuleItemsView.Pumps);
    const onFilterPress = (item) => {
      setView(item.key);
      if (item.key === ModuleItemsView.Pumps) {
        setNewItemScroll(0);
      } else {
        setNewItemScroll(1);
      }
    };

    const tabsItems: TabItem[] = [
      {
        key: ModuleItemsView.Pumps,
        name: translate("Modules.Details.pumps"),
        onPress: onFilterPress,
      },
      {
        key: ModuleItemsView.Sensors,
        name: translate("Modules.Details.sensors"),
        onPress: onFilterPress,
      },
    ];

    return (
      <>
        <View style={styles.list}>
          <Tabs
            items={tabsItems}
            currentTab={view}
            style={styles.tabs}
            withBG
          />

          {view === ModuleItemsView.Pumps ? (
            <View>
              {module.devices.map((device) => (
                <ModuleDeviceCard key={device.uid} device={device} />
              ))}
            </View>
          ) : null}

          {view === ModuleItemsView.Sensors &&
            (module.sensors.length ? (
              <View>
                {module.sensors.map((sensor) => (
                  <ModuleSensorCard
                    key={sensor.uid}
                    moduleSensor={sensor}
                    showOfflinePopup={showOfflinePopup}
                  />
                ))}
              </View>
            ) : (
              <NoSensors />
            ))}
        </View>

        <SensorOfflinePopup
          visible={visibleOfflinePopup}
          onClose={hideOfflinePopup}
        />
      </>
    );
  },
);

const MODULE_DETAILS_COMPONENTS = {
  [ModuleType.DryContact]: DeviceItems,
  [ModuleType.Sensor]: SensorItems,
  [ModuleType.Socket]: DeviceItems,
  [ModuleType.Hydro]: HydroItems,
  [ModuleType.Dimmer]: DeviceItems,
};

type ModuleDetailsProps = {
  module: Module;
  setNewItemScroll?: (item: number) => void;
};

export const ModuleDetailsOnline = observer(
  ({ module, setNewItemScroll }: ModuleDetailsProps) => {
    const theme = useAppTheme();
    const styles = useMemo(() => createStyles(theme), [theme]);

    const DetailsComponent = MODULE_DETAILS_COMPONENTS[module.type];

    return (
      <View style={styles.container}>
        {module.isLoading ? (
          <Loading withMask={false} style={styles.loading} />
        ) : (
          <DetailsComponent
            module={module}
            setNewItemScroll={setNewItemScroll}
          />
        )}
      </View>
    );
  },
);
