import { useState, VoidFunctionComponent } from "react";

import { Dialog } from "@components/ui/Dialog";
import { TextInput } from "@components/inputs/TextInput";

import { translate } from "../../i18n";

type Props = {
  visible: boolean;
  onOk: (value: string) => Record<any, any> | undefined;
  onCancel: () => void;
  title?: string;
  label?: string;
  value?: string;
  maxLength?: number;
  okText?: string;
};

export const InputDialog: VoidFunctionComponent<Props> = ({
  visible,
  onOk,
  onCancel,
  value: initialValue,
  title,
  label,
  maxLength = 100,
  okText,
}) => {
  const [value, setValue] = useState<string>(initialValue);
  const [error, setError] = useState<string>();

  const onCancelHandler = () => {
    setValue(initialValue);
    setError(null);
    onCancel();
  };

  const onChangeText = (text: string) => {
    setError(null);
    setValue(text);
  };

  const onOkHandler = async () => {
    const result = await onOk(value);

    if (result?.error) {
      setError(result?.error);
    } else {
      setError(null);
      onCancel();
    }
  };

  const hintLabel = maxLength
    ? `${maxLength} ${translate("common.characters")}`
    : undefined;

  return (
    <Dialog
      visible={visible}
      onOk={onOkHandler}
      onCancel={onCancelHandler}
      onDismiss={onCancelHandler}
      title={title}
      okText={okText || translate("common.rename")}
    >
      <TextInput
        label={label}
        hintLabel={hintLabel}
        defaultValue={value}
        // value={value}
        onChangeText={onChangeText}
        maxLength={maxLength}
        errorText={error}
      />
    </Dialog>
  );
};
