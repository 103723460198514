import { StyleSheet } from "react-native";
import { observer } from "mobx-react-lite";
import { useIsFocused } from "@react-navigation/native";

import { useStores } from "@models/index";
import { HelpHintsOneTime, THintsDataItem } from "@components/hints";

import { MANUAL_URL } from "@screens/Settings";
import { translate } from "../../../../i18n";
import {
  ButtonAdd,
  ButtonFilter,
  ButtonHistory,
  SensorCalibration,
  SensorCardName,
  // SensorCardStatus,
  SensorCardValue,
  SensorDisconnected,
  // SensorTypeUnknown,
  SensorUnusualReading,
} from "./components";

const dialogStyle = StyleSheet.create({
  dialog: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 242,
  },
});

const SensorUnusualReadingHint = () => ({
  id: "SensorUnusualReading",
  title: translate("Hints.title"),
  text: translate("Hints.Climate.textHintSensorUnusualReading"),
  HintComponent: SensorUnusualReading,
  dialogStyle: dialogStyle.dialog,
  // helpFileName: MANUAL_URL,
});

const SensorCalibrationHint = () => ({
  id: "SensorCalibration",
  title: translate("Hints.title"),
  text: translate("Hints.Climate.textHintSensorCalibration"),
  HintComponent: SensorCalibration,
  dialogStyle: dialogStyle.dialog,
  // helpFileName: MANUAL_URL,
});

export const SensorsDetailsHintsData = (): THintsDataItem[] => [
  {
    id: "ButtonFilter",
    title: translate("Hints.title"),
    text: translate("Hints.Climate.textHintButtonFilter"),
    HintComponent: ButtonFilter,
    dialogStyle: dialogStyle.dialog,
  },
  {
    id: "ButtonAdd",
    title: translate("Hints.title"),
    text: translate("Hints.Climate.textHintButtonAdd"),
    HintComponent: ButtonAdd,
    dialogStyle: dialogStyle.dialog,
  },
  {
    id: "ButtonHistory",
    title: translate("Hints.title"),
    text: translate("Hints.Climate.textHintButtonHistory"),
    HintComponent: ButtonHistory,
    dialogStyle: dialogStyle.dialog,
  },
  {
    id: "CardName",
    title: translate("Hints.title"),
    text: translate("Hints.Climate.textHintCardName"),
    HintComponent: SensorCardName,
    dialogStyle: dialogStyle.dialog,
  },
  {
    id: "CardValue",
    title: translate("Hints.title"),
    text: translate("Hints.Climate.textHintCardValue"),
    HintComponent: SensorCardValue,
    dialogStyle: dialogStyle.dialog,
  },
  // {
  //   id: "CardStatus",
  //   title: translate("Hints.title"),
  //   text: translate("Hints.Climate.textHintCardStatus"),
  //   HintComponent: SensorCardStatus,
  //   dialogStyle: dialogStyle.dialog,
  // },
  SensorUnusualReadingHint(),
  // {
  //   id: "TypeUnknown",
  //   title: translate("Hints.title"),
  //   text: translate("Hints.Climate.textHintSensorTypeUnknown"),
  //   HintComponent: SensorTypeUnknown,
  //   dialogStyle: dialogStyle.dialog,
  //   helpFileName: MANUAL_URL,
  // },
  {
    id: "Disconnected",
    title: translate("Hints.title"),
    text: translate("Hints.Climate.textHintSensorDisconnected"),
    HintComponent: SensorDisconnected,
    dialogStyle: dialogStyle.dialog,
    // helpFileName: MANUAL_URL,
  },
  SensorCalibrationHint(),
  {
    id: "gotIt",
    title: translate("Hints.helpButtonTitle"),
    text: translate("Hints.helpButtonContent"),
  },
];

export const SensorListOneTimeHints = observer(() => {
  const isFocused = useIsFocused();
  const { sensorStore, settingsStore } = useStores();

  if (!isFocused) return null;

  const hints = [];

  const sensorUnusualReadingHint = SensorUnusualReadingHint();
  if (
    !settingsStore.isOneTimeHintShown(sensorUnusualReadingHint.id) &&
    sensorStore.hasOutOfRange
  ) {
    hints.push(sensorUnusualReadingHint);
  }

  const sensorCalibrationHint = SensorCalibrationHint();
  if (
    !settingsStore.isOneTimeHintShown(sensorCalibrationHint.id) &&
    sensorStore.hasUncalibrated
  ) {
    hints.push(sensorCalibrationHint);
  }

  if (!hints.length) return null;

  const onOk = (itemId) => {
    settingsStore.markOneTimeHintAsShown(itemId);
  };

  return <HelpHintsOneTime hints={hints} onOk={onOk} />;
});
