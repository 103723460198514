import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import { observer } from "mobx-react-lite";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";

import {
  DimmerRuleData,
  Rule,
  RuleScheduleItem,
  RuleType,
  SensorRuleData,
} from "@models/rule/rule";
import { Dialog, ErrorsAlert } from "@components/ui";

import { GetRuleResult } from "../../../../../services/api/rules-api";
import { DevicesNavigatorParamList } from "../../../navigation";
import { translate } from "../../../../../i18n";
import { spacing } from "../../../../../theme";
import { FormSchedule } from "./schedule";
import { FormSensor } from "./sensor";
import { FormDimmerManual, FormDimmerAuto } from "./dimmer";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: spacing[4],
    paddingTop: spacing[4],
    paddingBottom: spacing[6],
  },
});

type RuleFormWrapperProps = {
  rule: Rule;
  onComplete: (result: GetRuleResult) => void;
  onUnsavedChanges?: (
    isUnsavedChanges: boolean,
    changeSensorRule?: SensorRuleData,
  ) => void;
};

const RuleFormWrapper = ({
  rule,
  onComplete,
  onUnsavedChanges,
}: RuleFormWrapperProps) => {
  const onScheduleSubmit = async (data: RuleScheduleItem[]) => {
    const result = await rule.setScheduleData(data);
    onComplete(result);
  };

  const onSensorSubmit = async (data: SensorRuleData) => {
    const result = await rule.setSensorData(data);
    onComplete(result);
  };

  const onDimmerSubmit = async (data: DimmerRuleData) => {
    const result = await rule.setDimmerData(data);
    onComplete(result);
  };

  switch (rule.ruleType) {
    case RuleType.DimmerManual:
      return (
        <FormDimmerManual
          rule={rule}
          onSubmit={onDimmerSubmit}
          onUnsavedChanges={onUnsavedChanges}
        />
      );
    case RuleType.DimmerAuto:
      return (
        <FormDimmerAuto
          rule={rule}
          onSubmit={onDimmerSubmit}
          onUnsavedChanges={onUnsavedChanges}
        />
      );
    case RuleType.Schedule:
      return (
        <FormSchedule
          rule={rule}
          onSubmit={onScheduleSubmit}
          onUnsavedChanges={onUnsavedChanges}
        />
      );

    default:
      return (
        <FormSensor
          rule={rule}
          onSubmit={onSensorSubmit}
          onUnsavedChanges={onUnsavedChanges}
        />
      );
  }
};

type RuleFormProps = {
  rule: Rule;
  onUnsavedChanges: (
    isUnsavedChanges: boolean,
    changeSensorRule?: SensorRuleData,
  ) => void;
};

export const RuleForm = observer(
  ({ rule, onUnsavedChanges }: RuleFormProps) => {
    const navigation =
      useNavigation() as StackNavigationProp<DevicesNavigatorParamList>;

    const [isShowDialog, setIsShowDialog] = useState(false);

    /* eslint-disable consistent-return */
    const onComplete = (result) => {
      if (result.kind === "ok") {
        navigation.navigate("device-details", { uid: rule.deviceUid });
      }
    };
    /* eslint-enable consistent-return */

    const onOkHandler = () => {
      setIsShowDialog(false);
      onUnsavedChanges(false);
    };

    return (
      <View style={styles.container}>
        <RuleFormWrapper
          rule={rule}
          onComplete={onComplete}
          onUnsavedChanges={onUnsavedChanges}
        />

        <Dialog
          title={translate("common.confirm")}
          content={translate("Devices.Rules.wontToLeaveMessage")}
          okText={translate("common.yes")}
          visible={isShowDialog}
          onOk={onOkHandler}
          onCancel={() => setIsShowDialog(false)}
        />

        <ErrorsAlert errors={rule.statusErrors} onCancel={rule.resetErrors} />
      </View>
    );
  },
);
