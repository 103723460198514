import React, { memo } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import { Logo as LogoIcon } from "../../svgs";
import { spacing } from "../../theme";

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    marginVertical: spacing[6],
  },
});

export enum LogoSizes {
  small = 64,
  default = 84,
}
type Props = {
  size?: LogoSizes;
  style?: StyleProp<ViewStyle>;
};

export const Logo = memo(({ size = LogoSizes.default, style }: Props) => {
  return (
    <View style={[styles.container, style]}>
      <LogoIcon height={size} />
    </View>
  );
});
