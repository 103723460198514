export enum AccountDeleteState {
  "Warning" = "warning",
  "EnterEmail" = "enterEmail",
  "EnterCode" = "enterCode",
}

export type TDataNextState = {
  nextState: AccountDeleteState;
  newEmail?: string;
};

export type TContent = {
  onNextState?: (data: TDataNextState) => void;
  email?: string;
};
