import { useEffect } from "react";
import { BackHandler, Platform } from "react-native";

export const useHardwareBackPress = (onBackPress?: () => void) => {
  return useEffect(() => {
    if (Platform.OS === "web") return undefined;
    if (!onBackPress) return undefined;

    const callback = () => {
      onBackPress?.();
      return true;
    };

    BackHandler.addEventListener("hardwareBackPress", callback);
    return () => BackHandler.removeEventListener("hardwareBackPress", callback);
  }, [onBackPress]);
};
