import React from "react";
import { StyleSheet, View } from "react-native";

import { Dialog, Text } from "@components/ui";

import { translate } from "../../i18n";
import { palette, spacing } from "../../theme";
import { WarningSvg } from "../../svgs/pictures";

export enum ConfirmType {
  "Normal" = "normal",
  "Warning" = "warning",
}

const styles = StyleSheet.create({
  content: {
    fontSize: 16,
    color: palette.GrayBlue,
    textAlign: "center",
  },
  image: {
    marginBottom: spacing[6],
    justifyContent: "center",
    alignItems: "center",
  },
});

type Props = {
  visible: boolean;
  onCancel?: () => void;
  onOk?: () => void;
  backHandler?: () => void;
  title?: string;
  content?: string;
  okText?: string;
  type?: ConfirmType;
  children?: React.ReactNode;
};

export const ConfirmDialog: React.VFC<Props> = ({
  visible,
  onOk,
  onCancel,
  backHandler,
  title,
  content,
  okText,
  type = ConfirmType.Normal,
  children,
}) => {
  const Image = type === ConfirmType.Warning ? WarningSvg : undefined;

  return (
    <Dialog
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      onDismiss={backHandler || onCancel}
      title={title}
      okText={okText || translate("common.yes")}
      okType={type === ConfirmType.Warning ? "warning" : "primary"}
      dismissable={!backHandler}
    >
      {Image && (
        <View style={styles.image}>
          <Image />
        </View>
      )}
      {content && <Text style={styles.content}>{content}</Text>}
      {children || null}
    </Dialog>
  );
};
