import * as React from "react";
import Svg, { Path } from "react-native-svg";

export default function PlayIcon({ color = "white" }) {
  return (
    <Svg width={32} height={35} viewBox="0 0 32 35" fill="none">
      <Path
        d="M0.629639 1.92073V32.6398C0.629537 32.9814 0.725924 33.3168 0.908855 33.6115C1.09179 33.9062 1.35464 34.1494 1.67029 34.3161C1.98594 34.4828 2.34295 34.567 2.7045 34.5598C3.06605 34.5527 3.41904 34.4546 3.72704 34.2756L30.1485 18.916C30.4444 18.7443 30.6888 18.5037 30.8583 18.2173C31.0278 17.931 31.1168 17.6083 31.1168 17.2802C31.1168 16.9521 31.0278 16.6295 30.8583 16.3431C30.6888 16.0568 30.4444 15.8162 30.1485 15.6445L3.72704 0.284936C3.41904 0.10593 3.06605 0.00779742 2.7045 0.000662987C2.34295 -0.00647145 1.98594 0.0776508 1.67029 0.24435C1.35464 0.41105 1.09179 0.654289 0.908855 0.948973C0.725924 1.24366 0.629537 1.57911 0.629639 1.92073Z"
        fill={color}
      />
    </Svg>
  );
}
