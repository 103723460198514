import React from "react";
import { Platform } from "react-native";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { useTheme } from "@react-navigation/native";
import { LinearGradient } from "expo-linear-gradient";

import { ModulesListScreen } from "@screens/Modules";
import { SensorsListScreen } from "@screens/Sensors";
import { DevicesListScreen } from "@screens/Devices";
import { SettingsScreen } from "@screens/Settings";

import { useStores } from "@models/index";
import { DevicesIcon, ModulesIcon, SensorsIcon, SettingsIcon } from "../svgs";
import { palette, spacing } from "../theme";
import { translate } from "../i18n";
import { useAppTheme } from "../hooks";
import { useLocale } from "./locale-context";

function TabIcon(props) {
  const { colors } = useTheme() as any;
  const { focused, color, size = 24, Icon } = props;
  const iconColor = focused ? colors.card : color;

  return (
    <LinearGradient
      colors={
        focused
          ? [palette.TabGradient1, palette.TabGradient2]
          : [colors.transparent, colors.transparent]
      }
      start={{ x: 0, y: 1 }}
      end={{ x: 1, y: 1 }}
      style={{
        marginTop: spacing[4],
        padding: 2,
        borderRadius: 14,
      }}
    >
      <Icon color={iconColor} width={size} height={size} />
    </LinearGradient>
  );
}
const SensorsBarIcon = (props) => (
  <TabIcon {...props} size={22} Icon={SensorsIcon} />
);
const DevicesBarIcon = (props) => (
  <TabIcon {...props} size={24} Icon={DevicesIcon} />
);
const ModulesBarIcon = (props) => (
  <TabIcon {...props} size={24} Icon={ModulesIcon} />
);
const SettingsBarIcon = (props) => (
  <TabIcon {...props} size={24} Icon={SettingsIcon} />
);

const styles = {
  tabBar: {
    height: Platform.OS === "ios" ? 100 : 84,
    borderTopWidth: 0,
    borderColor: "rgba(0, 33, 83, 0.1)",
    borderTopLeftRadius: spacing[2],
    borderTopRightRadius: spacing[2],
    marginTop: -spacing[4],
    shadowColor: "#002153",

    shadowOpacity: 0.5,
    shadowOffset: { width: 0, height: 5 },
    shadowRadius: 3.84,

    boxShadow: "rgb(0 0 0 / 10%) 0px -1px 2px",
  },
  tabBarLabel: {
    marginVertical: spacing[2],
    fontSize: 12,
    lineHeight: spacing[4],
    marginBottom: Platform.OS !== "ios" ? spacing[4] : undefined,
  },
  hidden: {
    display: "none",
  },
};

const Tab = createBottomTabNavigator();

export const TabNavigator = () => {
  const { colors, fonts } = useAppTheme();
  const { accountStore } = useStores();

  const locale = useLocale();
  console.log("TabNavigator locale", locale);

  return (
    <Tab.Navigator
      initialRouteName={accountStore.initialRouteName || "sensors-list"}
      screenOptions={{
        headerShown: false,
        tabBarStyle: styles.tabBar,
        tabBarLabelStyle: { ...fonts.regular, ...styles.tabBarLabel },
        tabBarLabelPosition: "below-icon",
        tabBarActiveTintColor: colors.text,
        tabBarInactiveTintColor: colors.label,
      }}
    >
      <Tab.Screen
        name="sensors-list"
        component={SensorsListScreen}
        options={{
          tabBarLabel: translate("navigation.Climate"),
          tabBarIcon: SensorsBarIcon,
        }}
      />
      <Tab.Screen
        name="devices-list"
        component={DevicesListScreen}
        options={{
          tabBarLabel: translate("navigation.Devices"),
          tabBarIcon: DevicesBarIcon,
        }}
      />
      <Tab.Screen
        name="modules-list"
        component={ModulesListScreen}
        options={{
          tabBarLabel: translate("navigation.Modules"),
          tabBarIcon: ModulesBarIcon,
        }}
      />
      <Tab.Screen
        name="settings-list"
        component={SettingsScreen}
        options={{
          tabBarLabel: translate("navigation.Settings"),
          tabBarIcon: SettingsBarIcon,
        }}
      />
    </Tab.Navigator>
  );
};
