import React, { useMemo } from "react";
import { StyleSheet, ViewStyle } from "react-native";

import { CustomSelectInput } from "@components/inputs";

import { spacing } from "../../../../../../theme";
import { useAppTheme } from "../../../../../../hooks";

const createStyles = (theme: any) =>
  StyleSheet.create({
    container: {
      marginBottom: spacing[4],
      padding: spacing[4],
      paddingBottom: spacing[3],
      borderRadius: spacing[4],
      borderWidth: 1,
      borderColor: theme.colors.menuBorder,
    },

    label: {
      color: theme.colors.label,
      fontSize: 16,
      marginBottom: spacing[2],
    },
  });

type SensorInputProps = {
  displayName: string;
  icon: React.FunctionComponent<any>;
  error?: string;
  onPress?: () => void;
  style?: ViewStyle;
};

export const SensorInput = ({
  displayName,
  icon,
  error,
  onPress = () => null,
  style,
}: SensorInputProps) => {
  const theme = useAppTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <CustomSelectInput
      txLabel="Devices.Rules.controllingSensor"
      value={displayName}
      errorText={error}
      icon={icon}
      iconColor={theme.colors.label}
      onPress={onPress}
      labelStyle={styles.label}
      style={[styles.container, style]}
    />
  );
};
