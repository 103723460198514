import * as React from "react";
import {
  View,
  StyleSheet,
  StyleProp,
  ViewStyle,
  TextStyle,
} from "react-native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { Button as PaperButton } from "react-native-paper";

import { spacing } from "../../theme";
import { useAppTheme } from "../../hooks";

const createStylesRadio = (theme: any) =>
  StyleSheet.create({
    tabsContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    button: {
      backgroundColor: theme.colors.tabBG,
      marginHorizontal: spacing[1],
      borderRadius: spacing[4],
      flexGrow: 1,
    },
    buttonActive: {
      backgroundColor: theme.colors.tabActiveBG,
    },
    buttonLabel: {
      color: theme.colors.text,
      lineHeight: 14,
    },
    buttonLabelActive: {
      color: theme.colors.text,
    },
  });
const createStylesWithBG = (theme: any) =>
  StyleSheet.create({
    tabsContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingVertical: spacing[1],
      backgroundColor: theme.colors.tabBGlight,
      borderRadius: 20,
    },
    button: {
      backgroundColor: theme.colors.tabBGlight,
      marginHorizontal: spacing[1],
      borderRadius: spacing[4],
      flexGrow: 1,
    },
    buttonActive: {
      backgroundColor: theme.colors.tabActiveBGlight,
    },
    buttonLabel: {
      color: theme.colors.text,
      lineHeight: 14,
    },
    buttonLabelActive: {
      color: theme.colors.text,
    },
  });

const Stack = createNativeStackNavigator();

export type TabItem = {
  key: string;
  name: string;
  component?: React.ComponentType<any>;
  onPress?: (item: TabItem) => void;
};

type TabButtonProps = React.ComponentProps<typeof PaperButton> & {
  isActive?: boolean;
  withBG?: boolean;
};
function TabButton({
  isActive = false,
  withBG,
  children,
  labelStyle,
  ...props
}: TabButtonProps) {
  const theme = useAppTheme();
  const styles = withBG ? createStylesWithBG(theme) : createStylesRadio(theme);

  return (
    <PaperButton
      uppercase={false}
      style={[styles.button, isActive && styles.buttonActive]}
      labelStyle={[
        styles.buttonLabel,
        isActive && styles.buttonLabelActive,
        labelStyle,
      ]}
      {...props}
    >
      {children}
    </PaperButton>
  );
}

type TabsProps = {
  items: TabItem[];
  currentTab?: string;
  style?: StyleProp<ViewStyle>;
  withBG?: boolean;
  labelStyle?: StyleProp<TextStyle>;
};
export const Tabs = React.memo(
  ({ items, currentTab, style, withBG = false, labelStyle }: TabsProps) => {
    const theme = useAppTheme();
    const styles = withBG
      ? createStylesWithBG(theme)
      : createStylesRadio(theme);

    return (
      <View style={[styles.tabsContainer, style]}>
        {items.map((item) => (
          <TabButton
            key={item.key}
            isActive={currentTab === item.key}
            withBG={withBG}
            onPress={() => item.onPress?.(item)}
            labelStyle={labelStyle}
          >
            {item.name}
          </TabButton>
        ))}
      </View>
    );
  },
);

type TabsNavigatorProps = {
  items: TabItem[];
  initialTab?: string;
};
function TabsNavigator({ items, initialTab }: TabsNavigatorProps) {
  return (
    <Stack.Navigator
      initialRouteName={initialTab}
      screenOptions={{
        header: ({ route }) => <Tabs items={items} currentTab={route.name} />,
      }}
    >
      {items.map((item) => (
        <Stack.Screen
          key={item.key}
          name={item.key}
          component={item.component}
          options={{
            title: item.name,
          }}
        />
      ))}
    </Stack.Navigator>
  );
}

export default TabsNavigator;
