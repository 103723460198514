import React from "react";
import { StyleProp, StyleSheet, ViewStyle } from "react-native";
import { useTheme } from "@react-navigation/native";
import { LinearGradient } from "expo-linear-gradient";
import shadow from "react-native-paper/src/styles/shadow";

import { Device } from "@models/device/device";

import { palette, spacing } from "../../../../theme";
import * as DeviceIcons from "../../../../svgs/devices";
import { NoneIcon } from "../../../../svgs";

const styles = StyleSheet.create({
  icon: {
    backgroundColor: palette.OrangeG2,
    padding: spacing[2],
    borderRadius: spacing[4],
    elevation: 5,
    // boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.2)",
  },
});

type DeviceIconProps = {
  device: Device;
  style?: StyleProp<ViewStyle>;
};

export const DeviceIcon: React.VFC<DeviceIconProps> = ({ device, style }) => {
  const theme = useTheme() as any;
  const Icon = DeviceIcons[device.iconName] || NoneIcon;
  const shadowStyle = shadow(5) as any;

  return (
    <LinearGradient
      colors={[palette.OrangeG1, palette.OrangeG2]}
      style={[styles.icon, style, shadowStyle]}
      start={{ x: 0.25, y: 0.25 }}
      end={{ x: 0.75, y: 0.75 }}
    >
      <Icon size={48} color={theme.colors.card} />
    </LinearGradient>
  );
};
