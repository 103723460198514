import React, { useCallback, useEffect } from "react";
import { StyleSheet } from "react-native";
import { observer } from "mobx-react-lite";
import { StackNavigationProp, StackScreenProps } from "@react-navigation/stack";
import { useNavigation } from "@react-navigation/native";

import { useStores } from "@models/index";
import { TNotificationType } from "@models/notifications/notifications-store";
import { Screen } from "@components/index";
import { TopBarNew } from "@components/layout";
import { Text } from "@components/ui";

import { translate } from "../../../../i18n";
import { spacing } from "../../../../theme";
import { normalizeFontSize } from "../../../../utils/sizes";
import { SettingsNavigatorParamList } from "../../navigation";
import { NotificationsPreferencesCard } from "./NotificationsPreferencesCard";

const createStyles = () =>
  StyleSheet.create({
    screen: {
      paddingTop: spacing[4],
      paddingHorizontal: spacing[4],
    },
    description: {
      fontSize: normalizeFontSize(14, 16),
      marginBottom: spacing[5],
    },
  });

export const NotificationsPreferencesScreen: React.FC<
  StackScreenProps<
    SettingsNavigatorParamList,
    "settings-notifications-preferences"
  >
> = observer(() => {
  const navigation = useNavigation() as StackNavigationProp<any>;
  const styles = createStyles();
  const { notificationsStore } = useStores();

  const onRefresh = useCallback(async () => {
    await notificationsStore.fetchNotificationsPreferences({ force: true });
  }, []);

  useEffect(() => {
    onRefresh();
  }, [onRefresh]);

  const goToPreferenrsType = (type: string) => {
    navigation.navigate("settings-notifications-preferences-type", {
      type,
    });
  };

  return (
    <Screen
      style={styles.screen}
      preset="scroll"
      testID="Notifications"
      onRefresh={onRefresh}
      refreshing={!notificationsStore.isLoaded}
      header={
        <TopBarNew
          title={translate("Settings.notifications.preferences.title")}
        />
      }
    >
      <Text
        style={styles.description}
        tx="Settings.notifications.preferences.description"
      />

      {notificationsStore.types.map((nType: TNotificationType) => {
        return (
          <NotificationsPreferencesCard
            key={nType.type}
            nType={nType}
            onPress={goToPreferenrsType}
          />
        );
      })}
    </Screen>
  );
});
