import { StyleSheet, View } from "react-native";
import { shadow } from "react-native-paper";

import { FilterButton } from "@components/common";

import dayjs from "dayjs";
import { DefaultTheme, spacing } from "../../../../../theme";
import { CalendarIcon } from "../../../../../svgs";
import {
  dateTimeFormat,
  DateTimeFormats,
} from "../../../../../utils/timeConverter";

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    backgroundColor: DefaultTheme.colors.backgroundGradientStart,
    padding: spacing[1],
    borderRadius: spacing[2],
    top: 62,
    right: 100,
    ...(shadow(5) as any),
  },
  button: {
    marginRight: spacing[0],
  },
});

const dataNow = dateTimeFormat(dayjs(), DateTimeFormats.DateDM);
const dataSevenDayAgo = dateTimeFormat(
  dayjs().add(-7, "day"),
  DateTimeFormats.DateDM,
);

const label = `${dataSevenDayAgo} - ${dataNow}`;
const onClick = () => null;

export const ButtonSelectPeriod = () => (
  <View style={styles.container}>
    <FilterButton
      onPress={onClick}
      iconLeft={CalendarIcon}
      label={label}
      selected
      style={styles.button}
    />
  </View>
);
