import { useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import Clipboard from "@react-native-clipboard/clipboard";
import { Snackbar } from "react-native-paper";
import { useKeepAwake } from "expo-keep-awake";

import { MODULE_SSID_PASSWORD } from "@models/module/module";
import { ModuleConnection } from "@models/module/module-connection";
import { Button, CheckList, CheckListStatus, Heading } from "@components/ui";
import { Label } from "@components/inputs";

import { translate } from "../../../../../i18n";
import { DefaultTheme, spacing } from "../../../../../theme";
import { CloseIcon, CopyIcon } from "../../../../../svgs";
import { ModuleAppConnectingSvg } from "../../../../../svgs/pictures";

const MODULE_WIFI_PREFIXES = "[Sensor|Hydro|...]Director___...";

const styles = StyleSheet.create({
  image: {
    alignItems: "center",
    justifyContent: "center",
    minHeight: 250,
    marginTop: -spacing[4],
  },
  heading: {
    textAlign: "center",
    marginHorizontal: spacing[4],
    marginBottom: spacing[4],
  },
  infoContainer: {
    backgroundColor: DefaultTheme.colors.inputBG,
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: spacing[2],
    padding: spacing[4],
  },
  copyIcon: {
    paddingHorizontal: spacing[2],
  },

  button: {
    width: "auto",
    marginTop: spacing[4],
    marginHorizontal: spacing[4],
    marginBottom: -spacing[2],
  },
});

type Props = {
  moduleConnection: ModuleConnection;
};

export const ModuleConnectInstruction = ({ moduleConnection }: Props) => {
  useKeepAwake();

  const [isCopy, setCopy] = useState(false);

  const instructionItems = [
    {
      label: translate("Modules.Add.Manual.instruction.step1"),
      status: CheckListStatus.Default,
    },
    {
      label: translate("Modules.Add.Manual.instruction.step2"),
      status: CheckListStatus.Default,
    },
    {
      label: translate("Modules.Add.Manual.instruction.step3"),
      status: CheckListStatus.Default,
    },
    {
      label: translate("Modules.Add.Manual.instruction.step4"),
      status: CheckListStatus.Default,
    },
  ];

  const copyPassword = () => {
    Clipboard.setString(MODULE_SSID_PASSWORD);
    setCopy(true);
  };

  const onDismissSnackBar = () => {
    setCopy(false);
  };

  // TODO: remove Start button and add Back button !!!
  // TODO: fix watcher -> use delay() and timestamp - not setInterval !!!

  return (
    <>
      <View style={styles.image}>
        <ModuleAppConnectingSvg />
      </View>

      <Heading
        level={2}
        tx="Modules.Add.Manual.steps.instruction"
        style={styles.heading}
      />
      <CheckList items={instructionItems} />

      <View style={{ flex: 1 }}>
        <View style={styles.infoContainer}>
          <View>
            <Label tx="fields.wifi">: {MODULE_WIFI_PREFIXES}</Label>
            <Label tx="fields.password">: {MODULE_SSID_PASSWORD}</Label>
          </View>
          <Pressable onPress={copyPassword} style={styles.copyIcon}>
            <CopyIcon />
          </Pressable>
        </View>
      </View>

      {!isCopy && (
        <Button
          style={styles.button}
          type="primary"
          onPress={moduleConnection.execute}
          disabled={!moduleConnection.executeIsAvailable}
        >
          {translate("common.start")}
        </Button>
      )}

      <Snackbar
        visible={isCopy}
        onDismiss={onDismissSnackBar}
        duration={5000}
        action={{
          label: "",
          icon: CloseIcon,
        }}
      >
        {translate("Modules.Add.Manual.instruction.PasswordCopied")}
      </Snackbar>
    </>
  );
};
