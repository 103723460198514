import { ApiResponse } from "apisauce";

import { Device, DeviceType } from "@models/device/device";
import { DeviceState, TDeviceHistoryItem } from "@models/device/types";

import { TRuleTypes } from "@models/rule/rule";
import { generateId } from "../../utils/generateId";
import {
  ApiProblem,
  GeneralResult,
  processResponse,
} from "./response-resolver";
import { Api } from "./api";

const DEVICE_VIEWS_PATH = "/Dashboard/device_views";
const DEVICE_TYPES_PATH = "/Device/types";
const DEVICES_PATH = "/Devices";

const RULE_TYPES_PATH = "Rule/types";

type DeviceSetStateParams = {
  state: string;
  needToSwitchBack: boolean;
  secondsToSwitchBack?: number;
  analogControlPinValue?: number;
};
type DeviceConnectParams = {
  name: string;
  connectedDeviceType: string;
  mode: string;
};
type DeviceDisconnectParams = {
  uid: string;
};

export type DeviseHistoryParams = {
  startDate: string;
  endDate: string;
};

export type GetDevicesViewsResult = { kind: "ok"; data: Device[] } | ApiProblem;
export type GetDeviceResult = { kind: "ok"; data: Device } | ApiProblem;
export type GetDeviceTypesResult =
  | { kind: "ok"; data: DeviceType[] }
  | ApiProblem;
export type SetStateTypesResult = { kind: "ok"; data: any } | ApiProblem;
type GetDeviceHistoryResult =
  | { kind: "ok"; data: TDeviceHistoryItem[] }
  | ApiProblem;

export type GetRuleTypesResult =
  | { kind: "ok"; data?: TRuleTypes[] }
  | ApiProblem;

export const convertDeviceState = (state: string): DeviceState => {
  switch (state) {
    case "on":
    case "stepOn":
      return DeviceState.On;
    case "off":
    case "requestOn":
    case "requestStepOn":
    case "stepWaiting":
    case "offByPowerOn":
      return DeviceState.Off;
    case DeviceState.Switching:
      return DeviceState.Switching;
    case DeviceState.Offline:
      return DeviceState.Offline;
    default:
      return DeviceState.Unknown;
  }
};

export class DevicesApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getDevicesViews(): Promise<GetDevicesViewsResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        DEVICE_VIEWS_PATH,
      );
      return processResponse(response);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async getDevices(): Promise<GetDevicesViewsResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        DEVICES_PATH,
      );
      return processResponse(response, (data) =>
        data.map((item) => ({
          ...item,
          state: convertDeviceState(item.state),
        })),
      );
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async getDevice(uid: string): Promise<GetDeviceResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `Device/${uid}`,
      );
      return processResponse(response, (data) => ({
        ...data,
        state: convertDeviceState(data.state),
      }));
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async getDeviceTypes(): Promise<GetDeviceTypesResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        DEVICE_TYPES_PATH,
      );
      return processResponse(response);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async getDeviceRules(uid: string): Promise<GetDeviceTypesResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/Device/${uid}/rule`,
      );
      return processResponse(response);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async getDeviceHistory(
    uid: string,
    params: DeviseHistoryParams,
  ): Promise<GetDeviceHistoryResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/Device/${uid}/history`,
        { ...params, limit: 256 },
      );
      return processResponse(response, (data) =>
        data
          .filter(
            (item) =>
              item.state === DeviceState.On ||
              item.state === DeviceState.Off ||
              item.state === "offByPowerOn" ||
              item.state === "stepWaiting" ||
              item.state === "stepOn",
          )
          .map((item) => ({
            ...item,
            dateTime: new Date(item.dateTime),
            state: convertDeviceState(item.state),
            id: generateId(),
          })),
      );
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async setState(
    uid,
    params: DeviceSetStateParams,
  ): Promise<SetStateTypesResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(
        `/Device/${uid}/set_state`,
        params,
      );
      return processResponse(response);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async connect(
    uid,
    params: DeviceConnectParams,
  ): Promise<SetStateTypesResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(
        `/Device/${uid}/connect`,
        params,
      );
      return processResponse(response);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async disconnect(uid: string): Promise<GetDevicesViewsResult> {
    try {
      const params: DeviceDisconnectParams = { uid };
      const response: ApiResponse<any> = await this.api.apisauce.post(
        `/Device/${uid}/disconnect`,
        params,
      );

      return processResponse(response);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error", errors: [e.message] };
    }
  }

  async updateName(uid: string, name: string): Promise<GetDevicesViewsResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.put(
        `/Device/${uid}`,
        { name },
      );

      return processResponse(response);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async getRuleTypes(): Promise<GetRuleTypesResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        RULE_TYPES_PATH,
      );
      return processResponse(response, (data) =>
        data.map((item: TRuleTypes) => ({
          ...item,
          sensors: item.sensors.length ? item.sensors : null,
          controlSensors: item.controlSensors.length
            ? item.controlSensors
            : null,
          availableDevices: item.availableDevices.length
            ? item.availableDevices
            : null,
        })),
      );
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async toggleAutomation(
    uid: string,
    enable?: boolean,
  ): Promise<GeneralResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(
        `/Device/${uid}/automation`,
        { enable: Boolean(enable) },
      );

      return processResponse(response);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }
}
