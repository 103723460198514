import * as React from "react";
import Svg, { Path } from "react-native-svg";

export default function CrossIcon({
  color = "#D01616",
  width = 24,
  height = 24,
}) {
  return (
    <Svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9589 7.53033C17.2518 7.23744 17.2518 6.76256 16.9589 6.46967C16.666 6.17678 16.1911 6.17678 15.8982 6.46967L11.7143 10.6536L7.53033 6.46967C7.23744 6.17678 6.76256 6.17678 6.46967 6.46967C6.17678 6.76256 6.17678 7.23744 6.46967 7.53033L10.6536 11.7143L6.46967 15.8982C6.17678 16.1911 6.17678 16.666 6.46967 16.9589C6.76256 17.2518 7.23744 17.2518 7.53033 16.9589L11.7143 12.7749L15.8982 16.9589C16.1911 17.2518 16.666 17.2518 16.9589 16.9589C17.2518 16.666 17.2518 16.1911 16.9589 15.8982L12.7749 11.7143L16.9589 7.53033Z"
        fill={color}
      />
    </Svg>
  );
}
