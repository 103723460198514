import * as React from "react";
import Svg, { Path } from "react-native-svg";

export default function ArrowLeftIcon({ color = "#2B2E32", size = 24 }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M11.5303 8.03034C12.2143 7.34637 11.1536 6.28571 10.4697 6.96968L5.46967 11.9697C5.17678 12.2626 5.17678 12.7374 5.46967 13.0303L10.4697 18.0303C11.1536 18.7143 12.2143 17.6536 11.5303 16.9697L7.81066 13.25H18C18.9673 13.25 18.9673 11.75 18 11.75H7.81066L11.5303 8.03034Z"
        fill={color}
      />
    </Svg>
  );
}
