import React from "react";
import { View, StyleSheet } from "react-native";

import { Text, Dialog, DialogProps } from "@components/ui";

import { palette, spacing } from "../../../theme";
import { translate } from "../../../i18n";
import { AddSensor } from "../../../svgs/pictures";

const styles = StyleSheet.create({
  content: {
    justifyContent: "center",
    alignItems: "center",
  },
  dialogText: {
    color: palette.GrayBlue,
    fontSize: 16,
    marginBottom: spacing[2],
    textAlign: "center",
  },
  dialogTextStep: {
    color: palette.GrayBlue,
    marginVertical: spacing[1],
  },
  dialogTextContainer: {
    marginTop: spacing[5],
  },
});

export const NewSensorDialog = (props: DialogProps) => {
  return (
    <Dialog {...props} okText={translate("common.done")} onCancel={false}>
      <View style={styles.content}>
        <AddSensor />
        <View style={styles.dialogTextContainer}>
          <Text style={styles.dialogText} tx="Sensors.Add.connectNewSensor" />

          <Text style={styles.dialogTextStep} tx="Sensors.Add.turnOffModule" />
          <Text style={styles.dialogTextStep} tx="Sensors.Add.connectSensor" />
          <Text style={styles.dialogTextStep} tx="Sensors.Add.turnOnModule" />
          <Text style={styles.dialogTextStep} tx="Sensors.Add.repeatSteps" />
        </View>
      </View>
    </Dialog>
  );
};
