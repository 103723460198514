import { View, StyleSheet } from "react-native";
import { shadow } from "react-native-paper";

import { Switch } from "@components/ui";

import { DefaultTheme, spacing } from "../../../../../theme";

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    backgroundColor: DefaultTheme.colors.background,
    padding: spacing[1],
    borderRadius: spacing[2],
    top: 290,
    left: 158,
    ...(shadow(5) as any),
  },
});

export const SwitchHint = ({ on = false }) => {
  return (
    <View style={styles.container}>
      <Switch value={on} />
    </View>
  );
};
