import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { StackScreenProps } from "@react-navigation/stack";

import { useStores } from "@models/index";
import { Rule, RuleType, SensorRuleData } from "@models/rule/rule";
import { Screen } from "@components/index";
import { ErrorText, Dialog } from "@components/ui";
import { TopBarContent, TopBarNew } from "@components/layout";

import { DevicesNavigatorParamList } from "../../../navigation";
import { translate } from "../../../../../i18n";
import { goBack } from "../../../../../navigators";
import { NoneIcon } from "../../../../../svgs";
import * as DeviceIcons from "../../../../../svgs/devices";
import { getAddRuleHints } from "../hints";
import { RuleForm, SelectRuleType } from "../components";

export const DeviceRulesScreen: React.FC<
  StackScreenProps<DevicesNavigatorParamList, "device-rules-edit">
> = observer(({ route }) => {
  const { params } = route;

  const [isUnsavedChanges, setIsUnsavedChanges] = useState(false);
  const [isShowDialog, setIsShowDialog] = useState(false);
  const [changeSensorRule, setChangeSensorRule] = useState();

  const { deviceStore, settingsStore } = useStores();
  const device = deviceStore.getDevice(params?.deviceUid);

  const [rule, setRule] = useState<Rule>(device.rule);

  if (!device)
    return <ErrorText>{translate("Devices.Details.noDevice")}</ErrorText>;

  const { isLoading } = device;

  useEffect(() => {
    device?.fetchRules();

    if (device.rule) {
      setRule(device.rule);
    }
  }, [device]);

  const setRuleType = (ruleType: RuleType) => {
    const newRule = device.buildRule(ruleType);
    setRule(newRule);
  };

  const backHandler = () => {
    if (isUnsavedChanges) {
      setIsShowDialog(true);
      return;
    }

    if (rule?.ruleType && rule.isNew) {
      setRule(undefined);
    } else {
      goBack();
    }
  };

  const DeviceIcon = DeviceIcons[device.iconName] || NoneIcon;
  const title =
    !rule || rule.isNew
      ? translate("Devices.Rules.Add.title")
      : translate("Devices.Rules.setRule");

  // TODO: use onChange instead onUnsavedChanges
  const onUnsavedChanges = (
    isUnsaved: boolean,
    sensorRule?: SensorRuleData,
  ) => {
    setIsUnsavedChanges(isUnsaved);
    setChangeSensorRule(sensorRule);
  };

  return (
    <Screen
      testID="DeviceRulesEdit"
      header={
        <>
          <TopBarNew title={title} backHandler={backHandler} />
          <TopBarContent
            title={device.displayName}
            subtitle={device.typeName}
            icon={DeviceIcon}
          />
        </>
      }
      preset="scroll"
      loading={isLoading || rule?.isLoading}
      hints={
        settingsStore.showHelpButton && getAddRuleHints(rule, changeSensorRule)
      }
    >
      {rule?.ruleType ? (
        <RuleForm rule={rule} onUnsavedChanges={onUnsavedChanges} />
      ) : (
        <SelectRuleType device={device} onChange={setRuleType} />
      )}

      <Dialog
        title={translate("common.confirm")}
        content={translate("Devices.Rules.wontToLeaveMessage")}
        okText={translate("common.yes")}
        visible={isShowDialog}
        onOk={goBack}
        onCancel={() => setIsShowDialog(false)}
      />
    </Screen>
  );
});
