import { useState } from "react";
import * as LocalAuthentication from "expo-local-authentication";

import { getAuthenticationType } from "../services/localAuthentication";
import { getSecureValueString, SecureValueKey } from "../utils/storage";
import { JSONParseSafety } from "../utils/JSONParseSafety";
import { rnLogger } from "../services/logger";

type UseLocalAuthenticationResponse = {
  authenticationType: LocalAuthentication.AuthenticationType | null;
  useBiometricToSignIn?: boolean;
  hasLoginData: boolean;
};

export const useLocalAuthentication = (): UseLocalAuthenticationResponse => {
  const [authenticationType, setAuthenticationType] = useState(null);
  const [useBiometricToSignIn, setUseBiometricToSignIn] = useState(null);
  const [loginData, setLoginData] = useState(null);

  const getAndSetData = async () => {
    const authenticationTypeValue = await getAuthenticationType();
    console.log("authenticationTypeValue", authenticationTypeValue);
    rnLogger.info(`authenticationTypeValue: ${authenticationTypeValue}`, {
      action: "biometric",
    });
    setAuthenticationType(authenticationTypeValue);

    try {
      const useBiometricToSignInString = await getSecureValueString(
        SecureValueKey.UseBiometricToSignIn,
      );
      const loginDataString = await getSecureValueString(
        SecureValueKey.LoginData,
      );
      setUseBiometricToSignIn(useBiometricToSignInString);
      setLoginData(loginDataString);
    } catch (e) {
      rnLogger.info(`getSecureValueString error: ${e.message}`, {
        action: "biometric",
      });
    }
  };

  getAndSetData();

  rnLogger.info(`authenticationType: ${authenticationType}`, {
    action: "biometric",
  });
  console.log("authenticationType", authenticationType);

  return {
    authenticationType,
    useBiometricToSignIn: JSONParseSafety(useBiometricToSignIn),
    hasLoginData: Boolean(loginData),
  };
};
