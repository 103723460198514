export enum NotificationType {
  DeviceSwitch = "device-switched",
  ModuleOffline = "module-offline",
  ModuleOfflineCriticalTime = "module-offline-critical-time",
  ModuleConnectionRestore = "module-connection-restore",
  SensorDisconnect = "sensor-disconnected",
  SensorConnectionRestore = "sensor-connection-restore",
  SensorOutOfRange = "sensor-out-of-range",
  SensorBackToRange = "sensor-back-to-range",
  SetPointCantBeReached = "setpoint-can-not-be-reached",
}

export enum NotificationEntityType {
  Devices = "Device",
  Sensors = "Sensor",
  Modules = "Module",
}

export const NOTIFICATION_ICON = {
  [NotificationType.DeviceSwitch]: "DeviceSwitchedIcon",
  [NotificationType.ModuleOfflineCriticalTime]: "ModuleCriticalIcon",
  [NotificationType.ModuleOffline]: "ModuleOffIcon",
  [NotificationType.ModuleConnectionRestore]: "ModuleRestoreIcon",
  [NotificationType.SensorDisconnect]: "SensorDiscIcon",
  [NotificationType.SensorOutOfRange]: "SensorOutRangeIcon",
  [NotificationType.SensorBackToRange]: "SensorRangeIcon",
  [NotificationType.SensorConnectionRestore]: "SensorRestoreIcon",
  // [NotificationType.SetPointCantBeReached]: "SomeIcon",
};

export enum NotificationSeverityLevel {
  Info = "Info",
  Warning = "Warning",
  Critical = "Critical",
  Error = "Error",
}

export enum NotificationsFilterAsRead {
  All = "all",
  Read = "read",
  Unread = "unread",
}

export const NotificationsMessegeFilterStatus = {
  [NotificationsFilterAsRead.All]: undefined,
  [NotificationsFilterAsRead.Read]: true,
  [NotificationsFilterAsRead.Unread]: false,
};
