import Svg, { Path } from "react-native-svg";

const DownloadIcon = ({ size = 24, color = "#2B2E32" }) => (
  <Svg width={size} height={size} fill="none" viewBox="0 0 24 24">
    <Path
      d="M12.75 4.49999C12.75 3.53272 11.25 3.53272 11.25 4.49999V14.6893L7.53033 10.9697C6.84637 10.2857 5.78571 11.3464 6.46967 12.0303L11.4697 17.0303C11.7495 17.3101 12.2329 17.3278 12.5303 17.0303L17.5303 12.0303C18.2143 11.3464 17.1536 10.2857 16.4697 10.9697L12.75 14.6893V4.49999Z"
      fill={color}
    />
    <Path
      d="M4.75 17.5V19.5C4.75 20.1808 5.31917 20.75 6 20.75H18C18.6808 20.75 19.25 20.1808 19.25 19.5V17.5C19.25 16.5327 20.75 16.5327 20.75 17.5V19.5C20.75 20.9978 19.4978 22.25 18 22.25H6C4.50217 22.25 3.25 20.9978 3.25 19.5V17.5C3.25 16.5327 4.75 16.5327 4.75 17.5Z"
      fill={color}
    />
  </Svg>
);

export default DownloadIcon;
