import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import { TimeSystem } from "@models/settings/user-settings-store";
import { i18n, Language } from "../i18n";

require("dayjs/locale/he");
require("dayjs/locale/fr");

dayjs.extend(utc);

export enum DateTimeFormats {
  DateYMD = "YYYY-MM-DD",
  DateDMY = "D MMM YYYY",
  DateDM = "D MMM",
  DateMDY = "MMM D YYYY",
  DateTime = "YYYY-MM-DDTHH:mm:ss",
  TimeHM = "HH:mm",
  TimeHMA = "h:mm a",
  TimeHMS = "HH:mm:ss",
  DateTimeDM = "D MMM HH:mm",
}

const TIME_ZONE_SUFFIX = "000Z";
export const SECONDS_DAY = 86400;

export type TimeProps = {
  hours: string;
  minutes: string;
  seconds: string;
};

export const pad = (num): string => (num < 10 ? `0${num}` : num.toString());

const getTimeFormat = (system?: TimeSystem) =>
  system === TimeSystem.hours12
    ? DateTimeFormats.TimeHMA
    : DateTimeFormats.TimeHM;

export const timeFormat = (value: any, system?: TimeSystem) => {
  return dayjs(value).format(getTimeFormat(system));
};

export const dateTimeFormat = (
  value: any,
  format: string,
  system?: TimeSystem,
) => {
  const preparedFormat =
    format === DateTimeFormats.DateTimeDM
      ? `D MMM ${getTimeFormat(system)}`
      : format;

  return dayjs(value)
    .locale(i18n.locale || Language.en)
    .format(preparedFormat);
};

// const timePropsToTime = (timeProps: TimeProps, timeSystem?: TimeSystem): string => {
//   const H = Number(timeProps.hours);
//   const i = Number(timeProps.minutes);
//
//   if (timeSystem === TimeSystem.hours12) {
//     const ampm = H >= 12 ? "pm" : "am";
//     const hours = H % 12 || 12;
//     return `${hours}:${pad(i)} ${ampm}`;
//   }
//   return `${pad(H)}:${pad(i)}`;
// };

const prepareUTCValue = (value: string) => {
  if (typeof value !== "string") return value;

  const newValue = value.replace("+00:00", "");
  if (newValue.includes("Z")) return newValue;
  if (!newValue.includes(".000")) return `${newValue}.${TIME_ZONE_SUFFIX}`;
  return `${newValue.split(".000")[0]}.000Z`;
};

export const secondsToText = (
  seconds?: number,
  format: "duration" | "time" | "timeWithSeconds" = "timeWithSeconds",
  timeSystem: TimeSystem = TimeSystem.hours24,
): string => {
  if (seconds === undefined) return "";

  const H = Math.floor(seconds / 3600);
  const i = Math.floor((seconds % 3600) / 60);
  const s = seconds % 60;

  let result = "";
  switch (format) {
    case "duration":
      if (H) result += `${H} h `;
      if (i) result += `${i} min `;
      if (s) result += `${s} sec`;
      return result;
    case "timeWithSeconds":
      return `${pad(H)}:${pad(i)}:${pad(s)}`;
    default:
      if (timeSystem === TimeSystem.hours12) {
        const ampm = H >= 12 ? "pm" : "am";
        const hours = H % 12 || 12;
        return `${hours}:${pad(i)} ${ampm}`;
      }
      return `${pad(H)}:${pad(i)}`;
  }
};

export const dateStringToTimeProps = (value: string): TimeProps | undefined => {
  const ms = Date.parse(prepareUTCValue(value));
  if (!ms) return undefined;
  const date = new Date(ms);

  return {
    hours: pad(date.getHours()),
    minutes: pad(date.getMinutes()),
    seconds: pad(date.getSeconds()),
  };
};

export const secondsToTimeProps = (seconds?: number): TimeProps => {
  if (seconds === undefined) return undefined;

  const H = Math.floor(seconds / 3600);
  const i = Math.floor((seconds % 3600) / 60);
  const s = seconds % 60;

  return {
    hours: pad(H),
    minutes: pad(i),
    seconds: pad(s),
  };
};

export const dateTimeToTime = (value: string, system?: TimeSystem): string => {
  const ms = Date.parse(prepareUTCValue(value));
  if (!ms) return "";

  return timeFormat(ms, system);
};

export const timePropsToDateString = (timeProps: TimeProps): string => {
  const hours = timeProps ? Number(timeProps.hours) : 0;
  const minutes = timeProps ? Number(timeProps.minutes) : 0;
  const seconds = timeProps ? Number(timeProps.seconds) : 0;

  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(seconds);
  date.setMilliseconds(0);

  return date.toISOString();
};

export const timePropsToSeconds = (timeProps: TimeProps): number => {
  if (!timeProps) {
    return null;
  }

  return (
    Number(timeProps.hours) * 3600 +
    Number(timeProps.minutes) * 60 +
    Number(timeProps.seconds)
  );
};

export const getDuration = (
  startAt?: string,
  endAt?: string,
): number | undefined => {
  if (!startAt || !endAt) return undefined;

  const startMs = Date.parse(prepareUTCValue(startAt));
  const endMs = Date.parse(prepareUTCValue(endAt));
  if (!startMs || !endMs) return undefined;

  return (endMs - startMs) / 1000;
};

export const addSecondsToDate = (
  dateTime: string | number | Date | undefined,
  seconds: number,
): string =>
  `${dayjs(dateTime)
    .add(seconds, "second")
    .utc()
    .format(DateTimeFormats.DateTime)}.${TIME_ZONE_SUFFIX}`;

export const dateTimeHaveTimeToSeconds = (value: string): number => {
  return timePropsToSeconds(dateStringToTimeProps(value));
};

export type TPeriod = {
  startDate: string;
  endDate: string;
};

export const getPeriodWithTime = (period: TPeriod): TPeriod => {
  const endDateIsNow =
    period.endDate === dayjs().format(DateTimeFormats.DateYMD);

  const daysDiff = dayjs(period.endDate).diff(period.startDate, "days");

  return endDateIsNow && daysDiff <= 1
    ? {
        // startDate: setCurrentTime(period.startDate).toDate().toISOString(),
        startDate: dayjs(period.startDate)
          .startOf("day")
          .toDate()
          .toISOString(),
        endDate: setCurrentTime(period.endDate).toDate().toISOString(),
      }
    : {
        startDate: dayjs(period.startDate)
          .startOf("day")
          .toDate()
          .toISOString(),
        endDate: dayjs(period.endDate).endOf("day").toDate().toISOString(),
      };
};

const setCurrentTime = (value: string | Date) =>
  dayjs(value)
    .hour(dayjs().hour())
    .minute(dayjs().minute())
    .second(dayjs().second());
