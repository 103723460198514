import { I18nManager, Platform } from "react-native";
import RNRestart from "react-native-restart";

export const forceRTL = (rtl: boolean, restart = false) => {
  I18nManager.allowRTL(rtl);
  I18nManager.forceRTL(rtl);
  // TODO don`t work in web !!!
  if (restart && Platform.OS !== "web") {
    RNRestart.Restart();
  }
};
