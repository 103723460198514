import * as React from "react";
import Svg, { Path } from "react-native-svg";

export default function SoilMoistureIcon({ color = "#6F6F6F", size = 24 }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2047 1.8119C12.067 1.59576 11.8285 1.46488 11.5722 1.46484C11.316 1.46481 11.0774 1.59563 10.9397 1.81173L7.59683 7.05642C7.58392 7.07668 7.57206 7.09741 7.56127 7.11855C7.16941 7.8229 6.96399 8.61804 6.96436 9.42634C6.96473 10.2454 7.1764 11.0508 7.57916 11.7617C7.98197 12.4727 8.5622 13.0649 9.26301 13.4774C9.96394 13.8899 10.7601 14.1077 11.5715 14.1077C12.3829 14.1077 13.1791 13.8899 13.88 13.4774C14.5808 13.0649 15.161 12.4727 15.5638 11.7617C15.9666 11.0508 16.1783 10.2454 16.1786 9.42634C16.179 8.60727 15.9681 7.8017 15.566 7.09043C15.5595 7.07897 15.5527 7.06768 15.5456 7.05658L12.2047 1.8119ZM8.46436 9.42566C8.4641 8.86734 8.60717 8.31952 8.87824 7.83676L11.572 3.61052L14.2693 7.84494C14.5374 8.3257 14.6789 8.87049 14.6786 9.42566C14.6784 9.98713 14.5332 10.5378 14.2587 11.0223C13.9843 11.5067 13.5908 11.9071 13.1191 12.1847C12.6476 12.4622 12.1139 12.6077 11.5715 12.6077C11.0291 12.6077 10.4954 12.4622 10.0239 12.1847C9.55221 11.9071 9.15868 11.5067 8.88426 11.0223C8.60979 10.5378 8.46461 9.98713 8.46436 9.42566ZM17.5713 11.75C17.1571 11.75 16.8213 12.0858 16.8213 12.5C16.8213 12.9142 17.1571 13.25 17.5713 13.25H20.5713C20.9855 13.25 21.3213 12.9142 21.3213 12.5C21.3213 12.0858 20.9855 11.75 20.5713 11.75H17.5713ZM2.57129 17.3203C2.15708 17.3203 1.82129 17.6561 1.82129 18.0703C1.82129 18.4845 2.15708 18.8203 2.57129 18.8203H20.5713C20.9855 18.8203 21.3213 18.4845 21.3213 18.0703C21.3213 17.6561 20.9855 17.3203 20.5713 17.3203H2.57129ZM1.82129 12.5C1.82129 12.0858 2.15708 11.75 2.57129 11.75H5.57129C5.9855 11.75 6.32129 12.0858 6.32129 12.5C6.32129 12.9142 5.9855 13.25 5.57129 13.25H2.57129C2.15708 13.25 1.82129 12.9142 1.82129 12.5Z"
      />
    </Svg>
  );
}
