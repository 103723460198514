import React, { useCallback, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { StyleSheet } from "react-native";
import { StackScreenProps } from "@react-navigation/stack";

import { ModulesNavigatorParamList } from "@screens/Modules/navigation";
import { Screen } from "@components/index";
import { TopBar } from "@components/layout";

import { OnboardingStep } from "@components/common";
import { translate } from "../../../../../i18n";
import { ONBOARDING_STEPS } from "./data";

const styles = StyleSheet.create({
  screen: {
    flex: 1,
  },
});

export const ModuleAddManualOnboardingScreen: React.FC<
  StackScreenProps<ModulesNavigatorParamList, "module-add-manual-onboarding">
> = observer(({ navigation }) => {
  const afterEnd = useCallback(() => {
    navigation.navigate("module-add-manual");
  }, []);

  const actions = useMemo(
    () => [
      {
        key: "Onboarding-close",
        text: translate("Onboarding.Skip"),
        onPress: afterEnd,
      },
    ],
    [],
  );

  return (
    <Screen
      style={styles.screen}
      header={<TopBar title="" actions={actions} />}
    >
      <OnboardingStep
        data={ONBOARDING_STEPS}
        afterEnd={afterEnd}
        labelLastButton={translate("common.start")}
        navigation={navigation}
      />
    </Screen>
  );
});
