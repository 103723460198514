import React, { useMemo } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import { observer } from "mobx-react-lite";

import { Text, FloatingButton, ButtonType, Heading } from "@components/ui";
import { useStores } from "@models/index";

import { spacing } from "../../../../theme";
import { useAppTheme } from "../../../../hooks";
import { translate } from "../../../../i18n";
import { CALIBRATION_STEPS_PROPS } from "../constants";
import { CalibrationStep } from "../types";
import { YoutubePreviewLink } from "./YoutubePreviewLink";

const createStyles = (theme: any) =>
  StyleSheet.create({
    container: {
      padding: spacing[4],
    },
    containerInner: {
      paddingBottom: spacing[8] + spacing[6],
    },
    title: {
      marginBottom: spacing[4],
    },
    description: {
      fontSize: 16,
      color: theme.colors.subtitle,
      marginBottom: spacing[2],
    },
    item: {
      fontSize: 16,
      marginLeft: spacing[2],
      marginBottom: spacing[1],
    },
  });

type StepPrepareInfoProps = {
  title: string;
  youtubeId?: string;
  items?: Array<string>;
  onSubmit: () => void;
  submitLabel: string;
  submitType?: ButtonType;
};

export const StepPrepareInfo = ({
  title,
  youtubeId,
  items,
  onSubmit,
  submitLabel,
  submitType = "warning",
}: StepPrepareInfoProps) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <>
      <ScrollView style={styles.container}>
        <View style={styles.containerInner}>
          {title ? (
            <Heading level={2} style={styles.title}>
              {title}
            </Heading>
          ) : null}

          {youtubeId ? <YoutubePreviewLink youtubeId={youtubeId} /> : null}

          <Heading level={2} style={styles.title}>
            {translate("Sensors.Calibration.PrepareInfo.contentTitle")}
          </Heading>

          <Text style={styles.description}>
            {translate("Sensors.Calibration.PrepareInfo.description")}
          </Text>

          {items?.map((item, index) => (
            <Text key={`cpii-${index}`} style={styles.item}>
              {index + 1}. {item}
            </Text>
          )) || null}
        </View>
      </ScrollView>

      <FloatingButton onPress={onSubmit} type={submitType}>
        {submitLabel}
      </FloatingButton>
    </>
  );
};

export const CalibrationStepPrepareInfo = observer(() => {
  const { sensorCalibration } = useStores();

  const sensorTypeProps =
    CALIBRATION_STEPS_PROPS[sensorCalibration.sensorType]?.[
      CalibrationStep.PrepareInfo
    ] || {};

  return (
    <StepPrepareInfo
      submitLabel={translate("Sensors.Calibration.goToCalibration")}
      onSubmit={sensorCalibration.nextStep}
      {...sensorTypeProps}
    />
  );
});
