import {
  applySnapshot,
  flow,
  getSnapshot,
  Instance,
  SnapshotOut,
  types,
} from "mobx-state-tree";

import { withEnvironment } from "@models/extensions/with-environment";
import { Environment } from "@models/environment";
import { RootStoreModel } from "@models/root-store/root-store";
import {
  displaySensorValue,
  inputSensorValue,
  Sensor,
  SensorState,
} from "@models/sensor/sensor";
import { Units } from "@models/settings/user-settings-store";
import { Device } from "@models/device/device";
import { SensorTypeName } from "@models/sensor/constants";

import { sortDataByTime } from "../../utils/sortDataBy";
import { translate } from "../../i18n";
import { RulesApi } from "../../services/api/rules-api";
import { withStatus } from "../extensions/with-status";
import { withRootStore } from "../extensions/with-root-store";

export const MIN_DURATION_SEC = 10;

const sensorDataValue = (
  sensor: Sensor | undefined,
  value: number | undefined,
  units: Units,
): number | undefined => {
  if (!sensor || value === undefined) return undefined;
  return parseFloat(
    displaySensorValue({
      sensorType: sensor.type,
      value,
      units,
      precision: sensor.numberOfDecimals,
    }),
  );
};

/* eslint-disable @typescript-eslint/no-shadow */
export enum RuleType {
  Schedule = "schedule",
  Sensor = "sensor",
  Ph = "ph",
  Ec = "ec",
  DimmerAuto = "dimmer_auto",
  DimmerManual = "dimmer_manual",
}
/* eslint-enable @typescript-eslint/no-shadow */

export enum RuleDirection {
  "Up" = "up",
  "Down" = "down",
}

export enum RuleState {
  "Error" = "Error",
  "Deleting" = "Deleting",
  "Synchronizing" = "Synchronizing",
  "Synchronized" = "Synchronized",
}

export type RuleScheduleItem = Instance<typeof RuleScheduleModel>;
export type RuleScheduleItemSnapshot = SnapshotOut<typeof RuleScheduleModel>;

export type SensorRuleData = Pick<
  RuleFormData,
  | "ruleType"
  | "sensorUid"
  | "valueLow"
  | "valueHigh"
  | "direction"
  | "dosingTime"
  | "tankSize"
  | "mixTime"
  | "timeToSetPoint"
  | "cyclesToSetPoint"
>;

export type RuleFormData = {
  uid?: string;
  deviceUid?: string;
  ruleType?: RuleType;
  schedule?: RuleScheduleItemSnapshot[];
  sensorUid?: string;
  valueLow?: number;
  valueHigh?: number;
  direction?: RuleDirection;
  dosingTime?: number;
  tankSize?: number;
  mixTime?: number;
  isRuleOn?: boolean;

  analogControlPinValue?: number;
  valueTarget?: number;
  startDayTime?: string;
  endDayTime?: string;
  timeToTarget?: number;
  controlSensorUid?: string;
  controlSensorValue?: number;

  timeToSetPoint?: number;
  cyclesToSetPoint?: number;
};

const NEW_UID = "new";
const DEFAULT_RULE_DATA = {
  isAdvanced: false,
  isRuleOn: true,
};

export type DimmerRuleData = {
  startDayTime?: string;
  endDayTime?: string;
  sensorUid?: string;
  valueTarget?: number;
  timeToTarget?: number;
  controlSensorUid?: string;
  controlSensorValue?: number;
  analogControlPinValue?: number;
};

export const RuleScheduleModel = types.model("RuleScheduleItem").props({
  // id: types.identifierNumber,
  // ruleId: types.maybe(types.identifier),
  // repeatEverySeconds: types.maybeNull(types.number),
  startAt: types.maybeNull(types.string),
  endAt: types.maybeNull(types.string),
});

const RuleAdvancedValueModel = types.model("RuleAdvancedValue").props({
  startDay: types.maybe(types.string),
  endDay: types.maybe(types.string),
  dayValueLow: types.maybe(types.number),
  dayValueHigh: types.maybe(types.number),
  dayValue: types.maybe(types.number),
  nightValueLow: types.maybe(types.number),
  nightValueHigh: types.maybe(types.number),
  nightValue: types.maybe(types.number),
});

export enum RuleTypesValue {
  All = "all",
}

export const RuleTypesModel = types.model("RuleTypesModel").props({
  type: types.enumeration<RuleType>("RuleType", Object.values(RuleType)),
  sensors: types.maybeNull(
    types.array(
      types.string ||
        types.enumeration<RuleTypesValue>(
          "RuleTypesValue",
          Object.values(RuleTypesValue),
        ),
    ),
  ),
  controlSensors: types.maybeNull(
    types.array(
      types.string ||
        types.enumeration<RuleTypesValue>(
          "RuleTypesValue",
          Object.values(RuleTypesValue),
        ),
    ),
  ),
  availableDevices: types.maybeNull(
    types.array(
      types.string ||
        types.enumeration<RuleTypesValue>(
          "RuleTypesValue",
          Object.values(RuleTypesValue),
        ),
    ),
  ),
});

export const RuleModel = types
  .model("Rule")
  .props({
    uid: types.identifier,
    deviceUid: types.string,
    ruleType: types.enumeration<RuleType>("RuleType", Object.values(RuleType)),
    isRuleOn: types.boolean,
    state: types.maybeNull(types.string), // RuleState
    modifiedAt: types.maybeNull(types.string),

    sensorUid: types.maybeNull(types.string),
    valueLow: types.maybeNull(types.number),
    valueHigh: types.maybeNull(types.number),
    direction: types.maybeNull(
      types.enumeration<RuleDirection>(
        "RuleDirection",
        Object.values(RuleDirection),
      ),
    ),
    dosingTime: types.maybeNull(types.number),
    tankSize: types.maybeNull(types.number),
    mixTime: types.maybeNull(types.number),
    valueTarget: types.maybeNull(types.number),
    isAdvanced: types.maybeNull(types.boolean),
    schedule: types.optional(types.array(RuleScheduleModel), []),
    advancedRuleValues: types.optional(types.array(RuleAdvancedValueModel), []),
    analogControlPinValue: types.maybeNull(types.number),
    startDayTime: types.maybeNull(types.string),
    endDayTime: types.maybeNull(types.string),
    timeToTarget: types.maybeNull(types.number),
    controlSensorUid: types.maybeNull(types.string),
    controlSensorValue: types.maybeNull(types.number),
    timeToSetPoint: types.maybeNull(types.number),
    cyclesToSetPoint: types.maybeNull(types.number),
  })
  .extend(withEnvironment)
  .extend(withStatus)
  .extend(withRootStore)
  .views((self) => ({
    get ruleRootStore(): SensorRuleData {
      return (self.environment?.rootStore ||
        self.rootStore) as typeof RootStoreModel;
    },
    get sortedSchedule(): RuleScheduleItem[] {
      return sortDataByTime(self.schedule.toJSON() || [], "startAt");
    },
    get isNew(): boolean {
      return self.uid === NEW_UID;
    },
    get isSynchronizing(): boolean {
      return self.state === RuleState.Synchronizing;
    },
  }))
  .views((self) => ({
    get device(): Device | undefined {
      return self.ruleRootStore.deviceStore.devices.get(self.deviceUid);
    },
    get sensor() {
      return self.ruleRootStore.sensorStore.getSensor(self.sensorUid);
    },
    // get controlSensor() {
    //   return self.ruleRootStore.sensorStore.getSensor(
    //     self.controlSensorUid,
    //   );
    // },
    get sortedSensors(): Sensor[] {
      return self.ruleRootStore.sensorStore.sortedSensors;
    },
    get ruleTypes() {
      return self.ruleRootStore.deviceStore.ruleTypes;
    },
  }))
  .actions((self) => {
    const rulesApi = new RulesApi(self.environment.api);

    const refetchRules = () => {
      return self.device?.fetchRules({ force: true });
    };

    const setRuleData = flow(function* (data: RuleFormData) {
      const { state, modifiedAt, ...ruleData } = {
        ...getSnapshot(self),
        ...data,
      };

      if (ruleData.sensorUid && ruleData.ruleType !== RuleType.Schedule) {
        const { units } = self.ruleRootStore.settingsStore;
        const sensor = self.ruleRootStore.sensorStore.getSensor(
          ruleData.sensorUid,
        );
        if (!sensor) {
          self.setStatusError([
            `setRuleData: Sensor with uid ${ruleData.sensorUid} not found`,
          ]);
        }

        ruleData.valueLow = inputSensorValue(
          ruleData.valueLow,
          sensor.type,
          units,
        );
        ruleData.valueHigh = inputSensorValue(
          ruleData.valueHigh,
          sensor.type,
          units,
        );

        // TODO: Liters/Gallons
        // ruleData.dosing = inputSensorValue(ruleData.dosing, sensor.type, units);
        // ruleData.tankSize = inputSensorValue(ruleData.tankSize, sensor.type, units);
      }

      self.setStatusPending();
      const result =
        !self.uid || self.uid === NEW_UID
          ? yield rulesApi.createRule(ruleData)
          : yield rulesApi.updateRule(self.uid, ruleData);

      if (result.kind === "ok") {
        self.setStatusDone();

        if (result.data && self.uid !== NEW_UID) {
          applySnapshot(self, result.data); // Problem with update uid for new rule
        } else {
          yield refetchRules();
        }

        yield self.device?.refetch();
        // if (refetch) yield refetchRules();
      } else {
        self.setStatusError(
          result.errors?.length ? result.errors : ["An unknown error occurred"],
        );
      }

      return result;
    });

    const setScheduleData = async (schedule: RuleScheduleItemSnapshot[]) => {
      if (!schedule?.length) {
        return {
          kind: "error",
          errors: [translate("Devices.Rules.scheduleCantBeEmpty")],
        };
      }

      const ruleData: RuleFormData = {
        schedule,
        sensorUid: null,
      };

      return setRuleData(ruleData);
    };

    const setSensorData = async (data: RuleFormData) => {
      const ruleData: RuleFormData = {
        ...data,
        schedule: [],
      };
      self.sensorUid = ruleData.sensorUid; // SET IMPORTANT for getting self.sensor !!!

      if (
        self.device?.isDosingPump &&
        [SensorTypeName.PH, SensorTypeName.EC].includes(self.sensor?.type)
      ) {
        ruleData.ruleType = self.sensor.type;
        ruleData.timeToSetPoint = null;
      } else {
        ruleData.ruleType = RuleType.Sensor;
        ruleData.cyclesToSetPoint = null;
      }

      return setRuleData(ruleData);
    };

    const setDimmerData = async (data: DimmerRuleData) => {
      const { units } = self.ruleRootStore.settingsStore;
      const sensor = self.ruleRootStore.sensorStore.getSensor(data.sensorUid);
      const valueTarget = sensor
        ? inputSensorValue(data.valueTarget, sensor.type, units)
        : undefined;

      const controlSensor = self.ruleRootStore.sensorStore.getSensor(
        data.controlSensorUid,
      );
      const controlSensorValue = controlSensor
        ? inputSensorValue(data.controlSensorValue, controlSensor.type, units)
        : undefined;

      const ruleData: RuleFormData = {
        ...data,
        valueTarget,
        controlSensorValue,
      };

      return setRuleData(ruleData);
    };

    const switchOn = async () => {
      return setRuleData({ isRuleOn: true });
    };

    const switchOff = async () => {
      return setRuleData({ isRuleOn: false });
    };

    const deleteRule = flow(function* () {
      self.setStatusPending();
      const result = yield rulesApi.deleteRule(self.uid);

      if (result.kind === "ok") {
        self.setStatusDone();
        yield self.device?.refetch();
        yield refetchRules();
      } else {
        self.setStatusError();
        if (__DEV__) console.log(result.kind);
      }
    });

    return {
      setScheduleData,
      setSensorData,
      switchOn,
      switchOff,
      deleteRule,
      setDimmerData,
    };
  })

  .views((self) => {
    const getSensorsForDeviceRule = (
      sensorTypes: Array<SensorTypeName | RuleTypesValue>,
    ): Sensor[] => {
      if (!sensorTypes) {
        return [];
      }

      if (sensorTypes.includes(RuleTypesValue.All)) {
        return self.sortedSensors;
      }

      return (
        self.sortedSensors.filter((sensor) =>
          sensorTypes.includes(sensor?.type),
        ) || []
      );
    };

    return {
      get sensorsForDevice(): Sensor[] | undefined {
        const ruleType = [RuleType.Ph, RuleType.Ec].includes(self.ruleType)
          ? RuleType.Sensor
          : self.ruleType;

        const sensorTypes = self.ruleTypes.find(
          (item) => item.type === ruleType,
        )?.sensors;

        return getSensorsForDeviceRule(sensorTypes);
      },

      get controlSensorsForDevice(): Sensor[] | undefined {
        const controlSensorTypes = self.ruleTypes.find(
          (ruleType) => ruleType.type === self.ruleType,
        )?.controlSensors;

        return getSensorsForDeviceRule(controlSensorTypes);
      },
    };
  })

  .views((self) => ({
    get defaultTimeToSetPoint(): number | undefined {
      return self.ruleRootStore.deviceStore.deviceTypes.find(
        (type) => type.name === self.device.type,
      )?.timeToSetPoint;
    },
    get defaultCyclesToSetPoint(): number | undefined {
      return self.ruleRootStore.deviceStore.deviceTypes.find(
        (type) => type.name === self.device.type,
      )?.cyclesToSetPoint;
    },
  }))

  .views((self) => ({
    get sensorData(): SensorRuleData {
      const { units } = self.ruleRootStore.settingsStore;
      const sensor = self.ruleRootStore.sensorStore.getSensor(self.sensorUid);

      // TODO: Liters/Gallons
      const dosingTime = self.dosingTime === null ? undefined : self.dosingTime;
      const tankSize = self.tankSize === null ? undefined : self.tankSize;

      return {
        ruleType: self.ruleType,
        sensorUid: self.sensorUid === null ? undefined : self.sensorUid,
        valueLow: sensorDataValue(sensor, self.valueLow, units),
        valueHigh: sensorDataValue(sensor, self.valueHigh, units),
        direction: self.direction ? self.direction : RuleDirection.Up,
        dosingTime,
        tankSize,
        mixTime: self.mixTime === null ? undefined : self.mixTime,

        timeToSetPoint: self.timeToSetPoint,
        cyclesToSetPoint: self.cyclesToSetPoint,
      };
    },

    get dimmerData(): DimmerRuleData {
      const { units } = self.ruleRootStore.settingsStore;
      const sensor = self.ruleRootStore.sensorStore.getSensor(self.sensorUid);
      const controlSensor = self.ruleRootStore.sensorStore.getSensor(
        self.controlSensorUid,
      );

      return {
        startDayTime:
          self.startDayTime === null ? undefined : self.startDayTime,
        endDayTime: self.endDayTime === null ? undefined : self.endDayTime,
        sensorUid: self.sensorUid === null ? undefined : self.sensorUid,
        valueTarget: sensorDataValue(sensor, self.valueTarget, units),
        timeToTarget:
          self.timeToTarget === null ? undefined : self.timeToTarget,
        controlSensorUid:
          self.controlSensorUid === null ? undefined : self.controlSensorUid,
        controlSensorValue: sensorDataValue(
          controlSensor,
          self.controlSensorValue,
          units,
        ),
        analogControlPinValue: self.analogControlPinValue || 10,
      };
    },
  }))

  .views((self) => ({
    get errors(): string[] | undefined {
      if (self.ruleType === RuleType.Sensor) {
        const sensor = self.ruleRootStore.sensorStore.getSensor(self.sensorUid);
        if (sensor?.state === SensorState.Offline) {
          return [translate("Devices.Rules.errors.sensorIsOffline")];
        }
      }

      if (self.device?.isDimmer) {
        const controlSensor = self.ruleRootStore.sensorStore.getSensor(
          self.controlSensorUid,
        );
        if (controlSensor?.state === SensorState.Offline) {
          return [translate("Devices.Rules.errors.sensorIsOffline")];
        }
      }

      return undefined;
    },
  }));

type NewRuleProps = {
  deviceUid?: string;
  ruleType: RuleType;
};
const getNewRuleProps = ({ deviceUid, ruleType }: NewRuleProps) => ({
  ...DEFAULT_RULE_DATA,
  uid: NEW_UID,
  deviceUid,
  ruleType,
});

export type Rule = Instance<typeof RuleModel>;

export const createRule = (
  props: { deviceUid?: string; ruleType: RuleType },
  env: Environment | { rootStore: any },
): Rule => RuleModel.create(getNewRuleProps(props), env);

type RuleTypesMOBXType = Instance<typeof RuleTypesModel>;
export type TRuleTypes = RuleTypesMOBXType;
