import * as React from "react";
import Svg, { Path } from "react-native-svg";

export const StatusContinuouslySvg = ({ size = 45, color = "#2B2E32" }) => (
  <Svg width={size} height={size} viewBox="0 0 45 45" fill="none">
    <Path
      d="M22 22.8081C20.9941 24.6722 19.6455 26.3485 18.0184 27.7571C16.993 28.7363 15.6864 29.4032 14.264 29.6734C12.8415 29.9437 11.3671 29.8052 10.0271 29.2755C8.68708 28.7457 7.54174 27.8485 6.73593 26.6973C5.93011 25.5461 5.5 24.1927 5.5 22.8081C5.5 21.4235 5.93011 20.0701 6.73593 18.9189C7.54174 17.7677 8.68708 16.8705 10.0271 16.3407C11.3671 15.811 12.8415 15.6725 14.264 15.9428C15.6864 16.213 16.993 16.8799 18.0184 17.8591C19.6455 19.2677 20.9941 20.944 22 22.8081ZM22 22.8081C23.0059 20.944 24.3545 19.2677 25.9816 17.8591C27.007 16.8799 28.3136 16.213 29.736 15.9428C31.1585 15.6725 32.6329 15.811 33.9729 16.3407C35.3129 16.8705 36.4583 17.7677 37.2641 18.9189C38.0699 20.0701 38.5 21.4235 38.5 22.8081C38.5 24.1927 38.0699 25.5461 37.2641 26.6973C36.4583 27.8485 35.3129 28.7457 33.9729 29.2755C32.6329 29.8052 31.1585 29.9437 29.736 29.6734C28.3136 29.4032 27.007 28.7363 25.9816 27.7571C24.3545 26.3485 23.0059 24.6722 22 22.8081Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
