import { useEffect, useState } from "react";

import { Device } from "@models/device/device";
import { RuleWithDevice, useRules } from "./useRules";

type UseSensorRuledDevicesProps = {
  sensorUid: string;
};
type UseSensorRuledDevicesResult = {
  devices: Device[] | null;
  resetDevices: () => void;
};

export const useSensorRuledDevices = ({
  sensorUid,
}: UseSensorRuledDevicesProps): UseSensorRuledDevicesResult => {
  const { rules } = useRules();
  const [devices, setDevices] = useState<Device[]>([]);

  useEffect(() => {
    if (sensorUid && rules?.length) {
      setDevices(
        rules
          .filter((rule: RuleWithDevice) => rule.sensorUid === sensorUid)
          .map((rule) => rule.device),
      );
    } else {
      setDevices([]);
    }
  }, [sensorUid, rules]);

  const resetDevices = () => setDevices(null);

  return {
    devices,
    resetDevices,
  };
};
