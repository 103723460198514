import React, { useMemo, useState } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { useTheme } from "@react-navigation/native";

import { TimeSystem } from "@models/settings/user-settings-store";
import { Drawer } from "@components/ui/Drawer";

import { translate, TxKeyPath } from "../../i18n";
import { spacing } from "../../theme";
import { CustomSelectInput } from "./CustomSelectInput";
import { PickerInput } from "./PickerInput";

import {
  pad,
  secondsToText,
  TimeProps,
  timePropsToSeconds,
} from "../../utils/timeConverter";
import { displayHour } from "../../utils/display";
import range from "../../utils/range";

const createStyles = (theme: any) =>
  StyleSheet.create({
    inputs: {
      paddingVertical: spacing[3],
      marginHorizontal: -spacing[2],
      flexDirection: "row",
      justifyContent: "space-between",
    },
    column: {
      flex: 1,
      marginHorizontal: spacing[2],
    },
    pickerLabel: {
      textAlign: "center",
      width: "100%",
    },
    errorLabel: {
      color: theme.colors.error,
      marginTop: spacing[2],
      textAlign: "center",
    },

    timerInputs: {
      marginHorizontal: -spacing[3],
    },
    timerColumn: {
      marginHorizontal: 2,
    },
  });

const TIMER_MAX_HOURS = 48;

export const DEFAULT_TIME_PROPS = {
  hours: "00",
  minutes: "00",
  seconds: "00",
};

type TimeInputProps = {
  onChange: (timeProps?: TimeProps) => void;
  value?: TimeProps;
  timeSystem?: TimeSystem;
  displaySeconds?: boolean;
  style?: StyleProp<ViewStyle>;
};

export const TimeInput: React.FunctionComponent<TimeInputProps> = ({
  onChange,
  value: timeProps = DEFAULT_TIME_PROPS,
  timeSystem,
  displaySeconds,
  style,
}) => {
  const theme = useTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);
  // const [selectorError, setSelectorError] = useState<string>(null);

  const onChangeValue = (value, unit: keyof TimeProps) => {
    // const maxValue = unit === "hours" ? 23 : 59;
    // const newValue = Number(value) > maxValue ? maxValue : value;
    onChange({
      ...timeProps,
      [unit]: value,
    });
    // setSelectorError(null);
  };

  return (
    <View style={style}>
      {/* {selectorError ? <Text style={styles.errorLabel}>{selectorError}</Text> : null} */}
      <View style={styles.inputs}>
        <PickerInput
          txLabel="fields.hours"
          labelStyle={styles.pickerLabel}
          containerStyle={styles.column}
          selectedValue={Number(timeProps.hours)}
          onChange={(value) => onChangeValue(value, "hours")}
          items={range(0, 23).map((number) => ({
            value: number,
            label: displayHour(number, timeSystem),
          }))}
        />
        <PickerInput
          txLabel="fields.minutes"
          labelStyle={styles.pickerLabel}
          containerStyle={styles.column}
          selectedValue={Number(timeProps.minutes)}
          onChange={(value) => onChangeValue(value, "minutes")}
          items={range(0, 59).map((number) => ({
            value: number,
            label: pad(number),
          }))}
        />
        {displaySeconds && (
          <PickerInput
            txLabel="fields.seconds"
            labelStyle={styles.pickerLabel}
            containerStyle={styles.column}
            selectedValue={Number(timeProps.seconds)}
            onChange={(value) => onChangeValue(value, "seconds")}
            items={range(0, 59).map((number) => ({
              value: number,
              label: pad(number),
            }))}
          />
        )}
      </View>
    </View>
  );
};

type TimeInputPopupProps = {
  onChangeSubmit: (timeProps?: TimeProps) => void;
  value?: TimeProps;
  timeSystem?: TimeSystem;
  style?: StyleProp<ViewStyle>;
  txLabel?: TxKeyPath;
  errorText?: string;
  applyText?: string;
  displaySeconds?: boolean;
  disabled?: boolean;
};

export const TimeInputPopup: React.FunctionComponent<TimeInputPopupProps> = ({
  onChangeSubmit,
  value: currentValue,
  timeSystem,
  style,
  txLabel,
  errorText,
  applyText = translate("common.apply"),
  displaySeconds,
  disabled = false,
}) => {
  const [selectorIsOpen, setSelectorOpen] = useState(false);
  const openTimeSelector = () => {
    if (!disabled) {
      setSelectorOpen(true);
    }
  };
  const closeTimeSelector = () => setSelectorOpen(false);

  const [timeProps, setTimeProps] = useState<TimeProps>(
    currentValue || DEFAULT_TIME_PROPS,
  );

  const onChangeSubmitHandler = () => {
    // if (selectorError) {
    // } else {
    //   onChangeSubmit(timeProps)
    //   closeTimeSelector();
    // }
    onChangeSubmit(timeProps);
    closeTimeSelector();
  };

  const onChangeValue = (value: TimeProps) => {
    setTimeProps(value);
  };

  const displayValue = currentValue
    ? secondsToText(
        timePropsToSeconds(currentValue),
        displaySeconds ? "timeWithSeconds" : "time",
        timeSystem,
      )
    : "";

  return (
    <>
      <CustomSelectInput
        txLabel={txLabel}
        value={displayValue}
        errorText={errorText}
        onPress={openTimeSelector}
        style={style}
      />

      <Drawer
        visible={selectorIsOpen}
        okText={applyText}
        onOk={onChangeSubmitHandler}
        onDismiss={closeTimeSelector}
        style={{ minHeight: "60%" }}
      >
        <TimeInput
          value={timeProps}
          onChange={onChangeValue}
          timeSystem={timeSystem}
          displaySeconds={displaySeconds}
        />
      </Drawer>
    </>
  );
};

type TimerInputProps = {
  onChange: (timeProps?: TimeProps) => void;
  value?: TimeProps;
  style?: StyleProp<ViewStyle>;
  maxHours?: number;
};

export const TimerInput: React.FunctionComponent<TimerInputProps> = ({
  onChange,
  value: timeProps = DEFAULT_TIME_PROPS,
  style,
  maxHours = TIMER_MAX_HOURS,
}) => {
  const theme = useTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);

  const onChangeValue = (value, unit: keyof TimeProps) => {
    onChange({
      ...timeProps,
      [unit]: value,
    });
  };

  return (
    <View style={style}>
      <View style={[styles.inputs, styles.timerInputs]}>
        <PickerInput
          txLabel="fields.hours"
          labelStyle={styles.pickerLabel}
          containerStyle={[styles.column, styles.timerColumn]}
          selectedValue={Number(timeProps.hours)}
          onChange={(value) => onChangeValue(value, "hours")}
          items={range(0, maxHours).map((number) => ({
            value: number,
            label: pad(number),
          }))}
        />
        <PickerInput
          txLabel="fields.minutes"
          labelStyle={styles.pickerLabel}
          containerStyle={[styles.column, styles.timerColumn]}
          selectedValue={Number(timeProps.minutes)}
          onChange={(value) => onChangeValue(value, "minutes")}
          items={range(0, 59).map((number) => ({
            value: number,
            label: pad(number),
          }))}
        />
        <PickerInput
          txLabel="fields.seconds"
          labelStyle={styles.pickerLabel}
          containerStyle={[styles.column, styles.timerColumn]}
          selectedValue={Number(timeProps.seconds)}
          onChange={(value) => onChangeValue(value, "seconds")}
          items={range(0, 59).map((number) => ({
            value: number,
            label: pad(number),
          }))}
        />
      </View>
    </View>
  );
};
