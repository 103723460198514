import { StyleSheet, View } from "react-native";
import { shadow } from "react-native-paper";

import { SensorCardView } from "@screens/Sensors/List/components/SensorCard";

import { spacing } from "../../../../../theme";

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    borderRadius: spacing[4],
    minWidth: "45%",
    top: 76,
    left: 16,
    ...(shadow(5) as any),
  },
});

const sensor = {
  uid: "SensorUnusualReading",
  type: "air_temp",
  displayType: "Air temperature",
  name: "Temperature 1",
  displayName: "Temperature 1",
  value: 35,
  displayValue: 35,
  unitName: "\u00B0C",
  isOutOfRange: true,
  iconName: "AirTemperatureIcon",
};

export const SensorUnusualReading = () => (
  <View style={styles.container}>
    <SensorCardView sensor={sensor as any} />
  </View>
);
