import React, { memo } from "react";
import { StyleSheet } from "react-native";

import { Checkbox, Text } from "@components/ui";
import { CustomControl } from "@components/inputs";
import { FilterItemComponentProps } from "@components/screen/Filter";

import { spacing } from "../../../../theme";
import * as DeviceIcons from "../../../../svgs/devices";
import { NoneIcon } from "../../../../svgs";
import { useAppTheme } from "../../../../hooks";
import { normalizeFontSize } from "../../../../utils/sizes";

const styles = StyleSheet.create({
  item: {
    height: 56,
    marginBottom: spacing[2],
  },
  text: {
    fontSize: normalizeFontSize(14, 16),
  },
});

export const FilterItem = memo(
  ({
    item: device,
    drag,
    isChecked,
    handleChangeCheckbox,
  }: FilterItemComponentProps) => {
    const theme = useAppTheme() as any;
    const Icon = DeviceIcons[device.iconName] || NoneIcon;
    const onPress = () => {
      handleChangeCheckbox(device.uid);
    };

    return (
      <CustomControl
        onLongPress={drag}
        key={`filter-item-${device.uid}`}
        onPress={onPress}
        style={styles.item}
        icon={<Icon size={24} color={theme.colors.label} />}
        control={
          <Checkbox
            status={isChecked ? "checked" : "unchecked"}
            onPress={onPress}
          />
        }
      >
        <Text style={styles.text} numberOfLines={1} ellipsizeMode="tail">
          {device.displayName}
        </Text>
      </CustomControl>
    );
  },
);
