import React, { useEffect, useMemo, useState } from "react";
import { StyleSheet, View } from "react-native";
import * as LocalAuthentication from "expo-local-authentication";

import { ErrorsAlert } from "@components/ui";
import { SwitchInput } from "@components/inputs";

import { translate } from "../../../../i18n";
import { useAppTheme } from "../../../../hooks";
import { useLocalAuthentication } from "../../../../hooks/useLocalAuthentication";
import { saveSecureValue, SecureValueKey } from "../../../../utils/storage";
import { FaceIdIcon, TouchIdIcon } from "../../../../svgs";
import { spacing } from "../../../../theme";

const createStyles = (theme: any) =>
  StyleSheet.create({
    container: {
      width: "100%",
      flexDirection: "row",
      marginBottom: spacing[3],
      paddingVertical: spacing[3],
      paddingHorizontal: spacing[5],
      borderRadius: spacing[4],
      alignItems: "center",
      backgroundColor: theme.colors.cardBG,
    },
    switchContainer: {
      marginTop: 0,
      marginVertical: 0,
    },
    switchLabel: {
      ...theme.fonts.medium,
      color: theme.colors.text,
      fontSize: 16,
      marginBottom: 0,
    },
    icon: {
      marginRight: spacing[5],
    },
  });

export const SwitchAuthentication = () => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  const [value, setValue] = useState<boolean>();
  const [errors, setErrors] = useState<Array<string> | null>();

  const { authenticationType, useBiometricToSignIn } = useLocalAuthentication();

  useEffect(() => {
    setValue(Boolean(authenticationType && useBiometricToSignIn));
  }, [authenticationType, useBiometricToSignIn]);

  const onChange = (newValue: boolean) => {
    if (authenticationType) {
      saveSecureValue(SecureValueKey.UseBiometricToSignIn, newValue);
      setValue(newValue);
    } else {
      setErrors([
        translate("Authentication.errors.biometricAuthenticationIsNotEnabled"),
      ]);
    }
  };

  const onCancel = () => setErrors(null);

  const isFaceId =
    authenticationType ===
    LocalAuthentication.AuthenticationType.FACIAL_RECOGNITION;

  const BiometricIcon = isFaceId ? FaceIdIcon : TouchIdIcon;

  return (
    <View style={styles.container}>
      <View style={styles.icon}>
        <BiometricIcon color={theme.colors.label} />
      </View>
      <SwitchInput
        label={
          isFaceId
            ? translate("Authentication.useFaceId")
            : translate("Authentication.useFingerprint")
        }
        labelStyle={styles.switchLabel}
        containerStyle={styles.switchContainer}
        onChange={onChange}
        value={value}
      />

      <ErrorsAlert title="" errors={errors} onCancel={onCancel} />
    </View>
  );
};
