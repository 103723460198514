import { flow, types } from "mobx-state-tree";
import { Linking, Platform } from "react-native";
import * as semver from "semver";

import { withEnvironment } from "../extensions/with-environment";
import { SettingsApi } from "../../services/api/settings-api";

/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
const { version } = require("../../../package.json");
/* eslint-enable global-require */
/* eslint-enable @typescript-eslint/no-var-requires */

const LINK_UPDATE_IOS =
  "https://apps.apple.com/us/app/growdirector-3-pro/id6449358084";
const LINK_UPDATE_ANDROID =
  "https://play.google.com/store/apps/details?id=com.growdirector.mobileapp.growdirectorapp3d";

export const goToAppUpdate = () => {
  if (Platform.OS === "ios") {
    Linking.openURL(LINK_UPDATE_IOS);
  }
  if (Platform.OS === "android") {
    Linking.openURL(LINK_UPDATE_ANDROID);
  }
};

export const VersionsModel = types
  .model("Versions")
  .props({
    api: types.maybeNull(types.string),
    firmware: types.maybeNull(types.string),
    android: types.maybeNull(types.string),
    ios: types.maybeNull(types.string),
  })
  .extend(withEnvironment)
  .views((self) => ({
    get latestAppVersion(): string | null {
      switch (Platform.OS) {
        case "android":
          return semver.valid(self.android);
        case "ios":
          return semver.valid(self.ios);
        case "web":
          return semver.valid(self.android);
        default:
          return null;
      }
    },
    get currentAppVersion(): string {
      return semver.valid(version);
    },
  }))
  .views((self) => ({
    get isNewAppAvailable(): boolean | null {
      if (!self.currentAppVersion || !self.latestAppVersion) {
        return null;
      }

      return semver.gt(self.latestAppVersion, self.currentAppVersion);
    },
  }))
  .actions((self) => {
    const settingsApi = new SettingsApi(self.environment.api);

    const fetch = flow(function* () {
      const result = yield settingsApi.getVersions();

      if (result.kind === "ok") {
        self.android = result.data.android;
        self.api = result.data.api;
        self.firmware = result.data.firmware;
        self.ios = result.data.ios;
      }
    });

    const clear = () => {
      self.android = null;
      self.api = null;
      self.firmware = null;
      self.ios = null;
    };

    return {
      fetch,
      clear,
    };
  });
