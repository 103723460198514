import { translate } from "../../../../i18n";

import {
  CalibrationPrepareORPSvg,
  CalibrationCleanORPSvg,
  CalibrationORP256Svg,
  CalibrationORP475Svg,
  CalibrationORP256ProcessSvg,
  CalibrationORP475PrecessSvg,
  CalibrationORP256ErrorSvg,
  CalibrationORP475ErrorSvg,
} from "../../../../svgs/pictures/calibration/ORP";
import {
  CalibrationProcessStatus,
  CalibrationStep,
  TCalibrationStepProps,
} from "../types";

export const ORP_STEPS = [
  CalibrationStep.Prepare,
  CalibrationStep.Clean,
  CalibrationStep.Process1,
  CalibrationStep.Clean,
  CalibrationStep.Process2,
  CalibrationStep.Success,
];

export const ORP_STEPS_PROPS: TCalibrationStepProps = {
  [CalibrationStep.Prepare]: {
    Picture: CalibrationPrepareORPSvg,
    items: [
      translate("Sensors.Calibration.Prepare.paperTowel"),
      translate("Sensors.Calibration.Prepare.distilledOrTapWater"),
      translate("Sensors.Calibration.orp.Prepare.liquid256mV"),
      translate("Sensors.Calibration.orp.Prepare.liquid475mV"),
    ],
    // submitLabel: translate("common.next"),
  },

  [CalibrationStep.Clean]: {
    Picture: CalibrationCleanORPSvg,
    items: [
      translate("Sensors.Calibration.Clean.stirSensorInCleanWater"),
      translate("Sensors.Calibration.Clean.cleanAndDryWithPaperTowel"),
    ],
    alert: translate("Sensors.Calibration.Clean.pleaseTouchVeryCarefully"),
  },

  [CalibrationStep.Process1]: {
    Picture: CalibrationORP256Svg,
    // title: translate("Sensors.Calibration.orp.calibrationIn256mVLiquid"),
    title: translate("Sensors.Calibration.orp.calibrationIn"),
    items: [
      translate("Sensors.Calibration.orp.putIn256mVLiquid"),
      translate("Sensors.Calibration.pressCalibrate"),
    ],
    withInput: true,
    afterInputText: translate("Sensors.Calibration.orp.mVLiquid"),
  },

  [`${CalibrationStep.Process1}${CalibrationProcessStatus.Process}`]: {
    Picture: CalibrationORP256ProcessSvg,
    // title: translate("Sensors.Calibration.orp.calibrationIn256mVLiquid"),
    titleTx: "Sensors.Calibration.orp.calibrationInXLiquid",
    alert: translate("Sensors.Calibration.dontTouchSensor"),
  },

  [`${CalibrationStep.Process1}${CalibrationProcessStatus.Error}`]: {
    Picture: CalibrationORP256ErrorSvg,
    // title: translate("Sensors.Calibration.orp.calibrationIn256mVLiquid"),
    titleTx: "Sensors.Calibration.orp.calibrationInXLiquid",
  },

  [CalibrationStep.Process2]: {
    Picture: CalibrationORP475Svg,
    // title: translate("Sensors.Calibration.orp.calibrationIn475mVLiquid"),
    title: translate("Sensors.Calibration.orp.calibrationIn"),
    items: [
      translate("Sensors.Calibration.orp.putIn475mVLiquid"),
      translate("Sensors.Calibration.pressCalibrate"),
    ],
    withInput: true,
    afterInputText: translate("Sensors.Calibration.orp.mVLiquid"),
  },

  [`${CalibrationStep.Process2}${CalibrationProcessStatus.Process}`]: {
    Picture: CalibrationORP475PrecessSvg,
    // title: translate("Sensors.Calibration.orp.calibrationIn475mVLiquid"),
    titleTx: "Sensors.Calibration.orp.calibrationInXLiquid",
    alert: translate("Sensors.Calibration.dontTouchSensor"),
  },

  [`${CalibrationStep.Process2}${CalibrationProcessStatus.Error}`]: {
    Picture: CalibrationORP475ErrorSvg,
    // title: translate("Sensors.Calibration.orp.calibrationIn475mVLiquid"),
    titleTx: "Sensors.Calibration.orp.calibrationInXLiquid",
  },

  [CalibrationStep.Error]: {
    Picture: CalibrationORP475ErrorSvg,
  },
};
