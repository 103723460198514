import * as React from "react";
import Svg, { Path } from "react-native-svg";

export default function FlowMeterIcon({ color = "#6F6F6F", size = 24 }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        fill={color}
        fillRule="evenodd"
        d="M8.435 7.34a5.02 5.02 0 0 0 .001.667H5.583V7.34h2.852Zm.335-1.5H4.834a.75.75 0 0 0-.75.75v2.167c0 .414.335.75.75.75h.898v1.228a.75.75 0 0 0 1.5 0V9.507H8.78c.306.76.794 1.425 1.411 1.943a.75.75 0 0 0-.03.214v2.89H6.481a.75.75 0 0 0-.75.75v5.513c0 .414.336.75.75.75h11.322a.75.75 0 0 0 .75-.75v-5.512a.75.75 0 0 0-.75-.75H16.58v-2.89a.748.748 0 0 0-.03-.215A4.946 4.946 0 1 0 8.77 5.84Zm1.204 1.235a3.447 3.447 0 1 1 0 1.172V7.075Zm3.396 5.532c.601 0 1.177-.107 1.71-.304v2.252h-3.42v-2.252a4.935 4.935 0 0 0 1.71.304Zm-6.138 3.448v4.012h9.822v-4.012H7.232Zm8.045-10.336c.296.29.3.765.01 1.06l-1.382 1.406a.75.75 0 0 1-1.07-1.051l1.382-1.406a.75.75 0 0 1 1.06-.009Z"
        clipRule="evenodd"
      />
    </Svg>
  );
}
