import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { StyleSheet } from "react-native";
import { StackScreenProps } from "@react-navigation/stack";

import { useStores } from "@models/index";
import { TopBar, TopBarAlign } from "@components/layout";
import { Button, ErrorsAlert, Form } from "@components/ui";
import { TextInput, DropDownOption, DropDownPicker } from "@components/inputs";

import { TSupportMessageData } from "../../../services/api";
import { Screen } from "../../../components";
import { SettingsNavigatorParamList } from "../navigation";
import {
  createDataValidator,
  DataValidator,
  emailValidator,
  requiredStringValidator,
} from "../../../utils/validator";
import { spacing } from "../../../theme";
import { translate } from "../../../i18n";

const styles = StyleSheet.create({
  screen: {
    padding: spacing[4],
  },
  enterEmailText: {
    marginBottom: 64,
  },
  button: {
    marginTop: spacing[3],
  },
  input: {
    height: 125,
  },
});

const dataIsValid: DataValidator<TSupportMessageData> = createDataValidator({
  email: emailValidator,
  // subject: requiredStringValidator,
  message: requiredStringValidator,
});

export const SupportScreen: React.FC<
  StackScreenProps<SettingsNavigatorParamList, "settings-support">
> = observer(({ navigation }) => {
  const SUBJECT_OPTIONS: DropDownOption[] = [
    {
      key: "securityRequest",
      value: translate("Settings.contactSupport.subjects.securityRequest"),
    },
    {
      key: "technicalRequest",
      value: translate("Settings.contactSupport.subjects.technicalRequest"),
    },
    {
      key: "other",
      value: translate("Settings.contactSupport.subjects.other"),
    },
  ];

  const getSubjectByKey = (key: string): string =>
    SUBJECT_OPTIONS.find((item) => item.key === key)?.value || "";

  const { accountStore } = useStores();

  const initialFormData = {
    email: accountStore.currentUser.email,
    subject: "",
    message: "",
  };
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({
    email: undefined,
    subject: undefined,
    message: undefined,
  });

  const submitHandler = async () => {
    const isValid = dataIsValid(formData, { setErrors });
    if (!isValid) return;

    const result = await accountStore.sendMessageToSupport({
      email: formData.email,
      subject: getSubjectByKey(formData.subject),
      message: formData.message,
    });

    if (result.kind === "ok") {
      setFormData(initialFormData);
      navigation.navigate("settings-request-sent-success");
    }
  };

  const setFormDataHandler = (key, value) => {
    setErrors({ ...errors, [key]: undefined });
    setFormData({ ...formData, [key]: value });
  };

  return (
    <Screen
      style={styles.screen}
      header={
        <TopBar
          back
          title={translate("Settings.contactSupport.title")}
          align={TopBarAlign.Left}
        />
      }
    >
      <Form.Container>
        <TextInput
          txLabel="fields.email"
          errorText={errors.email}
          onChangeText={(value) => setFormDataHandler("email", value)}
          value={formData.email}
          disabled
        />
        <DropDownPicker
          txLabel="Settings.contactSupport.chooseCategory"
          options={SUBJECT_OPTIONS}
          selected={formData.subject}
          onChange={(item) => setFormDataHandler("subject", item.key)}
          error={errors.subject}
        />
        <TextInput
          multiline
          inputStyle={styles.input}
          maxLength={250}
          errorText={errors.message}
          txLabel="Settings.contactSupport.issueDescription"
          value={formData.message}
          onChangeText={(value) => setFormDataHandler("message", value)}
          hintLabel={translate("validations.descriptionFormat", {
            charactersCount: 250,
          })}
        />
        <Button
          style={styles.button}
          type="primary"
          onPress={submitHandler}
          tx="Settings.contactSupport.sendRequest"
        >
          Send Request
        </Button>
      </Form.Container>

      <ErrorsAlert
        errors={accountStore.statusErrors}
        onCancel={accountStore.resetErrors}
      />
    </Screen>
  );
});
