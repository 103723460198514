import React, { useEffect, useMemo, useState } from "react";
import { Modal, StyleSheet, View } from "react-native";
import { RadioButton } from "react-native-paper";
import { observer } from "mobx-react-lite";

import { useStores } from "@models/root-store/root-store-context";
import { Sensor, SensorState } from "@models/sensor/sensor";
import { Screen } from "@components/index";
import { SelectorBar } from "@components/layout";
import {
  Badge,
  ErrorsAlert,
  ErrorsMessage,
  Loading,
  Text,
} from "@components/ui";
import { CustomControl } from "@components/inputs";

import { translate } from "../../../../../i18n";
import * as SensorIcons from "../../../../../svgs/sensors";
import { ChevronRightIcon, NoneIcon } from "../../../../../svgs";
import { spacing } from "../../../../../theme";
import { useAppTheme } from "../../../../../hooks";

const createStyles = (theme: any) =>
  StyleSheet.create({
    container: {
      paddingHorizontal: spacing[4],
      paddingTop: spacing[4],
    },
    textContainer: {
      flex: 1,
    },
    typeContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    typeLabel: {
      fontSize: 12,
      color: theme.colors.label,
    },
    label: {
      ...theme.fonts?.medium,
      fontSize: 16,
    },
    description: {
      color: theme.colors.label,
      marginBottom: spacing[3],
    },
  });

type SensorControlProps = {
  sensor: Sensor;
  onPress?: (identifier: string) => void;
  checked?: boolean;
  isInitial?: boolean;
};

const SensorControl = ({
  sensor,
  onPress,
  checked = false,
  isInitial = false,
}: SensorControlProps) => {
  const theme = useAppTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);
  const Icon = SensorIcons[sensor.iconName] || NoneIcon;
  const onPressHandler = () => onPress(sensor.uid);

  const errorText = [
    SensorState.Offline,
    SensorState.Error,
    SensorState.NeedCalibration,
  ].includes(sensor.state)
    ? sensor.translatedState
    : undefined;

  return (
    <CustomControl
      onPress={onPressHandler}
      icon={<Icon size={36} />}
      control={
        isInitial ? (
          <ChevronRightIcon />
        ) : (
          <RadioButton
            value="second"
            status={checked ? "checked" : "unchecked"}
            onPress={onPressHandler}
          />
        )
      }
    >
      <View style={styles.textContainer}>
        <View style={styles.typeContainer}>
          <Text style={styles.typeLabel}>{sensor.displayType}</Text>
          {errorText ? <Badge text={errorText} /> : null}
        </View>
        <Text style={styles.label}>{sensor.displayName}</Text>
      </View>
    </CustomControl>
  );
};

type SensorSelectorProps = {
  sensors: Sensor[];
  currentUid?: string;
  onChange: (value: string) => void;
  onClose: () => void;
};

export const SensorSelector = observer(
  ({ sensors, currentUid, onChange, onClose }: SensorSelectorProps) => {
    const theme = useAppTheme() as any;
    const styles = useMemo(() => createStyles(theme), [theme]);

    const [errors, setErrors] = useState<string[]>();

    const { sensorStore } = useStores();

    useEffect(() => {
      sensorStore.fetchSensors({});
    }, []);

    const resetErrors = () => {
      setErrors([]);
    };

    const selectSensor = (uid: string) => {
      if (sensorStore.getSensor(uid)?.needCalibration) {
        setErrors([translate("Devices.Rules.errorNeedCalibration")]);
      } else {
        resetErrors();
        onChange(uid);
      }
    };

    return (
      <Modal onRequestClose={onClose}>
        <Screen
          style={styles.container}
          preset="scroll"
          header={
            <SelectorBar
              title={translate("Devices.Rules.selectSensor")}
              onClose={onClose}
            />
          }
        >
          <Text style={styles.description}>
            {translate("Devices.Rules.selectSensorForRule")}
          </Text>

          {sensorStore.isLoading && <Loading />}

          {sensors?.length ? (
            sensors.map((sensorView) => (
              <SensorControl
                key={sensorView.uid}
                sensor={sensorView}
                checked={sensorView.uid === currentUid}
                onPress={selectSensor}
                isInitial={!currentUid}
              />
            ))
          ) : (
            <ErrorsMessage
              errors={[translate("Devices.Rules.errors.noSensorsForRule")]}
            />
          )}

          <ErrorsAlert errors={errors} onCancel={resetErrors} />
        </Screen>
      </Modal>
    );
  },
);
