import React, { useCallback, useMemo, useState } from "react";
import { FlatList, Platform, StyleSheet, View } from "react-native";
import {
  NestableScrollContainer,
  NestableDraggableFlatList,
  RenderItemParams,
} from "react-native-draggable-flatlist";
import { observer } from "mobx-react-lite";
import { GestureHandlerRootView } from "react-native-gesture-handler";

import { TFilterItem } from "@models/types";
import { Screen } from "@components/index";
import { TopBar, TopBarAlign } from "@components/layout";
import { FloatingButton, HeaderAction, Text } from "@components/ui";

import { spacing } from "../../theme";
import { translate } from "../../i18n";
import { goBack } from "../../navigators";

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: spacing[5],
    paddingTop: spacing[3],
  },
  containerWeb: {
    paddingHorizontal: spacing[1],
    paddingBottom: spacing[4],
  },
  list: {
    paddingHorizontal: spacing[4],
    marginTop: spacing[4],
    marginBottom: 80,
  },
});

type FilterItemProps = {
  uid: string;
  iconName: string;
  displayName?: string;
};

export type FilterItemComponentProps = RenderItemParams<TFilterItem> & {
  handleChangeCheckbox: (uid: string) => void;
  isChecked: boolean;
  item: FilterItemProps;
};

type FilterState = Record<string, boolean>;

export const useFilterItems = (items: any[]): FilterItemProps[] =>
  useMemo(
    () =>
      items.map((item) => ({
        uid: item.uid,
        iconName: item.iconName,
        displayName: item.displayName,
      })),
    [items],
  );

type FilterScreenProps = {
  items: FilterItemProps[];
  selectedUids: string[];
  onApply: (items: TFilterItem[]) => void;
  ItemComponent: React.FunctionComponent<FilterItemComponentProps>;
  title?: string;
  description?: string;
};

export const FilterScreen = observer(
  ({
    items,
    selectedUids,
    onApply,
    ItemComponent,
    title = translate("Filter.title"),
    description,
  }: FilterScreenProps) => {
    const [data, setData] = useState<FilterItemProps[]>(items);

    const defaultItems = Object.fromEntries(
      data.map((item) => [item.uid, selectedUids.includes(item.uid)]),
    );

    // TODO: use effect for selectedUids & items !!!
    // useEffect(() => {
    //   const newState = Object.fromEntries(
    //     data.map((item) => [item.uid, selectedUids.includes(item.uid)]),
    //   );
    //   setFilterState(newState);
    // }, [selectedUids]);

    const [filterState, setFilterState] = useState<FilterState>(defaultItems);

    const isNotAllSelected = () =>
      Object.values(filterState).findIndex((isChecked) => !isChecked) >= 0;

    const toggleAll = () => {
      const flag = isNotAllSelected();
      const newState = Object.fromEntries(
        Object.keys(filterState).map((uid) => [uid, flag]),
      );
      setFilterState(newState);
    };

    const headerActions: HeaderAction[] = [
      {
        key: "clear",
        text: isNotAllSelected()
          ? translate("common.selectAll")
          : translate("common.clearAll"),
        onPress: toggleAll,
      },
    ];

    const handleChangeCheckbox = useCallback((uid) => {
      setFilterState((prevState) => ({ ...prevState, [uid]: !prevState[uid] }));
    }, []);

    const onApplyHandler = () => {
      const positions = data.map((dataItem) => dataItem.uid);
      const filterItems = Object.entries(filterState).map(
        ([uid, isChecked]) => {
          const position = positions.indexOf(uid);
          return {
            uid,
            isChecked,
            position,
          };
        },
      );
      onApply(filterItems);
      goBack();
    };

    return (
      <Screen
        preset="fixed"
        header={
          <TopBar
            title={title}
            back
            align={TopBarAlign.Left}
            actions={headerActions}
          />
        }
      >
        <CustomDraggableFlatList
          data={data}
          filterState={filterState}
          ItemComponent={ItemComponent}
          handleChangeCheckbox={handleChangeCheckbox}
          setData={setData}
          description={description}
        />

        <FloatingButton onPress={onApplyHandler}>
          {translate("common.apply")}
        </FloatingButton>
      </Screen>
    );
  },
);

type CustomDraggableFlatListProps = {
  data: FilterItemProps[];
  filterState: FilterState;
  ItemComponent: React.FunctionComponent<FilterItemComponentProps>;
  handleChangeCheckbox: (uid: string) => void;
  setData: (data: FilterItemProps[]) => void;
  description?: string;
};

const CustomDraggableFlatList = ({
  data,
  filterState,
  ItemComponent,
  handleChangeCheckbox,
  setData,
  description,
}: CustomDraggableFlatListProps) => {
  if (Platform.OS === "web") {
    return (
      <FlatList
        ListHeaderComponent={
          Boolean(description) && (
            <View style={styles.containerWeb}>
              <Text>{description}</Text>
            </View>
          )
        }
        style={styles.list}
        data={data}
        renderItem={(props) => (
          <ItemComponent
            {...props}
            isChecked={Boolean(filterState[props.item?.uid])}
            handleChangeCheckbox={handleChangeCheckbox}
          />
        )}
        debug={false}
        keyExtractor={(item) => `filter-item-${item.uid}`}
      />
    );
  }

  return (
    <GestureHandlerRootView>
      <NestableScrollContainer>
        {Boolean(description) && (
          <View style={styles.container}>
            <Text>{description}</Text>
          </View>
        )}
        <NestableDraggableFlatList
          style={styles.list}
          data={data}
          renderItem={(props) => (
            <ItemComponent
              {...props}
              isChecked={Boolean(filterState[props.item?.uid])}
              handleChangeCheckbox={handleChangeCheckbox}
            />
          )}
          debug={false}
          keyExtractor={(item) => `filter-item-${item.uid}`}
          onDragEnd={(params) => setData(params.data)}
        />
      </NestableScrollContainer>
    </GestureHandlerRootView>
  );
};
