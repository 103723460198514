import React, { Component, ErrorInfo, ReactNode } from "react";
import { ErrorComponent } from "./error-component";
import { rnLogger } from "../../services/logger";

interface Props {
  children: ReactNode;
  catchErrors: "always" | "dev" | "prod" | "never";
}

interface State {
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

/**
 * This component handles whenever the user encounters a JS error in the
 * app. It follows the "error boundary" pattern in React. We're using a
 * class component because according to the documentation, only class
 * components can be error boundaries.
 *
 * Read more here:
 *
 * @link: https://reactjs.org/docs/error-boundaries.html
 */
export class ErrorBoundary extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  // To avoid unnecessary re-renders
  shouldComponentUpdate(
    nextProps: Readonly<any>,
    nextState: Readonly<any>,
  ): boolean {
    return nextState.error !== nextProps.error;
  }

  // If an error in a child is encountered, this will run
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo,
    });

    // You can also log error messages to an error reporting service here
    // This is a great place to put BugSnag, Sentry, Honeybadger, etc:
    // reportErrorToCrashReportingService(error)

    rnLogger.error("Client error", {
      error: error.stack,
      exceptionClass: errorInfo?.componentStack,
    });
  }

  // Reset the error back to null
  resetError = () => {
    this.setState({ error: null, errorInfo: null });
  };

  // Only enable if we're catching errors in the right environment
  isEnabled(): boolean {
    const { catchErrors } = this.props;
    return (
      catchErrors === "always" ||
      (catchErrors === "dev" && __DEV__) ||
      (catchErrors === "prod" && !__DEV__)
    );
  }

  // Render an error UI if there's an error; otherwise, render children
  render() {
    const { error, errorInfo } = this.state;
    const { children } = this.props;

    return this.isEnabled() && error ? (
      <ErrorComponent
        onReset={this.resetError}
        error={error}
        errorInfo={errorInfo}
      />
    ) : (
      children
    );
  }
}
