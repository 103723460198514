import * as React from "react";
import Svg, { Path } from "react-native-svg";

import { defaultColors } from "../../theme";

export default function ModuleRestoreIcon({
  size = 24,
  color = defaultColors.text,
}) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M12.0002 2.45184C11.5624 2.45184 11.132 2.56474 10.7506 2.77963C10.3681 2.99508 10.0489 3.30605 9.82267 3.67953L9.82071 3.68279L2.21436 16.3826L2.20829 16.3929C1.98601 16.7778 1.86838 17.2143 1.86711 17.6588C1.86583 18.1033 1.98096 18.5404 2.20104 18.9266C2.42111 19.3128 2.73847 19.6346 3.12153 19.8601C3.5046 20.0856 3.94003 20.2069 4.38451 20.2119L4.39294 20.212H10.4641C10.8783 20.212 11.2141 19.8762 11.2141 19.462C11.2141 19.0477 10.8783 18.712 10.4641 18.712H4.3977C4.21636 18.7093 4.0388 18.6595 3.88248 18.5675C3.72509 18.4748 3.59471 18.3426 3.50429 18.1839C3.41388 18.0253 3.36658 17.8457 3.3671 17.6631C3.36762 17.4822 3.41504 17.3045 3.5047 17.1475L11.1056 4.45679L11.1064 4.4554C11.2002 4.30121 11.3315 4.17398 11.4868 4.08651C11.6435 3.99823 11.8204 3.95184 12.0002 3.95184C12.1801 3.95184 12.3569 3.99823 12.5136 4.08651C12.6702 4.17473 12.8014 4.30179 12.8946 4.45546L16.1889 9.9097C16.403 10.2643 16.8641 10.3781 17.2186 10.1639C17.5732 9.94979 17.687 9.48876 17.4729 9.1342L14.1783 3.67947L14.1778 3.67859C13.9509 3.30414 13.6313 2.99452 13.2499 2.77963C12.8684 2.56474 12.438 2.45184 12.0002 2.45184Z"
        fill={color}
      />
      <Path
        d="M16.4313 19.448C16.0171 19.448 15.6813 19.7838 15.6813 20.198C15.6813 20.6122 16.0171 20.948 16.4313 20.948H16.4401C16.8543 20.948 17.1901 20.6122 17.1901 20.198C17.1901 19.7838 16.8543 19.448 16.4401 19.448H16.4313Z"
        fill={color}
      />
      <Path
        d="M16.4311 17.632C15.605 17.632 14.8126 17.9601 14.2284 18.5442C13.9354 18.837 13.4606 18.8369 13.1677 18.544C12.8749 18.2511 12.8749 17.7762 13.1679 17.4833C14.0334 16.6181 15.2072 16.132 16.4311 16.132C17.655 16.132 18.8288 16.6181 19.6943 17.4833C19.9873 17.7762 19.9873 18.2511 19.6945 18.544C19.4016 18.8369 18.9268 18.837 18.6338 18.5442C18.0496 17.9601 17.2572 17.632 16.4311 17.632Z"
        fill={color}
      />
      <Path
        d="M11.4947 15.8256C12.8041 14.5167 14.5798 13.7813 16.4312 13.7813C18.2827 13.7813 20.0583 14.5167 21.3677 15.8256C21.6607 16.1185 22.1355 16.1184 22.4284 15.8255C22.7212 15.5325 22.7212 15.0577 22.4282 14.7648C20.8376 13.1746 18.6804 12.2813 16.4312 12.2813C14.182 12.2813 12.0249 13.1746 10.4342 14.7648C10.1413 15.0577 10.1412 15.5325 10.4341 15.8255C10.7269 16.1184 11.2018 16.1185 11.4947 15.8256Z"
        fill={color}
      />
    </Svg>
  );
}
