import React, { useCallback } from "react";
import { StyleSheet, View } from "react-native";
import { observer } from "mobx-react-lite";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp, StackScreenProps } from "@react-navigation/stack";
import { SettingsNavigatorParamList } from "@screens/Settings/navigation";

import { Sensor, useStores } from "@models/index";
import { Module } from "@models/module/module";
import { Device } from "@models/device/device";
import { Screen } from "@components/index";
import { Dialog, Text, useDialog } from "@components/ui";
import { TopBarNew } from "@components/layout";
import {
  NotificationChannel,
  TNotificationType,
} from "@models/notifications/notifications-store";
import {
  NotificationEntityType,
  NOTIFICATION_ICON,
  NotificationType,
} from "@models/notifications/constants";

import { useNotificationTypeEntities } from "../../../../hooks";
import { normalizeFontSize } from "../../../../utils/sizes";
import { translate } from "../../../../i18n";
import * as SensorIcons from "../../../../svgs/sensors";
import * as DeviceIcons from "../../../../svgs/devices";
import * as NotificationIcons from "../../../../svgs/notificationTypes";
import { MessageIcon, NoneIcon } from "../../../../svgs";
import { spacing } from "../../../../theme";
import {
  HeaderPreference,
  NotificationTypeSwitch,
  EquipmentSwitch,
} from "./components";

const createStyles = () =>
  StyleSheet.create({
    screen: {
      padding: spacing[4],
    },
    description: {
      fontSize: normalizeFontSize(14, 16),
      lineHeight: 20,
    },
    channels: {
      marginVertical: spacing[4],
    },
    equipment: {
      marginTop: spacing[3],
    },
  });

const channels = Object.values(NotificationChannel);

export const NotificationsPreferencesTypeScreen: React.FC<
  StackScreenProps<
    SettingsNavigatorParamList,
    "settings-notifications-preferences-type"
  >
> = observer(({ route }) => {
  const navigation = useNavigation() as StackNavigationProp<any>;

  const { params = {} } = route as any;
  const type: NotificationType = params?.type;

  if (!type) {
    navigation.navigate("settings-notifications-preferences");
  }

  const styles = createStyles();
  const { notificationsStore } = useStores();

  const iconName = NOTIFICATION_ICON[type];
  const NotificationIcon = NotificationIcons[iconName] || MessageIcon;

  const onRefresh = useCallback(async () => {
    await notificationsStore.fetchNotificationsPreferences({ force: true });
  }, []);

  const {
    preferencesTitle,
    preferencesDescription,
    entityType,
  }: TNotificationType = notificationsStore.getNotificationsPreference(type);

  const { showDialog: showLastEquipmentDialog, ...dialogLastEquipmentProps } =
    useDialog();

  const { entities } = useNotificationTypeEntities(
    entityType as NotificationEntityType,
  );

  const toggleEntity = useCallback(
    (uid: string) => {
      const isToggle = notificationsStore.preferences.toggleEquipment(
        uid,
        type,
        entities.length,
      );

      if (!isToggle) {
        showLastEquipmentDialog();
      }
    },
    [type, entities.length],
  );

  return (
    <Screen
      style={styles.screen}
      preset="scroll"
      testID="PreferensType"
      onRefresh={onRefresh}
      refreshing={!notificationsStore.isLoaded}
      header={<TopBarNew title={preferencesTitle} icon={NotificationIcon} />}
    >
      <Text style={styles.description}>{preferencesDescription}</Text>

      <HeaderPreference
        title={translate("Settings.notifications.preferences.delivery.title")}
        description={translate(
          "Settings.notifications.preferences.delivery.description",
        )}
      />

      <View style={styles.channels}>
        {channels.map((channel) => {
          return (
            <NotificationTypeSwitch
              key={channel}
              notificationType={type}
              channelType={channel}
            />
          );
        })}
      </View>

      {entityType === NotificationEntityType.Devices ? (
        <>
          <HeaderPreference
            title={translate(
              "Settings.notifications.preferences.devices.title",
            )}
            description={translate(
              "Settings.notifications.preferences.devices.description",
            )}
          />

          <View style={styles.equipment}>
            {entities.map((device: Device) => {
              const Icon = DeviceIcons[device.iconName] || NoneIcon;
              return (
                <EquipmentSwitch
                  key={device.uid}
                  title={device.name}
                  icon={Icon}
                  value={notificationsStore.preferences.getEntityState(
                    device.uid,
                    type,
                  )}
                  onChange={() => toggleEntity(device.uid)}
                />
              );
            })}
          </View>
        </>
      ) : null}

      {entityType === NotificationEntityType.Modules ? (
        <>
          <HeaderPreference
            title={translate(
              "Settings.notifications.preferences.modules.title",
            )}
            description={translate(
              "Settings.notifications.preferences.modules.description",
            )}
          />

          <View style={styles.equipment}>
            {entities.map((module: Module) => {
              return (
                <EquipmentSwitch
                  key={module.uid}
                  title={module.displayName}
                  value={notificationsStore.preferences.getEntityState(
                    module.uid,
                    type,
                  )}
                  onChange={() => toggleEntity(module.uid)}
                />
              );
            })}
          </View>
        </>
      ) : null}

      {entityType === NotificationEntityType.Sensors ? (
        <>
          <HeaderPreference
            title={translate(
              "Settings.notifications.preferences.sensors.title",
            )}
            description={translate(
              "Settings.notifications.preferences.sensors.description",
            )}
          />

          <View style={styles.equipment}>
            {entities.map((sensor: Sensor) => {
              const Icon = SensorIcons[sensor.iconName] || NoneIcon;
              return (
                <EquipmentSwitch
                  key={sensor.uid}
                  title={sensor.displayName}
                  icon={Icon}
                  value={notificationsStore.preferences.getEntityState(
                    sensor.uid,
                    type,
                  )}
                  onChange={() => toggleEntity(sensor.uid)}
                />
              );
            })}
          </View>
        </>
      ) : null}

      <Dialog
        {...dialogLastEquipmentProps}
        title={translate("common.warning")}
        content={translate(
          "Settings.notifications.preferences.disableLastEquipmentMessage",
        )}
        onCancel={false}
      />
    </Screen>
  );
});
