import { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { FlatList, StyleSheet, View } from "react-native";
import { StackScreenProps } from "@react-navigation/stack";
import { useIsFocused } from "@react-navigation/native";

import { useStores } from "@models/index";
import { Screen } from "@components/index";
import { Action, TopBar, TopBarAlign } from "@components/layout";
import { Loading } from "@components/ui";

import { Calendar, FilterMenu } from "@components/common";
import {
  NotificationsFilterAsRead,
  NotificationsMessegeFilterStatus,
} from "@models/notifications/constants";
import { translate } from "../../../i18n";
import { spacing } from "../../../theme";
import { Filter } from "../../../svgs";
import { NotificationSettingsIcon } from "../../../svgs/menu";
import { SettingsNavigatorParamList } from "../navigation";
import { NotificationCard } from "./components/NotificationCard";
import { NoMessages } from "./components/NoMessages";
import { TPeriod, getPeriodWithTime } from "../../../utils/timeConverter";

const styles = StyleSheet.create({
  screen: {
    paddingTop: spacing[5],
    paddingHorizontal: spacing[4],
  },
  footer: {
    height: spacing[7],
  },
  filterBar: {
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: spacing[3],
    marginTop: -spacing[2],
  },
  loading: {
    marginTop: spacing[4],
    marginBottom: spacing[6],
  },
});

export const NotificationsScreen: React.FC<
  StackScreenProps<SettingsNavigatorParamList, "settings-notifications">
> = observer(({ navigation }) => {
  const { notificationsStore } = useStores();
  const { isAppliedFilter } = notificationsStore;

  const [filterMessage, setFilterMessage] = useState<NotificationsFilterAsRead>(
    NotificationsFilterAsRead.All,
  );
  const isOpened = NotificationsMessegeFilterStatus[filterMessage];

  const [period, setPeriod] = useState<TPeriod>();

  const periodWithTime =
    period?.startDate && period?.endDate ? getPeriodWithTime(period) : null;
  const startDate = periodWithTime?.startDate;
  const endDate = periodWithTime?.endDate;

  const isFocused = useIsFocused();

  const menuFilterMessage = useMemo(() => {
    return [
      {
        key: NotificationsFilterAsRead.All,
        title: translate("Settings.notifications.filter.allMessages"),
      },
      {
        key: NotificationsFilterAsRead.Read,
        title: translate("Settings.notifications.filter.readMessages"),
      },
      {
        key: NotificationsFilterAsRead.Unread,
        title: translate("Settings.notifications.filter.unreadMessages"),
      },
    ];
  }, [translate]);

  useEffect(() => {
    if (isFocused) {
      notificationsStore.fetchNotifications({
        isOpened,
        startDate,
        endDate,
      });
    }
  }, [isFocused, filterMessage, startDate, endDate]);

  const headerActions: Action[] = [
    {
      key: "notifications-filter",
      icon: Filter,
      onPress: () => {
        return navigation.navigate("settings-notifications-filter");
      },
      selected: isAppliedFilter,
    },
    {
      key: "notifications-preferences",
      icon: NotificationSettingsIcon,
      // color?: string;
      onPress: () => {
        navigation.navigate("settings-notifications-preferences");
      },
    },
  ];

  const setNextPageNotifications = async () => {
    await notificationsStore.fetchNextPageNotifications({
      isOpened,
      startDate,
      endDate,
    });
  };

  return (
    <Screen
      style={styles.screen}
      testID="Notifications"
      header={
        <TopBar
          title={translate("Settings.notifications.title")}
          back
          align={TopBarAlign.Left}
          actions={headerActions}
        />
      }
    >
      <View style={styles.filterBar}>
        <FilterMenu
          menuItems={menuFilterMessage}
          value={filterMessage}
          onChange={setFilterMessage as any}
          defaultValue={NotificationsFilterAsRead.All}
        />

        <Calendar period={period} onChange={setPeriod} isPeriodUndefined />
      </View>

      {notificationsStore.isLoading && (
        <Loading withMask={false} size="small" style={styles.loading} />
      )}

      {notificationsStore.notificationsArray.length ? (
        <FlatList
          data={notificationsStore.notificationsArray}
          keyExtractor={(notification) => notification.uid}
          renderItem={({ item }) => (
            <NotificationCard key={item.uid} notification={item} />
          )}
          onEndReached={setNextPageNotifications}
          onEndReachedThreshold={0.1}
          ListFooterComponent={
            <View style={styles.footer}>
              {notificationsStore.isLoading ? (
                <Loading withMask={false} size="small" />
              ) : null}
            </View>
          }
        />
      ) : (
        <NoMessages />
      )}
    </Screen>
  );
});
