import * as React from "react";
import Svg, { ClipPath, Defs, G, Path, Rect } from "react-native-svg";

export default function VPDIcon({ color = "#6F6F6F", size = 24 }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <G clipPath="url(#clip0_7_333)">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.6851 1.5C10.6851 1.08579 10.3493 0.75 9.93506 0.75C9.52084 0.75 9.18506 1.08579 9.18506 1.5V4.18934L8.46539 3.46967C8.1725 3.17678 7.69762 3.17678 7.40473 3.46967C7.11184 3.76256 7.11184 4.23744 7.40473 4.53033L9.40473 6.53033C9.69762 6.82322 10.1725 6.82322 10.4654 6.53033L12.4654 4.53033C12.7583 4.23744 12.7583 3.76256 12.4654 3.46967C12.1725 3.17678 11.6976 3.17678 11.4047 3.46967L10.6851 4.18934V1.5ZM3.75 7.56641C3.33579 7.56641 3 7.90219 3 8.31641V9.98771C3 11.5164 3.60727 12.9825 4.68822 14.0634C5.76916 15.1444 7.23524 15.7516 8.76393 15.7516H10.5209V17.5087V21.6869C10.5209 22.1011 10.8567 22.4369 11.2709 22.4369C11.6851 22.4369 12.0209 22.1011 12.0209 21.6869V18.2587H13.7779C15.3065 18.2587 16.7726 17.6515 17.8536 16.5705C18.9345 15.4896 19.5418 14.0235 19.5418 12.4948V11.6591C19.5418 11.2449 19.206 10.9091 18.7918 10.9091H16.2848C14.7561 10.9091 13.2901 11.5164 12.2091 12.5974C12.1358 12.6707 12.0655 12.7449 11.9983 12.82C11.8791 11.4784 11.2927 10.2146 10.3327 9.25462C9.25173 8.17368 7.78565 7.56641 6.25696 7.56641H3.75ZM9.27202 10.3153C10.0717 11.1149 10.5209 12.1995 10.5209 13.3303V14.2516H8.76393C7.63306 14.2516 6.54852 13.8024 5.74888 13.0028C4.94923 12.2031 4.5 11.1186 4.5 9.98771V9.06641H6.25696C7.38783 9.06641 8.47237 9.51564 9.27202 10.3153ZM13.2698 13.658C14.0694 12.8584 15.154 12.4091 16.2848 12.4091H18.0418V12.4948C18.0418 13.6257 17.5925 14.7102 16.7929 15.5098C15.9933 16.3095 14.9087 16.7587 13.7779 16.7587H12.0428C12.1211 15.5261 12.4288 14.499 13.2698 13.658Z"
          fill={color}
        />
      </G>
      <Defs>
        <ClipPath id="clip0_7_333">
          <Rect
            width={size}
            height={size}
            fill="white"
            transform="translate(0 0.5)"
          />
        </ClipPath>
      </Defs>
    </Svg>
  );
}
