import { IObservableValue, observable } from "mobx";

export enum StatusType {
  Idle,
  Pending,
  Done,
  Error,
  PendingPartly,
}

export const withStatus = () => {
  const status: IObservableValue<StatusType> = observable.box(StatusType.Idle);
  const currentErrors: IObservableValue<Array<string>> =
    observable.box(undefined);

  const loadingText: IObservableValue<string> = observable.box(null);

  return {
    views: {
      get status() {
        return status.get() as StatusType;
      },
      // set status(value: StatusType) {
      //   status.set(value);
      // },
      get isLoading() {
        return status.get() === StatusType.Pending;
      },
      get isLoaded() {
        return (
          status.get() === StatusType.Done || status.get() === StatusType.Error
        );
      },
      get isLoadingPartly() {
        return status.get() === StatusType.PendingPartly;
      },
      get isError() {
        return status.get() === StatusType.Error;
      },
      get statusErrors() {
        return currentErrors.get();
      },
      get loadingText() {
        return loadingText.get();
      },
    },

    actions: {
      setStatus(value: StatusType) {
        status.set(value);
      },
      setStatusPending(text?: string) {
        if (text) {
          loadingText.set(text);
        }
        status.set(StatusType.Pending);
        currentErrors.set(undefined);
      },
      setStatusPendingPartly() {
        status.set(StatusType.PendingPartly);
        currentErrors.set(undefined);
      },
      setStatusDone() {
        loadingText.set(null);
        status.set(StatusType.Done);
      },
      setStatusError(errors?: string[]) {
        loadingText.set(null);
        status.set(StatusType.Error);
        currentErrors.set(errors);
      },
      resetErrors() {
        currentErrors.set(undefined);
      },
      // resetStatus() {
      //   // TODO: return on useLayoutEffect
      //   status.set(StatusType.Idle);
      //   currentErrors.set(undefined);
      // },
    },
  };
};
