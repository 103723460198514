import { values } from "mobx";
import { flow, getParent, Instance, types } from "mobx-state-tree";

import {
  NotificationsSettingsKey,
  TNotificationsSettings,
} from "@models/settings/user-settings-store";
import { withRootStore } from "@models/extensions/with-root-store";
import { NotificationsApi } from "../../services/api/notifications-api";
import { withEnvironment } from "../extensions/with-environment";
import { withStatus } from "../extensions/with-status";
import {
  IsFetchTimeProps,
  withFetchTimeout,
} from "../extensions/with-fetch-timeout";
import { registerForPushNotificationsAsync } from "../../services/pushNotifications";
import { rnLogger } from "../../services/logger";
import { uniq } from "../../utils/uniq";
import { NotificationSeverityLevel, NotificationType } from "./constants";
import { dateTimeFormat, DateTimeFormats } from "../../utils/timeConverter";

const READ_NOTIFICATIONS_PREFERENCES = "fetchNotificationsPreferences";
const READ_NOTIFICATIONS_TYPE = "fetchNotificationsType";

export enum NotificationChannel {
  Push = "Push",
  Email = "Email",
}

type FetchNotificationsProps = {
  isOpened?: boolean;
  startDate?: string | null;
  endDate?: string | null;
};

const NotificationsPreferencesChannelModel = types
  .model("NotificationsPreferencesChannel")
  .props({
    type: types.string,
    enabled: types.maybeNull(types.boolean),
  });

const NotificationTypePreferencesModel = types
  .model("NotificationTypePreferences")
  .props({
    notificationType: types.identifier,
    channels: types.optional(
      types.array(NotificationsPreferencesChannelModel),
      [],
    ),
    disabledEntityUids: types.optional(types.array(types.string), []),
  });

const NotificationsPreferencesModel = types
  .model("NotificationsPreferences")
  .props({
    notificationTypePreferences: types.optional(
      types.array(NotificationTypePreferencesModel),
      [],
    ),
  })
  .extend(withStatus)
  .actions((self) => ({
    getPreferencesByType(type: NotificationType) {
      return self.notificationTypePreferences.find(
        (item) => item.notificationType === type,
      );
    },
  }))
  .actions((self) => ({
    enabled(
      notificationType: NotificationType,
      channelType: NotificationChannel,
    ): boolean {
      const preferences = self.getPreferencesByType(notificationType);

      if (!preferences) return false;

      const channel = preferences.channels.find(
        (item) => item.type === channelType,
      );
      if (!channel) return false;

      return channel.enabled;
    },

    toggle(
      notificationType: NotificationType,
      channelType: NotificationChannel,
    ) {
      const preferences = self.getPreferencesByType(notificationType);

      if (preferences) {
        const channel = preferences.channels.find(
          (item) => item.type === channelType,
        );
        if (channel) {
          channel.enabled = !channel.enabled;
        } else {
          preferences.channels.push({ type: channelType, enabled: true });
        }
      } else {
        self.notificationTypePreferences.push({
          notificationType,
          channels: [{ type: channelType, enabled: true }],
        });
      }

      getParent(self)?.saveNotificationsPreferences?.();
    },

    numberDisableEquipment(notificationType: NotificationType): number {
      const preferences = self.getPreferencesByType(notificationType);
      if (preferences) {
        return preferences.disabledEntityUids.length;
      }

      return 0;
    },

    getEntityState(uid: string, notificationType: NotificationType): boolean {
      const preferences = self.getPreferencesByType(notificationType);
      if (!preferences) return false;

      return !preferences.disabledEntityUids.includes(uid);
    },

    toggleEquipment(
      uid: string,
      notificationType: NotificationType,
      numberAll: number,
    ): boolean {
      const preferences = self.getPreferencesByType(notificationType);
      if (!preferences) return false;

      if (preferences.disabledEntityUids.includes(uid)) {
        preferences.disabledEntityUids.replace(
          preferences.disabledEntityUids.filter((item) => item !== uid),
        );
      } else {
        if (preferences.disabledEntityUids.length === numberAll - 1)
          return false;

        preferences.disabledEntityUids.push(uid);
      }

      getParent(self)?.saveNotificationsPreferences?.();

      return true;
    },
  }));

const NotificationTypeModel = types.model("NotificationType").props({
  type: types.identifier,
  preferencesTitle: types.maybeNull(types.string),
  preferencesDescription: types.maybeNull(types.string),
  severityLevel: types.maybeNull(types.string),
  entityType: types.maybeNull(types.string),
});

const NotificationModel = types
  .model("Notification")
  .props({
    uid: types.identifier,
    type: types.maybeNull(types.string),
    severityLevel: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    body: types.maybeNull(types.string),
    openAt: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.string),
    isDetailed: types.maybeNull(types.boolean),
  })
  .extend(withEnvironment)
  .extend(withRootStore)

  .actions((self) => {
    const notificationsApi = new NotificationsApi(self.environment.api);

    const open = flow(function* () {
      if (self.openAt) {
        self.isDetailed = true;
        return self.openAt;
      }

      const result = yield notificationsApi.fetchNotificationDetails(self.uid);

      if (result.kind === "ok") {
        // console.log("data", result.data);
        self.openAt = result.data.openAt;
        self.isDetailed = true;
        return result.data.openAt;
      }

      return null;
    });

    return { open };
  })

  .views((self) => ({
    get displayDate() {
      return dateTimeFormat(
        self.createdAt,
        DateTimeFormats.DateTimeDM,
        self.rootStore.settingsStore.currentTimeSystem,
      );
    },

    get displayOpenAt() {
      if (!self.openAt) return null;
      return dateTimeFormat(
        self.openAt,
        DateTimeFormats.DateTimeDM,
        self.rootStore.settingsStore.currentTimeSystem,
      );
    },

    get isNew(): boolean {
      return !self.openAt;
    },
    // get isOpened(): boolean {
    //   return !!self.openAt;
    // },
  }));

export const NotificationsStoreModel = types
  .model("NotificationsStore")
  .props({
    notifications: types.map(NotificationModel),
    types: types.optional(types.array(NotificationTypeModel), []),
    preferences: types.optional(NotificationsPreferencesModel, {}),
    pageNumber: types.maybeNull(types.number),
    totalPages: types.maybeNull(types.number),
  })
  .extend(withEnvironment)
  .extend(withStatus)
  .extend(withFetchTimeout)
  .extend(withRootStore)

  // .actions((self) => ({
  //   getNotification(uid: string) {
  //     if (!uid) return undefined;
  //     return self.notifications.find((item) => item.uid === uid);
  //   },
  // }))
  .actions((self) => ({
    setNotifications(notifications: TNotification[]) {
      notifications?.forEach((notification) => {
        self.notifications.put(notification);
      });
    },
  }))
  .views((self) => ({
    get severityLevels() {
      return uniq(self.types.map((item) => item.severityLevel));
    },

    get notificationsTypes() {
      return self.types.map((type) => type.type);
    },

    get notificationsArray(): TNotification[] | [] {
      return values(self.notifications) as TNotification[];
    },
  }))
  .views((self) => ({
    get severityLevelsSettings() {
      return (
        self.rootStore.settingsStore.userSettings.notificationsSettings.get(
          NotificationsSettingsKey.SeverityLevels,
        ) || self.severityLevels
      );
    },

    get notificationsTypesSettings() {
      return (
        self.rootStore.settingsStore.userSettings.notificationsSettings.get(
          NotificationsSettingsKey.Types,
        ) || self.notificationsTypes
      );
    },
  }))

  .views((self) => ({
    get isAppliedFilter() {
      return (
        self.notificationsTypes.length >
          self.notificationsTypesSettings?.length ||
        self.severityLevels.length > self.severityLevelsSettings?.length
      );
    },
  }))
  .actions((self) => {
    const notificationsApi = new NotificationsApi(self.environment.api);

    const addPushNotificationsToken = flow(function* () {
      const token = yield registerForPushNotificationsAsync();
      rnLogger.info(`Push token: ${token}`, {
        action: "register_push_token",
      });
      if (token) {
        yield notificationsApi.addPushNotificationsToken({ token });
      }
    });

    const markAsRead = flow(function* (uid: string) {
      const result = yield notificationsApi.fetchNotificationDetails(uid);
      return result.kind;
    });

    const fetchNotifications = flow(function* ({
      isOpened,
      startDate,
      endDate,
    }: FetchNotificationsProps) {
      self.setStatusPending();
      self.notifications.clear();

      const result = yield notificationsApi.fetchNotifications({
        types: self.notificationsTypesSettings,
        severityLevels: self.severityLevelsSettings,
        isOpened,
        startDate,
        endDate,
      });

      if (result.kind === "ok") {
        self.resetErrors();
        self.setNotifications(result.data?.items);
        self.pageNumber = result.data?.pageNumber;
        self.totalPages = result.data?.totalPages;

        self.setStatusDone();
        return self.notificationsArray;
      }
      self.setStatusError(result.errors);
      return null;
    });

    const fetchNextPageNotifications = flow(function* ({
      isOpened,
      startDate,
      endDate,
    }: FetchNotificationsProps) {
      const nextPage = self.pageNumber + 1;
      if (self.totalPages && nextPage > self.totalPages) return null;
      if (self.isLoading) return null;

      self.setStatusPending();
      const result = yield notificationsApi.fetchNotifications({
        pageNumber: nextPage,
        types: self.notificationsTypesSettings,
        severityLevels: self.severityLevelsSettings,
        isOpened,
        startDate,
        endDate,
      });

      if (result.kind === "ok" && result.data) {
        self.resetErrors();

        self.setNotifications(result.data.items);

        self.pageNumber = result.data.pageNumber;
        self.totalPages = result.data.totalPages;

        self.setStatusDone();
        return self.notificationsArray;
      }
      self.setStatusError(result.errors);
      return null;
    });

    const fetchNotificationTypes = flow(function* () {
      if (!self.isFetchTime(READ_NOTIFICATIONS_TYPE)) return null;
      const result = yield notificationsApi.getNotificationTypes();

      if (result.kind === "ok") {
        self.types.replace(result.data);
      }
      return self.types;
    });

    const fetchNotificationsPreferences = flow(function* (
      fetchProps: IsFetchTimeProps = { timeoutInSec: 5 },
    ) {
      if (!self.isFetchTime(READ_NOTIFICATIONS_PREFERENCES, fetchProps))
        return null;

      self.setStatusPending();
      const result = yield notificationsApi.getNotificationsPreferences();

      if (result.kind === "ok") {
        self.resetErrors();
        self.setStatusDone();
        self.preferences = result.data || {};
        return self.preferences;
      }

      self.setStatusError(result.errors);
      return result;
    });

    const saveNotificationsPreferences = flow(function* () {
      self.setStatusPending();
      const result = yield notificationsApi.saveNotificationsPreferences(
        self.preferences.toJSON(),
      );

      if (result.kind === "ok") {
        self.resetErrors();
        self.setStatusDone();
        return self.preferences;
      }

      self.setStatusError(result.errors);
      return result;
    });

    const getNotificationsPreference = (type: string) => {
      return self.types.find((item) => item.type === type);
    };

    const updateViewFilter = (settings: TNotificationsSettings) => {
      self.rootStore.settingsStore.userSettings.saveNotificationsSettings(
        settings,
      );
    };

    const notificationsTypesForSeverityLevel = (
      severityLevel: NotificationSeverityLevel,
    ) => {
      return self.types.filter((item) => item.severityLevel === severityLevel);
    };

    const getNotificationType = (type: NotificationType) => {
      return self.types.find((item) => item.type === type);
    };

    return {
      addPushNotificationsToken,
      fetchNotifications,
      fetchNextPageNotifications,
      markAsRead,

      fetchNotificationTypes,
      fetchNotificationsPreferences,
      saveNotificationsPreferences,

      getNotificationsPreference,

      updateViewFilter,
      notificationsTypesForSeverityLevel,
      getNotificationType,
    };
  });

type NotificationMOBXType = Instance<typeof NotificationModel>;
export type TNotification = NotificationMOBXType;
type NotificationTypeMOBXType = Instance<typeof NotificationTypeModel>;
export type TNotificationType = NotificationTypeMOBXType;
