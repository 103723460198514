import Svg, { Path } from "react-native-svg";
import { palette } from "../../theme";

export default function DimmerIcon({ color = palette.Gray, size = 24 }) {
  return (
    <Svg width={size} height={size} fill="none" viewBox="0 0 36 36">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 2.25a.75.75 0 0 1 .75.75v2.25a.75.75 0 0 1-1.5 0V3a.75.75 0 0 1 .75-.75ZM9.457 15.596c0-4.51 3.874-8.096 8.55-8.096 4.675 0 8.538 3.576 8.538 8.086V26.25a.75.75 0 0 1-.75.75H21.87v7.5a.75.75 0 0 1-1.5 0V27h-4.736v7.5a.75.75 0 0 1-1.5 0V27h-3.926a.75.75 0 0 1-.75-.75V15.596ZM25.045 25.5H10.957v-9.904c0-3.598 3.117-6.596 7.05-6.596s7.038 2.988 7.038 6.586V25.5ZM28.911 15a.75.75 0 0 0 0 1.5h2.339a.75.75 0 0 0 0-1.5H28.91Zm-24.91.75a.75.75 0 0 1 .75-.75H7.09a.75.75 0 0 1 0 1.5H4.75a.75.75 0 0 1-.75-.75Zm5.167-9.54a.75.75 0 0 0-1.04 1.08l1.559 1.5a.75.75 0 1 0 1.04-1.08l-1.56-1.5Zm18.727.02a.75.75 0 0 1-.02 1.06l-1.56 1.5a.75.75 0 1 1-1.04-1.08l1.56-1.5a.75.75 0 0 1 1.06.02ZM18.001 12c-2.115 0-3.868 1.658-3.868 3.75a.75.75 0 0 0 1.5 0c0-1.213 1.029-2.25 2.368-2.25a.75.75 0 0 0 0-1.5Z"
        fill={color}
      />
    </Svg>
  );
}
