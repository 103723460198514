import { translate } from "../../../../i18n";
import {
  CalibrationPrepareSoilMoistureSvg,
  CalibrationSoilMoistureAirProcessingSvg,
  CalibrationSoilMoistureAirSvg,
  CalibrationSoilMoistureErrorSvg,
  CalibrationSoilMoistureWaterSvg,
} from "../../../../svgs/pictures/calibration/SoilMoisture";
import {
  CalibrationProcessStatus,
  CalibrationStep,
  TCalibrationStepProps,
} from "../types";

export const SOIL_MOISTURE_STEPS = [
  CalibrationStep.Prepare,
  CalibrationStep.Process1,
  CalibrationStep.Process2,
  CalibrationStep.Success,
];

export const SOIL_MOISTURE_STEPS_PROPS: TCalibrationStepProps = {
  [CalibrationStep.Prepare]: {
    Picture: CalibrationPrepareSoilMoistureSvg,
    items: [
      translate(
        "Sensors.Calibration.SoilMoisture.Prepare.glassFilledWithTapWater",
      ),
      translate("Sensors.Calibration.SoilMoisture.Prepare.soilMoistureSensor"),
    ],
  },

  [CalibrationStep.Process1]: {
    Picture: CalibrationSoilMoistureAirSvg,
    title: translate("Sensors.Calibration.SoilMoisture.title"),
    items: [
      translate("Sensors.Calibration.SoilMoisture.keepSoilMoistureAir"),
      translate("Sensors.Calibration.pressCalibrate"),
    ],
    submitLabel: translate(
      "Sensors.Calibration.SoilMoisture.calibrateSoilMoisture",
    ),
  },

  [`${CalibrationStep.Process1}${CalibrationProcessStatus.Process}`]: {
    Picture: CalibrationSoilMoistureAirProcessingSvg,
    title: translate("Sensors.Calibration.SoilMoisture.title"),
    alert: translate("Sensors.Calibration.SoilMoisture.warning"),
  },

  [CalibrationStep.Process2]: {
    Picture: CalibrationSoilMoistureWaterSvg,
    title: translate("Sensors.Calibration.SoilMoisture.title"),
    items: [
      translate("Sensors.Calibration.SoilMoisture.putSensorIntoWater"),
      translate("Sensors.Calibration.pressCalibrate"),
    ],
    submitLabel: translate(
      "Sensors.Calibration.SoilMoisture.calibrateSoilMoisture",
    ),
  },

  [`${CalibrationStep.Process2}${CalibrationProcessStatus.Process}`]: {
    Picture: CalibrationSoilMoistureAirProcessingSvg,
    title: translate("Sensors.Calibration.SoilMoisture.title"),
    alert: translate("Sensors.Calibration.SoilMoisture.warning"),
  },

  [`${CalibrationStep.Process2}${CalibrationProcessStatus.Error}`]: {
    Picture: CalibrationSoilMoistureErrorSvg,
    title: translate("Sensors.Calibration.SoilMoisture.title"),
  },

  [CalibrationStep.Error]: {
    Picture: CalibrationSoilMoistureErrorSvg,
  },
};
