import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { ScrollView, StyleSheet, View } from "react-native";
import dayjs from "dayjs";

import { useStores } from "@models/index";
import { StackScreenProps } from "@react-navigation/stack";
import { DevicesNavigatorParamList } from "@screens/Devices/navigation";

import { ErrorsMessage, MenuItem, Text } from "@components/ui";
import { TopBarContent, TopBarNew } from "@components/layout";
import { Screen } from "@components/index";
import { DeviceHistoryMode, DeviceHistoryView } from "@models/device/types";
import { Calendar, FilterMenu } from "@components/common";
import { ModuleFeatureError } from "@screens/Modules/components";

// import { ModuleActivityType } from "@models/module/module";
import { translate } from "../../../../i18n";
import * as DeviceIcons from "../../../../svgs/devices";
import { NoneIcon } from "../../../../svgs";
import { goBack } from "../../../../navigators";
import { defaultColors, spacing } from "../../../../theme";
import {
  dateTimeFormat,
  DateTimeFormats,
  TPeriod,
} from "../../../../utils/timeConverter";
import {
  ModuleFeature,
  useCheckingModuleFeatureAvailability,
  useDeviceHistory,
  // useModuleActivityHistory,
} from "../../../../hooks";
import { groupByDataByDate } from "../../../../utils/sortDataBy";
import {
  DeviceHistoryCard,
  DeviceHistoryDiagram,
  ModuleHistoryCard,
} from "./components";

enum HistoryItemType {
  Module = "module",
  Device = "device",
}

const styles = StyleSheet.create({
  screen: {
    padding: spacing[4],
  },
  date: {
    fontSize: 14,
    lineHeight: 16,
    color: defaultColors.subtitle,
    marginBottom: spacing[3],
    marginTop: spacing[4],
  },
  filterBar: {
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: spacing[3],
  },
});

const menuFilter: MenuItem[] = [
  {
    key: DeviceHistoryMode.All,
    title: translate("Devices.History.allActivities"),
  },
  {
    key: DeviceHistoryMode.Manual,
    title: translate("Devices.History.manually"),
  },
  {
    key: DeviceHistoryMode.ByRule,
    title: translate("Devices.History.byRule"),
  },
  {
    key: DeviceHistoryMode.Unknown,
    title: translate("Devices.status.unknown"),
  },
];

const menuView: MenuItem[] = [
  {
    key: DeviceHistoryView.Detailed,
    title: translate("Devices.History.detailed"),
  },
  {
    key: DeviceHistoryView.Compact,
    title: translate("Devices.History.compact"),
  },
  // {
  //   key: DeviceHistoryView.Diagram,
  //   title: translate("Devices.History.diagram"),
  // },
];

export const DeviceHistoryScreen: React.FC<
  StackScreenProps<DevicesNavigatorParamList, "device-history">
> = observer(({ route }) => {
  const { params = {} } = route as any;

  const startDate = dayjs().add(-1, "day").format(DateTimeFormats.DateYMD);
  const endDate = dayjs().format(DateTimeFormats.DateYMD);

  const initialPeriod: TPeriod = {
    startDate,
    endDate,
  };

  const [period, setPeriod] = useState<TPeriod>(initialPeriod);

  const [filterMode, setFilterMode] = useState<DeviceHistoryMode>(
    DeviceHistoryMode.All,
  );

  const [viewMode, setViewMode] = useState<DeviceHistoryView>(
    DeviceHistoryView.Detailed,
  );

  const { deviceStore } = useStores();
  const device = deviceStore.getDevice(params?.uid);

  if (!device)
    return <ErrorsMessage errors={[translate("Devices.Details.noDevice")]} />;

  const {
    errors,
    isLoading: deviceLogsIsLoading,
    logs: deviceLogs,
    refetch,
  } = useDeviceHistory({
    device,
    ...period,
    filterMode,
    viewMode,
  });

  // const {
  //   activityHistory: moduleHistory,
  //   isLoading: moduleHistoryIsLoading,
  //   // errors: moduleHistoryErrors,
  //   refetch: moduleHistoryRefetch,
  // } = useModuleActivityHistory({
  //   uid: device.moduleUid as string,
  //   startDate: period.startDate,
  //   endDate: period.endDate,
  // });
  // const moduleLogs = moduleHistory
  //   .filter(
  //     (item) =>
  //       item.moduleState === ModuleActivityType.Offline ||
  //       item.moduleState === ModuleActivityType.Online,
  //   )
  //   .map((item) => ({
  //     ...item,
  //     dateTime: item.timestamp,
  //     type: HistoryItemType.Module,
  //   }));

  const logs = deviceLogs.sort(
    (itemA, itemB) => itemB.dateTime - itemA.dateTime,
  );
  const isLoading = deviceLogsIsLoading;

  const {
    availability,
    message: availabilityError,
    isUpdateAvailable,
  } = useCheckingModuleFeatureAvailability(
    device.moduleUid,
    ModuleFeature.DeviceHistory,
  );

  const historyLogs = groupByDataByDate(logs, "dateTime");

  const dates = historyLogs && Object.keys(historyLogs);

  const DeviceIcon = DeviceIcons[device.iconName] || NoneIcon;

  return (
    <Screen
      testID="DeviceDetails"
      preset="scroll"
      header={
        <>
          <TopBarNew
            title={translate("Devices.History.title")}
            backHandler={goBack}
          />
          <TopBarContent
            title={device.displayName}
            subtitle={device.typeName}
            icon={DeviceIcon}
          />
        </>
      }
      style={styles.screen}
      onRefresh={() => {
        refetch();
        // moduleHistoryRefetch();
      }}
      loading={isLoading}
      error={
        !availability && (
          <ModuleFeatureError
            moduleUid={device.moduleUid}
            message={availabilityError}
            isUpdateAvailable={isUpdateAvailable}
          />
        )
      }
    >
      <ScrollView
        bounces={false}
        horizontal
        showsHorizontalScrollIndicator={false}
      >
        <View style={styles.filterBar}>
          {period && (
            <Calendar
              period={period}
              onChange={setPeriod}
              defaultPeriod={initialPeriod}
            />
          )}
          <FilterMenu
            menuItems={menuFilter}
            value={filterMode}
            onChange={setFilterMode as any}
            defaultValue={DeviceHistoryMode.All}
          />

          <FilterMenu
            menuItems={menuView}
            value={viewMode}
            onChange={setViewMode as any}
            defaultValue={DeviceHistoryView.Detailed}
          />
        </View>
      </ScrollView>

      <ErrorsMessage errors={errors} />

      {!isLoading && !dates.length && (
        <ErrorsMessage
          errors={[translate("Devices.History.errors.noHistory")]}
        />
      )}

      {viewMode === DeviceHistoryView.Diagram ? (
        // TODO: add processing module logs to  DeviceHistoryDiagram !!!
        <DeviceHistoryDiagram dates={dates} historyLogs={historyLogs} />
      ) : (
        !isLoading &&
        dates.map((date) => (
          <View key={date}>
            <Text style={styles.date}>
              {dateTimeFormat(date, DateTimeFormats.DateDM)}
            </Text>

            {historyLogs[date]?.map((item) =>
              item.type === HistoryItemType.Module ? (
                <ModuleHistoryCard {...item} key={item.timestamp.getTime()} />
              ) : (
                <DeviceHistoryCard {...item} key={item.id} type={viewMode} />
              ),
            )}
          </View>
        ))
      )}
    </Screen>
  );
});
