import * as React from "react";
import Svg, { Path } from "react-native-svg";

export default function DevicesIcon({
  color = "#939393",
  width = 24,
  height = 24,
}) {
  return (
    <Svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75 3C9.75 2.58579 9.41421 2.25 9 2.25C8.58579 2.25 8.25 2.58579 8.25 3V6.25H7C6.58579 6.25 6.25 6.58579 6.25 7V13C6.25 13.9946 6.64509 14.9484 7.34835 15.6517C8.05161 16.3549 9.00544 16.75 10 16.75H11.25V18C11.25 18.7293 11.5397 19.4288 12.0555 19.9445C12.5712 20.4603 13.2707 20.75 14 20.75H17C17.4142 20.75 17.75 20.4142 17.75 20C17.75 19.5858 17.4142 19.25 17 19.25H14C13.6685 19.25 13.3505 19.1183 13.1161 18.8839C12.8817 18.6495 12.75 18.3315 12.75 18V16.75H14C14.9946 16.75 15.9484 16.3549 16.6517 15.6517C17.3549 14.9484 17.75 13.9946 17.75 13V7C17.75 6.58579 17.4142 6.25 17 6.25H15.75V3C15.75 2.58579 15.4142 2.25 15 2.25C14.5858 2.25 14.25 2.58579 14.25 3V6.25H9.75V3ZM14 15.25C14.5967 15.25 15.169 15.0129 15.591 14.591C16.0129 14.169 16.25 13.5967 16.25 13V7.75H7.75V13C7.75 13.5967 7.98705 14.169 8.40901 14.591C8.83097 15.0129 9.40326 15.25 10 15.25H14Z"
        fill={color}
      />
    </Svg>
  );
}
