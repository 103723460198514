import React from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "@models/index";
import { FilterScreen, useFilterItems } from "@components/screen/Filter";

import { translate } from "../../../i18n";
import { FilterItem } from "./components/FilterItem";

export const DevicesFilter = observer(() => {
  const { deviceStore } = useStores();
  const items = useFilterItems(deviceStore.sortedDevices);

  return (
    <FilterScreen
      items={items}
      selectedUids={deviceStore.viewFilter.visibleUids}
      onApply={deviceStore.updateViewFilter}
      ItemComponent={FilterItem}
      description={translate("Devices.Filter.selectDeviceWillBeDisplayed")}
    />
  );
});
