import { View, StyleSheet } from "react-native";
import dayjs from "dayjs";

import { BoldText, Button, Text } from "@components/ui";

import { palette, spacing } from "../../../../theme";
import { translate } from "../../../../i18n";
import {
  dateTimeFormat,
  DateTimeFormats,
} from "../../../../utils/timeConverter";

const MONTH_DAYS = 30;
const MONTH_ROUND_VALUE = 7 / MONTH_DAYS;

const styles = StyleSheet.create({
  cardContainer: {
    backgroundColor: palette.BlueBackground,
    borderRadius: spacing[4],
    paddingHorizontal: spacing[4],
    paddingVertical: spacing[3],
    marginTop: spacing[3],
  },
  description: {
    fontSize: 10,
    color: palette.GrayBlue,
    marginTop: 10,
  },
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: spacing[3],
    borderBottomColor: palette.White,
    borderBottomWidth: 1,
  },
  text: {
    color: palette.GrayBlue,
    marginBottom: spacing[1],
    fontSize: 12,
  },
  button: {
    marginVertical: 0,
    maxWidth: "50%",
  },
});

const getLastCalibration = (date?: Date) => {
  if (!date) return "-";
  const lastCalibration = dateTimeFormat(date, DateTimeFormats.DateDMY);
  if (lastCalibration === dateTimeFormat(dayjs(), DateTimeFormats.DateDMY))
    return translate("common.today");
  if (
    lastCalibration ===
    dateTimeFormat(dayjs().subtract(1, "day"), DateTimeFormats.DateDMY)
  )
    return translate("common.yesterday");
  return lastCalibration;
};

const getNextCalibration = (date?: Date) => {
  if (!date) return "-";
  const nextCalibration = dateTimeFormat(date, DateTimeFormats.DateDMY);
  if (nextCalibration === dateTimeFormat(dayjs(), DateTimeFormats.DateDMY))
    return translate("common.today");

  const diffDays = dayjs(date).diff(dayjs(), "days");

  if (diffDays < 1) return translate("common.today");
  if (diffDays === 1) return translate("common.tomorrow");
  if (diffDays < 15) return `in ${diffDays} days`;

  return nextCalibration;
};

const getPeriodCalibration = (
  days: number,
): { value: number | string; unit: string } => {
  if (!days)
    return {
      value: "-",
      unit: "",
    };

  const countMonth = days / MONTH_DAYS;
  const remainderOfMonth = countMonth - Math.trunc(countMonth);
  const isMonth = remainderOfMonth < MONTH_ROUND_VALUE;

  return isMonth
    ? {
        value: countMonth,
        unit: translate("Sensors.Details.month"),
      }
    : {
        value: days,
        unit: translate("Sensors.Details.days"),
      };
};

type RecalibrateCardProps = {
  date?: Date;
  recalibrationDate?: Date;
  onPress: () => void;
  periodCalibrationDays?: number;
  isInProgress?: boolean;
};

export const RecalibrateCard = ({
  date,
  recalibrationDate,
  onPress,
  periodCalibrationDays,
  isInProgress,
}: RecalibrateCardProps) => {
  const lastCalibration = getLastCalibration(date);
  const nextCalibration = getNextCalibration(recalibrationDate);

  return (
    <View style={styles.cardContainer}>
      <View>
        <View style={styles.container}>
          <View>
            <Text style={styles.text} tx="Sensors.Details.nextCalibration" />
            <Text>{nextCalibration}</Text>
          </View>
          <Button
            type={nextCalibration === "Today" ? "primary" : "secondary"}
            tx={
              isInProgress
                ? "Sensors.Details.calibrate"
                : "Sensors.Details.recalibrate"
            }
            onPress={onPress}
            style={styles.button}
          >
            Recalibrate
          </Button>
        </View>
        <Text style={styles.description}>
          {translate(
            "Sensors.Details.lastCalibrationWas",
            getPeriodCalibration(periodCalibrationDays),
          )}
          <BoldText style={styles.description}>{lastCalibration}</BoldText>
        </Text>
      </View>
    </View>
  );
};
