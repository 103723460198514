import { flow, getSnapshot, Instance, types } from "mobx-state-tree";

import { withEnvironment } from "@models/extensions/with-environment";
import { withStatus } from "@models/extensions/with-status";

import { Language } from "../../i18n";
import {
  AccountApi,
  AccountSettings,
  AccountSettingsValue,
} from "../../services/api/account-api";

export enum VolumeUnit {
  liters = "liters",
  gallons = "gallons",
}

export enum TemperatureUnit {
  celsius = "celsius",
  fahrenheit = "fahrenheit",
}

export enum TimeSystem {
  hours24 = "hours24",
  hours12 = "hours12",
}

export type Units = {
  temperature: TemperatureUnit;
  volume: VolumeUnit;
};

export const TEMPERATURE_UNITS = {
  [TemperatureUnit.celsius]: { unit: "\u00B0C" },
  [TemperatureUnit.fahrenheit]: { unit: "\u00B0F" },
};

const IsClickModel = types.model("IsClickModel").props({
  key: types.identifier,
});

const SensorsSettingsModel = types.model("SensorsSettings").props({
  uid: types.identifier,
  hideInHistory: types.maybeNull(types.boolean),
  positionInHistory: types.maybeNull(types.number),
  hideInView: types.maybeNull(types.boolean),
  positionInView: types.maybeNull(types.number),
  rangeMinValue: types.maybeNull(types.number),
  rangeMaxValue: types.maybeNull(types.number),
});
export type TSensorSettings = Instance<typeof SensorsSettingsModel>;

const DevicesSettingsModel = types.model("DeviceSettings").props({
  uid: types.identifier,
  hideInView: types.maybeNull(types.boolean),
  positionInView: types.maybeNull(types.number),
});
export type TDeviceSettings = Instance<typeof DevicesSettingsModel>;

export enum NotificationsSettingsKey {
  SeverityLevels = "severityLevels",
  Types = "types",
}

const NotificationsSettingsModel = types.model("NotificationsSettings").props({
  [NotificationsSettingsKey.SeverityLevels]: types.optional(
    types.array(types.string),
    [],
  ),

  [NotificationsSettingsKey.Types]: types.optional(
    types.array(types.string),
    [],
  ),
});

export type TNotificationsSettings = Instance<
  typeof NotificationsSettingsModel
>;

export const UserSettingsModel = types
  .model("UserSettings")
  .props({
    // settings
    language: types.maybe(
      types.enumeration<Language>(Language.en, Object.values(Language)),
    ),
    volumeUnit: types.maybe(
      types.enumeration<VolumeUnit>(
        VolumeUnit.liters,
        Object.values(VolumeUnit),
      ),
    ),
    temperatureUnit: types.maybe(
      types.enumeration<TemperatureUnit>(
        TemperatureUnit.celsius,
        Object.values(TemperatureUnit),
      ),
    ),
    timeSystem: types.maybe(
      types.enumeration<TimeSystem>(
        TimeSystem.hours24,
        Object.values(TimeSystem),
      ),
    ),
    showHelpButton: types.optional(types.boolean, true),
    oneTimeHints: types.map(IsClickModel),

    // module
    disclaimers: types.map(IsClickModel),

    sensorsSettings: types.map(SensorsSettingsModel),
    devicesSettings: types.map(DevicesSettingsModel),
    notificationsSettings: types.map(
      types.optional(types.array(types.string), []),
    ),

    updateVersionHint: types.maybeNull(types.string),
    releaseNotesHint: types.maybeNull(types.string),
    lastDateShowModulesToUpdate: types.maybeNull(types.string),
  })
  .extend(withEnvironment)
  .extend(withStatus)
  .actions((self) => {
    const accountApi = new AccountApi(self.environment.api);

    const fetchSettings = flow(function* (
      name: AccountSettings,
      cb: (data: AccountSettingsValue) => void,
    ) {
      self.setStatusPending();
      const result = yield accountApi.getSettings(name);
      if (result.kind === "ok") {
        self.setStatusDone();
        cb(result.data || {});
      } else {
        self.setStatusError(result.errors);
      }
      return result.data || {};
    });

    const saveSettings = flow(function* (
      name: AccountSettings,
      settings: AccountSettingsValue,
    ) {
      self.setStatusPending();
      const result = yield accountApi.saveSettings(name, settings);
      if (result.kind === "ok") {
        self.setStatusDone();
      } else {
        self.setStatusError(result.errors);
      }
      return result;
    });

    const saveUserSettings = () => {
      const settings = { ...getSnapshot(self) };
      delete settings.sensorsSettings;
      delete settings.devicesSettings;
      delete settings.notificationsSettings;

      return saveSettings(AccountSettings.User, settings as any);
    };

    return {
      fetchSettings,
      saveSettings,
      saveUserSettings,
    };
  })
  .actions((self) => ({
    setDisclaimerFor(key: string) {
      self.disclaimers.set(key, { key });
      self.saveUserSettings();
    },

    removeDisclaimerFor(key: string) {
      self.disclaimers.delete(key);
      self.saveUserSettings();
    },

    setSensorSettings: (uid: string, data: TSensorSettings) => {
      const prevData = self.sensorsSettings.get(uid) || {};
      const newData = { uid, ...prevData, ...data };
      return self.sensorsSettings.set(uid, newData);
    },

    setDeviceSettings: (uid: string, data: TDeviceSettings) => {
      const prevData = self.devicesSettings.get(uid) || {};
      const newData = { uid, ...prevData, ...data };
      return self.devicesSettings.set(uid, newData);
    },
  }))

  .actions((self) => ({
    fetchUserSettings: () => {
      return self.fetchSettings(AccountSettings.User, (settings) => {
        self.language = settings.language;
        self.volumeUnit = settings.volumeUnit;
        self.temperatureUnit = settings.temperatureUnit;
        self.timeSystem = settings.timeSystem;
        self.showHelpButton = settings.showHelpButton;
        self.oneTimeHints = settings.oneTimeHints;
        self.disclaimers = settings.disclaimers;
        self.updateVersionHint = settings.updateVersionHint;
        self.releaseNotesHint = settings.releaseNotesHint;
        self.lastDateShowModulesToUpdate = settings.lastDateShowModulesToUpdate;
        // Object.entries(settings).forEach(([key, value]) => {
        //   if (self[key]) self[key] = value;
        // });
      });
    },

    saveSensorSettings: (uid: string, data: TSensorSettings) => {
      self.setSensorSettings(uid, data);
      return self.saveSettings(
        AccountSettings.Sensors,
        self.sensorsSettings.toJSON() as any,
      );
    },

    saveSensorsSettings: (settings: TSensorSettings[]) => {
      settings.forEach((item) => {
        self.setSensorSettings(item.uid, item);
      });
      return self.saveSettings(
        AccountSettings.Sensors,
        self.sensorsSettings.toJSON() as any,
      );
    },

    fetchSensorsSettings: () => {
      return self.fetchSettings(AccountSettings.Sensors, (settings) => {
        self.sensorsSettings.replace(settings);
      });
    },

    deleteSensorsSettings: (sensorsUids: string[]) => {
      if (!sensorsUids?.length) return;

      sensorsUids.forEach((uid) => {
        self.sensorsSettings.delete(uid);
      });

      self.saveSettings(
        AccountSettings.Sensors,
        self.devicesSettings.toJSON() as any,
      );
    },

    saveDevicesSettings: (settings: TDeviceSettings[]) => {
      settings.forEach((item) => {
        self.setDeviceSettings(item.uid, item);
      });
      return self.saveSettings(
        AccountSettings.Devices,
        self.devicesSettings.toJSON() as any,
      );
    },

    fetchDevicesSettings: () => {
      return self.fetchSettings(AccountSettings.Devices, (settings) => {
        self.devicesSettings.replace(settings);
      });
    },

    deleteDevicesSettings: (devicesUids: string[]) => {
      if (!devicesUids?.length) return;

      devicesUids.forEach((uid) => {
        self.devicesSettings.delete(uid);
      });

      self.saveSettings(
        AccountSettings.Devices,
        self.devicesSettings.toJSON() as any,
      );
    },

    saveNotificationsSettings: (settings: TNotificationsSettings) => {
      self.notificationsSettings.set(
        NotificationsSettingsKey.SeverityLevels,
        settings[NotificationsSettingsKey.SeverityLevels],
      );

      self.notificationsSettings.set(
        NotificationsSettingsKey.Types,
        settings[NotificationsSettingsKey.Types],
      );

      return self.saveSettings(
        AccountSettings.Notifications,
        self.notificationsSettings.toJSON() as any,
      );
    },

    fetchNotificationsSettings: () => {
      return self.fetchSettings(AccountSettings.Notifications, (settings) => {
        self.notificationsSettings.replace(settings);
      });
    },
  }));
