import Svg, {
  Circle,
  Path,
  Rect,
  Defs,
  LinearGradient,
  Stop,
} from "react-native-svg";

export const VideoSvg = () => (
  <Svg width={360} height={212} viewBox="0 0 360 212" fill="none">
    <Circle cx={179} cy={106} r={106} fill="url(#a)" />
    <Path
      fill="#fff"
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M310.532 139.795a2.763 2.763 0 0 0 1.011-3.774l-13.285-23.011c-10.936-18.94-35.155-25.43-54.095-14.494l16.048 27.796c10.172 17.619 32.702 23.655 50.321 13.483Z"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M218 106c25.254-14.58 57.546-5.928 72.126 19.326"
    />
    <Path
      fill="#fff"
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M57 39.575A2.575 2.575 0 0 1 59.575 37h24.764c20.384 0 36.909 16.524 36.909 36.908H91.333C72.371 73.908 57 58.537 57 39.575Z"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M127.399 98.514c0-27.179-22.032-49.211-49.21-49.211"
    />
    <Path
      fill="#fff"
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M284.814 55.932A1.932 1.932 0 0 0 282.882 54h-18.578c-15.293 0-27.689 12.397-27.689 27.689h22.442c14.225 0 25.757-11.532 25.757-25.757Z"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M232 100.148c0-20.39 16.529-36.918 36.918-36.918"
    />
    <Path
      fill="#fff"
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M67.276 111.164a1.804 1.804 0 0 1 0-2.552L79.55 96.339c10.101-10.102 26.48-10.102 36.581 0l-14.825 14.825c-9.397 9.397-24.632 9.397-34.029 0Z"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M131.373 105.485c-13.469-13.47-35.307-13.47-48.776 0"
    />
    <Path
      fill="#fff"
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M117.947 11.055a1.218 1.218 0 0 1 1.664-.446l10.15 5.86c8.354 4.824 11.217 15.506 6.393 23.86l-12.26-7.078c-7.772-4.487-10.434-14.424-5.947-22.196Z"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M132.853 51.87c6.431-11.14 2.615-25.383-8.524-31.814"
    />
    <Rect
      width={106}
      height={119}
      x={126}
      y={46}
      fill="#fff"
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      rx={20}
    />
    <Path
      fill="#fff"
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M223.45 207.9c24.549 0 44.45-19.901 44.45-44.45 0-24.549-19.901-44.45-44.45-44.45-24.549 0-44.45 19.901-44.45 44.45 0 24.549 19.901 44.45 44.45 44.45Z"
    />
    <Path
      fill="#308AE3"
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M144 69.3c0-2.93 2.38-5.3 5.3-5.3H210c2.93 0 5.3 2.38 5.3 5.3 0 2.93-2.38 5.3-5.3 5.3h-60.7c-2.92 0-5.3-2.37-5.3-5.3Z"
    />
    <Path
      fill="#B0EACC"
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M244 156.572c5.333 3.079 5.333 10.777 0 13.856l-24.75 14.29c-5.333 3.079-12-.77-12-6.929v-28.578c0-6.159 6.667-10.008 12-6.929l24.75 14.29Z"
    />
    <Path
      fill="#308AE3"
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M144 94.3c0-2.93 2.38-5.3 5.3-5.3H210c2.93 0 5.3 2.38 5.3 5.3 0 2.93-2.38 5.3-5.3 5.3h-60.7c-2.92 0-5.3-2.37-5.3-5.3ZM144 119.3c0-2.93 2.38-5.3 5.3-5.3H176c2.93 0 5.3 2.38 5.3 5.3 0 2.93-2.38 5.3-5.3 5.3h-26.7c-2.92 0-5.3-2.37-5.3-5.3ZM144 144.3c0-2.93 2.38-5.3 5.3-5.3H160c2.93 0 5.3 2.38 5.3 5.3 0 2.93-2.38 5.3-5.3 5.3h-10.7c-2.92 0-5.3-2.37-5.3-5.3Z"
    />
    <Defs>
      <LinearGradient
        id="a"
        x1={179}
        x2={179}
        y1={0}
        y2={212}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#C1DCF7" />
        <Stop offset={1} stopColor="#C1DCF6" stopOpacity={0.2} />
      </LinearGradient>
    </Defs>
  </Svg>
);
