import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { palette } from "../../theme";

export default function WaterPumpIcon({ color = palette.Gray, size = 24 }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 36 36" fill="none">
      <Path
        d="M10.812 15.75L11.4993 15.4497C11.3799 15.1766 11.1101 15 10.812 15V15.75ZM22.188 15.75V15C21.8899 15 21.6201 15.1766 21.5007 15.4497L22.188 15.75ZM27 21.75H26.25C26.25 22.1642 26.5858 22.5 27 22.5V21.75ZM30 21.75V22.5C30.4142 22.5 30.75 22.1642 30.75 21.75H30ZM22.1889 12.7522L21.5015 13.052C21.6182 13.3197 21.8796 13.4952 22.1715 13.502L22.1889 12.7522ZM10.812 12.75V13.5C11.1101 13.5 11.3799 13.3234 11.4993 13.0503L10.812 12.75ZM10.812 15H6.25V16.5H10.812V15ZM14.25 17.25C13.021 17.25 11.9629 16.5108 11.4993 15.4497L10.1248 16.0503C10.8185 17.638 12.4035 18.75 14.25 18.75V17.25ZM18.75 17.25H14.25V18.75H18.75V17.25ZM21.5007 15.4497C21.0371 16.5108 19.979 17.25 18.75 17.25V18.75C20.5965 18.75 22.1815 17.638 22.8752 16.0503L21.5007 15.4497ZM22.188 16.5H23V15H22.188V16.5ZM23 16.5C24.7949 16.5 26.25 17.9551 26.25 19.75H27.75C27.75 17.1266 25.6234 15 23 15V16.5ZM26.25 19.75V21.75H27.75V19.75H26.25ZM27 22.5H30V21H27V22.5ZM30.75 21.75V20.75H29.25V21.75H30.75ZM30.75 20.75C30.75 15.9865 26.9437 12.1122 22.2063 12.0024L22.1715 13.502C26.0962 13.5929 29.25 16.8033 29.25 20.75H30.75ZM18.75 11.25C19.9797 11.25 21.0382 11.99 21.5015 13.052L22.8764 12.4524C22.1833 10.8632 20.5975 9.75 18.75 9.75V11.25ZM14.25 11.25H18.75V9.75H14.25V11.25ZM11.4993 13.0503C11.9629 11.9892 13.021 11.25 14.25 11.25V9.75C12.4035 9.75 10.8185 10.862 10.1248 12.4497L11.4993 13.0503ZM6.25 13.5H10.812V12H6.25V13.5ZM6 13.75C6 13.6119 6.11193 13.5 6.25 13.5V12C5.2835 12 4.5 12.7835 4.5 13.75H6ZM6 14.75V13.75H4.5V14.75H6ZM6.25 15C6.11193 15 6 14.8881 6 14.75H4.5C4.5 15.7165 5.2835 16.5 6.25 16.5V15Z"
        fill={color}
      />
      <Path
        d="M30.75 29.25C30.75 30.4926 29.7426 31.5 28.5 31.5C27.2574 31.5 26.25 30.4926 26.25 29.25C26.25 28.0204 26.7203 26.947 28.5 25.0737C30.2797 26.947 30.75 28.0204 30.75 29.25Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <Path
        d="M16.5 9.75V6.75H13.5H19.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
