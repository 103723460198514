import { StyleSheet, View } from "react-native";
import { ConnectingStatus } from "@models/module/module-connection";
import { spacing } from "../../../../../theme";
import {
  ModuleSuccessSvg,
  ModuleWaitingSvg,
  WarningSvg,
} from "../../../../../svgs/pictures";

const styles = StyleSheet.create({
  image: {
    alignItems: "center",
    justifyContent: "center",
    minHeight: 250,
    marginTop: -spacing[4],
  },
});

export const StatusImage = ({ status }: { status: ConnectingStatus }) => {
  if (status === ConnectingStatus.Error) {
    return (
      <View style={styles.image}>
        <WarningSvg />
      </View>
    );
  }
  if (status === ConnectingStatus.Success) {
    return (
      <View style={styles.image}>
        <ModuleSuccessSvg />
      </View>
    );
  }

  if (status === ConnectingStatus.Processing) {
    return (
      <View style={styles.image}>
        <ModuleWaitingSvg />
      </View>
    );
  }

  return null;
};
