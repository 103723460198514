/**
 * The app navigator (formerly "AppNavigator" and "MainNavigator") is used for the primary
 * navigation flows of your app.
 * Generally speaking, it will contain an auth flow (registration, login, forgot password)
 * and a "main" flow which the user will use once logged in.
 */
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { NavigationContainer, NavigationState } from "@react-navigation/native";

import { useStores } from "@models/index";
import { notificationRedirectTo } from "@models/notifications/redirectTo";
import { ConnectionLost } from "@screens/StatusScreens";
import { Loading } from "@components/ui";
import { Splash } from "@components/screen/Spalsh";

import { useIsConnected } from "../hooks";
// import { linking } from "../utils/linking";
import { navigationRef, useBackButtonHandler } from "./navigation-utilities";

import { GuestStack } from "./GuestStack";
import { UserStack } from "./UserStack";
import { useNotificationHandler } from "../services/pushNotifications";

/**
 * A list of routes from which we're allowed to leave the app when
 * the user presses the back button on Android.
 *
 * Anything not on this list will be a standard `back` action in
 * react-navigation.
 *
 * `canExit` is used in ./app/app.tsx in the `useBackButtonHandler` hook.
 */
const exitRoutes = [];
export const canExit = (routeName: string) => exitRoutes.includes(routeName);

type NavigationProps = Partial<
  React.ComponentProps<typeof NavigationContainer>
>;

export const AppNavigator = observer((props: NavigationProps) => {
  const [state, setState] = useState<NavigationState>(undefined);
  const rootStore = useStores();
  const { accountStore, notificationsStore } = rootStore;
  const { isConnected } = useIsConnected();
  useBackButtonHandler(canExit);

  const MODULE_ADD_PATH_NAME = "module-add-";
  const [path] = state?.routes?.slice(-1) || ([{}] as any);
  const isModuleAdding = (path.name || "").includes(MODULE_ADD_PATH_NAME);

  useNotificationHandler((event) => {
    const uid = event?.notification?.request?.content?.data?.uid;
    if (uid) {
      notificationsStore.markAsRead(uid);
    }
    notificationRedirectTo(event);
  });

  if (rootStore.isLoading) {
    return <Splash />;
  }

  return (
    <NavigationContainer
      ref={navigationRef}
      {...props}
      onStateChange={setState}
      // linking={linking}
    >
      {!isConnected && !isModuleAdding ? (
        <ConnectionLost />
      ) : (
        <>
          {accountStore.isLoggedIn ? <UserStack /> : <GuestStack />}
          {accountStore.isLoading ? <Loading /> : null}
        </>
      )}
    </NavigationContainer>
  );
});

AppNavigator.displayName = "AppNavigator";
