import React, { useMemo } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { observer } from "mobx-react-lite";
import { useTheme } from "@react-navigation/native";

import { Device } from "@models/device/device";
import { Text } from "@components/ui";

import { spacing } from "../../../../theme";
import { DeviceIcon } from "./DeviceIcon";

const createStyles = (theme: any) =>
  StyleSheet.create({
    container: {
      flexDirection: "row",
      alignItems: "center",
      padding: spacing[4],
    },
    icon: {
      marginRight: spacing[4],
    },
    info: {
      marginHorizontal: spacing[1],
    },
    typeName: {
      color: theme.colors.card,
      fontSize: 16,
    },
    name: {
      color: theme.colors.card,
      fontSize: 20,
      marginVertical: spacing[1],
    },
  });

type DeviceHeaderProps = {
  device: Device;
  style?: StyleProp<ViewStyle>;
};

export const DeviceHeader: React.FC<DeviceHeaderProps> = observer(
  ({ device, style }) => {
    const theme = useTheme() as any;
    const styles = useMemo(() => createStyles(theme), [theme]);

    return (
      <View style={[styles.container, style]}>
        <DeviceIcon device={device} style={styles.icon} />
        <View style={styles.info}>
          <Text style={[styles.typeName, theme.fonts.medium]}>
            {device.typeName}
          </Text>
          <Text style={[styles.name, theme.fonts.medium]}>
            {device.displayName}
          </Text>
        </View>
      </View>
    );
  },
);
