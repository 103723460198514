import React, { useState } from "react";
import { View, StyleSheet } from "react-native";
import { Portal } from "react-native-paper";
import dayjs from "dayjs";

import { TopBar, TopBarAlign } from "@components/layout";
import { Button, DateRangePicker, Text } from "@components/ui";

import { translate } from "../../i18n";
import { CalendarIcon } from "../../svgs";
import { defaultColors, palette, spacing } from "../../theme";
import {
  dateTimeFormat,
  DateTimeFormats,
  TPeriod,
} from "../../utils/timeConverter";
import { FilterButton } from "./FilterButton";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: defaultColors.background,
  },
  periodContainer: {
    paddingHorizontal: spacing[4],
    paddingBottom: spacing[4],
    backgroundColor: defaultColors.background,
    justifyContent: "center",
  },
  periodText: {
    fontSize: 16,
    textAlign: "center",
  },
});

const getPeriodText = (period: TPeriod): string | null => {
  if (period?.startDate && period?.endDate) {
    const startDate = dateTimeFormat(period.startDate, DateTimeFormats.DateDM);
    const endDate = dateTimeFormat(period.endDate, DateTimeFormats.DateDM);

    if (startDate === endDate) {
      return startDate;
    }
    return `${startDate} - ${endDate}`;
  }
  return translate("Calendar.allPeriod");
};

type CalendarProps = {
  period: TPeriod;
  onChange: (period: TPeriod) => void;
  defaultPeriod?: TPeriod;
  isPeriodUndefined?: boolean;
};

export const Calendar = ({
  period,
  onChange,
  defaultPeriod,
  isPeriodUndefined = false,
}: CalendarProps) => {
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();

  const [isCalendar, setCalendar] = useState(false);
  const openCalendar = () => setCalendar(true);
  const closeCalendar = () => setCalendar(false);

  const [isClearCalendar, setClearCalendar] = useState<boolean>(false);
  const clearCalendar = () => {
    setClearCalendar(true);
    setStartDate(undefined);
    setEndDate(undefined);
  };

  const isDefaultPeriod =
    period?.startDate === defaultPeriod?.startDate &&
    period?.endDate === defaultPeriod?.endDate;

  const applyHandler = () => {
    setCalendar(false);
    onChange({ startDate, endDate });
  };

  const setDateRangeHandler = (rangeStart?: string, rangeEnd?: string) => {
    setStartDate(rangeStart || undefined);
    setEndDate(rangeEnd || undefined);
    setClearCalendar(false);
  };

  const actionsTopBar = [
    {
      key: "clear-calendar",
      onPress: clearCalendar,
      text: translate("common.clear"),
    },
  ];

  return (
    <>
      <FilterButton
        onPress={openCalendar}
        label={getPeriodText(period)}
        iconLeft={CalendarIcon}
        selected={!isDefaultPeriod}
      />
      {isCalendar ? (
        <Portal>
          <View style={styles.container}>
            <TopBar
              title={translate("Calendar.selectDate")}
              back
              align={TopBarAlign.Left}
              backHandler={closeCalendar}
              actions={actionsTopBar}
            />

            <DateRangePicker
              onSuccess={setDateRangeHandler}
              theme={{ markColor: palette.GreenLight2 }}
              maxDate={dayjs().format(DateTimeFormats.DateYMD)}
              isClear={isClearCalendar}
            />

            {(startDate || isPeriodUndefined) && (
              <View style={styles.periodContainer}>
                <Text style={styles.periodText}>
                  {getPeriodText({ startDate, endDate })}
                </Text>

                <Button type="primary" onPress={applyHandler}>
                  {translate("fields.select")}
                </Button>
              </View>
            )}
          </View>
        </Portal>
      ) : null}
    </>
  );
};
