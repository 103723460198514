import Svg, { SvgProps, Path } from "react-native-svg";

export const NoAddedSensors = (props: SvgProps) => {
  return (
    <Svg width={360} height={250} fill="none" viewBox="0 0 360 250" {...props}>
      <Path
        d="M256.802 228.733h-151.62c-17.23 0-27.9-18.78-19.06-33.58l74.39-124.58c9.2-15.41 31.51-15.42 40.73-.03l74.6 124.58c8.87 14.8-1.79 33.61-19.04 33.61ZM234.191 77.21h30.64c3.48 0 5.64 3.8 3.85 6.79l-15.03 25.171c-1.86 3.11-6.37 3.12-8.23.01l-15.07-25.17c-1.8-3 .35-6.8 3.84-6.8ZM77.742 144.941h18.67c2.12 0 3.44 2.31 2.35 4.14l-9.16 15.34c-1.13 1.9-3.88 1.9-5.02 0l-9.19-15.34c-1.09-1.82.22-4.14 2.35-4.14Z"
        fill="#FFF1F1"
      />
      <Path
        d="M107.893 215.043h146.38"
        stroke="#2A2E32"
        strokeWidth={2.196}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M192.003 145.262c-4.81 1.31-9.87-1.56-11.22-6.36a9.273 9.273 0 0 1 6.06-11.34c1.85-.6 3.75-.84 5.68-.79 3.94.1 7.7 1.06 11.34 2.52.45.18.57.44.4.9-.34.92-.66 1.85-1.05 2.75-1.25 2.93-2.8 5.67-4.92 8.07-1.34 1.52-2.88 2.81-4.71 3.72-.53.18-1.05.38-1.58.53Z"
        fill="#fff"
        stroke="#2A2E32"
        strokeWidth={2.196}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M191.862 135.032c-8.45 0-15.3 6.85-15.3 15.3v63.16"
        stroke="#2A2E32"
        strokeWidth={2.196}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M192.003 173.892c-4.81 1.31-9.87-1.56-11.22-6.36a9.273 9.273 0 0 1 6.06-11.34c1.85-.6 3.75-.84 5.68-.79 3.94.1 7.7 1.06 11.34 2.52.45.18.57.44.4.9-.34.92-.66 1.85-1.05 2.75-1.25 2.93-2.8 5.67-4.92 8.07-1.34 1.52-2.88 2.81-4.71 3.72-.53.18-1.05.38-1.58.53Z"
        fill="#fff"
        stroke="#2A2E32"
        strokeWidth={2.196}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M191.862 163.662c-8.45 0-15.3 6.85-15.3 15.3"
        stroke="#2A2E32"
        strokeWidth={2.196}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M161.133 161.132c4.81 1.31 9.87-1.56 11.22-6.36a9.273 9.273 0 0 0-6.06-11.34c-1.85-.6-3.75-.84-5.68-.79-3.94.1-7.7 1.06-11.34 2.52-.45.18-.57.44-.4.9.34.92.66 1.85 1.05 2.75 1.25 2.93 2.8 5.67 4.92 8.07 1.34 1.52 2.88 2.81 4.71 3.72.52.18 1.04.39 1.58.53Z"
        fill="#fff"
        stroke="#2A2E32"
        strokeWidth={2.196}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M161.273 150.912c8.45 0 15.3 6.85 15.3 15.3"
        stroke="#2A2E32"
        strokeWidth={2.196}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M161.133 197.462c4.81 1.31 9.87-1.56 11.22-6.36a9.273 9.273 0 0 0-6.06-11.34c-1.85-.6-3.75-.84-5.68-.79-3.94.1-7.7 1.06-11.34 2.52-.45.18-.57.44-.4.9.34.92.66 1.85 1.05 2.75 1.25 2.93 2.8 5.67 4.92 8.07 1.34 1.52 2.88 2.81 4.71 3.72.52.18 1.04.39 1.58.53Z"
        fill="#fff"
        stroke="#2A2E32"
        strokeWidth={2.196}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M161.273 187.242c8.45 0 15.3 6.85 15.3 15.3"
        stroke="#2A2E32"
        strokeWidth={2.196}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M249.654 145.262c-4.81 1.31-9.87-1.56-11.22-6.36a9.273 9.273 0 0 1 6.06-11.34c1.85-.6 3.75-.84 5.68-.79 3.94.1 7.7 1.06 11.34 2.52.45.18.57.44.4.9-.34.92-.66 1.85-1.05 2.75-1.25 2.93-2.8 5.67-4.92 8.07-1.34 1.52-2.88 2.81-4.71 3.72-.52.18-1.04.38-1.58.53Z"
        fill="#fff"
        stroke="#2A2E32"
        strokeWidth={2.196}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M249.513 135.032c-8.45 0-15.3 6.85-15.3 15.3v63.16"
        stroke="#2A2E32"
        strokeWidth={2.196}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M249.654 173.892c-4.81 1.31-9.87-1.56-11.22-6.36a9.273 9.273 0 0 1 6.06-11.34c1.85-.6 3.75-.84 5.68-.79 3.94.1 7.7 1.06 11.34 2.52.45.18.57.44.4.9-.34.92-.66 1.85-1.05 2.75-1.25 2.93-2.8 5.67-4.92 8.07-1.34 1.52-2.88 2.81-4.71 3.72-.52.18-1.04.38-1.58.53Z"
        fill="#fff"
        stroke="#2A2E32"
        strokeWidth={2.196}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M249.513 163.662c-8.45 0-15.3 6.85-15.3 15.3"
        stroke="#2A2E32"
        strokeWidth={2.196}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M218.783 161.132c4.81 1.31 9.87-1.56 11.22-6.36a9.273 9.273 0 0 0-6.06-11.34c-1.85-.6-3.75-.84-5.68-.79-3.94.1-7.7 1.06-11.34 2.52-.45.18-.57.44-.4.9.34.92.66 1.85 1.05 2.75 1.25 2.93 2.8 5.67 4.92 8.07 1.34 1.52 2.88 2.81 4.71 3.72.53.18 1.05.39 1.58.53Z"
        fill="#fff"
        stroke="#2A2E32"
        strokeWidth={2.196}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M218.934 150.912c8.45 0 15.3 6.85 15.3 15.3"
        stroke="#2A2E32"
        strokeWidth={2.196}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M218.783 197.462c4.81 1.31 9.87-1.56 11.22-6.36a9.273 9.273 0 0 0-6.06-11.34c-1.85-.6-3.75-.84-5.68-.79-3.94.1-7.7 1.06-11.34 2.52-.45.18-.57.44-.4.9.34.92.66 1.85 1.05 2.75 1.25 2.93 2.8 5.67 4.92 8.07 1.34 1.52 2.88 2.81 4.71 3.72.53.18 1.05.39 1.58.53Z"
        fill="#fff"
        stroke="#2A2E32"
        strokeWidth={2.196}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M218.934 187.242c8.45 0 15.3 6.85 15.3 15.3"
        stroke="#2A2E32"
        strokeWidth={2.196}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M138.632 136.023h-21.62c-3.5 0-6.35-2.84-6.35-6.35v-26.28c0-3.5 2.84-6.35 6.35-6.35h21.62c3.5 0 6.35 2.84 6.35 6.35v26.28c-.01 3.51-2.85 6.35-6.35 6.35Z"
        fill="#fff"
        stroke="#2A2E32"
        strokeWidth={2.261}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path d="M122.182 153.943v-17.92h11.27v79.02h-11.27v-44.15" fill="#fff" />
      <Path
        d="M122.182 153.943v-17.92h11.27v79.02h-11.27v-44.15"
        stroke="#2A2E32"
        strokeWidth={2.261}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M127.822 125.943a9.41 9.41 0 1 0 0-18.82 9.41 9.41 0 0 0 0 18.82Z"
        fill="#fff"
        stroke="#2A2E32"
        strokeWidth={2.261}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="m244.261 215.04 7.58-7.58c3.23-3.23 3.23-8.47 0-11.69-3.23-3.23-8.47-3.23-11.69 0l-7.58 7.58-7.58-7.58c-3.23-3.23-8.47-3.23-11.69 0-3.23 3.23-3.23 8.47 0 11.69l7.58 7.58-7.58 7.58c-3.23 3.23-3.23 8.47 0 11.69 3.23 3.23 8.47 3.23 11.69 0l7.58-7.58 7.58 7.58c3.23 3.23 8.47 3.23 11.69 0 3.23-3.23 3.23-8.47 0-11.69l-7.58-7.58Z"
        fill="#F76B6B"
        stroke="#2A2E32"
        strokeWidth={2.196}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M127.693 88.19h.78M122.312 82.69a7.787 7.787 0 0 1 11.011 0M116.812 77.18c2.06-2.07 4.67-3.51 7.52-4.16m8.101.3c2.42.76 4.609 2.09 6.399 3.89M111.303 71.68c1.99-2 4.33-3.61 6.9-4.78m6.03-1.79c7.36-1.14 14.83 1.3 20.09 6.57M110.303 59l32.02 32.02"
        stroke="#2A2E32"
        strokeWidth={2.196}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default NoAddedSensors;
