import React, { useState } from "react";
import { Dialog } from "@components/ui";
import { translate } from "../../../i18n";

type Props = {
  visible: boolean;
  onClose: () => void;
};
export const SensorOfflinePopup = ({ visible, onClose }: Props) => (
  <Dialog
    content={translate("Sensors.Calibration.offlineSensorsPopup")}
    okText={translate("common.close")}
    visible={visible}
    onOk={onClose}
  />
);

export const useOfflinePopup = () => {
  const [visibleOfflinePopup, setVisibleOfflinePopup] = useState(false);
  const showOfflinePopup = () => setVisibleOfflinePopup(true);
  const hideOfflinePopup = () => setVisibleOfflinePopup(false);

  return { visibleOfflinePopup, showOfflinePopup, hideOfflinePopup };
};
