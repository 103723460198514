import * as Notifications from "expo-notifications";
import { navigationRef } from "../../navigators";
import { NotificationEntityType } from "./constants";

export const notificationRedirectTo = (
  event: Notifications.NotificationResponse,
) => {
  const data = event?.notification?.request?.content?.data;

  if (!data || !data.type) {
    return navigationRef?.navigate("settings-notifications");
  }

  switch (data.entityType) {
    case NotificationEntityType.Devices:
      return navigationRef?.navigate("device-details", { uid: data.deviceUid });

    case NotificationEntityType.Modules:
      return navigationRef?.navigate("module-details", { uid: data.moduleUid });

    case NotificationEntityType.Sensors:
      return navigationRef?.navigate("sensor-details", { uid: data.sensorUid });

    default:
      return navigationRef?.navigate("settings-notifications");
  }
};
