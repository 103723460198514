import { observer } from "mobx-react-lite";

import { useStores } from "@models/index";
import { Dialog } from "@components/ui";
import { ReleaseNoteContent } from "@components/common";

import { translate } from "../../i18n";

export const ReleaseNotesNotification = observer(() => {
  const {
    settingsStore: { wasReleaseNotesHintShown, markReleaseNotesHintAsShown },
    documentsStore: { releaseNotesForCurrentVersion },
  } = useStores();

  if (wasReleaseNotesHintShown) {
    return null;
  }

  if (!releaseNotesForCurrentVersion) {
    return null;
  }

  return (
    <Dialog
      visible
      title={translate("Settings.ReleaseNotes.whatsNew")}
      okText={translate("common.ok")}
      onOk={markReleaseNotesHintAsShown}
    >
      <ReleaseNoteContent releaseNote={releaseNotesForCurrentVersion} />
    </Dialog>
  );
});
