import { View, StyleSheet } from "react-native";
import { shadow } from "react-native-paper";

import { DeviceState, DeviceStateType } from "@models/device/types";

import { DefaultTheme, spacing } from "../../../../../theme";
import { translate } from "../../../../../i18n";
import { DeviceStatus } from "../../components";
import { ButtonManualHint, ButtonManualHintType } from "./ButtonManualHint";
import { SwitchHint } from "./SwitchHint";

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    width: "100%",
    backgroundColor: DefaultTheme.colors.background,
    paddingHorizontal: spacing[4],
    paddingTop: spacing[4],
    borderRadius: spacing[2],
    top: 86,
    left: 0,
    ...(shadow(5) as any),
  },
  button: {
    position: "relative",
    top: 0,
  },
});

export const DeviceStateHint = ({
  state = DeviceState.Off,
  stateType = DeviceStateType.Idle,
}) => {
  return (
    <>
      <View style={styles.container}>
        <DeviceStatus
          state={state}
          stateType={stateType}
          displayState={translate(`Devices.stateType.${stateType}`)}
        />

        {state === DeviceState.On ? (
          <ButtonManualHint
            type={ButtonManualHintType.Shutdown}
            style={styles.button}
          />
        ) : null}
      </View>

      {stateType === DeviceStateType.BySchedule ||
      stateType === DeviceStateType.BySensor ? (
        <SwitchHint on />
      ) : null}
    </>
  );
};
