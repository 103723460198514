import React from "react";
import { StyleSheet, View } from "react-native";

import { translate } from "../../i18n";
import { spacing } from "../../theme";
import { Dialog } from "./Dialog";
import { ErrorText } from "./ErrorText";

const styles = StyleSheet.create({
  error: {
    marginVertical: spacing[2],
  },
  image: {
    marginBottom: spacing[4],
    justifyContent: "center",
    alignItems: "center",
  },
});

interface AlertProps {
  errors?: string[];
  onCancel: any;
  Image?: React.FunctionComponent<any>;
  title?: string;
}
/* eslint-disable react/no-array-index-key */
export const ErrorsAlert: React.FunctionComponent<AlertProps> = ({
  errors,
  onCancel,
  Image,
  title = translate("common.error"),
}) => {
  if (!errors?.length) return null;
  return (
    <Dialog
      title={title}
      visible
      onCancel={onCancel}
      cancelText={translate("common.ok")}
    >
      {Image && (
        <View style={styles.image}>
          <Image />
        </View>
      )}
      <View>
        {errors?.length > 1 ? (
          errors.map((errorString, index) => (
            <ErrorText key={`error-string-${index}`} style={styles.error}>
              {errorString}
            </ErrorText>
          ))
        ) : (
          <ErrorText>{errors[0] || "Error"}</ErrorText>
        )}
      </View>
    </Dialog>
  );
};
