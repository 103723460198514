import { View, StyleSheet } from "react-native";
import { useNavigation } from "@react-navigation/native";

import { Screen } from "@components/index";
import { Button, Heading, Text } from "@components/ui";
import { LayoutCenter } from "@components/layout";

import { spacing } from "../../theme";
import { FooterFlowers, Success } from "../../svgs/pictures";

const styles = StyleSheet.create({
  screen: {
    paddingHorizontal: spacing[5],
    paddingBottom: spacing[0],
    paddingTop: spacing[6],
  },
  bottomImageContainer: {
    flex: 1,
    justifyContent: "flex-end",
  },
  heading: {
    marginBottom: spacing[6],
  },
  description: {
    textAlign: "center",
    marginBottom: spacing[3],
  },
  subDiscription: {
    marginBottom: spacing[6],
  },
  descriptionContainer: {
    width: "80%",
    alignItems: "center",
  },
  title: {
    lineHeight: spacing[6],
    fontSize: spacing[5],
  },
});

export const RegistrationSuccess = () => {
  const navigation = useNavigation() as any;

  const submitHandler = () => {
    navigation.navigate("Onboarding");
  };

  return (
    <Screen style={styles.screen}>
      <LayoutCenter>
        <View>
          <Success />
        </View>
        <View style={styles.descriptionContainer}>
          <Heading
            style={styles.title}
            tx="Auth.SuccessfullRegistrationScreen.success"
          />
          <Text
            tx="Auth.SuccessfullRegistrationScreen.congratulations"
            style={styles.description}
          />
          <Text
            tx="Auth.SuccessfullRegistrationScreen.haveAGoodHarvest"
            style={styles.subDiscription}
          />
          <Button type="primary" onPress={submitHandler} tx="common.gotIt">
            Got it
          </Button>
        </View>
        <View style={styles.bottomImageContainer}>
          <FooterFlowers />
        </View>
      </LayoutCenter>
    </Screen>
  );
};
