import { ApiResponse } from "apisauce";
import { Platform } from "react-native";

import {
  TFAQData,
  TManualData,
  TReleaseNotesData,
} from "@models/documents/types";

import { ApiProblem, processResponse } from "./response-resolver";
import { Api } from "./api";

const FAQ_PATH = "/documents/Faq";
const MANUAL_PATH = "/documents/Manual";
const RELEASE_NOTES_PATH = "/documents/ReleaseNotes/";

export type getFAQResult = { kind: "ok"; data: TFAQData[] } | ApiProblem;
export type getManualResult = { kind: "ok"; data: TManualData[] } | ApiProblem;
export type getReleaseNotesResult =
  | { kind: "ok"; data: TReleaseNotesData[] }
  | ApiProblem;

export class DocumentsApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async readFAQData(): Promise<getFAQResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(FAQ_PATH);
      return processResponse(response);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async readManualData(): Promise<getManualResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        MANUAL_PATH,
      );
      return processResponse(response);
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }

  async readReleaseNotesData(): Promise<getReleaseNotesResult> {
    // TODO !!! web
    const prefixReleaseNotes = Platform.OS === "web" ? "android" : Platform.OS;

    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        RELEASE_NOTES_PATH + prefixReleaseNotes,
      );
      return processResponse(response, (data) =>
        data.map((item) => ({
          ...item,
          date: new Date(item.date),
          createdAt: new Date(item.createdAt),
        })),
      );
    } catch (e) {
      if (__DEV__) console.log(e.message);
      return { kind: "error" };
    }
  }
}
