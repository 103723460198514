import { types as t, flow, Instance } from "mobx-state-tree";

import { withEnvironment } from "../extensions/with-environment";
import { withStatus } from "../extensions/with-status";
import { withRootStore } from "../extensions/with-root-store";
import {
  persistToken,
  eraseToken,
  getToken,
  SecureValueKey,
  saveSecureValue,
  deleteSecureValue,
  // clear as clearStorage,
} from "../../utils/storage";
import {
  AccountApi,
  LoginResponse,
  LoginResult,
  RegisterResult,
  UserResult,
  RecoveryRequestResult,
  RecoveryTokenResult,
  RecoveryChangePasswordResult,
  RecoveryChangePasswordAttrs,
  AccountDeleteRequestResult,
  AccountDeleteConfirmResult,
  ChangeEmailResult,
} from "../../services/api/account-api";
import { SupportApi, TSupportMessageData } from "../../services/api";

const UserModel = t.model("User", {
  id: t.maybeNull(t.number),
  uid: t.maybeNull(t.string),
  accountUid: t.maybeNull(t.string),
  email: t.maybeNull(t.string),
  firstName: t.maybeNull(t.string),
  lastName: t.maybeNull(t.string),
  role: t.maybeNull(t.string),
  phoneNumber: t.maybeNull(t.string),
  companyName: t.maybeNull(t.string),
  // companyUrl: t.maybeNull(t.string),
  // roleInCompany: t.maybeNull(t.string),
  description: t.maybeNull(t.string),
  fullName: t.maybeNull(t.string),
});

const UserLoginModel = t.model("UserLogin", {
  username: t.string,
  password: t.string,
});

const UserRegisterModel = t.model("UserRegister", {
  Email: t.string,
  Password: t.string,
  FirstName: t.maybeNull(t.string),
  LastName: t.maybeNull(t.string),
  PhoneNumber: t.maybeNull(t.string),
  CompanyName: t.maybeNull(t.string),
  CompanyUrl: t.maybeNull(t.string),
  RoleInCompany: t.maybeNull(t.string),
  Description: t.maybeNull(t.string),
});

export const AccountStoreModel = t
  .model("AccountStore", {
    isActive: false,
    currentUser: t.maybeNull(UserModel),
    initialPage: t.maybeNull(t.string),
  })
  .extend(withEnvironment)
  .extend(withStatus)
  .extend(withRootStore)
  .actions((self) => {
    const clear = () => {
      self.isActive = false;
      self.currentUser = undefined;
      self.initialPage = undefined;
    };

    const accountApi = new AccountApi(self.environment.api);
    const supportApi = new SupportApi(self.environment.api);

    const authUser = flow(function* (data: LoginResponse) {
      yield persistToken(data.token);
      self.environment.api.apisauce.setHeader(
        "Authorization",
        `Bearer ${data.token}`,
      );
      self.currentUser = data.user;
      self.rootStore.afterUserLogin();
    });

    const changeActiveStatus = (
      flag: boolean,
      initialPage = "sensors-list",
    ) => {
      self.isActive = flag;
      self.initialPage = initialPage;
    };

    const logout = flow(function* () {
      yield eraseToken();
      // yield eraseUsername();
      self.environment.api.apisauce.deleteHeader("Authorization");
      self.currentUser = undefined;
      self.initialPage = undefined;
      self.isActive = false;
      self.rootStore.clearAllData();
      // clearStorage();
    });

    const login = flow(function* (username: string, password: string) {
      // self.isActive = true;
      // yield authUser({
      //   token: "string",
      // userDetails: {
      //     id: 0,
      //     firstName: "string",
      //     lastName: "string",
      //     email: "string",
      //     role: "User",
      //     phoneNumber: "string",
      //     companyName: "string",
      //     companyUrl: "string",
      //     roleInCompany: "string",
      //     description: "string",
      //     fullName: "string"
      //   }
      // });
      // self.setStatusDone();
      // return;
      self.setStatusPending();
      const result: LoginResult = yield accountApi.login(
        username.trim(),
        password.trim(),
      );

      if (result.kind === "ok") {
        yield authUser(result.data);
        self.isActive = true;

        saveSecureValue(SecureValueKey.LoginData, { username, password });

        self.setStatusDone();
      } else {
        self.setStatusError(result.errors);
      }
    });

    const checkCurrentUser = flow(function* () {
      self.rootStore.setStatusPending();

      const token = yield getToken();
      if (token) {
        self.environment.api.apisauce.setHeader(
          "Authorization",
          `Bearer ${token}`,
        );

        self.setStatusPending();
        const result: UserResult = yield accountApi.getCurrentUser();

        if (result.kind === "ok") {
          self.currentUser = result.data;
          self.isActive = true;
          self.setStatusDone();
        } else {
          self.setStatusError(result.errors);
          yield logout();
        }
      } else {
        yield logout();
      }

      self.rootStore.setStatusDone();
    });

    const register = flow(function* (user: UserRegister) {
      self.setStatusPending();
      const result: RegisterResult = yield accountApi.register(user);

      if (result.kind === "ok") {
        yield authUser(result.data);
        self.setStatusDone();
      } else {
        // TODO: return { data, errors } ???
        self.setStatusError();
        throw new Error(result.errors.join());
      }
    });

    // const updateUser = flow(function*(user: UserRegister) {
    //   const userResponse: UserResponse = yield accountApi.updateUser(user);
    //   self.currentUser = userResponse.user;
    // });

    const recoveryRequest = flow(function* (username: string) {
      self.setStatusPending();
      const result: RecoveryRequestResult = yield accountApi.recoveryRequest(
        username,
      );

      if (result.kind === "ok") {
        self.setStatusDone();
      } else {
        self.setStatusError(result.errors);
      }

      return result;
    });

    const recoveryVerify = flow(function* (username: string, code: string) {
      const result: RecoveryTokenResult = yield accountApi.recoveryToken(
        username,
        code,
      );

      if (result.kind === "ok") {
        return { token: result.data.token };
      }

      return result;
    });

    const recoveryChangePassword = flow(function* (
      attrs: RecoveryChangePasswordAttrs,
    ) {
      self.setStatusPending();
      const result = yield accountApi.recoveryChangePassword(attrs);
      if (result.kind === "ok") {
        deleteSecureValue(SecureValueKey.LoginData);
        self.setStatusDone();
      } else {
        self.setStatusError(result.errors);
      }

      return result;
    });

    const changePassword = flow(function* (
      oldPassword: string,
      newPassword: string,
      confirmedPassword: string,
    ) {
      const result: RecoveryChangePasswordResult =
        yield accountApi.changePassword(
          oldPassword,
          newPassword,
          confirmedPassword,
        );

      if (result.kind === "ok") {
        deleteSecureValue(SecureValueKey.LoginData);
        return true;
      }

      return false;
    });
    const setDefoultInitialRouteName = () => {
      self.initialPage = undefined;
    };

    const sendMessageToSupport = flow(function* (
      formData: TSupportMessageData,
    ) {
      self.setStatusPending();
      const result = yield supportApi.sendMessage({
        ...formData,
        accountUid: self.currentUser.accountUid,
      });

      if (result.kind === "ok") {
        self.setStatusDone();
      } else {
        self.setStatusError(result.errors);
      }

      return result;
    });

    const accountDeleteRequest = flow(function* (email: string) {
      self.setStatusPending();
      const result: AccountDeleteRequestResult =
        yield accountApi.accountDeleteRequest(email);

      if (result.kind === "ok") {
        self.setStatusDone();
      } else {
        self.setStatusError(result.errors);
      }

      return result;
    });

    const accountDeleteConfirm = flow(function* (email: string, code: string) {
      self.setStatusPending();
      const result: AccountDeleteConfirmResult =
        yield accountApi.accountDeleteConfirm(email, code);

      if (result.kind === "ok") {
        self.setStatusDone();
      } else {
        self.setStatusError(result.errors);
      }

      return result;
    });

    const changeEmailRequest = flow(function* (newEmail: string) {
      self.setStatusPending();
      const result: ChangeEmailResult = yield accountApi.changeEmailRequest(
        newEmail,
      );

      if (result.kind === "ok") {
        self.setStatusDone();
      } else {
        self.setStatusError(result.errors);
      }

      return result;
    });

    const changeEmailConfirm = flow(function* (newEmail: string, code: string) {
      self.setStatusPending();
      const result: ChangeEmailResult = yield accountApi.changeEmailConfirm(
        newEmail,
        code,
      );

      if (result.kind === "ok") {
        self.setStatusDone();
      } else {
        self.setStatusError(result.errors);
      }

      return result;
    });

    const changeEmailConfirmNewEmail = flow(function* (
      newEmail: string,
      code: string,
    ) {
      self.setStatusPending();
      const result: ChangeEmailResult =
        yield accountApi.changeEmailConfirmNewEmail(newEmail, code);

      if (result.kind === "ok") {
        self.setStatusDone();
      } else {
        self.setStatusError(result.errors);
      }

      return result;
    });

    return {
      clear,
      login,
      logout,
      register,
      checkCurrentUser,
      recoveryRequest,
      recoveryVerify,
      recoveryChangePassword,
      changeActiveStatus,
      changePassword,
      setDefoultInitialRouteName,
      sendMessageToSupport,
      accountDeleteRequest,
      accountDeleteConfirm,
      changeEmailRequest,
      changeEmailConfirm,
      changeEmailConfirmNewEmail,
    };
  })
  .views((self) => ({
    get isLoggedIn() {
      return self.currentUser && self.isActive;
    },
    get initialRouteName() {
      return self.initialPage;
    },
  }));

export type User = Instance<typeof UserModel>;
export type UserRegister = Instance<typeof UserRegisterModel>;
export type UserLogin = Instance<typeof UserLoginModel>;
export type AccountStore = Instance<typeof AccountStoreModel>;

// https://github.com/airyboy/react-mobx-state-tree-typescript-realworld-example-app/blob/master/src/stores/AuthStore.ts

// ---- Auth ---
// https://github.com/jeffreylees/reactnative-jwts/blob/master/index.ios.js
// https://github.com/LucasGarcez/react-native-auth-flow/blob/master/src/contexts/Auth.tsx +
// https://github.com/MosesEsan/mesan-react-native-authentication-app/blob/auth/app/providers/auth.js ++

// ---- mobx-state-tree ---
// https://pietrzakadrian.medium.com/react-native-authentication-flow-with-mobx-state-tree-dd403e03f2c9 ???
// https://dev-gang.ru/article/mobx-state-tree-mstupravlenie-sostojaniem-5lpgahmw0d/
// https://dev.to/margaretkrutikova/how-to-mobx-state-tree-react-typescript-3d5j
// https://dev.to/lloyds-digital/normalize-your-react-query-data-with-mobx-state-tree-17fa

// https://oramind.com/react-and-mobx-state-tree/ ++ - refresh data !

// https://github.com/airyboy/react-mobx-state-tree-typescript-realworld-example-app/blob/master/src/stores/AuthStore.ts
