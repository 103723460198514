import { flow, types } from "mobx-state-tree";

import { withRootStore } from "@models/extensions/with-root-store";
import {
  DocumentsApi,
  getFAQResult,
  getManualResult,
  getReleaseNotesResult,
} from "../../services/api";
import { withEnvironment } from "../extensions/with-environment";
import { withStatus } from "../extensions/with-status";
import {
  IsFetchTimeProps,
  withFetchTimeout,
} from "../extensions/with-fetch-timeout";
import {
  ReleaseNotesType,
  TFAQData,
  TManualData,
  TReleaseNotesData,
} from "./types";

const READ_FAQ_DATA = "readFAQData";
const READ_MANUAL_DATA = "readManualData";
const READ_RELEASE_NOTES_DATA = "readReleaseNotesData";

const addIdToItems = (items: any[]) =>
  items.map((item) => ({
    ...item,
    id: Math.random().toString(32).substr(2),
  }));
export const FAQDataModel = types.model("FAQData").props({
  id: types.maybeNull(types.string),
  title: types.optional(types.string, ""),
  content: types.optional(types.string, ""),
  category: types.maybeNull(types.string),
});

export const ManualDataModel = types.model("ManualData").props({
  id: types.maybeNull(types.string),
  title: types.optional(types.string, ""),
  url: types.optional(types.string, ""),
});

export const ReleaseNotesDataModel = types.model("ReleaseNotesData").props({
  id: types.number,
  type: types.maybeNull(
    types.enumeration<ReleaseNotesType>(
      "ReleaseNotesType",
      Object.values(ReleaseNotesType),
    ),
  ),
  date: types.maybeNull(types.Date),
  version: types.optional(types.string, ""),
  content: types.optional(types.string, ""),
  createdAt: types.maybeNull(types.Date),
  createdBy: types.maybeNull(types.string),
});

export const DocumentsStoreModel = types
  .model("DocumentsStore")
  .props({
    faqData: types.optional(types.array(FAQDataModel), []),
    manualsData: types.optional(types.array(ManualDataModel), []),
    releaseNotesData: types.optional(types.array(ReleaseNotesDataModel), []),
  })
  .extend(withEnvironment)
  .extend(withStatus)
  .extend(withFetchTimeout)
  .extend(withRootStore)
  .views((self) => ({
    get releaseNotesForCurrentVersion(): TReleaseNotesData | null {
      if (!self.releaseNotesData?.length) return null;

      return (
        self.releaseNotesData.find(
          (item) =>
            item.version ===
            self.rootStore.settingsStore.versions?.currentAppVersion,
        ) || null
      );
    },
  }))
  .actions((self) => ({
    resetFAQData: (data: TFAQData[]) => {
      self.faqData.replace(addIdToItems(data));
    },

    resetManualData: (data: TManualData[]) => {
      self.manualsData.replace(addIdToItems(data));
    },

    clear: () => {
      self.faqData.clear();
      self.manualsData.clear();
      self.releaseNotesData.clear();
    },
  }))
  .actions((self) => {
    const documentsApi = new DocumentsApi(self.environment.api);

    const readFAQData = flow(function* (fetchProps: IsFetchTimeProps = {}) {
      if (!self.isFetchTime(READ_FAQ_DATA, fetchProps)) return null;

      self.setStatusPending();
      const result: getFAQResult = yield documentsApi.readFAQData();

      if (result.kind === "ok") {
        self.resetErrors();
        self.resetFAQData(result.data);
        self.setStatusDone();
        return self.faqData;
      }
      self.setStatusError(result.errors);
      self.clearFetchTime(READ_FAQ_DATA);
      return null;
    });

    const readManualData = flow(function* (fetchProps: IsFetchTimeProps = {}) {
      if (!self.isFetchTime(READ_MANUAL_DATA, fetchProps)) return null;

      self.setStatusPending();
      const result: getManualResult = yield documentsApi.readManualData();

      if (result.kind === "ok") {
        self.resetErrors();
        self.resetManualData(result.data);
        self.setStatusDone();
        return self.manualsData;
      }
      self.setStatusError(result.errors);
      self.clearFetchTime(READ_MANUAL_DATA);
      return null;
    });

    const readReleaseNotesData = flow(function* (
      fetchProps: IsFetchTimeProps = {},
    ) {
      if (!self.isFetchTime(READ_RELEASE_NOTES_DATA, fetchProps)) return null;

      self.setStatusPending();
      const result: getReleaseNotesResult =
        yield documentsApi.readReleaseNotesData();

      if (result.kind === "ok") {
        self.resetErrors();
        self.releaseNotesData.replace(result.data);
        self.setStatusDone();

        return self.releaseNotesData;
      }
      self.setStatusError(result.errors);
      self.clearFetchTime(READ_RELEASE_NOTES_DATA);
      return null;
    });

    return {
      readFAQData,
      readManualData,
      readReleaseNotesData,
    };
  });
