import { useCallback, useEffect, useState } from "react";

import { useStores } from "@models/index";
import { Rule } from "@models/rule/rule";
import { Device } from "@models/device/device";
import { RulesApi } from "../services/api/rules-api";

export type RuleWithDevice = Rule & { device: Device };

type TModuleActivityResult = {
  isLoading: boolean;
  rules: RuleWithDevice[];
  errors: string[] | null;
  refetch: () => void;
};

export const useRules = (): TModuleActivityResult => {
  const [rules, setRules] = useState<undefined | RuleWithDevice[]>([]);
  const [errors, setErrors] = useState<string[] | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [refetchToken, setRefetchToken] = useState<number | null>(null);

  const rootStore = useStores();
  const rulesApi = new RulesApi(rootStore.environment.api);

  const getData = async () => {
    setLoading(true);
    const result = await rulesApi.getRules();
    setLoading(false);

    if (result.kind === "ok" && result.data) {
      const rulesWithDevice = result.data.map((rule) => {
        const device = rootStore.deviceStore.getDevice(rule.deviceUid);
        return { ...rule, device };
      });

      setRules(rulesWithDevice);
      setErrors(null);
    }

    if (result.kind === "error") {
      setRules(undefined);
      setErrors(result.errors);
    }
  };

  useEffect(() => {
    getData();
  }, [refetchToken]);

  const refetch = useCallback(() => setRefetchToken(Date.now()), []);

  return {
    isLoading,
    rules,
    errors,
    refetch,
  };
};
