import React, { useMemo } from "react";
import { View, StyleSheet, StyleProp, ViewStyle } from "react-native";
import { useTheme } from "@react-navigation/native";

import { Button as PaperButton } from "react-native-paper";

import { spacing } from "../../theme";

const createStyles = (theme: any) =>
  StyleSheet.create({
    tabs: {
      flexDirection: "row",
      justifyContent: "flex-start",
      marginHorizontal: -spacing[1],
    },
    button: {
      backgroundColor: theme.colors.cardBG,
      marginHorizontal: spacing[1],
      borderRadius: spacing[4],
      minWidth: 76,
    },
    buttonActive: {
      backgroundColor: theme.colors.primary,
    },
    buttonLabel: {
      color: theme.colors.subLabel,
      lineHeight: 14,
    },
    buttonLabelActive: {
      color: theme.colors.card,
    },
  });

export type RadioTabItem = {
  key: string;
  name: string;
  component?: React.ComponentType<any>;
  onPress?: (key: string) => void;
};

type TabButtonProps = React.ComponentProps<typeof PaperButton> & {
  isActive?: boolean;
};
function RadioTabButton({
  isActive = false,
  children,
  ...props
}: TabButtonProps) {
  const theme = useTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <PaperButton
      uppercase={false}
      style={[styles.button, isActive && styles.buttonActive]}
      labelStyle={[styles.buttonLabel, isActive && styles.buttonLabelActive]}
      {...props}
    >
      {children}
    </PaperButton>
  );
}

type RadioTabsProps = {
  items: RadioTabItem[];
  currentTab?: string;
  onPress: (key: string) => void;
  style?: StyleProp<ViewStyle>;
};
export const RadioTabs = React.memo(
  ({ items, currentTab, onPress, style }: RadioTabsProps) => {
    const theme = useTheme() as any;
    const styles = useMemo(() => createStyles(theme), [theme]);

    return (
      <View style={[styles.tabs, style]}>
        {items.map((item) => (
          <RadioTabButton
            key={item.key}
            isActive={currentTab === item.key}
            onPress={() => (item.onPress || onPress)(item.key)}
          >
            {item.name}
          </RadioTabButton>
        ))}
      </View>
    );
  },
);
