import { StyleSheet, View, Text } from "react-native";
import { shadow } from "react-native-paper";

import { DefaultTheme, spacing } from "../../../../../theme";

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    backgroundColor: DefaultTheme.colors.backgroundGradientStart,
    padding: spacing[2],
    borderRadius: spacing[2],
    top: 64,
    right: 60,
    ...(shadow(5) as any),
  },
  text: {
    fontSize: 16,
    lineHeight: 20,
    color: DefaultTheme.colors.card,
  },
});

type ButtonCommonProps = {
  label: string;
  right: number;
};
const ButtonCommon = ({ label, right }: ButtonCommonProps) => (
  <View style={[styles.container, { right }]}>
    <Text style={styles.text}>{label}</Text>
  </View>
);

export const ButtonOneDay = () => <ButtonCommon label="1D" right={60} />;
export const ButtonSevenDay = () => <ButtonCommon label="7D" right={16} />;
