import * as LocalAuthentication from "expo-local-authentication";

import { translate } from "../i18n";
import { rnLogger } from "./logger";

export const getAuthenticationType =
  async (): Promise<LocalAuthentication.AuthenticationType | null> => {
    const isEnrolled = await LocalAuthentication.isEnrolledAsync();
    console.log("isEnrolled", isEnrolled);
    rnLogger.info(`isEnrolled: ${isEnrolled}`, {
      action: "biometric",
    });
    if (!isEnrolled) return null;

    const types = await LocalAuthentication.supportedAuthenticationTypesAsync();
    console.log("supportedAuthenticationTypesAsync", types);
    rnLogger.info(`supportedAuthenticationTypesAsync: ${types}`, {
      action: "biometric",
    });

    if (!types?.length) return null;

    return types.includes(
      LocalAuthentication.AuthenticationType.FACIAL_RECOGNITION,
    )
      ? LocalAuthentication.AuthenticationType.FACIAL_RECOGNITION
      : LocalAuthentication.AuthenticationType.FINGERPRINT;
  };

export const authenticateUser = async (): Promise<boolean> => {
  const result = await LocalAuthentication.authenticateAsync({
    promptMessage: translate("Auth.logIn"),
    cancelLabel: translate("common.cancel"),
    disableDeviceFallback: true,
  });

  return result.success;
};
