import { StyleSheet, View, ViewStyle } from "react-native";

import {
  NOTIFICATION_ICON,
  NotificationSeverityLevel,
  NotificationType,
} from "@models/notifications/constants";

import * as NotificationIcons from "../../../../svgs/notificationTypes";
import { MessageIcon } from "../../../../svgs";
import { spacing } from "../../../../theme";
import { useAppTheme } from "../../../../hooks";

const styles = StyleSheet.create({
  icon: {
    marginRight: spacing[2],
  },
});

type NotificationTypeIconProps = {
  type: NotificationType;
  severityLevel?: NotificationSeverityLevel;
  defaultIcon?: React.FunctionComponent<any>;
  style?: ViewStyle;
};

export const NotificationTypeIcon = ({
  type,
  severityLevel,
  style,
  defaultIcon = MessageIcon,
}: NotificationTypeIconProps) => {
  const theme = useAppTheme();
  const iconName = NOTIFICATION_ICON[type];
  const NotificationIcon = NotificationIcons[iconName] || defaultIcon;
  const isWarning = [
    NotificationSeverityLevel.Warning,
    NotificationSeverityLevel.Critical,
  ].includes(severityLevel as NotificationSeverityLevel);
  const iconColor = isWarning ? theme.colors.warning : theme.colors.label;

  return NotificationIcon ? (
    <View style={[styles.icon, style]}>
      <NotificationIcon color={iconColor} />
    </View>
  ) : null;
};
