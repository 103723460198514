import React from "react";
import Svg, { Path } from "react-native-svg";
import { palette } from "../theme";

const LogoHorizontal = ({
  color = palette.LogoGreen,
  width = 193,
  height = 84,
}) => {
  return (
    <Svg width={width} viewBox="0 0 193 32" fill="none" height={height}>
      <Path
        d="M48.7823 20.7834C48.2464 21.4863 47.5036 22.0186 46.554 22.3803C45.6044 22.742 44.5259 22.9228 43.3185 22.9228C42.0772 22.9228 40.9783 22.6396 40.0219 22.0732C39.0655 21.5068 38.3261 20.6981 37.8038 19.6472C37.2883 18.5894 37.0203 17.3577 37 15.9519V14.7849C37 12.5329 37.5359 10.7757 38.6076 9.51321C39.6793 8.2439 41.175 7.60925 43.0946 7.60925C44.7429 7.60925 46.0521 8.0187 47.022 8.83761C47.992 9.65652 48.5754 10.8371 48.7721 12.3794H46.2488C45.9639 10.5846 44.9295 9.68723 43.1455 9.68723C41.9924 9.68723 41.114 10.1069 40.5103 10.9463C39.9133 11.7789 39.6047 13.0038 39.5844 14.6212V15.7676C39.5844 17.3781 39.9201 18.6304 40.5917 19.5244C41.27 20.4115 42.2094 20.8551 43.41 20.8551C44.726 20.8551 45.662 20.5548 46.2182 19.9543V17.0369H43.1658V15.0716H48.7823V20.7834Z"
        fill={color}
      />
      <Path
        d="M57.0543 16.9755H54.185V22.7181H51.6108V7.81397H56.8202C58.5296 7.81397 59.8489 8.19954 60.7782 8.97068C61.7075 9.74182 62.1721 10.8576 62.1721 12.318C62.1721 13.3143 61.9313 14.1503 61.4497 14.8259C60.9749 15.4947 60.3101 16.0099 59.4555 16.3716L62.7826 22.585V22.7181H60.0253L57.0543 16.9755ZM54.185 14.8975H56.8304C57.6986 14.8975 58.377 14.6792 58.8653 14.2424C59.3537 13.7988 59.5979 13.1949 59.5979 12.4306C59.5979 11.6321 59.3707 11.0145 58.9162 10.5778C58.4685 10.141 57.797 9.91584 56.9016 9.90219H54.185V14.8975Z"
        fill={color}
      />
      <Path
        d="M76.437 15.655C76.437 17.1154 76.186 18.3984 75.6841 19.5039C75.1821 20.6026 74.4631 21.4488 73.527 22.0425C72.5977 22.6294 71.526 22.9228 70.3118 22.9228C69.1112 22.9228 68.0395 22.6294 67.0966 22.0425C66.1606 21.4488 65.4348 20.606 64.9193 19.5141C64.4105 18.4223 64.1528 17.1632 64.146 15.7369V14.8975C64.146 13.444 64.4004 12.161 64.9091 11.0487C65.4246 9.93631 66.147 9.08669 67.0763 8.49981C68.0124 7.9061 69.0841 7.60925 70.2915 7.60925C71.4989 7.60925 72.5672 7.90269 73.4965 8.48957C74.4326 9.06963 75.155 9.90901 75.6637 11.0077C76.1724 12.0996 76.4302 13.3723 76.437 14.8259V15.655ZM73.8628 14.8771C73.8628 13.2256 73.5508 11.9597 72.9267 11.0794C72.3095 10.199 71.431 9.75888 70.2915 9.75888C69.1791 9.75888 68.3074 10.199 67.6766 11.0794C67.0526 11.9529 66.7337 13.1915 66.7202 14.7952V15.655C66.7202 17.2928 67.0356 18.5587 67.6664 19.4527C68.304 20.3467 69.1858 20.7937 70.3118 20.7937C71.4514 20.7937 72.3264 20.3569 72.9369 19.4834C73.5542 18.6099 73.8628 17.3338 73.8628 15.655V14.8771Z"
        fill={color}
      />
      <Path
        d="M90.6205 19.033L92.8996 7.81397H95.4534L92.0347 22.7181H89.5725L86.7541 11.8369L83.8747 22.7181H81.4022L77.9835 7.81397H80.5374L82.8368 19.0125L85.6654 7.81397H87.8224L90.6205 19.033Z"
        fill={color}
      />
      <Path
        d="M97.3357 22.7181V7.81397H101.711C103.02 7.81397 104.18 8.10741 105.191 8.6943C106.208 9.28118 106.995 10.1137 107.551 11.192C108.107 12.2702 108.385 13.5054 108.385 14.8975V15.6448C108.385 17.0574 108.104 18.2994 107.541 19.3708C106.985 20.4422 106.188 21.268 105.15 21.848C104.119 22.4281 102.935 22.7181 101.599 22.7181H97.3357ZM99.9099 9.90219V20.6504H101.589C102.939 20.6504 103.973 20.2273 104.692 19.3811C105.418 18.528 105.787 17.3065 105.801 15.7164V14.8873C105.801 13.27 105.452 12.0348 104.753 11.1817C104.054 10.3287 103.04 9.90219 101.711 9.90219H99.9099Z"
        fill={color}
      />
      <Path
        d="M113.666 22.7181H111.102V7.81397H113.666V22.7181Z"
        fill={color}
      />
      <Path
        d="M122.274 16.9755H119.405V22.7181H116.83V7.81397H122.04C123.749 7.81397 125.068 8.19954 125.998 8.97068C126.927 9.74182 127.392 10.8576 127.392 12.318C127.392 13.3143 127.151 14.1503 126.669 14.8259C126.194 15.4947 125.53 16.0099 124.675 16.3716L128.002 22.585V22.7181H125.245L122.274 16.9755ZM119.405 14.8975H122.05C122.918 14.8975 123.597 14.6792 124.085 14.2424C124.573 13.7988 124.817 13.1949 124.817 12.4306C124.817 11.6321 124.59 11.0145 124.136 10.5778C123.688 10.141 123.017 9.91584 122.121 9.90219H119.405V14.8975Z"
        fill={color}
      />
      <Path
        d="M138.492 16.0645H132.408V20.6504H139.52V22.7181H129.834V7.81397H139.449V9.90219H132.408V14.0172H138.492V16.0645Z"
        fill={color}
      />
      <Path
        d="M152.92 17.8661C152.771 19.4561 152.187 20.6981 151.17 21.5921C150.152 22.4793 148.799 22.9228 147.11 22.9228C145.93 22.9228 144.889 22.643 143.987 22.0835C143.091 21.517 142.399 20.7152 141.911 19.6779C141.423 18.6406 141.168 17.4361 141.148 16.0645V14.6723C141.148 13.2665 141.395 12.0279 141.891 10.9565C142.386 9.88513 143.095 9.0594 144.017 8.47934C144.946 7.89928 146.018 7.60925 147.232 7.60925C148.867 7.60925 150.183 8.05623 151.18 8.95021C152.177 9.84418 152.757 11.1067 152.92 12.7377H150.356C150.234 11.6663 149.922 10.8951 149.42 10.4242C148.925 9.94655 148.195 9.7077 147.232 9.7077C146.113 9.7077 145.252 10.1206 144.648 10.9463C144.051 11.7652 143.746 12.9697 143.732 14.5597V15.8802C143.732 17.4907 144.017 18.7191 144.587 19.5653C145.163 20.4115 146.005 20.8346 147.11 20.8346C148.121 20.8346 148.881 20.606 149.389 20.1488C149.898 19.6916 150.22 18.9307 150.356 17.8661H152.92Z"
        fill={color}
      />
      <Path
        d="M165.628 9.90219H161.009V22.7181H158.455V9.90219H153.876V7.81397H165.628V9.90219Z"
        fill={color}
      />
      <Path
        d="M179.12 15.655C179.12 17.1154 178.869 18.3984 178.367 19.5039C177.865 20.6026 177.146 21.4488 176.21 22.0425C175.28 22.6294 174.209 22.9228 172.994 22.9228C171.794 22.9228 170.722 22.6294 169.779 22.0425C168.843 21.4488 168.117 20.606 167.602 19.5141C167.093 18.4223 166.835 17.1632 166.829 15.7369V14.8975C166.829 13.444 167.083 12.161 167.592 11.0487C168.107 9.93631 168.83 9.08669 169.759 8.49981C170.695 7.9061 171.767 7.60925 172.974 7.60925C174.182 7.60925 175.25 7.90269 176.179 8.48957C177.115 9.06963 177.838 9.90901 178.346 11.0077C178.855 12.0996 179.113 13.3723 179.12 14.8259V15.655ZM176.545 14.8771C176.545 13.2256 176.233 11.9597 175.609 11.0794C174.992 10.199 174.114 9.75888 172.974 9.75888C171.862 9.75888 170.99 10.199 170.359 11.0794C169.735 11.9529 169.416 13.1915 169.403 14.7952V15.655C169.403 17.2928 169.718 18.5587 170.349 19.4527C170.987 20.3467 171.868 20.7937 172.994 20.7937C174.134 20.7937 175.009 20.3569 175.62 19.4834C176.237 18.6099 176.545 17.3338 176.545 15.655V14.8771Z"
        fill={color}
      />
      <Path
        d="M187.127 16.9755H184.258V22.7181H181.684V7.81397H186.893C188.602 7.81397 189.922 8.19954 190.851 8.97068C191.78 9.74182 192.245 10.8576 192.245 12.318C192.245 13.3143 192.004 14.1503 191.523 14.8259C191.048 15.4947 190.383 16.0099 189.528 16.3716L192.855 22.585V22.7181H190.098L187.127 16.9755ZM184.258 14.8975H186.903C187.771 14.8975 188.45 14.6792 188.938 14.2424C189.427 13.7988 189.671 13.1949 189.671 12.4306C189.671 11.6321 189.444 11.0145 188.989 10.5778C188.541 10.141 187.87 9.91584 186.974 9.90219H184.258V14.8975Z"
        fill={color}
      />
      <Path
        d="M15.413 10.5739C15.5418 10.3878 15.7625 10.2726 15.9923 10.2726C16.2222 10.2726 16.4337 10.3789 16.5716 10.5651C16.59 10.5916 16.6084 10.6182 16.6176 10.6448L24.1115 23.1602H26.9895L17.0865 6.62078C16.8659 6.23079 16.4429 5.99145 15.9923 5.99145C15.551 5.99145 15.1372 6.22192 14.9165 6.59419L14.8797 6.65622L4.99512 23.1602H7.87316L15.3763 10.636C15.3855 10.6183 15.3947 10.5917 15.413 10.5739Z"
        fill={color}
      />
      <Path
        d="M31.4879 22.1337L20.3337 3.50131L19.3589 1.87032C18.6693 0.717981 17.3819 0 16.0026 0C14.6232 0 13.3359 0.717981 12.6462 1.87032L12.2692 2.50853L0.51725 22.1337C-0.172417 23.2861 -0.172417 24.7132 0.51725 25.8655C1.20692 27.0178 2.49429 27.7358 3.87362 27.7358H7.0277H7.20242H13.3542H13.4094C14.1543 27.7624 14.7336 28.3474 14.7336 29.0654V31.964H17.2256V29.0654C17.2256 27.009 15.4876 25.3337 13.3542 25.3337H3.84604C3.36787 25.3248 2.91728 25.0677 2.6782 24.6688C2.42992 24.2611 2.42992 23.747 2.6782 23.3392L14.8255 3.05811C15.0738 2.65922 15.5336 2.41104 16.0118 2.41104C16.4899 2.41104 16.9497 2.65922 17.198 3.05811L29.3453 23.3392C29.5936 23.747 29.5936 24.2611 29.3453 24.6688C29.1062 25.0677 28.6556 25.3248 28.1775 25.3337H23.9843H23.9107C23.4142 25.3337 22.9636 25.0766 22.7153 24.6688L17.1152 15.3172C16.8945 14.9272 16.4715 14.6879 16.021 14.6879C15.5796 14.6879 15.1658 14.9183 14.9451 15.2906L14.9083 15.3527L10.2186 23.1797H13.0968L15.4049 19.3326C15.4141 19.3149 15.4324 19.2883 15.4416 19.2706C15.5704 19.0844 15.7911 18.9692 16.021 18.9692C16.2508 18.9692 16.4623 19.0756 16.6003 19.2617C16.6187 19.2883 16.6371 19.3149 16.6462 19.3415L20.5636 25.8744C21.2532 27.0267 22.5406 27.7447 23.9199 27.7447H24.7659H26.5866H27.1844H27.3315H28.1591C29.5384 27.7447 30.8258 27.0267 31.5155 25.8744C32.1683 24.7132 32.1775 23.2861 31.4879 22.1337Z"
        fill={color}
      />
    </Svg>
  );
};

export default LogoHorizontal;
