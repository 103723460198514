import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FlatList, StyleSheet, View } from "react-native";
import { observer } from "mobx-react-lite";

import { useStores } from "@models/index";
import { Screen } from "@components/index";
import { ErrorsMessage, HeaderAction } from "@components/ui";
import { TopBar, TopBarAlign } from "@components/layout";
import { HistoryHeader } from "@components/common";

import { TPeriod } from "../../../utils/timeConverter";
import { DownloadIcon, Filter } from "../../../svgs";
import { palette, spacing } from "../../../theme";
import { translate } from "../../../i18n";
import { HistoryChart } from "./components";
import { SensorsHistoryHintsData } from "./hints";

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  listContainer: {
    padding: spacing[4],
  },
  footer: {
    height: spacing[6],
  },
});

const CHART_COLORS = [
  palette.Blue,
  palette.RedNew,
  palette.Yellow,
  palette.Green,
  palette.PurpleG1,
];

const PER_PAGE = 5;

export const ClimateHistoryScreen = observer(({ navigation }: any) => {
  const { sensorStore, settingsStore } = useStores();
  const { historySensors } = sensorStore;
  const [period, setPeriod] = useState<TPeriod>({} as TPeriod);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentDate, setCurrentDate] = useState<Date>();
  const [sensors, setSensors] = useState<Array<any> | []>([]);

  const offsetRef = useRef<number>(0);

  const setInitialSensors = useCallback(() => {
    setSensors(historySensors.slice(0, PER_PAGE));
  }, [historySensors]);

  const setNextSensors = () => {
    if (sensors.length >= historySensors.length) return;

    offsetRef.current += 1;
    setSensors(historySensors.slice(0, PER_PAGE * (offsetRef.current + 1)));
  };

  useEffect(() => {
    if (period.startDate && period.endDate) {
      setInitialSensors();
    }
  }, [period.startDate, period.endDate, setInitialSensors]);

  const exportHistory = async () => {
    const sensorsUids = historySensors.map((sensor) => sensor.uid);
    await sensorStore.exportSensorsHistory(sensorsUids, period);
  };

  const isAppliedFilter = Boolean(sensorStore.historyFilter.hiddenUids.length);

  const headerActions = useMemo<HeaderAction[]>(
    () => [
      {
        key: "exportHistory",
        icon: DownloadIcon,
        onPress: exportHistory,
      },
      {
        key: "filter",
        icon: Filter,
        onPress: () => navigation.navigate("history-filter"),
        selected: isAppliedFilter,
      },
    ],
    [period, historySensors],
  );

  const getColor = (colorIndex: number) => {
    const newIndex = colorIndex % CHART_COLORS.length;
    return CHART_COLORS[newIndex];
  };

  return (
    <Screen
      header={
        <TopBar
          title={translate("Sensors.History.title")}
          back
          align={TopBarAlign.Left}
          actions={headerActions}
        />
      }
      hints={settingsStore.showHelpButton && SensorsHistoryHintsData()}
      loading={sensorStore.isLoading}
    >
      <View style={styles.container}>
        <View>
          <HistoryHeader onChange={setPeriod} currentDateTime={currentDate} />
        </View>

        {!historySensors?.length ? (
          <ErrorsMessage
            errors={[translate("Sensors.History.errors.noSensorsHistory")]}
          />
        ) : (
          <FlatList
            // scrollEnabled={false}
            style={styles.listContainer}
            data={sensors}
            keyExtractor={(sensor) => sensor.uid}
            renderItem={({ item, index }) => (
              <HistoryChart
                sensor={item}
                period={period}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                setCurrentDate={setCurrentDate}
                color={getColor(index)}
              />
            )}
            onEndReached={setNextSensors}
            onEndReachedThreshold={0.05}
            ListFooterComponent={<View style={styles.footer} />}
            // ListFooterComponent={() => isFetchLoading && <Preloader />}
          />
        )}
      </View>
    </Screen>
  );
});
