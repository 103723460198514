import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { observer } from "mobx-react-lite";

import { SensorTypeName, useStores } from "@models/index";
import { Sensor } from "@models/sensor/sensor";
import {
  Rule,
  RuleDirection,
  RuleFormData,
  SensorRuleData,
} from "@models/rule/rule";
import { Button, ErrorsAlert } from "@components/ui";
import { DEFAULT_SENSOR_RANGES } from "@models/sensor/constants";

import * as SensorIcons from "../../../../../../svgs/sensors";
import {
  createDataValidator,
  DataValidator,
  requiredNumberValidator,
  requiredStringValidator,
} from "../../../../../../utils/validator";
import { WarningSvg } from "../../../../../../svgs/pictures";
import { translate } from "../../../../../../i18n";
import { SensorSelector } from "../SensorSelector";
import { SensorRuleErrors } from "./types";
import { NoSensors } from "./NoSensors";
import { Reservoir } from "./Reservoir";
import { SensorInput } from "./SensorInput";
import { SensorValues } from "./SensorValues";
import { TimeToSetPointInput } from "./TimeToSetPointInput";

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

const phAndEcDataIsValidator = (
  value?: string | number | null,
): string | undefined => {
  const MAGIC_VALUE = 9999; // TODO: !!!
  const numberValue = Number(value);

  if (numberValue > MAGIC_VALUE) {
    return translate("validations.valueShouldBeLess", { value: MAGIC_VALUE });
  }
  if (!value) {
    return translate("validations.couldNotBeEmpty");
  }
  return undefined;
};

const timeToSetPointValidator = (value) => {
  return value === "" ? translate("validations.mustBeNumber") : "";
};

const dataIsValid: DataValidator<SensorRuleData> = createDataValidator({
  sensorUid: requiredStringValidator,
  valueLow: requiredNumberValidator,
  valueHigh: requiredNumberValidator,
  timeToSetPoint: timeToSetPointValidator,
});

const phAndEcDataIsValid: DataValidator<SensorRuleData> = createDataValidator({
  tankSize: phAndEcDataIsValidator,
  mixTime: phAndEcDataIsValidator,
  dosingTime: phAndEcDataIsValidator,
  cyclesToSetPoint: timeToSetPointValidator,
});

const validateDirection = (formData: SensorRuleData) => {
  const { valueLow, valueHigh, direction } = formData;
  if (valueLow === valueHigh)
    return translate("validations.valuesCannotBeEqual");
  if (direction === RuleDirection.Up && valueLow > valueHigh)
    return translate("validations.1stCantBeMore2nd");
  if (direction === RuleDirection.Down && valueLow < valueHigh)
    return translate("validations.1stCantBeLess2nd");
  return "";
};

type RuleFormProps = {
  rule: Rule;
  onSubmit: (data: SensorRuleData) => void;
  onUnsavedChanges?: (
    isUnsavedChanges: boolean,
    changeSensorRule?: SensorRuleData,
  ) => void;
};

export const FormSensor: React.FC<RuleFormProps> = observer(
  ({ rule, onSubmit, onUnsavedChanges }) => {
    const [formData, setFormData] = useState<SensorRuleData>(rule.sensorData);
    const [errors, setErrors] = useState<SensorRuleErrors>(
      {} as SensorRuleErrors,
    );

    const { sensorStore } = useStores();
    const [sensor, setSensor] = useState<Sensor>(
      sensorStore.getSensor(formData.sensorUid),
    );

    const [warnings, setWarnings] = useState<string[]>(null);
    const resetWarnings = () => setWarnings(null);

    const [isSensorSelector, setSensorSelector] = useState<boolean>(
      !sensor?.uid,
    );
    const openSensorSelector = () => {
      if (!rule.isNew) {
        setWarnings([translate("Devices.Rules.youCantChangeSensor")]);
      } else {
        setSensorSelector(true);
      }
    };
    const closeSensorSelector = () => {
      setSensorSelector(false);
    };

    const SensorIcon = SensorIcons[sensor?.iconName];
    const sensorRange = sensor?.sensorRange || DEFAULT_SENSOR_RANGES;

    const isPhOrEc =
      rule.device?.isDosingPump &&
      (sensor?.type === SensorTypeName.PH ||
        sensor?.type === SensorTypeName.EC);

    // TODO: remove !!!
    useEffect(() => {
      onUnsavedChanges(true, { ...formData, isPhOrEc });
    }, [isPhOrEc]);

    const onSubmitHandler = () => {
      const isValid = dataIsValid(formData, { setErrors });
      const phAndEcIsValid = isPhOrEc
        ? phAndEcDataIsValid(formData, { setErrors })
        : true;
      if (!isValid || !phAndEcIsValid) return;

      const directionError = validateDirection(formData);
      if (directionError) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          direction: directionError,
        }));
        return;
      }

      onSubmit(formData);
    };

    const changeFormData = (data: Partial<RuleFormData>) => {
      setFormData((prevData) => {
        const newData = {
          ...prevData,
          ...data,
        };

        dataIsValid(newData, { setErrors });
        if (isPhOrEc) {
          phAndEcDataIsValid(newData, { setErrors });
        }
        // TODO: use onChange instead of onUnsavedChanges
        onUnsavedChanges(true, { ...newData, isPhOrEc });

        return newData;
      });
    };

    const changeSensorHandler = (newSensorUid: string) => {
      const newSensor = sensorStore.getSensor(newSensorUid);
      if (!newSensorUid) return;

      const newFormData: RuleFormData = { sensorUid: newSensorUid };
      if (sensor?.type !== newSensor?.type) {
        newFormData.valueLow = newSensor?.sensorRange?.minValue;
        newFormData.valueHigh = newSensor?.sensorRange?.maxValue;
        newFormData.timeToSetPoint = null;
      }

      changeFormData(newFormData);
      setSensor(newSensor);
      closeSensorSelector();
    };

    const sensorMinMaxOptions = sensor?.minMaxOptions || [];

    useEffect(() => {
      sensorStore.fetchSensors({});
    }, []);

    if (!sensorStore.sensors.size) {
      return (
        <NoSensors
          title={translate("Devices.Rules.noSensorsConnected")}
          message={translate("Devices.Rules.youNeedSensor")}
        />
      );
    }

    const isDisabledSetPoint =
      isPhOrEc && (!formData.dosingTime || !formData.mixTime);

    return (
      <View style={styles.container}>
        <SensorInput
          displayName={sensor?.displayName}
          icon={SensorIcon}
          error={errors.sensorUid}
          onPress={openSensorSelector}
        />

        <SensorValues
          formData={formData}
          changeFormData={changeFormData}
          sensorMinMaxOptions={sensorMinMaxOptions}
          sensorRange={sensorRange}
          errors={errors}
        />

        {isPhOrEc && (
          <Reservoir
            formData={formData}
            changeFormData={changeFormData}
            errors={errors}
          />
        )}

        <TimeToSetPointInput
          formData={formData}
          isPhOrEc={isPhOrEc}
          defaultValue={
            isPhOrEc ? rule.defaultCyclesToSetPoint : rule.defaultTimeToSetPoint
          }
          changeFormData={changeFormData}
          disabled={isDisabledSetPoint}
          error={errors.timeToSetPoint || errors.cyclesToSetPoint}
        />

        <Button
          type="primary"
          onPress={onSubmitHandler}
          tx="Devices.Rules.setRule"
          disabled={rule.isLoading}
        >
          Set Rule
        </Button>

        {isSensorSelector ? (
          <SensorSelector
            sensors={rule.sensorsForDevice}
            currentUid={formData.sensorUid}
            onChange={changeSensorHandler}
            onClose={closeSensorSelector}
          />
        ) : null}

        <ErrorsAlert
          errors={warnings}
          onCancel={resetWarnings}
          Image={WarningSvg}
          title=""
        />
      </View>
    );
  },
);
