import React, { useCallback } from "react";
import { View, StyleSheet } from "react-native";
import { useNavigation } from "@react-navigation/native";

import { Screen } from "@components/index";
import { TopBar } from "@components/layout";
import { Text, Button } from "@components/ui";

import { spacing } from "../../../theme";
import { translate } from "../../../i18n";

const styles = StyleSheet.create({
  screen: {
    paddingHorizontal: spacing[4],
    justifyContent: "center",
  },
  text: {
    fontSize: 18,
    textAlign: "center",
    marginBottom: spacing[6],
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  button: {
    width: "40%",
  },
});

export const ModuleAddChooseConnection = () => {
  const navigation = useNavigation() as any;

  const addModuleManual = useCallback(() => {
    navigation.navigate("module-add-manual-onboarding");
  }, []);

  return (
    <Screen
      style={styles.screen}
      header={<TopBar title={translate("Modules.Add.addModule")} back />}
    >
      <Text style={styles.text}>
        {translate("Modules.Add.chooseConnection")}
      </Text>

      <View style={styles.buttonContainer}>
        <Button
          onPress={addModuleManual}
          type="primary"
          style={styles.button}
          tx="Modules.Add.manual"
        >
          Manual
        </Button>
        <Button
          onPress={() => navigation.navigate("module-add-smart")}
          // TODO: remove Linking !!!
          type="primary"
          style={styles.button}
          tx="Modules.Add.smart"
          // disabled={Platform.OS === "web"}
        >
          Smart
        </Button>
      </View>
    </Screen>
  );
};
