import Svg, { SvgProps, Path } from "react-native-svg";

export const ConnectionLost = (props: SvgProps) => (
  <Svg width={360} height={250} fill="none" {...props}>
    <Path
      d="M131.391 49v96.71M138.811 54.5v12.1M123.971 54.5v12.1"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M131.391 79.87h4.21c5.17 0 9.37-4.19 9.37-9.37v-4.21h-4.21c-5.17 0-9.37 4.19-9.37 9.37v4.21Z"
      fill="#fff"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M131.391 92.72h4.21c5.17 0 9.37-4.19 9.37-9.37v-4.21h-4.21c-5.17 0-9.37 4.19-9.37 9.37v4.21Z"
      fill="#fff"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M131.391 105.57h4.21c5.17 0 9.37-4.19 9.37-9.37v-4.21h-4.21c-5.17 0-9.37 4.19-9.37 9.37v4.21Z"
      fill="#fff"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M131.391 118.42h4.21c5.17 0 9.37-4.19 9.37-9.37v-4.21h-4.21c-5.17 0-9.37 4.19-9.37 9.37v4.21ZM131.391 79.87h-4.21c-5.17 0-9.37-4.19-9.37-9.37v-4.21h4.21c5.17 0 9.37 4.19 9.37 9.37v4.21Z"
      fill="#fff"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M131.391 92.72h-4.21c-5.17 0-9.37-4.19-9.37-9.37v-4.21h4.21c5.17 0 9.37 4.19 9.37 9.37v4.21Z"
      fill="#fff"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M131.391 105.57h-4.21c-5.17 0-9.37-4.19-9.37-9.37v-4.21h4.21c5.17 0 9.37 4.19 9.37 9.37v4.21Z"
      fill="#fff"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M131.391 118.42h-4.21c-5.17 0-9.37-4.19-9.37-9.37v-4.21h4.21c5.17 0 9.37 4.19 9.37 9.37v4.21Z"
      fill="#fff"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M94.2 69.617v131.96M103.051 76.188v14.44M85.34 76.188v14.44"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M94.2 106.468h5.02c6.169 0 11.179-5.01 11.179-11.18v-5.02h-5.02c-6.17 0-11.18 5.01-11.18 11.18v5.02Z"
      fill="#fff"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M94.2 121.807h5.02c6.169 0 11.179-5.01 11.179-11.18v-5.02h-5.02c-6.17 0-11.18 5.01-11.18 11.18v5.02Z"
      fill="#fff"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M94.2 137.147h5.02c6.169 0 11.179-5.01 11.179-11.18v-5.02h-5.02c-6.17 0-11.18 5.01-11.18 11.18v5.02Z"
      fill="#fff"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M94.2 152.487h5.02c6.169 0 11.179-5.01 11.179-11.18v-5.02h-5.02c-6.17 0-11.18 5.01-11.18 11.18v5.02ZM94.2 106.468h-5.02c-6.17 0-11.18-5.01-11.18-11.18v-5.02h5.02c6.17 0 11.18 5.01 11.18 11.18v5.02Z"
      fill="#fff"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M94.2 121.807h-5.02c-6.17 0-11.18-5.01-11.18-11.18v-5.02h5.02c6.17 0 11.18 5.01 11.18 11.18v5.02Z"
      fill="#fff"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M94.2 137.147h-5.02c-6.17 0-11.18-5.01-11.18-11.18v-5.02h5.02c6.17 0 11.18 5.01 11.18 11.18v5.02Z"
      fill="#fff"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M94.2 152.487h-5.02c-6.17 0-11.18-5.01-11.18-11.18v-5.02h5.02c6.17 0 11.18 5.01 11.18 11.18v5.02Z"
      fill="#fff"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M255.13 236.959H103.51c-17.23 0-27.9-18.78-19.06-33.58l74.39-124.58c9.2-15.41 31.51-15.42 40.73-.03l74.6 124.58c8.87 14.8-1.79 33.61-19.04 33.61Z"
      fill="#FFF1F1"
    />
    <Path
      d="M185.211 208.428a7.99 7.99 0 0 1-5.67 2.34c-2.14 0-4.16-.83-5.67-2.34a7.917 7.917 0 0 1-2.35-5.66c.01-2.13.84-4.14 2.35-5.66a7.999 7.999 0 0 1 5.67-2.35c2.14 0 4.16.84 5.67 2.35a7.917 7.917 0 0 1 2.35 5.66c0 2.14-.84 4.15-2.35 5.66ZM211.401 189.688a9.282 9.282 0 0 1-6.6 2.73c-2.49 0-4.84-.97-6.6-2.73-4.97-4.97-11.6-7.71-18.65-7.71-7.05 0-13.68 2.74-18.65 7.71a9.299 9.299 0 0 1-6.6 2.73c-2.49 0-4.84-.97-6.6-2.73a9.244 9.244 0 0 1-2.74-6.59c0-2.48.97-4.83 2.74-6.58 8.51-8.5 19.82-13.17 31.86-13.17s23.35 4.67 31.86 13.17a9.221 9.221 0 0 1 2.74 6.58c-.02 2.49-1 4.84-2.76 6.59Z"
      fill="#fff"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M230.88 170.258a9.299 9.299 0 0 1-6.6 2.73c-2.49 0-4.84-.97-6.6-2.73-10.18-10.17-23.73-15.76-38.13-15.76-14.41 0-27.95 5.59-38.13 15.76a9.299 9.299 0 0 1-6.6 2.73c-2.49 0-4.84-.97-6.6-2.73a9.244 9.244 0 0 1-2.74-6.59c0-2.48.97-4.83 2.74-6.58 13.71-13.69 31.95-21.23 51.34-21.23 19.39 0 37.62 7.54 51.34 21.22a9.261 9.261 0 0 1 2.74 6.59c-.03 2.49-1 4.83-2.76 6.59Z"
      fill="#fff"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M243.791 153.518c-2.49-.01-4.84-.97-6.6-2.74-15.4-15.36-35.87-23.83-57.65-23.83s-42.26 8.47-57.65 23.83c-1.76 1.77-4.1 2.73-6.6 2.73-2.49 0-4.84-.96-6.6-2.72a9.244 9.244 0 0 1-2.74-6.59c0-2.49.97-4.83 2.74-6.59 18.92-18.88 44.09-29.28 70.86-29.28 26.78 0 51.94 10.4 70.87 29.28a9.276 9.276 0 0 1 2.73 6.59c0 2.49-.97 4.83-2.74 6.59a9.362 9.362 0 0 1-6.62 2.73ZM236.4 223.267l7.58-7.58c3.23-3.23 3.23-8.47 0-11.69-3.23-3.23-8.47-3.23-11.69 0l-7.58 7.58-7.58-7.58c-3.23-3.23-8.47-3.23-11.69 0-3.23 3.23-3.23 8.47 0 11.69l7.58 7.58-7.58 7.58c-3.23 3.23-3.23 8.47 0 11.69 3.23 3.23 8.47 3.23 11.69 0l7.58-7.58 7.58 7.58c3.23 3.23 8.47 3.23 11.69 0 3.23-3.23 3.23-8.47 0-11.69l-7.58-7.58Z"
      fill="#fff"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M228.47 82.977h25.69c2.92 0 4.73 3.18 3.23 5.69l-12.6 21.11c-1.56 2.61-5.34 2.61-6.9 0l-12.64-21.11c-1.51-2.51.3-5.69 3.22-5.69Z"
      fill="#FFF1F1"
    />
  </Svg>
);
