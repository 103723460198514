import * as React from "react";
import Svg, { Path } from "react-native-svg";

import { defaultColors } from "../../theme";

export default function SensorRestoreIcon({
  size = 24,
  color = defaultColors.text,
}) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        d="m7.121 21.554 -0.03 0.128 -0.045 -0.185 0.016 -0.65 0.01 -0.026z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.267 5.058c-0.795 0 -1.439 0.644 -1.439 1.438v0.297h0.157c0.806 0 1.462 0.653 1.462 1.462v1.812c0 0.521 -0.273 0.98 -0.685 1.238v7.709c0 0.367 -0.095 0.727 -0.277 1.046l-0.01 0.016 -0.008 0.016a0.1 0.1 0 0 0 -0.004 0.078l0.132 0.327v0.002c0.134 0.325 0.16 0.683 0.077 1.022l-0.203 0.84v0.001a1.36 1.36 0 0 1 -1.324 1.044h-0.11a1.36 1.36 0 0 1 -1.323 -1.042l-0.204 -0.843v-0.003a1.7 1.7 0 0 1 0.076 -1.018l0.134 -0.33a0.1 0.1 0 0 0 -0.005 -0.078l-0.015 -0.025a2.1 2.1 0 0 1 -0.28 -1.053v-7.695a1.46 1.46 0 0 1 -0.708 -1.253V8.255c0 -0.806 0.654 -1.462 1.463 -1.462h0.155v-0.297a2.938 2.938 0 1 1 5.877 0v1.895c0 0.794 0.643 1.438 1.438 1.438h0.205c0.794 0 1.438 -0.644 1.438 -1.438a2.94 2.94 0 0 1 2.938 -2.938h1.862a0.75 0.75 0 0 1 0 1.5h-1.862c-0.795 0 -1.438 0.643 -1.438 1.438a2.94 2.94 0 0 1 -2.938 2.938h-0.205a2.94 2.94 0 0 1 -2.938 -2.938V6.496c0 -0.794 -0.644 -1.438 -1.438 -1.438m-2.35 6.471h0.345v7.485a0.6 0.6 0 0 1 -0.081 0.305l-0.007 0.012 -0.009 0.016a2 2 0 0 0 -0.075 0.149 2 2 0 0 0 -0.078 -0.154l-0.007 -0.013 -0.01 -0.015a0.6 0.6 0 0 1 -0.078 -0.3zm1.03 -1.501V8.293H6.21v1.735zM7.072 20.73l-0.007 -0.016 0.024 0.009h0.001l0.018 0.008 -0.018 0.044zm0.05 0.823 0.092 -0.386v-0.001a0.16 0.16 0 0 0 -0.007 -0.101l-0.001 -0.004 -0.116 -0.286 -0.018 0.045 -0.007 -0.105 -0.003 0.13 -0.087 0.216a0.2 0.2 0 0 0 -0.008 0.108l0.079 0.326 -0.01 0.41 0.054 -0.224 0.054 0.224z"
        fill={color}
      />
      <Path
        d="M14.105 14.207a4.623 4.623 0 1 1 -2.235 5.413 0.75 0.75 0 1 1 1.435 -0.435 3.122 3.122 0 1 0 0.625 -2.947h0.64a0.75 0.75 0 1 1 0 1.5h-2.15a0.75 0.75 0 0 1 -0.75 -0.75v-2.151a0.75 0.75 0 0 1 1.5 0v0.035q0.422 -0.389 0.935 -0.665"
        fill={color}
      />
    </Svg>
  );
}
