import React, { useMemo } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import { spacing } from "../../theme";
import { useAppTheme } from "../../hooks";
import AttentionIcon from "../../svgs/AttentionIcon";
import { Text } from "./Text";

const createStyles = (theme: any) =>
  StyleSheet.create({
    container: {
      flexDirection: "row",
      alignItems: "center",
      backgroundColor: theme.colors.errorBG,
      borderRadius: spacing[4],
      paddingHorizontal: spacing[4],
      paddingVertical: spacing[2],
    },
    textContainer: {
      flex: 1,
    },
    error: {
      marginVertical: spacing[2],
    },
    icon: {
      marginRight: spacing[4],
    },
  });

interface ErrorsMessageProps {
  errors?: string[];
  onCancel?: any;
  style?: StyleProp<ViewStyle>;
}
/* eslint-disable react/no-array-index-key */
export const ErrorsMessage: React.FunctionComponent<ErrorsMessageProps> = ({
  errors,
  onCancel,
  style,
}) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  if (!errors?.length) return null;

  return (
    <View style={[styles.container, style]}>
      <View style={styles.icon}>
        <AttentionIcon />
      </View>
      <View style={styles.textContainer}>
        {errors?.length > 1 ? (
          errors.map((errorString, index) => (
            <Text key={`error-string-${index}`} style={styles.error}>
              {errorString}
            </Text>
          ))
        ) : (
          <Text style={styles.error}>{errors[0] || "Error"}</Text>
        )}
      </View>
    </View>
  );
};
