import { palette } from "./palette";

/**
 * Roles for colors.  Prefer using these over the palette.  It makes it easier
 * to change things.
 *
 * The only roles we need to place in here are the ones that span through the app.
 *
 * If you have a specific use-case, like a spinner color.  It makes more sense to
 * put that in the <Spinner /> component.
 */

export const defaultColors = {
  /**
   * The palette is available to use, but prefer using the name.
   */
  palette,
  /**
   * A helper for making something see-thru. Use sparingly as many layers of transparency
   * can cause older Android devices to slow down due to the excessive compositing required
   * by their under-powered GPUs.
   */
  transparent: "rgba(0, 0, 0, 0)",
  /**
   * The screen background.
   */
  background: palette.White,
  surface: palette.White,
  /**
   * The main tinting color.
   */
  primary: palette.Green,
  /**
   * The main tinting color, but darker.
   */
  primaryDarker: palette.Green,
  /**
   * The default color of text in many components.
   */
  text: palette.Black,

  subtitle: palette.GrayDark,
  /**
   * Error messages and icons.
   */
  error: palette.Red,
  errorBG: palette.RedLight,
  warning: palette.RedNew,

  errorRedBG1: palette.RedG1,
  errorRedBG2: palette.RedG2,
  errorLight: palette.BlueLightBackground,

  accent: palette.Blue,

  border: palette.GrayBlueLight,
  card: palette.White,
  cardBG: palette.BlueBackground,
  cardBgDark: palette.BlueDark,
  cardStateBG: palette.BlueDarkBackground,

  cardActive: palette.Green,
  cardDisabled: palette.Disable,

  notification: palette.Red,

  badgePurpleG1: palette.PurpleG1,
  badgePurpleG2: palette.PurpleG2,

  /**
   * App
   */
  mainGradient: [palette.Green, palette.GreenLight],
  GradientIconStartBG: palette.TabGradient1,
  GradientIconEndBG: palette.TabGradient2,

  headerBG: palette.White,
  heading: palette.Black,
  subheading: palette.Black,

  inputBorder: palette.GrayBlueLight,
  inputBorderFocus: palette.Black,
  inputBG: palette.BlueLight,

  label: palette.GrayBlue,
  labelFocus: palette.Black,
  value: "#2D3E50",
  subLabel: palette.Gray,
  link: palette.Blue,

  tabBGlight: "#D8E2EC",
  tabActiveBGlight: palette.White,

  tabBG: "rgba(231, 231, 231, 0.3)",
  tabActiveBG: palette.GreenLight2,
  tabActiveBG0: palette.Green0,

  menuBorder: palette.Disable,

  backgroundGradientStart: palette.Green,
  backgroundGradientEnd: palette.GreenLight,

  badgeGStart: palette.BlueG1,
  badgeGEnd: palette.BlueG2,
  badgeText: palette.White,
  badgeOn: palette.GreenLight,

  minMaxBarCenter: "#DADADA",

  loading: palette.White,
  loadingBG: "rgba(85, 93, 103, 0.8)",

  switchTrackFalse: palette.GrayBlueLight,
  switchTrackTrue: palette.GreenDark,

  disabled: palette.GrayBlueLight,

  selectedPeriod1: "rgba(176, 234, 204, 0.6)",
  selectedPeriod2: "rgba(176, 234, 204, 0.3)",

  dialogBG: "rgba(0, 0, 0, 0.5)",
  progressBG: "rgba(63, 154, 110, 0.5)",
  progressActiveBG: "rgba(255, 255, 255, 0.6)",

  // buttons
  // warningBackground: palette.RedNewDark,
  warningDisabledBackground: palette.BlueBackground,
  primaryDisabledText: palette.Gray,

  // line chart
  defaultLine: palette.Blue,
};

export const darkColors = {};
