import React from "react";
import { View, StyleSheet } from "react-native";
import { useNavigation, useTheme } from "@react-navigation/native";
import { StackScreenProps } from "@react-navigation/stack";

import { SettingsNavigatorParamList } from "@screens/Settings/navigation";
import { Screen } from "@components/index";
import { Button, Text } from "@components/ui";
import { LayoutCenter } from "@components/layout";

import { spacing } from "../../theme";
import { RequestSentSuccess as RequestSentSuccessPicture } from "../../svgs/pictures";
import { translate } from "../../i18n";

const styles = StyleSheet.create({
  screen: {
    paddingHorizontal: spacing[4],
  },
  title: {
    fontSize: 24,
    textAlign: "center",
  },
  textContainer: {
    marginVertical: spacing[6],
    alignItems: "center",
  },
  button: {
    marginBottom: spacing[4],
    width: "90%",
  },
});

export const RequestSentSuccess: React.FC<
  StackScreenProps<SettingsNavigatorParamList, "settings-request-sent-success">
> = () => {
  const navigation = useNavigation() as any;
  const { fonts } = useTheme() as any;

  const goToContactSupport = () => {
    navigation.navigate("settings-support");
  };

  const goToSettings = () => {
    navigation.navigate("settings-list");
  };

  return (
    <Screen style={styles.screen}>
      <LayoutCenter>
        <RequestSentSuccessPicture />
        <View style={styles.textContainer}>
          <Text style={[styles.title, fonts.medium]}>
            {translate("Settings.contactSupport.requestSuccessfullySent")}
          </Text>
        </View>

        <Button
          type="primary"
          onPress={goToSettings}
          tx="common.ok"
          style={styles.button}
        >
          Ok
        </Button>
        <Button
          type="ghost"
          onPress={goToContactSupport}
          tx="Settings.contactSupport.haveOtherIssue"
          style={styles.button}
        >
          I have other issue
        </Button>
      </LayoutCenter>
    </Screen>
  );
};
