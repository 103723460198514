import { useEffect } from "react";
import { StyleSheet } from "react-native";

import { useStores } from "@models/index";
import { Screen } from "@components/index";
import { TopBar } from "@components/layout";
import { Heading } from "@components/ui";
import { EnterCodeFromMail } from "@components/common";

import { spacing } from "../../theme";

const styles = StyleSheet.create({
  screen: {
    padding: spacing[4],
    alignItems: "center",
  },
  heading: {
    marginTop: spacing[8],
  },
});

export function RecoveryPasswordScreen({ navigation, route }) {
  const { accountStore } = useStores();
  const { username: email } = route.params || {};

  useEffect(() => {
    if (!email) navigation.navigate("ForgotPassword");
  }, [email, navigation]);

  const onSendNewCode = () => {
    return accountStore.recoveryRequest(email);
  };

  const goToSetNewPassword = (code: string) => {
    const token = [email, code].join("|");
    navigation.navigate("SetNewPassword", {
      recoveryToken: token,
    });
  };

  return (
    <Screen
      testID="RecoveryPassword"
      header={<TopBar back title="" />}
      keyboard
      style={styles.screen}
    >
      <Heading
        style={styles.heading}
        tx="Auth.RecoveryPasswordScreen.heading"
      />
      <EnterCodeFromMail
        email={email}
        onSendNewCode={onSendNewCode}
        onPress={goToSetNewPassword}
      />
    </Screen>
  );
}
