import Svg, { SvgProps, Path } from "react-native-svg";

export const NoAddedModules = (props: SvgProps) => {
  return (
    <Svg width={234} height={179} fill="none" {...props}>
      <Path
        d="M193.548 169.725H41.928c-17.23 0-27.9-18.78-19.06-33.58l74.39-124.58c9.2-15.41 31.51-15.42 40.73-.03l74.6 124.58c8.87 14.8-1.79 33.61-19.04 33.61ZM3.699 63.902h25.23c2.87 0 4.64 3.13 3.17 5.59l-12.38 20.73c-1.53 2.56-5.24 2.57-6.78 0L.528 69.492c-1.47-2.46.3-5.59 3.17-5.59ZM183.249 12.355h30.64c3.48 0 5.64 3.8 3.85 6.79l-15.03 25.17c-1.86 3.11-6.37 3.12-8.23.01l-15.07-25.17c-1.8-3 .36-6.8 3.84-6.8Z"
        fill="#FFF1F1"
      />
      <Path
        d="M74.44 115.764h121.659c7.98 0 12.91-8.7 8.81-15.54l-34.5-57.62c-2.12-3.54-5.61-5.32-9.24-5.33h-86.73"
        fill="#fff"
      />
      <Path
        d="M74.44 115.764h121.659c7.98 0 12.91-8.7 8.81-15.54l-34.5-57.62c-2.12-3.54-5.61-5.32-9.24-5.33h-86.73"
        stroke="#2B2E32"
        strokeWidth={2.196}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M109.5 115.764H39.38c-7.97 0-12.9-8.69-8.82-15.53l34.4-57.62c4.26-7.13 14.57-7.13 18.84-.01l34.5 57.62c4.11 6.84-.82 15.54-8.8 15.54Z"
        fill="#fff"
        stroke="#2B2E32"
        strokeWidth={2.196}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="M73.4 74.484c.23-.33.62-.53 1.03-.53.41 0 .78.19 1.03.52.03.05.07.09.08.14l13.29 22.12h5.11l-17.57-29.23a2.225 2.225 0 0 0-1.94-1.11c-.78 0-1.52.41-1.91 1.07l-.07.11-17.53 29.17h5.11l13.31-22.14c.01-.04.03-.09.06-.12Z"
        fill="#3F9A6E"
      />
      <Path
        d="m101.909 94.914-19.78-32.93-1.73-2.88c-1.22-2.04-3.51-3.31-5.95-3.31-2.44 0-4.73 1.27-5.95 3.31l-.67 1.13-20.84 34.68a6.408 6.408 0 0 0 0 6.6c1.22 2.04 3.51 3.31 5.95 3.31h16.91c1.32.05 2.35 1.08 2.35 2.35v5.12h4.42v-5.12c0-3.63-3.08-6.6-6.87-6.6H52.89c-.85-.02-1.65-.47-2.07-1.17-.44-.72-.44-1.63 0-2.35l21.55-35.84c.44-.7 1.26-1.14 2.1-1.14.85 0 1.66.44 2.1 1.14l21.55 35.84c.44.72.44 1.63 0 2.35-.42.7-1.22 1.16-2.07 1.17h-7.57c-.88 0-1.68-.45-2.12-1.17l-9.93-16.53a2.225 2.225 0 0 0-1.94-1.11c-.78 0-1.52.41-1.91 1.07l-.07.11-8.32 13.83h5.1l4.09-6.8.07-.11c.23-.33.62-.53 1.03-.53.41 0 .78.19 1.03.52.03.05.06.09.08.14l6.95 11.55c1.22 2.04 3.51 3.31 5.95 3.31H96.01c2.45 0 4.73-1.27 5.95-3.31 1.16-2.07 1.17-4.6-.05-6.63Z"
        fill="#3F9A6E"
      />
      <Path
        d="M186.24 69.024h6.33c21.94 0 39.72 17.78 39.72 39.72 0 21.94-17.78 39.72-39.72 39.72h-14.39M49.59 69.024h-6.33c-21.94 0-39.72 17.78-39.72 39.72 0 21.94 17.78 39.72 39.72 39.72h84.549"
        stroke="#2B2E32"
        strokeWidth={2.196}
        strokeMiterlimit={10}
        strokeLinejoin="round"
        strokeDasharray="12 12"
      />
      <Path
        d="M152.239 77.605h18.4c2.9 0 5.25 2.35 5.25 5.25v71.439c0 2.9-2.35 5.25-5.25 5.25h-34.1a5.25 5.25 0 0 1-5.25-5.25v-71.44c0-2.9 2.35-5.25 5.25-5.25h2.58"
        fill="#fff"
      />
      <Path
        d="M152.239 77.605h18.4c2.9 0 5.25 2.35 5.25 5.25v71.439c0 2.9-2.35 5.25-5.25 5.25h-34.1a5.25 5.25 0 0 1-5.25-5.25v-71.44c0-2.9 2.35-5.25 5.25-5.25h2.58M131.289 85.975h44.6M131.289 148.415h44.6M155.239 92.754l-17.1 17.59M143.769 93.174l-4.97 5.2"
        stroke="#2B2E32"
        strokeWidth={2.196}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <Path
        d="m178.82 156.034 7.58-7.58c3.23-3.23 3.23-8.47 0-11.69-3.23-3.23-8.47-3.23-11.69 0l-7.58 7.58-7.58-7.58c-3.23-3.23-8.47-3.23-11.69 0-3.23 3.23-3.23 8.47 0 11.69l7.58 7.58-7.58 7.58c-3.23 3.23-3.23 8.47 0 11.69 3.23 3.23 8.47 3.23 11.69 0l7.58-7.58 7.58 7.58c3.23 3.23 8.47 3.23 11.69 0 3.23-3.23 3.23-8.47 0-11.69l-7.58-7.58Z"
        fill="#F76B6B"
        stroke="#2B2E32"
        strokeWidth={2.196}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default NoAddedModules;
