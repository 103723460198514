import React, { useMemo, useState } from "react";
import { Image, StyleSheet, ScrollView, View } from "react-native";
import { observer } from "mobx-react-lite";
import { RadioButton } from "react-native-paper";

import { MODULE_IMAGES } from "@assets/modules";
import { useStores } from "@models/index";
import { Device } from "@models/device/device";
import { Text, Heading, FloatingButton } from "@components/ui";
import { CustomControl } from "@components/inputs";
import { TopBarNew } from "@components/layout";
import { Screen } from "@components/index";

import { translate } from "../../../i18n";
import { spacing } from "../../../theme";
import { useAppTheme } from "../../../hooks";

const createStyles = (theme: any) =>
  StyleSheet.create({
    screen: {
      padding: spacing[4],
    },
    container: {
      backgroundColor: theme.colors.card,
      paddingTop: spacing[6],
      marginBottom: spacing[8],
    },
    moduleContainer: {
      alignItems: "center",
    },
    picture: {
      width: "94%",
      height: 200,
      marginTop: spacing[2],
      marginBottom: spacing[2],
    },
    moduleType: {
      marginVertical: spacing[2],
      textAlign: "center",
      lineHeight: 20,
      fontSize: 14,
      color: theme.colors.subtitle,
    },
    moduleName: {
      textAlign: "center",
    },
  });

const stylesSocet = (theme: any) =>
  StyleSheet.create({
    container: {
      padding: 0,
      paddingLeft: spacing[4],
      paddingVertical: spacing[3],
      paddingRight: spacing[2],
    },
    label: {
      fontSize: 16,
      ...theme.fonts.medium,
    },
    colorRadio: {
      color: theme.colors.primary,
    },
  });

type SocketControlProps = {
  socket: Device;
  onPress?: (uid: string) => void;
  checked?: boolean;
};
const SocketControl: React.FunctionComponent<SocketControlProps> = ({
  socket,
  onPress,
  checked = false,
}) => {
  const theme = useAppTheme();
  const styles = useMemo(() => stylesSocet(theme), [theme]);

  const onPressHandler = () => onPress(socket.uid);

  return (
    <CustomControl
      style={styles.container}
      onPress={onPressHandler}
      control={
        <RadioButton
          uncheckedColor={styles.colorRadio.color}
          color={styles.colorRadio.color}
          value={socket.uid}
          status={checked ? "checked" : "unchecked"}
          onPress={onPressHandler}
        />
      }
    >
      <Text style={styles.label}>{socket.socketName}</Text>
    </CustomControl>
  );
};

export type ModuleSocketData = {
  moduleUid?: string;
  socketUid?: string;
};

type ModuleSocketSelectorProps = {
  moduleSocketData: ModuleSocketData;
  onChange: (type: ModuleSocketData) => void;
  onClose?: () => void;
  currentValue?: string;
};
export const ModuleSocketSelector: React.FunctionComponent<ModuleSocketSelectorProps> =
  observer(({ onChange, onClose, moduleSocketData }) => {
    const theme = useAppTheme();
    const styles = useMemo(() => createStyles(theme), [theme]);

    const { moduleStore } = useStores();
    const [socketUid, setSocketUid] = useState<string>(
      moduleSocketData.socketUid,
    );

    const setSocketHandler = (value) => {
      setSocketUid(value);
    };

    const onApplyHandler = () => {
      onChange({ ...moduleSocketData, socketUid });
    };

    const module = moduleStore.getModule(moduleSocketData.moduleUid);
    const imageSource = MODULE_IMAGES[module.type] || MODULE_IMAGES.default;

    return (
      <Screen
        testID="ModuleSocketSelector"
        preset="fixed"
        style={styles.screen}
        header={
          <TopBarNew
            title={translate("Devices.Add.selectSocket")}
            backHandler={onClose}
          />
        }
      >
        <ScrollView>
          <View style={styles.moduleContainer}>
            <Image style={styles.picture} source={imageSource} />
            <Text style={styles.moduleType}>{module.typeName}</Text>
            <Heading level={2} style={styles.moduleName} numberOfLines={2}>
              {module.displayName}
            </Heading>
          </View>

          <View style={styles.container}>
            {module.freeSockets.map((socket) => (
              <SocketControl
                key={socket.uid}
                socket={socket}
                checked={socket.uid === socketUid}
                onPress={setSocketHandler}
              />
            ))}
          </View>
        </ScrollView>

        <FloatingButton onPress={onApplyHandler} disabled={!socketUid}>
          {translate("common.apply")}
        </FloatingButton>
      </Screen>
    );
  });
