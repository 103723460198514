import { MenuItem } from "@components/ui";

import { translate } from "../../../i18n";
import { LinkIcon } from "../../../svgs";
import { defaultColors } from "../../../theme";
import { DeleteIcon, EditIcon, HistoryIcon } from "../../../svgs/menu";

export enum MenuDeviceItemsKeys {
  "Edit" = "device-edit",
  "Connection" = "module-details",
  "History" = "device-work-history",
  "Delete" = "device-delete",
}

export const getDeviceDetailsMenuItems = (): MenuItem[] => [
  {
    key: MenuDeviceItemsKeys.Edit,
    title: translate("Devices.Menu.editDeviceName"),
    icon: EditIcon,
  },
  {
    key: MenuDeviceItemsKeys.Connection,
    title: translate("Devices.Menu.connectionPlace"),
    icon: LinkIcon,
  },
  {
    key: MenuDeviceItemsKeys.History,
    title: translate("Devices.Menu.workHistory"),
    icon: HistoryIcon,
  },
  {
    key: MenuDeviceItemsKeys.Delete,
    color: defaultColors.warning,
    title: translate("Devices.Menu.deleteDevice"),
    icon: DeleteIcon,
    topLine: true,
  },
];

export enum MenuDeviceRuleItemsKeys {
  "Edit" = "rule-edit",
  "Delete" = "rule-delete",
}
export const getDeviceRuleMenuItems = (): MenuItem[] => [
  {
    key: MenuDeviceRuleItemsKeys.Edit,
    title: translate("Devices.Rules.edit"),
    icon: EditIcon,
  },
  {
    key: MenuDeviceRuleItemsKeys.Delete,
    title: translate("common.delete"),
    icon: DeleteIcon,
    color: defaultColors.warning,
    topLine: true,
  },
];
