import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

export const CalibrationDOAirErrorSvg = (props: SvgProps) => (
  <Svg width={286} height={172} fill="none" {...props}>
    <Path
      fill="#FBE3E3"
      d="M215.855 170.086H64.235c-17.23 0-27.9-18.82-19.06-33.651l74.39-124.845c9.2-15.443 31.51-15.453 40.73-.03l74.6 124.844c8.87 14.832-1.79 33.682-19.04 33.682Z"
    />
    <Path
      fill="#F76B6B"
      d="m232.086 45 7.513-7.687c3.201-3.276 3.201-8.59 0-11.856a8.058 8.058 0 0 0-11.586 0l-7.513 7.687-7.513-7.687a8.058 8.058 0 0 0-11.586 0c-3.201 3.276-3.201 8.59 0 11.856L208.914 45l-7.513 7.687c-3.201 3.276-3.201 8.59 0 11.856a8.058 8.058 0 0 0 11.586 0l7.513-7.687 7.513 7.687a8.058 8.058 0 0 0 11.586 0c3.201-3.276 3.201-8.59 0-11.856L232.086 45Z"
    />
    <Path
      fill="#6A7B8C"
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.261}
      d="M143.583 82.115h-7.578a4.266 4.266 0 0 1-4.26-4.268V58.211a4.266 4.266 0 0 1 4.26-4.267h7.578a4.265 4.265 0 0 1 4.26 4.267V77.84a4.267 4.267 0 0 1-4.26 4.276ZM144.824 53.943l-3.423-12.073h-3.215l-3.423 12.073 5.031-.155 5.03.155Z"
    />
    <Path
      stroke="#2A2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.261}
      d="M139.794 41.869v-1.597c0-11.342-9.243-20.537-20.644-20.537-11.406 0-20.65 9.195-20.65 20.537v37.404c0 11.342-9.243 20.537-20.644 20.537h-1.932c-11.401 0-20.645-9.195-20.645-20.537"
    />
    <Path
      fill="#6A7B8C"
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.261}
      d="M144.824 82.115h-10.061v66.405h10.061V82.115Z"
    />
    <Path
      fill="#6A7B8C"
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.261}
      d="M146.174 159.587h-12.76c-.917 0-1.669-.74-1.669-1.661v-7.746c0-.913.744-1.66 1.669-1.66h12.76a1.66 1.66 0 0 1 1.669 1.66v7.746a1.67 1.67 0 0 1-1.669 1.661Z"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.261}
      d="M134.763 143.489h10.061"
    />
  </Svg>
);
