import React from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import { CustomControl } from "../inputs/CustomControl";
import { Text } from "./Text";
import { RadioButton } from "./RadioButton";

import { useAppTheme } from "../../hooks";

const createStyles = (theme: any) =>
  StyleSheet.create({
    item: {
      minHeight: 56,
    },
    label: {
      fontSize: 16,
      ...theme.fonts.medium,
    },
    description: {
      color: theme.colors.label,
      fontSize: 12,
    },
    container: {
      flexDirection: "column",
    },
  });

type RadioItemProps = {
  children?: React.ReactNode;
  label: string;
  value?: string;
  description?: React.ReactNode;
  onPress?: (value: string) => void;
  checked?: boolean;
  icon?: React.ReactNode;
  controlIcon?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
  labelStyle?: StyleProp<ViewStyle>;
};

export const RadioItem: React.FunctionComponent<RadioItemProps> = ({
  children,
  label,
  value,
  description,
  onPress,
  checked,
  icon,
  controlIcon,
  style,
  labelStyle,
}) => {
  const theme = useAppTheme();
  const styles = createStyles(theme);
  const onPressHandler = () => onPress?.(value);

  return (
    <CustomControl
      style={[styles.item, style]}
      onPress={onPressHandler}
      icon={icon}
      control={
        controlIcon || (
          <RadioButton
            onPress={onPressHandler}
            value={value}
            status={checked ? "checked" : "unchecked"}
          />
        )
      }
    >
      <View style={styles.container}>
        <Text style={[styles.label, labelStyle]}>{label}</Text>
        {description && <Text style={styles.description}>{description}</Text>}
        {children}
      </View>
    </CustomControl>
  );
};
