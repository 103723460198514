import { StyleSheet } from "react-native";
import Markdown from "react-native-markdown-display";

import React from "react";
import { spacing } from "../../theme";
import { useAppTheme } from "../../hooks";

const createMarkdownStyles = (theme: any) =>
  StyleSheet.create({
    body: {
      ...theme.fonts?.regular,
      color: theme.colors.text,
      paddingTop: spacing[4],
    },
    list_item: {
      textAlign: "left",
      marginTop: spacing[2],
      marginBottom: spacing[2],
    },
    // bullet_list_content: {
    //   marginBottom: spacing[2],
    // },
    paragraph: {
      marginTop: spacing[2],
      marginBottom: spacing[2],
    },
    strong: {
      ...theme.fonts?.bold,
      // fontStyle: "normal",
    },
    em: {
      // ...theme.fonts?.regular,
      fontStyle: "italic",
    },
    heading1: {
      ...theme.fonts?.bold,
      fontSize: 14,
      textDecorationLine: "underline",
    },
    heading2: {
      ...theme.fonts?.bold,
    },
    heading3: {
      ...theme.fonts?.bold,
    },
    link: {
      color: theme.colors.accent,
    },

    s: {
      textDecorationLine: "none",
      backgroundColor: theme.colors.tabActiveBG,
    },
  });

type MarkdownContentProps = {
  children: React.ReactNode;
};

export const MarkdownContent = ({ children }: MarkdownContentProps) => {
  const theme = useAppTheme() as any;
  const markdownStyle = createMarkdownStyles(theme);

  return <Markdown style={markdownStyle}>{children}</Markdown>;
};
