import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { observer } from "mobx-react-lite";

import { useStores } from "@models/index";
import { Rule } from "@models/rule/rule";
import { Text } from "@components/ui";

import { spacing } from "../../../../theme";
import { useAppTheme } from "../../../../hooks";
import { displayDuration, displayTime } from "../../../../utils/display";

const createStyles = (theme: any) =>
  StyleSheet.create({
    container: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: spacing[2],
      marginBottom: spacing[1],
    },
    column: {
      flex: 1,
    },
    label: {
      color: theme.colors.label,
      marginBottom: spacing[4],
    },
    value: {
      fontSize: 16,
      color: theme.colors.value,
      marginBottom: spacing[2],
      ...theme.fonts.medium,
    },
    isRuleOff: {
      color: theme.colors.disabled,
    },
  });

type RulesScheduleProps = {
  rule: Rule;
};

export const RulesSchedule: React.FC<RulesScheduleProps> = observer(
  ({ rule }) => {
    const theme = useAppTheme();
    const styles = useMemo(() => createStyles(theme), [theme]);
    const { settingsStore } = useStores();

    /* eslint-disable react/no-array-index-key */
    return (
      <View style={styles.container}>
        <View style={styles.column}>
          <Text style={styles.label} tx="Devices.Rules.startAt" />
          {rule.sortedSchedule.map((scheduleItem, index) => (
            <Text style={styles.value} key={`schedule-item-start-${index}`}>
              {displayTime(
                scheduleItem.startAt,
                settingsStore.currentTimeSystem,
              )}
            </Text>
          ))}
        </View>
        <View style={styles.column}>
          <Text style={styles.label} tx="Devices.Rules.duration" />
          {rule.sortedSchedule.map((scheduleItem, index) => (
            <Text style={styles.value} key={`schedule-item-duration-${index}`}>
              {displayDuration(scheduleItem.startAt, scheduleItem.endAt)}
            </Text>
          ))}
        </View>
      </View>
    );
    /* eslint-enable react/no-array-index-key */
  },
);
