import { flow, Instance, types } from "mobx-state-tree";
import dayjs from "dayjs";

import { withEnvironment } from "@models/extensions/with-environment";
import { withRootStore } from "@models/extensions/with-root-store";
import {
  MODULE_SSID_PASSWORD,
  MODULE_SSID_PREFIXES,
} from "@models/module/module";

import { EspApi, GrowDirectorProApi } from "../../services/api";
import { translate } from "../../i18n";
import { delay } from "../../utils/delay";
import {
  connectToSsidForAndroid,
  disconnectSsid,
  getWifiList,
  getWifiSsid,
  TResultConnectToSsid,
} from "../../utils/wifi";
import { rnLogger } from "../../services/logger";
import { DateTimeFormats } from "../../utils/timeConverter";
import { getLocation } from "../../services/location";

const WAITING_WIFI_MS = 15 * 1000;

let waitingWiFiTimer;
let waitingModuleTimer;
let switchingToRouterTimer;

export enum ConnectingStatus {
  New = "new",
  Processing = "processing",
  Success = "success",
  Error = "error",
}

export enum ConnectingStep {
  WiFiSetting = "wifiSetting",
  Instruction = "instruction",
  WaitingConnectionToModuleWiFi = "waitingConnectionToModuleWiFi",
  TestModuleConnection = "testModuleConnection",
  ConnectingToWiFi = "connectingToWiFi",
  ConnectingToServer = "connectingToServer",
  SwitchingToRouter = "switchingToRouter",
  WaitingForModule = "waitingForModule",
  // Auto
  SearchingModule = "searchingModule",
  SelectModule = "selectModule",
  ConnectingToModule = "connectingToModule",
}

export type StepType = {
  key: ConnectingStep;
  label: string;
};
export const MODULE_CONNECTING_STEPS: StepType[] = [
  {
    key: ConnectingStep.TestModuleConnection,
    label: translate("Modules.Add.Manual.steps.testModuleConnection"),
  },
  {
    key: ConnectingStep.ConnectingToWiFi,
    label: translate("Modules.Add.Manual.steps.connectingToWiFi"),
  },
  {
    key: ConnectingStep.ConnectingToServer,
    label: translate("Modules.Add.Manual.steps.connectingToServer"),
  },
  {
    key: ConnectingStep.SwitchingToRouter,
    label: translate("Modules.Add.Manual.steps.switchingToRouter"),
  },
  {
    key: ConnectingStep.WaitingForModule,
    label: translate("Modules.Add.Manual.steps.waitingForModule"),
  },
];

enum ProcessInitiator {
  Watcher = "watcher",
  Auto = "auto",
}

export const ModuleConnectionModel = types
  .model("GrowDirectorProStore")
  .props({
    ssid: types.maybe(types.string),
    password: types.maybe(types.string),
    token: types.maybe(types.string),
    uid: types.maybe(types.string),
    mode: types.maybe(types.number),
    isSaveSettingWifi: types.maybe(types.boolean),

    currentStep: types.maybe(
      types.enumeration<ConnectingStep>(
        "ConnectingStep",
        Object.values(ConnectingStep),
      ),
    ),
    status: types.maybe(
      types.enumeration<ConnectingStatus>(
        "ConnectingStatus",
        Object.values(ConnectingStatus),
      ),
    ),
    error: types.maybe(types.string),
    logs: types.optional(types.string, ""),

    modulesList: types.maybe(types.array(types.string)),

    lat: types.maybeNull(types.number),
    lng: types.maybeNull(types.number),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .actions((self) => ({
    addLog(log: string) {
      rnLogger.info(log, {
        action: "module_connection",
      });
      self.logs = self.logs.concat(
        `${dayjs().format(DateTimeFormats.TimeHMS)} - ${log}\n`,
      );
    },
    resetLogs() {
      self.logs = "";
    },
    setError(error: string, status = ConnectingStatus.Error) {
      if (self.status && self.status !== ConnectingStatus.New) {
        self.error = error;
        self.status = status;

        rnLogger.error(self.logs, {
          action: "module_connection",
        });
      }
      return false;
    },
    setStatus(status: ConnectingStatus) {
      self.status = status;
    },
    setCurrentStep(step: ConnectingStep) {
      self.currentStep = step;
    },
    clear: () => {
      if (!self.isSaveSettingWifi) {
        self.ssid = undefined;
        self.password = undefined;
      }
      self.token = undefined;
      self.uid = undefined;
      self.mode = undefined;
      self.currentStep = undefined;
      self.status = undefined;
      self.error = undefined;
      self.logs = "";

      self.modulesList = undefined;

      self.lat = null;
      self.lng = null;
    },
    // afterCreate() {
    //   self.timestamp = new Date();
    //   self.status = ConnectingStatus.Processing;
    //   self.currentStep = UpdateStep.Rebooting;
    // },
  }))

  .actions((self) => {
    const growDirectorProApi = new GrowDirectorProApi(self.environment.api);

    const fetchToken = flow(function* () {
      const result = yield growDirectorProApi.generateToken();

      if (result.kind !== "ok") {
        rnLogger.error("fetch_token_error", {
          errorCode: result.errorCode,
          error: result.errors?.[0],
          problem: result.problem?.kind,
        });
        return false;
      }

      self.token = result.data.token;
      self.addLog(`fetchToken: ${self.token}`);
      return self.token;
    });

    const fetchModule = flow(function* () {
      // TODO: !!! "url":"/GrowDirectorPro/undefined/module"
      const result = yield growDirectorProApi.getModule(self.uid);
      return result.kind === "ok" ? result.data?.[0] || null : undefined;
    });

    const fetchModuleList = flow(function* () {
      const result = yield growDirectorProApi.getList();
      return result.kind === "ok" ? result.data || [] : undefined;
    });

    const addModule = flow(function* () {
      const result = yield growDirectorProApi.addModule(self.token, self.uid);
      return result.kind === "ok" ? result.data || null : undefined;
    });

    const setLatLng = flow(function* () {
      self.addLog("setLatLng: request...");
      const resultLocation = yield getLocation();
      self.addLog(`setLatLng: ${JSON.stringify(resultLocation)}`);
      self.lat = resultLocation.location.lat || self.lat;
      self.lng = resultLocation.location.lng || self.lng;
    });

    return { fetchToken, fetchModule, fetchModuleList, addModule, setLatLng };
  })

  .actions((self) => ({
    prevStep: () => {
      if (
        [
          ConnectingStep.Instruction,
          ConnectingStep.WaitingConnectionToModuleWiFi,
          ConnectingStep.SearchingModule,
          ConnectingStep.SelectModule,
          ConnectingStep.ConnectingToModule,
        ].includes(self.currentStep)
      ) {
        self.currentStep = ConnectingStep.WiFiSetting;
        // self.reset();
        self.addLog(`prevStep !!!!`);
        return;
      }

      if (self.status !== ConnectingStatus.New) {
        self.reset();
        self.currentStep = ConnectingStep.Instruction;
      }
    },
  }))
  .actions((self) => {
    const espApi = new EspApi();

    // Step 5 (Add module to Server & waiting module)
    const addModuleToServer = flow(function* () {
      self.addLog(`addModuleToServer current step: ${self.currentStep}`);
      if (self.currentStep === ConnectingStep.WaitingForModule) {
        return false;
      }
      self.currentStep = ConnectingStep.WaitingForModule;

      const addModuleResult = yield self.addModule();
      self.addLog(
        `addModuleResult: ${
          addModuleResult && JSON.stringify(addModuleResult)
        }`,
      );

      yield delay(2000);
      let moduleResult = yield self.fetchModule();
      self.addLog(
        `moduleResult 1: ${moduleResult && JSON.stringify(moduleResult)}`,
      );

      if (!moduleResult?.uid) {
        yield delay(2000);
        moduleResult = yield self.fetchModule();
        self.addLog(
          `moduleResult 2: ${moduleResult && JSON.stringify(moduleResult)}`,
        );
      }
      if (!moduleResult?.uid) {
        yield delay(2000);
        moduleResult = yield self.fetchModule();
        self.addLog(
          `moduleResult 3: ${moduleResult && JSON.stringify(moduleResult)}`,
        );
      }
      if (!moduleResult?.uid) {
        return self.setError(translate("Modules.Add.Manual.errors.failed"));
      }

      yield delay(2000);
      const module = yield self.rootStore.moduleStore.fetchModule(self.uid);
      if (!module) {
        return self.setError(translate("Modules.Add.Manual.errors.failed"));
      }

      yield module.updateLocation({ lat: self.lat, lng: self.lng });

      self.rootStore.sensorStore.clearCache();
      self.rootStore.sensorStore.fetchSensors({ force: true });
      self.rootStore.deviceStore.fetchDevices({ force: true });

      self.status = ConnectingStatus.Success;

      rnLogger.info(`Success: ${self.logs}`, {
        action: "module_connection",
      });
      return true;
    });

    // Step 4 (connecting to router WiFi)
    // TODO: check wifi by another way (not fetchModule) - !!!
    // TODO: !!! add exit from loop by timeout !!!
    // https://growdirector.atlassian.net/browse/GDV1-1342
    const loopUntilSwitchingToWiFi = () => {
      self.currentStep = ConnectingStep.SwitchingToRouter;

      const startTime = Date.now();
      clearInterval(switchingToRouterTimer);

      switchingToRouterTimer = setInterval(async () => {
        const moduleResult = await self.fetchModule();

        if (Date.now() - startTime > WAITING_WIFI_MS * 4) {
          clearInterval(switchingToRouterTimer);
          self.addLog(`Watcher timeout - ${WAITING_WIFI_MS * 4}`);
          self.addLog(
            `ERROR moduleResult loop: ${
              moduleResult && JSON.stringify(moduleResult)
            }`,
          );

          self.setError(
            translate("Modules.Add.Manual.errors.moduleNotConnectedToServer"),
          );
        }

        if (moduleResult) {
          clearInterval(switchingToRouterTimer);
          self.addLog(
            `moduleResult loop: ${
              moduleResult && JSON.stringify(moduleResult)
            }`,
          );
          await self.addModuleToServer();
        }
      }, 2000);

      self.addLog(
        `loopUntilSwitchingToWiFi - switchingToRouterTimer: ${switchingToRouterTimer}`,
      );

      // if (moduleResult === undefined) {
      //   setTimeout(self.loopUntilSwitchingToWiFi, 2000);
      // } else {
      //   self.addModuleToServer();
      // }
      // return self.setError(
      //   translate("Modules.Add.Manual.errors.notSwitchedToRouter"),
      // );
    };

    // Step 3
    const connectModuleToServer = flow(function* () {
      self.currentStep = ConnectingStep.ConnectingToServer;

      // Step 3.1 (Set uid)
      const uidResult = yield espApi.uid();
      self.uid = uidResult.data?.uid;
      self.addLog(`Module uid: ${self.uid}`);

      // Step 3.2 (Connect module to Server)
      const { email } = self.rootStore.accountStore.currentUser;
      const { accountUid } = self.rootStore.accountStore.currentUser;
      const serverUrl = self.rootStore.settingsStore.DEV_serverUrl;
      self.addLog(`connectToServer token: ${self.token}`);

      const connectResult = yield espApi.connectToServer({
        user: email,
        accountUid,
        token: self.token,
        ssid: self.ssid,
        password: self.password,
        server: serverUrl,
        lat: self.lat,
        lng: self.lng,
      });

      self.addLog(`connectToServer: ${JSON.stringify(connectResult)}`);

      if (!connectResult.ok) {
        return self.setError(
          translate("Modules.Add.Manual.errors.notConnectedToServer"),
        );
      }

      self.loopUntilSwitchingToWiFi();
      return true;
    });

    const execute = flow(function* (
      preserveLogs?: boolean,
      executeParent?: ProcessInitiator,
    ) {
      clearInterval(waitingModuleTimer);
      self.addLog(`execute waitingModuleTimer - ${waitingModuleTimer}`);

      if (!preserveLogs) {
        self.resetLogs();
      }
      self.status = ConnectingStatus.Processing;

      if (!self.token) {
        return self.setError(translate("Modules.Add.Manual.errors.noToken"));
      }

      // Step 1 (Test connection to module)
      self.currentStep = ConnectingStep.TestModuleConnection;
      let testResult = yield espApi.test();
      self.addLog(`Test connection - ${testResult.ok}`);

      if (!testResult.ok) {
        testResult = yield espApi.test();
        self.addLog(
          `Test connection (retying) - ${JSON.stringify(testResult)}`,
        );
      }

      if (!testResult.ok) {
        if (executeParent === ProcessInitiator.Auto) {
          const ssidResult = yield getWifiSsid();
          self.addLog(`Disconnect WifiSsid: ${JSON.stringify(ssidResult)}`);
          // yield disconnectSsid();
          self.status = ConnectingStatus.New;
          self.currentStep = ConnectingStep.Instruction;
          return false;
        }

        return self.setError(
          translate("Modules.Add.Manual.errors.notConnectedToModule"),
        );
      }

      // Step 1.1 (Check Mode)
      const modeResult = yield espApi.mode();
      self.addLog(`Mode result: ${JSON.stringify(modeResult)}`);
      if (!modeResult.ok) {
        yield disconnectSsid();

        return self.setError(
          translate("Modules.Add.Manual.errors.notConnectedToModule"),
        );
      }
      const { mode } = modeResult.data || {};
      self.addLog(`Mode - ${mode}`);
      // if (mode === 162) {  // TODO: ???
      //   const testSuccessResult = yield espApi.setTestSuccess();
      //   self.addLog(`setTestSuccess - ${JSON.stringify(testSuccessResult)}`);
      // }
      // if (mode === 106) {  // TODO: ???
      //   const resetResult = yield espApi.reset();
      //   self.addLog(`resetResult - ${JSON.stringify(resetResult)}`);
      // }
      if (mode !== 42 && mode !== 142) {
        yield disconnectSsid();

        return self.setError(
          translate("Modules.Add.Manual.errors.notInCorrectMode"),
        );
      }

      // Step 2 (Connect Module to WiFi)
      self.currentStep = ConnectingStep.ConnectingToWiFi;
      // Step 2.1 (Send credentials)
      const credentialsResult = yield espApi.connectToWifi(
        self.ssid,
        self.password,
      );
      self.addLog(
        `connectToWifi: ${JSON.stringify({
          ssid: self.ssid,
          password: self.password,
          result: credentialsResult,
        })}`,
      );
      // Step 2.2 (Waiting Module)
      const startTime = Date.now();
      waitingWiFiTimer = setInterval(async () => {
        // TODO: sometimes bug with this condition - #GDV1-710
        if (Date.now() - startTime > WAITING_WIFI_MS) {
          clearInterval(waitingWiFiTimer);
          await disconnectSsid();

          return self.setError(
            translate("Modules.Add.Manual.errors.notConnectedToWiFi"),
          );
        }

        const wifiStatusResult = await espApi.wifiStatus();
        const { connected } = wifiStatusResult.data || {};
        self.addLog(
          `wifiStatusResult: ${JSON.stringify(wifiStatusResult.data)}`,
        );
        if (connected) {
          clearInterval(waitingWiFiTimer);
          await self.connectModuleToServer();
        }
        return true;
      }, 2000);

      return true;
    });

    const saveWiFiCredentials = (props: {
      ssid: string;
      password: string;
      isSave: boolean;
      modulePrefix?: string;
    }) => {
      self.ssid = props.ssid;
      self.password = props.password;
      self.isSaveSettingWifi = props.isSave;
      self.currentStep = ConnectingStep.Instruction;
    };

    const watchConnectionAndExecute = () => {
      const startTime = Date.now();
      self.currentStep = ConnectingStep.WaitingConnectionToModuleWiFi;
      clearInterval(waitingModuleTimer);

      waitingModuleTimer = setInterval(async () => {
        if (Date.now() - startTime > WAITING_WIFI_MS * 4) {
          clearInterval(waitingModuleTimer);
          self.addLog(`Watcher timeout - ${WAITING_WIFI_MS * 4}`);
          self.setCurrentStep(ConnectingStep.Instruction);
        }

        const testResult = await espApi.test();
        self.addLog(`Watcher test connection - ${testResult.ok}`);
        self.addLog(`Watcher current step: ${self.currentStep}`);

        if (testResult.ok) {
          clearInterval(waitingModuleTimer);
          if (
            self.currentStep === ConnectingStep.WaitingConnectionToModuleWiFi
          ) {
            self.execute(true, ProcessInitiator.Watcher);
          }
        }
      }, 2000);

      return true;
    };

    const reset = flow(function* () {
      rnLogger.info(`Reset: ${self.logs}`, {
        action: "module_connection",
      });
      // disconnectSsid();
      clearInterval(waitingWiFiTimer);
      clearInterval(waitingModuleTimer);
      clearInterval(switchingToRouterTimer);
      self.clear();

      const resultToken = yield self.fetchToken();
      if (!resultToken) {
        self.status = ConnectingStatus.Error;
        self.setError(
          translate("Modules.Add.Manual.errors.applicationCannotReceiveData"),
        );
      }

      yield self.setLatLng();

      self.currentStep = ConnectingStep.WiFiSetting;
      self.status = ConnectingStatus.New;
    });

    const searchModulesForConnecting = flow(function* () {
      self.currentStep = ConnectingStep.SearchingModule;

      const moduleListResult = yield getWifiList(MODULE_SSID_PREFIXES);
      self.addLog(
        `searchModulesForConnecting - ${JSON.stringify(moduleListResult)}`,
      );

      // TODO: Can scan four times in a 2-minute period - https://developer.android.com/develop/connectivity/wifi/wifi-scan#:~:text=30%2Dminute%20period.-,Android%209%3A,in%20a%2030%2Dminute%20period.
      // if (!moduleListResult.list?.length) {
      //   moduleListResult = yield getWifiList(MODULE_SSID_PREFIXES);
      //   self.addLog(
      //     `searchModulesForConnecting 2 - ${JSON.stringify(moduleListResult)}`,
      //   );
      // }

      if (moduleListResult.list?.length) {
        // if (moduleListResult.list.length === 1) {
        //   startAutoConnection(moduleListResult.list[0]);
        //   return moduleListResult.list;
        // }

        self.modulesList = moduleListResult.list;
        self.currentStep = ConnectingStep.SelectModule;
      } else {
        self.currentStep = ConnectingStep.Instruction;
      }

      return moduleListResult.list;
    });

    const startAutoConnection = flow(function* (ssid) {
      self.currentStep = ConnectingStep.ConnectingToModule;

      // TODO: Platform.OS === "android" !!!
      const connectionResult: TResultConnectToSsid =
        yield connectToSsidForAndroid({
          ssid,
          password: MODULE_SSID_PASSWORD,
        });
      self.addLog(`connectToModuleWiFi: ${JSON.stringify(connectionResult)}`);

      if (connectionResult.error) {
        yield disconnectSsid();
        self.currentStep = ConnectingStep.Instruction;
        return;
      }

      setTimeout(async () => {
        const ssidResult = await getWifiSsid();
        self.addLog(
          `connectToModuleWiFi (getWifiSsid): ${JSON.stringify(ssidResult)}`,
        );

        if (ssidResult.ssid === ssid) {
          self.execute(true, ProcessInitiator.Auto);
        } else {
          self.setCurrentStep(ConnectingStep.Instruction);
        }
      }, 800);
    });

    return {
      reset,
      saveWiFiCredentials,
      execute,
      watchConnectionAndExecute,

      connectModuleToServer,
      loopUntilSwitchingToWiFi,
      addModuleToServer,

      searchModulesForConnecting,
      startAutoConnection,
    };
  })
  .views((self) => ({
    get executeIsAvailable() {
      return self.currentStep === ConnectingStep.Instruction;
    },
  }));

type ModuleConnectionType = Instance<typeof ModuleConnectionModel>;
export type ModuleConnection = ModuleConnectionType;
