import React, { useMemo } from "react";
import {
  View,
  StyleSheet,
  StyleProp,
  ViewStyle,
  TextStyle,
  Platform,
} from "react-native";
import { Picker } from "@react-native-picker/picker";

import { spacing } from "../../theme";
import { useAppTheme } from "../../hooks";
import { translate, TxKeyPath } from "../../i18n";
import { Text } from "../ui/Text";
import { Label } from "./Label";
import { ScrollPicker } from "./ScrollPicker";

const createStyles = (theme: any) =>
  StyleSheet.create({
    container: {
      width: "100%",
      marginVertical: spacing[2],
    },
    input: {
      minHeight: 48,
      fontSize: 18,
      paddingVertical: 0,
      textAlign: "center",
      lineHeight: 48,
      marginTop: 8,
      borderRadius: spacing[4],
      borderColor: theme.colors.inputBorder,
    },
    labelRow: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    hintLabel: {
      fontSize: 12,
      color: theme.colors.label,
    },
    error: {
      fontSize: 12,
      color: theme.colors.error,
      paddingTop: spacing[1],
    },
    description: {
      fontSize: 12,
      marginTop: spacing[1],
      paddingHorizontal: spacing[1],
      color: theme.colors.label,
    },
  });

export type TItemPickerInput = {
  label?: string;
  value: ItemValue;
};

type ItemValue = number | string;

type Props<T = ItemValue> = {
  selectedValue?: T;
  onChange?: (itemValue: T, itemIndex: number) => void;
  items: TItemPickerInput[];

  hasError?: boolean;
  enabled?: boolean;
  label?: string;
  errorText?: string;
  txLabel?: TxKeyPath;
  txHintLabel?: TxKeyPath;
  hintLabel?: string;
  description?: string;
  txDescription?: TxKeyPath;
  style?: StyleProp<ViewStyle>;
  labelStyle?: StyleProp<TextStyle>;
  containerStyle?: StyleProp<TextStyle>;
};

export const PickerInput = ({
  onChange,
  selectedValue,
  items,
  hasError,
  errorText,
  txLabel,
  label,
  txHintLabel,
  hintLabel,
  description,
  txDescription,
  style,
  labelStyle,
  containerStyle,
  enabled = true,
  ...props
}: Props) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  const i18nLabel = txLabel && translate(txLabel);
  const labelContent = i18nLabel || label;
  const i18nHintLabel = txHintLabel && translate(txHintLabel);
  const hintLabelContent = i18nHintLabel || hintLabel;
  const i18nDescription = txDescription && translate(txDescription);
  const descriptionContent = i18nDescription || description;
  const isFocus = false;

  const selectedIsEmpty =
    selectedValue === undefined ||
    selectedValue === null ||
    selectedValue === "";

  /* eslint-disable react/no-array-index-key */
  return (
    <View style={[styles.container, containerStyle]}>
      <View style={styles.labelRow}>
        {labelContent ? (
          <Label
            style={labelStyle}
            isFocus={isFocus}
            isError={!!errorText || hasError}
          >
            {labelContent}
          </Label>
        ) : null}
        {hintLabelContent ? (
          <Text style={styles.hintLabel}>{hintLabelContent}</Text>
        ) : null}
      </View>

      {Platform.OS === "android" ? (
        <ScrollPicker
          dataSource={items}
          selectedValue={selectedIsEmpty ? 0 : selectedValue}
          onValueChange={onChange}
          wrapperHeight={160}
          itemHeight={40}
          enabled={enabled}
        />
      ) : (
        <Picker
          selectedValue={selectedIsEmpty ? "" : selectedValue}
          onValueChange={onChange}
          style={[styles.input, style]}
          {...props}
          enabled={enabled}
        >
          {selectedIsEmpty ? (
            <Picker.Item key="_blank" label="" value="" enabled={false} />
          ) : null}

          {items.map((item, index) => (
            <Picker.Item
              key={`hours-${item.value}-${index}`}
              value={item.value}
              label={item.label ?? item.value.toString()}
            />
          ))}
        </Picker>
      )}

      {descriptionContent ? (
        <Text style={styles.description}>{descriptionContent}</Text>
      ) : null}

      {errorText ? <Text style={styles.error}>{errorText}</Text> : null}
    </View>
  );
  /* eslint-enable react/no-array-index-key */
};
