import React, { useCallback, useMemo } from "react";
import { View, StyleSheet } from "react-native";
import { observer } from "mobx-react-lite";
import { StackScreenProps } from "@react-navigation/stack";

import { useStores } from "@models/index";
import { Screen } from "@components/index";
import {
  HeadingWithActions,
  useDialog,
  gridStyles,
  Text,
  ErrorsMessage,
} from "@components/ui";

import { AddIcon, HistoryIcon } from "../../../svgs/menu";
import { spacing } from "../../../theme";
import { useSensors } from "../../../hooks";
import { Filter } from "../../../svgs";
import { translate } from "../../../i18n";
import { SensorsNavigatorParamList } from "../navigation";
import {
  NewSensorDialog,
  SensorOfflinePopup,
  useOfflinePopup,
} from "../components";
import SensorsNoData from "./components/NoData";
import { SensorCard } from "./components/SensorCard";
import { SensorsDetailsHintsData, SensorListOneTimeHints } from "./hints";

const styles = StyleSheet.create({
  message: {
    marginHorizontal: spacing[4],
    marginBottom: spacing[4],
  },
  list: {
    paddingHorizontal: spacing[4],
    marginTop: -spacing[1],
  },
});

export const SensorsListScreen: React.FC<
  StackScreenProps<SensorsNavigatorParamList, "sensors-list">
> = observer(({ navigation }) => {
  const { isLoading, isLoaded, allSensors, sensors } = useSensors();
  const { sensorStore, moduleStore, settingsStore } = useStores();

  const { visibleOfflinePopup, showOfflinePopup, hideOfflinePopup } =
    useOfflinePopup();

  const historyHandler = () => navigation.navigate("climate-history");

  const { showDialog: showAddSensorDialog, ...dialogProps } = useDialog();

  const isAppliedFilter = Boolean(sensorStore.viewFilter.hiddenUids.length);

  const actions = useMemo(
    () => [
      {
        key: "sensors-filter",
        icon: Filter,
        onPress: () => navigation.navigate("sensors-filter"),
        selected: isAppliedFilter,
      },
      {
        key: "sensors-add",
        icon: AddIcon,
        onPress: showAddSensorDialog,
      },
      {
        key: "sensors-history",
        icon: HistoryIcon,
        onPress: historyHandler,
      },
    ],
    [isAppliedFilter],
  );

  const onRefresh = useCallback(async () => {
    await moduleStore.fetchModules({ force: true });
    await sensorStore.fetchSensors({ force: true });
    await sensorStore.getSensorsData({ force: true });
  }, []);

  if (isLoaded && !allSensors?.length) {
    return (
      <Screen testID="SensorsList">
        <SensorsNoData />
      </Screen>
    );
  }

  return (
    <Screen
      testID="SensorsList"
      preset="scroll"
      onRefresh={onRefresh}
      header={<HeadingWithActions tx="Sensors.List.title" actions={actions} />}
      refreshing={isLoading}
      hints={settingsStore.showHelpButton && SensorsDetailsHintsData()}
    >
      {isAppliedFilter ? (
        <Text style={styles.message}>
          {translate("Sensors.List.count", {
            count: sensorStore.viewFilter.visibleUids.length,
            total: sensorStore.viewFilter.total,
          })}
        </Text>
      ) : null}

      <ErrorsMessage
        errors={sensorStore.statusErrors}
        onCancel={sensorStore.resetErrors}
        style={styles.message}
      />

      <View style={[gridStyles.container, styles.list]}>
        {sensors.map((sensor) => (
          <SensorCard
            key={sensor.uid}
            style={gridStyles.item}
            sensor={sensor}
            showOfflinePopup={showOfflinePopup}
          />
        ))}
      </View>

      <NewSensorDialog {...dialogProps} />

      <SensorOfflinePopup
        visible={visibleOfflinePopup}
        onClose={hideOfflinePopup}
      />

      <SensorListOneTimeHints />
    </Screen>
  );
});
