import { stringToNumber } from "../numbers";

export const celsiusToFahrenheit = (celsius?: number): number | undefined => {
  if (celsius) {
    const fahrenheit = celsius * 1.8 + 32;
    return stringToNumber(fahrenheit.toFixed(2));
  }

  return undefined;
};

export const fahrenheitToCelsius = (
  fahrenheit?: number,
): number | undefined => {
  if (fahrenheit) {
    const celsius = (fahrenheit - 32) * 0.5556;
    return stringToNumber(celsius.toFixed(2));
  }

  return undefined;
};
