import React, { ErrorInfo } from "react";
import { TextStyle, View, ViewStyle, ScrollView } from "react-native";

import { Button, Text } from "@components/ui";
import { defaultColors } from "../../theme";

const CONTAINER: ViewStyle = {
  alignItems: "center",
  flex: 1,
  padding: 16,
  paddingVertical: 50,
  backgroundColor: defaultColors.background,
};

const ERROR_DETAILS_CONTAINER: ViewStyle = {
  width: "100%",
  maxHeight: "60%",
  backgroundColor: defaultColors.border,
  marginVertical: 15,
  paddingHorizontal: 10,
  paddingBottom: 15,
  borderRadius: 6,
};

const BTN_RESET: ViewStyle = {
  paddingHorizontal: 40,
};

const TITLE_ERROR: TextStyle = {
  color: defaultColors.error,
  fontWeight: "bold",
  paddingVertical: 15,
};

const CONTENT_ERROR: TextStyle = {
  color: defaultColors.error,
  fontWeight: "bold",
  paddingVertical: 15,
};

// Uncomment this and the Text component in the ErrorComponent if
// you want to see a backtrace in your error reporting screen.
// const CONTENT_BACKTRACE: TextStyle = {
//   color: color.dim,
// }

export interface ErrorComponentProps {
  error: Error;
  errorInfo: ErrorInfo;
  onReset(): void;
}

/**
 * Describe your component here
 */
export function ErrorComponent(props: ErrorComponentProps) {
  const { error, errorInfo, onReset } = props;

  return (
    <View style={CONTAINER}>
      {/* <CrossIcon color={defaultColors.error} /> */}
      <Text style={TITLE_ERROR} tx="errorScreen.somethingWrong" />
      <View style={ERROR_DETAILS_CONTAINER}>
        <ScrollView>
          <Text selectable style={CONTENT_ERROR}>{`${error}`}</Text>
          <Text selectable>{`${errorInfo?.componentStack}`}</Text>
        </ScrollView>
      </View>
      <Button
        type="primary"
        style={BTN_RESET}
        onPress={onReset}
        tx="errorScreen.reset"
      >
        Reset
      </Button>
    </View>
  );
}
