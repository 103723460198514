import { useMemo } from "react";
import { View, StyleSheet } from "react-native";

import { LayoutCenter } from "@components/layout";
import { Text } from "@components/ui";

import { spacing } from "../../../../theme";
import { useAppTheme } from "../../../../hooks";
import { NoMessageIcon } from "../../../../svgs";

const createStyles = (theme: any) =>
  StyleSheet.create({
    noMessage: {
      marginTop: spacing[5],
      marginBottom: spacing[3],
    },
    text: {
      fontSize: 14,
      lineHeight: 20,
      color: theme.colors.subLabel,
    },
  });

export const NoMessages = () => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <LayoutCenter>
      <View style={styles.noMessage}>
        <NoMessageIcon />
      </View>
      <Text style={styles.text} tx="Settings.noMessages" />
    </LayoutCenter>
  );
};
