import { StyleSheet, View } from "react-native";
import { shadow } from "react-native-paper";

import { Button } from "@components/ui";

import { IconInfo } from "../../components";
import { defaultColors, spacing } from "../../../../../theme";
import { translate } from "../../../../../i18n";

const styles = StyleSheet.create({
  position: {
    position: "absolute",
    top: 280,
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
  },

  container: {
    backgroundColor: defaultColors.background,
    paddingHorizontal: spacing[2],
    borderRadius: spacing[2],
    ...(shadow(5) as any),
  },
  buttonInfo: {
    paddingHorizontal: spacing[4],
    backgroundColor: defaultColors.inputBG,
  },
});

export const ButtonModuleInfoHint = () => {
  return (
    <View style={styles.position}>
      <View style={styles.container}>
        <Button
          type="link"
          icon={IconInfo}
          iconPosition="right"
          style={styles.buttonInfo}
          onPress={() => null}
        >
          {translate("Modules.Details.modulInfo")}
        </Button>
      </View>
    </View>
  );
};
