import * as React from "react";
import Svg, { Path } from "react-native-svg";

import { defaultColors } from "../../theme";

export default function DeviceSwitchedIcon({
  size = 24,
  color = defaultColors.text,
}) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M12.821 3.99512C12.821 3.5809 12.4852 3.24512 12.071 3.24512C11.6568 3.24512 11.321 3.5809 11.321 3.99512V7.24164H6.76141V3.99512C6.76141 3.5809 6.42563 3.24512 6.01141 3.24512C5.5972 3.24512 5.26141 3.5809 5.26141 3.99512V7.24164H3.99445C3.58023 7.24164 3.24445 7.57742 3.24445 7.99164V13.9514C3.24445 16.0225 4.92338 17.7014 6.99444 17.7014H8.22455V18.504C8.22455 20.2994 9.68 21.7549 11.4754 21.7549C11.8896 21.7549 12.2254 21.4191 12.2254 21.0049C12.2254 20.5907 11.8896 20.2549 11.4754 20.2549C10.5084 20.2549 9.72455 19.471 9.72455 18.504V17.7014H10.6005C11.0147 17.7014 11.3505 17.3656 11.3505 16.9514C11.3505 16.5372 11.0147 16.2014 10.6005 16.2014H6.99444C5.7518 16.2014 4.74445 15.194 4.74445 13.9514V8.74164H13.2444V10.2442C13.2444 10.6584 13.5802 10.9942 13.9944 10.9942C14.4087 10.9942 14.7444 10.6584 14.7444 10.2442V7.99164C14.7444 7.57742 14.4087 7.24164 13.9944 7.24164H12.821V3.99512Z"
        fill={color}
      />
      <Path
        d="M14.705 12.9375C14.9722 13.254 14.9323 13.7272 14.6158 13.9944C14.1741 14.3673 13.8578 14.867 13.7095 15.4256C13.5612 15.9843 13.5881 16.5751 13.7866 17.1179C13.9852 17.6608 14.3457 18.1295 14.8195 18.4607C15.2932 18.7919 15.8573 18.9695 16.4353 18.9695C17.0133 18.9695 17.5774 18.7919 18.0511 18.4607C18.5249 18.1295 18.8854 17.6608 19.084 17.1179C19.2825 16.5751 19.3095 15.9843 19.1611 15.4256C19.0128 14.867 18.6965 14.3673 18.2548 13.9944C17.9383 13.7272 17.8984 13.254 18.1657 12.9375C18.4329 12.621 18.9061 12.5811 19.2226 12.8484C19.8991 13.4196 20.3838 14.185 20.6109 15.0408C20.8381 15.8966 20.7968 16.8015 20.4927 17.6331C20.1886 18.4647 19.6363 19.1828 18.9106 19.6901C18.1848 20.1974 17.3208 20.4695 16.4353 20.4695C15.5498 20.4695 14.6858 20.1974 13.9601 19.6901C13.2343 19.1828 12.682 18.4647 12.3779 17.6331C12.0738 16.8015 12.0325 15.8966 12.2597 15.0408C12.4869 14.185 12.9715 13.4196 13.648 12.8484C13.9645 12.5811 14.4377 12.621 14.705 12.9375Z"
        fill={color}
      />
      <Path
        d="M17.1852 12.5C17.1852 12.0858 16.8495 11.75 16.4352 11.75C16.021 11.75 15.6852 12.0858 15.6852 12.5V16.1854C15.6852 16.5996 16.021 16.9354 16.4352 16.9354C16.8495 16.9354 17.1852 16.5996 17.1852 16.1854V12.5Z"
        fill={color}
      />
    </Svg>
  );
}
