import * as React from "react";
import Svg, {
  SvgProps,
  G,
  Path,
  Circle,
  Defs,
  ClipPath,
} from "react-native-svg";

export const CalibrationCleanECSvg = (props: SvgProps) => (
  <Svg width={287} height={173} fill="none" {...props}>
    <G clipPath="url(#a)">
      <Path
        fill="#EDF1F5"
        d="m203.819 139.245 30.217 7.379c2.541.621 4.737-1.024 5.345-3.568l-.058-.014c.608-2.544-.546-5.006-3.086-5.627l-76.537-18.69 10.183-22.006a14.516 14.516 0 0 0 1.005-9.19l-1.555-7.142a14.506 14.506 0 0 1 .77-8.645l9.382-22.673 73.575 17.968a4.761 4.761 0 0 1 3.499 5.727 4.705 4.705 0 0 1-5.699 3.481l-30.217-7.379-1.552 6.492a17.263 17.263 0 0 1-7.788 10.724l-4.048 2.473a17.261 17.261 0 0 0-7.789 10.725 17.262 17.262 0 0 1-7.788 10.724l-2.515 1.537a17.28 17.28 0 0 0-7.692 10.341l-2.942 11.178 55.558 13.568a4.347 4.347 0 0 0 5.256-3.196l17.218-70.676"
      />
      <Path
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="m203.819 139.245 30.217 7.379c2.541.621 4.737-1.024 5.345-3.568l-.058-.014c.608-2.544-.546-5.006-3.086-5.627l-76.537-18.69 10.183-22.006a14.516 14.516 0 0 0 1.005-9.19l-1.555-7.142a14.506 14.506 0 0 1 .77-8.645l9.382-22.673 73.575 17.968a4.761 4.761 0 0 1 3.499 5.727 4.705 4.705 0 0 1-5.699 3.481l-30.217-7.379-1.552 6.492a17.263 17.263 0 0 1-7.788 10.724l-4.048 2.473a17.261 17.261 0 0 0-7.789 10.725 17.262 17.262 0 0 1-7.788 10.724l-2.515 1.537a17.28 17.28 0 0 0-7.692 10.341l-2.942 11.178 55.558 13.568a4.347 4.347 0 0 0 5.256-3.196l17.218-70.676"
      />
      <Path
        fill="#B6C5D3"
        d="M71.475 142.951c15.677 0 28.385-3.858 28.385-8.617s-12.709-8.616-28.385-8.616c-15.677 0-28.385 3.857-28.385 8.616 0 4.759 12.708 8.617 28.385 8.617Z"
      />
      <Path
        fill="#6A7B8C"
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="M224.705 51.32h-5.202a2.928 2.928 0 0 1-2.924-2.93V34.912c0-1.617 1.31-2.929 2.924-2.929h5.202a2.928 2.928 0 0 1 2.924 2.93v13.473a2.929 2.929 0 0 1-2.924 2.935ZM225.557 31.982l-2.35-8.288h-2.206l-2.35 8.288 3.453-.107 3.453.107Z"
      />
      <Path
        stroke="#2A2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="M222.104 23.693v-1.096c0-7.785-6.345-14.097-14.171-14.097-7.829 0-14.174 6.312-14.174 14.097v25.676c0 7.786-6.345 14.097-14.171 14.097h-1.327c-7.826 0-14.171-6.311-14.171-14.097"
      />
      <Path
        fill="#6A7B8C"
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="M225.557 51.32h-6.906v45.583h6.906V51.32Z"
      />
      <Path
        fill="#6A7B8C"
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="M226.484 104.5h-8.759c-.63 0-1.146-.508-1.146-1.14v-5.317c0-.627.51-1.14 1.146-1.14h8.759a1.14 1.14 0 0 1 1.145 1.14v5.317c0 .627-.515 1.14-1.145 1.14Z"
      />
      <Path
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="M218.651 93.45h6.906"
      />
      <Path
        stroke="#2A2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="M75.32 16.77v-.985c0-6.988-5.696-12.654-12.72-12.654-7.029 0-12.724 5.666-12.724 12.654v23.049c0 6.988-5.696 12.654-12.721 12.654h-1.191c-7.026 0-12.721-5.666-12.721-12.654"
      />
      <Path
        fill="#6A7B8C"
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="M78.84 35.465 76.443 17.25h-2.248L71.8 35.465l3.52-.246 3.518.246ZM81.884 35.465h-13.07v13.07h13.07v-13.07ZM81.884 103.816h-13.07v2.629h13.07v-2.629Z"
      />
      <Path
        stroke="#2A2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="M79.284 128.643H71.41a2.592 2.592 0 0 1-2.594-2.594v-12.355h13.069v12.355a2.6 2.6 0 0 1-2.6 2.594Z"
      />
      <Path
        fill="#6A7B8C"
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="M79.08 113.694h-7.46v11.869h7.46v-11.869ZM83.406 48.529h-16.12v55.281h16.12V48.529Z"
      />
      <Path
        stroke="#2A2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="M83.406 106.439h-16.12v7.255h16.12v-7.255ZM68.903 39.691h13.164M68.903 43.994h13.164"
      />
      <Path
        fill="#70B8FF"
        fillOpacity={0.4}
        d="M75.023 99.36c13.717 0 24.837-3.404 24.837-7.603 0-4.2-11.12-7.603-24.837-7.603s-24.837 3.404-24.837 7.603 11.12 7.603 24.837 7.603Z"
      />
      <Path
        fill="#308AE3"
        fillRule="evenodd"
        d="M99.86 91.757c0 4.199-11.12 7.603-24.837 7.603-13.71 0-24.825-3.4-24.837-7.596V132.126c0 4.294 11.124 7.784 24.837 7.784s24.837-3.49 24.837-7.784v-41.89l-.49.01c.321.489.49.994.49 1.51Zm-49.674-.007c0-.16.017-.32.05-.478h-.05v.478Z"
        clipRule="evenodd"
        opacity={0.6}
      />
      <Path
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.196}
        d="M99.86 69.961v62.17c0 4.292-11.149 7.779-24.892 7.779-13.742 0-24.891-3.487-24.891-7.779V81.228a6.762 6.762 0 0 0-2.07-4.873L44.103 72.2"
      />
      <Path
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.196}
        d="M83.446 63.311c9.578 1.054 16.424 3.856 16.414 7.151 0 4.198-11.105 7.61-24.82 7.61-8.195 0-15.47-1.222-19.983-3.098a21.702 21.702 0 0 0-5.086-1.415l-4.987-.46c-.957-.084-1.218-1.33-.36-1.766 2.586-1.306 6.23-3.133 7.92-3.883 3.55-1.583 8.148-3.26 14.39-4.077"
      />
      <Path
        fill="#fff"
        d="m75.193 105.151.093.094a49.677 49.677 0 0 1 2.38 2.818 38.797 38.797 0 0 1 2.286 3.196 29.736 29.736 0 0 1 1.823 3.256c.418.876.778 1.788 1.056 2.711.186.628.337 1.267.441 1.918.116.722.163 1.456.116 2.202-.058 1.089-.301 2.131-.777 3.102a7.012 7.012 0 0 1-2.357 2.699c-.766.533-1.602.9-2.496 1.148-.43.119-.859.201-1.288.261-.58.083-1.161.106-1.742.071a9.27 9.27 0 0 1-3.506-.853 7.633 7.633 0 0 1-2.368-1.728c-.79-.864-1.323-1.882-1.625-3.031a9.297 9.297 0 0 1-.22-1.267 9.205 9.205 0 0 1-.035-1.385c.034-.71.139-1.42.29-2.119a17.74 17.74 0 0 1 1.172-3.421 26.875 26.875 0 0 1 1.776-3.279 40.024 40.024 0 0 1 2.392-3.386 50.075 50.075 0 0 1 2.484-2.948c.047 0 .07-.024.105-.059Z"
      />
      <Path
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.196}
        d="M93.978 82.833v31.714M93.978 119.246v10.571"
      />
      <Circle
        cx={76.09}
        cy={160.5}
        r={11}
        fill="#B6C5D3"
        stroke="#fff"
        strokeWidth={2}
      />
      <Path
        fill="#fff"
        d="M77.464 166.5h-1.68v-8.32a159.294 159.294 0 0 1 .064-1.312 7.501 7.501 0 0 1-.432.4c-.15.128-.32.277-.512.448l-1.248 1.008-.864-1.088 3.264-2.56h1.408V166.5Z"
      />
      <Circle
        cx={210.09}
        cy={160.5}
        r={11}
        fill="#B6C5D3"
        stroke="#fff"
        strokeWidth={2}
      />
      <Path
        fill="#fff"
        d="M213.928 166.5h-7.68v-1.312l2.944-2.992a49.412 49.412 0 0 0 1.44-1.52c.384-.437.672-.853.864-1.248.192-.405.288-.843.288-1.312 0-.587-.171-1.024-.512-1.312-.342-.299-.784-.448-1.328-.448-.523 0-.992.101-1.408.304a6.609 6.609 0 0 0-1.296.816l-.912-1.136c.309-.267.64-.507.992-.72a5.271 5.271 0 0 1 1.2-.512 5.34 5.34 0 0 1 1.472-.192c.725 0 1.349.128 1.872.384.522.245.922.597 1.2 1.056.288.459.432.997.432 1.616 0 .459-.07.891-.208 1.296a4.928 4.928 0 0 1-.608 1.168 8.942 8.942 0 0 1-.944 1.168 44.22 44.22 0 0 1-1.232 1.248l-2.128 2.064v.08h5.552v1.504Z"
      />
      <Path
        fill="#EDF1F5"
        d="M252.92 66.797a4.813 4.813 0 0 1 3.515 5.754 4.657 4.657 0 0 1-5.676 3.467l-30.195-7.513-1.523 6.5a17.117 17.117 0 0 1-7.734 10.72l-4.032 2.462a17.12 17.12 0 0 0-7.733 10.719 17.12 17.12 0 0 1-7.733 10.719l-2.505 1.53a17.14 17.14 0 0 0-7.639 10.335l-2.894 11.192 55.518 13.816a4.302 4.302 0 0 0 5.234-3.183l16.914-70.772"
      />
      <Path
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="M252.92 66.797a4.813 4.813 0 0 1 3.515 5.754 4.657 4.657 0 0 1-5.676 3.467l-30.195-7.513-1.523 6.5a17.117 17.117 0 0 1-7.734 10.72l-4.032 2.462a17.12 17.12 0 0 0-7.733 10.719 17.12 17.12 0 0 1-7.733 10.719l-2.505 1.53a17.14 17.14 0 0 0-7.639 10.335l-2.894 11.192 55.518 13.816a4.302 4.302 0 0 0 5.234-3.183l16.914-70.772"
      />
      <Path
        stroke="#2B2E32"
        strokeDasharray="4 4"
        d="M64.523 52.929c-4.542 1.18-7.5 3.105-7.5 5.28 0 3.59 8.059 6.5 18 6.5s18-2.91 18-6.5c0-2.175-2.958-4.1-7.5-5.28"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M.09.5h286v172H.09z" />
      </ClipPath>
    </Defs>
  </Svg>
);
