import React, { useEffect, useState } from "react";
import { Platform, StyleSheet } from "react-native";
import { observer } from "mobx-react-lite";
import { StackScreenProps } from "@react-navigation/stack";

import {
  ConnectingStatus,
  ConnectingStep,
} from "@models/module/module-connection";
import { useStores } from "@models/index";
import { Screen } from "@components/index";
import { TopBar } from "@components/layout";
import { Dialog, ErrorsAlert, ErrorsMessage, Text } from "@components/ui";
import { ModulesNavigatorParamList } from "@screens/Modules/navigation";

import { translate } from "../../../../i18n";
import { DefaultTheme, spacing } from "../../../../theme";
import { DetectingProgress, WiFiInput } from "../components";
import {
  ModuleConnectInstruction,
  StatusImage,
  ModuleConnectControls,
  ModuleConnectionProcessStatus,
  ModuleList,
} from "./components";

const styles = StyleSheet.create({
  screen: {
    // flex: 1,
    overflow: "hidden",
    minHeight: "100%",
    paddingHorizontal: spacing[4],
    // justifyContent: "space-between",
    // alignContent: "space-between",
  },
  error: {
    color: DefaultTheme.colors.error,
    textAlign: "center",
  },
  stopButton: {
    marginHorizontal: spacing[4],
    marginBottom: spacing[4],
  },

  errorMessage: {
    marginTop: spacing[6],
  },
});

export const ModuleAddManualScreen: React.FC<
  StackScreenProps<ModulesNavigatorParamList, "module-add-manual">
> = observer(({ navigation, route }) => {
  const { moduleConnection } = useStores();

  const [isStopConnection, setStopConnection] = useState(false);
  const showStopConnectionDialog = () => {
    setStopConnection(true);
  };
  const hideStopConnectionDialog = () => {
    setStopConnection(false);
  };
  const stopConnection = () => {
    hideStopConnectionDialog();
    moduleConnection.reset();
  };

  // TODO: опрашивать модуль - если подключили - начинать добавление !!!

  const backHandler = () => {
    if (moduleConnection.status === ConnectingStatus.Processing) {
      showStopConnectionDialog();
      return null;
    }

    if (moduleConnection.currentStep === ConnectingStep.WiFiSetting) {
      navigation.navigate("modules-list");
    }

    moduleConnection.prevStep();
    return null;
  };

  useEffect(() => {
    moduleConnection.reset();
    setStopConnection(false);
  }, []);

  useEffect(() => {
    if (
      moduleConnection.status === ConnectingStatus.Success &&
      moduleConnection.uid
    ) {
      navigation.navigate("module-add-successfully-connected", {
        uid: moduleConnection.uid,
      });
      moduleConnection.reset(); // Must be after navigate !!!
    }
  }, [moduleConnection.status]);

  const saveSettingWifiAndExecute = async (formData) => {
    moduleConnection.saveWiFiCredentials(formData);

    if (Platform.OS === "android") {
      const newModuleList = await moduleConnection.searchModulesForConnecting();
      if (!newModuleList?.length) {
        moduleConnection.watchConnectionAndExecute();
      }
    } else {
      moduleConnection.watchConnectionAndExecute();
    }
  };

  const onNextIsTokenTrue = async (formData) => {
    if (moduleConnection.token) {
      saveSettingWifiAndExecute(formData);
    } else {
      await moduleConnection.reset();

      if (!moduleConnection.error) {
        saveSettingWifiAndExecute(formData);
      }
    }
  };

  return (
    <Screen
      testID="ModuleAddManual"
      style={styles.screen}
      preset="scroll"
      header={
        <TopBar
          title={translate("Modules.Add.Manual.title")}
          back
          backHandler={backHandler}
        />
      }
      keyboard
      loading={
        moduleConnection.currentStep === ConnectingStep.ConnectingToModule
      }
      error={
        Platform.OS === "web" && (
          <ErrorsMessage
            style={styles.errorMessage}
            errors={[
              translate("errorScreen.featureIsNotAvailableForThisPlatform", {
                platform: Platform.OS,
              }),
            ]}
          />
        )
      }
    >
      <StatusImage status={moduleConnection.status} />

      {(moduleConnection.currentStep === ConnectingStep.WiFiSetting ||
        !moduleConnection.currentStep) &&
        moduleConnection.status !== ConnectingStatus.Error && (
          <>
            {moduleConnection.error ? (
              <ErrorsAlert
                errors={[moduleConnection.error]}
                onCancel={moduleConnection.clear}
              />
            ) : null}

            <WiFiInput
              ssid={moduleConnection.ssid}
              password={moduleConnection.password}
              onSubmit={onNextIsTokenTrue}
            />
          </>
        )}

      {moduleConnection.currentStep === ConnectingStep.SelectModule &&
        moduleConnection.modulesList && (
          <ModuleList
            modulesSSIDList={moduleConnection.modulesList}
            onSubmit={moduleConnection.startAutoConnection}
          />
        )}

      {[
        ConnectingStep.Instruction,
        ConnectingStep.WaitingConnectionToModuleWiFi,
      ].includes(moduleConnection.currentStep) &&
        moduleConnection.status !== ConnectingStatus.Error && (
          <ModuleConnectInstruction moduleConnection={moduleConnection} />
        )}

      <ModuleConnectionProcessStatus moduleConnection={moduleConnection} />

      {moduleConnection.error && (
        <Text style={styles.error}>{moduleConnection.error}</Text>
      )}

      <ModuleConnectControls moduleConnection={moduleConnection} />

      {/* {moduleConnection.status === ConnectingStatus.Processing && (
        <Button
          style={styles.stopButton}
          type="secondary_warn"
          onPress={showStopConnectionDialog}
        >
          {translate("Modules.Add.Manual.stepsStopConnection")}
        </Button>
      )} */}

      {moduleConnection.currentStep === ConnectingStep.SearchingModule && (
        <DetectingProgress
          title={translate("Modules.Add.searchingModule")}
          timeMs={5000}
        />
      )}

      <Dialog
        title={translate("Modules.Add.Manual.stepsStopConnection")}
        content={translate("Modules.Add.Manual.contentStopConnection")}
        visible={isStopConnection}
        onCancel={hideStopConnectionDialog}
        okType="warning"
        okText={translate("common.stop")}
        onOk={stopConnection}
      />
    </Screen>
  );
});
