import { View, StyleSheet, ViewStyle, StyleProp } from "react-native";
import { useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTheme } from "@react-navigation/native";

import { Button, RadioItem, Text } from "@components/ui";
import { RuleType } from "@models/rule/rule";
import { Device } from "@models/device/device";

import { spacing } from "../../../../../theme";
import { translate } from "../../../../../i18n";
import {
  DimmerManualIcon,
  NoneIcon,
  ScheduleIcon,
  SensorIcon,
} from "../../../../../svgs";

const createStyles = (theme: any) =>
  StyleSheet.create({
    screen: {
      padding: spacing[4],
      paddingBottom: spacing[8],
    },
    title: {
      fontSize: 16,
      lineHeight: 22,
      ...theme.fonts.medium,
      marginBottom: spacing[2],
    },
    content: {
      fontSize: 14,
      lineHeight: 16,
      color: theme.colors.label,
      marginBottom: spacing[6],
    },
    container: {
      marginBottom: spacing[6],
    },
  });

const stylesItem = (theme: any) =>
  StyleSheet.create({
    item: {
      minHeight: 80,
      marginBottom: spacing[3],
    },
  });

type RuleIconProps = {
  ruleType: RuleType;
};

const RuleIcon = ({ ruleType }: RuleIconProps) => {
  switch (ruleType) {
    case RuleType.Schedule:
      return <ScheduleIcon />;

    case RuleType.Sensor:
      return <SensorIcon />;

    case RuleType.DimmerManual:
      return <DimmerManualIcon />;

    case RuleType.DimmerAuto:
      return <SensorIcon />;

    default:
      return <NoneIcon size={44} />;
  }
};

type TypeItemProps = {
  type: RuleType;
  currentType: RuleType | string;
  onPress?: (value: string) => void;
  style?: StyleProp<ViewStyle>;
};

export const RuleTypeItem = observer(
  ({ type, onPress, currentType, style }: TypeItemProps) => {
    const theme = useTheme() as any;
    const styles = useMemo(() => stylesItem(theme), [theme]);
    const checked = currentType === type;

    const DISPLAY_NAME_RULE_TYPE = {
      [RuleType.Schedule]: translate("Devices.Rules.ruleTypeSchedule"),
      [RuleType.Sensor]: translate("Devices.Rules.ruleTypeSensor"),
      [RuleType.DimmerManual]: translate("Devices.Rules.dimming"),
      [RuleType.DimmerAuto]: translate("Devices.Rules.autoBalancing"),
    };
    const label = DISPLAY_NAME_RULE_TYPE[type] || "-";

    return (
      <RadioItem
        value={type}
        label={label}
        checked={checked}
        style={[styles.item, style]}
        icon={<RuleIcon ruleType={type} />}
        onPress={onPress}
      />
    );
  },
);

type SelectRuleTypeProps = {
  device: Device;
  onChange: (type: RuleType) => void;
};

export const SelectRuleType = ({ device, onChange }: SelectRuleTypeProps) => {
  const theme = useTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);

  const [type, setType] = useState<RuleType | string>("");

  const onNext = () => {
    onChange(type as RuleType);
  };

  return (
    <View style={styles.screen}>
      <Text style={styles.title}>
        {translate("Devices.Rules.Add.Description.title")}
      </Text>
      <Text style={styles.content}>
        {translate("Devices.Rules.Add.Description.content")}
      </Text>

      <View style={styles.container}>
        {device.availableRuleTypes.map((typeRule) => (
          <RuleTypeItem
            key={typeRule}
            type={typeRule}
            currentType={type}
            onPress={setType}
          />
        ))}
      </View>

      <Button
        type="primary"
        tx="common.next"
        onPress={onNext}
        disabled={!type?.length}
      >
        Next
      </Button>
    </View>
  );
};
