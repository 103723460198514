import React, { memo } from "react";
import { Path } from "react-native-svg";

import { palette } from "../../../theme";
import { apx } from "../utils";

type LineProps = {
  line: any;
  color: string;
};
export const Line = memo(({ line, color = palette.Blue }: LineProps) => (
  <Path key="line" d={line} stroke={color} strokeWidth={apx(3)} fill="none" />
));
