import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { StyleSheet, View } from "react-native";
import { StackScreenProps } from "@react-navigation/stack";

import { useStores } from "@models/root-store/root-store-context";
import { Screen } from "@components/index";
import { TopBar } from "@components/layout";
import { ErrorsMessage } from "@components/ui";
import { TReleaseNotesData } from "@models/documents/types";
import { ReleaseNoteContent } from "@components/common";

import { translate } from "../../../../i18n";
import { spacing } from "../../../../theme";
import { SettingsNavigatorParamList } from "../../navigation";

const styles = StyleSheet.create({
  screen: {},
  container: {
    paddingHorizontal: spacing[4],
    paddingBottom: spacing[4],
  },
  message: {
    marginTop: spacing[4],
  },
  item: {
    marginBottom: spacing[6],
  },
});

export const ReleaseNotesScreen: React.FC<
  StackScreenProps<SettingsNavigatorParamList, "settings-help-release-notes">
> = observer(() => {
  const { documentsStore, accountStore } = useStores();

  useEffect(() => {
    documentsStore.readReleaseNotesData();
  }, [accountStore.currentUser.id]);

  const onRefresh = async () => {
    await documentsStore.readReleaseNotesData({ force: true });
  };

  const { releaseNotesData } = documentsStore;

  return (
    <Screen
      style={styles.screen}
      testID="ReleaseNotes"
      header={<TopBar title={translate("Settings.ReleaseNotes.title")} back />}
      preset="scroll"
      onRefresh={onRefresh}
      refreshing={!documentsStore.isLoaded}
    >
      <View style={styles.container}>
        <ErrorsMessage
          errors={documentsStore.statusErrors}
          onCancel={documentsStore.resetErrors}
          style={styles.message}
        />

        {releaseNotesData?.length ? (
          releaseNotesData.map((item: TReleaseNotesData) => (
            <ReleaseNoteContent
              key={item.id}
              releaseNote={item}
              style={styles.item}
            />
          ))
        ) : (
          <ErrorsMessage
            errors={[translate("Settings.ReleaseNotes.error.noReleaseNotes")]}
            style={styles.message}
          />
        )}
      </View>
    </Screen>
  );
});
