import * as React from "react";
import Svg, { Path } from "react-native-svg";

export default function PHHydroIcon({ color = "#6F6F6F", size = 24 }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0011 3.75C10.255 3.75003 10.4917 3.87854 10.63 4.09148L11.9292 6.09186C12.1548 6.43923 12.0561 6.90373 11.7088 7.12935C11.3614 7.35497 10.8969 7.25627 10.6713 6.9089L10.0008 5.87664L5.44965 12.8798C4.99086 13.6772 4.74958 14.5812 4.75 15.5013C4.75043 16.4228 4.9933 17.328 5.45421 18.1259C5.91511 18.9238 6.57783 19.5863 7.37575 20.0469C8.17368 20.5075 9.07873 20.75 10 20.75C10.9213 20.75 11.8263 20.5075 12.6242 20.0469C13.4222 19.5863 14.0849 18.9238 14.5458 18.1259C15.0067 17.328 15.2496 16.4228 15.25 15.5013C15.2502 15.0871 15.5861 14.7515 16.0003 14.7516C16.4146 14.7518 16.7502 15.0878 16.75 15.502C16.7495 16.6867 16.4372 17.8504 15.8447 18.8762C15.2521 19.902 14.4001 20.7538 13.3742 21.346C12.3482 21.9382 11.1845 22.25 10 22.25C8.81545 22.25 7.65177 21.9382 6.62585 21.346C5.59993 20.7538 4.7479 19.902 4.15534 18.8762C3.56278 17.8504 3.25055 16.6867 3.25 15.502C3.24946 14.3303 3.55381 13.1789 4.13282 12.1608C4.14465 12.1376 4.15776 12.1149 4.17213 12.0928L9.37213 4.09131C9.51049 3.87841 9.74718 3.74997 10.0011 3.75ZM9.25 10.5C9.25 9.5335 10.0335 8.75 11 8.75H13C13.9665 8.75 14.75 9.5335 14.75 10.5V12.5C14.75 13.4665 13.9665 14.25 13 14.25H10.75V16.5C10.75 16.9142 10.4142 17.25 10 17.25C9.58579 17.25 9.25 16.9142 9.25 16.5V13.5V10.5ZM10.75 12.75H13C13.1381 12.75 13.25 12.6381 13.25 12.5V10.5C13.25 10.3619 13.1381 10.25 13 10.25H11C10.8619 10.25 10.75 10.3619 10.75 10.5V12.75ZM16.75 5.5C16.75 5.08579 16.4142 4.75 16 4.75C15.5858 4.75 15.25 5.08579 15.25 5.5V9.5V13.5C15.25 13.9142 15.5858 14.25 16 14.25C16.4142 14.25 16.75 13.9142 16.75 13.5V10.25H20.2497L20.2495 13.5C20.2495 13.9142 20.5852 14.25 20.9994 14.25C21.4137 14.25 21.7495 13.9143 21.7495 13.5L21.7497 9.51986C21.7499 9.51326 21.75 9.50664 21.75 9.5C21.75 9.49339 21.7499 9.48681 21.7497 9.48024L21.75 5.50005C21.75 5.08583 21.4143 4.75003 21 4.75C20.5858 4.74997 20.25 5.08574 20.25 5.49995L20.2498 8.75H16.75V5.5Z"
        fill={color}
      />
    </Svg>
  );
}
