import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

export const CalibrationDOLiquidErrorSvg = (props: SvgProps) => (
  <Svg width={286} height={172} fill="none" {...props}>
    <Path
      fill="#FBE3E3"
      d="M215.855 170.086H64.235c-17.23 0-27.9-18.82-19.06-33.651l74.39-124.845c9.2-15.443 31.51-15.453 40.73-.03l74.6 124.844c8.87 14.832-1.79 33.682-19.04 33.682Z"
    />
    <Path
      fill="#B6C5D3"
      d="M136.421 166.814c17.993 0 32.579-4.428 32.579-9.89 0-5.462-14.586-9.89-32.579-9.89-17.993 0-32.579 4.428-32.579 9.89 0 5.462 14.586 9.89 32.579 9.89Z"
    />
    <Path
      fill="#6A7B8C"
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.261}
      d="M143.571 61.644h-5.97a3.36 3.36 0 0 1-3.356-3.362V42.81a3.36 3.36 0 0 1 3.356-3.363h5.97a3.361 3.361 0 0 1 3.357 3.363v15.464a3.362 3.362 0 0 1-3.357 3.369Z"
    />
    <Path
      fill="#6A7B8C"
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.261}
      d="m144.55 39.448-2.697-9.512H139.32l-2.697 9.512 3.964-.122 3.963.122Z"
    />
    <Path
      stroke="#2A2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.261}
      d="M140.587 28.973v-1.73C140.587 14.96 130.525 5 118.114 5c-12.416 0-22.478 9.96-22.478 22.243v40.514C95.636 80.04 85.574 90 73.164 90H71.06c-12.411 0-22.473-9.96-22.473-22.243"
    />
    <Path
      fill="#6A7B8C"
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.261}
      d="M144.55 61.644h-7.927v52.318h7.927V61.644ZM145.613 122.681H135.56a1.312 1.312 0 0 1-1.315-1.308v-6.103c0-.719.585-1.308 1.315-1.308h10.053c.729 0 1.315.583 1.315 1.308v6.103c0 .719-.592 1.308-1.315 1.308Z"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.261}
      d="M136.623 109.998h7.927"
    />
    <Path
      fill="#70B8FF"
      fillOpacity={0.4}
      d="M140.493 116.782c15.744 0 28.506-3.907 28.506-8.727 0-4.819-12.762-8.726-28.506-8.726s-28.507 3.907-28.507 8.726c0 4.82 12.763 8.727 28.507 8.727Z"
    />
    <Path
      fill="#308AE3"
      fillRule="evenodd"
      d="M169 108.056c0 4.819-12.763 8.726-28.507 8.726-15.744 0-28.507-3.907-28.507-8.726 0-.187.02-.373.057-.557l-.057.001V154.39c0 4.928 12.768 8.934 28.507 8.934S169 159.318 169 154.39v-46.334Zm-.563-1.734.563-.012v1.746c0-.594-.194-1.174-.563-1.734Z"
      clipRule="evenodd"
      opacity={0.6}
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M169 83.04v71.355c0 4.926-12.796 8.928-28.569 8.928-15.774 0-28.57-4.002-28.57-8.928V95.97c0-2.115-.858-4.13-2.376-5.592l-4.48-4.77"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M136.553 74.995c-9.975.514-16.832 2.92-21.859 5.162-1.941.86-6.123 2.957-9.092 4.457-.984.5-.685 1.93.414 2.027l5.723.528c2.013.305 3.968.847 5.838 1.624 5.181 2.152 13.53 3.555 22.935 3.555 15.743 0 28.488-3.916 28.488-8.734.013-4.367-10.467-7.98-24.151-8.62M162.248 97.813v36.4M162.248 139.606v12.134"
    />
    <Path
      stroke="#fff"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M132.618 151.276a51.564 51.564 0 0 1-5.821-1.158v-17.672c2.022 0 3.87.21 5.821.484 2.744.386 4.98 2.685 4.964 5.45v8.33c0 2.781-2.236 4.952-4.964 4.566ZM148.859 151.151c-.301.047-.602.094-.92.126-2.743.378-4.98-1.75-4.964-4.462v-8.83c0-2.712 2.237-4.967 4.964-5.345.302-.047.619-.079.92-.126 2.728-.426 4.901 1.198 4.901 3.91v8.83c.016 2.696-2.173 5.471-4.901 5.897Z"
    />
    <Path
      fill="#F76B6B"
      d="m232.086 45 7.513-7.687c3.201-3.276 3.201-8.59 0-11.856a8.058 8.058 0 0 0-11.586 0l-7.513 7.687-7.513-7.687a8.058 8.058 0 0 0-11.586 0c-3.201 3.276-3.201 8.59 0 11.856L208.914 45l-7.513 7.687c-3.201 3.276-3.201 8.59 0 11.856a8.058 8.058 0 0 0 11.586 0l7.513-7.687 7.513 7.687a8.058 8.058 0 0 0 11.586 0c3.201-3.276 3.201-8.59 0-11.856L232.086 45Z"
    />
    <Path
      stroke="#fff"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M132.559 125.219c-3.096-.454-5.559-3.634-5.559-6.718v-7.543c0-3.096 2.463-4.901 5.559-4.447 3.096.455 5.63 3.013 5.618 6.097v7.543c0 3.084-2.522 5.535-5.618 5.068ZM152.677 105.662a650.623 650.623 0 0 1-8.738 20.418M145.995 108.531c0-.681-.562-1.171-1.243-1.111-.694.059-1.255.657-1.255 1.339 0 .681.561 1.207 1.255 1.135.693-.06 1.243-.681 1.243-1.363ZM153 122.924c0-.681-.55-1.1-1.232-.932-.681.155-1.231.825-1.231 1.518 0 .681.55 1.124 1.231.968.682-.167 1.232-.873 1.232-1.554Z"
    />
  </Svg>
);
