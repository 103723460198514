import { useTheme } from "@react-navigation/native";
import { StyleSheet } from "react-native";

import { spacing } from "../../../theme";
import { Text } from "../Text";

const styles = StyleSheet.create({
  paragraph: {
    fontSize: 14,
    marginBottom: spacing[4],
  },
});

type Props = React.ComponentProps<typeof Text>;

const Paragraph = ({ style, children, ...props }: Props) => {
  const { colors, fonts } = useTheme() as any;

  const themeStyle = {
    ...fonts.light,
    color: colors.text,
  };

  return (
    <Text {...props} style={[styles.paragraph, themeStyle, style]}>
      {children}
    </Text>
  );
};

export default Paragraph;
