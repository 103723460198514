import { fetch as fetchNetInfo } from "@react-native-community/netinfo";
import { flow, getParent, Instance, types } from "mobx-state-tree";
import { Platform } from "react-native";
import { ApiResponse } from "apisauce";
import dayjs from "dayjs";

import {
  downloadFirmWareMobile,
  downloadFirmWareWeb,
  uploadFirmWareMobile,
  uploadFirmWareWeb,
} from "../../services/firmware-update";
import { rnLogger } from "../../services/logger";
import { translate } from "../../i18n";
import { delay } from "../../utils/delay";
import { getWifiSsid } from "../../utils/wifi";
import { ModuleApi } from "../../services/api";
import { DateTimeFormats } from "../../utils/timeConverter";
import { withEnvironment } from "../extensions/with-environment";
import { withRootStore } from "../extensions/with-root-store";
import { Module } from "./module";

export enum UpdateStatus {
  Ready = "ready",
  Processing = "processing",
  Success = "success",
  Error = "error",
  CanNotUpdate = "canNotUpdate",
}

export enum UpdateStep {
  NetworkCheck = "networkCheck",
  Connecting = "connecting",
  Checking = "checking",
  Downloading = "downloading",
  Uploading = "uploading",
  Rebooting = "rebooting",
}

export const MODULE_REBOOTING_TIMEOUT_MS = 30000;

type StepType = {
  key: UpdateStep;
  label: string;
};
export const MODULE_UPDATE_STEPS: StepType[] = [
  {
    key: UpdateStep.NetworkCheck,
    label: translate("Modules.update.steps.networkCheck"),
  },
  {
    key: UpdateStep.Connecting,
    label: translate("Modules.update.steps.connecting"),
  },
  {
    key: UpdateStep.Checking,
    label: translate("Modules.update.steps.checking"),
  },
  {
    key: UpdateStep.Downloading,
    label: translate("Modules.update.steps.downloading"),
  },
  {
    key: UpdateStep.Uploading,
    label: translate("Modules.update.steps.uploading"),
  },
  {
    key: UpdateStep.Rebooting,
    label: translate("Modules.update.steps.rebooting"),
  },
];

export const ModuleUpdateModel = types
  .model("ModuleUpdateModel")
  .props({
    status: types.maybeNull(
      types.enumeration<UpdateStatus>(
        "UpdateStatus",
        Object.values(UpdateStatus),
      ),
    ),
    currentStep: types.maybeNull(
      types.enumeration<UpdateStep>("UpdateStep", Object.values(UpdateStep)),
    ),
    error: types.maybeNull(types.string),
    timestamp: types.maybeNull(types.Date),
    logs: types.optional(types.string, ""),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .actions((self) => ({
    addLog(log: string) {
      rnLogger.info(log, {
        action: "module_firmware_update",
      });
      self.logs = self.logs.concat(
        `${dayjs().format(DateTimeFormats.TimeHMS)} - ${log}\n`,
      );
    },
    resetLogs() {
      self.logs = "";
    },
    setError(error: string, status = UpdateStatus.Error) {
      self.error = error;
      self.status = status;

      rnLogger.error(self.logs, {
        action: "module_firmware_update",
      });
      return false;
    },
    setStatus(status: UpdateStatus) {
      self.status = status;
    },
    reset() {
      self.status = null;
      self.currentStep = null;
      self.error = null;
      self.timestamp = null;
      self.logs = "";
    },
    // afterCreate() {
    //   self.timestamp = new Date();
    //   self.status = UpdateStatus.Processing;
    //   self.currentStep = UpdateStep.Rebooting;
    // },
  }))
  .views((self) => ({
    get module() {
      return getParent<Module>(self);
    },
    get rebootingLeft() {
      return Math.round(
        ((self.timestamp?.getTime() || 0) +
          MODULE_REBOOTING_TIMEOUT_MS -
          new Date().getTime()) /
          1000,
      );
    },
  }))
  .actions((self) => {
    const moduleApi = new ModuleApi(self.environment.api);

    const moduleIsUpdated = flow(function* () {
      const result = yield moduleApi.getModule(self.module.uid);
      const { latestFWVersion } = self.rootStore.settingsStore;
      self.addLog(
        `moduleIsUpdated result - ${JSON.stringify({
          kind: result.kind,
          moduleFirmware: result.data?.firmware,
          latestFWVersion,
        })}`,
      );
      return result.kind === "ok" && result.data.firmware === latestFWVersion;
    });

    const watchUpdateProcess = flow(function* () {
      self.addLog(`watchUpdateProcess status - ${self.status}`);
      // if (self.status !== UpdateStatus.Processing) {
      //   return false;
      // }

      let isUpdated = yield moduleIsUpdated();
      self.addLog(`watchUpdateProcess isUpdated - ${isUpdated}`);
      if (isUpdated) {
        self.reset();
        return true;
      }

      yield delay(MODULE_REBOOTING_TIMEOUT_MS / 2);
      self.addLog(`watchUpdateProcess status (2) - ${self.status}`);
      if (self.status === UpdateStatus.Error) {
        return false;
      }
      isUpdated = yield moduleIsUpdated();
      self.addLog(`watchUpdateProcess isUpdated (2) - ${isUpdated}`);
      if (isUpdated) {
        self.reset();
        return true;
      }

      yield delay(MODULE_REBOOTING_TIMEOUT_MS / 2);
      self.addLog(`watchUpdateProcess status (3) - ${self.status}`);
      isUpdated = yield moduleIsUpdated();
      self.addLog(`watchUpdateProcess isUpdated (3) - ${isUpdated}`);
      if (isUpdated) {
        self.reset();
        return true;
      }

      return self.setError(translate("Modules.update.status.error"));
    });

    const execute = flow(function* () {
      self.resetLogs();
      self.status = UpdateStatus.Processing;

      self.addLog(
        `Module network: ${JSON.stringify({
          UID: self.module.uid,
          IP: self.module.ip,
          SSID: self.module.ssid,
        })}`,
      );

      // 0. Check that the module and phone are on the same Wi-Fi network
      if (Platform.OS !== "ios") {
        // TODO: until check permissions for iOS
        self.currentStep = UpdateStep.NetworkCheck;
        const networkCheckResult = yield getWifiSsid();
        self.addLog(`Network check: ${JSON.stringify(networkCheckResult)}`);
        if (!networkCheckResult.ssid) {
          return self.setError(translate("Modules.update.errors.noNetwork"));
        }

        if (
          networkCheckResult.ssid &&
          self.module.ssid &&
          networkCheckResult.ssid !== self.module.ssid
        ) {
          return self.setError(translate("Modules.update.errors.networkCheck"));
        }
      }

      fetchNetInfo().then((state) => {
        self.addLog(`Client network: ${JSON.stringify(state.details || {})}`);
      });

      // 1. Check connection to Module
      self.currentStep = UpdateStep.Connecting;
      let isConnected = yield self.module.connectionTest();
      self.addLog(
        `Test connection to ${self.module.uid} (IP: ${self.module.ip}) - ${isConnected}`,
      );
      if (!isConnected) {
        // TODO: temporary solution
        yield delay(500);
        isConnected = yield self.module.connectionTest();
        self.addLog(`Test connection (2) - ${isConnected}`);
      }
      if (!isConnected) {
        // TODO: temporary solution
        yield delay(500);
        isConnected = yield self.module.connectionTest();
        self.addLog(`Test connection (3) - ${isConnected}`);
      }
      if (!isConnected) {
        return self.setError(translate("Modules.update.errors.notConnected"));
      }

      // 2. Checking if an update is possible
      self.currentStep = UpdateStep.Checking;
      const canUpdate = yield self.module.canUpdate();
      self.addLog(`canUpdate - ${canUpdate}`);
      if (!canUpdate) {
        return self.setError(
          translate("Modules.update.errors.cantUpdate"),
          UpdateStatus.CanNotUpdate,
        );
      }

      // 3. Download firmware file
      self.currentStep = UpdateStep.Downloading;

      const firmware = yield moduleApi.getLastFirmware();
      const firmwareUrl = firmware?.data?.link;
      if (!firmwareUrl) {
        return self.setError(translate("Modules.update.errors.noLinkDownload"));
      }

      self.addLog(`Downloading: ${firmwareUrl}`);
      let updateResult = {} as ApiResponse<any>;

      if (Platform.OS === "web") {
        // WEB
        const fileData = yield downloadFirmWareWeb(firmwareUrl);
        if (!fileData) {
          return self.setError(
            translate("Modules.update.errors.notDownloaded"),
          );
        }
        self.addLog("FileData fetched successfully");

        // 4. Uploading firmware to module (web)
        self.currentStep = UpdateStep.Uploading;
        updateResult = yield uploadFirmWareWeb(self.module.ip, fileData);
      } else {
        // iOS , Android
        const firmwareDownloadResult = yield downloadFirmWareMobile(
          firmwareUrl,
        );
        self.addLog(
          `FirmwareDownloadResult: ${JSON.stringify(firmwareDownloadResult)}`,
        );

        if (!firmwareDownloadResult.ok || !firmwareDownloadResult.uri) {
          return self.setError(
            translate("Modules.update.errors.notDownloaded"),
          );
        }

        // 4. Uploading firmware to module (android, ios)
        self.currentStep = UpdateStep.Uploading;
        updateResult = yield uploadFirmWareMobile(
          self.module.ip,
          firmwareDownloadResult.uri,
        );

        setTimeout(
          self.watchUpdateProcess,
          Math.round(MODULE_REBOOTING_TIMEOUT_MS / 2),
        );
      }

      self.addLog(
        `updateResult: ${JSON.stringify({
          ok: updateResult.ok,
          problem: updateResult.problem,
          data: updateResult.data,
        })}`,
      );

      const isUpdated = yield moduleIsUpdated();
      self.addLog(`isUpdated - ${isUpdated}`);

      if (updateResult.ok || isUpdated) {
        self.status = UpdateStatus.Success;
        self.addLog(translate("Modules.update.status.success"));
        // 3. Rebooting module
        self.currentStep = UpdateStep.Rebooting;
        self.timestamp = isUpdated ? null : new Date();

        const resultUpdateLocation = yield self.module.updateLocation();
        self.addLog(`UpdateLocation: ${JSON.stringify(resultUpdateLocation)}`);

        return true;
      }

      return self.setError(translate("Modules.update.status.error"));
    });

    return {
      execute,
      moduleIsUpdated,
      watchUpdateProcess,
    };
  });

type ModuleUpdateMOBXType = Instance<typeof ModuleUpdateModel>;
export type ModuleUpdate = ModuleUpdateMOBXType;
