import { useMemo } from "react";
import { View, StyleSheet } from "react-native";
import { TextInput } from "@components/inputs";
import { TextInput as PaperTextInput, shadow } from "react-native-paper";

import { spacing } from "../../../../../theme";
import { useAppTheme } from "../../../../../hooks";

const createStyles = (theme: any) =>
  StyleSheet.create({
    container: {
      backgroundColor: theme.colors.background,
      marginHorizontal: spacing[4],
      borderRadius: spacing[4],
      top: 156,
      ...(shadow(5) as any),
    },
    inputs: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    input: {
      flex: 1,
      marginHorizontal: spacing[2],
    },
    unit: {
      fontSize: 18,
      color: theme.colors.text,
    },
    unitDisabled: {
      color: theme.colors.disabled,
    },
  });

type InputsRangeProps = {
  disabledMin?: boolean;
  disabledMax?: boolean;
};

const unitName = "\u00B0C";

export const InputsRange = ({
  disabledMin = false,
  disabledMax = false,
}: InputsRangeProps) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  return (
    <View style={styles.container}>
      <View style={styles.inputs}>
        <View style={styles.input}>
          <TextInput
            label="Minimum"
            defaultValue="18"
            right={
              <PaperTextInput.Affix
                text={unitName}
                textStyle={[styles.unit, disabledMin && styles.unitDisabled]}
              />
            }
            disabled={disabledMin}
          />
        </View>
        <View style={styles.input}>
          <TextInput
            label="Maximum"
            defaultValue="35"
            right={
              <PaperTextInput.Affix
                text={unitName}
                textStyle={[styles.unit, disabledMax && styles.unitDisabled]}
              />
            }
            disabled={disabledMax}
          />
        </View>
      </View>
    </View>
  );
};
