import React, { useMemo } from "react";
import {
  View,
  Image,
  StyleSheet,
  Pressable,
  StyleProp,
  ViewStyle,
} from "react-native";
import { useNavigation, useTheme } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";

import { MODULE_IMAGES } from "@assets/modules";
import { Badge, Text } from "@components/ui";
import { Module } from "@models/module/module";

import { palette, spacing } from "../../../theme";
import { ModulesNavigatorParamList } from "../navigation";
import { translate } from "../../../i18n";

const createStyles = (theme: any) =>
  StyleSheet.create({
    container: {
      flexGrow: 1,
      flexBasis: "50%",
    },
    card: {
      overflow: "hidden",
      borderRadius: spacing[4],
      alignItems: "center",
      backgroundColor: theme.colors.cardBG,
      padding: spacing[3],
    },
    cardError: {
      backgroundColor: theme.colors.errorBG,
    },
    cardBottom: {
      width: 134,
      minHeight: 42,
      marginTop: spacing[3],
    },
    picture: {
      width: 134,
      height: 96,
    },
    pictureOffline: {
      opacity: 0.6,
    },
    badge: {
      zIndex: 10,
      position: "absolute",
      top: spacing[1],
      left: spacing[3] + spacing[2],
    },
    typeName: {
      color: theme.colors.label,
      marginBottom: spacing[1],
      fontSize: 12,
    },
    nameText: {
      fontSize: 14,
    },
  });

interface ModuleCardProps {
  module: Module;
  style?: StyleProp<ViewStyle>;
}

const ModuleCard: React.FunctionComponent<ModuleCardProps> = ({
  module,
  style,
}) => {
  const navigation =
    useNavigation() as StackNavigationProp<ModulesNavigatorParamList>;
  const theme = useTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);
  const cardStyle = [styles.card, style, !module.isOnline && styles.cardError];

  const imageSource = MODULE_IMAGES[module.type] || MODULE_IMAGES.default;

  const detailsScreenHandler = () => {
    const { uid } = module;

    navigation.navigate("module-details", { uid });
  };

  return (
    <Pressable style={styles.container} onPress={detailsScreenHandler}>
      {!module.isOnline && (
        <Badge text={translate("Modules.state.offline")} style={styles.badge} />
      )}

      {module.isOnline && module.isFirmwareUpdateAvailable && (
        <Badge
          text={translate("Modules.state.update")}
          style={styles.badge}
          gradientColors={[palette.OrangeG2, palette.OrangeG1]}
        />
      )}

      {module.isOnline && module.isNew && !module.isFirmwareUpdateAvailable && (
        <Badge
          text={translate("Modules.state.new")}
          style={styles.badge}
          gradientColors={[palette.Green, palette.Green]}
        />
      )}

      <View style={cardStyle}>
        <Image
          style={[styles.picture, !module.isOnline && styles.pictureOffline]}
          source={imageSource}
        />

        <View style={styles.cardBottom}>
          <Text style={styles.typeName} numberOfLines={1} ellipsizeMode="tail">
            {module.typeName}
          </Text>
          <Text style={styles.nameText} numberOfLines={2} ellipsizeMode="tail">
            {module.displayName}
          </Text>
        </View>
      </View>
    </Pressable>
  );
};

export default ModuleCard;
