import { useCallback, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";

import { useStores } from "@models/index";
import { Device, DeviceMode } from "@models/device/device";
import {
  DeviceHistoryMode,
  DeviceHistoryView,
  DeviceState,
  TDeviceHistoryItem,
  TDeviceHistoryLog,
  TDeviceHistoryLogRule,
} from "@models/device/types";

import { DevicesApi } from "../services/api";
import { translate } from "../i18n";
import {
  DateTimeFormats,
  getDuration,
  getPeriodWithTime,
} from "../utils/timeConverter";
import { displayDuration } from "../utils/display";

const DEVICE_HISTORY_RECORDS_LIMIT = 250;

const filterData = (
  data: TDeviceHistoryItem[],
  filterMode: DeviceHistoryMode,
): TDeviceHistoryItem[] => {
  if (filterMode === DeviceHistoryMode.ByRule) {
    return data.filter((item) => item.isAuto);
  }
  if (filterMode === DeviceHistoryMode.Manual) {
    return data.filter((item) => !item.isAuto);
  }
  if (filterMode === DeviceHistoryMode.Unknown) {
    return data.filter((item) => !item.isAuto && !item.isManual);
  }

  return data;
};

const compressData = (data: TDeviceHistoryItem[]): TDeviceHistoryItem[] => {
  const dataStateOn = data.filter((item) => item.state === DeviceState.On);

  if (!dataStateOn.length) return [];

  return dataStateOn
    .map((item, index) => {
      const prevItem = dataStateOn[index - 1];

      const datesOff = data
        .filter(
          (itemData) =>
            itemData.state === DeviceState.Off &&
            itemData.dateTime > item.dateTime &&
            (prevItem ? itemData.dateTime < prevItem.dateTime : true),
        )
        .map((itemFilter) => itemFilter.dateTime);

      /* eslint-disable no-nested-ternary */
      const endDateTime =
        datesOff.length > 1
          ? new Date(Math.max(...datesOff))
          : datesOff.length
          ? datesOff[0]
          : null;
      /* eslint-enable no-nested-ternary */
      return endDateTime ? { ...item, endDateTime } : null;
    })
    .filter((item) => item);
};

const getRule = (
  item: TDeviceHistoryItem,
  device: Device,
): TDeviceHistoryLogRule | undefined => {
  if (!item.isAuto) return undefined;

  return item?.rule?.type
    ? device.getHistoryItemRule(item.sensor, item.rule)
    : device.getHistoryItemRuleOld(item.activeRuleUid, item.sensor.value);
};

const getDeviceHistoryLogs = (
  data: TDeviceHistoryItem[],
  device: Device,
): TDeviceHistoryLog[] => {
  return data.map<TDeviceHistoryLog>((item) => {
    let mode;
    if (!item.isAuto && !item.isManual) {
      mode = DeviceMode.Unknown;
    } else {
      mode = item.isAuto ? DeviceMode.Auto : DeviceMode.Manual;
    }

    return {
      id: item.id,
      dateTime: item.dateTime,
      state: item.state,
      isOffline: item.isOffline,
      mode,

      startTime: dayjs(item.dateTime).format(DateTimeFormats.TimeHMS),
      rule: getRule(item, device),

      duration:
        item?.dateTime && item?.endDateTime
          ? displayDuration(
              dayjs(item.dateTime).format(DateTimeFormats.DateTime),
              dayjs(item.endDateTime).format(DateTimeFormats.DateTime),
            )
          : undefined,

      durationInSec:
        item?.dateTime && item?.endDateTime
          ? getDuration(
              dayjs(item.dateTime).format(DateTimeFormats.DateTime),
              dayjs(item.endDateTime).format(DateTimeFormats.DateTime),
            )
          : undefined,
    };
  });
};

type UseDeviceHistoryProps = {
  device: Device;
  startDate: string | undefined;
  endDate: string | undefined;
  filterMode: DeviceHistoryMode;
  viewMode?: DeviceHistoryView;
};

type TDeviceHistoryResult = {
  isLoading: boolean;
  data: TDeviceHistoryItem[];
  logs: TDeviceHistoryLog[];
  errors: string[] | null;
  refetch: () => void;
};

export const useDeviceHistory = ({
  device,
  startDate,
  endDate,
  filterMode,
  viewMode = DeviceHistoryView.Detailed,
}: UseDeviceHistoryProps): TDeviceHistoryResult => {
  const [data, setData] = useState<TDeviceHistoryItem[] | []>([]);
  const [errors, setErrors] = useState<string[] | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [refetchToken, setRefetchToken] = useState<number | null>(null);

  const rootStore = useStores();
  const devicesApi = new DevicesApi(rootStore.environment.api);

  const fetchHistoryData = async () => {
    if (!device.uid || !startDate || !endDate) return;

    setErrors(null);
    setLoading(true);
    const result = await devicesApi.getDeviceHistory(
      device.uid,
      getPeriodWithTime({ startDate, endDate }),
    );
    setLoading(false);

    if (result.kind === "ok") {
      if (result.data.length > DEVICE_HISTORY_RECORDS_LIMIT) {
        result.data = result.data.slice(0, DEVICE_HISTORY_RECORDS_LIMIT - 1);
        setErrors([
          translate("Devices.History.errors.recordsLimitWarning", {
            count: DEVICE_HISTORY_RECORDS_LIMIT,
          }),
        ]);
      }
      setData(result.data);
    } else {
      setData([]);
      setErrors(result.errors);
    }
  };

  useEffect(() => {
    fetchHistoryData();
  }, [device.uid, startDate, endDate, refetchToken]);

  const logs = useMemo(() => {
    let preparedData = filterData(data, filterMode);

    if (viewMode !== DeviceHistoryView.Detailed) {
      preparedData = compressData(preparedData);
    }

    return getDeviceHistoryLogs(preparedData, device);
  }, [data, filterMode, viewMode]);

  const refetch = useCallback(() => setRefetchToken(Date.now()), []);

  return {
    isLoading,
    data,
    logs,
    errors,
    refetch,
  };
};
