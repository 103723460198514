import * as React from "react";
import { View, StyleSheet } from "react-native";

const styles = StyleSheet.create({
  center: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

type Props = React.ComponentProps<typeof View> & {
  children: React.ReactNode;
};

export function LayoutCenter(props: Props) {
  const { style, ...otherProps } = props;
  return <View {...otherProps} style={[styles.center, style]} />;
}
