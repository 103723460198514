import React, { useMemo, useState } from "react";
import { StyleSheet, View } from "react-native";
import { useTheme } from "@react-navigation/native";

import { Dialog, ErrorsMessage, Text } from "@components/ui";
import {
  DEFAULT_TIME_PROPS,
  SwitchInput,
  TimerInput,
} from "@components/inputs";
import { MIN_DURATION_SEC } from "@models/rule/rule";

import { translate } from "../../../i18n";
import { spacing } from "../../../theme";
import { TimeProps, timePropsToSeconds } from "../../../utils/timeConverter";

const createStyles = (theme: any) =>
  StyleSheet.create({
    dialog: {
      marginLeft: spacing[2],
      marginRight: spacing[2],
    },
    switchContainer: {
      marginTop: 0,
      paddingBottom: spacing[4],
      borderBottomColor: theme.colors.menuBorder,
      borderBottomWidth: 1,
      flex: 0,
      minHeight: 46,
    },
    labelSwitch: {
      ...theme.fonts.medium,
      color: theme.colors.text,
      fontSize: 16,
    },
    errorLabel: {
      color: theme.colors.error,
      marginTop: spacing[2],
      textAlign: "center",
    },
  });

type Props = {
  onOk: (seconds?: number) => void;
  onCancel: () => void;
  visible?: boolean;
  durationProps?: TimeProps;
};

export const DurationSelectorDialog: React.FunctionComponent<Props> = ({
  visible,
  onOk,
  onCancel,
  durationProps = DEFAULT_TIME_PROPS,
}) => {
  const theme = useTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);

  const [duration, setDuration] = useState<TimeProps>(durationProps);
  const [error, setError] = useState<string>(null);
  const [isWorkTime, setIsWorkTime] = useState<boolean>(true);

  const onChangeDuration = (value: TimeProps) => {
    setDuration(value);
    setError(null);
  };
  const onToggleWorkTimeHandler = () => {
    setIsWorkTime(!isWorkTime);
    setError(null);
  };

  const startHandler = () => {
    const seconds = timePropsToSeconds(duration);

    if (seconds >= MIN_DURATION_SEC) {
      onOk(seconds);
    } else {
      setError(
        translate("Devices.Details.runDeviceError", {
          seconds: MIN_DURATION_SEC,
        }),
      );
    }
  };

  const startContinuouslyHandler = () => {
    onOk();
  };

  return (
    <Dialog
      visible={visible}
      okText={translate("Devices.Details.runDevice")}
      onOk={isWorkTime ? startHandler : startContinuouslyHandler}
      onCancel={onCancel}
      onDismiss={onCancel}
      style={styles.dialog}
      title={
        <SwitchInput
          txLabel="Devices.Details.workTime"
          labelStyle={styles.labelSwitch}
          containerStyle={styles.switchContainer}
          onChange={onToggleWorkTimeHandler}
          value={isWorkTime}
        />
      }
    >
      <View>
        {error ? <Text style={styles.errorLabel}>{error}</Text> : null}
        {isWorkTime ? (
          <TimerInput value={duration} onChange={onChangeDuration} />
        ) : (
          <ErrorsMessage
            errors={[translate("Devices.Details.messageRunContinuous")]}
          />
        )}
      </View>
    </Dialog>
  );
};
