import * as React from "react";
import Svg, { G, Path } from "react-native-svg";
import { palette } from "../theme";

export default function VideoIcon({ color = palette.Blue, size = 21 }) {
  return (
    <Svg viewBox="0 0 21 21" width={size} height={size} fill="none">
      <G stroke={color} strokeLinecap="round" strokeLinejoin="round">
        <Path d="M1.769 7.167a3.333 3.333 0 0 1 3.333-3.334h10a3.333 3.333 0 0 1 3.334 3.334v6.666a3.333 3.333 0 0 1-3.334 3.334h-10a3.333 3.333 0 0 1-3.333-3.334V7.167Z" />
        <Path d="m8.436 8 4.166 2.5L8.436 13V8Z" />
      </G>
    </Svg>
  );
}
