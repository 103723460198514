import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { palette } from "../../theme";

export default function DehumidifierIcon({ color = palette.Gray, size = 24 }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 36 36" fill="none">
      <Path
        d="M14.25 11.2495H18.6632C19.2259 11.2601 19.7804 11.1153 20.2646 10.8312C20.7489 10.5472 21.1436 10.1351 21.4046 9.64125C21.6656 9.14739 21.7825 8.59128 21.7422 8.03529C21.702 7.4793 21.5061 6.94542 21.1766 6.49353C20.8471 6.04164 20.397 5.68962 19.8767 5.47694C19.3565 5.26426 18.7868 5.19935 18.2314 5.28947C17.676 5.3796 17.157 5.6212 16.7325 5.98718C16.308 6.35316 15.9948 6.82904 15.8281 7.36145"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M24.2402 22.8587C24.4466 23.5242 24.8343 24.1191 25.3599 24.5766C25.8854 25.0341 26.528 25.3361 27.2156 25.4487C27.9033 25.5614 28.6086 25.4802 29.2527 25.2144C29.8968 24.9485 30.454 24.5085 30.862 23.9436C31.27 23.3788 31.5125 22.7114 31.5623 22.0164C31.6122 21.3214 31.4675 20.6263 31.1443 20.009C30.8212 19.3917 30.3325 18.8766 29.733 18.5215C29.1334 18.1664 28.4469 17.9854 27.7502 17.9987H21.75"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M13.5 18.1774L10.9297 14.1426L5.91546 22.0096H5.91642C5.40931 22.9066 5.1426 23.9239 5.14307 24.9593C5.14329 25.4546 5.20466 25.9458 5.32423 26.4215M16.5654 23.626C16.6643 24.0614 16.7147 24.5086 16.7145 24.9593C16.714 25.9947 16.4464 27.0118 15.9385 27.9083C15.4305 28.8048 14.7002 29.5493 13.8209 30.0668C12.9415 30.5844 11.9441 30.8569 10.9288 30.8569C10.4013 30.8569 9.87874 30.7833 9.375 30.6401"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M30.375 5.625L3.75 32.25"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
