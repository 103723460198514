import { flow, Instance, types } from "mobx-state-tree";
import { values } from "mobx";
import * as semver from "semver";

import { withRootStore } from "@models/extensions/with-root-store";
import { withStatus } from "@models/extensions/with-status";
import { withEnvironment } from "@models/extensions/with-environment";
import {
  OfflineTestType,
  withTestModuleConnection,
} from "@models/extensions/with-test-module-connection";
import { SENSOR_ICONS } from "@models/sensor/constants";
import {
  DeviceMode,
  DeviceState,
  getIconByDeviceType,
  NOT_CONNECTED_TYPE,
} from "@models/device/device";

import { ModuleCommands, EspApi, ModuleApi } from "../../services/api";
import { translate } from "../../i18n";
import { delay } from "../../utils/delay";
import { generateId } from "../../utils/generateId";
import { duplicateNameValidator } from "../../utils/validator";
import { ModuleUpdateModel, UpdateStatus } from "./module-update";
import { getLocation, TLocation } from "../../services/location";
import { rnLogger } from "../../services/logger";

export type ModuleSocket = {
  uid: string;
  pin?: string;
  name?: string;
  isFree?: boolean;
  displayName: string;
};

export enum ModuleType {
  DryContact = "drycontact",
  Sensor = "sensor",
  Socket = "socket",
  Hydro = "hydro",
  Dimmer = "dimmer",
}

export enum ModuleActivityType {
  Offline = "Offline",
  Online = "Online",
  PowerOn = "PowerOn",
  RebootTechnical = "RebootTechnical",
  RebootUser = "RebootUser",
  RebootSensorTypeChanged = "RebootSensorTypeChanged",
  FirmwareUpdate = "FirmwareUpdate",
}

export const MODULE_SSID_PREFIXES_BY_TYPE = {
  [ModuleType.DryContact]: ["DrycontactDirector-", "DryContactDirector_"],
  [ModuleType.Sensor]: ["SensorDirector-", "SensorDirector_"],
  [ModuleType.Socket]: ["SocketDirector-", "SocketDirector_"],
  [ModuleType.Hydro]: ["HydroDirector-", "HydroDirector_"],
  [ModuleType.Dimmer]: ["DimmerDirector-", "DimmerDirector_"],
  all: ["grow_director_"],
};

export const MODULE_SSID_PREFIXES = Object.values(
  MODULE_SSID_PREFIXES_BY_TYPE,
).flat();
export const MODULE_SSID_PASSWORD = "123456789";
const MODULE_REBOOT_TIMEOUT_MS = 60000;

const ModuleDeviceModel = types
  .model("ModuleDevice")
  .props({
    uid: types.string,
    name: types.maybeNull(types.string),
    type: types.string,
    typeName: types.maybeNull(types.string),
    connectedToPin: types.maybeNull(types.number),
    connectorType: types.string,
    connectorTypeName: types.string,
    mode: types.enumeration<DeviceMode>(
      "DeviceMode",
      Object.values(DeviceMode),
    ),
    state: types.maybeNull(types.string), // DeviceState
    // state: types.maybeNull(
    //   types.enumeration<DeviceState>("DeviceState", Object.values(DeviceState)),
    // ),
  })
  .views((self) => ({
    get isOn() {
      return self.state === DeviceState.On;
    },
    get displayName() {
      return self.name || self.typeName;
    },
    get iconName() {
      return getIconByDeviceType(self.type);
    },
    get socketName() {
      return `Socket ${self.connectedToPin}`;
    },
    get isConnected() {
      return self.type !== NOT_CONNECTED_TYPE;
    },
  }));

const ModuleSensorModel = types
  .model("ModuleSensor")
  .props({
    uid: types.string,
    name: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    typeName: types.maybeNull(types.string),
    connectedToPin: types.maybeNull(types.number),
    isConnected: types.optional(types.boolean, true),
  })
  .extend(withRootStore)
  .views((self) => ({
    get iconName() {
      return SENSOR_ICONS[self.type];
    },
    get unitName() {
      return self.rootStore.sensorStore?.getSensorType(self.type)?.unit;
    },
    get displayName() {
      if (self.name && self.name.length > 10) return self.name;
      return self.name ? `${self.typeName} (${self.name})` : self.typeName;
    },
    get pinName() {
      return `Pin ${self.connectedToPin || "-"}`;
    },
    get displayType() {
      return (
        self.rootStore.sensorStore?.getSensorType(self.type)?.displayName ||
        self.typeName ||
        "Undefined"
      );
    },
  }));

const ModuleOfflineInfoModel = types.model("ModuleOfflineInfo").props({
  online: types.boolean,
  onlineAt: types.maybeNull(types.string),
  rssi: types.maybeNull(types.number),
  signalLevel: types.maybeNull(types.number),
});

const PinModel = types.model("Pin").props({
  pin: types.maybeNull(types.number),
  isConnected: types.boolean,
  sensors: types.optional(types.array(ModuleSensorModel), []),
});

export const ModuleModel = types
  .model("Module")
  .props({
    uid: types.identifier,
    type: types.maybeNull(types.string),
    createdDate: types.maybeNull(types.number),
    // Update for featch modules
    firmware: types.maybeNull(types.string),
    ip: types.maybeNull(types.string),
    fileSystemStatus: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    onlineInfo: types.maybe(ModuleOfflineInfoModel),
    ssid: types.maybeNull(types.string),
    //  Update for featch module
    devices: types.optional(types.array(ModuleDeviceModel), []),
    pins: types.optional(types.array(PinModel), []),

    offlineTestError: types.optional(types.string, ""),
    update: types.optional(ModuleUpdateModel, {}),
  })
  .extend(withEnvironment)
  .extend(withStatus)
  .extend(withRootStore)
  .extend(withTestModuleConnection)
  .views((self) => ({
    get settings() {
      return self.rootStore.settingsStore;
    },

    get sensors() {
      return self.pins
        .reduce((acc, pin) => {
          const sensors = pin?.isConnected
            ? pin.sensors.filter((sensor) => sensor.type !== "none")
            : [
                ModuleSensorModel.create({
                  uid: generateId(),
                  isConnected: false,
                  connectedToPin: pin.pin,
                }),
              ];
          return [...acc, ...sensors];
        }, [])
        .sort((a, b) => a.connectedToPin - b.connectedToPin);
    },
  }))
  .actions((self) => {
    const moduleApi = new ModuleApi(self.environment.api);
    // const moduleConnectionApi = new ModuleConnectionApi();

    const updateName = flow(function* (rawName: string) {
      const name = rawName.trim();

      const nameError = duplicateNameValidator(
        name,
        self.rootStore.moduleStore.modulesNames,
      );

      if (nameError) {
        return { error: nameError };
      }

      self.setStatusPending(translate("Modules.loadingText.renamingModule"));
      const result = yield moduleApi.update(self.uid, { name });

      if (result.kind === "ok") {
        self.setStatusDone();
        self.name = name;
        return result;
      }

      self.setStatusError();
      const [error] = result.errors;
      return { error };
    });

    const updateLocation = flow(function* (location?: TLocation) {
      let lat;
      let lng;

      if (location?.lat && location?.lng) {
        lat = location.lat;
        lng = location.lng;
      } else {
        const resultLocation = yield getLocation();
        lat = resultLocation.location.lat;
        lng = resultLocation.location.lng;
      }

      if (!lat && !lat) {
        rnLogger.error("No coordinates", {
          action: "module_update_location",
        });
        return { kind: "error", errors: ["No coordinates"] };
      }

      self.setStatusPending();
      const result = yield moduleApi.update(self.uid, { lat, lng });

      if (result.kind === "ok") {
        self.setStatusDone();
      } else {
        self.setStatusError();
      }

      return result;
    });

    const testConnection = flow(function* () {
      try {
        self.setOfflineTestType(OfflineTestType.Module);
        // TODO refactor
        // let result = yield moduleApi.checkConnectionToModule();

        self.setOfflineTestType(OfflineTestType.Router);
        yield moduleApi.checkConnectionToModule();

        self.setOfflineTestType(OfflineTestType.Internet);
        yield moduleApi.checkConnectionToModule();
        // result = yield moduleConnectionApi.test();
        // if (result) {
        //   self.setStatusDone();
        //   return true;
        // }

        self.setOfflineTestType(OfflineTestType.Cloud);
        // result = yield moduleApi.getModule(self.uid);
        self.setOfflineTestType(OfflineTestType.Ok);
      } catch (e) {
        self.setOfflineTestType(OfflineTestType.Fail);
        self.offlineTestError = e.message;
      }
    });

    const removeDevicesAndSensorsFromUserSettings = () => {
      const devicesUids = self.devices.map((device) => device.uid);
      self.settings.userSettings.deleteDevicesSettings(devicesUids);

      const sensorsUids = self.sensors.map((sensor) => sensor.uid);
      self.settings.userSettings.deleteSensorsSettings(sensorsUids);
    };

    const remove = flow(function* () {
      self.setStatusPending(translate("Modules.loadingText.deletingModule"));
      const result = yield moduleApi.remove(self.uid);
      const isOk = result.kind === "ok";

      if (isOk) {
        removeDevicesAndSensorsFromUserSettings();
        self.rootStore.deviceStore.fetchDevices({ force: true });
        self.rootStore.deviceStore.fetchDevicesViews({ force: true });
        self.rootStore.sensorStore.fetchSensors({ force: true });

        self.setStatusDone();
        self.settings.userSettings.removeDisclaimerFor(self.uid);
      } else {
        self.setStatusError(result.errors);
        if (__DEV__) console.log(result.kind);
      }

      return isOk;
    });

    const checkPossibilityUpdating = flow(function* (
      ms: number,
      count: number,
    ) {
      /* eslint-disable no-plusplus */
      for (let index = 0; index < count; index++) {
        yield delay(ms);

        const isSuccess = yield self.update.execute();
        if (isSuccess) return true;

        if (count - 1 === index) {
          self.update.setError(
            translate("Modules.update.errors.noAutoDisconnectDevices"),
          );
        }
      }
      /* eslint-enable no-plusplus */
      return false;
    });

    const disconnectDevices = () => {
      self.update.setStatus(UpdateStatus.Processing);

      const devicesOn = values(self.rootStore.deviceStore.devices).filter(
        (device) =>
          device.moduleUid === self.uid && device.state === DeviceState.On,
      );

      if (devicesOn.length > 0) {
        devicesOn.forEach((device) => device.switchOff());
      } else {
        self.update.setError(
          translate("Modules.update.errors.noAutoDisconnectDevices"),
        );
        return false;
      }

      return checkPossibilityUpdating(1000, 5);
    };

    const connectionTest = flow(function* () {
      const espApi = new EspApi(self.ip);
      const connectResult = yield espApi.test();
      return connectResult.ok;
    });

    const canUpdate = flow(function* () {
      const result = yield moduleApi.canUpdate(self.uid);
      return Boolean(result.data?.can_update);
    });

    const acceptDisclaimer = () => {
      const key = self.type === ModuleType.Hydro ? self.uid : self.type;
      self.settings.userSettings.setDisclaimerFor(key);
    };

    const findModuleStart = flow(function* () {
      const result = yield moduleApi.sendCommand(
        self.uid,
        ModuleCommands.BlinkingStart,
      );
      const isOk = result.kind === "ok";

      if (!isOk) {
        self.setStatusError(result.errors);
      }
      return isOk;
    });

    const findModuleStop = flow(function* () {
      const result = yield moduleApi.sendCommand(
        self.uid,
        ModuleCommands.BlinkingStop,
      );
      const isOk = result.kind === "ok";

      if (!isOk) {
        self.setStatusError(result.errors);
      }
      return isOk;
    });

    const updateData = (data: Module) => {
      self.firmware = data.firmware;
      self.ip = data.ip;
      self.name = data.name;
      self.onlineInfo = data.onlineInfo;
      self.ssid = data.ssid;
    };

    let rebootTimestamp = null;

    const reboot = flow(function* () {
      if (Date.now() - rebootTimestamp < MODULE_REBOOT_TIMEOUT_MS) {
        return self.setStatusError([
          translate("Modules.Details.cannotBeRebootedTimeout"),
        ]);
      }
      if (!self?.onlineInfo?.online) {
        return self.setStatusError([
          translate("Modules.Details.cannotBeRebootedOffline"),
        ]);
      }

      rebootTimestamp = Date.now();
      self.setStatusPending();
      const result = yield moduleApi.sendCommand(
        self.uid,
        ModuleCommands.Reboot,
      );

      const isOk = result.kind === "ok";
      if (isOk) {
        self.setStatusDone();
      } else {
        self.setStatusError(result.errors);
      }

      return isOk;
    });

    return {
      findModuleStart,
      findModuleStop,
      updateName,
      updateLocation,
      remove,
      testConnection,
      disconnectDevices,
      connectionTest,
      canUpdate,
      acceptDisclaimer,
      updateData,
      reboot,
    };
  })
  .views((self) => ({
    get typeName() {
      const moduleType = self.rootStore.moduleStore.moduleTypes.get(self.type);
      return moduleType ? moduleType.displayName : self.type;
    },
    get displayName() {
      return self.name || "Unknown";
    },
    get isHydro() {
      return self.type === ModuleType.Hydro;
    },
    get isOnline() {
      return self?.onlineInfo?.online;
    },
    get isNew() {
      if (!self.createdDate) return false;
      return Date.now() - self.createdDate <= 24 * 3600 * 1000;
    },
    get hasFileSystem() {
      return self.fileSystemStatus === null || self.fileSystemStatus === "Done";
    },

    get sockets() {
      return values(self.rootStore.deviceStore.devices)
        .filter((device) => device.moduleUid === self.uid)
        .sort((a, b) => (a.connectedToPin > b.connectedToPin ? 1 : -1));
    },

    get moduleDevices() {
      const uids = self.devices.map((device) => device.uid);
      return self.rootStore.deviceStore.getDevices(uids);
    },

    get currentFirmwareVersion() {
      return semver.valid((self.firmware || "").replace("VER", "").trim());
    },

    get isUpdating() {
      return !!self.update.status;
    },

    get isDisclaimerAccepted(): boolean {
      if (self.type === ModuleType.DryContact) {
        return Boolean(self.settings.userSettings?.disclaimers?.get(self.type));
      }

      if (self.type === ModuleType.Hydro) {
        return Boolean(self.settings.userSettings?.disclaimers?.get(self.uid));
      }
      return true;
    },
  }))
  .views((self) => ({
    get freeSockets() {
      return self.sockets.filter((socket) => !socket.isConnected);
    },

    get isFirmwareUpdateAvailable() {
      // Unfortunately we have a problem with modules that are in Malaysia. They should not be allowed to update to the new firmware
      // if (self.firmware === "VER 0.0.37") return false;

      const latestFirmwareVersion = self.settings.latestFWVersion;
      if (!latestFirmwareVersion || !self.currentFirmwareVersion) return false;

      return semver.gt(latestFirmwareVersion, self.currentFirmwareVersion);
    },

    get isFirmwareDowngradeAvailable() {
      const latestFirmwareVersion = self.settings.latestFWVersion;
      if (!latestFirmwareVersion || !self.currentFirmwareVersion) return false;

      return semver.lt(latestFirmwareVersion, self.currentFirmwareVersion);
    },
  }));

export const ModuleTypeModel = types.model("ModuleType").props({
  id: types.number,
  name: types.identifier,
  displayName: types.string,
});

type PinModelMOBXType = Instance<typeof PinModel>;
export type PinModel = PinModelMOBXType;

type ModuleSensorMOBXType = Instance<typeof ModuleSensorModel>;
export type ModuleSensor = ModuleSensorMOBXType;

type ModuleDeviceMOBXType = Instance<typeof ModuleDeviceModel>;
export type ModuleDevice = ModuleDeviceMOBXType;

type ModuleMOBXType = Instance<typeof ModuleModel>;
export type Module = ModuleMOBXType;

type ModuleTypeMOBXType = Instance<typeof ModuleTypeModel>;
export type ModuleTypeType = ModuleTypeMOBXType;
