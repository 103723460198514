import React, { useState } from "react";
import { StyleSheet } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";

import { Dialog, Text, ProgressBar } from "@components/ui";
import { Module } from "@models/module/module";
import { ConfirmDialog, ConfirmType } from "@components/dialog";
import { ModulesNavigatorParamList } from "@screens/Modules/navigation";

import { ModuleFeatureError } from "@screens/Modules/components";
import { translate } from "../../../../i18n";
import { defaultColors, spacing } from "../../../../theme";
import {
  ModuleFeature,
  useCheckingModuleFeatureAvailability,
} from "../../../../hooks";

const styles = StyleSheet.create({
  description: {
    marginTop: spacing[4],
    marginBottom: spacing[6],
    color: defaultColors.label,
  },
});

type FindModuleProps = {
  module: Module;
  visible: boolean;
  onClose: () => void;
};

export const RebootModule = ({ module, visible, onClose }: FindModuleProps) => {
  const [rebootingDialog, setRebootingDialog] = useState(false);
  const hideRebootingDialog = () => setRebootingDialog(false);

  const rebootHandler = async () => {
    onClose();
    const isOk = await module.reboot();
    if (isOk) {
      setRebootingDialog(true);
    }
  };

  const {
    availability,
    message: availabilityError,
    isUpdateAvailable,
  } = useCheckingModuleFeatureAvailability(
    visible ? module.uid : null,
    ModuleFeature.ModuleCommands,
  );

  const navigation =
    useNavigation() as StackNavigationProp<ModulesNavigatorParamList>;

  const goToUpdate = () => {
    onClose();
    navigation.navigate("module-info", { uid: module.uid });
  };

  return (
    <>
      {availability ? (
        <ConfirmDialog
          content={translate("Modules.Details.rebootModuleConfirm")}
          okText={translate("common.reboot")}
          type={ConfirmType.Warning}
          onOk={rebootHandler}
          onCancel={onClose}
          visible={visible}
        />
      ) : (
        <Dialog
          title={translate("Modules.Details.rebootModule")}
          visible={visible}
          onDismiss={onClose}
          onOk={onClose}
          okText={translate("common.ok")}
          onCancel={isUpdateAvailable ? goToUpdate : undefined}
          cancelType="primary"
          cancelText={translate("common.update")}
        >
          <ModuleFeatureError
            moduleUid={module.uid}
            message={availabilityError}
            isUpdateAvailable={false}
          />
        </Dialog>
      )}

      <Dialog
        title={translate("Modules.Details.rebootModule")}
        visible={rebootingDialog}
        onDismiss={hideRebootingDialog}
        onOk={hideRebootingDialog}
        okText={translate("common.ok")}
      >
        <Text style={styles.description}>
          {translate("Modules.Details.rebootModuleDialog")}
        </Text>
        <ProgressBar />
      </Dialog>
    </>
  );
};
