import React, { useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { observer } from "mobx-react-lite";

import { useStores } from "@models/index";
import { LayoutCenter } from "@components/layout";
import { NoModules } from "@components/common";
import {
  HeadingWithActions,
  Loading,
  ScreenMessage,
  HeaderAction,
} from "@components/ui";

import { NoAddedDevices } from "../../../../svgs/pictures";
import { translate } from "../../../../i18n";
import { spacing } from "../../../../theme";

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    marginHorizontal: spacing[7],
    marginBottom: spacing[5],
  },
});

function NoDevices({ headerActions }) {
  return (
    <View style={{ flex: 1 }}>
      <HeadingWithActions tx="Devices.List.title" actions={headerActions} />
      <LayoutCenter style={styles.container}>
        <NoAddedDevices />
        <ScreenMessage
          style={{ marginTop: 24 }}
          title={translate("NoData.noDevices")}
          message={translate("NoData.noDevicesDescription")}
        />
      </LayoutCenter>
    </View>
  );
}

const DevicesNoData = observer(
  ({ headerActions }: { headerActions: HeaderAction[] }) => {
    const { moduleStore } = useStores();

    useEffect(() => {
      moduleStore.fetchModules({});
    }, [moduleStore]);

    if (!moduleStore.isLoaded) return <Loading />;

    return moduleStore.modules.length ? (
      <NoDevices headerActions={headerActions} />
    ) : (
      <NoModules
        heading={translate("Devices.List.title")}
        description={translate("NoData.devicesNoModulesDescription")}
      />
    );
  },
);

export default DevicesNoData;
