import React, { useMemo, useState } from "react";
import { StyleSheet, View } from "react-native";
import { useTheme } from "@react-navigation/native";

import { Text, SliderRange, Drawer } from "@components/ui";
import {
  DEFAULT_TIME_PROPS,
  SwitchInput,
  TimerInput,
} from "@components/inputs";
import { MIN_DURATION_SEC } from "@models/rule/rule";

import { translate } from "../../../i18n";
import { spacing } from "../../../theme";
import { AttentionIcon } from "../../../svgs";
import { TimeProps, timePropsToSeconds } from "../../../utils/timeConverter";

const createStyles = (theme: any) =>
  StyleSheet.create({
    errorLabel: {
      color: theme.colors.error,
      marginTop: spacing[2],
      textAlign: "center",
    },
    notification: {
      backgroundColor: theme.colors.errorBG,
      borderRadius: spacing[4],
      flexDirection: "row",
      padding: spacing[4],
      alignItems: "center",
      marginTop: spacing[2],
    },
    notificationText: {
      marginLeft: spacing[3],
      padding: spacing[1],
    },
    switchContainer: {
      marginVertical: spacing[4],
    },
    sliderRangeContainer: {
      height: 90,
      backgroundColor: theme.colors.card,
      justifyContent: "space-around",
    },
  });

type Props = {
  onOk: (seconds?: number, pinValue?: number) => void;
  onCancel: () => void;
  visible?: boolean;
  durationProps?: TimeProps;
};

export const DurationDimmerSelectorDialog: React.FunctionComponent<Props> = ({
  visible,
  onOk,
  onCancel,
  durationProps = DEFAULT_TIME_PROPS,
}) => {
  const theme = useTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);

  const [duration, setDuration] = useState<TimeProps>(durationProps);
  const [error, setError] = useState<string>(null);
  const [valueRange, setValueRange] = useState(70);
  const [isActiveSwitcher, setIsActiveSwitcher] = useState(true);

  const switchOnHandler = () => {
    setIsActiveSwitcher((prevState) => !prevState);
  };

  const onChangeDuration = (value: TimeProps) => {
    setDuration(value);
    setError(null);
  };

  const startHandler = () => {
    const seconds = timePropsToSeconds(duration);

    if (seconds >= MIN_DURATION_SEC) {
      onOk(seconds, valueRange);
    } else {
      setError(
        translate("Devices.Details.runDeviceError", {
          seconds: MIN_DURATION_SEC,
        }),
      );
    }
  };

  const startContinuouslyHandler = () => {
    onOk(null, valueRange);
  };

  return (
    <Drawer
      visible={visible}
      okText={translate("Devices.Details.runDevice")}
      onOk={isActiveSwitcher ? startHandler : startContinuouslyHandler}
      onDismiss={onCancel}
      style={{ height: "80%" }}
    >
      <View style={styles.sliderRangeContainer}>
        <Text tx="Devices.Details.dimming" />
        <SliderRange
          value={valueRange}
          minimumValue={10}
          maximumValue={110}
          onValueChange={setValueRange}
        />
      </View>

      <SwitchInput
        txLabel="Devices.Details.workTime"
        onChange={switchOnHandler}
        value={isActiveSwitcher}
        containerStyle={styles.switchContainer}
      />

      {isActiveSwitcher ? (
        <View>
          {error ? <Text style={styles.errorLabel}>{error}</Text> : null}
          <TimerInput value={duration} onChange={onChangeDuration} />
        </View>
      ) : (
        <View style={styles.notification}>
          <View>
            <AttentionIcon />
          </View>
          <Text style={styles.notificationText}>
            {translate("Devices.Details.messageRunContinuous")}
          </Text>
        </View>
      )}
    </Drawer>
  );
};
