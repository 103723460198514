import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Dimensions,
  ScrollView,
  StyleSheet,
  View,
  ViewStyle,
} from "react-native";
import { Dialog as PaperDialog, Portal } from "react-native-paper";

import { translate } from "../../i18n";
import { spacing } from "../../theme";
import { useAppTheme } from "../../hooks";
import { LayoutCenter } from "../layout/Center";
import { Button, ButtonType } from "./Button";
import { Text } from "./Text";

const createStyles = (theme: any) =>
  StyleSheet.create({
    dialog: {
      borderRadius: spacing[4],
    },
    title: {
      fontSize: 18,
    },
    customTitle: {
      paddingHorizontal: spacing[5],
      paddingTop: spacing[5],
      paddingBottom: spacing[2],
    },
    contentContainer: {
      maxHeight: Dimensions.get("window").height * 0.65,
    },
    content: {
      fontSize: 16,
      color: theme.colors.label,
    },

    actions: {
      borderTopColor: theme.colors.menuBorder,
      borderTopWidth: 1,
      justifyContent: "space-around",
      paddingVertical: spacing[4],
      paddingHorizontal: spacing[2],
    },
    button: {
      flex: 1,
      marginHorizontal: spacing[2],
      marginVertical: spacing[0],
    },
    image: {
      marginBottom: spacing[6],
    },
  });

export interface DialogProps {
  style?: ViewStyle;
  title?: React.ReactNode;
  content?: string;
  Image?: React.VoidFunctionComponent;
  visible?: boolean;
  onCancel?: any;
  onOk?: any;
  onDismiss?: any;
  okType?: ButtonType;
  cancelType?: ButtonType;
  okText?: string;
  cancelText?: string;
  children?: React.ReactNode;
  dismissable?: boolean;
  dismissableBackButton?: boolean;
}

type HookProps = { onOk?: any };
type HookResult = Pick<
  DialogProps,
  "visible" | "onCancel" | "onOk" | "onDismiss"
> & {
  showDialog: any;
};

function useDialog(props: HookProps = {}): HookResult {
  const [visible, setVisible] = useState(false);
  const showDialog = () => setVisible(true);
  const hideDialog = () => setVisible(false);
  const onOk = () => {
    props.onOk?.();
    setVisible(false);
  };

  return {
    showDialog,
    visible,
    onCancel: hideDialog,
    onOk,
    onDismiss: hideDialog,
  };
}

function Dialog(props: DialogProps) {
  const {
    style,
    title,
    content,
    Image,
    visible = false,
    onCancel,
    onOk,
    onDismiss,
    okType = "primary",
    cancelType = "ghost",
    okText = translate("common.ok"),
    cancelText = translate("common.cancel"),
    children = null,
    dismissable = true,
    dismissableBackButton = true,
  } = props;
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  const scrollViewRefs = useRef<ScrollView>(null);
  const scrollToTop = () => {
    scrollViewRefs.current?.scrollTo({
      x: 0,
      y: 0,
    });
  };

  useEffect(() => {
    scrollToTop();
  }, [content, children]);

  return (
    <Portal>
      <PaperDialog
        style={[styles.dialog, style]}
        visible={visible}
        onDismiss={onDismiss || onCancel}
        dismissable={dismissable}
        dismissableBackButton={dismissableBackButton}
      >
        {typeof title === "string" ? (
          <PaperDialog.Title style={styles.title}>{title}</PaperDialog.Title>
        ) : (
          <View style={styles.customTitle}>{title}</View>
        )}

        <ScrollView style={styles.contentContainer} ref={scrollViewRefs}>
          <PaperDialog.Content>
            {Image && (
              <LayoutCenter style={styles.image}>
                <Image />
              </LayoutCenter>
            )}
            {content ? <Text style={styles.content}>{content}</Text> : children}
          </PaperDialog.Content>
        </ScrollView>

        <PaperDialog.Actions style={styles.actions} theme={theme}>
          {onCancel ? (
            <Button type={cancelType} onPress={onCancel} style={styles.button}>
              {cancelText}
            </Button>
          ) : null}
          {onOk ? (
            <Button type={okType} onPress={onOk} style={styles.button}>
              {okText}
            </Button>
          ) : null}
        </PaperDialog.Actions>
      </PaperDialog>
    </Portal>
  );
}

export { useDialog, Dialog };
