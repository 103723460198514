import { translate } from "../../../../i18n";
import {
  CalibrationCleanPHSvg,
  CalibrationPH4ErrorSvg,
  CalibrationPH4ProcessSvg,
  CalibrationPH4Svg,
  CalibrationPH7ErrorSvg,
  CalibrationPH7ProcessSvg,
  CalibrationPH7Svg,
  CalibrationPreparePHSvg,
} from "../../../../svgs/pictures/calibration/PH";
import {
  CalibrationProcessStatus,
  CalibrationStep,
  TCalibrationStepProps,
} from "../types";

export const PH_STEPS = [
  CalibrationStep.PrepareInfo,
  CalibrationStep.Prepare,
  CalibrationStep.Clean,
  CalibrationStep.Process1,
  CalibrationStep.Clean,
  CalibrationStep.Process2,
  CalibrationStep.Success,
];

export const PH_STEPS_PROPS: TCalibrationStepProps = {
  [CalibrationStep.PrepareInfo]: {
    title: translate("Sensors.Calibration.PrepareInfo.title"),
    youtubeId: "3zmTsCbJe2s",
    items: [
      translate("Sensors.Calibration.PrepareInfo.item1"),
      translate("Sensors.Calibration.PrepareInfo.item2"),
    ],
  },

  [CalibrationStep.Prepare]: {
    Picture: CalibrationPreparePHSvg,
    items: [
      translate("Sensors.Calibration.Prepare.paperTowel"),
      translate("Sensors.Calibration.Prepare.distilledWater"),
      translate("Sensors.Calibration.ph.pHCalibrationLiquids"),
    ],
    youtubeId: "3zmTsCbJe2s",
    submitLabel: translate("common.next"),
  },

  [CalibrationStep.Clean]: {
    Picture: CalibrationCleanPHSvg,
    items: [
      translate("Sensors.Calibration.Clean.stirSensorInCleanWater"),
      translate("Sensors.Calibration.ph.boldItUpWithPaperTowel"),
    ],
    youtubeId: "3zmTsCbJe2s",
    alert: translate("Sensors.Calibration.ph.dontTouchSensorHead"),
  },

  [CalibrationStep.Process1]: {
    Picture: CalibrationPH4Svg,
    title: translate("Sensors.Calibration.ph.pH4Calibration"),
    items: [
      translate("Sensors.Calibration.ph.putSensorIntoPH4Liquid"),
      translate("Sensors.Calibration.ph.pressCalibratePh4"),
    ],
    youtubeId: "3zmTsCbJe2s",
    submitLabel: translate("Sensors.Calibration.ph.CalibratePH4"),
  },

  [`${CalibrationStep.Process1}${CalibrationProcessStatus.Process}`]: {
    Picture: CalibrationPH4ProcessSvg,
    title: translate("Sensors.Calibration.ph.pH4Calibration"),
    alert: translate("Sensors.Calibration.dontTouchSensor"),
  },

  [`${CalibrationStep.Process1}${CalibrationProcessStatus.Error}`]: {
    Picture: CalibrationPH4ErrorSvg,
    title: translate("Sensors.Calibration.ph.pH4Calibration"),
  },

  [CalibrationStep.Process2]: {
    Picture: CalibrationPH7Svg,
    title: translate("Sensors.Calibration.ph.pH7Calibration"),
    items: [
      translate("Sensors.Calibration.ph.putSensorIntoPH7Liquid"),
      translate("Sensors.Calibration.ph.pressCalibratePh7"),
    ],
    youtubeId: "3zmTsCbJe2s",
    submitLabel: translate("Sensors.Calibration.ph.CalibratePH7"),
  },

  [`${CalibrationStep.Process2}${CalibrationProcessStatus.Process}`]: {
    Picture: CalibrationPH7ProcessSvg,
    title: translate("Sensors.Calibration.ph.pH7Calibration"),
    alert: translate("Sensors.Calibration.dontTouchSensor"),
  },

  [`${CalibrationStep.Process2}${CalibrationProcessStatus.Error}`]: {
    Picture: CalibrationPH7ErrorSvg,
    title: translate("Sensors.Calibration.ph.pH7Calibration"),
  },

  [CalibrationStep.Error]: {
    Picture: CalibrationPH7ErrorSvg,
  },
};
