import * as React from "react";
import Svg, { SvgProps, Path, Rect } from "react-native-svg";

export const CalibrationSuccessSvg = (props: SvgProps) => (
  <Svg width={287} height={172} fill="none" {...props}>
    <Path
      fill="#FBE3E3"
      d="M215.945 170.086H64.325c-17.23 0-27.9-18.82-19.06-33.651l74.39-124.845c9.2-15.443 31.51-15.453 40.73-.03l74.6 124.844c8.87 14.832-1.79 33.682-19.04 33.682Z"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M262.977 140.112c-6.793 0-12.3-5.516-12.3-12.319"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M263.09 131.875c-3.867-1.055-7.934 1.256-9.02 5.12a7.469 7.469 0 0 0 4.872 9.131c1.487.483 3.015.676 4.566.636 3.168-.081 6.191-.854 9.117-2.029.362-.145.458-.354.322-.725-.274-.74-.531-1.489-.844-2.214-1.005-2.359-2.251-4.565-3.956-6.497-1.077-1.224-2.315-2.263-3.786-2.996-.418-.144-.836-.305-1.271-.426ZM273.935 118.163c-6.793 0-12.3-5.515-12.3-12.318"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M274.056 109.927c-3.867-1.055-7.935 1.256-9.021 5.121a7.469 7.469 0 0 0 4.872 9.13c1.488.483 3.015.676 4.567.636 3.167-.08 6.19-.853 9.116-2.029.362-.145.459-.354.322-.724-.273-.741-.531-1.49-.844-2.215-1.005-2.359-2.251-4.565-3.956-6.497-1.077-1.224-2.315-2.263-3.786-2.995-.426-.145-.844-.306-1.27-.427ZM192.452 88.76h48.466c11.553 0 20.927 9.38 20.927 20.958 0 11.489-9.23 20.821-20.67 20.95h-1.583"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M243.177 106.925c6.793 0 12.3-5.515 12.3-12.319"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M241.794 99.124c-1.479.732-2.709 1.771-3.787 2.995-1.704 1.932-2.95 4.138-3.955 6.497-.306.725-.571 1.474-.844 2.214-.137.371-.04.58.321.725 2.927 1.167 5.95 1.948 9.117 2.029 1.56.04 3.079-.153 4.567-.636a7.464 7.464 0 0 0 4.871-9.13c-1.085-3.857-5.153-6.176-9.02-5.121-.434.12-.852.282-1.27.427ZM248.567 76.1c-6.793 0-12.3 5.516-12.3 12.32"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M249.95 83.903c1.479-.733 2.709-1.772 3.786-2.996 1.705-1.932 2.951-4.138 3.956-6.497.305-.725.571-1.474.844-2.214.137-.37.04-.58-.322-.725-2.926-1.167-5.949-1.948-9.116-2.029-1.56-.04-3.079.153-4.567.636a7.465 7.465 0 0 0-4.872 9.13c1.086 3.857 5.154 6.176 9.021 5.121.434-.12.852-.281 1.27-.426ZM28.16 38.441c6.793 0 12.3 5.516 12.3 12.32"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M26.77 46.251c-1.48-.732-2.71-1.771-3.787-2.995-1.704-1.932-2.95-4.138-3.956-6.497-.305-.725-.57-1.474-.844-2.215-.136-.37-.04-.58.322-.724 2.926-1.168 5.95-1.949 9.117-2.03 1.56-.04 3.079.154 4.566.637a7.464 7.464 0 0 1 4.872 9.13c-1.085 3.857-5.153 6.176-9.02 5.121-.426-.12-.845-.29-1.27-.427ZM34.584 77.92c6.793 0 12.3-5.515 12.3-12.318"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M34.471 69.692c3.867-1.054 7.935 1.256 9.02 5.121a7.468 7.468 0 0 1-4.872 9.13c-1.487.484-3.014.677-4.566.636-3.168-.08-6.19-.853-9.117-2.028-.362-.145-.458-.355-.321-.725.273-.74.53-1.49.844-2.214 1.005-2.36 2.25-4.565 3.955-6.498 1.077-1.224 2.316-2.262 3.787-2.995.418-.145.836-.314 1.27-.427ZM57.093 84.361c6.794 0 12.3-5.515 12.3-12.318"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M56.98 76.132c3.867-1.054 7.935 1.256 9.02 5.121a7.468 7.468 0 0 1-4.871 9.13c-1.487.484-3.015.677-4.567.636-3.167-.08-6.19-.853-9.116-2.029-.362-.144-.459-.354-.322-.724.273-.74.53-1.49.844-2.214 1.005-2.36 2.251-4.566 3.956-6.498C53 78.33 54.239 77.292 55.71 76.56c.418-.145.836-.314 1.27-.427ZM75.445 41.689c0-6.804-5.507-12.319-12.3-12.319"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M67.229 41.802c-1.053-3.873 1.254-7.947 5.113-9.034a7.453 7.453 0 0 1 9.117 4.88c.482 1.489.675 3.018.635 4.572-.08 3.173-.852 6.2-2.026 9.13-.145.363-.354.46-.724.323-.74-.274-1.487-.532-2.21-.846-2.356-1.006-4.559-2.254-6.488-3.96-1.222-1.08-2.26-2.32-2.991-3.793-.145-.419-.305-.837-.426-1.272ZM54.98 12.979c-4.807 4.814-4.807 12.616 0 17.423"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M60.873 18.719c-1.994 3.486-6.504 4.734-9.993 2.77a7.463 7.463 0 0 1-2.999-9.904c.708-1.393 1.648-2.608 2.782-3.687 2.299-2.19 4.976-3.776 7.878-5.024.354-.153.571-.073.74.281.33.717.675 1.426.965 2.158.956 2.375 1.632 4.823 1.792 7.391.105 1.635-.04 3.237-.562 4.807-.201.403-.378.821-.603 1.208ZM87.943 12.174c-4.808 4.814-4.808 12.616 0 17.423"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M93.835 17.914c-1.993 3.486-6.503 4.734-9.992 2.77a7.463 7.463 0 0 1-3-9.903c.708-1.393 1.649-2.61 2.782-3.688 2.3-2.19 4.977-3.776 7.879-5.024.354-.153.57-.073.74.282.33.716.675 1.425.964 2.158.957 2.375 1.632 4.822 1.793 7.39.105 1.635-.04 3.238-.563 4.807-.2.403-.377.822-.603 1.208ZM110.027 29.37H61.561c-11.553 0-20.926 9.38-20.926 20.958 0 11.49 9.229 20.821 20.669 20.95h29.175"
    />
    <Path
      fill="#B0EACC"
      d="M215.979 170.086H64.359c-17.23 0-27.9-18.808-19.06-33.63l74.39-124.767c9.2-15.433 31.51-15.443 40.73-.03l74.6 124.766c8.87 14.823-1.79 33.661-19.04 33.661ZM21.823 102.259h25.23c2.87 0 4.64 3.135 3.17 5.599l-12.38 20.761c-1.53 2.564-5.24 2.574-6.78 0l-12.41-20.761c-1.47-2.464.3-5.599 3.17-5.599Z"
    />
    <Path
      fill="#3F9A6E"
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.261}
      d="M195.691 127.496h-41.848a.596.596 0 0 1-.594-.591v-7.268c0-.324.269-.592.594-.592h41.848c.325 0 .594.268.594.592v7.268a.596.596 0 0 1-.594.591Z"
    />
    <Path
      fill="#DBE2EA"
      d="M197.687 132.301h-47.893a2.755 2.755 0 0 1-2.76-2.76V28.646a2.755 2.755 0 0 1 2.76-2.76h47.893a2.755 2.755 0 0 1 2.76 2.76v100.895a2.763 2.763 0 0 1-2.76 2.76Z"
    />
    <Path
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M205.263 40.48V28.519a7.431 7.431 0 0 0-7.432-7.432h-48.275a7.431 7.431 0 0 0-7.432 7.432v101.136a7.431 7.431 0 0 0 7.432 7.432h48.275a7.431 7.431 0 0 0 7.432-7.432V59.422M176.028 42.534 151.834 67.45M159.792 43.129l-7.036 7.361"
    />
    <Path
      fill="#3F9A6E"
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M194.631 127.106H152.77a.598.598 0 0 1-.595-.595v-7.305c0-.325.269-.594.595-.594h41.861c.326 0 .595.269.595.594v7.305a.598.598 0 0 1-.595.595Z"
    />
    <Rect
      width={18}
      height={4}
      x={165.124}
      y={26.086}
      fill="#2B2E32"
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      rx={2}
    />
    <Path
      fill="#fff"
      stroke="#2B2E32"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.196}
      d="M141.959 161.018c22.553 0 40.835-18.31 40.835-40.896 0-22.587-18.282-40.896-40.835-40.896s-40.835 18.31-40.835 40.896c0 22.586 18.282 40.896 40.835 40.896Z"
    />
    <Path
      fill="#3F9A6E"
      d="m154.696 108.527-13.073 13.093-13.271-13.291c-3.28-3.285-8.619-3.285-11.898 0-3.28 3.284-3.28 8.631 0 11.916l19.327 19.34a8.266 8.266 0 0 0 11.684 0l34.2-34.251c3.279-3.284 3.279-8.631 0-11.916-3.28-3.284-8.619-3.284-11.898 0l-4.134 4.14"
    />
  </Svg>
);
