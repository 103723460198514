import React, { useMemo, useState } from "react";
import { StyleSheet, View } from "react-native";
import { observer } from "mobx-react-lite";

import { Sensor, SensorState } from "@models/sensor/sensor";
import { HistoryHeader } from "@components/common";
import { Loading } from "@components/ui";
import { ChartLineData, LineChart } from "@components/Chart";

import { palette, spacing } from "../../../../theme";
import { useSensorHistory, useSensorCalibrations } from "../../../../hooks";
import { TPeriod } from "../../../../utils/timeConverter";
import { displayNumber } from "../../../../utils/display";
import {
  TSensorCalibration,
  TSensorHistoryItem,
} from "../../../../services/api";
import { sortDataBy } from "../../../../utils/sortDataBy";

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: spacing[4],
    marginBottom: spacing[1],
  },
  historyHeaderContainer: {
    backgroundColor: palette.BlueBackground,
    borderRadius: spacing[4],
  },
  chart: {
    paddingHorizontal: spacing[2],
    paddingVertical: spacing[4],
  },
});

const mergeData = (
  sensorHistory: TSensorHistoryItem[],
  calibrations: TSensorCalibration[],
) => {
  if (!sensorHistory || !calibrations) return [];

  const calibrationPoints = calibrations
    .map((calibration) => {
      const item = sensorHistory.find(
        (itemHistory) => itemHistory.dateTime >= calibration.stopTime,
      );
      return item
        ? {
            dateTime: calibration.stopTime,
            value: item.value,
            state: SensorState.Calibration,
          }
        : null;
    })
    .filter(Boolean);

  return sortDataBy([...sensorHistory, ...calibrationPoints], "dateTime");
};

type SensorHistoryProps = {
  sensor: Sensor;
  chartHeight?: number;
};

export const SensorHistory = observer(
  ({ sensor, chartHeight = 250 }: SensorHistoryProps) => {
    const [period, setPeriod] = useState<TPeriod>({} as TPeriod);
    const [currentIndex, setCurrentIndex] = useState<number>(0);

    const {
      sensorHistory,
      isLoading: isLoadingSensorHistory,
      errors: errorsSensorHistory,
    } = useSensorHistory({
      uid: sensor.uid,
      startDate: period.startDate,
      endDate: period.endDate,
      limit: 150,
      transformValues: sensor.transformValues,
    });

    const {
      calibrations,
      isLoading: isLoadingCalibrations,
      errors: errorsCalibrations,
    } = useSensorCalibrations({
      uid: sensor.uid,
      startDate: period.startDate,
      endDate: period.endDate,
    });

    const isLoading = isLoadingSensorHistory || isLoadingCalibrations;
    const errors = errorsSensorHistory || errorsCalibrations;

    const data = useMemo(
      () => mergeData(sensorHistory, calibrations),
      [sensorHistory, calibrations],
    );

    const chartLines: ChartLineData[] = [
      {
        value: sensor.sensorRange.minValue,
        label: displayNumber(
          sensor.sensorRange.minValue,
          sensor.numberOfDecimals,
        ),
      },
      {
        value: sensor.sensorRange.maxValue,
        label: displayNumber(
          sensor.sensorRange.maxValue,
          sensor.numberOfDecimals,
        ),
      },
    ];

    const diff = sensor.sensorRange.maxValue - sensor.sensorRange.minValue;
    const yMin = Math.round(sensor.sensorRange.minValue - diff / 15);
    const yMax = Math.round(sensor.sensorRange.maxValue + diff / 15);

    return (
      <View style={styles.container}>
        <View style={styles.historyHeaderContainer}>
          <HistoryHeader
            onChange={setPeriod}
            currentDateTime={data[currentIndex]?.dateTime}
          />

          {isLoading ? (
            <Loading
              withMask={false}
              style={{
                minHeight: chartHeight + spacing[4] * 2,
                justifyContent: "center",
              }}
            />
          ) : (
            <LineChart
              data={data}
              yMin={yMin}
              yMax={yMax}
              yPrecision={sensor.numberOfDecimals}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
              height={chartHeight}
              style={styles.chart}
              error={errors?.[0]}
              lines={chartLines}
            />
          )}
        </View>
      </View>
    );
  },
);
