import * as React from "react";
import Svg, { Path } from "react-native-svg";

const RoutineRuleIcon = ({ size = 52 }) => (
  <Svg width={size} height={size} viewBox="0 0 52 52" fill="none">
    <Path
      d="M43.3332 26.0001V15.1667C43.3332 12.7735 41.3931 10.8334 38.9998 10.8334H12.9998C10.6066 10.8334 8.6665 12.7735 8.6665 15.1667V41.1667C8.6665 43.5599 10.6066 45.5 12.9998 45.5H18.5"
      stroke="#2B2E32"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M34.6665 6.5V15.1667"
      stroke="#2B2E32"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M17.3335 6.5V15.1667"
      stroke="#2B2E32"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M8.6665 20H43.3332"
      stroke="#2B2E32"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M28.3399 26H23.9199V30.42H28.3399V26Z"
      stroke="#2B2E32"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M18.46 26H14.04V30.42H18.46V26Z"
      stroke="#2B2E32"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M38.2198 26H33.7998V30.42H38.2198V26Z"
      stroke="#2B2E32"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M18.42 35.88H14V40.3H18.42V35.88Z"
      stroke="#2B2E32"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M35.5 41C34.7989 42.3315 33.859 43.5288 32.7249 44.535C32.0103 45.2344 31.0996 45.7108 30.1082 45.9038C29.1168 46.0969 28.0892 45.9979 27.1552 45.6195C26.2213 45.2411 25.423 44.6003 24.8614 43.778C24.2998 42.9557 24 41.989 24 41C24 40.011 24.2998 39.0443 24.8614 38.222C25.423 37.3997 26.2213 36.7589 27.1552 36.3805C28.0892 36.0021 29.1168 35.9031 30.1082 36.0962C31.0996 36.2892 32.0103 36.7656 32.7249 37.465C33.859 38.4712 34.7989 39.6685 35.5 41ZM35.5 41C36.2011 39.6685 37.141 38.4712 38.2751 37.465C38.9897 36.7656 39.9004 36.2892 40.8918 36.0962C41.8832 35.9031 42.9108 36.0021 43.8448 36.3805C44.7787 36.7589 45.577 37.3997 46.1386 38.222C46.7002 39.0443 47 40.011 47 41C47 41.989 46.7002 42.9557 46.1386 43.778C45.577 44.6003 44.7787 45.2411 43.8448 45.6195C42.9108 45.9979 41.8832 46.0969 40.8918 45.9038C39.9004 45.7108 38.9897 45.2344 38.2751 44.535C37.141 43.5288 36.2011 42.3315 35.5 41Z"
      stroke="#2B2E32"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default RoutineRuleIcon;
