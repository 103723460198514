// Use this import if you want to use "env.js" file
// const { API_URL } = require("../../config/env")

const DEV_SERVER_URI = "https://api-test.growdirector.com";
const STAGE_SERVER_URI = "https://api.stage.k8s.growdirector.com";
const PROD_SERVER_URI = "https://gen3.api.growdirector.com";

export enum APIServer {
  Prod = "prod",
  Dev = "dev",
  Stage = "stage",
  Custom = "custom",
}

export const API_SERVER_URIS = {
  [APIServer.Prod]: PROD_SERVER_URI,
  [APIServer.Dev]: DEV_SERVER_URI,
  [APIServer.Stage]: STAGE_SERVER_URI,
};

export const SERVER_URL_TO_API_URL = (
  server: APIServer,
  serverUrl?: string,
) => {
  if (server === APIServer.Custom && serverUrl) return `${serverUrl}/api/v1`;

  return `${API_SERVER_URIS[server] || PROD_SERVER_URI}/api/v1`;
};
const API_URL = SERVER_URL_TO_API_URL(APIServer.Prod);

/**
 * The options used to configure the API.
 */
export interface ApiConfig {
  /**
   * The URL of the api.
   */
  url: string;

  /**
   * Milliseconds before we timeout the request.
   */

  timeout: number;
}

/**
 * The default configuration for the app.
 */
export const DEFAULT_API_CONFIG: ApiConfig = {
  url: API_URL,
  timeout: 10000,
};

export const SENSORS_INTERVAL_MS = 5 * 1000;
export const DEVICES_INTERVAL_MS = 60 * 1000;
