import * as React from "react";
import Svg, { Path } from "react-native-svg";

import { defaultColors } from "../../theme";

export default function ModuleCriticalIcon({
  size = 24,
  color = defaultColors.text,
}) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M10.7505 2.77963C11.132 2.56474 11.5624 2.45184 12.0002 2.45184C12.438 2.45184 12.8684 2.56474 13.2499 2.77963C13.6313 2.99452 13.9509 3.30414 14.1778 3.67859L14.1783 3.67947L17.4728 9.1342C17.687 9.48876 17.5732 9.94979 17.2186 10.1639C16.864 10.3781 16.403 10.2643 16.1889 9.9097L12.8948 4.45585C12.8016 4.30218 12.6702 4.17473 12.5136 4.08651C12.3569 3.99823 12.1801 3.95184 12.0002 3.95184C11.8203 3.95184 11.6435 3.99823 11.4868 4.08651C11.3315 4.17398 11.2001 4.30121 11.1064 4.4554L11.1056 4.45679L3.50467 17.1475C3.41501 17.3045 3.36759 17.4822 3.36707 17.6631C3.36655 17.8457 3.41385 18.0253 3.50426 18.1839C3.59468 18.3426 3.72506 18.4748 3.88245 18.5675C4.03877 18.6595 4.21632 18.7093 4.39767 18.712H10.4641C10.8783 18.712 11.2141 19.0477 11.2141 19.462C11.2141 19.8762 10.8783 20.212 10.4641 20.212H4.38448C3.94 20.207 3.50457 20.0856 3.1215 19.8601C2.73843 19.6346 2.42108 19.3128 2.20101 18.9266C1.98093 18.5404 1.8658 18.1033 1.86708 17.6588C1.86835 17.2143 1.98598 16.7778 2.20826 16.3929L2.21425 16.3825L9.82264 3.67952C10.0489 3.30605 10.3681 2.99508 10.7505 2.77963Z"
        fill={color}
      />
      <Path
        d="M18.2447 14.2964C18.2447 13.8822 17.9089 13.5464 17.4947 13.5464C17.0804 13.5464 16.7447 13.8822 16.7447 14.2964V16.2966C16.7447 16.4955 16.8237 16.6863 16.9643 16.8269L18.1644 18.027C18.4573 18.3199 18.9322 18.3199 19.2251 18.027C19.518 17.7341 19.518 17.2593 19.2251 16.9664L18.2447 15.9859V14.2964Z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5764 12.6461C14.5729 11.6495 15.9246 11.0897 17.3339 11.0897C18.7433 11.0897 20.0949 11.6495 21.0915 12.6461C22.0881 13.6427 22.6479 14.9943 22.6479 16.4037C22.6479 17.1015 22.5105 17.7925 22.2434 18.4372C21.9764 19.082 21.5849 19.6678 21.0915 20.1612C20.598 20.6547 20.0122 21.0461 19.3675 21.3132C18.7228 21.5802 18.0318 21.7177 17.3339 21.7177C16.6361 21.7177 15.9451 21.5802 15.3004 21.3132C14.6556 21.0461 14.0698 20.6547 13.5764 20.1612C13.0829 19.6678 12.6915 19.082 12.4244 18.4372C12.1574 17.7925 12.0199 17.1015 12.0199 16.4037C12.0199 14.9943 12.5798 13.6427 13.5764 12.6461ZM17.3339 12.5897C16.3224 12.5897 15.3523 12.9915 14.637 13.7068C13.9218 14.422 13.5199 15.3921 13.5199 16.4037C13.5199 16.9045 13.6186 17.4005 13.8103 17.8632C14.0019 18.326 14.2829 18.7464 14.637 19.1006C14.9912 19.4547 15.4116 19.7357 15.8744 19.9273C16.3371 20.119 16.8331 20.2177 17.3339 20.2177C17.8348 20.2177 18.3308 20.119 18.7935 19.9273C19.2562 19.7357 19.6767 19.4547 20.0308 19.1006C20.385 18.7464 20.6659 18.326 20.8576 17.8632C21.0493 17.4005 21.1479 16.9045 21.1479 16.4037C21.1479 15.3921 20.7461 14.422 20.0308 13.7068C19.3156 12.9915 18.3455 12.5897 17.3339 12.5897Z"
        fill={color}
      />
    </Svg>
  );
}
