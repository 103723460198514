import {
  Image,
  Dimensions,
  StyleSheet,
  Linking,
  StyleProp,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";

import { useCallback } from "react";
import { spacing } from "../../../../theme";
import { PlayIcon } from "../../../../svgs";

const styles = StyleSheet.create({
  video: {
    alignItems: "center",
    marginBottom: spacing[4],
  },
  playIcon: {
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
});

type YoutubeLinkProps = {
  youtubeId: string;
  width?: number;
  height?: number;
  style?: StyleProp<ViewStyle>;
};

const DEFAULT_WIDTH = Dimensions.get("window").width - spacing[6];
const DEFAULT_HEIGHT = 200;

export const YoutubePreviewLink = ({
  youtubeId,
  width = DEFAULT_WIDTH,
  height = DEFAULT_HEIGHT,
  style,
}: YoutubeLinkProps) => {
  const imageURI = `https://img.youtube.com/vi/${youtubeId}/hqdefault.jpg`;

  const goToVideo = useCallback(() => {
    const videoLink = `https://www.youtube.com/watch?v=${youtubeId}&t=1s`;
    Linking.openURL(videoLink);
  }, [youtubeId]);

  return (
    <TouchableOpacity onPress={goToVideo} style={[styles.video, style]}>
      <Image source={{ uri: imageURI }} style={{ width, height }} />

      <View style={styles.playIcon}>
        <PlayIcon />
      </View>
    </TouchableOpacity>
  );
};
