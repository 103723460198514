import { TimeSystem } from "@models/settings/user-settings-store";
import {
  dateTimeToTime,
  getDuration,
  pad,
  secondsToText,
} from "./timeConverter";

export const displayTime = (dateTime: string, timeSystem: TimeSystem) => {
  return dateTimeToTime(dateTime, timeSystem);
};

export const displayDuration = (
  startAt: string,
  endAt: string,
  options = {},
) => {
  const duration = getDuration(startAt, endAt);
  return secondsToText(duration, "duration");
};

export const displayHour = (value: number, system?: TimeSystem): string => {
  if (system === TimeSystem.hours12) {
    const ampm = value >= 12 ? "pm" : "am";
    const hours = value % 12 || 12;
    return `${hours} ${ampm}`;
  }
  return pad(value);
};

export const displayNumber = (
  value: number | null | undefined,
  precision = 2,
  defaultValue = "",
): string => {
  if (value === undefined || value === null) return defaultValue;
  return Number(value).toFixed(precision);
};
