import Svg, { SvgProps, Mask, Path, G } from "react-native-svg";

const PressedCheckbox = (props: SvgProps) => (
  <Svg width={18} height={18} fill="none" {...props}>
    <Mask
      id="a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={18}
      height={18}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2Zm0 9 5 5 9-9-1.41-1.42L7 11.17 3.41 7.59 2 9Z"
        fill="#fff"
      />
    </Mask>
    <G mask="url(#a)">
      <Path fill="#3F9A6E" d="M-3-3h24v24H-3z" />
    </G>
  </Svg>
);

export default PressedCheckbox;
