import React, { useMemo } from "react";
import {
  StyleProp,
  StyleSheet,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";
import { useTheme } from "@react-navigation/native";

import { Text } from "../ui/Text";
import { Label } from "./Label";
import { spacing } from "../../theme";
import { TxKeyPath } from "../../i18n";

const createStyles = (theme: any) =>
  StyleSheet.create({
    card: {
      backgroundColor: theme.colors.card,
      borderRadius: spacing[2],
      marginTop: spacing[2],
      marginBottom: spacing[2],
      padding: spacing[3],
    },
    text: {
      color: theme.colors.primary,
      textAlign: "center",
      lineHeight: 24,
    },
    error: {
      fontSize: 12,
      color: theme.colors.error,
      paddingTop: spacing[1],
    },
  });

type CustomSelectProps = {
  txLabel?: TxKeyPath;
  txText?: TxKeyPath;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  labelStyle?: StyleProp<TextStyle>;
  errorText?: string;
};
export const CustomSelect: React.FunctionComponent<CustomSelectProps> = ({
  txLabel,
  txText = "fields.select",
  onPress,
  style,
  labelStyle,
  errorText,
  children,
}) => {
  const theme = useTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <View style={style}>
      {txLabel ? (
        <Label tx={txLabel} isError={!!errorText} style={labelStyle} />
      ) : null}
      <TouchableOpacity style={styles.card} onPress={onPress}>
        {children}
        <Text style={styles.text} tx={txText} />
      </TouchableOpacity>
      {errorText ? <Text style={styles.error}>{errorText}</Text> : null}
    </View>
  );
};
