import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { palette } from "../theme";

export default function ClockIcon({
  color = palette.Black,
  size = 16,
  ...props
}) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 25" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 12.5C3.75 7.94365 7.44365 4.25 12 4.25C16.5563 4.25 20.25 7.94365 20.25 12.5C20.25 17.0563 16.5563 20.75 12 20.75C7.44365 20.75 3.75 17.0563 3.75 12.5ZM12 2.75C6.61522 2.75 2.25 7.11522 2.25 12.5C2.25 17.8848 6.61522 22.25 12 22.25C17.3848 22.25 21.75 17.8848 21.75 12.5C21.75 7.11522 17.3848 2.75 12 2.75ZM12.75 7.5C12.75 7.08579 12.4142 6.75 12 6.75C11.5858 6.75 11.25 7.08579 11.25 7.5V12.5C11.25 12.6989 11.329 12.8897 11.4697 13.0303L14.4697 16.0303C14.7626 16.3232 15.2374 16.3232 15.5303 16.0303C15.8232 15.7374 15.8232 15.2626 15.5303 14.9697L12.75 12.1893V7.5Z"
        fill={color}
      />
    </Svg>
  );
}
