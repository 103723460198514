import { useEffect, useState } from "react";
import * as SecureStore from "expo-secure-store";
import { Platform } from "react-native";

import { JSONParseSafety } from "../JSONParseSafety";

// export type TLoginData = {
//   username: string;
//   password: string;
// };

export enum SecureValueKey {
  LoginData = "LoginData",
  UseBiometricToSignIn = "useBiometricToSignIn",
}

export const saveSecureValue = async (key: SecureValueKey, value: any) => {
  if (Platform.OS !== "web") {
    await SecureStore.setItemAsync(key, JSON.stringify(value));
  }
};

export const getSecureValueString = async (key: SecureValueKey) => {
  if (Platform.OS === "web") return null;

  try {
    return SecureStore.getItemAsync(key);
  } catch (e) {
    return null;
  }
};

export const getSecureValue = async (key: SecureValueKey) => {
  const result = await getSecureValueString(key);
  return result ? JSONParseSafety(result) : null;
};

export const useGetSecureValue = (key: SecureValueKey) => {
  const [value, setValue] = useState(null);

  useEffect(() => {
    const getAndSetSecureValue = async () => {
      const secureValue = await getSecureValueString(key);
      setValue(secureValue);
    };

    if (Platform.OS !== "web") {
      getAndSetSecureValue();
    }
  }, [key]);

  return JSONParseSafety(value);
};

export const deleteSecureValue = async (key: SecureValueKey) => {
  if (Platform.OS !== "web") {
    await SecureStore.deleteItemAsync(key);
  }
};
