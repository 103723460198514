import React, { useCallback } from "react";
import { Dimensions, StyleSheet, View, ViewStyle } from "react-native";
import {
  GestureHandlerRootView,
  LongPressGestureHandler,
} from "react-native-gesture-handler";
import Animated from "react-native-reanimated";
import * as shape from "d3-shape";

import { ErrorText, Text } from "@components/ui";

import { translate } from "../../i18n";
import { spacing } from "../../theme";
import { useAppTheme } from "../../hooks";
import { numberOfIntegers } from "../../utils/numbers";
import { apx, MAGIC_WIDTH } from "./utils";
import {
  Tooltip,
  Line,
  ScaleY,
  ScaleX,
  OfflinePoints,
  AdditionalLines,
} from "./components";
import AreaChart from "./lib/area-chart";

const styles = StyleSheet.create({
  container: {
    position: "relative",
    overflow: "hidden",
  },
  emptyContainer: {
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: spacing[4],
  },
});

const LEFT_PADDING = 8;
const RIGHT_PADDING = 8;

const VALUE_LENGTH = 7;
const OFFSET_RIGHT_VALUE = 1;

const OFFSET_SCALE_Y = 17;
const MIN_VALUE_SCALE_Y = 100;

const CONTENT_INSET = {
  top: OFFSET_SCALE_Y,
  bottom: OFFSET_SCALE_Y,
  right: apx(RIGHT_PADDING),
};

const ChartLine = ({ line, color }: any) => {
  const theme = useAppTheme();

  return <Line line={line} color={color || theme.colors.defaultLine} />;
};

const ChartPoints = ({ x, y, data, color }: any) => {
  const xMemo = useCallback(x, [data]);
  const yMemo = useCallback(y, [data]);
  return <OfflinePoints x={xMemo} y={yMemo} data={data} />;
};

const yAccessor = ({ item }) => item?.value;
export type ChartDataItem = {
  dateTime: string | Date;
  value: number;
  state?: string;
};

export type ChartLineData = {
  value: number;
  color?: string;
  label?: string;
  isVertically?: boolean;
};

export type LineChartProps = {
  data: ChartDataItem[];
  setCurrentIndex: (index: number) => void;
  currentIndex: number;
  height?: number;
  style?: ViewStyle;
  color?: string;
  yMin?: number;
  yMax?: number;
  yPrecision?: number;
  lines?: ChartLineData[];
  error?: string;
};

export const LineChart = ({
  data,
  setCurrentIndex,
  currentIndex: positionX = 0,
  height = Dimensions.get("window").width * 0.2,
  style,
  color,
  yMin,
  yMax,
  yPrecision = 0,
  lines = [],
  error,
}: LineChartProps) => {
  // const [positionX, setPositionX] = useState(0); // The currently selected X coordinate position
  const isNormalSizeChart = height >= MIN_VALUE_SCALE_Y;

  const updatePosition = useCallback(
    ({ nativeEvent }) => {
      let { x } = nativeEvent;
      const paddingHorizontal = nativeEvent.absoluteX - nativeEvent.x;

      // const YAxisWidth = apx(130);
      const x0 = apx(LEFT_PADDING); // x0 position
      const chartWidth =
        apx(MAGIC_WIDTH) - x0 - apx(RIGHT_PADDING) - paddingHorizontal * 2;
      const xN = x0 + chartWidth; // xN position
      const dataLength = data.length;
      const xDistance = chartWidth / dataLength; // The width of each coordinate point

      if (x <= x0) {
        x = x0;
      }
      if (x >= xN) {
        x = xN;
      }

      // The selected coordinate x :
      let value = Math.round((x - x0) / xDistance);
      if (value >= dataLength - 1) {
        value = dataLength - 1; // Out of chart range, automatic correction
      }
      const newPosition = Number(value);

      setCurrentIndex(newPosition); // setPositionX(newPosition);
    },
    [data],
  );

  if (error) {
    return (
      <View style={[styles.emptyContainer, { height }]}>
        <ErrorText>{error}</ErrorText>
      </View>
    );
  }

  if (!data?.length) {
    return (
      <View style={[styles.emptyContainer, { height }]}>
        <Text>{translate("Sensors.History.errors.noHistory")}</Text>
      </View>
    );
  }

  // const onHandlerStateChange = ({ nativeEvent }) => {
  //   console.log("onHandlerStateChange !!!", nativeEvent)
  //   if (nativeEvent.state === 4) {
  //     console.log("START") // TODO: disable ScrollView
  //   }
  //   if (nativeEvent.state === 5) {
  //     console.log("END") // TODO: enable ScrollView
  //   }
  // }

  const allNumberValues = [
    ...data.map((item) => item?.value),
    ...lines
      .filter((lineData) => !lineData?.isVertically)
      .map((lineData) => lineData?.value),
    yMin,
    yMax,
  ].filter((v) => v !== null && !Number.isNaN(Number(v)));

  const minValue = allNumberValues.length ? Math.min(...allNumberValues) : 0;
  const maxValue = allNumberValues.length ? Math.max(...allNumberValues) : 0;

  const offsetScaleYFromSize = isNormalSizeChart
    ? VALUE_LENGTH *
      (numberOfIntegers(maxValue) + yPrecision + OFFSET_RIGHT_VALUE)
    : LEFT_PADDING;

  return (
    <View style={[styles.container, style]}>
      <GestureHandlerRootView>
        <LongPressGestureHandler
          maxDist={100000}
          minDurationMs={0}
          // shouldCancelWhenOutside={false}
          onGestureEvent={updatePosition}
          // onHandlerStateChange={onHandlerStateChange}
          // onBegan={onBeganEvent}
          // onEnded={onEndedEvent}
        >
          <Animated.View
            style={{
              // width: "100%", //apx(MAGIC_WIDTH),
              height,
            }}
          >
            <AreaChart
              style={{ flex: 1 }}
              data={data}
              yAccessor={yAccessor}
              // curve={shape.curveNatural}
              curve={shape.curveMonotoneX}
              contentInset={{ ...CONTENT_INSET, left: offsetScaleYFromSize }}
              yMin={minValue.toFixed(yPrecision)}
              yMax={maxValue.toFixed(yPrecision)}

              // svg={{ fill: 'url(#gradient)' }}
            >
              <ChartLine color={color} />
              <ChartPoints color={color} />
              <Tooltip positionX={positionX} offsetScaleY={OFFSET_SCALE_Y} />

              <ScaleY
                yMin={minValue}
                yMax={maxValue}
                yPrecision={yPrecision}
                isNormalSizeChart={isNormalSizeChart}
                offsetScaleY={OFFSET_SCALE_Y}
                partLength={50}
              />

              {isNormalSizeChart ? (
                <ScaleX
                  offsetScaleYFromSize={offsetScaleYFromSize}
                  offsetScaleX={RIGHT_PADDING}
                  labelsCount={4}
                />
              ) : null}

              <AdditionalLines
                lines={lines}
                xOffset={offsetScaleYFromSize}
                offsetScaleY={OFFSET_SCALE_Y}
              />
            </AreaChart>
          </Animated.View>
        </LongPressGestureHandler>
      </GestureHandlerRootView>
    </View>
  );
};
