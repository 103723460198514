import React, { useMemo } from "react";
import { StyleSheet, View, ViewStyle } from "react-native";
import { TextInput as PaperTextInput } from "react-native-paper";

import { RuleFormData } from "@models/rule/rule";
import { Text } from "@components/ui";
import { TextInput } from "@components/inputs";

import { spacing } from "../../../../../../theme";
import { stringToNumber } from "../../../../../../utils/numbers";
import { translate } from "../../../../../../i18n";
import { useAppTheme } from "../../../../../../hooks";
import { SensorRuleErrors } from "./types";

const createStyles = (theme: any) =>
  StyleSheet.create({
    container: {
      marginBottom: spacing[4],
      padding: spacing[4],
      paddingBottom: spacing[3],
      borderRadius: spacing[4],
      borderWidth: 1,
      borderColor: theme.colors.menuBorder,
    },

    columns: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginHorizontal: -spacing[2],
    },
    column: {
      flex: 1,
      marginHorizontal: spacing[2],
    },

    unit: {
      fontSize: 18,
      color: theme.colors.text,
    },
  });

type ReservoirProps = {
  formData?: RuleFormData;
  changeFormData?: (data: Partial<RuleFormData>) => void;
  errors?: SensorRuleErrors;
  style?: ViewStyle;
};

export const Reservoir = ({
  formData,
  changeFormData = () => null,
  errors,
  style,
}: ReservoirProps) => {
  const theme = useAppTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <View style={[styles.container, style]}>
      <Text style={styles.label} tx="Devices.Rules.reservoir" />
      <View style={styles.columns}>
        <View style={styles.column}>
          <TextInput
            txLabel="Devices.Rules.dosing"
            // labelStyle={styles.label}
            value={formData?.dosingTime?.toString() ?? ""}
            errorText={errors?.dosingTime}
            hasError={!!errors?.dosingTime}
            onChangeText={(value) =>
              changeFormData({ dosingTime: stringToNumber(value) })
            }
            returnKeyType="next"
            right={
              <PaperTextInput.Affix
                text={translate("units.seconds")}
                textStyle={styles.unit}
              />
            }
            keyboardType="numeric"
          />
        </View>

        <View style={styles.column}>
          <TextInput
            txLabel="Devices.Rules.mixTime"
            value={formData?.mixTime?.toString() ?? ""}
            errorText={errors?.mixTime}
            onChangeText={(value) =>
              changeFormData({ mixTime: stringToNumber(value) })
            }
            disabled={!formData?.sensorUid}
            returnKeyType="next"
            right={
              <PaperTextInput.Affix
                text={translate("units.seconds")}
                textStyle={styles.unit}
              />
            }
            keyboardType="numeric"
          />
        </View>
      </View>

      <View style={styles.columns}>
        <View style={styles.column}>
          <TextInput
            txLabel="Devices.Rules.tankSize"
            value={formData?.tankSize?.toString() ?? ""}
            errorText={errors?.tankSize}
            onChangeText={(value) =>
              changeFormData({ tankSize: stringToNumber(value) })
            }
            disabled={!formData?.sensorUid}
            returnKeyType="next"
            right={
              <PaperTextInput.Affix
                text={translate("units.liters")}
                textStyle={styles.unit}
              />
            }
            keyboardType="numeric"
          />
        </View>
        <View style={styles.column} />
      </View>
    </View>
  );
};
