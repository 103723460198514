import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { ScrollView, StyleSheet, View } from "react-native";
import { TextInput as PaperTextInput } from "react-native-paper";
import { StackNavigationProp, StackScreenProps } from "@react-navigation/stack";
import { useNavigation } from "@react-navigation/native";

import { Sensor, useStores } from "@models/index";
import { Button, ErrorsAlert } from "@components/ui";
import { Screen } from "@components/index";
import { TextInput } from "@components/inputs";
import { TopBarContent, TopBarNew } from "@components/layout";

import { spacing } from "../../../theme";
import { translate } from "../../../i18n";
import { NoneIcon } from "../../../svgs";
import * as SensorIcons from "../../../svgs/sensors";
import { numberToString, stringToNumber } from "../../../utils/numbers";
import { useAppTheme } from "../../../hooks";
import { goBack } from "../../../navigators";
import { SensorsNavigatorParamList } from "../navigation";
import { SensorSelector } from "./SensorSelector";
import { SensorsRangeHintsData } from "./hints";

const createStyles = (theme: any) =>
  StyleSheet.create({
    screen: {
      paddingTop: spacing[6],
      paddingHorizontal: spacing[4],
    },
    inputs: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginHorizontal: -spacing[2],
    },
    input: {
      flex: 1,
      marginHorizontal: spacing[2],
    },
    button: {
      marginTop: spacing[5],
    },
    unit: {
      fontSize: 18,
      color: theme.colors.text,
    },
  });

const validate = (sensor: Sensor, minValue: number, maxValue: number) => {
  if (!minValue && minValue !== 0) {
    return { minValue: translate("validations.couldNotBeEmpty") };
  }

  if (!maxValue) {
    return { maxValue: translate("validations.couldNotBeEmpty") };
  }

  if (maxValue <= minValue) {
    return {
      maxValue: translate("validations.valueShouldBeMore", { value: minValue }),
    };
  }

  const { minValue: limitMinValue, maxValue: limitMaxValue } =
    sensor.valueLimits;

  if (minValue < limitMinValue) {
    return {
      minValue: translate("validations.valueShouldBeMore", {
        value: limitMinValue,
      }),
    };
  }

  if (maxValue > limitMaxValue) {
    return {
      maxValue: translate("validations.valueShouldBeLess", {
        value: limitMaxValue,
      }),
    };
  }

  return null;
};

type TFormData = {
  minValue?: string;
  maxValue?: string;
};

export const SensorRangeScreen: React.FC<
  StackScreenProps<SensorsNavigatorParamList, "sensor-range">
> = observer((props) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  const {
    route: { params = {} },
  } = props as any;
  const { sensorStore, settingsStore } = useStores();
  const sensor = sensorStore.getSensor(params?.uid);

  const navigation =
    useNavigation() as StackNavigationProp<SensorsNavigatorParamList>;

  const [isVisibleSensorSelector, setVisibleSensorSelector] =
    useState<boolean>(false);

  const closeSensorSelector = () => {
    setVisibleSensorSelector(false);
  };

  const [formData, setFormData] = useState<TFormData>();
  const [errors, setErrors] = useState<TFormData | null>();

  const onChangeValue = (name: string, value: string) => {
    setErrors(null);
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const setRange = (minValue?: number, maxValue?: number) =>
    setFormData({
      minValue: numberToString(minValue),
      maxValue: numberToString(maxValue),
    });

  useEffect(() => {
    setErrors(null);
    if (sensor) {
      setRange(sensor.sensorRange?.minValue, sensor.sensorRange?.maxValue);
    }
  }, [sensor]);

  const saveRange = async () => {
    const minValue = stringToNumber(formData.minValue);
    const maxValue = stringToNumber(formData.maxValue);
    setRange(minValue, maxValue);

    const newErrors = validate(sensor, minValue, maxValue);
    setErrors(newErrors);
    if (newErrors) {
      return false;
    }

    const result = await sensor.updateSensorRange(minValue, maxValue);
    if (!result.errors) {
      goBack();
    }

    return true;
  };

  // const copyRange = () => {
  //   setVisibleSensorSelector(true);
  // };

  const resetRange = () => {
    setErrors(null);
    const { minValue, maxValue } = sensor.defaultSensorRange;

    setRange(minValue, maxValue);
  };

  const copyRangeSensor = (range: TFormData) => {
    closeSensorSelector();
    setErrors(null);

    setRange(range.minValue as any, range.maxValue as any);
  };

  if (!sensor) {
    navigation.navigate("sensors-list");
    return null;
  }

  const Icon = SensorIcons[sensor.iconName] || NoneIcon;

  return (
    <Screen
      testID="SensorRange"
      style={styles.screen}
      header={
        <>
          <TopBarNew title={translate("Sensors.Range.title")} />
          <TopBarContent
            title={sensor.displayName}
            subtitle={sensor.displayType}
            icon={Icon}
          />
        </>
      }
      loading={sensor.isLoading}
      hints={settingsStore.showHelpButton && SensorsRangeHintsData()}
    >
      <ScrollView>
        <View style={styles.inputs}>
          <View style={styles.input}>
            <TextInput
              label="Minimum"
              value={formData?.minValue}
              errorText={errors?.minValue}
              onChangeText={(value) => onChangeValue("minValue", value)}
              returnKeyType="next"
              right={
                <PaperTextInput.Affix
                  text={sensor?.unitName}
                  textStyle={styles.unit}
                />
              }
              keyboardType="numeric"
            />
          </View>
          <View style={styles.input}>
            <TextInput
              label="Maximum"
              value={formData?.maxValue}
              errorText={errors?.maxValue}
              onChangeText={(value) => onChangeValue("maxValue", value)}
              returnKeyType="next"
              right={
                <PaperTextInput.Affix
                  text={sensor?.unitName}
                  textStyle={styles.unit}
                />
              }
              keyboardType="numeric"
            />
          </View>
        </View>

        <Button
          style={styles.button}
          type="link"
          tx="Sensors.Range.resetDefaults"
          onPress={resetRange}
        >
          Reset to defaults
        </Button>
        {/* <Button */}
        {/*  style={styles.button} */}
        {/*  type="link" */}
        {/*  tx="Sensors.Range.copyFromAnother" */}
        {/*  onPress={copyRange} */}
        {/* > */}
        {/*  Copy from another */}
        {/* </Button> */}
        <Button
          style={styles.button}
          type="primary"
          tx="common.save"
          onPress={saveRange}
          disabled={Boolean(errors)}
        >
          Save
        </Button>
      </ScrollView>

      {isVisibleSensorSelector ? (
        <SensorSelector
          currentUid={sensor.uid}
          sensorType={sensor.type}
          header={
            <TopBarNew
              title={translate("Devices.Rules.selectSensor")}
              backHandler={closeSensorSelector}
            />
          }
          onPress={copyRangeSensor}
        />
      ) : null}

      <ErrorsAlert errors={sensor.statusErrors} onCancel={sensor.resetErrors} />
    </Screen>
  );
});
