import * as React from "react";
import Svg, { Path } from "react-native-svg";

export const StatusIdleSvg = ({ size = 45, color = "#2B2E32" }) => (
  <Svg width={size} height={size} viewBox="0 0 45 45" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.7841 9.30811C25.2318 9.30811 24.7841 9.75582 24.7841 10.3081C24.7841 10.8604 25.2318 11.3081 25.7841 11.3081H27.7849L24.7601 14.6354C24.4938 14.9283 24.4254 15.3507 24.5855 15.7127C24.7456 16.0746 25.1042 16.3081 25.5 16.3081H30.5C31.0523 16.3081 31.5 15.8604 31.5 15.3081C31.5 14.7558 31.0523 14.3081 30.5 14.3081H27.7606L30.7854 10.9808C31.0517 10.6879 31.1201 10.2655 30.96 9.90355C30.7998 9.54157 30.4413 9.30811 30.0455 9.30811H27.9148H25.7841ZM17.7841 11.3081C17.2318 11.3081 16.7841 11.7558 16.7841 12.3081C16.7841 12.8604 17.2318 13.3081 17.7841 13.3081H19.7849L16.7601 16.6354C16.4938 16.9283 16.4254 17.3507 16.5855 17.7127C16.7456 18.0746 17.1042 18.3081 17.5 18.3081H22.5C23.0523 18.3081 23.5 17.8604 23.5 17.3081C23.5 16.7558 23.0523 16.3081 22.5 16.3081H19.7606L22.7854 12.9808C23.0517 12.6879 23.1201 12.2655 22.96 11.9036C22.7998 11.5416 22.4413 11.3081 22.0455 11.3081H19.9148H17.7841ZM8.5 24.2171C9.05228 24.2171 9.5 24.6648 9.5 25.2171V30.1263H19.9444V25.2172C19.9444 24.6649 20.3922 24.2172 20.9444 24.2172H33.3889C34.4771 24.2172 35.5221 24.6466 36.2936 25.4131C37.0653 26.1798 37.5 27.221 37.5 28.3081V31.1263V33.8081C37.5 34.3604 37.0523 34.8081 36.5 34.8081C35.9477 34.8081 35.5 34.3604 35.5 33.8081V32.1263H20.9444H9.5V33.8081C9.5 34.3604 9.05228 34.8081 8.5 34.8081C7.94772 34.8081 7.5 34.3604 7.5 33.8081V31.1263V25.2171C7.5 24.6648 7.94772 24.2171 8.5 24.2171ZM21.9444 30.1263H35.5V28.3081C35.5 27.7557 35.2792 27.2245 34.884 26.8319C34.4886 26.4391 33.9509 26.2172 33.3889 26.2172H21.9444V30.1263ZM13.5 24.8081C13.5 23.9797 14.1716 23.3081 15 23.3081C15.8284 23.3081 16.5 23.9797 16.5 24.8081C16.5 25.6365 15.8284 26.3081 15 26.3081C14.1716 26.3081 13.5 25.6365 13.5 24.8081ZM15 21.3081C13.067 21.3081 11.5 22.8751 11.5 24.8081C11.5 26.7411 13.067 28.3081 15 28.3081C16.933 28.3081 18.5 26.7411 18.5 24.8081C18.5 22.8751 16.933 21.3081 15 21.3081Z"
      fill={color}
    />
  </Svg>
);
