import * as React from "react";
import Svg, { Path } from "react-native-svg";

export function AddSensor() {
  return (
    <Svg width="210" height="145" viewBox="0 0 210 145" fill="none">
      <Path
        d="M101.274 134.67H84.1142C81.3342 134.67 79.0742 132.41 79.0742 129.63V108.77C79.0742 105.99 81.3342 103.73 84.1142 103.73H101.274C104.054 103.73 106.314 105.99 106.314 108.77V129.63C106.304 132.42 104.054 134.67 101.274 134.67Z"
        fill="#B0EACC"
        stroke="#2A2E32"
        strokeWidth="2.1962"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <Path
        d="M92.6946 126.682C96.8202 126.682 100.165 123.338 100.165 119.212C100.165 115.087 96.8202 111.742 92.6946 111.742C88.569 111.742 85.2246 115.087 85.2246 119.212C85.2246 123.338 88.569 126.682 92.6946 126.682Z"
        fill="#B0EACC"
        stroke="#2A2E32"
        strokeWidth="2.1962"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <Path
        d="M47.3438 80.5017H88.8538H123.914H169.004C176.984 80.5017 181.914 71.8017 177.814 64.9617L143.314 7.34172C141.194 3.80172 137.704 2.02172 134.074 2.01172H47.3438"
        fill="white"
      />
      <Path
        d="M47.3438 80.5017H88.8538H123.914H169.004C176.984 80.5017 181.914 71.8017 177.814 64.9617L143.314 7.34172C141.194 3.80172 137.704 2.02172 134.074 2.01172H47.3438"
        stroke="#2A2E32"
        strokeWidth="2.1962"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <Path
        d="M82.4042 80.4987H12.2842C4.31417 80.4987 -0.615825 71.8087 3.46417 64.9688L37.8642 7.34876C42.1242 0.218755 52.4342 0.218746 56.7042 7.33875L91.2042 64.9588C95.3142 71.7988 90.3842 80.4987 82.4042 80.4987Z"
        fill="white"
        stroke="#2A2E32"
        strokeWidth="2.1962"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <Path
        d="M46.3062 39.2228C46.5362 38.8928 46.9262 38.6928 47.3362 38.6928C47.7462 38.6928 48.1162 38.8828 48.3662 39.2128C48.3962 39.2628 48.4362 39.3028 48.4462 39.3528L61.7362 61.4728H66.8462L49.2762 32.2428C48.8862 31.5528 48.1362 31.1328 47.3362 31.1328C46.5562 31.1328 45.8162 31.5428 45.4262 32.2028L45.3562 32.3128L27.8262 61.4828H32.9362L46.2462 39.3428C46.2562 39.3028 46.2762 39.2528 46.3062 39.2228Z"
        fill="#1FB25A"
      />
      <Path
        d="M74.8155 59.6512L55.0355 26.7213L53.3055 23.8412C52.0855 21.8012 49.7955 20.5312 47.3555 20.5312C44.9155 20.5312 42.6255 21.8012 41.4055 23.8412L40.7355 24.9713L19.8955 59.6512C18.6755 61.6912 18.6755 64.2113 19.8955 66.2513C21.1155 68.2913 23.4055 69.5612 25.8455 69.5612H31.4355H31.7455H42.6455H42.7455C44.0655 69.6112 45.0955 70.6413 45.0955 71.9113V77.0312H49.5155V71.9113C49.5155 68.2813 46.4355 65.3112 42.6455 65.3112H25.7855C24.9355 65.2912 24.1355 64.8413 23.7155 64.1413C23.2755 63.4212 23.2755 62.5112 23.7155 61.7912L45.2655 25.9512C45.7055 25.2512 46.5255 24.8112 47.3655 24.8112C48.2155 24.8112 49.0255 25.2512 49.4655 25.9512L71.0155 61.7912C71.4555 62.5112 71.4555 63.4212 71.0155 64.1413C70.5955 64.8413 69.7955 65.3013 68.9455 65.3112H61.5055H61.3755C60.4955 65.3112 59.6955 64.8613 59.2555 64.1413L49.3255 47.6113C48.9355 46.9213 48.1855 46.5013 47.3855 46.5013C46.6055 46.5013 45.8655 46.9113 45.4755 47.5713L45.4055 47.6812L37.0855 61.5013H42.1855L46.2755 54.7012C46.2955 54.6712 46.3255 54.6212 46.3455 54.5912C46.5755 54.2612 46.9655 54.0612 47.3755 54.0612C47.7855 54.0612 48.1555 54.2513 48.4055 54.5813C48.4355 54.6313 48.4655 54.6713 48.4855 54.7213L55.4355 66.2713C56.6555 68.3113 58.9455 69.5813 61.3855 69.5813H62.8855H66.1155H67.1755H67.4355H68.9055C71.3555 69.5813 73.6355 68.3113 74.8555 66.2713C76.0155 64.2113 76.0355 61.6912 74.8155 59.6512Z"
        fill="#1FB25A"
      />
      <Path
        d="M108.377 50.7441C113.633 47.7093 115.056 40.3328 111.554 34.2682C108.053 28.2037 100.953 25.7475 95.697 28.7823C90.4407 31.8172 89.0182 39.1937 92.5197 45.2582C96.0212 51.3228 103.121 53.7789 108.377 50.7441Z"
        stroke="#2A2E32"
        strokeWidth="2.2848"
        strokeMiterlimit="10"
        strokeLinejoin="round"
        strokeDasharray="6.24 6.24"
      />
      <Path
        d="M104.428 43.9043C106.408 42.761 106.943 39.981 105.623 37.6948C104.303 35.4086 101.628 34.4821 99.6477 35.6253C97.6677 36.7685 97.1325 39.5486 98.4525 41.8348C99.7725 44.121 102.448 45.0475 104.428 43.9043Z"
        fill="#B0EACC"
        stroke="#2A2E32"
        strokeWidth="2.1962"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <Path
        d="M102.045 39.7695H168.475C190.415 39.7695 208.195 57.5495 208.195 79.4895C208.195 101.43 190.415 119.21 168.475 119.21H106.315"
        stroke="#2A2E32"
        strokeWidth="2.1962"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <Path
        d="M118.975 143.758C132.535 130.198 132.535 108.208 118.975 94.6484"
        stroke="#2A2E32"
        strokeWidth="2.1125"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <Path
        d="M111.225 136.018C120.505 126.738 120.505 111.688 111.225 102.398"
        stroke="#2A2E32"
        strokeWidth="2.1125"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <Path
        d="M66.4044 94.6484C52.8444 108.208 52.8444 130.198 66.4044 143.758"
        stroke="#2A2E32"
        strokeWidth="2.1125"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <Path
        d="M74.1553 102.398C64.8753 111.678 64.8753 126.728 74.1553 136.018"
        stroke="#2A2E32"
        strokeWidth="2.1125"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
