import {
  AppUpdateNotification,
  ReleaseNotesNotification,
  UpdateModulesNotification,
} from "./components";

export const AppNotifications = () => (
  <>
    <AppUpdateNotification />
    <ReleaseNotesNotification />
    <UpdateModulesNotification />
  </>
);
