import { translate } from "../../../../i18n";

import {
  CalibrationCleanECSvg,
  CalibrationECAirProcessSvg,
  CalibrationECAirSvg,
  CalibrationECErrorSvg,
  CalibrationECLiquidProcessSvg,
  CalibrationECLiquidSvg,
  CalibrationPrepareECSvg,
} from "../../../../svgs/pictures/calibration/EC";
import {
  CalibrationProcessStatus,
  CalibrationStep,
  TCalibrationStepProps,
} from "../types";

export const EC_STEPS = [
  CalibrationStep.PrepareInfo,
  CalibrationStep.Prepare,
  CalibrationStep.Clean,
  CalibrationStep.Process1,
  CalibrationStep.Process2,
  CalibrationStep.Success,
];

export const EC_STEPS_PROPS: TCalibrationStepProps = {
  [CalibrationStep.PrepareInfo]: {
    title: translate("Sensors.Calibration.PrepareInfo.title"),
    youtubeId: "L0K8zYU5nzc", // L0K8zYU5nzc
    items: [
      translate("Sensors.Calibration.PrepareInfo.item1"),
      translate("Sensors.Calibration.PrepareInfo.item2"),
    ],
  },

  [CalibrationStep.Prepare]: {
    Picture: CalibrationPrepareECSvg,
    items: [
      translate("Sensors.Calibration.Prepare.paperTowel"),
      translate("Sensors.Calibration.Prepare.distilledOrTapWater"),
      translate("Sensors.Calibration.ec.ecCalibrationLiquid"),
    ],
    youtubeId: "L0K8zYU5nzc",
    submitLabel: translate("common.next"),
  },

  [CalibrationStep.Clean]: {
    Picture: CalibrationCleanECSvg,
    items: [
      translate("Sensors.Calibration.Clean.stirSensorInCleanWater"),
      translate("Sensors.Calibration.Clean.cleanAndDryWithPaperTowel"),
    ],
    alert: translate("Sensors.Calibration.Clean.pleaseTouchVeryCarefully"),
    youtubeId: "L0K8zYU5nzc",
  },

  [CalibrationStep.Process1]: {
    Picture: CalibrationECAirSvg,
    title: translate("Sensors.Calibration.calibrationInAir"),
    items: [
      translate("Sensors.Calibration.ec.keepSensorInAir"),
      translate("Sensors.Calibration.pressCalibrate"),
    ],
    youtubeId: "L0K8zYU5nzc",
  },

  [`${CalibrationStep.Process1}${CalibrationProcessStatus.Process}`]: {
    Picture: CalibrationECAirProcessSvg,
    title: translate("Sensors.Calibration.calibrationInAir"),
    alert: translate("Sensors.Calibration.dontTouchSensorHead"),
  },

  [`${CalibrationStep.Process1}${CalibrationProcessStatus.Error}`]: {
    Picture: CalibrationECErrorSvg,
    title: translate("Sensors.Calibration.calibrationInAir"),
  },

  [CalibrationStep.Process2]: {
    Picture: CalibrationECLiquidSvg,
    title: translate("Sensors.Calibration.calibrationInLiquid"),
    items: [
      translate("Sensors.Calibration.ec.putSensorIntoECBuffer"),
      translate("Sensors.Calibration.pressCalibrate"),
    ],
    youtubeId: "L0K8zYU5nzc",
    // withInput: true,
    valueOptions: [
      { key: "1.42", name: "1.4 EC" },
      { key: "2.75", name: "2.7 EC" },
    ],
  },

  [`${CalibrationStep.Process2}${CalibrationProcessStatus.Process}`]: {
    Picture: CalibrationECLiquidProcessSvg,
    title: translate("Sensors.Calibration.calibrationInLiquid"),
    alert: translate("Sensors.Calibration.dontTouchSensorHead"),
  },

  [`${CalibrationStep.Process2}${CalibrationProcessStatus.Error}`]: {
    Picture: CalibrationECErrorSvg,
    title: translate("Sensors.Calibration.calibrationInLiquid"),
  },

  [CalibrationStep.Error]: {
    Picture: CalibrationECErrorSvg,
  },
};
