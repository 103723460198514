import React, { useMemo } from "react";
import {
  ImageSourcePropType,
  StyleSheet,
  View,
  TouchableOpacity,
} from "react-native";
import { useNavigation, useTheme } from "@react-navigation/native";
import { Appbar } from "react-native-paper";

import {
  BackgroundGradient,
  ContextMenu,
  MenuItem,
  Text,
} from "@components/ui";

import { spacing } from "../../theme";
import { useHardwareBackPress } from "../../hooks";

const createStyles = (theme: any) =>
  StyleSheet.create({
    header: {
      elevation: 0,
      zIndex: 99,
      backgroundColor: theme.colors.transparent,
      justifyContent: "space-between",
      marginBottom: -1,
      marginTop: 0,
    },
    disabled: {
      backgroundColor: theme.colors.cardDisabled,
    },
    back: {
      zIndex: 5,
      backgroundColor: "transparent",
    },
    "content-center": {
      marginLeft: 0,
      position: "absolute",
      left: 0,
      right: 0,
      zIndex: 0,
    },
    // "content-center-with-menu": {
    //   paddingHorizontal: spacing[7],
    // },
    "content-left": {
      marginLeft: 0,
      paddingLeft: spacing[2],
      paddingRight: 0,
    },
    title: {
      fontSize: 20,
      color: theme.colors.heading,
    },
    "title-center": {
      textAlign: "center",
      marginHorizontal: spacing[6],
    },
    "title-left": {},
    actions: {
      flexDirection: "row",
    },
    action: {
      backgroundColor: theme.colors.tabActiveBG,
    },
    actionText: {
      height: spacing[7],
      lineHeight: spacing[7],
      paddingHorizontal: spacing[5],
      ...theme.fonts.medium,
    },
  });

export enum TopBarType {
  Default = "default",
  Active = "active",
  Disabled = "disabled",
}
export enum TopBarAlign {
  Center = "center",
  Left = "left",
}

type IconSource = string | ImageSourcePropType | ((props) => React.ReactNode);

export type Action = {
  key: string;
  icon?: IconSource;
  color?: string;
  onPress?: (() => void) | undefined;
  text?: string;
  selected?: boolean;
};
type Props = {
  title: string;
  back?: boolean | undefined;
  backHandler?: () => void;
  menuItems?: MenuItem[];
  actions?: Action[];
  type?: TopBarType;
  align?: TopBarAlign;
};

export function TopBar({
  title,
  back,
  backHandler,
  menuItems,
  actions,
  type = TopBarType.Default,
  align = TopBarAlign.Center,
}: Props) {
  const theme = useTheme() as any;
  const navigation = useNavigation();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const colors = {
    [TopBarType.Default]: theme.colors.heading,
    [TopBarType.Disabled]: theme.colors.heading,
    [TopBarType.Active]: theme.colors.card,
  };
  const color = colors[type];

  useHardwareBackPress(backHandler);

  return (
    <Appbar.Header
      statusBarHeight={0}
      style={[styles.header, type === TopBarType.Disabled && styles.disabled]}
    >
      {type === TopBarType.Active && <BackgroundGradient />}
      {back ? (
        <Appbar.BackAction
          color={color}
          onPress={backHandler || navigation.goBack}
          style={styles.back}
        />
      ) : (
        <View />
      )}
      <Appbar.Content
        style={styles[`content-${align}`]}
        title={title}
        titleStyle={[styles.title, styles[`title-${align}`], { color }]}
      />

      {actions?.length ? (
        <View style={styles.actions}>
          {actions.map((action) => {
            if (action.text) {
              return (
                <TouchableOpacity
                  onPress={action.onPress}
                  key={`heading-action-${action.key}`}
                >
                  <Text style={styles.actionText}>{action.text}</Text>
                </TouchableOpacity>
              );
            }

            return (
              <Appbar.Action
                key={`heading-action-${action.key}`}
                style={action.selected && styles.action}
                icon={action.icon}
                color={action.color || color}
                onPress={action.onPress}
              />
            );
          })}
        </View>
      ) : null}

      {menuItems?.length ? (
        <ContextMenu menuItems={menuItems} iconColor={color} />
      ) : null}
    </Appbar.Header>
  );
}
