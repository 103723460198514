import { flow, Instance, types } from "mobx-state-tree";
import dayjs from "dayjs";
// import SmartConfig from "react-native-smartconfig-ash";

import { GrowDirectorProApi } from "../../services/api";
import { connectModuleToServer } from "../../services/connectModuleToServer";
import { DateTimeFormats } from "../../utils/timeConverter";

import { withEnvironment } from "../extensions/with-environment";
import { withStatus } from "../extensions/with-status";
import { withRootStore } from "../extensions/with-root-store";

// const BSSID = "d4:6e:0e:50:db:90";
const TIME_OUT_SMART_CONFIG = 45 * 1000; // 45s
const WAITING_NEW_MS = 15 * 1000; // 15s

export enum ConnectionStatus {
  New = "new",
  Detecting = "detecting",
  Connecting = "connecting",
  Waiting = "waiting",
  Success = "success",
  Error = "error",
}

export const GrowDirectorProStoreModel = types
  .model("GrowDirectorProStore")
  .props({
    connectionStatus: types.maybeNull(
      types.enumeration<ConnectionStatus>(
        "ConnectionStatus",
        Object.values(ConnectionStatus),
      ),
    ),
    token: types.maybeNull(types.string),
    ip: types.maybeNull(types.string),
    uid: types.maybeNull(types.string),
    logs: types.array(types.string),
  })
  .extend(withEnvironment)
  .extend(withStatus)
  .extend(withRootStore)
  .actions((self) => ({
    resetResult: () => {
      self.connectionStatus = ConnectionStatus.New;
      self.token = "";
      self.ip = "";
      self.uid = "";
      self.logs?.clear();
    },
    setLog: (log: string) => {
      self.logs.push(`${dayjs().format(DateTimeFormats.TimeHMS)} - ${log}\n`);
    },
    setConnectionStatus: (status: ConnectionStatus) => {
      self.connectionStatus = status;
    },
    setIP: (ip: string) => {
      self.ip = ip;
    },
    setUid: (uid: string) => {
      self.uid = uid;
    },
  }))
  .actions((self) => {
    const growDirectorProApi = new GrowDirectorProApi(self.environment.api);

    const clear = () => {
      self.resetResult();
    };

    const generateToken = flow(function* () {
      const result = yield growDirectorProApi.generateToken();
      self.token = result.kind === "ok" ? result.data.token : "";
      return self.token;
    });

    const addModule = flow(function* (token: string, uid: string) {
      const result = yield growDirectorProApi.addModule(token, uid);

      if (result.kind === "ok") {
        return result.data;
      }
      return undefined;
    });

    const getModuleList = flow(function* () {
      const result = yield growDirectorProApi.getList();

      if (result.kind === "ok") {
        return result.data;
      }
      return undefined;
    });

    const getModule = flow(function* (uid: string) {
      const result = yield growDirectorProApi.getModule(uid);

      if (result.kind === "ok") {
        return result.data;
      }
      return undefined;
    });

    const waitingNewModule = () => {
      self.connectionStatus = ConnectionStatus.Waiting;
      const startTime = Date.now();

      const timerId = setInterval(async () => {
        const module = await self.rootStore.moduleStore.fetchModule(self.uid);

        if (Date.now() - startTime > WAITING_NEW_MS) {
          clearInterval(timerId);
          self.connectionStatus = ConnectionStatus.Error;
          self.logs.push("Timeout for new module has expired");
          return;
        }

        if (module) {
          clearInterval(timerId);
          self.logs.push(`Module ${self.uid} was added`);
          self.connectionStatus = ConnectionStatus.Success;
        }
      }, 2000);
    };

    const connect = async (ip: string, ssid: string, password: string) => {
      self.ip = ip;
      self.connectionStatus = ConnectionStatus.Connecting;
      self.setStatusPending();

      const token = await generateToken();
      const user = self.rootStore.accountStore.currentUser.email;
      const { accountUid } = self.rootStore.accountStore.currentUser;
      const serverUrl = self.rootStore.settingsStore.DEV_serverUrl;

      self.logs.push(`Connecting to server: ${user} - ${token}`);

      const result = await connectModuleToServer(ip, {
        ssid,
        password,
        accountUid,
        user,
        token,
        server: serverUrl,
      });

      self.setUid(result.uid);

      if (result.ok) {
        self.logs.push(`Module initialized: ${result.uid}`);
        const addResult = await addModule(token, result.uid);
        self.logs.push(JSON.stringify(addResult));

        self.setStatusDone();
        waitingNewModule();
      } else {
        self.logs.push(`Error on connectModuleToServer...`);
        self.setStatusError();
        self.connectionStatus = ConnectionStatus.Error;
      }
    };

    const connectByIP = async (ip: string, ssid: string, password: string) => {
      self.resetResult();
      return connect(ip, ssid, password);
    };

    const searchAndConnect = async (ssid: string, password: string) => {
      self.resetResult();
      self.connectionStatus = ConnectionStatus.Detecting;
      self.logs.push(`Detecting module... ${ssid} - ${password}`);

      // try {
      //   SmartConfig.start(
      //     ssid,
      //     BSSID,
      //     password,
      //     TIME_OUT_SMART_CONFIG,
      //     (event) => {
      //       const { eventName, data } = event;
      //       self.logs.push(`eventName: ${eventName}`);
      //
      //       if (eventName === "onFoundDevice") {
      //         const parsedData = JSON.parse(data);
      //         self.ip = parsedData.ip;
      //         self.logs.push(
      //           `Found device: ${parsedData.ip} - ${parsedData.bssid}`,
      //         );
      //         connect(parsedData.ip, ssid, password);
      //       } else if (!self.ip) {
      //         self.logs.push("Not found");
      //         self.connectionStatus = ConnectionStatus.Error;
      //       }
      //     },
      //   );
      // } catch (e) {
      //   self.logs.push(JSON.stringify(e));
      //   self.connectionStatus = ConnectionStatus.Error;
      // }
    };

    const abortSearching = async () => {
      // try {
      //   SmartConfig.stop();
      // } catch (e) {
      //   self.logs.push(JSON.stringify(e));
      //   self.connectionStatus = ConnectionStatus.Error;
      // }
      self.resetResult();
    };

    return {
      clear,
      generateToken,
      addModule,
      getModuleList,
      getModule,
      connectByIP,
      searchAndConnect,
      abortSearching,
      connect,
    };
  })
  .views((self) => ({
    get searchTimeOut() {
      return TIME_OUT_SMART_CONFIG;
    },
  }));

type GrowDirectorProStoreType = Instance<typeof GrowDirectorProStoreModel>;
export type GrowDirectorProStore = GrowDirectorProStoreType;
