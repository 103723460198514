import React from "react";
import { G, Text as SvgText } from "react-native-svg";

import { palette } from "../../../theme";
import { dateTimeFormat, DateTimeFormats } from "../../../utils/timeConverter";

const FONT_SIZE_SMALL = 12;
const OFFSET_VALUE_Y = 2;
const SYMBOL_LENGTH = 11;

const getDate = (dateTime: Date) => {
  return dateTimeFormat(dateTime, DateTimeFormats.DateDM);
};

export const ScaleY = (props: any) => {
  const {
    height,
    yMax,
    yMin,
    yPrecision,
    offsetScaleY,
    isNormalSizeChart,
    partLength,
  } = props;

  const partsScale = isNormalSizeChart ? Math.round(height / partLength) : 0;
  const scalePart = (height - offsetScaleY * 2) / partsScale;
  const valuePartScale = (yMax - yMin) / partsScale;

  const getValueScale = (numberPosition: number) => {
    return yMax - valuePartScale * numberPosition;
  };

  const getPositionScale = (numberPosition: number) => {
    return Math.round(scalePart * numberPosition + offsetScaleY);
  };

  return (
    <>
      <G x={0} y={offsetScaleY - OFFSET_VALUE_Y}>
        <SvgText
          fill={palette.GrayBlue}
          fontSize={FONT_SIZE_SMALL}
          fontWeight="bold"
        >
          {yMax.toFixed(yPrecision)}
        </SvgText>
      </G>
      {partsScale
        ? Array(partsScale)
            .fill(null)
            .map((item, index) => (
              <G
                x={0}
                y={getPositionScale(index)}
                // eslint-disable-next-line react/no-array-index-key
                key={`scale-y-${index}`}
              >
                <SvgText
                  fill={palette.GrayBlue}
                  fontSize={FONT_SIZE_SMALL}
                  fontWeight="bold"
                >
                  {index > 0 ? getValueScale(index).toFixed(yPrecision) : null}
                </SvgText>
              </G>
            ))
        : null}
      <G
        x={0}
        y={isNormalSizeChart ? height - offsetScaleY : height - OFFSET_VALUE_Y}
      >
        <SvgText
          fill={palette.GrayBlue}
          fontSize={FONT_SIZE_SMALL}
          fontWeight="bold"
        >
          {yMin.toFixed(yPrecision)}
        </SvgText>
      </G>
    </>
  );
};

const getParts = (data = [], dates = [], count = 2) => {
  const newPartsScaleX = [];
  if (!dates.length || !data.length) return newPartsScaleX;

  const countIndex = data.length;
  const stepIndex = Math.trunc(countIndex / count);

  Array(count + 1)
    .fill(null)
    .forEach((item, index) => {
      const arrayIndex = index === 0 ? index : stepIndex * index;
      const value = dates.find(
        (element) => element.value === getDate(data[arrayIndex]?.dateTime),
      );
      if (value && arrayIndex !== data.length - 1) {
        newPartsScaleX.push(value);
      }
    });

  return newPartsScaleX;
};

const getPartsScaleX = (arrayDate = []) => {
  const partsScaleX = [];
  let arrayDateTime = [];
  if (arrayDate.length === 0) return partsScaleX;

  arrayDate.forEach((element, index) => {
    const isDate = arrayDateTime.includes(getDate(element.dateTime));
    if (!isDate) {
      arrayDateTime = [...arrayDateTime, getDate(element.dateTime)];
      partsScaleX.push({ indexValue: index, value: getDate(element.dateTime) });
    }
  });

  return partsScaleX;
};

export const ScaleX = (props: any) => {
  const {
    data,
    height,
    width,
    offsetScaleYFromSize,
    offsetScaleX,
    labelsCount,
  } = props;

  let partsScale = getPartsScaleX(data);

  if (partsScale.length > labelsCount) {
    partsScale = getParts(data, partsScale, labelsCount);
  }

  const getPositionScale = (index: number) => {
    const newPartLength = (width - offsetScaleYFromSize) / data.length;
    const position =
      offsetScaleYFromSize + newPartLength * (partsScale[index].indexValue - 1);

    const remainingLength =
      width - (partsScale[index].indexValue - 1) * newPartLength;

    const labelLength = partsScale[index].value.length * SYMBOL_LENGTH;
    const correctPosition =
      remainingLength - labelLength < 0 ? remainingLength - labelLength : 0;

    return position + correctPosition;
  };

  return (
    <>
      {partsScale.map((item, index) => (
        <G
          x={getPositionScale(index)}
          y={height - OFFSET_VALUE_Y}
          key={`scale-x-${item.value}`}
        >
          <SvgText
            fill={palette.GrayBlue}
            fontSize={FONT_SIZE_SMALL}
            fontWeight="bold"
          >
            {item.value}
          </SvgText>
        </G>
      ))}
    </>
  );
};

export const ScaleXDiagram = (props: any) => {
  const { data, height, width, offsetScaleX, labelsCount } = props;

  let partsScale = getPartsScaleX(data);
  if (partsScale.length > labelsCount) {
    partsScale = getParts(data, partsScale, labelsCount);
  }

  const getPositionScale = (index: number) => {
    const newPartLength = (width + offsetScaleX) / partsScale.length;
    return newPartLength * index;
  };

  return (
    <>
      {partsScale.map((item, index) => (
        <G
          x={getPositionScale(index)}
          y={height - OFFSET_VALUE_Y}
          key={`scale-x-${item.value}`}
        >
          <SvgText
            fill={palette.GrayBlue}
            fontSize={FONT_SIZE_SMALL}
            fontWeight="bold"
          >
            {item.value}
          </SvgText>
        </G>
      ))}
    </>
  );
};
