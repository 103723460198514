import React, { useMemo } from "react";
import { View, StyleSheet, StyleProp, TextStyle } from "react-native";

import { spacing } from "../../theme";
import { useAppTheme } from "../../hooks";
import { translate, TxKeyPath } from "../../i18n";
import { Text } from "../ui/Text";
import Switch from "../ui/Switch";
import { Label } from "./Label";

const createStyles = (theme: any) =>
  StyleSheet.create({
    container: {
      // width: "100%",
      flex: 1,
      marginVertical: spacing[2],
    },
    inputContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: spacing[1],
      // flexWrap: "nowrap",
      // flex: 1,
    },
    error: {
      fontSize: 12,
      color: theme.colors.error,
      paddingTop: spacing[1],
    },
    description: {
      fontSize: 12,
      marginTop: spacing[1],
      paddingHorizontal: spacing[1],
      color: theme.colors.label,
    },
  });

type Props = {
  onChange?: (value: boolean) => void;
  value?: boolean;
  errorText?: string;
  hasError?: boolean;
  txLabel?: TxKeyPath;
  label?: string;
  description?: string;
  txDescription?: TxKeyPath;
  labelStyle?: StyleProp<TextStyle>;
  containerStyle?: StyleProp<TextStyle>;
};

export const SwitchInput = ({
  onChange,
  value = false,
  errorText,
  hasError,
  txLabel,
  label,
  description,
  txDescription,
  labelStyle,
  containerStyle,
}: Props) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  const i18nLabel = txLabel && translate(txLabel);
  const labelContent = i18nLabel || label;
  const i18nDescription = txDescription && translate(txDescription);
  const descriptionContent = i18nDescription || description;

  return (
    <View style={[styles.container, containerStyle]}>
      <View style={styles.inputContainer}>
        {labelContent ? (
          <Label
            numberOfLines={1}
            ellipsizeMode="tail"
            style={labelStyle}
            isError={!!errorText || hasError}
          >
            {labelContent}
          </Label>
        ) : null}
        <Switch onValueChange={onChange} value={value} />
      </View>

      {descriptionContent ? (
        <Text style={styles.description}>{descriptionContent}</Text>
      ) : null}
      {errorText ? <Text style={styles.error}>{errorText}</Text> : null}
    </View>
  );
};
