import { StyleSheet, View } from "react-native";
import { shadow } from "react-native-paper";

import { DefaultTheme, spacing } from "../../../../../theme";
import { AddIcon } from "../../../../../svgs/menu";

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    backgroundColor: DefaultTheme.colors.background,
    padding: spacing[1],
    borderRadius: spacing[2],
    top: 22,
    right: 64,
    ...(shadow(5) as any),
  },
});

export const ButtonAdd = () => (
  <View style={styles.container}>
    <AddIcon />
  </View>
);
