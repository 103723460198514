import React from "react";
import { Text as PaperText } from "react-native-paper";
import { TranslateOptions } from "i18n-js";

import { translate, TxKeyPath } from "../../i18n";

type Props = React.ComponentProps<typeof PaperText> & {
  tx?: TxKeyPath;
  txOptions?: TranslateOptions;
  children?: React.ReactNode;
};

export function Text({ tx, txOptions, children, ...props }: Props) {
  const i18nText = tx && translate(tx, txOptions);

  return (
    <PaperText {...props}>
      {i18nText}
      {children}
    </PaperText>
  );
}
