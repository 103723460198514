import React, { useCallback, useMemo } from "react";
import { View, StyleSheet, Pressable } from "react-native";
import { observer } from "mobx-react-lite";
import { useNavigation, useTheme } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";

import { Module } from "@models/module/module";
import { UpdateStatus } from "@models/module/module-update";
import { Badge, Button } from "@components/ui";

import { defaultColors, palette, spacing } from "../../../../theme";
import { translate } from "../../../../i18n";
import { ModulesNavigatorParamList } from "../../navigation";
import { HelpIcon } from "../../../../svgs/menu";
import { ModuleDetailsImage } from "./DetailsImage";
import { NoFileSystemBadge } from "./NoFileSystemBadge";

const createStyles = (theme: any, opacity: number) =>
  StyleSheet.create({
    container: {
      alignItems: "center",
      justifyContent: "flex-start",
      paddingHorizontal: spacing[4],
      paddingTop: spacing[4],
      paddingBottom: spacing[6],
    },
    badgePosition: {
      position: "absolute",
      top: 36,
      left: 64,
      zIndex: 10,
    },
    badgeRightPosition: {
      left: "auto",
      right: 48,
    },
    buttonInfo: {
      paddingHorizontal: spacing[4],
      backgroundColor: theme.colors.inputBG,
      marginTop: spacing[2],
    },
  });

export const IconInfo = () => <HelpIcon color={defaultColors.link} size={24} />;

type ModuleDetailsPreviewProps = {
  module: Module;
  scrollItem?: number;
};

export const ModuleDetailsPreview: React.FunctionComponent<ModuleDetailsPreviewProps> =
  observer(({ module, scrollItem }) => {
    const opacity = module.isOnline ? 1 : 0.6;
    const theme = useTheme() as any;
    const styles = useMemo(
      () => createStyles(theme, opacity),
      [theme, opacity],
    );
    const navigation =
      useNavigation() as StackNavigationProp<ModulesNavigatorParamList>;

    const updateHandler = useCallback(() => {
      module.update.setStatus(UpdateStatus.Ready);
      navigation.navigate("module-update", { uid: module.uid });
    }, [module.uid]);

    const goToInfo = useCallback(() => {
      navigation.navigate("module-info", { uid: module.uid });
    }, [module.uid]);

    return (
      <View style={styles.container}>
        {!module.isOnline && (
          <Badge
            text={translate("Modules.state.offline")}
            style={styles.badgePosition}
          />
        )}

        {module.isOnline && module.isFirmwareUpdateAvailable && (
          <Pressable style={styles.badgePosition} onPress={updateHandler}>
            <Badge
              text={translate("Modules.state.update")}
              gradientColors={[palette.OrangeG2, palette.OrangeG1]}
            />
          </Pressable>
        )}

        {module.isOnline &&
          module.isNew &&
          !module.isFirmwareUpdateAvailable && (
            <Badge
              text={translate("Modules.state.new")}
              gradientColors={[palette.Green, palette.Green]}
            />
          )}

        {!module.hasFileSystem && <NoFileSystemBadge />}

        <ModuleDetailsImage
          moduleType={module.type}
          disabled={!module.isOnline}
          currentItem={scrollItem}
        />

        <Button
          type="link"
          icon={IconInfo}
          iconPosition="right"
          style={styles.buttonInfo}
          onPress={goToInfo}
        >
          {translate("Modules.Details.modulInfo")}
        </Button>
      </View>
    );
  });
