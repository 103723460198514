import React, { memo } from "react";
import { Circle } from "react-native-svg";

import { SensorState } from "@models/sensor/sensor";
import { palette } from "../../../theme";

export const OfflinePoints = memo(({ x, y, data }: any) => {
  return data.reduce((result, item, index) => {
    if (
      [
        SensorState.Offline,
        SensorState.Disconnect,
        SensorState.InvalidValue,
      ].includes(item?.state)
    ) {
      result.push(
        <Circle
          key={item.dateTime}
          cx={x(index)}
          cy={y(item.value)}
          r={2}
          fill={palette.RedNew}
        />,
      );
    }

    if (item?.state === SensorState.Calibration) {
      result.push(
        <Circle
          key={item.dateTime}
          cx={x(index)}
          cy={y(item.value)}
          r={4}
          fill={palette.PurpleG1}
        />,
      );
    }

    return result;
  }, []);
});
