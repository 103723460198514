import Svg, { Path } from "react-native-svg";
import { palette } from "../../theme";

export default function HistoryFilter({ color = palette.Black, size = 24 }) {
  return (
    <Svg width={size} height={size} fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 3.25a.75.75 0 0 1 .75.75v3.354a2.751 2.751 0 0 1 0 5.292V20a.75.75 0 0 1-1.5 0v-7.354a2.751 2.751 0 0 1 0-5.292V4A.75.75 0 0 1 6 3.25Zm0 8a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5Zm6 3.5a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5ZM14.75 16c0 1.259-.846 2.32-2 2.646V20a.75.75 0 0 1-1.5 0v-1.354a2.751 2.751 0 0 1 0-5.293V4a.75.75 0 0 1 1.5 0v9.354c1.154.326 2 1.387 2 2.646Zm2-9a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Zm2 2.646a2.751 2.751 0 0 0 0-5.292V4a.75.75 0 0 0-1.5 0v.354a2.751 2.751 0 0 0 0 5.292V20a.75.75 0 0 0 1.5 0V9.646Z"
        fill={color}
      />
    </Svg>
  );
}
