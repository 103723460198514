export default function byUid(data: any[]): Record<string, any> | undefined {
  if (!data) return undefined;
  return data.reduce(
    (prevValue, currentData) => ({
      ...prevValue,
      [currentData.uid]: currentData,
    }),
    {},
  );
}
