import React from "react";
import { observer } from "mobx-react-lite";
import { StackScreenProps } from "@react-navigation/stack";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { useTheme } from "@react-navigation/native";

import { useStores } from "@models/index";
import {
  TemperatureUnit,
  TimeSystem,
  VolumeUnit,
} from "@models/settings/user-settings-store";
import { Screen } from "@components/index";
import {
  Heading,
  Dialog,
  useDialog,
  Text,
  SelectionMenu,
  useCopyToClipboard,
  CopyToClipboard,
} from "@components/ui";

import { SettingsNavigatorParamList } from "./navigation";
import { spacing, palette } from "../../theme";
import {
  AccountIcon,
  HelpIcon,
  LanguageIcon,
  LogOutIcon,
  BellIcon,
  SupportIcon,
  UnitsIcon,
} from "../../svgs/menu";
import { translate } from "../../i18n";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
    paddingTop: spacing[4],
    paddingHorizontal: spacing[4],
  },
  versionContainer: {
    marginVertical: spacing[2],
  },
  version: {
    color: palette.GrayBlue,
    fontSize: 12,
  },
});

export const SettingsScreen: React.FC<
  StackScreenProps<SettingsNavigatorParamList, "settings-list">
> = observer(({ navigation }) => {
  const { accountStore, settingsStore } = useStores();
  const { showDialog: showLogOutDialog, ...dialogProps } = useDialog({
    onOk: accountStore.logout,
  });
  const theme = useTheme() as any;

  const {
    copyToClipboard,
    copiedItem,
    onDismiss: onDismissCopy,
  } = useCopyToClipboard();

  const accountHandler = () => navigation.navigate("settings-account");
  const notificationsHandler = () =>
    navigation.navigate("settings-notifications");
  const helpHandler = () => navigation.navigate("settings-help");
  const supportHandler = () => navigation.navigate("settings-support");
  const languageHandler = () => navigation.navigate("settings-language");
  const unitsHandler = () => navigation.navigate("settings-units");

  const unitDescription = [
    translate(
      `Settings.Formats.Volumes.${
        settingsStore.currentVolumeUnit as VolumeUnit
      }`,
    ),
    translate(
      `Settings.Formats.Temperature.${
        settingsStore.currentTemperatureUnit as TemperatureUnit
      }`,
    ),
    translate(
      `Settings.Formats.Times.${settingsStore.currentTimeSystem as TimeSystem}`,
    ),
  ].join(", ");

  const copyVersionInfo = () => {
    const data = `${translate("Settings.clientVersion")}: ${
      settingsStore.versions?.currentAppVersion
    },\r\n${translate("Settings.apiVersion")}: ${
      settingsStore.versions?.api
    },\r\n${translate("Settings.firmwareLastVersion")}: ${
      settingsStore.latestFWVersion
    }`;

    copyToClipboard(data, translate("Settings.versionInfo"));
  };

  return (
    <Screen testID="Settings" preset="scroll">
      <Heading style={{ paddingHorizontal: spacing[4] }} tx="Settings.title" />

      <View style={styles.container}>
        <SelectionMenu>
          <SelectionMenu.Item
            title={translate("Settings.notificationCenter")}
            description={translate("Settings.MessageAlerts")}
            icon={BellIcon}
            onPress={notificationsHandler}
          />
          <SelectionMenu.Item
            title={translate("Settings.account")}
            description="Privacy, integrations, email"
            icon={AccountIcon}
            onPress={accountHandler}
          />
          <SelectionMenu.Item
            title={translate("Settings.language")}
            description={settingsStore.currentLanguageProps.name}
            icon={LanguageIcon}
            onPress={languageHandler}
          />
          <SelectionMenu.Item
            title={translate("Settings.units")}
            description={unitDescription}
            icon={UnitsIcon}
            onPress={unitsHandler}
          />
          <SelectionMenu.Item
            title={translate("Settings.help")}
            description={translate("Settings.faqManual")}
            icon={HelpIcon}
            onPress={helpHandler}
          />
          <SelectionMenu.Item
            title={translate("Settings.contactSupport.title")}
            description={translate("Settings.liveChat")}
            icon={SupportIcon}
            onPress={supportHandler}
          />
          <SelectionMenu.Item
            title={translate("Settings.logOut")}
            color={theme.colors.warning}
            icon={LogOutIcon}
            onPress={showLogOutDialog}
            type="button"
          />
        </SelectionMenu>

        <TouchableOpacity
          style={styles.versionContainer}
          onPress={copyVersionInfo}
        >
          <Text style={styles.version} tx="Settings.clientVersion">
            : {settingsStore.versions?.currentAppVersion}
          </Text>
          <Text style={styles.version} tx="Settings.apiVersion">
            : {settingsStore.versions?.api}
          </Text>
          <Text style={styles.version} tx="Settings.firmwareLastVersion">
            : {settingsStore.latestFWVersion}
          </Text>
        </TouchableOpacity>
      </View>

      <CopyToClipboard copiedItem={copiedItem} onDismiss={onDismissCopy} />

      <Dialog
        {...dialogProps}
        title={translate("Settings.logout?")}
        content={translate("Settings.youWantToLogout")}
        okText={translate("common.yes")}
        okType="warning"
      />
    </Screen>
  );
});
