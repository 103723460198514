import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { palette } from "../../theme";

export default function HistoryIcon({ color = palette.Black, size = 24 }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9093 3.66265C13.9362 3.6395 15.9007 4.36338 17.428 5.69619C18.9553 7.02899 19.9384 8.87743 20.1898 10.8888C20.4412 12.9002 19.9434 14.9338 18.7912 16.6015C17.639 18.2693 15.9131 19.4544 13.9428 19.9309C11.9726 20.4074 9.89588 20.1419 8.10891 19.185C6.64773 18.4025 5.46391 17.2026 4.70059 15.7501H8.04986C8.46408 15.7501 8.79986 15.4143 8.79986 15.0001C8.79986 14.5859 8.46408 14.2501 8.04986 14.2501H3.56724C3.55564 14.2498 3.54401 14.2498 3.53236 14.2501H3.04986C2.63565 14.2501 2.29986 14.5859 2.29986 15.0001V20.0001C2.29986 20.4143 2.63565 20.7501 3.04986 20.7501C3.46408 20.7501 3.79986 20.4143 3.79986 20.0001V17.1811C4.69105 18.5686 5.92645 19.7178 7.4008 20.5073C9.51267 21.6382 11.967 21.952 14.2954 21.3889C16.6239 20.8258 18.6636 19.4251 20.0253 17.4541C21.387 15.4832 21.9754 13.0799 21.6782 10.7028C21.3811 8.32567 20.2192 6.14115 18.4143 4.56602C16.6093 2.99088 14.2876 2.13539 11.8922 2.16275C9.49673 2.19011 7.19519 3.09841 5.42668 4.71436C3.65818 6.33031 2.54649 8.54079 2.30372 10.9241C2.26175 11.3361 2.56178 11.7042 2.97386 11.7462C3.38594 11.7882 3.75403 11.4882 3.796 11.0761C4.00142 9.05946 4.94208 7.18905 6.43851 5.82171C7.93494 4.45436 9.88239 3.6858 11.9093 3.66265ZM12.7499 8.00007C12.7499 7.58586 12.4141 7.25007 11.9999 7.25007C11.5856 7.25007 11.2499 7.58586 11.2499 8.00007V12.0001C11.2499 12.199 11.3289 12.3897 11.4695 12.5304L13.4695 14.5304C13.7624 14.8233 14.2373 14.8233 14.5302 14.5304C14.8231 14.2375 14.8231 13.7626 14.5302 13.4697L12.7499 11.6894V8.00007Z"
        fill={color}
      />
    </Svg>
  );
}
