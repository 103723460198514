import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { palette } from "../../theme";

export default function UndefinedDeviceIcon({
  color = palette.Gray,
  size = 24,
}) {
  return (
    <Svg width={size} height={size} viewBox="0 0 36 36" fill="none">
      <Path
        d="M30 18C30 24.6274 24.6274 30 18 30C11.3726 30 6 24.6274 6 18C6 11.3726 11.3726 6 18 6C24.6274 6 30 11.3726 30 18Z"
        fill={color}
      />
    </Svg>
  );
}
