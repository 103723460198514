import { ApiResponse } from "apisauce";

import { EspApi } from "./api";

export type ModuleConnectResult = {
  ip: string;
  ok: boolean;
  uid: string | null;
  logs: Record<string, any>[];
};

export type ModuleConnectProps = {
  ssid: string;
  password: string;
  accountUid: string;
  user: string;
  token: string;
  server: string;
};

export const connectModuleToWiFi = async (
  ip: string,
  ssid: string,
  password: string,
  log?: (value: string) => void,
): Promise<any> => {
  const espApi = new EspApi(ip);

  const modeResult = await espApi.mode();
  const { data: modeData } = modeResult;
  log?.(`modeData: ${JSON.stringify(modeData)}`);

  if (modeData.mode === 162) {
    const setTestSuccess = await espApi.setTestSuccess();
    log?.(`setTestSuccess: ${JSON.stringify(setTestSuccess)}`);
  }
  if (modeData.mode === 106) {
    const reset = await espApi.reset();
    log?.(`reset: ${JSON.stringify(reset)}`);
  }

  return espApi.connectToWifi(ssid, password);
};

export const wifiStatusOk = async (ip: string): Promise<boolean> => {
  const espApi = new EspApi(ip);

  const statusResult = await espApi.wifiStatus();
  const { data: statusData } = statusResult;
  return statusData.connected;
};

export const connectModuleToServer = async (
  ip: string,
  { ssid, password, accountUid, user, token, server }: ModuleConnectProps,
): Promise<ModuleConnectResult> => {
  const result: ModuleConnectResult = {
    ip,
    ok: true,
    uid: null,
    logs: [],
  };

  const logResult = (
    method: string,
    response: ApiResponse<any>,
    params?: Record<string, any>,
  ) => {
    const { ok, data, problem } = response;
    result.logs.push({ method, ok, data, problem, params });
  };

  const espApi = new EspApi(ip);

  // const testResult = await espApi.test();
  // logResult("test", testResult);

  const modeResult = await espApi.mode();
  logResult("mode", modeResult);
  const { data: modeData } = modeResult;
  if (modeData.mode !== 42 && modeData.mode !== 142) {
    result.ok = false;
    return result;
  }
  if (modeData.mode === 162) {
    // TODO: ???
    const setTestSuccess = await espApi.setTestSuccess();
    logResult("setTestSuccess", setTestSuccess);
  }
  if (modeData.mode === 106) {
    // TODO: ???
    const reset = await espApi.reset();
    logResult("reset", reset);
  }

  // const connectToWifiResult = await espApi.connectToWifi(ssid, password);
  // logResult("connectToWifi", connectToWifiResult);

  const statusResult = await espApi.wifiStatus();
  logResult("wifiStatus", statusResult);
  const { data: statusData } = statusResult;
  if (!statusData.connected) {
    result.ok = false;
    return result;
  }

  const uidResult = await espApi.uid();
  logResult("uid", uidResult);
  const { data: uidData } = uidResult;
  result.uid = uidData.uid;

  const connectParams = {
    accountUid,
    user,
    token,
    ssid,
    password,
    server,
  };
  const connectResult = await espApi.connectToServer(connectParams);
  logResult("connectToServer", connectResult, connectParams);

  return result;
};
