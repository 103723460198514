import * as React from "react";
import { View } from "react-native";
import Svg, { G, Rect, Path, SvgProps } from "react-native-svg";
import { spacing } from "../../../../theme";

export const CalibrationSoilMoistureAirProcessingSvg = (props: SvgProps) => (
  <View style={{ marginTop: -spacing[8] }}>
    <Svg width={360} height={312} viewBox="0 0 360 312" fill="none" {...props}>
      <G clipPath="url(#clip0_314_654)">
        <Path
          d="M253.855 280.086H102.235C85.0047 280.086 74.3347 261.266 83.1747 246.435L157.565 121.59C166.765 106.147 189.075 106.137 198.295 121.56L272.895 246.404C281.765 261.236 271.105 280.086 253.855 280.086Z"
          fill="#C4E1FE"
          fillOpacity={0.4}
        />
        <Path
          d="M65.6985 174.038H90.9285C93.7985 174.038 95.5685 177.175 94.0985 179.64L81.7185 200.414C80.1885 202.98 76.4785 202.99 74.9385 200.414L62.5285 179.64C61.0585 177.175 62.8285 174.038 65.6985 174.038Z"
          fill="#C4E1FE"
          fillOpacity={0.4}
        />
        <Path
          d="M241.501 157.478C238.693 157.478 236.418 155.199 236.418 152.384V139.145C236.418 136.331 238.693 134.051 241.501 134.051C244.31 134.051 246.585 136.331 246.585 139.145V152.392C246.577 155.199 244.302 157.478 241.501 157.478Z"
          fill="#308AE3"
        />
        <Path
          d="M241.501 221.227C238.693 221.227 236.418 218.947 236.418 216.133V202.886C236.418 200.071 238.693 197.792 241.501 197.792C244.31 197.792 246.585 200.071 246.585 202.886V216.133C246.577 218.947 244.302 221.227 241.501 221.227Z"
          fill="#308AE3"
        />
        <Path
          d="M261.615 177.643C261.615 174.828 263.89 172.549 266.699 172.549H279.917C282.726 172.549 285.001 174.828 285.001 177.643C285.001 180.457 282.726 182.737 279.917 182.737H266.699C263.89 182.729 261.615 180.449 261.615 177.643Z"
          fill="#308AE3"
        />
        <Path
          d="M198 177.643C198 174.828 200.275 172.549 203.084 172.549H216.302C219.111 172.549 221.386 174.828 221.386 177.643C221.386 180.457 219.111 182.737 216.302 182.737H203.084C200.275 182.729 198 180.449 198 177.643Z"
          fill="#308AE3"
        />
        <Path
          d="M255.725 163.382C253.74 161.393 253.74 158.17 255.725 156.181L265.068 146.817C267.053 144.828 270.269 144.828 272.254 146.817C274.239 148.806 274.239 152.03 272.254 154.019L262.911 163.382C260.926 165.371 257.71 165.371 255.725 163.382Z"
          fill="#308AE3"
        />
        <Path
          d="M210.741 208.46C208.756 206.471 208.756 203.248 210.741 201.259L220.084 191.896C222.069 189.907 225.285 189.907 227.27 191.896C229.255 193.885 229.255 197.108 227.27 199.097L217.926 208.46C215.942 210.449 212.725 210.449 210.741 208.46Z"
          fill="#308AE3"
        />
        <Path
          d="M210.741 146.817C212.725 144.828 215.942 144.828 217.926 146.817L227.27 156.181C229.255 158.17 229.255 161.393 227.27 163.382C225.285 165.371 222.069 165.371 220.084 163.382L210.741 154.019C208.756 152.03 208.756 148.806 210.741 146.817Z"
          fill="#308AE3"
        />
        <Path
          d="M166.445 225.889V263.11"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M178.038 225.889V263.11"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M172.874 225.889V263.11"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M189.632 225.889V263.11"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M178.438 169.05V166.547C178.438 148.773 164.028 134.363 146.254 134.363C128.48 134.363 114.069 148.773 114.069 166.547V225.166C114.069 242.94 99.6589 257.351 81.8848 257.351H78.8714C61.0974 257.351 46.687 242.94 46.687 225.166V216.951C46.687 199.177 32.2766 184.766 14.5025 184.766H-12.8882"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Rect
          width={34.1699}
          height={42.7124}
          rx={3}
          transform="matrix(-1 0 0 1 195.123 183.177)"
          fill="#6A7B8C"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M183.53 172.753C183.53 171.096 182.187 169.753 180.53 169.753H175.547C173.89 169.753 172.547 171.096 172.547 172.753V183.177H183.53V172.753Z"
          fill="#6A7B8C"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M189.632 189.128V207.029"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
        <Path
          d="M189.632 212.522V218.624"
          stroke="#2B2E32"
          strokeWidth={2.2615}
          strokeMiterlimit={10}
          strokeLinejoin="round"
        />
      </G>
    </Svg>
  </View>
);
