import Svg, { Path } from "react-native-svg";

export default function ECHydroIcon({ color = "#6F6F6F", size = 24 }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.598 4.691a.75.75 0 0 0-1.257 0l-4.91 7.554a6.416 6.416 0 1 0 11.953 3.242.75.75 0 0 0-1.5 0A4.915 4.915 0 1 1 6.7 13.044l4.27-6.569.598.92a.75.75 0 1 0 1.257-.816L11.6 4.69Zm6.167 1.427a.75.75 0 0 0-1.2-.9l-3.4 4.532a.75.75 0 0 0 .6 1.2h1.9l-2.5 3.332a.75.75 0 1 0 1.2.9l3.4-4.533a.75.75 0 0 0-.6-1.2h-1.899l2.5-3.331Z"
        fill={color}
      />
    </Svg>
  );
}
