import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";

import {
  SignInScreen,
  SignUpScreen,
  ForgotPasswordScreen,
  RecoveryPasswordScreen,
  SetNewPasswordScreen,
} from "@screens/Auth";
import { Onboarding } from "@screens/Onboarding";
import {
  RegistrationSuccess,
  PasswordRecoverySuccess,
} from "@screens/StatusScreens";
import { DEVSettingsScreen } from "@screens/Settings/DEV";
import { Page } from "@screens/Pages";

export type GuestNavigatorParamList = {
  ConnectionLost: undefined;
  SignIn: undefined;
  SignUp: undefined;
  ForgotPassword: undefined;
  RecoveryPassword: { username: string };
  SetNewPassword: { recoveryToken: string };
  RegistrationSuccess: undefined;
  PasswordRecoverySuccess: undefined;
  Onboarding: undefined;
  "dev-settings": undefined;
  Page: { id: string };
};

const Stack = createNativeStackNavigator<GuestNavigatorParamList>();
export function GuestStack() {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
      initialRouteName="SignIn"
    >
      <Stack.Screen name="SignIn" component={SignInScreen} />
      <Stack.Screen name="Onboarding" component={Onboarding} />
      <Stack.Screen name="SignUp" component={SignUpScreen} />
      <Stack.Screen name="Page" component={Page} />
      <Stack.Screen name="ForgotPassword" component={ForgotPasswordScreen} />
      <Stack.Screen
        name="RecoveryPassword"
        component={RecoveryPasswordScreen}
      />
      <Stack.Screen name="SetNewPassword" component={SetNewPasswordScreen} />
      <Stack.Screen
        name="RegistrationSuccess"
        component={RegistrationSuccess}
      />
      <Stack.Screen
        name="PasswordRecoverySuccess"
        component={PasswordRecoverySuccess}
      />

      <Stack.Screen name="dev-settings" component={DEVSettingsScreen} />
    </Stack.Navigator>
  );
}
