import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { palette } from "../theme";

export default function ChevronDownIcon({ color = palette.Gray, size = 24 }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5399 6.4794C19.8274 6.77757 19.8188 7.25236 19.5206 7.53988L10.1873 16.5399C9.89674 16.82 9.4366 16.82 9.14607 16.5399L4.4794 12.0399C4.18123 11.7524 4.1726 11.2776 4.46012 10.9794C4.74764 10.6812 5.22243 10.6726 5.5206 10.9601L9.66667 14.9581L18.4794 6.46012C18.7776 6.1726 19.2524 6.18123 19.5399 6.4794Z"
        fill={color}
      />
    </Svg>
  );
}
