import * as React from "react";
import { TextStyle, StyleSheet, StyleProp } from "react-native";
import { useTheme } from "@react-navigation/native";

import { Text } from "./Text";
import { spacing } from "../../theme";
import { normalizeFontSize } from "../../utils/sizes";

const styles = StyleSheet.create({
  text: {
    fontSize: normalizeFontSize(32),
    lineHeight: 44,
    marginVertical: spacing[4],
  },
  "level-2": {
    fontSize: normalizeFontSize(24),
  },
  "level-3": {
    fontSize: normalizeFontSize(18, 20),
  },
});

type Props = React.ComponentProps<typeof Text> & {
  style?: StyleProp<TextStyle>;
  level?: number;
};

export function Heading(props: Props) {
  const { colors, fonts } = useTheme() as any;
  const { style, level = 1 } = props;
  const levelStyle = styles[`level-${level}`] || styles.text;
  const themeStyle = {
    ...fonts.medium,
    color: colors.heading,
  };

  return <Text {...props} style={[levelStyle, themeStyle, style]} />;
}
