import React, { useMemo } from "react";
import { Image, StyleSheet, View } from "react-native";

import { MODULE_IMAGES } from "@assets/modules";
import { Module } from "@models/module/module";
import { CustomControl } from "@components/inputs";
import { Text } from "@components/ui";

import { useAppTheme } from "../../../hooks";
import { ChevronRightIcon } from "../../../svgs";
import { spacing } from "../../../theme";

const createStyles = (theme: any) =>
  StyleSheet.create({
    image: {
      width: 90,
      height: 57,
    },
    box: {
      flex: 1,
      paddingVertical: spacing[1],
    },
    name: {
      fontSize: 14,
      marginTop: spacing[1],
      marginBottom: spacing[2],
    },
    text: {
      color: theme.colors.label,
      fontSize: 12,
    },
  });

type ModuleCompactCardProps = {
  module: Module;
  onPress?: (module: Module) => void;
  description?: string;
};

export const ModuleCompactCard = ({
  module,
  onPress,
  description,
}: ModuleCompactCardProps) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const onPressHandler = () => onPress?.(module);

  return (
    <CustomControl
      onPress={onPressHandler}
      icon={
        <Image
          style={styles.image}
          source={MODULE_IMAGES[module.type] || MODULE_IMAGES.default}
        />
      }
      control={<ChevronRightIcon />}
    >
      <View style={styles.box}>
        <Text style={styles.text}>{module.typeName}</Text>
        <Text style={styles.name} numberOfLines={1} ellipsizeMode="tail">
          {module.name}
        </Text>
        {description ? <Text style={styles.text}>{description}</Text> : null}
      </View>
    </CustomControl>
  );
};
