import { TranslateOptions } from "i18n-js";

import { i18n, TxKeyPath } from "./i18n";

/**
 * Translates text.
 *
 * @param key The i18n key.
 */
/* eslint-disable import/prefer-default-export */
export function translate(key: TxKeyPath, options?: TranslateOptions) {
  return key ? i18n.t(key, options) : null;
}
