import { MenuItem } from "@components/ui";

export type MenuItemActions = Record<string, () => void>;

export const addActionsToMenuItems = (
  menuItems: MenuItem[],
  actions: MenuItemActions,
) =>
  menuItems.map((item) => ({
    ...item,
    onPress: actions[item.key],
  }));
