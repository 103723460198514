import * as React from "react";
import Svg, { SvgProps, G, Path, Defs, ClipPath } from "react-native-svg";

export const CalibrationECAirProcessSvg = (props: SvgProps) => (
  <Svg width={287} height={173} fill="none" {...props}>
    <G clipPath="url(#a)">
      <Path
        fill="#C4E1FE"
        fillOpacity={0.4}
        d="M215.945 170.586H64.325c-17.23 0-27.9-18.82-19.06-33.651l74.39-124.845c9.2-15.443 31.51-15.453 40.73-.03l74.6 124.844c8.87 14.832-1.79 33.682-19.04 33.682Z"
      />
      <Path
        stroke="#2A2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="M140.721 29.473v-1.73c0-12.284-10.012-22.243-22.36-22.243-12.354 0-22.365 9.96-22.365 22.243v40.514c0 12.284-10.012 22.243-22.36 22.243h-2.094c-12.348 0-22.36-9.96-22.36-22.243"
      />
      <Path
        fill="#6A7B8C"
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="m144.789 42.879-2.768-21.058h-2.6l-2.768 21.058 4.068-.284 4.068.284ZM148.309 42.879h-15.108v15.108h15.108V42.88ZM148.309 121.893h-15.108v3.039h15.108v-3.039Z"
      />
      <Path
        stroke="#2A2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="M145.303 150.593h-9.104a2.995 2.995 0 0 1-2.998-2.998v-14.283h15.108v14.283a3.006 3.006 0 0 1-3.006 2.998Z"
      />
      <Path
        fill="#6A7B8C"
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="M145.067 133.312h-8.624v13.721h8.624v-13.721ZM150.069 57.98h-18.635v63.906h18.635V57.981Z"
      />
      <Path
        stroke="#2A2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="M150.069 124.925h-18.635v8.387h18.635v-8.387ZM133.303 47.764h15.217M133.303 52.739h15.217"
      />
      <Path
        fill="#308AE3"
        d="M219.591 24.928a5.088 5.088 0 0 1-5.083-5.095V6.594a5.088 5.088 0 0 1 5.083-5.094 5.088 5.088 0 0 1 5.084 5.094v13.247c-.008 2.807-2.283 5.087-5.084 5.087ZM219.591 88.676a5.088 5.088 0 0 1-5.083-5.094V70.335a5.088 5.088 0 0 1 5.083-5.094 5.088 5.088 0 0 1 5.084 5.094v13.247c-.008 2.814-2.283 5.094-5.084 5.094ZM239.705 45.092a5.088 5.088 0 0 1 5.084-5.094h13.218a5.088 5.088 0 0 1 5.084 5.094 5.088 5.088 0 0 1-5.084 5.094h-13.218a5.098 5.098 0 0 1-5.084-5.094ZM176.09 45.092a5.088 5.088 0 0 1 5.083-5.094h13.219a5.088 5.088 0 0 1 5.084 5.094 5.088 5.088 0 0 1-5.084 5.094h-13.219a5.098 5.098 0 0 1-5.083-5.094ZM233.815 30.831a5.099 5.099 0 0 1 0-7.201l9.343-9.363a5.073 5.073 0 0 1 7.186 0 5.099 5.099 0 0 1 0 7.2l-9.343 9.364a5.073 5.073 0 0 1-7.186 0ZM188.83 75.91a5.1 5.1 0 0 1 0-7.202l9.344-9.363a5.073 5.073 0 0 1 7.186 0 5.1 5.1 0 0 1 0 7.201l-9.344 9.363a5.073 5.073 0 0 1-7.186 0ZM188.83 14.267a5.073 5.073 0 0 1 7.186 0l9.344 9.363a5.1 5.1 0 0 1 0 7.201 5.073 5.073 0 0 1-7.186 0l-9.344-9.363a5.1 5.1 0 0 1 0-7.201Z"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M.09.5h286v172H.09z" />
      </ClipPath>
    </Defs>
  </Svg>
);
