import { StyleSheet, View } from "react-native";
import { shadow } from "react-native-paper";

import { SensorState } from "@models/index";

import { DefaultTheme, spacing } from "../../../../../theme";
import { HistoryChartView } from "../../components";

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    top: 126,
    left: 16,
    right: 16,
    backgroundColor: DefaultTheme.colors.card,
    borderRadius: spacing[2],
    ...(shadow(5) as any),
  },
  chart: {
    marginBottom: spacing[0],
  },
});
const displayName = "Air humidity (Bord 1)";
const iconName = "AirHumidityIcon";

const history = [
  {
    value: 83.13212,
    dateTime: "2023-05-06T07:41:04.031Z",
    state: SensorState.Online,
  },
  {
    value: 77.81261,
    dateTime: "2023-05-06T08:09:52.031Z",
    state: SensorState.Online,
  },
  {
    value: 77.828254,
    dateTime: "2023-05-06T08:38:40.031Z",
    state: SensorState.Online,
  },
  {
    value: 79.21776833333334,
    dateTime: "2023-05-06T09:07:28.031Z",
    state: SensorState.Online,
  },
  {
    value: 79.941545,
    dateTime: "2023-05-06T09:36:16.031Z",
    state: SensorState.Online,
  },
  {
    value: 77.55958166666667,
    dateTime: "2023-05-06T10:05:04.031Z",
    state: SensorState.Online,
  },
  {
    value: 73.256628,
    dateTime: "2023-05-06T10:33:52.031Z",
    state: SensorState.Online,
  },
  {
    value: 75.93677833333334,
    dateTime: "2023-05-06T11:02:40.031Z",
    state: SensorState.Online,
  },
  {
    value: 82.98085666666667,
    dateTime: "2023-05-06T11:31:28.031Z",
    state: SensorState.Online,
  },
  {
    value: 79.061278,
    dateTime: "2023-05-06T12:00:16.031Z",
    state: SensorState.Online,
  },
  {
    value: 74.70525166666667,
    dateTime: "2023-05-06T12:29:04.031Z",
    state: SensorState.Online,
  },
  {
    value: 73.61504333333333,
    dateTime: "2023-05-06T12:57:52.031Z",
    state: SensorState.Online,
  },
  {
    value: 75.129555,
    dateTime: "2023-05-06T13:26:40.031Z",
    state: SensorState.Online,
  },
  {
    value: 72.880096,
    dateTime: "2023-05-06T13:55:28.031Z",
    state: SensorState.Online,
  },
  {
    value: 75.3239,
    dateTime: "2023-05-06T14:24:16.031Z",
    state: SensorState.Online,
  },
  {
    value: 75.10336333333333,
    dateTime: "2023-05-06T14:53:04.031Z",
    state: SensorState.Online,
  },
  {
    value: 75.540734,
    dateTime: "2023-05-06T15:21:52.031Z",
    state: SensorState.Online,
  },
  {
    value: 73.124855,
    dateTime: "2023-05-06T15:50:40.031Z",
    state: SensorState.Online,
  },
  {
    value: 74.52199,
    dateTime: "2023-05-06T16:19:28.031Z",
    state: SensorState.Online,
  },
  {
    value: 75.34622,
    dateTime: "2023-05-06T16:48:16.031Z",
    state: SensorState.Online,
  },
  {
    value: 77.87275333333334,
    dateTime: "2023-05-06T17:17:04.031Z",
    state: SensorState.Online,
  },
  {
    value: 75.82157333333333,
    dateTime: "2023-05-06T17:45:52.031Z",
    state: SensorState.Online,
  },
  {
    value: 77.960745,
    dateTime: "2023-05-06T18:14:40.031Z",
    state: SensorState.Online,
  },
  {
    value: 73.68204,
    dateTime: "2023-05-06T18:43:28.031Z",
    state: SensorState.Online,
  },
  {
    value: 77.32755166666666,
    dateTime: "2023-05-06T19:12:16.031Z",
    state: SensorState.Online,
  },
  {
    value: 75.17573166666666,
    dateTime: "2023-05-06T19:41:04.031Z",
    state: SensorState.Online,
  },
  {
    value: 76.365834,
    dateTime: "2023-05-06T20:09:52.031Z",
    state: SensorState.Online,
  },
  {
    value: 74.76395,
    dateTime: "2023-05-06T20:38:40.031Z",
    state: SensorState.Online,
  },
  {
    value: 77.85144,
    dateTime: "2023-05-06T21:07:28.031Z",
    state: SensorState.Online,
  },
  {
    value: 76.23763166666667,
    dateTime: "2023-05-06T21:36:16.031Z",
    state: SensorState.Online,
  },
  {
    value: 77.86482,
    dateTime: "2023-05-06T22:05:04.031Z",
    state: SensorState.Online,
  },
  {
    value: 77.46439,
    dateTime: "2023-05-06T22:33:52.031Z",
    state: SensorState.Online,
  },
  {
    value: 78.11050333333333,
    dateTime: "2023-05-06T23:02:40.031Z",
    state: SensorState.Online,
  },
  {
    value: 75.71573,
    dateTime: "2023-05-06T23:31:28.031Z",
    state: SensorState.Online,
  },
  {
    value: 74.44132,
    dateTime: "2023-05-07T00:00:16.031Z",
    state: SensorState.Online,
  },
  {
    value: 73.92090333333333,
    dateTime: "2023-05-07T00:29:04.031Z",
    state: SensorState.Online,
  },
  {
    value: 72.746926,
    dateTime: "2023-05-07T00:57:52.031Z",
    state: SensorState.Online,
  },
  {
    value: 75.65010333333333,
    dateTime: "2023-05-07T01:26:40.031Z",
    state: SensorState.Online,
  },
  {
    value: 75.19558166666667,
    dateTime: "2023-05-07T01:55:28.031Z",
    state: SensorState.Online,
  },
  {
    value: 78.435055,
    dateTime: "2023-05-07T02:24:16.031Z",
    state: SensorState.Online,
  },
  {
    value: 76.816484,
    dateTime: "2023-05-07T02:53:04.031Z",
    state: SensorState.Online,
  },
  {
    value: 78.40218666666667,
    dateTime: "2023-05-07T03:21:52.031Z",
    state: SensorState.Online,
  },
  {
    value: 81.18249666666667,
    dateTime: "2023-05-07T03:50:40.031Z",
    state: SensorState.Online,
  },
  {
    value: 73.971786,
    dateTime: "2023-05-07T04:19:28.031Z",
    state: SensorState.Online,
  },
  {
    value: 80.43475333333333,
    dateTime: "2023-05-07T04:48:16.031Z",
    state: SensorState.Online,
  },
  {
    value: 80.75639666666666,
    dateTime: "2023-05-07T05:17:04.031Z",
    state: SensorState.Online,
  },
  {
    value: 77.51387,
    dateTime: "2023-05-07T05:45:52.031Z",
    state: SensorState.Online,
  },
  {
    value: 77.94218166666667,
    dateTime: "2023-05-07T06:14:40.031Z",
    state: SensorState.Online,
  },
  {
    value: 73.941675,
    dateTime: "2023-05-07T06:43:28.031Z",
    state: SensorState.Online,
  },
  {
    value: 77.081825,
    dateTime: "2023-05-07T07:12:16.031Z",
    state: SensorState.Online,
  },
  {
    value: 77.081825,
    dateTime: "2023-05-07T07:41:04.032Z",
    state: SensorState.Offline,
  },
];

export const GraphDatails = () => (
  <View style={[styles.container]}>
    <HistoryChartView
      data={history}
      yMax={8}
      yMin={4}
      currentIndex={12}
      setCurrentIndex={() => null}
      iconName={iconName}
      displayName={displayName}
      style={styles.chart}
    />
  </View>
);
