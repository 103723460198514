import React, { useState, useEffect, memo } from "react";
import { StyleSheet, View, ViewStyle } from "react-native";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

import { useStores } from "@models/root-store/root-store-context";
import { Text } from "@components/ui";

import { defaultColors, spacing } from "../../theme";
import { translate } from "../../i18n";
import {
  DateTimeFormats,
  TPeriod,
  getPeriodWithTime,
  dateTimeFormat,
  timeFormat,
} from "../../utils/timeConverter";
import { Calendar } from "./Calendar";
import { FilterButton } from "./FilterButton";

const styles = StyleSheet.create({
  container: {
    height: 55,
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: spacing[4],
  },
  dateText: {
    fontSize: 16,
  },
  dateTimeText: {
    fontSize: 12,
  },
  periods: {
    flexDirection: "row",
    alignItems: "center",
  },
  button: {
    height: 29,
  },
});

dayjs.extend(isSameOrBefore);

type HistoryHeaderProps = {
  onChange: (period: TPeriod) => void;
  currentDateTime?: Date;
  color?: string;
  style?: ViewStyle;
};

export const HistoryHeader = memo(
  ({
    currentDateTime,
    onChange,
    color = defaultColors.text,
    style,
  }: HistoryHeaderProps) => {
    const { settingsStore } = useStores();

    const dateNow = dayjs().format(DateTimeFormats.DateYMD);
    const dateOneDayAgo = dayjs()
      .add(-1, "day")
      .format(DateTimeFormats.DateYMD);
    const dateSevenDayAgo = dayjs()
      .add(-7, "day")
      .format(DateTimeFormats.DateYMD);

    const initialPeriod: TPeriod = {
      startDate: dateOneDayAgo,
      endDate: dateNow,
    };

    const periodSevenDays: TPeriod = {
      startDate: dateSevenDayAgo,
      endDate: dateNow,
    };

    const [period, setPeriod] = useState<TPeriod>(initialPeriod);

    const isOneDay =
      period.startDate === dateOneDayAgo && period.endDate === dateNow;
    const isWeek =
      period.startDate === dateSevenDayAgo && period.endDate === dateNow;

    const defaultPeriod = (): TPeriod => {
      if (isOneDay) {
        return initialPeriod;
      }

      if (isWeek) {
        return periodSevenDays;
      }

      return null;
    };

    const setPeriodOneDay = () => {
      setPeriod(initialPeriod);
    };

    const setPeriodWeek = () => {
      setPeriod({ startDate: dateSevenDayAgo, endDate: dateNow });
    };

    const currentNumberOfDate = currentDateTime
      ? dateTimeFormat(currentDateTime, DateTimeFormats.DateDM)
      : "";
    const currentTime = currentDateTime
      ? timeFormat(currentDateTime, settingsStore.currentTimeSystem)
      : "";

    useEffect(() => {
      onChange(getPeriodWithTime(period));
    }, [period]);

    return (
      <View>
        <View style={[styles.container, style]}>
          <View>
            <Text style={[styles.dateText, { color }]}>
              {currentNumberOfDate}
            </Text>

            <Text style={[styles.dateTimeText, { color }]}>{currentTime}</Text>
          </View>

          <View style={styles.periods}>
            <Calendar
              period={period}
              onChange={setPeriod}
              defaultPeriod={defaultPeriod()}
            />

            <FilterButton
              onPress={setPeriodOneDay}
              label={translate("Sensors.History.day")}
              selected={isOneDay}
            />
            <FilterButton
              onPress={setPeriodWeek}
              label={translate("Sensors.History.week")}
              selected={isWeek}
            />
          </View>
        </View>
      </View>
    );
  },
);
