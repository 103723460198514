import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import {
  View,
  StyleSheet,
  TextInput as TextInputRN,
  Platform,
} from "react-native";

import { Button, ErrorText, Text } from "@components/ui";

import { RecoveryTokenResult } from "../../services/api/account-api";
import { maskEmail } from "../../utils/formatters";
import { spacing } from "../../theme";
import { translate } from "../../i18n";
import { useAppTheme } from "../../hooks";

const MAX_SECONDS = 30;
const VERIFY_CODE_LENGTH = 6;

const createStyles = (theme: any) =>
  StyleSheet.create({
    maskedEmailText: {
      marginTop: spacing[3],
      marginBottom: spacing[6],
    },
    label: {
      color: theme.colors.label,
      textAlign: "center",
    },
    inputContainer: {
      flexDirection: "row",
      justifyContent: "center",
      marginVertical: spacing[6],
    },
    input: {
      borderColor: theme.colors.primary,
      borderRadius: spacing[4],
      borderWidth: 1,
      marginHorizontal: spacing[1],
      paddingVertical: 0,
      paddingHorizontal: spacing[2],
      lineHeight: Platform.OS === "ios" ? 22 : 32,
      height: 48,
      width: 44,
      fontSize: 18,
      textAlign: "center",
    },
    inputError: {
      borderColor: theme.colors.warning,
      backgroundColor: theme.colors.errorBG,
    },
    errorMessage: {
      textAlign: "center",
      color: theme.colors.warning,
    },
    secondsLeft: {
      textAlign: "center",
    },
    buttonContinue: {
      marginTop: spacing[8],
    },
  });

type EnterCodeFromMailProps = {
  email: string;
  onSendNewCode: () => any;
  onPress: (code: string) => void;
  isClearNextStep?: boolean;
  errorText?: string;
  isClearCode?: boolean;
  isSendNewCode?: boolean;
  children?: React.ReactNode;
};

export function EnterCodeFromMail({
  email,
  onSendNewCode,
  onPress,
  isClearNextStep = true,
  isClearCode = false,
  errorText = "",
  isSendNewCode = true,
  children,
}: EnterCodeFromMailProps) {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  const [codeArray, setCodeArray] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    setError(errorText);
  }, [errorText]);

  const maskedEmail = email && maskEmail(email);

  const inputsRef = useRef<TextInputRN[]>([]);

  const clearCode = () => {
    // Kludge solution for clearing last input on Android #GDV1-569
    inputsRef.current.forEach((input) => input.blur());
    setTimeout(() => {
      inputsRef.current.forEach((input) => input.clear());
    }, 50);

    setCodeArray([]);
  };

  useEffect(() => {
    if (isClearCode) {
      clearCode();
    }
  }, [isClearCode]);

  const handleSendNewCode = useCallback(async () => {
    clearCode();
    setError("");
    const result = await onSendNewCode();
    if (result.errors) {
      setError(result.errors[0]);
    }
    return result;
  }, [email, setError]);

  const nextStepSubmit = (code: string) => {
    if (isClearNextStep) {
      clearCode();
    }
    onPress(code);

    // const result = await accountStore.recoveryVerify(email, code);
    // if (result?.token) {
    //   navigation.navigate("SetNewPassword", {
    //     recoveryToken: result.token,
    //   });
    // } else {
    //   setError(translate("Auth.EnterCode.wrongCode"));
    // }
  };

  // const onContinueHandler = () => {
  //   const code = codeArray.join("");

  //   if (code.length === VERIFY_CODE_LENGTH) {
  //     nextStepSubmit(code);
  //   } else {
  //     setError(translate("Auth.EnterCode.wrongCode"));
  //   }
  // };

  const onCodeChange = (value: string, index) => {
    const newCodeArray = [...codeArray];
    newCodeArray[index] = value;
    setCodeArray(newCodeArray);

    const newCode = newCodeArray.join("");
    if (newCode?.length === VERIFY_CODE_LENGTH) {
      nextStepSubmit(newCode);
    } else {
      setError("");
    }
  };

  const handleChangeCode = (index: number, v: string) => {
    if (v && inputsRef.current[index + 1]) {
      inputsRef.current[index + 1].focus();
    }
    onCodeChange(v, index);
  };

  return (
    <>
      {children}

      <View style={styles.maskedEmailText}>
        <Text style={styles.label}>
          {translate("Auth.EnterCode.enterCode")}
        </Text>
        <Text style={styles.label}>{maskedEmail}</Text>
      </View>

      {error.length > 0 ? (
        <ErrorText style={styles.errorMessage}>{error}</ErrorText>
      ) : null}

      <View style={styles.inputContainer}>
        {Array(VERIFY_CODE_LENGTH)
          .fill(null)
          .map((_, index) => (
            <TextInputRN
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              style={[styles.input, Boolean(error.length) && styles.inputError]}
              onChangeText={(value) => handleChangeCode(index, value)}
              maxLength={1}
              keyboardType="numeric"
              returnKeyType="next"
              autoCapitalize="none"
              // eslint-disable-next-line no-return-assign
              ref={(input) => (inputsRef.current[index] = input)}
              underlineColorAndroid="transparent"
            />
          ))}
      </View>

      {isSendNewCode ? <CountdownSubmit onSubmit={handleSendNewCode} /> : null}

      {/* <Button
          style={styles.buttonContinue}
          type="primary"
          onPress={onContinueHandler}
          disabled={codeArray?.length < VERIFY_CODE_LENGTH}
        >
          {translate("common.continue")}
        </Button> */}
    </>
  );
}

type CountdownSubmitProps = {
  onSubmit: () => Promise<RecoveryTokenResult>;
};
const CountdownSubmit = ({ onSubmit }: CountdownSubmitProps) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  const [secondsLeft, setSecondsLeft] = useState(MAX_SECONDS);
  const [secondsLeftVisible, setSecondsLeftVisible] = useState(true);

  useEffect(() => {
    if (secondsLeft > 0) {
      setTimeout(() => setSecondsLeft(secondsLeft - 1), 1000);
    } else {
      setSecondsLeftVisible(false);
    }
  }, [secondsLeft]);

  const handleSubmit = useCallback(async () => {
    const result = await onSubmit();

    if (result?.kind === "ok") {
      setSecondsLeft(MAX_SECONDS);
      setSecondsLeftVisible(true);
    }
  }, [onSubmit, setSecondsLeft, setSecondsLeftVisible]);

  return (
    <View>
      <Button
        type="link"
        tx="Auth.EnterCode.sendNewCode"
        onPress={handleSubmit}
        disabled={secondsLeftVisible}
      >
        Send New Code
      </Button>
      {secondsLeftVisible && (
        <Text style={styles.secondsLeft}>{secondsLeft} sec.</Text>
      )}
    </View>
  );
};
