import { translate } from "../../../../i18n";

import {
  CalibrationPrepareORPSvg,
  CalibrationORP256Svg,
  CalibrationORP256ProcessSvg,
  CalibrationORP256ErrorSvg,
  CalibrationORP475ErrorSvg,
} from "../../../../svgs/pictures/calibration/ORP";
import {
  CalibrationProcessStatus,
  CalibrationStep,
  TCalibrationStepProps,
} from "../types";

export const LUX_STEPS = [
  CalibrationStep.Prepare,
  // CalibrationStep.Clean,
  CalibrationStep.Process1,
  // CalibrationStep.Clean,
  CalibrationStep.Process2,
  CalibrationStep.Success,
];

export const LUX_STEPS_PROPS: TCalibrationStepProps = {
  [CalibrationStep.Prepare]: {
    Picture: CalibrationPrepareORPSvg,
    items: [translate("Sensors.Calibration.Prepare.paperTowel"), "...", "..."],
    // submitLabel: translate("common.next"),
  },

  // [CalibrationStep.Clean]: {
  //   Picture: CalibrationCleanORPSvg,
  //   items: [
  //     translate("Sensors.Calibration.Clean.stirSensorInCleanWater"),
  //     translate("Sensors.Calibration.Clean.cleanAndDryWithPaperTowel"),
  //   ],
  //   alert: translate("Sensors.Calibration.Clean.pleaseTouchVeryCarefully"),
  // },

  [CalibrationStep.Process1]: {
    Picture: CalibrationORP256Svg,
    title: translate("Sensors.Calibration.lux.calibrationIn"),
    items: ["...", "..."],
    withInput: true,
    // afterInputText: translate("Sensors.Calibration.orp.mVLiquid"),
  },

  [`${CalibrationStep.Process1}${CalibrationProcessStatus.Process}`]: {
    Picture: CalibrationORP256ProcessSvg,
    // title: translate("Sensors.Calibration.orp.calibrationIn256mVLiquid"),
    titleTx: "Sensors.Calibration.lux.calibrationInX",
    alert: translate("Sensors.Calibration.dontTouchSensor"),
  },

  [`${CalibrationStep.Process1}${CalibrationProcessStatus.Error}`]: {
    Picture: CalibrationORP256ErrorSvg,
    // title: translate("Sensors.Calibration.orp.calibrationIn256mVLiquid"),
    titleTx: "Sensors.Calibration.lux.calibrationInX",
  },

  [CalibrationStep.Process2]: {
    Picture: CalibrationORP256Svg,
    title: translate("Sensors.Calibration.lux.calibrationIn"),
    items: ["...", "..."],
    withInput: true,
    // afterInputText: translate("Sensors.Calibration.orp.mVLiquid"),
  },

  [`${CalibrationStep.Process2}${CalibrationProcessStatus.Process}`]: {
    Picture: CalibrationORP256ProcessSvg,
    // title: translate("Sensors.Calibration.orp.calibrationIn256mVLiquid"),
    titleTx: "Sensors.Calibration.lux.calibrationInX",
    alert: translate("Sensors.Calibration.dontTouchSensor"),
  },

  [`${CalibrationStep.Process2}${CalibrationProcessStatus.Error}`]: {
    Picture: CalibrationORP256ErrorSvg,
    // title: translate("Sensors.Calibration.orp.calibrationIn256mVLiquid"),
    titleTx: "Sensors.Calibration.lux.calibrationInX",
  },

  [CalibrationStep.Error]: {
    Picture: CalibrationORP475ErrorSvg,
  },
};
