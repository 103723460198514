import { StyleSheet } from "react-native";
import { observer } from "mobx-react-lite";
import { useIsFocused } from "@react-navigation/native";

import { useStores } from "@models/index";
import { HelpHintsOneTime, THintsDataItem } from "@components/hints/HelpHints";

import { MANUAL_URL } from "@screens/Settings";
import { translate } from "../../../../i18n";
import {
  AutoModeCard,
  ButtonAdd,
  ButtonFilter,
  Card,
  Menu,
} from "./components";

const dialogStyle = StyleSheet.create({
  dialog: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 212,
  },
});

const DevicesCardHint = () => ({
  id: "DevicesCard",
  title: translate("Hints.title"),
  text: translate("Hints.DevicesList.textHintCard"),
  HintComponent: Card,
  dialogStyle: dialogStyle.dialog,
  // helpFileName: MANUAL_URL,
});

const DevicesAutoModeCard = () => ({
  id: "DevicesAutoModeCard",
  title: translate("Hints.title"),
  text: translate("Hints.DevicesList.textHintAutoMode"),
  HintComponent: AutoModeCard,
  dialogStyle: dialogStyle.dialog,
  // helpFileName: MANUAL_URL,
});

export const DevicesListHintsData = (): THintsDataItem[] => [
  {
    id: "ButtonFilter",
    title: translate("Hints.title"),
    text: translate("Hints.DevicesList.textHintButtonFilter"),
    HintComponent: ButtonFilter,
  },
  {
    id: "ButtonAdd",
    title: translate("Hints.title"),
    text: translate("Hints.DevicesList.textHintButtonAdd"),
    HintComponent: ButtonAdd,
  },
  {
    id: "Menu",
    title: translate("Hints.title"),
    text: translate("Hints.DevicesList.textHintMenu"),
    HintComponent: Menu,
  },
  DevicesCardHint(),
  DevicesAutoModeCard(),
  {
    id: "HelpButtonVisibility",
    title: translate("Hints.helpButtonTitle"),
    text: translate("Hints.helpButtonContent"),
  },
];

export const DeviceListOneTimeHints = observer(() => {
  const isFocused = useIsFocused();
  const { deviceStore, settingsStore } = useStores();

  if (!isFocused) return null;

  const hints = [];

  const devicesCardHint = DevicesCardHint();
  if (
    !settingsStore.isOneTimeHintShown(devicesCardHint.id) &&
    deviceStore.isAnyOn
  ) {
    hints.push(devicesCardHint);
  }

  const devicesAutoModeCard = DevicesAutoModeCard();
  if (
    !settingsStore.isOneTimeHintShown(devicesAutoModeCard.id) &&
    deviceStore.isAnyOnInAutoMode
  ) {
    hints.push(devicesAutoModeCard);
  }

  if (!hints.length) return null;

  const onOk = (itemId) => {
    settingsStore.markOneTimeHintAsShown(itemId);
  };

  return <HelpHintsOneTime hints={hints} onOk={onOk} />;
});
