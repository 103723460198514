import React from "react";
import { TabItem } from "@components/ui";
import { TxKeyPath } from "../../../i18n";

export enum CalibrationStep {
  PrepareInfo = "PrepareInfo",
  Prepare = "Prepare",
  Clean = "Clean",
  Process1 = "Process1",
  Process2 = "Process2",
  Success = "Success",
  Error = "Error",
}

export enum CalibrationProcessStatus {
  Process = "Process",
  Error = "Error",
}

const CALIBRATION_STEP_VALUES = [
  ...Object.values(CalibrationStep),
  ...Object.values(CalibrationProcessStatus).flatMap((status) => [
    `${CalibrationStep.Process1}${status}`,
    `${CalibrationStep.Process2}${status}`,
  ]),
] as const;

export type TCalibrationStepProps = {
  [key in (typeof CALIBRATION_STEP_VALUES)[number]]?: TCalibrationStep;
};

export type TCalibrationStep = {
  Picture?: React.FC;
  title?: string;
  titleTx?: TxKeyPath;
  items?: string[];
  alert?: string;
  submitLabel?: string;
  withInput?: boolean;
  valueOptions?: TabItem[];
  afterInputText?: string;
  youtubeId?: string;
};
