import React, { useState } from "react";
import { Platform, ScrollView, StyleSheet } from "react-native";

import { Module } from "@models/module/module";
import { UpdateStatus } from "@models/module/module-update";
import {
  Button,
  Dialog,
  ErrorsMessage,
  FloatingButtonView,
  Text,
} from "@components/ui";

import { translate } from "../../../../i18n";
import { spacing } from "../../../../theme";

const styles = StyleSheet.create({
  button: {
    width: "100%",
  },
  logsText: {
    fontSize: 12,
  },
  logsContainer: {
    maxHeight: 400,
    padding: 4,
  },
  errorMessage: {
    marginTop: spacing[6],
  },
});

type Props = {
  module: Module;
  reset: () => void;
};
export const UpdateControls = ({ module, reset }: Props) => {
  const moduleUpdate = module.update;
  const [logsVisibility, setLogsVisibility] = useState(false);

  const showLogs = () => {
    setLogsVisibility(true);
  };

  const hideLogs = () => {
    setLogsVisibility(false);
  };

  if (Platform.OS === "web") {
    return (
      <ErrorsMessage
        style={styles.errorMessage}
        errors={[
          translate("errorScreen.featureIsNotAvailableForThisPlatform", {
            platform: Platform.OS,
          }),
        ]}
      />
    );
  }

  return (
    <FloatingButtonView>
      {!moduleUpdate.currentStep && (
        <>
          <Button
            style={styles.button}
            type="primary"
            onPress={moduleUpdate.execute}
          >
            {translate("Modules.update.updateFirmware")}
          </Button>
          <Button type="ghost" style={styles.button} onPress={reset}>
            {translate("common.cancel")}
          </Button>
        </>
      )}

      {moduleUpdate.status === UpdateStatus.Error && (
        <>
          <Button style={styles.button} type="primary" onPress={reset}>
            {translate("common.ok")}
          </Button>
          <Button type="ghost" style={styles.button} onPress={showLogs}>
            {translate("Modules.update.showLogs")}
          </Button>
        </>
      )}

      {moduleUpdate.status === UpdateStatus.CanNotUpdate && (
        <>
          <Button style={styles.button} type="primary" onPress={reset}>
            {translate("Modules.update.turnOffManually")}
          </Button>
          <Button
            style={styles.button}
            labelStyle={{ fontSize: 16 }}
            type="primary"
            onPress={module.disconnectDevices}
          >
            {translate("Modules.update.turnOffAutomatically")}
          </Button>
        </>
      )}

      <Dialog
        title="Logs"
        visible={logsVisibility}
        onOk={hideLogs}
        okText={translate("common.ok")}
      >
        <ScrollView style={styles.logsContainer}>
          <Text selectable style={styles.logsText}>
            {module.update.logs}
          </Text>
        </ScrollView>
      </Dialog>
    </FloatingButtonView>
  );
};
