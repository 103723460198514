import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { StyleSheet, View } from "react-native";
import { useNavigation } from "@react-navigation/native";

import { StackScreenProps } from "@react-navigation/stack";
import { useStores } from "@models/index";

import { Button, Text, RadioItem } from "@components/ui";
import { TopBar } from "@components/layout";
import { TextInput } from "@components/inputs";

import { Screen } from "../../../components";
import { API_SERVER_URIS, APIServer } from "../../../services/api/api-config";
import { translate } from "../../../i18n";
import { palette, spacing } from "../../../theme";
import { urlValidator } from "../../../utils/validator";
import { DEVSettingsNavigatorParamList } from "../navigation";

const styles = StyleSheet.create({
  screen: {
    padding: spacing[4],
  },
  title: {
    marginTop: spacing[4],
    marginBottom: spacing[2],
    color: palette.GrayDark,
  },
  container: {
    marginBottom: spacing[4],
  },
});

export const DEVSettingsScreen: React.FC<
  StackScreenProps<DEVSettingsNavigatorParamList, "dev-settings">
> = observer(() => {
  const { settingsStore } = useStores();
  const [apiServer, setApiServer] = useState<APIServer | string>(
    settingsStore.DEV_apiServer,
  );
  const [apiUrl, setApiUrl] = useState<string>(settingsStore.DEV_serverUrl);

  const navigation = useNavigation();

  const isApplyDisable =
    apiServer === APIServer.Custom &&
    (!!urlValidator(apiUrl)?.length || !apiUrl?.length);

  const applyHandler = () => {
    settingsStore.DEV_setApiServer(apiServer, apiUrl);
    navigation.goBack();
  };

  return (
    <Screen
      style={styles.screen}
      header={<TopBar title={translate("Settings.DEV.title")} back />}
      keyboard
      preset="scroll"
    >
      <View style={styles.container}>
        <Text style={styles.title}>{translate("Settings.DEV.api")}</Text>

        <RadioItem
          value={APIServer.Prod}
          label="Production"
          description={API_SERVER_URIS[APIServer.Prod]}
          onPress={setApiServer}
          checked={apiServer === APIServer.Prod}
        />
        <RadioItem
          value={APIServer.Stage}
          label="Staging"
          description={API_SERVER_URIS[APIServer.Stage]}
          onPress={setApiServer}
          checked={apiServer === APIServer.Stage}
        />
        <RadioItem
          value={APIServer.Dev}
          label="Develop"
          description={API_SERVER_URIS[APIServer.Dev]}
          onPress={setApiServer}
          checked={apiServer === APIServer.Dev}
        />

        <RadioItem
          value={APIServer.Custom}
          label="Custom"
          onPress={setApiServer}
          checked={apiServer === APIServer.Custom}
          description={
            apiServer === APIServer.Custom ? (
              <TextInput
                value={apiUrl}
                keyboardType="url"
                onChangeText={setApiUrl}
                errorText={urlValidator(apiUrl)}
                containerStyle={{
                  marginVertical: 0,
                  minWidth: 200,
                  maxWidth: "100%",
                }}
                // inputStyle={{ width: 200 }}
              />
            ) : null
          }
        />
      </View>

      <Button onPress={applyHandler} disabled={isApplyDisable} type="primary">
        {translate("common.apply")}
      </Button>
    </Screen>
  );
});
