import React from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { TextInput as PaperTextInput } from "react-native-paper";

import { RuleFormData } from "@models/rule/rule";
import { TextInput } from "@components/inputs";
import { Text } from "@components/ui";

import { translate } from "../../../../../../i18n";
import { defaultColors, spacing } from "../../../../../../theme";
import { stringToNumber } from "../../../../../../utils/numbers";
import { SwitchBlock } from "../SwitchBlock";

const styles = StyleSheet.create({
  columns: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginHorizontal: -spacing[2],
  },
  column: {
    flex: 1,
    marginHorizontal: spacing[2],
    justifyContent: "center",
  },
  unit: {
    fontSize: 18,
    color: defaultColors.text,
  },
  description: {
    fontSize: 12,
    color: defaultColors.label,
  },
});

const cycleToMinutes = (formData: RuleFormData, isPhOrEc = false): string => {
  if (
    !isPhOrEc ||
    !formData.cyclesToSetPoint ||
    !formData.dosingTime ||
    !formData.mixTime
  )
    return "";

  const value = (
    (formData.cyclesToSetPoint *
      (Number(formData.dosingTime) + Number(formData.mixTime))) /
    60
  ).toFixed(0);
  return `≈ ${value} ${translate("units.minutes")}`;
};

const formDataToInputValue = (
  formData: RuleFormData,
  isPhOrEc = false,
): string => {
  if (isPhOrEc) {
    return formData.cyclesToSetPoint?.toString() || "";
  }
  if (!formData.timeToSetPoint) return "";
  return (Number(formData.timeToSetPoint) / 60).toFixed(0);
};

type Props = {
  formData: RuleFormData;
  defaultValue?: number;
  changeFormData?: (data: Partial<RuleFormData>) => void;
  style?: StyleProp<ViewStyle>;
  error?: string | undefined;
  disabled?: boolean;
  isPhOrEc?: boolean;
};

export const TimeToSetPointInput = ({
  formData,
  defaultValue,
  changeFormData = () => null,
  style,
  error,
  disabled = false,
  isPhOrEc = false,
}: Props) => {
  const switchHandler = (state: boolean) => {
    if (state) {
      if (isPhOrEc) {
        changeFormData({ cyclesToSetPoint: defaultValue });
      } else {
        changeFormData({ timeToSetPoint: defaultValue });
      }
    } else {
      changeFormData({ timeToSetPoint: null, cyclesToSetPoint: null });
    }
  };

  const changeHandler = (newValue?: string) => {
    const newValueFloat = stringToNumber(newValue);

    if (isPhOrEc) {
      changeFormData({
        cyclesToSetPoint: newValueFloat || "",
      });
    } else {
      changeFormData({
        timeToSetPoint: newValueFloat ? newValueFloat * 60 : "",
      });
    }
  };

  const unit = isPhOrEc
    ? translate("units.cycles")
    : translate("units.minutes");

  return (
    <SwitchBlock
      label={translate("Devices.Rules.timeToSetPoint")}
      style={style}
      disabled={disabled}
      isOpened={Boolean(formData?.timeToSetPoint || formData?.cyclesToSetPoint)}
      onChange={switchHandler}
    >
      <View style={styles.columns}>
        <View style={styles.column}>
          <TextInput
            value={formDataToInputValue(formData, isPhOrEc)}
            errorText={error}
            onChangeText={changeHandler}
            returnKeyType="next"
            right={<PaperTextInput.Affix text={unit} textStyle={styles.unit} />}
            keyboardType="numeric"
            disabled={disabled}
            description={cycleToMinutes(formData, isPhOrEc)}
          />
        </View>
        <View style={styles.column}>
          <Text style={styles.description}>
            {translate("Devices.Rules.timeToSetPointDescription")}
          </Text>
        </View>
      </View>
    </SwitchBlock>
  );
};
