import React, { useMemo, useState } from "react";
import { StyleSheet, View, ViewStyle } from "react-native";

import { DimmerRuleData, Rule } from "@models/rule/rule";
import { useStores } from "@models/index";
import { CustomSelectInput, Label, TextInput } from "@components/inputs";

import { spacing } from "../../../../../../theme";
import { useAppTheme } from "../../../../../../hooks";
import * as SensorIcons from "../../../../../../svgs/sensors";
import { stringToNumber } from "../../../../../../utils/numbers";
import { SensorSelector } from "../SensorSelector";
import { DimmerRuleErrors } from "./types";

const createStyles = (theme: any) =>
  StyleSheet.create({
    container: {
      marginBottom: spacing[4],
      padding: spacing[4],
      paddingBottom: spacing[3],
      borderRadius: spacing[4],
      borderWidth: 1,
      borderColor: theme.colors.menuBorder,
    },

    columns: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginHorizontal: -spacing[2],
    },
    column: {
      flex: 1,
      marginHorizontal: spacing[2],
    },
  });

type AutoBalancingProps = {
  rule?: Rule;
  formData?: DimmerRuleData;
  errors?: DimmerRuleErrors;
  changeFormData?: (data: any) => void;
  disabled?: boolean;
  style?: ViewStyle;
};

export const AutoBalancing = ({
  rule,
  formData,
  errors,
  changeFormData = () => null,
  disabled = false,
  style,
}: AutoBalancingProps) => {
  const theme = useAppTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);

  const { sensorStore } = useStores();
  const sensor = sensorStore.getSensor(formData?.sensorUid);
  const SensorIcon = SensorIcons[sensor?.iconName];

  const [isSensorSelector, setSensorSelector] = useState<boolean>(false);
  const openSensorSelector = () => {
    if (!disabled) {
      setSensorSelector(true);
    }
  };
  const closeSensorSelector = () => {
    setSensorSelector(false);
  };

  const changeSensorHandler = (value: string) => {
    changeFormData({ sensorUid: value });
    closeSensorSelector();
  };

  return (
    <View style={[styles.container, style]}>
      <Label tx="Devices.Rules.autoBalancing" size="large" />

      <CustomSelectInput
        txLabel="Devices.Rules.lightSensor"
        value={sensor?.displayName}
        errorText={errors?.sensorUid}
        icon={SensorIcon}
        iconColor={theme.colors.label}
        onPress={openSensorSelector}
      />

      <View style={styles.columns}>
        <View style={styles.column}>
          <TextInput
            txLabel="Devices.Rules.desiredValue"
            value={formData?.valueTarget?.toString() ?? ""}
            errorText={errors?.valueTarget}
            onChangeText={(value) =>
              changeFormData({ valueTarget: stringToNumber(value) })
            }
            disabled={!formData?.sensorUid}
            returnKeyType="next"
            keyboardType="numeric"
          />
        </View>
        <View style={styles.column} />
      </View>

      {isSensorSelector ? (
        <SensorSelector
          sensors={rule?.sensorsForDevice}
          currentUid={formData?.sensorUid}
          onChange={changeSensorHandler}
          onClose={closeSensorSelector}
        />
      ) : null}
    </View>
  );
};
