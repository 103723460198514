import React from "react";
import Svg, { Path } from "react-native-svg";
import { palette } from "../theme";

export default function SwitcherIcon({ color = palette.Gray, size = 32 }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 32 32" fill="none">
      <Path
        d="M9.33268 8C7.71453 9.36637 6.5553 11.1969 6.01192 13.2439C5.46854 15.2909 5.56725 17.4554 6.29469 19.4444C7.02213 21.4334 8.34316 23.1509 10.079 24.3643C11.8148 25.5777 13.8815 26.2285 15.9994 26.2285C18.1172 26.2285 20.1839 25.5777 21.9197 24.3643C23.6555 23.1509 24.9766 21.4334 25.704 19.4444C26.4315 17.4554 26.5302 15.2909 25.9868 13.2439C25.4434 11.1969 24.2842 9.36637 22.666 8"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M16 5.33594V16.0026"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
