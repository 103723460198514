import React, { useMemo } from "react";
import { StyleSheet } from "react-native";

import { Text } from "./Text";
import { useAppTheme } from "../../hooks";

const createStyles = (theme: any) =>
  StyleSheet.create({
    error: {
      color: theme.colors.error,
    },
  });

type Props = React.ComponentProps<typeof Text>;

export function ErrorText({ style, ...props }: Props) {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  return <Text style={[style, styles.error]} {...props} />;
}
