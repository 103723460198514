import { View, StyleSheet } from "react-native";

import { Button, Heading, Text } from "@components/ui";
import { LayoutCenter } from "@components/layout";

import { Screen } from "../../components";
import { spacing } from "../../theme";
import {
  ConnectionLost as ConnectionLostPicture,
  Leaves,
} from "../../svgs/pictures";

const styles = StyleSheet.create({
  screen: {
    paddingHorizontal: spacing[5],
    paddingBottom: spacing[0],
    paddingTop: spacing[6],
  },
  title: {
    marginVertical: spacing[1],
    lineHeight: spacing[6],
    fontSize: spacing[5],
    textAlign: "center",
  },
  mainContainer: {
    width: "80%",
    alignItems: "center",
  },
  description: {
    textAlign: "center",
  },
  descriptionContainer: {
    marginBottom: spacing[7],
    marginTop: spacing[6],
  },
});

export const ConnectionLost = () => {
  return (
    <Screen style={styles.screen}>
      <LayoutCenter>
        <ConnectionLostPicture />
        <View style={styles.mainContainer}>
          <View style={styles.descriptionContainer}>
            <Heading style={styles.title} tx="errorScreen.ops" />
            <Text tx="errorScreen.noInternet" style={styles.description} />
            <Text tx="errorScreen.checkConnection" style={styles.description} />
          </View>
          <Button type="link">Need help?</Button>
        </View>
      </LayoutCenter>
      <Leaves />
    </Screen>
  );
};
