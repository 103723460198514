import * as React from "react";
import Svg, {
  SvgProps,
  G,
  Circle,
  Path,
  Rect,
  Defs,
  ClipPath,
} from "react-native-svg";

export const CalibrationECAirSvg = (props: SvgProps) => (
  <Svg width={287} height={173} fill="none" {...props}>
    <G clipPath="url(#a)">
      <Circle
        cx={76.09}
        cy={160.5}
        r={11}
        fill="#B6C5D3"
        stroke="#fff"
        strokeWidth={2}
      />
      <Path
        fill="#fff"
        d="M77.464 166.5h-1.68v-8.32a159.294 159.294 0 0 1 .064-1.312 7.501 7.501 0 0 1-.432.4c-.15.128-.32.277-.512.448l-1.248 1.008-.864-1.088 3.264-2.56h1.408V166.5Z"
      />
      <Circle
        cx={210.09}
        cy={160.5}
        r={11}
        fill="#B6C5D3"
        stroke="#fff"
        strokeWidth={2}
      />
      <Path
        fill="#fff"
        d="M213.928 166.5h-7.68v-1.312l2.944-2.992a49.412 49.412 0 0 0 1.44-1.52c.384-.437.672-.853.864-1.248.192-.405.288-.843.288-1.312 0-.587-.171-1.024-.512-1.312-.342-.299-.784-.448-1.328-.448-.523 0-.992.101-1.408.304a6.609 6.609 0 0 0-1.296.816l-.912-1.136c.309-.267.64-.507.992-.72a5.271 5.271 0 0 1 1.2-.512 5.34 5.34 0 0 1 1.472-.192c.725 0 1.349.128 1.872.384.522.245.922.597 1.2 1.056.288.459.432.997.432 1.616 0 .459-.07.891-.208 1.296a4.928 4.928 0 0 1-.608 1.168 8.942 8.942 0 0 1-.944 1.168 44.22 44.22 0 0 1-1.232 1.248l-2.128 2.064v.08h5.552v1.504ZM242.723 157.345l32.987-3.855-32.41-82.026c-1.788-4.627-5.112-10.111-9.525-12.374l-6.452-3.324-.047 48.862c.084 2.728-1.34 7.663-3.566 6.09l-22.196-15.605a6.825 6.825 0 0 0-8.854.875 6.813 6.813 0 0 0-.298 9.059l6.201 7.43-17.821-.167.391 1.797a8.553 8.553 0 0 0 8.584 6.731l17.3-.298 10.381 13.975c4.413 6.918 12.876 15.233 21.07 15.791l1.936.131 2.319 6.908Z"
      />
      <Path
        fill="#DBE2EA"
        d="M220.202 107.886h-44.591a2.565 2.565 0 0 1-2.57-2.57V11.377a2.565 2.565 0 0 1 2.57-2.57h44.591a2.565 2.565 0 0 1 2.57 2.57v93.939a2.573 2.573 0 0 1-2.57 2.57Z"
      />
      <Path
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.196}
        d="M227.256 22.396V11.258a6.92 6.92 0 0 0-6.92-6.92h-44.947a6.919 6.919 0 0 0-6.919 6.92v94.163a6.918 6.918 0 0 0 6.919 6.92h44.947a6.92 6.92 0 0 0 6.92-6.92v-65.39M200.036 24.308 177.51 47.506M184.919 24.861l-6.551 6.854"
      />
      <Path
        fill="#3F9A6E"
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.196}
        d="M217.357 103.049h-38.975a.557.557 0 0 1-.554-.554v-6.8c0-.304.25-.554.554-.554h38.975c.303 0 .554.25.554.553v6.801a.558.558 0 0 1-.554.554Z"
      />
      <Rect
        width={16.759}
        height={3.724}
        x={189.884}
        y={8.994}
        fill="#2B2E32"
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.196}
        rx={1.862}
      />
      <Path
        stroke="#2A2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.196}
        d="m227.323 55.767 9.692 5.725a10.384 10.384 0 0 1 4.358 5.103L275.71 153.5"
      />
      <Path
        fill="#fff"
        d="M192.809 95.812a7.262 7.262 0 0 1 9.32-.801l24.756 17.476-14.803 13.752-19.394-20.269c-2.719-2.859-2.673-7.365.121-10.158Z"
      />
      <Path
        stroke="#2A2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.196}
        d="M238.524 112.934h-6.751a17.54 17.54 0 0 1-10.334-3.371l-19.59-14.3a6.928 6.928 0 0 0-8.957.67c-2.662 2.634-2.737 6.917-.176 9.655l19.366 20.651 8.286 12.923a24.443 24.443 0 0 0 18.612 11.172l1.415.112 2.328 6.899M233.514 97.71l-4.254-17.503-2.365-2.737M180.379 112.31l.745 2.141a9.099 9.099 0 0 0 8.584 6.099h11.266"
      />
      <Path
        fill="#fff"
        d="m46.473 141.861 5.718-21.377a33.509 33.509 0 0 0 .63-14.471l-7.603-43.972a15.931 15.931 0 0 1 4.456-14.07l18.901-20.433a17.966 17.966 0 0 1 3.644-2.782l33.675-11.44a2.658 2.658 0 0 1 3.399 1.729 11.317 11.317 0 0 1-1.744 10.113l-4.219 9.288c.293.05.591.108.876.184 2.91.781 5.03 3.041 5.782 5.757l4.984-1.716a1.866 1.866 0 0 1 2.392 1.218 7.989 7.989 0 0 1-5.073 9.92l-4.006 1.358-.702 2.624-2.939 10.988a18.182 18.182 0 0 0-.44 7.118c1.09 8.155 5.37 20.862-9.648 38.27-1.1 1.279-2.414 17.469-2.414 17.469l10.02 2.689-6.403 23.933-49.286-12.397Z"
      />
      <Path
        fill="#fff"
        d="m109.513 39.288 5.253-1.673c1.05-.337 2.251.356 2.636 1.52 1.601 4.783-.571 9.658-4.887 11.006l-4.224 1.321"
      />
      <Path
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="m109.513 39.288 5.253-1.673c1.05-.337 2.251.356 2.636 1.52 1.601 4.783-.571 9.658-4.887 11.006l-4.224 1.321"
      />
      <Path
        stroke="#2A2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="m28.098 82.48-.95.548c-6.742 3.893-15.38 1.554-19.294-5.224-3.914-6.78-1.62-15.43 5.122-19.324l22.236-12.837c6.742-3.893 9.036-12.544 5.123-19.322l-.664-1.149c-3.913-6.777-12.552-9.116-19.294-5.224"
      />
      <Path
        fill="#6A7B8C"
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="m48.095 75.462-18.908 7.836-.61-1.057-.033-.056-.61-1.056 16.24-12.457 1.723 3.532 2.198 3.258Z"
      />
      <Path
        fill="#6A7B8C"
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="m49.79 78.4-7.279-12.61 12.609-7.279L62.4 71.12 49.79 78.4ZM115.732 40.328l-7.28-12.609 2.537-1.464 7.279 12.609-2.536 1.464Z"
      />
      <Path
        stroke="#2A2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="m138.236 23.991-4.387-7.598a2.886 2.886 0 0 0-3.947-1.058l-11.92 6.882 7.28 12.609 11.919-6.882a2.897 2.897 0 0 0 1.055-3.953Z"
      />
      <Path
        fill="#6A7B8C"
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="m123.7 32.12-4.155-7.197 11.45-6.611 4.155 7.197-11.45 6.61ZM63.242 72.592l-8.98-15.552 53.333-30.792 8.979 15.552-53.332 30.792Z"
      />
      <Path
        stroke="#2A2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="m119.111 40.336-8.979-15.552 6.999-4.041 8.979 15.552-6.999 4.04ZM46.637 63.522l7.332 12.7M50.789 61.125l7.332 12.7"
      />
      <Path
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="m46.48 141.873 5.73-21.386a33.515 33.515 0 0 0 .635-14.477L47.41 75.94m58.595-48.587 1.631-2.242a11.318 11.318 0 0 0 1.749-10.118 2.658 2.658 0 0 0-3.401-1.727L72.279 24.72a18.001 18.001 0 0 0-3.648 2.785L49.71 47.953a15.942 15.942 0 0 0-4.465 14.077l.324 2.083"
      />
      <Path fill="#fff" d="m95.287 153.368 6.415-23.944-49.22-13.189" />
      <Path
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="m95.287 153.368 6.415-23.944-49.22-13.189"
      />
      <Path
        fill="#fff"
        d="m76.34 44.481 3.935-5.114a6.349 6.349 0 0 1 2.962-2.02l12.298-3.949"
      />
      <Path
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="m76.34 44.481 3.935-5.114a6.349 6.349 0 0 1 2.962-2.02l12.298-3.949"
      />
      <Path
        fill="#fff"
        d="m107.664 53.742 6.322.374a1.867 1.867 0 0 1 1.751 2.037c-.397 4.312-4.158 7.518-8.48 7.242l-1.563-.098"
      />
      <Path
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="m107.664 53.742 6.322.374a1.867 1.867 0 0 1 1.751 2.037c-.397 4.312-4.158 7.518-8.48 7.242l-1.563-.098"
      />
      <Rect
        width={16.583}
        height={36.19}
        fill="#fff"
        rx={8}
        transform="scale(1 -1) rotate(-16.403 -190.292 -333.175)"
      />
      <Path
        stroke="#2B2E32"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.261}
        d="M92.185 127.618s1.316-16.204 2.422-17.475c15.044-17.426 10.765-30.135 9.669-38.288-.317-2.382-.172-4.8.443-7.121l5.384-20.094c1.173-4.38-1.431-8.892-5.812-10.065-4.38-1.174-8.891 1.43-10.065 5.81l-7.24 23.543a11.652 11.652 0 0 1-8.429 8.292"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M.09.5h286v172H.09z" />
      </ClipPath>
    </Defs>
  </Svg>
);
