import Svg, { SvgProps, Path } from "react-native-svg";

export const LoginBG = (props: SvgProps) => (
  <Svg width={360} height={250} fill="none" viewBox="0 0 360 250">
    <Path
      d="M96.831 153H116.166C118.365 153 119.721 155.327 118.595 157.156L109.115 172.57C107.943 174.473 105.1 174.481 103.92 172.57L94.4094 157.156C93.2752 155.327 94.6317 153 96.831 153Z"
      fill="#B0EACC"
    />
    <Path
      d="M224.533 95H255.464C258.978 95 261.157 98.8712 259.352 101.927L244.18 127.616C242.302 130.79 237.753 130.797 235.868 127.623L220.652 101.934C218.84 98.8786 221.019 95 224.533 95Z"
      fill="#B0EACC"
    />
    <Path
      d="M236.559 211H124.442C111.701 211 103.811 197.058 110.348 186.071L165.356 93.5856C172.159 82.1456 188.657 82.1381 195.475 93.5633L250.639 186.049C257.198 197.036 249.315 211 236.559 211Z"
      fill="#B0EACC"
    />
    <Path
      d="M192.753 215.3H201.273C201.11 211.077 197.65 207.706 193.39 207.706C192.33 207.706 191.323 207.921 190.404 208.291C188.715 205.261 185.477 203.208 181.757 203.208C179.009 203.208 176.526 204.327 174.733 206.135C173.822 205.861 172.859 205.705 171.859 205.705C166.494 205.705 162.138 209.973 161.975 215.3H178.957"
      fill="white"
    />
    <Path
      d="M192.753 215.3H201.273C201.11 211.077 197.65 207.706 193.39 207.706C192.33 207.706 191.323 207.921 190.404 208.291C188.715 205.261 185.477 203.208 181.757 203.208C179.009 203.208 176.526 204.327 174.733 206.135C173.822 205.861 172.859 205.705 171.859 205.705C166.494 205.705 162.138 209.973 161.975 215.3H178.957"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M181.757 203.208V151.358C181.757 143.868 184.728 136.688 190.026 131.39L202.14 119.276"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M181.757 197.317V172.015C181.757 168.362 183.209 164.857 185.795 162.272L193.501 154.566"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M181.758 203.209V192.48C181.758 188.828 180.306 185.323 177.72 182.737L170.022 175.039"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M181.757 188.873V163.57C181.757 159.917 180.304 156.413 177.719 153.827L163.248 139.356"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M214.003 143.565H201.533C193.486 143.565 186.966 150.085 186.966 158.131V161.103H199.436C207.482 161.103 214.003 154.582 214.003 146.536V143.565Z"
      fill="white"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M195.443 114.623C194.191 117.313 193.495 120.314 193.495 123.47V127.76H211.499C223.109 127.76 232.527 118.35 232.527 106.733V102.443H214.522C209.121 102.443 204.201 104.48 200.474 107.822"
      fill="white"
    />
    <Path
      d="M195.443 114.623C194.191 117.313 193.495 120.314 193.495 123.47V127.76H211.499C223.109 127.76 232.527 118.35 232.527 106.733V102.443H214.522C209.121 102.443 204.201 104.48 200.474 107.822"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M153.142 166.141H163.278C169.813 166.141 175.118 171.439 175.118 177.981V180.397H164.982C158.447 180.397 153.142 175.099 153.142 168.557V166.141Z"
      fill="white"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M153.951 125.738C165.642 125.738 175.119 135.214 175.119 146.906V151.226H156.996C145.304 151.226 135.828 141.749 135.828 130.057V125.738H142.726"
      fill="white"
    />
    <Path
      d="M153.951 125.738C165.642 125.738 175.119 135.214 175.119 146.906V151.226H156.996C145.304 151.226 135.828 141.749 135.828 130.057V125.738H142.726"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M335.663 145.858C351.265 144.711 363.331 135.864 362.614 126.098C361.896 116.331 348.666 109.344 333.064 110.49C317.462 111.637 305.396 120.484 306.114 130.25C306.831 140.017 320.061 147.005 335.663 145.858Z"
      fill="white"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M325.072 128.848L362.626 126.088"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M366.478 116.889C378.312 108.931 377.598 87.1495 364.882 68.2395C352.166 49.3296 332.264 40.4515 320.429 48.4098C308.594 56.3682 309.308 78.1493 322.024 97.0592C334.74 115.969 354.643 124.847 366.478 116.889Z"
      fill="white"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M325.073 55.3013L366.485 116.887"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M354.216 57.0329L346.267 86.8226"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M323.292 98.6484L357.268 103.187"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M314.238 70.3628L337.267 73.4385"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M349.844 73.4213L362.452 64.9406"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M314.657 77.7818L323.794 71.6364"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M22.5249 128.496C36.3304 127.482 47.0071 119.653 46.3718 111.01C45.7366 102.368 34.0301 96.1839 20.2246 97.1986C6.41906 98.2133 -4.25757 106.042 -3.62236 114.685C-2.98714 123.327 8.7194 129.511 22.5249 128.496Z"
      fill="white"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M29.6115 112.237L-3.61694 114.682"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M33.7055 183.414C44.1777 176.372 43.5463 157.099 32.2951 140.368C21.044 123.636 3.43379 115.782 -7.03841 122.824C-17.5106 129.866 -16.8792 149.138 -5.62804 165.869C5.62309 182.601 23.2333 190.456 33.7055 183.414Z"
      fill="white"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M29.6077 177.323L-7.03839 122.828"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M3.81293 175.795L10.8478 149.433"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M31.1789 138.967L1.11243 134.951"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M39.1929 163.999L18.8181 161.274"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M7.6878 161.293L-3.46942 168.796"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <Path
      d="M38.8258 157.432L30.7369 162.87"
      stroke="#2B2E32"
      strokeWidth="2.1962"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </Svg>
);
