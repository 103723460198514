import React, { useState } from "react";
import { StyleSheet } from "react-native";

import { Button, Form, Text } from "@components/ui";
import { TextInput } from "@components/inputs";

import { emailValidator } from "../../utils/validator";
import { defaultColors, spacing } from "../../theme";
import { TxKeyPath, translate } from "../../i18n";

const styles = StyleSheet.create({
  form: {
    flex: 1,
    minHeight: 300,
    alignContent: "space-between",
  },
  enterEmailText: {
    marginTop: spacing[3],
    marginBottom: spacing[2],
    textAlign: "center",
    fontSize: 14,
    lineHeight: 16,
    color: defaultColors.label,
  },
  buttonContinue: {
    marginTop: "auto",
    marginBottom: spacing[4],
  },
});

type SendCodeToEmailProps = {
  onPress: (userName: string) => void;
  onRequest: (userName: string) => any;
  defaultUserName?: string;
  children?: React.ReactNode;
  txLabel?: TxKeyPath;
  title?: string | null;
};

export const SendCodeToEmail = ({
  onPress,
  onRequest,
  defaultUserName = "",
  children,
  txLabel = null,
  title = null,
}: SendCodeToEmailProps) => {
  const [formData, setFormData] = useState({
    username: defaultUserName,
  });
  const [errors, setErrors] = useState(null);

  // const [error, setError] = useState(null);

  const continueHandler = async () => {
    try {
      const result = await onRequest(formData.username);

      if (result.errors) {
        setErrors(result.errors[0]);
      } else {
        onPress(formData.username);
      }
    } catch (err) {
      setErrors(err.message || "No email");
    }
  };

  const setFormDataHandler = (key, value) => {
    setErrors(emailValidator(value));
    setFormData({ ...formData, [key]: value });
  };

  const disabledContinue = !(errors?.length === 0);

  return (
    <>
      <Text style={styles.enterEmailText}>
        {title || translate("Auth.enterEmail")}
      </Text>

      <Form.Container style={styles.form}>
        <Form.Inputs>
          <TextInput
            txLabel={txLabel}
            onChangeText={(value) => setFormDataHandler("username", value)}
            value={formData.username}
            errorText={errors}
            returnKeyType="next"
            autoCapitalize="none"
            textContentType="emailAddress"
            keyboardType="email-address"
          />
        </Form.Inputs>
        <Button
          style={styles.buttonContinue}
          type="primary"
          onPress={continueHandler}
          tx="common.continue"
          disabled={disabledContinue}
        >
          Continue
        </Button>
        {children || null}
      </Form.Container>
    </>
  );
};
