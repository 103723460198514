import { observer } from "mobx-react-lite";

import { useStores } from "@models/index";
import { goToAppUpdate } from "@models/settings/versions-store";
import { Dialog, Text } from "@components/ui";

import { translate } from "../../i18n";

export const AppUpdateNotification = observer(() => {
  const {
    settingsStore: {
      wasUpdateAppHintShown,
      markUpdateAppHintAsShown,
      versions: { isNewAppAvailable, latestAppVersion },
    },
  } = useStores();

  if (!isNewAppAvailable) {
    return null;
  }

  if (wasUpdateAppHintShown) {
    return null;
  }

  return (
    <Dialog
      visible
      title={translate("Settings.UpdateApp.title")}
      okText={translate("common.update")}
      onOk={goToAppUpdate}
      onCancel={markUpdateAppHintAsShown}
    >
      <Text>
        {translate("Settings.UpdateApp.content", {
          version: latestAppVersion,
        })}
      </Text>
    </Dialog>
  );
});
