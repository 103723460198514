import React, { useEffect, useState } from "react";
import { Platform, StyleSheet } from "react-native";
import { StackScreenProps } from "@react-navigation/stack";
import { observer } from "mobx-react-lite";

import { calibrationIsAvailable } from "@models/sensor/sensor-calibration";
import { useStores } from "@models/index";
import { Screen } from "@components/index";
import { TopBarNew } from "@components/layout";
import { Dialog, ErrorsAlert } from "@components/ui";

import { WarningSvg } from "../../../svgs/pictures";
import * as SensorIcons from "../../../svgs/sensors";
import { CloseIcon, NoneIcon } from "../../../svgs";
import { translate } from "../../../i18n";
import { spacing } from "../../../theme";
import { SensorsNavigatorParamList } from "../navigation";
import { CalibrationNotAvailable } from "./NotAvailable";
import { CalibrationStep } from "./types";
import {
  ActiveSensorRulesWarning,
  CalibrationStepClean,
  CalibrationStepPrepare,
  CalibrationStepProcess,
  CalibrationStepSuccess,
  CalibrationStepPrepareInfo,
} from "./components";

const styles = StyleSheet.create({
  screen: {
    paddingBottom: Platform.OS === "ios" ? spacing[5] : 0,
    flex: 1,
  },
});

export const SensorCalibration: React.FC<
  StackScreenProps<SensorsNavigatorParamList, "sensor-calibration">
> = observer(({ route, navigation }) => {
  const { params = {} } = route as any;
  const { sensorCalibration, sensorStore } = useStores();

  const [isStopCalibration, setStopCalibration] = useState(false);
  const showStopCalibrationDialog = () => {
    setStopCalibration(true);
  };
  const hideStopCalibrationDialog = () => {
    setStopCalibration(false);
  };

  const sensor = sensorStore.getSensor(params?.uid);

  useEffect(() => {
    sensorCalibration.reset(sensor?.uid, sensor?.type);

    if (sensor?.isChangeableType) {
      navigation.navigate("sensor-calibration-longtime", { uid: sensor?.uid });
    }
  }, [sensor]);

  // TODO If not sensor -> fetch sensors !!!
  if (!sensor) {
    navigation.navigate("sensors-list");
    return null;
  }

  const stopCalibration = () => {
    hideStopCalibrationDialog();
    sensorCalibration.reset();

    if (sensor?.isChangeableType) {
      navigation.navigate("sensors-list");
      navigation.navigate("sensor-details", { uid: sensor.uid });
    } else {
      navigation.goBack();
    }
  };

  const backHandler = () => {
    if (sensorCalibration.hasStarted) {
      showStopCalibrationDialog();
      return null;
    }

    stopCalibration();
    return null;
  };

  if (!calibrationIsAvailable(sensor.type)) {
    return <CalibrationNotAvailable sensor={sensor} />;
  }

  const Icon = SensorIcons[sensor.iconName] || NoneIcon;

  // TODO: show Errors if has errors not in Progress !!!

  return (
    <Screen
      style={styles.screen}
      header={
        <TopBarNew
          title={translate("Sensors.Calibration.titleWithSensorType", {
            type: sensor.displayType,
          })}
          subtitle={sensor.displayName}
          icon={Icon}
          back={false}
          action={{
            icon: CloseIcon,
            onPress: backHandler,
          }}
        />
      }
    >
      {sensorCalibration.currentStep === CalibrationStep.PrepareInfo ? (
        <CalibrationStepPrepareInfo />
      ) : null}

      {sensorCalibration.currentStep === CalibrationStep.Prepare ? (
        <CalibrationStepPrepare />
      ) : null}

      {sensorCalibration.currentStep === CalibrationStep.Clean ? (
        <CalibrationStepClean />
      ) : null}

      {sensorCalibration.currentStep === CalibrationStep.Process1 ||
      sensorCalibration.currentStep === CalibrationStep.Process2 ? (
        <CalibrationStepProcess onAbort={backHandler} />
      ) : null}

      {sensorCalibration.currentStep === CalibrationStep.Success ? (
        <CalibrationStepSuccess />
      ) : null}

      <Dialog
        title={translate("Sensors.Calibration.stopCalibrationTitle")}
        content={translate("Sensors.Calibration.stopCalibrationContent")}
        visible={isStopCalibration}
        onCancel={hideStopCalibrationDialog}
        okType="warning"
        okText={translate("common.stop")}
        onOk={stopCalibration}
      />

      <ErrorsAlert
        title=""
        Image={WarningSvg}
        errors={sensorCalibration.warningMessages}
        onCancel={sensorCalibration.resetWarnings}
      />

      <ActiveSensorRulesWarning sensorUid={sensor.uid} />
    </Screen>
  );
});
