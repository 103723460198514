import * as React from "react";
import Svg, { Rect } from "react-native-svg";

export default function DotsVerticalIcon({
  color = "#939393",
  width = 24,
  height = 24,
}) {
  return (
    <Svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <Rect x="10" y="2" width="4" height="4" rx="2" fill={color} />
      <Rect x="10" y="10" width="4" height="4" rx="2" fill={color} />
      <Rect x="10" y="18" width="4" height="4" rx="2" fill={color} />
    </Svg>
  );
}
