import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { useNavigation, useTheme } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";

import { Button, Text } from "@components/ui";
import { SensorsNavigatorParamList } from "@screens/Sensors/navigation";
import { CalibrationContent } from "@screens/Sensors/Calibration/components/CalibrationContent";

import { CalibrationSuccessSvg } from "../../../../../svgs/pictures/calibration";
import { translate } from "../../../../../i18n";
import { CalibrationContentProps } from "./types";
import { spacing } from "../../../../../theme";

const createStyles = (theme: any) =>
  StyleSheet.create({
    description: {
      color: theme.colors.label,
      textAlign: "center",
    },
    buttonContainer: {
      paddingHorizontal: spacing[3],
      marginTop: spacing[4],
    },
  });

export const CalibrationStepSuccess = ({
  calibration,
}: CalibrationContentProps) => {
  const theme = useTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);

  const navigation =
    useNavigation() as StackNavigationProp<SensorsNavigatorParamList>;

  const onSubmit = () => {
    navigation.navigate("sensors-list");
  };

  return (
    <CalibrationContent
      Picture={CalibrationSuccessSvg}
      title={translate("Sensors.Calibration.sensorHasBeenCalibrated")}
      submitLabel={translate("common.done")}
      onSubmit={onSubmit}
    >
      <Text style={styles.description}>
        {translate("Sensors.Calibration.successMessage")}
      </Text>

      <View style={styles.buttonContainer}>
        <Button type="primary" onPress={calibration.start}>
          {translate("Sensors.Calibration.startNewCalibration")}
        </Button>
      </View>
    </CalibrationContent>
  );
};
