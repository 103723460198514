import dayjs from "dayjs";
import { DateTimeFormats, dateTimeHaveTimeToSeconds } from "./timeConverter";

export function localeCompare(
  a: string | null | undefined,
  b: string | null | undefined,
) {
  return a && b && a.localeCompare(b);
}

export function sortDataBy(data: any[], byKey: string) {
  return data.sort(
    (itemA, itemB) =>
      itemA[byKey] - itemB[byKey] || localeCompare(itemA?.name, itemB?.name),
  );
}

export function sortDataByTime(data: any[], byKey: string) {
  return data.sort(
    (itemA, itemB) =>
      dateTimeHaveTimeToSeconds(itemA[byKey]) -
      dateTimeHaveTimeToSeconds(itemB[byKey]),
  );
}

export function groupByDataByDate(data: any[], byKey: string) {
  return data.reduce((group, item) => {
    const date = dayjs(item[byKey]).format(DateTimeFormats.DateYMD);
    if (!group[date]) {
      group[date] = [];
    }
    group[date].push(item);
    return group;
  }, {});
}

// function sortSensors(sensors) {
//   return sensors
//     // .filter((b) => b.isAvailable)
//     .sort((a, b) => (a.position > b.position ? 1 : a.position === b.position ? 0 : -1))
// }
