import { IObservableValue, observable } from "mobx";

export enum OfflineTestType {
  Fail,
  Module,
  Router,
  Internet,
  Cloud,
  Ok,
}

export const withTestModuleConnection = () => {
  const offlineTest: IObservableValue<OfflineTestType> = observable.box(
    OfflineTestType.Fail,
  );

  return {
    views: {
      get offlineTest() {
        return offlineTest.get() as OfflineTestType;
      },
      // set offlineTest(value: OfflineTestType) {
      //   offlineTest.set(value);
      // },
      get isModuleConnectionTesting() {
        return offlineTest.get() !== OfflineTestType.Fail;
      },
      get isModuleConnectionOk() {
        return offlineTest.get() === OfflineTestType.Ok;
      },
      get isModuleConnectionFailure() {
        return offlineTest.get() === OfflineTestType.Fail;
      },
      get isTestingModule() {
        return offlineTest.get() >= OfflineTestType.Module;
      },
      get isTestingRouter() {
        return offlineTest.get() >= OfflineTestType.Router;
      },
      get isTestingInternet() {
        return offlineTest.get() >= OfflineTestType.Internet;
      },
      get isTestingCloud() {
        return offlineTest.get() >= OfflineTestType.Cloud;
      },
    },

    actions: {
      setOfflineTestType(value: OfflineTestType) {
        offlineTest.set(value);
      },
      resetOfflineTestType() {
        offlineTest.set(OfflineTestType.Fail);
      },
    },
  };
};
