import * as React from "react";
import Svg, { Path } from "react-native-svg";

export default function TouchIdIcon({ color = "#000000", size = 24 }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M18 20a.94.94 0 0 1-.55-.17 1 1 0 0 1-.28-1.38A13.4 13.4 0 0 0 19 11a7 7 0 0 0-7-7 6.87 6.87 0 0 0-2.62.51 1 1 0 1 1-.76-1.85A8.89 8.89 0 0 1 12 2a9 9 0 0 1 9 9 15.2 15.2 0 0 1-2.17 8.55A1 1 0 0 1 18 20ZM3.34 15A1.19 1.19 0 0 1 3 15a1 1 0 0 1-.62-1.28A8.81 8.81 0 0 0 3 11a9 9 0 0 1 2.25-5.95 1 1 0 1 1 1.5 1.33A7 7 0 0 0 5 11a10.63 10.63 0 0 1-.71 3.33 1 1 0 0 1-.95.67Z"
        fill={color}
      />
      <Path
        d="M13 22a1 1 0 0 1-.6-.2 1 1 0 0 1-.2-1.4A15 15 0 0 0 15 11a1 1 0 0 1 2 0 16.87 16.87 0 0 1-3.2 10.6 1 1 0 0 1-.8.4ZM6.17 18.17a1 1 0 0 1-.71-.29 1 1 0 0 1 0-1.42C6 15.91 7 12.66 7 11a5 5 0 0 1 7.5-4.33 1 1 0 1 1-1 1.73A3 3 0 0 0 9 11c0 1.79-1 5.75-2.12 6.88a1 1 0 0 1-.71.29Z"
        fill={color}
      />
      <Path
        d="M8 22a1 1 0 0 1-.55-1.83C10 18.48 11 13 11 11a1 1 0 0 1 2 0c0 1.56-.79 8.4-4.45 10.83A.94.94 0 0 1 8 22Z"
        fill={color}
      />
    </Svg>
  );
}
