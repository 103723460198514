import * as React from "react";

import Svg, { Path } from "react-native-svg";

export const ModuleSearching = () => (
  <Svg width={229} height={170} fill="none">
    <Path
      d="M193.548 169.725H41.928c-17.23 0-27.9-18.78-19.06-33.58l74.39-124.58c9.2-15.41 31.51-15.42 40.73-.03l74.6 124.58c8.87 14.8-1.79 33.61-19.04 33.61ZM3.699 63.902h25.23c2.87 0 4.64 3.13 3.17 5.59l-12.38 20.73c-1.53 2.56-5.24 2.57-6.78 0L.528 69.492c-1.47-2.46.3-5.59 3.17-5.59ZM183.249 12.355h30.64c3.48 0 5.64 3.8 3.85 6.79l-15.03 25.17c-1.86 3.11-6.37 3.12-8.23.01l-15.07-25.17c-1.8-3 .36-6.8 3.84-6.8Z"
      fill="#B0EACC"
    />
    <Path
      d="M121.018 168.505h87.03c5.71 0 9.23-6.22 6.3-11.12l-24.68-41.22c-1.52-2.53-4.01-3.8-6.61-3.81h-62.05"
      fill="#fff"
    />
    <Path
      d="M121.018 168.505h87.03c5.71 0 9.23-6.22 6.3-11.12l-24.68-41.22c-1.52-2.53-4.01-3.8-6.61-3.81h-62.05"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M146.098 168.504h-50.16c-5.7 0-9.23-6.21-6.31-11.11l24.61-41.22c3.04-5.1 10.43-5.1 13.48-.01l24.68 41.22c2.93 4.9-.59 11.12-6.3 11.12ZM67.957 160.776h6.88c6.33 0 11.46-5.13 11.46-11.46v-9.8h-6.88c-6.33 0-11.46 5.13-11.46 11.46v9.8Z"
      fill="#fff"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="m77.127 150.152-9.17 10.62v7.73"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M67.957 149.104h-6.88c-6.33 0-11.46-5.13-11.46-11.46v-9.8h6.88c6.33 0 11.46 5.13 11.46 11.46v9.8Z"
      fill="#fff"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="m58.787 138.484 9.17 10.62v7.74"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M115.188 160.776h6.88c6.33 0 11.459-5.13 11.459-11.46v-9.8h-6.879c-6.33 0-11.46 5.13-11.46 11.46v9.8Z"
      fill="#fff"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="m124.348 150.152-9.16 10.62v7.73"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M115.188 149.104h-6.88c-6.33 0-11.46-5.13-11.46-11.46v-9.8h6.88c6.33 0 11.46 5.13 11.46 11.46v9.8Z"
      fill="#fff"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="m106.018 138.484 9.17 10.62v7.74M49.348 168.504h46.67M103.607 168.504h34.91"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M92.69 15.332h-6"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeLinejoin="round"
    />
    <Path
      d="M74.61 15.332H38.58c-10.43 0-18.88 8.45-18.88 18.88v1.28c0 10.43 8.45 18.88 18.88 18.88h169.849c10.43 0 18.88 8.45 18.88 18.88s-8.45 18.88-18.88 18.88h-6.52"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeLinejoin="round"
      strokeDasharray="12.08 12.08"
    />
    <Path
      d="M195.869 92.14h-6"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeLinejoin="round"
    />
    <Path
      d="M120.038 30.434c-19.48 0-35.28 15.79-35.28 35.28 0 8.18 2.79 15.7 7.46 21.68-.06.06-.12.11-.18.17l-14.66 14.66c-3.1 3.1-3.1 8.13 0 11.23 3.1 3.1 8.13 3.1 11.23 0l14.66-14.66c.45-.45.82-.94 1.14-1.45a35.17 35.17 0 0 0 15.63 3.65c19.48 0 35.28-15.79 35.28-35.28 0-19.49-15.8-35.28-35.28-35.28Zm0 62.63c-15.11 0-27.35-12.25-27.35-27.35 0-15.11 12.25-27.35 27.35-27.35 15.11 0 27.35 12.25 27.35 27.35 0 15.1-12.24 27.35-27.35 27.35Z"
      fill="#fff"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M146.482 72.74c3.877-14.598-4.814-29.576-19.413-33.454-14.599-3.877-29.577 4.814-33.454 19.413-3.878 14.599 4.814 29.577 19.413 33.455 14.599 3.877 29.577-4.814 33.454-19.413Z"
      fill="#B0EACC"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <Path
      d="M139.277 65.715c0 7.97-4.84 14.8-11.75 17.73M100.797 65.713c0-10.63 8.61-19.24 19.24-19.24"
      stroke="#2B2E32"
      strokeWidth={2.196}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
  </Svg>
);
