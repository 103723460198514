import React, { memo } from "react";
import { StyleProp, View, ViewStyle } from "react-native";

import { LogoHorizontal as LogoHorizontalIcon } from "../../svgs";

type Props = {
  style?: StyleProp<ViewStyle>;
};

export const LogoHorizontal = memo(({ style }: Props) => {
  return (
    <View style={[style]}>
      <LogoHorizontalIcon />
    </View>
  );
});
