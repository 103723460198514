import { useNavigation } from "@react-navigation/native";

/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-empty */
export const useNavigationSafety = (): unknown => {
  try {
    return useNavigation();
  } catch (e) {
    return null;
  }
};
