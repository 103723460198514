import React, { useMemo } from "react";
import { StyleSheet, View, ViewStyle } from "react-native";

import { DimmerRuleData } from "@models/rule/rule";
import { PickerInput } from "@components/inputs";

import { spacing } from "../../../../../../theme";

import range from "../../../../../../utils/range";
import { useAppTheme } from "../../../../../../hooks";
import { DimmerRuleErrors } from "./types";

const createStyles = (theme: any) =>
  StyleSheet.create({
    container: {
      marginBottom: spacing[4],
      padding: spacing[4],
      paddingBottom: spacing[3],
      borderRadius: spacing[4],
      borderWidth: 1,
      borderColor: theme.colors.menuBorder,
    },

    columns: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginHorizontal: -spacing[2],
    },
    column: {
      flex: 1,
      marginHorizontal: spacing[2],
    },

    label: {
      color: theme.colors.label,
      fontSize: 16,
      marginBottom: spacing[2],
    },
  });

type ManualDimmingProps = {
  formData?: DimmerRuleData;
  changeFormData?: (data: any) => void;
  errors?: DimmerRuleErrors;
  style?: ViewStyle;
  enabled?: boolean;
};

export const ManualDimming = ({
  formData,
  changeFormData = () => null,
  errors,
  style,
  enabled = true,
}: ManualDimmingProps) => {
  const theme = useAppTheme() as any;
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <View style={[styles.container, style]}>
      <View style={styles.columns}>
        <View style={styles.column}>
          <PickerInput
            txLabel="Devices.Rules.dimming"
            labelStyle={styles.label}
            containerStyle={styles.column}
            selectedValue={formData?.analogControlPinValue}
            errorText={errors?.analogControlPinValue}
            onChange={(value) =>
              changeFormData({
                analogControlPinValue: value,
              })
            }
            items={range(10, 110, 1).map((number) => ({
              value: number,
              label: `${number.toString()} %`,
            }))}
            enabled={enabled}
          />
        </View>
        <View style={styles.column} />
      </View>
    </View>
  );
};
