import { StyleSheet } from "react-native";

import { Screen } from "@components/index";
import { LayoutCenter, TopBar } from "@components/layout";
import { Button, Heading } from "@components/ui";
import { SendCodeToEmail } from "@components/common";
import { useStores } from "@models/index";

import { spacing } from "../../theme";

const styles = StyleSheet.create({
  container: {
    padding: spacing[4],
    flex: 1,
  },
  heading: {
    marginTop: 124,
  },
  buttonContinue: {
    marginTop: "auto",
    marginBottom: spacing[4],
  },
});

export function ForgotPasswordScreen({ navigation }) {
  const goToRecoveryPassword = (userName: string) => {
    navigation.navigate("RecoveryPassword", {
      username: userName,
    });
  };

  const { accountStore } = useStores();
  const onRequest = (userName: string) => {
    return accountStore.recoveryRequest(userName);
  };

  const signInHandler = () => navigation.navigate("SignIn");

  return (
    <Screen testID="ForgotPassword" header={<TopBar back title="" />} keyboard>
      <LayoutCenter style={styles.container}>
        <Heading
          style={styles.heading}
          tx="Auth.ForgotPasswordScreen.heading"
        />

        <SendCodeToEmail
          onPress={goToRecoveryPassword}
          onRequest={onRequest}
          txLabel="fields.email"
        >
          <Button type="ghost" onPress={signInHandler} tx="Auth.logIn">
            Log In
          </Button>
        </SendCodeToEmail>
      </LayoutCenter>
    </Screen>
  );
}
