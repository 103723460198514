import { View, StyleSheet } from "react-native";
import { shadow } from "react-native-paper";

import { translate } from "../../../../../i18n";
import { DefaultTheme, spacing } from "../../../../../theme";
import {
  CopyRuleIcon,
  GrowthStagesIcon,
  RoutineRuleIcon,
} from "../../../../../svgs/devices/rules";
import { CardRule } from "../../components";

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    backgroundColor: DefaultTheme.colors.background,
    borderRadius: spacing[2],
    paddingHorizontal: spacing[4],
    paddingTop: spacing[4],
    bottom: 86,
    width: "100%",
    left: 0,
    ...(shadow(5) as any),
  },
});

export enum ButtonCreatRuleTypes {
  RoutineRule = "RoutineRule",
  GrowthStages = "GrowthStages",
  CopyRule = "CopyRule",
}

export const ButtonCreatRuleHint = (
  type = ButtonCreatRuleTypes.RoutineRule,
) => {
  return (
    <View style={styles.container}>
      {type === ButtonCreatRuleTypes.RoutineRule ? (
        <CardRule
          Icon={RoutineRuleIcon}
          title={translate("Devices.Details.routineRuleTitle")}
          text={translate("Devices.Details.routineRuleText")}
          goTo={() => null}
        />
      ) : null}

      {type === ButtonCreatRuleTypes.GrowthStages ? (
        <CardRule
          disabled
          Icon={GrowthStagesIcon}
          title={translate("Devices.Details.growthStagesTitle")}
          text={`${translate(
            "Devices.Details.growthStagesText",
          )} (coming soon...)`}
          goTo={() => null}
        />
      ) : null}

      {type === ButtonCreatRuleTypes.CopyRule ? (
        <CardRule
          Icon={CopyRuleIcon}
          title={translate("Devices.Details.copyRule")}
          text={translate("Devices.Details.copyRuleText")}
          goTo={() => null}
        />
      ) : null}
    </View>
  );
};
