import { Platform, StyleSheet } from "react-native";

import { THintsDataItem } from "@components/hints";
import {
  Rule,
  RuleDirection,
  RuleType,
  SensorRuleData,
} from "@models/rule/rule";

import { translate } from "../../../../../i18n";
import { spacing } from "../../../../../theme";
import {
  AddScheduleRuleBattonHinte,
  SensorInputHinte,
  SensorValuesHinte,
  ReservoirHinte,
  WorkingTimeInputHinte,
  ManualDimmingHinte,
  OverheatPreventionInputHint,
  SunriseSunsetInputHint,
  AutoBalancingHint,
  TimeToSetPointInputHint,
  ButtonRuleTypeHinte,
} from "./components";

const top = Platform.OS === "web" ? 55 : -20;

const dialogStyle = StyleSheet.create({
  dialogTop: {
    position: "absolute",
    right: 0,
    left: 0,
    top,
    maxHeight: 250,
  },
  dialogBottom: {
    position: "absolute",
    right: 0,
    left: 0,
    bottom: spacing[4],
    maxHeight: 280,
  },
});

const gotIt = (): THintsDataItem => ({
  id: "gotIt",
  title: translate("Hints.helpButtonTitle"),
  text: translate("Hints.helpButtonContent"),
});

const addScheduleRule = (): THintsDataItem[] => [
  {
    id: "ButtonAddScheduleRule",
    title: translate("Hints.title"),
    text: translate("Hints.Rules.textHintButtonAddScheduleRule"),
    HintComponent: AddScheduleRuleBattonHinte,
    dialogStyle: dialogStyle.dialogBottom,
  },
  gotIt(),
];

const sensorRule = (changeSensorRule: SensorRuleData): THintsDataItem[] => [
  {
    id: "SensorInput",
    title: translate("Hints.title"),
    text: translate("Hints.Rules.textHintSensorInput"),
    HintComponent: () => SensorInputHinte(changeSensorRule.sensorUid),
    dialogStyle: dialogStyle.dialogBottom,
  },
  {
    id: "SensorValuesLess",
    title: translate("Hints.title"),
    text: translate("Hints.Rules.textHintSensorValuesLess"),
    HintComponent: () =>
      SensorValuesHinte(changeSensorRule.sensorUid, RuleDirection.Up),
    dialogStyle: dialogStyle.dialogTop,
  },
  {
    id: "SensorValuesMore",
    title: translate("Hints.title"),
    text: translate("Hints.Rules.textHintSensorValuesMore"),
    HintComponent: () =>
      SensorValuesHinte(changeSensorRule.sensorUid, RuleDirection.Down),
    dialogStyle: dialogStyle.dialogTop,
  },
];

const Reservoir: THintsDataItem = {
  id: "Reservoir",
  title: translate("Hints.title"),
  text: translate("Hints.Rules.textHintReservoir"),
  HintComponent: ReservoirHinte,
  dialogStyle: dialogStyle.dialogTop,
};

const TimeToSetPointInput: THintsDataItem = {
  id: "TimeToSetPointInput",
  title: translate("Hints.title"),
  text: translate("Hints.Rules.textHintTimeToSetPointInput"),
  HintComponent: TimeToSetPointInputHint,
  dialogStyle: dialogStyle.dialogTop,
};

const addSensorRule = (changeSensorRule: SensorRuleData): THintsDataItem[] => {
  if (changeSensorRule.isPhOrEc) {
    return [
      ...sensorRule(changeSensorRule),
      Reservoir,
      TimeToSetPointInput,
      gotIt(),
    ];
  }
  return [...sensorRule(changeSensorRule), TimeToSetPointInput, gotIt()];
};

const workingTimeInput: THintsDataItem = {
  id: "WorkingTimeInput",
  title: translate("Hints.title"),
  text: translate("Hints.Rules.textHintWorkingTimeInput"),
  HintComponent: WorkingTimeInputHinte,
  dialogStyle: dialogStyle.dialogBottom,
};

const overheatPreventionInput: THintsDataItem = {
  id: "OverheatPreventionInput",
  title: translate("Hints.title"),
  text: translate("Hints.Rules.textHintOverheatPreventionInput"),
  HintComponent: OverheatPreventionInputHint,
  dialogStyle: dialogStyle.dialogTop,
};

const sunriseSunsetInput: THintsDataItem = {
  id: "SunriseSunsetInput",
  title: translate("Hints.title"),
  text: translate("Hints.Rules.textHintSunriseSunsetInput"),
  HintComponent: SunriseSunsetInputHint,
  dialogStyle: dialogStyle.dialogTop,
};

const addManualDimmerRule = (): THintsDataItem[] => [
  workingTimeInput,
  {
    id: "ManualDimming",
    title: translate("Hints.title"),
    text: translate("Hints.Rules.textHintManualDimming"),
    HintComponent: ManualDimmingHinte,
    dialogStyle: dialogStyle.dialogTop,
  },
  overheatPreventionInput,
  sunriseSunsetInput,
  gotIt(),
];

const addAutoDimmerRule = (): THintsDataItem[] => [
  workingTimeInput,
  {
    id: "AutoBalancing",
    title: translate("Hints.title"),
    text: translate("Hints.Rules.textHintAutoBalancing"),
    HintComponent: AutoBalancingHint,
    dialogStyle: dialogStyle.dialogTop,
  },
  overheatPreventionInput,
  sunriseSunsetInput,
  gotIt(),
];

const ButtonScheduleRuleType = () => ButtonRuleTypeHinte(RuleType.Schedule);
const ButtonSensorRuleType = () => ButtonRuleTypeHinte(RuleType.Sensor);

const selectRuleType = (): THintsDataItem[] => [
  {
    id: "ButtonScheduleRuleType",
    title: translate("Hints.title"),
    text: translate("Hints.Rules.textHintSelectScheduleRuleType"),
    HintComponent: ButtonScheduleRuleType,
    dialogStyle: dialogStyle.dialogBottom,
  },
  {
    id: "ButtonSensorRuleType",
    title: translate("Hints.title"),
    text: translate("Hints.Rules.textHintSelectSensorRuleType"),
    HintComponent: ButtonSensorRuleType,
    dialogStyle: dialogStyle.dialogBottom,
  },
  gotIt(),
];

export const getAddRuleHints = (rule: Rule, formData?: SensorRuleData) => {
  if (!rule?.ruleType) {
    return selectRuleType();
  }

  if (rule.ruleType === RuleType.Schedule) {
    return addScheduleRule();
  }

  if (rule.ruleType === RuleType.Sensor && formData) {
    return addSensorRule(formData);
  }

  if (rule.ruleType === RuleType.DimmerManual) {
    return addManualDimmerRule();
  }

  if (rule.ruleType === RuleType.DimmerAuto) {
    return addAutoDimmerRule();
  }

  return null;
};
