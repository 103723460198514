import { CheckList, CheckListItemProps, CheckListStatus } from "@components/ui";
import {
  ConnectingStatus,
  ConnectingStep,
  MODULE_CONNECTING_STEPS,
  ModuleConnection,
} from "@models/module/module-connection";

import { translate } from "../../../../../i18n";

const getSteps = (
  currentStep: ConnectingStep,
  status: ConnectingStatus,
): CheckListItemProps[] => {
  const index = MODULE_CONNECTING_STEPS.findIndex(
    (step) => step.key === currentStep,
  );
  const currentStatus =
    status === ConnectingStatus.Error
      ? CheckListStatus.Error
      : CheckListStatus.Processing;

  return MODULE_CONNECTING_STEPS.slice(0, index + 1).map((step, i) => ({
    ...step,
    status: i === index ? currentStatus : CheckListStatus.Success,
  }));
};

type Props = {
  moduleConnection: ModuleConnection;
};

export const ModuleConnectionProcessStatus = ({ moduleConnection }: Props) => {
  const steps = getSteps(moduleConnection.currentStep, moduleConnection.status);

  if (steps.length) {
    return (
      <CheckList
        title={translate("Modules.Add.Manual.stepsTitle")}
        items={steps}
        style={{ flex: 1 }}
      />
    );
  }
  return null;
};
